import React, { Suspense, lazy, useState } from 'react';
import { isMobileOnly } from 'react-device-detect'
import LoadingIcon from './Components/Common/LoadingIcon';
import * as AWS from 'aws-sdk';
import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';

// import { useIdleTimer } from 'react-idle-timer';

// import { onUpdateDeventMarkets } from './graphql/subscriptions';

import { differenceInMinutes, format } from 'date-fns';
import { sessionTimer } from './config';
import { includes } from 'lodash';


const DesktopRoutes = lazy(() => import('./Routes/desktopRoutes'));
// const MobileRoutes = lazy(() => import('./Routes/mobileRoutes'));
Amplify.configure(awsconfig);

AWS.config.update({
  region: 'eu-west-1',
  endpoint: 'dynamodb.eu-west-1.amazonaws.com', 
  accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY
});

const dynamodb = new AWS.DynamoDB();
const dynamoClient = new AWS.DynamoDB.DocumentClient();

function App(props) {
   
    return (
        <div className="main-wrap">
            <Suspense fallback={<LoadingIcon theme="dark centered" />}>
                {/* { isMobileOnly ? <MobileRoutes isMobileOnly={ isMobileOnly } {...props } /> : <DesktopRoutes isMobileOnly={ isMobileOnly } {...props } /> } */}
                <DesktopRoutes isMobileOnly='false' {...props } /> 
            </Suspense>
        </div>

    );
}

export default App;
export { dynamoClient };