export const tr = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Spor Dalları',
    live: 'CANLI',
    inplay: 'Oyunda',
    today: 'Bugün',
    upcoming: 'Yaklaşan',
    tomorrow: 'Yarın',
    highlights: 'Önemli',
    results: 'Sonuçlar',
    lastMinute: 'Son dakika',
    liveCasino: 'Canlı Casino',
    coinPayments: 'Coin Payments',
    casino: 'kumarhane',
    myBets: 'Benim bahis',
    home: 'Ev',
    login: 'Oturum aç',
    signup: 'kaydol',
    betslip: 'Bahis kuponu',
    search: 'Arama',
    searchHere: 'Burada ara',
    transaction: 'işlem',
    changePassword: 'Şifre değiştir',
    logout: 'Çıkış Yap',
    single: 'Tek',
    multiple: 'çoklu',
    possibleWin: 'Olası kazan',
    amount: 'Miktar',
    ok: 'Tamam mı',
    totalOdds: 'Toplam oranlar',
    odd: 'garip',
    totalPossibleWin: 'Toplam olası kazan',
    placeBet: 'Bahis yeri',
    fastBet:'hızlı bahis',
    fast: 'Hızlı',
    bet: 'bahis',
    username: 'Kullanıcı adı',
    password: 'Parola',
    stayLoggedIn: 'Bağlı kal',
    all: 'Herşey',
    'in game': 'Oyunda',
    won: 'Kazandı',
    lost: 'Kayıp',
    refund: 'Geri ödeme',
    date: 'tarih',
    winAmount: 'Win tutarı',
    stake: 'kazık',
    pick: 'Toplamak',
    myAccount: 'Hesabım',
    football: 'Futbol',
    basketball: 'Basketbol',
    iceHockey: 'Buz Hokeyi',
    tennis: 'Tenis',
    volleyball: 'Voleybol',
    boxing: 'Boks',
    americanFootball: 'Amerikan futbolu',
    baseball: 'Beyzbol',
    hockey: 'Hokey',
    tableTennis: 'Masa Tenisi',
    badminton: 'Badminton',
    cricket: 'Kriket',
    futsal: 'Futsal',
    golf: 'Golf',
    handball: 'Hentbol',
    rugbyLeagues: 'Rugby ligleri',
    pickCanNotBeCombined: 'Bu seçim birleştirilemez',
    matchStopped: 'Maç durdu',
    favorites: 'Favoriler',
    cashout: 'Param bitti',
    'in progress': 'Devam etmekte',
    rejected: 'Reddedildi',
    tip: 'İpucu',
    noGoal: 'Gol yok',
    yes: 'Evet',
    over: 'Bitmiş',
    under: 'Altında',
    even: 'Hatta',
    areYouSureWantCashout: 'Eğer Cashout istediğinden emin misin?',
    noGoal: 'Hayır',
    bonus: 'Bonus',
    termsAndConditon: 'Şartlar ve koşullar',
    aboutUs: 'Hakkımızda',
    contactUs: 'Bize Ulaşın',
    payments: 'Ödemeler',
    howPlacebet: 'Bahis Nasıl Yapılır',
    deposit: 'Depozito',
    credit: 'Kredi',
    debit: 'Borç',
    withdraw: 'Çekil',
    totalOdd: 'Toplam Tek',
    totalStake: 'Toplam Bahis',
    topGames: 'En iyi oyunlar',
    allGames: 'Bütün oyunlar',
    canNotBeCombined: 'Bu Seçim birleştirilemez',
    betSettled: 'Bahis Settled',
    betSuspended: 'Bahis Asma',
    insufficientBalance: 'Yetersiz bakiye',
    oldPassword: 'eski Şifre',
    newPassword: 'Yeni Şifre',
    confirmPassword: 'Yeni şifreyi onayla',
    change: 'Değişiklik',
    fromDate: 'İtibaren',
    toDate: 'Bugüne kadar',
    activityType: 'Etkinlik türü',
    show: 'Göstermek',
    gameType: 'Oyun tipi',
    repeatBet: 'Tekrarlama Bahis',
    someThingWrong: 'Bir şeyler yanlış gitti',
    status: 'durum',
    coupon: 'Kupon',
    acceptOddChangesText: 'Bir bahis edebilmek için değişiklikleri kabul etmek gerek',
    turnOffAway: 'Neyse kapatma',
    acceptOddChanges: 'garip değişiklikleri kabul',
    maxWinAmount: 'Maksimum kazanç miktarının',
    maxOdd: 'Max garip',
    dateTime: 'Tarih / Saat',
    type: 'tip',
    refreshCashout: 'Yenile Cashout',
    vendor: 'SATICI',
    stakeLowerThen1: 'hissesini daha az olmalıdır',
    stakeGreaterThen1: 'hissesini sonra büyük olmalıdır',
    ESports: 'Easports',
    KSports: 'Ksports',
    promotion: 'Terfi',
    customerServicePage: 'Müşteri Hizmetleri Sayfası',
    decimal: 'Ondalık',
    fraction: 'Kesir',
    americanOdds: 'Amerikan oranları',
    nothingFound: 'Hiçbirşey Bulunamadı',
    noLiveMatch: 'Canlı maç yok', 
    sportBook: 'Spor kitabı',
    marketNames: {
         1: ' 1x2',
        2: ' Çok / az',
        238: ' Kalan maç',
        59: 'Sıradaki hedef',
        7: ' Çift şans',
        52: ' Beraberlik',
        226: ' 12 dahil Fazla',
        13: ' Avrupa Handikap',
        4: 'HT / FT',
        17: ' Her iki takım puanı',
        59: ' Sıradaki hedef',
        28: ' / Aşırı Dahil mesai Altında',
        247: ' 1 Yarım Kalan',
    },
    tabNames: {
        'All': 'Herşey',
       'Main Markets':  'Ana marketler',
        'Totals':  'Toplamları',
        '1st Half':  '1. Yarı',
        '2nd Half':  '2. Yarı',
        'Home Team':  'Ev sahibi takım',
        'Away Team':  'Deplasman Takım',
        'All Periods':  'Tüm Dönemler',
        'Results': 'Sonuçlar',
        'Home / Away':  'Evden uzak',
     },
     markets: {
        2: '  Çok / az',
        3: '  Asya Handikap',
        5: '  Tek çift',
        6: '  Doğru Skor',
        7: ' Çift şans',
        9: '  Doğru Skor 1. Periyot',
        11: ' Toplam Köşeler',
        12: ' Toplam faul',
        13: ' Avrupa Handikap',
        16: ' İlk Takım için Skor',
        17: ' Her iki takım puanı',
        19: ' İlk Kartı',
        21: ' Üstünde / 1 Dönem',
        22: ' Deplasman Takım Atacak',
        23: ' Puanına Ev Sahibi Takım',
        25: ' Çifte Şans İlk yarı',
        29: ' / Aşırı yuvarlar Altında',
        30: ' / Aşırı Corners Altında - Ev Ekibi',
        31: ' / Aşırı Corners Altında - Deplasman Takımı',
        34: ' Ev Sahibi Takım için İki Yarıda da Skor',
        35: ' Deplasman Takım için İki Yarıda da Skor',
        41: ' 1. Periyot Kazanan',
        42: ' 2 Dönem Kazanan',
        43: ' 3. Periyodun Kazanan',
        44: ' 4 Dönemi Kazanan',
        45: ' Üstünde / 2 Dönem',
        46: ' Üstünde / 3 Dönemi',
        47: ' / Aşırı 4 Dönemi Altında',
        48: ' / Aşırı 5 Dönemi Altında',
        49: ' 5 Dönemi Kazanan',
        50: ' 1X2 Dahil mesai',
        51: ' Tek / Çift Dahil mesai',
        52: ' 12',
        53: ' Asya Handikap İlk yarı',
        55: ' İlk Takım için 1. Dönem Skoru',
        56: ' Son Takım için Skor',
        57: ' Son Takım için yarı sonucu',
        59: ' Sıradaki hedef',
        61: ' Avrupa Handicap İlk yarı',
        62: ' Tek / Çift İlk yarı',
        63: ' 12 İlk yarı',
        64: ' Asya Handikap 1. Periyot',
        65: ' Asya Handikap 2 Dönem',
        66: ' Asya Handikap 3 Dönem',
        67: ' Asya Handikap 4 Dönem',
        68: ' Asya Handikap 5 Dönem',
        69: ' Oyun Git mesai yarar mı?',
        70: ' En Fazla Gol Dönemi',
        71: ' En Fazla Gol Yarım',
        72: ' 1. Periyot Tek / Çift',
        73: ' 2 Dönem Tek / Çift',
        74: ' 3. Periyodun Tek / Çift',
        75: ' 4 Dönem Tek / Çift',
        76: ' 5 Dönem Tek / Çift',
        77: ' Üstünde / İlk yarı',
        78: ' En altılı Atacak',
        79: ' Bir ceza verilecek?',
        80: ' İlk Köşe',
        81: ' Son Köşe',
        82: ' Bir Kırmızı Kart gösterilecek?',
        83: ' Temiz Sheet Keep',
        84: ' Win İki Yarıda için',
        85: ' Arkasından Win için',
        86: ' Win için Nil için',
        88: ' Atlayamayacak',
        89: ' İlk dönüştürülebilir deneyin olacak mı?',
        90: ' Maç 5 Set belli olacak?',
        91: ' Ne Yarım Birinci olarak gol kaçıncı dakikada atılır?',
        92: ' İlk Takım için 2 Half Skoru',
        95: ' Köşeler Handicap',
        96: ' Deplasman Takım Win için Nil',
        97: ' Ev Sahibi Takım Win için Nil',
        98: ' Ev Sahibi Takım A Clean Sheet tutun',
        99: ' Deplasman Takım A Clean Sheet tutun',
        100: ' Skor 2 Dönem',
        101: ' Üstünde / - Ev Ekibi',
        102: ' Üzerinden / Altında - Deplasman Takımı',
        104: ' Deplasman Takım için Win İki Yarıyı',
        105: ' Ev Sahibi Takım için Win İki Yarıyı',
        106: ' Arkasından Ev Sahibi Takım için kazanın',
        107: ' Arkadan gelen Deplasman Takım için kazanın',
        108: ' Bir Cezasına itibaren Ev Sahibi Takım için Skor',
        109: ' Bir Cezasına Away From Takım için Skor',
        110: ' Deplasman Takım için Win En Az Biri Yarım',
        111: ' Ev Sahibi Takım için Win En Az Biri Yarım',
        113: ' İki Takım 1 Half Atacak',
        114: ' Herhangi Oyuncu için Skor En Az 2 Goller',
        117: ' Herhangi Oyuncu için Skor En Az 3 Goller',
        122: ' Takım Puanlama İlk To Win The Match',
        123: ' Deplasman Takım için ilk Ve The Win The Match Skoru',
        124: ' Ev Sahibi Takım için ilk Ve The Win The Match Skoru',
        128: ' Hedefleri sayısı',
        129: ' / Aşırı Corners Altında - 1 Yarım',
        132: ' Hedef açık / Aşırı Shots Altında',
        133: ' / Üzeri Toplam Shots Altında',
        134: ' Hedefler 1. Yarı sayısı',
        136: ' Üstünde / Ofsayt',
        137: ' Ev Win Hayır Bahis',
        138: ' Deplasman Win Hayır Bahis',
        139: ' Hangi Yarım Ev Ekibi ilk golü mı?',
        140: ' Hangi Dışarıda Yarım Takımı ilk golü mı?',
        143: ' Hangi Yarım Ev Ekibi Diğer gol atar?',
        144: ' Hangi Yarıda Deplasman Takımı daha gol atar?',
        145: ' 1 Yarıda Hedefleri Of Ev Sahibi Takım Sayısı',
        146: ' 1 Yarıda Hedefleri Of Deplasman Takım Sayısı',
        147: ' 2 Yarıda Hedefleri Of Deplasman Takım Sayısı',
        148: ' 2 Yarıda Hedefleri Of Ev Sahibi Takım Sayısı',
        149: ' Hedefleri Of Ev Sahibi Takım Sayısı',
        150: ' Hedefleri Of Deplasman Takım Sayısı',
        151: ' Çifte Şans 2. Yarı',
        153: ' Üstünde / 1 Dönem - Ev Ekibi',
        154: ' Üstünde / 2 Dönem - Ev Ekibi',
        155: ' Üstünde / 1 Dönem - Deplasman Takımı',
        156: ' Üstünde / 2 Dönem - Deplasman Takımı',
        157: ' / Aşırı Sarı Kartlar Altında - 1 Yarım',
        158: ' Üstünde / Sarı Kartlar',
        159: ' Bir Tie-Break In The Match Olacak?',
        160: ' Yarış kazanan',
        161: ' 10 Dakika Sonucu',
        162: ' 10 Dakika Üstünde /',
        163: ' Hedefler 2. Yarı sayısı',
        164: ' Bir Tie-Break In The First Set Olacak?',
        165: ' / Aşırı Setleri Altında',
        166: ' / Aşırı Games Altında',
        168: ' Kendi kalesine Olacak?',
        169: ' İki Yarıda da Atacak',
        170: ' Tek / Çift Köşeler',
        171: ' Win Ya Yarım To',
        175: ' Bir Tie-Break In İkinci Seti Olacak?',
        176: ' Bir Tie-Break In The Third Seti Olacak?',
        177: ' Bir Tie-Break In Dördüncü Seti Olacak?',
        178: ' Bir Tie-Break In Beşinci Seti Olacak?',
        180: ' Üstünde / Atma-Ins',
        181: ' Üstünde / Sarı Kartlar - Ev Ekibi',
        182: ' Üstünde / Sarı Kartlar - Ev Sahibi Takım 1 Yarısında',
        183: ' Üstünde / Sarı Kartlar - Ev Sahibi Takım 2. yarıda',
        184: ' / Aşırı Sarı Kartlar Altında - Deplasman Takımı',
        185: ' Deplasman Takım 1 Yarım - / Aşırı Sarı Kartlar Altında',
        186: ' Deplasman Takım 2. yarıda - / Aşırı Sarı Kartlar Altında',
        187: ' / Aşırı Sarı Kartlar Altında - 2. yarıda',
        188: ' İlk Sarı Kart',
        190: ' Ilk sarı kart 2. Yarı',
        191: ' Herhangi Player bir hat-trick olacak mı?',
        192: ' Bir Hedef İki Yarıda da atılır?',
        194: ' Takım Ana A Clean Sheet 1. Dönem Keep',
        195: ' Ev A takımı Temiz Kağıdı 2 Dönemi Keep',
        196: ' Deplasman Takım A Clean Sheet 1. Dönem tutun',
        197: ' Deplasman Takım A Clean Sheet 2 Dönemi tutun',
        198: ' Tek / Çift - Ev Sahibi Takım',
        199: ' Tek / Çift - Deplasman Takım',
        200: ' İlk Sayı takım kazanır?',
        201: ' Asya Handikap Oyunu',
        202: ' Dışarıda 1. Periyot Kazanan Ev /',
        203: ' Deplasman 2 Dönem Kazanan Ev /',
        204: ' Dışarıda 3 Dönem Kazanan Ev /',
        205: ' Deplasman 4 Dönem Kazanan Ev /',
        206: ' Dışarıda 5 Dönem Kazanan Ev /',
        207: ' 15 Dakika Sonucu',
        208: ' 30 Dakika Sonucu',
        209: ' Toplam Köşeler - 1. Yarı',
        210: ' Toplam Köşeler - 2. Yarı',
        211: ' İki Takım 2 Half Atacak',
        212: ' İki Takım İki Yarıda Gol Atacak',
        213: ' İki Yarıda da çizin',
        214: ' Üstünde / Kartları',
        215: ' Deplasman Takım 1 Yarım Atacak',
        216: ' Deplasman Takım 2 Half Atacak',
        217: ' Doğru Skor 3 Dönem',
        218: ' Ev Sahibi Takım 1 Yarım Atacak',
        219: ' Ev Sahibi Takım 2 Half Atacak',
        220: ' Deplasman Takım Dahil mesai - üzerinden / Altında',
        221: ' Ev Sahibi Takım Dahil mesai - / Üst Alt',
        222: ' Üstünde / 3 Dönemi - Deplasman Takımı',
        223: ' Üstünde / 3 Dönemi - Ev Ekibi',
        224: ' Çifte Şans 3. Periyodun',
        225: ' Çifte Şans 4 Dönemi',
        226: ' 12 dahil Fazla',
        227: ' Yarış Kazanan 2 Yerleri',
        228: ' Yarış Kazanan 3 Yerleri',
        229: ' Yarış Kazanan 4 Yerleri',
        233: ' Win Not To Nil için',
        235: ' 1 5 Innings Kazanan',
        236: ' 1 5 Innings Üstünde /',
        237: ' 1 Inning yılında Will Orada Be A Skor',
        238: ' Match Kalan',
        239: ' Hedefleri Menzil',
        242: ' 1. Periyot Tek / Çift - Ev Sahibi Takım',
        243: ' 1. Periyot Tek / Çift - Deplasman Takım',
        244: ' En yüksek Açılış Ortaklığı',
        245: ' Hedef / Gol Yok',
        247: ' 1 Yarım Kalan',
        250: ' Köşeler Handicap - 1. Yarı',
        254: ' Kartlar Aralığı',
        255: ' 1 Kart Zaman',
        256: ' Ev Sahibi Takım - Kartlar Menzil',
        257: ' Deplasman Takım - Kartlar Menzil',
        258: ' Köşeler Menzil',
        259: ' En Corners ile Takım',
        260: ' En 1st Half Corners ile Takım',
        261: ' En 2. Yarı Corners ile Takım',
        262: ' Ev Sahibi Takım - Köşeler Menzil',
        263: ' Deplasman Takım - Köşeler Menzil',
        264: ' Ev Sahibi Takım - 1. Yarı Köşeler Menzil',
        265: ' Deplasman Takım - 1. Yarı Köşeler Menzil',
        266: ' Ev Sahibi Takım - 2. Yarı Köşeler Menzil',
        267: ' Deplasman Takım - 2. Yarı Köşeler Menzil',
        269: ' Hedefleri Menzil 1. Periyot',
        271: ' Şans Mix',
        274: ' Şampiyonluk Bahisi',
        275: ' Hedefleri Aralığı 2 Dönem',
        278: ' Hedefleri Aralığı Ev Sahibi Takım',
        279: ' Hedefleri Ekibi Dışarıda Menzil',
        281: ' 1 5 Innings Asya Handikap',
        282: ' 1X2 İlk yarı',
        283: ' Asya Handikap 2. Yarı',
        284: ' 1X2 2. Yarı',
        285: ' Tek / Çift 2. Yarı',
        286: ' 12 2. Yarı',
        287: ' Üstünde / 4 Dönem - Ev Ekibi',
        288: ' Üstünde / 4 Dönem - Deplasman Takımı',
        289: ' 2 Dönem Tek / Çift - Ev Sahibi Takım',
        290: ' 3. Periyodun Tek / Çift - Ev Sahibi Takım',
        291: ' 4 Dönem Tek / Çift - Ev Sahibi Takım',
        292: ' 2 Dönem Tek / Çift - Deplasman Takım',
        293: ' 3. Periyodun Tek / Çift - Deplasman Takım',
        294: ' 4 Dönem Tek / Çift - Deplasman Takım',
        297: ' SP',
        299: ' nokta kazanan',
        300: ' Oyun kazanan',
        301: ' Hedef önce',
        302: ' Hedef sonra',
        304: ' En Fazla Gol Dönemi ile Takım',
        305: ' Köşeler - Altında / Tam / Üzeri',
        306: ' İlk Hedef Son Hedef',
        307: ' Avrupa Handikap Oyunu',
        308: ' Üstünde / En Fazla Gol Dönemi',
        309: ' Üstünde / Düşük Puanlama Dönemi',
        310: ' Seri kazanan',
        317: ' 1 Dönem - / Tam / Üst Alt',
        318: ' 2 Dönem - / Tam / Üst Alt',
        319: ' 3 Dönem - / Tam / Üst Alt',
        320: ' 4 Dönem - / Tam / Üst Alt',
        322: ' Altında / Tam / Üzeri',
        329: ' 1. Periyot Race To',
        330: ' 2 Dönem Race To',
        331: ' 3. Periyodun Race To',
        332: ' 4 Dönem Race To',
        333: ' 5 Dönemi Race To',
        337: ' Üzerinden / Altında - 2. yarıda',
        338: ' Sonraki Hedef 1. Periyot',
        339: ' Sonraki Hedef 2 Dönem',
        341: ' Yarışmak',
        342: ' Asya Handikap Dahil mesai',
        343: ' Oyun Aralığı 1. Periyot',
        344: ' Oyun Aralığı 2 Dönem',
        345: ' Oyun Aralığı 3 Dönem',
        346: ' Oyun Aralığı 4 Dönem',
        347: ' Oyun Aralığı 5 Dönem',
        348: ' 6 Dönemi Kazanan',
        349: ' 7 Dönemi Kazanan',
        350: ' 6 Dönemi Tek / Çift',
        351: ' 7 Dönemi Tek / Çift',
        352: ' / Aşırı 6 Dönemi Altında',
        353: ' / Aşırı 7 Dönemi Altında',
        354: ' Ev Ekibi - / Aşırı İlk yarı Altında',
        355: ' / Aşırı İlk yarı Altında - Deplasman Takımı',
        356: ' Görevden 6-Way Of Yöntemi',
        357: ' Görevden Yöntemi',
        366: ' Hatta Hiçbir Manzaralı Tek / çalıştırır - Ev Ekibi',
        367: ' Toplam Hatta Hiçbir Manzaralı Odd / çalıştırır - Deplasman Takımı',
        370: ' Toplam Hiçbir Manzaralı çalıştırır - Ev Ekibi',
        371: ' Hiçbir Manzaralı Toplam çalıştırır - Deplasman Takım',
        386: ' Avrupa Handikap Dahil mesai',
        387: ' Tek / Çift - Ev Sahibi Takım Dahil mesai',
        388: ' Tek / Çift - Deplasman Takım Dahil mesai',
        390: ' HT / FT Dahil mesai',
        391: ' Sonraki Hedef - Ekstra Zaman',
        392: ' Ev Sahibi Takım penaltı atışları',
        393: ' Deplasman Takım penaltı atışları',
        394: ' Skor 4 Dönem',
        395: ' Skor 5 Dönem',
        398: ' Tek / Çift Kartları',
        400: ' Tek / Çift Kartları 1 Yarım',
        401: ' Üstünde / Köşeler 1 Yarım - Ev Ekibi',
        402: ' / Aşırı Köşeler 1 Yarım Altında - Deplasman Takımı',
        403: ' Üstünde / Köşeler 2. yarıda',
        404: ' Üstünde / Köşeler 2. yarıda - Ev Ekibi',
        405: ' / Aşırı Köşeler 2. yarıda Altında - Deplasman Takımı',
        406: ' Tek / Çift Köşeler 1 Yarım',
        407: ' Asya Handikap Kartları',
        408: ' Asya Handikap Kartları 1 Yarım',
        409: ' 1X2 Köşeler',
        410: ' 1X2 Köşeler 1 Yarım',
        411: ' 12 Köşeler',
        414: ' Üstünde / Kartlar 1 Yarısında',
        415: ' 1X2 Ve İki Takım Atacak',
        416: ' Üzerinden / Altında - Ekstra Zaman',
        417: ' Ekstra Zaman 1 Yarım - üzerinden / Altında',
        419: ' 1X2 - Uzatma Süresi',
        427: ' 1X2 Ve Üstünde /',
        428: ' Çoklu Doğru Skor',
        430: ' Altında / Tam / Aşırı - 1 Yarım',
        431: ' Altında / Tam / Aşırı - 2. Yarısında',
        433: ' Avrupa Handikap Köşeler',
        434: ' 8 Dönemi Kazanan',
        435: ' 9 Dönemi Kazanan',
        436: ' / Aşırı 8 Dönemi Altında',
        437: ' / Aşırı 9. Dönem Altında',
        438: ' Toplam Hatta Hiçbir Manzaralı / Odd çalıştırır',
        439: ' Hiçbir Manzaralı Toplam Skor',
        445: ' Dışarıda 6 Dönem Kazanan Ev /',
        446: ' Dışarıda 7 Dönemi Kazanan Ev /',
        447: ' Asya Handikap 6 Dönem',
        448: ' Asya Handikap 7 Dönem',
        449: ' Asya Handikap 8 Dönem',
        450: ' Asya Handikap 9 Dönem',
        451: ' Dışarıda 8 Dönem Kazanan Ev /',
        452: ' Deplasman 9 Dönem Kazanan Ev /',
        453: ' İlk Skor Yöntemi',
        454: ' İlk Yöntem Skor - Ev Ekibi',
        455: ' İlk Skor Yöntemi - Deplasman Takım',
        456: ' Çifte Şans 1. Periyot',
        457: ' Çifte Şans 2 Dönem',
        459: ' Ev Sahibi Takım Her Çeyrekte Sayı Yapacak',
        460: ' Deplasman Takım Her Çeyrekte Sayı Yapacak',
        462: ' İlk 5 Kazanan',
        463: ' En iyi 10 Kazanan',
        464: ' 12 2. Yarı Dahil mesai',
        465: ' 4 Dönem Kazanan Ev / Deplasman dahil mesai',
        466: ' / Aşırı 4 Dönem Dahil mesai Altında',
        467: ' Asya Handikap 4 Dönem Dahil mesai',
        468: ' Asya Handikap 2. Yarı Dahil mesai',
        469: ' / Aşırı 2. Yarı Dahil mesai Altında',
        472: ' Çatışmada Will Oyun Git?',
        475: ' Üzerinden / Altında aşkın çalıştırır - Ev Sahibi Takım 1 Inning',
        476: ' Deplasman Takım 1. vuruş - üzerinden / Altında In Üzerinde çalıştırır',
        477: ' Üzerinden / Altında aşkın çalıştırır - Ev Sahibi Takım 2 Inning',
        478: ' Deplasman Takım 2. vuruş - üzerinden / Altında In Üzerinde çalıştırır',
        479: ' Tek / Çift In Üzerinde çalıştırır - Ev Sahibi Takım 1 Inning',
        480: ' Tek / Çift In Üzerinde çalıştırır - Deplasman Takım 1. vuruş',
        481: ' Tek / Çift In Üzerinde çalıştırır - Ev Sahibi Takım 2 Inning',
        482: ' Tek / Çift In Üzerinde çalıştırır - Deplasman Takım 2. vuruş',
        483: ' Ev Sahibi Takım Hiçbir Manzaralı Bir Wicket Alacaklarını - 1. vuruş?',
        484: ' Deplasman Takım al bir Wicket Hiçbir Manzaralı Will - 1 İnning?',
        485: ' Ev Sahibi Takım Hiçbir Manzaralı Bir Wicket Alacaklarını - 2. vuruş?',
        486: ' 2. İnning - Deplasman Takım al bir Wicket Hiçbir Manzaralı edecek mi?',
        523: ' Üzerinden / Ve İki Takım için Puanına Altında',
        524: ' 1 7 Innings Kazanan',
        525: ' 1 7 Innings Üstünde /',
        526: ' 1 7 Innings Asya Handikap',
        529: ' Tek / Çift 2. Yarı Dahil mesai',
        553: ' / Aşırı çatışmada cezalar Alınan Altında',
        554: ' 12 çatışmada',
        555: ' Asya Handikap çatışmada',
        556: ' Doğru Skor çatışmada',
        557: ' Üstünde / Çatışmada',
        558: ' Ev Ekibi - / Aşırı atışları Altında',
        559: ' / Aşırı atışları Altında - Deplasman Takımı',
        560: ' Takım son çatışmada Cezası Take için',
        563: ' Yarış için dahil mesai',
        564: ' 1X2 Ve Üstünde / 5 yolu',
        566: ' Dışarıda 10 Dönem Kazanan Ev /',
        567: ' Dışarıda 11. Dönem Kazanan Ev /',
        568: ' Dışarıda 12. Dönem Kazanan Ev /',
        569: ' Dışarıda 13. Dönem Kazanan Ev /',
        570: ' Dışarıda 14 Dönem Kazanan Ev /',
        571: ' Dışarıda 15 Dönem Kazanan Ev /',
        573: ' Dışarıda 16 Dönem Kazanan Ev /',
        574: ' Dışarıda 17 Dönem Kazanan Ev /',
        575: ' Dışarıda 18 Dönem Kazanan Ev /',
        576: ' Dışarıda 19 Dönem Kazanan Ev /',
        577: ' 10 Dakika Üstünde / - Köşeler',
        578: ' Üzerinden / Altında 10 Dakika - Kartlar',
        579: ' Sonraki Köşe',
        586: ' To Bitiş yılında Top Yarım',
        587: ' Alt Yarısı içinde bitirmek için',
        588: ' To Bitiş Dip',
        589: ' Değil için Finish yılında Top 4',
        590: ' Küme Düşecek',
        591: ' Küme Düşecek Değil',
        592: ' To Reach Final',
        593: ' Bitiş yılında Bottom 6 için',
        594: ' Terfi edilecek',
        595: ' İlk Hedef Zaman',
        596: ' Hedefleri Aralığı Ev Sahibi Takım 1 Dönem',
        597: ' Hedefleri Aralığı Ev Sahibi Takım 2 Dönem',
        598: ' Hedefler Takım 1 Devir Böyle Menzil',
        599: ' Hedefler Takım 2 Devir Böyle Menzil',
        600: ' 1. Periyot Kazanan Ve İki Takım için Skor',
        601: ' HT / FT Çifte Şans',
        602: ' Doğru Skor Dahil mesai',
        603: ' Negatif HT / FT',
        604: ' Hedefleri Aralığı Ev Sahibi Takım Hem Süreleri',
        605: ' Hedefleri Her iki Süreleri takım Dışarıda Range',
        606: ' Kazanan Ve Hedefleri Tipi Tek',
        607: ' Çifte Şans Ve Hedefler',
        608: ' Kazanan Ve Hedefler Tip İki',
        609: ' Takımlar için Skor Ve Hedefleri Hem',
        610: ' Çifte Şans Mix',
        628: ' To Bitiş yılında Top 7',
        629: ' To Bitiş yılında Top 6',
        630: ' To Bitiş yılında Top 3',
        631: ' To Bitiş yılında Top 2',
        632: ' To Bitiş yılında Top 4',
        633: ' To Finish Top 5',
        634: ' Tek / Çift 1. Dönem yuvarlar',
        635: ' Tek / Çift 2 Dönemi yuvarlar',
        636: ' Tek / Çift 3 Dönemi yuvarlar',
        637: ' As Olacak?',
        638: ' Bir Bıçak öldür Olacak mı?',
        639: ' 1. Tur Kazanan',
        640: ' 16 Yuvarlak Kazanan',
        641: ' Bir bıçak öldürmek olacak mı? - 1. Dönem',
        642: ' Bir bıçak öldürmek olacak mı? - 2. Dönem',
        643: ' Bir bıçak öldürmek olacak mı? - 3. Dönem',
        644: ' As Olacak? - 1. Dönem',
        645: ' As Olacak? - 2. Dönem',
        646: ' As Olacak? - 3. Dönem',
        647: ' Fazla mesai Olacak mı? - 1. Dönem',
        648: ' Fazla mesai Olacak mı? - 2. Dönem',
        649: ' Fazla mesai Olacak mı? - 3. Dönem',
        650: ' 16 Yuvarlak Kazanan - 1. Periyot',
        651: ' 16 Yuvarlak Kazanan - 2. Dönem',
        652: ' 16 Yuvarlak Kazanan - 3 Dönem',
        653: ' 1. Tur Kazanan - 1. Periyot',
        654: ' 1. Tur Kazanan - 2. Dönem',
        655: ' 1. Tur Kazanan - 3 Dönem',
        660: ' Oyun Süresi',
        662: ' Tek / Çift yuvarlar',
        663: ' 1. Periyot Süresi',
        664: ' 2 Dönem Süre',
        665: ' 3 Dönem Süre',
        666: ' 1. Periyot - 1 Roshan',
        667: ' 2 Dönem - 1 Roshan',
        668: ' 3 Dönem - 1 Roshan',
        669: ' 1. Periyot - 1 Kan',
        670: ' 2 Dönem - 1 Kan',
        671: ' 3 Dönem - 1 Kan',
        672: ' 1. Periyot - 1 tou',
        673: ' 2 Dönem - 1 tou',
        674: ' 3 Dönem - 1 tou',
        675: ' 1. Periyot - 1 Kışlası',
        677: ' 2 Dönem - 1 Kışlası',
        678: ' 3 Dönem - 1 Kışlası',
        679: ' 1. Periyot - 1 taret',
        680: ' 2 Dönem - 1 taret',
        681: ' 3 Dönem - 1 taret',
        682: " Kills'de Race To - 1. Periyot",
        683: " Kills'de Race To - 2. Dönem",
        684: " Kills'de Race To - 3 Dönem",
        685: " 1. Dönem - Hem Takımlar bir ejderha öldürmek için",
        686: ' 2 Dönemi - Hem Takımlar bir ejderha öldürmek için',
        687: ' 3 Dönemi - Hem Takımlar bir ejderha öldürmek için',
        688: ' İki Takım bir İnhibitörü öldürmeye - 1. Dönem',
        689: ' İki Takım bir İnhibitörü öldürmeye - 2. Dönemi',
        690: ' İki Takım bir İnhibitörü öldürmeye - 3 Dönemi',
        691: ' Tek / Çift Öldü',
        692: ' Tek / Çift öldürür - 1. Dönem',
        693: ' Tek / Çift öldürür - 2. Dönemi',
        694: ' Tek / Çift öldürür - 3 Dönemi',
        695: ' HT / FT Ve Hedefler',
        697: ' To Bitiş yılında Top 8',
        711: ' Oyuncu için Herzaman olarak Skoru',
        712: ' İlk Oyuncu için Skor',
        713: ' Son Oyuncu için Skor',
        714: ' Oyuncu için 2 veya daha fazla gol',
        715: ' Oyuncu için 3 veya daha fazla gol',
        727: ' 1. Periyot İki Takım Atacak',
        728: ' 2 Dönem İki Takım Atacak',
        729: ' 3 Dönem İki Takım Atacak',
        730: ' 4 Dönem İki Takım Atacak',
        731: ' 1. Periyot Ev Sahibi Takım için Skor',
        732: ' 2 Dönem Ev Sahibi Takım için Skor',
        733: ' 3. Periyodun Ev Sahibi Takım için Skor',
        734: ' 4 Dönemi Ev Sahibi Takım için Skor',
        735: ' 1. Periyot Deplasman Takım için Skor',
        736: ' 2 Dönem Deplasman Takım için Skor',
        737: ' 3 Dönem Deplasman Takım için Skor',
        738: ' 4 Dönem Deplasman Takım için Skor',
        751: ' To 1 Yarım yarışı',
        752: ' 2. Yarı Race To - Dahil mesai',
        754: ' Oyun için Deuce',
        755: ' Ekstra Zaman Köşeler - Altında / Tam / Üzeri',
        756: ' Altında / Aşırı ekstra zaman Corners',
        757: ' Asya Handikap - Ekstra Zaman',
        758: ' 10 Dakika Üstünde / - Serbest Vuruş',
        759: ' 10 Dakika Üstünde / - Atma-ins',
        760: ' 10 Dakika Üstünde / - Hedef Başladı',
        762: ' The Win The Toss için',
        763: ' Üstünde / In Match çalıştırır',
        765: ' En Fours Atacak',
        766: ' Üstünde / - Maç Fours',
        767: ' Üstünde / - Maç Sixes',
        768: ' Bir Elli In The Match Attı Be',
        769: ' Yüz Maçta Edilecek',
        770: ' En Run Çıkışları',
        791: ' En Batsman - Ev Sahibi Takım',
        792: ' En Batsman - Deplasman Takım',
        793: ' En Bowler - Ev Sahibi Takım',
        794: ' En Bowler - Deplasman Takım',
        795: ' Maçın adamı',
        806: ' Vurucu için maçta Elli Skoru',
        808: ' 10 çalıştırır Race To',
        809: ' En yüksek 1 6 Overs Skoru',
        813: ' Oyuncu için En altılar Skoru',
        814: ' En yüksek Bireysel Skor',
        819: ' To Have A Break Point In Oyunu',
        820: ' To Corners yarış',
        822: ' En Yeni Takım',
        824: ' Oyuncu için rezervasyon Be',
        825: ' Oyuncu gönderilecekti',
        834: ' Ekstra Puan Seti',
        835: ' Asya Alt / Üst',
        836: ' Asya Üstünde / 1. Periyot',
        837: ' 1 Yarım takımlarının kazanmasını Nil',
        838: ' 1 Yarım Deplasman Takım Win için Nil',
        839: ' 1 Yarım 1X2 Ve Üstünde /',
        840: ' 1. Periyot takımlarının kazanmasını Nil',
        841: ' 1. Periyot Deplasman Takım Win için Nil',
        842: ' Altında / Tam üzerinden / - Ev Ekibi',
        843: ' Deplasman Takımı - / Tam / Üst Alt',
        844: ' İlk Takım için Skor - Ev / Deplasman',
        845: ' Takım için Çağrı İlk Zaman Aşımı',
        846: ' / Aşırı Hit Altında - Dahil mesai',
        849: ' Üstünde / 5 Inning',
        851: ' En Fazla Gol Yarısı ile Takım',
        852: ' Ya Takım 3 Cevapsız Times Atacak',
        853: ' 12 Ve Üstünde /',
        854: ' Özel Takım veya Savunma Touchdown attı',
        855: ' Menzil Noktaları',
        859: ' 147 Break In Maç',
        861: ' İlk Yöntem 3 Way Skoru - Ev Ekibi',
        862: ' İlk Skor Yöntem 3 Yollu - Deplasman Takım',
        863: ' En Fazla Gol Dönem -1X2 ile Takım',
        864: ' Takım İlk Golü Atan',
        865: ' Takım Son Golcü',
        866: ' Asya Handikap Setleri',
        870: ' İlk Dışarıda / Yöntem 6 Way Home Skoru',
        878: ' Hedefleri Aralığı 3 Dönem',
        879: ' / Aşırı Denemeler Altında',
        880: ' İlk Yarı Köşeler - Altında / Tam / Üzeri',
        881: ' İlk Hedef Yöntemi',
        882: ' Puan sayısı',
        891: ' En uzun touchdown için takım',
        892: ' Takım uzun alan gol',
        893: ' En uzun Touchdown Attı',
        894: ' En Kısa Touchdown Attı',
        895: ' En uzun alan Hedef attı',
        896: ' İlk Takım Golün Dakikası',
        897: ' 2 Points sonra Oyun Skor',
        898: ' 3. Sayıdan Sonraki Oyun Skor',
        899: ' 4 Points sonra Oyun Skor',
        900: ' Oyun için Puan',
        901: ' 1. Periyot Kurşun sonra',
        902: ' 2 Dönem Kurşun sonra',
        903: ' 3. Periyodun Kurşun sonra',
        904: ' 4 Dönemi Kurşun sonra',
        905: ' 5 Dönemi Kurşun sonra',
        906: ' 4 Games sonra Skoru',
        907: ' 6 Oyunlardan sonra Skoru',
        908: ' Break In Set Serve',
        909: ' Düz Setlerinde Ev için kazanın',
        910: ' Dışarıda Kazanç düz setlerle',
        911: ' 2 Setleri sonra Skoru',
        912: ' 3 Setleri sonra Skoru',
        914: ' / Aşırı Alan Hedefleri attı Altında - 1 Yarım',
        915: ' / Aşırı Goller attı Altında - 1 Yarım',
        916: ' / Aşırı Ev Sahibi Takım touchdown Altında - 1 Yarım',
        917: ' 1 Yarım - üzerinden / Deplasman Takım Goller Altında',
        920: " / Aşırı touchdown'lar Altında",
        921: ' Altında / Tam / Üzeri - Goller',
        922: ' / Aşırı Alan Hedefleri Altında',
        923: ' Altında / Tam / Aşırı - Saha Hedefleri',
        924: ' İlk Alan Golün Dakikası attı',
        925: ' İlk Touchdown Time Of attı',
        926: ' İlk Score Zamanı',
        927: ' Ev Sahibi Takım Üstünde / Goller',
        928: ' Deplasman Takım Üstünde / Goller',
        929: ' Ev Sahibi Takım Üstünde / Alan Hedefleri',
        930: ' Deplasman Takım Üstünde / Alan Hedefleri',
        932: ' Var İlk Koçlar Challenge Takımı',
        933: ' Takım İlk Kabul Cezası Commit',
        934: ' Emniyet Attı',
        935: ' Avrupa Kartları Handikap',
        936: ' Asya Alt / Üst Kartları',
        938: ' Ev Ekibi - / Aşırı Kartları Altında',
        939: ' / Aşırı Kartları Altında - Deplasman Takımı',
        940: ' İlk Kart Of Zaman',
        941: ' Sonraki Oyuncu için Skor',
        942: ' En Milliyet - Asya',
        943: ' En Milliyet - Avustralya',
        944: ' En Milliyet - Avrupa',
        945: ' En Milliyet - Büyük Britanya ve İrlanda',
        946: ' En Milliyet - dünyanın geri kalanı',
        947: ' Üstünde / Aslar',
        948: ' / Aşırı Aslar Altında - Ev Ekibi',
        949: ' Aslar kesin sayısı',
        950: ' / Aşırı Aslar Altında - Deplasman Takımı',
        951: ' Aslar Of Doğru Sayısı',
        955: ' 1X2 Aslar',
        956: ' Aslar Of Sayısı Exact - Ev Ekibi',
        957: ' Aslar tam sayısı - Deplasman Takım',
        958: ' Aces Asya Handikap',
        959: ' Yarış için Aslar',
        960: ' / Aşırı Aslar Altında - 1 Dönem',
        976: ' 1X2 As - 1. Periyot',
        982: ' As Asya Handikap - 1. Periyot',
        986: ' 12 Aces',
        989: " / Aşırı Kills'de Altında - 1 Dönem",
        990: " / Aşırı Kills'de Altında - 2 Dönem",
        991: " / Aşırı Kills'de Altında - 3 Dönem",
        992: ' Sonraki Touchdown Skorer - Ev Sahibi Takım',
        993: ' Sonraki Touchdown Skorer - Deplasman Takım',
        994: ' Sonraki Touchdown golcüyü',
        995: ' Oyuncu için Skor Touchdown In Herzaman',
        996: ' Oyuncu için 2 Veya Daha Fazla gol atmak',
        997: ' Oyuncu için 3 Veya Daha Fazla gol atmak',
        998: ' İlk Karşılaşma Köşe - 12',
        999: ' Son Maç Köşe - 12',
        1000: ' Setleri Sayısı',
        1001: ' / Aşırı Çift Hataları Altında',
        1002: ' / Aşırı Çift Hataları Altında - Deplasman Takımı',
        1003: ' / Aşırı Çift Hataları Altında - Ev Ekibi',
        1004: ' / Aşırı Çift Hataları Altında - 1 Dönem',
        1010: ' 12 Çift Faylar',
        1011: ' 12 Çift Arızaları - 1. Periyot',
        1013: ' 1X2 Çift Arızaları',
        1016: ' Çift Hataları tam sayısı',
        1019: ' Çift Hataları tam sayısı - Ev Sahibi Takım',
        1020: ' Çift Hataları tam sayısı - Deplasman Takım',
        1027: ' Çift Hataları Of Doğru Sayısı',
        1030: ' İlk Corner Of Zaman',
        1031: ' Birden Köşeler',
        1034: ' Dışarıda 20 Dönem Kazanan Ev /',
        1035: ' Dışarıda 21. Dönem Kazanan Ev /',
        1036: ' Dışarıda 22. Dönem Kazanan Ev /',
        1037: ' Dışarıda 23. Dönem Kazanan Ev /',
        1038: ' Dışarıda 24 Dönem Kazanan Ev /',
        1039: ' Dışarıda 25 Dönem Kazanan Ev /',
        1040: ' Dışarıda 26 Dönem Kazanan Ev /',
        1041: ' Dışarıda 27 Dönem Kazanan Ev /',
        1042: ' Dışarıda 28 Dönem Kazanan Ev /',
        1043: ' Dışarıda 29 Dönem Kazanan Ev /',
        1044: ' Dışarıda 30 Dönem Kazanan Ev /',
        1046: ' Dışarıda 32. Dönem Kazanan Ev /',
        1047: ' Dışarıda 33. Dönem Kazanan Ev /',
        1050: ' 20 puan için yarış',
        1051: ' Üstünde / 2. yarıda - Ev Ekibi',
        1052: ' / Aşırı 2. yarıda Altında - Deplasman Takımı',
        1053: ' Asya Üstünde / 2 Dönem',
        1054: ' Asya Üstünde / 3 Dönemi',
        1055: ' Asya Üstünde / 4 Dönem',
        1061: ' Asya Alt / Üst Dahil mesai',
        1062: ' En Fazla Gol Yarım - Ev Sahibi Takım',
        1063: ' En Fazla Gol Yarım - Deplasman Takım',
        1065: ' Ev Sahibi Takım - İlk Golü Atan',
        1066: ' Ev Sahibi Takım - Son Golcü',
        1067: ' Deplasman Takım - İlk Golü Atan',
        1068: ' Deplasman Takım - Son Golcü',
        1069: ' / Aşırı Oyuncu Noktalar Altında',
        1070: ' / Aşırı Oyuncu Blokları Altında',
        1071: ' / Aşırı Oyuncu Destekler Altında',
        1072: ' Üstünde / Oyuncu ribaund',
        1073: ' Üstünde / Oyuncu çaldı',
        1074: ' Üstünde / Oyuncu cirolar',
        1075: ' Yapılan / Aşırı Oyuncu 3 Points Altında',
        1076: ' / Aşırı Player Altında 3 Puan girişimi',
        1077: ' Yapılan / Aşırı Oyuncu 2 Noktalar Altında',
        1078: ' / Aşırı Player Altında 2 Puan girişimi',
        1079: ' Üstünde / Oyuncu Ücretsiz Üretilmiş Atar',
        1080: ' / Aşırı Oyuncu Free Altında girişimi Atar',
        1081: ' 1X2 Sarı Kartlar',
        1082: ' Çifte Şans Sarı Kartlar',
        1083: ' Asya Handikap Sarı Kartlar',
        1084: ' Doğru Skor Sarı Kartlar',
        1085: ' Üstünde / Kırmızı Kartlar',
        1086: ' Altında / Tam / Üzeri Rezervasyon Noktaları',
        1087: ' Altında / Tam / Üzeri Rezervasyon Noktaları - Ev Sahibi Takım',
        1088: ' Altında / Tam / Üzeri Rezervasyon Noktaları - Deplasman Takım',
        1089: ' / Aşırı Oyuncu çalar ve Bloklar Altında',
        1090: ' / Aşırı Oyuncu Noktalar Altında',
        1091: ' / Aşırı Oyuncu Destekler Ve ribaund Altında',
        1092: ' / Aşırı Oyuncu Noktalar Ve ribaund Altında',
        1093: ' / Aşırı Oyuncu Noktalar Ve Destekler Altında',
        1094: ' Oyuncu Çift Kişilik',
        1095: ' Oyuncu Üçlü Çift',
        1096: ' Altında / Tam / Üzeri Rezervasyon Noktaları - 1. Periyot',
        1100: ' Seti kazanan',
        1101: ' Üzerinden / 180 \ Altında Bir Set s',
        1102: ' Set / Bacak Kazanan',
        1103: " Set / Bacak Toplam Check-out'lar Üstünde /",
        1105: ' / Aşırı Toplam Altında 180 \ s - Ev Sahibi Takım',
        1106: ' / Üzeri Toplam 180 \ s Altında - Deplasman Takımı',
        1112: ' / Aşırı Oyuncu Gol',
        1115: ' / Aşırı Oyuncu Gol İzin',
        1120: ' / Aşırı yuvarlar Altında - 1 Dönem',
        1121: ' / Aşırı yuvarlar Altında - 2 Dönem',
        1122: ' / Aşırı yuvarlar Altında - 3 Dönem',
        1123: ' 4 Dönemi - 1. Kan',
        1124: ' 5 Dönemi - 1. Kan',
        1125: ' İki Takım bir ejderha için öldür',
        1126: ' İki Takım Baron için öldür',
        1127: ' İki Takım bir İnhibitör için öldür',
        1128: ' Üstünde / Barons Öldürülmüş',
        1129: ' / Aşırı Barons Öldürülmüş Altında - 1 Dönem',
        1130: ' / Aşırı Barons Öldürülmüş Altında - 2 Dönem',
        1131: ' / Aşırı Barons Öldürülmüş Altında - 3 Dönem',
        1132: ' Üstünde / Dragons Öldürülmüş',
        1133: ' / Aşırı Dragons Öldürülmüş Altında - 1 Dönem',
        1134: ' / Aşırı Dragons Öldürülmüş Altında - 2 Dönem',
        1135: ' / Aşırı Dragons Öldürülmüş Altında - 3 Dönem',
        1136: ' Çok / az 180 \ s',
        1137: ' İlk 180',
        1138: ' Son 180',
        1139: ' Üstünde / En Yüksek Ödeme Puanı',
        1140: ' En 180 \ s',
        1141: ' Üstünde / En Yüksek Ödeme Score - Ev Ekibi',
        1142: ' Üstünde / En Yüksek Ödeme Score - Deplasman Takımı',
        1143: ' İlk Oyuncu için Skor Touchdown',
        1144: ' Son Oyuncu için Skor Touchdown',
        1145: ' İlk Oyuncu için touchdown - Ev Ekibi',
        1146: ' İlk Oyuncu için touchdown - Deplasman Takımı',
        1147: " / Aşırı Kills'de Altında - 4 Dönem",
        1148: " / Aşırı Kills'de Altında - 5 Dönem",
        1149: ' Asya Handikap Kills - 1. Periyot',
        1150: ' Asya Handikap Kills - 2 Dönem',
        1151: ' Asya Handikap Kills - 3 Dönem',
        1152: ' Asya Handikap Kills - 4 Dönem',
        1153: ' Asya Handikap Kills - 5 Dönem',
        1154: ' Altında / Tam / boyunca 180 \ s',
        1155: ' Altında / Tam / Üzeri Toplam 180 \ s - Ev Sahibi Takım',
        1156: ' Altında / Tam / Üzeri Toplam 180 \ s - Deplasman Takımı',
        1157: " En Check-out'lar",
        1158: ' İlk deneyin golcüyü',
        1159: ' Son deneyin golcüyü',
        1160: ' Herzaman golcüyü deneyin',
        1162: ' Tahrip / Aşırı tous Altında - 1 Dönem',
        1163: ' Tahrip / Aşırı tous Altında - 2 Dönem',
        1164: ' Tahrip / Aşırı tous Altında - 3 Dönem',
        1165: ' 1. Periyot - 1 Baron',
        1166: ' 2 Dönem - 1 Baron',
        1167: ' 3 Dönem - 1 Baron',
        1168: ' 4 Dönem - 1 Baron',
        1169: ' 5 Dönemi - 1. Baron',
        1170: ' 1. Periyot - 1 Ejderha',
        1171: ' 2 Dönem - 1 Ejderha',
        1172: ' 3 Dönem - 1 Ejderha',
        1173: ' 4 Dönem - 1 Ejderha',
        1174: ' 5 Dönemi - 1. Ejderha',
        1175: ' Çoğu Kills - 1. Periyot',
        1176: ' Çoğu Kills - 2 Dönem',
        1177: ' Çoğu Kills - 3 Dönem',
        1178: ' Çoğu Kills - 4 Dönem',
        1179: ' Çoğu Kills - 5 Dönem',
        1180: ' Çoğu Kills - 6 Dönem',
        1181: ' Çoğu Kills - 7 Dönem',
        1182: ' Oyuncu için 2 Veya Daha trys Skoru',
        1183: ' Oyuncu için 3 Veya Daha trys Skoru',
        1184: ' Değil için The Win The Leauge',
        1185: ' Altında / Tam / Üzeri dahil mesai - Ev Ekibi',
        1186: ' Altında / Tam / Üzeri dahil mesai - Deplasman Takımı',
        1187: ' Altında / Tam / Üzeri Dahil mesai',
        1188: ' / Aşırı Değişiklikler Altında',
        1189: ' / Aşırı Değişiklikler Altında - 1 Dönem',
        1190: ' Son Oyuncu için touchdown - Ev Ekibi',
        1191: ' Son Oyuncu için touchdown - Deplasman Takımı',
        1192: ' İlk Oyuncu için bir As Hit',
        1193: ' Üstünde / Oyuncu kurucu Yards Geçme',
        1194: ' / Aşırı Oyuncu Pas touchdown Altında',
        1195: ' Üstünde / En uzun Oyuncu Geçiş Tamamlanma',
        1196: ' Üstünde / Oyuncu Yards Alma',
        1197: ' Üstünde / Oyuncu müdahaleleri',
        1198: ' Üstünde / Oyuncu Puanı tekme',
        1199: ' / Aşırı Oyuncu Yards Rushing ve Alma Altında',
        1200: ' Üstünde / Oyuncu Yards Rushing',
        1201: ' / Aşırı Oyuncu En uzun Resepsiyon Altında',
        1202: ' Üstünde / Oyuncu Receptions',
        1203: ' Yapılan / Aşırı Oyuncu Saha gol altı',
        1204: ' Denenen / Aşırı Oyuncu Saha gol altı',
        1205: ' / Aşırı Oyuncu kurucu Pas touchdown Altında',
        1206: ' / Aşırı Oyuncu Alıcı Resepsiyon Tamamlama Altında',
        1207: ' / Aşırı Oyuncu Quarterback Altında Tamamlanan Pasoları',
        1208: ' Üstünde / Oyuncu touchdown Rushing',
        1209: ' / Aşırı Oyuncu Alıcı Resepsiyon Denemeleri Altında',
        1210: ' / Aşırı Oyuncu Quarterback Altında girişimi Pasoları',
        1211: ' 1X2 Fauller',
        1212: ' Çifte Şans Fauller',
        1213: ' / Aşırı Fauller Altında - 1 Dönem',
        1214: ' / Aşırı Fauller Altında - 2 Dönem',
        1215: ' Asya Handikap Fauller',
        1216: ' Ev Ekibi - / Aşırı Fauller Altında',
        1217: ' / Aşırı Fauller Altında - Deplasman Takımı',
        1218: ' / Aşırı Oyuncu Rush Denemeleri Altında',
        1219: ' / Aşırı Oyuncu Alma touchdown Altında',
        1222: ' Ev Ekibi - / Aşırı Atma-Ins Altında',
        1223: ' / Aşırı Atma-Ins Altında - Deplasman Takımı',
        1224: ' 1X2 Taç-In',
        1225: ' Çifte Şans Atma-Ins',
        1226: ' / Aşırı Atma-Ins Altında - 1 Dönem',
        1227: ' Bir defalık ins Handikap',
        1228: ' Handikap taç atışı - 1. Dönem',
        1229: ' Altında / Hedef üzerinde Üzeri Atışlar - Ev Ekibi',
        1230: ' Altında / Hedef üzerinde Üzeri Atışlar - Deplasman Takımı',
        1231: ' Hedef Asya Handikap Atışlar',
        1232: ' On Target Asya Handikap Atışlar - 1. Periyot',
        1233: ' Altında / On Target Üzeri Şut - 1 Dönem',
        1234: ' Hedef üzerinde 1X2 Atışlar',
        1235: ' Target çift Şans Atışlar',
        1236: ' / Üstü Toplam Shots Altında - Ev Ekibi',
        1237: ' / Üstü Toplam Shots Altında - Deplasman Takımı',
        1238: ' Çifte Şans Toplam Atışlar',
        1239: ' 1X2 Toplam Atışlar',
        1240: ' Asya Handikap Toplam Atışlar',
        1241: ' Asya Handikap Toplam Atışlar - 1. Periyot',
        1242: ' / Üstü Toplam Shots Altında - 1 Dönem',
        1243: ' Çifte Şans Köşeler',
        1244: ' Çifte Şans Köşeler - 1. Periyot',
        1245: ' Pitch Of tamamı 4 Corners itibaren Köşeler',
        1246: ' Çifte Şans Sarı Kartlar - 1. Periyot',
        1247: ' 1X2 Sarı Kartlar - 1. Periyot',
        1248: ' Asya Handikap Sarı Kartlar - 1. Periyot',
        1249: ' Çifte Şans & Alt / Üst',
        1250: ' Çifte Şans & İki Takım için Skor',
        1251: ' 1. Periyot - 1 Önleyici',
        1252: ' 2 Dönem - 1 Önleyici',
        1253: ' 3 Dönem - 1 Önleyici',
        1254: ' 4 Dönemi - 1. Önleyici',
        1255: ' 5 Dönemi - 1. Önleyici',
        1256: ' İki Takım için öldür Baron - 1. Periyot',
        1257: ' İki Takım için öldür Baron - 2. Dönem',
        1258: ' İki Takım için öldür Baron - 3 Dönem',
        1259: ' İki Takım için öldür Baron - 4 Dönem',
        1260: ' İki Takım için öldür Baron - 5 Dönem',
        1261: ' 4 Dönemi - Hem Takımlar bir ejderha öldürmek için',
        1262: ' 5 Dönemi - Hem Takımlar bir ejderha öldürmek için',
        1263: ' İki Takım bir İnhibitörü öldürmeye - 4 Dönemi',
        1264: ' İki Takım bir İnhibitörü öldürmeye - 5 Dönemi',
        1265: ' As Sonraki Oyun Olacak?',
        1266: ' Çift Hata Sonraki Oyun Olacak?',
        1267: ' Tek / Çift Fauller',
        1268: ' Asya Handikap Topu bulundurmak',
        1269: ' Çifte Şans Ofsayt',
        1270: ' Asya Handikap Ofsayt',
        1271: ' Ev Ekibi - / Aşırı Ofsayt Altında',
        1272: ' / Aşırı Ofsayt Altında - Deplasman Takımı',
        1273: ' 1X2 Ofsayt',
        1274: ' Üstünde / Öldürür 1 Dönem - Ev Ekibi',
        1275: ' Üstünde / Öldürür 2 Dönem - Ev Ekibi',
        1276: ' Üstünde / Öldürür 3 Dönemi - Ev Ekibi',
        1277: ' / Aşırı Öldürür 1 Dönem Altında - Deplasman Takımı',
        1278: ' / Aşırı Öldürür 2 Dönem Altında - Deplasman Takımı',
        1279: ' / Aşırı Öldürür 3 Dönem Altında - Deplasman Takımı',
        1280: ' Üstünde / Wides',
        1281: ' / Aşırı Ducks Altında',
        1282: ' / Aşırı kaleyle Altında',
        1283: ' Üstünde / Maç Fours - Ev Ekibi',
        1284: ' Üstünde / Maç Sixes - Ev Ekibi',
        1285: ' / Aşırı Maç Fours Altında - Deplasman Takımı',
        1286: ' / Aşırı Maç Sixes Altında - Deplasman Takımı',
        1287: ' Üstünde / Ekstra',
        1288: ' 1 Wicket Of Fall - Ev Ekibi',
        1289: ' 1 Wicket Of Fall - Deplasman Takımı',
        1290: ' En Batsman Takımı',
        1291: ' / Aşırı Maç Sixes Ev Ekip altında - 1 Inning',
        1292: ' / Aşırı Maç Sixes Dışarıda Ekip altında - 1 Inning',
        1293: ' / Aşırı Maç Fours Ev Ekip altında - 1 Inning',
        1294: ' Dışarıda / Aşırı Maç Fours Ekip altında - 1 Inning',
        1295: ' Üstünde / Üst Batsman',
        1296: ' 1 Dismissal Üzeri 1. vuruş - Ev Ekibi',
        1297: ' 1. İnning 2 Üzeri Görevden - Ev Sahibi Takım',
        1298: ' 1. İnning 3 Üzeri Görevden - Ev Sahibi Takım',
        1299: ' 1. İnning 2 Üzeri Görevden - Deplasman Takım',
        1300: ' 1. İnning 3 Üzeri Görevden - Deplasman Takım',
        1301: ' 1. İnning 1 Üzeri Görevden - Deplasman Takım',
        1302: ' En Fazla Gol Üzeri - Alt / Üst',
        1303: ' 1. İnning 2 Üzeri Üstünde / - Ev Sahibi Takım',
        1304: ' 1. İnning 3 Üzeri Üstünde / - Ev Sahibi Takım',
        1306: ' 1. İnning 3 Üzeri Üstünde / - Deplasman Takım',
        1307: ' 1. İnning 2 Üzeri Üstünde / - Deplasman Takım',
        1309: ' Kazananla - Takımlar',
        1310: ' Kazanma Marjı - Takımlar Dahil mesai',
        1312: ' Tam Kazananla',
        1314: ' Marj Aralıkları Kazanma',
        1315: ' Tam Kazananla - Takımlar',
        1316: ' Herhangi Diğer Puanıyla Takımlar - Marjı Kazanma',
        1318: ' Asya Handikap Fauller - 1. Periyot',
        1319: ' Asya Handikap Ofsayt - 1. Periyot',
        1320: ' Asya Handikap Ofsayt - 2. Dönem',
        1321: ' Asya Handikap Fauller - 2. Dönem',
        1322: ' On Target Asya Handikap Atışlar - 2 Dönem',
        1324: ' / Aşırı Ofsayt Altında - 1 Dönem',
        1325: ' / Aşırı Ofsayt Altında - 2 Dönem',
        1326: ' İlk faul',
        1327: ' İlk iki Pointer',
        1328: ' İlk Üç Pointer',
        1329: ' Son İki Pointer',
        1330: ' Son Üç Pointer',
        1331: ' Son Serbest Atış attı',
        1332: ' İlk Serbest Atış attı',
        1333: ' İlk Rebound',
        1334: ' Son Zaman Aşımı',
        1335: ' Marj Takımlar Kazanma - 1. Dönem',
        1336: ' Marj Takımlar Kazanma - 2. Dönemi',
        1337: ' Marj Takımlar Kazanma - 3 Dönemi',
        1338: ' Marj Takımlar Kazanma - 4 Dönemi',
        1339: ' Marj Takımlar Kazanma - 5 Dönemi',
        1340: ' Son faul',
        1341: ' Üstünde / 10 Dönemi',
        1342: ' Üstünde / 11th Dönemi',
        1343: ' Üstünde / 12. Dönem',
        1344: ' Üstünde / 13. Dönem',
        1345: ' Üstünde / 14 Dönemi',
        1346: ' Üstünde / 15 Dönemi',
        1347: ' Üstünde / 16 Dönemi',
        1348: ' Üstünde / 17 Dönemi',
        1349: ' Üstünde / 18 Dönemi',
        1350: ' Üstünde / 19 Dönemi',
        1351: ' Üstünde / 20. Dönem',
        1352: ' Üstünde / 21 Dönemi',
        1353: ' Üstünde / 22 Dönemi',
        1354: ' / Aşırı 23 Dönem Altında',
        1355: ' Üstünde / 24 Dönemi',
        1356: ' Üstünde / 25 Dönemi',
        1357: ' Üstünde / 26 Dönemi',
        1358: ' Üstünde / 27 Dönemi',
        1359: ' Üstünde / 28 Dönemi',
        1360: ' Üstünde / 29 Dönemi',
        1361: ' Üstünde / 30 Dönemi',
        1362: ' Üstünde / 31 Dönemi',
        1363: ' Üstünde / 32 Dönemi',
        1364: ' / Aşırı 33 Dönem Altında',
        1365: ' Üstünde / 34 Dönemi',
        1366: ' Üstünde / 35 Dönemi',
        1367: ' Asya Handikap 11th Dönemi',
        1368: ' Asya Handikap 12. Dönem',
        1369: ' Asya Handikap 13. Dönem',
        1370: ' Asya Handikap 14 Dönem',
        1371: ' Asya Handikap 15 Dönem',
        1372: ' Asya Handikap 16 Dönem',
        1373: ' Asya Handikap 17 Dönem',
        1374: ' Asya Handikap 18 Dönem',
        1375: ' Asya Handikap 19 Dönem',
        1376: ' Asya Handikap 20 Dönem',
        1380: ' Asya Handikap 24 Dönem',
        1381: ' Asya Handikap 25 Dönem',
        1382: ' Asya Handikap 26 Dönem',
        1383: ' Asya Handikap 27 Dönem',
        1384: ' Asya Handikap 28 Dönem',
        1385: ' Asya Handikap 29 Dönem',
        1386: ' Asya Handikap 30 Dönem',
        1390: ' Asya Handikap 34 Dönem',
        1391: ' Asya Handikap 35 Dönem',
        1392: ' To Finish Top 10',
        1393: ' Yatmamak',
        1394: ' Asya Handikap 10 Dönem',
        1395: ' / Aşırı Oyuncu Powerplay Noktalar Altında',
        1396: ' Üstünde / Player Plus / Eksi',
        1397: ' / Aşırı Oyuncu Shots Altında',
        1398: ' En Victorian Kulübü',
        1399: ' / Aşırı Run Çıkışları Altında',
        1400: ' Üstünde / Çerçeveler',
        1401: ' İlk 20 Kazanan',
        1402: ' 1. Tur Lideri',
        1404: ' 1. Tur Altı Nişancı - Grup A',
        1406: ' 1. Tur 3 Toplar',
        1407: ' 1. Tur 18 Delik Match-Ups',
        1408: ' Maç Handicap',
        1409: ' Takım ile Top Bowler',
        1410: " The Win The Toss Ve Karşılaşması'na'",
        1411: ' / Aşırı Turrets Destroyed Altında',
        1412: ' / Aşırı Turrets Tahrip Altında - 1 Dönem',
        1413: ' / Aşırı Turrets Tahrip Altında - 2 Dönem',
        1414: ' / Aşırı Turrets Tahrip Altında - 3 Dönem',
        1415: ' 1-over sonra En Yüksek Puan',
        1416: ' Ev Sahibi Takım Üstünde / çalıştırır - 6 Over 1 Üzerinde',
        1417: ' Deplasman Takım Üstünde / çalıştırır - 6 Over 1 Üzeri',
        1418: ' Ev Sahibi Takım Üstünde / çalıştırır - 8 Over 1 Üzerinde',
        1419: ' Deplasman Takım Üstünde / çalıştırır - 8 Over 1 Üzeri',
        1420: ' Ev Sahibi Takım Üstünde / çalıştırır - 1 Üzerinde 10 Over',
        1421: ' Deplasman Takım Üstünde / çalıştırır - 10 Over 1 Üzeri',
        1422: ' Win için için Chase Üzeri 180 çalıştırır Ve Ekibi',
        1423: ' Kaç çalıştırır The First Ball atılır',
        1424: ' Üstünde / Maç Stumpings',
        1425: ' 1 Wicket Yöntemi',
        1426: ' Maçın ilk Topu',
        1427: ' Üzerinden / Altında - 1 Inning',
        1428: ' Üst Non Victorian Kulübü',
        1429: ' Büyük Final Kazanan',
        1431: ' Büyük Finale Ulaşır',
        1432: ' Değil için Finish yılında Top 8',
        1434: ' / Aşırı Öldürür 4 Dönemi Altında - Deplasman Takımı',
        1435: ' / Aşırı Öldürür 5 Dönem Altında - Deplasman Takımı',
        1436: ' / Aşırı Öldürür 4 Dönem Altında - Ev Ekibi',
        1437: ' / Aşırı Öldürür 5 Dönem Altında - Ev Ekibi',
        1438: ' Havalimanında / Aşırı Sıcaklığı Altında (Celsius)',
        1439: ' Asya Handikap - Tam gün',
        1440: ' / Aşırı Değer Altında Tüm Numaralarının Çizilen',
        1441: ' Tüm tek sayı / Aşırı Değer Altında Kalır',
        1442: ' Tüm Hatta sayılar Of / Aşırı Değer Altında Kalır',
        1443: ' Daha Çift Tek Numaraları Daha Çizilen Are',
        1444: ' Ardıl Numaraları çekilecek',
        1445: ' Altında / Tam / Aşırı - Odd Sayısı Toplar Numaralı Çizilen',
        1446: ' Altında / Tam / Aşırı - Hatta Numaralı Balls Sayısı Çizilen',
        1447: ' 5 Bonus topları toplamı',
        1448: ' İlk Bonus Topu çizilen - Tek / Çift',
        1449: ' Son Bonus Topu çizilen - Tek / Çift',
        1450: ' İlk Bonus Topu',
        1451: ' Son Bonus Topu',
        1452: ' 5 Bonus topları en düşük',
        1453: ' Bonus Topu - Tek / Çift',
        1454: ' Özdeş Numaraları çekilecek',
        1455: ' En yüksek Numara Çizilen - Tek / Çift',
        1456: ' Düşük sayısı çizilen - Tek / Çift',
        1457: ' Topu 1 - Alt / Üst',
        1458: ' Topu 1 - Tek / Çift',
        1459: ' Topu 2 ​​- Alt / Üst',
        1460: ' Topu 2 ​​- Tek / Çift',
        1461: ' Top 3 - Alt / Üst',
        1462: ' Top 3 - Tek / Çift',
        1463: ' Topu 4 - Alt / Üst',
        1464: ' Topu 4 - Tek / Çift',
        1465: ' Topu 5 - Alt / Üst',
        1466: ' Topu 5 - Tek / Çift',
        1467: ' Topu 6 - Alt / Üst',
        1468: ' Topu 6 - Tek / Çift',
        1469: ' Topu 7 - Alt / Üst',
        1470: ' Topu 7 - Tek / Çift',
        1471: ' Topu 8 - Alt / Üst',
        1472: ' Topu 8 - Tek / Çift',
        1473: ' Top 9 - Alt / Üst',
        1474: ' Top 9 - Tek / Çift',
        1475: ' Topu 10 - Alt / Üst',
        1476: ' Topu 10 - Tek / Çift',
        1477: ' Topu 11 - Alt / Üst',
        1478: ' Topu 11 - Tek / Çift',
        1479: ' Topu 12 - Alt / Üst',
        1480: ' Topu 12 - Tek / Çift',
        1481: ' Topu 13 - Alt / Üst',
        1482: ' Topu 13 - Tek / Çift',
        1483: ' Topu 14 - Alt / Üst',
        1484: ' Topu 14 - Tek / Çift',
        1485: ' Topu 15 - Alt / Üst',
        1486: ' Topu 15 - Tek / Çift',
        1487: ' Topu 16 - Alt / Üst',
        1488: ' Topu 16 - Tek / Çift',
        1489: ' Topu 17 - Alt / Üst',
        1490: ' Topu 17 - Tek / Çift',
        1491: ' Topu 18 - Alt / Üst',
        1492: ' Topu 18 - Tek / Çift',
        1493: ' Topu 19 - Alt / Üst',
        1494: ' Topu 19 - Tek / Çift',
        1495: ' Topu 20 - Alt / Üst',
        1496: ' Topu 20 - Tek / Çift',
        1497: " 3'ten itibaren bölünebilir Çizilen Numaralarının En Az Biri'",
        1498: ' 4 By Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1499: ' 5 By Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1500: ' 7 Tarafından Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1501: ' 10 By Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1502: ' 15 By Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1503: ' 20 By Is Bölünebilir Çizilen Numaralarının En Az Biri',
        1504: ' En yüksek Numara Çizilen - Alt / Üst',
        1505: ' Toplam Düşük Değeri Ve En Numaraları çizilen - Alt / Üst',
        1506: ' Düşük sayısı çizilen - Alt / Üst',
        1507: ' En çok ve en Arasındaki Fark çizilen - Alt / Üst',
        1508: ' Çizilen Toplam Düşük Değeri Ve En Sayılar - Tek / Çift',
        1509: ' En çok ve en Arasındaki Fark çizilen - Tek / Çift',
        1510: ' İlk sayısı çizilir Büyüktür The Last Numarası Çizilen',
        1511: ' Son Sayı Çizilen - Tek / Çift',
        1512: ' İlk sayısı çizilen - Tek / Çift',
        1513: ' Tüm Çizilen Sayılar bile',
        1514: ' Tüm Çizilen Numaralar Tek',
        1515: ' Numara için Kurası',
        1520: ' 4 Dönem - 1 taret',
        1521: ' 5 Dönem - 1 taret',
        1523: ' / Aşırı Barons Öldürülmüş Altında - 4 Dönem',
        1524: ' / Aşırı Barons Öldürülmüş Altında - 5 Dönem',
        1525: ' / Aşırı Dragons Öldürülmüş Altında - 4 Dönem',
        1526: ' / Aşırı Dragons Öldürülmüş Altında - 5 Dönem',
        1527: ' / Aşırı Turrets Tahrip Altında - 4 Dönem',
        1528: ' / Aşırı Turrets Tahrip Altında - 5 Dönem',
        1529: ' 4 Dönem Süre',
        1530: ' 5 Dönem Süre',
        1531: ' Tek / Çift öldürür - 4 Dönemi',
        1532: ' Tek / Çift öldürür - 5 Dönemi',
        1533: ' / Aşırı İnhibitörleri Altında - 1 Dönem',
        1534: ' / Aşırı İnhibitörleri Altında - 2 Dönem',
        1535: ' Aralıktaki Havalimanı (Celsius) At Sıcaklık',
        1536: ' / Aşırı Maps Altında',
        1537: ' Ev Sahibi Takım için Win & Üstünde / - Uzatmalar dahil',
        1538: ' Deplasman Takım için Win & Üstünde / - Uzatmalar dahil',
        1539: ' Yuvarlak kazanan',
        1540: ' Üzerinden / Altında Toplam Maç Bacaklar',
        1541: ' yuvarlar Handikap',
        1542: ' 1 Bacak Kazanan',
        1543: ' İlk To Win 3 Bacaklar',
        1546: ' 4 Dönem - 1 Roshan',
        1547: ' 5 Dönem - 1 Roshan',
        1548: ' 4 Dönem - 1 Kışlası',
        1549: ' 5 Dönemi - 1. Kışlası',
        1550: ' 4 Dönem - 1 tou',
        1551: ' 5 Dönemi - 1. tou',
        1552: ' Asya Üstünde / Köşeler',
        1553: ' İki Takım Sayısı Noktalarının Atacak - 1. Dönem',
        1554: ' İki Takım noktalarının sayısını Atacak - 1 Half',
        1555: ' Üstünde / 5 Dönemi - Ev Ekibi',
        1556: ' Üstünde / 5 Dönemi - Deplasman Takımı',
        1558: ' Asya Handikap Puanları',
        1559: ' Kupa kazanan',
        1561: ' 1 3 Innings Kazanan',
        1562: ' 1 3 Innings Üstünde /',
        1563: ' Puanlama Time Of',
        1564: ' Puanlama Zamanı - Ev Sahibi Takım',
        1565: ' Puanlama Time Of - Deplasman Takım',
        1566: ' Maç Sonucu',
        1567: ' En Milliyet - Güney Afrika',
        1568: ' En Milliyet - İspanyol',
        1569: ' En Milliyet - İrlanda',
        1570: ' En Milliyet - Güney Amerika',
        1571: ' En Milliyet - Kanadalı',
        1572: ' En Milliyet - İngiliz',
        1573: ' En Milliyet - Kıta Avrupası',
        1575: ' Cut Yapmak',
        1576: ' Miss Kesim',
        1577: ' En Milliyet - Amerikan',
        1578: ' Winner Of Milliyet',
        1579: ' Asya Handikap Noktaları - 1. Periyot',
        1580: ' Asya Handikap Noktaları - 2 Dönem',
        1581: ' Asya Handikap Noktaları - 3 Dönem',
        1583: ' Üst Takım Bowler 1. İnning - Ev Sahibi Takım',
        1584: ' Üst Takım Bowler 1. İnning - Deplasman Takım',
        1585: ' Üst Takım Batsman 1. İnning - Ev Sahibi Takım',
        1586: ' Üst Takım Batsman 1. İnning - Deplasman Takım',
        1587: ' As Sonraki Noktası olacak mı',
        1589: ' Bir Çift Hata Sonraki Noktası olacak mı',
        1591: ' Üzerinden / Altında - 1 Üzeri',
        1592: ' To Win Lig',
        1593: ' To Win Bölümü',
        1594: ' Çoğu Hits - Uzatmalar dahil',
        1595: ' Git mesafesi Fight',
        1596: ' Ne zaman Dövüş Sonu Olacak',
        1597: ' Dövüş Sonuç',
        1598: ' Yuvarlak Bahis',
        1599: ' Üst 1 Hit',
        1600: ' Sondan 1 Hit',
        1601: ' Üst 1. Yarış',
        1602: ' Alt 1. Yarış',
        1603: ' 1 Inning yılında En Hit',
        1605: ' En Fazla Gol Inning ile Takım',
        1606: ' İki Takım 2 veya daha fazla gol için',
        1607: ' İki Takım 3 veya daha fazla gol için',
        1608: ' Takım için The Win The Most Dönemleri',
        1609: ' Asya Handikap Tam Zamanı - 1. Periyot',
        1610: ' Asya Handikap Tam gün - 2 Dönem',
        1611: ' Asya Handikap Tam Zamanı - 3 Dönem',
        1612: ' MVP',
        1614: ' To Win Konferansı',
        1615: ' Konferansı Kazanma',
        1617: ' Sezon Oyuncu Özel',
        1618: ' 1 5 Innings Kazanan - 12',
        1619: ' 1. Periyot - Deplasman İlk yarı Kazanan Ev /',
        1620: ' 1. Periyot - İlk yarı Asya Handicap',
        1621: ' 2 Dönem - Deplasman İlk yarı Kazanan Ev /',
        1622: ' 2 Dönem - İlk yarı Asya Handicap',
        1624: ' / Aşırı Oyuncu çalıştırır Altında',
        1625: ' / Aşırı Oyuncu Tekli',
        1626: ' / Aşırı Oyuncu Ev çalıştırır Altında',
        1627: ' / Aşırı Oyuncu Çalıntı Üs Altında',
        1628: ' Üstünde / Oyuncu Çiftler',
        1629: ' Üstünde / Oyuncu üç katına',
        1630: ' Kaydı Kazanç sürahi',
        1631: ' / Üzeri Oyuncu Toplam Üs Altında',
        1635: ' Kazanılan / Aşırı sürahi çalıştırır Altında',
        1636: ' / Aşırı Sürahi Hit Altında İzin',
        1637: ' / Aşırı sürahi strikeout Altında',
        1638: ' Takım Klasmanı',
        1639: ' Genç Binici Sınıflandırma',
        1640: ' Puan Sınıflandırma',
        1641: ' Dağlar Kralı',
        1642: ' 1. Aşama',
        1649: ' En hızlı tur',
        1650: ' Kazanan Araç',
        1651: ' İlk Sürücü emekli',
        1652: ' Kazanan Car Yeterlilik',
        1653: ' Uygulama 1 en hızlı',
        1654: ' Puan Bitiş',
        1655: ' En hızlı Elemeleri',
        1657: ' Sahne 3',
        1659: ' İlk Yapıcı Emeklilik',
        1660: ' Winner Izgara Pozisyonu',
        1661: ' Yarışında Emniyet Araba Dönemi',
        1662: ' Seri Sürücülerin Sayısı',
        1663: ' Yarışında Sanal Güvenlik Araç Dönemi',
        1664: ' Delik Skor',
        1667: ' To Win Delik',
        1670: ' Bağlı Maç',
        1672: ' / Aşırı Gol - 2. yarıda',
        1673: ' Tek / Çift 4 Dönemi yuvarlar',
        1674: ' Tek / Çift 5 Dönemi yuvarlar',
        1675: ' 2. Tur Lideri',
        1676: ' İki Takım Skoru için - Dahil mesai',
        1677: ' Skoru Son Takım - Uzatmalar dahil',
        1678: ' En Milliyet - Alman',
        1679: ' En Milliyet - Avusturya',
        1680: ' En Milliyet - Yeni Zelandalı',
        1681: ' Sol Üst Elli Oyuncu',
        1682: ' Üst Amatör',
        1683: ' En Milliyet - Korece',
        1684: ' En Milliyet - İskandinav',
        1685: ' En Milliyet - Fransız',
        1686: ' En Eski Kazanan',
        1687: ' En Milliyet - İsveçli',
        1688: ' En Milliyet - Japon',
        1689: ' En Milliyet - Scotsman',
        1690: ' En Milliyet - Belçika',
        1691: ' Avrupa Handicap 4 Dönemi',
        1692: ' Avrupa Handikap 3 Dönem',
        1693: ' Avrupa Handikap 2 Dönem',
        1694: ' Avrupa Handikap 1. Periyot',
        1695: ' Yuvarlak Skor',
        1696: ' Turnuva Kazanan Skor',
        1697: ' Round sonra Puanını Öncü',
        1698: ' Fairway ile Tee vuruşa da',
        1699: ' Yeşil In Yönetmeliği vurmak',
        1700: ' 3. Tur Lideri',
        1702: ' / Aşırı Oyuncu Pas Denemeleri Altında',
        1704: ' 1 periyot hakkında Touchdown',
        1705: ' 2 periyot hakkında Touchdown',
        1706: ' 3 periyot hakkında Touchdown',
        1707: ' 4 periyot hakkında Touchdown',
        1708: " Altında / Tam üzerinden / - Ev Sahibi Takım touchdown'lar",
        1709: ' Altında / Tam üzerinden / - Deplasman Takım Goller',
        1710: " Altında / Tam üzerinden / - Ev Sahibi Takım 1 Yarım touchdown'lar",
        1711: ' Altında / Tam üzerinden / - Deplasman Takım 1 Yarım Goller',
        1712: ' 16 Yuvarlak Kazanan - 4 Dönem',
        1713: ' 16 Yuvarlak Kazanan - 5 Dönem',
        1714: ' Tahrip / Aşırı tous Altında - 4 Dönem',
        1715: ' Tahrip / Aşırı tous Altında - 5 Dönem',
        1722: ' Nil için Finish ayarlayın',
        1723: " / Aşırı Kills'de Altında - 1 Dönem - 1 Round",
        1724: " / Aşırı Kills'de Altında - 2 Dönem - 1 Round",
        1728: ' Puanına İki Takım - Ekstra Zaman',
        1729: ' Çifte Şans - Ekstra Zaman',
        1730: ' Oyun Git Süper Üzeri edecek mi?',
        1731: ' Çifte Şans 5 Dönem',
        1732: ' Çifte Şans 6 Dönemi',
        1733: ' Çifte Şans 7 Dönemi',
        1734: ' Çifte Şans 8 Dönem',
        1736: ' Skor 1 İnning sayısı',
        1737: ' Sayısı 2 Inning çalıştırır',
        1738: ' Skor 3 İnning sayısı',
        1739: ' Sayısı 4 Inning çalıştırır',
        1740: ' Sayısı 5. vuruş çalıştırır',
        1741: ' Sayısı 6 Inning çalıştırır',
        1742: ' Sayısı 7 Inning çalıştırır',
        1743: ' Sayısı 8. vuruş çalıştırır',
        1744: ' Bir Elli 1 vuruştan attı edilecek',
        1745: ' 1 Wicket Of Güz',
        1747: ' Sonraki Man Out',
        1748: ' Tamamlanan Maç',
        1749: ' Yüzyıl Attı',
        1750: ' Yüzyıl Attı - 1 İnning',
        1752: ' Deplasman Takım İlk Görevden 6 Yollu',
        1753: ' En Maç Batsman',
        1754: ' Bir Cezası Atacak',
        1758: ' Yılın 6 Man',
        1760: ' Yılın Çaylağı',
        1761: ' Normal Sezon MVP',
        1763: ' Batsman 1. vuruş da yüz Atacak',
        1765: ' Maçta 9 Dart Finish',
        1766: ' İlk deneyin Zamanı',
        1767: ' Son Try Zamanı',
        1770: ' Maçın 9 Dart Finish - Ev Sahibi Takım',
        1771: ' Maçın 9 Dart Finish - Deplasman Takım',
        1772: ' En Run golcüyü',
        1773: ' En Run Skorer - Ev Sahibi Takım',
        1774: ' En Run Skorer - Deplasman Takım',
        1775: ' En Run golcüyü Takımı',
        1776: ' Üstünde / Punts',
        1777: ' Üstünde / Sacks',
        1778: ' / Aşırı 1 Downs Altında',
        1779: ' 1 A takımı Birinci Aşağı Get',
        1780: ' 1 Yarım Üstünde / Alan Hedefler - Ev Sahibi Takım',
        1781: ' 1 Yarım Üstünde / Alan Hedefleri - Deplasman Takım',
        1782: ' Saha Hedefleri 1 Yarım - / Tam / Üst Alt',
        1783: ' Üstünde / Goller 1 Dönem',
        1784: ' / Aşırı Cezaları Altında Kabul',
        1785: ' Maçın çoğu öldürür',
        1786: " Maçın En Kills'de ile Takım'",
        1787: ' / Aşırı Geçiş Denemeleri Altında',
        1788: ' Üstünde / Geçiş Tamamlama',
        1789: ' Üstünde / Yards Geçme',
        1790: ' / Aşırı Rush Denemeleri Altında',
        1791: ' Üstünde / Rush Yards',
        1792: ' Üstünde / Oyuncu ele alan ve Assist',
        1794: ' Punt Birinci Takım',
        1795: ' 1. Periyot 1X2 Ve Üstünde /',
        1796: ' 1X2 Asist',
        1797: ' Çifte Şans Asist',
        1798: ' Her Dönemi sonra Kurşun için takım',
        1799: ' En Yards Geçme ile Takım',
        1800: ' En Yards Rushing ile Takım',
        1801: ' Başarılı 2 Nokta Dönüşüm',
        1802: ' / Aşırı Touchdown Geçirdi Altında',
        1803: ' / Aşırı Saha gol 1. Dönem Altında',
        1804: ' 1 Yarısı 2 Dakika Son Sayı',
        1805: ' Takım Kick Off Açılış Alacak',
        1806: ' / Aşırı Ev Sahibi Takım touchdown Altında - 1 dönem',
        1807: ' Üstünde / Deplasman Takım Goller - 1 dönem',
        1808: ' Altında / Tam / Üzeri Ev Sahibi Takım Goller - 1 dönem',
        1809: ' Altında / Tam / Üzeri Deplasman Takım Goller - 1 dönem',
        1810: ' / Aşırı Ev Sahibi Takım Saha Hedefleri Altında - 1 dönem',
        1812: ' Üstünde / Deplasman Takım Saha Hedefler - 1 dönem',
        1813: " Win Her Mahallesi'ne Deplasman Takım'",
        1814: " Win Her Mahallesi'ne Ev Sahibi Takım",
        1815: ' Oyunun ilk Saldırgan Çal',
        1816: ' To Win Çeyrek',
        1817: ' Finale Ulaşır',
        1818: ' Ev Sahibi Takım 25 gol',
        1819: ' Ev Sahibi Takım 30 gol',
        1820: ' 25 gol Deplasman Takım',
        1821: ' 30 gol Deplasman Takım',
        1822: ' Ev Sahibi Takım için Win Ya Yarım',
        1823: ' Deplasman Takım için Win Ya Yarım',
        1824: ' Önlenmesi Aşaması',
        1825: ' / Aşırı Assists Altında',
        1826: ' Üstünde / ribaund',
        1827: ' / Aşırı Ev takımı Assists Altında',
        1828: ' / Aşırı Deplasman Takım Destekler Altında',
        1829: ' Üstünde / Ev Sahibi Takım ribaund',
        1830: ' Deplasman üzerinden / Takım Rebound Altında',
        1831: ' Yapılan 1X2 3 Puan',
        1832: ' Yapılan çok / az 3 Puan',
        1833: ' / Aşırı Ev Ekip altında 3 Puan Yapılan',
        1834: ' / Aşırı Dışarıda 3 Noktalar Yapımı takım Altında',
        1835: ' Yapılan Asya Handikap 3 Puan',
        1841: ' İlk Oyuncu Değişikliği',
        1842: ' Maçta 2 Gol',
        1843: ' Maçta 3 Gol',
        1846: ' 60 Dakika Sonucu',
        1847: ' 75 Dakika Sonucu',
        1848: ' İlk 5 Dakika Attı Hedef?',
        1849: ' İlk Topun oyuna sokulması Match',
        1850: ' Ya Yarısında çizin',
        1851: ' Match Hedef Başlı?',
        1852: ' / Aşırı Hedef Dakika Altında',
        1853: ' Maçta / Aşırı Tie Break the Altında',
        1854: ' / Aşırı Molası noktaları Altında',
        1855: ' Üstünde / Molası Ev Ekibi Puan',
        1856: ' / Aşırı Molası Deplasman Takımı Puan Altında',
        1857: ' / Aşırı En Fazla Gol Mahallesi Altında',
        1858: ' / Aşırı Düşük Puanlama Mahallesi Altında',
        1859: ' Çoğu Denemeleri 3 Yollu',
        1860: ' 10 Dakika Kazanma Marjı 5-Yönlü',
        1861: ' Çoğu Zararlar Takım',
        1862: ' Bir alt Oyunu gol atar',
        1863: ' Win Tüm Dönemleri - Ev Sahibi Takım',
        1864: ' Win Tüm Dönemler - Deplasman Takım',
        1865: ' İlk 10 Dakika Üstünde / deneme sayısı',
        1866: ' 15 Dakika Üstünde / - Köşeler',
        1867: ' Ekibi tarafından Bir Satır 2 Goller',
        1868: ' Ekibi tarafından Bir Satır 3 Goller',
        1869: ' En Dönüştürülen Denemeleri',
        1872: ' Tohumsuz Finalisti?',
        1873: ' Joker için Reach Final?',
        1874: ' Kazanan Tenis - Yarım Kazanma',
        1875: ' Kazanma Çeyrek - Tenis Şampiyonluk',
        1881: ' Bir delik',
        1882: ' Play-off Olacak?',
        1883: ' 72 Delik Maç Ups',
        1884: ' 54 Delik Maç Ups',
        1885: ' Sayı 1 Taslak Seçim',
        1886: ' 1X2 ve Çifte Şans',
        1887: ' Win için Ev Sahibi Takım çizin ve \\ skoru Her iki takım',
        1888: ' Deplasman Win Takım çizin ve skoru Her iki takım \\',
        1889: ' Kazanma Marjı - 1. Yarı',
        1891: ' Amerikan Lig Cy Genç',
        1892: ' Amerikan Lig MVP',
        1893: ' Milli Lig Cy Genç',
        1894: ' Ulusal Lig MVP',
        1895: ' Kaydı En Normal Sezon Wins için sürahi',
        1896: ' Oyuncu için çoğu Normal Sezon home run',
        1897: ' Will Oyun Ekstra Zaman Karar Verileceği?',
        1898: ' Normal sezon Kazanma%',
        1900: ' Oyuncu Gol Krallığı',
        1901: ' Üstünde / Tackles',
        1902: ' Sayı 2 Taslak Seçim',
     }
     
}