import { en } from './en';
//import { pr } from './pr';
import { tr } from './tr';
import { de } from './de';
import { nl } from './nl';
import { ko } from './ko';
import { es } from './es';
import { fr } from './fr';
import { pt } from './pt';
import { ru } from './ru';
import { sv } from './sv';
import { zh as zhcn } from './zhcn';
import { zh as zhtw } from './zhtw';
import { th } from './th';
import { it } from './it';
import { ja } from './ja';


import LocalizedStrings from 'react-localization';

export const Translate = new LocalizedStrings({
    'en-US': en,
    // tr: tr,
    // de: de,
    // nl: nl,
    'ko': ko,
    // es: es,
    // fr: fr,
    // pt: pt,
    // ru: ru,
    // sv: sv,
    'zh-CN': zhcn,
    'zh-TW': zhtw,
    'th': th,
    // it: it,
    // ja: ja,
});
