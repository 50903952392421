import { toastr } from 'react-redux-toastr';
import Util from '../../helper/Util';
import { Translate } from '../../localization';
import jwtService from '../../services/jwtService';
import * as Actions from './actionTypes';
import { getFavorites } from './favorites.actions';
import { getSlotGameData } from './casino.actions';

//Register user
export const submitSignUp = (obj, callback) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let userData = {
            username: obj.inputUsername,
            phone_number: obj.inputPhoneNo,
            email: obj.inputEmail,
            otp: obj.inputOtp,
            password: obj.inputPassword,
            confirm_password: obj.inputConfirmPassword,
            currency: obj.currencyName,
            bank_acc_name: obj.bankAccountName,
            bank_acc_number: obj.bankAccountNo,
            agent_code: '',
            country_code: obj.code
        };
        jwtService
            .signup(userData, language)
            .then((user) => {
                toastr.success('', 'Sign Up SuccessFull');
                dispatch(submitLogin(obj.inputUsername, obj.inputPassword));
            })
            .catch((error) => {
                // if (error.response && error.response.status === 400) {
                //     Object.values(error.response.data).map((err, index) => (
                //         toastr.error('', err[0])
                //     ));
                //     dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'Validation error' });
                //     setTimeout(() => {
                //         dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                //     }, 1000);
                // }
                toastr.error('', error.response.data.message);
                callback();
                dispatch({ type: Actions.SIGNUP_ERROR, signupError: 'Validation error' });
                setTimeout(() => {
                    dispatch({ type: Actions.SIGNUP_ERROR, signupError: '' });
                }, 1000);
            });
    };
};

// Login user
export const submitLogin = (username, password, history) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let gameData = sessionStorage.getItem('gameData') ? JSON.parse(sessionStorage.getItem('gameData')) : null;
        jwtService
            .signInWithUsernameAndPassword(username, password, language)
            .then((user) => {
                localStorage.setItem('user_id', user.pk);
                jwtService.getUser(language).then((user) => {
                    dispatch({ type: Actions.SET_USER, user });
                    if (gameData) dispatch(getSlotGameData(gameData, 'real', history));
                });
                return dispatch({ type: Actions.LOGIN_SUCCESS });
            })
            .catch((error) => {
                if (error.response && error.response.status === 401) {
                    dispatch(setLoginError(error.response.data?.non_field_errors && error.response.data?.non_field_errors[0]));
                } else if (error.response && error.response.status === 404) {
                    dispatch(setLoginError(error.response.data.detail));
                }
            });
    };
};

// Set login error
export const setLoginError = (error) => {
    return (dispatch) => {
        dispatch({
            type: Actions.LOGIN_ERROR,
            loginError: error,
        });
    };
};

// Change user password
export const changePassword = (oldPassword, newPassword) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService
            .changeUserPassword(oldPassword, newPassword, language)
            .then((response) => {
                dispatch(setChangePasswordSuccess(true));
                toastr.success('', 'Password was changed successfully!');
            })
            .catch((error) => {
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    dispatch(setChangePasswordError(error.response.data.detail));
                }
            });
    };
};

export const resetDepositAmount = () => {
    return (dispatch) => {
        dispatch({ type: Actions.RESET_DEPOSIT_AMOUNT });
    };
};

// Set change password success
export const setChangePasswordSuccess = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_SUCCESS, value });
    };
};

// Set change password error
export const setChangePasswordError = (error) => {
    return (dispatch) => {
        dispatch({ type: Actions.CHANGE_PASSWORD_ERROR, error });
    };
};

// Logout user
export const logoutUser = () => {
    return (dispatch) => {
        jwtService.logout();
        dispatch({ type: Actions.SET_FAVORITES, events: [] });
        dispatch({ type: Actions.SET_FAVORITES_LIVE, events: [] });
        dispatch({ type: Actions.LOGOUT_USER });
    };
};

// Set user object data
export const setUser = (user) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_USER, user });
    };
};

// Get user object data
export const getUser = () => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        jwtService.getUser(language).then((user) => {
            dispatch(setUser(user));
        });
    };
};

export const getCashbackData = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.getCashbackData(userId).then((response) => {
            dispatch(setCashback(response));
        });
    };
};

export const setCashback = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_DATA, data });
    };
};

export const getCashbackSucess = () => {
    return (dispatch) => {
        const userId = localStorage.getItem('user_id');
        jwtService.setCashback(parseInt(userId)).then((response) => {
            dispatch(setCashbackSucess(response));

        })
            .catch((error) => {
                console.error(error);
            });

    };
};

export const setCashbackSucess = (data) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASHBACK_SUCESS, data });
        toastr.success('', data.message);
        dispatch(getUser());
    };
};

export const setOddType = (oddType) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_ODD_TYPE, oddType });
    };
};

export const clearSuccess = () => {
    return (dispatch) => {
        dispatch({ type: Actions.CLEAR_SUCCESS });
    };
};

//Deposit
export const depositAmount = (params) => {
    return (dispatch, getState) => {
        const { amount, buyer_email, currency2 } = params;
        dispatch(clearSuccess());
        jwtService
            .getDepositAddress(currency2)
            .then(({ error, result }) => {
                if (error && !result?.address) {
                    dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error });
                    throw error;
                }
                jwtService
                    .deposit(amount, buyer_email, currency2, result?.address)
                    .then(({ result }) => {
                        // window.location.href = result?.checkout_url;
                        dispatch({ type: Actions.DEPOSIT_SUCCESS, success: "Your Transaction Will be Done within 5 to 10 min." });
                        window.open(result?.checkout_url, '_blank');
                    })
                    .catch((error) => {
                        if (error?.response && error?.response?.data && error?.response?.data?.message) {
                            dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error.response.data.message });
                        } else if (error?.response && error?.response?.data && error?.response?.data?.detail && error?.response?.data?.detail?.length > 0) {
                            dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error?.response?.data?.detail[0]?.msg });
                        } else {
                            console.log(error);
                        }
                        if (getState().user.depositError) {
                            setTimeout(() => {
                                dispatch(clearSuccess());
                            }, 3000);
                        }
                    });
            })
            .catch((error) => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error.response.data.message });
                } else if (error?.response && error?.response?.data && error?.response?.data?.detail && error?.response?.data?.detail?.length > 0) {
                    dispatch({ type: Actions.DEPOSIT_AMOUNT, error: error?.response?.data?.detail[0]?.msg });
                } else {
                    console.log(error);
                }
                if (getState().user.depositError) {
                    setTimeout(() => {
                        dispatch(clearSuccess());
                    }, 3000);
                }
                console.log(error);
            });
    };
};

// Withdraw
export const withdraw = ({ amount, currency, address }) => {
    return (dispatch, getState) => {
        dispatch(clearSuccess());
        jwtService
            .withdraw(amount, currency, address)
            .then(({ status }) => {
                // console.log('data', data)
                // toastr.success('', 'Withdraw request created successfully')
                if (status === 'Complete') {
                    dispatch({ type: Actions.WITHDRAW_AMOUNT, withdrawSuccess: Translate.withdrawSuccess });
                } else if (status === 'Failed') {
                    dispatch({ type: Actions.WITHDRAW_AMOUNT, error: Translate.withdrawFailed });
                };
            })
            .catch((error) => {
                if (error?.response && error?.response?.data && error?.response?.data?.message) {
                    dispatch({ type: Actions.WITHDRAW_AMOUNT, error: error.response.data.message });
                } else if (error?.response && error?.response?.data && error?.response?.data?.detail && error?.response?.data?.detail?.length > 0) {
                    dispatch({ type: Actions.WITHDRAW_AMOUNT, error: error?.response?.data?.detail[0]?.msg });
                } else {
                    console.log(error);
                }
                if (getState().user.withdrawError) {
                    setTimeout(() => {
                        // toastr.error('', 'Something went wrong, please try again.')
                        dispatch(clearSuccess());
                    }, 3000);
                }

            });
    };
};
