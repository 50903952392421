export const de = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en: 'EN',
    tr: 'TR',
    fr: 'FR',
    de: 'DE',
    ru: 'RU',
    nl: 'NL',
    th: 'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports : 'Sport',
    live : ' WOHNEN',
    inplay :'im Spiel',
    today : ' Heute',
    upcoming : ' Bevorstehende',
    tomorrow : ' Morgen',
    highlights : ' Highlights',
    results : ' Ergebnisse',
    lastMinute : ' Letzte Minute',
    liveCasino : ' Live Casino',
    coinPayments: 'Coin Payments',
    casino : ' Kasino',
    myBets : ' Meine Wetten',
    home : ' Zuhause',
    login : ' Anmeldung',
    signup : ' Anmelden',
    betslip : ' Wettschein',
    search : ' Suche',
    searchHere : ' Suche hier',
    transaction : ' Transaktion',
    changePassword : ' Kennwort ändern',
    logout : ' Ausloggen',
    single : ' Single',
    multiple : ' Mehrere',
    possibleWin : ' Möglicher Gewinn',
    amount : ' Menge',
    ok : ' OK',
    totalOdds : ' Gesamte Gewinnchancen',
    odd : ' Seltsam',
    totalPossibleWin : ' Insgesamt möglicher Gewinn',
    placeBet : ' Wetteinsatz festlegen',
    fastBet:'schnelle Wette',
    fast : ' Schnell',
    bet : ' Wette',
    username : ' Nutzername',
    password : ' Passwort',
    stayLoggedIn : ' Bleibe eingeloggt',
    all : ' Alle',
    'in game' : ' Im Spiel',
    won : ' Gewonnen',
    lost : ' Hat verloren',
    refund : ' Rückerstattung',
    date : ' Datum',
    winAmount : ' Gewinnbetrag',
    stake : ' Anteil',
    pick : ' Auswahl',
    myAccount : ' Mein Konto',
    football : ' Fußball',
    basketball : ' Basketball',
    iceHockey : ' Eishockey',
    tennis : ' Tennis',
    volleyball : ' Volleyball',
    boxing : ' Boxen',
    americanFootball: 'Amerikanischer Fußball',
    baseball: 'Baseball',
    hockey:	'Eishockey',
    tableTennis: 'Tischtennis',
    badminton: 'Badminton',
    cricket: 'Kricket',
    futsal: 'Futsal',
    golf: 'Golf',
    handball: 'Handball',
    rugbyLeagues: 'Rugby-Leagues',
    pickCanNotBeCombined : ' Diese Wahl kann nicht kombiniert werden',
    matchStopped : ' Spiel beendet',
    favorites : ' Favoriten',
    cashout : ' Auszahlen',
    'in progress' : ' In Bearbeitung',
    rejected : ' Abgelehnt',
    tip : ' Trinkgeld',
    noGoal : ' Kein Tor',
    yes : ' Ja',
    over : ' Über',
    under : ' Unter',
    even : ' Sogar',
    areYouSureWantCashout : ' Sind Sie sicher, dass Sie Cashout wollen?',
    noGoal : ' Nein',
    bonus : ' Bonus',
    termsAndConditon : ' Geschäftsbedingungen',
    aboutUs: 'Über uns',
    contactUs: 'Kontaktiere uns',
    payments: 'Zahlungen',
    howPlacebet: 'Wie man wette',
    deposit: ' Anzahlung',
    credit : 'Anerkennung',
    debit : 'Lastschrift',
    withdraw : ' Abheben',
    totalOdd : ' insgesamt Odd',
    totalStake : ' Gesamteinsatz',
    topGames : ' Spitzenspiele',
    allGames : ' Alle Spiele',
    canNotBeCombined : ' Diese Pick-können nicht kombiniert werden',
    betSettled : ' bet Ausgewertet',
    betSuspended : ' bet Suspended',
    insufficientBalance : ' Mangelhaftes Gleichgewicht',
    oldPassword : ' Altes Passwort',
    newPassword : ' Neues Kennwort',
    confirmPassword : ' Bestätige neues Passwort',
    change : ' Veränderung',
    fromDate : ' Ab Datum',
    toDate : ' Miteinander ausgehen',
    activityType : ' Leistungsart',
    show : ' Show',
    gameType : ' Spiel Typ',
    repeatBet : ' Repeat Bet',
    someThingWrong : ' Etwas ist schief gelaufen',
    status : ' Status',
    coupon : ' Coupon',
    acceptOddChangesText : ' Sie müssen Änderungen zu übernehmen zu können, um eine Wette platzieren',
    turnOffAway : ' Schalten Sie sowieso',
    acceptOddChanges : ' Akzeptieren ungerade Änderungen',
    maxWinAmount : ' Max Gewinnbetrag',
    maxOdd : ' Max ungerade',
    dateTime : ' Terminzeit',
    type : ' Art',
    refreshCashout : ' Aktualisieren Cashout',
    vendor : ' Verkäufer',
    stakeLowerThen1 : ' Der Einsatz muss weniger dann',
    stakeGreaterThen1 : ' Der Einsatz muss größer sein dann',
    ESports: 'Esports',
    KSports: 'Ksports',
    promotion: 'Beförderung',
    customerServicePage: 'Kundenservice-Seite',
    decimal: 'Dezimal',
    fraction: 'Fraktion',
    americanOdds: 'Amerikanische Quoten',
    nothingFound: 'Nichts gefunden',
    noLiveMatch: 'Kein Live-Match ist verfügbar',
    sportBook: 'Sportbuch',

    // new Translation end
    marketNames: {
        1: '1x2',
        2: ' Unter / Über',
        238: ' verbleibende Spiel',
        59:'Nächste Ziel',
        7: ' Doppelte Chance',
        52: ' Unentschieden keine Wette',
        226: ' 12 einschließlich der Überstunden',
        13: 'European Handicap',
        4: 'HT/FT',
        17: ' Beide Teams erzielen ein Tor',
        59: ' Nächste Ziel',
        28: ' Unter / Über einschließlich Überstunden',
        247: ' Verbleibende 1. Halb',
    },
    
    tabNames: {
        'All':'Alle',
       'Main Markets': 'Hauptmärkte',
        'Totals': 'Totals',
        '1st Half': '1. Halb',
        '2nd Half': '2. Halbzeit',
        'Home Team': 'Heimmannschaft',
        'Away Team': 'Gastmannschaft',
        'All Periods': 'Alle Perioden',
        'Results':'Ergebnisse',
        'Home / Away': 'Heim / Auswärts',
    },
    markets: {
        1: 'Tipp 1X2',
        2: 'Unter/Über',
        3: 'Asian Handicap',
        4: 'Halbzeit/Endstand',
        5 : '  Ungerade gerade',
        6 : '  Korrekter Spielstand',
        7 : ' Doppelte Chance',
        9 : '  Korrektes Ergebnis 1. Periode',
        11 : ' Gesamtanzahl Ecken',
        12 : ' Insgesamt begangene Fouls',
        13 : ' European Handicap',
        16 : ' First Team To Score',
        17 : ' Beide Teams erzielen ein Tor',
        19 : ' erste Karte',
        21 : ' Unter / Über 1. Drittel',
        22 : ' Gastmannschaft zum Spiel',
        23 : ' Home Team zu Score',
        25 : ' Doppelte Chance Halbzeit',
        29 : ' Unter / Über Runden',
        30 : ' Unter / Über Corners - Home Team',
        31 : ' Unter / Über Corners - Gastmannschaft',
        34 : ' Home Team, um in beiden Hälften',
        35 : ' Auswärtsmannschaft in beiden Hälften',
        41 : ' 1. Drittel Gewinner',
        42 : ' 2. Periode Gewinner',
        43 : ' 3. Periode Gewinner',
        44 : ' 4. Periode Gewinner',
        45 : ' Unter / Über 2. Periode',
        46 : ' Unter / Über dritten Periode',
        47 : ' Unter / Über 4. Periode',
        48 : ' Unter / über fünfte Periode',
        49 : ' 5. Periode Gewinner',
        50 : ' 1X2 einschließlich Überstunden',
        51 : ' Gerade / Ungerade inklusive Verlängerung',
        52 : ' 12',
        53 : ' Asian Handicap Halbzeit',
        55 : ' Erster Mannschaftstor erste Periode',
        56 : ' Letzter Mannschaftstor',
        57 : ' Letzte Mannschaftstor Halbzeit',
        59 : ' Nächste Ziel',
        61 : ' European Handicap Halbzeit',
        62 : ' Gerade / Ungerade Halbzeit',
        63 : ' 12 Halbzeit',
        64 : ' Asian Handicap erste Periode',
        65 : ' Asian Handicap zweite Periode',
        66 : ' Asian Handicap 3. Periode',
        67 : ' Asian Handicap 4. Periode',
        68 : ' Asian Handicap 5. Periode',
        69 : ' Wird das Spiel Go To Verlängerung?',
        70 : ' Mit höchsten Punktzahl Period',
        71 : ' Hälfte mit höchster Punktzahl',
        72 : ' 1. Periode Gerade / Ungerade',
        73 : ' 2. Periode Gerade / Ungerade',
        74 : ' 3. Periode Gerade / Ungerade',
        75 : ' 4. Periode Gerade / Ungerade',
        76 : ' 5. Periode Gerade / Ungerade',
        77 : ' Unter / Über Halbzeit',
        78 : ' Erzielt die meisten Sixes',
        79 : ' Wird es einen Elfmeter vergeben?',
        80 : ' erste Ecke',
        81 : ' Letzter Corner',
        82 : ' Es wird eine Rote Karte gezeigt?',
        83 : ' Bleibt ohne Gegentor',
        84 : ' Um Gewinnt beide Hälften',
        85 : ' Sieg von hinten',
        86 : ' Gewinnt zu null',
        88 : ' Qualifizieren',
        89 : ' Wird der erste Versuch konvertiert werden?',
        90 : ' Wird das Spiel in der 5. Satz entschieden?',
        91 : ' Was ist in der Halb Zuerst Tor erzielt?',
        92 : ' Erste Mannschaftstor 2. Hälfte',
        95 : ' Ecken Handicap',
        96 : ' Gastmannschaft gewinnt zu null',
        97 : ' Home Team gewinnt zu null',
        98 : ' Home Team, um sauber halten',
        99 : ' Auswärtsmannschaft sauber halten',
        100 : ' Ergebniswette 2. Periode',
        101 : ' Unter / Über - Home Team',
        102 : ' Unter / Über - Gastmannschaft',
        104 : ' Auswärtsmannschaft gewinnt beide Hälften',
        105 : ' Heimmannschaft gewinnt beide Hälften',
        106 : ' Home Team To Win von hinten',
        107 : ' Gastmannschaft To Win von hinten',
        108 : ' Startseite Mannschaftstor Von A Penalty',
        109 : ' Auswärtsmannschaft Ergebnis aus A Penalty',
        110 : ' Gastmannschaft zu gewinnen mindestens die Hälfte',
        111 : ' Start der Gegner Mindestens die Hälfte',
        113 : ' Beide Teams erzielen ein Tor 1. Halb',
        114 : ' Jeder Spieler Um Score mindestens 2 Tore',
        117 : ' Jeder Spieler, um Ergebnis mindestens 3 Tore',
        122 : ' Team Scoring First To Win Das Spiel',
        123 : ' Gastmannschaft zu den ersten und Gewinnen Das Spiel Ergebnis',
        124 : ' Home Team erzielt zuerst ein Tor und gewinnt das Spiel',
        128 : ' Anzahl Tore',
        129 : ' Unter / Über Ecken - 1. Halb',
        132 : ' Unter / Über Torschüsse',
        133 : ' Unter / Über Total Shots',
        134 : ' Anzahl der Tore 1. Hälfte',
        136 : ' Unter / Über Abseits',
        137 : ' Startseite Gewinnen Keine Wette',
        138 : ' Auswärtssieg Keine Wette',
        139 : ' In welcher Hälfte Home Team zuerst Tor?',
        140 : ' In der die Hälfte der Gastmannschaft zuerst Tor?',
        143 : ' In welcher Hälfte Home Team mehr Tore?',
        144 : ' In welcher Hälfte Auswärts Will Team Mehr Tore?',
        145 : ' Home Team Anzahl Tore in der 1. Hälfte',
        146 : ' Gastmannschaft Anzahl Tore in der 1. Hälfte',
        147 : ' Gastmannschaft Anzahl Tore in der 2. Hälfte',
        148 : ' Home Team Anzahl Tore in der 2. Hälfte',
        149 : ' Home Team Anzahl Tore',
        150 : ' Gastmannschaft Anzahl Tore',
        151 : ' Doppelte Chance zweiten Halb',
        153 : ' Unter / Über 1. Periode - Home Team',
        154 : ' Unter / Über 2. Periode - Home Team',
        155 : ' Unter / Über 1. Periode - Gastmannschaft',
        156 : ' Unter / Über 2. Periode - Gastmannschaft',
        157 : ' Unter / Über Gelben Karten - 1. Halb',
        158 : ' Unter / Über Gelben Karten',
        159 : ' Wird ein Tie-Break im Match?',
        160 : ' Rennen-Sieger',
        161 : ' 10 Minute Ergebnis',
        162 : ' 10 Minute Unter / Über',
        163 : ' Anzahl Tore 2. Halb',
        164 : ' Wird ein Tie-Break im ersten Satz?',
        165 : ' Unter / Über Sets',
        166 : ' Unter / Über Spiele',
        168 : ' Will Be ein Eigentor?',
        169 : ' Um in beiden Hälften',
        170 : ' Gerade / Ungerade Corners',
        171 : ' Um Gewinnt irgendeine Halb',
        175 : ' Wird ein Tie-Break im zweiten Satz?',
        176 : ' Wird ein Tie-Break im dritten Satz?',
        177 : ' Wird ein Tie-Break im vierten Satz?',
        178 : ' Wird ein Tie-Break im fünften Satz?',
        180 : ' Unter / Über Einwürfen',
        181 : ' Unter / Über Gelben Karten - Home Team',
        182 : ' Unter / Über Gelben Karten - Home Team 1. Halb',
        183 : ' Unter / Über Gelben Karten - Home Team 2. Halb',
        184 : ' Unter / Über Gelben Karten - Gastmannschaft',
        185 : ' Unter / Über Gelben Karten - Gastmannschaft 1. Halb',
        186 : ' Unter / Über Gelben Karten - Gastmannschaft 2. Halb',
        187 : ' Unter / Über Gelben Karten - die 2. Halbzeit',
        188 : ' Gelbe Karte: ',
        190 : ' Erste Gelbe Karte für die 2. Halbzeit',
        191 : ' Jeder möglicher Spieler einen Hat-Trick Score?',
        192 : ' Wird ein Tor in beiden Hälften erzielt?',
        194 : ' Home Team, um ein sauberes Blatt 1. Zeitraum zu halten',
        195 : ' Home Team, um ein sauberes Blatt 2. Periode zu Halten',
        196 : ' Keep Away Team zu einem Gegentor 1. Drittel',
        197 : ' Keep Away Team zu einem Gegentor 2. Periode',
        198 : ' Gerade / Ungerade - Home Team',
        199 : ' Gerade / Ungerade - Gastmannschaft',
        200 : ' Wird das erste Scoring-Team Win?',
        201 : ' Asian Handicap Spiele',
        202 : ' 1. Drittel Gewinner Heim / Auswärts',
        203 : ' 2. Periode Gewinner Heim / Auswärts',
        204 : ' 3. Periode Gewinner Heim / Auswärts',
        205 : ' 4. Periode Gewinner Heim / Auswärts',
        206 : ' 5. Periode Gewinner Heim / Auswärts',
        207 : ' 15 Minute Ergebnis',
        208 : ' 30 Minute Ergebnis',
        209 : ' Gesamtanzahl Ecken - 1. Halb',
        210 : ' Gesamtanzahl Ecken - 2. Halb',
        211 : ' Beide Teams erzielen ein Tor 2. Halb',
        212 : ' Beiden Teams in beiden Hälften Erzielt',
        213 : ' Zeichnen Sie in beiden Hälften',
        214 : ' Unter / Über-Karten',
        215 : ' Gastmannschaft 1. Halb zum Spiel',
        216 : ' Auswärts-Team 2. Halb zum Spiel',
        217 : ' Korrektes Ergebnis 3. Periode',
        218 : ' Home Team zum Spiel 1. Halb',
        219 : ' Home Team zum Spiel 2. Halb',
        220 : ' Unter / Über - Gastmannschaft einschließlich Überstunden',
        221 : ' Unter / Über - Home Team inklusive Verlängerung',
        222 : ' Unter / Über dritten Periode - Gastmannschaft',
        223 : ' Unter / Über dritten Periode - Home Team',
        224 : ' Doppelte Chance dritte Periode',
        225 : ' Doppelte Chance 4. Periode',
        226 : ' 12 einschließlich der Überstunden',
        227 : ' Rennen-Sieger 2 Plätze',
        228 : ' Rennen-Sieger 3 Plätze',
        229 : ' Rennen-Sieger 4 Plätze',
        233 : ' To Win Nicht zu Nil',
        235 : ' 1. 5 Innings Sieger',
        236 : ' 1. 5 Innings Unter / Über',
        237 : ' Wird es eine Punktzahl in der ersten Inning',
        238 : ' verbleibende Spiel',
        239 : ' Tore Bereich',
        242 : ' 1. Periode Gerade / Ungerade - Home Team',
        243 : ' 1. Periode Gerade / Ungerade - Gastmannschaft',
        244 : ' Höchste öffnende Partnerschaft',
        245 : ' Ziel / Kein Tor',
        247 : ' Verbleibende 1. Halb',
        250 : ' Ecken Handicap - 1. Halb',
        254 : ' Karten Bereich',
        255 : ' Zeitpunkt der 1.-Karte',
        256 : ' Home Team - Karten Bereich',
        257 : ' Gastmannschaft - Karten-Strecke',
        258 : ' Corners Bereich',
        259 : ' Team mit den meisten Ecken',
        260 : ' Team mit den meisten ersten Halb Corners',
        261 : ' Team mit den meisten 2. Halb Corners',
        262 : ' Home Team - Corners Bereich',
        263 : ' Gastmannschaft - Corners Bereich',
        264 : ' Home Team - 1. Halb Corners Bereich',
        265 : ' Gastmannschaft - 1. Halb Corners Bereich',
        266 : ' Home Team - 2. Halb Corners Bereich',
        267 : ' Gastmannschaft - 2. Halb Corners Bereich',
        269 : ' Tore Bereich 1. Periode',
        271 : ' Chance Mix',
        274 : ' Sieger',
        275 : ' Tore Bereich 2. Periode',
        278 : ' Tore Bereich Home Team',
        279 : ' Tore Reichweite Auswärts-Team',
        281 : ' 1. 5 Innings Asian Handicap',
        282 : ' 1X2 Halbzeit',
        283 : ' Asian Handicap 2. Hälfte',
        284 : ' 1X2 2. Halb',
        285 : ' Gerade / Ungerade 2. Halb',
        286 : ' 12 2. Halb',
        287 : ' Unter / Über 4. Periode - Home Team',
        288 : ' Unter / Über 4. Periode - Gastmannschaft',
        289 : ' 2. Periode Gerade / Ungerade - Home Team',
        290 : ' 3. Periode Gerade / Ungerade - Home Team',
        291 : ' 4. Periode Gerade / Ungerade - Home Team',
        292 : ' 2. Periode Gerade / Ungerade - Gastmannschaft',
        293 : ' 3. Periode Gerade / Ungerade - Gastmannschaft',
        294 : ' 4. Periode Gerade / Ungerade - Gastmannschaft',
        297 : ' SP',
        299 : ' Punkt Gewinner',
        300 : ' Spiel Sieger',
        301 : ' Tor Vor',
        302 : ' Ziel Nach',
        304 : ' Team mit höchster Punktzahl Period',
        305 : ' Ecken - Unter / Genau / Über',
        306 : ' Erstes Tor Letztes Tor',
        307 : ' European Handicap Spiele',
        308 : ' Unter / über höchsten Punktzahl Period',
        309 : ' Unter / Über Niedrigste Punkten Zeitraum',
        310 : ' Series Gewinner',
        317 : ' Unter / Genau / Über - 1. Periode',
        318 : ' Unter / Genau / Über - 2. Periode',
        319 : ' Unter / Genau / Über - 3. Periode',
        320 : ' Unter / Genau / Über - 4. Periode',
        322 : ' Unter / Genau / Über',
        329 : ' 1. Periode Race To',
        330 : ' 2. Periode Race To',
        331 : ' 3. Periode Race To',
        332 : ' 4. Periode Race To',
        333 : ' 5. Periode Race To',
        337 : ' Unter / Über - 2. Halb',
        338 : ' Nächstes Tor 1. Drittel',
        339 : ' Nächstes Ziel der 2. Periode',
        341 : ' Race To',
        342 : ' Asian Handicap inklusive Verlängerung',
        343 : ' Spielbereich 1. Drittel',
        344 : ' Spielbereich 2. Periode',
        345 : ' Spielbereich dritte Periode',
        346 : ' Spielbereich 4. Periode',
        347 : ' Spielbereich 5. Periode',
        348 : ' 6. Periode Gewinner',
        349 : ' 7. Periode Gewinner',
        350 : ' 6. Periode Gerade / Ungerade',
        351 : ' 7. Periode Gerade / Ungerade',
        352 : ' Unter / Über 6. Periode',
        353 : ' Unter / Über 7. Periode',
        354 : ' Unter / Über Halbzeit - Home Team',
        355 : ' Unter / Über Halbzeit - Auswärts-Team',
        356 : ' Verfahren Entlassungs 6-Way',
        357 : ' Entlassungs Methode',
        366 : ' Läuft Gerade / Ungerade in Over - Home Team',
        367 : ' Insgesamt Läuft Gerade / Ungerade in Over - Gastmannschaft',
        370 : ' Gesamtanzahl Runs in Over - Home Team',
        371 : ' Insgesamt Runs in Over - Auswärts-Team',
        386 : ' European Handicap inklusive Verlängerung',
        387 : ' Gerade / Ungerade - Home Team inklusive Verlängerung',
        388 : ' Gerade / Ungerade - Gastmannschaft einschließlich Überstunden',
        390 : ' HT / FT inklusive Verlängerung',
        391 : ' Nächstes Tor - Extra Time',
        392 : ' Home Team Penalty Schiessen',
        393 : ' Gastmannschaft Elfmeterschiessen',
        394 : ' Korrektes Ergebnis 4. Periode',
        395 : ' Korrektes Ergebnis 5. Periode',
        398 : ' Gerade / Ungerade-Karten',
        400 : ' Gerade / Ungerade-Karten 1. Halb',
        401 : ' Unter / Über Corners 1. Halb - Home Team',
        402 : ' Unter / Über Corners 1. Halb - Gastmannschaft',
        403 : ' Unter / Über Corners 2. Halb',
        404 : ' Unter / Über Corners 2. Halb - Home Team',
        405 : ' Unter / Über Corners 2. Halb - Gastmannschaft',
        406 : ' Gerade / Ungerade Ecken 1. Halb',
        407 : ' Asian Handicap Karten',
        408 : ' Asian Handicap Karten 1. Halb',
        409 : ' 1X2 Corners',
        410 : ' 1X2 Corners 1. Halb',
        411 : ' 12 Corners',
        414 : ' Unter / Über-Karten 1. Halb',
        415 : ' 1X2 Und Beide Teams erzielen ein Tor',
        416 : ' Unter / Über - Extra Time',
        417 : ' Unter / Über - Extra Time 1. Halb',
        419 : ' 1X2 - Extra Time',
        427 : ' 1X2 und Unter / Über',
        428 : ' Mehrere Ergebniswette',
        430 : ' Unter / Genau / Über - 1. Halb',
        431 : ' Unter / Genau / Über - 2. Halb',
        433 : ' European Handicap Corners',
        434 : ' 8. Periode Gewinner',
        435 : ' 9. Periode Gewinner',
        436 : ' Unter / Über 8. Periode',
        437 : ' Unter / Über 9. Periode',
        438 : ' Insgesamt Läuft Gerade / Ungerade in Over',
        439 : ' Insgesamt Läuft im Over',
        445 : ' 6. Periode Gewinner Heim / Auswärts',
        446 : ' 7. Periode Gewinner Heim / Auswärts',
        447 : ' Asian Handicap 6. Periode',
        448 : ' Asian Handicap 7. Periode',
        449 : ' Asian Handicap 8. Periode',
        450 : ' Asian Handicap 9. Periode',
        451 : ' 8. Periode Gewinner Heim / Auswärts',
        452 : ' 9. Periode Gewinner Heim / Auswärts',
        453 : ' Erste Punkte-Methode',
        454 : ' Erste Punkte-Methode - Home Team',
        455 : ' Erste Punkte-Methode - der Gastmannschaft',
        456 : ' Doppelte Chance 1. Periode',
        457 : ' Doppelte Chance zweite Periode',
        459 : ' Home Team in jedem Quartal zum Spiel',
        460 : ' Gastmannschaft in jedem Quartal zum Spiel',
        462 : ' Top 5 Gewinner',
        463 : ' Top 10 Gewinner',
        464 : ' 12 2. Hälfte einschließlich der Überstunden',
        465 : ' 4. Periode Gewinner Heim / Auswärts Inklusive Verlängerung',
        466 : ' Unter / Über 4. Zeitraum inklusive Verlängerung',
        467 : ' Asian Handicap 4. Periode inklusive Verlängerung',
        468 : ' Asian Handicap 2. Hälfte einschließlich der Überstunden',
        469 : ' Unter / Über 2. Halbzeit einschließlich der Überstunden',
        472 : ' Will Spiel Zum Schiessen?',
        475 : ' Unter / Über-Läufe in Over - Home Team 1. Inning',
        476 : ' Unter / Über-Läufe in Over - Gastmannschaft 1. Inning',
        477 : ' Unter / Über-Läufe in Over - Home Team 2. Inning',
        478 : ' Unter / Über-Läufe in Over - Auswärts Team 2. Inning',
        479 : ' Odd / Runs Even In Over - Home Team 1. Inning',
        480 : ' Odd / Runs Even In Over - Gastmannschaft 1. Inning',
        481 : ' Odd / Runs Even In Over - Home Team 2. Inning',
        482 : ' Odd / Runs Even In Over - Gastmannschaft 2. Inning',
        483 : ' Will Home Team A Wicket in Over Get - 1. Inning?',
        484 : ' Will der Gastmannschaft Get A Wicket in Over - 1. Inning?',
        485 : ' Will Home Team A Wicket in Over Get - 2. Inning?',
        486 : ' Will der Gastmannschaft Get A Wicket in Over - 2. Inning?',
        523 : ' Unter / Über und beide Teams erzielen ein Tor',
        524 : ' 1. 7 Innings Sieger',
        525 : ' 1. 7 Innings Unter / Über',
        526 : ' 1. 7 Innings Asian Handicap',
        529 : ' Gerade / Ungerade 2. Halb einschließlich Überstunden',
        553 : ' Unter / Über Shootout Penalties Taken',
        554 : ' 12 Shootout',
        555 : ' Asian Handicap Shootout',
        556 : ' Genaues Ergebnis Schiessen',
        557 : ' Unter / Über Schiessen',
        558 : ' Unter / Über Shootout - Home Team',
        559 : ' Unter / Über Shootout - Auswärts-Team',
        560 : ' Team To Take Last Shootout Penalty',
        563 : ' Race To einschließlich Überstunden',
        564 : ' 1X2 und Unter / Über 5-Wege',
        566 : ' 10. Zeitraum Gewinner Heim / Auswärts',
        567 : ' 11. Zeitraum Gewinner Heim / Auswärts',
        568 : ' 12. Periode Gewinner Heim / Auswärts',
        569 : ' 13. Zeitraum Gewinner Heim / Auswärts',
        570 : ' 14. Zeitraum Gewinner Heim / Auswärts',
        571 : ' 15. Zeitraum Gewinner Heim / Auswärts',
        573 : ' 16. Zeitraum Gewinner Heim / Auswärts',
        574 : ' 17. Zeitraum Gewinner Heim / Auswärts',
        575 : ' 18. Zeitraum Gewinner Heim / Auswärts',
        576 : ' 19. Periode Gewinner Heim / Auswärts',
        577 : ' 10 Minute Unter / Über - Corners',
        578 : ' 10 Minute Unter / Über - Karten',
        579 : ' Weiter Corner',
        586 : ' Zum Beenden des Vorgangs in der oberen Hälfte',
        587 : ' Zum Fertigstellen In Untere Hälfte',
        588 : ' Zum Fertigstellen Bottom',
        589 : ' Nicht zu beenden In Top 4',
        590 : ' Um Absteigende',
        591 : ' Nicht zu Absteiger',
        592 : ' Zu erreichen Finale',
        593 : ' Zum Fertigstellen In Bottom 6',
        594 : ' Befördert werden',
        595 : ' Erste Goal-Zeit',
        596 : ' Tore Bereich Home Team erste Periode',
        597 : ' Tore Bereich Home Team der 2. Periode',
        598 : ' Tore Reichweite Gastmannschaft ersten Periode',
        599 : ' Tore Reichweite Auswärts-Team der 2. Periode',
        600 : ' 1. Drittel Gewinner und beide Teams erzielen ein Tor',
        601 : ' HT / FT Doppelte Chance',
        602 : ' Korrektes Ergebnis inklusive Verlängerung',
        603 : ' Negative HT / FT',
        604 : ' Tore Bereich Home Team Beide Perioden',
        605 : ' Tore Range zu Fuss Beide Perioden von Team',
        606 : ' Sieger und Tore Typ Eins',
        607 : ' Doppelte Chance und Tore',
        608 : ' Sieger und Tore Typ Zwei',
        609 : ' Beide Teams erzielen ein Tor und Tore',
        610 : ' Doppelte Chance Mix',
        628 : ' Zum Fertigstellen In Top 7',
        629 : ' Zum Fertigstellen In Top 6',
        630 : ' Zum Fertigstellen In Top 3',
        631 : ' Zum Fertigstellen In Top 2',
        632 : ' Zum Fertigstellen In Top 4',
        633 : ' Zum Fertigstellen In Top 5',
        634 : ' Ungerade / Runden Auch erste Periode',
        635 : ' Ungerade / Runden Auch die 2. Periode',
        636 : ' Ungerade / Runden Auch dritte Periode',
        637 : ' Wird es ein Ass?',
        638 : ' Wird es ein Messer töten?',
        639 : ' 1. Runde Gewinner',
        640 : ' 16. Runde Gewinner',
        641 : ' Wird es ein Messer zu töten sein? - 1. Zeitraum',
        642 : ' Wird es ein Messer zu töten sein? - 2. Periode',
        643 : ' Wird es ein Messer zu töten sein? - 3. Periode',
        644 : ' Wird es ein Ass? - 1. Zeitraum',
        645 : ' Wird es ein Ass? - 2. Periode',
        646 : ' Wird es ein Ass? - 3. Periode',
        647 : ' Wird es Verlängerung geben? - 1. Zeitraum',
        648 : ' Wird es Verlängerung geben? - 2. Periode',
        649 : ' Wird es Verlängerung geben? - 3. Periode',
        650 : ' 16. Runde Gewinner - 1. Periode',
        651 : ' 16. Runde Gewinner - 2. Periode',
        652 : ' 16. Runde Gewinner - 3. Periode',
        653 : ' 1. Runde Gewinner - 1. Periode',
        654 : ' 1. Runde Gewinner - 2. Periode',
        655 : ' 1. Runde Gewinner - 3. Periode',
        660 : ' Spieldauer',
        662 : ' Gerade / Ungerade Runden',
        663 : ' 1. Periodendauer',
        664 : ' 2. Periodendauer',
        665 : ' 3. Periodendauer',
        666 : ' 1. Periode - 1. Roshan',
        667 : ' 2. Periode - 1. Roshan',
        668 : ' 3. Periode - 1. Roshan',
        669 : ' 1. Periode - 1. Blut',
        670 : ' 2. Periode - 1. Blut',
        671 : ' 3. Periode - 1. Blut',
        672 : ' 1. Periode - 1. tou',
        673 : ' 2. Periode - 1. tou',
        674 : ' 3. Periode - 1. tou',
        675 : ' 1. Periode - 1. Kaserne',
        677 : ' 2. Periode - 1. Kaserne',
        678 : ' 3. Periode - 1. Kaserne',
        679 : ' 1. Periode - 1. Turret',
        680 : ' 2. Periode - 1. Turret',
        681 : ' 3. Periode - 1. Turret',
        682 : ' Race To Kills - 1. Periode',
        683 : ' Race To Kills - 2. Periode',
        684 : ' Race To Kills - 3. Periode',
        685 : ' 1. Periode - Beide Teams einen Drachen zu töten',
        686 : ' 2. Periode - Beide Teams einen Drachen zu töten',
        687 : ' 3. Zeit - Beide Teams einen Drachen zu töten',
        688 : ' 1. Periode - Beide Teams Inhibitor zu töten',
        689 : ' 2. Periode - Beide Teams Inhibitor zu töten',
        690 : ' 3. Zeit - Beide Teams Inhibitor zu töten',
        691 : ' Gerade / Ungerade Kills',
        692 : ' Gerade / Ungerade Kills - 1. Periode',
        693 : ' Gerade / Ungerade Kills - 2. Periode',
        694 : ' Gerade / Ungerade Kills - 3. Periode',
        695 : ' HT / FT und Tore',
        697 : ' Zum Fertigstellen In Top 8',
        711 : ' Spieler Erzielt In Immer',
        712 : ' Erster Spieler Score',
        713 : ' Letzter Spieler Score',
        714 : ' Spieler Erzielt 2 oder mehr Tore',
        715 : ' Spieler erzielen ein Tor 3 oder mehr Tore',
        727 : ' 1. Periode Beide Teams erzielen ein Tor',
        728 : ' 2. Periode Beide Teams erzielen ein Tor',
        729 : ' 3. Periode Beide Teams erzielen ein Tor',
        730 : ' 4. Periode Beide Teams erzielen ein Tor',
        731 : ' 1. Periode Startseite Mannschaftstor',
        732 : ' 2. Periode Heim Mannschaftstor',
        733 : ' 3. Periode Heim Mannschaftstor',
        734 : ' 4. Periode Startseite Mannschaftstor',
        735 : ' 1. Periode Auswärts Mannschaftstor',
        736 : ' 2. Periode Auswärts Mannschaftstor',
        737 : ' 3. Periode Auswärts Mannschaftstor',
        738 : ' 4. Periode Auswärts Mannschaftstor',
        751 : ' 1. Halb Race To',
        752 : ' 2. Halb Race To - inklusive Verlängerung',
        754 : ' Spiel auf Deuce',
        755 : ' Extra Time Corners - Unter / Genau / Über',
        756 : ' Unter / Over- Extra Time Corners',
        757 : ' Asian Handicap - Extra Time',
        758 : ' 10 Minute Unter / Über - Freistöße',
        759 : ' 10 Minute Under / Over - Einwürfe',
        760 : ' 10 Minute Under / Over - Ziel Kicks',
        762 : ' To Win The Toss',
        763 : ' Unter / Über-Läufe in Spiel',
        765 : ' Erzielt meisten Fours',
        766 : ' Unter / Über - Spiel Fours',
        767 : ' Unter / Über - Match Sixes',
        768 : ' Ein fünfzig erzielte im Match',
        769 : ' Hundert Jahre alt werden im Match Erzielte',
        770 : ' Die meisten Run Outs',
        791 : ' Top Batsman - Home Team',
        792 : ' Top Batsman - Gastmannschaft',
        793 : ' Top-Werfer - Home Team',
        794 : ' Top-Werfer - Gastmannschaft',
        795 : ' Mann des Spiels',
        806 : ' Batsman Erzielt eine Fünfzig im Match',
        808 : ' Rennen um 10 Runs',
        809 : ' Höchste 1 6 Overs Ergebnis',
        813 : ' Spieler Erzielt meisten Sixes',
        814 : ' Höchstes Einzel Score',
        819 : ' Um eine Pause Punkt im Spiel',
        820 : ' Rennen zu Corners',
        822 : ' Die besten neuen Teams',
        824 : ' Spieler gebucht werden',
        825 : ' Spieler abgeschickt werden',
        834 : ' Extra Punkte Set',
        835 : ' Asian Unter / Über',
        836 : ' Asian Unter / Über 1. Drittel',
        837 : ' 1. Halb Home Team gewinnt zu null',
        838 : ' 1. Halbgastmannschaft gewinnt zu null',
        839 : ' 1. Halb 1X2 und Unter / Über',
        840 : ' 1. Periode Home Team gewinnt zu null',
        841 : ' 1. Zeitraum der Gastmannschaft gewinnt zu null',
        842 : ' Unter / Genau / Über - Home Team',
        843 : ' Unter / Genau / Über - Gastmannschaft',
        844 : ' Erste Mannschaftstor - Heim / Auswärts',
        845 : ' Welches Team Anruf First Time Out',
        846 : ' Unter / Über Hits - inklusive Überstunden',
        849 : ' Unter / Über fünften Inning',
        851 : ' Team mit höchster Punktzahl Hälfte',
        852 : ' Entweder Tor? 3 Unbeantw.Rufe Zeiten',
        853 : ' 12 und Unter / Über',
        854 : ' Spezielle Teams oder Defensive Landungs ​​Erzielte',
        855 : ' Punkte Bereich',
        859 : ' 147 Pause Im Spiel',
        861 : ' Erste Führung Methode 3 Way - Home Team',
        862 : ' Erste Punkte Methode 3 Way - Gastmannschaft',
        863 : ' Team mit höchsten Punktzahl Zeitraum -1X2',
        864 : ' Team Erster Torschütze',
        865 : ' Team Letzter Torschütze',
        866 : ' Asian Handicap Sets',
        870 : ' Erste Führung Methode 6 Way Home / Auswärts',
        878 : ' Tore Bereich dritte Periode',
        879 : ' Unter / Über Tries',
        880 : ' Erste Halbzeit Ecken - Unter / Genau / Über',
        881 : ' Erste Goal-Methode',
        882 : ' Anzahl der Punkte',
        891 : ' Team Longest Touchdown erzielen',
        892 : ' Welches Team Longest Field Goal Ergebnis',
        893 : ' Längste Landungs ​​Erzielte',
        894 : ' Kürzeste Landungs ​​Erzielte',
        895 : ' Längste Field Goal Erzielte',
        896 : ' Time Of First Team Goal',
        897 : ' Spiel Score Nach 2 Punkte',
        898 : ' Spiel Ergebnis nach 3 Punkte',
        899 : ' Spiel Ergebnis nach 4 Punkten',
        900 : ' Spiel um Punkte',
        901 : ' 1. Periode Führung nach',
        902 : ' 2. Periode Führung nach',
        903 : ' 3. Periode Führung nach',
        904 : ' 4. Periode Führung nach',
        905 : ' 5. Periode Führung nach',
        906 : ' Ergebnis nach 4 Spielen',
        907 : ' Ergebnis Nach 6 Spielen',
        908 : ' Zur Pause Serve In Set',
        909 : ' Home To Win glatt in zwei Sätzen',
        910 : ' Auswärts zu gewinnen glatt in zwei Sätzen',
        911 : ' Ergebnis nach 2 Sets',
        912 : ' Ergebnis nach 3 Sets',
        914 : ' Unter / Über Feld der geschossenen Tore - 1. Halb',
        915 : ' Unter / Über Touchdowns Tore - 1. Halb',
        916 : ' Unter / Über Home Team Touchdowns - 1. Halb',
        917 : ' Unter / Über Gastmannschaft Touchdowns - 1. Halb',
        920 : ' Unter / Über Touchdowns',
        921 : ' Unter / Genau / Über - Touchdowns',
        922 : ' Unter / Über Field Goals',
        923 : ' Unter / Genau / Über - Field Goals',
        924 : ' Zeitpunkt der ersten Field Goal Erzielte',
        925 : ' Zeitpunkt der ersten Touchdown Erzielte',
        926 : ' Zeitpunkt der ersten Score',
        927 : ' Home Team Unter / Über Touchdowns',
        928 : ' Gastmannschaft Unter / Über Touchdowns',
        929 : ' Home Team Under / Over Field Goals',
        930 : ' Gastmannschaft Unter / Über Field Goals',
        932 : ' Team zu haben erste Trainer Herausforderung',
        933 : ' Team zum über Erste Accepted Penalty',
        934 : ' Sicherheit Erzielte',
        935 : ' Europäische Karten Handicap',
        936 : ' Asian Unter / Über-Karten',
        938 : ' Unter / Über-Karten - Home Team',
        939 : ' Unter / Über-Karten - Gastmannschaft',
        940 : ' Zeitpunkt der ersten Karte',
        941 : ' Weiter Spieler Um Score',
        942 : ' Top Nationalität - Asien',
        943 : ' Top Nationalität - Australian',
        944 : ' Top Nationalität - Europäische',
        945 : ' Top Nationalität - Großbritannien & Irland',
        946 : ' Top Nationalität - Rest der Welt',
        947 : ' Unter / Über Asse',
        948 : ' Unter / Über Aces - Home Team',
        949 : ' Genaue Zahl der Asse',
        950 : ' Unter / Über Aces - Gastmannschaft',
        951 : ' Korrekte Anzahl der Asse',
        955 : ' 1X2 Aces',
        956 : ' Genaue Zahl der Aces - Home Team',
        957 : ' Genaue Zahl der Asse - Auswärts-Team',
        958 : ' Aces Asian Handicap',
        959 : ' Race To Asse',
        960 : ' Unter / Über Aces - 1. Periode',
        976 : ' 1X2 Aces - 1. Periode',
        982 : ' Aces Asian Handicap - 1. Periode',
        986 : ' 12 Asse',
        989 : ' Unter / Über Kills - 1. Periode',
        990 : ' Unter / Über Kills - 2. Periode',
        991 : ' Unter / Über Kills - 3. Periode',
        992 : ' Weiter Landungs-Scorer - Home Team',
        993 : ' Weiter Landungs-Scorer - Auswärts-Team',
        994 : ' Weiter Landungs-Scorer',
        995 : ' Player Um Score Landungs-In jederzeit',
        996 : ' Spieler Zum Erzielt 2 oder mehr Touchdowns',
        997 : ' Spieler erzielen ein Tor 3 oder mehr Touchdowns',
        998 : ' Erstes Spiel Corner - 12',
        999 : ' Letztes Spiel Corner - 12',
        1000 : ' Anzahl der Sätze',
        1001 : ' Unter / Über Doppelfehler',
        1002 : ' Unter / Über Doppelfehler - Gastmannschaft',
        1003 : ' Unter / Über Doppelfehler - Home Team',
        1004 : ' Unter / Über Doppelfehler - 1. Periode',
        1010 : ' 12 Doppelfehler',
        1011 : ' 12 Doppelfehler - 1. Periode',
        1013 : ' 1X2 Doppelte Fehler',
        1016 : ' Genaue Anzahl der Doppelfehler',
        1019 : ' Genaue Anzahl der Doppelfehler - Home Team',
        1020 : ' Genaue Zahl der Doppelfehler - Gastmannschaft',
        1027 : ' Korrekte Anzahl der Doppelfehler',
        1030 : ' Zeitpunkt der ersten Ecke',
        1031 : ' mehrere Ecken',
        1034 : ' 20. Zeitraum Gewinner Heim / Auswärts',
        1035 : ' 21. Zeitraum Gewinner Heim / Auswärts',
        1036 : ' 22. Zeitraum Gewinner Heim / Auswärts',
        1037 : ' 23. Zeitraum Gewinner Heim / Auswärts',
        1038 : ' 24. Zeitraum Gewinner Heim / Auswärts',
        1039 : ' 25. Zeitraum Gewinner Heim / Auswärts',
        1040 : ' 26. Zeitraum Gewinner Heim / Auswärts',
        1041 : ' 27. Zeitraum Gewinner Heim / Auswärts',
        1042 : ' 28. Zeitraum Gewinner Heim / Auswärts',
        1043 : ' 29. Zeitraum Gewinner Heim / Auswärts',
        1044 : ' 30. Zeitraum Gewinner Heim / Auswärts',
        1046 : ' 32th Zeitraum Gewinner Heim / Auswärts',
        1047 : ' 33th Zeitraum Gewinner Heim / Auswärts',
        1050 : ' Rennen um 20 Punkte',
        1051 : ' Unter / Über 2. Halbzeit - Home Team',
        1052 : ' Unter / Über 2. Halbzeit - Gastmannschaft',
        1053 : ' Asian Unter / Über 2. Periode',
        1054 : ' Asian Unter / Über dritten Periode',
        1055 : ' Asian Unter / Über 4. Periode',
        1061 : ' Asian Unter / Über einschließlich Überstunden',
        1062 : ' Hälfte mit höchster Punktzahl - Home Team',
        1063 : ' Hälfte mit höchster Punktzahl - Gastmannschaft',
        1065 : ' Home Team - Erster Torschütze',
        1066 : ' Home Team - Letzter Torschütze',
        1067 : ' Gastmannschaft - Erster Torschütze',
        1068 : ' Gastmannschaft - Letzter Torschütze',
        1069 : ' Unter / Über Player Points',
        1070 : ' Unter / Über-Spieler Blocks',
        1071 : ' Unter / Über-Spieler Assists',
        1072 : ' Unter / Über-Spieler Rebounds',
        1073 : ' Unter / Über-Spieler Steals',
        1074 : ' Unter / Über-Player Turnovers',
        1075 : ' Unter / Über-Spieler 3 Punkte gemacht',
        1076 : ' Unter / Über-Spieler 3 Punkte Versuchte',
        1077 : ' Unter / Über Spieler 2 Punkte gemacht',
        1078 : ' Unter / Über Spieler 2 Punkte Versuchte',
        1079 : ' Unter / Über-Spieler Freiwürfe gemacht',
        1080 : ' Unter / Über-Spieler Freiwürfe Versuchte',
        1081 : ' 1X2 Gelbe Karten',
        1082 : ' Doppelte Chance Gelbe Karten',
        1083 : ' Asian Handicap Gelbe Karten',
        1084 : ' Korrektes Ergebnis Gelbe Karten',
        1085 : ' Unter / Über Roten Karten',
        1086 : ' Unter / Genau / Über Buchung Punkte',
        1087 : ' Unter / Genau / Über Buchung Punkte - Home Team',
        1088 : ' Unter / Genau / Über Buchung Punkte - Gastmannschaft',
        1089 : ' Unter / Über-Spieler Steals und Blocks',
        1090 : ' Unter / Über Player Points',
        1091 : ' Unter / Über-Spieler Assists und Rebounds',
        1092 : ' Unter / Über Player Points und prallt',
        1093 : ' Unter / Über Player Points und Vorlagen',
        1094 : ' Spieler Double Double',
        1095 : ' Spieler Triple Double',
        1096 : ' Unter / Genau / Über Buchung Punkte - 1. Drittel',
        1100 : ' Set Gewinner',
        1101 : ' Unter / Über 180 \ s in einem Satz',
        1102 : ' Set / Leg Gewinner',
        1103 : ' Set / Leg Gesamt Auscheckvorgänge Unter / Über',
        1105 : ' Unter / Über Total 180 \ s - Home Team',
        1106 : ' Unter / Über Total 180 \ s - Gastmannschaft',
        1112 : ' Unter / Über Spieler Tore',
        1115 : ' Unter / Über Spieler Tore erlaubt',
        1120 : ' Unter / Über Runden - 1. Periode',
        1121 : ' Unter / Über Runden - 2. Periode',
        1122 : ' Unter / Über Runden - 3. Periode',
        1123 : ' 4. Periode - 1. Blut',
        1124 : ' 5. Periode - 1. Blut',
        1125 : ' Beide Teams To Kill a Drachen',
        1126 : ' Beide Teams To Kill a Baron',
        1127 : ' Beide Teams To Kill a Inhibitor',
        1128 : ' Unter / Über Barons getöteten',
        1129 : ' Unter / Über Barons getöteten - 1. Periode',
        1130 : ' Unter / Über Barons getöteten - 2. Periode',
        1131 : ' Unter / Über Barons getöteten - 3. Periode',
        1132 : ' Unter / Über Dragons getöteten',
        1133 : ' Unter / Über Dragons getöteten - 1. Periode',
        1134 : ' Unter / Über Dragons getöteten - 2. Periode',
        1135 : ' Unter / Über Dragons getöteten - 3. Periode',
        1136 : ' Unter / Über 180 \ s',
        1137 : ' Erst 180',
        1138 : ' Letzte 180',
        1139 : ' Unter / Über Höchster Checkout Score',
        1140 : ' Die meisten 180 \ s',
        1141 : ' Unter / Über Höchster Checkout Score - Home Team',
        1142 : ' Unter / Über Höchster Checkout Score - Gastmannschaft',
        1143 : ' Erster Spieler Score Landungs',
        1144 : ' Letzte Spieler Score Landungs',
        1145 : ' Erster Spieler Erzielt Landungs ​​- Home Team',
        1146 : ' Erster Spieler Erzielt Landungs ​​- Gastmannschaft',
        1147 : ' Unter / Über Kills - 4. Periode',
        1148 : ' Unter / Über Kills - 5. Periode',
        1149 : ' Asian Handicap Kills - 1. Periode',
        1150 : ' Asian Handicap Kills - 2. Periode',
        1151 : ' Asian Handicap Kills - 3. Periode',
        1152 : ' Asian Handicap Kills - 4. Periode',
        1153 : ' Asian Handicap Kills - 5. Periode',
        1154 : ' Unter / Genau / Über 180 \ s',
        1155 : ' Unter / Genau / Über Total 180 \ s - Home Team',
        1156 : ' Unter / Genau / Über Total 180 \ s - Gastmannschaft',
        1157 : ' Die meisten Auscheckvorgänge',
        1158 : ' Erster Versuch Scorer',
        1159 : ' Last Try Scorer',
        1160 : ' Immer Scorer Versuchen',
        1162 : ' Unter / Über tous zerstört - 1. Periode',
        1163 : ' Unter / Über tous zerstört - 2. Periode',
        1164 : ' Unter / Über tous zerstört - 3. Periode',
        1165 : ' 1. Periode - 1. Baron',
        1166 : ' 2. Periode - 1. Baron',
        1167 : ' 3. Periode - 1. Baron',
        1168 : ' 4. Periode - 1. Baron',
        1169 : ' 5. Periode - 1. Baron',
        1170 : ' 1. Periode - 1. Drachen',
        1171 : ' 2. Periode - 1. Drachen',
        1172 : ' 3. Periode - 1. Drachen',
        1173 : ' 4. Periode - 1. Drachen',
        1174 : ' 5. Periode - 1. Drachen',
        1175 : ' Die meisten Kills - 1. Periode',
        1176 : ' Die meisten Kills - 2. Periode',
        1177 : ' Die meisten Kills - 3. Periode',
        1178 : ' Die meisten Kills - 4. Periode',
        1179 : ' Die meisten Kills - 5. Periode',
        1180 : ' Die meisten Kills - 6. Periode',
        1181 : ' Die meisten Kills - 7. Periode',
        1182 : ' Spieler Zum Erzielt 2 oder mehr Trys',
        1183 : ' Spieler erzielen ein Tor 3 oder mehr Trys',
        1184 : ' Not To Win The Leauge',
        1185 : ' Unter / Genau / Über einschließlich Überstunden - Home Team',
        1186 : ' Unter / Genau / Über einschließlich Überstunden - Gastmannschaft',
        1187 : ' Unter / Genau / Über einschließlich Überstunden',
        1188 : ' Unter / Über Auswechslungen',
        1189 : ' Unter / Über Auswechslungen - 1. Periode',
        1190 : ' Letzter Spieler Erzielt Landungs ​​- Home Team',
        1191 : ' Letzter Spieler Erzielt Landungs ​​- Gastmannschaft',
        1192 : ' Erster Spieler Hit ein Ass',
        1193 : ' Unter / Über Passing-Spieler Quarterback Yards',
        1194 : ' Unter / Über-Spieler Passing Touchdowns',
        1195 : ' Unter / Über Longest Spieler Pass Completion',
        1196 : ' Unter / Über-Spieler Empfangen Yards',
        1197 : ' Unter / Über-Spieler Interceptions',
        1198 : ' Unter / Über-Spieler treten Punkte',
        1199 : ' Unter / Über-Spieler Rushing & Receiving Yards',
        1200 : ' Unter / Über-Spieler Yards Rushing',
        1201 : ' Unter / Über-Spieler Longest Rezeption',
        1202 : ' Unter / Über-Spieler Empfänge',
        1203 : ' Unter / Über-Spieler Feld Tore',
        1204 : ' Unter / Über Spieler Field Goals Versuchte',
        1205 : ' Unter / Über-Spieler Quarterback Passing Touchdowns',
        1206 : ' Unter / Über-Spieler empfänger Beendigungen',
        1207 : ' Pässe Unter / Über-Spieler Quarterback abgeschlossen',
        1208 : ' Unter / Über-Spieler Rushing Touchdowns',
        1209 : ' Unter / Über-Player Receiver Empfangsversuche',
        1210 : ' Unter / Über-Spieler Quarterback Pässe Versuchte',
        1211 : ' 1X2 Fouls',
        1212 : ' Doppelte Chance Foul',
        1213 : ' Unter / Über Fouls - 1. Periode',
        1214 : ' Unter / Über Fouls - 2. Periode',
        1215 : ' Asian Handicap Fouls',
        1216 : ' Unter / Über Fouls - Home Team',
        1217 : ' Unter / Über Fouls - Gastmannschaft',
        1218 : ' Unter / Über-Spieler Eile Versuche',
        1219 : ' Unter / Über-Spieler Receiving Touchdowns',
        1222 : ' Unter / Über Einwürfen - Home Team',
        1223 : ' Unter / Über Einwürfen - Gastmannschaft',
        1224 : ' 1X2 Einwürfe',
        1225 : ' Doppelte Chance Einwürfe',
        1226 : ' Unter / Über Einwürfen - 1. Periode',
        1227 : ' Einwürfe Handicap',
        1228 : ' Einwürfen Handicap - 1. Periode',
        1229 : ' Unter / Über Torschüsse - Home Team',
        1230 : ' Unter / Über Torschüsse - Gastmannschaft',
        1231 : ' Asian Handicap Torschüsse',
        1232 : ' Asian Handicap Torschüsse - 1. Periode',
        1233 : ' Unter / Über Torschüsse - 1. Periode',
        1234 : ' 1X2 Torschüsse',
        1235 : ' Doppelte Chance Schüsse aufs Tor',
        1236 : ' Unter / Über Total Shots - Home Team',
        1237 : ' Unter / Über Total Schüsse - Gastmannschaft',
        1238 : ' Doppelte Chance Gesamt Schüsse',
        1239 : ' 1X2 Gesamt Schüsse',
        1240 : ' Asian Handicap Gesamt Schüsse',
        1241 : ' Asian Handicap Gesamt Schüsse - 1. Periode',
        1242 : ' Unter / Über Total Shots - 1. Periode',
        1243 : ' Doppelte Chance Corners',
        1244 : ' Doppelte Chance Ecken - 1. Drittel',
        1245 : ' Ecken Von allen 4 Ecken des Spielfeldes',
        1246 : ' Doppelte Chance Gelben Karten - 1. Periode',
        1247 : ' 1X2 Gelbe Karten - 1. Periode',
        1248 : ' Asian Handicap Gelben Karten - 1. Periode',
        1249 : ' Doppelte Chance & Unter / Über',
        1250 : ' Doppelte Chance & Beide Teams erzielen ein Tor',
        1251 : ' 1. Periode - 1. Inhibitor',
        1252 : ' 2. Periode - 1. Inhibitor',
        1253 : ' 3. Periode - 1. Inhibitor',
        1254 : ' 4. Periode - 1. Inhibitor',
        1255 : ' 5. Periode - 1. Inhibitor',
        1256 : ' Beide Teams To Kill a Baron - 1. Periode',
        1257 : ' Beide Teams To Kill a Baron - 2. Periode',
        1258 : ' Beide Teams To Kill a Baron - 3. Periode',
        1259 : ' Beide Teams To Kill a Baron - 4. Periode',
        1260 : ' Beide Teams To Kill a Baron - 5. Periode',
        1261 : ' 4. Zeit - Beide Teams einen Drachen zu töten',
        1262 : ' 5. Zeit - Beide Teams einen Drachen zu töten',
        1263 : ' 4. Zeit - Beide Teams Inhibitor zu töten',
        1264 : ' 5. Zeit - Beide Teams Inhibitor zu töten',
        1265 : ' Wird es ein Ace Nächstes Spiel?',
        1266 : ' Wird es Doppelfehler Nächstes Spiel?',
        1267 : ' Gerade / Ungerade Fouls',
        1268 : ' Asian Handicap Ballbesitz',
        1269 : ' Doppelte Chance Abseits',
        1270 : ' Asian Handicap Abseits',
        1271 : ' Unter / Über Abseits - Home Team',
        1272 : ' Unter / Über Abseits - Gastmannschaft',
        1273 : ' 1X2 Abseits',
        1274 : ' Unter / Über Kills ersten Periode - Home Team',
        1275 : ' Unter / Über Kills zweiten Periode - Home Team',
        1276 : ' Unter / Über Kills dritten Periode - Home Team',
        1277 : ' Unter / Über Kills ersten Periode - Gastmannschaft',
        1278 : ' Unter / Über Kills zweiten Periode - Gastmannschaft',
        1279 : ' Unter / Über Kills dritten Periode - Gastmannschaft',
        1280 : ' Unter / Über Wides',
        1281 : ' Unter / Über Enten',
        1282 : ' Unter / Über Wickets',
        1283 : ' Unter / Über Spiel Fours - Home Team',
        1284 : ' Unter / Über Spiel Sixes - Home Team',
        1285 : ' Unter / Über Spiel Fours - Auswärts-Team',
        1286 : ' Unter / Über Spiel Sixes - Auswärts-Team',
        1287 : ' Unter / Über Extras',
        1288 : ' Herbst 1. Wicket - Home Team',
        1289 : ' Herbst 1. Wicket - Gastmannschaft',
        1290 : ' Team von Top Batsman',
        1291 : ' Unter / Über Spiel Sixes Home Team - 1. Inning',
        1292 : ' Unter / Über Spiel Sixes Gastmannschaft - 1. Inning',
        1293 : ' Unter / Über Spiel Fours Home Team - 1. Inning',
        1294 : ' Unter / Über Spiel Fours Gastmannschaft - 1. Inning',
        1295 : ' Unter / Über Top Batsman',
        1296 : ' 1. Inning 1. Über Dismissal - Home Team',
        1297 : ' 1. Inning 2. Über Dismissal - Home Team',
        1298 : ' 1. Inning 3. Über Dismissal - Home Team',
        1299 : ' 1. Inning 2. Über Dismissal - Gastmannschaft',
        1300 : ' 1. Inning 3. Über Dismissal - Gastmannschaft',
        1301 : ' 1. Inning 1. Over Dismissal - Gastmannschaft',
        1302 : ' Mit höchster Punktzahl Over - Unter / Über',
        1303 : ' 1. Inning 2. Über Unter / Über - Home Team',
        1304 : ' 1. Inning 3. Über Unter / Über - Home Team',
        1306 : ' 1. Inning 3. Über Unter / Über - Gastmannschaft',
        1307 : ' 1. Inning 2. Über Unter / Über - Gastmannschaft',
        1309 : ' Gewinnspanne - Teams',
        1310 : ' Gewinnspanne - Teams einschließlich der Überstunden',
        1312 : ' Gewinnspanne Genaue',
        1314 : ' Gewinnspanne Ranges',
        1315 : ' Gewinnspanne Exact - Teams',
        1316 : ' Gewinnspanne - Teams mit jedem anderen Score',
        1318 : ' Asian Handicap Fouls - 1. Periode',
        1319 : ' Asian Handicap Abseits - 1. Periode',
        1320 : ' Asian Handicap Abseits - 2. Periode',
        1321 : ' Asian Handicap Fouls - 2. Periode',
        1322 : ' Asian Handicap Torschüsse - 2. Periode',
        1324 : ' Unter / Über Abseits - 1. Periode',
        1325 : ' Unter / Über Abseits - 2. Periode',
        1326 : ' Erstes Foul',
        1327 : ' Erste Zwei Pointer',
        1328 : ' Ersten drei Zeiger',
        1329 : ' Letzte zwei Pointer',
        1330 : ' Letzter Drei-Zeiger',
        1331 : ' Zuletzt Freiwurf Erzielte',
        1332 : ' Erster Freiwurf Erzielte',
        1333 : ' Erster Rückprall',
        1334 : ' Letztes Timeout',
        1335 : ' Gewinnspanne Teams - 1. Periode',
        1336 : ' Gewinnspanne Teams - 2. Periode',
        1337 : ' Gewinnspanne Teams - 3. Drittel',
        1338 : ' Gewinnspanne Teams - 4. Periode',
        1339 : ' Gewinnspanne Teams - 5. Periode',
        1340 : ' Letztes Foul',
        1341 : ' Unter / Über 10. Zeitraum',
        1342 : ' Unter / Über 11. Zeitraum',
        1343 : ' Unter / Über 12. Periode',
        1344 : ' Unter / Über 13. Zeitraum',
        1345 : ' Unter / Über 14. Zeitraum',
        1346 : ' Unter / Über 15. Zeitraum',
        1347 : ' Unter / Über 16. Zeitraum',
        1348 : ' Unter / Über 17. Zeitraum',
        1349 : ' Unter / Über 18. Zeitraum',
        1350 : ' Unter / Über 19. Periode',
        1351 : ' Unter / Über 20. Periode',
        1352 : ' Unter / Über 21 Period',
        1353 : ' Unter / Über 22. Zeitraum',
        1354 : ' Unter / Über 23. Zeitraum',
        1355 : ' Unter / Über 24. Zeitraum',
        1356 : ' Unter / Über 25 Period',
        1357 : ' Unter / Über 26. Zeitraum',
        1358 : ' Unter / Über 27. Zeitraum',
        1359 : ' Unter / Über 28. Zeitraum',
        1360 : ' Unter / Über 29. Zeitraum',
        1361 : ' Unter / Über 30. Zeitraum',
        1362 : ' Unter / Über 31. Zeitraum',
        1363 : ' Unter / Über 32. Zeitraum',
        1364 : ' Unter / Über 33. Zeitraum',
        1365 : ' Unter / Über 34th Periode',
        1366 : ' Unter / Über 35. Zeitraum',
        1367 : ' Asian Handicap 11. Periode',
        1368 : ' Asian Handicap 12. Periode',
        1369 : ' Asian Handicap 13. Periode',
        1370 : ' Asian Handicap 14. Periode',
        1371 : ' Asian Handicap 15. Periode',
        1372 : ' Asian Handicap 16. Periode',
        1373 : ' Asian Handicap 17. Periode',
        1374 : ' Asian Handicap 18. Periode',
        1375 : ' Asian Handicap 19. Periode',
        1376 : ' Asian Handicap 20. Periode',
        1380 : ' Asian Handicap 24. Periode',
        1381 : ' Asian Handicap 25. Periode',
        1382 : ' Asian Handicap 26. Periode',
        1383 : ' Asian Handicap 27. Periode',
        1384 : ' Asian Handicap 28. Periode',
        1385 : ' Asian Handicap 29. Periode',
        1386 : ' Asian Handicap 30. Periode',
        1390 : ' Asian Handicap 34. Periode',
        1391 : ' Asian Handicap 35. Periode',
        1392 : ' Zum Fertigstellen in Top 10',
        1393 : ' Aufstehen',
        1394 : ' Asian Handicap 10. Periode',
        1395 : ' Unter / Über-Spieler Powerplay Punkte',
        1396 : ' Unter / Über Player Plus / Minus',
        1397 : ' Unter / Über-Spieler Schüsse',
        1398 : ' Top Victorian Verein',
        1399 : ' Unter / Über Run Outs',
        1400 : ' Unter / Über Frames',
        1401 : ' Top 20 Gewinner',
        1402 : ' 1. Runde Führer',
        1404 : ' 1. Runde Six Shooter - Gruppe A',
        1406 : ' 1. Runde 3 Bälle',
        1407 : ' 1. Runde 18-Loch-Match-Ups',
        1408 : ' Spiel Handicap',
        1409 : ' Team mit Top-Werfer',
        1410 : ' To Win The Toss und das Spiel',
        1411 : ' Unter / Über Turrets Zerstörte',
        1412 : ' Unter / Über Turrets zerstört - 1. Periode',
        1413 : ' Unter / Über Turrets zerstört - 2. Periode',
        1414 : ' Unter / Über Turrets zerstört - 3. Periode',
        1415 : ' Höchste Punktzahl Nach dem 1. Over',
        1416 : ' Home Team Unter / Über Runs - 1. Over bis 6. Over',
        1417 : ' Gastmannschaft Unter / Über Runs - 1. Over bis 6. Over',
        1418 : ' Home Team Unter / Über Runs - 1. Over bis 8. Über',
        1419 : ' Gastmannschaft Unter / Über Runs - 1. Over bis 8. Über',
        1420 : ' Home Team Unter / Über Runs - 1. Over bis 10. Over',
        1421 : ' Gastmannschaft Unter / Über Runs - 1. Over bis 10. Over',
        1422 : ' Um Chase über 180 Läufe und den Gegner',
        1423 : ' Wie viele Runs ab der ersten Kugel Erzielte',
        1424 : ' Unter / Über Spiel stumpings',
        1425 : ' 1. Wicket-Methode',
        1426 : ' Erster Ball Of The Match',
        1427 : ' Unter / Über - 1. Inning',
        1428 : ' Top Non Victorian Verein',
        1429 : ' Grand Final Sieger',
        1431 : ' Zum Erreichen Sie das Grand Final',
        1432 : ' Nicht zu beenden In Top 8',
        1434 : ' Unter / Über Kills 4. Periode - Auswärts-Team',
        1435 : ' Unter / Über Kills 5. Periode - Gastmannschaft',
        1436 : ' Unter / Über Kills 4. Periode - Home Team',
        1437 : ' Unter / Über Kills 5. Periode - Home Team',
        1438 : ' Unter / Über Temperatur Bei Airport (Celsius)',
        1439 : ' Asian Handicap - Endstand',
        1440 : ' Unter / Über Wert aller Zahlen gezogen',
        1441 : ' Unter / Über Wert aller ungerade Zahlen Gezeichnet',
        1442 : ' Unter / Über Wert aller geraden Zahlen Gezeichnet',
        1443 : ' Auch sind mehr als ungerade Zahlen Gezeichnet',
        1444 : ' Aufeinander folgende Zahlen gezogen werden',
        1445 : ' Unter / Genau / Über - Anzahl Ungerade nummerierte Kugeln gezeichnet',
        1446 : ' Unter / Genau / Über - Anzahl geradzahliger Ball Gezeichnet',
        1447 : ' Summe von 5 Bonus-Kugeln',
        1448 : ' Erste Bonus-Ball Drawn - Gerade / Ungerade',
        1449 : ' Letzter Bonus Kugel Gezeichnet - Gerade / Ungerade',
        1450 : ' Erster Bonus-Ball',
        1451 : ' Letzter Bonus-Ball',
        1452 : ' Die niedrigste von 5 Bonus-Kugeln',
        1453 : ' Bonus Ball - Gerade / Ungerade',
        1454 : ' Identische Zahlen gezeichnet werden',
        1455 : ' Höchste Anzahl Drawn - Gerade / Ungerade',
        1456 : ' Die niedrigste Anzahl Drawn - Gerade / Ungerade',
        1457 : ' Ball 1 - Unter / Über',
        1458 : ' Ball 1 - Gerade / Ungerade',
        1459 : ' Ball 2 - Unter / Über',
        1460 : ' Ball 2 - Gerade / Ungerade',
        1461 : ' Ball 3 - Unter / Über',
        1462 : ' Ball 3 - Gerade / Ungerade',
        1463 : ' Ball 4 - Unter / Über',
        1464 : ' Ball 4 - Gerade / Ungerade',
        1465 : ' Ball 5 - Unter / Über',
        1466 : ' Ball 5 - Gerade / Ungerade',
        1467 : ' Ball 6 - Unter / Über',
        1468 : ' Ball 6 - Gerade / Ungerade',
        1469 : ' Ball 7 - Unter / Über',
        1470 : ' Ball 7 - Gerade / Ungerade',
        1471 : ' Ball 8 - Unter / Über',
        1472 : ' Ball 8 - Gerade / Ungerade',
        1473 : ' Ball 9 - Unter / Über',
        1474 : ' Ball 9 - Gerade / Ungerade',
        1475 : ' Ball 10 - Unter / Über',
        1476 : ' Ball 10 - Gerade / Ungerade',
        1477 : ' Ball 11 - Unter / Über',
        1478 : ' Ball 11 - Gerade / Ungerade',
        1479 : ' Ball 12 - Unter / Über',
        1480 : ' Ball 12 - Gerade / Ungerade',
        1481 : ' Ball 13 - Unter / Über',
        1482 : ' Ball 13 - Gerade / Ungerade',
        1483 : ' Ball 14 - Unter / Über',
        1484 : ' Ball 14 - Gerade / Ungerade',
        1485 : ' Ball 15 - Unter / Über',
        1486 : ' Ball 15 - Gerade / Ungerade',
        1487 : ' Ball 16 - Unter / Über',
        1488 : ' Ball 16 - Gerade / Ungerade',
        1489 : ' Ball 17 - Unter / Über',
        1490 : ' Ball 17 - Gerade / Ungerade',
        1491 : ' Ball 18 - Unter / Über',
        1492 : ' Ball 18 - Gerade / Ungerade',
        1493 : ' Ball 19 - Unter / Über',
        1494 : ' Ball 19 - Gerade / Ungerade',
        1495 : ' Ball 20 - Unter / Über',
        1496 : ' Ball 20 - Gerade / Ungerade',
        1497 : ' Mindestens eine der Zahlen Gezeichnet durch 3 teilbar ist',
        1498 : ' Mindestens eine der Zahlen gezogen wird durch 4 teilbaren',
        1499 : ' Mindestens eine der Zahlen gezogen wird durch 5 teilbar',
        1500 : ' Mindestens eine der Zahlen gezogen durch 7 teilbar',
        1501 : ' Mindestens eine der Zahlen gezeichnet durch 10 teilbare',
        1502 : ' Mindestens eine der Zahlen Gezeichnet teilbar durch 15',
        1503 : ' Mindestens eine der Zahlen Gezeichnet teilbar durch 20',
        1504 : ' Höchste Anzahl Drawn - Unter / Über',
        1505 : ' Gesamtwert der niedrigsten und höchsten Zahlen gezogen - Unter / Über',
        1506 : ' Der niedrigste Anzahl Drawn - Unter / Über',
        1507 : ' Differenz zwischen dem höchsten und dem niedrigsten Zahlen gezogen - Unter / Über',
        1508 : ' Gesamtwert der niedrigsten und höchsten Zahlen gezogen - Gerade / Ungerade',
        1509 : ' Differenz zwischen den höchsten und der niedrigsten Zahl gezogen - Gerade / Ungerade',
        1510 : ' Erste Numerus Gezeichnet ist größer als die letzte Nummer Gezeichnet',
        1511 : ' Letzte Nummer Drawn - Gerade / Ungerade',
        1512 : ' Erster Numerus Drawn - Gerade / Ungerade',
        1513 : ' Alle gezogenen Zahlen Selbst',
        1514 : ' Alle gezogenen Zahlen Odd',
        1515 : ' Anzahl zu zeichnen',
        1520 : ' 4. Periode - 1. Turret',
        1521 : ' 5. Periode - 1. Turret',
        1523 : ' Unter / Über Barons getöteten - 4. Periode',
        1524 : ' Unter / Über Barons getöteten - 5. Periode',
        1525 : ' Unter / Über Dragons getöteten - 4. Periode',
        1526 : ' Unter / Über Dragons getöteten - 5. Periode',
        1527 : ' Unter / Über Turrets zerstört - 4. Periode',
        1528 : ' Unter / Über Turrets zerstört - 5. Periode',
        1529 : ' 4. Periode Dauer',
        1530 : ' 5. Periodendauer',
        1531 : ' Gerade / Ungerade Kills - 4. Periode',
        1532 : ' Gerade / Ungerade Kills - 5. Periode',
        1533 : ' Unter / Über-Inhibitoren - 1. Periode',
        1534 : ' Unter / Über Inhibitoren - 2. Periode',
        1535 : ' Temperatur am Flughafen in Reichweite (Celsius)',
        1536 : ' Unter / Über Karten',
        1537 : ' Home Team gewinnt und Unter / Über - inklusive Überstunden',
        1538 : ' Gastmannschaft gewinnt und Unter / Über - inklusive Überstunden',
        1539 : ' Runde Gewinner',
        1540 : ' Insgesamt Spiel Legs Unter / Über',
        1541 : ' Runder Handicap',
        1542 : ' 1. Leg Gewinner',
        1543 : ' First To Win 3 Legs',
        1546 : ' 4. Periode - 1. Roshan',
        1547 : ' 5. Periode - 1. Roshan',
        1548 : ' 4. Periode - 1. Kaserne',
        1549 : ' 5. Periode - 1. Kaserne',
        1550 : ' 4. Periode - 1. tou',
        1551 : ' 5. Periode - 1. tou',
        1552 : ' Asian Unter / Über Corners',
        1553 : ' Beide Teams erzielen ein Tor Anzahl Punkte - 1. Periode',
        1554 : ' Beide Teams erzielen ein Tor Anzahl Punkte - 1. Halb',
        1555 : ' Unter / Über 5. Periode - Home Team',
        1556 : ' Unter / Über fünften Periode - Gastmannschaft',
        1558 : ' Asian Handicap Punkte',
        1559 : ' Cup Sieger',
        1561 : ' 1. 3 Innings Sieger',
        1562 : ' 1. 3 Innings Unter / Über',
        1563 : ' Zeit Scoring',
        1564 : ' Zeit Scoring - Home Team',
        1565 : ' Zeit Scoring - Gastmannschaft',
        1566 : ' Spiel-Zeit Ergebnis',
        1567 : ' Top Nationalität - Südafrikanischer',
        1568 : ' Top Nationalität - Spanier',
        1569 : ' Top Nationalität - Irish',
        1570 : ' Top Nationalität - südamerikanisch',
        1571 : ' Top Nationalität - Kanadische',
        1572 : ' Top Nationalität - Engländer',
        1573 : ' Top Nationalität - Continental European',
        1575 : ' To Make Cut',
        1576 : ' Miss Cut',
        1577 : ' Top Nationalität - Amerikanische',
        1578 : ' Nationalität des Gewinners',
        1579 : ' Asian Handicap-Punkte - 1. Drittel',
        1580 : ' Asian Handicap Punkte - 2. Periode',
        1581 : ' Asian Handicap-Punkte - 3. Drittel',
        1583 : ' Top Team Bowler 1. Inning - Home Team',
        1584 : ' Top Team Bowler 1. Inning - Gastmannschaft',
        1585 : ' Top Team Batsman ersten Inning - Home Team',
        1586 : ' Top Team Batsman ersten Inning - Gastmannschaft',
        1587 : ' Wird es ein Ass Next Punkt sein',
        1589 : ' Wird es einen Doppelfehler Weiter Point sein',
        1591 : ' Unter / Über - 1. Over',
        1592 : ' Sieg Liga',
        1593 : ' Sieg der Division',
        1594 : ' Die meisten Hits - einschließlich Überstunden',
        1595 : ' Kampf bis Go The Distance',
        1596 : ' Will wenn der Kampf End',
        1597 : ' Kampf Outcome',
        1598 : ' Runde Wetten',
        1599 : ' Top erster Hit',
        1600 : ' Bottom 1. Hit',
        1601 : ' Top 1st Run',
        1602 : ' Unten, 1. Durchgang',
        1603 : ' Die meisten Treffer in dem ersten Inning',
        1605 : ' Team mit höchster Punktzahl Inning',
        1606 : ' Beide Teams Erzielt 2 oder mehr Tore',
        1607 : ' Beide Teams erzielen ein Tor 3 oder mehr Tore',
        1608 : ' Team gewinnen Die meisten Perioden',
        1609 : ' Asian Handicap Vollzeit - 1. Periode',
        1610 : ' Asian Handicap Vollzeit - 2. Periode',
        1611 : ' Asian Handicap Vollzeit - 3. Periode',
        1612 : ' MVP',
        1614 : ' To Win Conference',
        1615 : ' Gewinnen Konferenz',
        1617 : ' Saison-Player Angebote',
        1618 : ' 1. 5 Innings Sieger - 12',
        1619 : ' 1. Periode - Halbzeit Gewinner Heim / Auswärts',
        1620 : ' 1. Periode - Halbzeit Asian Handicap',
        1621 : ' 2. Periode - Halbzeit Gewinner Heim / Auswärts',
        1622 : ' 2. Periode - Halbzeit Asian Handicap',
        1624 : ' Unter / Über-Spieler läuft',
        1625 : ' Unter / Über-Spieler Single',
        1626 : ' Unter / Über-Spieler Home Runs',
        1627 : ' Unter / Über-Spieler Stolen Bases',
        1628 : ' Unter / Über-Spieler Double',
        1629 : ' Unter / Über-Spieler Triples',
        1630 : ' Pitcher zur Aufzeichnung der Win',
        1631 : ' Unter / Über Spieler Alle Bases',
        1635 : ' Unter / Über Pitcher Runs verdient',
        1636 : ' Unter / Über Pitcher Hits erlaubt',
        1637 : ' Unter / Über Pitcher Ausstreichen',
        1638 : ' Mannschaftswertung',
        1639 : ' Junge Reiter Klassifizierung',
        1640 : ' Punkte Classification',
        1641 : ' König der Berge',
        1642 : ' Bühne 1',
        1649 : ' Schnellste Runde',
        1650 : ' Gewinnen Auto',
        1651 : ' Erster Fahrer in den Ruhestand',
        1652 : ' Die Qualifikation Gewinnen Auto',
        1653 : ' Am schnellsten in der Praxis 1',
        1654 : ' Punkte-Finish',
        1655 : ' Schnellster Qualifier',
        1657 : ' Stufe 3',
        1659 : ' Erste Constructor Retirement',
        1660 : ' Gitter Reihenfolge des Gewinners',
        1661 : ' Safety Car-Phase während des Rennens',
        1662 : ' Anzahl klassifizierter Treiber',
        1663 : ' Virtuelle Safety Car-Phase während des Rennens',
        1664 : ' Loch Score',
        1667 : ' Sieg Loch',
        1670 : ' gebunden Spiel',
        1672 : ' Unter / Über Tore - 2. Halb',
        1673 : ' Ungerade / Runden Auch der 4. Periode',
        1674 : ' Ungerade / Runden Auch der 5. Periode',
        1675 : ' 2. Runde Führer',
        1676 : ' Beide Mannschaften zu treffen - inklusive Verlängerung',
        1677 : ' Letztes Tor? - inklusive Überstunden',
        1678 : ' Top Nationalität - Deutsch',
        1679 : ' Top Nationalität - Austrian',
        1680 : ' Top Nationalität - Neuseeländer',
        1681 : ' Top Linkshänder Spieler',
        1682 : ' Top Amateur',
        1683 : ' Top Nationalität - Koreanisch',
        1684 : ' Top Nationalität - Scandinavian',
        1685 : ' Top Nationalität - Franzose',
        1686 : ' Top Der ehemalige Sieger',
        1687 : ' Top Nationalität - Schwede',
        1688 : ' Top Nationalität - Japanisch',
        1689 : ' Top Nationalität - Scotsman',
        1690 : ' Top Nationalität - Belgisch',
        1691 : ' European Handicap 4. Periode',
        1692 : ' European Handicap 3. Periode',
        1693 : ' European Handicap 2. Periode',
        1694 : ' European Handicap 1. Drittel',
        1695 : ' Round Score',
        1696 : ' Turnier gewinnen Score',
        1697 : ' Führende Score Nach Runde',
        1698 : ' Um Hit The Fairway mit Abschlag',
        1699 : ' Hit Grün in Regulation',
        1700 : ' 3. Runde Führer',
        1702 : ' Unter / Über-Spieler Passing Versuche',
        1704 : ' Landungs ​​am 1. Periode',
        1705 : ' Landungs ​​am 2. Periode',
        1706 : ' Landungs ​​am 3. Periode',
        1707 : ' Landungs ​​am 4. Periode',
        1708 : ' Unter / Genau / Über - Home Team Touchdowns',
        1709 : ' Unter / Genau / Über - Gastmannschaft Touchdowns',
        1710 : ' Unter / Genau / Über - Home Team 1. Halbtouchdowns',
        1711 : ' Unter / Genau / Über - Gastmannschaft 1. Halbtouchdowns',
        1712 : ' 16. Runde Gewinner - 4. Periode',
        1713 : ' 16. Runde Gewinner - 5. Periode',
        1714 : ' Unter / Über tous zerstört - 4. Periode',
        1715 : ' Unter / Über tous zerstört - 5. Periode',
        1722 : ' Auf Fertig stellen, um Nil',
        1723 : ' Unter / Über Kills - 1. Periode - 1. Runde',
        1724 : ' Unter / Über Kills - 2. Periode - 1. Runde',
        1728 : ' Beide Teams erzielen ein Tor - Extra Time',
        1729 : ' Doppelte Chance - Extra Time',
        1730 : ' Wird das Spiel Go To Super-Over?',
        1731 : ' Doppelte Chance 5. Periode',
        1732 : ' Doppelte Chance 6. Periode',
        1733 : ' Doppelte Chance 7. Periode',
        1734 : ' Doppelte Chance 8. Periode',
        1736 : ' Anzahl der Durchläufe ersten Inning',
        1737 : ' Anzahl der Durchläufe 2. Inning',
        1738 : ' Anzahl der Durchläufe 3. Inning',
        1739 : ' Anzahl der Durchläufe vierten Inning',
        1740 : ' Anzahl der Durchläufe fünften Inning',
        1741 : ' Anzahl der Durchläufe sechsten Inning',
        1742 : ' Anzahl der Durchläufe 7. Inning',
        1743 : ' Anzahl der Durchläufe achten Inning',
        1744 : ' Ein Fünfzig in den ersten Innings zu',
        1745 : ' Herbst 1. Wicket',
        1747 : ' Weiter Man Out',
        1748 : ' abgeschlossen Spiel',
        1749 : ' Century Erzielte',
        1750 : ' Century Tore - 1. Inning',
        1752 : ' Auswärts Team First Abweisung 6-Way',
        1753 : ' Topspiel Batsman',
        1754 : ' Erzielt einen Elfmeter',
        1758 : ' 6. Mann des Jahres',
        1760 : ' Anfänger des Jahres',
        1761 : ' Regular Season MVP',
        1763 : ' Batsman hundert 1. Innings zum Spiel',
        1765 : ' 9 Darter im Spiel',
        1766 : ' Zeit des ersten Versuchs',
        1767 : ' Time of Last Try',
        1770 : ' 9 Darter im Spiel - Home Team',
        1771 : ' 9 Darter im Spiel - Auswärts-Team',
        1772 : ' Top Run Scorer',
        1773 : ' Top Run Scorer - Home Team',
        1774 : ' Top Run Scorer - Auswärts-Team',
        1775 : ' Team von Top Run Scorer',
        1776 : ' Unter / Über Punts',
        1777 : ' Unter / Über Sacks',
        1778 : ' Unter / Über 1. Downs',
        1779 : ' 1. Team A Erste Down To Get',
        1780 : ' 1. Hälfte Unter / Über Field Goals - Home Team',
        1781 : ' 1. Hälfte Unter / Über Field Goals - Auswärts-Team',
        1782 : ' Unter / Genau / Über - Field Goals 1. Halb',
        1783 : ' Unter / Über Touchdowns ersten Periode',
        1784 : ' Unter / Über Strafen Accepted',
        1785 : ' Die meisten Kills im Spiel',
        1786 : ' Team mit den meisten Kills im Spiel',
        1787 : ' Unter / Über Pass',
        1788 : ' Unter / Über Pass Beendigungen',
        1789 : ' Unter / Über Passing Yards',
        1790 : ' Unter / Über Eile Versuche',
        1791 : ' Unter / Über Eile Yards',
        1792 : ' Unter / Über-Spieler Tackles und Assist',
        1794 : ' Team Punt Erste',
        1795 : ' 1. Periode 1X2 und Unter / Über',
        1796 : ' 1X2 Vorlagen',
        1797 : ' Doppelte Chance Vorlagen',
        1798 : ' Team führt nach jeder Periode',
        1799 : ' Team mit den meisten Yards Passing',
        1800 : ' Team mit den meisten Yards Rushing',
        1801 : ' Erfolgreich 2-Punkt Conversion',
        1802 : ' Unter / Über Touchdown-Pässe',
        1803 : ' Unter / Über Field Goals ersten Periode',
        1804 : ' Ergebnis in letzten 2 Minuten des ersten Halb',
        1805 : ' Team zum Empfang von Kick Off Öffnungs',
        1806 : ' Unter / Über Home Team Touchdowns - 1. Periode',
        1807 : ' Unter / Über Gastmannschaft Touchdowns - 1. Periode',
        1808 : ' Unter / Genau / Über Home Team Touchdowns - 1. Periode',
        1809 : ' Unter / Genau / Über Gastmannschaft Touchdowns - 1. Periode',
        1810 : ' Unter / Über Home Team Field Goals - 1. Periode',
        1812 : ' Unter / Über Gastmannschaft Field Goals - 1. Periode',
        1813 : ' Gastmannschaft zu gewinnen jedes Quartal',
        1814 : ' Home Team zu gewinnen jedes Quartal',
        1815 : ' Erstes Offensivspiel des Spiels',
        1816 : ' To Win Quarter',
        1817 : ' Erreicht das Finale?',
        1818 : ' Home Team 25 Tore zum Spiel',
        1819 : ' Home Team 30 Tore zum Spiel',
        1820 : ' Gastmannschaft 25 Tore zum Spiel',
        1821 : ' Gastmannschaft 30 Tore zum Spiel',
        1822 : ' Home Team um Gewinnt irgendeine Halb',
        1823 : ' Auswärtsmannschaft Gewinnt irgendeine Halb',
        1824 : ' Stufe der Beseitigung',
        1825 : ' Unter / Über Vorlagen',
        1826 : ' Unter / Über Rebounds',
        1827 : ' Unter / Über Heimmannschaft Vorlagen',
        1828 : ' Unter / Über Gastmannschaft Vorlagen',
        1829 : ' Unter / Über Home Team Rebounds',
        1830 : ' Unter / Über Auswärts-Team Rebounds',
        1831 : ' 1X2 3 Punkte gemacht',
        1832 : ' Unter / Über 3 Punkte gemacht',
        1833 : ' Unter / Über Home Team 3 Punkte gemacht',
        1834 : ' Unter / Über von Team Auswärts 3 Punkte gemacht',
        1835 : ' Asian Handicap 3 Punkte gemacht',
        1841 : ' erste Auswechslungen',
        1842 : ' 2. Tor im Spiel',
        1843 : ' 3. Tor im Spiel',
        1846 : ' 60 Minute Ergebnis',
        1847 : ' 75 Minute Ergebnis',
        1848 : ' Erzielt Tor in den ersten 5 Minuten?',
        1849 : ' Erster Einwurf in Spiel',
        1850 : ' Zeichnen Sie in irgendeine Halb',
        1851 : ' Kopfballtor im Spiel?',
        1852 : ' Unter / Über Torminuten',
        1853 : ' Unter / Über Tie Break im Match',
        1854 : ' Unter / Über Pause Punkte',
        1855 : ' Unter / Über Break Points Home Team',
        1856 : ' Unter / Über Break Points Gastmannschaft',
        1857 : ' Unter / Über höchster Punktzahl Viertel',
        1858 : ' Unter / Über Niedrigste Scoring Quarter',
        1859 : ' Die meisten Tries 3-Way',
        1860 : ' 10 Minute Gewinnspanne 5-Way',
        1861 : ' Die meisten Verluste Teams',
        1862 : ' Ein Teil wird im Spiel punkten',
        1863 : ' Win Alle Perioden - Home Team',
        1864 : ' Perioden Win All - Gastmannschaft',
        1865 : ' Die ersten 10 Minute Unter / Über Tries',
        1866 : ' 15 Minute Unter / Über - Corners',
        1867 : ' 2 Tore in einer Reihe von Teams',
        1868 : ' 3 Tore in einer Reihe von Teams',
        1869 : ' Die meisten Converted Tries',
        1872 : ' Ungeimpfte Finalist?',
        1873 : ' Wild Card zu erreichen Finale?',
        1874 : ' Gewinnen Halb - Tennis Outright',
        1875 : ' Gewinnen Quarter - Tennis Outright',
        1881 : ' Mit einem Schlag einlochen',
        1882 : ' Wird es eine Play-off?',
        1883 : ' 72 Loch Spiel Ups',
        1884 : ' 54 Loch Spiel Ups',
        1885 : ' Nummer 1 Draft-Pick',
        1886 : ' 1X2 und Doppelte Chance',
        1887 : ' Home Team zu gewinnen \\ Zeichnen und Beide Teams erzielen ein Tor',
        1888 : ' Gastmannschaft zu gewinnen \\ zu Punktzahl Draw und beiden Teams',
        1889 : ' Gewinnspanne - 1. Halb',
        1891 : ' American League Cy Young',
        1892 : ' American League MVP',
        1893 : ' National League Cy Young',
        1894 : ' National League MVP',
        1895 : ' Pitcher zur Aufzeichnung der meisten Regular Season Siege',
        1896 : ' Player zu Hit The Most Regular Season Home Runs',
        1897 : ' Will Spiel in Verlängerung entschieden?',
        1898 : ' Reguläre Saison gewinnen%',
        1900 : ' Spieler Bester Torschütze',
        1901 : ' Unter / Über Tackles',
        1902 : ' Nummer 2 Draft-Pick',
      }
};
