export const SET_CASINO_GAMES = '[APP] SET CASINO GAMES';
export const SET_CASINO_CATEGORIES = '[APP] SET CASINO CATEGORIES';
export const SET_CASINO_FILTERED_GAMES = '[APP] SET CASINO FILTERED GAMES';
export const SET_CASINO_LOADING = '[APP] SET CASINO LOADING';
export const CLEAR_FILTERED_GAMES = '[APP] CLEAR FILTERED GAMES';
export const SET_CASINO_PROVIDERS = '[APP] SET CASINO PROVIDERS';
export const SET_CASINO_SEARCH_VALUE = '[APP] SET CASINO SEARCH VALUE';
export const SET_CASINO_ACTIVE_CATEGORY = '[APP] SET CASINO ACTIVE CATEGORY';
export const SET_CASINO_SEARCH_STARTED = '[APP] SET CASINO SEARCH STARTED';
export const SET_CASINO_SEARCH = '[APP] SET CASINO SEARCH';
export const SET_GAME_DATA = '[APP] SET GAME DATA';
export const CLEAR_CASINO_GAMES = '[APP] CLEAR CASINO GAMES';

export const SET_LIVE_CASINO_GAMES = '[APP] SET LIVE CASINO GAMES';
export const SET_LIVE_CASINO_ACTIVE_CATEGORY = '[APP] SET LIVE CASINO ACTIVE CATEGORY';
export const SET_LIVE_CASINO_SEARCH_STARTED = '[APP] SET LIVE CASINO SEARCH STARTED';
export const SET_LIVE_CASINO_SEARCH = '[APP] SET LIVE CASINO SEARCH';
export const SET_LIVE_GAME_DATA = '[APP] SET LIVE GAME DATA';
export const CLEAR_LIVE_CASINO_GAMES = '[APP] CLEAR LIVE CASINO GAMES';
export const SET_VIRTUAL_SPORTS = '[APP] SET VIRTUAL SPORTS';
export const CLEAR_VIRTUAL_SPORTS = '[APP] CLEAR VIRTUAL SPORTS';
export const SET_VIRTUAL_SPORTS_SEARCH = '[APP] SET VIRTUAL SPORTS SEARCH';
export const SET_VIRTUAL_SPORTS_CATEGORY = '[APP] SET VIRTUAL SPORTS CATEGORY';
export const SET_VIRTUAL_SEARCH_STARTED = '[APP] SET VIRTUAL SEARCH STARTED';
export const SET_CASINO_LOBBY = '[APP] SET CASINO LOBBY';
export const SET_LOADING_SLOT = '[APP] SET_LOADING_SLOT';
