import axios from 'axios';
import { apiConfig } from '../config';
import EventEmitter from '../helper/EventEmitter';
import Util from '../helper/Util';
import { Translate } from '../localization';

class casinoService extends EventEmitter {
    getCasinoGames = (filters) => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getCasinoGames, {
                    params: {
                        ...filters,
                        lang: Util.getQTechLanguageCode(Translate.getLanguage()),
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getCasinoCategories = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getCasinoCategories, {
                    params: {
                        lang: Util.getQTechLanguageCode(Translate.getLanguage()),
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getCasinoProviders = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getCasinoProviders, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    initCasinoUser = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.initCasinoUser)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getLiveCasinoGames = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getLiveCasinoGames, {
                    params: {
                        game_type: 'live-casino',
                        lang: Util.getQTechLanguageCode(Translate.getLanguage()),
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getGameData = (game, language, country, currency, type) => {
        let url;
        if (type === 'demo' && !Util.isLoggedIn()) {
            url = apiConfig.routes.getDemoGameData;
        } else {
            url = apiConfig.routes.casinoAPI;
        }

        return new Promise((resolve, reject) => {
            axios
                .post(url, {
                    game_id: game.game_id,
                    lang: Util.getQTechLanguageCode(Translate.getLanguage()),
                    mode: type,
                    device: 'mobile',
                }, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getVirtualSports = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.getVirtualSports, {
                    params: {
                        game_type: 'virtual',
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    },
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getEzugiLobbyURL = () => {

        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.ezugiCasinoLobby, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        language: 'en',
                        open_table: -1,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getEvoLobbyURL = () => {
        return new Promise((resolve, reject) => {
            axios
                .get(apiConfig.routes.ezugiCasinoLobby, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                        language: 'en',
                        open_table: 1000000,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    getLobbyURL = (type) => {
        if (type === 'evo') {
            return this.getEvoLobbyURL();
        }

        if (type === 'ezugi') {
            return this.getEzugiLobbyURL();
        }
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.lobbyURL, {
                    // "playerId": 9,
                    // "unique_id": "eab42d8e-9feb-4d0e-8d78-4f26fc96e107",
                    // "displayName": "CNY",
                    // "currency": "EUR",
                    // "lang": "en_US",
                    'device': 'mobile',
                    // "mode": "real",
                    // "country": "IN",
                    'gameLaunchTarget': 'SELF'
                }, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    };

    initGgSlotUser = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.initGgSlotUser, {
                }, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    getAllGgGamesList = () => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.getGgAllGamesList, {
                }, {
                    params: {
                        lang: Util.getQTechLanguageCode(Translate.getLanguage()),
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {

                    reject(error);
                });
        });
    }

    ggSlotURL = (lobby_id) => {
        return new Promise((resolve, reject) => {
            axios
                .post(apiConfig.routes.ggSlotURL, {
                    lobby_id: lobby_id,
                }, {
                    params: {
                        unique_id: process.env.REACT_APP_UNIQUE_ID,
                    }
                })
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

}

const instance = new casinoService();


export default instance;