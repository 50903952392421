export const ja = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'スポーツの',
    live: '住む',
    inplay: '劇中',
    today: '今日',
    upcoming: '来たる',
    tomorrow: '明日',
    highlights: 'ハイライト',
    results: '結果',
    lastMinute: 'ぎりぎり',
    liveCasino: 'ライブカジノ',
    casino: 'カジノ',
    myBets: '私のベット',
    home: '家',
    login: 'ログイン',
    signup: 'サインアップ',
    betslip: 'Betslip',
    search: '探す',
    searchHere: '検索',
    transaction: 'トランザクション',
    changePassword: 'パスワードを変更する',
    logout: 'ログアウト',
    single: 'シングル',
    multiple: '複数',
    possibleWin: '可能性のある勝利',
    amount: '量',
    ok: 'OK',
    totalOdds: '合計オッズ',
    odd: '奇妙な',
    totalPossibleWin: '総可能勝利',
    placeBet: '場所ベット',
    fastBet:'速い賭け',
    fast: '速いです',
    bet: 'ベット',
    username: 'ユーザー名',
    password: 'パスワード',
    stayLoggedIn: 'ログインしたまま',
    all: 'すべて',
    'in game': 'ゲームで',
    won: '勝った',
    lost: '失われました',
    refund: '返金',
    date: '日付',
    winAmount: '勝利量',
    stake: '杭',
    pick: 'ピック',
    myAccount: 'マイアカウント',
    football: 'フットボール',
    basketball: 'バスケットボール',
    iceHockey: 'アイスホッケー',
    tennis: 'テニス',
    volleyball: 'バレーボール',
    boxing: 'ボクシング',
    americanFootball : 'アメリカンフットボール',
    baseball: '野球',
    hockey: 'ホッケー',
    tableTennis: '卓球',
    badminton: 'バドミントン',
    cricket: 'クリケット',
    futsal: 'フットサル',
    golf: 'ゴルフ',
    handball: 'ハンドボール',
    rugbyLeagues: 'ラグビーリーグ',
    pickCanNotBeCombined: 'このピックを組み合わせることができません',
    matchStopped: 'マッチが停止しました',
    favorites: 'お気に入り',
    cashout: '払い戻し',
    'in progress': '進行中',
    rejected: '拒否されました',
    tip: 'ヒント',
    noGoal: 'いいえ目標ません',
    yes: 'はい',
    over: 'オーバー',
    under: '下',
    even: 'さえ',
    areYouSureWantCashout: 'あなたがキャッシュアウトしてもよろしいでしょうか？',
    noGoal: '番号',
    bonus: 'ボーナス',
    termsAndConditon: '規約と条件',
    aboutUs: '私たちに関しては',
    contactUs: 'お問い合わせ',
    payments: '支払い',
    howPlacebet: '賭けをする方法',
    deposit: '保証金',
    credit: 'クレジット',
    debit: '借り返る',
    withdraw: '取り下げます',
    totalOdd: '合計奇数',
    totalStake: '総出資',
    topGames: 'トップゲーム',
    allGames: 'すべてのゲーム',
    canNotBeCombined: 'このピックを組み合わせることができません',
    betSettled: 'ベット定住',
    betSuspended: 'ベット中断',
    insufficientBalance: '残高不足です',
    oldPassword: '以前のパスワード',
    newPassword: '新しいパスワード',
    confirmPassword: '新しいパスワードを確認',
    change: '変化する',
    fromDate: '日から',
    toDate: '現在まで',
    activityType: 'アクティビティタイプ',
    show: '公演',
    gameType: 'ゲームの種類',
    repeatBet: 'リピートベット',
    someThingWrong: '何かが間違っていました',
    status: '状態',
    coupon: 'クーポン',
    acceptOddChangesText: 'あなたはベットを置くことができるように変更を承認する必要があります',
    turnOffAway: 'とにかくオフにします',
    acceptOddChanges: '奇妙な変化を受け入れます',
    maxWinAmount: '最大の勝利量',
    maxOdd: 'マックス奇数',
    dateTime: '日付時刻',
    type: 'タイプ',
    refreshCashout: 'リフレッシュキャッシュアウト',
    vendor: 'ベンダー',
    stakeLowerThen1: '出資比率は少ないしなければなりません',
    stakeGreaterThen1: '出資比率は、大きくなければなりません。',
    ESports: 'Esports',
    KSports: 'KSPORTS',
    promotion: 'プロモーション',
    customerServicePage: 'カスタマーサービスページ',
    decimal: '小数',
    fraction: 'fr',
    americanOdds: 'アメリカのオッズ',
    nothingFound: '何も見つかりません',
    noLiveMatch: 'ライブマッチはありません',
    sportBook: 'スポーツブック',

     marketNames: {
        1: '1×2',
        2: '下上',
        238: '残りの試合',
        59: '次の目標',
        7: 'ダブルチャンス',
        52: 'ノーベットを描きます',
        226: '残業を含め12',
        13: 'ヨーロッパのハンディキャップ',
        4: 'HT / FT',
        17: '双方が得点',
        59: '次の目標',
        28: '以下の下/上を含む残業',
        247: '残り上期',
    },
    tabNames: {
        'All':'すべて',
       'Main Markets': 'メイン市場',
        'Totals': '合計',
        '1st Half': '上期',
        '2nd Half': '下期',
        'Home Team': 'ホームチーム',
        'Away Team': 'アウェイチーム',
        'All Periods': 'すべての期間',
        'Results':'結果',
        'Home / Away': 'ホーム/アウェイ',
    },
    markets: {
        2: '  下上',
        3: '  アジアのハンディキャップ',
        5: '  奇数/偶数',
        6: '  正しいスコア',
        7: ' ダブルチャンス',
        9: '  正しいスコア第一期間',
        11: ' 総コーナー',
        12: ' コミット合計ファウル',
        13: ' ヨーロッパのハンディキャップ',
        16: ' 最初のチームにスコア',
        17: ' 双方が得点',
        19: ' まずカード',
        21: ' 第1回にわたり/の下で',
        22: ' アウェイチームの得点に',
        23: ' ホームチームの得点に',
        25: ' ダブルチャンスハーフタイム',
        29: ' 回のラウンドで/の下で',
        30: ' /オーバーコーナーの下で - ホームチーム',
        31: ' /オーバーコーナーの下で - アウェイチーム',
        34: ' ホームチームには、両方の半分でスコア',
        35: ' アウェイチームには、両方の半分でスコア',
        41: ' 第一期受賞',
        42: ' 第二期受賞',
        43: ' 第三期受賞',
        44: ' 第四期受賞',
        45: ' オーバー/アンダー第二期',
        46: ' オーバー/アンダー第三期',
        47: ' 以下の下で/第4回にわたり',
        48: ' 以下の下で/第5回にわたり',
        49: ' 第五期受賞',
        50: ' 残業を含め1X2',
        51: ' 奇数/偶数を含む残業',
        52: ' 12',
        53: ' アジアハンディキャップハーフタイム',
        55: ' 最初のチームには、第一期のスコア',
        56: ' 最後のチームにスコア',
        57: ' 最後のチームには、ハーフタイムのスコア',
        59: ' 次の目標',
        61: ' ヨーロッパのハンディキャップハーフタイム',
        62: ' 奇数/偶数ハーフタイム',
        63: ' 12ハーフタイム',
        64: ' アジアハンディキャップ第一期',
        65: ' アジアハンディキャップ第二期',
        66: ' アジアハンディキャップ第三期',
        67: ' アジアハンディキャップ第四期',
        68: ' アジアハンディキャップ第五期',
        69: ' ゲーム[移動残業だろうか？',
        70: ' 最高得点期間',
        71: ' 最高得点の半分',
        72: ' 第一期奇数/偶数',
        73: ' 第二期奇数/偶数',
        74: ' 第三期奇数/偶数',
        75: ' 第四期奇数/偶数',
        76: ' 第五期奇数/偶数',
        77: ' オーバー/アンダーハーフタイム',
        78: ' ほとんどのシックスを獲得するために',
        79: ' ペナルティが授与されますか？',
        80: ' 最初のコーナー',
        81: ' 最終コーナー',
        82: ' Aレッドカードが表示されますか？',
        83: ' クリーンシートを保つために',
        84: ' 勝つ両方の半分に',
        85: ' 勝つために後ろから',
        86: ' 勝つためにはnilに',
        88: ' 修飾します',
        89: ' まず、変換対象しようとしますか？',
        90: ' マッチは、第5セットで決定されますか？',
        91: ' 何でハーフ初ゴールは得点になりますか？',
        92: ' 最初のチームには、下期のスコア',
        95: ' コーナーハンディキャップ',
        96: ' アウェイチーム勝つために無記号',
        97: ' ホームチームの勝利に無記号',
        98: ' ホームチームのクリーンシートを保つために',
        99: ' アウェイチームはクリーンシートを保つために',
        100: ' 正しいスコア第二期',
        101: ' ホームチームの -  /オーバーの下で',
        102: ' アウェイチーム -  /オーバーの下で',
        104: ' アウェイチームに勝つ両方の半分',
        105: ' ホームチームに勝つ両方の半分',
        106: ' 後ろからホームチームに勝利',
        107: ' アウェイチームに勝利の後ろから',
        108: ' ホームチームは、ペナルティからの得点に',
        109: ' アウェイチームはペナルティからの得点に',
        110: ' アウェイチームに勝利少なくとも半分',
        111: ' ホームチームに勝利少なくとも半分',
        113: ' 両チームは第一半分を獲得するために',
        114: ' 任意のプレイヤーには、少なくとも2つのゴールを決めます',
        117: ' 任意のプレイヤーには、少なくとも3つのゴールを',
        122: ' チーム得点まずに勝つザ・マッチ',
        123: ' アウェイチームには、まず、勝つザ・マッチのスコア',
        124: ' ホームチームはまず、勝つザ・マッチを獲得するために',
        128: ' 目標の数',
        129: ' /オーバーコーナーの下で - 上期',
        132: ' ターゲット上のショットオーバー/の下で',
        133: ' オーバー/アンダー総ショット',
        134: ' 目標上期の数',
        136: ' オーバー/アンダーオフサイド',
        137: ' ホーム勝利はありませんベット',
        138: ' アウェイ勝利なしベット',
        139: ' 半分ホームチームの最初のゴールを決めるのでしょうか？',
        140: ' 半分でアウェイチームはまずゴールを決めるのでしょうか？',
        143: ' 半分ホームチームではより多くのゴ​​ールを決めるのでしょうか？',
        144: ' 半分でアウェイチームはもっとゴールをだろうか？',
        145: ' 上期には目標のホームチームの番号',
        146: ' 上期には目標のアウェイチーム数',
        147: ' 下期には目標のアウェイチーム数',
        148: ' 下期には目標のホームチームの番号',
        149: ' 目標のホームチームの番号',
        150: ' 目標のアウェイチーム数',
        151: ' ダブルチャンス下期',
        153: ' ホームチームの -  /オーバー第一期の下で',
        154: ' ホームチームの -  /オーバー第二期の下で',
        155: ' /オーバー第一期の下で - アウェイチーム',
        156: ' アウェイチーム -  /オーバー第二期の下で',
        157: ' オーバー/アンダーイエローカード - 上期',
        158: ' オーバー/アンダーイエローカード',
        159: ' タイブレークでザ・マッチになりますか？',
        160: ' レース優勝',
        161: ' 10ミニッツの結果',
        162: ' 10分未満/オーバー',
        163: ' 目標下期の数',
        164: ' タイブレークでザ・ファーストに設定されますか？',
        165: ' /オーバーセットの下で',
        166: ' /以上のゲームの下で',
        168: ' オウンゴールになりますか？',
        169: ' 両方の半分で得点に',
        170: ' 奇数/偶数コーナー',
        171: ' 勝つどちらの半分に',
        175: ' タイブレークでザ・セカンドセットされますか？',
        176: ' タイブレークでザ・サード設定されますか？',
        177: ' タイブレークでザ・第4セットになりますか？',
        178: ' タイブレークでザ・フィフス設定されますか？',
        180: ' 以下の下で/オーバースローイン',
        181: ' ホームチームの -  /オーバーイエローカードの下で',
        182: ' オーバー/アンダーイエローカード - ホームチームの上期',
        183: ' ホームチームの下期 -  /オーバーイエローカードの下で',
        184: ' アウェイチーム -  /オーバーイエローカードの下で',
        185: ' アウェイチーム上期 -  /オーバーイエローカードの下で',
        186: ' アウェイチーム下期 -  /オーバーイエローカードの下で',
        187: ' オーバー/アンダーイエローカード - 下期',
        188: ' 最初のイエローカード',
        190: ' まずイエローカード下期',
        191: ' 任意のプレイヤーはハットトリックをスコアでしょうか？',
        192: ' 目標は、両方の半分で得点になりますか？',
        194: ' ホームチームAクリーンシート第一期間を維持するために',
        195: ' ホームチームがクリーンシート第二期を維持するために',
        196: ' アウェイチームAクリーンシート第一期間を維持するために',
        197: ' アウェイチームがクリーンシート第二期を維持するために',
        198: ' 奇数/偶数 - ホームチーム',
        199: ' 奇数/偶数 - アウェイチーム',
        200: ' まず得点チームの勝利だろうか？',
        201: ' アジアハンディキャップ大会',
        202: ' アウェイ第一期の勝者ホーム/',
        203: ' アウェイ第二期の勝者ホーム/',
        204: ' アウェイ第三期の勝者ホーム/',
        205: ' アウェイ第四期の勝者ホーム/',
        206: ' アウェイ第五期受賞ホーム/',
        207: ' 15ミニッツの結果',
        208: ' 30ミニッツの結果',
        209: ' 総コーナー - 上期',
        210: ' 総コーナー - 下期',
        211: ' 両チームは第二半分を獲得するために',
        212: ' 両チームは、両方の半分で得点に',
        213: ' 両方の半分でドロー',
        214: ' オーバー/アンダーカード',
        215: ' アウェイチームは上期を獲得するために',
        216: ' アウェイチームは下期を獲得するために',
        217: ' 正しいスコア第三期',
        218: ' ホームチームの第一の半分を獲得するために',
        219: ' ホームチームの第二半分を獲得するために',
        220: ' アウェイチームを含む残業 -  /オーバーの下で',
        221: ' ホームチームを含む残業 -  /オーバーの下で',
        222: ' /オーバー第三期の下で - アウェイチーム',
        223: ' ホームチームの -  /オーバー第三期の下で',
        224: ' ダブルチャンス第三期',
        225: ' ダブルチャンス第四期',
        226: ' 残業を含め12',
        227: ' レース優勝2つの場所',
        228: ' レース優勝3つの場所',
        229: ' レース優勝4つの場所',
        233: ' 勝つためにはないnilに',
        235: ' 第一5イニング受賞',
        236: ' 第一5イニングの下に/オーバー',
        237: ' 第一イニングでスコアがあるだろう',
        238: ' 残りのマッチ',
        239: ' 目標レンジ',
        242: ' 第一期奇数/偶数 - ホームチーム',
        243: ' 第一期奇数/偶数 - アウェイチーム',
        244: ' 最高のオープニングパートナーシップ',
        245: ' ゴール/いいえゴール',
        247: ' 残り上期',
        250: ' コーナーハンディキャップ - 上期',
        254: ' カードの範囲',
        255: ' 第一カードの時',
        256: ' ホームチームの - カードレンジ',
        257: ' アウェイチーム - カードレンジ',
        258: ' コーナー範囲',
        259: ' ほとんどの角を持つチーム',
        260: ' ほとんどの上期角を持つチーム',
        261: ' ほとんどの下期角を持つチーム',
        262: ' ホームチームの - コーナーレンジ',
        263: ' アウェイチーム - コーナーレンジ',
        264: ' ホームチームの - 上期コーナーレンジ',
        265: ' アウェイチーム - 上期コーナーレンジ',
        266: ' ホームチームの - 下期コーナーレンジ',
        267: ' アウェイチーム - 下期コーナーレンジ',
        269: ' 目標レンジ第一期',
        271: ' チャンスミックス',
        274: ' アウトライト受賞',
        275: ' 目標レンジ第二期',
        278: ' 目標レンジホームチーム',
        279: ' 目標はチームのアウェイレンジ',
        281: ' 第1回5イニングアジアのハンディキャップ',
        282: ' 1X2ハーフタイム',
        283: ' アジアハンディキャップ下期',
        284: ' 1X2下期',
        285: ' 奇数/偶数下期',
        286: ' 12下期',
        287: ' 第4回にわたり/の下で - ホームチーム',
        288: ' アウェイチーム - 第4回にわたり/の下で',
        289: ' 第二期奇数/偶数 - ホームチーム',
        290: ' 第三期奇数/偶数 - ホームチーム',
        291: ' 第四期奇数/偶数 - ホームチーム',
        292: ' 第二期奇数/偶数 - アウェイチーム',
        293: ' 第三期奇数/偶数 - アウェイチーム',
        294: ' 第四期奇数/偶数 - アウェイチーム',
        297: ' SP',
        299: ' ポイント受賞',
        300: ' ゲームの勝者',
        301: ' ゴール前に',
        302: ' ゴール',
        304: ' 最高得点ピリオドでチーム',
        305: ' コーナー - アンダー/正確に/オーバー',
        306: ' 最初の目標最終目標',
        307: ' ヨーロッパのハンディキャップゲーム',
        308: ' 最高得点にわたり/の下で',
        309: ' 最低得点にわたり/の下で',
        310: ' シリーズ優勝',
        317: ' 以下の下で/正確に/オーバー - 第一期',
        318: ' 以下の下で/正確に/オーバー - 第二期',
        319: ' 以下の下で/正確に/オーバー - 第三期',
        320: ' 以下の下で/正確に/オーバー - 第四期',
        322: ' 以下の下で/正確に/オーバー',
        329: ' 第一期のレースへ',
        330: ' 第二期のレースへ',
        331: ' 第三期のレースへ',
        332: ' 第四期のレースへ',
        333: ' 第五期のレースへ',
        337: ' オーバー/の下で - 下期',
        338: ' 次の目標の第一期間',
        339: ' 次の目標第2期',
        341: ' レースをします',
        342: ' アジアのハンディキャップを含む残業',
        343: ' ゲームの範囲第一期',
        344: ' ゲームの範囲第二期',
        345: ' ゲームの範囲第三期',
        346: ' ゲームの範囲第四期',
        347: ' ゲームの範囲第五期',
        348: ' 第6期受賞',
        349: ' 第7期受賞',
        350: ' 第6期奇数/偶数',
        351: ' 第7期奇数/偶数',
        352: ' 以下の下で/第六期間にわたって',
        353: ' 以下の下で/ 7期間にわたって',
        354: ' ホームチームの -  /オーバーハーフタイムの下で',
        355: ' /オーバーハーフタイムの下で - アウェイチーム',
        356: ' 解任する方法6ウェイ',
        357: ' 解任方法',
        366: ' ホームチームの - でも以上で奇数は/実行します。',
        367: ' 合計してもオーバーで奇数を/実行 - アウェイチーム',
        370: ' ホームチームの - 以上で合計ラン',
        371: ' 以上で合計ラン - アウェイチーム',
        386: ' ヨーロッパのハンディキャップを含む残業',
        387: ' 奇数/偶数 - ホームチームを含む残業',
        388: ' 奇数/偶数 - アウェイチームを含む残業',
        390: ' HT / FT含む残業',
        391: ' 次の目標 - エクストラタイム',
        392: ' ホームチームのPK戦',
        393: ' アウェイチームのペナルティシュートアウト',
        394: ' 正しいスコア第四期',
        395: ' 正しいスコア第五期',
        398: ' 奇数/偶数のカード',
        400: ' 奇数/偶数のカード上期',
        401: ' ホームチームの -  /オーバーコーナー上期の下で',
        402: ' アウェイチーム -  /オーバーコーナー上期の下で',
        403: ' オーバー/アンダーコーナー下期',
        404: ' ホームチームの -  /オーバーコーナー下期の下で',
        405: ' アウェイチーム -  /オーバーコーナー下期の下で',
        406: ' 奇数/偶数コーナー上期',
        407: ' アジアハンディキャップカード',
        408: ' アジアハンディキャップカード上期',
        409: ' 1X2コーナー',
        410: ' 1X2コーナー上期',
        411: ' 12のコーナー',
        414: ' オーバー/アンダーカード上期',
        415: ' 1X2は両チームが得点に',
        416: ' /オーバーの下で - エクストラタイム',
        417: ' エクストラタイム上期 -  /オーバーの下で',
        419: ' 1X2  - エクストラタイム',
        427: ' 1X2アンダー/オーバー',
        428: ' 複数の正しいスコア',
        430: ' 以下の下で/正確に/オーバー - 上期',
        431: ' 以下の下で/正確に/オーバー - 下期',
        433: ' ヨーロッパのハンディキャップコーナー',
        434: ' 第8期受賞',
        435: ' 第9期受賞',
        436: ' 下の/ 8期間にわたって',
        437: ' 以下の下で/第9回にわたり',
        438: ' 合計してもオーバーで/奇数を実行します。',
        439: ' 以上で総実行します',
        445: ' アウェイ第6期受賞ホーム/',
        446: ' アウェイ第7期受賞ホーム/',
        447: ' アジアハンディキャップ第6期',
        448: ' アジアハンディキャップ第7期',
        449: ' アジアハンディキャップ第8期',
        450: ' アジアハンディキャップ第9期',
        451: ' アウェイ第8期受賞ホーム/',
        452: ' アウェイ第9期の勝者ホーム/',
        453: ' 第1のスコアの方法',
        454: ' 第1のスコアの方法 - ホームチーム',
        455: ' 第1のスコアの方法 - アウェイチーム',
        456: ' ダブルチャンス第一期',
        457: ' ダブルチャンス第二期',
        459: ' ホームチームは、四半期ごとに得点に',
        460: ' アウェイチームは四半期ごとに得点に',
        462: ' トップ5受賞',
        463: ' トップ10の勝者',
        464: ' 12下期含む残業',
        465: ' アウェイ残業を含む第四期の勝者ホーム/',
        466: ' オーバー/アンダー4位期間を含む残業',
        467: ' アジアハンディキャップ4期間を含む残業',
        468: ' アジアハンディキャップ下期含む残業',
        469: ' オーバー/アンダー下期含む残業',
        472: ' シュートへの意志のゲーム行きますか？',
        475: ' ホームチームの第一イニング -  /オーバーの下でオーバーラン',
        476: ' アウェイチームの第一イニング -  /オーバーの下でオーバーを実行します。',
        477: ' ホームチームの第二イニング -  /オーバーの下でオーバーラン',
        478: ' アウェイチームの第二イニング -  /オーバーの下でオーバーを実行します。',
        479: ' ホームチームの第一イニング - 奇数/偶数でオーバーラン',
        480: ' 奇数は/においてもオーバーを実行します - アウェイチームの第一イニングを',
        481: ' ホームチームの第二イニング - 奇数/偶数でオーバーラン',
        482: ' 奇数は/においてもオーバーを実行します - アウェイチームの第二イニングを',
        483: ' ホームチーム以上でAウィケットを取得します - 第一イニングを？',
        484: ' アウェイチームのGet Aウィケット以上でウィル - 第一イニング？',
        485: ' ホームチーム以上でAウィケットを取得します - 第二イニングを？',
        486: ' 第二イニング - アウェイチームのGet Aウィケット以上でだろうか？',
        523: ' /オーバー両チームの下でのスコアに',
        524: ' 第一7イニング受賞',
        525: ' 第一7イニングアンダー/オーバー',
        526: ' 第1回7イニングアジアのハンディキャップ',
        529: ' 奇数/偶数下期含む残業',
        553: ' /オーバーシュートアウトの罰則を取るの下で',
        554: ' 12シュートアウト',
        555: ' アジアハンディキャップシュートアウト',
        556: ' 正しいスコアシュートアウト',
        557: ' オーバー/アンダーシュートアウト',
        558: ' ホームチームの -  /オーバーシュートの下で',
        559: ' /オーバーシュートの下で - アウェイチーム',
        560: ' 最後のシュートアウトペナルティを取るためにチーム',
        563: ' 残業を含むことに人種',
        564: ' 1X2アンダー/オーバー5の方法',
        566: ' アウェイ10日の期間受賞ホーム/',
        567: ' アウェイ11日の期間受賞ホーム/',
        568: ' アウェイ12日の期間受賞ホーム/',
        569: ' アウェイ13日の期間受賞ホーム/',
        570: ' アウェイ14日の期間受賞ホーム/',
        571: ' アウェイ15日の期間受賞ホーム/',
        573: ' アウェイ16日の期間受賞ホーム/',
        574: ' アウェイ17日の期間受賞ホーム/',
        575: ' アウェイ18日の期間受賞ホーム/',
        576: ' アウェイ19日の期間受賞ホーム/',
        577: ' 10分未満/オーバー - コーナー',
        578: ' /オーバーアンダー10分 - カード',
        579: ' 次のコーナー',
        586: ' 仕上げのトップ半分に',
        587: ' 下半分に終了するには',
        588: ' フィニッシュの下へ',
        589: ' しないように仕上げのトップ4',
        590: ' 降格されるように',
        591: ' 追いやられるべきではなく',
        592: ' リーチ決勝へ',
        593: ' フィニッシュでの下へ6',
        594: ' 昇格します',
        595: ' 最初のゴール時間',
        596: ' 目標レンジホームチームの第一期',
        597: ' 目標レンジホームチームの第二期',
        598: ' 目標はチームの第一期をアウェイレンジ',
        599: ' 目標はチームの第二期アウェイレンジ',
        600: ' 第一期の受賞は両チームに得点',
        601: ' HT / FTダブルチャンス',
        602: ' 残業を含め、正しいスコア',
        603: ' 負HT / FT',
        604: ' 目標レンジホームチームの両方の期間',
        605: ' 目標は、両期間をチームアウェイ範囲',
        606: ' 勝者と目標タイプワン',
        607: ' ダブルチャンスと目標',
        608: ' 勝者と目標型2',
        609: ' スコアと目標にチームの両方',
        610: ' ダブルチャンスミックス',
        628: ' 仕上げのトップへ7',
        629: ' フィニッシュでトップに6',
        630: ' 仕上げのトップへ3',
        631: ' 仕上げのトップへ2',
        632: ' 仕上げのトップへ4',
        633: ' 仕上げのトップへ5',
        634: ' 奇数は/でも、第一期を丸め',
        635: ' 奇数は/でも、第二期を丸め',
        636: ' 奇数は/でも、第三期を丸め',
        637: ' アンエースがあるでしょうか？',
        638: ' Aナイフキルがあるでしょうか？',
        639: ' 第一ラウンドの勝者',
        640: ' 第16回ラウンド受賞',
        641: ' ナイフキルありますか？ - 第一期',
        642: ' ナイフキルありますか？ - 第二期',
        643: ' ナイフキルありますか？ - 第三期',
        644: ' アンエースがあるでしょうか？ - 第一期',
        645: ' アンエースがあるでしょうか？ - 第二期',
        646: ' アンエースがあるでしょうか？ - 第三期',
        647: ' 残業があるでしょうか？ - 第一期',
        648: ' 残業があるでしょうか？ - 第二期',
        649: ' 残業があるでしょうか？ - 第三期',
        650: ' 16ラウンド受賞 - 第一期',
        651: ' 16ラウンド受賞 - 第二期',
        652: ' 16ラウンド受賞 - 第三期',
        653: ' 第一ラウンド受賞 - 第一期',
        654: ' 第一ラウンドの勝者 - 第二期',
        655: ' 第一ラウンドの勝者 - 第三期',
        660: ' ゲームの期間',
        662: ' 奇数/偶数ラウンド',
        663: ' 第一周期期間',
        664: ' 第二周期期間',
        665: ' 第三周期期間',
        666: ' 第一期 - 第一ロシャン',
        667: ' 第二期 - 第一ロシャン',
        668: ' 第三期 - 第一ロシャン',
        669: ' 第一期 - 第一ブラッド',
        670: ' 第二期 - 第一ブラッド',
        671: ' 第三期 - 第一ブラッド',
        672: ' 第一期 - 第一TOU',
        673: ' 第二期 - 第一TOU',
        674: ' 第三期 - 第一TOU',
        675: ' 第一期 - 第一バラック',
        677: ' 第二期 - 第一バラック',
        678: ' 第三期 - 第一バラック',
        679: ' 第一期 - 第一タレット',
        680: ' 第二期 - 第一タレット',
        681: ' 第三期 - 第一タレット',
        682: ' キルへのレース - 第一期',
        683: ' キルへのレース - 第二期',
        684: ' キルへのレース - 第三期',
        685: ' 第一期 - 両チームは、ドラゴンを殺すために',
        686: ' 第二期 - 両チームは、ドラゴンを殺すために',
        687: ' 第三期 - 両チームは、ドラゴンを殺すために',
        688: ' 両チームは、阻害剤を殺すために - 第一期を',
        689: ' 両チームは、阻害剤を殺すために - 第二期を',
        690: ' 両チームは、阻害剤を殺すために - 第三期を',
        691: ' 奇数/偶数キル',
        692: ' 奇数/偶数キル - 第一期',
        693: ' 奇数/偶数キル - 第二期',
        694: ' 奇数/偶数キル - 第三期',
        695: ' HT / FTと目標',
        697: ' 仕上げのトップへ8',
        711: ' プレイヤーはいつでもでは得点に',
        712: ' まずプレイヤーにスコア',
        713: ' 最後のプレイヤーにスコア',
        714: ' 2つの以上のゴールをプレーヤー',
        715: ' 3つの以上のゴールをプレーヤー',
        727: ' 第一期両チームが得点に',
        728: ' 第二期どちらのチームが得点に',
        729: ' 第三期両チームが得点に',
        730: ' 第四期両チームが得点に',
        731: ' 第一期ホームチームに得点',
        732: ' 第二期ホームチームに得点',
        733: ' 第三期ホームチームに得点',
        734: ' 第四期ホームチームに得点',
        735: ' 第一期アウェイチームにスコア',
        736: ' 第二期アウェイチームにスコア',
        737: ' 第三期アウェイチームにスコア',
        738: ' 第四期アウェイチームにスコア',
        751: ' 第1〜第ハーフ人種',
        752: ' 下期のレースに - 含む残業',
        754: ' ゲームへデュース',
        755: ' エクストラタイムコーナー - アンダー/正確に/オーバー',
        756: ' 以下の下で/過エクストラタイムコーナー',
        757: ' アジアのハンディキャップ - エクストラタイム',
        758: ' 10分未満/オーバー - フリーキック',
        759: ' 10分未満/オーバー - スローイン',
        760: ' アンダー/オーバー10分 - ゴールキック',
        762: ' 勝つザ・トスへ',
        763: ' 下に/オーバーでマッチを実行します。',
        765: ' ほとんどのフォーカードを獲得するために',
        766: ' マッチフール -  /オーバーの下で',
        767: ' マッチシックス -  /オーバーの下で',
        768: ' ザ・マッチで得点することがAフィフティー',
        769: ' ザ・マッチで得点することが百',
        770: ' ほとんどのランアウト',
        791: ' トップ打者 - ホームチーム',
        792: ' トップ打者 - アウェイチーム',
        793: ' トップ山高帽子 - ホームチーム',
        794: ' トップ山高帽子 - アウェイチーム',
        795: ' 試合のマン',
        806: ' 試合でフィフティーを獲得するために打者',
        808: ' 10の実験と人種',
        809: ' 最高の1日6オーバーのスコア',
        813: ' プレイヤーにはほとんどシックススコア',
        814: ' 個人最高点',
        819: ' する必要がAブレークポイントでザ・ゲーム',
        820: ' コーナーに人種',
        822: ' トップ新規チーム',
        824: ' プレーヤー予約が必要',
        825: ' プレイヤーオフ送信します',
        834: ' 余分なポイントセット',
        835: ' アジアのアンダー/オーバー',
        836: ' 第1回にわたりアジアの下で/',
        837: ' 上期ホームチームの勝利に無記号',
        838: ' 上期アウェイチーム勝つために無記号',
        839: ' 上期1X2アンダー/オーバー',
        840: ' 第一期ホームチームの勝利に無記号',
        841: ' 第一期アウェイチーム勝つために無記号',
        842: ' 以下の下で/正確に/オーバー - ホームチーム',
        843: ' アウェイチーム -  /正確に/オーバーの下で',
        844: ' ホーム/アウェイ - スコアへの最初のチーム',
        845: ' チームへの呼び出し初めてアウト',
        846: ' 残業を含め - ヒット数オーバー/の下で',
        849: ' 以下の下で/第5イニング以上',
        851: ' 最高得点半分チーム',
        852: ' どちらのチームが3つの未回答のタイムズを獲得するために',
        853: ' 12歳以下/以上',
        854: ' 特別チームや守備タッチダウンを採点します',
        855: ' ポイントレンジ',
        859: ' 147ブレークでマッチ',
        861: ' 第1のスコア方法3ウェイ - ホームチーム',
        862: ' 第1のスコア方法3ウェイ - アウェイチーム',
        863: ' 最高得点期間-1X2とチーム',
        864: ' チーム初得点をもたらし',
        865: ' チームの最終得点をもたらし',
        866: ' アジアハンディキャップセット',
        870: ' まずアウェイ/方法6ウェイ・ホームスコア',
        878: ' 目標レンジ第三期',
        879: ' トライオーバー/の下で',
        880: ' 上半期コーナー - アンダー/正確に/オーバー',
        881: ' 最初の目標の方法',
        882: ' ポイント数',
        891: ' 最長タッチダウンを獲得するためにチーム',
        892: ' 最長フィールドゴールを決めチーム',
        893: ' 最長のタッチダウンを得点します',
        894: ' 最短タッチダウンを得点します',
        895: ' 得点最長フィールドゴール',
        896: ' ファーストチームのゴールの時',
        897: ' 2点の後にゲームのスコア',
        898: ' 3点の後にゲームのスコア',
        899: ' 4点の後にゲームのスコア',
        900: ' ゲームへのスコア',
        901: ' 第一期リードした後、',
        902: ' 第二期リードした後、',
        903: ' 第三期リードした後、',
        904: ' 第四期リードした後、',
        905: ' 第五期リードした後、',
        906: ' 4つのゲームの後にスコア',
        907: ' 6つのゲームの後にスコア',
        908: ' ブレークにセットサーブ',
        909: ' ストレートセットでホームに勝利',
        910: ' アウェイ勝つためにストレートセットで',
        911: ' 2セットの後にスコア',
        912: ' 3セットの後にスコア',
        914: ' /オーバーフィールドゴールは得点の下で - 上期',
        915: ' 上期 - 得点/オーバータッチダウンの下で',
        916: ' /オーバーホームチームのタッチダウンの下で - 上期',
        917: ' 上期 -  /オーバーアウェイチームのタッチダウンの下で',
        920: ' /オーバータッチダウンの下で',
        921: ' 以下の下で/正確に/オーバー - タッチダウン',
        922: ' /オーバーフィールドゴールの下で',
        923: ' 以下の下で/正確に/オーバー - フィールドゴール',
        924: ' まず、フィールドゴールの時間が得点します',
        925: ' ファーストタッチダウンの時間が得点します',
        926: ' 第1のスコアの時',
        927: ' ホームチームの下で/オーバータッチダウン',
        928: ' アウェイチームの下で/オーバータッチダウン',
        929: ' ホームチームの下で/オーバーフィールドゴール',
        930: ' アウェイチームの下で/オーバーフィールドゴール',
        932: ' した最初のコーチのチャレンジにチーム',
        933: ' チームはまず受け入れられるペナルティをコミットします',
        934: ' 安全得点',
        935: ' ヨーロッパカードハンディキャップ',
        936: ' アジアのアンダー/オーバーカード',
        938: ' ホームチームの -  /オーバーカードの下で',
        939: ' アウェイチーム -  /オーバーカードの下で',
        940: ' まず、カードの時間',
        941: ' 次のプレイヤーにスコア',
        942: ' トップ国籍 - アジア',
        943: ' トップ国籍 - オーストラリア',
        944: ' トップ国籍 - ヨーロッパ',
        945: ' トップ国籍 - イギリス＆アイルランド',
        946: ' トップ国籍 - 世界の残りの部分',
        947: ' オーバー/アンダーエース',
        948: ' ホームチームの -  /オーバーエースの下で',
        949: ' エースの正確な数',
        950: ' /オーバーエースの下で - アウェイチーム',
        951: ' エースの正しい数',
        955: ' 1X2エース',
        956: ' エースの正確な数 - ホームチーム',
        957: ' エースの正確な数 - アウェイチーム',
        958: ' エースアジアのハンディキャップ',
        959: ' レースにエース',
        960: ' /オーバーエースの下で - 第一期',
        976: ' 1X2エース - 第一期',
        982: ' エースアジアのハンディキャップ - 第一期',
        986: ' 12枚のエース',
        989: ' /オーバーキルの下で - 第一期',
        990: ' /オーバーキルの下で - 第二期',
        991: ' /オーバーキルの下で - 第三期',
        992: ' 次のタッチダウン得点 - ホームチーム',
        993: ' 次のタッチダウン得点 - アウェイチーム',
        994: ' 次のタッチダウン得点',
        995: ' プレイヤーにスコアタッチダウンで、いつでも',
        996: ' 2つの以上のタッチダウンを獲得するためにプレーヤー',
        997: ' 3つの以上のタッチダウンを獲得するためにプレーヤー',
        998: ' 最初に一致したコーナー -  12',
        999: ' ラストマッチコーナー -  12',
        1000: ' セット数',
        1001: ' オーバー/アンダーダブルフォルト',
        1002: ' 以上のダブルフォルト/の下で - アウェイチーム',
        1003: ' オーバー/アンダーダブルフォルト - ホームチーム',
        1004: ' オーバー/アンダーダブルフォルト - 第一期',
        1010: ' 12のダブルフォールト',
        1011: ' 12のダブルフォールト - 第一期',
        1013: ' 1X2ダブルフォルト',
        1016: ' ダブルフォルトの正確な数',
        1019: ' ダブルフォルトの正確な数 - ホームチーム',
        1020: ' ダブルフォルトの正確な数 - アウェイチーム',
        1027: ' ダブルフォルトの正しい数',
        1030: ' 最初の隅の時間',
        1031: ' 複数のコーナー',
        1034: ' アウェイ20日の期間受賞ホーム/',
        1035: ' アウェイ21日の期間受賞ホーム/',
        1036: ' アウェイ22日の期間受賞ホーム/',
        1037: ' アウェイ23日の期間受賞ホーム/',
        1038: ' アウェイ24日の期間受賞ホーム/',
        1039: ' アウェイ25日の期間受賞ホーム/',
        1040: ' アウェイ26日の期間受賞ホーム/',
        1041: ' アウェイ27日の期間受賞ホーム/',
        1042: ' アウェイ28日の期間受賞ホーム/',
        1043: ' アウェイ29日の期間受賞ホーム/',
        1044: ' アウェイ30日の期間受賞ホーム/',
        1046: ' アウェイ第32期受賞ホーム/',
        1047: ' アウェイ第33期受賞ホーム/',
        1050: ' 20ポイントに人種',
        1051: ' ホームチームの -  /オーバー下期の下で',
        1052: ' アウェイチーム -  /オーバー下期の下で',
        1053: ' 第2回にわたりアジアの下で/',
        1054: ' 第3回にわたりアジアの下で/',
        1055: ' 第4回にわたりアジアの下で/',
        1061: ' アジアのアンダー/オーバーを含む残業',
        1062: ' 最高得点の半分 - ホームチーム',
        1063: ' 最高得点の半分 - アウェイチーム',
        1065: ' ホームチームの - 最初の得点をもたらし',
        1066: ' ホームチーム - 最終得点をもたらし',
        1067: ' アウェイチーム - 初得点をもたらし',
        1068: ' アウェイチーム - 最終得点をもたらし',
        1069: ' オーバー/アンダープレーヤーポイント',
        1070: ' /オーバープレーヤーブロックの下で',
        1071: ' オーバー/アンダープレーヤーアシスト',
        1072: ' オーバー/アンダープレーヤーリバウンド',
        1073: ' オーバー/アンダープレーヤー盗みます',
        1074: ' オーバー/アンダープレイヤーターンオーバー',
        1075: ' メイド/オーバープレーヤー3ポイントの下で',
        1076: ' /オーバープレーヤーの下に3ポイントがしようとしました',
        1077: ' メイド/オーバープレーヤー2ポイントの下で',
        1078: ' /オーバープレーヤーの下に2ポイントをしようとしました',
        1079: ' オーバー/アンダープレーヤー無料でメイドをスローします',
        1080: ' /オーバープレーヤー無料のもとしようとしました例外',
        1081: ' 1X2イエローカード',
        1082: ' ダブルチャンスイエローカード',
        1083: ' アジアハンディキャップイエローカード',
        1084: ' 正しいスコアイエローカード',
        1085: ' オーバー/アンダーレッドカード',
        1086: ' 以下の下で/正確に/オーバー予約ポイント',
        1087: ' 以下の下で/正確に/オーバー予約ポイント - ホームチーム',
        1088: ' 以下の下で/正確に/オーバー予約ポイント - アウェイチーム',
        1089: ' /オーバープレーヤー盗み、ブロックの下で',
        1090: ' オーバー/アンダープレーヤーポイント',
        1091: ' /オーバープレーヤー支援し、リバウンドの下で',
        1092: ' /オーバープレイヤーポイントとリバウンドの下で',
        1093: ' オーバー/アンダープレイヤーポイントを助けます',
        1094: ' プレイヤーダブルダブル',
        1095: ' プレイヤートリプルダブル',
        1096: ' 以下の下で/正確に/オーバー予約ポイント - 第一期',
        1100: ' セットの勝者',
        1101: ' セットの中/ \ 180以上の下で',
        1102: ' セット/脚受賞',
        1103: ' セット/脚総チェックアウトアンダー/オーバー',
        1105: ' ホームチームの -  /オーバー合計180 \ sの下で',
        1106: ' アウェイチーム -  /オーバー合計180 \ sの下で',
        1112: ' /オーバー選手ゴールの下で',
        1115: ' /オーバー選手ゴール下で可',
        1120: ' 第一期 - ラウンドオーバー/の下で',
        1121: ' 第二期 - ラウンドオーバー/の下で',
        1122: ' 回のラウンドで/の下に - 第三期',
        1123: ' 第四期 - 第一ブラッド',
        1124: ' 第五期 - 第一ブラッド',
        1125: ' 両チームドラゴンにキル',
        1126: ' 両チームバロンにキル',
        1127: ' 両チーム阻害剤に対するキル',
        1128: ' オーバー/アンダーバロンズ殺害されました',
        1129: ' /オーバーバロンズ殺害の下で - 第一期',
        1130: ' /オーバーバロンズ殺害の下で - 第二期',
        1131: ' /オーバーバロンズ殺害の下で - 第三期',
        1132: ' オーバー/アンダードラゴンズ殺害されました',
        1133: ' 第一期 -  /オーバードラゴンズ殺害されたの下で',
        1134: ' /オーバードラゴンズ殺害の下で - 第二期',
        1135: ' /オーバードラゴンズ殺害の下で - 第三期',
        1136: ' オーバー/アンダー180 \ sの',
        1137: ' まず180',
        1138: ' 最後の180',
        1139: ' 最高のチェックアウトスコアオーバー/の下で',
        1140: ' ほとんど180 \ sの',
        1141: ' 最高のチェックアウトスコアオーバー/の下で - ホームチーム',
        1142: ' アウェイチーム - 最高アウトスコアオーバー/の下で',
        1143: ' まずプレイヤーにスコアのタッチダウン',
        1144: ' 最後のプレイヤーにスコアのタッチダウン',
        1145: ' タッチダウンを獲得するために最初のプレーヤー - ホームチーム',
        1146: ' 最初のプレーヤーがタッチダウンを獲得するために - アウェイチームを',
        1147: ' /オーバーキルの下で - 第四期',
        1148: ' /オーバーキルの下で - 第五期',
        1149: ' アジアハンディキャップキル - 第一期',
        1150: ' アジアハンディキャップキル - 第二期',
        1151: ' アジアハンディキャップキル - 第三期',
        1152: ' アジアハンディキャップキル - 第四期',
        1153: ' アジアハンディキャップキル - 第五期',
        1154: ' 以下の下で/正確に/オーバー180 \ sの',
        1155: ' 以下の下で/正確に/オーバー合計180 \ sの - ホームチーム',
        1156: ' 正確に/オーバー合計180 /下の\ sの - アウェイチーム',
        1157: ' ほとんどのチェックアウト',
        1158: ' まず試し得点',
        1159: ' 最後のトライ得点',
        1160: ' いつでも得点してみてください',
        1162: ' 第一期 - デストロイTOUSオーバー/の下で',
        1163: ' 破壊された/オーバーTOUSの下で - 第二期',
        1164: ' 破壊された/オーバーTOUSの下で - 第三期',
        1165: ' 第一期 - 第一男爵',
        1166: ' 第二期 - 第一男爵',
        1167: ' 第三期 - 第一男爵',
        1168: ' 第四期 - 第一男爵',
        1169: ' 第五期 - 第一男爵',
        1170: ' 第一期 - 第一竜',
        1171: ' 第二期 - 第一竜',
        1172: ' 第三期 - 第一竜',
        1173: ' 第四期 - 第一竜',
        1174: ' 第五期 - 第一竜',
        1175: ' ほとんど撃破 - 第一期',
        1176: ' ほとんど撃破 - 第二期',
        1177: ' ほとんど撃破 - 第三期',
        1178: ' ほとんど撃破 - 第四期',
        1179: ' ほとんど撃破 - 第五期',
        1180: ' ほとんど撃破 - 第6期',
        1181: ' ほとんど撃破 - 第7期',
        1182: ' 2以上Trysを獲得するためにプレーヤー',
        1183: ' プレイヤーには、3つ以上のTrysスコア',
        1184: ' しないように勝つザ・Leauge',
        1185: ' 残業の下で/正確に/上を含む - ホームチーム',
        1186: ' 以下の下で/正確に/上を含む残業 - アウェイチーム',
        1187: ' 以下の下で/正確に/上を含む残業',
        1188: ' オーバー/アンダー置換',
        1189: ' オーバー/アンダー置換 - 第一期',
        1190: ' ホームチーム - 最終Playerがタッチダウンを獲得するために',
        1191: ' 最終Playerがタッチダウンを獲得するために - アウェイチームを',
        1192: ' 最初のプレイヤーには、エースをヒット',
        1193: ' オーバー/アンダープレーヤークォーターバックパッシングヤード',
        1194: ' オーバー/アンダー選手がタッチダウンを渡します',
        1195: ' オーバー/アンダー最長プレイヤーパス完成',
        1196: ' オーバー/アンダーPlayerはヤードを受信します',
        1197: ' オーバー/アンダープレーヤーインターセプト',
        1198: ' オーバー/アンダープレイヤーはポイントを蹴ります',
        1199: ' オーバー/アンダーPlayerはヤードを急い＆受信します',
        1200: ' オーバー/アンダーPlayerはヤードを急い',
        1201: ' オーバー/アンダープレーヤー最長レセプション',
        1202: ' オーバー/アンダープレイヤーレセプション',
        1203: ' メイド/オーバープレイヤーのフィールドゴールの下で',
        1204: ' 未遂/オーバープレイヤーのフィールドゴールの下で',
        1205: ' オーバー/アンダープレーヤークォーター渡すタッチダウン',
        1206: ' オーバー/アンダープレーヤーレシーバーの受信完了数',
        1207: ' オーバー/アンダープレーヤークォーターバックが完了渡し',
        1208: ' オーバー/アンダーPlayerがタッチダウンを急い',
        1209: ' オーバー/アンダープレーヤーレシーバーレセプション試み',
        1210: ' オーバー/アンダープレーヤークォーターバックが試み渡し',
        1211: ' 1X2ファウル',
        1212: ' ダブルチャンスファウル',
        1213: ' /オーバーファウルの下で - 第一期',
        1214: ' /オーバーファウルの下で - 第二期',
        1215: ' アジアハンディキャップファウル',
        1216: ' ホームチームの -  /オーバーファウルの下で',
        1217: ' アウェイチーム -  /オーバーファウルの下で',
        1218: ' オーバー/アンダープレーヤーラッシュの試み',
        1219: ' オーバー/アンダーPlayerがタッチダウンを受けます',
        1222: ' ホームチームの -  /オーバースローインの下で',
        1223: ' /オーバースローインの下で - アウェイチーム',
        1224: ' 1X2スローイン',
        1225: ' ダブルチャンススローイン',
        1226: ' /オーバースローインの下で - 第一期',
        1227: ' スローインのハンディキャップ',
        1228: ' スローインハンディキャップ - 第一期を',
        1229: ' ホームチーム - ターゲット上のショットオーバー/の下で',
        1230: ' アウェイチーム - ターゲット上のショットオーバー/の下で',
        1231: ' ターゲット上のアジアのハンディキャップショット',
        1232: ' ターゲット上でアジアのハンディキャップショット - 第一期',
        1233: ' 第一期 - ターゲットオンショットオーバー/の下で',
        1234: ' ターゲット上の1X2ショット',
        1235: ' ターゲットをダブルチャンスショット',
        1236: ' オーバー/アンダー総ショット - ホームチーム',
        1237: ' /オーバー総ショットの下で - アウェイチーム',
        1238: ' ダブルチャンス総ショット',
        1239: ' 1X2総ショット',
        1240: ' アジアハンディキャップの合計ショット',
        1241: ' アジアハンディキャップの合計ショット - 第一期',
        1242: ' オーバー/アンダー総ショット - 第一期',
        1243: ' ダブルチャンスコーナー',
        1244: ' ダブルチャンスコーナー - 第一期',
        1245: ' ピッチのすべての4つのコーナーからコーナー',
        1246: ' ダブルチャンスイエローカード - 第一期',
        1247: ' 1X2イエローカード - 第一期',
        1248: ' アジアハンディキャップイエローカード - 第一期',
        1249: ' ダブルチャンス＆アンダー/オーバー',
        1250: ' ダブルチャンス＆両チームのスコアに',
        1251: ' 第一期 - 第一阻害剤',
        1252: ' 第二期 - 第一阻害剤',
        1253: ' 第三期 - 第一阻害剤',
        1254: ' 第四期 - 第一阻害剤',
        1255: ' 第五期 - 第一阻害剤',
        1256: ' どちらのチームにキルバロン - 第一期',
        1257: ' どちらのチームにキルバロン - 第二期',
        1258: ' どちらのチームにキルバロン - 第三期',
        1259: ' どちらのチームにキルバロン - 第四期',
        1260: ' どちらのチームにキルバロン - 第五期',
        1261: ' 第四期 - 両チームは、ドラゴンを殺すために',
        1262: ' 第五期 - 両チームは、ドラゴンを殺すために',
        1263: ' 両チームは、阻害剤を殺すために - 第四期を',
        1264: ' 両チームは、阻害剤を殺すために - 第五期を',
        1265: ' アンエース次の試合があるでしょうか？',
        1266: ' ダブルフォルト次の試合があるでしょうか？',
        1267: ' 奇数/偶数ファウル',
        1268: ' アジアハンディキャップボール支配率',
        1269: ' ダブルチャンスオフサイド',
        1270: ' アジアハンディキャップオフサイド',
        1271: ' ホームチームの -  /オーバーオフサイドの下で',
        1272: ' アウェイチーム -  /オーバーオフサイドの下で',
        1273: ' 1X2オフサイド',
        1274: ' ホームチームの -  /オーバーキル第一期の下で',
        1275: ' ホームチームの -  /オーバーキル第二期の下で',
        1276: ' ホームチームの -  /オーバーキル第三期の下で',
        1277: ' /オーバーキル第一期の下で - アウェイチーム',
        1278: ' アウェイチーム -  /オーバーキル第二期の下で',
        1279: ' /オーバーキル第三期の下で - アウェイチーム',
        1280: ' オーバー/アンダーWides',
        1281: ' アヒルオーバー/の下で',
        1282: ' /オーバーウィケットの下で',
        1283: ' ホームチームの -  /オーバーマッチフールの下で',
        1284: ' ホームチームの -  /オーバーマッチシックスの下で',
        1285: ' アウェイチーム -  /オーバーマッチフールの下で',
        1286: ' アウェイチーム -  /オーバーマッチシックスの下で',
        1287: ' オーバー/アンダーエクストラ',
        1288: ' 第一Wicketの秋 - ホームチーム',
        1289: ' 第一Wicketの秋 - アウェイチーム',
        1290: ' トップ打者チーム',
        1291: ' /オーバーマッチシックスホームチームの下で - 第一イニング',
        1292: ' /オーバーマッチシックスアウェイチームの下で - 第一イニング',
        1293: ' /オーバーマッチフールホームチームの下で - 第一イニング',
        1294: ' 第一イニング - アウェイチーム/オーバーマッチフールの下で',
        1295: ' オーバー/アンダートップ打者',
        1296: ' 解雇以上の第一イニング第一 - ホームチーム',
        1297: ' 解雇以上の第一イニング2日 - ホームチーム',
        1298: ' 第一イニング第三以上の解任 - ホームチーム',
        1299: ' 第一イニング第二以上の解任 - アウェイチーム',
        1300: ' 第一イニング第三以上の解任 - アウェイチーム',
        1301: ' 第一イニング1以上の解任 - アウェイチーム',
        1302: ' 最高得点オーバー - アンダー/オーバー',
        1303: ' 第一イニング第二オーバーアンダー/オーバー - ホームチーム',
        1304: ' 第一イニング第三オーバーアンダー/オーバー - ホームチーム',
        1306: ' 第一イニング第三オーバーアンダー/オーバー - アウェイチーム',
        1307: ' 第一イニング第二オーバーアンダー/オーバー - アウェイチーム',
        1309: ' マージンを獲得 - チーム',
        1310: ' 勝利マージン - 残業を含めチーム',
        1312: ' 正確なマージンを受賞',
        1314: ' マージンの範囲を受賞',
        1315: ' 正確なマージンを獲得 - チーム',
        1316: ' 任意の他のスコアがチーム - マージンを受賞',
        1318: ' アジアハンディキャップファウル - 第一期',
        1319: ' アジアハンディキャップオフサイド - 第一期',
        1320: ' アジアハンディキャップオフサイド - 第二期',
        1321: ' アジアハンディキャップファウル - 第二期',
        1322: ' ターゲット上でアジアのハンディキャップショット - 第二期',
        1324: ' /オーバーオフサイドの下で - 第一期',
        1325: ' /オーバーオフサイドの下で - 第二期',
        1326: ' ファーストファウル',
        1327: ' 最初の二つのポインタ',
        1328: ' 最初の三つのポインタ',
        1329: ' 最後の二つのポインタ',
        1330: ' 最後の三つのポインタ',
        1331: ' 最後のフリースローで得点します',
        1332: ' 最初のフリースローで得点します',
        1333: ' まずリバウンド',
        1334: ' 最後のタイムアウト',
        1335: ' 勝利マージンチーム - 第一期',
        1336: ' 勝利マージンチーム - 第二期',
        1337: ' 勝利マージンチーム - 第三期',
        1338: ' 第四期 - マージン勝利チームを',
        1339: ' 第五期 - マージン勝利チームを',
        1340: ' 最後の反則',
        1341: ' オーバー/アンダー10期',
        1342: ' オーバー/アンダー11日の期間',
        1343: ' オーバー/アンダー12日の期間',
        1344: ' オーバー/アンダー13日の期間',
        1345: ' オーバー/アンダー14日の期間',
        1346: ' オーバー/アンダー15日の期間',
        1347: ' オーバー/アンダー16日の期間',
        1348: ' オーバー/アンダー17日の期間',
        1349: ' オーバー/アンダー18日の期間',
        1350: ' オーバー/アンダー19期',
        1351: ' オーバー/アンダー20日の期間',
        1352: ' オーバー/アンダー21期',
        1353: ' オーバー/アンダー22日の期間',
        1354: ' 23日の期間にわたって/の下で',
        1355: ' オーバー/アンダー24日の期間',
        1356: ' オーバー/アンダー25日の期間',
        1357: ' オーバー/アンダー26日の期間',
        1358: ' オーバー/アンダー27日の期間',
        1359: ' オーバー/アンダー28日の期間',
        1360: ' オーバー/アンダー29日の期間',
        1361: ' オーバー/アンダー30期',
        1362: ' オーバー/アンダー31日の期間',
        1363: ' オーバー/アンダー32分の期間',
        1364: ' 第33回にわたって/の下で',
        1365: ' オーバー/アンダー第34期',
        1366: ' オーバー/アンダー35期',
        1367: ' アジアハンディキャップ11日の期間',
        1368: ' アジアハンディキャップ12日の期間',
        1369: ' アジアハンディキャップ13日の期間',
        1370: ' アジアハンディキャップ14日の期間',
        1371: ' アジアハンディキャップ15日の期間',
        1372: ' アジアハンディキャップ16日の期間',
        1373: ' アジアハンディキャップ17日の期間',
        1374: ' アジアハンディキャップ18期',
        1375: ' アジアハンディキャップ19期',
        1376: ' アジアハンディキャップ20日の期間',
        1380: ' アジアハンディキャップ24日の期間',
        1381: ' アジアハンディキャップ25日の期間',
        1382: ' アジアハンディキャップ26日の期間',
        1383: ' アジアハンディキャップ27日の期間',
        1384: ' アジアハンディキャップ28日の期間',
        1385: ' アジアハンディキャップ29日の期間',
        1386: ' アジアハンディキャップ30日の期間',
        1390: ' アジアハンディキャップ第34期',
        1391: ' アジアハンディキャップ35期',
        1392: ' フィニッシュでトップ10に',
        1393: ' 滞在まで',
        1394: ' アジアハンディキャップ10期',
        1395: ' オーバー/アンダープレーヤーパワープレイポイント',
        1396: ' オーバー/アンダープレイヤープラス/マイナス',
        1397: ' オーバー/アンダープレイヤーショット',
        1398: ' トップビクトリア朝のクラブ',
        1399: ' /オーバーランアウトの下で',
        1400: ' オーバー/アンダーフレーム',
        1401: ' トップ20勝者',
        1402: ' 第一ラウンドリーダー',
        1404: ' 第一ラウンドシックス・シューター - グループA',
        1406: ' 第一ラウンド3ボール',
        1407: ' 第一ラウンド18ホールのマッチアップ',
        1408: ' マッチハンディキャップ',
        1409: ' チームとトップボウラー',
        1410: ' ウィンザ・トスと一致するように、',
        1411: ' オーバー/アンダータレットを破壊',
        1412: ' 第一期 - デストロイ/オーバータレットの下で',
        1413: ' 第二期 - デストロイ/オーバータレットの下で',
        1414: ' 第三期 - デストロイ/オーバータレットの下で',
        1415: ' 第1回以上の後に最高スコア',
        1416: ' 6日以上に1以上を - ホームチームの下で/オーバーラン',
        1417: ' アウェイチームの下で/オーバーラン -  6オーバーの第一オーバー',
        1418: ' 8日以上に1以上を - ホームチームの下で/オーバーラン',
        1419: ' アウェイチームの下で/オーバーラン -  8以上に第一オーバー',
        1420: ' ホームチームの下では、/オーバーラン - 第一オーバーを10日以上に',
        1421: ' アウェイチームの下で/オーバーラン -  10以上の第一オーバー',
        1422: ' チェイスオーバー180で実行され、チームに勝つために',
        1423: ' どのように多くのランは、ザ・ファーストボールから得点されます',
        1424: ' オーバー/アンダーマッチStumpings',
        1425: ' 第一Wicketの方法',
        1426: ' 試合の第1ボール',
        1427: ' オーバー/の下に - 第一イニング',
        1428: ' トップ以外のビクトリア朝のクラブ',
        1429: ' グランドファイナル優勝',
        1431: ' グランドファイナルに到達するために',
        1432: ' しないようにフィニッシュでトップ8',
        1434: ' アウェイチーム -  /オーバーキル第四期の下で',
        1435: ' アウェイチーム -  /オーバーキル第五期の下で',
        1436: ' /オーバーキル第四期の下で - ホームチーム',
        1437: ' /オーバーキル第五期の下で - ホームチーム',
        1438: ' 空港での温度（摂氏）以上/の下で',
        1439: ' アジアのハンディキャップ - フルタイム',
        1440: ' /オーバーバリューの下にあるすべての数字の描かれました',
        1441: ' すべての奇数番号の/オーバーバリューの下に描かれました',
        1442: ' すべての偶数/オーバーバリューの下に描かれました',
        1443: ' もっと偶数奇数番号描かれていたより',
        1444: ' 連続した番号は、描画されます',
        1445: ' 正確に/オーバー/の下で - 奇数の数が描かれたボールを番号付き',
        1446: ' 以下の下で/正確に/オーバー - 描かれた偶数番号のボールの数',
        1447: ' 5回のボーナスボールの合計',
        1448: ' 偶数奇数を/  - 描かれた最初のボーナスボール',
        1449: ' 偶数奇数を/  - 最後に描画ボーナスボール',
        1450: ' 最初のボーナスボール',
        1451: ' 最後のボーナスボール',
        1452: ' 5つのボーナスボールの最低',
        1453: ' ボーナスボール - 奇数/偶数',
        1454: ' 同一数字は描画されます',
        1455: ' 最も多く描かれた - 奇数/偶数',
        1456: ' 最低数描か - 奇数/偶数',
        1457: ' ボール1  - アンダー/オーバー',
        1458: ' ボール1  - 奇数/偶数',
        1459: ' ボール2  - アンダー/オーバー',
        1460: ' ボール2  - 奇数/偶数',
        1461: ' ボール3  - アンダー/オーバー',
        1462: ' ボール3  - 奇数/偶数',
        1463: ' ボール4  - アンダー/オーバー',
        1464: ' ボール4  - 奇数/偶数',
        1465: ' ボール5  - アンダー/オーバー',
        1466: ' ボール5  - 奇数/偶数',
        1467: ' ボール6  - アンダー/オーバー',
        1468: ' ボール6  - 奇数/偶数',
        1469: ' ボール7  - アンダー/オーバー',
        1470: ' ボール7  - 奇数/偶数',
        1471: ' ボール8  - アンダー/オーバー',
        1472: ' ボール8  - 奇数/偶数',
        1473: ' ボール9  - アンダー/オーバー',
        1474: ' ボール9  - 奇数/偶数',
        1475: ' ボール10  - アンダー/オーバー',
        1476: ' ボール10  - 奇数/偶数',
        1477: ' ボール11  - アンダー/オーバー',
        1478: ' ボール11  - 奇数/偶数',
        1479: ' ボール12  - アンダー/オーバー',
        1480: ' ボール12  - 奇数/偶数',
        1481: ' ボール13  - アンダー/オーバー',
        1482: ' ボール13  - 奇数/偶数',
        1483: ' ボール14  - アンダー/オーバー',
        1484: ' ボール14  - 奇数/偶数',
        1485: ' ボール15  - アンダー/オーバー',
        1486: ' ボール15  - 奇数/偶数',
        1487: ' ボール16  - アンダー/オーバー',
        1488: ' ボール16  - 奇数/偶数',
        1489: ' ボール17  - アンダー/オーバー',
        1490: ' ボール17  - 奇数/偶数',
        1491: ' ボール18  - アンダー/オーバー',
        1492: ' ボール18  - 奇数/偶数',
        1493: ' ボール19  - アンダー/オーバー',
        1494: ' ボール19  - 奇数/偶数',
        1495: ' ボール20  - アンダー/オーバー',
        1496: ' ボール20  - 奇数/偶数',
        1497: ' ある可分で描かれた番号のうちの少なくとも一方3',
        1498: ' ある可分で描かれた番号のうちの少なくとも一方4',
        1499: ' ある可分で描かれた番号のうちの少なくとも一方5',
        1500: ' ある可分で描かれた番号のうちの少なくとも一方7',
        1501: ' 10です可分を引かれた番号のうちの少なくとも一つ',
        1502: ' 15です可分を引かれた番号のうちの少なくとも一つ',
        1503: ' 20です可分を引かれた番号のうちの少なくとも一つ',
        1504: ' 最も多く描かれた - アンダー/オーバー',
        1505: ' 描かれた最低と最高の数字の合計値 - アンダー/オーバー',
        1506: ' 最低数が描か - アンダー/オーバー',
        1507: ' 最高と最低の数値の差が描か - アンダー/オーバー',
        1508: ' 描かれた最低と最高の数字の合計値 - 偶数奇数/',
        1509: ' 偶数奇数/  - 最高描画された最低数の差',
        1510: ' 最初の数字が描かれより大きいラストナンバーを引かれます',
        1511: ' 最後の番号が描か - 偶数奇数を/',
        1512: ' 最初の数字が描か - 偶数奇数を/',
        1513: ' すべての描かれた数字であっても',
        1514: ' すべての描かれた数字奇数',
        1515: ' NUMBERにドロー',
        1520: ' 第四期 - 第一タレット',
        1521: ' 第五期 - 第一タレット',
        1523: ' /オーバーバロンズ殺害の下で - 第四期',
        1524: ' /オーバーバロンズ殺害の下で - 第五期',
        1525: ' 第四期 -  /オーバードラゴンズ殺害されたの下で',
        1526: ' 第五期 -  /オーバードラゴンズ殺害されたの下で',
        1527: ' 第四期 - デストロイ/オーバータレットの下で',
        1528: ' 第五期 - デストロイ/オーバータレットの下で',
        1529: ' 第四周期期間',
        1530: ' 第五周期期間',
        1531: ' 奇数/偶数キル - 第四期',
        1532: ' 奇数/偶数キル - 第五期',
        1533: ' オーバー/アンダー阻害剤 - 第一期',
        1534: ' オーバー/アンダー阻害剤 - 第二期',
        1535: ' 範囲内の空港での温度（摂氏）',
        1536: ' オーバー/アンダー地図',
        1537: ' ホームチームに勝利＆アンダー/オーバー - 残業を含みます',
        1538: ' アウェイチームに勝利＆アンダー/オーバー - 残業を含みます',
        1539: ' ラウンド受賞',
        1540: ' アンダー/オーバー合計マッチ美脚',
        1541: ' ラウンドハンディキャップ',
        1542: ' 第1戦の勝者',
        1543: ' 最初に勝つ3脚',
        1546: ' 第四期 - 第一ロシャン',
        1547: ' 第五期 - 第一ロシャン',
        1548: ' 第四期 - 第一バラック',
        1549: ' 第五期 - 第一バラック',
        1550: ' 第四期 - 第一TOU',
        1551: ' 第五期 - 第一TOU',
        1552: ' アジアのアンダー/オーバーコーナー',
        1553: ' 第一期 - 両チームは、ポイント数を獲得するために',
        1554: ' 上期 - 両チームは、ポイント数を獲得するために',
        1555: ' 第5回にわたり/の下で - ホームチーム',
        1556: ' アウェイチーム - 第5回にわたり/の下で',
        1558: ' アジアハンディキャップのポイント',
        1559: ' カップ優勝',
        1561: ' 第一3イニング受賞',
        1562: ' 第一3イニングの下に/オーバー',
        1563: ' 得点の時',
        1564: ' 得点の時 - ホームチーム',
        1565: ' 得点の時 - アウェイチーム',
        1566: ' マッチタイム結果',
        1567: ' トップ国籍 - 南アフリカ',
        1568: ' トップ国籍 - スペイン人',
        1569: ' トップ国籍 - アイルランド',
        1570: ' トップ国籍 - 南アメリカ',
        1571: ' トップ国籍 - カナダ',
        1572: ' トップ国籍 - イギリス',
        1573: ' トップ国籍 - 欧州大陸',
        1575: ' カットを作るために',
        1576: ' ミスカットします',
        1577: ' トップ国籍 - アメリカ',
        1578: ' 受賞者の国籍',
        1579: ' アジアハンディキャップのポイント - 第一期',
        1580: ' アジアハンディキャップのポイント - 第二期',
        1581: ' アジアハンディキャップのポイント - 第三期',
        1583: ' トップチーム山高帽子第一イニング - ホームチーム',
        1584: ' トップチーム山高帽子第一イニング - アウェイチーム',
        1585: ' トップチーム打者第一イニング - ホームチーム',
        1586: ' トップチーム打者第一イニング - アウェイチーム',
        1587: ' エース次のポイントがあるだろう',
        1589: ' ダブルフォルト次のポイントがあるだろう',
        1591: ' /オーバーの下で - 第1回以上',
        1592: ' 勝つリーグへ',
        1593: ' 勝つ課へ',
        1594: ' 残業を含め - ほとんどのヒット数',
        1595: ' ゴーザ・距離にファイト',
        1596: ' ときファイトエンドウィル',
        1597: ' ファイト成果',
        1598: ' ラウンドベット',
        1599: ' トップ第一ヒット',
        1600: ' ボトム第一ヒット',
        1601: ' トップ第一ラン',
        1602: ' ボトム第一ラン',
        1603: ' 第一イニングで最もヒット',
        1605: ' 最高得点イニングでチーム',
        1606: ' 両チームは2つの以上のゴールを',
        1607: ' 両チームは3つの以上のゴールを',
        1608: ' チームに勝つザ・ほとんどの期間',
        1609: ' アジアハンディキャップフルタイム - 第一期',
        1610: ' アジアハンディキャップフルタイム - 第二期',
        1611: ' アジアハンディキャップフルタイム - 第三期',
        1612: ' MVP',
        1614: ' 勝つ会議に',
        1615: ' 会議を受賞',
        1617: ' シーズンプレイヤースペシャル',
        1618: ' 第一5イニング受賞 -  12',
        1619: ' 第一期 - ハーフタイムの勝者ホーム/アウェイ',
        1620: ' 第一期 - ハーフタイムアジアンハンディキャップ',
        1621: ' 第二期 - ハーフタイムの勝者ホーム/アウェイ',
        1622: ' 第二期 - ハーフタイムアジアンハンディキャップ',
        1624: ' /オーバープレイヤーアンダーラン',
        1625: ' オーバー/アンダープレーヤーシングルス',
        1626: ' オーバー/アンダー選手のホームラン',
        1627: ' オーバー/アンダープレーヤー盗ま拠点',
        1628: ' オーバー/アンダープレーヤーダブルス',
        1629: ' オーバー/アンダープレイヤートリプル',
        1630: ' 録音ザ・勝つためにピッチャー',
        1631: ' オーバー/アンダープレーヤーの合計拠点',
        1635: ' 獲得/オーバーピッチャーのアンダーラン',
        1636: ' 可ピッチャーのヒット数オーバー/の下で',
        1637: ' 投手の奪三振オーバー/の下で',
        1638: ' チームの分類',
        1639: ' 若いライダーの分類',
        1640: ' ポイント分類',
        1641: ' 山岳賞',
        1642: ' 第1ステージ',
        1649: ' 最速ラップ',
        1650: ' 受賞車',
        1651: ' 引退する第一ドライバ',
        1652: ' 予選優勝車',
        1653: ' 練習で最速1',
        1654: ' ポイントフィニッシュ',
        1655: ' 最速予選',
        1657: ' ステージ3',
        1659: ' 最初のコンストラクタ退職',
        1660: ' 勝者のグリッドポジション',
        1661: ' レース中にセーフティカーピリオド',
        1662: ' クラシファイドドライバ数',
        1663: ' レース中に仮想セーフティカーピリオド',
        1664: ' ホールスコア',
        1667: ' 勝つホールへ',
        1670: ' タイドマッチ',
        1672: ' /オーバー目標の下で - 下期',
        1673: ' 奇数は/でも、第四期を丸め',
        1674: ' 奇数は/でも第五期を丸め',
        1675: ' 第二ラウンドのリーダー',
        1676: ' 両チームは得点に - 含む残業',
        1677: ' スコアへの最後のチーム - 残業を含みます',
        1678: ' トップ国籍 - ドイツ',
        1679: ' トップ国籍 - オーストリア',
        1680: ' トップ国籍 - ニュージーランド',
        1681: ' トップ左利きプレーヤー',
        1682: ' トップアマチュア',
        1683: ' トップ国籍 - 韓国',
        1684: ' トップ国籍 - スカンジナビア',
        1685: ' トップ国籍 - フランス',
        1686: ' トップ元受賞',
        1687: ' トップ国籍 - スウェーデン人',
        1688: ' トップ国籍 - 日本',
        1689: ' トップ国籍 - スコットランド',
        1690: ' トップ国籍 - ベルギー',
        1691: ' ヨーロッパのハンディキャップ第四期',
        1692: ' ヨーロッパのハンディキャップ第三期',
        1693: ' ヨーロッパのハンディキャップ第二期',
        1694: ' ヨーロッパのハンディキャップ第一期',
        1695: ' ラウンドスコア',
        1696: ' トーナメント優勝スコア',
        1697: ' ラウンド後にスコアをリードします',
        1698: ' フェアウェイウッドでティーショットを打つために',
        1699: ' グリーンで規制をヒットします',
        1700: ' 第三ラウンドリーダー',
        1702: ' オーバー/アンダーPlayerが試みを渡します',
        1704: ' 第一期のタッチダウン',
        1705: ' 第二期のタッチダウン',
        1706: ' 第三期のタッチダウン',
        1707: ' 第四期のタッチダウン',
        1708: ' 以下の下で/正確に/オーバー - ホームチームのタッチダウン',
        1709: ' 以下の下で/正確に/オーバー - アウェイチームのタッチダウン',
        1710: ' 以下の下で/正確に/オーバー - ホームチームの上期のタッチダウン',
        1711: ' 以下の下で/正確に/オーバー - アウェイチーム上期タッチダウン',
        1712: ' 16ラウンド受賞 - 第四期',
        1713: ' 16ラウンド受賞 - 第五期',
        1714: ' 破壊された/オーバーTOUSの下で - 第四期',
        1715: ' 破壊された/オーバーTOUSの下で - 第五期',
        1722: ' nilに完了に設定',
        1723: ' /オーバーキルの下で - 第1期 - 第一ラウンド',
        1724: ' 第二期 -   - 第一ラウンド/オーバーキルの下で',
        1728: ' 両チームは得点に - 余分な時間',
        1729: ' ダブルチャンス - エキストラ時間',
        1730: ' ゲーム[移動スーパーオーバーウィル？',
        1731: ' ダブルチャンス第五期',
        1732: ' ダブルチャンス第6期',
        1733: ' ダブルチャンス第7期',
        1734: ' ダブルチャンス第8期',
        1736: ' ラン第一イニング数',
        1737: ' 数は、第二イニングを実行します。',
        1738: ' ラン第三イニング数',
        1739: ' 数は、4イニングを実行します。',
        1740: ' 数は、第5イニングを実行します。',
        1741: ' 数は、第6イニングを実行します。',
        1742: ' 数は、7イニングを実行します。',
        1743: ' 数は、8イニングを実行します。',
        1744: ' 第一イニングで得点するAフィフティー',
        1745: ' 第一Wicketの秋',
        1747: ' 次マンアウト',
        1748: ' 完成したマッチ',
        1749: ' センチュリー得点',
        1750: ' センチュリー得点 - 第一イニング',
        1752: ' アウェイチーム初の解任6ウェイ',
        1753: ' トップマッチ打者',
        1754: ' ペナルティを獲得するために',
        1758: ' 今年の第六男',
        1760: ' 新人王',
        1761: ' レギュラーシーズンMVP',
        1763: ' 第一イニングで百を獲得するために打者',
        1765: ' 試合で9ダーツフィニッシュ',
        1766: ' まず試しの時間',
        1767: ' 最後のトライの時間',
        1770: ' 試合で9ダーツフィニッシュ - ホームチーム',
        1771: ' 試合で9ダーツフィニッシュ - アウェイチーム',
        1772: ' トップラン得点',
        1773: ' トップラン得点 - ホームチーム',
        1774: ' トップラン得点 - アウェイチーム',
        1775: ' トップラン得点のチーム',
        1776: ' オーバー/アンダーパント',
        1777: ' オーバー/アンダーサックス',
        1778: ' 以下の下で/第一ダウンズオーバー',
        1779: ' Aまず、ダウンを取得するために第一チーム',
        1780: ' 上期の下で/オーバーフィールドゴール - ホームチーム',
        1781: ' 上期の下で/オーバーフィールドゴール - アウェイチーム',
        1782: ' 以下の下で/正確に/オーバー - フィールドゴール上期',
        1783: ' オーバー/アンダータッチダウン第一期',
        1784: ' 受け入れ罰則オーバー/の下で',
        1785: ' マッチのほとんどのキル',
        1786: ' マッチのほとんどのキルとチーム',
        1787: ' オーバー/アンダーパスの試み',
        1788: ' 以下の下/上を通過するさまざまな補完',
        1789: ' ヤードを渡すオーバー/の下で',
        1790: ' オーバー/アンダーラッシュの試み',
        1791: ' オーバー/アンダーラッシュヤード',
        1792: ' オーバー/アンダープレーヤータックルとアシスト',
        1794: ' パント初のチーム',
        1795: ' 第一期1X2アンダー/オーバー',
        1796: ' 1X2支援します',
        1797: ' ダブルチャンス支援します',
        1798: ' すべての期間の後にリードをチーム',
        1799: ' ほとんどのパッシングヤードを持つチーム',
        1800: ' ほとんどのラッシングヤードを持つチーム',
        1801: ' 成功した2点の変換',
        1802: ' オーバー/アンダータッチダウンを渡します',
        1803: ' オーバー/アンダーフィールドゴール第一期',
        1804: ' 上期の最終2分でスコア',
        1805: ' チームキックオフを開く受信します',
        1806: ' 第一期 -  /オーバーホームチームのタッチダウンの下で',
        1807: ' オーバー/アンダーアウェイチームのタッチダウン - 第一期',
        1808: ' 以下の下で/正確に/オーバーホームチームのタッチダウン - 第一期',
        1809: ' 以下の下で/正確に/オーバーアウェイチームのタッチダウン - 第一期',
        1810: ' /オーバーホームチームのフィールドゴールの下で - 第一期',
        1812: ' オーバー/アンダーアウェイチームのフィールドゴール - 第一期',
        1813: ' アウェイチームの勝利のすべての四半期に',
        1814: ' 勝つすべての四半期にホームチーム',
        1815: ' ゲームの最初の攻撃プレイ',
        1816: ' 勝利のクォーター',
        1817: ' 最終的に到達するために',
        1818: ' ホームチームは25ゴールを',
        1819: ' ホームチームは30ゴールを',
        1820: ' 25ゴールをアウェイチーム',
        1821: ' 30ゴールをアウェイチーム',
        1822: ' ホームチームに勝利いずれかのハーフ',
        1823: ' アウェイチームに勝利いずれかのハーフ',
        1824: ' 除去のステージ',
        1825: ' /オーバーアシストの下で',
        1826: ' オーバー/アンダーリバウンド',
        1827: ' オーバー/アンダーホームチームを支援',
        1828: ' /オーバーアウェイチームのアシストの下で',
        1829: ' オーバー/アンダーホームチームのリバウンド',
        1830: ' アウェイ/オーバーチームリバウンドの下で',
        1831: ' メイド1X2 3ポイント',
        1832: ' メイド/ 3ポイント以上の下で',
        1833: ' メイド/オーバーホームチームの3点の下で',
        1834: ' 以下の下で/メイドオーバーアウェイチーム3点',
        1835: ' メイドアジアのハンディキャップ3ポイント',
        1841: ' まず置換',
        1842: ' 試合での第二の目標',
        1843: ' 試合中に第3の目標',
        1846: ' 60ミニッツの結果',
        1847: ' 75ミニッツの結果',
        1848: ' 最初の5分間で得点ゴール？',
        1849: ' 最初のスローインで試合で',
        1850: ' どちらの半分に描きます',
        1851: ' マッチでゴール向かいましたか？',
        1852: ' 下の/目標分かけて',
        1853: ' 試合中/オーバータイブレークの下で',
        1854: ' /オーバーブレークポイントの下で',
        1855: ' オーバー/アンダーブレークがホームチームのポイント',
        1856: ' /オーバーブレークアウェイチームポイントの下に',
        1857: ' 最高得点クオーターオーバー/の下で',
        1858: ' 最低得点クオーターオーバー/の下で',
        1859: ' ほとんどのトライ3ウェイ',
        1860: ' 10分勝利マージン5ウェイ',
        1861: ' ほとんどの損失チーム',
        1862: ' サブは、ゲーム中に獲得します',
        1863: ' 勝つすべての期間 - ホームチーム',
        1864: ' 勝つすべての期間 - アウェイチーム',
        1865: ' トライオーバー/アンダーまず10分',
        1866: ' 15分未満/オーバー - コーナー',
        1867: ' チームによる行の2つの目標',
        1868: ' チームによる行の3つの目標',
        1869: ' ほとんどの変換後のトライ',
        1872: ' 播種されていないファイナリスト？',
        1873: ' ワイルドカードにリーチ決勝？',
        1874: ' アウトライトテニス - ハーフ受賞',
        1875: ' 勝利四半期 - テニスアウトライト',
        1881: ' ホールインワン',
        1882: ' プレイオフがあるでしょうか？',
        1883: ' 72のホールのマッチアップ',
        1884: ' 54のホールのマッチアップ',
        1885: ' ナンバー1つのドラフトピック',
        1886: ' 1X2とダブルチャンス',
        1887: ' 勝つためにホームチームが得点に描画し、両チーム\\',
        1888: ' アウェイ勝利へのチームはドローとスコアに両チーム\\',
        1889: ' 勝利マージン - 上期',
        1891: ' アメリカンリーグサイ・ヤング',
        1892: ' アメリカンリーグMVP',
        1893: ' ナショナルリーグサイ・ヤング',
        1894: ' ナショナルリーグMVP',
        1895: ' レコードへのピッチャーほとんどのレギュラーシーズンの勝利',
        1896: ' プレイヤーには、ほとんどのレギュラーシーズンのホームランを打ちます',
        1897: ' ウィルゲームは、余分な時間で決定しますか？',
        1898: ' レギュラーシーズン優勝％',
        1900: ' プレイヤートップ得点をもたらし',
        1901: ' オーバー/アンダータックル',
        1902: ' ナンバー2ドラフトピック',
    }
}