export const SET_HOME_LEAGUES = '[APP] SET_HOME_LEAGUES';
export const SET_HOME_ACTIVE = '[APP] SET HOME ACTIVE';
export const SET_HOME_LEAGUES_ACTIVE = '[APP] SET HOME LEAGUES ACTIVE';

export const ON_SELECT_HOME_ACTIVE_LEAGUE = '[APP] ON SELECT HOME ACTIVE LEAGUE';
export const ON_REMOVE_HOME_ACTIVE_LEAGUE = '[APP] ON REMOVE HOME ACTIVE LEAGUE';
export const SET_HOME_ACTIVE_LEAGUE_EVENTS = '[APP] SET HOME ACTIVE LEAGUE EVENTS';
export const ON_HOME_MAIN_MARKET_SELECTED = '[APP] ON HOME MAIN MARKET SELECTED';
export const ON_HOME_RESET_PAGE = '[APP] ON HOME RESET PAGE';
export const CLEAR_HOME_ACTIVE_LEAGUE_EVENTS = '[APP] CLEAR HOME ACTIVE LEAGUE EVENTS';
export const SET_EVENTS_MARKETS_DATA = '[APP] SET EVENT MARKETS DATA';

export const UPDATE_HOME_EVENTS_MARKET = '[APP] UPDATE HOME EVENTS MARKET';
export const UPDATE_HOME_EVENTS_STATUS = '[APP] UPDATE HOME EVENTS STATUS';
export const SET_IMP_MATCHES = '[APP] SET IMP MATCHES';
