import * as Actions from '../actions/actionTypes';
import forEach from 'lodash.foreach';
import jwtService from '../../services/jwtService';
import { pick } from 'lodash';
import genralServices from '../../services/genralServices';
import { dynamoClient } from '../../App';
import { getFavEvents } from '../../dynamo/favoriteParams';
import Util from '../../helper/Util';

// Show loading overlay
let statsData = [];
let statsLoading = false;
export const setLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LOADING, value });
    };
};

//For sports banner images
export const setBannersLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_BANNERS_LOADING, value });
    };
};

export const setPromotionBannersLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_PROMOTION_BANNERS_LOADING, value });
    };
};

export const setAdSponsorLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_AD_SPONSOR_LOADING, value });
    };
};

export const setPageLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_PAGE_LOADING, value });
    };
};

// Set language
export const setLanguage = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LANGUAGE, value });
    };
};

export const getBanners = () => {
    return (dispatch) => {
        jwtService.getBanners().then((data) => {
            let banners = [];
            if (data.banners) {
                banners = data.banners;
                dispatch({ type: Actions.SET_BANNERS, data: banners });
            }
            dispatch(setBannersLoading(false));
        });
    };
};

export const getPromotionBanners = () => {
    return (dispatch) => {
        dispatch(setPromotionBannersLoading(true));
        jwtService.getPromotionBanners().then((data) => {
            dispatch({ type: Actions.SET_PROMOTION_BANNERS, data });
            dispatch(setPromotionBannersLoading(false));
        }).catch((error) => {
            console.log(error);
            dispatch(setPromotionBannersLoading(false));
        });
    };
};

export const getAdSponsor = () => {
    return (dispatch) => {
        jwtService.getAdSponsor().then((data) => {
            dispatch({ type: Actions.SET_AD_SPONSOR_BANNERS, data });
            dispatch(setAdSponsorLoading(false));
        }).catch((error) => {
            console.log(error);
            dispatch(setAdSponsorLoading(false));
        });
    };
};

export const getPageContent = (type) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_PAGE_CONTENT, data:null });
        dispatch(setPageLoading(true));
        genralServices.getPageContent(type).then((res) => {
            let data = res[0];
            let title = '';
            data.type.split('_').forEach((text) => {
                title += (Util.textCapitalize(text)+' ');
            });
            data = { ...data, title };
            dispatch({ type: Actions.SET_PAGE_CONTENT, data });
            dispatch(setPageLoading(false));
        }).catch((error) => {
            console.log(error);
            dispatch(setPageLoading(false));
        });
    };
};

export const getDeviceLocation = () => {
    return (dispatch) => {
        genralServices.getDeviceLocation().then(data => dispatch({
            type: Actions.SET_COUNTRY_CODE, countryCode: data.country
        }));
    };
};


export const getStatsData = (url) => {
    if (true) return (dispatch, getState) => { };
    return (dispatch) => {
        statsLoading = true;
        genralServices.getStatsStatus(url).then(res => {
            if (res?.api) {
                const { data, method } = res.api;
                const { booked_events } = data;
                if (booked_events) statsData = [...statsData, ...booked_events];
                if (method.next_page) {
                    dispatch(getStatsData(method.next_page));
                } else {
                    let evtWithData = {};
                    forEach(statsData, (evt) => {
                        const { client_event_id } = evt;
                        const event = pick(evt, ['id', 'name', 'start_date', 'client_event_id', 'status_type']);
                        evtWithData[client_event_id] = event;
                        // if (['live', 'scheduled'].includes(evt['status_type'])) {
                        // }
                    });
                    statsLoading = false;
                    dispatch({ type: Actions.SET_STATS_DATA, data: evtWithData });
                    sessionStorage.setItem('stats_data', JSON.stringify({ date: new Date(), data: evtWithData }));
                    return null;
                }
            }
        });
    };
};

export const getTenentStatus = () => {
    return (dispatch) => {
        genralServices.tenetCasinoStatus().then((res) => {
            dispatch({ type: Actions.SET_TENET_STATUS, isShowCasino: res });
        })
            .catch((err) => {
                console.log(err);
            });
    };
};
