export const sv = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en: 'EN',
    tr: 'TR',
    fr: 'FR',
    de: 'DE',
    ru: 'RU',
    nl: 'NL',
    th: 'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Sporter',
    live: 'LEVA',
    inplay: 'LEVA',
    today: 'I dag',
    upcoming: 'Kommande',
    tomorrow: 'I morgon',
    highlights: 'Slingor',
    results: 'Resultat',
    lastMinute: 'Sista minuten',
    liveCasino: 'Live Casino',
    casino: 'Kasino',
    myBets: 'Mina spel',
    home: 'Hem',
    login: 'Logga in',
    signup: 'Bli Medlem',
    betslip: 'Spelkupong',
    search: 'Sök',
    searchHere: 'Sök här',
    transaction: 'Transaktion',
    changePassword: 'ändra lösenord',
    logout: 'Logga ut',
    single: 'Enda',
    multiple: 'Flera olika',
    possibleWin: 'möjlig vinst',
    amount: 'Belopp',
    ok: 'Ok',
    totalOdds: 'Totalt odds',
    odd: 'Udda',
    totalPossibleWin: 'Total möjlig vinst',
    placeBet: 'Slå vad',
    fastBet: 'Snabb slå vad',
    fast: 'Snabb',
    bet: 'Slå vad',
    username: 'Användarnamn',
    password: 'Lösenord',
    stayLoggedIn: 'Stanna inloggad',
    all: 'Allt',
    "in game": 'I spelet',
    won: 'Vann',
    lost: 'Förlorat',
    refund: 'Återbetalning',
    date: 'Datum',
    winAmount: 'Vinstsumman',
    stake: 'Insats',
    pick: 'Plocka',
    myAccount: 'Mitt konto',
    football: 'Fotboll',
    basketball: 'Basketboll',
    iceHockey: 'Ishockey',
    tennis: 'Tennis',
    volleyball: 'Volleyboll',
    boxing: 'Boxning',
    americanFootball: 'amerikansk fotboll',
    baseball: 'Baseboll',
    hockey: 'Hockey',
    tableTennis: 'Bordtennis',
    badminton: 'Badminton',
    cricket: 'Cricket',
    futsal: 'Futsal',
    golf: 'Golf',
    handball: 'Handboll',
    rugbyLeagues: 'Rugby ligor',
    pickCanNotBeCombined: 'Denna pick kan inte kombineras',
    matchStopped: 'Match slutade',
    favorites: 'Favoriter',
    cashout: 'Utbetalning',
    "in progress": 'Pågående',
    rejected: 'Avvisade',
    tip: 'Dricks',
    noGoal: 'Inget mål',
    yes: 'Ja',
    over: 'Över',
    under: 'Under',
    even: 'Även',
    areYouSureWantCashout: 'Är du säker på att du vill Cashout?',
    noGoal: 'Nej',
    bonus: 'Bonus',
    termsAndConditon: 'Villkor',
    aboutUs: 'Om oss',
    contactUs: 'Kontakta oss',
    payments: 'Betalningar',
    howPlacebet: 'Hur man placerar satsning',
    deposit: 'Deposition',
    credit: 'Kreditera',
    debit: 'Debitera',
    withdraw: 'Dra tillbaka',
    totalOdd: 'Total Odd',
    totalStake: 'Total insats',
    topGames: 'Populära spel',
    allGames: 'Alla spel',
    canNotBeCombined: 'Denna Pick kan inte kombineras',
    betSettled: 'Bet Fast',
    betSuspended: 'Bet Avstängd',
    insufficientBalance: 'Otillräcklig balans',
    oldPassword: 'Gammalt lösenord',
    newPassword: 'nytt lösenord',
    confirmPassword: 'Bekräfta nytt lösenord',
    change: 'Förändra',
    fromDate: 'Från datum',
    toDate: 'Hittills',
    activityType: 'Aktivitet Typ',
    show: 'Show',
    gameType: 'Speltyp',
    repeatBet: 'Repeat bet',
    someThingWrong: 'Något gick fel',
    status: 'Status',
    coupon: 'Kupong',
    acceptOddChangesText: 'Du måste acceptera förändringar för att kunna satsa',
    turnOffAway: 'Stäng av alla fall',
    acceptOddChanges: 'Acceptera udda förändringar',
    maxWinAmount: 'Max vinstsumman',
    maxOdd: 'Max udda',
    dateTime: 'Datum Tid',
    type: 'Typ',
    refreshCashout: 'Uppdatera Utbetalning',
    vendor: 'Säljare',
    stakeLowerThen1: 'Insatsen måste vara mindre än',
    stakeGreaterThen1: 'Insatsen måste vara större än',
    ESports: 'Esports',
    KSports: 'Ksport',
    promotion: 'Befordran',
    customerServicePage: 'Kundservice sida',
    decimal: 'Decimal',
    fraction: 'Fraktion',
    americanOdds: 'Amerikanska odds',
    nothingFound: 'inget hittat',
    noLiveMatch: 'Ingen levande match är tillgänglig',
    sportBook: 'Sportbok',   
    
    marketNames: {
       1: '1x2',
       2: 'Under över',
       238: 'återstående match',
       59: 'Nästa mål',
       7: 'Dubbelchans',
       52: 'Oavgjort inget spel',
       226: '12 inklusive övertid',
       13: 'Handikapp',
       4: 'HT / FT',
       17: 'Båda lagen gör mål',
       59: 'Nästa mål',
       28: 'Under / Över inklusive övertid',
       247: 'Återstående 1: a halvlek',
    },
    tabNames: {
        'All': 'Allt',
        'Main Markets': 'Huvudsakliga marknader',
        'Totals': 'Totals',
        '1st Half': '1:a Half',
        '2nd Half': '2:e Half',
        'Home Team': 'Hemmalag',
        'Away Team': 'Bortalag',
        'All Periods': 'alla Perioder',
        'Results': 'Resultat',
        'Home / Away': 'Hem / Borta',
    },
    markets: {
        1:  '1x2',
        2: ' Under över',
        3: ' Asian Handicap',
        5: ' Udda / Jämnt',
        6: ' Rätt poäng',
        7: 'Dubbelchans',
        9: ' Rätt resultat Period 1',
        11: 'Totalt Corners',
        12: 'Totalt Fouls Engagerade',
        13: 'Handikapp',
        16: 'Första lag att göra mål',
        17: 'Båda lagen gör mål',
        19: 'First Card',
        21: 'Under / Över 1: a perioden',
        22: 'Bortalaget gör mål',
        23: 'Hemmalaget gör mål',
        25: 'Dubbelchans Halvtid',
        29: 'Under / Över Rounds',
        30: 'Under / Över Corners - Hemmalag',
        31: 'Under / Över Corners - Bortalag',
        34: 'Hemmalaget gör mål i båda halvlekarna',
        35: 'Bortalaget gör mål i båda halvlekarna',
        41: '1: a perioden Winner',
        42: '2:e Period Winner',
        43: '3:e Period Winner',
        44: '4:e Period Winner',
        45: 'Under / Över 2: a Period',
        46: 'Under / Över 3rd Period',
        47: 'Under / Över 4 Period',
        48: 'Under / Över 5 Period',
        49: '5:e Period Winner',
        50: '1X2 inklusive övertid',
        51: 'Udda / Jämnt inklusive övertid',
        52: '12',
        53: 'Asian Handicap Halvtid',
        55: 'Första lag att göra mål 1: a perioden',
        56: 'Sista lag att göra mål',
        57: 'Sista lag att göra mål Halvtid',
        59: 'Nästa mål',
        61: 'Handikapp Halvtid',
        62: 'Udda / Jämnt halvtid',
        63: '12 halvtid',
        64: 'Asian Handicap Period 1',
        65: 'Asian Handicap 2nd Period',
        66: 'Asian Handicap 3rd Period',
        67: 'Asian Handicap 4 Period',
        68: 'Asian Handicap 5th Period',
        69: 'Kommer The Game Gå till övertid?',
        70: 'Flest poäng Period',
        71: 'Halvlek med flest mål',
        72: '1:a Period Udda / jämn',
        73: '2:e Period Udda / jämn',
        74: '3:e Period Udda / jämn',
        75: '4:e Period Udda / jämn',
        76: '5:e Period Udda / jämn',
        77: 'Under / Över Halvtid',
        78: 'Som gör flest sexor',
        79: 'Kommer en straff tilldelas?',
        80: 'First Corner',
        81: 'senast Corner',
        82: 'Kommer ett rött kort visas?',
        83: 'Att hålla nollan',
        84: 'Vinner båda halvlekarna',
        85: 'Vinner från underläge',
        86: 'Vinner utan insläppt mål',
        88: 'Att kvalificera',
        89: 'Kommer första försöket omvandlas?',
        90: 'Blir det beslutas i 5: e uppsättning?',
        91: 'I Vad Half första målet görs?',
        92: 'Första lag att göra poäng 2: a halvlek',
        95: 'Corners Handicap',
        96: 'Bortalaget vinner utan insläppt mål',
        97: 'Hemmalaget vinner utan insläppt mål',
        98: 'Hemmalaget Håller nollan',
        99: 'Bortalaget Håller nollan',
        100: 'Rätt resultat 2nd Period',
        101: 'Under / Over - Hemmalag',
        102: 'Under / Over - Bortalag',
        104: 'Bortalaget vinner båda halvlekarna',
        105: 'Hemmalaget vinner båda halvlekarna',
        106: 'Home laget att vinna från underläge',
        107: 'Bortalaget vinner och bakifrån',
        108: 'Hemmalaget gör mål på en straff',
        109: 'Bortalaget gör mål på en straff',
        110: 'Bortalaget vinner och minst hälften',
        111: 'Home laget att vinna minst hälften',
        113: 'Båda lagen gör mål 1: a halvlek',
        114: 'Alla spelare som gör mål Minst 2 Mål',
        117: 'Alla spelare som gör mål Minst 3 Mål',
        122: 'Team Scoring First för att vinna matchen',
        123: 'Bortalaget gör mål först och vinna Match',
        124: 'Hemmalaget gör mål först och vinna Match',
        128: 'Antal mål',
        129: 'Under / Över Corners - 1: a halvlek',
        132: 'Under / Över Skott på mål',
        133: 'Under / Över Totalt Shots',
        134: 'Antal mål 1: a halvlek',
        136: 'Under / Över Offsides',
        137: 'Home Win inget spel',
        138: 'Bortaseger inget spel',
        139: 'Där hälften Home lag gör första målet?',
        140: 'Där hälften borta lag gör första målet?',
        143: 'Där hälften Hemmalaget kommer göra fler mål?',
        144: 'Där hälften Bortalag som gör flest mål?',
        145: 'Home Team Antal mål i 1: a halvlek',
        146: 'Bortalaget antal mål i 1: a halvlek',
        147: 'Bortalaget Antal mål i 2: a halvlek',
        148: 'Home Team Antal mål i 2: a halvlek',
        149: 'Home Team antal mål',
        150: 'Bortalaget antal mål',
        151: 'Dubbelchans 2: a halvlek',
        153: 'Under / Över 1: a perioden - Hemmalag',
        154: 'Under / Över 2: a perioden - Hemmalag',
        155: 'Under / Över 1: a perioden - Bortalag',
        156: 'Under / Över 2: a perioden - Bortalag',
        157: 'Under / Över Yellow Cards - 1: a halvlek',
        158: 'Under / Över Yellow Cards',
        159: 'Kommer att vara en Tiebreak i matchen?',
        160: 'Race Winner',
        161: '10 Minute Resultat',
        162: '10 Minute Under / Över',
        163: 'Antal mål 2nd Half',
        164: 'Kommer att vara en Tie-break i första set?',
        165: 'Under / Över Sets',
        166: 'Under / Över Spel',
        168: 'Kommer att vara ett självmål?',
        169: 'Gör mål i båda halvlekarna',
        170: 'Udda / Jämnt Corners',
        171: 'To Win Antingen Half',
        175: 'Kommer att vara en Tiebreak i andra set?',
        176: 'Kommer att vara en Tiebreak i tredje set?',
        177: 'Kommer att vara en Tiebreak i fjärde set?',
        178: 'Kommer att vara en Tiebreak i femte set?',
        180: 'Under / Över inkast',
        181: 'Under / Över Yellow Cards - Hemmalag',
        182: 'Under / Över Yellow Cards - Home Team 1: a halvlek',
        183: 'Under / Över Yellow Cards - Home Team 2: a halvlek',
        184: 'Under / Över Yellow Cards - Bortalag',
        185: 'Under / Över Yellow Cards - Bortalag 1: a halvlek',
        186: 'Under / Över Yellow Cards - Bortalag 2: a halvlek',
        187: 'Under / Över Yellow Cards - 2: a halvlek',
        188: 'Det gula kortet',
        190: 'Det gula kortet 2: a halvlek',
        191: 'Kommer någon spelare att göra ett hattrick?',
        192: 'Kommer en Goal görs i bägge halvlekarna?',
        194: 'Hemmalaget Håller nollan Period 1',
        195: 'Hemmalaget Håller nollan 2nd Period',
        196: 'Bortalaget Håller nollan Period 1',
        197: 'Bortalaget Håller nollan 2nd Period',
        198: 'Udda / Jämnt - Hemmalag',
        199: 'Udda / Jämnt - Bortalag',
        200: 'Kommer den första poäng laget vinner?',
        201: 'Asian Handicap Games',
        202: 'Vinnare 1: a perioden Home / Borta',
        203: 'Winner 2nd Period Hem / Borta',
        204: 'Winner 3rd Period Hem / Borta',
        205: 'Winner 4 Period Hem / Borta',
        206: 'Winner 5th Period Hem / Borta',
        207: '15 Minute Resultat',
        208: '30 Minute Resultat',
        209: 'Total Corners - 1: a halvlek',
        210: 'Total Corners - 2: a Half',
        211: 'Båda lagen gör mål 2: a halvlek',
        212: 'Båda lagen gör mål i båda halvlekarna',
        213: 'Dra i båda halvlek',
        214: 'Under / Över Cards',
        215: 'Bortalaget gör mål 1: a halvlek',
        216: 'Bortalaget gör mål 2: a halvlek',
        217: 'Rätt resultat 3rd Period',
        218: 'Hemmalaget gör mål 1: a halvlek',
        219: 'Hemmalaget gör mål 2: a halvlek',
        220: 'Under / Over - Bortalag inklusive övertid',
        221: 'Under / Over - Hemmalag inklusive övertid',
        222: 'Under / Över 3 Period - Bortalag',
        223: 'Under / Över 3 Period - Hemmalag',
        224: 'Dubbelchans 3rd Period',
        225: 'Dubbelchans 4th Period',
        226: '12 inklusive övertid',
        227: 'Ras Winner 2 Platser',
        228: 'Ras Winner 3 Platser',
        229: 'Ras Winner 4 Platser',
        233: 'To Win Inte Nil',
        235: '1:a 5 Inningar Winner',
        236: '1:a 5 Inningar Under / Över',
        237: 'Kommer det att finnas i 1: a omgången',
        238: 'återstående Match',
        239: 'mål Range',
        242: 'Period 1 Udda / Jämnt - Hemmalag',
        243: 'Period 1 Udda / Jämnt - Bortalag',
        244: 'Högsta Opening Partnership',
        245: 'Mål / Inget mål',
        247: 'Återstående 1: a halvlek',
        250: 'Corners Handicap - 1a Halvlek',
        254: 'kort Range',
        255: 'Tid för 1: a Card',
        256: 'Home Team - Kort Range',
        257: 'Bortalaget - Kort Range',
        258: 'Corners Range',
        259: 'Lag med flest hörnor',
        260: 'Team med flest 1: a halvlek Corners',
        261: 'Team med flest 2: a halvlek Corners',
        262: 'Home Team - Corners Range',
        263: 'Bortalaget - Corners Range',
        264: 'Home Team - 1a Halvlek Corners Range',
        265: 'Bortalaget - 1: a halvlek Corners Range',
        266: 'Home Team - 2: a halvlek Corners Range',
        267: 'Bortalaget - 2: a halvlek Corners Range',
        269: 'Mål Range 1: a perioden',
        271: 'Chance Mix',
        274: 'Vinnar',
        275: 'Mål Range 2nd Period',
        278: 'Mål Range Hemmalag',
        279: 'Mål Räckvidd Bortalag',
        281: '1:a 5 Inningar Asian handicap',
        282: '1X2 halvtid',
        283: 'Asian Handicap 2: a halvlek',
        284: '1X2 2:e Half',
        285: 'Udda / Jämnt 2: a halvlek',
        286: '12 2:e Half',
        287: 'Under / Över 4 Period - Hemmalag',
        288: 'Under / Över 4 Period - Bortalag',
        289: '2nd Period Udda / Jämnt - Hemmalag',
        290: '3rd Period Udda / Jämnt - Hemmalag',
        291: '4. Period Udda / Jämnt - Hemmalag',
        292: '2nd Period Udda / Jämnt - Bortalag',
        293: '3rd Period Udda / Jämnt - Bortalag',
        294: '4. Period Udda / Jämnt - Bortalag',
        297: 'SP',
        299: 'Point Winner',
        300: 'Game Winner',
        301: 'mål Före',
        302: 'mål Efter',
        304: 'Lag med det högsta poäng Period',
        305: 'Corners - Under / Exakt / Över',
        306: 'Första Goal sista målet',
        307: 'Handikapp-spel',
        308: 'Under / Över flest poäng Period',
        309: 'Under / Över Lägsta poäng Period',
        310: 'Series Winner',
        317: 'Under / Exakt / Over - Period 1',
        318: 'Under / Exakt / Over - 2nd Period',
        319: 'Under / Exakt / Over - 3rd Period',
        320: 'Under / Exakt / Over - 4 Period',
        322: 'Under / Exakt / Över',
        329: '1: a perioden Race Till',
        330: '2:e Period Race Till',
        331: '3:e Period Race Till',
        332: '4:e Period Race Till',
        333: '5:e Period Race Till',
        337: 'Under / Over - 2: a halvlek',
        338: 'Nästa mål Period 1',
        339: 'Nästa mål 2nd Period',
        341: 'Race To',
        342: 'Asian Handicap inklusive övertid',
        343: 'Game Range 1: a perioden',
        344: 'Game Range 2nd Period',
        345: 'Game Range 3rd Period',
        346: 'Game Range 4 Period',
        347: 'Game Range 5 Period',
        348: '6:e Period Winner',
        349: '7:e Period Winner',
        350: '6:e Period Udda / jämn',
        351: '7:e Period Udda / jämn',
        352: 'Under / Över 6 Period',
        353: 'Under / Över 7 Period',
        354: 'Under / Över Halvtid - Hemmalag',
        355: 'Under / Över Halvtid - Bortalag',
        356: 'Metod för uppsägning 6-Way',
        357: 'uppsägning Metod',
        366: 'Körs Udda / Jämnt i Over - Hemmalag',
        367: 'Total Runs Udda / Jämnt i Over - Bortalag',
        370: 'Totalt Körs i Over - Hemmalag',
        371: 'Totalt Körs i Over - Bortalag',
        386: 'Handikapp inklusive övertid',
        387: 'Udda / Jämnt - Home Team inklusive övertid',
        388: 'Udda / Jämnt - Bortalag inklusive övertid',
        390: 'HT / FT inklusive övertid',
        391: 'Nästa mål - Extra Time',
        392: 'Home Team straffar',
        393: 'Bortalaget straffar',
        394: 'Rätt resultat 4: e Period',
        395: 'Rätt resultat 5:e Period',
        398: 'Udda / Jämnt Kort',
        400: 'Udda / Jämnt Cards 1: a halvlek',
        401: 'Under / Över Corners 1: a halvlek - Hemmalag',
        402: 'Under / Över Corners 1: a halvlek - Bortalag',
        403: 'Under / Över Corners 2: a halvlek',
        404: 'Under / Över Corners 2: a halvlek - Hemmalag',
        405: 'Under / Över Corners 2: a halvlek - Bortalag',
        406: 'Udda / Jämnt Corners 1: a halvlek',
        407: 'Asian Handicap Kort',
        408: 'Asian Handicap Kort 1: a halvlek',
        409: '1X2 Corners',
        410: '1X2 Corners 1:a Half',
        411: '12 Corners',
        414: 'Under / Över Cards 1: a halvlek',
        415: '1X2 Och Båda lagen gör mål',
        416: 'Under / Over - Extra Time',
        417: 'Under / Over - Extra Time 1: a halvlek',
        419: '1X2 - Extra Time',
        427: '1X2 och Under / Över',
        428: 'Flera Rätt resultat',
        430: 'Under / Exakt / Over - 1: a halvlek',
        431: 'Under / Exakt / Over - 2: a halvlek',
        433: 'Handikapp Corners',
        434: '8:e Period Winner',
        435: '9:e Period Winner',
        436: 'Under / Över 8 Period',
        437: 'Under / Över 9 Period',
        438: 'Total Runs Udda / Jämnt i Over',
        439: 'Totalt Körs i Over',
        445: 'Winner 6th Period Hem / Borta',
        446: 'Winner 7th Period Hem / Borta',
        447: 'Asian Handicap 6th Period',
        448: 'Asian Handicap 7th Period',
        449: 'Asian Handicap 8 Period',
        450: 'Asian Handicap 9th Period',
        451: 'Winner 8th Period Hem / Borta',
        452: 'Winner 9th Period Hem / Borta',
        453: 'Första Score Metod',
        454: 'Först Score Metod - Hemmalag',
        455: 'Första Score Metod - Bortalag',
        456: 'Dubbelchans 1: a perioden',
        457: 'Dubbelchans 2nd Period',
        459: 'Hemmalaget gör mål i varje kvartal',
        460: 'Bortalaget gör mål i varje kvartal',
        462: 'Topp 5 Winner',
        463: 'Topp 10 Vinnare',
        464: '12 2:e Hälften inklusive övertid',
        465: 'Winner 4 Period Hem / bort inklusive övertid',
        466: 'Under / Över 4 Period inklusive övertid',
        467: 'Asian Handicap 4 Period inklusive övertid',
        468: 'Asian Handicap 2: a halvlek inklusive övertid',
        469: 'Under / Över 2: a halvlek inklusive övertid',
        472: 'Will Game Gå till Shootout?',
        475: 'Under / Över körs i Over - Home Team 1st Inning',
        476: 'Under / Över körs i Over - Bortalag 1st Inning',
        477: 'Under / Över körs i Over - Home Team 2nd Inning',
        478: 'Under / Över körs i Over - Bortalag 2nd Inning',
        479: 'Udda / Jämnt körs i Over - Home Team 1st Inning',
        480: 'Udda / Jämnt körs i Over - Bortalag 1st Inning',
        481: 'Udda / Jämnt körs i Over - Home Team 2nd Inning',
        482: 'Udda / Jämnt körs i Over - Bortalag 2nd Inning',
        483: 'Kommer Hemmalag Få en wicket i Over - 1: a omgången?',
        484: 'Kommer Bortalag få en Wicket i Over - 1: a omgången?',
        485: 'Kommer Hemmalag Få en wicket i Over - 2: a omgången?',
        486: 'Kommer Bortalag få en Wicket i Over - 2: a omgången?',
        523: 'Under / över och båda lagen gör mål',
        524: '1:a 7 inningen Winner',
        525: '1:a 7 inningen Under / Över',
        526: '1:a 7 omgångar Asian handicap',
        529: 'Udda / Jämnt 2: a halvlek inklusive övertid',
        553: 'Under / Över Shootout straffar',
        554: '12 Shootout',
        555: 'Asian Handicap Shootout',
        556: 'Rätt resultat Shootout',
        557: 'Under / Över Shootout',
        558: 'Under / Över Shootout - Hemmalag',
        559: 'Under / Över Shootout - Bortalag',
        560: 'Team för att ta Senaste Shootout Penalty',
        563: 'Race To inklusive övertid',
        564: '1X2 och Under / Över 5 sätt',
        566: 'Winner 10th Period Hem / Borta',
        567: 'Winner 11th Period Hem / Borta',
        568: 'Winner 12 Period Hem / Borta',
        569: 'Winner 13th Period Hem / Borta',
        570: 'Winner 14 Period Hem / Borta',
        571: 'Winner 15 Period Hem / Borta',
        573: 'Winner 16 Period Hem / Borta',
        574: 'Winner 17 Period Hem / Borta',
        575: 'Winner 18 Period Hem / Borta',
        576: 'Winner 19 Period Hem / Borta',
        577: '10 Minute Under / Över - Hörn',
        578: '10 minut under / över - Cards',
        579: 'Nästa Corner',
        586: 'Till slut i övre halvan',
        587: 'Till slut i nedre halvan',
        588: 'Till slut Bottom',
        589: 'Inte till slut i Top 4',
        590: 'Att förvisas',
        591: 'Inte att förpassas',
        592: 'Att nå en slutlig',
        593: 'Till Finish I Botten 6',
        594: 'Att bli befodrad',
        595: 'Första målet Tid',
        596: 'Mål Range Hemmalag Period 1',
        597: 'Mål Range Home Team 2nd Period',
        598: 'Mål Räckvidd Bortalag 1: a perioden',
        599: 'Mål Räckvidd Bortalag 2nd Period',
        600: '1: a perioden vinnaren och båda lagen gör mål',
        601: 'HT / FT Dubbelchans',
        602: 'Rätt resultat inklusive övertid',
        603: 'Negativ HT / FT',
        604: 'Mål Range Hemmalag båda perioderna',
        605: 'Mål Sortiment bortalaget båda perioderna',
        606: 'Vinnaren och mål Typ One',
        607: 'Dubbelchans OCH MÅL',
        608: 'Vinnaren och mål Typ Two',
        609: 'Båda lagen gör mål och mål',
        610: 'Dubbelchans Mix',
        628: 'Till slut i Top 7',
        629: 'Till slut i Top 6',
        630: 'Till slut i Top 3',
        631: 'Till slut i Top 2',
        632: 'Till slut i Top 4',
        633: 'Till slut i Top 5',
        634: 'Udda / Jämnt Rounds Period 1',
        635: 'Udda / Jämnt Rounds 2nd Period',
        636: 'Udda / Jämnt Rounds 3rd Period',
        637: 'Kommer det att finnas en Ace?',
        638: 'Kommer det att finnas en kniv Kill?',
        639: '1:a Round Winner',
        640: '16:e Round Winner',
        641: 'Kommer det att finnas en kniv döda? - Period 1',
        642: 'Kommer det att finnas en kniv döda? - 2nd Period',
        643: 'Kommer det att finnas en kniv döda? - 3: e Period',
        644: 'Kommer det att finnas en Ace? - Period 1',
        645: 'Kommer det att finnas en Ace? - 2nd Period',
        646: 'Kommer det att finnas en Ace? - 3: e Period',
        647: 'Kommer det att finnas övertid? - Period 1',
        648: 'Kommer det att finnas övertid? - 2nd Period',
        649: 'Kommer det att finnas övertid? - 3: e Period',
        650: '16 Round Winner - Period 1',
        651: '16 Round Winner - 2nd Period',
        652: '16 Round Winner - 3rd Period',
        653: '1st Round Winner - Period 1',
        654: '1st Round Winner - 2nd Period',
        655: '1st Round Winner - 3rd Period',
        660: 'Spellängd',
        662: 'Udda / Jämnt Rounds',
        663: '1: a perioden Varaktighet',
        664: '2:e Period Varaktighet',
        665: '3:e Period Varaktighet',
        666: 'Period 1 - 1 Roshan',
        667: '2nd Period - 1 Roshan',
        668: 'Period 3 - 1 Roshan',
        669: 'Period 1 - 1: a Blood',
        670: '2nd Period - 1: a Blood',
        671: 'Period 3 - 1: a Blood',
        672: 'Period 1 - 1: a tou',
        673: '2nd Period - 1st tou',
        674: 'Period 3 - 1: a tou',
        675: 'Period 1 - 1: a Barracks',
        677: '2nd Period - 1st Barracks',
        678: '3rd Period - 1st Barracks',
        679: 'Period 1 - 1 Turret',
        680: '2nd Period - 1 Turret',
        681: 'Period 3 - 1 Turret',
        682: 'Race To Kills - Period 1',
        683: 'Race To Kills - 2nd Period',
        684: 'Race To Kills - 3rd Period',
        685: 'Båda lagen att döda en drake - Period 1',
        686: 'Båda lagen att döda en drake - 2nd Period',
        687: 'Båda lagen att döda en drake - 3: e Period',
        688: 'Båda lagen att döda en inhibitor - Period 1',
        689: 'Båda lagen att döda en inhibitor - 2nd Period',
        690: 'Båda lagen att döda en inhibitor - 3: e Period',
        691: 'Udda / Jämnt Kills',
        692: 'Udda / Jämnt dödar - Period 1',
        693: 'Udda / Jämnt dödar - 2nd Period',
        694: 'Udda / Jämnt dödar - 3rd Period',
        695: 'HT / FT och mål',
        697: 'Till slut i Top 8',
        711: 'Spelare som gör mål i när som helst',
        712: 'Första spelare som gör mål',
        713: 'Sista spelare som gör mål',
        714: 'Spelare gör mål 2 eller fler mål',
        715: 'Spelare gör mål 3 eller fler mål',
        727: 'Period 1, Båda lagen gör mål',
        728: '2:e Period Båda lagen gör mål',
        729: '3:e Period Båda lagen gör mål',
        730: '4:e Period Båda lagen gör mål',
        731: 'Period 1 Hemmalaget gör mål',
        732: '2nd Period Hemmalaget gör mål',
        733: 'Period 3 Hemmalaget gör mål',
        734: '4. Period Hemmalaget gör mål',
        735: 'Period 1 Bortalaget gör mål',
        736: '2nd Period Bortalaget gör mål',
        737: '3rd Period Bortalaget gör mål',
        738: '4. Period Bortalaget gör mål',
        751: '1:a Half Race Till',
        752: '2: a halvlek Race To - inklusive övertid',
        754: 'Game för att Deuce',
        755: 'Förlängning Corners - Under / Exakt / Över',
        756: 'Under / Över- extra tid Corners',
        757: 'Asian Handicap - Extra Time',
        758: '10 Minute Under / Over - Gratis Kicks',
        759: '10 Minute Under / Över - inkast',
        760: '10 Minute Under / Över - inspark',
        762: 'Att vinna Toss',
        763: 'Under / Över körs i Match',
        765: 'Som gör flest Fours',
        766: 'Under / Over - Match Fours',
        767: 'Under / Over - Match sexor',
        768: 'En femtio till görs i Match',
        769: 'En hundra till görs i Match',
        770: 'De flesta Run Outs',
        791: 'Top slagman - Hemmalag',
        792: 'Top slagman - Bortalag',
        793: 'Top Bowler - Hemmalag',
        794: 'Top Bowler - Bortalag',
        795: 'Man of the Match',
        806: 'Slagman gör mål på Fifty i matchen',
        808: 'Ras Till 10 runs',
        809: 'Högsta 1st 6 overs Score',
        813: 'Spelare som gör flest sexor',
        814: 'Högsta Individuell Score',
        819: 'Att ta en paus i spelet',
        820: 'Race to Corners',
        822: 'Top New Team',
        824: 'Spelare bokas',
        825: 'Spelare som ska skickas iväg',
        834: 'Extrapoäng Set',
        835: 'Asian Under / Över',
        836: 'Asian Under / Över 1: a perioden',
        837: '1: a halvlek Hemmalag vinner utan insläppt mål',
        838: '1: a halvlek Bortalag vinner utan insläppt mål',
        839: '1: a halvlek 1X2 och Under / Över',
        840: 'Period 1 Home Team vinner utan insläppt mål',
        841: 'Period 1 Bortalag vinner utan insläppt mål',
        842: 'Under / Exakt / Over - Hemmalag',
        843: 'Under / Exakt / Over - Bortalag',
        844: 'Första lag att göra poäng - Hem / Borta',
        845: 'Lag att ringa först Time Out',
        846: 'Under / Över Hits - Inklusive övertid',
        849: 'Under / Över 5th omgången',
        851: 'Lag med det högsta poäng Half',
        852: 'Antingen lag att göra mål tre Obesvarade Times',
        853: '12 och Under / Över',
        854: 'Special Team eller defensiva Touchdown Mål',
        855: 'Points Range',
        859: '147 Break I Match',
        861: 'Första poängen Metod 3 Way - Hemmalag',
        862: 'Första Score Metod 3 Way - Bortalag',
        863: 'Team med flest poäng Period -1X2',
        864: 'Team Första målgörare',
        865: 'Team Sista målgörare',
        866: 'Asian Handicap Sets',
        870: 'Första poängen Metod 6 Way Home / Borta',
        878: 'Mål Range 3rd Period',
        879: 'Under / Över Tries',
        880: 'Första halvåret Corners - Under / Exakt / Över',
        881: 'Första målet Method',
        882: 'Antal poäng',
        891: 'Lag att göra mål Längsta Touchdown',
        892: 'Lag att göra mål Längsta Field Goal',
        893: 'Längsta Touchdown Mål',
        894: 'Kortaste Touchdown Mål',
        895: 'Längsta Field mål som görs',
        896: 'Tid för första laget mål',
        897: 'Game Score Efter 2 poäng',
        898: 'Game Score Efter 3 poäng',
        899: 'Game Score Efter 4 poäng',
        900: 'Game gör mål',
        901: 'Period 1, Lead Efter',
        902: '2:e Period Lead Efter',
        903: '3:e Period Lead Efter',
        904: '4:e Period Lead Efter',
        905: '5:e Period Lead Efter',
        906: 'Score Efter 4 Spel',
        907: 'Score Efter 6 Spel',
        908: 'Till Break Serve I Set',
        909: 'Hem för att vinna i raka set',
        910: 'Bort för att vinna i raka set',
        911: 'Score Efter 2 Ställer',
        912: 'Score Efter 3 seten',
        914: 'Under / Över sätter in mål Mål - 1: a halvlek',
        915: 'Under / Över touchdowns - 1: a halvlek',
        916: 'Under / Över Hemmalagets Touchdowns - 1: a halvlek',
        917: 'Under / Över bortalaget Touchdowns - 1a Halvlek',
        920: 'Under / Över Touchdowns',
        921: 'Under / Exakt / Over - Touchdowns',
        922: 'Under / Över sätter in mål',
        923: 'Under / Exakt / Over - sätter in mål',
        924: 'Tid för första fält mål som görs',
        925: 'Tid för första Touchdown Mål',
        926: 'Tid för första poäng',
        927: 'Home Team under / över Touchdowns',
        928: 'Bortalaget under / över Touchdowns',
        929: 'Home Team Under / Över sätter in mål',
        930: 'Bortalaget Under / Över sätter in mål',
        932: 'Lag som har första Coaches Challenge',
        933: 'Team att begå First Godkända Penalty',
        934: 'säkerhet Mål',
        935: 'Europeiska Kort Handikapp',
        936: 'Asian Under / Över Cards',
        938: 'Under / Över Cards - Hemmalag',
        939: 'Under / Över Cards - Bortalag',
        940: 'Time av First Card',
        941: 'Nästa spelare som gör mål',
        942: 'Top nationalitet - Asien',
        943: 'Top nationalitet - Australian',
        944: 'Top nationalitet - European',
        945: 'Top nationalitet - Storbritannien och Irland',
        946: 'Top nationalitet - övriga världen',
        947: 'Under / Över Aces',
        948: 'Under / Över Aces - Hemmalag',
        949: 'Exakta antalet ess',
        950: 'Under / Över Aces - Bortalag',
        951: 'Rätt antal ess',
        955: '1X2 ess',
        956: 'Exakta antalet Aces - Hemmalag',
        957: 'Exakta antalet Aces - Bortalag',
        958: 'Aces Asian Handicap',
        959: 'Race To Aces',
        960: 'Under / Över Aces - Period 1',
        976: '1X2 Aces - Period 1',
        982: 'Aces Asian Handicap - Period 1',
        986: '12 ess',
        989: 'Under / Över Kills - Period 1',
        990: 'Under / Över Kills - 2nd Period',
        991: 'Under / Över Kills - 3rd Period',
        992: 'Nästa Touchdown Scorer - Hemmalag',
        993: 'Nästa Touchdown Scorer - Bortalag',
        994: 'Nästa Touchdown Scorer',
        995: 'Spelare som gör touchdown i helst',
        996: 'Spelare gör mål 2 eller fler Touchdowns',
        997: 'Spelare gör mål 3 eller fler Touchdowns',
        998: 'Första Match Corner - 12',
        999: 'Senaste match Corner - 12',
        1000: 'Antal uppsättningar',
        1001: 'Under / Över dubbelfel',
        1002: 'Under / Över dubbelfel - Bortalag',
        1003: 'Under / Över dubbelfel - Hemmalag',
        1004: 'Under / Över dubbelfel - Period 1',
        1010: '12 dubbelfel',
        1011: '12 dubbelfel - Period 1',
        1013: '1X2 dubbelfel',
        1016: 'Exakta antalet dubbelfel',
        1019: 'Exakta antalet dubbelfel - Hemmalag',
        1020: 'Exakta antalet dubbelfel - Bortalag',
        1027: 'Rätt antal dubbelfel',
        1030: 'Tidpunkt då första hörnan',
        1031: 'flera Corners',
        1034: 'Winner 20 Period Hem / Borta',
        1035: 'Winner 21th Period Hem / Borta',
        1036: 'Vinnare 22: Period Hem / Borta',
        1037: 'Winner 23th Period Hem / Borta',
        1038: 'Winner 24 Period Hem / Borta',
        1039: 'Winner 25 Period Hem / Borta',
        1040: 'Winner 26 Period Hem / Borta',
        1041: 'Winner 27 Period Hem / Borta',
        1042: 'Winner 28 Period Hem / Borta',
        1043: 'Winner 29 Period Hem / Borta',
        1044: 'Winner 30 Period Hem / Borta',
        1046: 'Winner 32th Period Hem / Borta',
        1047: 'Winner 33th Period Hem / Borta',
        1050: 'Ras till 20 poäng',
        1051: 'Under / Över 2: a halvlek - Hemmalag',
        1052: 'Under / Över 2: a halvlek - Bortalag',
        1053: 'Asian Under / Över 2: a Period',
        1054: 'Asian Under / Över 3rd Period',
        1055: 'Asian Under / Över 4 Period',
        1061: 'Asian Under / Över inklusive övertid',
        1062: 'Halvlek med flest mål - Home Team',
        1063: 'Halvlek med flest mål - Bortalag',
        1065: 'Home Team - Första målgörare',
        1066: 'Home Team - Sista målgörare',
        1067: 'Bortalaget - Första målgörare',
        1068: 'Bortalaget - Sista målgörare',
        1069: 'Under / Över Player Points',
        1070: 'Under / Över Player Blocks',
        1071: 'Under / Över spelare Assist',
        1072: 'Under / Över Player Rebounds',
        1073: 'Under / Över Player stjäl',
        1074: 'Under / Över Player Omsättningen',
        1075: 'Under / Över Player 3 punkter',
        1076: 'Under / Över Player 3 poäng Försök',
        1077: 'Under / Över Player 2 punkter',
        1078: 'Under / Över Player 2 poäng Försök',
        1079: 'Under / Över Player gratis kastar Made',
        1080: 'Under / Över Player fria kast Försök',
        1081: '1X2 Gul Kort',
        1082: 'Dubbelchans Yellow Cards',
        1083: 'Asian Handicap Yellow Cards',
        1084: 'Rätt resultat Gula Kort',
        1085: 'Under / Över röda kort',
        1086: 'Under / Exakt / över bokning Points',
        1087: 'Under / Exakt / över bokning Points - Home Team',
        1088: 'Under / Exakt / över bokning Points - Bortalag',
        1089: 'Under / Över Player stjäl och Blocks',
        1090: 'Under / Över Player Points',
        1091: 'Under / Över spelare assist och Rebounds',
        1092: 'Under / Över Player Points Och rekylerar',
        1093: 'Under / Över Player Points och bistår',
        1094: 'Spelare Double Double',
        1095: 'Spelare Triple Double',
        1096: 'Under / Exakt / över bokning Points - Period 1',
        1100: 'Setvinnare',
        1101: 'Under / Över 180 \ s i en uppsättning',
        1102: 'Set / Leg Winner',
        1103: 'Set / Ben Totalt kassadiskar Under / Över',
        1105: 'Under / Över Totalt 180 \ s - Home Team',
        1106: 'Under / Över Totalt 180 \ s - Bortalag',
        1112: 'Under / Över Spelarmål',
        1115: 'Under / Över Spelarmål accepteras',
        1120: 'Under / Över Rounds - Period 1',
        1121: 'Under / Över Rounds - 2nd Period',
        1122: 'Under / Över Rounds - 3rd Period',
        1123: '4. Period - 1st Blood',
        1124: '5. Period - 1st Blood',
        1125: 'Båda lagen att döda en drake',
        1126: 'Båda lagen att döda en Baron',
        1127: 'Båda lagen att döda en hämmare',
        1128: 'Under / Över Barons Slain',
        1129: 'Under / Över Barons Slain - Period 1',
        1130: 'Under / Över Barons Slain - 2nd Period',
        1131: 'Under / Över Barons Slain - 3rd Period',
        1132: 'Under / Över Dragons Slain',
        1133: 'Under / Över Dragons Slain - Period 1',
        1134: 'Under / Över Dragons Slain - 2nd Period',
        1135: 'Under / Över Dragons Slain - 3rd Period',
        1136: 'Under / Över 180 \ s',
        1137: 'första 180',
        1138: 'senast 180',
        1139: 'Under / Över Högsta kassan Score',
        1140: 'De flesta 180 \ s',
        1141: 'Under / Över Högsta kassan Score - Hemmalag',
        1142: 'Under / Över Högsta kassan Score - Bortalag',
        1143: 'Första spelare som gör mål Touchdown',
        1144: 'Sista spelare som gör mål Touchdown',
        1145: 'Första spelare som gör mål Touchdown - Hemmalag',
        1146: 'Första spelare som gör mål Touchdown - Bortalag',
        1147: 'Under / Över Kills - 4 Period',
        1148: 'Under / Över Kills - 5: e Period',
        1149: 'Asian Handicap Kills - Period 1',
        1150: 'Asian Handicap Kills - 2nd Period',
        1151: 'Asian Handicap Kills - 3rd Period',
        1152: 'Asian Handicap Kills - 4 Period',
        1153: 'Asian Handicap Kills - 5: e Period',
        1154: 'Under / Exakt / Över 180 \ s',
        1155: 'Under / Exakt / Över Totalt 180 \ s - Home Team',
        1156: 'Under / Exakt / Över Totalt 180 \ s - Bortalag',
        1157: 'De flesta kassadiskar',
        1158: 'Först försöka målgörare',
        1159: 'Sista försök Scorer',
        1160: 'När som helst Försök Scorer',
        1162: 'Under / Över tous Förstörda - Period 1',
        1163: 'Under / Över tous Förstörda - 2nd Period',
        1164: 'Under / Över tous Förstörda - 3rd Period',
        1165: 'Period 1 - 1 Baron',
        1166: '2nd Period - 1st Baron',
        1167: 'Period 3 - 1 Baron',
        1168: '4. Period - 1st Baron',
        1169: '5. Period - 1st Baron',
        1170: 'Period 1 - 1 Draken',
        1171: '2nd Period - 1 Draken',
        1172: 'Period 3 - 1 Draken',
        1173: '4. Period - 1 Draken',
        1174: '5. Period - 1 Draken',
        1175: 'De flesta Kills - Period 1',
        1176: 'De flesta Kills - 2nd Period',
        1177: 'De flesta Kills - 3rd Period',
        1178: 'De flesta Kills - 4 Period',
        1179: 'De flesta Kills - 5: e Period',
        1180: 'De flesta Kills - 6: e Period',
        1181: 'De flesta Kills - 7 Period',
        1182: 'Spelare gör mål 2 eller fler Trys',
        1183: 'Spelare gör mål 3 eller fler Trys',
        1184: 'Inte för att vinna Leauge',
        1185: 'Under / Exakt / Över inklusive övertid - Hemmalag',
        1186: 'Under / Exakt / Över inklusive övertid - Bortalag',
        1187: 'Under / Exakt / Över inklusive övertid',
        1188: 'Under / Över Ersättare',
        1189: 'Under / Över Avbytare - Period 1',
        1190: 'Sista spelare som gör mål Touchdown - Hemmalag',
        1191: 'Sista spelare som gör mål Touchdown - Bortalag',
        1192: 'Första spelaren att Hit ett ess',
        1193: 'Under / Över Player Quarter Passing Yards',
        1194: 'Under / Över Player Passing Touchdowns',
        1195: 'Under / Över Längsta Player Pass Slutförande',
        1196: 'Under / Över Player emot Yards',
        1197: 'Under / Över Player Brytningar',
        1198: 'Under / Över Player Kicking Points',
        1199: 'Under / Över Player Rushing & emot Yards',
        1200: 'Under / Över Player ilayards',
        1201: 'Under / Över Player Längsta mottagning',
        1202: 'Under / Över Player Mottagningar',
        1203: 'Under / Över Player sätter in mål Made',
        1204: 'Under / Över Player sätter in mål Utförda',
        1205: 'Under / Över Player Quarter Passing Touchdowns',
        1206: 'Under / Över Player mottagare Mottagningsförda',
        1207: 'Under / Över Player Quarter Pass Avslutade',
        1208: 'Under / Över Player Rushing Touchdowns',
        1209: 'Under / Över Player mottagare Mottagnings Försök',
        1210: 'Under / Över Player Quarter Pass Försök',
        1211: '1X2 Ojustheter',
        1212: 'Dubbelchans Fouls',
        1213: 'Under / Över Fouls - Period 1',
        1214: 'Under / Över Fouls - 2nd Period',
        1215: 'Asian Handicap Fouls',
        1216: 'Under / Över Regelbrott - Hemmalag',
        1217: 'Under / Över Regelbrott - Bortalag',
        1218: 'Under / Över Player Rush Försök',
        1219: 'Under / Över spelare som får Touchdowns',
        1222: 'Under / Över inkast - Hemmalag',
        1223: 'Under / Över inkast - Bortalag',
        1224: '1X2 inkast',
        1225: 'Dubbelchans inkast',
        1226: 'Under / Över inkast - Period 1',
        1227: 'Inkast Handicap',
        1228: 'Inkast Handicap - Period 1',
        1229: 'Under / Över Skott på mål - Hemmalag',
        1230: 'Under / Över Skott på mål - Bortalag',
        1231: 'Asian Handicap Skott på mål',
        1232: 'Asian Handicap skott på mål - Period 1',
        1233: 'Under / Över skott på mål - Period 1',
        1234: '1X2 Skott på Target',
        1235: 'Dubbelchans Skott på mål',
        1236: 'Under / Över Totalt Shots - Home Team',
        1237: 'Under / Över Totalt Shots - Bortalag',
        1238: 'Dubbelchans Total Shots',
        1239: '1X2 Totalt Skott',
        1240: 'Asian Handicap Totalt Shots',
        1241: 'Asian Handicap Totalt Shots - Period 1',
        1242: 'Under / Över Totalt Shots - Period 1',
        1243: 'Dubbelchans Corners',
        1244: 'Dubbelchans Corners - Period 1',
        1245: 'Hörn Från alla 4 hörn av Pitch',
        1246: 'Dubbelchans Yellow Cards - 1: a perioden',
        1247: '1X2 Yellow Cards - Period 1',
        1248: 'Asian Handicap Yellow Cards - Period 1',
        1249: 'Dubbel chans & Under / Över',
        1250: 'Dubbelchans & Båda lagen gör mål',
        1251: 'Period 1 - 1 Inhibitor',
        1252: '2nd Period - 1 Inhibitor',
        1253: 'Period 3 - 1 Inhibitor',
        1254: '4. Period - 1 Inhibitor',
        1255: '5. Period - 1 Inhibitor',
        1256: 'Båda lagen att döda en Baron - Period 1',
        1257: 'Båda lagen att döda en Baron - 2nd Period',
        1258: 'Båda lagen att döda en Baron - 3: e Period',
        1259: 'Båda lagen att döda en Baron - 4 Period',
        1260: 'Båda lagen att döda en Baron - 5: e Period',
        1261: 'Båda lagen att döda en drake - 4 Period',
        1262: 'Båda lagen att döda en drake - 5: e Period',
        1263: 'Båda lagen att döda en inhibitor - 4 Period',
        1264: 'Båda lagen att döda en inhibitor - 5: e Period',
        1265: 'Kommer det att finnas en Ace nästa match?',
        1266: 'Kommer det att finnas dubbelfel nästa match?',
        1267: 'Udda / Jämnt Fouls',
        1268: 'Asian Handicap Ball Innehav',
        1269: 'Dubbelchans Offsides',
        1270: 'Asian Handicap Offsides',
        1271: 'Under / Över Offsides - Hemmalag',
        1272: 'Under / Över Offsides - Bortalag',
        1273: '1X2 Offsides',
        1274: 'Under / Över Kills 1: a perioden - Hemmalag',
        1275: 'Under / Över Kills 2nd Period - Hemmalag',
        1276: 'Under / Över Kills 3rd Period - Hemmalag',
        1277: 'Under / Över Kills 1: a perioden - Bortalag',
        1278: 'Under / Över Kills 2nd Period - Bortalag',
        1279: 'Under / Över Kills 3rd Period - Bortalag',
        1280: 'Under / Över Wides',
        1281: 'Under / Över Ducks',
        1282: 'Under / Över Wickets',
        1283: 'Under / Över Match Fours - Hemmalag',
        1284: 'Under / Över Match Sexor - Hemmalag',
        1285: 'Under / Över Match Fours - Bortalag',
        1286: 'Under / Över Match Sexor - Bortalag',
        1287: 'Under / Över Extra',
        1288: 'Fall of 1st Wicket - Hemmalag',
        1289: 'Fall of 1st Wicket - Bortalag',
        1290: 'Team Top slagman',
        1291: 'Under / Över Match Sexor Home Team - 1: a omgången',
        1292: 'Under / Över Match Sexor Bortalag - 1: a omgången',
        1293: 'Under / Över Match Fours Home Team - 1: a omgången',
        1294: 'Under / Över Match Fours Bortalagets - 1: a omgången',
        1295: 'Under / Över Top slagman',
        1296: '1st inningen 1 Over Uppsägning - Hemmalag',
        1297: '1st Inning 2nd Over Uppsägning - Hemmalag',
        1298: '1st Inning 3rd Over Uppsägning - Hemmalag',
        1299: '1st Inning 2nd Over Uppsägning - Bortalag',
        1300: '1st Inning 3rd Over Uppsägning - Bortalag',
        1301: '1st Inning 1st Over Uppsägning - Bortalag',
        1302: 'Högsta poäng Over - Under / Över',
        1303: '1st Inning 2nd Över Under / Over - Hemmalag',
        1304: '1st Inning 3rd Över Under / Over - Hemmalag',
        1306: '1st Inning 3rd Över Under / Over - Bortalag',
        1307: '1st Inning 2nd Över Under / Over - Bortalag',
        1309: 'Vinstmarginal - lag',
        1310: 'Vinstmarginal - Lag inklusive övertid',
        1312: 'Vinstmarginal Exakt',
        1314: 'Vinstmarginal Ranges',
        1315: 'Vinstmarginal exakt - Lag',
        1316: 'Vinstmarginal - lag med någon annan Score',
        1318: 'Asian Handicap Fouls - Period 1',
        1319: 'Asian Handicap Offsides - Period 1',
        1320: 'Asian Handicap Offsides - 2nd Period',
        1321: 'Asian Handicap Fouls - 2nd Period',
        1322: 'Asian Handicap skott på mål - 2nd Period',
        1324: 'Under / Över Offsides - Period 1',
        1325: 'Under / Över Offsides - 2nd Period',
        1326: 'första Foul',
        1327: 'Första två visare',
        1328: 'Första tre Pointer',
        1329: 'Senast två visare',
        1330: 'Senast tre Pointer',
        1331: 'Senaste Frikast Mål',
        1332: 'Första Free Throw Mål',
        1333: 'första Rebound',
        1334: 'senast Timeout',
        1335: 'Vinstmarginal Lag - Period 1',
        1336: 'Vinstmarginal Lag - 2nd Period',
        1337: 'Vinstmarginal Lag - 3rd Period',
        1338: 'Vinstmarginal Lag - 4 Period',
        1339: 'Vinstmarginal Lag - 5: e Period',
        1340: 'senast Foul',
        1341: 'Under / Över 10th Period',
        1342: 'Under / Över 11 Period',
        1343: 'Under / Över 12 Period',
        1344: 'Under / Över 13 Period',
        1345: 'Under / Över 14 Period',
        1346: 'Under / Över 15 Period',
        1347: 'Under / Över 16 Period',
        1348: 'Under / Över 17 Period',
        1349: 'Under / Över 18 Period',
        1350: 'Under / Över 19 Period',
        1351: 'Under / Över 20 Period',
        1352: 'Under / Över 21 Period',
        1353: 'Under / Över 22 Period',
        1354: 'Under / Över 23 Period',
        1355: 'Under / Över 24 Period',
        1356: 'Under / Över 25 Period',
        1357: 'Under / Över 26 Period',
        1358: 'Under / Över 27 Period',
        1359: 'Under / Över 28 Period',
        1360: 'Under / Över 29 Period',
        1361: 'Under / Över 30 Period',
        1362: 'Under / Över 31 Period',
        1363: 'Under / Över 32nd Period',
        1364: 'Under / Över 33: e Period',
        1365: 'Under / Över 34th Period',
        1366: 'Under / Över 35: e Period',
        1367: 'Asian Handicap 11th Period',
        1368: 'Asian Handicap 12th Period',
        1369: 'Asian Handicap 13th Period',
        1370: 'Asian Handicap 14th Period',
        1371: 'Asian Handicap 15 Period',
        1372: 'Asian Handicap 16th Period',
        1373: 'Asian Handicap 17 Period',
        1374: 'Asian Handicap 18 Period',
        1375: 'Asian Handicap 19 Period',
        1376: 'Asian Handicap 20th Period',
        1380: 'Asian Handicap 24 Period',
        1381: 'Asian Handicap 25 Period',
        1382: 'Asian Handicap 26 Period',
        1383: 'Asian Handicap 27 Period',
        1384: 'Asian Handicap 28 Period',
        1385: 'Asian Handicap 29 Period',
        1386: 'Asian Handicap 30 Period',
        1390: 'Asian Handicap 34th Period',
        1391: 'Asian Handicap 35th Period',
        1392: 'Till slut i Top 10',
        1393: 'Att stanna uppe',
        1394: 'Asian Handicap 10th Period',
        1395: 'Under / Över Player Powerplay Points',
        1396: 'Under / Över Player Plus / Minus',
        1397: 'Under / Över Player Shots',
        1398: 'Top Victorian Club',
        1399: 'Under / Över Run Outs',
        1400: 'Under / Över Frames',
        1401: 'Topp 20 Vinnare',
        1402: '1:a Round Leader',
        1404: '1st Round Six Shooter - Grupp A',
        1406: '1: a omgången 3 bollar',
        1407: '1:a Runda 18 Hål Match-Ups',
        1408: 'match handikapp',
        1409: 'Lag med Top Bowler',
        1410: 'För att vinna Toss och matchen',
        1411: 'Under / Över Turrets Förstörda',
        1412: 'Under / Över Turrets Förstörda - Period 1',
        1413: 'Under / Över Turrets Förstörda - 2nd Period',
        1414: 'Under / Över Turrets Förstörda - 3rd Period',
        1415: 'Högsta ställningen efter 1: a Over',
        1416: 'Home Team Under / Över Runs - 1 Över till 6 Over',
        1417: 'Bortalaget Under / Över Runs - 1 Över till 6 Over',
        1418: 'Home Team Under / Över Runs - 1 Över till 8 Over',
        1419: 'Bortalaget Under / Över Runs - 1 Över till 8 Over',
        1420: 'Home Team Under / Över Runs - 1 Över till 10th Over',
        1421: 'Bortalaget Under / Över Runs - 1 Över till 10th Over',
        1422: 'Chase över 180 körningar och laget att vinna',
        1423: 'Hur många körningar görs från den första bollen',
        1424: 'Under / Över Match Stumpings',
        1425: '1:e Wicket Metod',
        1426: 'Första bollen i matchen',
        1427: 'Under / Over - 1: a omgången',
        1428: 'Top Non Victorian Club',
        1429: 'Grand Final vinnare',
        1431: 'För att nå Grand Final',
        1432: 'Inte till slut i Top 8',
        1434: 'Under / Över Kills 4 Period - Bortalag',
        1435: 'Under / Över Kills 5th Period - Bortalag',
        1436: 'Under / Över Kills 4: e perioden - Hemmalag',
        1437: 'Under / Över Kills 5 Period - Hemmalag',
        1438: 'Under / Över Temperatur vid flygplatsen (Celsius)',
        1439: 'Asian Handicap - Heltid',
        1440: 'Under / Över värdet av allt dragna numren',
        1441: 'Under / Över värdet av udda nummer Drawn',
        1442: 'Under / Över värdet av jämna tal Drawn',
        1443: 'Jämnare dras än udda nummer',
        1444: 'På varandra följande nummer kommer att dras',
        1445: 'Under / Exakt / Over - Antal Odd numrerade bollar Drawn',
        1446: 'Under / Exakt / Over - Antal jämna bollar Drawn',
        1447: 'Summan av fem Bonus Balls',
        1448: 'Första Bonus Ball Drawn - Udda / Jämnt',
        1449: 'Senaste Bonus Ball Drawn - Udda / Jämnt',
        1450: 'Första Bonus Ball',
        1451: 'Senast Bonus Ball',
        1452: 'Den lägsta av 5 Bonus Balls',
        1453: 'Bonus Ball - Udda / Jämnt',
        1454: 'Identiska nummer kommer att dras',
        1455: 'Högsta antalet Drawn - Udda / Jämnt',
        1456: 'Lägsta antalet Drawn - Udda / Jämnt',
        1457: 'Ball 1 - Under / Över',
        1458: 'Ball 1 - Udda / Jämnt',
        1459: 'Ball 2 - Under / Över',
        1460: 'Ball 2 - Udda / Jämnt',
        1461: 'Ball 3 - Under / Över',
        1462: 'Ball 3 - Udda / Jämnt',
        1463: 'Ball 4 - Under / Över',
        1464: 'Ball 4 - Udda / Jämnt',
        1465: 'Ball 5 - Under / Över',
        1466: 'Ball 5 - Udda / Jämnt',
        1467: 'Ball 6 - Under / Över',
        1468: 'Ball 6 - Udda / Jämnt',
        1469: 'Ball 7 - Under / Över',
        1470: 'Ball 7 - Udda / Jämnt',
        1471: 'Ball 8 - Under / Över',
        1472: 'Ball 8 - Udda / Jämnt',
        1473: 'Ball 9 - Under / Över',
        1474: 'Ball 9 - Udda / Jämnt',
        1475: 'Ball 10 - Under / Över',
        1476: 'Ball 10 - Udda / Jämnt',
        1477: 'Ball 11 - Under / Över',
        1478: 'Ball 11 - Udda / Jämnt',
        1479: 'Ball 12 - Under / Över',
        1480: 'Ball 12 - Udda / Jämnt',
        1481: 'Ball 13 - Under / Över',
        1482: 'Ball 13 - Udda / Jämnt',
        1483: 'Ball 14 - Under / Över',
        1484: 'Ball 14 - Udda / Jämnt',
        1485: 'Ball 15 - Under / Över',
        1486: 'Ball 15 - Udda / Jämnt',
        1487: 'Ball 16 - Under / Över',
        1488: 'Ball 16 - Udda / Jämnt',
        1489: 'Ball 17 - Under / Över',
        1490: 'Ball 17 - Udda / Jämnt',
        1491: 'Ball 18 - Under / Över',
        1492: 'Ball 18 - Udda / Jämnt',
        1493: 'Ball 19 - Under / Över',
        1494: 'Ball 19 - Udda / Jämnt',
        1495: 'Ball 20 - Under / Över',
        1496: 'Ball 20 - Udda / Jämnt',
        1497: 'Minst ett av numren dras är delbart med 3',
        1498: 'Minst ett av numren dras är delbart med fyra',
        1499: 'Minst ett av numren dras är delbart med fem',
        1500: 'Minst ett av numren dras är delbart med 7',
        1501: 'Minst ett av numren dras är delbart med 10',
        1502: 'Minst ett av numren dras är delbart med 15',
        1503: 'Minst ett av numren dras är delbart med 20',
        1504: 'Högsta antalet Drawn - Under / Över',
        1505: 'Totala värdet av lägsta och högsta numren dras - Under / Över',
        1506: 'Lägsta antalet Drawn - Under / Över',
        1507: 'Skillnaden mellan den högsta och den lägsta numren dras - Under / Över',
        1508: 'Totala värdet av lägsta och högsta numren dras - Udda / Jämnt',
        1509: 'Skillnaden mellan den högsta och den lägsta numren dras - Udda / Jämnt',
        1510: 'Första Number Drawn är större än det sista numret Drawn',
        1511: 'Sista numret Drawn - Udda / Jämnt',
        1512: 'Första Number Drawn - Udda / Jämnt',
        1513: 'Alla dragna numren Även',
        1514: 'Alla dragna numren Odd',
        1515: 'Nummer att dra',
        1520: '4. Period - 1 Turret',
        1521: '5. Period - 1 Turret',
        1523: 'Under / Över Barons Slain - 4 Period',
        1524: 'Under / Över Barons Slain - 5: e Period',
        1525: 'Under / Över Dragons Slain - 4 Period',
        1526: 'Under / Över Dragons Slain - 5: e Period',
        1527: 'Under / Över Turrets Förstörda - 4 Period',
        1528: 'Under / Över Turrets Förstörda - 5: e Period',
        1529: '4:e Period Varaktighet',
        1530: '5:e Period Varaktighet',
        1531: 'Udda / Jämnt dödar - 4 Period',
        1532: 'Udda / Jämnt dödar - 5: e Period',
        1533: 'Under / Över hämmare - Period 1',
        1534: 'Under / Över hämmare - 2nd Period',
        1535: 'Temperatur vid flygplatsen inom räckvidd (Celsius)',
        1536: 'Under / Maps över',
        1537: 'Home laget att vinna och Under / Over - Inklusive övertid',
        1538: 'Bortalaget vinner och & Under / Over - Inklusive övertid',
        1539: 'Round Winner',
        1540: 'Total Match ben under / över',
        1541: 'Rounds Handicap',
        1542: '1:e Leg Winner',
        1543: 'Först att vinna 3 Legs',
        1546: '4. Period - 1 Roshan',
        1547: '5. Period - 1 Roshan',
        1548: '4. Period - 1st Barracks',
        1549: '5. Period - 1st Barracks',
        1550: '4. Period - 1st tou',
        1551: '5. Period - 1st tou',
        1552: 'Asian under / över Corners',
        1553: 'Båda lagen gör mål Antal poäng - Period 1',
        1554: 'Båda lagen gör mål Antal poäng - 1: a halvlek',
        1555: 'Under / Över 5 Period - Hemmalag',
        1556: 'Under / Över 5 Period - Bortalag',
        1558: 'Asian Handicap Poäng',
        1559: 'Cup Winner',
        1561: '1:a 3 inningen Winner',
        1562: '1:a 3 inningen Under / Över',
        1563: 'Time Of Scoring',
        1564: 'Time Of Scoring - Hemmalag',
        1565: 'Time av Scoring - Bortalag',
        1566: 'Matchtid Resultat',
        1567: 'Top nationalitet - Sydafrikanska',
        1568: 'Top nationalitet - spanjoren',
        1569: 'Top nationalitet - Irish',
        1570: 'Top nationalitet - Sydamerikanskt',
        1571: 'Top nationalitet - Canadian',
        1572: 'Top nationalitet - engelsman',
        1573: 'Top nationalitet - Kontinentaleuropa',
        1575: 'Man gör Cut',
        1576: 'Miss Cut',
        1577: 'Top nationalitet - American',
        1578: 'Nationalitet Winner',
        1579: 'Asian Handicap Poäng - Period 1',
        1580: 'Asian Handicap Poäng - 2nd Period',
        1581: 'Asian Handicap Poäng - 3rd Period',
        1583: 'Top Team Bowler 1st Inning - Hemmalag',
        1584: 'Top Team Bowler 1st Inning - Bortalag',
        1585: 'Top Team slagman 1st Inning - Hemmalag',
        1586: 'Top Team slagman 1st Inning - Bortalag',
        1587: 'Kommer det att finnas ett ess Nästa Point',
        1589: 'Kommer det att finnas en dubbelfel Nästa Point',
        1591: 'Under / Over - 1 Over',
        1592: 'Att vinna ligan',
        1593: 'Att vinna Division',
        1594: 'De flesta Hits - Inklusive Övertid',
        1595: 'Kämpa för att gå avståndet',
        1596: 'När kommer Fight End',
        1597: 'Fight Utfall',
        1598: 'Round Betting',
        1599: 'Top 1 Hit',
        1600: 'Botten 1 Hit',
        1601: 'Top 1st Kör',
        1602: 'Botten 1st Kör',
        1603: 'De flesta träffar i 1: a omgången',
        1605: 'Lag med det högsta poäng Inning',
        1606: 'Båda lagen gör mål 2 eller fler mål',
        1607: 'Båda lagen gör mål 3 eller fler mål',
        1608: 'Laget att vinna de flesta Perioder',
        1609: 'Asian Handicap Full Time - Period 1',
        1610: 'Asian Handicap Full Time - 2nd Period',
        1611: 'Asian Handicap Full Time - 3rd Period',
        1612: 'MVP',
        1614: 'Att vinna Conference',
        1615: 'vinnande Conference',
        1617: 'Säsong Player Specials',
        1618: '1:a 5 Inningar Winner - 12',
        1619: '1: a perioden - Halvtid Vinnare Hem / Borta',
        1620: 'Period 1 - Halvtid Asian Handicap',
        1621: '2nd Period - Halvtid Vinnare Hem / Borta',
        1622: '2nd Period - Halvtid Asian Handicap',
        1624: 'Under / Över Player Runs',
        1625: 'Under / Över Player singlar',
        1626: 'Under / Över Player hemkörningar',
        1627: 'Under / Över Player stal baser',
        1628: 'Under / Över Player Doubles',
        1629: 'Under / Över Player Triples',
        1630: 'Pitcher att spela in Win',
        1631: 'Under / Över Player Totalt Bases',
        1635: 'Under / Över Pitcher Runs Intjänade',
        1636: 'Under / Över Pitcher Hits accepteras',
        1637: 'Under / Över Pitcher Utslag',
        1638: 'team Klassificering',
        1639: 'Young Rider Klassificering',
        1640: 'Points Klassificering',
        1641: 'King of the Mountains',
        1642: 'Steg 1',
        1649: 'snabbaste varv',
        1650: 'vinnande bil',
        1651: 'Första Driver att gå i pension',
        1652: 'Kval vinnande bilen',
        1653: 'Snabbast i Practice 1',
        1654: 'Points Finish',
        1655: 'snabbaste Qualifier',
        1657: 'stadium 3',
        1659: 'Första Constructor Retirement',
        1660: 'Grid placering Winner',
        1661: 'Safety Car Period Under Race',
        1662: 'Antal Classified Drivers',
        1663: 'Virtual Safety Car Period Under Race',
        1664: 'hål Score',
        1667: 'Att vinna Hole',
        1670: 'bundet Match',
        1672: 'Under / Över mål - 2: a halvlek',
        1673: 'Udda / Jämnt Rounds 4 Period',
        1674: 'Udda / Jämnt Rounds 5th Period',
        1675: '2:e Round Leader',
        1676: 'Båda lagen gör mål - inklusive övertid',
        1677: 'Sista lag att göra mål - inklusive övertid',
        1678: 'Top nationalitet - Tyska',
        1679: 'Top nationalitet - österrikiska',
        1680: 'Top nationalitet - nyzeeländaren',
        1681: 'Top vänsterhänt spelare',
        1682: 'Top Amateur',
        1683: 'Top nationalitet - Korean',
        1684: 'Top nationalitet - Scandinavian',
        1685: 'Top nationalitet - frans',
        1686: 'Top Tidigare vinnare',
        1687: 'Top nationalitet - Swede',
        1688: 'Top nationalitet - Japanska',
        1689: 'Top nationalitet - Scotsman',
        1690: 'Top nationalitet - Belgiska',
        1691: 'Handikapp 4th Period',
        1692: 'Handikapp 3rd Period',
        1693: 'Handikapp 2nd Period',
        1694: 'Handikapp 1: a perioden',
        1695: 'runda Score',
        1696: 'Turnering vinnande Score',
        1697: 'Ledande ställningen efter Round',
        1698: 'Att träffa fairway med utslag',
        1699: 'Att Hit Green i förordning',
        1700: '3:e Round Leader',
        1702: 'Under / Över Player Passing Försök',
        1704: 'Touchdown på Period 1',
        1705: 'Touchdown den 2 Period',
        1706: 'Touchdown på 3: e Period',
        1707: 'Touchdown på 4: e Period',
        1708: 'Under / Exakt / Over - Home Team Touchdowns',
        1709: 'Under / Exakt / Over - Bortalag Touchdowns',
        1710: 'Under / Exakt / Over - hemmalaget 1: a halvlek Touchdowns',
        1711: 'Under / Exakt / Over - Bortalag 1: a halvlek Touchdowns',
        1712: '16 Round Winner - 4 Period',
        1713: '16 Round Winner - 5: e Period',
        1714: 'Under / Över tous Förstörda - 4 Period',
        1715: 'Under / Över tous Förstörda - 5: e Period',
        1722: 'Ställ in på Slutför för att Nil',
        1723: 'Under / Över Kills - Period 1 - 1: a omgången',
        1724: 'Under / Över Kills - 2: a perioden - 1: a omgången',
        1728: 'Båda lagen gör mål - extra tid',
        1729: 'Dubbelchans - Extra Time',
        1730: 'Kommer The Game Gå till Super Over?',
        1731: 'Dubbelchans 5th Period',
        1732: 'Dubbelchans 6th Period',
        1733: 'Dubbelchans 7th Period',
        1734: 'Dubbelchans 8th Period',
        1736: 'Antal körningar 1st Inning',
        1737: 'Antal körningar 2nd Inning',
        1738: 'Antal körningar 3rd omgången',
        1739: 'Antal körningar 4 Inning',
        1740: 'Antal körningar 5th omgången',
        1741: 'Antal körningar 6th omgången',
        1742: 'Antal körningar 7th Inning',
        1743: 'Antal körningar 8th Inning',
        1744: 'En femtio till görs i 1: a innings',
        1745: 'Fall of 1st Wicket',
        1747: 'Näste Man Ut',
        1748: 'Avslutade Match',
        1749: 'Century Mål',
        1750: 'Century Gjorde - 1: a omgången',
        1752: 'Bortalaget Första Uppsägning 6-Way',
        1753: 'Top Match slagman',
        1754: 'Gör mål på straff',
        1758: '6th Man of the Year',
        1760: 'Årets nybörjare',
        1761: 'Ordinarie säsong MVP',
        1763: 'Slagman gör mål hundra i 1: a innings',
        1765: '9 Dart Finish i matchen',
        1766: 'Tid för första försöket',
        1767: 'Tid för sista försök',
        1770: '9 Dart Finish i Match - Home Team',
        1771: '9 Dart Finish i Match - Bortalag',
        1772: 'Top Run Scorer',
        1773: 'Top Run Scorer - Hemmalag',
        1774: 'Top Run Scorer - Bortalag',
        1775: 'Team Top Run Scorer',
        1776: 'Under / Över Punts',
        1777: 'Under / Över Sacks',
        1778: 'Under / Över 1: a Downs',
        1779: '1st laget för att få ett första besegrar',
        1780: '1: a halvlek Under / Över sätter in mål - Home Team',
        1781: '1: a halvlek Under / Över sätter in mål - Bortalag',
        1782: 'Under / Exakt / Over - Fält Mål 1: a halvlek',
        1783: 'Under / Över Touchdowns 1: a perioden',
        1784: 'Under / Över Påföljder Godkända',
        1785: 'De flesta Kills i matchen',
        1786: 'Team med de flesta Kills i matchen',
        1787: 'Under / Över Pass Försök',
        1788: 'Under / Över Pass Förda',
        1789: 'Under / Över Passing Yards',
        1790: 'Under / Över Rush Försök',
        1791: 'Under / Över Rush Yards',
        1792: 'Under / Över Player tacklar och Assist',
        1794: 'Lag som Punt First',
        1795: 'Period 1 1X2 och Under / Över',
        1796: '1X2 Assist',
        1797: 'Dubbelchans Assist',
        1798: 'Team för att leda efter varje period',
        1799: 'Team med de flesta Passing Yards',
        1800: 'Team med de flesta ilayards',
        1801: 'Framgångsrik 2 Point Conversion',
        1802: 'Under / Över touchdownpasserandar',
        1803: 'Under / Över Fält mål Period 1',
        1804: 'Betyg i sista 2 minuterna i 1: a halvlek',
        1805: 'Team att ta emot Öppna Kick Off',
        1806: 'Under / Över Hemmalagets Touchdowns - 1: a perioden',
        1807: 'Under / Över Bortalagets Touchdowns - 1: a perioden',
        1808: 'Under / Exakt / Över Hemmalagets Touchdowns - 1: a perioden',
        1809: 'Under / Exakt / Över bortalaget Touchdowns - 1: a perioden',
        1810: 'Under / Över Hemmalagets sätter in mål - 1: a perioden',
        1812: 'Under / Över Bortalagets sätter in mål - 1: a perioden',
        1813: 'Bortalaget vinner och varje kvartal',
        1814: 'Home laget att vinna varje kvartal',
        1815: 'Första offensiva spel av spelet',
        1816: 'Att vinna Quarter',
        1817: 'För att nå den slutliga',
        1818: 'Hemmalaget gör mål 25 mål',
        1819: 'Hemmalaget gör mål 30 mål',
        1820: 'Bortalaget gör mål 25 mål',
        1821: 'Bortalaget gör mål 30 mål',
        1822: 'Home laget att vinna Antingen Half',
        1823: 'Bortalaget vinner och Antingen Half',
        1824: 'Stadium Elimination',
        1825: 'Under / Över Assist',
        1826: 'Under / Över Rebounds',
        1827: 'Under / Över hemmalaget Assist',
        1828: 'Under / Över bortalaget Assist',
        1829: 'Under / Över Hemmalagets Rebounds',
        1830: 'Under / Över Bortalag Rebounds',
        1831: '1X2 3 Poäng Made',
        1832: 'Under / Över 3 punkter',
        1833: 'Under / Över Hemmalagets 3 punkter',
        1834: 'Under / Över bortalaget 3 punkter',
        1835: 'Asian Handicap 3 punkter',
        1841: 'första Ersättare',
        1842: '2: a mål i matchen',
        1843: '3: e mål i matchen',
        1846: '60 Minute Resultat',
        1847: '75 Minute Resultat',
        1848: 'Mål görs i första 5 minuterna?',
        1849: 'Första inkast i matchen',
        1850: 'Rita in Antingen Half',
        1851: 'Leds mål i matchen?',
        1852: 'Under / Över Goal Minutes',
        1853: 'Under / Över Tie Break i matchen',
        1854: 'Under / Över brytpunkter',
        1855: 'Under / Över brytpunkter Hemmalag',
        1856: 'Under / Över brytpunkter Bortalag',
        1857: 'Under / Över flest poäng Quarter',
        1858: 'Under / Över Lägsta poäng Quarter',
        1859: 'De flesta Tries 3-vägs',
        1860: '10 Minute Vinstmarginal 5-Way',
        1861: 'De flesta Förluster Team',
        1862: 'En Sub Kommer mål i spelet',
        1863: 'Win Alla Perioder - Home Team',
        1864: 'Win Alla Perioder - Bortalag',
        1865: 'Första 10 minut under / över Tries',
        1866: '15 minut under / över - Corners',
        1867: '2 mål i en ro av Lag',
        1868: '3 mål i en ro av Lag',
        1869: 'De flesta Konverterade Tries',
        1872: 'Unseeded Finalist?',
        1873: 'Wild Card att nå en slutlig?',
        1874: 'Vinnande Half - Tennis Outright',
        1875: 'Vinnande Quarter - Tennis Outright',
        1881: 'Enhålare',
        1882: 'Kommer det att finnas en Play-off?',
        1883: '72 Hål Match Ups',
        1884: '54 Hål Match Ups',
        1885: 'Nummer 1 Utkast Pick',
        1886: '1X2 och Dubbelchans',
        1887: 'Home laget att vinna \\ Rita och båda lagen gör mål',
        1888: 'Bortalaget vinner och \\ Rita och båda lagen gör mål',
        1889: 'Vinstmarginal - 1: a halvlek',
        1891: 'American League Cy Young',
        1892: 'American League MVP',
        1893: 'National League Cy Young',
        1894: 'National League MVP',
        1895: 'Pitcher för att spela in de flesta Regular Season Wins',
        1896: 'Spelare att träffa flesta Regular Season hemkörningar',
        1897: 'Will Game beslutade extra tid?',
        1898: 'Ordinarie säsong Vinnande%',
        1900: 'Spelare Top målskytt',
        1901: 'Under / Över tacklar',
        1902: 'Nummer 2 Utkast Pick',
    }
}