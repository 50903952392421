export const GET_USER = '[APP] GET USER';
export const SET_USER = '[APP] SET USER';
export const SUBMIT_LOGIN = '[APP] SUBMIT LOGIN';
export const LOGOUT_USER = '[APP] LOGOUT USER';
export const LOGIN_SUCCESS = '[APP] LOGIN SUCCESS';
export const SIGNUP_ERROR = '[APP] SIGNUP ERROR';
export const LOGIN_ERROR = '[APP] LOGIN ERROR';
export const CHANGE_PASSWORD_SUCCESS = '[APP] CHANGE PASSWORD SUCCESS';
export const CHANGE_PASSWORD_ERROR = '[APP] CHANGE PASSWORD ERROR';
export const SET_CASHBACK_DATA = '[APP] SET CASHBACK DATA';
export const SET_CASHBACK_SUCESS = '[APP] SET CASHBACK SUCESS';
export const DEPOSIT_AMOUNT = '[APP] DEPOSIT AMOUNT';
export const DEPOSIT_SUCCESS = '[APP] DEPOSIT SUCCESS';
export const RESET_DEPOSIT_AMOUNT = '[APP] RESET DEPOSIT AMOUNT';
export const SET_ODD_TYPE = '[APP] SET ODD TYPE';
export const USER_DATA = '[APP] USER_DATA';
export const CLEAR_SUCCESS = '[APP] CLEAR SUCCESS';
export const WITHDRAW_AMOUNT = '[APP] WITHDRAW AMOUNT';

