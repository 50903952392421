export const es = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Deportes',
    live: 'EN VIVO',
    inplay: 'EN VIVO',
    today: 'Hoy',
    upcoming: 'próxima',
    tomorrow: 'Mañana',
    highlights: 'Reflejos',
    results: 'resultados',
    lastMinute: 'Último minuto',
    liveCasino: 'Casino en vivo',
    casino: 'Casino',
    myBets: 'Mis Apuestas',
    home: 'Casa',
    login: 'Iniciar Sesión',
    signup: 'Regístrate',
    betslip: 'Hoja de apuestas',
    search: 'Buscar',
    searchHere: 'Busca aquí',
    transaction: 'Transacción',
    changePassword: 'Cambiar la contraseña',
    logout: 'Cerrar sesión',
    single: 'Único',
    multiple: 'Múltiple',
    possibleWin: 'posible victoria',
    amount: 'Monto',
    ok: 'OK',
    totalOdds: 'Total de posibilidades',
    odd: 'Impar',
    totalPossibleWin: 'victoria total posible',
    placeBet: 'Cerrar apuesta',
    fastBet: 'Rápido Apuesta',
    fast: 'Rápido',
    bet: 'Apuesta',
    username: 'Nombre de usuario',
    password: 'Contraseña',
    stayLoggedIn: 'Permanecer conectado',
    all: 'Todas',
    "in game": 'En el juego',
    won: 'Ganado',
    lost: 'Perdió',
    refund: 'Reembolso',
    date: 'Fecha',
    winAmount: 'Importe del premio',
    stake: 'Apostar',
    pick: 'Elegir',
    myAccount: 'Mi cuenta',
    football: 'Fútbol',
    basketball: 'Baloncesto',
    iceHockey: 'Hockey sobre hielo',
    tennis: 'Tenis',
    volleyball: 'Vóleibol',
    boxing: 'Boxeo',
    americanFootball : ' fútbol americano',
    baseball: 'Béisbol',
    hockey: 'Hockey',
    tableTennis: 'Tenis de mesa',
    badminton: 'Bádminton',
    cricket: 'Grillo',
    futsal: 'Futsal',
    golf: 'Golf',
    handball: 'Balonmano',
    rugbyLeagues: 'Ligas de rugby',
    pickCanNotBeCombined: 'Esta opción no se puede combinar',
    matchStopped: 'Partido detuvo',
    favorites: 'Favoritos',
    cashout: 'Salida de efectivo',
    "in progress": 'En curso',
    rejected: 'Rechazado',
    tip: 'Consejo',
    noGoal: 'Sin meta',
    yes: 'Sí',
    over: 'Encima',
    under: 'Debajo',
    even: 'Incluso',
    areYouSureWantCashout: '¿Seguro que desea realizar un retiro?',
    noGoal: 'No',
    bonus: 'Prima',
    termsAndConditon: 'Términos y condiciones',
    aboutUs: 'Sobre nosotros',
    contactUs: 'Contáctenos',
    payments: 'Pagos',
    howPlacebet: 'Cómo colocar la apuesta',
    deposit: "D'epositar",
    credit: 'Crédito',
    debit: 'Débito',
    withdraw: 'Retirar',
    totalOdd: 'total Odd',
    totalStake: 'Apuesta total',
    topGames: 'Los mejores juegos',
    allGames: 'Todos los juegos',
    canNotBeCombined: 'Esta selección no se puede combinar',
    betSettled: 'apuesta fijada',
    betSuspended: 'apuesta suspendida',
    insufficientBalance: 'Saldo insuficiente',
    oldPassword: 'Contraseña anterior',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmar nueva contraseña',
    change: 'Cambio',
    fromDate: 'Partir de la fecha',
    toDate: 'Hasta la fecha',
    activityType: 'Tipo de actividad',
    show: 'Show',
    gameType: 'Tipo de juego',
    repeatBet: 'Repetir la misma apuesta',
    someThingWrong: 'Algo salió mal',
    status: 'Estado',
    coupon: 'Cupón',
    acceptOddChangesText: 'Tienes que aceptar los cambios para poder hacer una apuesta',
    turnOffAway: 'Apague todos modos',
    acceptOddChanges: 'Aceptar cambios impares',
    maxWinAmount: 'Max cantidad victoria',
    maxOdd: 'Max extraña',
    dateTime: 'Fecha y hora',
    type: 'Tipo',
    refreshCashout: 'El retiro de actualización',
    vendor: 'Vendedor',
    stakeLowerThen1: 'La participación debe ser menor',
    stakeGreaterThen1: 'La participación debe ser mayor a continuación',
    ESports: 'Esportúa',
    KSports: 'Ksportúa',
    promotion: 'Promoción',
    customerServicePage: 'Página de servicio al cliente',
    decimal: 'Decimal',
    fraction: 'Fracción',
    americanOdds: 'Probabilidades americanas',
    nothingFound: 'Nada Encontrado',
    noLiveMatch: 'Ningún partido en vivo está disponible',
    sportBook: 'Libro deportivo',
    claimNow: 'Reclama ahora',
    findOutMore: 'Saber más',
    seeMore: 'Ver más',
    playSports: 'Apostar ahora',
    playCasino: 'Ir al casino',
    playLiveCasino: 'Ir al casino en vivo', 
    playVirtual: 'Ir ahora',
    virtualForcasts: 'Juegos virtuales',
    sportsCard: 'Los mejores y variados mercados en todos tus deportes ',
    casinoCard: 'Diviértete en las tragamonedas y conviertete en  un ganador',
    liveCasinoCard: 'Vive toda la emoción del casino en vivo sin salir de casa ',
    virtualCard: 'Los mejores y divertidos juegos virtuales para ti',
    emptyBetslip: 'Su talón de apuestas está actualmente vacía. Haga clic en cualquier cuota para agregarla al boleto de apuestas.',
    topLeagues: 'Ligas Principales',
    print: 'Impresión',
    ticketVerification:'Verificación de entradas',
    checkTicket: 'Ver billete',
    ticketNumber: 'Numero de ticket',
    invalidTicketNumber: 'Número de billete no válido',
    reserve: 'Reserva',
    startDate: 'Fecha de inicio',
    betPlaced: 'Apuesta realizada',
    score: 'Puntaje',
    rulesAndRegulations: 'Reglas y regulaciones',
    responsibleGaming: 'Juego Responsable',
    printedOn: 'Impreso en',
    refHeading:'Código de Remisión',    
    refLabel: 'Remisión',
    marketNames: {
        1: '1x2',
        2: 'Bajo sobre',
        238: 'partido restante',
        59: 'Próximo objetivo',
        7: 'Doble oportunidad',
        52: 'Sin empate',
        226: '12 incluidas las horas extraordinarias',
        13: 'Handicap Europeo',
        4: 'HT / FT',
        17: 'Tanto Puntuación Equipos Para',
        59: 'Próximo objetivo',
        28: 'Más / Menos incluidas las horas extraordinarias',
        247: 'La mitad restante primero',
    },
    tabNames: {
        'All': 'Todas',
        'Main Markets': 'Los mercados principales',
        'Totals': 'Los totales',
        '1st Half': 'Primera mitad',
        '2nd Half': '2ª Parte',
        'Home Team': 'Equipo loca',
        'Away Team': 'Equipo de fuera',
        'All Periods': 'todos los períodos',
        'Results': 'Resultados',
        'Home / Away': 'Lejos de casa',
    },
    markets: {
      1: '1x2',  
      2: ' Bajo sobre',
      3: ' Handicap Asiático',
      5: ' Par / Impar',
      6: ' Puntuación correcta',
      7: 'Doble oportunidad',
      9: ' Resultado Correcto 1er Periodo',
      11: 'Total de Esquinas',
      12: 'Faltas total comprometido',
      13: 'Handicap Europeo',
      16: 'Primer equipo en marcar',
      17: 'Tanto Puntuación Equipos Para',
      19: 'primera tarjeta',
      21: 'Más / Menos 1er Periodo',
      22: 'Lejos equipo en marcar',
      23: 'El equipo local para anotar',
      25: 'Doble oportunidad Medio tiempo',
      29: 'Más / Menos Rondas',
      30: 'Bajo Menos Corners / - Equipo Local',
      31: 'Bajo Menos Corners / - Equipo visitante',
      34: 'Equipo Local en ambos tiempos',
      35: 'Para el equipo visitante en ambos tiempos',
      41: '1er Periodo Ganador',
      42: 'Ganador del 2º tiempo',
      43: '3er tiempo Ganador',
      44: 'Cuarto período Ganador',
      45: 'Más / Menos segundo período',
      46: 'Más / Menos del 3er tiempo',
      47: 'Más / Menos cuarto período',
      48: 'Más / Menos quinto período',
      49: 'Quinto período Ganador',
      50: 'Las horas extraordinarias 1X2 Incluyendo',
      51: 'Par / Impar horas extras incluido',
      52: '12',
      53: 'Medio tiempo Handicap Asiático',
      55: 'Primer equipo en marcar 1er Periodo',
      56: 'Último equipo en marcar',
      57: 'Último equipo en marcar parcial:',
      59: 'Próximo objetivo',
      61: 'Medio tiempo Handicap Europeo',
      62: 'Par / Impar Medio tiempo',
      63: '12 de medio tiempo',
      64: 'Handicap asiático 1er Periodo',
      65: 'Handicap asiático Período segundo',
      66: '3er tiempo Handicap asiático',
      67: 'Handicap Asiático cuarto período',
      68: 'Handicap Asiático quinto período',
      69: 'La voluntad de horas extras del juego Go To?',
      70: 'Más alto que alcanzaron el Período',
      71: 'Mitad de mayor puntuación',
      72: '1er Periodo par / impar',
      73: 'Segundo período de par / impar',
      74: '3er tiempo Par / Impar',
      75: 'Cuarto período de par / impar',
      76: 'Quinto período de par / impar',
      77: 'Más / Menos de medio tiempo',
      78: 'Que marque más Seises',
      79: 'Será premiado con un penalti?',
      80: 'En primer lugar Corner',
      81: 'última curva',
      82: 'Se le mostrará la tarjeta roja A?',
      83: 'Para mantener la portería a cero',
      84: 'Ganador de ambas mitades',
      85: 'Remontar',
      86: 'Para ganar a cero',
      88: 'Para calificar',
      89: 'Será el primer intento puede convertir la?',
      90: 'Irá el encuentro se decidirá en 5 ° set?',
      91: 'En lo que medio Primer Gol se marcarán?',
      92: 'Primer equipo en marcar segunda mitad',
      95: 'esquinas de la desventaja',
      96: 'Equipo visitante ganar a cero',
      97: 'Equipo Local ganar a cero',
      98: 'Inicio equipo para mantener la hoja A Clean',
      99: 'Equipo visitante para mantener la hoja A Clean',
      100: 'Resultado correcto segundo período',
      101: 'Más / Menos - Equipo Inicio',
      102: 'Más / Menos - Equipo visitante',
      104: 'Equipo visitante Ganador de ambas mitades',
      105: 'Equipo Local Ganador de ambas mitades',
      106: 'Inicio equipo para ganar desde detrás de',
      107: 'Lejos equipo para ganar desde detrás de',
      108: 'Inicio equipo en marcar de penal',
      109: 'Equipo visitante para anotar de penal',
      110: 'Lejos equipo para ganar al menos la mitad',
      111: 'Inicio equipo para ganar al menos la mitad',
      113: 'Los dos equipos marcan primera mitad',
      114: 'Cualquier jugador en anotar al menos 2 Objetivos',
      117: 'Cualquier jugador en anotar al menos 3 goles',
      122: 'En primer equipo que marca para ganar el partido',
      123: 'Equipo visitante a marcar primero y ganar el partido',
      124: 'Inicio Primer equipo en marcar y ganar el partido',
      128: 'Número de Goles',
      129: 'Bajo Menos Corners / - 1ª Parte',
      132: 'Bajo Disparos / Más en Target',
      133: 'Más / Menos Total de Disparos',
      134: 'Número de Goles 1er tiempo',
      136: 'Más / Menos de Lugar',
      137: 'Inicio Gane Apuesta sin',
      138: 'Victoria a domicilio Apuesta sin',
      139: '¿En qué tiempo Inicio equipo anotará Primer Gol?',
      140: '¿En qué tiempo de equipo visitante anotará Primer Gol?',
      143: '¿En qué tiempo Inicio equipo marcará más goles?',
      144: '¿En qué tiempo lejos equipo marcará más goles?',
      145: 'Equipo Local número de goles en 1ª mitad',
      146: 'Equipo visitante número de goles en 1ª mitad',
      147: 'Equipo visitante número de goles en 2ª mitad',
      148: 'Equipo Local número de goles en 2ª mitad',
      149: 'Equipo Local número de goles',
      150: 'Equipo visitante número de goles',
      151: 'Doble oportunidad segunda mitad',
      153: 'Más / Menos 1er Periodo - Equipo Local',
      154: 'Más / Menos segundo período - Equipo Local',
      155: 'Más / Menos 1er Periodo - Equipo visitante',
      156: 'Más / Menos segundo período - Equipo visitante',
      157: 'Más / Menos Tarjetas Amarillas - 1ª Parte',
      158: 'Más / Menos Tarjetas Amarillas',
      159: 'Será un tie-break en el partido?',
      160: 'Ganador de la carrera',
      161: '10 Resultado Minuto',
      162: '10 Minutos Más / Menos',
      163: 'Número de Goles 2a parte',
      164: 'Será un tie-break en el primer set?',
      165: 'En Conjuntos de / Más',
      166: 'Bajo Juegos / Más',
      168: 'Será un gol?',
      169: 'Anotar en ambas mitades',
      170: 'Par / impar Esquinas',
      171: 'Para ganar cualquiera de las mitades',
      175: 'Será un tie-break en el segundo set?',
      176: 'Será un tie-break en el tercer set?',
      177: 'Será un tie-break en el cuarto set?',
      178: 'Será un tie-break en el quinto set?',
      180: 'Más / Menos saques de banda',
      181: 'Más / Menos Tarjetas Amarillas - Equipo Inicio',
      182: 'Más / Menos Tarjetas Amarillas - Equipo Local primera mitad',
      183: 'Más / Menos Tarjetas Amarillas - Equipo Local segunda mitad',
      184: 'Más / Menos Tarjetas Amarillas - Equipo visitante',
      185: 'Más / Menos Tarjetas Amarillas - Equipo visitante primera mitad',
      186: 'Más / Menos Tarjetas Amarillas - Equipo visitante segunda mitad',
      187: 'Más / Menos Tarjetas Amarillas - 2ª Parte',
      188: 'La tarjeta amarilla',
      190: 'Primera tarjeta amarilla segunda mitad',
      191: 'Cualquier jugador le Marca un triplete?',
      192: 'Le marca un gol en ambos tiempos?',
      194: 'Equipo Local mantener una hoja limpia 1er Periodo',
      195: 'Equipo Local para mantener limpia la hoja Un segundo período',
      196: 'Equipo visitante mantener una hoja limpia 1er Periodo',
      197: 'Equipo visitante mantener una hoja limpia segundo período',
      198: 'Par / Impar - Equipo Inicio',
      199: 'Par / Impar - Equipo visitante',
      200: 'Will se dispare la primera equipo a ganar?',
      201: 'Juegos de Asia Handicap',
      202: '1er Periodo Ganador Local / Visitante',
      203: 'Ganador del 2º tiempo Local / Visitante',
      204: '3er tiempo Ganador Local / Visitante',
      205: 'Cuarto período Ganador Local / Visitante',
      206: 'Quinto período Ganador Local / Visitante',
      207: '15 Resultado Minuto',
      208: '30 Resultado Minuto',
      209: 'Esquinas totales - 1er tiempo',
      210: 'Esquinas totales - 2a parte',
      211: 'Ambos equipos a marcar 2ª Parte',
      212: 'Los dos equipos en ambos tiempos',
      213: 'Dibuje en ambos tiempos',
      214: 'Más / Menos Tarjetas',
      215: 'Equipo visitante en marcar primera mitad',
      216: 'Equipo visitante en marcar segunda mitad',
      217: '3er tiempo Marcador correcto',
      218: 'Inicio Equipo en Marcar primera mitad',
      219: 'Inicio Equipo en Marcar segunda mitad',
      220: 'Más / Menos - Lejos equipo que incluye horas extras',
      221: 'Más / Menos - Equipo Local incluidas las horas extraordinarias',
      222: 'Más / Menos del 3er tiempo - Equipo visitante',
      223: 'Más / Menos del 3er tiempo - Equipo Local',
      224: 'Doble 3er Periodo Probabilidad',
      225: 'Doble oportunidad cuarto período',
      226: '12 incluidas las horas extraordinarias',
      227: 'Ganador de la carrera 2 Lugares',
      228: 'Ganador de la carrera 3 Lugares',
      229: 'Ganador de la carrera 4 Lugares',
      233: 'Ganar para no Cero',
      235: '5 entradas primera Ganador',
      236: '5 entradas primero Más / Menos',
      237: 'Habrá una puntuación en el 1r turno',
      238: 'restante del partido',
      239: 'objetivos de la gama',
      242: '1er Periodo par / impar - Equipo Inicio',
      243: '1er Periodo par / impar - Equipo visitante',
      244: 'Asociación de apertura más alto',
      245: 'Objetivo / cero goles',
      247: 'La mitad restante primero',
      250: 'Esquinas Handicap - 1ª Parte',
      254: 'Rango de tarjetas',
      255: 'Tiempo de la 1ª tarjeta',
      256: 'El equipo local - Tarjetas Rango',
      257: 'Equipo visitante - Tarjetas Rango',
      258: 'esquinas Rango',
      259: 'Equipo con más Esquinas',
      260: 'Equipo con más 1ª parte Corners',
      261: 'Equipo con más 2ª parte Corners',
      262: 'El equipo local - Esquinas Rango',
      263: 'Equipo visitante - Esquinas Rango',
      264: 'Equipo Local - primera parte Corners Rango',
      265: 'Equipo visitante - primera parte Corners Rango',
      266: 'El equipo local - segunda parte Corners Rango',
      267: 'Equipo visitante - segunda parte Corners Rango',
      269: 'Objetivos de la gama 1er Periodo',
      271: 'Posibilidad Mix',
      274: 'ganador absoluto',
      275: 'Objetivos de la gama Período segundo',
      278: 'Objetivos de la gama del equipo Inicio',
      279: 'Objetivos Alcance de equipo visitante',
      281: 'Primero 5 entradas Handicap Asiático',
      282: '1X2 parcial:',
      283: 'Handicap asiático segunda mitad',
      284: '1X2 segunda mitad',
      285: 'Par / Impar segunda mitad',
      286: '12 segunda mitad',
      287: 'Más / Menos cuarto período - Equipo Local',
      288: 'Más / Menos cuarto período - Equipo visitante',
      289: 'Segundo período de par / impar - Equipo Inicio',
      290: '3er tiempo Par / Impar - Equipo Inicio',
      291: 'Cuarto período de par / impar - Equipo Inicio',
      292: 'Segundo período de par / impar - Equipo visitante',
      293: '3er tiempo Par / Impar - Equipo visitante',
      294: 'Cuarto período de par / impar - Equipo visitante',
      297: 'SP',
      299: 'Ganador punto',
      300: 'El ganador del juego',
      301: 'Antes de meta',
      302: 'Después de meta',
      304: 'Equipo con más alta puntuación de Período',
      305: 'Esquinas - Bajo / Exactamente / Más',
      306: 'En primer gol en el último Meta',
      307: 'Juegos Europeos para discapacitados',
      308: 'Más / Menos de puntuación más alta Periodo',
      309: 'Más / Menos de puntuación más baja Periodo',
      310: 'El ganador de la serie',
      317: 'Bajo / Exactamente / Más - 1er Periodo',
      318: 'Bajo / Exactamente / Más - 2º Periodo',
      319: 'Bajo / Exactamente / Más - 3er Periodo',
      320: 'Bajo / Exactamente / Más - cuarto período',
      322: 'Bajo / Exactamente / Más',
      329: '1er Periodo Race To',
      330: 'Segundo período de Race To',
      331: '3er Periodo Race To',
      332: 'Cuarto período Race To',
      333: 'Quinto período Race To',
      337: 'Más / Menos - 2ª Parte',
      338: 'Próximo Gol 1er Periodo',
      339: 'Próximo Gol segundo período',
      341: 'Para la raza',
      342: 'Incluyendo asiático prórroga Handicap',
      343: 'Juego Rango 1er Periodo',
      344: 'Juego Rango Período segundo',
      345: 'Juego del 3er tiempo Rango',
      346: 'Rango juego de cuarto período',
      347: 'Rango juego quinto período',
      348: 'Período sexto ganador',
      349: '7º período Ganador',
      350: 'Sexto período de par / impar',
      351: '7º período Par / Impar',
      352: 'Más / Menos sexto período',
      353: 'Más / Menos 7º período',
      354: 'Más / Menos de medio tiempo - Equipo Inicio',
      355: 'Más / Menos de medio tiempo - Equipo visitante',
      356: 'Método de despido 6-Way',
      357: 'Método despido',
      366: 'Corre par / impar en Over - Equipo Local',
      367: 'Carreras totales Par / Impar en Over - Equipo visitante',
      370: 'Se ejecuta en total Over - Equipo Local',
      371: 'Se ejecuta en total en Over - Equipo visitante',
      386: 'Las horas extraordinarias Europea Handicap Incluyendo',
      387: 'Par / Impar - Las horas extraordinarias Equipo Local Incluyendo',
      388: 'Par / Impar - Lejos equipo que incluye horas extras',
      390: 'HT / FT incluidas las horas extraordinarias',
      391: 'Siguiente Meta - Tiempo de descuento',
      392: 'Equipo Local Penalty Shootout',
      393: 'Pena de distancia del equipo tiroteo',
      394: 'Resultado correcto cuarto período',
      395: 'Resultado correcto quinto período',
      398: 'Par / Tarjetas Incluso',
      400: 'Par / Impar Tarjetas primera mitad',
      401: 'Más / Menos Corners primera mitad - Equipo Inicio',
      402: 'Más / Menos Esquinas primera mitad - Equipo visitante',
      403: 'Más / Menos Corners 2a parte',
      404: 'Más / Menos Corners 2a parte - Equipo Inicio',
      405: 'Más / Menos Corners 2a parte - Equipo visitante',
      406: 'Par / Impar Esquinas primera mitad',
      407: 'Handicap Asiático Tarjetas',
      408: 'Handicap Asiático Tarjetas primera mitad',
      409: '1X2 Esquinas',
      410: '1X2 Esquinas primera mitad',
      411: '12 Esquinas',
      414: 'Más / Menos Tarjetas primera mitad',
      415: '1X2 y ambos equipos marcan',
      416: 'Más / Menos - Tiempo de descuento',
      417: 'Más / Menos - Tiempo Extra primera mitad',
      419: '1X2 - Tiempo de descuento',
      427: '1X2 y Más / Menos',
      428: 'Resultado correcto múltiple',
      430: 'Bajo / Exactamente / Más - 1ª Parte',
      431: 'Bajo / Exactamente / Más - 2ª Parte',
      433: 'Esquinas europeos Handicap',
      434: 'Octavo Periodo Ganador',
      435: 'Noveno Periodo Ganador',
      436: 'Más / Menos octavo Período',
      437: 'Más / Menos noveno Período',
      438: 'Carreras totales Par / Impar en Over',
      439: 'Se ejecuta en total en Over',
      445: 'Período sexto ganador Local / Visitante',
      446: '7º período Ganador Local / Visitante',
      447: 'Handicap Asiático sexto período',
      448: 'Handicap Asiático 7º período',
      449: 'Asiático octavo período Handicap',
      450: 'Asiático noveno período Handicap',
      451: 'Octavo Periodo Ganador Local / Visitante',
      452: 'Noveno Periodo Ganador Local / Visitante',
      453: 'Puntuación de la primera Método',
      454: 'Puntuación de la primera Método - Equipo Local',
      455: 'Puntuación de la primera Método - Equipo visitante',
      456: 'Doble 1er Periodo Probabilidad',
      457: 'Doble oportunidad Período segundo',
      459: 'Inicio Equipo en Anotar en cada trimestre',
      460: 'Equipo visitante en Anotar en cada trimestre',
      462: 'Top 5 Ganador',
      463: 'Top 10 Ganador',
      464: '12 horas extraordinarias segunda mitad Incluyendo',
      465: 'Cuarto período Ganador Inicio / horas extras Lejos Incluyendo',
      466: 'Más / Menos cuarto período incluidas las horas extraordinarias',
      467: 'Las horas extraordinarias Handicap Asiático cuarto periodo que incluye',
      468: 'Las horas extraordinarias Handicap Asiático segunda mitad Incluyendo',
      469: 'Más / Menos segunda mitad incluidas las horas extraordinarias',
      472: 'Juego voluntad Ir al tiroteo?',
      475: 'Más / Menos Rayo de Over - Equipo Local 1r turno',
      476: 'Más / Menos Rayo de largo - de equipo visitante 1r turno',
      477: 'Más / Menos Rayo de Over - Equipo Local segunda Entrada',
      478: 'Más / Menos Rayo de largo - de equipo visitante segunda Entrada',
      479: 'Par / Impar Ejecuta En Over - Equipo Local 1r turno',
      480: 'Par / Impar Ejecuta En Over - Equipo visitante 1r turno',
      481: 'Par / Impar Ejecuta En Over - Equipo Local segunda Entrada',
      482: 'Par / Impar Ejecuta En Over - Equipo visitante segunda Entrada',
      483: 'Equipo Local será Obtener un portillo en Over - 1r turno?',
      484: 'Will Get Equipo visitante un portillo en Over - 1r turno?',
      485: 'Equipo Local será Obtener un portillo en Over - Entrada segundo?',
      486: 'Will Get Equipo visitante un portillo en Over - Entrada segundo?',
      523: 'Más / Menos y ambos Puntuación Equipos Para',
      524: '7 entradas primera Ganador',
      525: '7 entradas primero Más / Menos',
      526: 'Primero 7 entradas Handicap Asiático',
      529: 'Par / Impar segunda mitad Incluyendo horas extras',
      553: 'Bajo pena de / Más Shootout Tomados',
      554: '12 Shootout',
      555: 'Handicap Asiático tiroteo',
      556: 'Puntuación correcta tiroteo',
      557: 'Más / Menos tiroteo',
      558: 'Más / Menos tiroteo - Equipo Inicio',
      559: 'Más / Menos tiroteo - Equipo visitante',
      560: 'Equipo Para Tomar Última Penalty Shootout',
      563: 'Race To incluidas las horas extraordinarias',
      564: '1X2 y Más / Menos de 5 vías',
      566: '10o Período Ganador Local / Visitante',
      567: '11 Periodo Ganador Local / Visitante',
      568: '12 Periodo Ganador Local / Visitante',
      569: '13 Periodo Ganador Local / Visitante',
      570: '14o Período Ganador Local / Visitante',
      571: '15o Período Ganador Local / Visitante',
      573: '16o Período Ganador Local / Visitante',
      574: 'Período 17a Ganador Local / Visitante',
      575: '18o Período Ganador Local / Visitante',
      576: 'Período 19o Ganador Local / Visitante',
      577: '10 Minutos Más / Menos - Esquinas',
      578: '10 Minutos Más / Menos - Tarjetas',
      579: 'Siguiente Corner',
      586: 'Para terminar en la mitad superior',
      587: 'Para finaliza en el último medio',
      588: 'Para acabado del fondo',
      589: 'No terminar en el Top 4',
      590: 'Que van a descender',
      591: 'No es que van a descender',
      592: 'Para llegar a final',
      593: 'Para terminar en la parte inferior 6',
      594: 'Para ser promovido',
      595: 'Objetivo primera vez',
      596: 'Objetivos Rango Equipo Local 1er Periodo',
      597: 'Objetivos Rango Equipo Local Período segundo',
      598: 'Objetivos Alcance de equipo visitante 1er Periodo',
      599: 'Objetivos Alcance de equipo visitante segundo período',
      600: '1er Periodo Ganador y ambos Puntuación Equipos Para',
      601: 'HT / FT Doble oportunidad',
      602: 'Resultado correcto incluidas las horas extraordinarias',
      603: 'HT Negativo / FT',
      604: 'Objetivos de la gama de equipo ambos períodos Inicio',
      605: 'Objetivos gama del equipo de fuera ambos períodos',
      606: 'El ganador y Objetivos de tipo uno',
      607: 'Doble oportunidad y metas',
      608: 'El ganador y Objetivos de tipo dos',
      609: 'Ambos equipos Score A y metas',
      610: 'Mezcla Doble oportunidad',
      628: 'Para terminar Top 7',
      629: 'Para terminar Top 6',
      630: 'Para terminar Top 3',
      631: 'Para terminar Top 2',
      632: 'Para terminar Top 4',
      633: 'Para terminar Top 5',
      634: 'Par / Impar Rondas 1er Periodo',
      635: 'Par / Impar Rondas segundo período',
      636: 'Par / Impar Rondas 3er Periodo',
      637: '¿Habrá un as?',
      638: '¿Habrá una lámina matanza?',
      639: 'Ganador primera ronda',
      640: 'Ganador ronda 16',
      641: '¿Habrá una matanza cuchillo? - 1er periodo',
      642: '¿Habrá una matanza cuchillo? - segundo período',
      643: '¿Habrá una matanza cuchillo? - 3er Periodo',
      644: '¿Habrá un as? - 1er periodo',
      645: '¿Habrá un as? - segundo período',
      646: '¿Habrá un as? - 3er Periodo',
      647: 'Habrá horas extras? - 1er periodo',
      648: 'Habrá horas extras? - segundo período',
      649: 'Habrá horas extras? - 3er Periodo',
      650: '16a Ronda Ganador - 1er Periodo',
      651: '16a Ronda Ganador - 2º Periodo',
      652: '16a Ronda Ganador - 3er Periodo',
      653: '1ª jornada Ganador - 1er Periodo',
      654: '1ª jornada Ganador - 2º Periodo',
      655: '1ª jornada Ganador - 3er Periodo',
      660: 'Duración del juego',
      662: 'Par / Rondas Incluso',
      663: '1er Periodo Duración',
      664: 'Segundo período de duración',
      665: '3er Periodo Duración',
      666: '1er Periodo - primera Roshan',
      667: 'Segundo período - primera Roshan',
      668: '3er tiempo - primero Roshan',
      669: '1er Periodo - primero Sangre',
      670: 'Segundo período - primero Sangre',
      671: '3er tiempo - primero Sangre',
      672: '1er Periodo - primera tou',
      673: 'Segundo período - primera tou',
      674: '3er tiempo - primero tou',
      675: '1er Periodo - 1ra cuartel',
      677: 'Segundo período - 1ra cuartel',
      678: '3er tiempo - 1º cuartel',
      679: '1er Periodo - primera torreta',
      680: 'Segundo período - primera torreta',
      681: '3er tiempo - primero Torreta',
      682: 'Race To Muertes - 1er Periodo',
      683: 'Race To Muertes - segundo período',
      684: 'Race To Muertes - 3er Periodo',
      685: 'Ambos equipos matar a un dragón - 1er Periodo',
      686: 'Ambos equipos matar a un dragón - 2º Periodo',
      687: 'Ambos equipos matar a un dragón - 3er Periodo',
      688: 'Ambos equipos matar a un Inhibidor - 1er Periodo',
      689: 'Ambos equipos matar a un Inhibidor - segundo período',
      690: 'Ambos equipos matar a un Inhibidor - 3er Periodo',
      691: 'Muertes par / impar',
      692: 'Par / Impar Veces - 1er Periodo',
      693: 'Par / Impar Veces - 2º Periodo',
      694: 'Par / Impar Veces - 3er Periodo',
      695: 'HT / FT y las metas',
      697: 'Para terminar Top 8',
      711: 'Jugador en anotar En cualquier momento',
      712: 'En primer jugador Para Score',
      713: 'Último jugador en marcar',
      714: 'Jugador que marque 2 o más goles',
      715: 'Jugador que marque 3 o más goles',
      727: '1er periodo ambos equipos a marcar',
      728: 'Segundo período de dos equipos marcan',
      729: '3er tiempo ambos equipos a marcar',
      730: 'Cuarto período de dos equipos marcan',
      731: '1er Periodo Inicio equipo en marcar',
      732: 'Segundo período de Inicio equipo en marcar',
      733: '3er Periodo Inicio equipo en marcar',
      734: 'Cuarto período de Inicio equipo en marcar',
      735: '1er Periodo de equipo visitante para anotar',
      736: 'Segundo período de ausencia equipo en marcar',
      737: '3er Periodo de equipo visitante para anotar',
      738: 'Cuarto período de ausencia equipo en marcar',
      751: 'Para primera parte Carrera',
      752: 'Segunda parte Carrera A - incluidas las horas extraordinarias',
      754: 'Juego Para Deuce',
      755: 'Tiempo extra Esquinas - Bajo / Exactamente / Más',
      756: 'Bajo / Extra sobre- tiempo Corners',
      757: 'Handicap asiático - Tiempo de descuento',
      758: '10 Minutos Más / Menos - Tiros libres',
      759: '10 Minutos Más / Menos - saques de banda',
      760: '10 Minutos Más / Menos - el saque de meta',
      762: 'Para ganar el Toss',
      763: 'Más / Menos Ejecuta En Partido',
      765: 'Que marque más Fours',
      766: 'Más / Menos - Partido Fours',
      767: 'Más / Menos - Partido Seis',
      768: 'Un cincuenta y se marcarán en el partido',
      769: 'Cien a marcarán en el partido',
      770: 'La mayoría de las salidas Run',
      791: 'Top Bateador - Equipo Inicio',
      792: 'Top Bateador - Equipo visitante',
      793: 'Top Bowler - Equipo Inicio',
      794: 'Top Bowler - Equipo visitante',
      795: 'Hombre del partido',
      806: 'Bateador para anotar un cincuenta en el partido',
      808: 'Para la carrera 10 carreras',
      809: 'Más altos 1st 6 overs',
      813: 'Jugador que marque más Seises',
      814: 'Mayor resultado individual',
      819: 'Para tener una rotura punto en el juego',
      820: 'Carrera a Corners',
      822: 'Top Nuevo equipo',
      824: 'Jugador para ser reservados',
      825: 'Jugador en ser expulsado',
      834: 'Puntos Extra Set',
      835: 'Asiático Más / Menos',
      836: 'Asiático Más / Menos 1er Periodo',
      837: '1er tiempo Equipo Local ganar a cero',
      838: '1er tiempo Equipo visitante ganar a cero',
      839: '1er tiempo 1X2 y Más / Menos',
      840: '1er Periodo Equipo Local ganar a cero',
      841: '1er Periodo de equipo visitante ganar a cero',
      842: 'Bajo / Exactamente / Más - Equipo Inicio',
      843: 'Bajo / Exactamente / Más - Equipo visitante',
      844: 'Primer equipo en marcar - Local / Visitante',
      845: 'Para equipo de llamada de un primer tiempo de espera',
      846: 'Bajo Golpea / Más de - Las horas extraordinarias Incluyendo',
      849: 'Más / Menos 5to turno',
      851: 'Con equipo de Mitad de mayor puntuación',
      852: 'De cualquier equipo en marcar 3 veces sin respuesta',
      853: '12 y Más / Menos',
      854: 'Equipo especial o Touchdown defensiva Marcados',
      855: 'Puntos Rango',
      859: '147 Break In Partido',
      861: 'Puntuación de la primera Método 3 Way - Equipo Local',
      862: 'Puntuación de la primera Método 3 Way - Equipo visitante',
      863: 'Equipo con mayor -1X2 Marcar puntos Período',
      864: 'Equipo de Primer Goleador',
      865: 'Equipo Ultimo Goleador',
      866: 'Conjuntos Handicap asiático',
      870: 'Puntuación de la primera Método 6 Camino Local / Visitante',
      878: 'Objetivos de la gama del 3er tiempo',
      879: 'Bajo Tries / Más',
      880: 'Primera mitad Esquinas - Bajo / Exactamente / Más',
      881: 'Primer Gol Método',
      882: 'Número de puntos',
      891: 'Equipo que marca más larga Touchdown',
      892: 'Equipo en marcar más larga Field Goal',
      893: 'Touchdown más larga Anoto',
      894: 'Touchdown más corto Anoto',
      895: 'El campo más largo de gol',
      896: 'Tiempo del primer gol por equipo',
      897: 'Juego Resultado después de 2 Puntos',
      898: 'Juego Resultado después de 3 Puntos',
      899: 'Juego Resultado después de 4 puntos',
      900: 'Juego de anotar',
      901: '1er Periodo Después de plomo',
      902: 'Segundo período después del plomo',
      903: 'Después del 3er tiempo de plomo',
      904: 'Cuarto período después del plomo',
      905: 'Quinto período después del plomo',
      906: 'Resultado después de 4 Juegos',
      907: 'Resultado después de 6 Juegos',
      908: 'Para romper servir en conjunto',
      909: 'Inicio para ganar en dos sets',
      910: 'Distancia para ganar en dos sets',
      911: 'Después de anotar 2 juegos',
      912: 'Resultado después de 3 Sets',
      914: 'Bajo Objetivos / sobre el campo Marcados - 1ª Parte',
      915: 'Más / Menos momentos del aterrizaje fue marcado - 1ª Parte',
      916: 'Más / Menos equipo local momentos del aterrizaje - 1ª Parte',
      917: 'Más / Menos del equipo visitante momentos del aterrizaje - 1ª Parte',
      920: 'Bajo Más de momentos del aterrizaje /',
      921: 'Bajo / Exactamente / Más - momentos del aterrizaje',
      922: 'Bajo Objetivos / sobre el campo',
      923: 'Bajo / Exactamente / Over - Canastas',
      924: 'Tiempo del primer gol anotado Campo',
      925: 'Momento de la primera toma de contacto Anoto',
      926: 'Puntuación de la primera época de',
      927: 'Equipo Local Más / Menos momentos del aterrizaje',
      928: 'Equipo visitante Más / Menos momentos del aterrizaje',
      929: 'Equipo Local Bajo / Objetivos sobre el campo',
      930: 'Goles del equipo Más / Menos de campo',
      932: 'Equipo para tener el primer desafío entrenadores',
      933: 'Equipo para cometer Primera Aceptado Pena',
      934: 'seguridad Anotó',
      935: 'Tarjetas Europea Handicap',
      936: 'Asiático Más / Menos Tarjetas',
      938: 'Más / Menos Cards - Equipo Inicio',
      939: 'Más / Menos Cards - Equipo visitante',
      940: 'Momento de la primera tarjeta',
      941: 'A continuación jugador en marcar',
      942: 'Top nacionalidad - Asia',
      943: 'Top nacionalidad - Australia',
      944: 'Top nacionalidad - Europeo',
      945: 'Top nacionalidad - Gran Bretaña e Irlanda',
      946: 'Top nacionalidad - Resto del mundo',
      947: 'Más / Menos Aces',
      948: 'Bajo Más de Aces / - Equipo Local',
      949: 'Número exacto de los as',
      950: 'Más / Menos Aces - Equipo visitante',
      951: 'Número correcto de los as',
      955: '1X2 Aces',
      956: 'Exact número de ACE - Equipo Local',
      957: 'Número exacto de los as - Equipo visitante',
      958: 'Ases Handicap Asiático',
      959: 'Race To Aces',
      960: 'Más / Menos Aces - 1er Periodo',
      976: '1X2 Aces - 1er Periodo',
      982: 'Ases Asia Handicap - 1er Periodo',
      986: '12 Aces',
      989: 'Más / Menos Muertes - 1er Periodo',
      990: 'Más / Menos Muertes - 2º Periodo',
      991: 'Más / Menos Muertes - 3er Periodo',
      992: 'Siguiente Touchdown Goleador - Equipo Inicio',
      993: 'Siguiente Touchdown Goleador - Equipo visitante',
      994: 'Siguiente Touchdown Goleador',
      995: 'Jugador en anotar touchdown en cualquier momento',
      996: 'Jugador que marque 2 o más touchdowns',
      997: 'Jugador que marque 3 o más momentos del aterrizaje',
      998: 'Primera curva de ajuste - 12',
      999: 'Último partido de la esquina - 12',
      1000: 'Número de conjuntos',
      1001: 'Bajo Fallos / más del doble',
      1002: 'Bajo Fallos / más del doble - Equipo visitante',
      1003: 'Bajo Fallos / más del doble - Equipo Local',
      1004: 'Bajo Fallos / más del doble - 1er Periodo',
      1010: '12 Doble Faltas',
      1011: '12 Doble Faltas - 1er Periodo',
      1013: 'Fallos 1X2 dobles',
      1016: 'Número exacto de Doble Faltas',
      1019: 'Número exacto de Doble Faltas - Equipo Local',
      1020: 'Número exacto de Doble Faltas - Equipo visitante',
      1027: 'Número correcto de Doble Faltas',
      1030: 'Momento de la primera esquina',
      1031: 'múltiples Esquinas',
      1034: '20 Periodo Ganador Local / Visitante',
      1035: 'Período 21 Ganador Local / Visitante',
      1036: 'Período 22 Ganador Local / Visitante',
      1037: 'Período 23 Ganador Local / Visitante',
      1038: 'Período 24 Ganador Local / Visitante',
      1039: '25 Periodo Ganador Local / Visitante',
      1040: 'Período 26 Ganador Local / Visitante',
      1041: 'Período 27 Ganador Local / Visitante',
      1042: 'Período 28 Ganador Local / Visitante',
      1043: '29 Periodo Ganador Local / Visitante',
      1044: 'Período 30 de Ganador Local / Visitante',
      1046: 'Período 32th Ganador Local / Visitante',
      1047: 'Período 33th Ganador Local / Visitante',
      1050: 'Carrera a 20 Puntos',
      1051: 'Más / Menos segunda mitad - Equipo Inicio',
      1052: 'Más / Menos segunda mitad - Equipo visitante',
      1053: 'Asiático Más / Menos segundo período',
      1054: 'Asiático Más / Menos del 3er tiempo',
      1055: 'Asiático Más / Menos cuarto período',
      1061: 'Las horas extraordinarias asiático Más / Menos Incluyendo',
      1062: 'Mitad de mayor puntuación - Equipo Inicio',
      1063: 'Mitad de mayor puntuación - Equipo visitante',
      1065: 'El equipo local - Primer Goleador',
      1066: 'El equipo local - Ultimo Goleador',
      1067: 'Equipo visitante - Primer Goleador',
      1068: 'Equipo visitante - Ultimo Goleador',
      1069: 'En Puntos de / sobre del jugador',
      1070: 'Más / Menos jugador bloquea',
      1071: 'Más / Menos del jugador Ayuda',
      1072: 'Más / Menos jugador Rebotes',
      1073: 'Más / Menos jugador Roba',
      1074: 'Más / Menos jugador de Balón',
      1075: 'Más / Menos Player 3 Puntos Hecho',
      1076: 'Más / Menos jugador 3 puntos intentados',
      1077: 'Más / Menos del jugador 2 Puntos Hecho',
      1078: 'Más / Menos Jugador 2 Puntos Intentados',
      1079: 'Más / Menos jugador tiros libres anotados',
      1080: 'Más / Menos jugador tiros libres intentados',
      1081: '1X2 Tarjetas Amarillas',
      1082: 'Doble oportunidad Amarillas',
      1083: 'Handicap asiático Tarjetas Amarillas',
      1084: 'Resultado correcto Amarillas',
      1085: 'Más / Menos tarjetas rojas',
      1086: 'Más / Exactamente / Puntos exceso de reservas',
      1087: 'Más / Exactamente / Puntos exceso de reservas - Inicio del equipo',
      1088: 'Bajo / Exactamente / Puntos exceso de reservas - Equipo visitante',
      1089: 'Más / Menos del jugador robos y Bloques',
      1090: 'En Puntos de / sobre del jugador',
      1091: 'Más / Menos del jugador asistencias y rebotes',
      1092: 'Más / Menos puntos y rebotes del jugador',
      1093: 'Más / Menos de puntos y asistencias del jugador',
      1094: 'Reproductor Doble Doble',
      1095: 'Jugador Triple Doble',
      1096: 'Bajo / Exactamente / Puntos exceso de reservas - 1er Periodo',
      1100: 'Ganador conjunto',
      1101: 'Más / Menos de 180 \ T en un set',
      1102: 'Set / Ganador Pierna',
      1103: 'Set / LEG totales desprotecciones Más / Menos',
      1105: 'Más / Menos total 180 \ s - Inicio del equipo',
      1106: 'Más / Menos Total de 180 \ s - Equipo visitante',
      1112: 'Bajo Objetivos / Más del jugador',
      1115: 'Bajo Objetivos / Más jugador permitido',
      1120: 'Más / Menos Rondas - 1er Periodo',
      1121: 'Más / Menos Rondas - 2º Periodo',
      1122: 'Más / Menos Rondas - 3er Periodo',
      1123: 'Cuarto período - primero Sangre',
      1124: 'Quinto período - primero Sangre',
      1125: 'Ambos equipos para matar a un dragón',
      1126: 'Ambos equipos para matar a un Barón',
      1127: 'Ambos equipos para matar a un inhibidor',
      1128: 'Más / Menos Barons asesinada',
      1129: 'Más / Menos Barons asesinada - 1er Periodo',
      1130: 'Más / Menos Barons asesinada - 2º Periodo',
      1131: 'Más / Menos Barons asesinada - 3er Periodo',
      1132: 'Más / Menos dragones asesinada',
      1133: 'Más / Menos dragones asesinada - 1er Periodo',
      1134: 'Más / Menos dragones asesinada - 2º Periodo',
      1135: 'Más / Menos dragones asesinada - 3er Periodo',
      1136: 'Más / Menos de 180 \ s',
      1137: 'primero 180',
      1138: 'última 180',
      1139: 'Más / Menos Pedido Mayor Puntaje',
      1140: 'La mayoría de los 180 \ s',
      1141: 'Más / Menos Pedido Mayor Puntuación - Equipo Local',
      1142: 'Más / Menos Pedido Mayor Puntuación - Equipo visitante',
      1143: 'En primer jugador Para Score Touchdown',
      1144: 'Último jugador en anotar Touchdown',
      1145: 'En primer jugador en anotar Touchdown - Equipo Local',
      1146: 'En primer jugador en anotar Touchdown - Equipo visitante',
      1147: 'Más / Menos Muertes - cuarto período',
      1148: 'Más / Menos Muertes - quinto período',
      1149: 'Handicap Asiático Muertes - 1er Periodo',
      1150: 'Handicap Asiático Muertes - 2º Periodo',
      1151: 'Handicap Asiático Muertes - 3er Periodo',
      1152: 'Handicap Asiático Muertes - cuarto período',
      1153: 'Handicap Asiático Muertes - quinto período',
      1154: 'Bajo / Exactamente / Más de 180 \ s',
      1155: 'Más / Exactamente / sobre el total de 180 \ s - Inicio del equipo',
      1156: 'Bajo / Exactamente / Más Total 180 \ s - Equipo visitante',
      1157: 'La mayoría de las desprotecciones',
      1158: 'El anotador primer intento',
      1159: 'El anotador último intento',
      1160: 'Trate cualquier momento anotador',
      1162: 'Más / Menos tous Destruido - 1er Periodo',
      1163: 'Más / Menos tous Destruido - 2º Periodo',
      1164: 'Más / Menos tous Destruido - 3er Periodo',
      1165: '1er Periodo - 1er Baron',
      1166: 'Segundo período - 1er Baron',
      1167: '3er tiempo - 1er Baron',
      1168: 'Cuarto período - 1er Baron',
      1169: 'Quinto período - 1er Baron',
      1170: '1er Periodo - primera Dragón',
      1171: 'Segundo período - primera Dragón',
      1172: '3er tiempo - primero Dragón',
      1173: 'Cuarto período - primera Dragón',
      1174: 'Quinto período - primera Dragón',
      1175: 'La mayoría de las Muertes - 1er Periodo',
      1176: 'La mayoría de las Muertes - 2º Periodo',
      1177: 'La mayoría de las Muertes - 3er Periodo',
      1178: 'La mayoría de las Muertes - cuarto período',
      1179: 'La mayoría de las Muertes - quinto período',
      1180: 'La mayoría de las Muertes - sexto período',
      1181: 'La mayoría de las Muertes - 7º período',
      1182: 'Jugador que marque 2 o más Trys',
      1183: 'Jugador que marque 3 o más Trys',
      1184: 'No para ganar el Leauge',
      1185: 'Bajo / Exactamente / Más incluidas las horas extraordinarias - Equipo Local',
      1186: 'Bajo / Exactamente / Más incluidas las horas extraordinarias - Equipo visitante',
      1187: 'Bajo / Exactamente / tiempo extra sobre Incluyendo',
      1188: 'Bajo Más de sustituciones /',
      1189: 'Bajo Más de sustituciones / - 1er Periodo',
      1190: 'Último jugador en marcar Touchdown - Equipo Local',
      1191: 'Último jugador en marcar Touchdown - Equipo visitante',
      1192: 'En primer jugador en batear un As',
      1193: 'Más / Menos jugador del estratega yardas por pase',
      1194: 'Bajo momentos del aterrizaje de cruce / Durante jugador',
      1195: 'Más / Menos Mayor jugador Pass Finalización',
      1196: 'Más / Menos jugador yardas recibiendo',
      1197: 'Más / Menos jugador interceptaciones',
      1198: 'Más / Menos jugador que patea Puntos',
      1199: 'Más / Menos jugador Corriendo y yardas recibiendo',
      1200: 'Más / Menos jugador yardas terrestres',
      1201: 'Más / Menos jugador más larga de recepción',
      1202: 'Más / Menos jugador Recepciones',
      1203: 'Bajo Objetivos / Más jugador de campo Hecho',
      1204: 'Bajo Objetivos / Más jugador de campo intentados',
      1205: 'Bajo momentos del aterrizaje de cruce / El jugador Quarterback',
      1206: 'Bajo Receptor / Más jugador Terminaciones Recepción',
      1207: 'Más / Menos jugador del estratega pases completos',
      1208: 'Más / Menos jugador touchdowns',
      1209: 'Bajo Receptor / Más jugador intenta Recepción',
      1210: 'Más / Menos jugador del estratega Pases Intento',
      1211: '1X2 Faltas',
      1212: 'Doble oportunidad Faltas',
      1213: 'Más / Menos Faltas - 1er Periodo',
      1214: 'Más / Menos Faltas - 2º Periodo',
      1215: 'Handicap Asiático Faltas',
      1216: 'Más / Menos Faltas - Equipo Inicio',
      1217: 'Más / Menos Faltas - Equipo visitante',
      1218: 'Bajo intentos de / Más jugador de Rush',
      1219: 'Bajo touchdowns / Más del jugador',
      1222: 'Más / Menos saques de banda - Equipo Inicio',
      1223: 'Más / Menos saques de banda - Equipo visitante',
      1224: '1X2 saques de banda',
      1225: 'Doble oportunidad saques de banda',
      1226: 'Más / Menos saques de banda - 1er Periodo',
      1227: 'Saques de banda Handicap',
      1228: 'Saques de banda Handicap - 1er Periodo',
      1229: 'Bajo / Vacunas Más en Target - Equipo Local',
      1230: 'Bajo / Vacunas Más en Target - Equipo visitante',
      1231: 'Disparos Handicap asiático en blanco',
      1232: 'Disparos Handicap asiático en el blanco - 1er Periodo',
      1233: 'Bajo / Vacunas Más en Target - 1er Periodo',
      1234: '1X2 disparos a puerta',
      1235: 'Disparos doble oportunidad en Target',
      1236: 'Más / Menos Total de Disparos - Equipo Local',
      1237: 'Más / Menos Total de Disparos - Equipo visitante',
      1238: 'Doble oportunidad Total de Disparos',
      1239: '1X2 totales Disparos',
      1240: 'Asiáticos Shots Handicap totales',
      1241: 'Asiáticos Shots Handicap Total - 1er Periodo',
      1242: 'Más / Menos Total de Disparos - 1er Periodo',
      1243: 'Doble oportunidad Esquinas',
      1244: 'Doble oportunidad Esquinas - 1er Periodo',
      1245: 'Esquinas A partir de las 4 esquinas de la cancha',
      1246: 'Doble oportunidad Amarillas - 1er Periodo',
      1247: '1X2 Tarjetas Amarillas - 1er Periodo',
      1248: 'Handicap asiático Tarjetas Amarillas - 1er Periodo',
      1249: 'Doble oportunidad y Más / Menos',
      1250: 'Doble oportunidad y ambos sumaran Equipos Para',
      1251: '1er Periodo - primera Inhibidor',
      1252: 'Segundo período - primera Inhibidor',
      1253: '3er tiempo - primero Inhibidor',
      1254: 'Cuarto período - primera Inhibidor',
      1255: 'Quinto período - primera Inhibidor',
      1256: 'Ambos equipos para matar a un Barón - 1er Periodo',
      1257: 'Ambos equipos para matar a un Barón - 2º Periodo',
      1258: 'Ambos equipos para matar a un Barón - 3er Periodo',
      1259: 'Ambos equipos para matar a un Barón - cuarto período',
      1260: 'Ambos equipos para matar a un Barón - quinto período',
      1261: 'Ambos equipos matar a un dragón - cuarto período',
      1262: 'Ambos equipos matar a un dragón - quinto período',
      1263: 'Ambos equipos matar a un Inhibidor - cuarto período',
      1264: 'Ambos equipos matar a un Inhibidor - quinto período',
      1265: '¿Habrá un Ace próximo partido?',
      1266: 'Habrá doble falta próximo partido?',
      1267: 'Par / Faltas Incluso',
      1268: 'Posesión Handicap Asiático bola',
      1269: 'Doble oportunidad de Lugar',
      1270: 'Handicap Asiático de Lugar',
      1271: 'Más / Menos de Lugar - Equipo Inicio',
      1272: 'Más / Menos de Lugar - Equipo visitante',
      1273: '1X2 de Lugar',
      1274: 'Más / Menos Muertes primera Período - Equipo Local',
      1275: 'Más / Menos Muertes segundo período - Equipo Local',
      1276: 'Más / Menos Muertes del 3er tiempo - Equipo Local',
      1277: 'Más / Menos Muertes primera Período - Equipo visitante',
      1278: 'Más / Menos Muertes segundo período - Equipo visitante',
      1279: 'Menos Más Muertes 3er Periodo / - Equipo visitante',
      1280: 'Más / Menos Wides',
      1281: 'Bajo Patos / Más',
      1282: 'Bajo Más de Wicketes /',
      1283: 'Más / Menos Partido Fours - Equipo Inicio',
      1284: 'Más / Menos de ajuste de los Seis - Equipo Inicio',
      1285: 'Más / Menos Partido Fours - Equipo visitante',
      1286: 'Más / Menos de ajuste de los Seis - Equipo visitante',
      1287: 'Más / Menos Extras',
      1288: 'Caída de primera Wicket - Equipo Local',
      1289: 'Caída de primera Wicket - Equipo visitante',
      1290: 'Equipo de los mejores Bateador',
      1291: 'Más / Menos de ajuste de los Seis Equipo Local - 1r turno',
      1292: 'Más / Menos de ajuste de los Seis Equipo visitante - 1r turno',
      1293: 'Más / Menos Partido Fours Equipo Local - 1r turno',
      1294: 'Más / Menos del partido Fours del equipo de fuera - 1r turno',
      1295: 'Más / Menos Mejor Bateador',
      1296: 'Primera entrada primera Durante Despido - Equipo Local',
      1297: '1r turno segundo Durante Despido - Equipo Local',
      1298: '1r turno tercera Durante Despido - Equipo Local',
      1299: '1r turno segundo Durante Despido - Equipo visitante',
      1300: '1r turno tercera Durante Despido - Equipo visitante',
      1301: '1r turno primera Durante Despido - Equipo visitante',
      1302: 'De mayor puntuación Over - Más / Menos',
      1303: '1r turno segundo Durante Más / Menos - Equipo Inicio',
      1304: '1r turno tercera Durante Más / Menos - Equipo Inicio',
      1306: '1r turno tercera Durante Más / Menos - Equipo visitante',
      1307: '1r turno segundo Durante Más / Menos - Equipo visitante',
      1309: 'Margen de victoria - Equipos',
      1310: 'Ventaja del ganador - Las horas extraordinarias Equipos Incluyendo',
      1312: 'Ventaja del ganador exacta',
      1314: 'Ganando rangos de márgenes',
      1315: 'Ventaja del ganador exacta - Equipos',
      1316: 'Ventaja del ganador - Los equipos con ningún otro Score',
      1318: 'Faltas Handicap asiático - 1er Periodo',
      1319: 'Handicap Asiático de Lugar - 1er Periodo',
      1320: 'Handicap Asiático de Lugar - 2º Periodo',
      1321: 'Faltas Handicap asiático - 2º Periodo',
      1322: 'Disparos Handicap asiático en el blanco - 2º Periodo',
      1324: 'Más / Menos de Lugar - 1er Periodo',
      1325: 'Más / Menos de Lugar - 2º Periodo',
      1326: 'En primer lugar Falta',
      1327: 'Primera de dos punteros',
      1328: 'En primer triple',
      1329: 'Última de dos punteros',
      1330: 'Última triple',
      1331: 'Throw última gratuito Anoto',
      1332: 'Throw primera gratuito Anoto',
      1333: 'En primer rebote',
      1334: 'último tiempo de espera',
      1335: 'Ventaja del ganador Equipos - 1er Periodo',
      1336: 'Ventaja del ganador Equipos - 2º Periodo',
      1337: 'Ventaja del ganador Equipos - 3er Periodo',
      1338: 'Ventaja del ganador Equipos - cuarto período',
      1339: 'Ventaja del ganador Equipos - quinto período',
      1340: 'última Falta',
      1341: 'Más / Menos 10a Período',
      1342: 'Más / Menos del 11 Periodo',
      1343: 'Más / Menos 12º Período',
      1344: 'Más / Menos 13 Periodo',
      1345: 'Más / Menos Período 14a',
      1346: 'Más / Menos 15o Período',
      1347: 'Más / Menos 16o Período',
      1348: 'Más / Menos Período 17a',
      1349: 'Más / Menos Período 18a',
      1350: 'Más / Menos Período 19o',
      1351: 'Más / Menos Período 20',
      1352: 'Más / Menos Período 21',
      1353: 'Más / Menos Período 22',
      1354: 'Más / Menos Período 23',
      1355: 'Más / Menos Período 24',
      1356: 'Más / Menos 25 Periodo',
      1357: 'Más / Menos Período 26',
      1358: 'Más / Menos Período 27',
      1359: 'Más / Menos Período 28',
      1360: 'Más / Menos 29 Periodo',
      1361: 'Más / Menos Período 30 de',
      1362: 'Más / Menos del 31 Periodo',
      1363: 'Más / Menos Período 32a',
      1364: 'Más / Menos Período 33',
      1365: 'Más / Menos Período 34',
      1366: 'Más / Menos Período 35a',
      1367: 'Período 11 de hándicap asiático',
      1368: 'Handicap asiático 12º Período',
      1369: '13 Periodo de hándicap asiático',
      1370: 'Handicap asiático Período 14a',
      1371: 'Período 15a Handicap asiático',
      1372: 'Handicap asiático Período 16a',
      1373: 'Período 17a Handicap asiático',
      1374: 'Período 18a Handicap asiático',
      1375: 'Handicap asiático Período 19a',
      1376: 'Handicap asiático Período 20',
      1380: 'Handicap asiático Período 24',
      1381: 'Handicap asiático Período 25',
      1382: 'Período 26 de hándicap asiático',
      1383: 'Período 27 de hándicap asiático',
      1384: 'Período 28 de hándicap asiático',
      1385: '29 Periodo de hándicap asiático',
      1386: 'Período 30 de hándicap asiático',
      1390: 'Período 34 Handicap asiático',
      1391: 'Período 35a Handicap asiático',
      1392: 'Para terminar Top 10',
      1393: 'Para mantenerse',
      1394: 'Período 10 de hándicap asiático',
      1395: 'En Puntos de / sobre jugador Powerplay',
      1396: 'Más / Menos jugador más / menos',
      1397: 'Bajo Disparos / Más del jugador',
      1398: 'Superior del club victoriano',
      1399: 'Bajo Salidas / Over Run',
      1400: 'Más / Menos Marcos',
      1401: 'Top 20 Ganador',
      1402: 'Líder primera ronda',
      1404: 'Primera ronda Six Shooter - Grupo A',
      1406: '1ª ronda 3 bolas',
      1407: 'Primera ronda de 18 hoyos emparejamientos',
      1408: 'Handicap match',
      1409: 'Con equipo de Top Bowler',
      1410: 'Para ganar el sorteo y el Partido',
      1411: 'Más / Menos torretas destruida',
      1412: 'Más / Menos torretas Destroyed - 1er Periodo',
      1413: 'Más / Menos torretas Destroyed - 2º Periodo',
      1414: 'Más / Menos torretas Destroyed - 3er Periodo',
      1415: 'Mayor resultado Después primero Más',
      1416: 'Equipo Local Más / Menos corre - Con el primero a sexto largo',
      1417: 'Equipo visitante Más / Menos corre - Con el primero a sexto largo',
      1418: 'Equipo Local Más / Menos corre - Con el primero a octavo Durante',
      1419: 'Equipo visitante Más / Menos corre - Con el primero a octavo Durante',
      1420: 'Equipo Local Más / Menos corre - Con el primero al 10 de largo',
      1421: 'Equipo visitante Más / Menos corre - Con el primero al 10 de largo',
      1422: 'Para Chase de 180 carreras y equipo para ganar',
      1423: 'Como muchas pistas se marcarán desde la primera bola',
      1424: 'Más / Menos Partido Stumpings',
      1425: 'Método primera Wicket',
      1426: 'Primera bola del partido',
      1427: 'Más / Menos - 1r turno',
      1428: 'Top Club no victoriano',
      1429: 'Ganador de la Gran Final de',
      1431: 'Para llegar a la Gran Final',
      1432: 'No terminar en el Top 8',
      1434: 'Más / Menos Muertes cuarto período - Equipo visitante',
      1435: 'Menos Más Muertes quinto Periodo / - Equipo visitante',
      1436: 'Más / Menos Muertes cuarto período - Equipo Local',
      1437: 'Más / Menos Muertes quinto período - Equipo Local',
      1438: 'Más / Menos Temperatura En el Aeropuerto (Celsius)',
      1439: 'Asia Handicap - Tiempo completo',
      1440: 'Más / Menos valor de todos los números sorteados',
      1441: 'Más / Menos valor de todos los números impares Drawn',
      1442: 'Más / Menos valor de todos los números pares Drawn',
      1443: 'Incluso se dibujan más que números impares',
      1444: 'Los números consecutivos se dibujará',
      1445: 'Bajo / Exactamente / Más - Número de Impares bolas extraídas',
      1446: 'Bajo / Exactamente / Más - número de bolas numeradas Incluso Drawn',
      1447: 'Suma de 5 bolas de bonificación',
      1448: 'Primera bola extraída Bono - Par / Impar',
      1449: 'Bono última bola extraída - Par / Impar',
      1450: 'Primera bola de la prima',
      1451: 'Última bola de la prima',
      1452: 'El más bajo de 5 bolas de bonificación',
      1453: 'Bonus Ball - Par / Impar',
      1454: 'Los números idénticos se dibujará',
      1455: 'Número más alto Drawn - Par / Impar',
      1456: 'Número más bajo Drawn - Par / Impar',
      1457: 'Bola 1 - Más / Menos',
      1458: 'Bola 1 - Par / Impar',
      1459: 'Ball 2 - Más / Menos',
      1460: 'Ball 2 - Par / Impar',
      1461: 'Ball 3 - Más / Menos',
      1462: 'Ball 3 - Par / Impar',
      1463: 'Bola 4 - Más / Menos',
      1464: 'Bola 4 - Par / Impar',
      1465: 'Bola 5 - Más / Menos',
      1466: 'Bola 5 - Par / Impar',
      1467: 'Bola 6 - Más / Menos',
      1468: 'Bola 6 - Par / Impar',
      1469: 'Pelota 7 - Más / Menos',
      1470: 'Pelota 7 - Par / Impar',
      1471: 'Bola 8 - Más / Menos',
      1472: 'Bola 8 - Par / Impar',
      1473: 'Bola 9 - Más / Menos',
      1474: 'Bola 9 - Par / Impar',
      1475: 'Bola 10 - Más / Menos',
      1476: 'Bola 10 - Par / Impar',
      1477: 'Bola 11 - Más / Menos',
      1478: 'Bola 11 - Par / Impar',
      1479: 'Bola 12 - Más / Menos',
      1480: 'Bola 12 - Par / Impar',
      1481: 'Bola 13 - Más / Menos',
      1482: 'Bola 13 - Par / Impar',
      1483: 'Bola 14 - Más / Menos',
      1484: 'Bola 14 - Par / Impar',
      1485: 'Bola 15 - Más / Menos',
      1486: 'Bola 15 - Par / Impar',
      1487: 'Bola 16 - Más / Menos',
      1488: 'Bola 16 - Par / Impar',
      1489: 'Bola 17 - Más / Menos',
      1490: 'Bola 17 - Par / Impar',
      1491: 'Bola 18 - Más / Menos',
      1492: 'Bola 18 - Par / Impar',
      1493: 'Bola 19 - Más / Menos',
      1494: 'Bola 19 - Par / Impar',
      1495: 'Bola 20 - Más / Menos',
      1496: 'Bola 20 - Par / Impar',
      1497: 'Al menos uno de los números sorteados es divisible por 3',
      1498: 'Al menos uno de los números sorteados es divisible por 4',
      1499: 'Al menos uno de los números sorteados es divisible por 5',
      1500: 'Al menos uno de los números sorteados es divisible por 7',
      1501: 'Al menos uno de los números sorteados es divisible por 10',
      1502: 'Al menos uno de los números sorteados es divisible por 15',
      1503: 'Al menos uno de los números sorteados es divisible por 20',
      1504: 'Número más alto Drawn - Más / Menos',
      1505: 'Valor total de la más baja y con mayor cantidad de Drawn - Más / Menos',
      1506: 'Número más bajo Drawn - Más / Menos',
      1507: 'Diferencia entre la máxima y mínima números sorteados - / Menos',
      1508: 'Valor total de la más baja y con mayor cantidad de Drawn - Par / Impar',
      1509: 'Diferencia entre el mayor y el menor números sorteados - Par / Impar',
      1510: 'En primer número extraído es mayor que el último número Drawn',
      1511: 'Último número sorteado - Par / Impar',
      1512: 'En primer número sorteado - Par / Impar',
      1513: 'Todos los números sorteados Incluso',
      1514: 'Todos los números sorteados Odd',
      1515: 'Número dibujar',
      1520: 'Cuarto período - primera torreta',
      1521: 'Quinto período - primera torreta',
      1523: 'Más / Menos Barons asesinada - cuarto período',
      1524: 'Más / Menos Barons asesinada - quinto período',
      1525: 'Más / Menos dragones asesinada - cuarto período',
      1526: 'Más / Menos dragones asesinada - quinto período',
      1527: 'Más / Menos torretas Destroyed - cuarto período',
      1528: 'Más / Menos torretas Destroyed - quinto período',
      1529: 'Cuarto período de duración',
      1530: 'Quinto período de duración',
      1531: 'Par / Impar Veces - cuarto período',
      1532: 'Par / Impar Veces - quinto período',
      1533: 'Bajo Inhibidores / Over - 1er Periodo',
      1534: 'Bajo Inhibidores / Over - 2º Periodo',
      1535: 'En el Aeropuerto de temperatura en el rango de (Celsius)',
      1536: 'Bajo Maps / Más',
      1537: 'Inicio equipo para ganar y Más / Menos - Las horas extraordinarias Incluyendo',
      1538: 'Lejos equipo para ganar y Más / Menos - Las horas extraordinarias Incluyendo',
      1539: 'Ganador',
      1540: 'Las piernas del partido total Más / Menos',
      1541: 'rondas Handicap',
      1542: '1ª Etapa Ganador',
      1543: 'Primero en ganar 3 piernas',
      1546: 'Cuarto período - primera Roshan',
      1547: 'Quinto período - primera Roshan',
      1548: 'Cuarto período - 1º cuartel',
      1549: 'Quinto período - 1º cuartel',
      1550: 'Cuarto período - primera tou',
      1551: 'Quinto período - primera tou',
      1552: 'Asiático Más / Menos Esquinas',
      1553: 'Los dos equipos marcan Cantidad de puntos - 1er Periodo',
      1554: 'Los dos equipos marcan Cantidad de puntos - 1ª Parte',
      1555: 'Más / Menos quinto período - Equipo Local',
      1556: 'Más / Menos quinto período - Equipo visitante',
      1558: 'Puntos Handicap asiático',
      1559: 'Ganador de la Copa',
      1561: '3 entradas primera Ganador',
      1562: '3 entradas primero Más / Menos',
      1563: 'Tiempo de gol',
      1564: 'Tiempo de gol - Equipo Inicio',
      1565: 'Tiempo de gol - Equipo visitante',
      1566: 'Tiempo Resultado del partido',
      1567: 'Top nacionalidad - Sudáfrica',
      1568: 'Top nacionalidad - español',
      1569: 'Top nacionalidad - Irlandés',
      1570: 'Top nacionalidad - América del Sur',
      1571: 'Top nacionalidad - Canadá',
      1572: 'Top nacionalidad - inglés',
      1573: 'Top nacionalidad - Europa Continental',
      1575: 'Hacer Cut',
      1576: 'Para la señorita Cut',
      1577: 'Top nacionalidad - American',
      1578: 'Nacionalidad del ganador',
      1579: 'Puntos de Asia Handicap - 1er Periodo',
      1580: 'Puntos de Asia Handicap - 2º Periodo',
      1581: 'Puntos de Asia Handicap - 3er Periodo',
      1583: 'Mejor equipo Bowler 1r turno - Equipo Inicio',
      1584: 'Mejor equipo Bowler 1r turno - Equipo visitante',
      1585: 'Mejor equipo bateador 1r turno - Equipo Inicio',
      1586: 'Mejor equipo bateador 1r turno - Equipo visitante',
      1587: '¿Habrá un As Punto siguiente',
      1589: '¿Habrá una doble falta Punto siguiente',
      1591: 'Más / Menos - 1er largo',
      1592: 'Para ganar la Liga',
      1593: 'División de ambas partes para',
      1594: 'La mayoría de los Hits - Las horas extraordinarias Incluyendo',
      1595: 'Luchar para llegar hasta el final',
      1596: 'Cuando terminará la lucha',
      1597: 'Resultado lucha',
      1598: 'ronda de Apuestas',
      1599: 'Top Hit primero',
      1600: 'Inferior primero Hit',
      1601: 'Top primera Run',
      1602: 'Inferior primero Run',
      1603: 'La mayoría de los éxitos en el 1r turno',
      1605: 'Con el equipo de mayor puntuación Entrada',
      1606: 'Ambos equipos marcarán 2 o más goles',
      1607: 'Ambos equipos marcarán 3 o más goles',
      1608: 'Equipo para ganar la mayoría de los períodos',
      1609: 'Handicap asiático Tiempo completo - 1er Periodo',
      1610: 'Handicap asiático Tiempo completo - 2º Periodo',
      1611: 'Handicap asiático Tiempo completo - 3er Periodo',
      1612: 'MVP',
      1614: 'Conferencia de ambas partes para',
      1615: 'ganar Conferencia',
      1617: 'Temporada del jugador Especiales',
      1618: '5 entradas primera Ganador - 12',
      1619: '1er Periodo - Mitad Ganador Local / Visitante',
      1620: '1er Periodo - Medio tiempo Handicap Asiático',
      1621: 'Segundo período - Mitad Ganador Local / Visitante',
      1622: 'Segundo período - Medio tiempo Handicap Asiático',
      1624: 'Se ejecuta bajo / sobre del jugador',
      1625: 'Bajo solteros / Más del jugador',
      1626: 'Más / Menos jugador Jonrones',
      1627: 'En Bases / Más jugador robados',
      1628: 'Más / Menos jugador de dobles',
      1629: 'Más / Menos jugador Triples',
      1630: 'Lanzador para grabar el Win',
      1631: 'Más / Menos jugador Total de Bases',
      1635: 'Se ejecuta bajo la jarra / Más de Ganado',
      1636: 'Bajo Golpea jarra / Más de mascotas',
      1637: 'Bajo ponches jarra / Más',
      1638: 'clasificación por equipos',
      1639: 'Clasificación de Jovenes',
      1640: 'puntos Clasificación',
      1641: 'Rey de las Montañas',
      1642: 'Nivel 1',
      1649: 'Vuelta más rápida',
      1650: 'gana el coche',
      1651: 'Primer piloto en Retiro',
      1652: 'La clasificación que gana el coche',
      1653: 'Rápido en la práctica 1',
      1654: 'puntos Finalizar',
      1655: 'Calificador más rápido',
      1657: 'Etapa 3',
      1659: 'En primer constructor de Retiro',
      1660: 'Cuadrícula de posición del ganador',
      1661: 'El coche de seguridad durante la carrera',
      1662: 'Número de drivers clasificados',
      1663: 'Período Virtual Car seguridad durante la carrera',
      1664: 'Puntuación agujero',
      1667: 'Agujero para Ganar',
      1670: 'Partido atada',
      1672: 'Bajo Objetivos / Más de - 2ª Parte',
      1673: 'Par / Impar Rondas cuarto período',
      1674: 'Par / Impar Rondas quinto período',
      1675: 'Líder segunda ronda',
      1676: 'Ambos equipos marcarán - incluidas las horas extraordinarias',
      1677: 'Último equipo en marcar - Incluye prórroga',
      1678: 'Top nacionalidad - alemán',
      1679: 'Top nacionalidad - austríaco',
      1680: 'Top nacionalidad - Nueva Zelanda',
      1681: 'Arriba a la izquierda del jugador Handed',
      1682: 'Top Amateur',
      1683: 'Top nacionalidad - Corea',
      1684: 'Top nacionalidad - Scandinavian',
      1685: 'Top nacionalidad - El francés',
      1686: 'Top ex ganador',
      1687: 'Top nacionalidad - sueco',
      1688: 'Top nacionalidad - Japonés',
      1689: 'Top nacionalidad - Scotsman',
      1690: 'Top Nacionalidad - Belga',
      1691: 'Cuarto período europeo de la desventaja',
      1692: '3er tiempo Handicap Europeo',
      1693: 'Período Europea segunda Handicap',
      1694: '1er Periodo Europea Handicap',
      1695: 'Puntuación ronda',
      1696: 'Torneo de anotación de la victoria',
      1697: 'Resultado después de Ronda que lleva',
      1698: 'Para el tiro del espacio abierto con la te',
      1699: 'Para Hit green en regulación',
      1700: 'Líder tercera ronda',
      1702: 'Bajo intentos de pase / Más del jugador',
      1704: 'Touchdown en 1er Periodo',
      1705: 'Touchdown en segundo Periodo',
      1706: 'Touchdown en 3er Periodo',
      1707: 'Touchdown en cuarta Periodo',
      1708: 'Bajo / Exactamente / Más - Equipo Local momentos del aterrizaje',
      1709: 'Bajo / Exactamente / Más - Equipo visitante momentos del aterrizaje',
      1710: 'Bajo / Exactamente / Más - Inicio del equipo 1st Half momentos del aterrizaje',
      1711: 'Bajo / Exactamente / Más - Equipo visitante primera mitad momentos del aterrizaje',
      1712: '16a Ronda Ganador - cuarto período',
      1713: '16a Ronda Ganador - quinto período',
      1714: 'Más / Menos tous Destruido - cuarto período',
      1715: 'Más / Menos tous Destruido - quinto período',
      1722: 'Se establece en Finalizar para Cero',
      1723: 'Más / Menos Muertes - 1er periodo - primera ronda',
      1724: 'Más / Menos Muertes - segundo período - primera ronda',
      1728: 'Ambos equipos marcarán - Tiempo extra',
      1729: 'Doble oportunidad - Tiempo de descuento',
      1730: '¿El juego Ir a Super Over?',
      1731: 'Doble quinto período de Probabilidad',
      1732: 'Doble Período 6 de Probabilidad',
      1733: 'Doble 7º período Probabilidad',
      1734: 'Doble Período 8 de Probabilidad',
      1736: 'Número de carreras 1r turno',
      1737: 'Número de carreras segunda Entrada',
      1738: 'Número de carreras tercera Inning',
      1739: 'Número de carreras 4ta entrada',
      1740: 'Número de carreras 5to turno',
      1741: 'Número de carreras 6to turno',
      1742: 'Número de carreras 7ª entrada',
      1743: 'Número de carreras 8vo turno',
      1744: 'Un cincuenta que se marcarán en el 1er Innings',
      1745: 'Caída de primera Wicket',
      1747: 'Siguiente es la noche',
      1748: 'Partido completado',
      1749: 'Anotó siglo',
      1750: 'Anotó siglo - 1r turno',
      1752: 'Equipo visitante Primera Salida 6-Way',
      1753: 'Top Partido Bateador',
      1754: 'En marcar una multa',
      1758: 'Sexto hombre del año',
      1760: 'Novato del Año',
      1761: 'MVP de la temporada regular',
      1763: 'Bateador en anotar un Hundred en 1er inning',
      1765: '9 dardo en Finalizar en el partido',
      1766: 'Hora del primer intento',
      1767: 'Hora del último intento',
      1770: '9 dardo Finalizar en el Partido - Equipo Local',
      1771: '9 dardo Finalizar en el Partido - Equipo visitante',
      1772: 'Top Run Goleador',
      1773: 'Top Run Goleador - Equipo Inicio',
      1774: 'Top Run Goleador - Equipo visitante',
      1775: 'Equipo de Top Run Goleador',
      1776: 'Más / Menos Punts',
      1777: 'Más / Menos Sacks',
      1778: 'Más / Menos 1ª Downs',
      1779: '1er equipo para conseguir un primer intento',
      1780: 'Objetivos de la 1ª Parte Más / Menos de campo - Inicio del equipo',
      1781: 'Objetivos de la 1ª Parte Más / Menos de campo - Equipo visitante',
      1782: 'Bajo / Exactamente / Más - Canastas primera mitad',
      1783: 'Más / Menos momentos del aterrizaje 1er Periodo',
      1784: 'Bajo pena / Más de Aceptada',
      1785: 'La mayoría de las muertes en el partido',
      1786: 'Equipo con el mayor número de muertes en el partido',
      1787: 'Los intentos bajo / sobre Pass',
      1788: 'Más / Menos Pass Terminaciones',
      1789: 'Más / Menos yardas por pase',
      1790: 'Los intentos bajo / sobre la fiebre',
      1791: 'Más / Menos de Rush Yardas',
      1792: 'Más / Menos jugador tacleadas y Asistencia',
      1794: 'En primer equipo a Punt',
      1795: '1er Periodo 1X2 y Más / Menos',
      1796: 'Ayuda 1X2',
      1797: 'Doble oportunidad Ayuda',
      1798: 'Equipo para liderar Después de cada periodo',
      1799: 'Equipo con más yardas aéreas',
      1800: 'Equipo con más yardas terrestres',
      1801: 'El éxito de la conversión de 2 puntos',
      1802: 'Más / Menos pases de anotación',
      1803: 'Bajo / Objetivos sobre el campo 1er Periodo',
      1804: 'Anotar en los 2 últimos minutos de la primera mitad',
      1805: 'Equipo para Recibir apertura Kick Off',
      1806: 'Más / Menos Equipo Local momentos del aterrizaje - 1er periodo',
      1807: 'Más / Menos del equipo visitante momentos del aterrizaje - 1er periodo',
      1808: 'Bajo / Exactamente / Más de equipo local momentos del aterrizaje - 1er periodo',
      1809: 'Bajo / Exactamente / Más Away momentos del aterrizaje del equipo - 1er periodo',
      1810: 'Bajo Objetivos / Durante Equipo de campo - 1er periodo',
      1812: 'Más / Menos de goles del equipo de campo - 1er periodo',
      1813: 'Equipo visitante a ganar cada barrio',
      1814: 'El equipo local para ganar cada barrio',
      1815: 'Primero ofensivo jugada del juego',
      1816: 'Para triunfo en cuartos',
      1817: 'Para llegar a la final',
      1818: 'Inicio equipo en marcar 25 Goles',
      1819: 'Inicio equipo en marcar 30 Goles',
      1820: 'Equipo visitante en marcar 25 Goles',
      1821: 'Equipo visitante en marcar 30 Goles',
      1822: 'Inicio equipo para ganar cualquiera de las mitades',
      1823: 'Lejos equipo para ganar cualquiera de las mitades',
      1824: 'Etapa de eliminación',
      1825: 'Bajo Más de Ayuda /',
      1826: 'Más / Menos rebotes',
      1827: 'Bajo las ayudas del equipo de / Más Inicio',
      1828: 'Más / Menos del equipo visitante Ayuda',
      1829: 'Más / Menos Equipo Local Rebotes',
      1830: 'Más / Menos de equipo visitante Rebotes',
      1831: '1X2 3 Puntos Hecho',
      1832: 'Más / Menos 3 Puntos Hecho',
      1833: 'Bajo / Team en el hogar 3 puntos señalados',
      1834: 'Más / Menos del equipo de fuera 3 puntos señalados',
      1835: 'Handicap asiático 3 Puntos Hecho',
      1841: 'primeros Sustituciones',
      1842: 'Segundo gol en el partido',
      1843: '3er gol en el partido',
      1846: '60 Resultado Minuto',
      1847: '75 Resultado Minuto',
      1848: 'Gol en los primeros 5 minutos?',
      1849: 'En primer saque de banda en el Partido',
      1850: 'Dibujar en cualquier Medio',
      1851: 'Encabezada gol en el partido?',
      1852: 'Más / Menos Meta Minutos',
      1853: 'Más / Menos Tie Break en el partido',
      1854: 'Bajo puntos / durante las vacaciones',
      1855: 'Más / Menos puntos de ruptura Equipo Local',
      1856: 'Más / Menos puntos de ruptura Equipo visitante',
      1857: 'Más / Menos trimestre más alto de puntuación',
      1858: 'Más / Menos cuarto más bajo de puntuación',
      1859: 'La mayoría de las Tries 3-Way',
      1860: '10 Minute Margen Ganar 5-Way',
      1861: 'La mayoría de las pérdidas de equipo',
      1862: 'Un Sub Marcarán en el Juego',
      1863: 'Ganar todos los períodos - Inicio del equipo',
      1864: 'Ganar todos los períodos - Equipo visitante',
      1865: 'Primeros 10 minutos Tries Más / Menos',
      1866: 'Minuto 15 Más / Menos - Esquinas',
      1867: '2 goles en una fila por el equipo',
      1868: '3 goles en una fila por el equipo',
      1869: 'La mayoría de los tries convertidos',
      1872: 'El tenista Finalista?',
      1873: 'Wild Card para llegar a final?',
      1874: 'La mitad de ganar - Tenis Ganador absoluto',
      1875: 'Ganar Quarter - Tenis Ganador absoluto',
      1881: 'Hoyo en uno',
      1882: '¿Habrá un play-off?',
      1883: '72 Hoyos Ups',
      1884: '54 Agujero Partido Ups',
      1885: 'Número 1 del draft',
      1886: '1X2 y Doble oportunidad',
      1887: 'Inicio equipo para ganar \\ Draw y ambos equipos a marcar',
      1888: 'Lejos equipo para ganar \\ Draw y ambos equipos a marcar',
      1889: 'Ventaja del ganador - 1ª Parte',
      1891: 'Liga Americana Cy Young',
      1892: 'MVP de la Liga Americana',
      1893: 'Liga Nacional Cy Young',
      1894: 'MVP de la Liga Nacional',
      1895: 'Lanzador para registrar la mayor cantidad de victorias de la temporada regular',
      1896: 'Jugador en batear Más Cuadrangulares Regular Season',
      1897: 'Juego voluntad decidió en tiempo extra?',
      1898: 'temporada regular Ganar%',
      1900: 'Jugador Mejor Goleador',
      1901: 'Más / Menos Aparejos',
      1902: 'El número 2 del draft',
    }
}