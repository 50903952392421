import { filter, uniqBy, map, orderBy, forEach } from 'lodash';
import { LIVE_CASINO_CATEGORIES, LIVE_CASINO_EVOLUTION_GAMES, NEW_CASINO_CATEGORIES, VIRTUAL_SPORTS } from '../../config';
import * as Actions from '../actions/actionTypes';
import { Translate } from '../../localization';

const initialState = {
    casinoGames: [],
    casinoProviders: [],
    casinoCategories: [],
    filteredCasinoGames: [],
    casinoSearchValue: '',
    filteredGamesCount: 0,
    loadingCasinoGames: true,
    activeCategory: 'All',
    currentGameData: {},
    liveCasinoGames: [],
    filteredLiveCasinoGames: [],
    liveCasinoCategories: [],
    loadingLiveCasinoGames: true,
    searchStarted: false,
    noSearchResults: false,
    activeCategoryLive: 'All',
    currentLiveGameData: {},
    virtualSports: [],
    filteredVirtualSports: [],
    virtualSportsCategories: [],
    loadingVirtualSports: true,
    virtualSportsSearchStarted: false,
    noVirtualSearchResult: false,
    virtualSportsActiveCategory: 'All',
    casinoLobbyURL: '',
    setLoadingSlot: false,
};

const casinoReducer = function (state = initialState, action) {
    switch (action.type) {
        // case Actions.SET_CASINO_GAMES: {
        //     let games = action.games;
        //     let categories = uniqBy(map(games, 'category'));
        //     categories =
        //         uniqBy(map(categories, (category) => {
        //             const splitedCategory = category.split('/');
        //             return {
        //                 value: splitedCategory[1],
        //                 name: splitedCategory[1]
        //             };
        //         }), 'value');
        //     categories.unshift({ value: Translate.all, name: Translate.all });
        //     categories.splice(1, 0, { value: 'PROVIDERS', name: 'PROVIDERS' });

        //     const filterProviders = uniqBy(map(games, 'provider'));
        //     const providers = uniqBy(map(filterProviders, 'name'));

        //     return {
        //         ...state,
        //         casinoGames: games,
        //         casinoCategories: { ...categories, ...categories },
        //         casinoProviders: providers,
        //         loadingCasinoGames: false,
        //     };
        // }

        case Actions.SET_CASINO_GAMES: {
            let games = action.games;
            return {
                ...state,
                casinoGames: { ...state.casinoGames, ...games },
            };
        }

        case Actions.SET_CASINO_FILTERED_GAMES: {
            return {
                ...state,
                filteredCasinoGames: [...state.filteredCasinoGames, ...action.games],
                filteredGamesCount: action.count,
                loadingCasinoGames: false,
            };
        }

        case Actions.SET_CASINO_LOADING: {
            return {
                ...state,
                loadingCasinoGames: action.value,
            };
        }

        case Actions.CLEAR_FILTERED_GAMES: {
            return {
                ...state,
                filteredCasinoGames: [],
                filteredGamesCount: 0,
            };
        }

        case Actions.SET_CASINO_CATEGORIES: {
            return {
                ...state,
                casinoCategories: action.categories,
            };
        }

        case Actions.SET_CASINO_PROVIDERS: {
            return {
                ...state,
                casinoProviders: action.providers,
                loadingCasinoGames: false,
            };
        }

        case Actions.SET_CASINO_SEARCH_VALUE: {
            return {
                ...state,
                casinoSearchValue: action.searchVal,
            };
        }

        case Actions.SET_LIVE_CASINO_GAMES: {
            let games = action.games;

            forEach(games, (game) => {
                if (game.category === 'CASINO/LIVECASINO/ROULETTE') {
                    game.sort = 1;
                } else if (game.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    game.sort = 2;
                } else if (game.category === 'CASINO/LIVECASINO/BACCARAT') {
                    game.sort = 3;
                } else {
                    game.sort = 4;
                }
            });
            games = orderBy(games, ['sort'], 'asc');
            const categories = games.map(game => game.category)

            let liveCasinoCategories = LIVE_CASINO_CATEGORIES
            liveCasinoCategories = liveCasinoCategories.filter(category => categories.indexOf(category.value) > -1);
            liveCasinoCategories = [LIVE_CASINO_CATEGORIES[0], ...liveCasinoCategories]
            return {
                ...state,
                liveCasinoGames: games,
                liveCasinoCategories: liveCasinoCategories,
                loadingLiveCasinoGames: false,
            };
        }

        case Actions.SET_CASINO_ACTIVE_CATEGORY: {
            let { casinoGames } = state;

            if (action.category === Translate.all) {
                return {
                    ...state,
                    casinoGames: casinoGames,
                    filteredCasinoGames: [],
                    activeCategory: action.category,
                };
            } else {
                let filteredGames = filter(casinoGames, (game) => {
                    // return game.provider === action.category;  // GG casino
                    return game.category.split('/')[1] === action.category;  // for casino
                });
                return {
                    ...state,
                    filteredCasinoGames: filteredGames,
                    activeCategory: action.category,
                };
            }
        }

        case Actions.SET_LIVE_CASINO_ACTIVE_CATEGORY: {
            let { liveCasinoGames } = state;

            if (action.category === Translate.all) {
                return {
                    ...state,
                    liveCasinoGames: liveCasinoGames,
                    filteredLiveCasinoGames: [],
                    activeCategoryLive: action.category,
                };
            } else {
                let filteredGames = filter(liveCasinoGames, (game) => {
                    return game.category === action.category;
                });
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    activeCategoryLive: action.category,
                };
            }
        }

        case Actions.SET_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                noSearchResults: false,
                filteredCasinoGames: [],
            };
        }

        case Actions.SET_LIVE_CASINO_SEARCH_STARTED: {
            return {
                ...state,
                searchStarted: action.value,
                filteredLiveCasinoGames: [],
            };
        }

        case Actions.SET_CASINO_SEARCH: {
            let { casinoGames } = state;

            let filteredGames = filter(casinoGames, (game) => {
                let lowercaseGameName = game.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredGames.length > 0) {
                return {
                    ...state,
                    filteredCasinoGames: filteredGames,
                    noSearchResults: false,
                };
            } else {
                return {
                    ...state,
                    filteredCasinoGames: [],
                    noSearchResults: true,
                };
            }
        }

        case Actions.SET_LIVE_CASINO_SEARCH: {
            let { liveCasinoGames } = state;

            let filteredGames = filter(liveCasinoGames, (game) => {
                let lowercaseGameName = game.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredGames.length > 0) {
                return {
                    ...state,
                    filteredLiveCasinoGames: filteredGames,
                    noSearchResults: false,
                };
            } else {
                return {
                    ...state,
                    filteredLiveCasinoGames: [],
                    noSearchResults: true,
                };
            }
        }

        case Actions.SET_GAME_DATA: {
            return {
                ...state,
                currentGameData: action.data,
            };
        }

        case Actions.SET_LIVE_GAME_DATA: {
            return {
                ...state,
                currentLiveGameData: action.data,
            };
        }

        case Actions.CLEAR_CASINO_GAMES: {
            return {
                ...state,
                casinoGames: [],
                filteredCasinoGames: [],
                loadingCasinoGames: true,
            };
        }

        case Actions.CLEAR_LIVE_CASINO_GAMES: {
            return {
                ...state,
                liveCasinoGames: [],
                filteredLiveCasinoGames: [],
                liveCasinoCategories: [],
                loadingLiveCasinoGames: true,
                searchStarted: false,
                noSearchResults: false,
                activeCategoryLive: 'All',
                // currentLiveGameData: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS: {
            let sports = action.sports;

            forEach(sports, (sport) => {
                if (sport.category === 'CASINO/LIVECASINO/ROULETTE') {
                    sport.sort = 1;
                } else if (sport.category === 'CASINO/LIVECASINO/BLACKJACK') {
                    sport.sort = 2;
                    // } else if (sport.category === 'CASINO/LIVECASINO/BACCARAT') {
                    //     sport.sort = 3;
                } else {
                    sport.sort = 3;
                }
            });
            sports = orderBy(sports, ['sort'], 'asc');

            let virtualSportsCategories = VIRTUAL_SPORTS;
            return {
                ...state,
                virtualSports: sports,
                virtualSportsCategories: virtualSportsCategories,
                loadingVirtualSports: false,
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_CATEGORY: {
            let { virtualSportsActiveCategory, virtualSports } = state;

            if (action.category === Translate.all) {
                return {
                    ...state,
                    virtualSportsActiveCategory: virtualSportsActiveCategory,
                    filteredVirtualSports: [],
                    virtualSportsActiveCategory: action.category,
                };
            } else {
                let filteredSports = filter(virtualSports, (sport) => {
                    return sport.category.split('/')[1] === action.category.split('/')[1];
                });
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    virtualSportsActiveCategory: action.category,
                };
            }
        }

        case Actions.CLEAR_VIRTUAL_SPORTS: {
            return {
                ...state,
                virtualSports: [],
                filteredVirtualSports: [],
                virtualSportsCategories: [],
                loadingVirtualSports: true,
                virtualSportsSearchStarted: false,
                noVirtualSearchResult: false,
                virtualSportsActiveCategory: 'All',
                currentVirtualSports: {},
            };
        }

        case Actions.SET_VIRTUAL_SPORTS_SEARCH: {
            let { virtualSports } = state;

            let filteredSports = filter(virtualSports, (sport) => {
                let lowercaseGameName = sport.name.toLowerCase();
                let lowercaseSearchVal = action.value.toLowerCase();
                return lowercaseGameName.includes(lowercaseSearchVal);
            });

            if (filteredSports.length > 0) {
                return {
                    ...state,
                    filteredVirtualSports: filteredSports,
                    noVirtualSearchResult: false,
                };
            } else {
                return {
                    ...state,
                    filteredVirtualSports: [],
                    noVirtualSearchResult: true,
                };
            }
        }

        case Actions.SET_VIRTUAL_SEARCH_STARTED: {
            return {
                ...state,
                virtualSportsSearchStarted: action.value
            };
        }

        case Actions.SET_CASINO_LOBBY: {
            return {
                ...state,
                casinoLobbyURL: action.url,
            };
        }

        case Actions.SET_LOADING_SLOT: {
            return {
                ...state,
                setLoadingSlot: action.value
            };
        }

        default:
            return state;
    }
};

export default casinoReducer;
