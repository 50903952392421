export const STATIC_OUTCOME = {
    "1": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "2": {
        "name_en": "X",
        "name_aa": "X",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "X",
        "name_ro": "X",
        "name_ru": "X",
        "name_tr": "X",
        "name_se": "X",
        "name_el": "X"
    },
    "3": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "4": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "5": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "6": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "7": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "keines",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "ни одного",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα(ς)"
    },
    "8": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "9": {
        "name_en": "{$competitor1} or X",
        "name_aa": "{$competitor1} أو ارسم",
        "name_de": "{$competitor1} oder X",
        "name_nl": "{$competitor1} of X",
        "name_pl": "{$competitor1} lub remis",
        "name_ro": "{$competitor1} sau egal",
        "name_ru": "{$competitor1} или ничья",
        "name_tr": "{$competitor1} veya X",
        "name_se": "{$competitor1} elr oavgjort",
        "name_el": "{$competitor1} ή ισοπαλία"
    },
    "10": {
        "name_en": "{$competitor1} or {$competitor2}",
        "name_aa": "{$competitor1} أو {$competitor2}",
        "name_de": "{$competitor1} oder {$competitor2}",
        "name_nl": "{$competitor1} of {$competitor2}",
        "name_pl": "{$competitor1} lub {$competitor2}",
        "name_ro": "{$competitor1} sau {$competitor2}",
        "name_ru": "{$competitor1} или {$competitor2}",
        "name_tr": "{$competitor1} veya {$competitor2}",
        "name_se": "{$competitor1} elr {$competitor2}",
        "name_el": "{$competitor1} ή {$competitor2}"
    },
    "11": {
        "name_en": "X or {$competitor2}",
        "name_aa": "ارسم أو {$competitor2}",
        "name_de": "X oder {$competitor2}",
        "name_nl": "X of {$competitor2}",
        "name_pl": "remis lub {$competitor2}",
        "name_ro": "egal sau {$competitor2}",
        "name_ru": "ничья или {$competitor2}",
        "name_tr": "X veya {$competitor2}",
        "name_se": "oavgjort elr {$competitor2}",
        "name_el": "ισοπαλία ή {$competitor2}"
    },
    "12": {
        "name_en": "over {total}",
        "name_aa": "over {total}",
        "name_de": "over {total}",
        "name_nl": "over {total}",
        "name_pl": "over {total}",
        "name_ro": "over {total}",
        "name_ru": "over {total}",
        "name_tr": "over {total}",
        "name_se": "over {total}",
        "name_el": "over {total}"
    },
    "13": {
        "name_en": "under {total}",
        "name_aa": "under {total}",
        "name_de": "under {total}",
        "name_nl": "under {total}",
        "name_pl": "under {total}",
        "name_ro": "under {total}",
        "name_ru": "under {total}",
        "name_tr": "under {total}",
        "name_se": "under {total}",
        "name_el": "under {total}"
    },
    "14": {
        "name_en": "{$competitor1} regular time",
        "name_aa": "{$competitor1} وقت منتظم",
        "name_de": "{$competitor1} reguläre Spielzeit",
        "name_nl": "{$competitor1} reguliere speeltijd",
        "name_pl": "{$competitor1} regulaminowy czas gry",
        "name_ro": "{$competitor1} timp regulamentar",
        "name_ru": "{$competitor1} в основное время",
        "name_tr": "{$competitor1} normal süre",
        "name_se": "{$competitor1} ordinarie tid",
        "name_el": "{$competitor1} κανονική διάρκεια"
    },
    "15": {
        "name_en": "{$competitor2} regular time",
        "name_aa": "{$competitor2} وقت منتظم",
        "name_de": "{$competitor2} reguläre Spielzeit",
        "name_nl": "{$competitor2} reguliere speeltijd",
        "name_pl": "{$competitor2} regulaminowy czas gry",
        "name_ro": "{$competitor2} timp regulamentar",
        "name_ru": "{$competitor2} в основное время",
        "name_tr": "{$competitor2} normal süre",
        "name_se": "{$competitor2} ordinarie tid",
        "name_el": "{$competitor2} κανονική διάρκεια"
    },
    "16": {
        "name_en": "{$competitor1} overtime",
        "name_aa": "{$competitor1} الوقت الإضافي",
        "name_de": "{$competitor1} Verlängerung",
        "name_nl": "{$competitor1} verlenging",
        "name_pl": "{$competitor1} po dogrywce",
        "name_ro": "{$competitor1} prelungiri",
        "name_ru": "{$competitor1} в овертайме",
        "name_tr": "{$competitor1} uzatmalar",
        "name_se": "{$competitor1} övertid",
        "name_el": "{$competitor1} παράταση"
    },
    "17": {
        "name_en": "{$competitor2} overtime",
        "name_aa": "{$competitor2} الوقت الإضافي",
        "name_de": "{$competitor2} Verlängerung",
        "name_nl": "{$competitor2} verlenging",
        "name_pl": "{$competitor2} po dogrywce",
        "name_ro": "{$competitor2} prelungiri",
        "name_ru": "{$competitor2} в овертайме",
        "name_tr": "{$competitor2} uzatmalar",
        "name_se": "{$competitor2} övertid",
        "name_el": "{$competitor2} παράταση"
    },
    "18": {
        "name_en": "{$competitor1} penalties",
        "name_aa": "{$competitor1} العقوبات",
        "name_de": "{$competitor1} Elfmeterschießen",
        "name_nl": "{$competitor1} strafschoppen",
        "name_pl": "{$competitor1} po rzutach karnych",
        "name_ro": "{$competitor1} lovituri de departajare",
        "name_ru": "{$competitor1} по пенальти",
        "name_tr": "{$competitor1} penaltılar",
        "name_se": "{$competitor1} straffar",
        "name_el": "{$competitor1} πέναλτι"
    },
    "19": {
        "name_en": "{$competitor2} penalties",
        "name_aa": "{$competitor2} العقوبات",
        "name_de": "{$competitor2} Elfmeterschießen",
        "name_nl": "{$competitor2} strafschoppen",
        "name_pl": "{$competitor2} po rzutach karnych",
        "name_ro": "{$competitor2} lovituri de departajare",
        "name_ru": "{$competitor2} по пенальти",
        "name_tr": "{$competitor2} penaltılar",
        "name_se": "{$competitor2} straffar",
        "name_el": "{$competitor2} πέναλτι"
    },
    "54": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "56": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "58": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "60": {
        "name_en": "3+",
        "name_aa": "3+",
        "name_de": "3+",
        "name_nl": "3+",
        "name_pl": "3+",
        "name_ro": "3+",
        "name_ru": "3+",
        "name_tr": "3+",
        "name_se": "3+",
        "name_el": "3+"
    },
    "70": {
        "name_en": "odd",
        "name_aa": "الفردية",
        "name_de": "ungerade",
        "name_nl": "oneven",
        "name_pl": "nieparzysty",
        "name_ro": "impar",
        "name_ru": "нечетное",
        "name_tr": "tek",
        "name_se": "udda",
        "name_el": "μονά"
    },
    "72": {
        "name_en": "even",
        "name_aa": "زوجي",
        "name_de": "gerade",
        "name_nl": "even",
        "name_pl": "parzysty",
        "name_ro": "par",
        "name_ru": "четное",
        "name_tr": "çift",
        "name_se": "jämna",
        "name_el": "ζυγά"
    },
    "74": {
        "name_en": "yes",
        "name_aa": "yes",
        "name_de": "yes",
        "name_nl": "yes",
        "name_pl": "yes",
        "name_ro": "yes",
        "name_ru": "yes",
        "name_tr": "yes",
        "name_se": "yes",
        "name_el": "yes"
    },
    "76": {
        "name_en": "no",
        "name_aa": "no",
        "name_de": "no",
        "name_nl": "no",
        "name_pl": "no",
        "name_ro": "no",
        "name_ru": "no",
        "name_tr": "no",
        "name_se": "no",
        "name_el": "no"
    },
    "78": {
        "name_en": "{$competitor1} & yes",
        "name_aa": "{$competitor1} ونعم",
        "name_de": "{$competitor1} & ja",
        "name_nl": "{$competitor1} & ja",
        "name_pl": "{$competitor1} & tak",
        "name_ro": "{$competitor1} & da",
        "name_ru": "{$competitor1} & да",
        "name_tr": "{$competitor1} & var",
        "name_se": "{$competitor1} & ja",
        "name_el": "ναι και {$competitor1}"
    },
    "80": {
        "name_en": "{$competitor1} & no",
        "name_aa": "{$competitor1} ولا",
        "name_de": "{$competitor1} & nein",
        "name_nl": "{$competitor1} & nee",
        "name_pl": "{$competitor1} & nie",
        "name_ro": "{$competitor1} & nu",
        "name_ru": "{$competitor1} & нет",
        "name_tr": "{$competitor1} & yok",
        "name_se": "{$competitor1} & nej",
        "name_el": "όχι και {$competitor1}"
    },
    "82": {
        "name_en": "X & yes",
        "name_aa": "ارسم ونعم",
        "name_de": "X & ja",
        "name_nl": "X & ja",
        "name_pl": "remis & tak",
        "name_ro": "egal & da",
        "name_ru": "ничья & да",
        "name_tr": "X & var",
        "name_se": "oavgjort & ja",
        "name_el": "ναι και ισοπαλία"
    },
    "84": {
        "name_en": "X & no",
        "name_aa": "ارسم و لا",
        "name_de": "X & nein",
        "name_nl": "X & nee",
        "name_pl": "remis & nie",
        "name_ro": "egal & nu",
        "name_ru": "ничья & нет",
        "name_tr": "X & yok",
        "name_se": "oavgjort & nej",
        "name_el": "όχι και ισοπαλία"
    },
    "86": {
        "name_en": "{$competitor2} & yes",
        "name_aa": "{$competitor2} ونعم",
        "name_de": "{$competitor2} & ja",
        "name_nl": "{$competitor2} & ja",
        "name_pl": "{$competitor2} & tak",
        "name_ro": "{$competitor2} & da",
        "name_ru": "{$competitor2} & да",
        "name_tr": "{$competitor2} & var",
        "name_se": "{$competitor2} & ja",
        "name_el": "ναι και {$competitor2}"
    },
    "88": {
        "name_en": "{$competitor2} & no",
        "name_aa": "{$competitor2} ولا",
        "name_de": "{$competitor2} & nein",
        "name_nl": "{$competitor2} & nee",
        "name_pl": "{$competitor2} & nie",
        "name_ro": "{$competitor2} & nu",
        "name_ru": "{$competitor2} & нет",
        "name_tr": "{$competitor2} & yok",
        "name_se": "{$competitor2} & nej",
        "name_el": "όχι και {$competitor2}"
    },
    "90": {
        "name_en": "over {total} & yes",
        "name_aa": "أكثر من {total} ونعم",
        "name_de": "über {total} & ja",
        "name_nl": "boven {total} & ja",
        "name_pl": "ponad {total} & tak",
        "name_ro": "peste {total} & da",
        "name_ru": "больше {total} & да",
        "name_tr": "{total} üstü & var",
        "name_se": "över {total} & ja",
        "name_el": "ναι και over {total}"
    },
    "92": {
        "name_en": "under {total} & yes",
        "name_aa": "تحت {total} و نعم",
        "name_de": "unter {total} & ja",
        "name_nl": "onder {total} & ja",
        "name_pl": "poniżej {total} & tak",
        "name_ro": "sub {total} & da",
        "name_ru": "меньше {total} & да",
        "name_tr": "{total} altı & var",
        "name_se": "under {total} & ja",
        "name_el": "ναι και under {total}"
    },
    "94": {
        "name_en": "over {total} & no",
        "name_aa": "أكثر من {total} و لا",
        "name_de": "über {total} & nein",
        "name_nl": "boven {total} & nee",
        "name_pl": "ponad {total} & nie",
        "name_ro": "peste {total} & nu",
        "name_ru": "больше {total} & нет",
        "name_tr": "{total} üstü & yok",
        "name_se": "över {total} & nej",
        "name_el": "όχι και over {total}"
    },
    "96": {
        "name_en": "under {total} & no",
        "name_aa": "تحت {total} و لا",
        "name_de": "unter {total} & nein",
        "name_nl": "onder {total} & nee",
        "name_pl": "poniżej {total} & nie",
        "name_ro": "sub {total} & nu",
        "name_ru": "меньше {total} & нет",
        "name_tr": "{total} altı & yok",
        "name_se": "under {total} & nej",
        "name_el": "όχι και under {total}"
    },
    "110": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "114": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "116": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "118": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "120": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "122": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0"
    },
    "124": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0"
    },
    "126": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "128": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "130": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "132": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "134": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "136": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1"
    },
    "138": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "140": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "142": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "144": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "146": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "148": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "150": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "152": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "154": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3"
    },
    "156": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "158": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "160": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "162": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5"
    },
    "164": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5"
    },
    "166": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6"
    },
    "274": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "276": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "278": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "280": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "282": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "284": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "286": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "288": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "290": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "292": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "294": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "296": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "298": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "300": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "302": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "304": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "306": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "308": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "310": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3"
    },
    "312": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "314": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "316": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "318": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "320": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "322": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4"
    },
    "324": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "326": {
        "name_en": "0:0 0:0",
        "name_aa": "0:0 0:0",
        "name_de": "0:0 0:0",
        "name_nl": "0:0 0:0",
        "name_pl": "0:0 0:0",
        "name_ro": "0:0 0:0",
        "name_ru": "0:0 0:0",
        "name_tr": "0:0 0:0",
        "name_se": "0:0 0:0",
        "name_el": "0:0 0:0"
    },
    "328": {
        "name_en": "0:0 0:1",
        "name_aa": "0:0 0:1",
        "name_de": "0:0 0:1",
        "name_nl": "0:0 0:1",
        "name_pl": "0:0 0:1",
        "name_ro": "0:0 0:1",
        "name_ru": "0:0 0:1",
        "name_tr": "0:0 0:1",
        "name_se": "0:0 0:1",
        "name_el": "0:0 0:1"
    },
    "330": {
        "name_en": "0:0 0:2",
        "name_aa": "0:0 0:2",
        "name_de": "0:0 0:2",
        "name_nl": "0:0 0:2",
        "name_pl": "0:0 0:2",
        "name_ro": "0:0 0:2",
        "name_ru": "0:0 0:2",
        "name_tr": "0:0 0:2",
        "name_se": "0:0 0:2",
        "name_el": "0:0 0:2"
    },
    "332": {
        "name_en": "0:0 0:3",
        "name_aa": "0:0 0:3",
        "name_de": "0:0 0:3",
        "name_nl": "0:0 0:3",
        "name_pl": "0:0 0:3",
        "name_ro": "0:0 0:3",
        "name_ru": "0:0 0:3",
        "name_tr": "0:0 0:3",
        "name_se": "0:0 0:3",
        "name_el": "0:0 0:3"
    },
    "334": {
        "name_en": "0:0 1:0",
        "name_aa": "0:0 1:0",
        "name_de": "0:0 1:0",
        "name_nl": "0:0 1:0",
        "name_pl": "0:0 1:0",
        "name_ro": "0:0 1:0",
        "name_ru": "0:0 1:0",
        "name_tr": "0:0 1:0",
        "name_se": "0:0 1:0",
        "name_el": "0:0 1:0"
    },
    "336": {
        "name_en": "0:0 1:1",
        "name_aa": "0:0 1:1",
        "name_de": "0:0 1:1",
        "name_nl": "0:0 1:1",
        "name_pl": "0:0 1:1",
        "name_ro": "0:0 1:1",
        "name_ru": "0:0 1:1",
        "name_tr": "0:0 1:1",
        "name_se": "0:0 1:1",
        "name_el": "0:0 1:1"
    },
    "338": {
        "name_en": "0:0 1:2",
        "name_aa": "0:0 1:2",
        "name_de": "0:0 1:2",
        "name_nl": "0:0 1:2",
        "name_pl": "0:0 1:2",
        "name_ro": "0:0 1:2",
        "name_ru": "0:0 1:2",
        "name_tr": "0:0 1:2",
        "name_se": "0:0 1:2",
        "name_el": "0:0 1:2"
    },
    "340": {
        "name_en": "0:0 2:0",
        "name_aa": "0:0 2:0",
        "name_de": "0:0 2:0",
        "name_nl": "0:0 2:0",
        "name_pl": "0:0 2:0",
        "name_ro": "0:0 2:0",
        "name_ru": "0:0 2:0",
        "name_tr": "0:0 2:0",
        "name_se": "0:0 2:0",
        "name_el": "0:0 2:0"
    },
    "342": {
        "name_en": "0:0 2:1",
        "name_aa": "0:0 2:1",
        "name_de": "0:0 2:1",
        "name_nl": "0:0 2:1",
        "name_pl": "0:0 2:1",
        "name_ro": "0:0 2:1",
        "name_ru": "0:0 2:1",
        "name_tr": "0:0 2:1",
        "name_se": "0:0 2:1",
        "name_el": "0:0 2:1"
    },
    "344": {
        "name_en": "0:0 3:0",
        "name_aa": "0:0 3:0",
        "name_de": "0:0 3:0",
        "name_nl": "0:0 3:0",
        "name_pl": "0:0 3:0",
        "name_ro": "0:0 3:0",
        "name_ru": "0:0 3:0",
        "name_tr": "0:0 3:0",
        "name_se": "0:0 3:0",
        "name_el": "0:0 3:0"
    },
    "346": {
        "name_en": "0:0 4+",
        "name_aa": "0:0 4+",
        "name_de": "0:0 4+",
        "name_nl": "0:0 4+",
        "name_pl": "0:0 4+",
        "name_ro": "0:0 4+",
        "name_ru": "0:0 4+",
        "name_tr": "0:0 4+",
        "name_se": "0:0 4+",
        "name_el": "0:0 4+"
    },
    "348": {
        "name_en": "0:1 0:1",
        "name_aa": "0:1 0:1",
        "name_de": "0:1 0:1",
        "name_nl": "0:1 0:1",
        "name_pl": "0:1 0:1",
        "name_ro": "0:1 0:1",
        "name_ru": "0:1 0:1",
        "name_tr": "0:1 0:1",
        "name_se": "0:1 0:1",
        "name_el": "0:1 0:1"
    },
    "350": {
        "name_en": "0:1 0:2",
        "name_aa": "0:1 0:2",
        "name_de": "0:1 0:2",
        "name_nl": "0:1 0:2",
        "name_pl": "0:1 0:2",
        "name_ro": "0:1 0:2",
        "name_ru": "0:1 0:2",
        "name_tr": "0:1 0:2",
        "name_se": "0:1 0:2",
        "name_el": "0:1 0:2"
    },
    "352": {
        "name_en": "0:1 0:3",
        "name_aa": "0:1 0:3",
        "name_de": "0:1 0:3",
        "name_nl": "0:1 0:3",
        "name_pl": "0:1 0:3",
        "name_ro": "0:1 0:3",
        "name_ru": "0:1 0:3",
        "name_tr": "0:1 0:3",
        "name_se": "0:1 0:3",
        "name_el": "0:1 0:3"
    },
    "354": {
        "name_en": "0:1 1:1",
        "name_aa": "0:1 1:1",
        "name_de": "0:1 1:1",
        "name_nl": "0:1 1:1",
        "name_pl": "0:1 1:1",
        "name_ro": "0:1 1:1",
        "name_ru": "0:1 1:1",
        "name_tr": "0:1 1:1",
        "name_se": "0:1 1:1",
        "name_el": "0:1 1:1"
    },
    "356": {
        "name_en": "0:1 1:2",
        "name_aa": "0:1 1:2",
        "name_de": "0:1 1:2",
        "name_nl": "0:1 1:2",
        "name_pl": "0:1 1:2",
        "name_ro": "0:1 1:2",
        "name_ru": "0:1 1:2",
        "name_tr": "0:1 1:2",
        "name_se": "0:1 1:2",
        "name_el": "0:1 1:2"
    },
    "358": {
        "name_en": "0:1 2:1",
        "name_aa": "0:1 2:1",
        "name_de": "0:1 2:1",
        "name_nl": "0:1 2:1",
        "name_pl": "0:1 2:1",
        "name_ro": "0:1 2:1",
        "name_ru": "0:1 2:1",
        "name_tr": "0:1 2:1",
        "name_se": "0:1 2:1",
        "name_el": "0:1 2:1"
    },
    "360": {
        "name_en": "0:1 4+",
        "name_aa": "0:1 4+",
        "name_de": "0:1 4+",
        "name_nl": "0:1 4+",
        "name_pl": "0:1 4+",
        "name_ro": "0:1 4+",
        "name_ru": "0:1 4+",
        "name_tr": "0:1 4+",
        "name_se": "0:1 4+",
        "name_el": "0:1 4+"
    },
    "362": {
        "name_en": "0:2 0:2",
        "name_aa": "0:2 0:2",
        "name_de": "0:2 0:2",
        "name_nl": "0:2 0:2",
        "name_pl": "0:2 0:2",
        "name_ro": "0:2 0:2",
        "name_ru": "0:2 0:2",
        "name_tr": "0:2 0:2",
        "name_se": "0:2 0:2",
        "name_el": "0:2 0:2"
    },
    "364": {
        "name_en": "0:2 0:3",
        "name_aa": "0:2 0:3",
        "name_de": "0:2 0:3",
        "name_nl": "0:2 0:3",
        "name_pl": "0:2 0:3",
        "name_ro": "0:2 0:3",
        "name_ru": "0:2 0:3",
        "name_tr": "0:2 0:3",
        "name_se": "0:2 0:3",
        "name_el": "0:2 0:3"
    },
    "366": {
        "name_en": "0:2 1:2",
        "name_aa": "0:2 1:2",
        "name_de": "0:2 1:2",
        "name_nl": "0:2 1:2",
        "name_pl": "0:2 1:2",
        "name_ro": "0:2 1:2",
        "name_ru": "0:2 1:2",
        "name_tr": "0:2 1:2",
        "name_se": "0:2 1:2",
        "name_el": "0:2 1:2"
    },
    "368": {
        "name_en": "0:2 4+",
        "name_aa": "0:2 4+",
        "name_de": "0:2 4+",
        "name_nl": "0:2 4+",
        "name_pl": "0:2 4+",
        "name_ro": "0:2 4+",
        "name_ru": "0:2 4+",
        "name_tr": "0:2 4+",
        "name_se": "0:2 4+",
        "name_el": "0:2 4+"
    },
    "370": {
        "name_en": "0:3 0:3",
        "name_aa": "0:3 0:3",
        "name_de": "0:3 0:3",
        "name_nl": "0:3 0:3",
        "name_pl": "0:3 0:3",
        "name_ro": "0:3 0:3",
        "name_ru": "0:3 0:3",
        "name_tr": "0:3 0:3",
        "name_se": "0:3 0:3",
        "name_el": "0:3 0:3"
    },
    "372": {
        "name_en": "0:3 4+",
        "name_aa": "0:3 4+",
        "name_de": "0:3 4+",
        "name_nl": "0:3 4+",
        "name_pl": "0:3 4+",
        "name_ro": "0:3 4+",
        "name_ru": "0:3 4+",
        "name_tr": "0:3 4+",
        "name_se": "0:3 4+",
        "name_el": "0:3 4+"
    },
    "374": {
        "name_en": "1:0 1:0",
        "name_aa": "1:0 1:0",
        "name_de": "1:0 1:0",
        "name_nl": "1:0 1:0",
        "name_pl": "1:0 1:0",
        "name_ro": "1:0 1:0",
        "name_ru": "1:0 1:0",
        "name_tr": "1:0 1:0",
        "name_se": "1:0 1:0",
        "name_el": "1:0 1:0"
    },
    "376": {
        "name_en": "1:0 1:1",
        "name_aa": "1:0 1:1",
        "name_de": "1:0 1:1",
        "name_nl": "1:0 1:1",
        "name_pl": "1:0 1:1",
        "name_ro": "1:0 1:1",
        "name_ru": "1:0 1:1",
        "name_tr": "1:0 1:1",
        "name_se": "1:0 1:1",
        "name_el": "1:0 1:1"
    },
    "378": {
        "name_en": "1:0 1:2",
        "name_aa": "1:0 1:2",
        "name_de": "1:0 1:2",
        "name_nl": "1:0 1:2",
        "name_pl": "1:0 1:2",
        "name_ro": "1:0 1:2",
        "name_ru": "1:0 1:2",
        "name_tr": "1:0 1:2",
        "name_se": "1:0 1:2",
        "name_el": "1:0 1:2"
    },
    "380": {
        "name_en": "1:0 2:0",
        "name_aa": "1:0 2:0",
        "name_de": "1:0 2:0",
        "name_nl": "1:0 2:0",
        "name_pl": "1:0 2:0",
        "name_ro": "1:0 2:0",
        "name_ru": "1:0 2:0",
        "name_tr": "1:0 2:0",
        "name_se": "1:0 2:0",
        "name_el": "1:0 2:0"
    },
    "382": {
        "name_en": "1:0 2:1",
        "name_aa": "1:0 2:1",
        "name_de": "1:0 2:1",
        "name_nl": "1:0 2:1",
        "name_pl": "1:0 2:1",
        "name_ro": "1:0 2:1",
        "name_ru": "1:0 2:1",
        "name_tr": "1:0 2:1",
        "name_se": "1:0 2:1",
        "name_el": "1:0 2:1"
    },
    "384": {
        "name_en": "1:0 3:0",
        "name_aa": "1:0 3:0",
        "name_de": "1:0 3:0",
        "name_nl": "1:0 3:0",
        "name_pl": "1:0 3:0",
        "name_ro": "1:0 3:0",
        "name_ru": "1:0 3:0",
        "name_tr": "1:0 3:0",
        "name_se": "1:0 3:0",
        "name_el": "1:0 3:0"
    },
    "386": {
        "name_en": "1:0 4+",
        "name_aa": "1:0 4+",
        "name_de": "1:0 4+",
        "name_nl": "1:0 4+",
        "name_pl": "1:0 4+",
        "name_ro": "1:0 4+",
        "name_ru": "1:0 4+",
        "name_tr": "1:0 4+",
        "name_se": "1:0 4+",
        "name_el": "1:0 4+"
    },
    "388": {
        "name_en": "1:1 1:1",
        "name_aa": "1:1 1:1",
        "name_de": "1:1 1:1",
        "name_nl": "1:1 1:1",
        "name_pl": "1:1 1:1",
        "name_ro": "1:1 1:1",
        "name_ru": "1:1 1:1",
        "name_tr": "1:1 1:1",
        "name_se": "1:1 1:1",
        "name_el": "1:1 1:1"
    },
    "390": {
        "name_en": "1:1 1:2",
        "name_aa": "1:1 1:2",
        "name_de": "1:1 1:2",
        "name_nl": "1:1 1:2",
        "name_pl": "1:1 1:2",
        "name_ro": "1:1 1:2",
        "name_ru": "1:1 1:2",
        "name_tr": "1:1 1:2",
        "name_se": "1:1 1:2",
        "name_el": "1:1 1:2"
    },
    "392": {
        "name_en": "1:1 2:1",
        "name_aa": "1:1 2:1",
        "name_de": "1:1 2:1",
        "name_nl": "1:1 2:1",
        "name_pl": "1:1 2:1",
        "name_ro": "1:1 2:1",
        "name_ru": "1:1 2:1",
        "name_tr": "1:1 2:1",
        "name_se": "1:1 2:1",
        "name_el": "1:1 2:1"
    },
    "394": {
        "name_en": "1:1 4+",
        "name_aa": "1:1 4+",
        "name_de": "1:1 4+",
        "name_nl": "1:1 4+",
        "name_pl": "1:1 4+",
        "name_ro": "1:1 4+",
        "name_ru": "1:1 4+",
        "name_tr": "1:1 4+",
        "name_se": "1:1 4+",
        "name_el": "1:1 4+"
    },
    "396": {
        "name_en": "1:2 1:2",
        "name_aa": "1:2 1:2",
        "name_de": "1:2 1:2",
        "name_nl": "1:2 1:2",
        "name_pl": "1:2 1:2",
        "name_ro": "1:2 1:2",
        "name_ru": "1:2 1:2",
        "name_tr": "1:2 1:2",
        "name_se": "1:2 1:2",
        "name_el": "1:2 1:2"
    },
    "398": {
        "name_en": "1:2 4+",
        "name_aa": "1:2 4+",
        "name_de": "1:2 4+",
        "name_nl": "1:2 4+",
        "name_pl": "1:2 4+",
        "name_ro": "1:2 4+",
        "name_ru": "1:2 4+",
        "name_tr": "1:2 4+",
        "name_se": "1:2 4+",
        "name_el": "1:2 4+"
    },
    "400": {
        "name_en": "2:0 2:0",
        "name_aa": "2:0 2:0",
        "name_de": "2:0 2:0",
        "name_nl": "2:0 2:0",
        "name_pl": "2:0 2:0",
        "name_ro": "2:0 2:0",
        "name_ru": "2:0 2:0",
        "name_tr": "2:0 2:0",
        "name_se": "2:0 2:0",
        "name_el": "2:0 2:0"
    },
    "402": {
        "name_en": "2:0 2:1",
        "name_aa": "2:0 2:1",
        "name_de": "2:0 2:1",
        "name_nl": "2:0 2:1",
        "name_pl": "2:0 2:1",
        "name_ro": "2:0 2:1",
        "name_ru": "2:0 2:1",
        "name_tr": "2:0 2:1",
        "name_se": "2:0 2:1",
        "name_el": "2:0 2:1"
    },
    "404": {
        "name_en": "2:0 3:0",
        "name_aa": "2:0 3:0",
        "name_de": "2:0 3:0",
        "name_nl": "2:0 3:0",
        "name_pl": "2:0 3:0",
        "name_ro": "2:0 3:0",
        "name_ru": "2:0 3:0",
        "name_tr": "2:0 3:0",
        "name_se": "2:0 3:0",
        "name_el": "2:0 3:0"
    },
    "406": {
        "name_en": "2:0 4+",
        "name_aa": "2:0 4+",
        "name_de": "2:0 4+",
        "name_nl": "2:0 4+",
        "name_pl": "2:0 4+",
        "name_ro": "2:0 4+",
        "name_ru": "2:0 4+",
        "name_tr": "2:0 4+",
        "name_se": "2:0 4+",
        "name_el": "2:0 4+"
    },
    "408": {
        "name_en": "2:1 2:1",
        "name_aa": "2:1 2:1",
        "name_de": "2:1 2:1",
        "name_nl": "2:1 2:1",
        "name_pl": "2:1 2:1",
        "name_ro": "2:1 2:1",
        "name_ru": "2:1 2:1",
        "name_tr": "2:1 2:1",
        "name_se": "2:1 2:1",
        "name_el": "2:1 2:1"
    },
    "410": {
        "name_en": "2:1 4+",
        "name_aa": "2:1 4+",
        "name_de": "2:1 4+",
        "name_nl": "2:1 4+",
        "name_pl": "2:1 4+",
        "name_ro": "2:1 4+",
        "name_ru": "2:1 4+",
        "name_tr": "2:1 4+",
        "name_se": "2:1 4+",
        "name_el": "2:1 4+"
    },
    "412": {
        "name_en": "3:0 3:0",
        "name_aa": "3:0 3:0",
        "name_de": "3:0 3:0",
        "name_nl": "3:0 3:0",
        "name_pl": "3:0 3:0",
        "name_ro": "3:0 3:0",
        "name_ru": "3:0 3:0",
        "name_tr": "3:0 3:0",
        "name_se": "3:0 3:0",
        "name_el": "3:0 3:0"
    },
    "414": {
        "name_en": "3:0 4+",
        "name_aa": "3:0 4+",
        "name_de": "3:0 4+",
        "name_nl": "3:0 4+",
        "name_pl": "3:0 4+",
        "name_ro": "3:0 4+",
        "name_ru": "3:0 4+",
        "name_tr": "3:0 4+",
        "name_se": "3:0 4+",
        "name_el": "3:0 4+"
    },
    "416": {
        "name_en": "4+ 4+",
        "name_aa": "4+ 4+",
        "name_de": "4+ 4+",
        "name_nl": "4+ 4+",
        "name_pl": "4+ 4+",
        "name_ro": "4+ 4+",
        "name_ru": "4+ 4+",
        "name_tr": "4+ 4+",
        "name_se": "4+ 4+",
        "name_el": "4+ 4+"
    },
    "418": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1} / {$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}"
    },
    "420": {
        "name_en": "{$competitor1}/X",
        "name_aa": "{$competitor1} / رسم",
        "name_de": "{$competitor1}/X",
        "name_nl": "{$competitor1}/X",
        "name_pl": "{$competitor1}/remis",
        "name_ro": "{$competitor1}/egal",
        "name_ru": "{$competitor1}/ничья",
        "name_tr": "{$competitor1}/X",
        "name_se": "{$competitor1}/oavgjort",
        "name_el": "{$competitor1}/ισοπαλία"
    },
    "422": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1} / {$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}"
    },
    "424": {
        "name_en": "X/{$competitor1}",
        "name_aa": "رسم / {$competitor1}",
        "name_de": "X/{$competitor1}",
        "name_nl": "X/{$competitor1}",
        "name_pl": "remis/{$competitor1}",
        "name_ro": "egal/{$competitor1}",
        "name_ru": "ничья/{$competitor1}",
        "name_tr": "X/{$competitor1}",
        "name_se": "oavgjort/{$competitor1}",
        "name_el": "ισοπαλία/{$competitor1}"
    },
    "426": {
        "name_en": "X/X",
        "name_aa": "رسم / رسم",
        "name_de": "X / X",
        "name_nl": "X/X",
        "name_pl": "remis/remis",
        "name_ro": "egal/egal",
        "name_ru": "ничья/ничья",
        "name_tr": "X/X",
        "name_se": "oavgjort/oavgjort",
        "name_el": "ισοπαλία/ισοπαλία"
    },
    "428": {
        "name_en": "X/{$competitor2}",
        "name_aa": "رسم / {$competitor2}",
        "name_de": "X/{$competitor2}",
        "name_nl": "X/{$competitor2}",
        "name_pl": "remis/{$competitor2}",
        "name_ro": "egal/{$competitor2}",
        "name_ru": "ничья/{$competitor2}",
        "name_tr": "X/{$competitor2}",
        "name_se": "oavgjort/{$competitor2}",
        "name_el": "ισοπαλία/{$competitor2}"
    },
    "430": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2} / {$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}"
    },
    "432": {
        "name_en": "{$competitor2}/X",
        "name_aa": "{$competitor2} / رسم",
        "name_de": "{$competitor2}/X",
        "name_nl": "{$competitor2}/X",
        "name_pl": "{$competitor2}/remis",
        "name_ro": "{$competitor2}/egal",
        "name_ru": "{$competitor2}/ничья",
        "name_tr": "{$competitor2}/X",
        "name_se": "{$competitor2}/oavgjort",
        "name_el": "{$competitor2}/ισοπαλία"
    },
    "434": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2} / {$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}"
    },
    "436": {
        "name_en": "1st half",
        "name_aa": "النصف الأول",
        "name_de": "1. Halbzeit",
        "name_nl": "eerste helft",
        "name_pl": "1. Połowa",
        "name_ro": "Repriza 1",
        "name_ru": "1ая половина",
        "name_tr": "İlk yarı",
        "name_se": "1:a halvlek",
        "name_el": "1ο ημίχρονο"
    },
    "438": {
        "name_en": "2nd half",
        "name_aa": "النصف الثاني",
        "name_de": "2. Halbzeit",
        "name_nl": "tweede helft",
        "name_pl": "2. Połowa",
        "name_ro": "Repriza 2",
        "name_ru": "2ая половина",
        "name_tr": "İkinci yarı",
        "name_se": "2:a halvlek",
        "name_el": "2ο ημίχρονο"
    },
    "440": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός"
    },
    "442": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "444": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "446": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "448": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "450": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "452": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "454": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "456": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "458": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "460": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "462": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "464": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "466": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "468": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "470": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "472": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "474": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "476": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "478": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "480": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "546": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "548": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "550": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "552": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "554": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "556": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "558": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "560": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "562": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "564": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "584": {
        "name_en": "1-15",
        "name_aa": "1-15",
        "name_de": "1-15",
        "name_nl": "1-15",
        "name_pl": "1-15",
        "name_ro": "1-15",
        "name_ru": "1-15",
        "name_tr": "1-15",
        "name_se": "1-15",
        "name_el": "1-15"
    },
    "586": {
        "name_en": "16-30",
        "name_aa": "16-30",
        "name_de": "16-30",
        "name_nl": "16-30",
        "name_pl": "16-30",
        "name_ro": "16-30",
        "name_ru": "16-30",
        "name_tr": "16-30",
        "name_se": "16-30",
        "name_el": "16-30"
    },
    "588": {
        "name_en": "31-45",
        "name_aa": "31-45",
        "name_de": "31-45",
        "name_nl": "31-45",
        "name_pl": "31-45",
        "name_ro": "31-45",
        "name_ru": "31-45",
        "name_tr": "31-45",
        "name_se": "31-45",
        "name_el": "31-45"
    },
    "590": {
        "name_en": "46-60",
        "name_aa": "46-60",
        "name_de": "46-60",
        "name_nl": "46-60",
        "name_pl": "46-60",
        "name_ro": "46-60",
        "name_ru": "46-60",
        "name_tr": "46-60",
        "name_se": "46-60",
        "name_el": "46-60"
    },
    "592": {
        "name_en": "61-75",
        "name_aa": "61-75",
        "name_de": "61-75",
        "name_nl": "61-75",
        "name_pl": "61-75",
        "name_ro": "61-75",
        "name_ru": "61-75",
        "name_tr": "61-75",
        "name_se": "61-75",
        "name_el": "61-75"
    },
    "594": {
        "name_en": "76-90",
        "name_aa": "76-90",
        "name_de": "76-90",
        "name_nl": "76-90",
        "name_pl": "76-90",
        "name_ro": "76-90",
        "name_ru": "76-90",
        "name_tr": "76-90",
        "name_se": "76-90",
        "name_el": "76-90"
    },
    "596": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "не будет",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα"
    },
    "598": {
        "name_en": "1-10",
        "name_aa": "1-10",
        "name_de": "1-10",
        "name_nl": "1-10",
        "name_pl": "1-10",
        "name_ro": "1-10",
        "name_ru": "1-10",
        "name_tr": "1-10",
        "name_se": "1-10",
        "name_el": "1-10"
    },
    "600": {
        "name_en": "11-20",
        "name_aa": "11-20",
        "name_de": "11-20",
        "name_nl": "11-20",
        "name_pl": "11-20",
        "name_ro": "11-20",
        "name_ru": "11-20",
        "name_tr": "11-20",
        "name_se": "11-20",
        "name_el": "11-20"
    },
    "602": {
        "name_en": "21-30",
        "name_aa": "21-30",
        "name_de": "21-30",
        "name_nl": "21-30",
        "name_pl": "21-30",
        "name_ro": "21-30",
        "name_ru": "21-30",
        "name_tr": "21-30",
        "name_se": "21-30",
        "name_el": "21-30"
    },
    "604": {
        "name_en": "31-40",
        "name_aa": "31-40",
        "name_de": "31-40",
        "name_nl": "31-40",
        "name_pl": "31-40",
        "name_ro": "31-40",
        "name_ru": "31-40",
        "name_tr": "31-40",
        "name_se": "31-40",
        "name_el": "31-40"
    },
    "606": {
        "name_en": "41-50",
        "name_aa": "41-50",
        "name_de": "41-50",
        "name_nl": "41-50",
        "name_pl": "41-50",
        "name_ro": "41-50",
        "name_ru": "41-50",
        "name_tr": "41-50",
        "name_se": "41-50",
        "name_el": "41-50"
    },
    "608": {
        "name_en": "51-60",
        "name_aa": "51-60",
        "name_de": "51-60",
        "name_nl": "51-60",
        "name_pl": "51-60",
        "name_ro": "51-60",
        "name_ru": "51-60",
        "name_tr": "51-60",
        "name_se": "51-60",
        "name_el": "51-60"
    },
    "610": {
        "name_en": "61-70",
        "name_aa": "61-70",
        "name_de": "61-70",
        "name_nl": "61-70",
        "name_pl": "61-70",
        "name_ro": "61-70",
        "name_ru": "61-70",
        "name_tr": "61-70",
        "name_se": "61-70",
        "name_el": "61-70"
    },
    "612": {
        "name_en": "71-80",
        "name_aa": "71-80",
        "name_de": "71-80",
        "name_nl": "71-80",
        "name_pl": "71-80",
        "name_ro": "71-80",
        "name_ru": "71-80",
        "name_tr": "71-80",
        "name_se": "71-80",
        "name_el": "71-80"
    },
    "614": {
        "name_en": "81-90",
        "name_aa": "81-90",
        "name_de": "81-90",
        "name_nl": "81-90",
        "name_pl": "81-90",
        "name_ro": "81-90",
        "name_ru": "81-90",
        "name_tr": "81-90",
        "name_se": "81-90",
        "name_el": "81-90"
    },
    "616": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "garnicht",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "не будет",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα"
    },
    "618": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "620": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "622": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "624": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "626": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "628": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "630": {
        "name_en": "{$competitor1} by 1",
        "name_aa": "{$competitor1} في 1",
        "name_de": "{$competitor1} mit 1",
        "name_nl": "{$competitor1} met 1",
        "name_pl": "{$competitor1} 1-ym",
        "name_ro": "{$competitor1} cu 1",
        "name_ru": "{$competitor1} на 1",
        "name_tr": "{$competitor1} 1 farkla",
        "name_se": "{$competitor1} med 1",
        "name_el": "{$competitor1} με 1"
    },
    "632": {
        "name_en": "{$competitor1} by 2",
        "name_aa": "{$competitor1} في 2",
        "name_de": "{$competitor1} mit 2",
        "name_nl": "{$competitor1} met 2",
        "name_pl": "{$competitor1} 2-a",
        "name_ro": "{$competitor1} cu 2",
        "name_ru": "{$competitor1} на 2",
        "name_tr": "{$competitor1} 2 farkla",
        "name_se": "{$competitor1} med 2",
        "name_el": "{$competitor1} με 2"
    },
    "634": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} في 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} 3+",
        "name_ro": "{$competitor1} cu 3+",
        "name_ru": "{$competitor1} на 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} med 3+",
        "name_el": "{$competitor1} με 3+"
    },
    "636": {
        "name_en": "{$competitor2} by 1",
        "name_aa": "{$competitor2} في 1",
        "name_de": "{$competitor2} mit 1",
        "name_nl": "{$competitor2} met 1",
        "name_pl": "{$competitor2} 1-ym",
        "name_ro": "{$competitor2} cu 1",
        "name_ru": "{$competitor2} на 1",
        "name_tr": "{$competitor2} 1 farkla",
        "name_se": "{$competitor2} med 1",
        "name_el": "{$competitor2} με 1"
    },
    "638": {
        "name_en": "{$competitor2} by 2",
        "name_aa": "{$competitor2} في 2",
        "name_de": "{$competitor2} mit 2",
        "name_nl": "{$competitor2} met 2",
        "name_pl": "{$competitor2} 2-a",
        "name_ro": "{$competitor2} cu 2",
        "name_ru": "{$competitor2} на 2",
        "name_tr": "{$competitor2} 2 farkla",
        "name_se": "{$competitor2} med 2",
        "name_el": "{$competitor2} με 2"
    },
    "640": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} في 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} 3+",
        "name_ro": "{$competitor2} cu 3+",
        "name_ru": "{$competitor2} на 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} med 3+",
        "name_el": "{$competitor2} με 3+"
    },
    "656": {
        "name_en": "{$competitor1} & under {total}",
        "name_aa": "{$competitor1} و تحت {total}",
        "name_de": "{$competitor1} & unter {total}",
        "name_nl": "{$competitor1} & onder {total}",
        "name_pl": "{$competitor1} & poniżej {total}",
        "name_ro": "{$competitor1} & sub {total}",
        "name_ru": "{$competitor1} & меньше {total}",
        "name_tr": "{$competitor1} & {total} altı",
        "name_se": "{$competitor1} & under {total}",
        "name_el": "{$competitor1} και under {total}"
    },
    "658": {
        "name_en": "{$competitor2} & under {total}",
        "name_aa": "{$competitor2} و تحت {total}",
        "name_de": "{$competitor2} & unter {total}",
        "name_nl": "{$competitor2} & onder {total}",
        "name_pl": "{$competitor2} & poniżej {total}",
        "name_ro": "{$competitor2} & sub {total}",
        "name_ru": "{$competitor2} & меньше {total}",
        "name_tr": "{$competitor2} & {total} altı",
        "name_se": "{$competitor2} & under {total}",
        "name_el": "{$competitor2} και under {total}"
    },
    "660": {
        "name_en": "{$competitor1} & over {total}",
        "name_aa": "{$competitor1} وأكثر من {total}",
        "name_de": "{$competitor1} & über {total}",
        "name_nl": "{$competitor1} & boven {total}",
        "name_pl": "{$competitor1} & ponad {total}",
        "name_ro": "{$competitor1} & peste {total}",
        "name_ru": "{$competitor1} & больше {total}",
        "name_tr": "{$competitor1} & {total} üstü",
        "name_se": "{$competitor1} & över {total}",
        "name_el": "{$competitor1} και over {total}"
    },
    "662": {
        "name_en": "{$competitor2} & over {total}",
        "name_aa": "{$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2} & über {total}",
        "name_nl": "{$competitor2} & boven {total}",
        "name_pl": "{$competitor2} & ponad {total}",
        "name_ro": "{$competitor2} & peste {total}",
        "name_ru": "{$competitor2} & больше {total}",
        "name_tr": "{$competitor2} & {total} üstü",
        "name_se": "{$competitor2} & över {total}",
        "name_el": "{$competitor2} και over {total}"
    },
    "710": {
        "name_en": "0-3",
        "name_aa": "0-3",
        "name_de": "0-3",
        "name_nl": "0-3",
        "name_pl": "0-3",
        "name_ro": "0-3",
        "name_ru": "0-3",
        "name_tr": "0-3",
        "name_se": "0-3",
        "name_el": "0-3"
    },
    "712": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "714": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5"
    },
    "716": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6"
    },
    "718": {
        "name_en": "7",
        "name_aa": "7",
        "name_de": "7",
        "name_nl": "7",
        "name_pl": "7",
        "name_ro": "7",
        "name_ru": "7",
        "name_tr": "7",
        "name_se": "7",
        "name_el": "7"
    },
    "720": {
        "name_en": "8",
        "name_aa": "8",
        "name_de": "8",
        "name_nl": "8",
        "name_pl": "8",
        "name_ro": "8",
        "name_ru": "8",
        "name_tr": "8",
        "name_se": "8",
        "name_el": "8"
    },
    "722": {
        "name_en": "9",
        "name_aa": "9",
        "name_de": "9",
        "name_nl": "9",
        "name_pl": "9",
        "name_ro": "9",
        "name_ru": "9",
        "name_tr": "9",
        "name_se": "9",
        "name_el": "9"
    },
    "724": {
        "name_en": "10",
        "name_aa": "10",
        "name_de": "10",
        "name_nl": "10",
        "name_pl": "10",
        "name_ro": "10",
        "name_ru": "10",
        "name_tr": "10",
        "name_se": "10",
        "name_el": "10"
    },
    "726": {
        "name_en": "11",
        "name_aa": "11",
        "name_de": "11",
        "name_nl": "11",
        "name_pl": "11",
        "name_ro": "11",
        "name_ru": "11",
        "name_tr": "11",
        "name_se": "11",
        "name_el": "11"
    },
    "728": {
        "name_en": "12+",
        "name_aa": "12+",
        "name_de": "12+",
        "name_nl": "12+",
        "name_pl": "12+",
        "name_ro": "12+",
        "name_ru": "12+",
        "name_tr": "12+",
        "name_se": "12+",
        "name_el": "12+"
    },
    "730": {
        "name_en": "0-1",
        "name_aa": "0-1",
        "name_de": "0-1",
        "name_nl": "0-1",
        "name_pl": "0-1",
        "name_ro": "0-1",
        "name_ru": "0-1",
        "name_tr": "0-1",
        "name_se": "0-1",
        "name_el": "0-1"
    },
    "732": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "734": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "736": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+"
    },
    "776": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "778": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "780": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "782": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "784": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "keiner",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciuna",
        "name_ru": "ни одна",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "καμία"
    },
    "788": {
        "name_en": "only {$competitor1}",
        "name_aa": "فقط {$competitor1}",
        "name_de": "nur {$competitor1}",
        "name_nl": "alleen {$competitor1}",
        "name_pl": "tylko {$competitor1}",
        "name_ro": "doar {$competitor1}",
        "name_ru": "только {$competitor1}",
        "name_tr": "sadece {$competitor1}",
        "name_se": "endast {$competitor1}",
        "name_el": "μόνο {$competitor1}"
    },
    "790": {
        "name_en": "only {$competitor2}",
        "name_aa": "فقط {$competitor2}",
        "name_de": "nur {$competitor2}",
        "name_nl": "alleen {$competitor2}",
        "name_pl": "tylko {$competitor2}",
        "name_ro": "doar {$competitor2}",
        "name_ru": "только {$competitor2}",
        "name_tr": "sadece {$competitor2}",
        "name_se": "endast {$competitor2}",
        "name_el": "μόνο {$competitor2}"
    },
    "792": {
        "name_en": "both teams",
        "name_aa": "كلا الفريقين",
        "name_de": "beide Mannschaften",
        "name_nl": "beide teams",
        "name_pl": "oba zespoły",
        "name_ro": "ambele echipe",
        "name_ru": "обе команды",
        "name_tr": "her iki takım da",
        "name_se": "båda lagen",
        "name_el": "και οι 2 ομάδες"
    },
    "794": {
        "name_en": "{$competitor1} & under {total}",
        "name_aa": "{$competitor1} و تحت {total}",
        "name_de": "{$competitor1} & unter {total}",
        "name_nl": "{$competitor1} & onder {total}",
        "name_pl": "{$competitor1} & poniżej {total}",
        "name_ro": "{$competitor1} & sub {total}",
        "name_ru": "{$competitor1} & меньше {total}",
        "name_tr": "{$competitor1} & {total} altı",
        "name_se": "{$competitor1} & under {total}",
        "name_el": "{$competitor1} και under  {total}"
    },
    "796": {
        "name_en": "{$competitor1} & over {total}",
        "name_aa": "{$competitor1} وأكثر من {total}",
        "name_de": "{$competitor1} & über {total}",
        "name_nl": "{$competitor1} & boven {total}",
        "name_pl": "{$competitor1} & ponad {total}",
        "name_ro": "{$competitor1} & peste {total}",
        "name_ru": "{$competitor1} & больше {total}",
        "name_tr": "{$competitor1} & {total} üstü",
        "name_se": "{$competitor1} & över {total}",
        "name_el": "{$competitor1} και over  {total}"
    },
    "798": {
        "name_en": "X & under {total}",
        "name_aa": "ارسم و تحت {total}",
        "name_de": "X & unter {total}",
        "name_nl": "X & onder {total}",
        "name_pl": "remis & poniżej {total}",
        "name_ro": "egal & sub {total}",
        "name_ru": "ничья & меньше {total}",
        "name_tr": "X & {total} altı",
        "name_se": "oavgjort & under {total}",
        "name_el": "ισοπαλία και under {total}"
    },
    "800": {
        "name_en": "X & over {total}",
        "name_aa": "السحب والإسقاط {total}",
        "name_de": "X & über {total}",
        "name_nl": "X & boven {total}",
        "name_pl": "remis & ponad {total}",
        "name_ro": "egal & peste {total}",
        "name_ru": "ничья & больше {total}",
        "name_tr": "X & {total} üstü",
        "name_se": "oavgjort & över {total}",
        "name_el": "ισοπαλία και over {total}"
    },
    "802": {
        "name_en": "{$competitor2} & under {total}",
        "name_aa": "{$competitor2} و تحت {total}",
        "name_de": "{$competitor2} & unter {total}",
        "name_nl": "{$competitor2} & onder {total}",
        "name_pl": "{$competitor2} & poniżej {total}",
        "name_ro": "{$competitor2} & sub {total}",
        "name_ru": "{$competitor2} & меньше {total}",
        "name_tr": "{$competitor2} & {total} altı",
        "name_se": "{$competitor2} & under {total}",
        "name_el": "{$competitor2} και under  {total}"
    },
    "804": {
        "name_en": "{$competitor2} & over {total}",
        "name_aa": "{$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2} & über {total}",
        "name_nl": "{$competitor2} & boven {total}",
        "name_pl": "{$competitor2} & ponad {total}",
        "name_ro": "{$competitor2} & peste {total}",
        "name_ru": "{$competitor2} & больше {total}",
        "name_tr": "{$competitor2} & {total} üstü",
        "name_se": "{$competitor2} & över {total}",
        "name_el": "{$competitor2} και over  {total}"
    },
    "806": {
        "name_en": "no/no",
        "name_aa": "لا / لا",
        "name_de": "nein/nein",
        "name_nl": "nee/nee",
        "name_pl": "nie/nie",
        "name_ro": "nu/nu",
        "name_ru": "нет/нет",
        "name_tr": "yok/yok",
        "name_se": "nej/nej",
        "name_el": "όχι/όχι"
    },
    "808": {
        "name_en": "yes/no",
        "name_aa": "نعم / لا",
        "name_de": "ja/nein",
        "name_nl": "ja/nee",
        "name_pl": "tak/nie",
        "name_ro": "da/nu",
        "name_ru": "да/нет",
        "name_tr": "var/yok",
        "name_se": "ja/nej",
        "name_el": "ναι/όχι"
    },
    "810": {
        "name_en": "yes/yes",
        "name_aa": "نعم / نعم",
        "name_de": "ja/ja",
        "name_nl": "ja/ja",
        "name_pl": "tak/tak",
        "name_ro": "da/da",
        "name_ru": "да/да",
        "name_tr": "var/var",
        "name_se": "ja/ja",
        "name_el": "ναι/ναι"
    },
    "812": {
        "name_en": "no/yes",
        "name_aa": "لا / نعم",
        "name_de": "nein/ja",
        "name_nl": "nee/ja",
        "name_pl": "nie/tak",
        "name_ro": "nu/da",
        "name_ru": "нет/да",
        "name_tr": "yok/var",
        "name_se": "nej/ja",
        "name_el": "όχι/ναι"
    },
    "814": {
        "name_en": "{$competitor1} goal & {$competitor1}",
        "name_aa": "{$competitor1} هدف و{$competitor1}",
        "name_de": "{$competitor1} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor1} & {$competitor1}",
        "name_pl": "{$competitor1} Gol & {$competitor1}",
        "name_ro": "gol {$competitor1} & {$competitor1}",
        "name_ru": "гол {$competitor1} &  {$competitor1}",
        "name_tr": "{$competitor1} golü & {$competitor1} kazanır",
        "name_se": "{$competitor1} mål & {$competitor1}",
        "name_el": "γκολ {$competitor1} και {$competitor1}"
    },
    "816": {
        "name_en": "{$competitor1} goal & X",
        "name_aa": "{$competitor1} هدف وارسم",
        "name_de": "{$competitor1} Tor & X",
        "name_nl": "doelpunt {$competitor1} & X",
        "name_pl": "{$competitor1} Gol & remis",
        "name_ro": "gol {$competitor1} & egal",
        "name_ru": "гол {$competitor1} & ничья",
        "name_tr": "{$competitor1} golü & X",
        "name_se": "{$competitor1} mål & oavgjort",
        "name_el": "γκολ {$competitor1} και ισοπαλία"
    },
    "818": {
        "name_en": "{$competitor1} goal & {$competitor2}",
        "name_aa": "{$competitor1} هدف و{$competitor2}",
        "name_de": "{$competitor1} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor1} & {$competitor2}",
        "name_pl": "{$competitor1} Gol & {$competitor2}",
        "name_ro": "gol {$competitor1} & {$competitor2}",
        "name_ru": "гол {$competitor1} & {$competitor2}",
        "name_tr": "{$competitor1} golü & {$competitor2} kazanır",
        "name_se": "{$competitor1} mål & {$competitor2}",
        "name_el": "γκολ {$competitor1} και {$competitor2}"
    },
    "820": {
        "name_en": "{$competitor2} goal & {$competitor1}",
        "name_aa": "{$competitor2} هدف و{$competitor1}",
        "name_de": "{$competitor2} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor2} & {$competitor1}",
        "name_pl": "{$competitor2} Gol & {$competitor1}",
        "name_ro": "gol {$competitor2} & {$competitor1}",
        "name_ru": "гол {$competitor2} & {$competitor1}",
        "name_tr": "{$competitor2} golü & {$competitor1} kazanır",
        "name_se": "{$competitor2} mål & {$competitor1}",
        "name_el": "γκολ {$competitor2} και {$competitor1}"
    },
    "822": {
        "name_en": "{$competitor2} goal & X",
        "name_aa": "{$competitor2} هدف وارسم",
        "name_de": "{$competitor2} Tor & X",
        "name_nl": "doelpunt {$competitor2} & X",
        "name_pl": "{$competitor2} Gol & remis",
        "name_ro": "gol {$competitor2} & egal",
        "name_ru": "гол {$competitor2} & ничья",
        "name_tr": "{$competitor2} golü & X",
        "name_se": "{$competitor2} mål & oavgjort",
        "name_el": "γκολ {$competitor2} και ισοπαλία"
    },
    "824": {
        "name_en": "{$competitor2} goal & {$competitor2}",
        "name_aa": "{$competitor2} هدف و{$competitor2}",
        "name_de": "{$competitor2} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor2} & {$competitor2}",
        "name_pl": "{$competitor2} Gol & {$competitor2}",
        "name_ro": "gol {$competitor2} & {$competitor2}",
        "name_ru": "гол {$competitor2} &  {$competitor2}",
        "name_tr": "{$competitor2} golü & {$competitor2} kazanır",
        "name_se": "{$competitor2} mål & {$competitor2}",
        "name_el": "γκολ {$competitor2} και {$competitor2}"
    },
    "826": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ"
    },
    "861": {
        "name_en": "{$competitor1}/{$competitor1}",
        "name_aa": "{$competitor1} / {$competitor1}",
        "name_de": "{$competitor1}/{$competitor1}",
        "name_nl": "{$competitor1}/{$competitor1}",
        "name_pl": "{$competitor1}/{$competitor1}",
        "name_ro": "{$competitor1}/{$competitor1}",
        "name_ru": "{$competitor1}/{$competitor1}",
        "name_tr": "{$competitor1}/{$competitor1}",
        "name_se": "{$competitor1}/{$competitor1}",
        "name_el": "{$competitor1}/{$competitor1}"
    },
    "862": {
        "name_en": "{$competitor2}/{$competitor1}",
        "name_aa": "{$competitor2} / {$competitor1}",
        "name_de": "{$competitor2}/{$competitor1}",
        "name_nl": "{$competitor2}/{$competitor1}",
        "name_pl": "{$competitor2}/{$competitor1}",
        "name_ro": "{$competitor2}/{$competitor1}",
        "name_ru": "{$competitor2}/{$competitor1}",
        "name_tr": "{$competitor2}/{$competitor1}",
        "name_se": "{$competitor2}/{$competitor1}",
        "name_el": "{$competitor2}/{$competitor1}"
    },
    "863": {
        "name_en": "{$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1} / {$competitor2}",
        "name_de": "{$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2}"
    },
    "864": {
        "name_en": "{$competitor2}/{$competitor2}",
        "name_aa": "{$competitor2} / {$competitor2}",
        "name_de": "{$competitor2}/{$competitor2}",
        "name_nl": "{$competitor2}/{$competitor2}",
        "name_pl": "{$competitor2}/{$competitor2}",
        "name_ro": "{$competitor2}/{$competitor2}",
        "name_ru": "{$competitor2}/{$competitor2}",
        "name_tr": "{$competitor2}/{$competitor2}",
        "name_se": "{$competitor2}/{$competitor2}",
        "name_el": "{$competitor2}/{$competitor2}"
    },
    "865": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0"
    },
    "866": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1"
    },
    "867": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2"
    },
    "868": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3"
    },
    "869": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4"
    },
    "870": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5"
    },
    "871": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6"
    },
    "872": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6"
    },
    "873": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6"
    },
    "874": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6"
    },
    "875": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6"
    },
    "876": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6"
    },
    "877": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7"
    },
    "878": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7"
    },
    "882": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "883": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5"
    },
    "884": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6"
    },
    "885": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+"
    },
    "886": {
        "name_en": "{$competitor1} to 0",
        "name_aa": "{$competitor1} إلى 0",
        "name_de": "{$competitor1} zu 0",
        "name_nl": "{$competitor1} tot 0",
        "name_pl": "{$competitor1} do 0",
        "name_ro": "{$competitor1} la 0",
        "name_ru": "{$competitor1} до 0",
        "name_tr": "{$competitor1} 0'a karşı",
        "name_se": "{$competitor1} till 0",
        "name_el": "{$competitor1} 0"
    },
    "887": {
        "name_en": "{$competitor1} to 15",
        "name_aa": "{$competitor1} إلى 15",
        "name_de": "{$competitor1} zu 15",
        "name_nl": "{$competitor1} tot 15",
        "name_pl": "{$competitor1} do 15",
        "name_ro": "{$competitor1} la 15",
        "name_ru": "{$competitor1} до 15",
        "name_tr": "{$competitor1} 15'a karşı",
        "name_se": "{$competitor1} till 15",
        "name_el": "{$competitor1} 15"
    },
    "888": {
        "name_en": "{$competitor1} to 30",
        "name_aa": "{$competitor1} إلى 30",
        "name_de": "{$competitor1} zu 30",
        "name_nl": "{$competitor1} tot 30",
        "name_pl": "{$competitor1} do 30",
        "name_ro": "{$competitor1} la 30",
        "name_ru": "{$competitor1} до 30",
        "name_tr": "{$competitor1} 30'a karşı",
        "name_se": "{$competitor1} till 30",
        "name_el": "{$competitor1} 30"
    },
    "889": {
        "name_en": "{$competitor1} to 40",
        "name_aa": "{$competitor1} إلى 40",
        "name_de": "{$competitor1} zu 40",
        "name_nl": "{$competitor1} tot 40",
        "name_pl": "{$competitor1} do 40",
        "name_ro": "{$competitor1} la 40",
        "name_ru": "{$competitor1} до 40",
        "name_tr": "{$competitor1} 40'a karşı",
        "name_se": "{$competitor1} till 40",
        "name_el": "{$competitor1} 40"
    },
    "890": {
        "name_en": "{$competitor2} to 0",
        "name_aa": "{$competitor2} إلى 0",
        "name_de": "{$competitor2} zu 0",
        "name_nl": "{$competitor2} tot 0",
        "name_pl": "{$competitor2} do 0",
        "name_ro": "{$competitor2} la 0",
        "name_ru": "{$competitor2} до 0",
        "name_tr": "{$competitor2} 0'a karşı",
        "name_se": "{$competitor2} till 0",
        "name_el": "{$competitor2} 0"
    },
    "891": {
        "name_en": "{$competitor2} to 15",
        "name_aa": "{$competitor2} إلى 15",
        "name_de": "{$competitor2} zu 15",
        "name_nl": "{$competitor2} tot 15",
        "name_pl": "{$competitor2} do 15",
        "name_ro": "{$competitor2} la 15",
        "name_ru": "{$competitor2} до 15",
        "name_tr": "{$competitor2} 15'a karşı",
        "name_se": "{$competitor2} till 15",
        "name_el": "{$competitor2} 15"
    },
    "892": {
        "name_en": "{$competitor2} to 30",
        "name_aa": "{$competitor2} إلى 30",
        "name_de": "{$competitor2} zu 30",
        "name_nl": "{$competitor2} tot 30",
        "name_pl": "{$competitor2} do 30",
        "name_ro": "{$competitor2} la 30",
        "name_ru": "{$competitor2} до 30",
        "name_tr": "{$competitor2} 30'a karşı",
        "name_se": "{$competitor2} till 30",
        "name_el": "{$competitor2} 30"
    },
    "893": {
        "name_en": "{$competitor2} to 40",
        "name_aa": "{$competitor2} إلى 40",
        "name_de": "{$competitor2} zu 40",
        "name_nl": "{$competitor2} tot 40",
        "name_pl": "{$competitor2} do 40",
        "name_ro": "{$competitor2} la 40",
        "name_ru": "{$competitor2} до 40",
        "name_tr": "{$competitor2} 40'a karşı",
        "name_se": "{$competitor2} till 40",
        "name_el": "{$competitor2} 40"
    },
    "894": {
        "name_en": "{%server} to 0",
        "name_aa": "{!server} إلى 0",
        "name_de": "{!server} zu 0",
        "name_nl": "{!server} tot 0",
        "name_pl": "{!server} do 0",
        "name_ro": "{!server} la 0",
        "name_ru": "{!server} до 0",
        "name_tr": "{!server} 'e 0",
        "name_se": "{!server} till 0",
        "name_el": "{!server} 0"
    },
    "895": {
        "name_en": "{%server} to 15",
        "name_aa": "{!server} إلى 15",
        "name_de": "{!server} zu 15",
        "name_nl": "{!server} tot 15",
        "name_pl": "{!server} do 15",
        "name_ro": "{!server} la 15",
        "name_ru": "{!server} до 15",
        "name_tr": "{!server} 'e 15",
        "name_se": "{!server} till 15",
        "name_el": "{!server} 15"
    },
    "896": {
        "name_en": "{%server} to 30",
        "name_aa": "{!server} إلى 30",
        "name_de": "{!server} zu 30",
        "name_nl": "{!server} tot 30",
        "name_pl": "{!server} do 30",
        "name_ro": "{!server} la 30",
        "name_ru": "{!server} до 30",
        "name_tr": "{!server} 'e 30",
        "name_se": "{!server} till 30",
        "name_el": "{!server} 30"
    },
    "897": {
        "name_en": "{%server} to 40",
        "name_aa": "{!server} إلى 40",
        "name_de": "{!server} zu 40",
        "name_nl": "{!server} tot 40",
        "name_pl": "{!server} do 40",
        "name_ro": "{!server} la 40",
        "name_ru": "{!server} до 40",
        "name_tr": "{!server} 'e 40",
        "name_se": "{!server} till 40",
        "name_el": "{!server} 40"
    },
    "898": {
        "name_en": "break",
        "name_aa": "استراحة",
        "name_de": "Break",
        "name_nl": "break",
        "name_pl": "break",
        "name_ro": "break",
        "name_ru": "брейк",
        "name_tr": "mola",
        "name_se": "break",
        "name_el": "μπρέικ"
    },
    "901": {
        "name_en": "touchdown",
        "name_aa": "لمس أرض",
        "name_de": "Touchdown",
        "name_nl": "touchdown",
        "name_pl": "Touchdown",
        "name_ro": "touchdown",
        "name_ru": "тачдаун",
        "name_tr": "gol",
        "name_se": "touchdown",
        "name_el": "τατσντάουν"
    },
    "902": {
        "name_en": "fieldgoal",
        "name_aa": "هدف ميداني",
        "name_de": "Fieldgoal",
        "name_nl": "veldgoal",
        "name_pl": "Fieldgoal",
        "name_ro": "fieldgoal",
        "name_ru": "гол в ворота",
        "name_tr": "alan golü",
        "name_se": "fieldgoal",
        "name_el": "fieldgoal"
    },
    "903": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другое",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "904": {
        "name_en": "none",
        "name_aa": "لا شيء",
        "name_de": "keiner",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciunul",
        "name_ru": "ничего",
        "name_tr": "Gol olmaz",
        "name_se": "ingen",
        "name_el": "κανένα"
    },
    "920": {
        "name_en": "1st quarter",
        "name_aa": "الربع الاول",
        "name_de": "1. Viertel",
        "name_nl": "Eerste kwart",
        "name_pl": "1. kwarta",
        "name_ro": "Sfertul 1",
        "name_ru": "1ая четверть",
        "name_tr": "İlk çeyrek",
        "name_se": "1:a quarter",
        "name_el": "1η περίοδος"
    },
    "921": {
        "name_en": "2nd quarter",
        "name_aa": "الربع الثاني",
        "name_de": "2. Viertel",
        "name_nl": "Tweede kwart",
        "name_pl": "2. kwarta",
        "name_ro": "Sfertul 2",
        "name_ru": "2ая четверть",
        "name_tr": "İkinci çeyrek",
        "name_se": "2:a quarter",
        "name_el": "2η περίοδος"
    },
    "922": {
        "name_en": "3rd quarter",
        "name_aa": "الربع الثالث",
        "name_de": "3. Viertel",
        "name_nl": "Derde kwart",
        "name_pl": "3. kwarta",
        "name_ro": "Sfertul 3",
        "name_ru": "3ая четверть",
        "name_tr": "Üçüncü çeyrek",
        "name_se": "3:e quarter",
        "name_el": "3η περίοδος"
    },
    "923": {
        "name_en": "4th quarter",
        "name_aa": "الربع الرابع",
        "name_de": "4. Viertel",
        "name_nl": "Vierde kwart",
        "name_pl": "4. kwarta",
        "name_ro": "Sfertul 4",
        "name_ru": "4ая четверть",
        "name_tr": "Dördüncü çeyrek",
        "name_se": "4:e quarter",
        "name_el": "4η περίοδος"
    },
    "924": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "beide gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός"
    },
    "939": {
        "name_en": "under {total}",
        "name_aa": "تحت {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "poniżej {total}",
        "name_ro": "sub {total}",
        "name_ru": "меньше {total}",
        "name_tr": "{total} altında",
        "name_se": "under {total}",
        "name_el": "under {total}"
    },
    "940": {
        "name_en": "exact {total}",
        "name_aa": "الدقيق {total}",
        "name_de": "genau {total}",
        "name_nl": "exact {total}",
        "name_pl": "dokładnie {total}",
        "name_ro": "exact {total}",
        "name_ru": "точно {total}",
        "name_tr": "tam {total}",
        "name_se": "exakta {total}",
        "name_el": "ακριβώς {total}"
    },
    "941": {
        "name_en": "over {total}",
        "name_aa": "أكثر من {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "ponad {total}",
        "name_ro": "peste {total}",
        "name_ru": "больше {total}",
        "name_tr": "{total} üstünde",
        "name_se": "över {total}",
        "name_el": "over {total}"
    },
    "948": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "949": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "950": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "951": {
        "name_en": "1st inning",
        "name_aa": "الشوط الأول",
        "name_de": "1. Inning",
        "name_nl": "1e inning",
        "name_pl": "1. inning",
        "name_ro": "inning 1",
        "name_ru": "1ый иннинг",
        "name_tr": "1. devre",
        "name_se": "1:a inning",
        "name_el": "1ο inning"
    },
    "952": {
        "name_en": "2nd inning",
        "name_aa": "الشوط الثاني",
        "name_de": "2. Inning",
        "name_nl": "2e inning",
        "name_pl": "2. inning",
        "name_ro": "inning 2",
        "name_ru": "2ый иннинг",
        "name_tr": "2. devre",
        "name_se": "2:a inning",
        "name_el": "2ο inning"
    },
    "953": {
        "name_en": "3rd inning",
        "name_aa": "الشوط الثالث",
        "name_de": "3. Inning",
        "name_nl": "3e inning",
        "name_pl": "3. inning",
        "name_ro": "inning 3",
        "name_ru": "3ий иннинг",
        "name_tr": "3. devre",
        "name_se": "3:e inning",
        "name_el": "3ο inning"
    },
    "954": {
        "name_en": "4th inning",
        "name_aa": "الشوط الرابع",
        "name_de": "4. Inning",
        "name_nl": "4e inning",
        "name_pl": "4. inning",
        "name_ro": "inning 4",
        "name_ru": "4ый иннинг",
        "name_tr": "4. devre",
        "name_se": "4:e inning",
        "name_el": "4ο inning"
    },
    "955": {
        "name_en": "5th inning",
        "name_aa": "الشوط الخامس",
        "name_de": "5. Inning",
        "name_nl": "5e inning",
        "name_pl": "5. inning",
        "name_ro": "inning 5",
        "name_ru": "5ый иннинг",
        "name_tr": "5. devre",
        "name_se": "5:e inning",
        "name_el": "5ο inning"
    },
    "956": {
        "name_en": "6th inning",
        "name_aa": "الشوط السادس",
        "name_de": "6. Inning",
        "name_nl": "6e inning",
        "name_pl": "6. inning",
        "name_ro": "inning 6",
        "name_ru": "6ой иннинг",
        "name_tr": "6. devre",
        "name_se": "6:e inning",
        "name_el": "6ο inning"
    },
    "957": {
        "name_en": "7th inning",
        "name_aa": "الشوط السابع",
        "name_de": "7. Inning",
        "name_nl": "7e inning",
        "name_pl": "7. inning",
        "name_ro": "inning 7",
        "name_ru": "7ой иннинг",
        "name_tr": "7. devre",
        "name_se": "7:e inning",
        "name_el": "7ο inning"
    },
    "958": {
        "name_en": "8th inning",
        "name_aa": "الشوط الثامن",
        "name_de": "8. Inning",
        "name_nl": "8e inning",
        "name_pl": "8. inning",
        "name_ro": "inning 8",
        "name_ru": "8ой иннинг",
        "name_tr": "8. devre",
        "name_se": "8:e inning",
        "name_el": "8ο inning"
    },
    "959": {
        "name_en": "9th inning",
        "name_aa": "الشوط التاسع",
        "name_de": "9. Inning",
        "name_nl": "9e inning",
        "name_pl": "9. inning",
        "name_ro": "inning 9",
        "name_ru": "9ый иннинг",
        "name_tr": "9. devre",
        "name_se": "9:e inning",
        "name_el": "9ο inning"
    },
    "960": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός"
    },
    "961": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "962": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "963": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "964": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "965": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "966": {
        "name_en": "5+",
        "name_aa": "5+",
        "name_de": "5+",
        "name_nl": "5+",
        "name_pl": "5+",
        "name_ro": "5+",
        "name_ru": "5+",
        "name_tr": "5+",
        "name_se": "5+",
        "name_el": "5+"
    },
    "973": {
        "name_en": "{$competitor1} & over {total}",
        "name_aa": "{$competitor1} وأكثر من {total}",
        "name_de": "{$competitor1} & über {total}",
        "name_nl": "{$competitor1} & boven {total}",
        "name_pl": "{$competitor1} & ponad {total}",
        "name_ro": "{$competitor1} & peste {total}",
        "name_ru": "{$competitor1} & больше {total}",
        "name_tr": "{$competitor1} & {total} üstü",
        "name_se": "{$competitor1} & över {total}",
        "name_el": "{$competitor1} και over {total}"
    },
    "974": {
        "name_en": "{$competitor2} & over {total}",
        "name_aa": "{$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2} & über {total}",
        "name_nl": "{$competitor2} & boven {total}",
        "name_pl": "{$competitor2} & ponad {total}",
        "name_ro": "{$competitor2} & peste {total}",
        "name_ru": "{$competitor2} & больше {total}",
        "name_tr": "{$competitor2} & {total} üstü",
        "name_se": "{$competitor2} & över {total}",
        "name_el": "{$competitor2} και over {total}"
    },
    "975": {
        "name_en": "{$competitor1} & under {total}",
        "name_aa": "{$competitor1} و تحت {total}",
        "name_de": "{$competitor1} & unter {total}",
        "name_nl": "{$competitor1} & onder {total}",
        "name_pl": "{$competitor1} & poniżej {total}",
        "name_ro": "{$competitor1} & sub {total}",
        "name_ru": "{$competitor1} & меньше {total}",
        "name_tr": "{$competitor1} & {total} altı",
        "name_se": "{$competitor1} & under {total}",
        "name_el": "{$competitor1} και under {total}"
    },
    "976": {
        "name_en": "{$competitor2} & under {total}",
        "name_aa": "{$competitor2} و تحت {total}",
        "name_de": "{$competitor2} & unter {total}",
        "name_nl": "{$competitor2} & onder {total}",
        "name_pl": "{$competitor2} & poniżej {total}",
        "name_ro": "{$competitor2} & sub {total}",
        "name_ru": "{$competitor2} & меньше {total}",
        "name_tr": "{$competitor2} & {total} altı",
        "name_se": "{$competitor2} & under {total}",
        "name_el": "{$competitor2} και under {total}"
    },
    "1002": {
        "name_en": "{$competitor1} by 3+",
        "name_aa": "{$competitor1} في 3+",
        "name_de": "{$competitor1} mit 3+",
        "name_nl": "{$competitor1} met 3+",
        "name_pl": "{$competitor1} 3+",
        "name_ro": "{$competitor1} cu 3+",
        "name_ru": "{$competitor1} на 3+",
        "name_tr": "{$competitor1} 3 ve üstü farkla",
        "name_se": "{$competitor1} med 3+",
        "name_el": "{$competitor1} με 3+"
    },
    "1003": {
        "name_en": "{$competitor2} by 3+",
        "name_aa": "{$competitor2} في 3+",
        "name_de": "{$competitor2} mit 3+",
        "name_nl": "{$competitor2} met 3+",
        "name_pl": "{$competitor2} 3+",
        "name_ro": "{$competitor2} cu 3+",
        "name_ru": "{$competitor2} на 3+",
        "name_tr": "{$competitor2} 3 ve üstü farkla",
        "name_se": "{$competitor2} med 3+",
        "name_el": "{$competitor2} με 3+"
    },
    "1004": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "alta",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1005": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "1006": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "1007": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "1008": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "1009": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "1031": {
        "name_en": "caught",
        "name_aa": "القبض",
        "name_de": "gefangen",
        "name_nl": "caught",
        "name_pl": "caught",
        "name_ro": "caught",
        "name_ru": "пойман",
        "name_tr": "yakalama",
        "name_se": "caught",
        "name_el": "caught"
    },
    "1032": {
        "name_en": "bowled",
        "name_aa": "رمى",
        "name_de": "bowled",
        "name_nl": "bowled",
        "name_pl": "bowled",
        "name_ro": "bowled",
        "name_ru": "Боулд",
        "name_tr": "bowled",
        "name_se": "bowled",
        "name_el": "bowled"
    },
    "1033": {
        "name_en": "lbw",
        "name_aa": "نقص الوزن عند الولادة",
        "name_de": "lbw",
        "name_nl": "lbw",
        "name_pl": "lbw",
        "name_ro": "lbw",
        "name_ru": "нога перед калиткой",
        "name_tr": "lbw",
        "name_se": "lbw",
        "name_el": "lbw"
    },
    "1034": {
        "name_en": "run out",
        "name_aa": "نفذ",
        "name_de": "run out",
        "name_nl": "run out",
        "name_pl": "run out",
        "name_ro": "run out",
        "name_ru": "ран-аут",
        "name_tr": "run out",
        "name_se": "run out",
        "name_el": "run out"
    },
    "1035": {
        "name_en": "stumped",
        "name_aa": "الحيرة",
        "name_de": "stumped",
        "name_nl": "stumped",
        "name_pl": "stumped",
        "name_ro": "stumped",
        "name_ru": "стампд",
        "name_tr": "stumped",
        "name_se": "stumped",
        "name_el": "stumped"
    },
    "1036": {
        "name_en": "others",
        "name_aa": "الآخرين",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inne",
        "name_ro": "altele",
        "name_ru": "другие",
        "name_tr": "diğerleri",
        "name_se": "andra",
        "name_el": "άλλα"
    },
    "1037": {
        "name_en": "caught",
        "name_aa": "القبض",
        "name_de": "gefangen",
        "name_nl": "caught",
        "name_pl": "caught",
        "name_ro": "caught",
        "name_ru": "пойман",
        "name_tr": "yakalar",
        "name_se": "caught",
        "name_el": "caught"
    },
    "1038": {
        "name_en": "not caught",
        "name_aa": "لم يدرك",
        "name_de": "nicht gefangen",
        "name_nl": "not caught",
        "name_pl": "not caught",
        "name_ro": "not caught",
        "name_ru": "не пойман",
        "name_tr": "yakalayamaz",
        "name_se": "inte caught",
        "name_el": "not caught"
    },
    "1039": {
        "name_en": "over {total}",
        "name_aa": "أكثر من {total}",
        "name_de": "über {total}",
        "name_nl": "boven {total}",
        "name_pl": "ponad {total}",
        "name_ro": "peste {total}",
        "name_ru": "больше {total}",
        "name_tr": "{total} üstü",
        "name_se": "över {total}",
        "name_el": "over {total}"
    },
    "1040": {
        "name_en": "under {total}",
        "name_aa": "تحت {total}",
        "name_de": "unter {total}",
        "name_nl": "onder {total}",
        "name_pl": "poniżej {total}",
        "name_ro": "sub {total}",
        "name_ru": "меньше {total}",
        "name_tr": "{total} altı",
        "name_se": "under {total}",
        "name_el": "under {total}"
    },
    "1041": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "1042": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "1043": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "1044": {
        "name_en": "0-89",
        "name_aa": "0-89",
        "name_de": "0-89",
        "name_nl": "0-89",
        "name_pl": "0-89",
        "name_ro": "0-89",
        "name_ru": "0-89",
        "name_tr": "0-89",
        "name_se": "0-89",
        "name_el": "0-89"
    },
    "1045": {
        "name_en": "90-100",
        "name_aa": "90-100",
        "name_de": "90-100",
        "name_nl": "90-100",
        "name_pl": "90-100",
        "name_ro": "90-100",
        "name_ru": "90-100",
        "name_tr": "90-100",
        "name_se": "90-100",
        "name_el": "90-100"
    },
    "1046": {
        "name_en": "101+",
        "name_aa": "101+",
        "name_de": "101+",
        "name_nl": "101+",
        "name_pl": "101+",
        "name_ro": "101+",
        "name_ru": "101+",
        "name_tr": "101+",
        "name_se": "101+",
        "name_el": "101+"
    },
    "1049": {
        "name_en": "red",
        "name_aa": "أحمر",
        "name_de": "rot",
        "name_nl": "rood",
        "name_pl": "czerwony",
        "name_ro": "roșu",
        "name_ru": "красный",
        "name_tr": "kırmızı",
        "name_se": "röd",
        "name_el": "κόκκινο"
    },
    "1050": {
        "name_en": "green",
        "name_aa": "أخضر",
        "name_de": "grün",
        "name_nl": "groen",
        "name_pl": "zielony",
        "name_ro": "verde",
        "name_ru": "зеленый",
        "name_tr": "yeşil",
        "name_se": "grön",
        "name_el": "πράσινο"
    },
    "1058": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "1059": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1060": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1061": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "1062": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "1063": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5"
    },
    "1064": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6"
    },
    "1065": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7"
    },
    "1066": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8"
    },
    "1067": {
        "name_en": "0:9",
        "name_aa": "0:9",
        "name_de": "0:9",
        "name_nl": "0:9",
        "name_pl": "0:9",
        "name_ro": "0:9",
        "name_ru": "0:9",
        "name_tr": "0:9",
        "name_se": "0:9",
        "name_el": "0:9"
    },
    "1068": {
        "name_en": "0:10",
        "name_aa": "0:10",
        "name_de": "0:10",
        "name_nl": "0:10",
        "name_pl": "0:10",
        "name_ro": "0:10",
        "name_ru": "0:10",
        "name_tr": "0:10",
        "name_se": "0:10",
        "name_el": "0:10"
    },
    "1069": {
        "name_en": "0:11",
        "name_aa": "0:11",
        "name_de": "0:11",
        "name_nl": "0:11",
        "name_pl": "0:11",
        "name_ro": "0:11",
        "name_ru": "0:11",
        "name_tr": "0:11",
        "name_se": "0:11",
        "name_el": "0:11"
    },
    "1070": {
        "name_en": "0:12",
        "name_aa": "0:12",
        "name_de": "0:12",
        "name_nl": "0:12",
        "name_pl": "0:12",
        "name_ro": "0:12",
        "name_ru": "0:12",
        "name_tr": "0:12",
        "name_se": "0:12",
        "name_el": "0:12"
    },
    "1071": {
        "name_en": "0:13",
        "name_aa": "0:13",
        "name_de": "0:13",
        "name_nl": "0:13",
        "name_pl": "0:13",
        "name_ro": "0:13",
        "name_ru": "0:13",
        "name_tr": "0:13",
        "name_se": "0:13",
        "name_el": "0:13"
    },
    "1072": {
        "name_en": "0:14",
        "name_aa": "0:14",
        "name_de": "0:14",
        "name_nl": "0:14",
        "name_pl": "0:14",
        "name_ro": "0:14",
        "name_ru": "0:14",
        "name_tr": "0:14",
        "name_se": "0:14",
        "name_el": "0:14"
    },
    "1073": {
        "name_en": "0:15",
        "name_aa": "0:15",
        "name_de": "0:15",
        "name_nl": "0:15",
        "name_pl": "0:15",
        "name_ro": "0:15",
        "name_ru": "0:15",
        "name_tr": "0:15",
        "name_se": "0:15",
        "name_el": "0:15"
    },
    "1074": {
        "name_en": "0:16",
        "name_aa": "0:16",
        "name_de": "0:16",
        "name_nl": "0:16",
        "name_pl": "0:16",
        "name_ro": "0:16",
        "name_ru": "0:16",
        "name_tr": "0:16",
        "name_se": "0:16",
        "name_el": "0:16"
    },
    "1075": {
        "name_en": "0:17",
        "name_aa": "0:17",
        "name_de": "0:17",
        "name_nl": "0:17",
        "name_pl": "0:17",
        "name_ro": "0:17",
        "name_ru": "0:17",
        "name_tr": "0:17",
        "name_se": "0:17",
        "name_el": "0:17"
    },
    "1076": {
        "name_en": "0:18",
        "name_aa": "0:18",
        "name_de": "0:18",
        "name_nl": "0:18",
        "name_pl": "0:18",
        "name_ro": "0:18",
        "name_ru": "0:18",
        "name_tr": "0:18",
        "name_se": "0:18",
        "name_el": "0:18"
    },
    "1077": {
        "name_en": "0:19",
        "name_aa": "0:19",
        "name_de": "0:19",
        "name_nl": "0:19",
        "name_pl": "0:19",
        "name_ro": "0:19",
        "name_ru": "0:19",
        "name_tr": "0:19",
        "name_se": "0:19",
        "name_el": "0:19"
    },
    "1078": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1079": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "1080": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1081": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "1082": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "1083": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5"
    },
    "1084": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6"
    },
    "1085": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7"
    },
    "1086": {
        "name_en": "1:8",
        "name_aa": "1:8",
        "name_de": "1:8",
        "name_nl": "1:8",
        "name_pl": "1:8",
        "name_ro": "1:8",
        "name_ru": "1:8",
        "name_tr": "1:8",
        "name_se": "1:8",
        "name_el": "1:8"
    },
    "1087": {
        "name_en": "1:9",
        "name_aa": "1:9",
        "name_de": "1:9",
        "name_nl": "1:9",
        "name_pl": "1:9",
        "name_ro": "1:9",
        "name_ru": "1:9",
        "name_tr": "1:9",
        "name_se": "1:9",
        "name_el": "1:9"
    },
    "1088": {
        "name_en": "1:10",
        "name_aa": "1:10",
        "name_de": "1:10",
        "name_nl": "1:10",
        "name_pl": "1:10",
        "name_ro": "1:10",
        "name_ru": "1:10",
        "name_tr": "1:10",
        "name_se": "1:10",
        "name_el": "1:10"
    },
    "1089": {
        "name_en": "1:11",
        "name_aa": "1:11",
        "name_de": "1:11",
        "name_nl": "1:11",
        "name_pl": "1:11",
        "name_ro": "1:11",
        "name_ru": "1:11",
        "name_tr": "1:11",
        "name_se": "1:11",
        "name_el": "1:11"
    },
    "1090": {
        "name_en": "1:12",
        "name_aa": "1:12",
        "name_de": "1:12",
        "name_nl": "1:12",
        "name_pl": "1:12",
        "name_ro": "1:12",
        "name_ru": "1:12",
        "name_tr": "1:12",
        "name_se": "1:12",
        "name_el": "1:12"
    },
    "1091": {
        "name_en": "1:13",
        "name_aa": "1:13",
        "name_de": "1:13",
        "name_nl": "1:13",
        "name_pl": "1:13",
        "name_ro": "1:13",
        "name_ru": "1:13",
        "name_tr": "1:13",
        "name_se": "1:13",
        "name_el": "1:13"
    },
    "1092": {
        "name_en": "1:14",
        "name_aa": "1:14",
        "name_de": "1:14",
        "name_nl": "1:14",
        "name_pl": "1:14",
        "name_ro": "1:14",
        "name_ru": "1:14",
        "name_tr": "1:14",
        "name_se": "1:14",
        "name_el": "1:14"
    },
    "1093": {
        "name_en": "1:15",
        "name_aa": "1:15",
        "name_de": "1:15",
        "name_nl": "1:15",
        "name_pl": "1:15",
        "name_ro": "1:15",
        "name_ru": "1:15",
        "name_tr": "1:15",
        "name_se": "1:15",
        "name_el": "1:15"
    },
    "1094": {
        "name_en": "1:16",
        "name_aa": "1:16",
        "name_de": "1:16",
        "name_nl": "1:16",
        "name_pl": "1:16",
        "name_ro": "1:16",
        "name_ru": "1:16",
        "name_tr": "1:16",
        "name_se": "1:16",
        "name_el": "1:16"
    },
    "1095": {
        "name_en": "1:17",
        "name_aa": "1:17",
        "name_de": "1:17",
        "name_nl": "1:17",
        "name_pl": "1:17",
        "name_ro": "1:17",
        "name_ru": "1:17",
        "name_tr": "1:17",
        "name_se": "1:17",
        "name_el": "1:17"
    },
    "1096": {
        "name_en": "1:18",
        "name_aa": "1:18",
        "name_de": "1:18",
        "name_nl": "1:18",
        "name_pl": "1:18",
        "name_ro": "1:18",
        "name_ru": "1:18",
        "name_tr": "1:18",
        "name_se": "1:18",
        "name_el": "1:18"
    },
    "1097": {
        "name_en": "1:19",
        "name_aa": "1:19",
        "name_de": "1:19",
        "name_nl": "1:19",
        "name_pl": "1:19",
        "name_ro": "1:19",
        "name_ru": "1:19",
        "name_tr": "1:19",
        "name_se": "1:19",
        "name_el": "1:19"
    },
    "1098": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1099": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1100": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "1101": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "1102": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "1103": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5"
    },
    "1104": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6"
    },
    "1105": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7"
    },
    "1106": {
        "name_en": "2:8",
        "name_aa": "2:8",
        "name_de": "2:8",
        "name_nl": "2:8",
        "name_pl": "2:8",
        "name_ro": "2:8",
        "name_ru": "2:8",
        "name_tr": "2:8",
        "name_se": "2:8",
        "name_el": "2:8"
    },
    "1107": {
        "name_en": "2:9",
        "name_aa": "2:9",
        "name_de": "2:9",
        "name_nl": "2:9",
        "name_pl": "2:9",
        "name_ro": "2:9",
        "name_ru": "2:9",
        "name_tr": "2:9",
        "name_se": "2:9",
        "name_el": "2:9"
    },
    "1108": {
        "name_en": "2:10",
        "name_aa": "2:10",
        "name_de": "2:10",
        "name_nl": "2:10",
        "name_pl": "2:10",
        "name_ro": "2:10",
        "name_ru": "2:10",
        "name_tr": "2:10",
        "name_se": "2:10",
        "name_el": "2:10"
    },
    "1109": {
        "name_en": "2:11",
        "name_aa": "2:11",
        "name_de": "2:11",
        "name_nl": "2:11",
        "name_pl": "2:11",
        "name_ro": "2:11",
        "name_ru": "2:11",
        "name_tr": "2:11",
        "name_se": "2:11",
        "name_el": "2:11"
    },
    "1110": {
        "name_en": "2:12",
        "name_aa": "2:12",
        "name_de": "2:12",
        "name_nl": "2:12",
        "name_pl": "2:12",
        "name_ro": "2:12",
        "name_ru": "2:12",
        "name_tr": "2:12",
        "name_se": "2:12",
        "name_el": "2:12"
    },
    "1111": {
        "name_en": "2:13",
        "name_aa": "2:13",
        "name_de": "2:13",
        "name_nl": "2:13",
        "name_pl": "2:13",
        "name_ro": "2:13",
        "name_ru": "2:13",
        "name_tr": "2:13",
        "name_se": "2:13",
        "name_el": "2:13"
    },
    "1112": {
        "name_en": "2:14",
        "name_aa": "2:14",
        "name_de": "2:14",
        "name_nl": "2:14",
        "name_pl": "2:14",
        "name_ro": "2:14",
        "name_ru": "2:14",
        "name_tr": "2:14",
        "name_se": "2:14",
        "name_el": "2:14"
    },
    "1113": {
        "name_en": "2:15",
        "name_aa": "2:15",
        "name_de": "2:15",
        "name_nl": "2:15",
        "name_pl": "2:15",
        "name_ro": "2:15",
        "name_ru": "2:15",
        "name_tr": "2:15",
        "name_se": "2:15",
        "name_el": "2:15"
    },
    "1114": {
        "name_en": "2:16",
        "name_aa": "2:16",
        "name_de": "2:16",
        "name_nl": "2:16",
        "name_pl": "2:16",
        "name_ro": "2:16",
        "name_ru": "2:16",
        "name_tr": "2:16",
        "name_se": "2:16",
        "name_el": "2:16"
    },
    "1115": {
        "name_en": "2:17",
        "name_aa": "2:17",
        "name_de": "2:17",
        "name_nl": "2:17",
        "name_pl": "2:17",
        "name_ro": "2:17",
        "name_ru": "2:17",
        "name_tr": "2:17",
        "name_se": "2:17",
        "name_el": "2:17"
    },
    "1116": {
        "name_en": "2:18",
        "name_aa": "2:18",
        "name_de": "2:18",
        "name_nl": "2:18",
        "name_pl": "2:18",
        "name_ro": "2:18",
        "name_ru": "2:18",
        "name_tr": "2:18",
        "name_se": "2:18",
        "name_el": "2:18"
    },
    "1117": {
        "name_en": "2:19",
        "name_aa": "2:19",
        "name_de": "2:19",
        "name_nl": "2:19",
        "name_pl": "2:19",
        "name_ro": "2:19",
        "name_ru": "2:19",
        "name_tr": "2:19",
        "name_se": "2:19",
        "name_el": "2:19"
    },
    "1118": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "1119": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "1120": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "1121": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3"
    },
    "1122": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "1123": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5"
    },
    "1124": {
        "name_en": "3:6",
        "name_aa": "3:6",
        "name_de": "3:6",
        "name_nl": "3:6",
        "name_pl": "3:6",
        "name_ro": "3:6",
        "name_ru": "3:6",
        "name_tr": "3:6",
        "name_se": "3:6",
        "name_el": "3:6"
    },
    "1125": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7"
    },
    "1126": {
        "name_en": "3:8",
        "name_aa": "3:8",
        "name_de": "3:8",
        "name_nl": "3:8",
        "name_pl": "3:8",
        "name_ro": "3:8",
        "name_ru": "3:8",
        "name_tr": "3:8",
        "name_se": "3:8",
        "name_el": "3:8"
    },
    "1127": {
        "name_en": "3:9",
        "name_aa": "3:9",
        "name_de": "3:9",
        "name_nl": "3:9",
        "name_pl": "3:9",
        "name_ro": "3:9",
        "name_ru": "3:9",
        "name_tr": "3:9",
        "name_se": "3:9",
        "name_el": "3:9"
    },
    "1128": {
        "name_en": "3:10",
        "name_aa": "3:10",
        "name_de": "3:10",
        "name_nl": "3:10",
        "name_pl": "3:10",
        "name_ro": "3:10",
        "name_ru": "3:10",
        "name_tr": "3:10",
        "name_se": "3:10",
        "name_el": "3:10"
    },
    "1129": {
        "name_en": "3:11",
        "name_aa": "3:11",
        "name_de": "3:11",
        "name_nl": "3:11",
        "name_pl": "3:11",
        "name_ro": "3:11",
        "name_ru": "3:11",
        "name_tr": "3:11",
        "name_se": "3:11",
        "name_el": "3:11"
    },
    "1130": {
        "name_en": "3:12",
        "name_aa": "3:12",
        "name_de": "3:12",
        "name_nl": "3:12",
        "name_pl": "3:12",
        "name_ro": "3:12",
        "name_ru": "3:12",
        "name_tr": "3:12",
        "name_se": "3:12",
        "name_el": "3:12"
    },
    "1131": {
        "name_en": "3:13",
        "name_aa": "3:13",
        "name_de": "3:13",
        "name_nl": "3:13",
        "name_pl": "3:13",
        "name_ro": "3:13",
        "name_ru": "3:13",
        "name_tr": "3:13",
        "name_se": "3:13",
        "name_el": "3:13"
    },
    "1132": {
        "name_en": "3:14",
        "name_aa": "3:14",
        "name_de": "3:14",
        "name_nl": "3:14",
        "name_pl": "3:14",
        "name_ro": "3:14",
        "name_ru": "3:14",
        "name_tr": "3:14",
        "name_se": "3:14",
        "name_el": "3:14"
    },
    "1133": {
        "name_en": "3:15",
        "name_aa": "3:15",
        "name_de": "3:15",
        "name_nl": "3:15",
        "name_pl": "3:15",
        "name_ro": "3:15",
        "name_ru": "3:15",
        "name_tr": "3:15",
        "name_se": "3:15",
        "name_el": "3:15"
    },
    "1134": {
        "name_en": "3:16",
        "name_aa": "3:16",
        "name_de": "3:16",
        "name_nl": "3:16",
        "name_pl": "3:16",
        "name_ro": "3:16",
        "name_ru": "3:16",
        "name_tr": "3:16",
        "name_se": "3:16",
        "name_el": "3:16"
    },
    "1135": {
        "name_en": "3:17",
        "name_aa": "3:17",
        "name_de": "3:17",
        "name_nl": "3:17",
        "name_pl": "3:17",
        "name_ro": "3:17",
        "name_ru": "3:17",
        "name_tr": "3:17",
        "name_se": "3:17",
        "name_el": "3:17"
    },
    "1136": {
        "name_en": "3:18",
        "name_aa": "3:18",
        "name_de": "3:18",
        "name_nl": "3:18",
        "name_pl": "3:18",
        "name_ro": "3:18",
        "name_ru": "3:18",
        "name_tr": "3:18",
        "name_se": "3:18",
        "name_el": "3:18"
    },
    "1137": {
        "name_en": "3:19",
        "name_aa": "3:19",
        "name_de": "3:19",
        "name_nl": "3:19",
        "name_pl": "3:19",
        "name_ro": "3:19",
        "name_ru": "3:19",
        "name_tr": "3:19",
        "name_se": "3:19",
        "name_el": "3:19"
    },
    "1138": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "1139": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "1140": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "1141": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "1142": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4"
    },
    "1143": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5"
    },
    "1144": {
        "name_en": "4:6",
        "name_aa": "4:6",
        "name_de": "4:6",
        "name_nl": "4:6",
        "name_pl": "4:6",
        "name_ro": "4:6",
        "name_ru": "4:6",
        "name_tr": "4:6",
        "name_se": "4:6",
        "name_el": "4:6"
    },
    "1145": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7"
    },
    "1146": {
        "name_en": "4:8",
        "name_aa": "4:8",
        "name_de": "4:8",
        "name_nl": "4:8",
        "name_pl": "4:8",
        "name_ro": "4:8",
        "name_ru": "4:8",
        "name_tr": "4:8",
        "name_se": "4:8",
        "name_el": "4:8"
    },
    "1147": {
        "name_en": "4:9",
        "name_aa": "4:9",
        "name_de": "4:9",
        "name_nl": "4:9",
        "name_pl": "4:9",
        "name_ro": "4:9",
        "name_ru": "4:9",
        "name_tr": "4:9",
        "name_se": "4:9",
        "name_el": "4:9"
    },
    "1148": {
        "name_en": "4:10",
        "name_aa": "4:10",
        "name_de": "4:10",
        "name_nl": "4:10",
        "name_pl": "4:10",
        "name_ro": "4:10",
        "name_ru": "4:10",
        "name_tr": "4:10",
        "name_se": "4:10",
        "name_el": "4:10"
    },
    "1149": {
        "name_en": "4:11",
        "name_aa": "4:11",
        "name_de": "4:11",
        "name_nl": "4:11",
        "name_pl": "4:11",
        "name_ro": "4:11",
        "name_ru": "4:11",
        "name_tr": "4:11",
        "name_se": "4:11",
        "name_el": "4:11"
    },
    "1150": {
        "name_en": "4:12",
        "name_aa": "4:12",
        "name_de": "4:12",
        "name_nl": "4:12",
        "name_pl": "4:12",
        "name_ro": "4:12",
        "name_ru": "4:12",
        "name_tr": "4:12",
        "name_se": "4:12",
        "name_el": "4:12"
    },
    "1151": {
        "name_en": "4:13",
        "name_aa": "4:13",
        "name_de": "4:13",
        "name_nl": "4:13",
        "name_pl": "4:13",
        "name_ro": "4:13",
        "name_ru": "4:13",
        "name_tr": "4:13",
        "name_se": "4:13",
        "name_el": "4:13"
    },
    "1152": {
        "name_en": "4:14",
        "name_aa": "4:14",
        "name_de": "4:14",
        "name_nl": "4:14",
        "name_pl": "4:14",
        "name_ro": "4:14",
        "name_ru": "4:14",
        "name_tr": "4:14",
        "name_se": "4:14",
        "name_el": "4:14"
    },
    "1153": {
        "name_en": "4:15",
        "name_aa": "4:15",
        "name_de": "4:15",
        "name_nl": "4:15",
        "name_pl": "4:15",
        "name_ro": "4:15",
        "name_ru": "4:15",
        "name_tr": "4:15",
        "name_se": "4:15",
        "name_el": "4:15"
    },
    "1154": {
        "name_en": "4:16",
        "name_aa": "4:16",
        "name_de": "4:16",
        "name_nl": "4:16",
        "name_pl": "4:16",
        "name_ro": "4:16",
        "name_ru": "4:16",
        "name_tr": "4:16",
        "name_se": "4:16",
        "name_el": "4:16"
    },
    "1155": {
        "name_en": "4:17",
        "name_aa": "4:17",
        "name_de": "4:17",
        "name_nl": "4:17",
        "name_pl": "4:17",
        "name_ro": "4:17",
        "name_ru": "4:17",
        "name_tr": "4:17",
        "name_se": "4:17",
        "name_el": "4:17"
    },
    "1156": {
        "name_en": "4:18",
        "name_aa": "4:18",
        "name_de": "4:18",
        "name_nl": "4:18",
        "name_pl": "4:18",
        "name_ro": "4:18",
        "name_ru": "4:18",
        "name_tr": "4:18",
        "name_se": "4:18",
        "name_el": "4:18"
    },
    "1157": {
        "name_en": "4:19",
        "name_aa": "4:19",
        "name_de": "4:19",
        "name_nl": "4:19",
        "name_pl": "4:19",
        "name_ro": "4:19",
        "name_ru": "4:19",
        "name_tr": "4:19",
        "name_se": "4:19",
        "name_el": "4:19"
    },
    "1158": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0"
    },
    "1159": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1"
    },
    "1160": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2"
    },
    "1161": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3"
    },
    "1162": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4"
    },
    "1163": {
        "name_en": "5:5",
        "name_aa": "5:5",
        "name_de": "5:5",
        "name_nl": "5:5",
        "name_pl": "5:5",
        "name_ro": "5:5",
        "name_ru": "5:5",
        "name_tr": "5:5",
        "name_se": "5:5",
        "name_el": "5:5"
    },
    "1164": {
        "name_en": "5:6",
        "name_aa": "5:6",
        "name_de": "5:6",
        "name_nl": "5:6",
        "name_pl": "5:6",
        "name_ro": "5:6",
        "name_ru": "5:6",
        "name_tr": "5:6",
        "name_se": "5:6",
        "name_el": "5:6"
    },
    "1165": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7"
    },
    "1166": {
        "name_en": "5:8",
        "name_aa": "5:8",
        "name_de": "5:8",
        "name_nl": "5:8",
        "name_pl": "5:8",
        "name_ro": "5:8",
        "name_ru": "5:8",
        "name_tr": "5:8",
        "name_se": "5:8",
        "name_el": "5:8"
    },
    "1167": {
        "name_en": "5:9",
        "name_aa": "5:9",
        "name_de": "5:9",
        "name_nl": "5:9",
        "name_pl": "5:9",
        "name_ro": "5:9",
        "name_ru": "5:9",
        "name_tr": "5:9",
        "name_se": "5:9",
        "name_el": "5:9"
    },
    "1168": {
        "name_en": "5:10",
        "name_aa": "5:10",
        "name_de": "5:10",
        "name_nl": "5:10",
        "name_pl": "5:10",
        "name_ro": "5:10",
        "name_ru": "5:10",
        "name_tr": "5:10",
        "name_se": "5:10",
        "name_el": "5:10"
    },
    "1169": {
        "name_en": "5:11",
        "name_aa": "5:11",
        "name_de": "5:11",
        "name_nl": "5:11",
        "name_pl": "5:11",
        "name_ro": "5:11",
        "name_ru": "5:11",
        "name_tr": "5:11",
        "name_se": "5:11",
        "name_el": "5:11"
    },
    "1170": {
        "name_en": "5:12",
        "name_aa": "5:12",
        "name_de": "5:12",
        "name_nl": "5:12",
        "name_pl": "5:12",
        "name_ro": "5:12",
        "name_ru": "5:12",
        "name_tr": "5:12",
        "name_se": "5:12",
        "name_el": "5:12"
    },
    "1171": {
        "name_en": "5:13",
        "name_aa": "5:13",
        "name_de": "5:13",
        "name_nl": "5:13",
        "name_pl": "5:13",
        "name_ro": "5:13",
        "name_ru": "5:13",
        "name_tr": "5:13",
        "name_se": "5:13",
        "name_el": "5:13"
    },
    "1172": {
        "name_en": "5:14",
        "name_aa": "5:14",
        "name_de": "5:14",
        "name_nl": "5:14",
        "name_pl": "5:14",
        "name_ro": "5:14",
        "name_ru": "5:14",
        "name_tr": "5:14",
        "name_se": "5:14",
        "name_el": "5:14"
    },
    "1173": {
        "name_en": "5:15",
        "name_aa": "5:15",
        "name_de": "5:15",
        "name_nl": "5:15",
        "name_pl": "5:15",
        "name_ro": "5:15",
        "name_ru": "5:15",
        "name_tr": "5:15",
        "name_se": "5:15",
        "name_el": "5:15"
    },
    "1174": {
        "name_en": "5:16",
        "name_aa": "5:16",
        "name_de": "5:16",
        "name_nl": "5:16",
        "name_pl": "5:16",
        "name_ro": "5:16",
        "name_ru": "5:16",
        "name_tr": "5:16",
        "name_se": "5:16",
        "name_el": "5:16"
    },
    "1175": {
        "name_en": "5:17",
        "name_aa": "5:17",
        "name_de": "5:17",
        "name_nl": "5:17",
        "name_pl": "5:17",
        "name_ro": "5:17",
        "name_ru": "5:17",
        "name_tr": "5:17",
        "name_se": "5:17",
        "name_el": "5:17"
    },
    "1176": {
        "name_en": "5:18",
        "name_aa": "5:18",
        "name_de": "5:18",
        "name_nl": "5:18",
        "name_pl": "5:18",
        "name_ro": "5:18",
        "name_ru": "5:18",
        "name_tr": "5:18",
        "name_se": "5:18",
        "name_el": "5:18"
    },
    "1177": {
        "name_en": "5:19",
        "name_aa": "5:19",
        "name_de": "5:19",
        "name_nl": "5:19",
        "name_pl": "5:19",
        "name_ro": "5:19",
        "name_ru": "5:19",
        "name_tr": "5:19",
        "name_se": "5:19",
        "name_el": "5:19"
    },
    "1178": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0"
    },
    "1179": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1"
    },
    "1180": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2"
    },
    "1181": {
        "name_en": "6:3",
        "name_aa": "6:3",
        "name_de": "6:3",
        "name_nl": "6:3",
        "name_pl": "6:3",
        "name_ro": "6:3",
        "name_ru": "6:3",
        "name_tr": "6:3",
        "name_se": "6:3",
        "name_el": "6:3"
    },
    "1182": {
        "name_en": "6:4",
        "name_aa": "6:4",
        "name_de": "6:4",
        "name_nl": "6:4",
        "name_pl": "6:4",
        "name_ro": "6:4",
        "name_ru": "6:4",
        "name_tr": "6:4",
        "name_se": "6:4",
        "name_el": "6:4"
    },
    "1183": {
        "name_en": "6:5",
        "name_aa": "6:5",
        "name_de": "6:5",
        "name_nl": "6:5",
        "name_pl": "6:5",
        "name_ro": "6:5",
        "name_ru": "6:5",
        "name_tr": "6:5",
        "name_se": "6:5",
        "name_el": "6:5"
    },
    "1184": {
        "name_en": "6:6",
        "name_aa": "6:6",
        "name_de": "6:6",
        "name_nl": "6:6",
        "name_pl": "6:6",
        "name_ro": "6:6",
        "name_ru": "6:6",
        "name_tr": "6:6",
        "name_se": "6:6",
        "name_el": "6:6"
    },
    "1185": {
        "name_en": "6:7",
        "name_aa": "6:7",
        "name_de": "6:7",
        "name_nl": "6:7",
        "name_pl": "6:7",
        "name_ro": "6:7",
        "name_ru": "6:7",
        "name_tr": "6:7",
        "name_se": "6:7",
        "name_el": "6:7"
    },
    "1186": {
        "name_en": "6:8",
        "name_aa": "6:8",
        "name_de": "6:8",
        "name_nl": "6:8",
        "name_pl": "6:8",
        "name_ro": "6:8",
        "name_ru": "6:8",
        "name_tr": "6:8",
        "name_se": "6:8",
        "name_el": "6:8"
    },
    "1187": {
        "name_en": "6:9",
        "name_aa": "6:9",
        "name_de": "6:9",
        "name_nl": "6:9",
        "name_pl": "6:9",
        "name_ro": "6:9",
        "name_ru": "6:9",
        "name_tr": "6:9",
        "name_se": "6:9",
        "name_el": "6:9"
    },
    "1188": {
        "name_en": "6:10",
        "name_aa": "6:10",
        "name_de": "6:10",
        "name_nl": "6:10",
        "name_pl": "6:10",
        "name_ro": "6:10",
        "name_ru": "6:10",
        "name_tr": "6:10",
        "name_se": "6:10",
        "name_el": "6:10"
    },
    "1189": {
        "name_en": "6:11",
        "name_aa": "6:11",
        "name_de": "6:11",
        "name_nl": "6:11",
        "name_pl": "6:11",
        "name_ro": "6:11",
        "name_ru": "6:11",
        "name_tr": "6:11",
        "name_se": "6:11",
        "name_el": "6:11"
    },
    "1190": {
        "name_en": "6:12",
        "name_aa": "6:12",
        "name_de": "6:12",
        "name_nl": "6:12",
        "name_pl": "6:12",
        "name_ro": "6:12",
        "name_ru": "6:12",
        "name_tr": "6:12",
        "name_se": "6:12",
        "name_el": "6:12"
    },
    "1191": {
        "name_en": "6:13",
        "name_aa": "6:13",
        "name_de": "6:13",
        "name_nl": "6:13",
        "name_pl": "6:13",
        "name_ro": "6:13",
        "name_ru": "6:13",
        "name_tr": "6:13",
        "name_se": "6:13",
        "name_el": "6:13"
    },
    "1192": {
        "name_en": "6:14",
        "name_aa": "6:14",
        "name_de": "6:14",
        "name_nl": "6:14",
        "name_pl": "6:14",
        "name_ro": "6:14",
        "name_ru": "6:14",
        "name_tr": "6:14",
        "name_se": "6:14",
        "name_el": "6:14"
    },
    "1193": {
        "name_en": "6:15",
        "name_aa": "6:15",
        "name_de": "6:15",
        "name_nl": "6:15",
        "name_pl": "6:15",
        "name_ro": "6:15",
        "name_ru": "6:15",
        "name_tr": "6:15",
        "name_se": "6:15",
        "name_el": "6:15"
    },
    "1194": {
        "name_en": "6:16",
        "name_aa": "6:16",
        "name_de": "6:16",
        "name_nl": "6:16",
        "name_pl": "6:16",
        "name_ro": "6:16",
        "name_ru": "6:16",
        "name_tr": "6:16",
        "name_se": "6:16",
        "name_el": "6:16"
    },
    "1195": {
        "name_en": "6:17",
        "name_aa": "6:17",
        "name_de": "6:17",
        "name_nl": "6:17",
        "name_pl": "6:17",
        "name_ro": "6:17",
        "name_ru": "6:17",
        "name_tr": "6:17",
        "name_se": "6:17",
        "name_el": "6:17"
    },
    "1196": {
        "name_en": "6:18",
        "name_aa": "6:18",
        "name_de": "6:18",
        "name_nl": "6:18",
        "name_pl": "6:18",
        "name_ro": "6:18",
        "name_ru": "6:18",
        "name_tr": "6:18",
        "name_se": "6:18",
        "name_el": "6:18"
    },
    "1197": {
        "name_en": "6:19",
        "name_aa": "6:19",
        "name_de": "6:19",
        "name_nl": "6:19",
        "name_pl": "6:19",
        "name_ro": "6:19",
        "name_ru": "6:19",
        "name_tr": "6:19",
        "name_se": "6:19",
        "name_el": "6:19"
    },
    "1198": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0"
    },
    "1199": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1"
    },
    "1200": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2"
    },
    "1201": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3"
    },
    "1202": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4"
    },
    "1203": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5"
    },
    "1204": {
        "name_en": "7:6",
        "name_aa": "7:6",
        "name_de": "7:6",
        "name_nl": "7:6",
        "name_pl": "7:6",
        "name_ro": "7:6",
        "name_ru": "7:6",
        "name_tr": "7:6",
        "name_se": "7:6",
        "name_el": "7:6"
    },
    "1205": {
        "name_en": "7:7",
        "name_aa": "7:7",
        "name_de": "7:7",
        "name_nl": "7:7",
        "name_pl": "7:7",
        "name_ro": "7:7",
        "name_ru": "7:7",
        "name_tr": "7:7",
        "name_se": "7:7",
        "name_el": "7:7"
    },
    "1206": {
        "name_en": "7:8",
        "name_aa": "7:8",
        "name_de": "7:8",
        "name_nl": "7:8",
        "name_pl": "7:8",
        "name_ro": "7:8",
        "name_ru": "7:8",
        "name_tr": "7:8",
        "name_se": "7:8",
        "name_el": "7:8"
    },
    "1207": {
        "name_en": "7:9",
        "name_aa": "7:9",
        "name_de": "7:9",
        "name_nl": "7:9",
        "name_pl": "7:9",
        "name_ro": "7:9",
        "name_ru": "7:9",
        "name_tr": "7:9",
        "name_se": "7:9",
        "name_el": "7:9"
    },
    "1208": {
        "name_en": "7:10",
        "name_aa": "7:10",
        "name_de": "7:10",
        "name_nl": "7:10",
        "name_pl": "7:10",
        "name_ro": "7:10",
        "name_ru": "7:10",
        "name_tr": "7:10",
        "name_se": "7:10",
        "name_el": "7:10"
    },
    "1209": {
        "name_en": "7:11",
        "name_aa": "7:11",
        "name_de": "7:11",
        "name_nl": "7:11",
        "name_pl": "7:11",
        "name_ro": "7:11",
        "name_ru": "7:11",
        "name_tr": "7:11",
        "name_se": "7:11",
        "name_el": "7:11"
    },
    "1210": {
        "name_en": "7:12",
        "name_aa": "7:12",
        "name_de": "7:12",
        "name_nl": "7:12",
        "name_pl": "7:12",
        "name_ro": "7:12",
        "name_ru": "7:12",
        "name_tr": "7:12",
        "name_se": "7:12",
        "name_el": "7:12"
    },
    "1211": {
        "name_en": "7:13",
        "name_aa": "7:13",
        "name_de": "7:13",
        "name_nl": "7:13",
        "name_pl": "7:13",
        "name_ro": "7:13",
        "name_ru": "7:13",
        "name_tr": "7:13",
        "name_se": "7:13",
        "name_el": "7:13"
    },
    "1212": {
        "name_en": "7:14",
        "name_aa": "7:14",
        "name_de": "7:14",
        "name_nl": "7:14",
        "name_pl": "7:14",
        "name_ro": "7:14",
        "name_ru": "7:14",
        "name_tr": "7:14",
        "name_se": "7:14",
        "name_el": "7:14"
    },
    "1213": {
        "name_en": "7:15",
        "name_aa": "7:15",
        "name_de": "7:15",
        "name_nl": "7:15",
        "name_pl": "7:15",
        "name_ro": "7:15",
        "name_ru": "7:15",
        "name_tr": "7:15",
        "name_se": "7:15",
        "name_el": "7:15"
    },
    "1214": {
        "name_en": "7:16",
        "name_aa": "7:16",
        "name_de": "7:16",
        "name_nl": "7:16",
        "name_pl": "7:16",
        "name_ro": "7:16",
        "name_ru": "7:16",
        "name_tr": "7:16",
        "name_se": "7:16",
        "name_el": "7:16"
    },
    "1215": {
        "name_en": "7:17",
        "name_aa": "7:17",
        "name_de": "7:17",
        "name_nl": "7:17",
        "name_pl": "7:17",
        "name_ro": "7:17",
        "name_ru": "7:17",
        "name_tr": "7:17",
        "name_se": "7:17",
        "name_el": "7:17"
    },
    "1216": {
        "name_en": "7:18",
        "name_aa": "7:18",
        "name_de": "7:18",
        "name_nl": "7:18",
        "name_pl": "7:18",
        "name_ro": "7:18",
        "name_ru": "7:18",
        "name_tr": "7:18",
        "name_se": "7:18",
        "name_el": "7:18"
    },
    "1217": {
        "name_en": "7:19",
        "name_aa": "7:19",
        "name_de": "7:19",
        "name_nl": "7:19",
        "name_pl": "7:19",
        "name_ro": "7:19",
        "name_ru": "7:19",
        "name_tr": "7:19",
        "name_se": "7:19",
        "name_el": "7:19"
    },
    "1218": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0"
    },
    "1219": {
        "name_en": "8:1",
        "name_aa": "8:1",
        "name_de": "8:1",
        "name_nl": "8:1",
        "name_pl": "8:1",
        "name_ro": "8:1",
        "name_ru": "8:1",
        "name_tr": "8:1",
        "name_se": "8:1",
        "name_el": "8:1"
    },
    "1220": {
        "name_en": "8:2",
        "name_aa": "8:2",
        "name_de": "8:2",
        "name_nl": "8:2",
        "name_pl": "8:2",
        "name_ro": "8:2",
        "name_ru": "8:2",
        "name_tr": "8:2",
        "name_se": "8:2",
        "name_el": "8:2"
    },
    "1221": {
        "name_en": "8:3",
        "name_aa": "8:3",
        "name_de": "8:3",
        "name_nl": "8:3",
        "name_pl": "8:3",
        "name_ro": "8:3",
        "name_ru": "8:3",
        "name_tr": "8:3",
        "name_se": "8:3",
        "name_el": "8:3"
    },
    "1222": {
        "name_en": "8:4",
        "name_aa": "8:4",
        "name_de": "8:4",
        "name_nl": "8:4",
        "name_pl": "8:4",
        "name_ro": "8:4",
        "name_ru": "8:4",
        "name_tr": "8:4",
        "name_se": "8:4",
        "name_el": "8:4"
    },
    "1223": {
        "name_en": "8:5",
        "name_aa": "8:5",
        "name_de": "8:5",
        "name_nl": "8:5",
        "name_pl": "8:5",
        "name_ro": "8:5",
        "name_ru": "8:5",
        "name_tr": "8:5",
        "name_se": "8:5",
        "name_el": "8:5"
    },
    "1224": {
        "name_en": "8:6",
        "name_aa": "8:6",
        "name_de": "8:6",
        "name_nl": "8:6",
        "name_pl": "8:6",
        "name_ro": "8:6",
        "name_ru": "8:6",
        "name_tr": "8:6",
        "name_se": "8:6",
        "name_el": "8:6"
    },
    "1225": {
        "name_en": "8:7",
        "name_aa": "8:7",
        "name_de": "8:7",
        "name_nl": "8:7",
        "name_pl": "8:7",
        "name_ro": "8:7",
        "name_ru": "8:7",
        "name_tr": "8:7",
        "name_se": "8:7",
        "name_el": "8:7"
    },
    "1226": {
        "name_en": "8:8",
        "name_aa": "8:8",
        "name_de": "8:8",
        "name_nl": "8:8",
        "name_pl": "8:8",
        "name_ro": "8:8",
        "name_ru": "8:8",
        "name_tr": "8:8",
        "name_se": "8:8",
        "name_el": "8:8"
    },
    "1227": {
        "name_en": "8:9",
        "name_aa": "8:9",
        "name_de": "8:9",
        "name_nl": "8:9",
        "name_pl": "8:9",
        "name_ro": "8:9",
        "name_ru": "8:9",
        "name_tr": "8:9",
        "name_se": "8:9",
        "name_el": "8:9"
    },
    "1228": {
        "name_en": "8:10",
        "name_aa": "8:10",
        "name_de": "8:10",
        "name_nl": "8:10",
        "name_pl": "8:10",
        "name_ro": "8:10",
        "name_ru": "8:10",
        "name_tr": "8:10",
        "name_se": "8:10",
        "name_el": "8:10"
    },
    "1229": {
        "name_en": "8:11",
        "name_aa": "8:11",
        "name_de": "8:11",
        "name_nl": "8:11",
        "name_pl": "8:11",
        "name_ro": "8:11",
        "name_ru": "8:11",
        "name_tr": "8:11",
        "name_se": "8:11",
        "name_el": "8:11"
    },
    "1230": {
        "name_en": "8:12",
        "name_aa": "8:12",
        "name_de": "8:12",
        "name_nl": "8:12",
        "name_pl": "8:12",
        "name_ro": "8:12",
        "name_ru": "8:12",
        "name_tr": "8:12",
        "name_se": "8:12",
        "name_el": "8:12"
    },
    "1231": {
        "name_en": "8:13",
        "name_aa": "8:13",
        "name_de": "8:13",
        "name_nl": "8:13",
        "name_pl": "8:13",
        "name_ro": "8:13",
        "name_ru": "8:13",
        "name_tr": "8:13",
        "name_se": "8:13",
        "name_el": "8:13"
    },
    "1232": {
        "name_en": "8:14",
        "name_aa": "8:14",
        "name_de": "8:14",
        "name_nl": "8:14",
        "name_pl": "8:14",
        "name_ro": "8:14",
        "name_ru": "8:14",
        "name_tr": "8:14",
        "name_se": "8:14",
        "name_el": "8:14"
    },
    "1233": {
        "name_en": "8:15",
        "name_aa": "8:15",
        "name_de": "8:15",
        "name_nl": "8:15",
        "name_pl": "8:15",
        "name_ro": "8:15",
        "name_ru": "8:15",
        "name_tr": "8:15",
        "name_se": "8:15",
        "name_el": "8:15"
    },
    "1234": {
        "name_en": "8:16",
        "name_aa": "8:16",
        "name_de": "8:16",
        "name_nl": "8:16",
        "name_pl": "8:16",
        "name_ro": "8:16",
        "name_ru": "8:16",
        "name_tr": "8:16",
        "name_se": "8:16",
        "name_el": "8:16"
    },
    "1235": {
        "name_en": "8:17",
        "name_aa": "8:17",
        "name_de": "8:17",
        "name_nl": "8:17",
        "name_pl": "8:17",
        "name_ro": "8:17",
        "name_ru": "8:17",
        "name_tr": "8:17",
        "name_se": "8:17",
        "name_el": "8:17"
    },
    "1236": {
        "name_en": "8:18",
        "name_aa": "8:18",
        "name_de": "8:18",
        "name_nl": "8:18",
        "name_pl": "8:18",
        "name_ro": "8:18",
        "name_ru": "8:18",
        "name_tr": "8:18",
        "name_se": "8:18",
        "name_el": "8:18"
    },
    "1237": {
        "name_en": "8:19",
        "name_aa": "8:19",
        "name_de": "8:19",
        "name_nl": "8:19",
        "name_pl": "8:19",
        "name_ro": "8:19",
        "name_ru": "8:19",
        "name_tr": "8:19",
        "name_se": "8:19",
        "name_el": "8:19"
    },
    "1238": {
        "name_en": "9:0",
        "name_aa": "9:0",
        "name_de": "9:0",
        "name_nl": "9:0",
        "name_pl": "9:0",
        "name_ro": "9:0",
        "name_ru": "9:0",
        "name_tr": "9:0",
        "name_se": "9:0",
        "name_el": "9:0"
    },
    "1239": {
        "name_en": "9:1",
        "name_aa": "9:1",
        "name_de": "9:1",
        "name_nl": "9:1",
        "name_pl": "9:1",
        "name_ro": "9:1",
        "name_ru": "9:1",
        "name_tr": "9:1",
        "name_se": "9:1",
        "name_el": "9:1"
    },
    "1240": {
        "name_en": "9:2",
        "name_aa": "9:2",
        "name_de": "9:2",
        "name_nl": "9:2",
        "name_pl": "9:2",
        "name_ro": "9:2",
        "name_ru": "9:2",
        "name_tr": "9:2",
        "name_se": "9:2",
        "name_el": "9:2"
    },
    "1241": {
        "name_en": "9:3",
        "name_aa": "9:3",
        "name_de": "9:3",
        "name_nl": "9:3",
        "name_pl": "9:3",
        "name_ro": "9:3",
        "name_ru": "9:3",
        "name_tr": "9:3",
        "name_se": "9:3",
        "name_el": "9:3"
    },
    "1242": {
        "name_en": "9:4",
        "name_aa": "9:4",
        "name_de": "9:4",
        "name_nl": "9:4",
        "name_pl": "9:4",
        "name_ro": "9:4",
        "name_ru": "9:4",
        "name_tr": "9:4",
        "name_se": "9:4",
        "name_el": "9:4"
    },
    "1243": {
        "name_en": "9:5",
        "name_aa": "9:5",
        "name_de": "9:5",
        "name_nl": "9:5",
        "name_pl": "9:5",
        "name_ro": "9:5",
        "name_ru": "9:5",
        "name_tr": "9:5",
        "name_se": "9:5",
        "name_el": "9:5"
    },
    "1244": {
        "name_en": "9:6",
        "name_aa": "9:6",
        "name_de": "9:6",
        "name_nl": "9:6",
        "name_pl": "9:6",
        "name_ro": "9:6",
        "name_ru": "9:6",
        "name_tr": "9:6",
        "name_se": "9:6",
        "name_el": "9:6"
    },
    "1245": {
        "name_en": "9:7",
        "name_aa": "9:7",
        "name_de": "9:7",
        "name_nl": "9:7",
        "name_pl": "9:7",
        "name_ro": "9:7",
        "name_ru": "9:7",
        "name_tr": "9:7",
        "name_se": "9:7",
        "name_el": "9:7"
    },
    "1246": {
        "name_en": "9:8",
        "name_aa": "9:8",
        "name_de": "9:8",
        "name_nl": "9:8",
        "name_pl": "9:8",
        "name_ro": "9:8",
        "name_ru": "9:8",
        "name_tr": "9:8",
        "name_se": "9:8",
        "name_el": "9:8"
    },
    "1247": {
        "name_en": "9:9",
        "name_aa": "9:9",
        "name_de": "9:9",
        "name_nl": "9:9",
        "name_pl": "9:9",
        "name_ro": "9:9",
        "name_ru": "9:9",
        "name_tr": "9:9",
        "name_se": "9:9",
        "name_el": "9:9"
    },
    "1248": {
        "name_en": "9:10",
        "name_aa": "9:10",
        "name_de": "9:10",
        "name_nl": "9:10",
        "name_pl": "9:10",
        "name_ro": "9:10",
        "name_ru": "9:10",
        "name_tr": "9:10",
        "name_se": "9:10",
        "name_el": "9:10"
    },
    "1249": {
        "name_en": "9:11",
        "name_aa": "9:11",
        "name_de": "9:11",
        "name_nl": "9:11",
        "name_pl": "9:11",
        "name_ro": "9:11",
        "name_ru": "9:11",
        "name_tr": "9:11",
        "name_se": "9:11",
        "name_el": "9:11"
    },
    "1250": {
        "name_en": "9:12",
        "name_aa": "9:12",
        "name_de": "9:12",
        "name_nl": "9:12",
        "name_pl": "9:12",
        "name_ro": "9:12",
        "name_ru": "9:12",
        "name_tr": "9:12",
        "name_se": "9:12",
        "name_el": "9:12"
    },
    "1251": {
        "name_en": "9:13",
        "name_aa": "9:13",
        "name_de": "9:13",
        "name_nl": "9:13",
        "name_pl": "9:13",
        "name_ro": "9:13",
        "name_ru": "9:13",
        "name_tr": "9:13",
        "name_se": "9:13",
        "name_el": "9:13"
    },
    "1252": {
        "name_en": "9:14",
        "name_aa": "9:14",
        "name_de": "9:14",
        "name_nl": "9:14",
        "name_pl": "9:14",
        "name_ro": "9:14",
        "name_ru": "9:14",
        "name_tr": "9:14",
        "name_se": "9:14",
        "name_el": "9:14"
    },
    "1253": {
        "name_en": "9:15",
        "name_aa": "9:15",
        "name_de": "9:15",
        "name_nl": "9:15",
        "name_pl": "9:15",
        "name_ro": "9:15",
        "name_ru": "9:15",
        "name_tr": "9:15",
        "name_se": "9:15",
        "name_el": "9:15"
    },
    "1254": {
        "name_en": "9:16",
        "name_aa": "9:16",
        "name_de": "9:16",
        "name_nl": "9:16",
        "name_pl": "9:16",
        "name_ro": "9:16",
        "name_ru": "9:16",
        "name_tr": "9:16",
        "name_se": "9:16",
        "name_el": "9:16"
    },
    "1255": {
        "name_en": "9:17",
        "name_aa": "9:17",
        "name_de": "9:17",
        "name_nl": "9:17",
        "name_pl": "9:17",
        "name_ro": "9:17",
        "name_ru": "9:17",
        "name_tr": "9:17",
        "name_se": "9:17",
        "name_el": "9:17"
    },
    "1256": {
        "name_en": "9:18",
        "name_aa": "9:18",
        "name_de": "9:18",
        "name_nl": "9:18",
        "name_pl": "9:18",
        "name_ro": "9:18",
        "name_ru": "9:18",
        "name_tr": "9:18",
        "name_se": "9:18",
        "name_el": "9:18"
    },
    "1257": {
        "name_en": "9:19",
        "name_aa": "9:19",
        "name_de": "9:19",
        "name_nl": "9:19",
        "name_pl": "9:19",
        "name_ro": "9:19",
        "name_ru": "9:19",
        "name_tr": "9:19",
        "name_se": "9:19",
        "name_el": "9:19"
    },
    "1258": {
        "name_en": "10:0",
        "name_aa": "10:0",
        "name_de": "10:0",
        "name_nl": "10:0",
        "name_pl": "10:0",
        "name_ro": "10:0",
        "name_ru": "10:0",
        "name_tr": "10:0",
        "name_se": "10:0",
        "name_el": "10:0"
    },
    "1259": {
        "name_en": "10:1",
        "name_aa": "10:1",
        "name_de": "10:1",
        "name_nl": "10:1",
        "name_pl": "10:1",
        "name_ro": "10:1",
        "name_ru": "10:1",
        "name_tr": "10:1",
        "name_se": "10:1",
        "name_el": "10:1"
    },
    "1260": {
        "name_en": "10:2",
        "name_aa": "10:2",
        "name_de": "10:2",
        "name_nl": "10:2",
        "name_pl": "10:2",
        "name_ro": "10:2",
        "name_ru": "10:2",
        "name_tr": "10:2",
        "name_se": "10:2",
        "name_el": "10:2"
    },
    "1261": {
        "name_en": "10:3",
        "name_aa": "10:3",
        "name_de": "10:3",
        "name_nl": "10:3",
        "name_pl": "10:3",
        "name_ro": "10:3",
        "name_ru": "10:3",
        "name_tr": "10:3",
        "name_se": "10:3",
        "name_el": "10:3"
    },
    "1262": {
        "name_en": "10:4",
        "name_aa": "10:4",
        "name_de": "10:4",
        "name_nl": "10:4",
        "name_pl": "10:4",
        "name_ro": "10:4",
        "name_ru": "10:4",
        "name_tr": "10:4",
        "name_se": "10:4",
        "name_el": "10:4"
    },
    "1263": {
        "name_en": "10:5",
        "name_aa": "10:5",
        "name_de": "10:5",
        "name_nl": "10:5",
        "name_pl": "10:5",
        "name_ro": "10:5",
        "name_ru": "10:5",
        "name_tr": "10:5",
        "name_se": "10:5",
        "name_el": "10:5"
    },
    "1264": {
        "name_en": "10:6",
        "name_aa": "10:6",
        "name_de": "10:6",
        "name_nl": "10:6",
        "name_pl": "10:6",
        "name_ro": "10:6",
        "name_ru": "10:6",
        "name_tr": "10:6",
        "name_se": "10:6",
        "name_el": "10:6"
    },
    "1265": {
        "name_en": "10:7",
        "name_aa": "10:7",
        "name_de": "10:7",
        "name_nl": "10:7",
        "name_pl": "10:7",
        "name_ro": "10:7",
        "name_ru": "10:7",
        "name_tr": "10:7",
        "name_se": "10:7",
        "name_el": "10:7"
    },
    "1266": {
        "name_en": "10:8",
        "name_aa": "10:8",
        "name_de": "10:8",
        "name_nl": "10:8",
        "name_pl": "10:8",
        "name_ro": "10:8",
        "name_ru": "10:8",
        "name_tr": "10:8",
        "name_se": "10:8",
        "name_el": "10:8"
    },
    "1267": {
        "name_en": "10:9",
        "name_aa": "10:9",
        "name_de": "10:9",
        "name_nl": "10:9",
        "name_pl": "10:9",
        "name_ro": "10:9",
        "name_ru": "10:9",
        "name_tr": "10:9",
        "name_se": "10:9",
        "name_el": "10:9"
    },
    "1268": {
        "name_en": "10:10",
        "name_aa": "10:10",
        "name_de": "10:10",
        "name_nl": "10:10",
        "name_pl": "10:10",
        "name_ro": "10:10",
        "name_ru": "10:10",
        "name_tr": "10:10",
        "name_se": "10:10",
        "name_el": "10:10"
    },
    "1269": {
        "name_en": "10:11",
        "name_aa": "10:11",
        "name_de": "10:11",
        "name_nl": "10:11",
        "name_pl": "10:11",
        "name_ro": "10:11",
        "name_ru": "10:11",
        "name_tr": "10:11",
        "name_se": "10:11",
        "name_el": "10:11"
    },
    "1270": {
        "name_en": "10:12",
        "name_aa": "10:12",
        "name_de": "10:12",
        "name_nl": "10:12",
        "name_pl": "10:12",
        "name_ro": "10:12",
        "name_ru": "10:12",
        "name_tr": "10:12",
        "name_se": "10:12",
        "name_el": "10:12"
    },
    "1271": {
        "name_en": "10:13",
        "name_aa": "10:13",
        "name_de": "10:13",
        "name_nl": "10:13",
        "name_pl": "10:13",
        "name_ro": "10:13",
        "name_ru": "10:13",
        "name_tr": "10:13",
        "name_se": "10:13",
        "name_el": "10:13"
    },
    "1272": {
        "name_en": "10:14",
        "name_aa": "10:14",
        "name_de": "10:14",
        "name_nl": "10:14",
        "name_pl": "10:14",
        "name_ro": "10:14",
        "name_ru": "10:14",
        "name_tr": "10:14",
        "name_se": "10:14",
        "name_el": "10:14"
    },
    "1273": {
        "name_en": "10:15",
        "name_aa": "10:15",
        "name_de": "10:15",
        "name_nl": "10:15",
        "name_pl": "10:15",
        "name_ro": "10:15",
        "name_ru": "10:15",
        "name_tr": "10:15",
        "name_se": "10:15",
        "name_el": "10:15"
    },
    "1274": {
        "name_en": "10:16",
        "name_aa": "10:16",
        "name_de": "10:16",
        "name_nl": "10:16",
        "name_pl": "10:16",
        "name_ro": "10:16",
        "name_ru": "10:16",
        "name_tr": "10:16",
        "name_se": "10:16",
        "name_el": "10:16"
    },
    "1275": {
        "name_en": "10:17",
        "name_aa": "10:17",
        "name_de": "10:17",
        "name_nl": "10:17",
        "name_pl": "10:17",
        "name_ro": "10:17",
        "name_ru": "10:17",
        "name_tr": "10:17",
        "name_se": "10:17",
        "name_el": "10:17"
    },
    "1276": {
        "name_en": "10:18",
        "name_aa": "10:18",
        "name_de": "10:18",
        "name_nl": "10:18",
        "name_pl": "10:18",
        "name_ro": "10:18",
        "name_ru": "10:18",
        "name_tr": "10:18",
        "name_se": "10:18",
        "name_el": "10:18"
    },
    "1277": {
        "name_en": "10:19",
        "name_aa": "10:19",
        "name_de": "10:19",
        "name_nl": "10:19",
        "name_pl": "10:19",
        "name_ro": "10:19",
        "name_ru": "10:19",
        "name_tr": "10:19",
        "name_se": "10:19",
        "name_el": "10:19"
    },
    "1278": {
        "name_en": "11:0",
        "name_aa": "11:0",
        "name_de": "11:0",
        "name_nl": "11:0",
        "name_pl": "11:0",
        "name_ro": "11:0",
        "name_ru": "11:0",
        "name_tr": "11:0",
        "name_se": "11:0",
        "name_el": "11:0"
    },
    "1279": {
        "name_en": "11:1",
        "name_aa": "11:1",
        "name_de": "11:1",
        "name_nl": "11:1",
        "name_pl": "11:1",
        "name_ro": "11:1",
        "name_ru": "11:1",
        "name_tr": "11:1",
        "name_se": "11:1",
        "name_el": "11:1"
    },
    "1280": {
        "name_en": "11:2",
        "name_aa": "11:2",
        "name_de": "11:2",
        "name_nl": "11:2",
        "name_pl": "11:2",
        "name_ro": "11:2",
        "name_ru": "11:2",
        "name_tr": "11:2",
        "name_se": "11:2",
        "name_el": "11:2"
    },
    "1281": {
        "name_en": "11:3",
        "name_aa": "11:3",
        "name_de": "11:3",
        "name_nl": "11:3",
        "name_pl": "11:3",
        "name_ro": "11:3",
        "name_ru": "11:3",
        "name_tr": "11:3",
        "name_se": "11:3",
        "name_el": "11:3"
    },
    "1282": {
        "name_en": "11:4",
        "name_aa": "11:4",
        "name_de": "11:4",
        "name_nl": "11:4",
        "name_pl": "11:4",
        "name_ro": "11:4",
        "name_ru": "11:4",
        "name_tr": "11:4",
        "name_se": "11:4",
        "name_el": "11:4"
    },
    "1283": {
        "name_en": "11:5",
        "name_aa": "11:5",
        "name_de": "11:5",
        "name_nl": "11:5",
        "name_pl": "11:5",
        "name_ro": "11:5",
        "name_ru": "11:5",
        "name_tr": "11:5",
        "name_se": "11:5",
        "name_el": "11:5"
    },
    "1284": {
        "name_en": "11:6",
        "name_aa": "11:6",
        "name_de": "11:6",
        "name_nl": "11:6",
        "name_pl": "11:6",
        "name_ro": "11:6",
        "name_ru": "11:6",
        "name_tr": "11:6",
        "name_se": "11:6",
        "name_el": "11:6"
    },
    "1285": {
        "name_en": "11:7",
        "name_aa": "11:7",
        "name_de": "11:7",
        "name_nl": "11:7",
        "name_pl": "11:7",
        "name_ro": "11:7",
        "name_ru": "11:7",
        "name_tr": "11:7",
        "name_se": "11:7",
        "name_el": "11:7"
    },
    "1286": {
        "name_en": "11:8",
        "name_aa": "11:8",
        "name_de": "11:8",
        "name_nl": "11:8",
        "name_pl": "11:8",
        "name_ro": "11:8",
        "name_ru": "11:8",
        "name_tr": "11:8",
        "name_se": "11:8",
        "name_el": "11:8"
    },
    "1287": {
        "name_en": "11:9",
        "name_aa": "11:9",
        "name_de": "11:9",
        "name_nl": "11:9",
        "name_pl": "11:9",
        "name_ro": "11:9",
        "name_ru": "11:9",
        "name_tr": "11:9",
        "name_se": "11:9",
        "name_el": "11:9"
    },
    "1288": {
        "name_en": "11:10",
        "name_aa": "11:10",
        "name_de": "11:10",
        "name_nl": "11:10",
        "name_pl": "11:10",
        "name_ro": "11:10",
        "name_ru": "11:10",
        "name_tr": "11:10",
        "name_se": "11:10",
        "name_el": "11:10"
    },
    "1289": {
        "name_en": "11:11",
        "name_aa": "11:11",
        "name_de": "11:11",
        "name_nl": "11:11",
        "name_pl": "11:11",
        "name_ro": "11:11",
        "name_ru": "11:11",
        "name_tr": "11:11",
        "name_se": "11:11",
        "name_el": "11:11"
    },
    "1290": {
        "name_en": "11:12",
        "name_aa": "11:12",
        "name_de": "11:12",
        "name_nl": "11:12",
        "name_pl": "11:12",
        "name_ro": "11:12",
        "name_ru": "11:12",
        "name_tr": "11:12",
        "name_se": "11:12",
        "name_el": "11:12"
    },
    "1291": {
        "name_en": "11:13",
        "name_aa": "11:13",
        "name_de": "11:13",
        "name_nl": "11:13",
        "name_pl": "11:13",
        "name_ro": "11:13",
        "name_ru": "11:13",
        "name_tr": "11:13",
        "name_se": "11:13",
        "name_el": "11:13"
    },
    "1292": {
        "name_en": "11:14",
        "name_aa": "11:14",
        "name_de": "11:14",
        "name_nl": "11:14",
        "name_pl": "11:14",
        "name_ro": "11:14",
        "name_ru": "11:14",
        "name_tr": "11:14",
        "name_se": "11:14",
        "name_el": "11:14"
    },
    "1293": {
        "name_en": "11:15",
        "name_aa": "11:15",
        "name_de": "11:15",
        "name_nl": "11:15",
        "name_pl": "11:15",
        "name_ro": "11:15",
        "name_ru": "11:15",
        "name_tr": "11:15",
        "name_se": "11:15",
        "name_el": "11:15"
    },
    "1294": {
        "name_en": "11:16",
        "name_aa": "11:16",
        "name_de": "11:16",
        "name_nl": "11:16",
        "name_pl": "11:16",
        "name_ro": "11:16",
        "name_ru": "11:16",
        "name_tr": "11:16",
        "name_se": "11:16",
        "name_el": "11:16"
    },
    "1295": {
        "name_en": "11:17",
        "name_aa": "11:17",
        "name_de": "11:17",
        "name_nl": "11:17",
        "name_pl": "11:17",
        "name_ro": "11:17",
        "name_ru": "11:17",
        "name_tr": "11:17",
        "name_se": "11:17",
        "name_el": "11:17"
    },
    "1296": {
        "name_en": "11:18",
        "name_aa": "11:18",
        "name_de": "11:18",
        "name_nl": "11:18",
        "name_pl": "11:18",
        "name_ro": "11:18",
        "name_ru": "11:18",
        "name_tr": "11:18",
        "name_se": "11:18",
        "name_el": "11:18"
    },
    "1297": {
        "name_en": "11:19",
        "name_aa": "11:19",
        "name_de": "11:19",
        "name_nl": "11:19",
        "name_pl": "11:19",
        "name_ro": "11:19",
        "name_ru": "11:19",
        "name_tr": "11:19",
        "name_se": "11:19",
        "name_el": "11:19"
    },
    "1298": {
        "name_en": "12:0",
        "name_aa": "12:0",
        "name_de": "12:0",
        "name_nl": "12:0",
        "name_pl": "12:0",
        "name_ro": "12:0",
        "name_ru": "12:0",
        "name_tr": "12:0",
        "name_se": "12:0",
        "name_el": "12:0"
    },
    "1299": {
        "name_en": "12:1",
        "name_aa": "12:1",
        "name_de": "12:1",
        "name_nl": "12:1",
        "name_pl": "12:1",
        "name_ro": "12:1",
        "name_ru": "12:1",
        "name_tr": "12:1",
        "name_se": "12:1",
        "name_el": "12:1"
    },
    "1300": {
        "name_en": "12:2",
        "name_aa": "12:2",
        "name_de": "12:2",
        "name_nl": "12:2",
        "name_pl": "12:2",
        "name_ro": "12:2",
        "name_ru": "12:2",
        "name_tr": "12:2",
        "name_se": "12:2",
        "name_el": "12:2"
    },
    "1301": {
        "name_en": "12:3",
        "name_aa": "12:3",
        "name_de": "12:3",
        "name_nl": "12:3",
        "name_pl": "12:3",
        "name_ro": "12:3",
        "name_ru": "12:3",
        "name_tr": "12:3",
        "name_se": "12:3",
        "name_el": "12:3"
    },
    "1302": {
        "name_en": "12:4",
        "name_aa": "12:4",
        "name_de": "12:4",
        "name_nl": "12:4",
        "name_pl": "12:4",
        "name_ro": "12:4",
        "name_ru": "12:4",
        "name_tr": "12:4",
        "name_se": "12:4",
        "name_el": "12:4"
    },
    "1303": {
        "name_en": "12:5",
        "name_aa": "12:5",
        "name_de": "12:5",
        "name_nl": "12:5",
        "name_pl": "12:5",
        "name_ro": "12:5",
        "name_ru": "12:5",
        "name_tr": "12:5",
        "name_se": "12:5",
        "name_el": "12:5"
    },
    "1304": {
        "name_en": "12:6",
        "name_aa": "12:6",
        "name_de": "12:6",
        "name_nl": "12:6",
        "name_pl": "12:6",
        "name_ro": "12:6",
        "name_ru": "12:6",
        "name_tr": "12:6",
        "name_se": "12:6",
        "name_el": "12:6"
    },
    "1305": {
        "name_en": "12:7",
        "name_aa": "12:7",
        "name_de": "12:7",
        "name_nl": "12:7",
        "name_pl": "12:7",
        "name_ro": "12:7",
        "name_ru": "12:7",
        "name_tr": "12:7",
        "name_se": "12:7",
        "name_el": "12:7"
    },
    "1306": {
        "name_en": "12:8",
        "name_aa": "12:8",
        "name_de": "12:8",
        "name_nl": "12:8",
        "name_pl": "12:8",
        "name_ro": "12:8",
        "name_ru": "12:8",
        "name_tr": "12:8",
        "name_se": "12:8",
        "name_el": "12:8"
    },
    "1307": {
        "name_en": "12:9",
        "name_aa": "12:9",
        "name_de": "12:9",
        "name_nl": "12:9",
        "name_pl": "12:9",
        "name_ro": "12:9",
        "name_ru": "12:9",
        "name_tr": "12:9",
        "name_se": "12:9",
        "name_el": "12:9"
    },
    "1308": {
        "name_en": "12:10",
        "name_aa": "12:10",
        "name_de": "12:10",
        "name_nl": "12:10",
        "name_pl": "12:10",
        "name_ro": "12:10",
        "name_ru": "12:10",
        "name_tr": "12:10",
        "name_se": "12:10",
        "name_el": "12:10"
    },
    "1309": {
        "name_en": "12:11",
        "name_aa": "12:11",
        "name_de": "12:11",
        "name_nl": "12:11",
        "name_pl": "12:11",
        "name_ro": "12:11",
        "name_ru": "12:11",
        "name_tr": "12:11",
        "name_se": "12:11",
        "name_el": "12:11"
    },
    "1310": {
        "name_en": "12:12",
        "name_aa": "12:12",
        "name_de": "12:12",
        "name_nl": "12:12",
        "name_pl": "12:12",
        "name_ro": "12:12",
        "name_ru": "12:12",
        "name_tr": "12:12",
        "name_se": "12:12",
        "name_el": "12:12"
    },
    "1311": {
        "name_en": "12:13",
        "name_aa": "12:13",
        "name_de": "12:13",
        "name_nl": "12:13",
        "name_pl": "12:13",
        "name_ro": "12:13",
        "name_ru": "12:13",
        "name_tr": "12:13",
        "name_se": "12:13",
        "name_el": "12:13"
    },
    "1312": {
        "name_en": "12:14",
        "name_aa": "12:14",
        "name_de": "12:14",
        "name_nl": "12:14",
        "name_pl": "12:14",
        "name_ro": "12:14",
        "name_ru": "12:14",
        "name_tr": "12:14",
        "name_se": "12:14",
        "name_el": "12:14"
    },
    "1313": {
        "name_en": "12:15",
        "name_aa": "12:15",
        "name_de": "12:15",
        "name_nl": "12:15",
        "name_pl": "12:15",
        "name_ro": "12:15",
        "name_ru": "12:15",
        "name_tr": "12:15",
        "name_se": "12:15",
        "name_el": "12:15"
    },
    "1314": {
        "name_en": "12:16",
        "name_aa": "12:16",
        "name_de": "12:16",
        "name_nl": "12:16",
        "name_pl": "12:16",
        "name_ro": "12:16",
        "name_ru": "12:16",
        "name_tr": "12:16",
        "name_se": "12:16",
        "name_el": "12:16"
    },
    "1315": {
        "name_en": "12:17",
        "name_aa": "12:17",
        "name_de": "12:17",
        "name_nl": "12:17",
        "name_pl": "12:17",
        "name_ro": "12:17",
        "name_ru": "12:17",
        "name_tr": "12:17",
        "name_se": "12:17",
        "name_el": "12:17"
    },
    "1316": {
        "name_en": "12:18",
        "name_aa": "12:18",
        "name_de": "12:18",
        "name_nl": "12:18",
        "name_pl": "12:18",
        "name_ro": "12:18",
        "name_ru": "12:18",
        "name_tr": "12:18",
        "name_se": "12:18",
        "name_el": "12:18"
    },
    "1317": {
        "name_en": "12:19",
        "name_aa": "12:19",
        "name_de": "12:19",
        "name_nl": "12:19",
        "name_pl": "12:19",
        "name_ro": "12:19",
        "name_ru": "12:19",
        "name_tr": "12:19",
        "name_se": "12:19",
        "name_el": "12:19"
    },
    "1318": {
        "name_en": "13:0",
        "name_aa": "13:0",
        "name_de": "13:0",
        "name_nl": "13:0",
        "name_pl": "13:0",
        "name_ro": "13:0",
        "name_ru": "13:0",
        "name_tr": "13:0",
        "name_se": "13:0",
        "name_el": "13:0"
    },
    "1319": {
        "name_en": "13:1",
        "name_aa": "13:1",
        "name_de": "13:1",
        "name_nl": "13:1",
        "name_pl": "13:1",
        "name_ro": "13:1",
        "name_ru": "13:1",
        "name_tr": "13:1",
        "name_se": "13:1",
        "name_el": "13:1"
    },
    "1320": {
        "name_en": "13:2",
        "name_aa": "13:2",
        "name_de": "13:2",
        "name_nl": "13:2",
        "name_pl": "13:2",
        "name_ro": "13:2",
        "name_ru": "13:2",
        "name_tr": "13:2",
        "name_se": "13:2",
        "name_el": "13:2"
    },
    "1321": {
        "name_en": "13:3",
        "name_aa": "13:3",
        "name_de": "13:3",
        "name_nl": "13:3",
        "name_pl": "13:3",
        "name_ro": "13:3",
        "name_ru": "13:3",
        "name_tr": "13:3",
        "name_se": "13:3",
        "name_el": "13:3"
    },
    "1322": {
        "name_en": "13:4",
        "name_aa": "13:4",
        "name_de": "13:4",
        "name_nl": "13:4",
        "name_pl": "13:4",
        "name_ro": "13:4",
        "name_ru": "13:4",
        "name_tr": "13:4",
        "name_se": "13:4",
        "name_el": "13:4"
    },
    "1323": {
        "name_en": "13:5",
        "name_aa": "13:5",
        "name_de": "13:5",
        "name_nl": "13:5",
        "name_pl": "13:5",
        "name_ro": "13:5",
        "name_ru": "13:5",
        "name_tr": "13:5",
        "name_se": "13:5",
        "name_el": "13:5"
    },
    "1324": {
        "name_en": "13:6",
        "name_aa": "13:6",
        "name_de": "13:6",
        "name_nl": "13:6",
        "name_pl": "13:6",
        "name_ro": "13:6",
        "name_ru": "13:6",
        "name_tr": "13:6",
        "name_se": "13:6",
        "name_el": "13:6"
    },
    "1325": {
        "name_en": "13:7",
        "name_aa": "13:7",
        "name_de": "13:7",
        "name_nl": "13:7",
        "name_pl": "13:7",
        "name_ro": "13:7",
        "name_ru": "13:7",
        "name_tr": "13:7",
        "name_se": "13:7",
        "name_el": "13:7"
    },
    "1326": {
        "name_en": "13:8",
        "name_aa": "13:8",
        "name_de": "13:8",
        "name_nl": "13:8",
        "name_pl": "13:8",
        "name_ro": "13:8",
        "name_ru": "13:8",
        "name_tr": "13:8",
        "name_se": "13:8",
        "name_el": "13:8"
    },
    "1327": {
        "name_en": "13:9",
        "name_aa": "13:9",
        "name_de": "13:9",
        "name_nl": "13:9",
        "name_pl": "13:9",
        "name_ro": "13:9",
        "name_ru": "13:9",
        "name_tr": "13:9",
        "name_se": "13:9",
        "name_el": "13:9"
    },
    "1328": {
        "name_en": "13:10",
        "name_aa": "13:10",
        "name_de": "13:10",
        "name_nl": "13:10",
        "name_pl": "13:10",
        "name_ro": "13:10",
        "name_ru": "13:10",
        "name_tr": "13:10",
        "name_se": "13:10",
        "name_el": "13:10"
    },
    "1329": {
        "name_en": "13:11",
        "name_aa": "13:11",
        "name_de": "13:11",
        "name_nl": "13:11",
        "name_pl": "13:11",
        "name_ro": "13:11",
        "name_ru": "13:11",
        "name_tr": "13:11",
        "name_se": "13:11",
        "name_el": "13:11"
    },
    "1330": {
        "name_en": "13:12",
        "name_aa": "13:12",
        "name_de": "13:12",
        "name_nl": "13:12",
        "name_pl": "13:12",
        "name_ro": "13:12",
        "name_ru": "13:12",
        "name_tr": "13:12",
        "name_se": "13:12",
        "name_el": "13:12"
    },
    "1331": {
        "name_en": "13:13",
        "name_aa": "13:13",
        "name_de": "13:13",
        "name_nl": "13:13",
        "name_pl": "13:13",
        "name_ro": "13:13",
        "name_ru": "13:13",
        "name_tr": "13:13",
        "name_se": "13:13",
        "name_el": "13:13"
    },
    "1332": {
        "name_en": "13:14",
        "name_aa": "13:14",
        "name_de": "13:14",
        "name_nl": "13:14",
        "name_pl": "13:14",
        "name_ro": "13:14",
        "name_ru": "13:14",
        "name_tr": "13:14",
        "name_se": "13:14",
        "name_el": "13:14"
    },
    "1333": {
        "name_en": "13:15",
        "name_aa": "13:15",
        "name_de": "13:15",
        "name_nl": "13:15",
        "name_pl": "13:15",
        "name_ro": "13:15",
        "name_ru": "13:15",
        "name_tr": "13:15",
        "name_se": "13:15",
        "name_el": "13:15"
    },
    "1334": {
        "name_en": "13:16",
        "name_aa": "13:16",
        "name_de": "13:16",
        "name_nl": "13:16",
        "name_pl": "13:16",
        "name_ro": "13:16",
        "name_ru": "13:16",
        "name_tr": "13:16",
        "name_se": "13:16",
        "name_el": "13:16"
    },
    "1335": {
        "name_en": "13:17",
        "name_aa": "13:17",
        "name_de": "13:17",
        "name_nl": "13:17",
        "name_pl": "13:17",
        "name_ro": "13:17",
        "name_ru": "13:17",
        "name_tr": "13:17",
        "name_se": "13:17",
        "name_el": "13:17"
    },
    "1336": {
        "name_en": "13:18",
        "name_aa": "13:18",
        "name_de": "13:18",
        "name_nl": "13:18",
        "name_pl": "13:18",
        "name_ro": "13:18",
        "name_ru": "13:18",
        "name_tr": "13:18",
        "name_se": "13:18",
        "name_el": "13:18"
    },
    "1337": {
        "name_en": "13:19",
        "name_aa": "13:19",
        "name_de": "13:19",
        "name_nl": "13:19",
        "name_pl": "13:19",
        "name_ro": "13:19",
        "name_ru": "13:19",
        "name_tr": "13:19",
        "name_se": "13:19",
        "name_el": "13:19"
    },
    "1338": {
        "name_en": "14:0",
        "name_aa": "14:0",
        "name_de": "14:0",
        "name_nl": "14:0",
        "name_pl": "14:0",
        "name_ro": "14:0",
        "name_ru": "14:0",
        "name_tr": "14:0",
        "name_se": "14:0",
        "name_el": "14:0"
    },
    "1339": {
        "name_en": "14:1",
        "name_aa": "14:1",
        "name_de": "14:1",
        "name_nl": "14:1",
        "name_pl": "14:1",
        "name_ro": "14:1",
        "name_ru": "14:1",
        "name_tr": "14:1",
        "name_se": "14:1",
        "name_el": "14:1"
    },
    "1340": {
        "name_en": "14:2",
        "name_aa": "14:2",
        "name_de": "14:2",
        "name_nl": "14:2",
        "name_pl": "14:2",
        "name_ro": "14:2",
        "name_ru": "14:2",
        "name_tr": "14:2",
        "name_se": "14:2",
        "name_el": "14:2"
    },
    "1341": {
        "name_en": "14:3",
        "name_aa": "14:3",
        "name_de": "14:3",
        "name_nl": "14:3",
        "name_pl": "14:3",
        "name_ro": "14:3",
        "name_ru": "14:3",
        "name_tr": "14:3",
        "name_se": "14:3",
        "name_el": "14:3"
    },
    "1342": {
        "name_en": "14:4",
        "name_aa": "14:4",
        "name_de": "14:4",
        "name_nl": "14:4",
        "name_pl": "14:4",
        "name_ro": "14:4",
        "name_ru": "14:4",
        "name_tr": "14:4",
        "name_se": "14:4",
        "name_el": "14:4"
    },
    "1343": {
        "name_en": "14:5",
        "name_aa": "14:5",
        "name_de": "14:5",
        "name_nl": "14:5",
        "name_pl": "14:5",
        "name_ro": "14:5",
        "name_ru": "14:5",
        "name_tr": "14:5",
        "name_se": "14:5",
        "name_el": "14:5"
    },
    "1344": {
        "name_en": "14:6",
        "name_aa": "14:6",
        "name_de": "14:6",
        "name_nl": "14:6",
        "name_pl": "14:6",
        "name_ro": "14:6",
        "name_ru": "14:6",
        "name_tr": "14:6",
        "name_se": "14:6",
        "name_el": "14:6"
    },
    "1345": {
        "name_en": "14:7",
        "name_aa": "14:7",
        "name_de": "14:7",
        "name_nl": "14:7",
        "name_pl": "14:7",
        "name_ro": "14:7",
        "name_ru": "14:7",
        "name_tr": "14:7",
        "name_se": "14:7",
        "name_el": "14:7"
    },
    "1346": {
        "name_en": "14:8",
        "name_aa": "14:8",
        "name_de": "14:8",
        "name_nl": "14:8",
        "name_pl": "14:8",
        "name_ro": "14:8",
        "name_ru": "14:8",
        "name_tr": "14:8",
        "name_se": "14:8",
        "name_el": "14:8"
    },
    "1347": {
        "name_en": "14:9",
        "name_aa": "14:9",
        "name_de": "14:9",
        "name_nl": "14:9",
        "name_pl": "14:9",
        "name_ro": "14:9",
        "name_ru": "14:9",
        "name_tr": "14:9",
        "name_se": "14:9",
        "name_el": "14:9"
    },
    "1348": {
        "name_en": "14:10",
        "name_aa": "14:10",
        "name_de": "14:10",
        "name_nl": "14:10",
        "name_pl": "14:10",
        "name_ro": "14:10",
        "name_ru": "14:10",
        "name_tr": "14:10",
        "name_se": "14:10",
        "name_el": "14:10"
    },
    "1349": {
        "name_en": "14:11",
        "name_aa": "14:11",
        "name_de": "14:11",
        "name_nl": "14:11",
        "name_pl": "14:11",
        "name_ro": "14:11",
        "name_ru": "14:11",
        "name_tr": "14:11",
        "name_se": "14:11",
        "name_el": "14:11"
    },
    "1350": {
        "name_en": "14:12",
        "name_aa": "14:12",
        "name_de": "14:12",
        "name_nl": "14:12",
        "name_pl": "14:12",
        "name_ro": "14:12",
        "name_ru": "14:12",
        "name_tr": "14:12",
        "name_se": "14:12",
        "name_el": "14:12"
    },
    "1351": {
        "name_en": "14:13",
        "name_aa": "14:13",
        "name_de": "14:13",
        "name_nl": "14:13",
        "name_pl": "14:13",
        "name_ro": "14:13",
        "name_ru": "14:13",
        "name_tr": "14:13",
        "name_se": "14:13",
        "name_el": "14:13"
    },
    "1352": {
        "name_en": "14:14",
        "name_aa": "14:14",
        "name_de": "14:14",
        "name_nl": "14:14",
        "name_pl": "14:14",
        "name_ro": "14:14",
        "name_ru": "14:14",
        "name_tr": "14:14",
        "name_se": "14:14",
        "name_el": "14:14"
    },
    "1353": {
        "name_en": "14:15",
        "name_aa": "14:15",
        "name_de": "14:15",
        "name_nl": "14:15",
        "name_pl": "14:15",
        "name_ro": "14:15",
        "name_ru": "14:15",
        "name_tr": "14:15",
        "name_se": "14:15",
        "name_el": "14:15"
    },
    "1354": {
        "name_en": "14:16",
        "name_aa": "14:16",
        "name_de": "14:16",
        "name_nl": "14:16",
        "name_pl": "14:16",
        "name_ro": "14:16",
        "name_ru": "14:16",
        "name_tr": "14:16",
        "name_se": "14:16",
        "name_el": "14:16"
    },
    "1355": {
        "name_en": "14:17",
        "name_aa": "14:17",
        "name_de": "14:17",
        "name_nl": "14:17",
        "name_pl": "14:17",
        "name_ro": "14:17",
        "name_ru": "14:17",
        "name_tr": "14:17",
        "name_se": "14:17",
        "name_el": "14:17"
    },
    "1356": {
        "name_en": "14:18",
        "name_aa": "14:18",
        "name_de": "14:18",
        "name_nl": "14:18",
        "name_pl": "14:18",
        "name_ro": "14:18",
        "name_ru": "14:18",
        "name_tr": "14:18",
        "name_se": "14:18",
        "name_el": "14:18"
    },
    "1357": {
        "name_en": "14:19",
        "name_aa": "14:19",
        "name_de": "14:19",
        "name_nl": "14:19",
        "name_pl": "14:19",
        "name_ro": "14:19",
        "name_ru": "14:19",
        "name_tr": "14:19",
        "name_se": "14:19",
        "name_el": "14:19"
    },
    "1358": {
        "name_en": "15:0",
        "name_aa": "15:0",
        "name_de": "15:0",
        "name_nl": "15:0",
        "name_pl": "15:0",
        "name_ro": "15:0",
        "name_ru": "15:0",
        "name_tr": "15:0",
        "name_se": "15:0",
        "name_el": "15:0"
    },
    "1359": {
        "name_en": "15:1",
        "name_aa": "15:1",
        "name_de": "15:1",
        "name_nl": "15:1",
        "name_pl": "15:1",
        "name_ro": "15:1",
        "name_ru": "15:1",
        "name_tr": "15:1",
        "name_se": "15:1",
        "name_el": "15:1"
    },
    "1360": {
        "name_en": "15:2",
        "name_aa": "15:2",
        "name_de": "15:2",
        "name_nl": "15:2",
        "name_pl": "15:2",
        "name_ro": "15:2",
        "name_ru": "15:2",
        "name_tr": "15:2",
        "name_se": "15:2",
        "name_el": "15:2"
    },
    "1361": {
        "name_en": "15:3",
        "name_aa": "15:3",
        "name_de": "15:3",
        "name_nl": "15:3",
        "name_pl": "15:3",
        "name_ro": "15:3",
        "name_ru": "15:3",
        "name_tr": "15:3",
        "name_se": "15:3",
        "name_el": "15:3"
    },
    "1362": {
        "name_en": "15:4",
        "name_aa": "15:4",
        "name_de": "15:4",
        "name_nl": "15:4",
        "name_pl": "15:4",
        "name_ro": "15:4",
        "name_ru": "15:4",
        "name_tr": "15:4",
        "name_se": "15:4",
        "name_el": "15:4"
    },
    "1363": {
        "name_en": "15:5",
        "name_aa": "15:5",
        "name_de": "15:5",
        "name_nl": "15:5",
        "name_pl": "15:5",
        "name_ro": "15:5",
        "name_ru": "15:5",
        "name_tr": "15:5",
        "name_se": "15:5",
        "name_el": "15:5"
    },
    "1364": {
        "name_en": "15:6",
        "name_aa": "15:6",
        "name_de": "15:6",
        "name_nl": "15:6",
        "name_pl": "15:6",
        "name_ro": "15:6",
        "name_ru": "15:6",
        "name_tr": "15:6",
        "name_se": "15:6",
        "name_el": "15:6"
    },
    "1365": {
        "name_en": "15:7",
        "name_aa": "15:7",
        "name_de": "15:7",
        "name_nl": "15:7",
        "name_pl": "15:7",
        "name_ro": "15:7",
        "name_ru": "15:7",
        "name_tr": "15:7",
        "name_se": "15:7",
        "name_el": "15:7"
    },
    "1366": {
        "name_en": "15:8",
        "name_aa": "15:8",
        "name_de": "15:8",
        "name_nl": "15:8",
        "name_pl": "15:8",
        "name_ro": "15:8",
        "name_ru": "15:8",
        "name_tr": "15:8",
        "name_se": "15:8",
        "name_el": "15:8"
    },
    "1367": {
        "name_en": "15:9",
        "name_aa": "15:9",
        "name_de": "15:9",
        "name_nl": "15:9",
        "name_pl": "15:9",
        "name_ro": "15:9",
        "name_ru": "15:9",
        "name_tr": "15:9",
        "name_se": "15:9",
        "name_el": "15:9"
    },
    "1368": {
        "name_en": "15:10",
        "name_aa": "15:10",
        "name_de": "15:10",
        "name_nl": "15:10",
        "name_pl": "15:10",
        "name_ro": "15:10",
        "name_ru": "15:10",
        "name_tr": "15:10",
        "name_se": "15:10",
        "name_el": "15:10"
    },
    "1369": {
        "name_en": "15:11",
        "name_aa": "15:11",
        "name_de": "15:11",
        "name_nl": "15:11",
        "name_pl": "15:11",
        "name_ro": "15:11",
        "name_ru": "15:11",
        "name_tr": "15:11",
        "name_se": "15:11",
        "name_el": "15:11"
    },
    "1370": {
        "name_en": "15:12",
        "name_aa": "15:12",
        "name_de": "15:12",
        "name_nl": "15:12",
        "name_pl": "15:12",
        "name_ro": "15:12",
        "name_ru": "15:12",
        "name_tr": "15:12",
        "name_se": "15:12",
        "name_el": "15:12"
    },
    "1371": {
        "name_en": "15:13",
        "name_aa": "15:13",
        "name_de": "15:13",
        "name_nl": "15:13",
        "name_pl": "15:13",
        "name_ro": "15:13",
        "name_ru": "15:13",
        "name_tr": "15:13",
        "name_se": "15:13",
        "name_el": "15:13"
    },
    "1372": {
        "name_en": "15:14",
        "name_aa": "15:14",
        "name_de": "15:14",
        "name_nl": "15:14",
        "name_pl": "15:14",
        "name_ro": "15:14",
        "name_ru": "15:14",
        "name_tr": "15:14",
        "name_se": "15:14",
        "name_el": "15:14"
    },
    "1373": {
        "name_en": "15:15",
        "name_aa": "15:15",
        "name_de": "15:15",
        "name_nl": "15:15",
        "name_pl": "15:15",
        "name_ro": "15:15",
        "name_ru": "15:15",
        "name_tr": "15:15",
        "name_se": "15:15",
        "name_el": "15:15"
    },
    "1374": {
        "name_en": "15:16",
        "name_aa": "15:16",
        "name_de": "15:16",
        "name_nl": "15:16",
        "name_pl": "15:16",
        "name_ro": "15:16",
        "name_ru": "15:16",
        "name_tr": "15:16",
        "name_se": "15:16",
        "name_el": "15:16"
    },
    "1375": {
        "name_en": "15:17",
        "name_aa": "15:17",
        "name_de": "15:17",
        "name_nl": "15:17",
        "name_pl": "15:17",
        "name_ro": "15:17",
        "name_ru": "15:17",
        "name_tr": "15:17",
        "name_se": "15:17",
        "name_el": "15:17"
    },
    "1376": {
        "name_en": "15:18",
        "name_aa": "15:18",
        "name_de": "15:18",
        "name_nl": "15:18",
        "name_pl": "15:18",
        "name_ro": "15:18",
        "name_ru": "15:18",
        "name_tr": "15:18",
        "name_se": "15:18",
        "name_el": "15:18"
    },
    "1377": {
        "name_en": "15:19",
        "name_aa": "15:19",
        "name_de": "15:19",
        "name_nl": "15:19",
        "name_pl": "15:19",
        "name_ro": "15:19",
        "name_ru": "15:19",
        "name_tr": "15:19",
        "name_se": "15:19",
        "name_el": "15:19"
    },
    "1378": {
        "name_en": "16:0",
        "name_aa": "16:0",
        "name_de": "16:0",
        "name_nl": "16:0",
        "name_pl": "16:0",
        "name_ro": "16:0",
        "name_ru": "16:0",
        "name_tr": "16:0",
        "name_se": "16:0",
        "name_el": "16:0"
    },
    "1379": {
        "name_en": "16:1",
        "name_aa": "16:1",
        "name_de": "16:1",
        "name_nl": "16:1",
        "name_pl": "16:1",
        "name_ro": "16:1",
        "name_ru": "16:1",
        "name_tr": "16:1",
        "name_se": "16:1",
        "name_el": "16:1"
    },
    "1380": {
        "name_en": "16:2",
        "name_aa": "16:2",
        "name_de": "16:2",
        "name_nl": "16:2",
        "name_pl": "16:2",
        "name_ro": "16:2",
        "name_ru": "16:2",
        "name_tr": "16:2",
        "name_se": "16:2",
        "name_el": "16:2"
    },
    "1381": {
        "name_en": "16:3",
        "name_aa": "16:3",
        "name_de": "16:3",
        "name_nl": "16:3",
        "name_pl": "16:3",
        "name_ro": "16:3",
        "name_ru": "16:3",
        "name_tr": "16:3",
        "name_se": "16:3",
        "name_el": "16:3"
    },
    "1382": {
        "name_en": "16:4",
        "name_aa": "16:4",
        "name_de": "16:4",
        "name_nl": "16:4",
        "name_pl": "16:4",
        "name_ro": "16:4",
        "name_ru": "16:4",
        "name_tr": "16:4",
        "name_se": "16:4",
        "name_el": "16:4"
    },
    "1383": {
        "name_en": "16:5",
        "name_aa": "16:5",
        "name_de": "16:5",
        "name_nl": "16:5",
        "name_pl": "16:5",
        "name_ro": "16:5",
        "name_ru": "16:5",
        "name_tr": "16:5",
        "name_se": "16:5",
        "name_el": "16:5"
    },
    "1384": {
        "name_en": "16:6",
        "name_aa": "16:6",
        "name_de": "16:6",
        "name_nl": "16:6",
        "name_pl": "16:6",
        "name_ro": "16:6",
        "name_ru": "16:6",
        "name_tr": "16:6",
        "name_se": "16:6",
        "name_el": "16:6"
    },
    "1385": {
        "name_en": "16:7",
        "name_aa": "16:7",
        "name_de": "16:7",
        "name_nl": "16:7",
        "name_pl": "16:7",
        "name_ro": "16:7",
        "name_ru": "16:7",
        "name_tr": "16:7",
        "name_se": "16:7",
        "name_el": "16:7"
    },
    "1386": {
        "name_en": "16:8",
        "name_aa": "16:8",
        "name_de": "16:8",
        "name_nl": "16:8",
        "name_pl": "16:8",
        "name_ro": "16:8",
        "name_ru": "16:8",
        "name_tr": "16:8",
        "name_se": "16:8",
        "name_el": "16:8"
    },
    "1387": {
        "name_en": "16:9",
        "name_aa": "16:9",
        "name_de": "16:9",
        "name_nl": "16:9",
        "name_pl": "16:9",
        "name_ro": "16:9",
        "name_ru": "16:9",
        "name_tr": "16:9",
        "name_se": "16:9",
        "name_el": "16:9"
    },
    "1388": {
        "name_en": "16:10",
        "name_aa": "16:10",
        "name_de": "16:10",
        "name_nl": "16:10",
        "name_pl": "16:10",
        "name_ro": "16:10",
        "name_ru": "16:10",
        "name_tr": "16:10",
        "name_se": "16:10",
        "name_el": "16:10"
    },
    "1389": {
        "name_en": "16:11",
        "name_aa": "16:11",
        "name_de": "16:11",
        "name_nl": "16:11",
        "name_pl": "16:11",
        "name_ro": "16:11",
        "name_ru": "16:11",
        "name_tr": "16:11",
        "name_se": "16:11",
        "name_el": "16:11"
    },
    "1390": {
        "name_en": "16:12",
        "name_aa": "16:12",
        "name_de": "16:12",
        "name_nl": "16:12",
        "name_pl": "16:12",
        "name_ro": "16:12",
        "name_ru": "16:12",
        "name_tr": "16:12",
        "name_se": "16:12",
        "name_el": "16:12"
    },
    "1391": {
        "name_en": "16:13",
        "name_aa": "16:13",
        "name_de": "16:13",
        "name_nl": "16:13",
        "name_pl": "16:13",
        "name_ro": "16:13",
        "name_ru": "16:13",
        "name_tr": "16:13",
        "name_se": "16:13",
        "name_el": "16:13"
    },
    "1392": {
        "name_en": "16:14",
        "name_aa": "16:14",
        "name_de": "16:14",
        "name_nl": "16:14",
        "name_pl": "16:14",
        "name_ro": "16:14",
        "name_ru": "16:14",
        "name_tr": "16:14",
        "name_se": "16:14",
        "name_el": "16:14"
    },
    "1393": {
        "name_en": "16:15",
        "name_aa": "16:15",
        "name_de": "16:15",
        "name_nl": "16:15",
        "name_pl": "16:15",
        "name_ro": "16:15",
        "name_ru": "16:15",
        "name_tr": "16:15",
        "name_se": "16:15",
        "name_el": "16:15"
    },
    "1394": {
        "name_en": "16:16",
        "name_aa": "16:16",
        "name_de": "16:16",
        "name_nl": "16:16",
        "name_pl": "16:16",
        "name_ro": "16:16",
        "name_ru": "16:16",
        "name_tr": "16:16",
        "name_se": "16:16",
        "name_el": "16:16"
    },
    "1395": {
        "name_en": "16:17",
        "name_aa": "16:17",
        "name_de": "16:17",
        "name_nl": "16:17",
        "name_pl": "16:17",
        "name_ro": "16:17",
        "name_ru": "16:17",
        "name_tr": "16:17",
        "name_se": "16:17",
        "name_el": "16:17"
    },
    "1396": {
        "name_en": "16:18",
        "name_aa": "16:18",
        "name_de": "16:18",
        "name_nl": "16:18",
        "name_pl": "16:18",
        "name_ro": "16:18",
        "name_ru": "16:18",
        "name_tr": "16:18",
        "name_se": "16:18",
        "name_el": "16:18"
    },
    "1397": {
        "name_en": "16:19",
        "name_aa": "16:19",
        "name_de": "16:19",
        "name_nl": "16:19",
        "name_pl": "16:19",
        "name_ro": "16:19",
        "name_ru": "16:19",
        "name_tr": "16:19",
        "name_se": "16:19",
        "name_el": "16:19"
    },
    "1398": {
        "name_en": "17:0",
        "name_aa": "17:0",
        "name_de": "17:0",
        "name_nl": "17:0",
        "name_pl": "17:0",
        "name_ro": "17:0",
        "name_ru": "17:0",
        "name_tr": "17:0",
        "name_se": "17:0",
        "name_el": "17:0"
    },
    "1399": {
        "name_en": "17:1",
        "name_aa": "17:1",
        "name_de": "17:1",
        "name_nl": "17:1",
        "name_pl": "17:1",
        "name_ro": "17:1",
        "name_ru": "17:1",
        "name_tr": "17:1",
        "name_se": "17:1",
        "name_el": "17:1"
    },
    "1400": {
        "name_en": "17:2",
        "name_aa": "17:2",
        "name_de": "17:2",
        "name_nl": "17:2",
        "name_pl": "17:2",
        "name_ro": "17:2",
        "name_ru": "17:2",
        "name_tr": "17:2",
        "name_se": "17:2",
        "name_el": "17:2"
    },
    "1401": {
        "name_en": "17:3",
        "name_aa": "17:3",
        "name_de": "17:3",
        "name_nl": "17:3",
        "name_pl": "17:3",
        "name_ro": "17:3",
        "name_ru": "17:3",
        "name_tr": "17:3",
        "name_se": "17:3",
        "name_el": "17:3"
    },
    "1402": {
        "name_en": "17:4",
        "name_aa": "17:4",
        "name_de": "17:4",
        "name_nl": "17:4",
        "name_pl": "17:4",
        "name_ro": "17:4",
        "name_ru": "17:4",
        "name_tr": "17:4",
        "name_se": "17:4",
        "name_el": "17:4"
    },
    "1403": {
        "name_en": "17:5",
        "name_aa": "17:5",
        "name_de": "17:5",
        "name_nl": "17:5",
        "name_pl": "17:5",
        "name_ro": "17:5",
        "name_ru": "17:5",
        "name_tr": "17:5",
        "name_se": "17:5",
        "name_el": "17:5"
    },
    "1404": {
        "name_en": "17:6",
        "name_aa": "17:6",
        "name_de": "17:6",
        "name_nl": "17:6",
        "name_pl": "17:6",
        "name_ro": "17:6",
        "name_ru": "17:6",
        "name_tr": "17:6",
        "name_se": "17:6",
        "name_el": "17:6"
    },
    "1405": {
        "name_en": "17:7",
        "name_aa": "17:7",
        "name_de": "17:7",
        "name_nl": "17:7",
        "name_pl": "17:7",
        "name_ro": "17:7",
        "name_ru": "17:7",
        "name_tr": "17:7",
        "name_se": "17:7",
        "name_el": "17:7"
    },
    "1406": {
        "name_en": "17:8",
        "name_aa": "17:8",
        "name_de": "17:8",
        "name_nl": "17:8",
        "name_pl": "17:8",
        "name_ro": "17:8",
        "name_ru": "17:8",
        "name_tr": "17:8",
        "name_se": "17:8",
        "name_el": "17:8"
    },
    "1407": {
        "name_en": "17:9",
        "name_aa": "17:9",
        "name_de": "17:9",
        "name_nl": "17:9",
        "name_pl": "17:9",
        "name_ro": "17:9",
        "name_ru": "17:9",
        "name_tr": "17:9",
        "name_se": "17:9",
        "name_el": "17:9"
    },
    "1408": {
        "name_en": "17:10",
        "name_aa": "17:10",
        "name_de": "17:10",
        "name_nl": "17:10",
        "name_pl": "17:10",
        "name_ro": "17:10",
        "name_ru": "17:10",
        "name_tr": "17:10",
        "name_se": "17:10",
        "name_el": "17:10"
    },
    "1409": {
        "name_en": "17:11",
        "name_aa": "17:11",
        "name_de": "17:11",
        "name_nl": "17:11",
        "name_pl": "17:11",
        "name_ro": "17:11",
        "name_ru": "17:11",
        "name_tr": "17:11",
        "name_se": "17:11",
        "name_el": "17:11"
    },
    "1410": {
        "name_en": "17:12",
        "name_aa": "17:12",
        "name_de": "17:12",
        "name_nl": "17:12",
        "name_pl": "17:12",
        "name_ro": "17:12",
        "name_ru": "17:12",
        "name_tr": "17:12",
        "name_se": "17:12",
        "name_el": "17:12"
    },
    "1411": {
        "name_en": "17:13",
        "name_aa": "17:13",
        "name_de": "17:13",
        "name_nl": "17:13",
        "name_pl": "17:13",
        "name_ro": "17:13",
        "name_ru": "17:13",
        "name_tr": "17:13",
        "name_se": "17:13",
        "name_el": "17:13"
    },
    "1412": {
        "name_en": "17:14",
        "name_aa": "17:14",
        "name_de": "17:14",
        "name_nl": "17:14",
        "name_pl": "17:14",
        "name_ro": "17:14",
        "name_ru": "17:14",
        "name_tr": "17:14",
        "name_se": "17:14",
        "name_el": "17:14"
    },
    "1413": {
        "name_en": "17:15",
        "name_aa": "17:15",
        "name_de": "17:15",
        "name_nl": "17:15",
        "name_pl": "17:15",
        "name_ro": "17:15",
        "name_ru": "17:15",
        "name_tr": "17:15",
        "name_se": "17:15",
        "name_el": "17:15"
    },
    "1414": {
        "name_en": "17:16",
        "name_aa": "17:16",
        "name_de": "17:16",
        "name_nl": "17:16",
        "name_pl": "17:16",
        "name_ro": "17:16",
        "name_ru": "17:16",
        "name_tr": "17:16",
        "name_se": "17:16",
        "name_el": "17:16"
    },
    "1415": {
        "name_en": "17:17",
        "name_aa": "17:17",
        "name_de": "17:17",
        "name_nl": "17:17",
        "name_pl": "17:17",
        "name_ro": "17:17",
        "name_ru": "17:17",
        "name_tr": "17:17",
        "name_se": "17:17",
        "name_el": "17:17"
    },
    "1416": {
        "name_en": "17:18",
        "name_aa": "17:18",
        "name_de": "17:18",
        "name_nl": "17:18",
        "name_pl": "17:18",
        "name_ro": "17:18",
        "name_ru": "17:18",
        "name_tr": "17:18",
        "name_se": "17:18",
        "name_el": "17:18"
    },
    "1417": {
        "name_en": "17:19",
        "name_aa": "17:19",
        "name_de": "17:19",
        "name_nl": "17:19",
        "name_pl": "17:19",
        "name_ro": "17:19",
        "name_ru": "17:19",
        "name_tr": "17:19",
        "name_se": "17:19",
        "name_el": "17:19"
    },
    "1418": {
        "name_en": "18:0",
        "name_aa": "18:0",
        "name_de": "18:0",
        "name_nl": "18:0",
        "name_pl": "18:0",
        "name_ro": "18:0",
        "name_ru": "18:0",
        "name_tr": "18:0",
        "name_se": "18:0",
        "name_el": "18:0"
    },
    "1419": {
        "name_en": "18:1",
        "name_aa": "18:1",
        "name_de": "18:1",
        "name_nl": "18:1",
        "name_pl": "18:1",
        "name_ro": "18:1",
        "name_ru": "18:1",
        "name_tr": "18:1",
        "name_se": "18:1",
        "name_el": "18:1"
    },
    "1420": {
        "name_en": "18:2",
        "name_aa": "18:2",
        "name_de": "18:2",
        "name_nl": "18:2",
        "name_pl": "18:2",
        "name_ro": "18:2",
        "name_ru": "18:2",
        "name_tr": "18:2",
        "name_se": "18:2",
        "name_el": "18:2"
    },
    "1421": {
        "name_en": "18:3",
        "name_aa": "18:3",
        "name_de": "18:3",
        "name_nl": "18:3",
        "name_pl": "18:3",
        "name_ro": "18:3",
        "name_ru": "18:3",
        "name_tr": "18:3",
        "name_se": "18:3",
        "name_el": "18:3"
    },
    "1422": {
        "name_en": "18:4",
        "name_aa": "18:4",
        "name_de": "18:4",
        "name_nl": "18:4",
        "name_pl": "18:4",
        "name_ro": "18:4",
        "name_ru": "18:4",
        "name_tr": "18:4",
        "name_se": "18:4",
        "name_el": "18:4"
    },
    "1423": {
        "name_en": "18:5",
        "name_aa": "18:5",
        "name_de": "18:5",
        "name_nl": "18:5",
        "name_pl": "18:5",
        "name_ro": "18:5",
        "name_ru": "18:5",
        "name_tr": "18:5",
        "name_se": "18:5",
        "name_el": "18:5"
    },
    "1424": {
        "name_en": "18:6",
        "name_aa": "18:6",
        "name_de": "18:6",
        "name_nl": "18:6",
        "name_pl": "18:6",
        "name_ro": "18:6",
        "name_ru": "18:6",
        "name_tr": "18:6",
        "name_se": "18:6",
        "name_el": "18:6"
    },
    "1425": {
        "name_en": "18:7",
        "name_aa": "18:7",
        "name_de": "18:7",
        "name_nl": "18:7",
        "name_pl": "18:7",
        "name_ro": "18:7",
        "name_ru": "18:7",
        "name_tr": "18:7",
        "name_se": "18:7",
        "name_el": "18:7"
    },
    "1426": {
        "name_en": "18:8",
        "name_aa": "18:8",
        "name_de": "18:8",
        "name_nl": "18:8",
        "name_pl": "18:8",
        "name_ro": "18:8",
        "name_ru": "18:8",
        "name_tr": "18:8",
        "name_se": "18:8",
        "name_el": "18:8"
    },
    "1427": {
        "name_en": "18:9",
        "name_aa": "18:9",
        "name_de": "18:9",
        "name_nl": "18:9",
        "name_pl": "18:9",
        "name_ro": "18:9",
        "name_ru": "18:9",
        "name_tr": "18:9",
        "name_se": "18:9",
        "name_el": "18:9"
    },
    "1428": {
        "name_en": "18:10",
        "name_aa": "18:10",
        "name_de": "18:10",
        "name_nl": "18:10",
        "name_pl": "18:10",
        "name_ro": "18:10",
        "name_ru": "18:10",
        "name_tr": "18:10",
        "name_se": "18:10",
        "name_el": "18:10"
    },
    "1429": {
        "name_en": "18:11",
        "name_aa": "18:11",
        "name_de": "18:11",
        "name_nl": "18:11",
        "name_pl": "18:11",
        "name_ro": "18:11",
        "name_ru": "18:11",
        "name_tr": "18:11",
        "name_se": "18:11",
        "name_el": "18:11"
    },
    "1430": {
        "name_en": "18:12",
        "name_aa": "18:12",
        "name_de": "18:12",
        "name_nl": "18:12",
        "name_pl": "18:12",
        "name_ro": "18:12",
        "name_ru": "18:12",
        "name_tr": "18:12",
        "name_se": "18:12",
        "name_el": "18:12"
    },
    "1431": {
        "name_en": "18:13",
        "name_aa": "18:13",
        "name_de": "18:13",
        "name_nl": "18:13",
        "name_pl": "18:13",
        "name_ro": "18:13",
        "name_ru": "18:13",
        "name_tr": "18:13",
        "name_se": "18:13",
        "name_el": "18:13"
    },
    "1432": {
        "name_en": "18:14",
        "name_aa": "18:14",
        "name_de": "18:14",
        "name_nl": "18:14",
        "name_pl": "18:14",
        "name_ro": "18:14",
        "name_ru": "18:14",
        "name_tr": "18:14",
        "name_se": "18:14",
        "name_el": "18:14"
    },
    "1433": {
        "name_en": "18:15",
        "name_aa": "18:15",
        "name_de": "18:15",
        "name_nl": "18:15",
        "name_pl": "18:15",
        "name_ro": "18:15",
        "name_ru": "18:15",
        "name_tr": "18:15",
        "name_se": "18:15",
        "name_el": "18:15"
    },
    "1434": {
        "name_en": "18:16",
        "name_aa": "18:16",
        "name_de": "18:16",
        "name_nl": "18:16",
        "name_pl": "18:16",
        "name_ro": "18:16",
        "name_ru": "18:16",
        "name_tr": "18:16",
        "name_se": "18:16",
        "name_el": "18:16"
    },
    "1435": {
        "name_en": "18:17",
        "name_aa": "18:17",
        "name_de": "18:17",
        "name_nl": "18:17",
        "name_pl": "18:17",
        "name_ro": "18:17",
        "name_ru": "18:17",
        "name_tr": "18:17",
        "name_se": "18:17",
        "name_el": "18:17"
    },
    "1436": {
        "name_en": "18:18",
        "name_aa": "18:18",
        "name_de": "18:18",
        "name_nl": "18:18",
        "name_pl": "18:18",
        "name_ro": "18:18",
        "name_ru": "18:18",
        "name_tr": "18:18",
        "name_se": "18:18",
        "name_el": "18:18"
    },
    "1437": {
        "name_en": "18:19",
        "name_aa": "18:19",
        "name_de": "18:19",
        "name_nl": "18:19",
        "name_pl": "18:19",
        "name_ro": "18:19",
        "name_ru": "18:19",
        "name_tr": "18:19",
        "name_se": "18:19",
        "name_el": "18:19"
    },
    "1438": {
        "name_en": "19:0",
        "name_aa": "19:0",
        "name_de": "19:0",
        "name_nl": "19:0",
        "name_pl": "19:0",
        "name_ro": "19:0",
        "name_ru": "19:0",
        "name_tr": "19:0",
        "name_se": "19:0",
        "name_el": "19:0"
    },
    "1439": {
        "name_en": "19:1",
        "name_aa": "19:1",
        "name_de": "19:1",
        "name_nl": "19:1",
        "name_pl": "19:1",
        "name_ro": "19:1",
        "name_ru": "19:1",
        "name_tr": "19:1",
        "name_se": "19:1",
        "name_el": "19:1"
    },
    "1440": {
        "name_en": "19:2",
        "name_aa": "19:2",
        "name_de": "19:2",
        "name_nl": "19:2",
        "name_pl": "19:2",
        "name_ro": "19:2",
        "name_ru": "19:2",
        "name_tr": "19:2",
        "name_se": "19:2",
        "name_el": "19:2"
    },
    "1441": {
        "name_en": "19:3",
        "name_aa": "19:3",
        "name_de": "19:3",
        "name_nl": "19:3",
        "name_pl": "19:3",
        "name_ro": "19:3",
        "name_ru": "19:3",
        "name_tr": "19:3",
        "name_se": "19:3",
        "name_el": "19:3"
    },
    "1442": {
        "name_en": "19:4",
        "name_aa": "19:4",
        "name_de": "19:4",
        "name_nl": "19:4",
        "name_pl": "19:4",
        "name_ro": "19:4",
        "name_ru": "19:4",
        "name_tr": "19:4",
        "name_se": "19:4",
        "name_el": "19:4"
    },
    "1443": {
        "name_en": "19:5",
        "name_aa": "19:5",
        "name_de": "19:5",
        "name_nl": "19:5",
        "name_pl": "19:5",
        "name_ro": "19:5",
        "name_ru": "19:5",
        "name_tr": "19:5",
        "name_se": "19:5",
        "name_el": "19:5"
    },
    "1444": {
        "name_en": "19:6",
        "name_aa": "19:6",
        "name_de": "19:6",
        "name_nl": "19:6",
        "name_pl": "19:6",
        "name_ro": "19:6",
        "name_ru": "19:6",
        "name_tr": "19:6",
        "name_se": "19:6",
        "name_el": "19:6"
    },
    "1445": {
        "name_en": "19:7",
        "name_aa": "19:7",
        "name_de": "19:7",
        "name_nl": "19:7",
        "name_pl": "19:7",
        "name_ro": "19:7",
        "name_ru": "19:7",
        "name_tr": "19:7",
        "name_se": "19:7",
        "name_el": "19:7"
    },
    "1446": {
        "name_en": "19:8",
        "name_aa": "19:8",
        "name_de": "19:8",
        "name_nl": "19:8",
        "name_pl": "19:8",
        "name_ro": "19:8",
        "name_ru": "19:8",
        "name_tr": "19:8",
        "name_se": "19:8",
        "name_el": "19:8"
    },
    "1447": {
        "name_en": "19:9",
        "name_aa": "19:9",
        "name_de": "19:9",
        "name_nl": "19:9",
        "name_pl": "19:9",
        "name_ro": "19:9",
        "name_ru": "19:9",
        "name_tr": "19:9",
        "name_se": "19:9",
        "name_el": "19:9"
    },
    "1448": {
        "name_en": "19:10",
        "name_aa": "19:10",
        "name_de": "19:10",
        "name_nl": "19:10",
        "name_pl": "19:10",
        "name_ro": "19:10",
        "name_ru": "19:10",
        "name_tr": "19:10",
        "name_se": "19:10",
        "name_el": "19:10"
    },
    "1449": {
        "name_en": "19:11",
        "name_aa": "19:11",
        "name_de": "19:11",
        "name_nl": "19:11",
        "name_pl": "19:11",
        "name_ro": "19:11",
        "name_ru": "19:11",
        "name_tr": "19:11",
        "name_se": "19:11",
        "name_el": "19:11"
    },
    "1450": {
        "name_en": "19:12",
        "name_aa": "19:12",
        "name_de": "19:12",
        "name_nl": "19:12",
        "name_pl": "19:12",
        "name_ro": "19:12",
        "name_ru": "19:12",
        "name_tr": "19:12",
        "name_se": "19:12",
        "name_el": "19:12"
    },
    "1451": {
        "name_en": "19:13",
        "name_aa": "19:13",
        "name_de": "19:13",
        "name_nl": "19:13",
        "name_pl": "19:13",
        "name_ro": "19:13",
        "name_ru": "19:13",
        "name_tr": "19:13",
        "name_se": "19:13",
        "name_el": "19:13"
    },
    "1452": {
        "name_en": "19:14",
        "name_aa": "19:14",
        "name_de": "19:14",
        "name_nl": "19:14",
        "name_pl": "19:14",
        "name_ro": "19:14",
        "name_ru": "19:14",
        "name_tr": "19:14",
        "name_se": "19:14",
        "name_el": "19:14"
    },
    "1453": {
        "name_en": "19:15",
        "name_aa": "19:15",
        "name_de": "19:15",
        "name_nl": "19:15",
        "name_pl": "19:15",
        "name_ro": "19:15",
        "name_ru": "19:15",
        "name_tr": "19:15",
        "name_se": "19:15",
        "name_el": "19:15"
    },
    "1454": {
        "name_en": "19:16",
        "name_aa": "19:16",
        "name_de": "19:16",
        "name_nl": "19:16",
        "name_pl": "19:16",
        "name_ro": "19:16",
        "name_ru": "19:16",
        "name_tr": "19:16",
        "name_se": "19:16",
        "name_el": "19:16"
    },
    "1455": {
        "name_en": "19:17",
        "name_aa": "19:17",
        "name_de": "19:17",
        "name_nl": "19:17",
        "name_pl": "19:17",
        "name_ro": "19:17",
        "name_ru": "19:17",
        "name_tr": "19:17",
        "name_se": "19:17",
        "name_el": "19:17"
    },
    "1456": {
        "name_en": "19:18",
        "name_aa": "19:18",
        "name_de": "19:18",
        "name_nl": "19:18",
        "name_pl": "19:18",
        "name_ro": "19:18",
        "name_ru": "19:18",
        "name_tr": "19:18",
        "name_se": "19:18",
        "name_el": "19:18"
    },
    "1457": {
        "name_en": "19:19",
        "name_aa": "19:19",
        "name_de": "19:19",
        "name_nl": "19:19",
        "name_pl": "19:19",
        "name_ro": "19:19",
        "name_ru": "19:19",
        "name_tr": "19:19",
        "name_se": "19:19",
        "name_el": "19:19"
    },
    "1475": {
        "name_en": "both",
        "name_aa": "على حد سواء",
        "name_de": "beide",
        "name_nl": "beide",
        "name_pl": "oba",
        "name_ro": "ambele",
        "name_ru": "обе",
        "name_tr": "her ikiside",
        "name_se": "båda",
        "name_el": "και οι 2"
    },
    "1476": {
        "name_en": "only {$competitor1}",
        "name_aa": "فقط {$competitor1}",
        "name_de": "nur {$competitor1}",
        "name_nl": "alleen {$competitor1}",
        "name_pl": "tylko {$competitor1}",
        "name_ro": "doar {$competitor1}",
        "name_ru": "только {$competitor1}",
        "name_tr": "sadece {$competitor1}",
        "name_se": "endast {$competitor1}",
        "name_el": "μόνο {$competitor1}"
    },
    "1477": {
        "name_en": "only {$competitor2}",
        "name_aa": "فقط {$competitor2}",
        "name_de": "nur {$competitor2}",
        "name_nl": "alleen {$competitor2}",
        "name_pl": "tylko {$competitor2}",
        "name_ro": "doar {$competitor2}",
        "name_ru": "только {$competitor2}",
        "name_tr": "sadece {$competitor2}",
        "name_se": "endast {$competitor2}",
        "name_el": "μόνο {$competitor2}"
    },
    "1478": {
        "name_en": "{$competitor1} goal & {$competitor1}",
        "name_aa": "{$competitor1} هدف و{$competitor1}",
        "name_de": "{$competitor1} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor1} & {$competitor1}",
        "name_pl": "{$competitor1} Gol & {$competitor1}",
        "name_ro": "gol {$competitor1} & {$competitor1}",
        "name_ru": "гол {$competitor1} & {$competitor1}",
        "name_tr": "{$competitor1} gol atar ve {$competitor1} kazanır",
        "name_se": "{$competitor1} mål & {$competitor1}",
        "name_el": "γκολ {$competitor1} και {$competitor1}"
    },
    "1479": {
        "name_en": "{$competitor1} goal & {$competitor2}",
        "name_aa": "{$competitor1} هدف و{$competitor2}",
        "name_de": "{$competitor1} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor1} & {$competitor2}",
        "name_pl": "{$competitor1} Gol & {$competitor2}",
        "name_ro": "gol {$competitor1} & {$competitor2}",
        "name_ru": "гол {$competitor1} & {$competitor2}",
        "name_tr": "{$competitor1} gol atar ve {$competitor2} kazanır",
        "name_se": "{$competitor1} mål & {$competitor2}",
        "name_el": "γκολ {$competitor1} και {$competitor2}"
    },
    "1480": {
        "name_en": "{$competitor2} goal & {$competitor1}",
        "name_aa": "{$competitor2} هدف و{$competitor1}",
        "name_de": "{$competitor2} Tor & {$competitor1}",
        "name_nl": "doelpunt {$competitor2} & {$competitor1}",
        "name_pl": "{$competitor2} Gol & {$competitor1}",
        "name_ro": "gol {$competitor2} & {$competitor1}",
        "name_ru": "гол {$competitor2} & {$competitor1}",
        "name_tr": "{$competitor2} gol atar ve {$competitor1} kazanır",
        "name_se": "{$competitor2} mål & {$competitor1}",
        "name_el": "γκολ {$competitor2} και {$competitor1}"
    },
    "1481": {
        "name_en": "{$competitor2} goal & {$competitor2}",
        "name_aa": "{$competitor2} هدف و{$competitor2}",
        "name_de": "{$competitor2} Tor & {$competitor2}",
        "name_nl": "doelpunt {$competitor2} & {$competitor2}",
        "name_pl": "{$competitor2} Gol & {$competitor2}",
        "name_ro": "gol {$competitor2} & {$competitor2}",
        "name_ru": "гол {$competitor2} & {$competitor2}",
        "name_tr": "{$competitor2} gol atar ve {$competitor2} kazanır",
        "name_se": "{$competitor2} mål & {$competitor2}",
        "name_el": "γκολ {$competitor2} και {$competitor2}"
    },
    "1482": {
        "name_en": "{$competitor1} & {$competitor1}",
        "name_aa": "{$competitor1} و {$competitor1}",
        "name_de": "{$competitor1} & {$competitor1}",
        "name_nl": "{$competitor1} & {$competitor1}",
        "name_pl": "{$competitor1} & {$competitor1}",
        "name_ro": "{$competitor1} & {$competitor1}",
        "name_ru": "{$competitor1} & {$competitor1}",
        "name_tr": "{$competitor1} & {$competitor1}",
        "name_se": "{$competitor1} & {$competitor1}",
        "name_el": "{$competitor1} και {$competitor1}"
    },
    "1483": {
        "name_en": "X & {$competitor1}",
        "name_aa": "ارسم و  {$competitor1}",
        "name_de": "X & {$competitor1}",
        "name_nl": "X & {$competitor1}",
        "name_pl": "remis & {$competitor1}",
        "name_ro": "egal & {$competitor1}",
        "name_ru": "ничья & {$competitor1}",
        "name_tr": "X & {$competitor1}",
        "name_se": "oavgjort & {$competitor1}",
        "name_el": "ισοπαλία και {$competitor1}"
    },
    "1484": {
        "name_en": "{$competitor2} & {$competitor1}",
        "name_aa": "{$competitor2} و {$competitor1}",
        "name_de": "{$competitor2} & {$competitor1}",
        "name_nl": "{$competitor2} & {$competitor1}",
        "name_pl": "{$competitor2} & {$competitor1}",
        "name_ro": "{$competitor2} & {$competitor1}",
        "name_ru": "{$competitor2} & {$competitor1}",
        "name_tr": "{$competitor2} & {$competitor1}",
        "name_se": "{$competitor2} & {$competitor1}",
        "name_el": "{$competitor2} και {$competitor1}"
    },
    "1485": {
        "name_en": "{$competitor1} & {$competitor2}",
        "name_aa": "{$competitor1} و {$competitor2}",
        "name_de": "{$competitor1} & {$competitor2}",
        "name_nl": "{$competitor1} & {$competitor2}",
        "name_pl": "{$competitor1} & {$competitor2}",
        "name_ro": "{$competitor1} & {$competitor2}",
        "name_ru": "{$competitor1} & {$competitor2}",
        "name_tr": "{$competitor1} & {$competitor2}",
        "name_se": "{$competitor1} & {$competitor2}",
        "name_el": "{$competitor1} και {$competitor2}"
    },
    "1486": {
        "name_en": "X & {$competitor2}",
        "name_aa": "رسم و{$competitor2}",
        "name_de": "X & {$competitor2}",
        "name_nl": "X & {$competitor2}",
        "name_pl": "remis & {$competitor2}",
        "name_ro": "egal & {$competitor2}",
        "name_ru": "ничья & {$competitor2}",
        "name_tr": "X & {$competitor2}",
        "name_se": "oavgjort & {$competitor2}",
        "name_el": "ισοπαλία και {$competitor2}"
    },
    "1487": {
        "name_en": "{$competitor2} & {$competitor2}",
        "name_aa": "{$competitor2} و {$competitor2}",
        "name_de": "{$competitor2} & {$competitor2}",
        "name_nl": "{$competitor2} & {$competitor2}",
        "name_pl": "{$competitor2} & {$competitor2}",
        "name_ro": "{$competitor2} & {$competitor2}",
        "name_ru": "{$competitor2} & {$competitor2}",
        "name_tr": "{$competitor2} & {$competitor2}",
        "name_se": "{$competitor2} & {$competitor2}",
        "name_el": "{$competitor2} και {$competitor2}"
    },
    "1488": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1489": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1490": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "1491": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "1492": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0"
    },
    "1493": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1494": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1495": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "1496": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "1497": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1"
    },
    "1498": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1499": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1500": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "1501": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "1502": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2"
    },
    "1503": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "1504": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "1505": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "1506": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "1507": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3"
    },
    "1508": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "1509": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "1510": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "1511": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "1512": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4"
    },
    "1513": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5"
    },
    "1514": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5"
    },
    "1515": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5"
    },
    "1516": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5"
    },
    "1517": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5"
    },
    "1518": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1529": {
        "name_en": "{$competitor1} & {$competitor1}",
        "name_aa": "{$competitor1} و {$competitor1}",
        "name_de": "{$competitor1} & {$competitor1}",
        "name_nl": "{$competitor1} & {$competitor1}",
        "name_pl": "{$competitor1} & {$competitor1}",
        "name_ro": "{$competitor1} & {$competitor1}",
        "name_ru": "{$competitor1} и {$competitor1}",
        "name_tr": "{$competitor1} & {$competitor1}",
        "name_se": "{$competitor1} & {$competitor1}",
        "name_el": "{$competitor1} και {$competitor1}"
    },
    "1530": {
        "name_en": "X & {$competitor1}",
        "name_aa": "ارسم  و {$competitor1}",
        "name_de": "X & {$competitor1}",
        "name_nl": "X & {$competitor1}",
        "name_pl": "remis & {$competitor1}",
        "name_ro": "egal & {$competitor1}",
        "name_ru": "ничья и {$competitor1}",
        "name_tr": "X & {$competitor1}",
        "name_se": "oavgjort & {$competitor1}",
        "name_el": "ισοπαλία και {$competitor1}"
    },
    "1531": {
        "name_en": "{$competitor2} & {$competitor1}",
        "name_aa": "{$competitor2} و {$competitor1}",
        "name_de": "{$competitor2} & {$competitor1}",
        "name_nl": "{$competitor2} & {$competitor1}",
        "name_pl": "{$competitor2} & {$competitor1}",
        "name_ro": "{$competitor2} & {$competitor1}",
        "name_ru": "{$competitor2} и {$competitor1}",
        "name_tr": "{$competitor2} & {$competitor1}",
        "name_se": "{$competitor2} & {$competitor1}",
        "name_el": "{$competitor2} και {$competitor1}"
    },
    "1532": {
        "name_en": "{$competitor1} & X",
        "name_aa": "{$competitor1} ورسم",
        "name_de": "{$competitor1} & X",
        "name_nl": "{$competitor1} & X",
        "name_pl": "{$competitor1} & remis",
        "name_ro": "{$competitor1} & egal",
        "name_ru": "{$competitor1} и ничья",
        "name_tr": "{$competitor1} & X",
        "name_se": "{$competitor1} & oavgjort",
        "name_el": "{$competitor1} και ισοπαλία"
    },
    "1533": {
        "name_en": "X & X",
        "name_aa": "ارسم وارسم",
        "name_de": "X & X",
        "name_nl": "X & X",
        "name_pl": "remis & remis",
        "name_ro": "egal & egal",
        "name_ru": "ничья и ничья",
        "name_tr": "X & X",
        "name_se": "oavgjort & oavgjort",
        "name_el": "ισοπαλία και ισοπαλία"
    },
    "1534": {
        "name_en": "{$competitor2} & X",
        "name_aa": "{$competitor2} ورسم",
        "name_de": "{$competitor2} & X",
        "name_nl": "{$competitor2} & X",
        "name_pl": "{$competitor2} & remis",
        "name_ro": "{$competitor2} & egal",
        "name_ru": "{$competitor2} и ничья",
        "name_tr": "{$competitor2} & X",
        "name_se": "{$competitor2} & oavgjort",
        "name_el": "{$competitor2} και ισοπαλία"
    },
    "1535": {
        "name_en": "{$competitor1} & {$competitor2}",
        "name_aa": "{$competitor1} و {$competitor2}",
        "name_de": "{$competitor1} & {$competitor2}",
        "name_nl": "{$competitor1} & {$competitor2}",
        "name_pl": "{$competitor1} & {$competitor2}",
        "name_ro": "{$competitor1} & {$competitor2}",
        "name_ru": "{$competitor1} и {$competitor2}",
        "name_tr": "{$competitor1} & {$competitor2}",
        "name_se": "{$competitor1} & {$competitor2}",
        "name_el": "{$competitor1} και {$competitor2}"
    },
    "1536": {
        "name_en": "X & {$competitor2}",
        "name_aa": "رسم و{$competitor2}",
        "name_de": "X & {$competitor2}",
        "name_nl": "X & {$competitor2}",
        "name_pl": "remis & {$competitor2}",
        "name_ro": "egal & {$competitor2}",
        "name_ru": "ничья и {$competitor2}",
        "name_tr": "X & {$competitor2}",
        "name_se": "oavgjort & {$competitor2}",
        "name_el": "ισοπαλία και {$competitor2}"
    },
    "1537": {
        "name_en": "{$competitor2} & {$competitor2}",
        "name_aa": "{$competitor2} و {$competitor2}",
        "name_de": "{$competitor2} & {$competitor2}",
        "name_nl": "{$competitor2} & {$competitor2}",
        "name_pl": "{$competitor2} & {$competitor2}",
        "name_ro": "{$competitor2} & {$competitor2}",
        "name_ru": "{$competitor2} и {$competitor2}",
        "name_tr": "{$competitor2} & {$competitor2}",
        "name_se": "{$competitor2} & {$competitor2}",
        "name_el": "{$competitor2} και {$competitor2}"
    },
    "1538": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "1539": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1540": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1541": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "1542": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "1543": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0"
    },
    "1544": {
        "name_en": "6:0",
        "name_aa": "6:0",
        "name_de": "6:0",
        "name_nl": "6:0",
        "name_pl": "6:0",
        "name_ro": "6:0",
        "name_ru": "6:0",
        "name_tr": "6:0",
        "name_se": "6:0",
        "name_el": "6:0"
    },
    "1545": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0"
    },
    "1546": {
        "name_en": "8:0",
        "name_aa": "8:0",
        "name_de": "8:0",
        "name_nl": "8:0",
        "name_pl": "8:0",
        "name_ro": "8:0",
        "name_ru": "8:0",
        "name_tr": "8:0",
        "name_se": "8:0",
        "name_el": "8:0"
    },
    "1547": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1548": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "1549": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1550": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "1551": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "1552": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1"
    },
    "1553": {
        "name_en": "6:1",
        "name_aa": "6:1",
        "name_de": "6:1",
        "name_nl": "6:1",
        "name_pl": "6:1",
        "name_ro": "6:1",
        "name_ru": "6:1",
        "name_tr": "6:1",
        "name_se": "6:1",
        "name_el": "6:1"
    },
    "1554": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1"
    },
    "1555": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1556": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1557": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "1558": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "1559": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "1560": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2"
    },
    "1561": {
        "name_en": "6:2",
        "name_aa": "6:2",
        "name_de": "6:2",
        "name_nl": "6:2",
        "name_pl": "6:2",
        "name_ro": "6:2",
        "name_ru": "6:2",
        "name_tr": "6:2",
        "name_se": "6:2",
        "name_el": "6:2"
    },
    "1562": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "1563": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "1564": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "1565": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3"
    },
    "1566": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "1567": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3"
    },
    "1568": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "1569": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "1570": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "1571": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "1572": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4"
    },
    "1573": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5"
    },
    "1574": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5"
    },
    "1575": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5"
    },
    "1576": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5"
    },
    "1577": {
        "name_en": "0:6",
        "name_aa": "0:6",
        "name_de": "0:6",
        "name_nl": "0:6",
        "name_pl": "0:6",
        "name_ro": "0:6",
        "name_ru": "0:6",
        "name_tr": "0:6",
        "name_se": "0:6",
        "name_el": "0:6"
    },
    "1578": {
        "name_en": "1:6",
        "name_aa": "1:6",
        "name_de": "1:6",
        "name_nl": "1:6",
        "name_pl": "1:6",
        "name_ro": "1:6",
        "name_ru": "1:6",
        "name_tr": "1:6",
        "name_se": "1:6",
        "name_el": "1:6"
    },
    "1579": {
        "name_en": "2:6",
        "name_aa": "2:6",
        "name_de": "2:6",
        "name_nl": "2:6",
        "name_pl": "2:6",
        "name_ro": "2:6",
        "name_ru": "2:6",
        "name_tr": "2:6",
        "name_se": "2:6",
        "name_el": "2:6"
    },
    "1580": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7"
    },
    "1581": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7"
    },
    "1582": {
        "name_en": "0:8",
        "name_aa": "0:8",
        "name_de": "0:8",
        "name_nl": "0:8",
        "name_pl": "0:8",
        "name_ro": "0:8",
        "name_ru": "0:8",
        "name_tr": "0:8",
        "name_se": "0:8",
        "name_el": "0:8"
    },
    "1584": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "1585": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1586": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1587": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "1588": {
        "name_en": "4:0",
        "name_aa": "4:0",
        "name_de": "4:0",
        "name_nl": "4:0",
        "name_pl": "4:0",
        "name_ro": "4:0",
        "name_ru": "4:0",
        "name_tr": "4:0",
        "name_se": "4:0",
        "name_el": "4:0"
    },
    "1589": {
        "name_en": "5:0",
        "name_aa": "5:0",
        "name_de": "5:0",
        "name_nl": "5:0",
        "name_pl": "5:0",
        "name_ro": "5:0",
        "name_ru": "5:0",
        "name_tr": "5:0",
        "name_se": "5:0",
        "name_el": "5:0"
    },
    "1590": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1591": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "1592": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1593": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "1594": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "1595": {
        "name_en": "5:1",
        "name_aa": "5:1",
        "name_de": "5:1",
        "name_nl": "5:1",
        "name_pl": "5:1",
        "name_ro": "5:1",
        "name_ru": "5:1",
        "name_tr": "5:1",
        "name_se": "5:1",
        "name_el": "5:1"
    },
    "1596": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1597": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1598": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "1599": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "1600": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "1601": {
        "name_en": "5:2",
        "name_aa": "5:2",
        "name_de": "5:2",
        "name_nl": "5:2",
        "name_pl": "5:2",
        "name_ro": "5:2",
        "name_ru": "5:2",
        "name_tr": "5:2",
        "name_se": "5:2",
        "name_el": "5:2"
    },
    "1602": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "1603": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "1604": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "1605": {
        "name_en": "3:3",
        "name_aa": "3:3",
        "name_de": "3:3",
        "name_nl": "3:3",
        "name_pl": "3:3",
        "name_ro": "3:3",
        "name_ru": "3:3",
        "name_tr": "3:3",
        "name_se": "3:3",
        "name_el": "3:3"
    },
    "1606": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "1607": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5:3"
    },
    "1608": {
        "name_en": "0:4",
        "name_aa": "0:4",
        "name_de": "0:4",
        "name_nl": "0:4",
        "name_pl": "0:4",
        "name_ro": "0:4",
        "name_ru": "0:4",
        "name_tr": "0:4",
        "name_se": "0:4",
        "name_el": "0:4"
    },
    "1609": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "1610": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "1611": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "1612": {
        "name_en": "4:4",
        "name_aa": "4:4",
        "name_de": "4:4",
        "name_nl": "4:4",
        "name_pl": "4:4",
        "name_ro": "4:4",
        "name_ru": "4:4",
        "name_tr": "4:4",
        "name_se": "4:4",
        "name_el": "4:4"
    },
    "1613": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5:4"
    },
    "1614": {
        "name_en": "0:5",
        "name_aa": "0:5",
        "name_de": "0:5",
        "name_nl": "0:5",
        "name_pl": "0:5",
        "name_ro": "0:5",
        "name_ru": "0:5",
        "name_tr": "0:5",
        "name_se": "0:5",
        "name_el": "0:5"
    },
    "1615": {
        "name_en": "1:5",
        "name_aa": "1:5",
        "name_de": "1:5",
        "name_nl": "1:5",
        "name_pl": "1:5",
        "name_ro": "1:5",
        "name_ru": "1:5",
        "name_tr": "1:5",
        "name_se": "1:5",
        "name_el": "1:5"
    },
    "1616": {
        "name_en": "2:5",
        "name_aa": "2:5",
        "name_de": "2:5",
        "name_nl": "2:5",
        "name_pl": "2:5",
        "name_ro": "2:5",
        "name_ru": "2:5",
        "name_tr": "2:5",
        "name_se": "2:5",
        "name_el": "2:5"
    },
    "1617": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5"
    },
    "1618": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4:5"
    },
    "1619": {
        "name_en": "5:5",
        "name_aa": "5:5",
        "name_de": "5:5",
        "name_nl": "5:5",
        "name_pl": "5:5",
        "name_ro": "5:5",
        "name_ru": "5:5",
        "name_tr": "5:5",
        "name_se": "5:5",
        "name_el": "5:5"
    },
    "1620": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1621": {
        "name_en": "1st period",
        "name_aa": "الفترة الأولى",
        "name_de": "1. Periode",
        "name_nl": "1e periode",
        "name_pl": "1. Część gry",
        "name_ro": "repriza 1",
        "name_ru": "1ый период",
        "name_tr": "İlk periyot",
        "name_se": "1:a period",
        "name_el": "1η περίοδος"
    },
    "1622": {
        "name_en": "2nd period",
        "name_aa": "الفترة الثانية",
        "name_de": "2. Periode",
        "name_nl": "2e periode",
        "name_pl": "2. Część gry",
        "name_ro": "repriza 2",
        "name_ru": "2ой период",
        "name_tr": "İkinci periyot",
        "name_se": "2:a period",
        "name_el": "2η περίοδος"
    },
    "1623": {
        "name_en": "3rd period",
        "name_aa": "الفترة الثالثة",
        "name_de": "3. Periode",
        "name_nl": "3e periode",
        "name_pl": "3. Część gry",
        "name_ro": "repriza 3",
        "name_ru": "3ий период",
        "name_tr": "Üçüncü periyot",
        "name_se": "3:e period",
        "name_el": "3η περίοδος"
    },
    "1624": {
        "name_en": "equal",
        "name_aa": "مساو",
        "name_de": "gleich",
        "name_nl": "gelijk",
        "name_pl": "równo",
        "name_ro": "egalitate",
        "name_ru": "равные",
        "name_tr": "eşit",
        "name_se": "lika",
        "name_el": "ίδιος αριθμός"
    },
    "1630": {
        "name_en": "0:0",
        "name_aa": "0:0",
        "name_de": "0:0",
        "name_nl": "0:0",
        "name_pl": "0:0",
        "name_ro": "0:0",
        "name_ru": "0:0",
        "name_tr": "0:0",
        "name_se": "0:0",
        "name_el": "0:0"
    },
    "1631": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1632": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1633": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1634": {
        "name_en": "1:1",
        "name_aa": "1:1",
        "name_de": "1:1",
        "name_nl": "1:1",
        "name_pl": "1:1",
        "name_ro": "1:1",
        "name_ru": "1:1",
        "name_tr": "1:1",
        "name_se": "1:1",
        "name_el": "1:1"
    },
    "1635": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1636": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1637": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1638": {
        "name_en": "2:2",
        "name_aa": "2:2",
        "name_de": "2:2",
        "name_nl": "2:2",
        "name_pl": "2:2",
        "name_ro": "2:2",
        "name_ru": "2:2",
        "name_tr": "2:2",
        "name_se": "2:2",
        "name_el": "2:2"
    },
    "1639": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1640": {
        "name_en": "{$competitor1} with try",
        "name_aa": "{$competitor1} مع المحاولة",
        "name_de": "{$competitor1} mit Versuch",
        "name_nl": "{$competitor1} met try",
        "name_pl": "{$competitor1} z przyłożeniem",
        "name_ro": "{$competitor1} cu încercare",
        "name_ru": "{$competitor1} с попытки",
        "name_tr": "{$competitor1} try ile",
        "name_se": "{$competitor1} med försök",
        "name_el": "{$competitor1} με try"
    },
    "1641": {
        "name_en": "{$competitor1} with penalty",
        "name_aa": "{$competitor1} مع ركلة جزاء",
        "name_de": "{$competitor1} mit Strafstoß",
        "name_nl": "{$competitor1} met penalty",
        "name_pl": "{$competitor1} z karnym",
        "name_ro": "{$competitor1} cu penalti",
        "name_ru": "{$competitor1} с пенальти",
        "name_tr": "{$competitor1} penaltı ile",
        "name_se": "{$competitor1} med straff",
        "name_el": "{$competitor1} με πέναλτι"
    },
    "1642": {
        "name_en": "{$competitor1} with drop goal",
        "name_aa": "{$competitor1} مع هدف إسقاط",
        "name_de": "{$competitor1} mit Drop goal",
        "name_nl": "{$competitor1} met drop goal",
        "name_pl": "{$competitor1} z dropgolem",
        "name_ro": "{$competitor1} cu drop goal",
        "name_ru": "{$competitor1} с дроп-гола",
        "name_tr": "{$competitor1}şutla",
        "name_se": "{$competitor1} med drop goal",
        "name_el": "{$competitor1} με drop goal"
    },
    "1643": {
        "name_en": "{$competitor2} with try",
        "name_aa": "{$competitor2} مع المحاولة",
        "name_de": "{$competitor2} mit Versuch",
        "name_nl": "{$competitor2} met try",
        "name_pl": "{$competitor2} z przyłożeniem",
        "name_ro": "{$competitor2} cu încercare",
        "name_ru": "{$competitor2} с попытки",
        "name_tr": "{$competitor2} try ile",
        "name_se": "{$competitor2} med försök",
        "name_el": "{$competitor2} με try"
    },
    "1644": {
        "name_en": "{$competitor2} with penalty",
        "name_aa": "{$competitor2} مع ركلة جزاء",
        "name_de": "{$competitor2} mit Strafstoß",
        "name_nl": "{$competitor2} met penalty",
        "name_pl": "{$competitor2} z karnym",
        "name_ro": "{$competitor2} cu penalti",
        "name_ru": "{$competitor2} с пенальти",
        "name_tr": "{$competitor2} penaltı ile",
        "name_se": "{$competitor2} med straff",
        "name_el": "{$competitor2} με πέναλτι"
    },
    "1645": {
        "name_en": "{$competitor2} with drop goal",
        "name_aa": "{$competitor2} مع هدف السقوط",
        "name_de": "{$competitor2} mit Drop goal",
        "name_nl": "{$competitor2} met drop goal",
        "name_pl": "{$competitor2} z dropgolem",
        "name_ro": "{$competitor2} cu drop goal",
        "name_ru": "{$competitor2} с дроп-гола",
        "name_tr": "{$competitor2}şutla",
        "name_se": "{$competitor2} med drop goal",
        "name_el": "{$competitor2} με drop goal"
    },
    "1667": {
        "name_en": "0-49",
        "name_aa": "0-49",
        "name_de": "0-49",
        "name_nl": "0-49",
        "name_pl": "0-49",
        "name_ro": "0-49",
        "name_ru": "0-49",
        "name_tr": "0-49",
        "name_se": "0-49",
        "name_el": "0-49"
    },
    "1668": {
        "name_en": "50-99",
        "name_aa": "50-99",
        "name_de": "50-99",
        "name_nl": "50-99",
        "name_pl": "50-99",
        "name_ro": "50-99",
        "name_ru": "50-99",
        "name_tr": "50-99",
        "name_se": "50-99",
        "name_el": "50-99"
    },
    "1669": {
        "name_en": "100-119",
        "name_aa": "100-119",
        "name_de": "100-119",
        "name_nl": "100-119",
        "name_pl": "100-119",
        "name_ro": "100-119",
        "name_ru": "100-119",
        "name_tr": "100-119",
        "name_se": "100-119",
        "name_el": "100-119"
    },
    "1670": {
        "name_en": "120-146",
        "name_aa": "120-146",
        "name_de": "120-146",
        "name_nl": "120-146",
        "name_pl": "120-146",
        "name_ro": "120-146",
        "name_ru": "120-146",
        "name_tr": "120-146",
        "name_se": "120-146",
        "name_el": "120-146"
    },
    "1671": {
        "name_en": "147+",
        "name_aa": "147+",
        "name_de": "147+",
        "name_nl": "147+",
        "name_pl": "147+",
        "name_ro": "147+",
        "name_ru": "147+",
        "name_tr": "147+",
        "name_se": "147+",
        "name_el": "147+"
    },
    "1672": {
        "name_en": "yellow",
        "name_aa": "الأصفر",
        "name_de": "Gelb",
        "name_nl": "geel",
        "name_pl": "żółta",
        "name_ro": "galben",
        "name_ru": "желтый",
        "name_tr": "sarı",
        "name_se": "gul",
        "name_el": "κίτρινο"
    },
    "1673": {
        "name_en": "green",
        "name_aa": "أخضر",
        "name_de": "Grün",
        "name_nl": "groen",
        "name_pl": "zielona",
        "name_ro": "verde",
        "name_ru": "зеленый",
        "name_tr": "yeşil",
        "name_se": "grön",
        "name_el": "πράσινο"
    },
    "1674": {
        "name_en": "brown",
        "name_aa": "بنى",
        "name_de": "Braun",
        "name_nl": "bruin",
        "name_pl": "brązowa",
        "name_ro": "maro",
        "name_ru": "коричневый",
        "name_tr": "kahverengi",
        "name_se": "brun",
        "name_el": "καφέ"
    },
    "1675": {
        "name_en": "blue",
        "name_aa": "أزرق",
        "name_de": "Blau",
        "name_nl": "blauw",
        "name_pl": "niebieska",
        "name_ro": "albastru",
        "name_ru": "синий",
        "name_tr": "mavi",
        "name_se": "blå",
        "name_el": "μπλε"
    },
    "1676": {
        "name_en": "pink",
        "name_aa": "زهري",
        "name_de": "Pink",
        "name_nl": "roze",
        "name_pl": "różowa",
        "name_ro": "roz",
        "name_ru": "розовый",
        "name_tr": "pembe",
        "name_se": "rosa",
        "name_el": "ροζ"
    },
    "1677": {
        "name_en": "black",
        "name_aa": "أسود",
        "name_de": "Schwarz",
        "name_nl": "zwart",
        "name_pl": "czarna",
        "name_ro": "negru",
        "name_ru": "черный",
        "name_tr": "siyah",
        "name_se": "svart",
        "name_el": "μαύρο"
    },
    "1678": {
        "name_en": "red",
        "name_aa": "أحمر",
        "name_de": "Rot",
        "name_nl": "rood",
        "name_pl": "czerwona",
        "name_ro": "roșu",
        "name_ru": "красный",
        "name_tr": "kırmızı",
        "name_se": "röd",
        "name_el": "κόκκινο"
    },
    "1679": {
        "name_en": "yellow",
        "name_aa": "الأصفر",
        "name_de": "Gelb",
        "name_nl": "geel",
        "name_pl": "żółta",
        "name_ro": "galben",
        "name_ru": "желтый",
        "name_tr": "sarı",
        "name_se": "gul",
        "name_el": "κίτρινο"
    },
    "1680": {
        "name_en": "green",
        "name_aa": "أخضر",
        "name_de": "Grün",
        "name_nl": "groen",
        "name_pl": "zielona",
        "name_ro": "verde",
        "name_ru": "зеленый",
        "name_tr": "yeşil",
        "name_se": "grön",
        "name_el": "πράσινο"
    },
    "1681": {
        "name_en": "brown",
        "name_aa": "بنى",
        "name_de": "Braun",
        "name_nl": "bruin",
        "name_pl": "brązowa",
        "name_ro": "maro",
        "name_ru": "коричневый",
        "name_tr": "kahverengi",
        "name_se": "brun",
        "name_el": "καφέ"
    },
    "1682": {
        "name_en": "blue",
        "name_aa": "أزرق",
        "name_de": "Blau",
        "name_nl": "blauw",
        "name_pl": "niebieska",
        "name_ro": "albastru",
        "name_ru": "синий",
        "name_tr": "mavi",
        "name_se": "blå",
        "name_el": "μπλε"
    },
    "1683": {
        "name_en": "pink",
        "name_aa": "زهري",
        "name_de": "Pink",
        "name_nl": "roze",
        "name_pl": "różowa",
        "name_ro": "roz",
        "name_ru": "розовый",
        "name_tr": "pembe",
        "name_se": "rosa",
        "name_el": "ροζ"
    },
    "1684": {
        "name_en": "black",
        "name_aa": "أسود",
        "name_de": "Schwarz",
        "name_nl": "zwart",
        "name_pl": "czarna",
        "name_ro": "negru",
        "name_ru": "черный",
        "name_tr": "siyah",
        "name_se": "svart",
        "name_el": "μαύρο"
    },
    "1685": {
        "name_en": "foul",
        "name_aa": "خطأ",
        "name_de": "Foul",
        "name_nl": "fout",
        "name_pl": "faul",
        "name_ro": "roșu",
        "name_ru": "фол",
        "name_tr": "faul",
        "name_se": "foul",
        "name_el": "φάουλ"
    },
    "1706": {
        "name_en": "{$competitor1} & yes",
        "name_aa": "{$competitor1} ونعم",
        "name_de": "{$competitor1} & ja",
        "name_nl": "{$competitor1} & ja",
        "name_pl": "{$competitor1} & tak",
        "name_ro": "{$competitor1} & da",
        "name_ru": "{$competitor1} и да",
        "name_tr": "{$competitor1} & var",
        "name_se": "{$competitor1} & ja",
        "name_el": "ναι και {$competitor1}"
    },
    "1708": {
        "name_en": "{$competitor1} & no",
        "name_aa": "{$competitor1} ولا",
        "name_de": "{$competitor1} & nein",
        "name_nl": "{$competitor1} & nee",
        "name_pl": "{$competitor1} & nie",
        "name_ro": "{$competitor1} & nu",
        "name_ru": "{$competitor1} и нет",
        "name_tr": "{$competitor1} & yok",
        "name_se": "{$competitor1} & nej",
        "name_el": "όχι και {$competitor1}"
    },
    "1709": {
        "name_en": "{$competitor2} & yes",
        "name_aa": "{$competitor2} ونعم",
        "name_de": "{$competitor2} & ja",
        "name_nl": "{$competitor2} & ja",
        "name_pl": "{$competitor2} & tak",
        "name_ro": "{$competitor2} & da",
        "name_ru": "{$competitor2} и да",
        "name_tr": "{$competitor2} & var",
        "name_se": "{$competitor2} & ja",
        "name_el": "ναι και {$competitor2}"
    },
    "1710": {
        "name_en": "{$competitor2} & no",
        "name_aa": "{$competitor2} ولا",
        "name_de": "{$competitor2} & nein",
        "name_nl": "{$competitor2} & nee",
        "name_pl": "{$competitor2} & nie",
        "name_ro": "{$competitor2} & nu",
        "name_ru": "{$competitor2} и нет",
        "name_tr": "{$competitor2} & yok",
        "name_se": "{$competitor2} & nej",
        "name_el": "όχι και {$competitor2}"
    },
    "1711": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "1712": {
        "name_en": "X",
        "name_aa": "ارسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "berabere",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "1713": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "1714": {
        "name_en": "{$competitor1} ({+hcp})",
        "name_aa": "{$competitor1} ({+hcp})",
        "name_de": "{$competitor1} ({+hcp})",
        "name_nl": "{$competitor1} ({hcp})",
        "name_pl": "{$competitor1} ({+hcp})",
        "name_ro": "{$competitor1} ({+hcp})",
        "name_ru": "{$competitor1} ({+hcp})",
        "name_tr": "{$competitor1} ({+hcp})",
        "name_se": "{$competitor1} ({+hcp})",
        "name_el": "({+hcp}) {$competitor1}"
    },
    "1715": {
        "name_en": "{$competitor2} ({-hcp})",
        "name_aa": "{$competitor2} ({-hcp})",
        "name_de": "{$competitor2} ({-hcp})",
        "name_nl": "{$competitor2} ({hcp})",
        "name_pl": "{$competitor2} ({-hcp})",
        "name_ro": "{$competitor2} ({-hcp})",
        "name_ru": "{$competitor2} ({-hcp})",
        "name_tr": "{$competitor2} ({-hcp})",
        "name_se": "{$competitor2} ({-hcp})",
        "name_el": "({-hcp}) {$competitor2}"
    },
    "1718": {
        "name_en": "{$competitor1}/X & yes",
        "name_aa": "{$competitor1} / رسم ونعم",
        "name_de": "{$competitor1}/X & ja",
        "name_nl": "{$competitor1}/X & ja",
        "name_pl": "{$competitor1}/remis & tak",
        "name_ro": "{$competitor1}/egal & da",
        "name_ru": "{$competitor1}/ничья и да",
        "name_tr": "{$competitor1}/X & var",
        "name_se": "{$competitor1}/oavgjort & ja",
        "name_el": "{$competitor1}/ ισοπαλία και ναι"
    },
    "1719": {
        "name_en": "{$competitor1}/X & no",
        "name_aa": "{$competitor1} / رسم ولا",
        "name_de": "{$competitor1}/X & nein",
        "name_nl": "{$competitor1}/X & nee",
        "name_pl": "{$competitor1}/remis & nie",
        "name_ro": "{$competitor1}/egal & nu",
        "name_ru": "{$competitor1}/ничья и нет",
        "name_tr": "{$competitor1}/X & yok",
        "name_se": "{$competitor1}/oavgjort & nej",
        "name_el": "{$competitor1}/ ισοπαλία και όχι"
    },
    "1720": {
        "name_en": "{$competitor1}/{$competitor2} & yes",
        "name_aa": "{$competitor1} / {$competitor2} ونعم",
        "name_de": "{$competitor1}/{$competitor2} & ja",
        "name_nl": "{$competitor1}/{$competitor2} & ja",
        "name_pl": "{$competitor1}/{$competitor2} & tak",
        "name_ro": "{$competitor1}/{$competitor2} & da",
        "name_ru": "{$competitor1}/{$competitor2} и да",
        "name_tr": "{$competitor1}/{$competitor2} & var",
        "name_se": "{$competitor1}/{$competitor2} & ja",
        "name_el": "{$competitor1}/{$competitor2} και ναι"
    },
    "1721": {
        "name_en": "{$competitor1}/{$competitor2} & no",
        "name_aa": "{$competitor1} / {$competitor2} ولا",
        "name_de": "{$competitor1}/{$competitor2} & nein",
        "name_nl": "{$competitor1}/{$competitor2} & nee",
        "name_pl": "{$competitor1}/{$competitor2} & nie",
        "name_ro": "{$competitor1}/{$competitor2} & nu",
        "name_ru": "{$competitor1}/{$competitor2} и нет",
        "name_tr": "{$competitor1}/{$competitor2} & yok",
        "name_se": "{$competitor1}/{$competitor2} & nej",
        "name_el": "{$competitor1}/{$competitor2} και όχι"
    },
    "1722": {
        "name_en": "X/{$competitor2} & yes",
        "name_aa": "ارسم / {$competitor2} ونعم",
        "name_de": "X/{$competitor2} & ja",
        "name_nl": "X/{$competitor2} & ja",
        "name_pl": "remis/{$competitor2} & tak",
        "name_ro": "egal/{$competitor2} & da",
        "name_ru": "ничья/{$competitor2} и да",
        "name_tr": "X/{$competitor2} & var",
        "name_se": "oavgjort/{$competitor2} & ja",
        "name_el": "ισοπαλία/{$competitor2} και ναι"
    },
    "1723": {
        "name_en": "X/{$competitor2} & no",
        "name_aa": "رسم / {$competitor2} ولا",
        "name_de": "X/{$competitor2} & nein",
        "name_nl": "X/{$competitor2} & nee",
        "name_pl": "remis/{$competitor2} & nie",
        "name_ro": "egal/{$competitor2} & nu",
        "name_ru": "ничья/{$competitor2} и нет",
        "name_tr": "X/{$competitor2} & yok",
        "name_se": "oavgjort/{$competitor2} & nej",
        "name_el": "ισοπαλία/{$competitor2} και όχι"
    },
    "1724": {
        "name_en": "{$competitor1}/X & under {total}",
        "name_aa": "{$competitor1}/X & under {total}",
        "name_de": "{$competitor1}/X & under {total}",
        "name_nl": "{$competitor1}/X & under {total}",
        "name_pl": "{$competitor1}/X & under {total}",
        "name_ro": "{$competitor1}/X & under {total}",
        "name_ru": "{$competitor1}/X & under {total}",
        "name_tr": "{$competitor1}/X & under {total}",
        "name_se": "{$competitor1}/X & under {total}",
        "name_el": "{$competitor1}/X & under {total}"
    },
    "1725": {
        "name_en": "{$competitor1}/{$competitor2} & under {total}",
        "name_aa": "{$competitor1}/{$competitor2} & under {total}",
        "name_de": "{$competitor1}/{$competitor2} & under {total}",
        "name_nl": "{$competitor1}/{$competitor2} & under {total}",
        "name_pl": "{$competitor1}/{$competitor2} & under {total}",
        "name_ro": "{$competitor1}/{$competitor2} & under {total}",
        "name_ru": "{$competitor1}/{$competitor2} & under {total}",
        "name_tr": "{$competitor1}/{$competitor2} & under {total}",
        "name_se": "{$competitor1}/{$competitor2} & under {total}",
        "name_el": "{$competitor1}/{$competitor2} & under {total}"
    },
    "1726": {
        "name_en": "X/{$competitor2} & under {total}",
        "name_aa": "X/{$competitor2} & under {total}",
        "name_de": "X/{$competitor2} & under {total}",
        "name_nl": "X/{$competitor2} & under {total}",
        "name_pl": "X/{$competitor2} & under {total}",
        "name_ro": "X/{$competitor2} & under {total}",
        "name_ru": "X/{$competitor2} & under {total}",
        "name_tr": "X/{$competitor2} & under {total}",
        "name_se": "X/{$competitor2} & under {total}",
        "name_el": "X/{$competitor2} & under {total}"
    },
    "1727": {
        "name_en": "{$competitor1}/X & over {total}",
        "name_aa": "{$competitor1}/X & over {total}",
        "name_de": "{$competitor1}/X & over {total}",
        "name_nl": "{$competitor1}/X & over {total}",
        "name_pl": "{$competitor1}/X & over {total}",
        "name_ro": "{$competitor1}/X & over {total}",
        "name_ru": "{$competitor1}/X & over {total}",
        "name_tr": "{$competitor1}/X & over {total}",
        "name_se": "{$competitor1}/X & over {total}",
        "name_el": "{$competitor1}/X & over {total}"
    },
    "1728": {
        "name_en": "{$competitor1}/{$competitor2} & over {total}",
        "name_aa": "{$competitor1}/{$competitor2} & over {total}",
        "name_de": "{$competitor1}/{$competitor2} & over {total}",
        "name_nl": "{$competitor1}/{$competitor2} & over {total}",
        "name_pl": "{$competitor1}/{$competitor2} & over {total}",
        "name_ro": "{$competitor1}/{$competitor2} & over {total}",
        "name_ru": "{$competitor1}/{$competitor2} & over {total}",
        "name_tr": "{$competitor1}/{$competitor2} & over {total}",
        "name_se": "{$competitor1}/{$competitor2} & over {total}",
        "name_el": "{$competitor1}/{$competitor2} & over {total}"
    },
    "1729": {
        "name_en": "X/{$competitor2} & over {total}",
        "name_aa": "X/{$competitor2} & over {total}",
        "name_de": "X/{$competitor2} & over {total}",
        "name_nl": "X/{$competitor2} & over {total}",
        "name_pl": "X/{$competitor2} & over {total}",
        "name_ro": "X/{$competitor2} & over {total}",
        "name_ru": "X/{$competitor2} & over {total}",
        "name_tr": "X/{$competitor2} & over {total}",
        "name_se": "X/{$competitor2} & over {total}",
        "name_el": "X/{$competitor2} & over {total}"
    },
    "1730": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2"
    },
    "1731": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3"
    },
    "1732": {
        "name_en": "1-4",
        "name_aa": "1-4",
        "name_de": "1-4",
        "name_nl": "1-4",
        "name_pl": "1-4",
        "name_ro": "1-4",
        "name_ru": "1-4",
        "name_tr": "1-4",
        "name_se": "1-4",
        "name_el": "1-4"
    },
    "1733": {
        "name_en": "1-5",
        "name_aa": "1-5",
        "name_de": "1-5",
        "name_nl": "1-5",
        "name_pl": "1-5",
        "name_ro": "1-5",
        "name_ru": "1-5",
        "name_tr": "1-5",
        "name_se": "1-5",
        "name_el": "1-5"
    },
    "1734": {
        "name_en": "1-6",
        "name_aa": "1-6",
        "name_de": "1-6",
        "name_nl": "1-6",
        "name_pl": "1-6",
        "name_ro": "1-6",
        "name_ru": "1-6",
        "name_tr": "1-6",
        "name_se": "1-6",
        "name_el": "1-6"
    },
    "1735": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3"
    },
    "1736": {
        "name_en": "2-4",
        "name_aa": "2-4",
        "name_de": "2-4",
        "name_nl": "2-4",
        "name_pl": "2-4",
        "name_ro": "2-4",
        "name_ru": "2-4",
        "name_tr": "2-4",
        "name_se": "2-4",
        "name_el": "2-4"
    },
    "1737": {
        "name_en": "2-5",
        "name_aa": "2-5",
        "name_de": "2-5",
        "name_nl": "2-5",
        "name_pl": "2-5",
        "name_ro": "2-5",
        "name_ru": "2-5",
        "name_tr": "2-5",
        "name_se": "2-5",
        "name_el": "2-5"
    },
    "1738": {
        "name_en": "2-6",
        "name_aa": "2-6",
        "name_de": "2-6",
        "name_nl": "2-6",
        "name_pl": "2-6",
        "name_ro": "2-6",
        "name_ru": "2-6",
        "name_tr": "2-6",
        "name_se": "2-6",
        "name_el": "2-6"
    },
    "1739": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4"
    },
    "1740": {
        "name_en": "3-5",
        "name_aa": "3-5",
        "name_de": "3-5",
        "name_nl": "3-5",
        "name_pl": "3-5",
        "name_ro": "3-5",
        "name_ru": "3-5",
        "name_tr": "3-5",
        "name_se": "3-5",
        "name_el": "3-5"
    },
    "1741": {
        "name_en": "3-6",
        "name_aa": "3-6",
        "name_de": "3-6",
        "name_nl": "3-6",
        "name_pl": "3-6",
        "name_ro": "3-6",
        "name_ru": "3-6",
        "name_tr": "3-6",
        "name_se": "3-6",
        "name_el": "3-6"
    },
    "1742": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5"
    },
    "1743": {
        "name_en": "4-6",
        "name_aa": "4-6",
        "name_de": "4-6",
        "name_nl": "4-6",
        "name_pl": "4-6",
        "name_ro": "4-6",
        "name_ru": "4-6",
        "name_tr": "4-6",
        "name_se": "4-6",
        "name_el": "4-6"
    },
    "1744": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6"
    },
    "1745": {
        "name_en": "7+",
        "name_aa": "7+",
        "name_de": "7+",
        "name_nl": "7+",
        "name_pl": "7+",
        "name_ro": "7+",
        "name_ru": "7+",
        "name_tr": "7+",
        "name_se": "7+",
        "name_el": "7+"
    },
    "1746": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2"
    },
    "1747": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3"
    },
    "1748": {
        "name_en": "2-3",
        "name_aa": "2-3",
        "name_de": "2-3",
        "name_nl": "2-3",
        "name_pl": "2-3",
        "name_ro": "2-3",
        "name_ru": "2-3",
        "name_tr": "2-3",
        "name_se": "2-3",
        "name_el": "2-3"
    },
    "1749": {
        "name_en": "4+",
        "name_aa": "4+",
        "name_de": "4+",
        "name_nl": "4+",
        "name_pl": "4+",
        "name_ro": "4+",
        "name_ru": "4+",
        "name_tr": "4+",
        "name_se": "4+",
        "name_el": "4+"
    },
    "1750": {
        "name_en": "1:0, 2:0 or 3:0",
        "name_aa": "1:0, 2:0 أو 3:0",
        "name_de": "1:0, 2:0 oder 3:0",
        "name_nl": "1:0, 2:0 of 3:0",
        "name_pl": "1:0, 2:0 lub 3:0",
        "name_ro": "1:0, 2:0 sau 3:0",
        "name_ru": "1:0, 2:0 или 3:0",
        "name_tr": "1:0, 2:0 veya 3:0",
        "name_se": "1:0, 2:0 elr 3:0",
        "name_el": "1-0, 2-0 ή 3-0"
    },
    "1751": {
        "name_en": "0:1, 0:2 or 0:3",
        "name_aa": "0:1, 0:2 أو 0:3",
        "name_de": "0:1, 0:2 oder 0:3",
        "name_nl": "0:1, 0:2 of 0:3",
        "name_pl": "0:1, 0:2 lub 0:3",
        "name_ro": "0:1, 0:2 sau 0:3",
        "name_ru": "0:1, 0:2 или 0:3",
        "name_tr": "0:1, 0:2 veya 0:3",
        "name_se": "0:1, 0:2 elr 0:3",
        "name_el": "0-1, 0-2 ή 0-3"
    },
    "1752": {
        "name_en": "4:0, 5:0 or 6:0",
        "name_aa": "4:0, 5:0 أو 6:0",
        "name_de": "4:0, 5:0 oder 6:0",
        "name_nl": "4:0, 5:0 of 6:0",
        "name_pl": "4:0, 5:0 lub 6:0",
        "name_ro": "4:0, 5:0 sau 6:0",
        "name_ru": "4:0, 5:0 или 6:0",
        "name_tr": "4:0, 5:0 veya 6:0",
        "name_se": "4:0, 5:0 elr 6:0",
        "name_el": "4-0, 5-0 ή 6-0"
    },
    "1753": {
        "name_en": "0:4, 0:5 or 0:6",
        "name_aa": "0:4, 0:5 أو 0:6",
        "name_de": "0:4, 0:5 oder 0:6",
        "name_nl": "0:4, 0:5 of 0:6",
        "name_pl": "0:4, 0:5 lub 0:6",
        "name_ro": "0:4, 0:5 sau 0:6",
        "name_ru": "0:4, 0:5 или 0:6",
        "name_tr": "0:4, 0:5 veya 0:6",
        "name_se": "0:4, 0:5 elr 0:6",
        "name_el": "0-4, 0-5 ή 0-6"
    },
    "1754": {
        "name_en": "2:1, 3:1 or 4:1",
        "name_aa": "2:1, 3:1 أو 4:1",
        "name_de": "2:1, 3:1 oder 4:1",
        "name_nl": "2:1, 3:1 of 4:1",
        "name_pl": "2:1, 3:1 lub 4:1",
        "name_ro": "2:1, 3:1 sau 4:1",
        "name_ru": "2:1, 3:1 или 4:1",
        "name_tr": "2:1, 3:1 veya 4:1",
        "name_se": "2:1, 3:1 elr 4:1",
        "name_el": "2-1, 3-1 ή 4-1"
    },
    "1755": {
        "name_en": "1:2, 1:3 or 1:4",
        "name_aa": "1:2, 1:3 أو 1:4",
        "name_de": "1:2, 1:3 oder 1:4",
        "name_nl": "1:2, 1:3 of 1:4",
        "name_pl": "1:2, 1:3 lub 1:4",
        "name_ro": "1:2, 1:3 sau 1:4",
        "name_ru": "1:2, 1:3 или 1:4",
        "name_tr": "1:2, 1:3 veya 1:4",
        "name_se": "1:2, 1:3 elr 1:4",
        "name_el": "1-2, 1-3 ή 1-4"
    },
    "1756": {
        "name_en": "3:2, 4:2, 4:3 or 5:1",
        "name_aa": "3:2, 4:2, 4:3 أو 5:1",
        "name_de": "3:2, 4:2, 4:3 oder 5:1",
        "name_nl": "3:2, 4:2, 4:3 of 5:1",
        "name_pl": "3:2, 4:2, 4:3 lub 5:1",
        "name_ro": "3:2, 4:2, 4:3 sau 5:1",
        "name_ru": "3:2, 4:2, 4:3 или 5:1",
        "name_tr": "3:2, 4:2, 4:3 veya 5:1",
        "name_se": "3:2, 4:2, 4:3 elr 5:1",
        "name_el": "3-2, 4-2, 4-3 ή 5-1"
    },
    "1757": {
        "name_en": "2:3, 2:4, 3:4 or 1:5",
        "name_aa": "2:3, 2:4, 3:4 أو 1:5",
        "name_de": "2:3, 2:4, 3:4 oder 1:5",
        "name_nl": "2:3, 2:4, 3:4 of 1:5",
        "name_pl": "2:3, 2:4, 3:4 lub 1:5",
        "name_ro": "2:3, 2:4, 3:4 sau 1:5",
        "name_ru": "2:3, 2:4, 3:4 или 1:5",
        "name_tr": "2:3, 2:4, 3:4 veya 1:5",
        "name_se": "2:3, 2:4, 3:4 elr 1:5",
        "name_el": "2-3, 2-4, 3-4 ή 1-5"
    },
    "1758": {
        "name_en": "other homewin",
        "name_aa": "منازل أخرى",
        "name_de": "andere Sieg Heimmannschaft",
        "name_nl": "ander thuisoverwinning",
        "name_pl": "inne zwycięstwo gospodarze",
        "name_ro": "altul victorie gazde",
        "name_ru": "другой, победа хозяев",
        "name_tr": "diğer ev sahibi galibiyeti",
        "name_se": "annan hemmaseger",
        "name_el": "νίκη γηπεδούχου με άλλο σκορ"
    },
    "1759": {
        "name_en": "other awaywin",
        "name_aa": "بعيدا اخرى",
        "name_de": "andere Sieg Auswärtsmannschaft",
        "name_nl": "ander uitoverwinning",
        "name_pl": "inne zwycięstwo goście",
        "name_ro": "altul victorie oaspeți",
        "name_ru": "другой, победа гостей",
        "name_tr": "diğer deplasman galibiyeti",
        "name_se": "annan bortaseger",
        "name_el": "νίκη φιλοξενούμενου με άλλο σκορ"
    },
    "1760": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "1761": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "1762": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "1763": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "1764": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "1765": {
        "name_en": "5",
        "name_aa": "5",
        "name_de": "5",
        "name_nl": "5",
        "name_pl": "5",
        "name_ro": "5",
        "name_ru": "5",
        "name_tr": "5",
        "name_se": "5",
        "name_el": "5"
    },
    "1766": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+"
    },
    "1767": {
        "name_en": "0:1",
        "name_aa": "0:1",
        "name_de": "0:1",
        "name_nl": "0:1",
        "name_pl": "0:1",
        "name_ro": "0:1",
        "name_ru": "0:1",
        "name_tr": "0:1",
        "name_se": "0:1",
        "name_el": "0:1"
    },
    "1768": {
        "name_en": "0:2",
        "name_aa": "0:2",
        "name_de": "0:2",
        "name_nl": "0:2",
        "name_pl": "0:2",
        "name_ro": "0:2",
        "name_ru": "0:2",
        "name_tr": "0:2",
        "name_se": "0:2",
        "name_el": "0:2"
    },
    "1769": {
        "name_en": "0:3",
        "name_aa": "0:3",
        "name_de": "0:3",
        "name_nl": "0:3",
        "name_pl": "0:3",
        "name_ro": "0:3",
        "name_ru": "0:3",
        "name_tr": "0:3",
        "name_se": "0:3",
        "name_el": "0:3"
    },
    "1770": {
        "name_en": "1:0",
        "name_aa": "1:0",
        "name_de": "1:0",
        "name_nl": "1:0",
        "name_pl": "1:0",
        "name_ro": "1:0",
        "name_ru": "1:0",
        "name_tr": "1:0",
        "name_se": "1:0",
        "name_el": "1:0"
    },
    "1771": {
        "name_en": "1:2",
        "name_aa": "1:2",
        "name_de": "1:2",
        "name_nl": "1:2",
        "name_pl": "1:2",
        "name_ro": "1:2",
        "name_ru": "1:2",
        "name_tr": "1:2",
        "name_se": "1:2",
        "name_el": "1:2"
    },
    "1772": {
        "name_en": "1:3",
        "name_aa": "1:3",
        "name_de": "1:3",
        "name_nl": "1:3",
        "name_pl": "1:3",
        "name_ro": "1:3",
        "name_ru": "1:3",
        "name_tr": "1:3",
        "name_se": "1:3",
        "name_el": "1:3"
    },
    "1773": {
        "name_en": "1:4",
        "name_aa": "1:4",
        "name_de": "1:4",
        "name_nl": "1:4",
        "name_pl": "1:4",
        "name_ro": "1:4",
        "name_ru": "1:4",
        "name_tr": "1:4",
        "name_se": "1:4",
        "name_el": "1:4"
    },
    "1774": {
        "name_en": "2:0",
        "name_aa": "2:0",
        "name_de": "2:0",
        "name_nl": "2:0",
        "name_pl": "2:0",
        "name_ro": "2:0",
        "name_ru": "2:0",
        "name_tr": "2:0",
        "name_se": "2:0",
        "name_el": "2:0"
    },
    "1775": {
        "name_en": "2:1",
        "name_aa": "2:1",
        "name_de": "2:1",
        "name_nl": "2:1",
        "name_pl": "2:1",
        "name_ro": "2:1",
        "name_ru": "2:1",
        "name_tr": "2:1",
        "name_se": "2:1",
        "name_el": "2:1"
    },
    "1776": {
        "name_en": "2:3",
        "name_aa": "2:3",
        "name_de": "2:3",
        "name_nl": "2:3",
        "name_pl": "2:3",
        "name_ro": "2:3",
        "name_ru": "2:3",
        "name_tr": "2:3",
        "name_se": "2:3",
        "name_el": "2:3"
    },
    "1777": {
        "name_en": "2:4",
        "name_aa": "2:4",
        "name_de": "2:4",
        "name_nl": "2:4",
        "name_pl": "2:4",
        "name_ro": "2:4",
        "name_ru": "2:4",
        "name_tr": "2:4",
        "name_se": "2:4",
        "name_el": "2:4"
    },
    "1778": {
        "name_en": "3:0",
        "name_aa": "3:0",
        "name_de": "3:0",
        "name_nl": "3:0",
        "name_pl": "3:0",
        "name_ro": "3:0",
        "name_ru": "3:0",
        "name_tr": "3:0",
        "name_se": "3:0",
        "name_el": "3:0"
    },
    "1779": {
        "name_en": "3:1",
        "name_aa": "3:1",
        "name_de": "3:1",
        "name_nl": "3:1",
        "name_pl": "3:1",
        "name_ro": "3:1",
        "name_ru": "3:1",
        "name_tr": "3:1",
        "name_se": "3:1",
        "name_el": "3:1"
    },
    "1780": {
        "name_en": "3:2",
        "name_aa": "3:2",
        "name_de": "3:2",
        "name_nl": "3:2",
        "name_pl": "3:2",
        "name_ro": "3:2",
        "name_ru": "3:2",
        "name_tr": "3:2",
        "name_se": "3:2",
        "name_el": "3:2"
    },
    "1781": {
        "name_en": "3:4",
        "name_aa": "3:4",
        "name_de": "3:4",
        "name_nl": "3:4",
        "name_pl": "3:4",
        "name_ro": "3:4",
        "name_ru": "3:4",
        "name_tr": "3:4",
        "name_se": "3:4",
        "name_el": "3:4"
    },
    "1782": {
        "name_en": "3:5",
        "name_aa": "3:5",
        "name_de": "3:5",
        "name_nl": "3:5",
        "name_pl": "3:5",
        "name_ro": "3:5",
        "name_ru": "3:5",
        "name_tr": "3:5",
        "name_se": "3:5",
        "name_el": "3:5"
    },
    "1783": {
        "name_en": "4:1",
        "name_aa": "4:1",
        "name_de": "4:1",
        "name_nl": "4:1",
        "name_pl": "4:1",
        "name_ro": "4:1",
        "name_ru": "4:1",
        "name_tr": "4:1",
        "name_se": "4:1",
        "name_el": "4:1"
    },
    "1784": {
        "name_en": "4:2",
        "name_aa": "4:2",
        "name_de": "4:2",
        "name_nl": "4:2",
        "name_pl": "4:2",
        "name_ro": "4:2",
        "name_ru": "4:2",
        "name_tr": "4:2",
        "name_se": "4:2",
        "name_el": "4:2"
    },
    "1785": {
        "name_en": "4:3",
        "name_aa": "4:3",
        "name_de": "4:3",
        "name_nl": "4:3",
        "name_pl": "4:3",
        "name_ro": "4:3",
        "name_ru": "4:3",
        "name_tr": "4:3",
        "name_se": "4:3",
        "name_el": "4:3"
    },
    "1786": {
        "name_en": "4:5",
        "name_aa": "4:5",
        "name_de": "4:5",
        "name_nl": "4:5",
        "name_pl": "4:5",
        "name_ro": "4:5",
        "name_ru": "4:5",
        "name_tr": "4:5",
        "name_se": "4:5",
        "name_el": "4-5"
    },
    "1787": {
        "name_en": "5:3",
        "name_aa": "5:3",
        "name_de": "5:3",
        "name_nl": "5:3",
        "name_pl": "5:3",
        "name_ro": "5:3",
        "name_ru": "5:3",
        "name_tr": "5:3",
        "name_se": "5:3",
        "name_el": "5-3"
    },
    "1788": {
        "name_en": "5:4",
        "name_aa": "5:4",
        "name_de": "5:4",
        "name_nl": "5:4",
        "name_pl": "5:4",
        "name_ro": "5:4",
        "name_ru": "5:4",
        "name_tr": "5:4",
        "name_se": "5:4",
        "name_el": "5-4"
    },
    "1789": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другой",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1797": {
        "name_en": "high left",
        "name_aa": "اليسار عالية",
        "name_de": "oben links",
        "name_nl": "hoog links",
        "name_pl": "U góry po lewej",
        "name_ro": "stânga sus",
        "name_ru": "вверх влево",
        "name_tr": "sol yukarı",
        "name_se": "hög vänster",
        "name_el": "ψηλά αριστερά"
    },
    "1798": {
        "name_en": "low left",
        "name_aa": "منخفض اليسار",
        "name_de": "unten links",
        "name_nl": "laag links",
        "name_pl": "Na dole po lewej",
        "name_ro": "stânga jos",
        "name_ru": "вниз влево",
        "name_tr": "sol aşağı",
        "name_se": "låg vänster",
        "name_el": "κάτω αριστερά"
    },
    "1799": {
        "name_en": "high center",
        "name_aa": "مركز عالي",
        "name_de": "oben Mitte",
        "name_nl": "hoog midden",
        "name_pl": "Na górze na środku",
        "name_ro": "centru sus",
        "name_ru": "вверх по центру",
        "name_tr": "merkez yukarı",
        "name_se": "hög mitt",
        "name_el": "ψηλά κέντρο"
    },
    "1800": {
        "name_en": "low center",
        "name_aa": "مركز منخفض",
        "name_de": "unten Mitte",
        "name_nl": "laag midden",
        "name_pl": "Na dole na środku",
        "name_ro": "centru jos",
        "name_ru": "вниз по центру",
        "name_tr": "merkez aşağı",
        "name_se": "låg mitt",
        "name_el": "κάτω κέντρο"
    },
    "1801": {
        "name_en": "high right",
        "name_aa": "على حق عالية",
        "name_de": "oben rechts",
        "name_nl": "hoog rechts",
        "name_pl": "U góry po prawej",
        "name_ro": "dreapta sus",
        "name_ru": "вверх вправо",
        "name_tr": "sağ yukarı",
        "name_se": "hög höger",
        "name_el": "ψηλά δεξιά"
    },
    "1802": {
        "name_en": "low right",
        "name_aa": "منخفض على اليمين",
        "name_de": "unten rechts",
        "name_nl": "laag rechts",
        "name_pl": "Na dole po prawej",
        "name_ro": "dreapta jos",
        "name_ru": "вниз вправо",
        "name_tr": "sağ aşağı",
        "name_se": "låg höger",
        "name_el": "κάτω δεξιά"
    },
    "1803": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "1804": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ"
    },
    "1805": {
        "name_en": "no goal",
        "name_aa": "لا هدف",
        "name_de": "kein Tor",
        "name_nl": "geen doelpunt",
        "name_pl": "bez gola",
        "name_ro": "niciun gol",
        "name_ru": "гола не будет",
        "name_tr": "gol yok",
        "name_se": "inga mål",
        "name_el": "κανένα γκολ"
    },
    "1806": {
        "name_en": "fielder catch",
        "name_aa": "صيد فيلدر",
        "name_de": "Fielder Catch",
        "name_nl": "fielder catch",
        "name_pl": "fielder catch",
        "name_ro": "fielder catch",
        "name_ru": "пойман полевым игроком",
        "name_tr": "fielder yakalama",
        "name_se": "fielder mottagare",
        "name_el": "fielder catch"
    },
    "1807": {
        "name_en": "bowled",
        "name_aa": "رمى",
        "name_de": "Bowled",
        "name_nl": "bowled",
        "name_pl": "bowled",
        "name_ro": "bowled",
        "name_ru": "Боулд",
        "name_tr": "bowled",
        "name_se": "bowled",
        "name_el": "bowled"
    },
    "1808": {
        "name_en": "keeper catch",
        "name_aa": "الحارس قبض",
        "name_de": "Keeper Catch",
        "name_nl": "keeper catch",
        "name_pl": "keeper catch",
        "name_ro": "keeper catch",
        "name_ru": "пойман кипером",
        "name_tr": "kaleci yakalayışı",
        "name_se": "mottagare",
        "name_el": "keeper catch"
    },
    "1809": {
        "name_en": "lbw",
        "name_aa": "نقص الوزن عند الولادة",
        "name_de": "lbw",
        "name_nl": "lbw",
        "name_pl": "lbw",
        "name_ro": "lbw",
        "name_ru": "нога перед калиткой",
        "name_tr": "lbw",
        "name_se": "lbw",
        "name_el": "lbw"
    },
    "1810": {
        "name_en": "run out",
        "name_aa": "نفذ",
        "name_de": "Run out",
        "name_nl": "run out",
        "name_pl": "run out",
        "name_ro": "run out",
        "name_ru": "ран-аут",
        "name_tr": "run out",
        "name_se": "run out",
        "name_el": "run out"
    },
    "1811": {
        "name_en": "stumped",
        "name_aa": "الحيرة",
        "name_de": "Stumped",
        "name_nl": "stumped",
        "name_pl": "stumped",
        "name_ro": "stumped",
        "name_ru": "стампд",
        "name_tr": "stumped",
        "name_se": "stumped",
        "name_el": "stumped"
    },
    "1812": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "alta",
        "name_ru": "другое",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1813": {
        "name_en": "{$competitor1}",
        "name_aa": "{$competitor1}",
        "name_de": "{$competitor1}",
        "name_nl": "{$competitor1}",
        "name_pl": "{$competitor1}",
        "name_ro": "{$competitor1}",
        "name_ru": "{$competitor1}",
        "name_tr": "{$competitor1}",
        "name_se": "{$competitor1}",
        "name_el": "{$competitor1}"
    },
    "1814": {
        "name_en": "neither",
        "name_aa": "لا هذا ولا ذاك",
        "name_de": "weder noch",
        "name_nl": "geen van beide",
        "name_pl": "żaden",
        "name_ro": "niciuna",
        "name_ru": "ни одна",
        "name_tr": "her ikiside değil",
        "name_se": "varken",
        "name_el": "κανένα"
    },
    "1815": {
        "name_en": "{$competitor2}",
        "name_aa": "{$competitor2}",
        "name_de": "{$competitor2}",
        "name_nl": "{$competitor2}",
        "name_pl": "{$competitor2}",
        "name_ro": "{$competitor2}",
        "name_ru": "{$competitor2}",
        "name_tr": "{$competitor2}",
        "name_se": "{$competitor2}",
        "name_el": "{$competitor2}"
    },
    "1816": {
        "name_en": "{%player1} ({+hcp})",
        "name_aa": "{!player1} ({+hcp})",
        "name_de": "{!player1} ({+hcp})",
        "name_nl": "{!player1} ({+hcp})",
        "name_pl": "{!player1} ({+hcp})",
        "name_ro": "{!player1} ({+hcp})",
        "name_ru": "{!player1} ({+hcp})",
        "name_tr": "{!player1} ({+hcp})",
        "name_se": "{!player1} ({+hcp})",
        "name_el": "{!player1} ({+hcp})"
    },
    "1817": {
        "name_en": "{%player2} ({-hcp})",
        "name_aa": "{!player2} ({-hcp})",
        "name_de": "{!player2} ({-hcp})",
        "name_nl": "{!player2} ({-hcp})",
        "name_pl": "{!player2} ({-hcp})",
        "name_ro": "{!player2} ({-hcp})",
        "name_ru": "{!player2} ({-hcp})",
        "name_tr": "{!player2} ({-hcp})",
        "name_se": "{!player2} ({-hcp})",
        "name_el": "{!player2} ({-hcp})"
    },
    "1818": {
        "name_en": "{%player1}",
        "name_aa": "{!player1}",
        "name_de": "{!player1}",
        "name_nl": "{!player1}",
        "name_pl": "{!player1}",
        "name_ro": "{!player1}",
        "name_ru": "{!player1}",
        "name_tr": "{!player1}",
        "name_se": "{!player1}",
        "name_el": "{!player1}"
    },
    "1819": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egalitate",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "1820": {
        "name_en": "{%player2}",
        "name_aa": "{!player2}",
        "name_de": "{!player2}",
        "name_nl": "{!player2}",
        "name_pl": "{!player2}",
        "name_ro": "{!player2}",
        "name_ru": "{!player2}",
        "name_tr": "{!player2}",
        "name_se": "{!player2}",
        "name_el": "{!player2}"
    },
    "1821": {
        "name_en": "{%player1}",
        "name_aa": "{!player1}",
        "name_de": "{!player1}",
        "name_nl": "{!player1}",
        "name_pl": "{!player1}",
        "name_ro": "{!player1}",
        "name_ru": "{!player1}",
        "name_tr": "{!player1}",
        "name_se": "{!player1}",
        "name_el": "{!player1}"
    },
    "1822": {
        "name_en": "{%player2}",
        "name_aa": "{!player2}",
        "name_de": "{!player2}",
        "name_nl": "{!player2}",
        "name_pl": "{!player2}",
        "name_ro": "{!player2}",
        "name_ru": "{!player2}",
        "name_tr": "{!player2}",
        "name_se": "{!player2}",
        "name_el": "{!player2}"
    },
    "1823": {
        "name_en": "top of 9th inning",
        "name_aa": "قمة الشوط التاسع",
        "name_de": "Anfang des 9. Innings",
        "name_nl": "eerste helft 9e inning",
        "name_pl": "top of 9th inning",
        "name_ro": "prima jumătate a inning-ului 9",
        "name_ru": "первая половина 9го иннинга",
        "name_tr": "9. devrenin sonunda",
        "name_se": "toppen av 9:e inning",
        "name_el": "top of 9th inning"
    },
    "1824": {
        "name_en": "bottom of 9th inning",
        "name_aa": "الجزء السفلي من الشوط التاسع",
        "name_de": "Ende des 9. Innings",
        "name_nl": "tweede helft 9e inning",
        "name_pl": "bottom of 9th inning",
        "name_ro": "a doua jumătate a inning-ului 9",
        "name_ru": "вторая половина 9го иннинга",
        "name_tr": "9. devrenin başında",
        "name_se": "botten av 9:e inning",
        "name_el": "bottom of 9th inning"
    },
    "1825": {
        "name_en": "any extra inning",
        "name_aa": "أي دور إضافي",
        "name_de": "jedes zusätzliches Inning",
        "name_nl": "extra inning(s)",
        "name_pl": "Jakikolwiek extra inning",
        "name_ro": "oricare extra inning",
        "name_ru": "в любой из дополнительных иннингов",
        "name_tr": "uzatma devresinde",
        "name_se": "några extra innings",
        "name_el": "any extra inning"
    },
    "1826": {
        "name_en": "{!inningnr} inning",
        "name_aa": "{!inningnr} الشوط",
        "name_de": "{!inningnr} Inning",
        "name_nl": "{!inningnr} inning",
        "name_pl": "{!inningnr} inning",
        "name_ro": "{!inningnr} inning",
        "name_ru": "{inningnr}иннинг",
        "name_tr": "{!inningnr} devre",
        "name_se": "{!inningnr} inning",
        "name_el": "{!inningnr} inning"
    },
    "1828": {
        "name_en": "{!(inningnr+1)} inning",
        "name_aa": "{!(inningnr+1)} الشوط",
        "name_de": "{!(inningnr+1)} Inning",
        "name_nl": "{!(inningnr+1)} inning",
        "name_pl": "{!(inningnr+1)} inning",
        "name_ro": "{!(inningnr+1)} inning",
        "name_ru": "{!(inningnr+1)} иннинг",
        "name_tr": "{!(inningnr+1)} devre",
        "name_se": "{!(inningnr+1)} inning",
        "name_el": "{!(inningnr+1)} inning"
    },
    "1829": {
        "name_en": "{!(inningnr+2)} inning",
        "name_aa": "{!(inningnr+2)} الشوط",
        "name_de": "{!(inningnr+2)} Inning",
        "name_nl": "{!(inningnr+2)} inning",
        "name_pl": "{!(inningnr+2)} inning",
        "name_ro": "{!(inningnr+2)} inning",
        "name_ru": "{!(inningnr+2)} иннинг",
        "name_tr": "{!(inningnr+2)} devre",
        "name_se": "{!(inningnr+2)} inning",
        "name_el": "{!(inningnr+2)} inning"
    },
    "1830": {
        "name_en": "other inning or no run",
        "name_aa": "دور آخر أو لا المدى",
        "name_de": "Anderes Inning oder kein Lauf",
        "name_nl": "andere inning of geen run",
        "name_pl": "inny inning lub bez run",
        "name_ro": "alt inning sau niciun run",
        "name_ru": "другой иннинг или рана не будет",
        "name_tr": "diğer devre veya koşu yok",
        "name_se": "annan inning elr ingen",
        "name_el": "άλλο inning ή κανένα run"
    },
    "1831": {
        "name_en": "00:00 - {(minute-1)}:59",
        "name_aa": "00:00 - {(minute-1)}:59",
        "name_de": "00:00 - {(minute-1)}:59",
        "name_nl": "00:00 - {(minute-1)}:59",
        "name_pl": "00:00 - {(minute-1)}:59",
        "name_ro": "00:00 - {(minute-1)}:59",
        "name_ru": "00:00 - {(minute-1)}:59",
        "name_tr": "00:00 - {(minute-1)}:59",
        "name_se": "00:00 - {(minute-1)}:59",
        "name_el": "00:00 - {(minute-1)}:59"
    },
    "1832": {
        "name_en": "{minute}:00+",
        "name_aa": "{minute}:00+",
        "name_de": "{minute}:00+",
        "name_nl": "{minute}:00+",
        "name_pl": "{minute}:00+",
        "name_ro": "{minute}:00+",
        "name_ru": "{minute}:00+",
        "name_tr": "{minute}:00+",
        "name_se": "{minute}:00+",
        "name_el": "{minute}:00+"
    },
    "1833": {
        "name_en": "home teams",
        "name_aa": "فرق المنزل",
        "name_de": "Heimmannschaften",
        "name_nl": "thuisspelende teams",
        "name_pl": "gospodarze",
        "name_ro": "echipele gazdă",
        "name_ru": "хозяева",
        "name_tr": "ev sahibi takımı",
        "name_se": "hemmalagen",
        "name_el": "γηπεδούχοι"
    },
    "1834": {
        "name_en": "X",
        "name_aa": "رسم",
        "name_de": "X",
        "name_nl": "X",
        "name_pl": "remis",
        "name_ro": "egal",
        "name_ru": "ничья",
        "name_tr": "X",
        "name_se": "oavgjort",
        "name_el": "ισοπαλία"
    },
    "1835": {
        "name_en": "away teams",
        "name_aa": "فرق خارج أرضه",
        "name_de": "Auswärtsmannschaften",
        "name_nl": "uitspelende teams",
        "name_pl": "goście",
        "name_ro": "echipele oaspete",
        "name_ru": "гости",
        "name_tr": "deplasman takımı",
        "name_se": "bortalagen",
        "name_el": "φιλοξενούμενοι"
    },
    "1836": {
        "name_en": "{$competitor1}/{$competitor1} & under {total}",
        "name_aa": "{$competitor1} / {$competitor1} و تحت{total}",
        "name_de": "{$competitor1}/{$competitor1} & unter {total}",
        "name_nl": "{$competitor1}/{$competitor1} & onder {total}",
        "name_pl": "{$competitor1}/{$competitor1} & poniżej {total}",
        "name_ro": "{$competitor1}/{$competitor1} & sub {total}",
        "name_ru": "{$competitor1}/{$competitor1} или меньше {total}",
        "name_tr": "{$competitor1}/{$competitor1} & {total} altı",
        "name_se": "{$competitor1}/{$competitor1} & under {total}",
        "name_el": "{$competitor1}/{$competitor1} & under {total}"
    },
    "1837": {
        "name_en": "{$competitor1}/X & under {total}",
        "name_aa": "{$competitor1} / رسم و تحت{total}",
        "name_de": "{$competitor1}/X & unter {total}",
        "name_nl": "{$competitor1}/X & onder {total}",
        "name_pl": "{$competitor1}/remis & poniżej {total}",
        "name_ro": "{$competitor1}/egal & sub {total}",
        "name_ru": "{$competitor1}/ничья или меньше {total}",
        "name_tr": "{$competitor1}/X & {total} altı",
        "name_se": "{$competitor1}/oavgjort & under {total}",
        "name_el": "{$competitor1}/ισοπαλία & under {total}"
    },
    "1838": {
        "name_en": "{$competitor1}/{$competitor2} & under {total}",
        "name_aa": "{$competitor1} / {$competitor2} و تحت{total}",
        "name_de": "{$competitor1}/{$competitor2} & unter {total}",
        "name_nl": "{$competitor1}/{$competitor2} & onder {total}",
        "name_pl": "{$competitor1}/{$competitor2} & poniżej {total}",
        "name_ro": "{$competitor1}/{$competitor2} & sub {total}",
        "name_ru": "{$competitor1}/{$competitor2} или меньше {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} altı",
        "name_se": "{$competitor1}/{$competitor2} & under {total}",
        "name_el": "{$competitor1}/{$competitor2} & under {total}"
    },
    "1839": {
        "name_en": "X/{$competitor1} & under {total}",
        "name_aa": "رسم / {$competitor1} و تحت{total}",
        "name_de": "X /{$competitor1} & unter {total}",
        "name_nl": "X/{$competitor1} & onder {total}",
        "name_pl": "remis/{$competitor1} & poniżej {total}",
        "name_ro": "egal/{$competitor1} & sub {total}",
        "name_ru": "ничья/{$competitor1} или меньше {total}",
        "name_tr": "X/{$competitor1} & {total} altı",
        "name_se": "oavgjort/{$competitor1} & under {total}",
        "name_el": "ισοπαλία /{$competitor1} & under {total}"
    },
    "1840": {
        "name_en": "X/X & under {total}",
        "name_aa": "رسم / رسم و تحت{total}",
        "name_de": "X/X & unter {total}",
        "name_nl": "X/X & onder {total}",
        "name_pl": "remis/remis & poniżej {total}",
        "name_ro": "egal/egal & sub {total}",
        "name_ru": "ничья/ничья или меньше {total}",
        "name_tr": "X/X & {total} altı",
        "name_se": "oavgjort/oavgjort & under {total}",
        "name_el": "ισοπαλία/ισοπαλία & under {total}"
    },
    "1841": {
        "name_en": "X/{$competitor2} & under {total}",
        "name_aa": "رسم / {$competitor2} و تحت{total}",
        "name_de": "X/{$competitor2} & unter {total}",
        "name_nl": "X/{$competitor2} & onder {total}",
        "name_pl": "remis/{$competitor2} & poniżej {total}",
        "name_ro": "egal/{$competitor2} & sub {total}",
        "name_ru": "ничья/{$competitor2} и меньше {total}",
        "name_tr": "X/{$competitor2} & {total} altı",
        "name_se": "oavgjort/{$competitor2} & under {total}",
        "name_el": "ισοπαλία /{$competitor2} & under {total}"
    },
    "1842": {
        "name_en": "{$competitor2}/{$competitor1} & under {total}",
        "name_aa": "{$competitor2} / {$competitor1} و تحت{total}",
        "name_de": "{$competitor2}/{$competitor1} & unter {total}",
        "name_nl": "{$competitor2}/{$competitor1} & onder {total}",
        "name_pl": "{$competitor2}/{$competitor1} & poniżej {total}",
        "name_ro": "{$competitor2}/{$competitor1} & sub {total}",
        "name_ru": "{$competitor2}/{$competitor1} и меньше {total}",
        "name_tr": "{$competitor2}/{$competitor1} & {total} altı",
        "name_se": "{$competitor2}/{$competitor1} & under {total}",
        "name_el": "{$competitor2}/{$competitor1} & under {total}"
    },
    "1843": {
        "name_en": "{$competitor2}/X & under {total}",
        "name_aa": "{$competitor2} / رسم و تحت{total}",
        "name_de": "{$competitor2}/X & unter {total}",
        "name_nl": "{$competitor2}/X & onder {total}",
        "name_pl": "{$competitor2}/remis & poniżej {total}",
        "name_ro": "{$competitor2}/egal & sub {total}",
        "name_ru": "{$competitor2}/ничья и меньше {total}",
        "name_tr": "{$competitor2}/X & {total} altı",
        "name_se": "{$competitor2}/oavgjort & under {total}",
        "name_el": "{$competitor2}/ισοπαλία & under {total}"
    },
    "1844": {
        "name_en": "{$competitor2}/{$competitor2} & under {total}",
        "name_aa": "{$competitor2} / {$competitor2} و تحت{total}",
        "name_de": "{$competitor2}/{$competitor2} & unter {total}",
        "name_nl": "{$competitor2}/{$competitor2} & onder {total}",
        "name_pl": "{$competitor2}/{$competitor2} & poniżej {total}",
        "name_ro": "{$competitor2}/{$competitor2} & sub {total}",
        "name_ru": "{$competitor2}/{$competitor2} и меньше {total}",
        "name_tr": "{$competitor2}/{$competitor2} & {total} altı",
        "name_se": "{$competitor2}/{$competitor2} & under {total}",
        "name_el": "{$competitor2}/{$competitor2} & under {total}"
    },
    "1845": {
        "name_en": "{$competitor1}/{$competitor1} & over {total}",
        "name_aa": "{$competitor1} / {$competitor1} و أكثر من{total}",
        "name_de": "{$competitor1}/{$competitor1} & über {total}",
        "name_nl": "{$competitor1}/{$competitor1} & boven {total}",
        "name_pl": "{$competitor1}/{$competitor1} & ponad {total}",
        "name_ro": "{$competitor1}/{$competitor1} & peste {total}",
        "name_ru": "{$competitor1}/{$competitor1} и больше {total}",
        "name_tr": "{$competitor1}/{$competitor1} & {total} üstü",
        "name_se": "{$competitor1}/{$competitor1} & över {total}",
        "name_el": "{$competitor1}/{$competitor1} & over {total}"
    },
    "1846": {
        "name_en": "{$competitor1}/X & over {total}",
        "name_aa": "{$competitor1} / رسم و أكثر من{total}",
        "name_de": "{$competitor1}/X & über {total}",
        "name_nl": "{$competitor1}/X & boven {total}",
        "name_pl": "{$competitor1}/remis & ponad {total}",
        "name_ro": "{$competitor1}/egal & peste {total}",
        "name_ru": "{$competitor1}/ничья и больше {total}",
        "name_tr": "{$competitor1}/X & {total} üstü",
        "name_se": "{$competitor1}/oavgjort & över {total}",
        "name_el": "{$competitor1}/X & over {total}"
    },
    "1847": {
        "name_en": "{$competitor1}/{$competitor2} & over {total}",
        "name_aa": "{$competitor1} / {$competitor2} وأكثر من {total}",
        "name_de": "{$competitor1}/{$competitor2} & über {total}",
        "name_nl": "{$competitor1}/{$competitor2} & boven {total}",
        "name_pl": "{$competitor1}/{$competitor2} & ponad {total}",
        "name_ro": "{$competitor1}/{$competitor2} & peste {total}",
        "name_ru": "{$competitor1}/{$competitor2} и больше {total}",
        "name_tr": "{$competitor1}/{$competitor2} & {total} üstü",
        "name_se": "{$competitor1}/{$competitor2} & över {total}",
        "name_el": "{$competitor1}/{$competitor2} & over {total}"
    },
    "1848": {
        "name_en": "X/{$competitor1} & over {total}",
        "name_aa": "رسم / {$competitor1} و أكثر من{total}",
        "name_de": "X/{$competitor1} & über {total}",
        "name_nl": "X/{$competitor1} & boven {total}",
        "name_pl": "remis/{$competitor1} & ponad {total}",
        "name_ro": "egal/{$competitor1} & peste {total}",
        "name_ru": "ничья/{$competitor1} и больше {total}",
        "name_tr": "X/{$competitor1} & {total} üstü",
        "name_se": "oavgjort/{$competitor1} & över {total}",
        "name_el": "ισοπαλία /{$competitor1} & over {total}"
    },
    "1849": {
        "name_en": "X/X & over {total}",
        "name_aa": "رسم / رسم و أكثر من{total}",
        "name_de": "X/X & über {total}",
        "name_nl": "X/X & boven {total}",
        "name_pl": "remis/remis & ponad {total}",
        "name_ro": "egal/egal & peste {total}",
        "name_ru": "ничья/ничья и больше {total}",
        "name_tr": "X/X & {total} üstü",
        "name_se": "oavgjort/oavgjort & över {total}",
        "name_el": "ισοπαλία/ισοπαλία & over {total}"
    },
    "1850": {
        "name_en": "X/{$competitor2} & over {total}",
        "name_aa": "ارسم / {$competitor2} وأكثر من {total}",
        "name_de": "X/{$competitor2} & über {total}",
        "name_nl": "X/{$competitor2} & boven {total}",
        "name_pl": "remis/{$competitor2} & ponad {total}",
        "name_ro": "egal/{$competitor2} & peste {total}",
        "name_ru": "ничья/{$competitor2} и больше {total}",
        "name_tr": "X/{$competitor2} & {total} üstü",
        "name_se": "oavgjort/{$competitor2} & över {total}",
        "name_el": "ισοπαλία/{$competitor2} & over {total}"
    },
    "1851": {
        "name_en": "{$competitor2}/{$competitor1} & over {total}",
        "name_aa": "{$competitor2} / {$competitor1} وأكثر من {total}",
        "name_de": "{$competitor2}/{$competitor1} & über {total}",
        "name_nl": "{$competitor2}/{$competitor1} & boven {total}",
        "name_pl": "{$competitor2}/{$competitor1} & ponad {total}",
        "name_ro": "{$competitor2}/{$competitor1} & peste {total}",
        "name_ru": "{$competitor2}/{$competitor1} и больше {total}",
        "name_tr": "{$competitor2}/{$competitor1} & {total} üstü",
        "name_se": "{$competitor2}/{$competitor1} & över {total}",
        "name_el": "{$competitor2}/{$competitor1} & over {total}"
    },
    "1852": {
        "name_en": "{$competitor2}/X & over {total}",
        "name_aa": "{$competitor2} / رسم وأكثر من {total}",
        "name_de": "{$competitor2}/X & über {total}",
        "name_nl": "{$competitor2}/X & boven {total}",
        "name_pl": "{$competitor2}/remis & ponad {total}",
        "name_ro": "{$competitor2}/egal & peste {total}",
        "name_ru": "{$competitor2}/ничья и больше {total}",
        "name_tr": "{$competitor2}/X & {total} üstü",
        "name_se": "{$competitor2}/oavgjort & över {total}",
        "name_el": "{$competitor2}/ισοπαλία & over {total}"
    },
    "1853": {
        "name_en": "{$competitor2}/{$competitor2} & over {total}",
        "name_aa": "{$competitor2} / {$competitor2} وأكثر من {total}",
        "name_de": "{$competitor2}/{$competitor2} & über {total}",
        "name_nl": "{$competitor2}/{$competitor2} & boven {total}",
        "name_pl": "{$competitor2}/{$competitor2} & ponad {total}",
        "name_ro": "{$competitor2}/{$competitor2} & peste {total}",
        "name_ru": "{$competitor2}/{$competitor2} и больше {total}",
        "name_tr": "{$competitor2}/{$competitor2} & {total} üstü",
        "name_se": "{$competitor2}/{$competitor2} & över {total}",
        "name_el": "{$competitor2}/{$competitor2} & over {total}"
    },
    "1854": {
        "name_en": "X/X & 0",
        "name_aa": "رسم / رسم و0",
        "name_de": "X/X & 0",
        "name_nl": "X/X & 0",
        "name_pl": "remis/remis & 0",
        "name_ro": "egal/egal & 0",
        "name_ru": "ничья/ничья и 0",
        "name_tr": "X/X & 0",
        "name_se": "oavgjort/oavgjort & 0",
        "name_el": "ισοπαλία/ισοπαλία & 0"
    },
    "1855": {
        "name_en": "{$competitor1}/{$competitor1} & 1",
        "name_aa": "{$competitor1} / {$competitor1} و 1",
        "name_de": "{$competitor1}/{$competitor1} & 1",
        "name_nl": "{$competitor1}/{$competitor1} & 1",
        "name_pl": "{$competitor1}/{$competitor1} & 1",
        "name_ro": "{$competitor1}/{$competitor1} & 1",
        "name_ru": "{$competitor1}/{$competitor1} и 1",
        "name_tr": "{$competitor1}/{$competitor1} & 1",
        "name_se": "{$competitor1}/{$competitor1} & 1",
        "name_el": "{$competitor1}/{$competitor1} & 1"
    },
    "1856": {
        "name_en": "X/{$competitor1} & 1",
        "name_aa": "رسم / {$competitor1} و 1",
        "name_de": "X/{$competitor1} & 1",
        "name_nl": "X/{$competitor1} & 1",
        "name_pl": "remis/{$competitor1} & 1",
        "name_ro": "egal/{$competitor1} & 1",
        "name_ru": "ничья/{$competitor1} и 1",
        "name_tr": "X/{$competitor1} & 1",
        "name_se": "oavgjort/{$competitor1} & 1",
        "name_el": "ισοπαλία/{$competitor1} & 1"
    },
    "1857": {
        "name_en": "X/{$competitor2} & 1",
        "name_aa": "رسم / {$competitor2} و 1",
        "name_de": "X/{$competitor2} & 1",
        "name_nl": "X/{$competitor2} & 1",
        "name_pl": "remis/{$competitor2} & 1",
        "name_ro": "egal/{$competitor2} & 1",
        "name_ru": "ничья/{$competitor2} и 1",
        "name_tr": "X/{$competitor2} & 1",
        "name_se": "oavgjort/{$competitor2} & 1",
        "name_el": "ισοπαλία/{$competitor2} & 2"
    },
    "1858": {
        "name_en": "{$competitor2}/{$competitor2} & 1",
        "name_aa": "{$competitor2} / {$competitor2} و 1",
        "name_de": "{$competitor2}/{$competitor2} & 1",
        "name_nl": "{$competitor2}/{$competitor2} & 1",
        "name_pl": "{$competitor2}/{$competitor2} & 1",
        "name_ro": "{$competitor2}/{$competitor2} & 1",
        "name_ru": "{$competitor2}/{$competitor2} и 1",
        "name_tr": "{$competitor2}/{$competitor2} & 1",
        "name_se": "{$competitor2}/{$competitor2} & 1",
        "name_el": "{$competitor2}/{$competitor2} & 1"
    },
    "1859": {
        "name_en": "{$competitor1}/{$competitor1} & 2",
        "name_aa": "{$competitor1} / {$competitor1} و 2",
        "name_de": "{$competitor1}/{$competitor1} & 2",
        "name_nl": "{$competitor1}/{$competitor1} & 2",
        "name_pl": "{$competitor1}/{$competitor1} & 2",
        "name_ro": "{$competitor1}/{$competitor1} & 2",
        "name_ru": "{$competitor1}/{$competitor1} и 2",
        "name_tr": "{$competitor1}/{$competitor1} & 2",
        "name_se": "{$competitor1}/{$competitor1} & 2",
        "name_el": "{$competitor1}/{$competitor1} & 2"
    },
    "1860": {
        "name_en": "{$competitor1}/X & 2",
        "name_aa": "{$competitor1} / رسم و 2",
        "name_de": "{$competitor1}/X & 2",
        "name_nl": "{$competitor1}/X & 2",
        "name_pl": "{$competitor1}/remis & 2",
        "name_ro": "{$competitor1}/egal & 2",
        "name_ru": "{$competitor1}/ничья и 2",
        "name_tr": "{$competitor1}/X & 2",
        "name_se": "{$competitor1}/oavgjort & 2",
        "name_el": "{$competitor1}/ισοπαλία & 2"
    },
    "1861": {
        "name_en": "X/{$competitor1} & 2",
        "name_aa": "رسم / {$competitor1} و 2",
        "name_de": "X/{$competitor1} & 2",
        "name_nl": "X/{$competitor1} & 2",
        "name_pl": "remis/{$competitor1} & 2",
        "name_ro": "egal/{$competitor1} & 2",
        "name_ru": "ничья/{$competitor1} и 2",
        "name_tr": "X/{$competitor1} & 2",
        "name_se": "oavgjort/{$competitor1} & 2",
        "name_el": "ισοπαλία/{$competitor1} & 2"
    },
    "1862": {
        "name_en": "X/X & 2",
        "name_aa": "رسم / رسم و 2",
        "name_de": "X/X& 2",
        "name_nl": "X/X & 2",
        "name_pl": "remis/remis & 2",
        "name_ro": "egal/egal & 2",
        "name_ru": "ничья/ничья и 2",
        "name_tr": "X/X & 2",
        "name_se": "oavgjort/oavgjort & 2",
        "name_el": "ισοπαλία/ισοπαλία & 2"
    },
    "1863": {
        "name_en": "X/{$competitor2} & 2",
        "name_aa": "رسم / {$competitor2} و 2",
        "name_de": "X/{$competitor2} & 2",
        "name_nl": "X/{$competitor2} & 2",
        "name_pl": "remis/{$competitor2} & 2",
        "name_ro": "egal/{$competitor2} & 2",
        "name_ru": "ничья/{$competitor2} и 2",
        "name_tr": "X/{$competitor2} & 2",
        "name_se": "oavgjort/{$competitor2} & 2",
        "name_el": "ισοπαλία/{$competitor2} & 2"
    },
    "1864": {
        "name_en": "{$competitor2}/X & 2",
        "name_aa": "{$competitor2} / رسم و 2",
        "name_de": "{$competitor2}/X & 2",
        "name_nl": "{$competitor2}/X & 2",
        "name_pl": "{$competitor2}/remis & 2",
        "name_ro": "{$competitor2}/egal & 2",
        "name_ru": "{$competitor2}/ничья и 2",
        "name_tr": "{$competitor2}/X & 2",
        "name_se": "{$competitor2}/oavgjort & 2",
        "name_el": "{$competitor2}/ισοπαλία & 2"
    },
    "1865": {
        "name_en": "{$competitor2}/{$competitor2} & 2",
        "name_aa": "{$competitor2} / {$competitor2} و 2",
        "name_de": "{$competitor2}/{$competitor2} & 2",
        "name_nl": "{$competitor2}/{$competitor2} & 2",
        "name_pl": "{$competitor2}/{$competitor2} & 2",
        "name_ro": "{$competitor2}/{$competitor2} & 2",
        "name_ru": "{$competitor2}/{$competitor2} и 2",
        "name_tr": "{$competitor2}/{$competitor2} & 2",
        "name_se": "{$competitor2}/{$competitor2} & 2",
        "name_el": "{$competitor2}/{$competitor2} & 2"
    },
    "1866": {
        "name_en": "{$competitor1}/{$competitor1} & 3",
        "name_aa": "{$competitor1} / {$competitor1}و 3",
        "name_de": "{$competitor1}/{$competitor1} & 3",
        "name_nl": "{$competitor1}/{$competitor1} & 3",
        "name_pl": "{$competitor1}/{$competitor1} & 3",
        "name_ro": "{$competitor1}/{$competitor1} & 3",
        "name_ru": "{$competitor1}/{$competitor1} и 3",
        "name_tr": "{$competitor1}/{$competitor1} & 3",
        "name_se": "{$competitor1}/{$competitor1} & 3",
        "name_el": "{$competitor1}/{$competitor1} & 3"
    },
    "1867": {
        "name_en": "{$competitor1}/{$competitor2} & 3",
        "name_aa": "{$competitor1} / {$competitor2} و 3",
        "name_de": "{$competitor1}/{$competitor2} & 3",
        "name_nl": "{$competitor1}/{$competitor2} & 3",
        "name_pl": "{$competitor1}/{$competitor2} & 3",
        "name_ro": "{$competitor1}/{$competitor2} & 3",
        "name_ru": "{$competitor1}/{$competitor2} и 3",
        "name_tr": "{$competitor1}/{$competitor2} & 3",
        "name_se": "{$competitor1}/{$competitor2} & 3",
        "name_el": "{$competitor1}/{$competitor2} & 3"
    },
    "1868": {
        "name_en": "X/{$competitor1} & 3",
        "name_aa": "رسم / {$competitor1} و 3",
        "name_de": "X/{$competitor1} & 3",
        "name_nl": "X/{$competitor1} & 3",
        "name_pl": "remis/{$competitor1} & 3",
        "name_ro": "egal/{$competitor1} & 3",
        "name_ru": "ничья/{$competitor1} и 3",
        "name_tr": "X/{$competitor1} & 3",
        "name_se": "oavgjort/{$competitor1} & 3",
        "name_el": "ισοπαλία/{$competitor1} & 3"
    },
    "1869": {
        "name_en": "X/{$competitor2} & 3",
        "name_aa": "رسم / {$competitor2} و 3",
        "name_de": "X/{$competitor2} & 3",
        "name_nl": "X/{$competitor2} & 3",
        "name_pl": "remis/{$competitor2} & 3",
        "name_ro": "egal/{$competitor2} & 3",
        "name_ru": "ничья/{$competitor2} и 3",
        "name_tr": "X/{$competitor2} & 3",
        "name_se": "oavgjort/{$competitor2} & 3",
        "name_el": "ισοπαλία{$competitor2} & 3"
    },
    "1870": {
        "name_en": "{$competitor2}/{$competitor1} & 3",
        "name_aa": "{$competitor2} / {$competitor1} و 3",
        "name_de": "{$competitor2}/{$competitor1} & 3",
        "name_nl": "{$competitor2}/{$competitor1} & 3",
        "name_pl": "{$competitor2}/{$competitor1} & 3",
        "name_ro": "{$competitor2}/{$competitor1} & 3",
        "name_ru": "{$competitor2}/{$competitor1} и 3",
        "name_tr": "{$competitor2}/{$competitor1} & 3",
        "name_se": "{$competitor2}/{$competitor1} & 3",
        "name_el": "{$competitor2}/{$competitor1} & 3"
    },
    "1871": {
        "name_en": "{$competitor2}/{$competitor2} & 3",
        "name_aa": "{$competitor2} / {$competitor2} و 3",
        "name_de": "{$competitor2}/{$competitor2} & 3",
        "name_nl": "{$competitor2}/{$competitor2} & 3",
        "name_pl": "{$competitor2}/{$competitor2} & 3",
        "name_ro": "{$competitor2}/{$competitor2} & 3",
        "name_ru": "{$competitor2}/{$competitor2} и 3",
        "name_tr": "{$competitor2}/{$competitor2} & 3",
        "name_se": "{$competitor2}/{$competitor2} & 3",
        "name_el": "{$competitor2}/{$competitor2} & 3"
    },
    "1872": {
        "name_en": "{$competitor1}/{$competitor1} & 4",
        "name_aa": "{$competitor1} / {$competitor1} و 4",
        "name_de": "{$competitor1}/{$competitor1} & 4",
        "name_nl": "{$competitor1}/{$competitor1} & 4",
        "name_pl": "{$competitor1}/{$competitor1} & 4",
        "name_ro": "{$competitor1}/{$competitor1} & 4",
        "name_ru": "{$competitor1}/{$competitor1} и 4",
        "name_tr": "{$competitor1}/{$competitor1} & 4",
        "name_se": "{$competitor1}/{$competitor1} & 4",
        "name_el": "{$competitor1}/{$competitor1} & 4"
    },
    "1873": {
        "name_en": "{$competitor1}/X & 4",
        "name_aa": "{$competitor1} / رسم و 4",
        "name_de": "{$competitor1}/X & 4",
        "name_nl": "{$competitor1}/X & 4",
        "name_pl": "{$competitor1}/remis & 4",
        "name_ro": "{$competitor1}/egal & 4",
        "name_ru": "{$competitor1}/ничья и 4",
        "name_tr": "{$competitor1}/X & 4",
        "name_se": "{$competitor1}/oavgjort & 4",
        "name_el": "{$competitor1}/X & 4"
    },
    "1874": {
        "name_en": "{$competitor1}/{$competitor2} & 4",
        "name_aa": "{$competitor1} / {$competitor2} و 4",
        "name_de": "{$competitor1}/{$competitor2} & 4",
        "name_nl": "{$competitor1}/{$competitor2} & 4",
        "name_pl": "{$competitor1}/{$competitor2} & 4",
        "name_ro": "{$competitor1}/{$competitor2} & 4",
        "name_ru": "{$competitor1}/{$competitor2} и 4",
        "name_tr": "{$competitor1}/{$competitor2} & 4",
        "name_se": "{$competitor1}/{$competitor2} & 4",
        "name_el": "{$competitor1}/{$competitor2} & 4"
    },
    "1875": {
        "name_en": "X/{$competitor1} & 4",
        "name_aa": "رسم / {$competitor1} و 4",
        "name_de": "X/{$competitor1} & 4",
        "name_nl": "X/{$competitor1} & 4",
        "name_pl": "remis/{$competitor1} & 4",
        "name_ro": "egal/{$competitor1} & 4",
        "name_ru": "ничья/{$competitor1} и 4",
        "name_tr": "X/{$competitor1} & 4",
        "name_se": "oavgjort/{$competitor1} & 4",
        "name_el": "ισοπαλία/{$competitor1} & 4"
    },
    "1876": {
        "name_en": "X/X & 4",
        "name_aa": "رسم / رسم و 4",
        "name_de": "X/X& 4",
        "name_nl": "X/X & 4",
        "name_pl": "remis/remis & 4",
        "name_ro": "egal/egal & 4",
        "name_ru": "ничья/ничья и 4",
        "name_tr": "X/X & 4",
        "name_se": "oavgjort/oavgjort & 4",
        "name_el": "ισοπαλία/ισοπαλία & 4"
    },
    "1877": {
        "name_en": "X/{$competitor2} & 4",
        "name_aa": "رسم / {$competitor2} و 4",
        "name_de": "X/{$competitor2} & 4",
        "name_nl": "X/{$competitor2} & 4",
        "name_pl": "remis/{$competitor2} & 4",
        "name_ro": "egal/{$competitor2} & 4",
        "name_ru": "ничья/{$competitor2} и 4",
        "name_tr": "X/{$competitor2} & 4",
        "name_se": "oavgjort/{$competitor2} & 4",
        "name_el": "ισοπαλία/{$competitor2} & 4"
    },
    "1878": {
        "name_en": "{$competitor2}/{$competitor1} & 4",
        "name_aa": "{$competitor2} / {$competitor1} و 4",
        "name_de": "{$competitor2}/{$competitor1} & 4",
        "name_nl": "{$competitor2}/{$competitor1} & 4",
        "name_pl": "{$competitor2}/{$competitor1} & 4",
        "name_ro": "{$competitor2}/{$competitor1} & 4",
        "name_ru": "{$competitor2}/{$competitor1} и 4",
        "name_tr": "{$competitor2}/{$competitor1} & 4",
        "name_se": "{$competitor2}/{$competitor1} & 4",
        "name_el": "{$competitor2}/{$competitor1} & 4"
    },
    "1879": {
        "name_en": "{$competitor2}/X & 4",
        "name_aa": "{$competitor2} / رسم و 4",
        "name_de": "{$competitor2}/X & 4",
        "name_nl": "{$competitor2}/X & 4",
        "name_pl": "{$competitor2}/remis & 4",
        "name_ro": "{$competitor2}/egal & 4",
        "name_ru": "{$competitor2}/ничья и 4",
        "name_tr": "{$competitor2}/X & 4",
        "name_se": "{$competitor2}/oavgjort & 4",
        "name_el": "{$competitor2}/ισοπαλία & 4"
    },
    "1880": {
        "name_en": "{$competitor2}/{$competitor2} & 4",
        "name_aa": "{$competitor2} / {$competitor2} و 4",
        "name_de": "{$competitor2}/{$competitor2} & 4",
        "name_nl": "{$competitor2}/{$competitor2} & 4",
        "name_pl": "{$competitor2}/{$competitor2} & 4",
        "name_ro": "{$competitor2}/{$competitor2} & 4",
        "name_ru": "{$competitor2}/{$competitor2} и 4",
        "name_tr": "{$competitor2}/{$competitor2} & 4",
        "name_se": "{$competitor2}/{$competitor2} & 4",
        "name_el": "{$competitor2}/{$competitor2} & 4"
    },
    "1881": {
        "name_en": "{$competitor1}/{$competitor1} & 5+",
        "name_aa": "{$competitor1} / {$competitor1} و 5+",
        "name_de": "{$competitor1}/{$competitor1} & 5+",
        "name_nl": "{$competitor1}/{$competitor1} & 5+",
        "name_pl": "{$competitor1}/{$competitor1} & 5+",
        "name_ro": "{$competitor1}/{$competitor1} & 5+",
        "name_ru": "{$competitor1}/{$competitor1} и 5+",
        "name_tr": "{$competitor1}/{$competitor1} & 5+",
        "name_se": "{$competitor1}/{$competitor1} & 5+",
        "name_el": "{$competitor1}/{$competitor1} & 5+"
    },
    "1882": {
        "name_en": "{$competitor1}/X & 5+",
        "name_aa": "{$competitor1} / رسم و5+",
        "name_de": "{$competitor1}/X & 5+",
        "name_nl": "{$competitor1}/X & 5+",
        "name_pl": "{$competitor1}/remis & 5+",
        "name_ro": "{$competitor1}/egal & 5+",
        "name_ru": "{$competitor1}/ничья и 5+",
        "name_tr": "{$competitor1}/X & 5+",
        "name_se": "{$competitor1}/oavgjort& 5+",
        "name_el": "{$competitor1}/ισοπαλία & 5+"
    },
    "1883": {
        "name_en": "{$competitor1}/{$competitor2} & 5+",
        "name_aa": "{$competitor1} / {$competitor2} و 5+",
        "name_de": "{$competitor1}/{$competitor2} & 5+",
        "name_nl": "{$competitor1}/{$competitor2} & 5+",
        "name_pl": "{$competitor1}/{$competitor2} & 5+",
        "name_ro": "{$competitor1}/{$competitor2} & 5+",
        "name_ru": "{$competitor1}/{$competitor2} и 5+",
        "name_tr": "{$competitor1}/{$competitor2} & 5+",
        "name_se": "{$competitor1}/{$competitor2} & 5+",
        "name_el": "{$competitor1}/{$competitor2} & 5+"
    },
    "1884": {
        "name_en": "X/{$competitor1} & 5+",
        "name_aa": "رسم / {$competitor1} و 5+",
        "name_de": "X/{$competitor1} & 5+",
        "name_nl": "X/{$competitor1} & 5+",
        "name_pl": "remis/{$competitor1} & 5+",
        "name_ro": "egal/{$competitor1} & 5+",
        "name_ru": "ничья/{$competitor1} и 5+",
        "name_tr": "X/{$competitor1} & 5+",
        "name_se": "oavgjort/{$competitor1} & 5+",
        "name_el": "ισοπαλία/{$competitor1} & 5+"
    },
    "1885": {
        "name_en": "X/X & 5+",
        "name_aa": "رسم / رسم و5+",
        "name_de": "X/X& 5+",
        "name_nl": "X/X & 5+",
        "name_pl": "remis/remis & 5+",
        "name_ro": "egal/egal & 5+",
        "name_ru": "ничья/ничья и 5+",
        "name_tr": "X/X & 5+",
        "name_se": "oavgjort/oavgjort & 5+",
        "name_el": "ισοπαλία/ισοπαλία & 5+"
    },
    "1886": {
        "name_en": "X/{$competitor2} & 5+",
        "name_aa": "رسم / {$competitor2} و 5+",
        "name_de": "X/{$competitor2} & 5+",
        "name_nl": "X/{$competitor2} & 5+",
        "name_pl": "remis/{$competitor2} & 5+",
        "name_ro": "egal/{$competitor2} & 5+",
        "name_ru": "ничья/{$competitor2} и 5+",
        "name_tr": "X/{$competitor2} & 5+",
        "name_se": "oavgjort/{$competitor2} & 5+",
        "name_el": "ισοπαλία/{$competitor2} & 5+"
    },
    "1887": {
        "name_en": "{$competitor2}/{$competitor1} & 5+",
        "name_aa": "{$competitor2} / {$competitor1}و 5+",
        "name_de": "{$competitor2}/{$competitor1} & 5+",
        "name_nl": "{$competitor2}/{$competitor1} & 5+",
        "name_pl": "{$competitor2}/{$competitor1} & 5+",
        "name_ro": "{$competitor2}/{$competitor1} & 5+",
        "name_ru": "{$competitor2}/{$competitor1} и 5+",
        "name_tr": "{$competitor2}/{$competitor1} & 5+",
        "name_se": "{$competitor2}/{$competitor1} & 5+",
        "name_el": "{$competitor2}/{$competitor1} & 5+"
    },
    "1888": {
        "name_en": "{$competitor2}/X & 5+",
        "name_aa": "{$competitor2} / رسم و5+",
        "name_de": "{$competitor2}/X & 5+",
        "name_nl": "{$competitor2}/X & 5+",
        "name_pl": "{$competitor2}/remis & 5+",
        "name_ro": "{$competitor2}/egal & 5+",
        "name_ru": "{$competitor2}/ничья и 5+",
        "name_tr": "{$competitor2}/X & 5+",
        "name_se": "{$competitor2}/oavgjort & 5+",
        "name_el": "{$competitor2}/ισοπαλία & 5+"
    },
    "1889": {
        "name_en": "{$competitor2}/{$competitor2} & 5+",
        "name_aa": "{$competitor2} / {$competitor2}و 5+",
        "name_de": "{$competitor2}/{$competitor2} & 5+",
        "name_nl": "{$competitor2}/{$competitor2} & 5+",
        "name_pl": "{$competitor2}/{$competitor2} & 5+",
        "name_ro": "{$competitor2}/{$competitor2} & 5+",
        "name_ru": "{$competitor2}/{$competitor2} и 5+",
        "name_tr": "{$competitor2}/{$competitor2} & 5+",
        "name_se": "{$competitor2}/{$competitor2} & 5+",
        "name_el": "{$competitor2}/{$competitor2} & 5+"
    },
    "1890": {
        "name_en": "sell",
        "name_aa": "sell",
        "name_de": "sell",
        "name_nl": "verkopen",
        "name_pl": "sell",
        "name_ro": "vinde",
        "name_ru": "sell",
        "name_tr": "sell",
        "name_se": "sälj",
        "name_el": "sell"
    },
    "1891": {
        "name_en": "buy",
        "name_aa": "buy",
        "name_de": "buy",
        "name_nl": "kopen",
        "name_pl": "buy",
        "name_ro": "cumpără",
        "name_ru": "buy",
        "name_tr": "buy",
        "name_se": "köp",
        "name_el": "buy"
    },
    "1892": {
        "name_en": "mid",
        "name_aa": "mid",
        "name_de": "mid",
        "name_nl": "mid",
        "name_pl": "mid",
        "name_ro": "mid",
        "name_ru": "mid",
        "name_tr": "mid",
        "name_se": "mellan",
        "name_el": "mid"
    },
    "1894": {
        "name_en": "{%player} & {$competitor1}",
        "name_aa": "{!player} و {$competitor1}",
        "name_de": "{!player} & {$competitor1}",
        "name_nl": "{!player} & {$competitor1}",
        "name_pl": "{!player} & {$competitor1}",
        "name_ro": "{!player} & {$competitor1}",
        "name_ru": "{!player} и {$competitor1}",
        "name_tr": "{!player} & {$competitor1}",
        "name_se": "{!player} & {$competitor1}",
        "name_el": "{!player} & {$competitor1}"
    },
    "1895": {
        "name_en": "{%player} & X",
        "name_aa": "{!player} وارسم",
        "name_de": "{!player} & X",
        "name_nl": "{!player} & X",
        "name_pl": "{!player} & remis",
        "name_ro": "{!player} & egal",
        "name_ru": "{!player}и ничья",
        "name_tr": "{!player} & berabere",
        "name_se": "{!player} & oavgjort",
        "name_el": "{!player} & ισοπαλία"
    },
    "1896": {
        "name_en": "{%player} & {$competitor2}",
        "name_aa": "{!player} و {$competitor2}",
        "name_de": "{!player} & {$competitor2}",
        "name_nl": "{!player} & {$competitor2}",
        "name_pl": "{!player} & {$competitor2}",
        "name_ro": "{!player} & {$competitor2}",
        "name_ru": "{!player} и {$competitor2}",
        "name_tr": "{!player} & {$competitor2}",
        "name_se": "{!player} & {$competitor2}",
        "name_el": "{!player} & {$competitor2}"
    },
    "1897": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другое",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1898": {
        "name_en": "{%player} & 1:0",
        "name_aa": "{!player} & 1:0",
        "name_de": "{!player} & 1:0",
        "name_nl": "{!player} & 1:0",
        "name_pl": "{!player} & 1:0",
        "name_ro": "{!player} & 1:0",
        "name_ru": "{!player} & 1:0",
        "name_tr": "{!player} & 1:0",
        "name_se": "{!player} & 1:0",
        "name_el": "{!player} & 1:0"
    },
    "1899": {
        "name_en": "{%player} & 2:0",
        "name_aa": "{!player} & 2:0",
        "name_de": "{!player} & 2:0",
        "name_nl": "{!player} & 2:0",
        "name_pl": "{!player} & 2:0",
        "name_ro": "{!player} & 2:0",
        "name_ru": "{!player} & 2:0",
        "name_tr": "{!player} & 2:0",
        "name_se": "{!player} & 2:0",
        "name_el": "{!player} & 2:0"
    },
    "1900": {
        "name_en": "{%player} & 3:0",
        "name_aa": "{!player} & 3:0",
        "name_de": "{!player} & 3:0",
        "name_nl": "{!player} & 3:0",
        "name_pl": "{!player} & 3:0",
        "name_ro": "{!player} & 3:0",
        "name_ru": "{!player} & 3:0",
        "name_tr": "{!player} & 3:0",
        "name_se": "{!player} & 3:0",
        "name_el": "{!player} & 3:0"
    },
    "1901": {
        "name_en": "{%player} & 4:0",
        "name_aa": "{!player} & 4:0",
        "name_de": "{!player} & 4:0",
        "name_nl": "{!player} & 4:0",
        "name_pl": "{!player} & 4:0",
        "name_ro": "{!player} & 4:0",
        "name_ru": "{!player} & 4:0",
        "name_tr": "{!player} & 4:0",
        "name_se": "{!player} & 4:0",
        "name_el": "{!player} & 4:0"
    },
    "1902": {
        "name_en": "{%player} & 2:1",
        "name_aa": "{!player} & 2:1",
        "name_de": "{!player} & 2:1",
        "name_nl": "{!player} & 2:1",
        "name_pl": "{!player} & 2:1",
        "name_ro": "{!player} & 2:1",
        "name_ru": "{!player} & 2:1",
        "name_tr": "{!player} & 2:1",
        "name_se": "{!player} & 2:1",
        "name_el": "{!player} & 2:1"
    },
    "1903": {
        "name_en": "{%player} & 3:1",
        "name_aa": "{!player} & 3:1",
        "name_de": "{!player} & 3:1",
        "name_nl": "{!player} & 3:1",
        "name_pl": "{!player} & 3:1",
        "name_ro": "{!player} & 3:1",
        "name_ru": "{!player} & 3:1",
        "name_tr": "{!player} & 3:1",
        "name_se": "{!player} & 3:1",
        "name_el": "{!player} & 3:1"
    },
    "1904": {
        "name_en": "{%player} & 4:1",
        "name_aa": "{!player} & 4:1",
        "name_de": "{!player} & 4:1",
        "name_nl": "{!player} & 4:1",
        "name_pl": "{!player} & 4:1",
        "name_ro": "{!player} & 4:1",
        "name_ru": "{!player} & 4:1",
        "name_tr": "{!player} & 4:1",
        "name_se": "{!player} & 4:1",
        "name_el": "{!player} & 4:1"
    },
    "1905": {
        "name_en": "{%player} & 3:2",
        "name_aa": "{!player} & 3:2",
        "name_de": "{!player} & 3:2",
        "name_nl": "{!player} & 3:2",
        "name_pl": "{!player} & 3:2",
        "name_ro": "{!player} & 3:2",
        "name_ru": "{!player} & 3:2",
        "name_tr": "{!player} & 3:2",
        "name_se": "{!player} & 3:2",
        "name_el": "{!player} & 3:2"
    },
    "1906": {
        "name_en": "{%player} & 4:2",
        "name_aa": "{!player} & 4:2",
        "name_de": "{!player} & 4:2",
        "name_nl": "{!player} & 4:2",
        "name_pl": "{!player} & 4:2",
        "name_ro": "{!player} & 4:2",
        "name_ru": "{!player} & 4:2",
        "name_tr": "{!player} & 4:2",
        "name_se": "{!player} & 4:2",
        "name_el": "{!player} & 4:2"
    },
    "1907": {
        "name_en": "{%player} & 4:3",
        "name_aa": "{!player} & 4:3",
        "name_de": "{!player} & 4:3",
        "name_nl": "{!player} & 4:3",
        "name_pl": "{!player} & 4:3",
        "name_ro": "{!player} & 4:3",
        "name_ru": "{!player} & 4:3",
        "name_tr": "{!player} & 4:3",
        "name_se": "{!player} & 4:3",
        "name_el": "{!player} & 4:3"
    },
    "1908": {
        "name_en": "{%player} & 0:1",
        "name_aa": "{!player} & 0:1",
        "name_de": "{!player} & 0:1",
        "name_nl": "{!player} & 0:1",
        "name_pl": "{!player} & 0:1",
        "name_ro": "{!player} & 0:1",
        "name_ru": "{!player} & 0:1",
        "name_tr": "{!player} & 0:1",
        "name_se": "{!player} & 0:1",
        "name_el": "{!player} & 0:1"
    },
    "1909": {
        "name_en": "{%player} & 0:2",
        "name_aa": "{!player} & 0:2",
        "name_de": "{!player} & 0:2",
        "name_nl": "{!player} & 0:2",
        "name_pl": "{!player} & 0:2",
        "name_ro": "{!player} & 0:2",
        "name_ru": "{!player} & 0:2",
        "name_tr": "{!player} & 0:2",
        "name_se": "{!player} & 0:2",
        "name_el": "{!player} & 0:2"
    },
    "1910": {
        "name_en": "{%player} & 0:3",
        "name_aa": "{!player} & 0:3",
        "name_de": "{!player} & 0:3",
        "name_nl": "{!player} & 0:3",
        "name_pl": "{!player} & 0:3",
        "name_ro": "{!player} & 0:3",
        "name_ru": "{!player} & 0:3",
        "name_tr": "{!player} & 0:3",
        "name_se": "{!player} & 0:3",
        "name_el": "{!player} & 0:3"
    },
    "1911": {
        "name_en": "{%player} & 0:4",
        "name_aa": "{!player} & 0:4",
        "name_de": "{!player} & 0:4",
        "name_nl": "{!player} & 0:4",
        "name_pl": "{!player} & 0:4",
        "name_ro": "{!player} & 0:4",
        "name_ru": "{!player} & 0:4",
        "name_tr": "{!player} & 0:4",
        "name_se": "{!player} & 0:4",
        "name_el": "{!player} & 0:4"
    },
    "1912": {
        "name_en": "{%player} & 1:2",
        "name_aa": "{!player} & 1:2",
        "name_de": "{!player} & 1:2",
        "name_nl": "{!player} & 1:2",
        "name_pl": "{!player} & 1:2",
        "name_ro": "{!player} & 1:2",
        "name_ru": "{!player} & 1:2",
        "name_tr": "{!player} & 1:2",
        "name_se": "{!player} & 1:2",
        "name_el": "{!player} & 1:2"
    },
    "1913": {
        "name_en": "{%player} & 1:3",
        "name_aa": "{!player} & 1:3",
        "name_de": "{!player} & 1:3",
        "name_nl": "{!player} & 1:3",
        "name_pl": "{!player} & 1:3",
        "name_ro": "{!player} & 1:3",
        "name_ru": "{!player} & 1:3",
        "name_tr": "{!player} & 1:3",
        "name_se": "{!player} & 1:3",
        "name_el": "{!player} & 1:3"
    },
    "1914": {
        "name_en": "{%player} & 1:4",
        "name_aa": "{!player} & 1:4",
        "name_de": "{!player} & 1:4",
        "name_nl": "{!player} & 1:4",
        "name_pl": "{!player} & 1:4",
        "name_ro": "{!player} & 1:4",
        "name_ru": "{!player} & 1:4",
        "name_tr": "{!player} & 1:4",
        "name_se": "{!player} & 1:4",
        "name_el": "{!player} & 1:4"
    },
    "1915": {
        "name_en": "{%player} & 2:3",
        "name_aa": "{!player} & 2:3",
        "name_de": "{!player} & 2:3",
        "name_nl": "{!player} & 2:3",
        "name_pl": "{!player} & 2:3",
        "name_ro": "{!player} & 2:3",
        "name_ru": "{!player} & 2:3",
        "name_tr": "{!player} & 2:3",
        "name_se": "{!player} & 2:3",
        "name_el": "{!player} & 2:3"
    },
    "1916": {
        "name_en": "{%player} & 2:4",
        "name_aa": "{!player} & 2:4",
        "name_de": "{!player} & 2:4",
        "name_nl": "{!player} & 2:4",
        "name_pl": "{!player} & 2:4",
        "name_ro": "{!player} & 2:4",
        "name_ru": "{!player} & 2:4",
        "name_tr": "{!player} & 2:4",
        "name_se": "{!player} & 2:4",
        "name_el": "{!player} & 2:4"
    },
    "1917": {
        "name_en": "{%player} & 3:4",
        "name_aa": "{!player} & 3:4",
        "name_de": "{!player} & 3:4",
        "name_nl": "{!player} & 3:4",
        "name_pl": "{!player} & 3:4",
        "name_ro": "{!player} & 3:4",
        "name_ru": "{!player} & 3:4",
        "name_tr": "{!player} & 3:4",
        "name_se": "{!player} & 3:4",
        "name_el": "{!player} & 3:4"
    },
    "1918": {
        "name_en": "{%player} & 1:1",
        "name_aa": "{!player} & 1:1",
        "name_de": "{!player} & 1:1",
        "name_nl": "{!player} & 1:1",
        "name_pl": "{!player} & 1:1",
        "name_ro": "{!player} & 1:1",
        "name_ru": "{!player} & 1:1",
        "name_tr": "{!player} & 1:1",
        "name_se": "{!player} & 1:1",
        "name_el": "{!player} & 1:1"
    },
    "1919": {
        "name_en": "{%player} & 2:2",
        "name_aa": "{!player} & 2:2",
        "name_de": "{!player} & 2:2",
        "name_nl": "{!player} & 2:2",
        "name_pl": "{!player} & 2:2",
        "name_ro": "{!player} & 2:2",
        "name_ru": "{!player} & 2:2",
        "name_tr": "{!player} & 2:2",
        "name_se": "{!player} & 2:2",
        "name_el": "{!player} & 2:2"
    },
    "1920": {
        "name_en": "{%player} & 3:3",
        "name_aa": "{!player} & 3:3",
        "name_de": "{!player} & 3:3",
        "name_nl": "{!player} & 3:3",
        "name_pl": "{!player} & 3:3",
        "name_ro": "{!player} & 3:3",
        "name_ru": "{!player} & 3:3",
        "name_tr": "{!player} & 3:3",
        "name_se": "{!player} & 3:3",
        "name_el": "{!player} & 3:3"
    },
    "1921": {
        "name_en": "{%player} & 4:4",
        "name_aa": "{!player} & 4:4",
        "name_de": "{!player} & 4:4",
        "name_nl": "{!player} & 4:4",
        "name_pl": "{!player} & 4:4",
        "name_ro": "{!player} & 4:4",
        "name_ru": "{!player} & 4:4",
        "name_tr": "{!player} & 4:4",
        "name_se": "{!player} & 4:4",
        "name_el": "{!player} & 4:4"
    },
    "1922": {
        "name_en": "other",
        "name_aa": "آخر",
        "name_de": "andere",
        "name_nl": "ander",
        "name_pl": "inny",
        "name_ro": "altul",
        "name_ru": "другое",
        "name_tr": "diğer",
        "name_se": "annan",
        "name_el": "άλλο"
    },
    "1923": {
        "name_en": "7:0",
        "name_aa": "7:0",
        "name_de": "7:0",
        "name_nl": "7:0",
        "name_pl": "7:0",
        "name_ro": "7:0",
        "name_ru": "7:0",
        "name_tr": "7:0",
        "name_se": "7:0",
        "name_el": "7:0"
    },
    "1924": {
        "name_en": "7:1",
        "name_aa": "7:1",
        "name_de": "7:1",
        "name_nl": "7:1",
        "name_pl": "7:1",
        "name_ro": "7:1",
        "name_ru": "7:1",
        "name_tr": "7:1",
        "name_se": "7:1",
        "name_el": "7:1"
    },
    "1925": {
        "name_en": "7:2",
        "name_aa": "7:2",
        "name_de": "7:2",
        "name_nl": "7:2",
        "name_pl": "7:2",
        "name_ro": "7:2",
        "name_ru": "7:2",
        "name_tr": "7:2",
        "name_se": "7:2",
        "name_el": "7:2"
    },
    "1926": {
        "name_en": "7:3",
        "name_aa": "7:3",
        "name_de": "7:3",
        "name_nl": "7:3",
        "name_pl": "7:3",
        "name_ro": "7:3",
        "name_ru": "7:3",
        "name_tr": "7:3",
        "name_se": "7:3",
        "name_el": "7:3"
    },
    "1927": {
        "name_en": "7:4",
        "name_aa": "7:4",
        "name_de": "7:4",
        "name_nl": "7:4",
        "name_pl": "7:4",
        "name_ro": "7:4",
        "name_ru": "7:4",
        "name_tr": "7:4",
        "name_se": "7:4",
        "name_el": "7:4"
    },
    "1928": {
        "name_en": "7:5",
        "name_aa": "7:5",
        "name_de": "7:5",
        "name_nl": "7:5",
        "name_pl": "7:5",
        "name_ro": "7:5",
        "name_ru": "7:5",
        "name_tr": "7:5",
        "name_se": "7:5",
        "name_el": "7:5"
    },
    "1929": {
        "name_en": "other {$competitor1} win",
        "name_aa": "آخر {$competitor1} الفوز",
        "name_de": "other {$competitor1} win",
        "name_nl": "ander {$competitor1} win",
        "name_pl": "other {$competitor1} win",
        "name_ro": "altul câștigă {$competitor1}",
        "name_ru": "other {$competitor1} win",
        "name_tr": "other {$competitor1} win",
        "name_se": "vinst för annan{$competitor1}",
        "name_el": "άλλο  {$competitor1} νίκητης"
    },
    "1930": {
        "name_en": "0:7",
        "name_aa": "0:7",
        "name_de": "0:7",
        "name_nl": "0:7",
        "name_pl": "0:7",
        "name_ro": "0:7",
        "name_ru": "0:7",
        "name_tr": "0:7",
        "name_se": "0:7",
        "name_el": "0:7"
    },
    "1931": {
        "name_en": "1:7",
        "name_aa": "1:7",
        "name_de": "1:7",
        "name_nl": "1:7",
        "name_pl": "1:7",
        "name_ro": "1:7",
        "name_ru": "1:7",
        "name_tr": "1:7",
        "name_se": "1:7",
        "name_el": "1:7"
    },
    "1932": {
        "name_en": "2:7",
        "name_aa": "2:7",
        "name_de": "2:7",
        "name_nl": "2:7",
        "name_pl": "2:7",
        "name_ro": "2:7",
        "name_ru": "2:7",
        "name_tr": "2:7",
        "name_se": "2:7",
        "name_el": "2:7"
    },
    "1933": {
        "name_en": "3:7",
        "name_aa": "3:7",
        "name_de": "3:7",
        "name_nl": "3:7",
        "name_pl": "3:7",
        "name_ro": "3:7",
        "name_ru": "3:7",
        "name_tr": "3:7",
        "name_se": "3:7",
        "name_el": "3:7"
    },
    "1934": {
        "name_en": "4:7",
        "name_aa": "4:7",
        "name_de": "4:7",
        "name_nl": "4:7",
        "name_pl": "4:7",
        "name_ro": "4:7",
        "name_ru": "4:7",
        "name_tr": "4:7",
        "name_se": "4:7",
        "name_el": "4:7"
    },
    "1935": {
        "name_en": "5:7",
        "name_aa": "5:7",
        "name_de": "5:7",
        "name_nl": "5:7",
        "name_pl": "5:7",
        "name_ro": "5:7",
        "name_ru": "5:7",
        "name_tr": "5:7",
        "name_se": "5:7",
        "name_el": "5:7"
    },
    "1936": {
        "name_en": "other {$competitor2} win",
        "name_aa": "آخر {$competitor2} الفوز",
        "name_de": "other {$competitor2} win",
        "name_nl": "ander {$competitor2} win",
        "name_pl": "other {$competitor2} win",
        "name_ro": "altul câștigă {$competitor2}",
        "name_ru": "other {$competitor2} win",
        "name_tr": "other {$competitor2} win",
        "name_se": "vinst för annan{$competitor2}",
        "name_el": "άλλο  {$competitor2} νίκητης"
    },
    "1937": {
        "name_en": "shot",
        "name_aa": "shot",
        "name_de": "shot",
        "name_nl": "schot",
        "name_pl": "strzał",
        "name_ro": "șut",
        "name_ru": "shot",
        "name_tr": "shot",
        "name_se": "skott",
        "name_el": "σουτ"
    },
    "1938": {
        "name_en": "header",
        "name_aa": "header",
        "name_de": "header",
        "name_nl": "kopbal",
        "name_pl": "główka",
        "name_ro": "cu capul",
        "name_ru": "header",
        "name_tr": "header",
        "name_se": "nick",
        "name_el": "κεφαλιά"
    },
    "1939": {
        "name_en": "own goal",
        "name_aa": "own goal",
        "name_de": "own goal",
        "name_nl": "eigen doelpunt",
        "name_pl": "samobój",
        "name_ro": "autogol",
        "name_ru": "own goal",
        "name_tr": "own goal",
        "name_se": "självmål",
        "name_el": "αυτογκόλ"
    },
    "1940": {
        "name_en": "penalty",
        "name_aa": "penalty",
        "name_de": "penalty",
        "name_nl": "strafschop",
        "name_pl": "rzut karny",
        "name_ro": "penalti",
        "name_ru": "penalty",
        "name_tr": "penalty",
        "name_se": "straff",
        "name_el": "πέναλτι"
    },
    "1941": {
        "name_en": "free kick",
        "name_aa": "free kick",
        "name_de": "free kick",
        "name_nl": "vrije trap",
        "name_pl": "rzut wolny",
        "name_ro": "lovitură liberă",
        "name_ru": "free kick",
        "name_tr": "free kick",
        "name_se": "frispark",
        "name_el": "φάουλ"
    },
    "1942": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "none",
        "name_nl": "geen",
        "name_pl": "żaden",
        "name_ro": "niciuna",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "ingen",
        "name_el": "κανένα"
    },
    "1945": {
        "name_en": "touchdown",
        "name_aa": "touchdown",
        "name_de": "touchdown",
        "name_nl": "touchdown",
        "name_pl": "touchdown",
        "name_ro": "touchdown",
        "name_ru": "touchdown",
        "name_tr": "touchdown",
        "name_se": "touchdown",
        "name_el": "touchdown"
    },
    "1946": {
        "name_en": "field goal",
        "name_aa": "field goal",
        "name_de": "field goal",
        "name_nl": "veldgoal",
        "name_pl": "field goal",
        "name_ro": "field goal",
        "name_ru": "field goal",
        "name_tr": "field goal",
        "name_se": "field goal",
        "name_el": "field goal"
    },
    "1947": {
        "name_en": "safety",
        "name_aa": "safety",
        "name_de": "safety",
        "name_nl": "safety",
        "name_pl": "safety",
        "name_ro": "safety",
        "name_ru": "safety",
        "name_tr": "safety",
        "name_se": "safety",
        "name_el": "safety"
    },
    "1948": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "none",
        "name_nl": "geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "ingen",
        "name_el": "κανένα"
    },
    "1949": {
        "name_en": "{$competitor1} d/st player",
        "name_aa": "{$competitor1} d/st player",
        "name_de": "{$competitor1} d/st player",
        "name_nl": "{$competitor1} d/st speler",
        "name_pl": "{$competitor1} d/st player",
        "name_ro": "{$competitor1} d/st player",
        "name_ru": "{$competitor1} d/st player",
        "name_tr": "{$competitor1} d/st player",
        "name_se": "{$competitor1} d/st spelare",
        "name_el": "{$competitor1} d/st player"
    },
    "1950": {
        "name_en": "{$competitor1} other player",
        "name_aa": "{$competitor1} other player",
        "name_de": "{$competitor1} other player",
        "name_nl": "{$competitor1} andere speler",
        "name_pl": "{$competitor1} other player",
        "name_ro": "{$competitor1} other player",
        "name_ru": "{$competitor1} other player",
        "name_tr": "{$competitor1} other player",
        "name_se": "{$competitor1} annan spelare",
        "name_el": "{$competitor1} other player"
    },
    "1951": {
        "name_en": "{$competitor2} d/st player",
        "name_aa": "{$competitor2} d/st player",
        "name_de": "{$competitor2} d/st player",
        "name_nl": "{$competitor2} d/st speler",
        "name_pl": "{$competitor2} d/st player",
        "name_ro": "{$competitor2} d/st player",
        "name_ru": "{$competitor2} d/st player",
        "name_tr": "{$competitor2} d/st player",
        "name_se": "{$competitor2} d/st spelare",
        "name_el": "{$competitor2} d/st player"
    },
    "1952": {
        "name_en": "{$competitor2} other player",
        "name_aa": "{$competitor2} other player",
        "name_de": "{$competitor2} other player",
        "name_nl": "{$competitor2} andere speler",
        "name_pl": "{$competitor2} other player",
        "name_ro": "{$competitor2} other player",
        "name_ru": "{$competitor2} other player",
        "name_tr": "{$competitor2} other player",
        "name_se": "{$competitor2} annan spelare",
        "name_el": "{$competitor2} other player"
    },
    "1961": {
        "name_en": "field goal attempt",
        "name_aa": "field goal attempt",
        "name_de": "field goal attempt",
        "name_nl": "veldgoal poging",
        "name_pl": "field goal attempt",
        "name_ro": "field goal attempt",
        "name_ru": "field goal attempt",
        "name_tr": "field goal attempt",
        "name_se": "field goal-försök",
        "name_el": "field goal attempt"
    },
    "1962": {
        "name_en": "offensive touchdown",
        "name_aa": "offensive touchdown",
        "name_de": "offensive touchdown",
        "name_nl": "offensive touchdown",
        "name_pl": "offensive touchdown",
        "name_ro": "offensive touchdown",
        "name_ru": "offensive touchdown",
        "name_tr": "offensive touchdown",
        "name_se": "offensiv touchdown",
        "name_el": "offensive touchdown"
    },
    "1963": {
        "name_en": "punt",
        "name_aa": "punt",
        "name_de": "punt",
        "name_nl": "punt",
        "name_pl": "punt",
        "name_ro": "punt",
        "name_ru": "punt",
        "name_tr": "punt",
        "name_se": "punt",
        "name_el": "punt"
    },
    "1964": {
        "name_en": "turnover",
        "name_aa": "turnover",
        "name_de": "turnover",
        "name_nl": "turnover",
        "name_pl": "turnover",
        "name_ro": "turnover",
        "name_ru": "turnover",
        "name_tr": "turnover",
        "name_se": "turnover",
        "name_el": "turnover"
    },
    "1965": {
        "name_en": "safety",
        "name_aa": "safety",
        "name_de": "safety",
        "name_nl": "safety",
        "name_pl": "safety",
        "name_ro": "safety",
        "name_ru": "safety",
        "name_tr": "safety",
        "name_se": "safety",
        "name_el": "safety"
    },
    "1966": {
        "name_en": "{%competitor1}",
        "name_aa": "{!competitor1}",
        "name_de": "{!competitor1}",
        "name_nl": "{!competitor1}",
        "name_pl": "{!competitor1}",
        "name_ro": "{!competitor1}",
        "name_ru": "{!competitor1}",
        "name_tr": "{!competitor1}",
        "name_se": "{!competitor1}",
        "name_el": "{!competitor1}"
    },
    "1967": {
        "name_en": "X",
        "name_aa": "X",
        "name_de": "X",
        "name_nl": "gelijk",
        "name_pl": "X",
        "name_ro": "X",
        "name_ru": "X",
        "name_tr": "X",
        "name_se": "X",
        "name_el": "ισοπαλία"
    },
    "1968": {
        "name_en": "{%competitor2}",
        "name_aa": "{!competitor2}",
        "name_de": "{!competitor2}",
        "name_nl": "{!competitor2}",
        "name_pl": "{!competitor2}",
        "name_ro": "{!competitor2}",
        "name_ru": "{!competitor2}",
        "name_tr": "{!competitor2}",
        "name_se": "{!competitor2}",
        "name_el": "{!competitor2}"
    },
    "1969": {
        "name_en": "{%competitor1} ({+hcp})",
        "name_aa": "{!competitor1} ({+hcp})",
        "name_de": "{!competitor1} ({+hcp})",
        "name_nl": "{!competitor1} ({+hcp})",
        "name_pl": "{!competitor1} ({+hcp})",
        "name_ro": "{!competitor1} ({+hcp})",
        "name_ru": "{!competitor1} ({+hcp})",
        "name_tr": "{!competitor1} ({+hcp})",
        "name_se": "{!competitor1} ({+hcp})",
        "name_el": "{!competitor1} ({+hcp})"
    },
    "1970": {
        "name_en": "{%competitor2} ({-hcp})",
        "name_aa": "{!competitor2} ({-hcp})",
        "name_de": "{!competitor2} ({-hcp})",
        "name_nl": "{!competitor2} ({-hcp})",
        "name_pl": "{!competitor2} ({-hcp})",
        "name_ro": "{!competitor2} ({-hcp})",
        "name_ru": "{!competitor2} ({-hcp})",
        "name_tr": "{!competitor2} ({-hcp})",
        "name_se": "{!competitor2} ({-hcp})",
        "name_el": "{!competitor2} ({-hcp})"
    },
    "1978": {
        "name_en": "strike",
        "name_aa": "strike",
        "name_de": "strike",
        "name_nl": "slag",
        "name_pl": "strike",
        "name_ro": "strike",
        "name_ru": "strike",
        "name_tr": "strike",
        "name_se": "strike",
        "name_el": "strike"
    },
    "1979": {
        "name_en": "ball",
        "name_aa": "ball",
        "name_de": "ball",
        "name_nl": "wijd",
        "name_pl": "ball",
        "name_ro": "ball",
        "name_ru": "ball",
        "name_tr": "ball",
        "name_se": "boll",
        "name_el": "ball"
    },
    "1980": {
        "name_en": "hit",
        "name_aa": "hit",
        "name_de": "hit",
        "name_nl": "geraakt",
        "name_pl": "hit",
        "name_ro": "hit",
        "name_ru": "hit",
        "name_tr": "hit",
        "name_se": "hit",
        "name_el": "hit"
    },
    "1981": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "overig",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "annat",
        "name_el": "άλλο"
    },
    "1982": {
        "name_en": "single",
        "name_aa": "single",
        "name_de": "single",
        "name_nl": "enkel",
        "name_pl": "single",
        "name_ro": "single",
        "name_ru": "single",
        "name_tr": "single",
        "name_se": "singel",
        "name_el": "single"
    },
    "1983": {
        "name_en": "double",
        "name_aa": "double",
        "name_de": "double",
        "name_nl": "dubbel",
        "name_pl": "double",
        "name_ro": "double",
        "name_ru": "double",
        "name_tr": "double",
        "name_se": "dubbel",
        "name_el": "double"
    },
    "1984": {
        "name_en": "triple",
        "name_aa": "triple",
        "name_de": "triple",
        "name_nl": "triple",
        "name_pl": "triple",
        "name_ro": "triple",
        "name_ru": "triple",
        "name_tr": "triple",
        "name_se": "trippel",
        "name_el": "triple"
    },
    "1985": {
        "name_en": "home run",
        "name_aa": "home run",
        "name_de": "home run",
        "name_nl": "homerun",
        "name_pl": "home run",
        "name_ro": "home run",
        "name_ru": "home run",
        "name_tr": "home run",
        "name_se": "homerun",
        "name_el": "home run"
    },
    "1986": {
        "name_en": "no further hit",
        "name_aa": "no further hit",
        "name_de": "no further hit",
        "name_nl": "geen slag meer",
        "name_pl": "no further hit",
        "name_ro": "no further hit",
        "name_ru": "no further hit",
        "name_tr": "no further hit",
        "name_se": "ingen ytterligare hit",
        "name_el": "no further hit"
    },
    "1987": {
        "name_en": "on base",
        "name_aa": "on base",
        "name_de": "on base",
        "name_nl": "op het honk",
        "name_pl": "on base",
        "name_ro": "on base",
        "name_ru": "on base",
        "name_tr": "on base",
        "name_se": "på bas",
        "name_el": "on base"
    },
    "1988": {
        "name_en": "out",
        "name_aa": "out",
        "name_de": "out",
        "name_nl": "uit",
        "name_pl": "out",
        "name_ro": "out",
        "name_ru": "out",
        "name_tr": "out",
        "name_se": "ute",
        "name_el": "out"
    },
    "1989": {
        "name_en": "home run",
        "name_aa": "home run",
        "name_de": "home run",
        "name_nl": "homerun",
        "name_pl": "home run",
        "name_ro": "home run",
        "name_ru": "home run",
        "name_tr": "home run",
        "name_se": "homerun",
        "name_el": "home run"
    },
    "1990": {
        "name_en": "rush",
        "name_aa": "rush",
        "name_de": "rush",
        "name_nl": "rush",
        "name_pl": "rush",
        "name_ro": "rush",
        "name_ru": "rush",
        "name_tr": "rush",
        "name_se": "rush",
        "name_el": "rush"
    },
    "1991": {
        "name_en": "pass",
        "name_aa": "pass",
        "name_de": "pass",
        "name_nl": "pass",
        "name_pl": "pass",
        "name_ro": "pass",
        "name_ru": "pass",
        "name_tr": "pass",
        "name_se": "pasning",
        "name_el": "pass"
    },
    "1992": {
        "name_en": "punt",
        "name_aa": "punt",
        "name_de": "punt",
        "name_nl": "punt",
        "name_pl": "punt",
        "name_ro": "punt",
        "name_ru": "punt",
        "name_tr": "punt",
        "name_se": "punt",
        "name_el": "punt"
    },
    "1993": {
        "name_en": "touchdown",
        "name_aa": "touchdown",
        "name_de": "touchdown",
        "name_nl": "touchdown",
        "name_pl": "touchdown",
        "name_ro": "touchdown",
        "name_ru": "touchdown",
        "name_tr": "touchdown",
        "name_se": "touchdown",
        "name_el": "touchdown"
    },
    "1994": {
        "name_en": "field goal attempt",
        "name_aa": "field goal attempt",
        "name_de": "field goal attempt",
        "name_nl": "veldgoal poging",
        "name_pl": "field goal attempt",
        "name_ro": "field goal attempt",
        "name_ru": "field goal attempt",
        "name_tr": "field goal attempt",
        "name_se": "field goal forsøg",
        "name_el": "field goal attempt"
    },
    "1995": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "anders",
        "name_pl": "inny",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "andra",
        "name_el": "άλλο"
    },
    "1996": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "1997": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "1998": {
        "name_en": "2+",
        "name_aa": "2+",
        "name_de": "2+",
        "name_nl": "2+",
        "name_pl": "2+",
        "name_ro": "2+",
        "name_ru": "2+",
        "name_tr": "2+",
        "name_se": "2+",
        "name_el": "2+"
    },
    "1999": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "2000": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "2001": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "2002": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "2005": {
        "name_en": "1-3",
        "name_aa": "1-3",
        "name_de": "1-3",
        "name_nl": "1-3",
        "name_pl": "1-3",
        "name_ro": "1-3",
        "name_ru": "1-3",
        "name_tr": "1-3",
        "name_se": "1-3",
        "name_el": "1-3"
    },
    "2006": {
        "name_en": "4-5",
        "name_aa": "4-5",
        "name_de": "4-5",
        "name_nl": "4-5",
        "name_pl": "4-5",
        "name_ro": "4-5",
        "name_ru": "4-5",
        "name_tr": "4-5",
        "name_se": "4-5",
        "name_el": "4-5"
    },
    "2007": {
        "name_en": "6+",
        "name_aa": "6+",
        "name_de": "6+",
        "name_nl": "6+",
        "name_pl": "6+",
        "name_ro": "6+",
        "name_ru": "6+",
        "name_tr": "6+",
        "name_se": "6+",
        "name_el": "6+"
    },
    "2008": {
        "name_en": "{$competitor1} 1 point score",
        "name_aa": "{$competitor1} نقطة مسجلة",
        "name_de": "{$competitor1} 1 point score",
        "name_nl": "{$competitor1} eenpunter",
        "name_pl": "{$competitor1} za 1 punkt",
        "name_ro": "{$competitor1} 1 point score",
        "name_ru": "{$competitor1} 1 point score",
        "name_tr": "{$competitor1} 1 sayı",
        "name_se": "{$competitor1} 1 poäng",
        "name_el": "{$competitor1} 1 πόντος"
    },
    "2009": {
        "name_en": "{$competitor1} 2 point score",
        "name_aa": "{$competitor1} نقطتين مسجلتين",
        "name_de": "{$competitor1} 2 point score",
        "name_nl": "{$competitor1} tweepunter",
        "name_pl": "{$competitor1} za 2 punkty",
        "name_ro": "{$competitor1} 2 point score",
        "name_ru": "{$competitor1} 2 point score",
        "name_tr": "{$competitor1} 2 sayı",
        "name_se": "{$competitor1} 2 poäng",
        "name_el": "{$competitor1} 2 πόντοι"
    },
    "2010": {
        "name_en": "{$competitor1} 3 point score",
        "name_aa": "{$competitor1} ثلاث نقاط مسجلة",
        "name_de": "{$competitor1} 3 point score",
        "name_nl": "{$competitor1} driepunter",
        "name_pl": "{$competitor1} za 3 punkty",
        "name_ro": "{$competitor1} 3 point score",
        "name_ru": "{$competitor1} 3 point score",
        "name_tr": "{$competitor1} 3 sayı",
        "name_se": "{$competitor1} 3 poäng",
        "name_el": "{$competitor1} 3 πόντοι"
    },
    "2011": {
        "name_en": "{$competitor2} 1 point score",
        "name_aa": "{$competitor2} نقطة مسجلة",
        "name_de": "{$competitor2} 1 point score",
        "name_nl": "{$competitor2} eenpunter",
        "name_pl": "{$competitor2} za 1 punkt",
        "name_ro": "{$competitor2} 1 point score",
        "name_ru": "{$competitor2} 1 point score",
        "name_tr": "{$competitor2} 1 sayı",
        "name_se": "{$competitor2} 1 poäng",
        "name_el": "{$competitor2} 1 πόντος"
    },
    "2012": {
        "name_en": "{$competitor2} 2 point score",
        "name_aa": "{$competitor2} نقطتين مسجلتين",
        "name_de": "{$competitor2} 2 point score",
        "name_nl": "{$competitor2} tweepunter",
        "name_pl": "{$competitor2} za 2 punkty",
        "name_ro": "{$competitor2} 2 point score",
        "name_ru": "{$competitor2} 2 point score",
        "name_tr": "{$competitor2} 2 sayı",
        "name_se": "{$competitor2} 2 poäng",
        "name_el": "{$competitor2} 2 πόντοι"
    },
    "2013": {
        "name_en": "{$competitor2} 3 point score",
        "name_aa": "{$competitor2} ثلاث نقاط مسجلة",
        "name_de": "{$competitor2} 3 point score",
        "name_nl": "{$competitor2} driepunter",
        "name_pl": "{$competitor2} za 3 punkty",
        "name_ro": "{$competitor2} 3 point score",
        "name_ru": "{$competitor2} 3 point score",
        "name_tr": "{$competitor2} 3 sayı",
        "name_se": "{$competitor2} 3 poäng",
        "name_el": "{$competitor2} 2 πόντοι"
    },
    "2014": {
        "name_en": "1 point score",
        "name_aa": "نقطة مسجلة",
        "name_de": "1 point score",
        "name_nl": "Eenpunter",
        "name_pl": "za 1 punkt",
        "name_ro": "1 point score",
        "name_ru": "1 point score",
        "name_tr": "1 sayı",
        "name_se": "1 poäng",
        "name_el": "1 πόντος"
    },
    "2015": {
        "name_en": "2 point score",
        "name_aa": "نقطتين مسجلتين",
        "name_de": "2 point score",
        "name_nl": "Tweepunter",
        "name_pl": "za 2 punkty",
        "name_ro": "2 point score",
        "name_ru": "2 point score",
        "name_tr": "2 sayı",
        "name_se": "2 poäng",
        "name_el": "2 πόντοι"
    },
    "2016": {
        "name_en": "3 point score",
        "name_aa": "ثلاث نقاط مسجلة",
        "name_de": "3 point score",
        "name_nl": "Driepunter",
        "name_pl": "za 3 punkty",
        "name_ro": "3 point score",
        "name_ru": "3 point score",
        "name_tr": "3 sayı",
        "name_se": "3 poäng",
        "name_el": "3 πόντοι"
    },
    "2017": {
        "name_en": "1-2",
        "name_aa": "1-2",
        "name_de": "1-2",
        "name_nl": "1-2",
        "name_pl": "1-2",
        "name_ro": "1-2",
        "name_ru": "1-2",
        "name_tr": "1-2",
        "name_se": "1-2",
        "name_el": "1-2"
    },
    "2018": {
        "name_en": "3-4",
        "name_aa": "3-4",
        "name_de": "3-4",
        "name_nl": "3-4",
        "name_pl": "3-4",
        "name_ro": "3-4",
        "name_ru": "3-4",
        "name_tr": "3-4",
        "name_se": "3-4",
        "name_el": "3-4"
    },
    "2019": {
        "name_en": "5-6",
        "name_aa": "5-6",
        "name_de": "5-6",
        "name_nl": "5-6",
        "name_pl": "5-6",
        "name_ro": "5-6",
        "name_ru": "5-6",
        "name_tr": "5-6",
        "name_se": "5-6",
        "name_el": "5-6"
    },
    "2020": {
        "name_en": "7-8",
        "name_aa": "7-8",
        "name_de": "7-8",
        "name_nl": "7-8",
        "name_pl": "7-8",
        "name_ro": "7-8",
        "name_ru": "7-8",
        "name_tr": "7-8",
        "name_se": "7-8",
        "name_el": "7-8"
    },
    "2021": {
        "name_en": "9-10",
        "name_aa": "9-10",
        "name_de": "9-10",
        "name_nl": "9-10",
        "name_pl": "9-10",
        "name_ro": "9-10",
        "name_ru": "9-10",
        "name_tr": "9-10",
        "name_se": "9-10",
        "name_el": "9-10"
    },
    "2022": {
        "name_en": "11+",
        "name_aa": "11+",
        "name_de": "11+",
        "name_nl": "11+",
        "name_pl": "11+",
        "name_ro": "11+",
        "name_ru": "11+",
        "name_tr": "11+",
        "name_se": "11+",
        "name_el": "11+"
    },
    "2023": {
        "name_en": "{%player1}",
        "name_aa": "{!player1}",
        "name_de": "{!player1}",
        "name_nl": "{!player1}",
        "name_pl": "{!player1}",
        "name_ro": "{!player1}",
        "name_ru": "{!player1}",
        "name_tr": "{!player1}",
        "name_se": "{!player1}",
        "name_el": "{!player1}"
    },
    "2024": {
        "name_en": "none",
        "name_aa": "none",
        "name_de": "none",
        "name_nl": "geen",
        "name_pl": "none",
        "name_ro": "none",
        "name_ru": "none",
        "name_tr": "none",
        "name_se": "none",
        "name_el": "άλλο"
    },
    "2025": {
        "name_en": "{%player2}",
        "name_aa": "{!player2}",
        "name_de": "{!player2}",
        "name_nl": "{!player2}",
        "name_pl": "{!player2}",
        "name_ro": "{!player2}",
        "name_ru": "{!player2}",
        "name_tr": "{!player2}",
        "name_se": "{!player2}",
        "name_el": "{!player2}"
    },
    "2026": {
        "name_en": "0",
        "name_aa": "0",
        "name_de": "0",
        "name_nl": "0",
        "name_pl": "0",
        "name_ro": "0",
        "name_ru": "0",
        "name_tr": "0",
        "name_se": "0",
        "name_el": "0"
    },
    "2027": {
        "name_en": "1",
        "name_aa": "1",
        "name_de": "1",
        "name_nl": "1",
        "name_pl": "1",
        "name_ro": "1",
        "name_ru": "1",
        "name_tr": "1",
        "name_se": "1",
        "name_el": "1"
    },
    "2028": {
        "name_en": "2",
        "name_aa": "2",
        "name_de": "2",
        "name_nl": "2",
        "name_pl": "2",
        "name_ro": "2",
        "name_ru": "2",
        "name_tr": "2",
        "name_se": "2",
        "name_el": "2"
    },
    "2029": {
        "name_en": "3",
        "name_aa": "3",
        "name_de": "3",
        "name_nl": "3",
        "name_pl": "3",
        "name_ro": "3",
        "name_ru": "3",
        "name_tr": "3",
        "name_se": "3",
        "name_el": "3"
    },
    "2030": {
        "name_en": "4",
        "name_aa": "4",
        "name_de": "4",
        "name_nl": "4",
        "name_pl": "4",
        "name_ro": "4",
        "name_ru": "4",
        "name_tr": "4",
        "name_se": "4",
        "name_el": "4"
    },
    "2031": {
        "name_en": "6",
        "name_aa": "6",
        "name_de": "6",
        "name_nl": "6",
        "name_pl": "6",
        "name_ro": "6",
        "name_ru": "6",
        "name_tr": "6",
        "name_se": "6",
        "name_el": "6"
    },
    "2032": {
        "name_en": "other",
        "name_aa": "other",
        "name_de": "other",
        "name_nl": "ander",
        "name_pl": "other",
        "name_ro": "other",
        "name_ru": "other",
        "name_tr": "other",
        "name_se": "other",
        "name_el": "άλλο"
    },
    "2033": {
        "name_en": "{%competitor1}",
        "name_aa": "{!competitor1}",
        "name_de": "{!competitor1}",
        "name_nl": "{!competitor1}",
        "name_pl": "{!competitor1}",
        "name_ro": "{!competitor1}",
        "name_ru": "{!competitor1}",
        "name_tr": "{!competitor1}",
        "name_se": "{!competitor1}",
        "name_el": "{!competitor1}"
    },
    "2034": {
        "name_en": "{%competitor2}",
        "name_aa": "{!competitor2}",
        "name_de": "{!competitor2}",
        "name_nl": "{!competitor2}",
        "name_pl": "{!competitor2}",
        "name_ro": "{!competitor2}",
        "name_ru": "{!competitor2}",
        "name_tr": "{!competitor2}",
        "name_se": "{!competitor2}",
        "name_el": "{!competitor2}"
    },
    "16001": {
        "name_en": "{$competitor1} & 2-3 goals",
        "name_aa": "{$competitor1} & 2-3 goals",
        "name_de": "{$competitor1} & 2-3 goals",
        "name_nl": "{$competitor1} & 2-3 goals",
        "name_pl": "{$competitor1} & 2-3 goals",
        "name_ro": "{$competitor1} & 2-3 goals",
        "name_ru": "{$competitor1} & 2-3 goals",
        "name_tr": "{$competitor1} & 2-3 goals",
        "name_se": "{$competitor1} & 2-3 goals",
        "name_el": "{$competitor1} & 2-3 goals"
    },
    "16002": {
        "name_en": "{$competitor1} & 2-4 goals",
        "name_aa": "{$competitor1} & 2-4 goals",
        "name_de": "{$competitor1} & 2-4 goals",
        "name_nl": "{$competitor1} & 2-4 goals",
        "name_pl": "{$competitor1} & 2-4 goals",
        "name_ro": "{$competitor1} & 2-4 goals",
        "name_ru": "{$competitor1} & 2-4 goals",
        "name_tr": "{$competitor1} & 2-4 goals",
        "name_se": "{$competitor1} & 2-4 goals",
        "name_el": "{$competitor1} & 2-4 goals"
    },
    "16003": {
        "name_en": "{$competitor1} & 2-5 goals",
        "name_aa": "{$competitor1} & 2-5 goals",
        "name_de": "{$competitor1} & 2-5 goals",
        "name_nl": "{$competitor1} & 2-5 goals",
        "name_pl": "{$competitor1} & 2-5 goals",
        "name_ro": "{$competitor1} & 2-5 goals",
        "name_ru": "{$competitor1} & 2-5 goals",
        "name_tr": "{$competitor1} & 2-5 goals",
        "name_se": "{$competitor1} & 2-5 goals",
        "name_el": "{$competitor1} & 2-5 goals"
    },
    "16004": {
        "name_en": "{$competitor1} & 3-4 goals",
        "name_aa": "{$competitor1} & 3-4 goals",
        "name_de": "{$competitor1} & 3-4 goals",
        "name_nl": "{$competitor1} & 3-4 goals",
        "name_pl": "{$competitor1} & 3-4 goals",
        "name_ro": "{$competitor1} & 3-4 goals",
        "name_ru": "{$competitor1} & 3-4 goals",
        "name_tr": "{$competitor1} & 3-4 goals",
        "name_se": "{$competitor1} & 3-4 goals",
        "name_el": "{$competitor1} & 3-4 goals"
    },
    "16005": {
        "name_en": "{$competitor1} & 3-5 goals",
        "name_aa": "{$competitor1} & 3-5 goals",
        "name_de": "{$competitor1} & 3-5 goals",
        "name_nl": "{$competitor1} & 3-5 goals",
        "name_pl": "{$competitor1} & 3-5 goals",
        "name_ro": "{$competitor1} & 3-5 goals",
        "name_ru": "{$competitor1} & 3-5 goals",
        "name_tr": "{$competitor1} & 3-5 goals",
        "name_se": "{$competitor1} & 3-5 goals",
        "name_el": "{$competitor1} & 3-5 goals"
    },
    "16006": {
        "name_en": "{$competitor1} & 3-6 goals",
        "name_aa": "{$competitor1} & 3-6 goals",
        "name_de": "{$competitor1} & 3-6 goals",
        "name_nl": "{$competitor1} & 3-6 goals",
        "name_pl": "{$competitor1} & 3-6 goals",
        "name_ro": "{$competitor1} & 3-6 goals",
        "name_ru": "{$competitor1} & 3-6 goals",
        "name_tr": "{$competitor1} & 3-6 goals",
        "name_se": "{$competitor1} & 3-6 goals",
        "name_el": "{$competitor1} & 3-6 goals"
    },
    "16007": {
        "name_en": "X & 2-3 goals",
        "name_aa": "X & 2-3 goals",
        "name_de": "X & 2-3 goals",
        "name_nl": "X & 2-3 goals",
        "name_pl": "X & 2-3 goals",
        "name_ro": "X & 2-3 goals",
        "name_ru": "X & 2-3 goals",
        "name_tr": "X & 2-3 goals",
        "name_se": "X & 2-3 goals",
        "name_el": "X & 2-3 goals"
    },
    "16008": {
        "name_en": "X & 2-4 goals",
        "name_aa": "X & 2-4 goals",
        "name_de": "X & 2-4 goals",
        "name_nl": "X & 2-4 goals",
        "name_pl": "X & 2-4 goals",
        "name_ro": "X & 2-4 goals",
        "name_ru": "X & 2-4 goals",
        "name_tr": "X & 2-4 goals",
        "name_se": "X & 2-4 goals",
        "name_el": "X & 2-4 goals"
    },
    "16009": {
        "name_en": "X & 2-5 goals",
        "name_aa": "X & 2-5 goals",
        "name_de": "X & 2-5 goals",
        "name_nl": "X & 2-5 goals",
        "name_pl": "X & 2-5 goals",
        "name_ro": "X & 2-5 goals",
        "name_ru": "X & 2-5 goals",
        "name_tr": "X & 2-5 goals",
        "name_se": "X & 2-5 goals",
        "name_el": "X & 2-5 goals"
    },
    "16010": {
        "name_en": "X & 3-4 goals",
        "name_aa": "X & 3-4 goals",
        "name_de": "X & 3-4 goals",
        "name_nl": "X & 3-4 goals",
        "name_pl": "X & 3-4 goals",
        "name_ro": "X & 3-4 goals",
        "name_ru": "X & 3-4 goals",
        "name_tr": "X & 3-4 goals",
        "name_se": "X & 3-4 goals",
        "name_el": "X & 3-4 goals"
    },
    "16011": {
        "name_en": "X & 3-5 goals",
        "name_aa": "X & 3-5 goals",
        "name_de": "X & 3-5 goals",
        "name_nl": "X & 3-5 goals",
        "name_pl": "X & 3-5 goals",
        "name_ro": "X & 3-5 goals",
        "name_ru": "X & 3-5 goals",
        "name_tr": "X & 3-5 goals",
        "name_se": "X & 3-5 goals",
        "name_el": "X & 3-5 goals"
    },
    "16012": {
        "name_en": "X & 3-6 goals",
        "name_aa": "X & 3-6 goals",
        "name_de": "X & 3-6 goals",
        "name_nl": "X & 3-6 goals",
        "name_pl": "X & 3-6 goals",
        "name_ro": "X & 3-6 goals",
        "name_ru": "X & 3-6 goals",
        "name_tr": "X & 3-6 goals",
        "name_se": "X & 3-6 goals",
        "name_el": "X & 3-6 goals"
    },
    "16013": {
        "name_en": "{$competitor2} & 2-3 goals",
        "name_aa": "{$competitor2} & 2-3 goals",
        "name_de": "{$competitor2} & 2-3 goals",
        "name_nl": "{$competitor2} & 2-3 goals",
        "name_pl": "{$competitor2} & 2-3 goals",
        "name_ro": "{$competitor2} & 2-3 goals",
        "name_ru": "{$competitor2} & 2-3 goals",
        "name_tr": "{$competitor2} & 2-3 goals",
        "name_se": "{$competitor2} & 2-3 goals",
        "name_el": "{$competitor2} & 2-3 goals"
    },
    "16014": {
        "name_en": "{$competitor2} & 2-4 goals",
        "name_aa": "{$competitor2} & 2-4 goals",
        "name_de": "{$competitor2} & 2-4 goals",
        "name_nl": "{$competitor2} & 2-4 goals",
        "name_pl": "{$competitor2} & 2-4 goals",
        "name_ro": "{$competitor2} & 2-4 goals",
        "name_ru": "{$competitor2} & 2-4 goals",
        "name_tr": "{$competitor2} & 2-4 goals",
        "name_se": "{$competitor2} & 2-4 goals",
        "name_el": "{$competitor2} & 2-4 goals"
    },
    "16015": {
        "name_en": "{$competitor2} & 2-5 goals",
        "name_aa": "{$competitor2} & 2-5 goals",
        "name_de": "{$competitor2} & 2-5 goals",
        "name_nl": "{$competitor2} & 2-5 goals",
        "name_pl": "{$competitor2} & 2-5 goals",
        "name_ro": "{$competitor2} & 2-5 goals",
        "name_ru": "{$competitor2} & 2-5 goals",
        "name_tr": "{$competitor2} & 2-5 goals",
        "name_se": "{$competitor2} & 2-5 goals",
        "name_el": "{$competitor2} & 2-5 goals"
    },
    "16016": {
        "name_en": "{$competitor2} & 3-4 goals",
        "name_aa": "{$competitor2} & 3-4 goals",
        "name_de": "{$competitor2} & 3-4 goals",
        "name_nl": "{$competitor2} & 3-4 goals",
        "name_pl": "{$competitor2} & 3-4 goals",
        "name_ro": "{$competitor2} & 3-4 goals",
        "name_ru": "{$competitor2} & 3-4 goals",
        "name_tr": "{$competitor2} & 3-4 goals",
        "name_se": "{$competitor2} & 3-4 goals",
        "name_el": "{$competitor2} & 3-4 goals"
    },
    "16017": {
        "name_en": "{$competitor2} & 3-5 goals",
        "name_aa": "{$competitor2} & 3-5 goals",
        "name_de": "{$competitor2} & 3-5 goals",
        "name_nl": "{$competitor2} & 3-5 goals",
        "name_pl": "{$competitor2} & 3-5 goals",
        "name_ro": "{$competitor2} & 3-5 goals",
        "name_ru": "{$competitor2} & 3-5 goals",
        "name_tr": "{$competitor2} & 3-5 goals",
        "name_se": "{$competitor2} & 3-5 goals",
        "name_el": "{$competitor2} & 3-5 goals"
    },
    "16018": {
        "name_en": "{$competitor2} & 3-6 goals",
        "name_aa": "{$competitor2} & 3-6 goals",
        "name_de": "{$competitor2} & 3-6 goals",
        "name_nl": "{$competitor2} & 3-6 goals",
        "name_pl": "{$competitor2} & 3-6 goals",
        "name_ro": "{$competitor2} & 3-6 goals",
        "name_ru": "{$competitor2} & 3-6 goals",
        "name_tr": "{$competitor2} & 3-6 goals",
        "name_se": "{$competitor2} & 3-6 goals",
        "name_el": "{$competitor2} & 3-6 goals"
    },
    "16021": {
        "name_en": "{$competitor1} or X & 2-3 goals",
        "name_aa": "{$competitor1} or X & 2-3 goals",
        "name_de": "{$competitor1} or X & 2-3 goals",
        "name_nl": "{$competitor1} or X & 2-3 goals",
        "name_pl": "{$competitor1} or X & 2-3 goals",
        "name_ro": "{$competitor1} or X & 2-3 goals",
        "name_ru": "{$competitor1} or X & 2-3 goals",
        "name_tr": "{$competitor1} or X & 2-3 goals",
        "name_se": "{$competitor1} or X & 2-3 goals",
        "name_el": "{$competitor1} or X & 2-3 goals"
    },
    "16022": {
        "name_en": "{$competitor1} or X & 2-4 goals",
        "name_aa": "{$competitor1} or X & 2-4 goals",
        "name_de": "{$competitor1} or X & 2-4 goals",
        "name_nl": "{$competitor1} or X & 2-4 goals",
        "name_pl": "{$competitor1} or X & 2-4 goals",
        "name_ro": "{$competitor1} or X & 2-4 goals",
        "name_ru": "{$competitor1} or X & 2-4 goals",
        "name_tr": "{$competitor1} or X & 2-4 goals",
        "name_se": "{$competitor1} or X & 2-4 goals",
        "name_el": "{$competitor1} or X & 2-4 goals"
    },
    "16023": {
        "name_en": "{$competitor1} or X & 2-5 goals",
        "name_aa": "{$competitor1} or X & 2-5 goals",
        "name_de": "{$competitor1} or X & 2-5 goals",
        "name_nl": "{$competitor1} or X & 2-5 goals",
        "name_pl": "{$competitor1} or X & 2-5 goals",
        "name_ro": "{$competitor1} or X & 2-5 goals",
        "name_ru": "{$competitor1} or X & 2-5 goals",
        "name_tr": "{$competitor1} or X & 2-5 goals",
        "name_se": "{$competitor1} or X & 2-5 goals",
        "name_el": "{$competitor1} or X & 2-5 goals"
    },
    "16024": {
        "name_en": "{$competitor1} or X & 3-4 goals",
        "name_aa": "{$competitor1} or X & 3-4 goals",
        "name_de": "{$competitor1} or X & 3-4 goals",
        "name_nl": "{$competitor1} or X & 3-4 goals",
        "name_pl": "{$competitor1} or X & 3-4 goals",
        "name_ro": "{$competitor1} or X & 3-4 goals",
        "name_ru": "{$competitor1} or X & 3-4 goals",
        "name_tr": "{$competitor1} or X & 3-4 goals",
        "name_se": "{$competitor1} or X & 3-4 goals",
        "name_el": "{$competitor1} or X & 3-4 goals"
    },
    "16025": {
        "name_en": "{$competitor1} or X & 3-5 goals",
        "name_aa": "{$competitor1} or X & 3-5 goals",
        "name_de": "{$competitor1} or X & 3-5 goals",
        "name_nl": "{$competitor1} or X & 3-5 goals",
        "name_pl": "{$competitor1} or X & 3-5 goals",
        "name_ro": "{$competitor1} or X & 3-5 goals",
        "name_ru": "{$competitor1} or X & 3-5 goals",
        "name_tr": "{$competitor1} or X & 3-5 goals",
        "name_se": "{$competitor1} or X & 3-5 goals",
        "name_el": "{$competitor1} or X & 3-5 goals"
    },
    "16026": {
        "name_en": "{$competitor1} or X & 3-6 goals",
        "name_aa": "{$competitor1} or X & 3-6 goals",
        "name_de": "{$competitor1} or X & 3-6 goals",
        "name_nl": "{$competitor1} or X & 3-6 goals",
        "name_pl": "{$competitor1} or X & 3-6 goals",
        "name_ro": "{$competitor1} or X & 3-6 goals",
        "name_ru": "{$competitor1} or X & 3-6 goals",
        "name_tr": "{$competitor1} or X & 3-6 goals",
        "name_se": "{$competitor1} or X & 3-6 goals",
        "name_el": "{$competitor1} or X & 3-6 goals"
    },
    "16027": {
        "name_en": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_de": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_se": "{$competitor1} or {$competitor2} & 2-3 goals",
        "name_el": "{$competitor1} or {$competitor2} & 2-3 goals"
    },
    "16028": {
        "name_en": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_de": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_se": "{$competitor1} or {$competitor2} & 2-4 goals",
        "name_el": "{$competitor1} or {$competitor2} & 2-4 goals"
    },
    "16029": {
        "name_en": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_de": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_se": "{$competitor1} or {$competitor2} & 2-5 goals",
        "name_el": "{$competitor1} or {$competitor2} & 2-5 goals"
    },
    "16030": {
        "name_en": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_de": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_se": "{$competitor1} or {$competitor2} & 3-4 goals",
        "name_el": "{$competitor1} or {$competitor2} & 3-4 goals"
    },
    "16031": {
        "name_en": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_de": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_se": "{$competitor1} or {$competitor2} & 3-5 goals",
        "name_el": "{$competitor1} or {$competitor2} & 3-5 goals"
    },
    "16032": {
        "name_en": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_aa": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_de": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_nl": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_pl": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_ro": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_ru": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_tr": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_se": "{$competitor1} or {$competitor2} & 3-6 goals",
        "name_el": "{$competitor1} or {$competitor2} & 3-6 goals"
    },
    "16033": {
        "name_en": "X or {$competitor2} & 2-3 goals",
        "name_aa": "X or {$competitor2} & 2-3 goals",
        "name_de": "X or {$competitor2} & 2-3 goals",
        "name_nl": "X or {$competitor2} & 2-3 goals",
        "name_pl": "X or {$competitor2} & 2-3 goals",
        "name_ro": "X or {$competitor2} & 2-3 goals",
        "name_ru": "X or {$competitor2} & 2-3 goals",
        "name_tr": "X or {$competitor2} & 2-3 goals",
        "name_se": "X or {$competitor2} & 2-3 goals",
        "name_el": "X or {$competitor2} & 2-3 goals"
    },
    "16034": {
        "name_en": "X or {$competitor2} & 2-4 goals",
        "name_aa": "X or {$competitor2} & 2-4 goals",
        "name_de": "X or {$competitor2} & 2-4 goals",
        "name_nl": "X or {$competitor2} & 2-4 goals",
        "name_pl": "X or {$competitor2} & 2-4 goals",
        "name_ro": "X or {$competitor2} & 2-4 goals",
        "name_ru": "X or {$competitor2} & 2-4 goals",
        "name_tr": "X or {$competitor2} & 2-4 goals",
        "name_se": "X or {$competitor2} & 2-4 goals",
        "name_el": "X or {$competitor2} & 2-4 goals"
    },
    "16035": {
        "name_en": "X or {$competitor2} & 2-5 goals",
        "name_aa": "X or {$competitor2} & 2-5 goals",
        "name_de": "X or {$competitor2} & 2-5 goals",
        "name_nl": "X or {$competitor2} & 2-5 goals",
        "name_pl": "X or {$competitor2} & 2-5 goals",
        "name_ro": "X or {$competitor2} & 2-5 goals",
        "name_ru": "X or {$competitor2} & 2-5 goals",
        "name_tr": "X or {$competitor2} & 2-5 goals",
        "name_se": "X or {$competitor2} & 2-5 goals",
        "name_el": "X or {$competitor2} & 2-5 goals"
    },
    "16036": {
        "name_en": "X or {$competitor2} & 3-4 goals",
        "name_aa": "X or {$competitor2} & 3-4 goals",
        "name_de": "X or {$competitor2} & 3-4 goals",
        "name_nl": "X or {$competitor2} & 3-4 goals",
        "name_pl": "X or {$competitor2} & 3-4 goals",
        "name_ro": "X or {$competitor2} & 3-4 goals",
        "name_ru": "X or {$competitor2} & 3-4 goals",
        "name_tr": "X or {$competitor2} & 3-4 goals",
        "name_se": "X or {$competitor2} & 3-4 goals",
        "name_el": "X or {$competitor2} & 3-4 goals"
    },
    "16037": {
        "name_en": "X or {$competitor2} & 3-5 goals",
        "name_aa": "X or {$competitor2} & 3-5 goals",
        "name_de": "X or {$competitor2} & 3-5 goals",
        "name_nl": "X or {$competitor2} & 3-5 goals",
        "name_pl": "X or {$competitor2} & 3-5 goals",
        "name_ro": "X or {$competitor2} & 3-5 goals",
        "name_ru": "X or {$competitor2} & 3-5 goals",
        "name_tr": "X or {$competitor2} & 3-5 goals",
        "name_se": "X or {$competitor2} & 3-5 goals",
        "name_el": "X or {$competitor2} & 3-5 goals"
    },
    "16038": {
        "name_en": "X or {$competitor2} & 3-6 goals",
        "name_aa": "X or {$competitor2} & 3-6 goals",
        "name_de": "X or {$competitor2} & 3-6 goals",
        "name_nl": "X or {$competitor2} & 3-6 goals",
        "name_pl": "X or {$competitor2} & 3-6 goals",
        "name_ro": "X or {$competitor2} & 3-6 goals",
        "name_ru": "X or {$competitor2} & 3-6 goals",
        "name_tr": "X or {$competitor2} & 3-6 goals",
        "name_se": "X or {$competitor2} & 3-6 goals",
        "name_el": "X or {$competitor2} & 3-6 goals"
    },
    "16040": {
        "name_en": "0:0/1:1",
        "name_aa": "0:0/1:1",
        "name_de": "0:0/1:1",
        "name_nl": "0:0/1:1",
        "name_pl": "0:0/1:1",
        "name_ro": "0:0/1:1",
        "name_ru": "0:0/1:1",
        "name_tr": "0:0/1:1",
        "name_se": "0:0/1:1",
        "name_el": "0:0/1:1"
    },
    "16041": {
        "name_en": "0:1/0:2/1:2",
        "name_aa": "0:1/0:2/1:2",
        "name_de": "0:1/0:2/1:2",
        "name_nl": "0:1/0:2/1:2",
        "name_pl": "0:1/0:2/1:2",
        "name_ro": "0:1/0:2/1:2",
        "name_ru": "0:1/0:2/1:2",
        "name_tr": "0:1/0:2/1:2",
        "name_se": "0:1/0:2/1:2",
        "name_el": "0:1/0:2/1:2"
    },
    "16042": {
        "name_en": "0:3/1:3/2:3",
        "name_aa": "0:3/1:3/2:3",
        "name_de": "0:3/1:3/2:3",
        "name_nl": "0:3/1:3/2:3",
        "name_pl": "0:3/1:3/2:3",
        "name_ro": "0:3/1:3/2:3",
        "name_ru": "0:3/1:3/2:3",
        "name_tr": "0:3/1:3/2:3",
        "name_se": "0:3/1:3/2:3",
        "name_el": "0:3/1:3/2:3"
    },
    "16043": {
        "name_en": "1:0/2:0/2:1",
        "name_aa": "1:0/2:0/2:1",
        "name_de": "1:0/2:0/2:1",
        "name_nl": "1:0/2:0/2:1",
        "name_pl": "1:0/2:0/2:1",
        "name_ro": "1:0/2:0/2:1",
        "name_ru": "1:0/2:0/2:1",
        "name_tr": "1:0/2:0/2:1",
        "name_se": "1:0/2:0/2:1",
        "name_el": "1:0/2:0/2:1"
    },
    "16044": {
        "name_en": "1:4/2:4/3:4",
        "name_aa": "1:4/2:4/3:4",
        "name_de": "1:4/2:4/3:4",
        "name_nl": "1:4/2:4/3:4",
        "name_pl": "1:4/2:4/3:4",
        "name_ro": "1:4/2:4/3:4",
        "name_ru": "1:4/2:4/3:4",
        "name_tr": "1:4/2:4/3:4",
        "name_se": "1:4/2:4/3:4",
        "name_el": "1:4/2:4/3:4"
    },
    "16045": {
        "name_en": "2:2/3:3/4:4",
        "name_aa": "2:2/3:3/4:4",
        "name_de": "2:2/3:3/4:4",
        "name_nl": "2:2/3:3/4:4",
        "name_pl": "2:2/3:3/4:4",
        "name_ro": "2:2/3:3/4:4",
        "name_ru": "2:2/3:3/4:4",
        "name_tr": "2:2/3:3/4:4",
        "name_se": "2:2/3:3/4:4",
        "name_el": "2:2/3:3/4:4"
    },
    "16046": {
        "name_en": "3:0/3:1/3:2",
        "name_aa": "3:0/3:1/3:2",
        "name_de": "3:0/3:1/3:2",
        "name_nl": "3:0/3:1/3:2",
        "name_pl": "3:0/3:1/3:2",
        "name_ro": "3:0/3:1/3:2",
        "name_ru": "3:0/3:1/3:2",
        "name_tr": "3:0/3:1/3:2",
        "name_se": "3:0/3:1/3:2",
        "name_el": "3:0/3:1/3:2"
    },
    "16047": {
        "name_en": "4:1/4:2/4:3",
        "name_aa": "4:1/4:2/4:3",
        "name_de": "4:1/4:2/4:3",
        "name_nl": "4:1/4:2/4:3",
        "name_pl": "4:1/4:2/4:3",
        "name_ro": "4:1/4:2/4:3",
        "name_ru": "4:1/4:2/4:3",
        "name_tr": "4:1/4:2/4:3",
        "name_se": "4:1/4:2/4:3",
        "name_el": "4:1/4:2/4:3"
    },
    "16048": {
        "name_en": "4:0/0:4/Other",
        "name_aa": "4:0/0:4/Other",
        "name_de": "4:0/0:4/Other",
        "name_nl": "4:0/0:4/Other",
        "name_pl": "4:0/0:4/Other",
        "name_ro": "4:0/0:4/Other",
        "name_ru": "4:0/0:4/Other",
        "name_tr": "4:0/0:4/Other",
        "name_se": "4:0/0:4/Other",
        "name_el": "4:0/0:4/Other"
    },
    "16050": {
        "name_en": "0:0/1:1/0:1/1:0",
        "name_aa": "0:0/1:1/0:1/1:0",
        "name_de": "0:0/1:1/0:1/1:0",
        "name_nl": "0:0/1:1/0:1/1:0",
        "name_pl": "0:0/1:1/0:1/1:0",
        "name_ro": "0:0/1:1/0:1/1:0",
        "name_ru": "0:0/1:1/0:1/1:0",
        "name_tr": "0:0/1:1/0:1/1:0",
        "name_se": "0:0/1:1/0:1/1:0",
        "name_el": "0:0/1:1/0:1/1:0"
    },
    "16051": {
        "name_en": "0:2/1:2/0:3/1:3",
        "name_aa": "0:2/1:2/0:3/1:3",
        "name_de": "0:2/1:2/0:3/1:3",
        "name_nl": "0:2/1:2/0:3/1:3",
        "name_pl": "0:2/1:2/0:3/1:3",
        "name_ro": "0:2/1:2/0:3/1:3",
        "name_ru": "0:2/1:2/0:3/1:3",
        "name_tr": "0:2/1:2/0:3/1:3",
        "name_se": "0:2/1:2/0:3/1:3",
        "name_el": "0:2/1:2/0:3/1:3"
    },
    "16052": {
        "name_en": "0:4/1:4/2:4/3:4",
        "name_aa": "0:4/1:4/2:4/3:4",
        "name_de": "0:4/1:4/2:4/3:4",
        "name_nl": "0:4/1:4/2:4/3:4",
        "name_pl": "0:4/1:4/2:4/3:4",
        "name_ro": "0:4/1:4/2:4/3:4",
        "name_ru": "0:4/1:4/2:4/3:4",
        "name_tr": "0:4/1:4/2:4/3:4",
        "name_se": "0:4/1:4/2:4/3:4",
        "name_el": "0:4/1:4/2:4/3:4"
    },
    "16053": {
        "name_en": "2:0/2:1/3:0/3:1",
        "name_aa": "2:0/2:1/3:0/3:1",
        "name_de": "2:0/2:1/3:0/3:1",
        "name_nl": "2:0/2:1/3:0/3:1",
        "name_pl": "2:0/2:1/3:0/3:1",
        "name_ro": "2:0/2:1/3:0/3:1",
        "name_ru": "2:0/2:1/3:0/3:1",
        "name_tr": "2:0/2:1/3:0/3:1",
        "name_se": "2:0/2:1/3:0/3:1",
        "name_el": "2:0/2:1/3:0/3:1"
    },
    "16054": {
        "name_en": "2:2/2:3/3:2/3:3",
        "name_aa": "2:2/2:3/3:2/3:3",
        "name_de": "2:2/2:3/3:2/3:3",
        "name_nl": "2:2/2:3/3:2/3:3",
        "name_pl": "2:2/2:3/3:2/3:3",
        "name_ro": "2:2/2:3/3:2/3:3",
        "name_ru": "2:2/2:3/3:2/3:3",
        "name_tr": "2:2/2:3/3:2/3:3",
        "name_se": "2:2/2:3/3:2/3:3",
        "name_el": "2:2/2:3/3:2/3:3"
    },
    "16055": {
        "name_en": "4:0/4:1/4:2/4:3",
        "name_aa": "4:0/4:1/4:2/4:3",
        "name_de": "4:0/4:1/4:2/4:3",
        "name_nl": "4:0/4:1/4:2/4:3",
        "name_pl": "4:0/4:1/4:2/4:3",
        "name_ro": "4:0/4:1/4:2/4:3",
        "name_ru": "4:0/4:1/4:2/4:3",
        "name_tr": "4:0/4:1/4:2/4:3",
        "name_se": "4:0/4:1/4:2/4:3",
        "name_el": "4:0/4:1/4:2/4:3"
    },
    "16056": {
        "name_en": "4:4/Other",
        "name_aa": "4:4/Other",
        "name_de": "4:4/Other",
        "name_nl": "4:4/Other",
        "name_pl": "4:4/Other",
        "name_ro": "4:4/Other",
        "name_ru": "4:4/Other",
        "name_tr": "4:4/Other",
        "name_se": "4:4/Other",
        "name_el": "4:4/Other"
    },
    "16057": {
        "name_en": "{$competitor1} or no",
        "name_aa": "{$competitor1} or no",
        "name_de": "{$competitor1} or no",
        "name_nl": "{$competitor1} or no",
        "name_pl": "{$competitor1} or no",
        "name_ro": "{$competitor1} or no",
        "name_ru": "{$competitor1} or no",
        "name_tr": "{$competitor1} or no",
        "name_se": "{$competitor1} or no",
        "name_el": "{$competitor1} or no"
    },
    "16058": {
        "name_en": "{$competitor1} or yes",
        "name_aa": "{$competitor1} or yes",
        "name_de": "{$competitor1} or yes",
        "name_nl": "{$competitor1} or yes",
        "name_pl": "{$competitor1} or yes",
        "name_ro": "{$competitor1} or yes",
        "name_ru": "{$competitor1} or yes",
        "name_tr": "{$competitor1} or yes",
        "name_se": "{$competitor1} or yes",
        "name_el": "{$competitor1} or yes"
    },
    "16059": {
        "name_en": "{$competitor2} or no",
        "name_aa": "{$competitor2} or no",
        "name_de": "{$competitor2} or no",
        "name_nl": "{$competitor2} or no",
        "name_pl": "{$competitor2} or no",
        "name_ro": "{$competitor2} or no",
        "name_ru": "{$competitor2} or no",
        "name_tr": "{$competitor2} or no",
        "name_se": "{$competitor2} or no",
        "name_el": "{$competitor2} or no"
    },
    "16060": {
        "name_en": "{$competitor2} or yes",
        "name_aa": "{$competitor2} or yes",
        "name_de": "{$competitor2} or yes",
        "name_nl": "{$competitor2} or yes",
        "name_pl": "{$competitor2} or yes",
        "name_ro": "{$competitor2} or yes",
        "name_ru": "{$competitor2} or yes",
        "name_tr": "{$competitor2} or yes",
        "name_se": "{$competitor2} or yes",
        "name_el": "{$competitor2} or yes"
    },
    "16061": {
        "name_en": "X or no",
        "name_aa": "X or no",
        "name_de": "X or no",
        "name_nl": "X or no",
        "name_pl": "X or no",
        "name_ro": "X or no",
        "name_ru": "X or no",
        "name_tr": "X or no",
        "name_se": "X or no",
        "name_el": "X or no"
    },
    "16062": {
        "name_en": "X or yes",
        "name_aa": "X or yes",
        "name_de": "X or yes",
        "name_nl": "X or yes",
        "name_pl": "X or yes",
        "name_ro": "X or yes",
        "name_ru": "X or yes",
        "name_tr": "X or yes",
        "name_se": "X or yes",
        "name_el": "X or yes"
    },
    "16063": {
        "name_en": "{$competitor1}/X / {$competitor1}/X",
        "name_aa": "{$competitor1}/X / {$competitor1}/X",
        "name_de": "{$competitor1}/X / {$competitor1}/X",
        "name_nl": "{$competitor1}/X / {$competitor1}/X",
        "name_pl": "{$competitor1}/X / {$competitor1}/X",
        "name_ro": "{$competitor1}/X / {$competitor1}/X",
        "name_ru": "{$competitor1}/X / {$competitor1}/X",
        "name_tr": "{$competitor1}/X / {$competitor1}/X",
        "name_se": "{$competitor1}/X / {$competitor1}/X",
        "name_el": "{$competitor1}/X / {$competitor1}/X"
    },
    "16064": {
        "name_en": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_de": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/X / {$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/X / {$competitor1}/{$competitor2}"
    },
    "16065": {
        "name_en": "{$competitor1}/X / X/{$competitor2}",
        "name_aa": "{$competitor1}/X / X/{$competitor2}",
        "name_de": "{$competitor1}/X / X/{$competitor2}",
        "name_nl": "{$competitor1}/X / X/{$competitor2}",
        "name_pl": "{$competitor1}/X / X/{$competitor2}",
        "name_ro": "{$competitor1}/X / X/{$competitor2}",
        "name_ru": "{$competitor1}/X / X/{$competitor2}",
        "name_tr": "{$competitor1}/X / X/{$competitor2}",
        "name_se": "{$competitor1}/X / X/{$competitor2}",
        "name_el": "{$competitor1}/X / X/{$competitor2}"
    },
    "16066": {
        "name_en": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_aa": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_de": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_nl": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_pl": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_ro": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_ru": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_tr": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_se": "{$competitor1}/{$competitor2} / {$competitor1}/X",
        "name_el": "{$competitor1}/{$competitor2} / {$competitor1}/X"
    },
    "16067": {
        "name_en": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_aa": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_de": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2} / X/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2} / X/{$competitor2}"
    },
    "16068": {
        "name_en": "X/{$competitor2} / {$competitor1}/X",
        "name_aa": "X/{$competitor2} / {$competitor1}/X",
        "name_de": "X/{$competitor2} / {$competitor1}/X",
        "name_nl": "X/{$competitor2} / {$competitor1}/X",
        "name_pl": "X/{$competitor2} / {$competitor1}/X",
        "name_ro": "X/{$competitor2} / {$competitor1}/X",
        "name_ru": "X/{$competitor2} / {$competitor1}/X",
        "name_tr": "X/{$competitor2} / {$competitor1}/X",
        "name_se": "X/{$competitor2} / {$competitor1}/X",
        "name_el": "X/{$competitor2} / {$competitor1}/X"
    },
    "16069": {
        "name_en": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_aa": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_de": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_se": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_el": "{$competitor1}/{$competitor2} / {$competitor1}/{$competitor2}"
    },
    "16070": {
        "name_en": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_aa": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_de": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_nl": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_pl": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_ro": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_ru": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_tr": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_se": "X/{$competitor2} / {$competitor1}/{$competitor2}",
        "name_el": "X/{$competitor2} / {$competitor1}/{$competitor2}"
    },
    "16071": {
        "name_en": "X/{$competitor2} / X/{$competitor2}",
        "name_aa": "X/{$competitor2} / X/{$competitor2}",
        "name_de": "X/{$competitor2} / X/{$competitor2}",
        "name_nl": "X/{$competitor2} / X/{$competitor2}",
        "name_pl": "X/{$competitor2} / X/{$competitor2}",
        "name_ro": "X/{$competitor2} / X/{$competitor2}",
        "name_ru": "X/{$competitor2} / X/{$competitor2}",
        "name_tr": "X/{$competitor2} / X/{$competitor2}",
        "name_se": "X/{$competitor2} / X/{$competitor2}",
        "name_el": "X/{$competitor2} / X/{$competitor2}"
    },
    "16072": {
        "name_en": "{$competitor1} or {total} over",
        "name_aa": "{$competitor1} or {total} over",
        "name_de": "{$competitor1} or {total} over",
        "name_nl": "{$competitor1} or {total} over",
        "name_pl": "{$competitor1} or {total} over",
        "name_ro": "{$competitor1} or {total} over",
        "name_ru": "{$competitor1} or {total} over",
        "name_tr": "{$competitor1} or {total} over",
        "name_se": "{$competitor1} or {total} over",
        "name_el": "{$competitor1} or {total} over"
    },
    "16073": {
        "name_en": "X or {total} over",
        "name_aa": "X or {total} over",
        "name_de": "X or {total} over",
        "name_nl": "X or {total} over",
        "name_pl": "X or {total} over",
        "name_ro": "X or {total} over",
        "name_ru": "X or {total} over",
        "name_tr": "X or {total} over",
        "name_se": "X or {total} over",
        "name_el": "X or {total} over"
    },
    "16074": {
        "name_en": "{$competitor2} or {total} over",
        "name_aa": "{$competitor2} or {total} over",
        "name_de": "{$competitor2} or {total} over",
        "name_nl": "{$competitor2} or {total} over",
        "name_pl": "{$competitor2} or {total} over",
        "name_ro": "{$competitor2} or {total} over",
        "name_ru": "{$competitor2} or {total} over",
        "name_tr": "{$competitor2} or {total} over",
        "name_se": "{$competitor2} or {total} over",
        "name_el": "{$competitor2} or {total} over"
    },
    "16075": {
        "name_en": "{$competitor1} or {total} under",
        "name_aa": "{$competitor1} or {total} under",
        "name_de": "{$competitor1} or {total} under",
        "name_nl": "{$competitor1} or {total} under",
        "name_pl": "{$competitor1} or {total} under",
        "name_ro": "{$competitor1} or {total} under",
        "name_ru": "{$competitor1} or {total} under",
        "name_tr": "{$competitor1} or {total} under",
        "name_se": "{$competitor1} or {total} under",
        "name_el": "{$competitor1} or {total} under"
    },
    "16076": {
        "name_en": "X or {total} under",
        "name_aa": "X or {total} under",
        "name_de": "X or {total} under",
        "name_nl": "X or {total} under",
        "name_pl": "X or {total} under",
        "name_ro": "X or {total} under",
        "name_ru": "X or {total} under",
        "name_tr": "X or {total} under",
        "name_se": "X or {total} under",
        "name_el": "X or {total} under"
    },
    "16077": {
        "name_en": "{$competitor2} or {total} under",
        "name_aa": "{$competitor2} or {total} under",
        "name_de": "{$competitor2} or {total} under",
        "name_nl": "{$competitor2} or {total} under",
        "name_pl": "{$competitor2} or {total} under",
        "name_ro": "{$competitor2} or {total} under",
        "name_ru": "{$competitor2} or {total} under",
        "name_tr": "{$competitor2} or {total} under",
        "name_se": "{$competitor2} or {total} under",
        "name_el": "{$competitor2} or {total} under"
    },
    "16078": {
        "name_en": "yes or {total} over",
        "name_aa": "yes or {total} over",
        "name_de": "yes or {total} over",
        "name_nl": "yes or {total} over",
        "name_pl": "yes or {total} over",
        "name_ro": "yes or {total} over",
        "name_ru": "yes or {total} over",
        "name_tr": "yes or {total} over",
        "name_se": "yes or {total} over",
        "name_el": "yes or {total} over"
    },
    "16079": {
        "name_en": "yes or {total} under",
        "name_aa": "yes or {total} under",
        "name_de": "yes or {total} under",
        "name_nl": "yes or {total} under",
        "name_pl": "yes or {total} under",
        "name_ro": "yes or {total} under",
        "name_ru": "yes or {total} under",
        "name_tr": "yes or {total} under",
        "name_se": "yes or {total} under",
        "name_el": "yes or {total} under"
    },
    "16080": {
        "name_en": "no or {total} over",
        "name_aa": "no or {total} over",
        "name_de": "no or {total} over",
        "name_nl": "no or {total} over",
        "name_pl": "no or {total} over",
        "name_ro": "no or {total} over",
        "name_ru": "no or {total} over",
        "name_tr": "no or {total} over",
        "name_se": "no or {total} over",
        "name_el": "no or {total} over"
    },
    "16081": {
        "name_en": "no or {total} under",
        "name_aa": "no or {total} under",
        "name_de": "no or {total} under",
        "name_nl": "no or {total} under",
        "name_pl": "no or {total} under",
        "name_ro": "no or {total} under",
        "name_ru": "no or {total} under",
        "name_tr": "no or {total} under",
        "name_se": "no or {total} under",
        "name_el": "no or {total} under"
    },
    "16082": {
        "name_en": "{$competitor1} and yes and {total} over",
        "name_aa": "{$competitor1} and yes and {total} over",
        "name_de": "{$competitor1} and yes and {total} over",
        "name_nl": "{$competitor1} and yes and {total} over",
        "name_pl": "{$competitor1} and yes and {total} over",
        "name_ro": "{$competitor1} and yes and {total} over",
        "name_ru": "{$competitor1} and yes and {total} over",
        "name_tr": "{$competitor1} and yes and {total} over",
        "name_se": "{$competitor1} and yes and {total} over",
        "name_el": "{$competitor1} and yes and {total} over"
    },
    "16083": {
        "name_en": "{$competitor1} and no and {total} over",
        "name_aa": "{$competitor1} and no and {total} over",
        "name_de": "{$competitor1} and no and {total} over",
        "name_nl": "{$competitor1} and no and {total} over",
        "name_pl": "{$competitor1} and no and {total} over",
        "name_ro": "{$competitor1} and no and {total} over",
        "name_ru": "{$competitor1} and no and {total} over",
        "name_tr": "{$competitor1} and no and {total} over",
        "name_se": "{$competitor1} and no and {total} over",
        "name_el": "{$competitor1} and no and {total} over"
    },
    "16084": {
        "name_en": "{$competitor1} and no and {total} under",
        "name_aa": "{$competitor1} and no and {total} under",
        "name_de": "{$competitor1} and no and {total} under",
        "name_nl": "{$competitor1} and no and {total} under",
        "name_pl": "{$competitor1} and no and {total} under",
        "name_ro": "{$competitor1} and no and {total} under",
        "name_ru": "{$competitor1} and no and {total} under",
        "name_tr": "{$competitor1} and no and {total} under",
        "name_se": "{$competitor1} and no and {total} under",
        "name_el": "{$competitor1} and no and {total} under"
    },
    "16085": {
        "name_en": "X and yes and {total} over",
        "name_aa": "X and yes and {total} over",
        "name_de": "X and yes and {total} over",
        "name_nl": "X and yes and {total} over",
        "name_pl": "X and yes and {total} over",
        "name_ro": "X and yes and {total} over",
        "name_ru": "X and yes and {total} over",
        "name_tr": "X and yes and {total} over",
        "name_se": "X and yes and {total} over",
        "name_el": "X and yes and {total} over"
    },
    "16086": {
        "name_en": "X and yes and {total} under",
        "name_aa": "X and yes and {total} under",
        "name_de": "X and yes and {total} under",
        "name_nl": "X and yes and {total} under",
        "name_pl": "X and yes and {total} under",
        "name_ro": "X and yes and {total} under",
        "name_ru": "X and yes and {total} under",
        "name_tr": "X and yes and {total} under",
        "name_se": "X and yes and {total} under",
        "name_el": "X and yes and {total} under"
    },
    "16087": {
        "name_en": "X and no and {total} under",
        "name_aa": "X and no and {total} under",
        "name_de": "X and no and {total} under",
        "name_nl": "X and no and {total} under",
        "name_pl": "X and no and {total} under",
        "name_ro": "X and no and {total} under",
        "name_ru": "X and no and {total} under",
        "name_tr": "X and no and {total} under",
        "name_se": "X and no and {total} under",
        "name_el": "X and no and {total} under"
    },
    "16088": {
        "name_en": "{$competitor2} and yes and {total} over",
        "name_aa": "{$competitor2} and yes and {total} over",
        "name_de": "{$competitor2} and yes and {total} over",
        "name_nl": "{$competitor2} and yes and {total} over",
        "name_pl": "{$competitor2} and yes and {total} over",
        "name_ro": "{$competitor2} and yes and {total} over",
        "name_ru": "{$competitor2} and yes and {total} over",
        "name_tr": "{$competitor2} and yes and {total} over",
        "name_se": "{$competitor2} and yes and {total} over",
        "name_el": "{$competitor2} and yes and {total} over"
    },
    "16089": {
        "name_en": "{$competitor2} and no and {total} over",
        "name_aa": "{$competitor2} and no and {total} over",
        "name_de": "{$competitor2} and no and {total} over",
        "name_nl": "{$competitor2} and no and {total} over",
        "name_pl": "{$competitor2} and no and {total} over",
        "name_ro": "{$competitor2} and no and {total} over",
        "name_ru": "{$competitor2} and no and {total} over",
        "name_tr": "{$competitor2} and no and {total} over",
        "name_se": "{$competitor2} and no and {total} over",
        "name_el": "{$competitor2} and no and {total} over"
    },
    "16090": {
        "name_en": "{$competitor2} and no and {total} under",
        "name_aa": "{$competitor2} and no and {total} under",
        "name_de": "{$competitor2} and no and {total} under",
        "name_nl": "{$competitor2} and no and {total} under",
        "name_pl": "{$competitor2} and no and {total} under",
        "name_ro": "{$competitor2} and no and {total} under",
        "name_ru": "{$competitor2} and no and {total} under",
        "name_tr": "{$competitor2} and no and {total} under",
        "name_se": "{$competitor2} and no and {total} under",
        "name_el": "{$competitor2} and no and {total} under"
    },
    "16091": {
        "name_en": "<1 and <1",
        "name_aa": "<1 and <1",
        "name_de": "<1 and <1",
        "name_nl": "<1 and <1",
        "name_pl": "<1 and <1",
        "name_ro": "<1 and <1",
        "name_ru": "<1 and <1",
        "name_tr": "<1 and <1",
        "name_se": "<1 and <1",
        "name_el": "<1 and <1"
    },
    "16092": {
        "name_en": ">=1 and >=1",
        "name_aa": ">=1 and >=1",
        "name_de": ">=1 and >=1",
        "name_nl": ">=1 and >=1",
        "name_pl": ">=1 and >=1",
        "name_ro": ">=1 and >=1",
        "name_ru": ">=1 and >=1",
        "name_tr": ">=1 and >=1",
        "name_se": ">=1 and >=1",
        "name_el": ">=1 and >=1"
    },
    "16093": {
        "name_en": "<1 and <2",
        "name_aa": "<1 and <2",
        "name_de": "<1 and <2",
        "name_nl": "<1 and <2",
        "name_pl": "<1 and <2",
        "name_ro": "<1 and <2",
        "name_ru": "<1 and <2",
        "name_tr": "<1 and <2",
        "name_se": "<1 and <2",
        "name_el": "<1 and <2"
    },
    "16094": {
        "name_en": ">=1 and >=2",
        "name_aa": ">=1 and >=2",
        "name_de": ">=1 and >=2",
        "name_nl": ">=1 and >=2",
        "name_pl": ">=1 and >=2",
        "name_ro": ">=1 and >=2",
        "name_ru": ">=1 and >=2",
        "name_tr": ">=1 and >=2",
        "name_se": ">=1 and >=2",
        "name_el": ">=1 and >=2"
    },
    "16095": {
        "name_en": "<1 and <3",
        "name_aa": "<1 and <3",
        "name_de": "<1 and <3",
        "name_nl": "<1 and <3",
        "name_pl": "<1 and <3",
        "name_ro": "<1 and <3",
        "name_ru": "<1 and <3",
        "name_tr": "<1 and <3",
        "name_se": "<1 and <3",
        "name_el": "<1 and <3"
    },
    "16096": {
        "name_en": ">=1 and >=3",
        "name_aa": ">=1 and >=3",
        "name_de": ">=1 and >=3",
        "name_nl": ">=1 and >=3",
        "name_pl": ">=1 and >=3",
        "name_ro": ">=1 and >=3",
        "name_ru": ">=1 and >=3",
        "name_tr": ">=1 and >=3",
        "name_se": ">=1 and >=3",
        "name_el": ">=1 and >=3"
    },
    "16097": {
        "name_en": "<2 and <1",
        "name_aa": "<2 and <1",
        "name_de": "<2 and <1",
        "name_nl": "<2 and <1",
        "name_pl": "<2 and <1",
        "name_ro": "<2 and <1",
        "name_ru": "<2 and <1",
        "name_tr": "<2 and <1",
        "name_se": "<2 and <1",
        "name_el": "<2 and <1"
    },
    "16098": {
        "name_en": ">=2 and >=1",
        "name_aa": ">=2 and >=1",
        "name_de": ">=2 and >=1",
        "name_nl": ">=2 and >=1",
        "name_pl": ">=2 and >=1",
        "name_ro": ">=2 and >=1",
        "name_ru": ">=2 and >=1",
        "name_tr": ">=2 and >=1",
        "name_se": ">=2 and >=1",
        "name_el": ">=2 and >=1"
    },
    "16099": {
        "name_en": "<2 and <2",
        "name_aa": "<2 and <2",
        "name_de": "<2 and <2",
        "name_nl": "<2 and <2",
        "name_pl": "<2 and <2",
        "name_ro": "<2 and <2",
        "name_ru": "<2 and <2",
        "name_tr": "<2 and <2",
        "name_se": "<2 and <2",
        "name_el": "<2 and <2"
    },
    "16100": {
        "name_en": ">=2 and >=2",
        "name_aa": ">=2 and >=2",
        "name_de": ">=2 and >=2",
        "name_nl": ">=2 and >=2",
        "name_pl": ">=2 and >=2",
        "name_ro": ">=2 and >=2",
        "name_ru": ">=2 and >=2",
        "name_tr": ">=2 and >=2",
        "name_se": ">=2 and >=2",
        "name_el": ">=2 and >=2"
    },
    "16101": {
        "name_en": "<2 and <3",
        "name_aa": "<2 and <3",
        "name_de": "<2 and <3",
        "name_nl": "<2 and <3",
        "name_pl": "<2 and <3",
        "name_ro": "<2 and <3",
        "name_ru": "<2 and <3",
        "name_tr": "<2 and <3",
        "name_se": "<2 and <3",
        "name_el": "<2 and <3"
    },
    "16102": {
        "name_en": ">=2 and >=3",
        "name_aa": ">=2 and >=3",
        "name_de": ">=2 and >=3",
        "name_nl": ">=2 and >=3",
        "name_pl": ">=2 and >=3",
        "name_ro": ">=2 and >=3",
        "name_ru": ">=2 and >=3",
        "name_tr": ">=2 and >=3",
        "name_se": ">=2 and >=3",
        "name_el": ">=2 and >=3"
    },
    "16103": {
        "name_en": "<3 and <1",
        "name_aa": "<3 and <1",
        "name_de": "<3 and <1",
        "name_nl": "<3 and <1",
        "name_pl": "<3 and <1",
        "name_ro": "<3 and <1",
        "name_ru": "<3 and <1",
        "name_tr": "<3 and <1",
        "name_se": "<3 and <1",
        "name_el": "<3 and <1"
    },
    "16104": {
        "name_en": ">=3 and >=1",
        "name_aa": ">=3 and >=1",
        "name_de": ">=3 and >=1",
        "name_nl": ">=3 and >=1",
        "name_pl": ">=3 and >=1",
        "name_ro": ">=3 and >=1",
        "name_ru": ">=3 and >=1",
        "name_tr": ">=3 and >=1",
        "name_se": ">=3 and >=1",
        "name_el": ">=3 and >=1"
    },
    "16105": {
        "name_en": ">=3 and >=2",
        "name_aa": ">=3 and >=2",
        "name_de": ">=3 and >=2",
        "name_nl": ">=3 and >=2",
        "name_pl": ">=3 and >=2",
        "name_ro": ">=3 and >=2",
        "name_ru": ">=3 and >=2",
        "name_tr": ">=3 and >=2",
        "name_se": ">=3 and >=2",
        "name_el": ">=3 and >=2"
    },
    "16106": {
        "name_en": ">=3 and >=3",
        "name_aa": ">=3 and >=3",
        "name_de": ">=3 and >=3",
        "name_nl": ">=3 and >=3",
        "name_pl": ">=3 and >=3",
        "name_ro": ">=3 and >=3",
        "name_ru": ">=3 and >=3",
        "name_tr": ">=3 and >=3",
        "name_se": ">=3 and >=3",
        "name_el": ">=3 and >=3"
    },
    "16114": {
        "name_en": "{$competitor1}/X & {$competitor1}",
        "name_aa": "{$competitor1}/X & {$competitor1}",
        "name_de": "{$competitor1}/X & {$competitor1}",
        "name_nl": "{$competitor1}/X & {$competitor1}",
        "name_pl": "{$competitor1}/X & {$competitor1}",
        "name_ro": "{$competitor1}/X & {$competitor1}",
        "name_ru": "{$competitor1}/X & {$competitor1}",
        "name_tr": "{$competitor1}/X & {$competitor1}",
        "name_se": "{$competitor1}/X & {$competitor1}",
        "name_el": "{$competitor1}/X & {$competitor1}"
    },
    "16115": {
        "name_en": "{$competitor1}/X & {$competitor2}",
        "name_aa": "{$competitor1}/X & {$competitor2}",
        "name_de": "{$competitor1}/X & {$competitor2}",
        "name_nl": "{$competitor1}/X & {$competitor2}",
        "name_pl": "{$competitor1}/X & {$competitor2}",
        "name_ro": "{$competitor1}/X & {$competitor2}",
        "name_ru": "{$competitor1}/X & {$competitor2}",
        "name_tr": "{$competitor1}/X & {$competitor2}",
        "name_se": "{$competitor1}/X & {$competitor2}",
        "name_el": "{$competitor1}/X & {$competitor2}"
    },
    "16116": {
        "name_en": "{$competitor1}/X & X",
        "name_aa": "{$competitor1}/X & X",
        "name_de": "{$competitor1}/X & X",
        "name_nl": "{$competitor1}/X & X",
        "name_pl": "{$competitor1}/X & X",
        "name_ro": "{$competitor1}/X & X",
        "name_ru": "{$competitor1}/X & X",
        "name_tr": "{$competitor1}/X & X",
        "name_se": "{$competitor1}/X & X",
        "name_el": "{$competitor1}/X & X"
    },
    "16117": {
        "name_en": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_aa": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_de": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_nl": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_pl": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_ro": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_ru": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_tr": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_se": "{$competitor1}/{$competitor2} & {$competitor1}",
        "name_el": "{$competitor1}/{$competitor2} & {$competitor1}"
    },
    "16118": {
        "name_en": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_aa": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_de": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_nl": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_pl": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_ro": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_ru": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_tr": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_se": "{$competitor1}/{$competitor2} & {$competitor2}",
        "name_el": "{$competitor1}/{$competitor2} & {$competitor2}"
    },
    "16119": {
        "name_en": "{$competitor1}/{$competitor2} & X",
        "name_aa": "{$competitor1}/{$competitor2} & X",
        "name_de": "{$competitor1}/{$competitor2} & X",
        "name_nl": "{$competitor1}/{$competitor2} & X",
        "name_pl": "{$competitor1}/{$competitor2} & X",
        "name_ro": "{$competitor1}/{$competitor2} & X",
        "name_ru": "{$competitor1}/{$competitor2} & X",
        "name_tr": "{$competitor1}/{$competitor2} & X",
        "name_se": "{$competitor1}/{$competitor2} & X",
        "name_el": "{$competitor1}/{$competitor2} & X"
    },
    "16120": {
        "name_en": "X/{$competitor2} & {$competitor1}",
        "name_aa": "X/{$competitor2} & {$competitor1}",
        "name_de": "X/{$competitor2} & {$competitor1}",
        "name_nl": "X/{$competitor2} & {$competitor1}",
        "name_pl": "X/{$competitor2} & {$competitor1}",
        "name_ro": "X/{$competitor2} & {$competitor1}",
        "name_ru": "X/{$competitor2} & {$competitor1}",
        "name_tr": "X/{$competitor2} & {$competitor1}",
        "name_se": "X/{$competitor2} & {$competitor1}",
        "name_el": "X/{$competitor2} & {$competitor1}"
    },
    "16121": {
        "name_en": "X/{$competitor2} & {$competitor2}",
        "name_aa": "X/{$competitor2} & {$competitor2}",
        "name_de": "X/{$competitor2} & {$competitor2}",
        "name_nl": "X/{$competitor2} & {$competitor2}",
        "name_pl": "X/{$competitor2} & {$competitor2}",
        "name_ro": "X/{$competitor2} & {$competitor2}",
        "name_ru": "X/{$competitor2} & {$competitor2}",
        "name_tr": "X/{$competitor2} & {$competitor2}",
        "name_se": "X/{$competitor2} & {$competitor2}",
        "name_el": "X/{$competitor2} & {$competitor2}"
    },
    "16122": {
        "name_en": "X/{$competitor2} & X",
        "name_aa": "X/{$competitor2} & X",
        "name_de": "X/{$competitor2} & X",
        "name_nl": "X/{$competitor2} & X",
        "name_pl": "X/{$competitor2} & X",
        "name_ro": "X/{$competitor2} & X",
        "name_ru": "X/{$competitor2} & X",
        "name_tr": "X/{$competitor2} & X",
        "name_se": "X/{$competitor2} & X",
        "name_el": "X/{$competitor2} & X"
    }
}