export const fr = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Des sports',
    live: 'Sport direct',
    inplay: 'Sport direct',
    today: "Aujourd'hui",
    upcoming: 'A venir',
    tomorrow: 'Demain',
    highlights: 'Points forts',
    results: 'Résultats',
    lastMinute: 'Dernière minute',
    liveCasino: 'Casino en direct',
    coinPayments: 'Coin Payments',
    casino: 'Casino',
    myBets: 'Mes paris',
    home: 'Domicile',
    login: 'Connexion',
    signup: "S'inscrire",
    betslip: 'Pari slip',
    search: 'Rechercher',
    searchHere: 'Cherche ici',
    transaction: 'Transaction',
    changePassword: 'Changer le mot de passe',
    logout: 'Se déconnecter',
    single: 'Seul',
    multiple: 'Plusieurs',
    possibleWin: 'Victoire possible',
    amount: 'Montant',
    ok: "D'accord",
    totalOdds: 'Cote totale',
    odd: 'Impair',
    totalPossibleWin: 'Total des victoire possible',
    placeBet: 'Parier',
    fastBet: 'Vite Pari',
    fast: 'Vite',
    bet: 'Pari',
    username: "Nom d'utilisateur",
    password: 'Mot de passe',
    stayLoggedIn: 'Rester connecté',
    all: 'Tout',
    "in game": 'En jeu',
    won: 'Gagné',
    lost: 'Perdu',
    refund: 'Remboursement',
    date: 'Date',
    winAmount: 'Montant Win',
    stake: 'Miser',
    pick: 'Prendre',
    myAccount: 'Mon compte',
    football: 'Football',
    basketball: 'Basketball',
    iceHockey: 'Hockey sur glace',
    tennis: 'Tennis',
    volleyball: 'Volley-ball',
    boxing: 'Boxe',
    americanFootball: 'football américain',
    baseball: 'Base-ball',
    hockey: 'Le hockey',
    tableTennis: 'Tennis de table',
    badminton: 'Badminton',
    cricket: 'Criquet',
    futsal: 'Futsal',
    golf: 'Le golf',
    handball: 'Handball',
    rugbyLeagues: 'Ligues de rugby',
    pickCanNotBeCombined: 'Ce choix ne peut pas être combiné',
    matchStopped: 'Match arrêté',
    favorites: 'Favoris',
    cashout: 'Encaissement',
    "in progress": 'En cours',
    rejected: 'Rejeté',
    tip: 'Conseil',
    noGoal: 'Sans but',
    yes: 'Oui',
    over: 'Plus de',
    under: 'En dessous de',
    even: 'Même',
    areYouSureWantCashout: 'Etes-vous sûr que vous voulez Encaissement?',
    noGoal: 'Non',
    bonus: 'Prime',
    termsAndConditon: 'Termes et conditions',
    aboutUs: 'À propos de nous',
    contactUs: 'Contactez-nous',
    payments: 'Paiements',
    howPlacebet: 'Comment placer parier',
    deposit: 'Dépôt',
    credit: 'Crédit',
    debit: 'Débit',
    withdraw: 'Retirer',
    totalOdd: 'Total des Odd',
    totalStake: 'Mise totale',
    topGames: 'Meilleurs jeux',
    allGames: 'Tous les jeux',
    canNotBeCombined: 'Cette Pioche ne peut pas être combiné',
    betSettled: 'Bet Réglé',
    betSuspended: 'Bet suspendu',
    insufficientBalance: 'Solde insuffisant',
    oldPassword: 'Ancien mot de passe',
    newPassword: 'Nouveau mot de passe',
    confirmPassword: 'Confirmer le nouveau mot de passe',
    change: 'Changer',
    fromDate: 'Partir de la date',
    toDate: 'À ce jour',
    activityType: "Type d'activité",
    show: 'Spectacle',
    gameType: 'Type de jeu',
    repeatBet: 'Remiser',
    someThingWrong: 'Une erreur se est',
    status: 'Statut',
    coupon: 'Coupon',
    acceptOddChangesText: 'Vous devez accepter les changements pour être en mesure de placer un pari',
    turnOffAway: 'Éteignez de toute façon',
    acceptOddChanges: 'Accepter les changements impairs',
    maxWinAmount: 'Nombre maximal de victoire',
    maxOdd: 'Max étrange',
    dateTime: 'Date / Heure',
    type: 'Taper',
    refreshCashout: 'Actualiser encaissement',
    vendor: 'Vendeur',
    stakeLowerThen1: "L'enjeu doit être inférieur",
    stakeGreaterThen1: "L'enjeu doit être supérieur à",
    ESports: 'Déports',
    KSports: 'Ksports',
    promotion: 'Promotion',
    customerServicePage: 'Page de service clientèle',
    decimal: 'Décimal',
    fraction: 'Fraction',
    americanOdds: 'Cotes américaines',
    nothingFound: "rien n'a été trouvé",
    noLiveMatch: "Aucun match en direct n'est disponible",
    sportBook: 'Livre de sport',

    marketNames: {
        1:  '1x2',
        2:  'Moins de / Plus',
        238: 'correspondance restante',
        59: 'Prochain but',
        7: 'Double chance',
        52: 'Pari remboursé Dessiner',
        226: '12 heures supplémentaires comprises',
        13: 'Handicap européen',
        4: 'HT / FT',
        17: 'Les deux equipes marquent',
        59: 'Prochain but',
        28: 'Moins de / Plus incluant les heures supplémentaires',
        247: 'Restant 1er semestre',
    },
    tabNames: {
        'All': 'Toute',
        'Main Markets': 'Marchés principaux',
        'Totals': 'Les totaux',
        '1st Half': '1ère moitié',
        '2nd Half': '2e mi-temps',
        'Home Team': 'équipe domicile',
        'Away Team': 'Équipe extérieure',
        'All Periods': 'toutes les périodes',
        'Results': 'Résultats',
        'Home / Away': 'Loin de la maison',
    },
    markets: {
       1:  '1x2',
       2: 'Moins de / Plus',
       3: 'Handicap asiatique',
       5: 'Odd / Even',
       6: 'Score correct',
       7: 'Double chance',
       9: ' Score correct 1ère période',
       11: 'total des coins',
       12: 'Total des Fautes commises',
       13: 'Handicap européen',
       16: 'Première équipe à marquer',
       17: 'Les deux equipes marquent',
       19: 'première carte',
       21: 'Sous / Sur la 1ère période',
       22: "L'équipe à marquer",
       23: 'Accueil à marquer',
       25: 'Double Chance Mi-temps',
       29: 'Moins de / Plus Rounds',
       30: 'Moins de / Plus Corners - Team Accueil',
       31: 'Moins de / Plus Corners - Équipe loin',
       34: 'Accueil équipe à marquer deux mi-temps',
       35: "L'équipe à marquer dans les deux mi",
       41: '1ère période Gagnant',
       42: '2ème période Gagnant',
       43: '3e période Gagnant',
       44: '4e période Gagnant',
       45: 'Sous / Sur 2e période',
       46: 'Moins de / Plus de la 3e période',
       47: 'Moins de / Plus 4e période',
       48: 'Moins de / Plus 5ème période',
       49: '5 Période Gagnant',
       50: '1X2 compris les heures supplémentaires',
       51: 'Odd / Même compris les heures supplémentaires',
       52: '12',
       53: 'Asian Handicap Mi-temps',
       55: 'Première équipe à marquer 1re période',
       56: 'Dernière équipe à marquer',
       57: 'Dernière équipe à marquer la mi-temps',
       59: 'Prochain but',
       61: 'Mi-temps Handicap européen',
       62: 'Odd / Even Mi-temps',
       63: '12 Mi-temps',
       64: 'Handicap asiatique 1ère période',
       65: 'Asian Handicap 2ème période',
       66: 'Handicap asiatique 3e période',
       67: '4 Période Handicap Asiatique',
       68: '5 Période Handicap Asiatique',
       69: 'Est-ce que le jeu Aller à des heures supplémentaires?',
       70: 'Meilleur score Période',
       71: 'Meilleur demi',
       72: '1ère période Odd / Even',
       73: '2ème période Odd / Even',
       74: '3e période Odd / Even',
       75: '4e période Odd / Even',
       76: '5ème période Odd / Even',
       77: 'Moins de / Plus Mi-temps',
       78: 'La plupart Sixes à marquer',
       79: 'Un châtiment sera attribué?',
       80: 'premier angle',
       81: 'dernier virage',
       82: 'Est-ce une carte rouge être affichée?',
       83: 'Pour garder une feuille blanche',
       84: 'Pour gagner les deux Moitié',
       85: 'Gagner par derrière',
       86: 'Pour gagner à néant',
       88: 'Qualifier',
       89: 'Est-ce que le premier essai être converti?',
       90: 'Y aura-être décidé en 5ème set?',
       91: 'Quelle moitié premier but marqués?',
       92: 'Première équipe à marquer 2e mi-temps',
       95: 'coins Handicap',
       96: "L'équipe Gagner à néant",
       97: 'Équipe domicile Gagnez à zéro',
       98: 'Équipe domicile pour garder une feuille propre',
       99: "L'équipe de garder une feuille propre",
       100: 'Score correct 2e période',
       101: 'Moins de / Plus - Équipe Accueil',
       102: 'Moins de / Plus - Équipe loin',
       104: "L'équipe pour gagner chaque mi-temps",
       105: 'Équipe domicile pour gagner chaque mi-temps',
       106: 'Équipe domicile pour gagner par derrière',
       107: "L'équipe à gagner par derrière",
       108: 'Home Team Score sur une pénalité',
       109: 'Équipe extérieur Score sur une pénalité',
       110: "L'équipe pour gagner la moitié au moins",
       111: 'Équipe domicile pour gagner la moitié au moins',
       113: 'Les deux équipes marquent 1ère mi-temps',
       114: 'Tout joueur à marquer au moins 2 buts',
       117: 'Tout joueur à marquer au moins 3 buts',
       122: "L'équipe de notation Premier Gagnant du match",
       123: "L'équipe à marquer et gagner le match",
       124: 'Accueil équipe à marquer et gagner le match',
       128: 'Nombre de buts',
       129: 'Moins de / Plus Corners - 1er semestre',
       132: 'Sous / Plus de Tirs cadrés',
       133: 'Moins de / Plus Tirs au total',
       134: 'Nombre de buts 1er semestre',
       136: 'Moins de / Plus Offsides',
       137: 'Accueil Gagnez Pari remboursé',
       138: "Victoire à l'extérieur Pari remboursé",
       139: 'Dans laquelle la moitié Équipe marquerez premier but?',
       140: 'Dans Quelle demie équipe marquerez premier but?',
       143: 'Dans laquelle la moitié Équipe marquerez Plus de buts?',
       144: 'Dans Quelle demie équipe va marquer plus de buts?',
       145: 'Équipe domicile Nombre de buts en 1ère mi-temps',
       146: "Nombre loin de l'équipe de buts en 1ère mi-temps",
       147: "Nombre loin de l'équipe de buts en 2ème mi",
       148: "Équipe domicile Nombre de buts en 2ème mi",
       149: 'Équipe domicile Nombre de buts',
       150: "Nombre d'équipe loin des objectifs",
       151: 'Double Chance 2e mi-temps',
       153: 'Sous / Sur la 1ère période - équipe Accueil',
       154: 'Sous / Sur 2e période - équipe Accueil',
       155: 'Sous / Sur la 1ère période - Équipe loin',
       156: 'Sous / Sur 2e période - Équipe loin',
       157: 'Moins de / Plus de Cartons Jaunes - 1er semestre',
       158: 'Moins de / Plus de Cartons Jaunes',
       159: 'Sera un Tie-Break dans le match?',
       160: 'Vainqueur de la course',
       161: '10 Résultat Minute',
       162: '10 minutes Moins de / Plus',
       163: 'Nombre de buts 2ème mi-temps',
       164: 'Sera un Tie-Break La première série?',
       165: 'Sous / Ensembles Plus',
       166: 'Sous / Plus de Jeux',
       168: 'SEREZ Un but?',
       169: 'À marquer dans deux mi-temps',
       170: 'Odd / Even Coins',
       171: 'Pour gagner HALF',
       175: 'Sera un Tie-Break The Second Set?',
       176: 'Sera un Tie-Break The Third Set?',
       177: 'Sera un Tie-Break The Quatrième série?',
       178: 'Sera un Tie-Break The Fifth Set?',
       180: 'Moins de / Plus-Ins Throw',
       181: 'Moins de / Plus de Cartons Jaunes - équipe Accueil',
       182: 'Moins de / Plus de Cartons Jaunes - Équipe 1er semestre',
       183: 'Moins de / Plus de Cartons Jaunes - Équipe 2e mi-temps',
       184: 'Moins de / Plus de Cartons Jaunes - Équipe loin',
       185: 'Moins de / Plus de Cartons Jaunes - Équipe 1er semestre',
       186: 'Moins de / Plus de Cartons Jaunes - Équipe 2e mi-temps',
       187: 'Moins de / Plus de Cartons Jaunes - 2e mi-temps',
       188: 'Carton jaune',
       190: 'Carton jaune 2e mi-temps',
       191: 'Y aura-t Score du Joueur un hat-trick?',
       192: 'Est-ce un objectif marqués en deux mi-temps?',
       194: 'Équipe domicile Pour garder une feuille propre 1ère période',
       195: 'Équipe domicile Pour garder une feuille propre 2ème période',
       196: "L'équipe de garder une feuille propre 1ère période",
       197: "L'équipe de garder une feuille propre 2ème période",
       198: 'Odd / Even - Équipe',
       199: 'Odd / Even - Équipe loin',
       200: 'Est-ce que la première équipe de notation Win?',
       201: 'Jeux Handicap Asiatique',
       202: '1ère période Gagnant Domicile / Extérieur',
       203: '2ème période Gagnant Domicile / Extérieur',
       204: '3e période Gagnant Domicile / Extérieur',
       205: '4e période Gagnant Domicile / Extérieur',
       206: '5 Période Gagnant Domicile / Extérieur',
       207: '15 Résultat Minute',
       208: '30 Résultat Minute',
       209: 'Total des Corners - 1er semestre',
       210: 'Total des Corners - 2ème mi-temps',
       211: 'Les deux équipes marquent 2e mi-temps',
       212: 'Les deux équipes à marquer dans les deux Moitié',
       213: 'Dessiner dans les deux mi',
       214: 'Moins de / Plus de cartes',
       215: "L'équipe à marquer 1er semestre",
       216: "L'équipe à marquer 2ème mi-temps",
       217: 'Score correct 3e période',
       218: 'Accueil à marquer 1er semestre',
       219: 'Accueil à marquer 2ème mi-temps',
       220: 'Moins de / Plus - Équipe compris les heures supplémentaires',
       221: 'Moins de / Plus - Équipe compris les heures supplémentaires',
       222: 'Sous / Sur la 3e période - Équipe loin',
       223: 'Sous / Sur la 3e période - équipe Accueil',
       224: 'Double Chance 3e période',
       225: 'Double Chance 4 Période',
       226: '12 heures supplémentaires comprises',
       227: 'Vainqueur de la course 2 places',
       228: 'Vainqueur de la course 3 Places',
       229: 'Vainqueur de la course 4 Places',
       233: 'Pour gagner Sans Nil',
       235: '1er 5 Innings Vainqueur',
       236: '1er 5 Innings Moins de / Plus',
       237: 'Will There Be A Score Au 1er Inning',
       238: 'match restant',
       239: 'objectifs Range',
       242: '1ère période Odd / Even - Team Accueil',
       243: '1ère période Odd / Even - Équipe loin',
       244: "Meilleur partenariat d'ouverture",
       245: 'But / Pas de but',
       247: 'Restant 1er semestre',
       250: 'Coins Handicap - 1er semestre',
       254: 'cartes Range',
       255: 'Temps de 1ère carte',
       256: 'Accueil équipe - Cartes Range',
       257: 'Équipe extérieur - Cartes Range',
       258: 'coins Gamme',
       259: 'Equipe la plus Corners',
       260: 'Equipe la plus Corners 1er semestre',
       261: 'Equipe la plus 2ème mi-temps Corners',
       262: 'Home Team - Coins Gamme',
       263: 'Équipe extérieur - Corners Range',
       264: 'Home Team - 1er semestre Corners Gamme',
       265: 'Équipe extérieur - 1er semestre Corners Gamme',
       266: 'Home Team - 2ème mi-temps Corners Gamme',
       267: 'Équipe extérieur - 2ème mi-temps Corners Gamme',
       269: 'Objectifs RANG 1ère période',
       271: 'Chance Mix',
       274: 'Vainqueur Outright',
       275: 'Objectifs Gamme 2e période',
       278: 'Objectifs Plage Équipe',
       279: 'Objectifs Équipe Portée',
       281: '1er 5 Innings Handicap Asiatique',
       282: '1X2 Mi-temps',
       283: 'Handicap Asiatique 2e mi-temps',
       284: '1X2 2e mi-temps',
       285: 'Odd / Even 2e mi-temps',
       286: '12 2e mi-temps',
       287: 'Sous / Sur 4e période - équipe Accueil',
       288: "Moins de / Plus 4e période - l'équipe extérieure",
       289: '2ème période Odd / Even - Team Accueil',
       290: '3e période Odd / Even - Team Accueil',
       291: '4e période Odd / Even - Team Accueil',
       292: '2ème période Odd / Even - Équipe loin',
       293: '3e période Odd / Even - Équipe loin',
       294: '4e période Odd / Even - Équipe loin',
       297: 'SP',
       299: 'point gagnant',
       300: 'Vainqueur du jeu',
       301: 'objectif Avant',
       302: 'objectif Après',
       304: 'Équipe avec Score Période la plus élevée',
       305: 'Coins - Sous / Exactement / Plus',
       306: 'Premier but dernier but',
       307: 'Jeux Européens Handicap',
       308: 'Moins de / Plus meilleur score Période',
       309: 'Moins de / Plus bas Scoring Période',
       310: 'série Vainqueur',
       317: 'Sous / Exactement / Over - 1ère période',
       318: 'Sous / Exactement / Au cours - 2e période',
       319: 'Sous / Exactement / Over - 3e période',
       320: 'Sous / Exactement / Au cours - 4e période',
       322: 'Sous / Exactement / Plus',
       329: '1ère période Race To',
       330: '2ème période Race To',
       331: '3ème période Race To',
       332: '4e période Race To',
       333: '5 Période Race To',
       337: 'Moins de / Plus - 2e mi-temps',
       338: 'Prochain objectif 1ère période',
       339: 'Prochain objectif 2ème période',
       341: 'Race To',
       342: 'Handicap Asiatique compris les heures supplémentaires',
       343: 'Jeu RANG 1ère période',
       344: 'Jeu Plage 2ème période',
       345: 'Jeu Range 3e période',
       346: 'Jeu Plage 4 Période',
       347: 'Jeu Plage 5 Période',
       348: '6e période Gagnant',
       349: '7 Période Gagnant',
       350: '6e période Odd / Even',
       351: '7 Période Odd / Even',
       352: 'Moins de / Plus 6 Période',
       353: 'Moins de / Plus 7 Période',
       354: 'Moins de / Plus Mi-temps - Équipe Accueil',
       355: 'Moins de / Plus Mi-temps - Équipe loin',
       356: 'Méthode de Licenciement 6 voies',
       357: 'Méthode Rejet',
       366: 'Runs Odd / Même dans Over - Team Accueil',
       367: 'Total des Runs Odd / Même dans Over - Équipe loin',
       370: 'Total des points en cours - Équipe Accueil',
       371: 'Total des Runs en cours - Équipe loin',
       386: 'Heures supplémentaires européenne, y compris Handicap',
       387: 'Odd / Even - Équipe compris les heures supplémentaires',
       388: 'Odd / Even - Équipe compris les heures supplémentaires',
       390: 'HT / FT compris les heures supplémentaires',
       391: 'Prochain objectif - Temps supplémentaire',
       392: 'Équipe domicile Penalty Shootout',
       393: 'Équipe loin Penalty Shootout',
       394: 'Score correct 4e période',
       395: 'Score exact 5 Période',
       398: 'Cartes / Odd Même',
       400: 'Odd / Even Cartes 1er semestre',
       401: 'Moins de / Plus Corners 1er semestre - équipe Accueil',
       402: "Moins de / Plus Corners 1er semestre - l'équipe extérieure",
       403: 'Moins de / Plus Corners 2e mi-temps',
       404: 'Moins de / Plus corners, 2ème mi - équipe Accueil',
       405: 'Moins de / Plus corners, 2ème mi - équipe loin',
       406: 'Odd / Even Corners 1er semestre',
       407: 'Asie Cartes Handicap',
       408: 'Cartes Handicap Asiatique 1er semestre',
       409: 'Coins 1X2',
       410: '1X2 Corners 1er semestre',
       411: '12 Coins',
       414: 'Sous / overcards 1er semestre',
       415: '1X2 et les deux équipes marquent',
       416: 'Moins de / Plus - Prolongations',
       417: 'Moins de / Plus - Prolongations 1er semestre',
       419: '1X2 - Temps supplémentaire',
       427: '1X2 Moins de / Plus',
       428: 'Multiple Score correct',
       430: 'Sous / Exactement / Au cours - 1er semestre',
       431: 'Sous / Exactement / Over - 2e mi-temps',
       433: 'Handicap européen Corners',
       434: '8 Période Gagnant',
       435: '9 Période Gagnant',
       436: 'Moins de / Plus 8 Période',
       437: 'Moins de / Plus 9 Période',
       438: 'Total des Runs Odd / Même Plus',
       439: 'Total des Runs en cours',
       445: '6 Période Gagnant Domicile / Extérieur',
       446: '7 Période Gagnant Domicile / Extérieur',
       447: '6 Période Handicap Asiatique',
       448: '7 Période Handicap Asiatique',
       449: '8 Période Handicap Asiatique',
       450: 'Handicap 9 Période asiatique',
       451: '8 Période Gagnant Domicile / Extérieur',
       452: '9 Période Gagnant Domicile / Extérieur',
       453: 'Premier score Méthode',
       454: "Premier score Méthode - L'équipe Accueil",
       455: 'Premier score Méthode - Équipe loin',
       456: 'Double Chance 1ère période',
       457: 'Double Chance 2ème période',
       459: 'Accueil à marquer dans tous les quartiers',
       460: "L'équipe à marquer dans tous les quartiers",
       462: 'Top 5 Vainqueur',
       463: 'Top 10 Vainqueur',
       464: '12 2e mi-temps compris les heures supplémentaires',
       465: '4e période Gagnant Domicile / Extérieur Including Overtime',
       466: 'Moins de / Plus 4e période incluant les heures supplémentaires',
       467: 'Asian Handicap 4e période incluant les heures supplémentaires',
       468: 'Asian Handicap 2e mi-temps compris les heures supplémentaires',
       469: 'Moins de / Plus 2e mi-temps compris les heures supplémentaires',
       472: 'Will jeu Aller à Shootout?',
       475: 'Moins de / Plus Runs En cours - Équipe 1er Inning',
       476: 'Moins de / Plus Runs En cours - Équipe 1ère manche de baseball',
       477: 'Moins de / Plus Runs En cours - Équipe 2e manche de baseball',
       478: 'Moins de / Plus Runs En cours - Équipe 2e manche de baseball',
       479: 'Odd / Even fonctionne en cours - Équipe 1er Inning',
       480: 'Odd / Even fonctionne en cours - Équipe 1ère manche de baseball',
       481: 'Odd / Even fonctionne en cours - Équipe 2e manche de baseball',
       482: 'Odd / Even fonctionne en cours - Équipe 2e manche de baseball',
       483: 'Équipe va obtenir un Wicket en cours - 1er Inning?',
       484: 'Will Équipe Get A Wicket en cours - 1er Inning?',
       485: "Est-ce que l'équipe d'accueil Obtenir une Wicket en cours - 2ème manche de baseball?",
       486: 'Will Équipe Get A Wicket en cours - 2ème manche de baseball?',
       523: 'Moins de / Plus et les deux équipes marquent',
       524: '1er gagnant 7 Innings',
       525: '1er 7 Innings Moins de / Plus',
       526: '1er 7 Innings Handicap Asiatique',
       529: 'Odd / Even 2e mi-temps compris les heures supplémentaires',
       553: 'Moins de / Plus Pénalités Shootout Taken',
       554: '12 Shootout',
       555: 'Shootout Handicap Asiatique',
       556: 'Shootout Score correct',
       557: 'Moins de / Plus Shootout',
       558: 'Moins de / Plus Shootout - Team Accueil',
       559: 'Moins de / Plus Shootout - Équipe loin',
       560: "Pour l'équipe Prendre Dernière Penalty Shootout",
       563: "Course à l'inclusion des heures supplémentaires",
       564: '1X2 et moins / Plus de 5 manière',
       566: '10 Période Gagnant Domicile / Extérieur',
       567: '11e Gagnant Domicile / Extérieur',
       568: '12 Période Gagnant Domicile / Extérieur',
       569: '13 Période Gagnant Domicile / Extérieur',
       570: '14 Période Gagnant Domicile / Extérieur',
       571: '15 Période Gagnant Domicile / Extérieur',
       573: '16 Période Gagnant Domicile / Extérieur',
       574: '17 Période Gagnant Domicile / Extérieur',
       575: '18 Période Gagnant Domicile / Extérieur',
       576: '19 Période Gagnant Domicile / Extérieur',
       577: '10 Minutes Under / Over - Corners',
       578: '10 minutes Moins de / Plus - Cartes',
       579: 'coin suivant',
       586: 'Pour finir dans le Top Half',
       587: 'Pour finir dans la moitié inférieure',
       588: 'Pour terminer Bas',
       589: 'Non à la fin dans le Top 4',
       590: 'Reléguée',
       591: 'Non reléguée',
       592: 'Pour atteindre final',
       593: 'Pour terminer en bas 6',
       594: 'Être promu',
       595: 'Du premier but',
       596: 'Objectifs Range Équipe 1re période',
       597: 'Objectifs Plage Équipe 2e période',
       598: 'Objectifs Gamme Équipe 1ère période',
       599: 'Objectifs Portée Équipe 2e période',
       600: '1ère période Gagnant et les deux équipes marquent',
       601: 'HT / FT Double Chance',
       602: 'Score correct incluant les heures supplémentaires',
       603: 'Négatif HT / FT',
       604: 'Objectifs Range Équipe Les deux périodes',
       605: 'Objectifs Range équipe détruiront Périodes',
       606: 'Vainqueur et objectifs de type One',
       607: 'Double Chance et objectifs',
       608: 'Vainqueur et objectifs Type de Deux',
       609: 'Les deux équipes marquent et objectifs',
       610: 'Double Chance Mix',
       628: 'Pour finir dans le Top 7',
       629: 'Pour finir dans le Top 6',
       630: 'Pour finir dans le Top 3',
       631: 'Pour finir dans le Top 2',
       632: 'Pour finir dans le Top 4',
       633: 'Pour finir dans le Top 5',
       634: 'Odd / Même Rounds 1ère période',
       635: 'Odd / Même Rounds 2ème période',
       636: 'Odd / Même Rounds 3e période',
       637: 'Y aura-t Un As?',
       638: 'Y aura-t un couteau tuer?',
       639: '1ère journée Lauréat',
       640: '16e Lauréat',
       641: 'Y aura-t un couteau tuer? - 1ère période',
       642: 'Y aura-t un couteau tuer? - 2ème période',
       643: 'Y aura-t un couteau tuer? - 3e période',
       644: 'Y aura-t Un As? - 1ère période',
       645: 'Y aura-t Un As? - 2ème période',
       646: 'Y aura-t Un As? - 3e période',
       647: 'Y aura-t Heures supplémentaires? - 1ère période',
       648: 'Y aura-t Heures supplémentaires? - 2ème période',
       649: 'Y aura-t Heures supplémentaires? - 3e période',
       650: '16e Lauréat - 1ère période',
       651: '16e Lauréat - 2e période',
       652: '16e Lauréat - 3e période',
       653: '1ère journée Lauréat - 1ère période',
       654: '1ère journée Lauréat - 2ème période',
       655: '1ère journée Lauréat - 3e période',
       660: 'Durée du jeu',
       662: 'Odd / Même Rounds',
       663: '1ère Durée Période',
       664: '2 Durée Période',
       665: 'Durée 3 Période',
       666: '1ère période - 1er Roshan',
       667: '2ème période - 1er Roshan',
       668: '3e période - 1er Roshan',
       669: '1re période - 1er sang',
       670: '2e période - 1er sang',
       671: '3e période - 1er sang',
       672: '1ère période - 1er Tou',
       673: '2ème période - 1er Tou',
       674: '3e période - 1er Tou',
       675: '1ère période - 1er Caserne',
       677: '2ème période - 1er Caserne',
       678: '3e période - 1er caserne',
       679: '1ère période - 1er Tourelle',
       680: '2ème période - 1er Tourelle',
       681: '3e période - 1er Tourelle',
       682: 'Race To Kills - 1ère période',
       683: 'Race To Kills - 2e période',
       684: 'Race To Kills - 3e période',
       685: 'Les deux équipes de tuer un dragon - 1ère période',
       686: 'Les deux équipes de tuer un dragon - 2e période',
       687: 'Les deux équipes de tuer un dragon - 3e période',
       688: 'Les deux équipes de tuer un Inhibiteur - 1ère période',
       689: 'Les deux équipes de tuer un Inhibiteur - 2e période',
       690: 'Les deux équipes de tuer un Inhibiteur - 3e période',
       691: 'Odd / Even Kills',
       692: 'Odd / Even Kills - 1ère période',
       693: 'Odd / Even Kills - 2e période',
       694: 'Odd / Even Kills - 3e période',
       695: 'HT / FT et objectifs',
       697: 'Pour finir dans le Top 8',
       711: 'Joueur à marquer en tout temps',
       712: 'Premier joueur à marquer',
       713: 'Dernière joueur à marquer',
       714: 'Joueur à marquer 2 buts ou plus',
       715: 'Joueur à marquer 3 buts ou plus',
       727: '1ère période, les deux équipes marquent',
       728: '2ème période, les deux équipes marquent',
       729: '3ème période, les deux équipes marquent',
       730: '4e période, les deux équipes marquent',
       731: '1re période Équipe de marquer',
       732: '2e période Accueil équipe à marquer',
       733: '3e période Équipe de marquer',
       734: '4e période Équipe de marquer',
       735: '1re période Équipe de marquer',
       736: '2ème période Équipe de marquer',
       737: '3ème temps Equipe de marquer',
       738: '4ème temps Equipe de marquer',
       751: '1er semestre Race To',
       752: '2e mi-temps Race To - Heures supplémentaires compris',
       754: 'Jeu à Deuce',
       755: 'PROLONGATIONS Coins - Sous / Exactement / Plus',
       756: 'Sous / Sur- Coins PROLONGATIONS',
       757: 'Handicap Asiatique - Prolongations',
       758: '10 Minutes Under / Over - Free Kicks',
       759: '10 minutes Moins de / Plus - ins-Throw',
       760: '10 Minutes Under / Over - Kicks Objectif',
       762: 'Pour gagner le toss',
       763: 'Moins de / Plus Runs En match',
       765: 'La plupart Fours à marquer',
       766: 'Moins de / Plus - match Fours',
       767: 'Moins de / Plus - match Sixes',
       768: 'A cinquante à être marqué dans le match',
       769: 'Cent à être marqué dans le match',
       770: 'La plupart des sorties Run',
       791: 'Top Batteur - Team Accueil',
       792: 'Top Batteur - Équipe loin',
       793: 'Top Bowler - Team Accueil',
       794: 'Top Bowler - Équipe loin',
       795: 'Homme du match',
       806: 'Batteur à marquer une cinquantaine dans le match',
       808: 'Course à 10 Runs',
       809: 'Les plus hauts 1er 6 Overs Score',
       813: 'Joueur à marquer plus Sixes',
       814: 'Meilleur score individuel',
       819: 'Avoir un point de rupture dans le jeu',
       820: 'Race To Corners',
       822: 'Top Nouvelle équipe',
       824: 'Joueur à réserver',
       825: 'Joueur à être EXPULSÉ',
       834: 'Set Points supplémentaires',
       835: 'Asiatique Moins de / Plus',
       836: 'Asie du sous / sur la 1ère période',
       837: '1ère mi-temps Équipe victoire à zéro',
       838: '1ère mi-temps Équipe victoire à zéro',
       839: '1er semestre 1X2 Moins de / Plus',
       840: '1re période Équipe Win à zéro',
       841: '1re période Équipe à zéro victoire',
       842: 'Sous / Exactement / Over - Team Accueil',
       843: 'Sous / Exactement / Over - Équipe loin',
       844: 'Première équipe à marquer - Domicile / Extérieur',
       845: "Pour appeler l'équipe première sortie",
       846: 'Moins de / Plus Hits - Heures supplémentaires inclus',
       849: 'Moins de / Plus 5ème Inning',
       851: 'Équipe avec meilleur score demi',
       852: 'Soit à marquer 3 fois Unanswered',
       853: 'Moins de 12 ans / Plus',
       854: 'Équipe spéciale ou défensive Touchdown Marqués',
       855: 'Points Plage',
       859: '147 Break In match',
       861: 'Premier score Méthode 3 Way - Team Accueil',
       862: 'Premier score Méthode 3 Way - Équipe loin',
       863: 'Équipe avec Score Période la plus élevée -1X2',
       864: 'Première équipe Goalscorer',
       865: 'Dernière équipe Goalscorer',
       866: 'Jeux Handicap Asiatique',
       870: 'Premier score Méthode 6 Way Home / Total',
       878: 'Objectifs Gamme 3e période',
       879: 'Moins de / Plus Tries',
       880: 'Première mi-temps Corners - Sous / Exactement / Plus',
       881: 'Premier but Méthode',
       882: 'Nombre de points',
       891: 'Équipe à marquer plus longue Touchdown',
       892: 'Équipe à marquer plus longue Field Goal',
       893: 'La plus longue Touchdown A marqué',
       894: 'Shortest Touchdown A marqué',
       895: 'Champ le plus long But marqué',
       896: 'Temps du but Première équipe',
       897: 'Score de jeu après 2 Points',
       898: 'Score de jeu après 3 Points',
       899: 'Score de jeu après 4 points',
       900: 'Jeu de marquer',
       901: '1ère période Après plomb',
       902: '2ème période après plomb',
       903: '3e période après plomb',
       904: '4e période après plomb',
       905: '5 Après la période de plomb',
       906: 'Score après 4 Jeux',
       907: 'Score après 6 Jeux',
       908: 'Pour briser Servir dans Set',
       909: 'Pour gagner, en deux sets',
       910: 'Loin pour gagner en deux sets',
       911: 'Score après 2 jeux',
       912: 'Score après 3 Sets',
       914: 'Moins de / Plus Tirs Marqués - 1er semestre',
       915: 'Moins de / Plus Touchés A marqué - 1er semestre',
       916: "Sous / Sur l'équipe d'accueil Touchés - 1er semestre",
       917: 'Moins de / Plus Équipe extérieur Touchés - 1er semestre',
       920: 'Moins de / Plus Touchés',
       921: 'Sous / Exactement / Over - Touchés',
       922: 'Sous / Sur le terrain Objectifs',
       923: 'Sous / Exactement / Over - Tirs',
       924: 'Temps du premier terrain But marqué',
       925: 'Heure de la première Touchdown A marqué',
       926: 'Temps du premier Score',
       927: 'Équipe domicile Moins de / Plus Touchés',
       928: 'Équipe extérieur Moins de / Plus Touchés',
       929: 'Équipe domicile Objectifs du sous / sur le terrain',
       930: 'Équipe extérieur sous / sur le terrain Objectifs',
       932: 'Équipe à avoir First Challenge des entraîneurs',
       933: "Équipe de commettre d'abord accepté Penalty",
       934: 'A marqué la sécurité',
       935: 'Cartes européen Handicap',
       936: 'Asiatique Moins de / Plus de cartes',
       938: 'Moins de / Plus de cartes - Équipe Accueil',
       939: 'Moins de / Plus de cartes - Équipe loin',
       940: 'Heure de la première carte',
       941: 'Joueur suivant Score',
       942: 'Top Nationalité - Asie',
       943: 'Top Nationalité - Australie',
       944: 'Top Nationalité - européenne',
       945: 'Top Nationalité - Grande-Bretagne et en Irlande',
       946: 'Top Nationalité - Reste du Monde',
       947: 'Sous / As Plus',
       948: 'Sous / As Plus - Team Accueil',
       949: "Le nombre exact d'as",
       950: 'Moins de / Plus Aces - Équipe loin',
       951: "Correct Nombre d'as",
       955: '1X2 as',
       956: "Exact Nombre d'As - Team Accueil",
       957: "Le nombre exact d'as - Équipe loin",
       958: 'As Asian Handicap',
       959: 'Race To As',
       960: 'Moins de / Plus As - 1ère période',
       976: 'As 1X2 - 1ère période',
       982: 'As Asian Handicap - 1ère période',
       986: '12 As',
       989: 'Moins de / Plus Kills - 1ère période',
       990: 'Moins de / Plus Kills - 2e période',
       991: 'Moins de / Plus Kills - 3e période',
       992: 'Suivant Touchdown Scorer - Team Accueil',
       993: 'Suivant Touchdown Scorer - Équipe loin',
       994: 'Suivant Touchdown Scorer',
       995: 'Joueur à marquer Touchdown En tout temps',
       996: 'Joueur à marquer 2 ou plus Touchés',
       997: 'Joueur à marquer 3 ou plus Touchés',
       998: 'Premier match Corner - 12',
       999: 'Dernier match Corner - 12',
       1000: 'Nombre de jeux',
       1001: 'Moins de / Plus doubles fautes',
       1002: 'Moins de / Plus doubles fautes - Équipe loin',
       1003: 'Moins de / Plus doubles fautes - équipe Accueil',
       1004: 'Moins de / Plus doubles fautes - 1ère période',
       1010: '12 doubles fautes',
       1011: '12 doubles fautes - 1ère période',
       1013: 'Les défauts 1X2 Double',
       1016: 'Nombre exact de doubles fautes',
       1019: 'Nombre exact de doubles fautes - Équipe',
       1020: 'Nombre exact de doubles fautes - Équipe loin',
       1027: 'Correct Nombre de doubles fautes',
       1030: 'Temps du premier angle',
       1031: 'Coins multiples',
       1034: '20 Période Gagnant Domicile / Extérieur',
       1035: 'Période Gagnant 21th Domicile / Extérieur',
       1036: 'Période Gagnant domicile 22 / Total',
       1037: 'Période Gagnant domicile 23 / Total',
       1038: '24 Période Gagnant Domicile / Extérieur',
       1039: '25 Période Gagnant Domicile / Extérieur',
       1040: '26 Période Gagnant Domicile / Extérieur',
       1041: '27 Période Gagnant Domicile / Extérieur',
       1042: '28 Période Gagnant Domicile / Extérieur',
       1043: '29 Période Gagnant Domicile / Extérieur',
       1044: '30 Période Gagnant Domicile / Extérieur',
       1046: '32ème Période Gagnant Domicile / Extérieur',
       1047: '33ème Période Gagnant Domicile / Extérieur',
       1050: 'Course à 20 points',
       1051: 'Moins de / Plus 2ème mi-temps - équipe Accueil',
       1052: 'Moins de / Plus 2ème mi-temps - Équipe loin',
       1053: 'Asie du sous / sur 2e période',
       1054: 'Asie du sous / sur la 3e période',
       1055: 'Asiatique Moins de / Plus 4e période',
       1061: 'Asiatique Moins de / Plus incluant les heures supplémentaires',
       1062: 'Meilleur demi - équipe Accueil',
       1063: 'Meilleur score demi - Équipe loin',
       1065: 'Équipe domicile - Première Goalscorer',
       1066: 'Équipe domicile - Dernière Goalscorer',
       1067: 'Équipe extérieur - Premier buteur',
       1068: 'Équipe extérieur - Dernière Goalscorer',
       1069: 'Moins de / Plus Player Points',
       1070: 'Sous / Sur les blocs joueur',
       1071: 'Sous / Sur Assiste Joueur',
       1072: 'Moins de / Plus joueur Rebonds',
       1073: 'Moins de / Plus joueur Vole',
       1074: 'Moins de / Plus joueur revirements',
       1075: 'Moins de / Plus joueur 3 Points Made',
       1076: 'Moins de / Plus joueur 3 Points Tentatives',
       1077: 'Moins de / Plus joueur 2 Points Made',
       1078: 'Moins de / Plus Joueur 2 Points Tentatives',
       1079: 'Moins de / Plus joueur Lancers Francs',
       1080: 'Moins de / Plus joueur lancers francs Tentative',
       1081: '1X2 Cartons Jaunes',
       1082: 'Double Chance Cartons Jaunes',
       1083: 'Handicap asiatique Cartons Jaunes',
       1084: 'Score exact Cartons Jaunes',
       1085: 'Moins de / Plus de Cartons Rouges',
       1086: 'Sous / Exactement / Points de réservation Plus',
       1087: 'Sous / Exactement / Plus de Points de réservation - Équipe domicile',
       1088: 'Sous / Exactement / Plus de Points de réservation - Équipe loin',
       1089: 'Sous / Plus de joueurs Vole et blocs',
       1090: 'Moins de / Plus Player Points',
       1091: 'Sous / Sur Assiste Player et Rebonds',
       1092: 'Sous / Plus de Points Player et Rebonds',
       1093: 'Sous / Plus de points et Assiste joueur',
       1094: 'Joueur Double',
       1095: 'Triple Double joueur',
       1096: 'Sous / Exactement / Plus de Points de réservation - 1ère période',
       1100: 'Vainqueur du set',
       1101: 'Moins de / Plus de 180 \ s Dans un ensemble',
       1102: 'Set / Vainqueur Leg',
       1103: 'Set / LEG Total des Checkouts Moins de / Plus',
       1105: 'Sous / Plus de Total 180 \ s - Équipe domicile',
       1106: 'Moins de / Plus au total 180 \ s - Équipe loin',
       1112: 'Sous / Joueur Buts Plus',
       1115: 'Moins de / Plus Joueur Buts admis',
       1120: 'Moins de / Plus Rounds - 1ère période',
       1121: 'Moins de / Plus Rounds - 2e période',
       1122: 'Moins de / Plus Rounds - 3e période',
       1123: '4e période - 1er sang',
       1124: '5ème période - 1er sang',
       1125: 'Les deux équipes de tuer un dragon',
       1126: 'Les deux équipes Tuer un baron',
       1127: 'Les deux équipes Tuer Inhibiteur',
       1128: 'Moins de / Plus Barons Tué',
       1129: 'Moins de / Plus Barons Tué - 1ère période',
       1130: 'Moins de / Plus Barons Tué - 2e période',
       1131: 'Moins de / Plus Barons Tué - 3e période',
       1132: 'Moins de / Plus Dragons Tué',
       1133: 'Moins de / Plus Dragons Tué - 1ère période',
       1134: 'Moins de / Plus Dragons Tué - 2e période',
       1135: 'Moins de / Plus Dragons Tué - 3e période',
       1136: 'Moins de / Plus de 180 \ s',
       1137: '180 premiers',
       1138: 'dernière 180',
       1139: 'Moins de / Plus Score le plus élevé Commander',
       1140: 'La plupart des 180 \ s',
       1141: 'Moins de / Plus Score le plus élevé Commander - Équipe Accueil',
       1142: 'Moins de / Plus Score le plus élevé Commander - Équipe loin',
       1143: 'Premier joueur à marquer Touchdown',
       1144: 'Dernière joueur à marquer Touchdown',
       1145: 'Premier joueur à marquer Touchdown - Team Accueil',
       1146: 'Premier joueur à marquer Touchdown - Équipe loin',
       1147: 'Moins de / Plus Kills - 4e période',
       1148: 'Moins de / Plus Kills - 5ème période',
       1149: 'Kills Handicap Asiatique - 1ère période',
       1150: 'Kills Handicap Asiatique - 2ème période',
       1151: 'Kills Handicap Asiatique - 3e période',
       1152: 'Kills Handicap Asiatique - 4e période',
       1153: 'Kills Handicap Asiatique - 5ème période',
       1154: 'Sous / Exactement / Plus de 180 \ s',
       1155: 'Sous / Exactement / Total des 180 Plus d \ - Équipe domicile',
       1156: 'Sous / Exactement / Plus de Total 180 \ s - Équipe loin',
       1157: 'La plupart des Checkouts',
       1158: "Premier marqueur d'essais",
       1159: "Dernière marqueur d'essais",
       1160: "Chaque fois que marqueur d'essais",
       1162: 'Sous / Sur tous Détruit - 1ère période',
       1163: 'Sous / Sur tous Détruit - 2e période',
       1164: 'Sous / Sur tous Détruit - 3e période',
       1165: '1ère période - 1er Baron',
       1166: '2ème période - 1er Baron',
       1167: '3e période - 1er Baron',
       1168: '4e période - 1er Baron',
       1169: '5ème période - 1er Baron',
       1170: '1ère période - 1er dragon',
       1171: '2ème période - 1er dragon',
       1172: '3e période - 1er dragon',
       1173: '4e période - 1er dragon',
       1174: '5ème période - 1er dragon',
       1175: 'La plupart des Kills - 1ère période',
       1176: 'La plupart des Kills - 2e période',
       1177: 'La plupart des Kills - 3e période',
       1178: 'La plupart des Kills - 4e période',
       1179: 'La plupart des Kills - 5ème période',
       1180: 'La plupart des Kills - 6 Période',
       1181: 'La plupart des Kills - 7 Période',
       1182: 'Joueur à marquer 2 ou plus Trys',
       1183: 'Joueur à marquer 3 ou plus Trys',
       1184: 'Non pour gagner le Leauge',
       1185: 'Sous / Exactement / Plus incluant les heures supplémentaires - équipe Accueil',
       1186: 'Sous / Exactement / Plus incluant les heures supplémentaires - Équipe extérieur',
       1187: 'Sous / Exactement / Plus incluant les heures supplémentaires',
       1188: 'Moins de / Plus Remplacements',
       1189: 'Moins de / Plus Remplacements - 1ère période',
       1190: 'Dernier joueur à marquer Touchdown - Team Accueil',
       1191: 'Dernier joueur à marquer Touchdown - Équipe loin',
       1192: 'Premier joueur à frapper un As',
       1193: 'Moins de / Plus joueur Quarterback Yards Passing',
       1194: 'Moins de / Plus joueur Touchés Passing',
       1195: 'Moins de / Plus joueur le plus long col achèvement',
       1196: 'Moins de / Plus joueur Verges',
       1197: 'Moins de / Plus joueur Interceptions',
       1198: 'Moins de / Plus joueur Kicking Points',
       1199: 'Moins de / Plus joueur Rushing et Verges',
       1200: 'Moins de / Plus joueur verges au sol',
       1201: 'Moins de / Plus joueur le plus long Réception',
       1202: 'Moins de / Plus Receptions Joueur',
       1203: 'Sous / Sur Joueur Buts réussis',
       1204: 'Sous / Joueur Buts Plus de terrain Tentatives',
       1205: 'Sous / Sur Joueur Quarterback Passing Touchés',
       1206: 'Moins de / Plus Lecteur DVD récepteur achèvements de réception',
       1207: 'Moins de / Plus joueur Quarterback Terminé Laissez-passer',
       1208: 'Moins de / Plus joueur Rushing Touchés',
       1209: 'Sous / Sur les tentatives de réception du récepteur joueur',
       1210: 'Moins de / Plus joueur Quarterback Laissez-passer Tentative',
       1211: '1X2 Fautes',
       1212: 'Double Chance Fautes',
       1213: 'Moins de / Plus Fautes - 1ère période',
       1214: 'Moins de / Plus Fautes - 2e période',
       1215: 'Asian Handicap Fautes',
       1216: 'Moins de / Plus Fautes - Team Accueil',
       1217: 'Moins de / Plus Fautes - Équipe loin',
       1218: 'Moins de / Plus joueur Rush tentatives',
       1219: 'Moins de / Plus Touchés réceptionnaires des joueurs',
       1222: 'Moins de / Plus-Ins Throw - Team Accueil',
       1223: 'Moins de / Plus-Ins Throw - Équipe loin',
       1224: '1X2-Ins Throw',
       1225: 'Double Chance-Ins Throw',
       1226: 'Moins de / Plus-Ins Throw - 1ère période',
       1227: 'Jeter-ins handicap',
       1228: 'Handicap-ins Throw - 1ère période',
       1229: 'Sous / Plus de Tirs cadrés - Équipe Accueil',
       1230: 'Sous / Plus de Tirs cadrés - Équipe loin',
       1231: "Tirs d'Asie Handicap sur la cible",
       1232: 'Tirs Asian Handicap - Sur la cible 1ère période',
       1233: 'Sous / Plus de Tirs cadrés - 1ère période',
       1234: '1X2 Tirs cadrés',
       1235: 'Double Chance Tirs cadrés',
       1236: 'Moins de / Plus Shots total - Team Accueil',
       1237: 'Moins de / Plus Tirs au total - Équipe loin',
       1238: 'Double Chance Tirs au total',
       1239: '1X2 Tirs au total',
       1240: 'Handicap Asiatique Tirs au total',
       1241: 'Handicap Asie Tirs Total - 1ère période',
       1242: 'Moins de / Plus Shots au total - 1ère période',
       1243: 'Double Chance Corners',
       1244: 'Double Chance Coins - 1ère période',
       1245: 'Coins de tous les 4 coins du terrain',
       1246: 'Double Chance Cartons Jaunes - 1ère période',
       1247: '1X2 Cartons Jaunes - 1ère période',
       1248: 'Handicap Cartons Jaunes Asie - 1ère période',
       1249: 'Double Chance & Moins de / Plus',
       1250: 'Double Chance et les deux équipes de marquer',
       1251: '1ère période - 1er Inhibiteur',
       1252: '2ème période - 1er Inhibiteur',
       1253: '3e période - 1er Inhibiteur',
       1254: '4e période - 1er Inhibiteur',
       1255: '5ème période - 1er Inhibiteur',
       1256: 'Les deux équipes Tuer un Baron - 1ère période',
       1257: 'Les deux équipes Tuer un Baron - 2e période',
       1258: 'Les deux équipes Tuer un Baron - 3e période',
       1259: 'Les deux équipes Tuer un Baron - 4e période',
       1260: 'Les deux équipes Tuer un Baron - 5ème période',
       1261: 'Les deux équipes de tuer un dragon - 4e période',
       1262: 'Les deux équipes de tuer un dragon - 5ème période',
       1263: 'Les deux équipes pour tuer Inhibiteur - 4e période',
       1264: 'Les deux équipes de tuer un Inhibiteur - 5ème période',
       1265: 'Y aura-t Un As Next jeu?',
       1266: 'Y aura-t double erreur suivant jeu?',
       1267: 'Odd / Même Fautes',
       1268: 'Handicap asiatique Possession',
       1269: 'Double Chance Offsides',
       1270: 'Asian Handicap Offsides',
       1271: 'Moins de / Plus Offsides - Team Accueil',
       1272: 'Moins de / Plus Offsides - Équipe loin',
       1273: '1X2 Offsides',
       1274: 'Moins de / Plus Kills 1ère période - Équipe Accueil',
       1275: 'Moins de / Plus Kills 2e période - équipe Accueil',
       1276: 'Moins de / Plus Kills 3e période - équipe Accueil',
       1277: 'Moins de / Plus Kills 1ère période - Équipe loin',
       1278: 'Moins de / Plus Kills 2e période - Équipe loin',
       1279: 'Moins de / Plus Kills 3e période - Équipe loin',
       1280: 'Moins de / Plus Wides',
       1281: 'Sous / Canards Plus',
       1282: 'Moins de / Plus portillons',
       1283: 'Moins de / Plus match Fours - équipe Accueil',
       1284: 'Moins de / Plus match Sixes - Team Accueil',
       1285: 'Moins de / Plus match Fours - Équipe loin',
       1286: 'Moins de / Plus match Sixes - Équipe loin',
       1287: 'Moins de / Plus Extras',
       1288: 'Automne 1er Wicket - Team Accueil',
       1289: 'Automne 1er Wicket - Équipe loin',
       1290: "L'équipe de Top Batteur",
       1291: 'Moins de / Plus match Sixes Home Team - 1ère manche de baseball',
       1292: 'Moins de / Plus match Sixes Équipe - 1ère manche de baseball',
       1293: 'Moins de / Plus match Fours Équipe - 1er Inning',
       1294: "Sous / match Plus de Fours à l'extérieur de l'équipe - 1ère manche de baseball",
       1295: 'Sous / au-dessus Batteur',
       1296: '1ère manche 1er Plus Licenciement - Team Accueil',
       1297: '1er Inning 2 Plus Licenciement - Équipe',
       1298: '1er Inning 3 Plus Licenciement - Équipe',
       1299: '1er Inning 2 Plus Licenciement - Équipe loin',
       1300: '1er Inning 3 Plus Licenciement - Équipe loin',
       1301: '1ère manche de baseball 1er Plus Licenciement - Équipe loin',
       1302: 'Meilleur score Plus - Moins de / Plus',
       1303: '1er Inning 2 Plus de Moins de / Plus de - Équipe Accueil',
       1304: '1er Inning 3 Plus de Moins de / Plus de - Équipe Accueil',
       1306: '1er Inning 3 Plus de Moins de / Plus de - Équipe loin',
       1307: '1er Inning 2 Plus de Moins de / Plus de - Équipe loin',
       1309: 'Marge - Les équipes',
       1310: 'Marge - Les équipes incluant les heures supplémentaires',
       1312: 'Marge exacte',
       1314: 'Gagner Fourchettes de marge',
       1315: 'Marge exacte - Équipes',
       1316: 'Marge - Les équipes avec tout autre Score',
       1318: 'Fautes Handicap Asiatique - 1ère période',
       1319: 'Asian Handicap Hors-jeu - 1ère période',
       1320: 'Asian Handicap Hors-jeu - 2ème période',
       1321: 'Fautes Handicap Asiatique - 2ème période',
       1322: 'Tirs Asian Handicap - 2ème cible sur la période',
       1324: 'Moins de / Plus Offsides - 1ère période',
       1325: 'Moins de / Plus Offsides - 2e période',
       1326: 'première faute',
       1327: 'Deux premiers pointeur',
       1328: 'Trois premiers pointeur',
       1329: 'Deux derniers Pointer',
       1330: 'Trois dernières Pointer',
       1331: 'Dernière gratuit Throw A marqué',
       1332: 'Throw premier gratuit A marqué',
       1333: "Tout d'abord rebond",
       1334: "dernière Délai d'attente",
       1335: 'Marge équipes - 1ère période',
       1336: 'Marge équipes - 2e période',
       1337: 'Marge équipes - 3e période',
       1338: 'Marge équipes - 4e période',
       1339: 'Marge équipes - 5e période',
       1340: 'dernière Foul',
       1341: 'Moins de / Plus 10 Période',
       1342: 'Moins de / Plus 11e',
       1343: 'Moins de / Plus période du 12e',
       1344: 'Moins de / Plus 13 Période',
       1345: 'Moins de / Plus 14 Période',
       1346: 'Moins de / Plus 15 Période',
       1347: 'Moins de / Plus 16 Période',
       1348: 'Moins de / Plus 17 Période',
       1349: 'Sous / Sur 18 Période',
       1350: 'Moins de / Plus 19 Période',
       1351: 'Moins de / Plus 20 Période',
       1352: 'Moins de / Plus 21 Période',
       1353: 'Moins de / Plus 22 Période',
       1354: 'Sous / sur une période de 23',
       1355: 'Moins de / Plus 24 Période',
       1356: 'Moins de / Plus 25 Période',
       1357: 'Moins de / Plus 26 Période',
       1358: 'Moins de / Plus 27 Période',
       1359: 'Moins de / Plus 28 Période',
       1360: 'Moins de / Plus 29 Période',
       1361: 'Moins de / Plus 30 Période',
       1362: 'Moins de / Plus 31 Période',
       1363: 'Moins de / Plus 32e Période',
       1364: 'Moins de / Plus 33ème Période',
       1365: 'Moins de / Plus 34e Période',
       1366: 'Moins de / Plus 35 Période',
       1367: 'Handicap asiatique 11e',
       1368: 'Handicap asiatique 12 Période',
       1369: 'Handicap asiatique 13 Période',
       1370: 'Handicap Asiatique 14 Période',
       1371: 'Handicap Asiatique 15 Période',
       1372: 'Handicap Asiatique 16 Période',
       1373: 'Handicap Asiatique 17 Période',
       1374: 'Handicap Asiatique 18 Période',
       1375: '19 Handicap asiatique Période',
       1376: 'Handicap asiatique 20 Période',
       1380: 'Handicap asiatique 24 Période',
       1381: 'Handicap asiatique 25 Période',
       1382: 'Handicap Asiatique 26 Période',
       1383: 'Handicap asiatique 27 Période',
       1384: 'Handicap Asiatique 28 Période',
       1385: 'Handicap asiatique 29 Période',
       1386: 'Handicap Asiatique 30 Période',
       1390: 'Handicap Asiatique 34e Période',
       1391: 'Handicap Asiatique 35 Période',
       1392: 'Pour finir dans le Top 10',
       1393: 'Rester debout',
       1394: 'Handicap asiatique 10 Période',
       1395: 'Moins de / Plus Joueur Points Powerplay',
       1396: 'Moins de / Plus Player Plus / Moins',
       1397: 'Moins de / Plus Shots joueurs',
       1398: 'Top victorienne du Club',
       1399: 'Moins de / Plus Outs Run',
       1400: 'Moins de / Plus Cadres',
       1401: 'Top 20 Vainqueur',
       1402: '1er chef Round',
       1404: '1er tour Six Shooter - Groupe A',
       1406: '1er tour 3 balles',
       1407: '1er tour 18 trous Match-Ups',
       1408: 'Handicap match',
       1409: "Avec l'équipe Top Bowler",
       1410: 'Pour gagner le toss et le match',
       1411: 'Moins de / Plus Tourelles Détruite',
       1412: 'Sous / Tourelles Plus de Destroyed - 1ère période',
       1413: 'Moins de / Plus Tourelles Destroyed - 2e période',
       1414: 'Sous / Tourelles Plus Destroyed - 3e période',
       1415: 'Meilleur score après le 1er Plus',
       1416: 'Équipe domicile Moins de / Plus Runs - 1er au 6 Plus Plus',
       1417: 'Équipe extérieur Moins de / Plus Runs - 1er au 6 Plus Plus',
       1418: 'Équipe domicile Moins de / Plus Runs - 1er au 8 Plus Plus',
       1419: 'Équipe extérieur Moins de / Plus Runs - 1er au 8 Plus Plus',
       1420: 'Équipe domicile Moins de / Plus Runs - 1er cours au 10 Plus',
       1421: 'Équipe extérieur Moins de / Plus Runs - 1er cours au 10 Plus',
       1422: 'Chase Plus de 180 courses et équipe pour gagner',
       1423: 'Nombre de Runs marqués de la première balle',
       1424: 'Moins de / Plus match stumpings',
       1425: '1er Wicket Méthode',
       1426: 'Première balle du match',
       1427: 'Moins de / Plus - 1er Inning',
       1428: 'Top non victorienne du Club',
       1429: 'Vainqueur Grande Finale',
       1431: 'Atteindre la Grande Finale',
       1432: 'Non à la fin dans le Top 8',
       1434: 'Moins de / Plus Kills 4 Période - Équipe loin',
       1435: 'Moins de / Plus Kills 5 Période - Équipe loin',
       1436: '4 Moins de / Plus Kills Période - Équipe Accueil',
       1437: 'Moins de / Plus Kills 5 Période - Équipe Accueil',
       1438: "Sous / Surchauffe à l'aéroport (Celsius)",
       1439: 'Handicap Asiatique - Plein temps',
       1440: 'Sous / Sur la valeur de tous les numéros tirés',
       1441: 'Sous / Sur valeur de tous les nombres impairs Drawn',
       1442: 'Sous / Sur la valeur de tous les numéros Même Drawn',
       1443: 'Plus encore que des chiffres sont attirés par Odd',
       1444: 'Numéros consécutifs seront tirés au sort',
       1445: 'Sous / Exactement / Over - Nombre de Odd Balls Numéroté Drawn',
       1446: 'Sous / Exactement / Over - Nombre de balles paires Drawn',
       1447: 'Somme des 5 Bonus Balls',
       1448: 'Ballon Premier Bonus Drawn - Odd / Even',
       1449: 'Bonus dernière boule tirée - Odd / Even',
       1450: 'Première balle Bonus',
       1451: 'Boule Dernière Bonus',
       1452: 'Le plus bas de 5 balles Bonus',
       1453: 'Bonus Ball - Odd / Even',
       1454: 'Nombres identiques seront tirés au sort',
       1455: 'Plus grand nombre Drawn - Odd / Even',
       1456: 'Le plus bas Nombre Drawn - Odd / Even',
       1457: 'Ball 1 - Moins de / Plus',
       1458: 'Ball 1 - Odd / Even',
       1459: 'Ball 2 - Moins de / Plus',
       1460: 'Ball 2 - Odd / Even',
       1461: 'Ball 3 - Moins de / Plus',
       1462: 'Ball 3 - Odd / Even',
       1463: 'Boule 4 - Moins de / Plus',
       1464: 'Ball 4 - Odd / Even',
       1465: 'Boule 5 - Moins de / Plus',
       1466: 'Boule 5 - Odd / Even',
       1467: 'Boule 6 - Moins de / Plus',
       1468: 'Boule 6 - Odd / Even',
       1469: 'Boule 7 - Moins de / Plus',
       1470: 'Ball 7 - Odd / Even',
       1471: 'Ball 8 - Moins de / Plus',
       1472: 'Ball 8 - Odd / Even',
       1473: 'Ball 9 - Moins de / Plus',
       1474: 'Ball 9 - Odd / Even',
       1475: 'Boule 10 - Moins de / Plus',
       1476: 'Boule 10 - Odd / Even',
       1477: 'Boule 11 - Moins de / Plus',
       1478: 'Boule 11 - Odd / Even',
       1479: 'Ball 12 - Moins de / Plus',
       1480: 'Ball 12 - Odd / Even',
       1481: 'Boule 13 - Moins de / Plus',
       1482: 'Boule 13 - Odd / Even',
       1483: 'Boule 14 - Moins de / Plus',
       1484: 'Boule 14 - Odd / Even',
       1485: 'Boule 15 - Moins de / Plus',
       1486: 'Ball 15 - Odd / Even',
       1487: 'Boule 16 - Moins de / Plus',
       1488: 'Boule 16 - Odd / Even',
       1489: 'Boule 17 - Moins de / Plus',
       1490: 'Bille 17 - Odd / Even',
       1491: 'Boule 18 - Moins de / Plus',
       1492: 'Bille 18 - Odd / Even',
       1493: 'Boule 19 - Moins de / Plus',
       1494: 'Boule 19 - Odd / Even',
       1495: 'Boule 20 - Moins de / Plus',
       1496: 'Boule 20 - Odd / Even',
       1497: 'Au moins un des numéros tirés sécable est de 3',
       1498: 'Au moins un des numéros tirés par 4 sécable Est',
       1499: 'Au moins un des numéros tirés sécable est de 5',
       1500: 'Au moins un des numéros tirés sécable est de 7',
       1501: 'Au moins un des numéros tirés sécable est de 10',
       1502: 'Au moins un des numéros tirés sécable est de 15',
       1503: 'Au moins un des numéros tirés sécable est de 20',
       1504: 'Le plus grand nombre Drawn - Moins de / Plus',
       1505: 'Valeur totale des plus bas et plus grand nombre Drawn - Moins de / Plus',
       1506: 'Le plus bas Nombre Drawn - Moins de / Plus',
       1507: 'Différence entre le plus haut et le plus bas numéros tirés - Moins de / Plus',
       1508: 'Valeur totale des plus bas et plus grand nombre Dessiné - Odd / Even',
       1509: 'Différence entre le plus haut et le plus bas Numbers Drawn - Odd / Even',
       1510: 'Premier numéro tiré est supérieur au dernier numéro Drawn',
       1511: 'Dernier numéro Drawn - Odd / Even',
       1512: 'Première Nombre Drawn - Odd / Even',
       1513: 'Tous les numéros tirés Même',
       1514: 'Tous les numéros tirés Odd',
       1515: 'Nombre Pour dessiner',
       1520: '4e période - 1er Tourelle',
       1521: '5ème période - 1er Tourelle',
       1523: 'Moins de / Plus Barons Tué - 4e période',
       1524: 'Moins de / Plus Barons Tué - 5ème période',
       1525: 'Moins de / Plus Dragons Tué - 4e période',
       1526: 'Moins de / Plus Dragons Tué - 5ème période',
       1527: 'Sous / Tourelles Plus Destroyed - 4e période',
       1528: 'Sous / Tourelles Plus Destroyed - 5ème période',
       1529: '4 Durée Période',
       1530: '5 Durée Période',
       1531: 'Odd / Même Kills - 4e période',
       1532: 'Odd / Even Kills - 5ème période',
       1533: 'Moins de / Plus Inhibiteurs - 1ère période',
       1534: 'Moins de / Plus Inhibiteurs - 2e période',
       1535: "Température à l'aéroport dans la plage (Celsius)",
       1536: 'Sous / sur les cartes',
       1537: 'Équipe domicile pour gagner et moins / Plus de - Heures supplémentaires inclus',
       1538: "L'équipe à gagner et moins / Plus de - Heures supplémentaires inclus",
       1539: 'Lauréat',
       1540: 'Total des Jambes match Moins de / Plus',
       1541: 'Handicap rounds',
       1542: '1er Gagnant Leg',
       1543: "Tout d'abord pour gagner 3 Jambes",
       1546: '4e période - 1er Roshan',
       1547: '5ème période - 1er Roshan',
       1548: '4e période - 1er caserne',
       1549: '5ème période - 1er caserne',
       1550: '4e période - 1er Tou',
       1551: '5ème période - 1er Tou',
       1552: 'Asiatique Moins de / Plus Corners',
       1553: 'Les deux équipes marquent Nombre de points - 1ère période',
       1554: 'Les deux équipes marquent Nombre de points - 1er semestre',
       1555: 'Sous / Sur 5ème période - équipe Accueil',
       1556: "Moins de / Plus 5ème période - l'équipe extérieure",
       1558: 'Points Handicap Asiatique',
       1559: 'Vainqueur de la Coupe',
       1561: '1er Vainqueur 3 manches',
       1562: '1er 3 Innings Moins de / Plus',
       1563: 'Temps de notation',
       1564: "Temps de notation - L'équipe Accueil",
       1565: 'Temps de notation - Équipe loin',
       1566: 'Résultat match Temps',
       1567: 'Top Nationalité - Afrique du Sud',
       1568: 'Top Nationalité - Espagnol',
       1569: 'Top Nationalité - Irlande',
       1570: 'Top Nationalité - Amérique du Sud',
       1571: 'Top Nationalité - Canada',
       1572: 'Top Nationalité - Anglais',
       1573: 'Top Nationalité - Europe continentale',
       1575: 'Pour faire couper',
       1576: 'Miss Cut',
       1577: 'Top Nationalité - américaine',
       1578: 'Nationalité du vainqueur',
       1579: 'Points Handicap Asiatique - 1ère période',
       1580: 'Points Handicap Asiatique - 2ème période',
       1581: 'Points Handicap Asiatique - 3e période',
       1583: 'Top Team Bowler 1er Inning - Team Accueil',
       1584: 'Top Team Bowler 1er Inning - Équipe loin',
       1585: 'Top Team Batteur 1er Inning - Team Accueil',
       1586: 'Top Team Batteur 1er Inning - Équipe loin',
       1587: 'Y aura-t un as point suivant',
       1589: 'Y aura-t un point suivant Double défaut',
       1591: 'Moins de / Plus - 1er Plus',
       1592: 'Pour gagner la Ligue',
       1593: 'Pour Win Division',
       1594: 'La plupart des Hits - Heures supplémentaires inclus',
       1595: 'Battez-vous pour la distance',
       1596: 'Quand le combat Fin',
       1597: 'lutte Résultat',
       1598: "Tour d'enchères",
       1599: 'Top 1er Hit',
       1600: 'Bas 1er Hit',
       1601: 'Top 1ère manche',
       1602: 'Bas 1er Run',
       1603: 'Les plus visités dans le 1er Inning',
       1605: 'Équipe avec meilleur score Inning',
       1606: 'Les deux équipes de marquer 2 buts ou plus',
       1607: 'Les deux équipes de marquer 3 buts ou plus',
       1608: "L'équipe de gagner la plupart des périodes",
       1609: 'Handicap Plein temps Asie - 1ère période',
       1610: 'Handicap Plein temps Asie - 2ème période',
       1611: 'Handicap Plein temps Asie - 3e période',
       1612: 'MVP',
       1614: 'Pour Win Conférence',
       1615: 'gagner Conférence',
       1617: 'Saison Spécial joueur',
       1618: '1er 5 Innings Vainqueur - 12',
       1619: '1re période - Mi-temps Vainqueur Domicile / Extérieur',
       1620: '1ère période - Mi-temps Handicap Asiatique',
       1621: '2ème période - Mi-temps Vainqueur Domicile / Extérieur',
       1622: '2ème période - Mi-temps Handicap Asiatique',
       1624: 'Moins de / Plus joueur Runs',
       1625: 'Moins de / Plus Simple joueur',
       1626: 'Moins de / Plus joueur Home Runs',
       1627: 'Sous / Joueur Plus de bases volées',
       1628: 'Sous / Sur Doubles Joueur',
       1629: 'Moins de / Plus joueur Triples',
       1630: 'Pitcher pour enregistrer la victoire',
       1631: 'Moins de / Plus Player Total Bases',
       1635: 'Sous / Sur Runs Pitcher Earned',
       1636: 'Sous / Sur Hits Pitcher admis',
       1637: 'Sous / Sur Pitcher fiascos',
       1638: 'Classement par Equipe',
       1639: 'Classification des Jeunes Cavaliers',
       1640: 'Classement par points',
       1641: 'Roi des Montagnes',
       1642: 'Étape 1',
       1649: 'Tour le plus rapide',
       1650: 'Car gagner',
       1651: 'Premier pilote à Retire',
       1652: 'Qualification Gagner voiture',
       1653: 'Le plus rapide dans la pratique 1',
       1654: 'Points Terminer',
       1655: 'plus rapide Qualifier',
       1657: 'étape 3',
       1659: 'Premier constructeur retraite',
       1660: 'Grille Position du gagnant',
       1661: 'Sécurité Période voiture pendant la course',
       1662: 'Nombre de pilotes classés',
       1663: 'Sécurité virtuelle Période voiture pendant la course',
       1664: 'Score trou',
       1667: 'Pour gagner du trou',
       1670: 'Tied match',
       1672: 'Moins de / Plus objectifs - 2e mi-temps',
       1673: 'Odd / Même Rounds 4 Période',
       1674: 'Odd / Même Rounds 5 Période',
       1675: '2e chef ronde',
       1676: 'Les deux équipes Score - Heures supplémentaires compris',
       1677: 'Dernière équipe à marquer - Including Overtime',
       1678: 'Top Nationalité - Allemand',
       1679: 'Top Nationalité - Autriche',
       1680: 'Top Nationalité - Néo-Zélandais',
       1681: 'En haut à gauche Joueur Handed',
       1682: 'Top amateur',
       1683: 'Top Nationalité - Coréen',
       1684: 'Top Nationalité - Scandinavian',
       1685: 'Top Nationalité - Français',
       1686: 'Vainqueur Top Ancien',
       1687: 'Top Nationalité - Swede',
       1688: 'Top Nationalité - Japonais',
       1689: 'Top Nationalité - Scotsman',
       1690: 'Top Nationalité - Belgique',
       1691: '4ème européen Handicap Période',
       1692: 'Handicap européen de la 3e période',
       1693: 'Handicap européen 2e période',
       1694: 'Européen Handicaps 1ère période',
       1695: 'Score Round',
       1696: 'Tournoi de Score',
       1697: 'Score leader après la première ronde',
       1698: 'Hit The Fairway Avec coup de départ',
       1699: 'Hit vert dans le règlement',
       1700: '3e chef ronde',
       1702: 'Moins de / Plus joueur Passing tentatives',
       1704: 'Touché sur la 1ère période',
       1705: 'Touché au 2ème période',
       1706: 'Touchdown sur la 3e période',
       1707: 'Touchdown le 4 Période',
       1708: 'Sous / Exactement / Over - Équipe Touchés',
       1709: 'Sous / Exactement / Over - Équipe Touchés',
       1710: 'Sous / Exactement / Over - équipe Accueil 1er semestre Touchés',
       1711: 'Sous / Exactement / Over - Équipe du 1er semestre Touchés',
       1712: '16e Lauréat - 4e période',
       1713: '16e Lauréat - 5ème période',
       1714: 'Sous / Sur tous Détruit - 4e période',
       1715: 'Sous / Sur tous Détruit - 5ème période',
       1722: 'Réglez sur Terminer pour Nil',
       1723: 'Moins de / Plus Kills - 1ère période - 1er tour',
       1724: 'Moins de / Plus Kills - 2ème période - 1er tour',
       1728: 'Les deux équipes Score - PROLONGATIONS',
       1729: 'Double Chance - Temps supplémentaire',
       1730: 'Est-ce que le jeu Go Super Over?',
       1731: 'Double Chance 5 Période',
       1732: 'Double Chance 6 Période',
       1733: 'Double Chance 7 Période',
       1734: 'Double Chance 8 Période',
       1736: 'Nombre de Runs 1er Inning',
       1737: 'Nombre de pistes 2e manche de baseball',
       1738: 'Nombre de Runs 3 Inning',
       1739: 'Nombre de pistes 4 Inning',
       1740: 'Nombre de pistes 5 Inning',
       1741: 'Nombre de pistes 6 Inning',
       1742: 'Nombre de pistes 7th Inning',
       1743: 'Nombre de pistes 8 Inning',
       1744: 'Une Cinquante à marqués en 1ère Innings',
       1745: 'Automne 1er Wicket',
       1747: 'Next Man Out',
       1748: 'match terminé',
       1749: 'A marqué siècle',
       1750: 'Siècle A marqué - 1ère manche de baseball',
       1752: "Première équipe à l'extérieur Licenciement 6 voies",
       1753: 'Top Match Batteur',
       1754: 'Pour une pénalité Note',
       1758: "6e homme de l'année",
       1760: "Rookie de l'année",
       1761: 'MVP de la saison régulière',
       1763: 'Batteur à marquer un cent au 1er inning',
       1765: '9 Dart Terminer dans le match',
       1766: 'Temps de premier essai',
       1767: 'Heure de la dernière Try',
       1770: '9 Dart Terminer dans le match - Équipe',
       1771: '9 Dart Terminer dans le Match - Loin',
       1772: 'Top Run Scorer',
       1773: 'Top Run Scorer - Team Accueil',
       1774: 'Top Run Scorer - Équipe loin',
       1775: 'Équipe de Top Run Scorer',
       1776: 'Moins de / Plus Punts',
       1777: 'Moins de / Plus Sacks',
       1778: 'Moins de / Plus 1st Downs',
       1779: '1ère équipe pour obtenir un premier jeu',
       1780: '1er semestre sous / sur le terrain - Objectifs Équipe domicile',
       1781: '1er semestre sous / sur le terrain Objectifs - Équipe loin',
       1782: 'Sous / Exactement / Plus de - Tirs 1er semestre',
       1783: 'Sous / Sur Touchés 1ère période',
       1784: 'Moins de / Plus Pénalités acceptées',
       1785: 'La plupart des Kills dans le match',
       1786: 'Equipe la plus Kills dans le match',
       1787: 'Moins de / Plus Passe tentatives',
       1788: 'Moins de / Plus achèvements Passe',
       1789: 'Moins de / Plus Passing Yards',
       1790: 'Moins de / Plus Rush tentatives',
       1791: 'Moins de / Plus Rush Yards',
       1792: 'Moins de / Plus joueur Filières et aider',
       1794: 'Équipe Punt Première',
       1795: '1ère période 1X2 Moins de / Plus',
       1796: '1X2 Assiste',
       1797: 'Double Chance Assiste',
       1798: 'Équipe pour diriger après la période Chaque',
       1799: 'Équipe avec la plupart des Yards Passing',
       1800: 'Equipe la plus Rushing Yards',
       1801: 'Conversion réussie 2 points',
       1802: 'Moins de / Plus passes de touché',
       1803: 'Sous / Sur le terrain Objectifs 1re période',
       1804: 'Score en dernier 2 minutes de la 1ère mi-temps',
       1805: "Équipe de réception d'ouverture Kick Off",
       1806: 'Moins de / Plus Équipe Touchés - 1ère période',
       1807: "Moins de / Plus loin de l'équipe Touchés - 1ère période",
       1808: "Sous / Exactement / Plus de l'équipe d'accueil Touchés - 1ère période",
       1809: "Sous / Exactement / Équipe extérieur Plus Touchés - 1ère période",
       1810: "Sous / Sur le terrain Équipe Objectifs - 1ère période",
       1812: "Sous / Sur le terrain à l'extérieur de l'équipe Buts - 1ère période",
       1813: "Loin pour gagner l'équipe TRIMESTRIELLEMENT",
       1814: 'Équipe domicile pour gagner chaque trimestre',
       1815: 'Première offensive Jouer du jeu',
       1816: 'Pour Win trimestre',
       1817: 'Pour atteindre la finale',
       1818: 'Accueil à marquer 25 buts',
       1819: 'Accueil à marquer 30 buts',
       1820: 'Équipe pour égaliser 25 Buts',
       1821: 'Équipe pour égaliser 30 Buts',
       1822: 'Équipe domicile pour gagner HALF',
       1823: "L'équipe pour gagner HALF",
       1824: "Stade d'élimination",
       1825: 'Moins de / Plus Assiste',
       1826: 'Moins de / Plus Rebonds',
       1827: 'Moins de / Plus équipe Accueil Assiste',
       1828: "Sous / extérieur Plus de Assiste l'équipe",
       1829: 'Moins de / Plus Équipe Rebonds',
       1830: 'Moins de / Plus Équipe Rebonds',
       1831: '1X2 3 Points Made',
       1832: 'Moins de / Plus 3 Points Made',
       1833: 'Moins de / Plus Équipe 3 Points Made',
       1834: 'Moins de / Plus loin 3 Points Équipe Made',
       1835: 'Handicap Asiatique 3 Points Made',
       1841: 'premières remplacements',
       1842: '2e but dans le match',
       1843: '3e but dans le match',
       1846: '60 Résultat Minute',
       1847: '75 Résultat Minute',
       1848: 'But marqué dans les 5 premières minutes?',
       1849: 'Première remise en jeu dans le match',
       1850: 'Dessiner dans HALF',
       1851: 'Dirigée but dans le match?',
       1852: 'Sous / Plus de Minutes Objectif',
       1853: 'Moins de / Plus Tie Break dans le match',
       1854: 'Moins de / Plus de points Pause',
       1855: 'Moins de / Plus Points Pause Équipe',
       1856: 'Sous / Brisez Points Équipe loin',
       1857: 'Moins de / Plus Meilleur quart-',
       1858: 'Moins de / Plus bas quart-',
       1859: 'La plupart des Tries 3-Way',
       1860: '10 minutes Marge 5-Way',
       1861: "La plupart des pertes d'équipe",
       1862: 'Un sous marqueront dans le jeu',
       1863: 'Gagnez toutes les périodes - Équipe domicile',
       1864: 'Gagnez toutes les périodes - Équipe loin',
       1865: 'Les 10 premières minutes pour Moins de / Plus Tries',
       1866: '15 Minutes Under / Over - Corners',
       1867: '2 Buts en rang par équipe',
       1868: '3 Buts en rang par équipe',
       1869: 'La plupart des Tries convertis',
       1872: 'Finaliste unseeded?',
       1873: 'Wild Card pour atteindre final?',
       1874: 'Gagner demi - Tennis Outright',
       1875: 'Quartier Gagner - Tennis Outright',
       1881: 'Trou en un',
       1882: 'Y aura-t un play-off?',
       1883: '72 trous match Ups',
       1884: '54 trous match Ups',
       1885: 'Numéro 1 Draft Pick',
       1886: '1X2 Double Chance',
       1887: 'Équipe domicile pour gagner \\ Draw et les deux équipes de marquer',
       1888: "L'équipe pour gagner \\ Draw et les deux équipes de marquer",
       1889: 'Marge - 1er semestre',
       1891: 'Ligue américaine Cy Young',
       1892: 'Ligue américaine MVP',
       1893: 'Ligue nationale Cy Young',
       1894: 'Ligue nationale MVP',
       1895: 'Pitcher pour enregistrer la saison régulière La plupart des Victoires',
       1896: 'Joueur défrayé la saison régulière La plupart des coups de circuit',
       1897: 'Will Jeu Décidé dans le temps supplémentaire?',
       1898: 'Saison régulière% Gagner',
       1900: 'Joueur Meilleur buteur',
       1901: 'Moins de / Plus Filières',
       1902: 'Numéro 2 Draft Pick',
    }
};
