import * as Actions from '../actions/actionTypes';

export const initialState = {
    bannerImages:
        [{ id: 1, image_url: '/images/promotion/banner-7.png', url: 'casino/casino-welcome-offer', title: 'Casino Welcome Offer', description: 'Join us today and get a 100% bonus of up to €200 and 50 free spins to start your jackpotCT casino adventure!' },
        { id: 2, image_url: '/images/promotion/banner-8.png', url: 'casino/playson-egg-hunt', title: 'The April Egg Blast 80k ', description: ' Play the selected games to find hidden eggs worth €80,000!' },
        { id: 3, image_url: '/images/promotion/banner-9.png', url: '/loyalty', title: 'Loyalty System', description: 'We want to make sure players are always rewarded when playing at jackpotCT!' },
        { id: 4, image_url: '/images/promotion/banner-9.png', url: 'casino/cashback-weekender', title: 'The Weekend Cashback Booster', description: `We're putting a 15% cashback into your pockets every Monday!` },],
    findMore: false,
};

const promotionReducer = function (state = initialState, action) {
    switch (action.type) {
        // case Actions.GET_BANNER: {
        //     return {
        //         ...state,
        //         loading: action.value,
        //     };
        // }

        default:
            return state;
    }
};

export default promotionReducer;