export const it = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports : 'Gli sport',
    live : 'VIVERE',
    today : 'Oggi',
    inplay : 'in gioco',
    upcoming : 'Prossime',
    tomorrow : 'Domani',
    highlights : 'Punti salienti',
    results : 'risultati',
    lastMinute : 'Ultimo minuto',
    liveCasino : 'Casinò dal vivo',
    coinPayments: 'Coin Payments',
    casino : 'Casinò',
    myBets : 'Le mie scommesse',
    home : 'Casa',
    login : 'Login',
    signup : 'Iscriviti',
    betslip : 'Multiplo',
    search : 'Ricerca',
    searchHere : 'Cerca qui',
    transaction : 'Transazione',
    changePassword : 'Cambia la password',
    logout : 'Disconnettersi',
    single : 'Singolo',
    multiple : ' Multiplo',
    possibleWin : 'Vittoria possibile',
    amount : 'Quantità',
    ok : 'Ok',
    totalOdds : 'Quota totale',
    odd : 'Dispari',
    totalPossibleWin : 'Totale vittoria possibile',
    placeBet : 'Piazza scommesse',
    fastBet:'Scommessa veloce',
    fast : 'Veloce',
    bet : 'scommessa',
    username : 'Nome utente',
    password : "Parola d'ordine",
    stayLoggedIn : 'Rimani connesso',
    all : 'Tutti',
    'in game' : 'In gioco',
    won : 'Ha vinto',
    lost : 'Perduto',
    refund : 'Rimborso',
    date : 'Data',
    winAmount : 'ammontare',
    stake : 'Palo',
    pick : 'scegliere',
    myAccount : 'Il mio conto',
    football : 'Calcio',
    basketball : 'Pallacanestro',
    iceHockey : 'Hockey su ghiaccio',
    tennis : 'Tennis',
    volleyball : 'Pallavolo',
    boxing : 'Boxe',
    americanFootball: 'Football americano',
    baseball: 'Baseball',
    hockey: 'Hockey',
    tableTennis: 'Ping-pong',
    badminton: 'Badminton',
    cricket: 'Cricket',
    futsal: 'Futsal',
    golf: 'Golf',
    handball: 'Palla a mano',
    rugbyLeagues: 'Leagues di rugby',
    pickCanNotBeCombined : 'Questa scelta non è cumulabile',
    matchStopped : 'partita interrotta',
    favorites : 'Preferiti',
    cashout : 'Incassare',
    'in progress' : 'In corso',
    rejected : 'Respinto',
    tip : 'Mancia',
    noGoal : 'No Goal',
    yes : 'sì',
    over : 'Al di sopra di',
    under : 'Sotto',
    even : 'Anche',
    areYouSureWantCashout : 'Sei sicuro che si desidera Cashout?',
    noGoal : 'No',
    bonus : 'indennità',
    termsAndConditon : 'Termini e Condizioni',
    aboutUs: 'Chi siamo',
    contactUs: 'Contattaci',
    payments: 'Pagamenti',
    howPlacebet: 'Come posizionare Scommetti',
    deposit: 'Depositare',
    credit: 'Credito',
    debit: 'Addebito',
    withdraw : 'ritirarsi',
    totalOdd : 'totale Odd',
    totalStake : 'Puntata totale',
    topGames : 'Top Giochi',
    allGames : 'Tutti i giochi',
    canNotBeCombined : 'Questo ritiro non è cumulabile',
    betSettled : 'Bet Settled',
    betSuspended : 'Bet sospeso',
    insufficientBalance : 'Equilibrio insufficiente',
    oldPassword : 'vecchia password',
    newPassword : 'nuova password',
    confirmPassword : 'Conferma la nuova password',
    change : 'Modificare',
    fromDate : 'dalla Data',
    toDate : 'Ad oggi',
    activityType : 'Tipo di attività',
    show : 'Mostrare',
    gameType : 'Tipo di gioco',
    repeatBet : 'Ripetere Bet',
    someThingWrong : 'Qualcosa è andato storto',
    status : 'Stato',
    coupon : 'buono',
    acceptOddChangesText : 'È necessario accettare i cambiamenti per essere in grado di piazzare una scommessa',
    turnOffAway : 'Spegnere comunque',
    acceptOddChanges : 'Accetta modifiche dispari',
    maxWinAmount : 'importo massimo vittoria',
    maxOdd : 'Max dispari',
    dateTime : 'Appuntamento',
    type : 'genere',
    refreshCashout : 'Refresh Cashout',
    vendor : 'venditore',
    stakeLowerThen1 : 'La quota deve essere inferiore a',
    stakeGreaterThen1 : 'La quota deve essere maggiore di',
    ESports: 'Esports',
    KSports: 'Ksports',
    promotion: 'Promozione',
    customerServicePage: 'Pagina del servizio clienti',
    decimal: 'Decimale',
    fraction: 'Frazione',
    americanOdds: 'Odds americani',
    nothingFound: 'Non abbiamo trovato nulla',
    noLiveMatch: 'Nessuna partita dal vivo è disponibile',
    sportBook: 'Libro sportivo',
     marketNames: {
        1: ' 1x2',
        2: ' Under / Over',
        238: ' partita rimanente',
        59:'prossimo obiettivo',
        7: ' doppia Chance',
        52: ' Draw No Bet',
        226: ' 12 compresi gli straordinari',
        13: ' European Handicap',
        4: 'HT / FT',
        17: ' Segnano entrambe le squadre',
        59: ' prossimo obiettivo',
        28: ' Under / Over compresi gli straordinari',
        247: ' Rimanendo 1 ° semestre',
    },
    tabNames: {
        'All':'Tutte',
       'Main Markets': 'Mercati principali',
        'Totals': 'Totali',
        '1st Half': '1 ° semestre',
        '2nd Half': '2 ° tempo',
        'Home Team': 'Squadra di casa',
        'Away Team': 'squadra in trasferta',
        'All Periods': 'tutti i periodi',
         'Results':'Risultati',
        'Home / Away': 'Lontano da casa',
    },
    markets: {
        2:  '  Under / Over',
        3:  '  Asian Handicap',
        5:  '  Pari e dispari',
        6:  '  Punteggio corretto',
        7:  ' doppia Chance',
        9:  '  Risultato esatto 1 ° Periodo',
        11:  ' Angoli totali',
        12:  ' Totale falli commessi',
        13:  ' European Handicap',
        16:  ' Prima squadra a segnare',
        17:  ' Segnano entrambe le squadre',
        19:  ' prima scheda',
        21:  ' Under / Over 1 ° Periodo',
        22:  ' Posizione squadra a segnare',
        23:  ' Squadra in casa a punteggio',
        25:  ' Doppia Chance Halftime',
        29:  ' Sotto giri / Oltre',
        30:  ' Under / Over Corners - Squadra in casa',
        31:  ' Under / Over Corners - Team Lontano',
        34:  ' Squadra in casa a segno in entrambi i tempi',
        35:  ' Posizione squadra a segno in entrambi i tempi',
        41:  ' 1 ° Periodo Vincitore',
        42:  ' 2 ° periodo Vincitore',
        43:  ' 3 ° periodo Vincitore',
        44:  ' 4 ° periodo Vincitore',
        45:  ' Under / Over 2 ° periodo',
        46:  ' Under / Over 3 ° periodo',
        47:  ' Under / Over 4 ° periodo',
        48:  ' Under / Over 5 ° Periodo',
        49:  ' 5 ° Periodo Winner',
        50:  ' 1X2 compresi gli straordinari',
        51:  ' Pari / Dispari compresi gli straordinari',
        52:  ' 12',
        53:  ' Asian Handicap Halftime',
        55:  ' Prima squadra che segnerà 1 ° Periodo',
        56:  ' Ultima squadra a segnare',
        57:  ' Ultima squadra a segnare Halftime',
        59:   ' prossimo obiettivo',
        61:   ' European Handicap Halftime',
        62:   ' Pari / Dispari Halftime',
        63:   ' 12 Halftime',
        64:   ' Asian Handicap 1 ° Periodo',
        65:   ' Asian Handicap 2 ° periodo',
        66:   ' Asian Handicap 3 ° periodo',
        67:   ' Asian Handicap 4 ° periodo',
        68:   ' Asian 5 ° Periodo Handicap',
        69:   " Sarà l'Overtime gioco Go To?",
        70:   ' Più alto punteggio Periodo',
        71:   ' Più alto punteggio Metà',
        72:   ' 1 ° Periodo pari / dispari',
        73:   ' 2 ° periodo pari / dispari',
        74:   ' 3 ° periodo pari / dispari',
        75:   ' 4 ° periodo pari / dispari',
        76:   ' 5 ° Periodo pari / dispari',
        77:   ' Under / Over Halftime',
        78:   ' Che segna il maggior Sixes',
        79:   ' Sarà una pena essere assegnato?',
        80:   " primo calcio d'angolo",
        81:   " all'ultima curva",
        82:   ' Sarà un cartellino rosso verrà mostrato?',
        83:   ' Per mantenere inviolata',
        84:   ' Per vincere entrambi i tempi',
        85:   ' Per vincere da dietro',
        86:   ' Vincerà con zero',
        88:   ' Qualificare',
        89:   ' Sarà al primo tentativo essere convertito?',
        90:   ' Sarà partita sarà decisa nel 5 ° set?',
        91:   ' In Che primo tempo gol saranno segnati?',
        92:   ' Prima squadra che segnerà 2 ° tempo',
        95:   ' angoli Handicap',
        96:   ' Posizione squadra vincerà con zero',
        97:   ' Squadra in casa vincerà con zero',
        98:   ' Squadra in casa di mantenere un Clean Sheet',
        99:   ' Posizione squadra di mantenere un Clean Sheet',
        100:   ' Risultato esatto 2 ° periodo',
        101:   ' Under / Over - Squadra in casa',
        102:   ' Under / Over - Team Lontano',
        104:   ' Posizione squadra per vincere entrambi i tempi',
        105:   ' Squadra in casa per vincere entrambi i tempi',
        106:   ' Squadra in casa per vincere da dietro',
        107:   ' Posizione squadra per vincere da dietro',
        108:   ' Squadra in casa a segnare da calcio di rigore',
        109:   ' Posizione squadra a segnare da calcio di rigore',
        110:   ' Posizione squadra per vincere almeno la metà',
        111:   ' Squadra in casa per vincere almeno la metà',
        113:   ' Entrambe le squadre 1 ° semestre',
        114:   ' Qualsiasi giocatore a segnare almeno 2 Gol',
        117:   ' Qualsiasi giocatore a segnare almeno 3 gol',
        122:   ' Prima squadra che ha segnato per vincere la partita',
        123:   ' Posizione squadra che segnerà Prima e vincere la partita',
        124:   ' Squadra in casa che segnerà Prima e vincere la partita',
        128:   ' Numero dei gol',
        129:   ' Under / Over Corners - 1 ° semestre',
        132:   ' Sotto Shots / Oltre target',
        133:   ' Under / Over Totale Shots',
        134:   ' Numero dei gol 1 ° semestre',
        136:   ' Under / Over Fuorigioco',
        137:   ' Vittoria in casa No Bet',
        138:   ' Vittoria in trasferta No Bet',
        139:   ' In cui metà della squadra di casa segnerà primo goal?',
        140:   ' In cui metà Posizione squadra segnerà primo goal?',
        143:   ' In cui metà della squadra di casa segnerà più goal?',
        144:   ' In cui metà Posizione squadra segnerà più goal?',
        145:   ' Squadra in casa Numero di gol Nel 1 ° tempo',
        146:   ' Posizione squadra Numero di gol Nel 1 ° tempo',
        147:   ' Posizione squadra Numero di gol Nel 2 ° tempo',
        148:   ' Squadra in casa Numero di gol Nel 2 ° tempo',
        149:   ' Squadra in casa Numero di gol',
        150:   ' Posizione squadra Numero di gol',
        151:   ' Doppia Chance 2 ° tempo',
        153:   ' Under / Over 1 ° Periodo - Squadra in casa',
        154:   ' Under / Over 2 ° periodo - Squadra in casa',
        155:   ' Under / Over 1 ° Periodo - Team Lontano',
        156:   ' Under / Over 2 ° periodo - Team Lontano',
        157:   ' Under / Over Ammonizioni - 1 ° semestre',
        158:   ' Under / Over Ammonizioni',
        159:   ' Sarà un tie-break nel match?',
        160:   ' Vincitore della corsa',
        161:   ' 10 Risultato Minute',
        162:   ' 10 Minute Under / Over',
        163:   ' Numero dei gol nel 2 ° tempo',
        164:   ' Sarà un tie-break nel primo set?',
        165:   ' In Gruppi / Over',
        166:   ' Under / Over Games',
        168:   ' Sarà un Autorete?',
        169:   ' A segno in entrambi i tempi',
        170:   ' Pari / dispari Corners',
        171:   ' Per vincere due metà',
        175:   ' Sarà un tie-break nel secondo set?',
        176:   ' Sarà un tie-break nel terzo set?',
        177:   ' Sarà un tie-break nel quarto set?',
        178:   ' Sarà un tie-break nel quinto set?',
        180:   ' Under / Over rimesse laterali',
        181:   ' Under / Over Ammonizioni - Squadra in casa',
        182:   ' Under / Over Ammonizioni - Squadra in casa 1 ° semestre',
        183:   ' Under / Over Ammonizioni - Squadra in casa 2 ° tempo',
        184:   ' Under / Over Ammonizioni - Team Lontano',
        185:   ' Under / Over Ammonizioni - Posizione squadra 1 ° semestre',
        186:   ' Under / Over Ammonizioni - Posizione squadra 2 ° tempo',
        187:   ' Under / Over Ammonizioni - 2 ° tempo',
        188:   ' Primo cartellino giallo',
        190:   ' Primo cartellino giallo 2 ° tempo',
        191:   ' Sarà Qualsiasi Player Score una tripletta?',
        192:   ' Sarà realizzata una rete in entrambi i tempi?',
        194:   ' Squadra in casa di mantenere un Clean Sheet 1 ° Periodo',
        195:   ' Squadra in casa di mantenere un Clean Sheet 2 ° periodo',
        196:   ' Posizione squadra di mantenere un Clean Sheet 1 ° Periodo',
        197:   ' Posizione squadra di mantenere un Clean Sheet 2 ° periodo',
        198:   ' Pari / Dispari - Squadra in casa',
        199:   ' Pari / Dispari - Team Lontano',
        200:   ' Sarà il primo punteggio squadra a vincere?',
        201:   ' Asian Games per disabili',
        202:   ' 1 ° Periodo Vincitore Home / Lontano',
        203:   ' 2 ° periodo Vincitore Home / Lontano',
        204:   ' 3 ° Periodo Vincitore Home / Lontano',
        205:   ' 4 ° Periodo Winner Home / Lontano',
        206:   ' 5 ° Periodo Winner Home / Lontano',
        207:   ' 15 Risultato Minute',
        208:   ' 30 Risultato Minute',
        209:   ' Angoli totali - 1 ° semestre',
        210:   ' Angoli totali - 2 ° semestre',
        211:   ' Entrambe le squadre 2 ° tempo',
        212:   ' Entrambe le squadre segnano in entrambi i tempi',
        213:   ' Disegnare in entrambi i tempi',
        214:   ' Under / Over Cards',
        215:   ' Posizione squadra a segnare 1 ° semestre',
        216:   ' Posizione squadra a segnare 2 ° tempo',
        217:   ' Risultato esatto 3 ° periodo',
        218:   ' Squadra in casa a segnare 1 ° semestre',
        219:   ' Squadra in casa a segnare 2 ° tempo',
        220:   ' Under / Over - Posizione squadra compresi gli straordinari',
        221:   ' Under / Over - squadra di casa compresi gli straordinari',
        222:   ' Under / Over 3 ° periodo - Team Lontano',
        223:   ' Under / Over 3 ° periodo - Squadra in casa',
        224:   ' Doppia Chance 3 ° periodo',
        225:   ' Doppia Chance 4 ° periodo',
        226:   ' 12 compresi gli straordinari',
        227:   ' Vincitore della corsa 2 Posti',
        228:   ' Race Winner 3 Posti',
        229:   ' Race Winner 4 Posti',
        233:   ' Per vincere non per Nil',
        235:   ' 1 ° 5 inning Vincitore',
        236:   ' 1 ° 5 inning Under / Over',
        237:   ' Ci sarà un segneranno nel 1 ° Inning',
        238:   ' rimanendo Partita',
        239:   ' obiettivi Gamma',
        242:   ' 1 ° Periodo Pari / Dispari - Squadra in casa',
        243:   ' 1 ° Periodo Pari / Dispari - Team Lontano',
        244:   ' Partnership Massima apertura',
        245:   ' Goal / No Goal',
        247:   ' Rimanendo 1 ° semestre',
        250:   ' Angoli Handicap - 1 ° semestre',
        254:   ' carte Gamma',
        255:   ' Time Of prima scheda',
        256:   ' Squadra in casa - Carte di Gamma',
        257:   ' Posizione squadra - Carte di Gamma',
        258:   ' angoli Gamma',
        259:   ' Con la maggior parte della squadra Corners',
        260:   ' Con la maggior parte della squadra 1 ° semestre Corners',
        261:   ' Con la maggior parte della squadra 2 ° tempo Corners',
        262:   ' Squadra in casa - Angoli Gamma',
        263:   ' Posizione squadra - Angoli Gamma',
        264:   ' Squadra in casa - 1 ° semestre Angoli Gamma',
        265:   ' Posizione squadra - 1 ° semestre Angoli Gamma',
        266:   ' Squadra in casa - 2 ° tempo Angoli Gamma',
        267:   ' Posizione squadra - 2 ° tempo Angoli Gamma',
        269:   ' Obiettivi Gamma 1 ° Periodo',
        271:   ' Chance Mix',
        274:   ' Vincitore',
        275:   ' Obiettivi Gamma 2 ° periodo',
        278:   ' Obiettivi Gamma squadra di casa',
        279:   ' Obiettivi Gamma Posizione squadra',
        281:   ' 1 ° 5 inning Handicap Asiatico',
        282:   ' 1X2 Halftime',
        283:   ' Asian Handicap 2 ° tempo',
        284:   ' 1X2 2 ° tempo',
        285:   ' Pari / Dispari 2 ° tempo',
        286:   ' 12 2 ° tempo',
        287:   ' Under / Over 4 ° periodo - Squadra in casa',
        288:   ' Under / Over 4 ° periodo - Team Lontano',
        289:   ' 2 ° periodo Pari / Dispari - Squadra in casa',
        290:   ' 3 ° periodo Pari / Dispari - Squadra in casa',
        291:   ' 4 ° periodo Pari / Dispari - Squadra in casa',
        292:   ' 2 ° periodo Pari / Dispari - Team Lontano',
        293:   ' 3 ° periodo Pari / Dispari - Team Lontano',
        294:   ' 4 ° periodo Pari / Dispari - Team Lontano',
        297:   ' SP',
        299:   ' Point Vincitore',
        300:   ' Gioco Vincitore',
        301:   ' obiettivo Prima',
        302:   ' obiettivo Dopo',
        304:   ' Squadra con il più alto punteggio Periodo',
        305:   ' Angoli - Under / Esattamente / Over',
        306:   " Primo Goal dell'ultimo obiettivo",
        307:   ' Giochi Europei per disabili',
        308:   ' Under / Over Miglior punteggio Periodo',
        309:   ' Under / Over basso punteggio Periodo',
        310:   ' Serie Winner',
        317:   ' Under / Esattamente / Over - 1 ° Periodo',
        318:   ' Under / Esattamente / Over - 2 ° periodo',
        319:   ' Under / Esattamente / Over - 3 ° periodo',
        320:   ' Under / Esattamente / Over - 4 ° periodo',
        322:   ' Under / Esattamente / Over',
        329:   ' 1 ° Periodo Race To',
        330:   ' 2 ° periodo Race To',
        331:   ' 3 ° periodo Race To',
        332:   ' 4 ° periodo Race To',
        333:   ' 5 ° Periodo Race To',
        337:   ' Under / Over - 2 ° tempo',
        338:   ' Prossimo Obiettivo 1 ° Periodo',
        339:   ' Prossimo Goal 2 ° periodo',
        341:   ' Race To',
        342:   ' Asian Handicap Overtime Compresi',
        343:   ' Gioco Gamma 1 ° Periodo',
        344:   ' Gamma Gioco 2 ° periodo',
        345:   ' Gioco Gamma 3 ° periodo',
        346:   ' Gamma Gioco 4 ° periodo',
        347:   ' Gamma Gioco 5 ° Periodo',
        348:   ' 6 ° Periodo Winner',
        349:   ' 7 ° Periodo Winner',
        350:   ' 6 ° Periodo pari / dispari',
        351:   ' 7 ° Periodo pari / dispari',
        352:   ' Under / Over 6 ° Periodo',
        353:   ' Under / Over 7 Periodo',
        354:   ' Under / Over Halftime - Squadra in casa',
        355:   ' Under / Over Halftime - Team Lontano',
        356:   ' Metodo di licenziamento 6-Way',
        357:   ' licenziamento Metodo',
        366:   ' Esegue pari / dispari in corso - della squadra di casa',
        367:   ' Lavorazione totale pari / dispari in corso - Team Lontano',
        370:   ' Esegue totale in corso - della squadra di casa',
        371:   ' Lavorazione totale in corso - della squadra Lontano',
        386:   ' European Handicap compresi gli straordinari',
        387:   ' Pari / Dispari - squadra di casa compresi gli straordinari',
        388:   ' Pari / Dispari - Posizione squadra compresi gli straordinari',
        390:   ' HT / FT compresi gli straordinari',
        391:   ' Prossimo Goal - Extra Time',
        392:   ' Squadra in casa Penalty Shootout',
        393:   ' Posizione squadra Penalty Shootout',
        394:   ' Risultato esatto 4 ° periodo',
        395:   ' Risultato esatto 5 ° Periodo',
        398:   ' Odd / Cards Anche',
        400:   ' Pari / Dispari Carte 1 ° semestre',
        401:   ' Under / Over Corners 1 ° semestre - Squadra in casa',
        402:   ' Under / Over Corners 1 ° semestre - Team Lontano',
        403:   ' Under / Over Corners 2 ° tempo',
        404:   ' Under / Over Corners 2 ° tempo - Squadra in casa',
        405:   ' Under / Over Corners 2 ° tempo - Team Lontano',
        406:   ' Pari / Dispari Corners 1 ° semestre',
        407:   ' Carte Handicap asiatico',
        408:   ' Asian Handicap Carte 1 ° semestre',
        409:   ' 1X2 Corners',
        410:   ' 1X2 Corners 1 ° semestre',
        411:   ' 12 Corners',
        414:   ' Under / Over Carte 1 ° semestre',
        415:   ' 1X2 E entrambe le squadre',
        416:   ' Under / Over - Extra Time',
        417:   ' Under / Over - Extra Tempo 1 ° semestre',
        419:   ' 1X2 - Extra Time',
        427:   ' 1X2 E Under / Over',
        428:   ' Risultato esatto multiplo',
        430:   ' Under / Esattamente / Over - 1 ° semestre',
        431:   ' Under / Esattamente / Over - 2 ° tempo',
        433:   ' Angoli europei disabili',
        434:   ' 8 ° Periodo Winner',
        435:   ' 9 Periodo Winner',
        436:   ' Under / Over 8 Periodo',
        437:   ' Under / Over 9 Periodo',
        438:   ' Lavorazione totale pari / dispari in corso',
        439:   ' Corre Totale in corso',
        445:   ' 6 ° Periodo Winner Home / Lontano',
        446:   ' 7 Periodo Winner Home / Lontano',
        447:   ' Asian 6 ° Periodo Handicap',
        448:   ' Asian 7 Periodo Handicap',
        449:   ' Asian 8 ° Periodo Handicap',
        450:   ' Asian Handicap 9 Periodo',
        451:   ' 8 ° Periodo Winner Home / Lontano',
        452:   ' 9 Periodo Winner Home / Lontano',
        453:   ' Prima Score Metodo',
        454:   ' Prima Punteggio Metodo - Squadra in casa',
        455:   ' Prima Score Metodo - Team Lontano',
        456:   ' Doppia Chance primo periodo',
        457:   ' Doppia Chance 2 ° periodo',
        459:   ' Home page squadra a segnare in ogni quartiere',
        460:   ' Posizione squadra a segnare in ogni quartiere',
        462:   ' Top Winner 5',
        463:   ' Top Winner 10',
        464:   ' 12 2 ° Overtime Mezza Compresi',
        465:   ' 4 ° Periodo Vincitore casa / Lontano compresi gli straordinari',
        466:   ' Under / Over 4 ° periodo compresi gli straordinari',
        467:   ' Asian Handicap 4 ° periodo compresi gli straordinari',
        468:   ' Asian Handicap 2 ° tempo compresi gli straordinari',
        469:   ' Under / Over 2 ° tempo compresi gli straordinari',
        472:   ' Will gioco Vai a Shootout?',
        475:   ' Under / Over viene eseguito in corso - della squadra di casa prima Inning',
        476:   ' Under / Over viene eseguito in corso - Posizione squadra 1 ° Inning',
        477:   ' Under / Over viene eseguito in corso - della squadra di casa 2 ° Inning',
        478:   ' Under / Over viene eseguito in corso - Posizione squadra 2 ° Inning',
        479:   ' Pari / Dispari eseguito in corso - della squadra di casa prima Inning',
        480:   ' Pari / Dispari eseguito in corso - Posizione squadra 1 ° Inning',
        481:   ' Pari / Dispari eseguito in corso - della squadra di casa 2 ° Inning',
        482:   ' Pari / Dispari eseguito in corso - Posizione squadra 2 ° Inning',
        483:   ' Sarà squadra di casa Get A Wicket in corso - 1 ° Inning?',
        484:   ' Will Posizione squadra ottenere un Wicket in corso - 1 ° Inning?',
        485:   ' Sarà squadra di casa Get A Wicket in corso - 2 ° Inning?',
        486:   ' Will Posizione squadra ottenere un Wicket in corso - 2 ° Inning?',
        523:   ' Under / Over And entrambe le squadre',
        524:   ' 1 ° 7 inning Vincitore',
        525:   ' 1 ° 7 inning Under / Over',
        526:   ' 1 ° 7 inning Handicap Asiatico',
        529:   ' Pari / Dispari secondo Overtime Mezza Compresi',
        553:   ' Sotto Sanzioni / Over Shootout Taken',
        554:   ' 12 Shootout',
        555:   ' Asian Handicap Shootout',
        556:   ' Corretto Shootout Score',
        557:   ' Under / Over Shootout',
        558:   ' Under / Over Shootout - Squadra in casa',
        559:   ' Under / Over Shootout - Team Lontano',
        560:   ' Squadra ad andare Ultima Penalty Shootout',
        563:   ' Race To compresi gli straordinari',
        564:   ' 1X2 E Under / Over a 5 vie',
        566:   ' 10 ° Periodo Winner Home / Lontano',
        567:   ' 11 ° Periodo Winner Home / Lontano',
        568:   ' 12 ° Periodo Winner Home / Lontano',
        569:   ' 13 Periodo Winner Home / Lontano',
        570:   ' 14 Periodo Winner Home / Lontano',
        571:   ' 15 Periodo Winner Home / Lontano',
        573:   ' 16 Periodo Winner Home / Lontano',
        574:   ' 17 Periodo Winner Home / Lontano',
        575:   ' 18 Periodo Winner Home / Lontano',
        576:   ' 19 Periodo Winner Home / Lontano',
        577:   ' 10 Minute Under / Over - Corners',
        578:   ' 10 Minute Under / Over - Carte',
        579:   ' angolo',
        586:   ' Per finire nella metà superiore',
        587:   ' Per arrivano ultimi Metà',
        588:   ' Per finire in basso',
        589:   ' Per finire Not In Top 4',
        590:   ' Per essere relegato',
        591:   ' Non essere relegato',
        592:   ' Per raggiungere Finale',
        593:   ' Per arrivano ultimi 6',
        594:   ' Essere promosso',
        595:   ' Primo obiettivo di tempo',
        596:   ' Obiettivi Gamma squadra di casa prima Periodo',
        597:   ' Obiettivi Gamma squadra di casa 2 ° periodo',
        598:   ' Obiettivi Gamma Posizione squadra 1 ° Periodo',
        599:   ' Obiettivi Gamma Posizione squadra 2 ° periodo',
        600:   ' 1 ° Periodo vincitore e entrambe le squadre',
        601:   ' HT / FT Doppia Chance',
        602:   ' Risultato esatto compresi gli straordinari',
        603:   ' HT Negativo / FT',
        604:   ' Obiettivi Gamma squadra di casa entrambi i periodi',
        605:   ' Obiettivi gamma squadra entrambi i periodi',
        606:   ' Vincitore e gli obiettivi tipo One',
        607:   ' Doppia Chance E OBIETTIVI',
        608:   ' Vincitore e gli obiettivi di tipo due',
        609:   ' Entrambe le squadre e gli obiettivi',
        610:   ' Doppia Chance Mix',
        628:   ' Alla fine in Le 7',
        629:   ' Alla fine in Top 6',
        630:   ' Alla fine in Top 3',
        631:   ' Alla fine in Top 2',
        632:   ' Alla fine in Top 4',
        633:   ' Alla fine in Top 5',
        634:   ' Pari / Dispari Gironi 1 ° Periodo',
        635:   ' Pari / Dispari Gironi 2 ° periodo',
        636:   ' Pari / Dispari Gironi 3 ° periodo',
        637:   ' Ci sarà un asso?',
        638:   ' Ci sarà una Knife uccidere?',
        639:   ' 1º girone Vincitore',
        640:   ' 16 ° girone Vincitore',
        641:   ' Ci sarà un kill coltello? - 1 ° Periodo',
        642:   ' Ci sarà un kill coltello? - 2 ° periodo',
        643:   ' Ci sarà un kill coltello? - 3 ° periodo',
        644:   ' Ci sarà un asso? - 1 ° Periodo',
        645:   ' Ci sarà un asso? - 2 ° periodo',
        646:   ' Ci sarà un asso? - 3 ° periodo',
        647:   ' Ci sarà lavoro straordinario? - 1 ° Periodo',
        648:   ' Ci sarà lavoro straordinario? - 2 ° periodo',
        649:   ' Ci sarà lavoro straordinario? - 3 ° periodo',
        650:   ' 16 ° girone Vincitore - 1 ° Periodo',
        651:   ' 16 ° girone Vincitore - 2 ° periodo',
        652:   ' 16 ° girone Vincitore - 3 ° periodo',
        653:   ' 1º girone Vincitore - 1 ° Periodo',
        654:   ' 1º girone Vincitore - 2 ° periodo',
        655:   ' 1º girone Vincitore - 3 ° periodo',
        660:   ' Gioco Durata',
        662:   ' Odd / Rounds Anche',
        663:   ' 1 ° Periodo Durata',
        664:   ' 2 ° periodo Durata',
        665:   ' 3 ° periodo Durata',
        666:   ' 1 ° Periodo - 1 ° Roshan',
        667:   ' 2 ° periodo - 1 ° Roshan',
        668:   ' 3 ° periodo - 1 ° Roshan',
        669:   ' 1 ° Periodo - 1 ° Sangue',
        670:   ' 2 ° periodo - 1 ° Sangue',
        671:   ' 3 ° periodo - 1 ° Sangue',
        672:   ' 1 ° Periodo - 1 ° tou',
        673:   ' 2 ° periodo - 1 ° tou',
        674:   ' 3 ° periodo - 1 ° tou',
        675:   ' 1 ° periodo - 1 ° Caserma',
        677:   ' 2 ° periodo - 1 ° Caserma',
        678:   ' 3 ° periodo - 1 ° Caserma',
        679:   ' 1 ° Periodo - 1 ° Torretta',
        680:   ' 2 ° periodo - 1 ° Torretta',
        681:   ' 3 ° periodo - 1 ° Torretta',
        682:   ' Race To Kills - 1 ° Periodo',
        683:   ' Race To Kills - 2 ° periodo',
        684:   ' Race To Kills - 3 ° periodo',
        685:   ' Entrambe le squadre di uccidere un drago - 1 ° Periodo',
        686:   ' Entrambe le squadre di uccidere un drago - 2 ° periodo',
        687:   ' Entrambe le squadre di uccidere un drago - 3 ° periodo',
        688:   ' Entrambe le squadre per uccidere un inibitore - 1 ° Periodo',
        689:   ' Entrambe le squadre per uccidere un inibitore - 2 ° periodo',
        690:   ' Entrambe le squadre per uccidere un inibitore - 3 ° periodo',
        691:   ' Pari / dispari Kills',
        692:   ' Pari / Dispari Kills - 1 ° Periodo',
        693:   ' Pari / Dispari Kills - 2 ° periodo',
        694:   ' Pari / Dispari Kills - 3 ° periodo',
        695:   ' HT / FT e gli obiettivi',
        697:   ' Alla fine in Top 8',
        711:   ' Giocatore a segnare in qualsiasi momento',
        712:   ' Primo giocatore a segnare',
        713:   ' Ultimo giocatore a segnare',
        714:   ' Giocatore a segnare 2 o più gol',
        715:   ' Giocatore a segnare 3 o più gol',
        727:   ' 1 ° Periodo entrambe le squadre',
        728:   ' 2 ° periodo entrambe le squadre',
        729:   ' 3 ° periodo entrambe le squadre',
        730:   ' 4 ° periodo entrambe le squadre',
        731:   ' 1 ° Periodo casa squadra a segnare',
        732:   ' 2 ° periodo casa squadra a segnare',
        733:   ' 3 ° periodo casa squadra a segnare',
        734:   ' 4 ° periodo casa squadra a segnare',
        735:   ' 1 ° periodo lontano squadra a segnare',
        736:   ' 2 ° periodo lontano squadra a segnare',
        737:   ' 3 ° periodo lontano squadra a segnare',
        738:   ' 4 ° periodo lontano squadra a segnare',
        751:   ' 1 ° semestre Race To',
        752:   ' 2 ° tempo Race To - compresi gli straordinari',
        754:   ' Gioco Per Deuce',
        755:   ' Extra Tempo Corners - Under / Esattamente / Over',
        756:   ' Under / Extra sovra Tempo Corners',
        757:   ' Asian Handicap - Extra Time',
        758:   ' 10 Minute Under / Over - Free Kicks',
        759:   ' 10 Minute Under / Over - rimesse laterali',
        760:   ' 10 Minute Under / Over - Kicks Goal',
        762:   ' Per vincere il Toss',
        763:   ' Under / Over viene eseguito in partita',
        765:   ' Che segna il maggior Fours',
        766:   ' Under / Over - Partita Fours',
        767:   ' Under / Over - Partita Sixes',
        768:   ' A cinquanta segnati nel match',
        769:   ' A Hundred Per segnati nel match',
        770:   ' La maggior parte dei Outs Run',
        791:   ' Miglior battitore - Squadra in casa',
        792:   ' Miglior battitore - Team Lontano',
        793:   ' Top Bowler - Squadra in casa',
        794:   ' Top Bowler - Team Lontano',
        795:   ' Uomo partita',
        806:   ' Battitore a segnare un Cinquanta nella partita',
        808:   ' Race To 10 piste',
        809:   ' Massima 1st 6 Overs Score',
        813:   ' Giocatore a segnare maggior Sixes',
        814:   ' Più alto punteggio individuale',
        819:   ' Di avere una pausa punto del gioco',
        820:   ' Race To Corners',
        822:   ' Top New Team',
        824:   ' Player da prenotare',
        825:   ' Giocatore ad essere espulso',
        834:   ' Punti Extra Set',
        835:   ' Asian Under / Over',
        836:   ' Asian Under / Over 1 ° Periodo',
        837:   ' 1 ° semestre Squadra in casa vincerà con zero',
        838:   ' 1 ° semestre Posizione squadra vincerà con zero',
        839:   ' 1 ° semestre 1X2 E Under / Over',
        840:   ' 1 ° Periodo Squadra in casa vincerà con zero',
        841:   ' 1 ° periodo lontano squadra vincerà con zero',
        842:   ' Under / Esattamente / Over - Squadra in casa',
        843:   ' Under / Esattamente / Over - Team Lontano',
        844:   ' Prima squadra che segna - Home / Lontano',
        845:   ' Squadra di chiamare prima volta fuori',
        846:   ' Sotto Hits / Over - Overtime Compreso',
        849:   ' Under / Over 5 ° Inning',
        851:   ' Squadra con il più alto punteggio Metà',
        852:   ' O squadra a segnare 3 Senza risposta Volte',
        853:   ' 12 E Under / Over',
        854:   ' Squadra speciale o Touchdown Defensive Segnate',
        855:   ' punti di campo',
        859:   ' 147 Pausa In Partita',
        861:   ' Prima Nota Metodo 3 Way - squadra di casa',
        862:   ' Prima Score Metodo 3 Way - Team Lontano',
        863:   ' Squadra con più alto punteggio -1X2 Periodo',
        864:   ' Squadra Primo Marcatore',
        865:   ' Squadra Ultimo Marcatore',
        866:   ' Imposta handicap asiatico',
        870:   ' Prima partitura Metodo 6 Way Home / Lontano',
        878:   ' Obiettivi Gamma 3 ° periodo',
        879:   ' Sotto Mete / Over',
        880:   ' Primo tempo Corners - Under / Esattamente / over',
        881:   ' Primo Goal Metodo',
        882:   ' Numero di punti',
        891:   ' Squadra a segnare più lungo Touchdown',
        892:   ' Squadra a segnare più lungo Field Goal',
        893:   ' Longest Touchdown Segnato',
        894:   ' Più breve Touchdown Segnato',
        895:   ' Longest Field Goal Segnato',
        896:   ' Time Of First Team Goal',
        897:   ' Gioco Punteggio Dopo 2 Punti',
        898:   ' Gioco Punteggio Dopo 3 Punti',
        899:   ' Gioco Punteggio Dopo 4 Punti',
        900:   ' Gioco di segnare',
        901:   ' 1st Period After piombo',
        902:   ' 2 ° periodo Dopo piombo',
        903:   ' 3rd Period After piombo',
        904:   ' 4 ° periodo Dopo piombo',
        905:   ' 5 ° Period After piombo',
        906:   ' Siamo a 4 Giochi',
        907:   ' Tacco al 6 Giochi',
        908:   ' Per rompere servire in Set',
        909:   ' Casa per vincere in due set',
        910:   ' Via per vincere in due set',
        911:   ' Punteggio Dopo 2 set',
        912:   ' E siamo a 3 Imposta',
        914:   ' Under / Over campo Segnate - 1 ° semestre',
        915:   ' Under / Over Touchdowns segnato - 1 ° semestre',
        916:   ' Under / Over Squadra di casa Touchdowns - 1 ° semestre',
        917:   ' Under / Over Squadra fuori Touchdowns - 1 ° semestre',
        920:   ' Under / Over Touchdowns',
        921:   ' Under / Esattamente / Over - Touchdowns',
        922:   ' Under / Over campo Obiettivi',
        923:   ' Under / Esattamente / Over - Campo Obiettivi',
        924:   ' Tempo Di Prima Field Goal Segnato',
        925:   ' Tempo Di Prima Touchdown Segnato',
        926:   ' Time Of prima partitura',
        927:   ' Squadra in casa Under / Over Touchdowns',
        928:   ' Posizione squadra Under / Over Touchdowns',
        929:   ' Squadra in casa Under / Over campo Obiettivi',
        930:   ' Gol della squadra ospite Under / Over campo',
        932:   ' Prima squadra ad aver Coaches Sfida',
        933:   ' Squadra a commettere Prima accettate Pena',
        934:   ' sicurezza Segnato',
        935:   ' Tessere europee di Handicap',
        936:   ' Le carte asiatico Under /',
        938:   ' Under / Over Cards - Squadra in casa',
        939:   ' Under / Over Cards - Team Lontano',
        940:   ' Time Of prima carta',
        941:   ' Successivo giocatore a segnare',
        942:   ' Top Nazionalità - Asian',
        943:   ' Top Nazionalità - Australian',
        944:   ' Top Nazionalità - europea',
        945:   ' Top Nazionalità - Gran Bretagna e Irlanda',
        946:   ' Top Nazionalità - Resto Del Mondo',
        947:   ' Under / Over Aces',
        948:   ' Under / Over Aces - squadra di casa',
        949:   ' Numero esatto di Aces',
        950:   ' Under / Over Aces - squadra Lontano',
        951:   ' Numero corretto di Aces',
        955:   ' 1X2 Aces',
        956:   ' Numero Esatto di Aces - squadra di casa',
        957:   ' Numero esatto di Aces - squadra Lontano',
        958:   ' Aces Asian Handicap',
        959:   ' Race To Aces',
        960:   ' Under / Over Aces - 1 ° Periodo',
        976:   ' 1X2 Aces - 1 ° Periodo',
        982:   ' Aces Handicap Asiatico - 1 ° Periodo',
        986:   ' 12 Aces',
        989:   ' Under / Over Kills - 1 ° Periodo',
        990:   ' Under / Over Kills - 2 ° periodo',
        991:   ' Under / Over Kills - 3 ° periodo',
        992:   ' Successivo Touchdown Scorer - Squadra in casa',
        993:   ' Successivo Touchdown Scorer - Team Lontano',
        994:   ' Successivo Touchdown Scorer',
        995:   ' Giocatore a segnare Touchdown In qualsiasi momento',
        996:   ' Giocatore a segnare 2 o più Touchdowns',
        997:   ' Giocatore a segnare 3 o più Touchdowns',
        998:   ' Prima Partita Corner - 12',
        999:   ' Ultimo Partita Corner - 12',
        1000:   ' Numero di set',
        1001:   ' Under / Over doppi falli',
        1002:   ' Under / Over doppi falli - Team Lontano',
        1003:   ' Under / Over doppi falli - Squadra in casa',
        1004:   ' Under / Over doppi falli - 1 ° Periodo',
        1010:   ' 12 doppi falli',
        1011:   ' 12 doppi falli - 1 ° Periodo',
        1013:   ' Guasti 1X2 Doppia',
        1016:   ' Numero esatto di doppi falli',
        1019:   ' Numero esatto di doppi falli - Squadra in casa',
        1020:   ' Numero esatto di doppi falli - Team Lontano',
        1027:   ' Numero corretto di doppi falli',
        1030:    " Time Of Primo calcio d'angolo",
        1031:   ' Angoli multipli',
        1034:   ' 20 Periodo Winner Home / Lontano',
        1035:   ' 21 Periodo Winner Home / Lontano',
        1036:   ' 22 Periodo Winner Home / Lontano',
        1037:   ' 23 Periodo Winner Home / Lontano',
        1038:   ' 24 Periodo Winner Home / Lontano',
        1039:   ' 25 Periodo Winner Home / Lontano',
        1040:   ' 26 Periodo Winner Home / Lontano',
        1041:   ' 27 Periodo Winner Home / Lontano',
        1042:   ' 28 Periodo Winner Home / Lontano',
        1043:   ' 29 Periodo Winner Home / Lontano',
        1044:   ' 30 Periodo Winner Home / Lontano',
        1046:   ' 32 ° Periodo Winner Home / Lontano',
        1047:   ' 33th Periodo Winner Home / Lontano',
        1050:   ' Corsa a 20 punti',
        1051:   ' Under / Over 2 ° tempo - Squadra in casa',
        1052:   ' Under / Over 2 ° tempo - Team Lontano',
        1053:   ' Asian Under / Over 2 ° periodo',
        1054:   ' Asian Under / Over 3 ° periodo',
        1055:   ' Asian Under / Over 4 ° periodo',
        1061:   ' Asian Under / Over compresi gli straordinari',
        1062:   ' Miglior punteggio in Mezza - Squadra in casa',
        1063:   ' Miglior punteggio in Mezza - Team Lontano',
        1065:   ' Squadra in casa - Primo Marcatore',
        1066:   ' Squadra in casa - Ultimo Marcatore',
        1067:   ' Posizione squadra - Primo Marcatore',
        1068:   ' Posizione squadra - Ultimo Marcatore',
        1069:   ' Sotto Punti / Over Player',
        1070:   ' Sotto su blocchi / lettore',
        1071:   ' Under / Over Player Assist',
        1072:   ' Under / Over Player Rimbalzi',
        1073:   ' Under / Over Player Sottrae',
        1074:   ' Under / Over Player Fatturati',
        1075:   ' Under / Over Player 3 punte Materiale',
        1076:   ' Under / Over Player 3 Punti Tentato',
        1077:   ' Under / Over Giocatore 2 punte Materiale',
        1078:   ' Under / Over Player 2 Punti Tentato',
        1079:   ' Under / Over Player tiri liberi Realizzato',
        1080:   ' Under / Over Player tiri liberi Tentativo',
        1081:   ' Ammonizioni 1X2',
        1082:   ' Doppia Chance Ammonizioni',
        1083:   ' Ammonizioni Asian Handicap',
        1084:   ' Ammonizioni Risultato esatto',
        1085:   ' Under / Over espulsi',
        1086:   ' Under / Esattamente / Over Punti di prenotazione',
        1087:   ' Under / Esattamente / Over prenotazione Punti - Squadra in casa',
        1088:   ' Under / Esattamente / Over Booking Punti - squadra Lontano',
        1089:   ' Under / Over giocatore palle rubate e blocchi',
        1090:   ' Sotto Punti / Over Player',
        1091:   ' Under / Over giocatore assist e rimbalzi',
        1092:   ' Under / Over Player Points E Rimbalzi',
        1093:   ' Under / Over Player Points e assiste',
        1094:   ' Player Doppia Doppia',
        1095:   ' Player Tripla Doppia',
        1096:   ' Under / Esattamente / Over Booking Punti - 1 ° Periodo',
        1100:   ' Vincitore del set',
        1101:   ' Under / Over 180 \ s in un set',
        1102:   ' Set / Leg Winner',
        1103:   ' Set / Leg totali Estrazioni Under / Over',
        1105:   ' Under / Over totale 180 \ s - Squadra in casa',
        1106:   ' Under / Over Totale 180 \ s - Team Lontano',
        1112:   ' Under / Over Giocatore Gol',
        1115:   ' Under / Over Giocatore Gol ammessi',
        1120:   ' Sotto giri / Over - 1 ° Periodo',
        1121:   ' Sotto giri / Over - 2 ° periodo',
        1122:   ' Sotto giri / Over - 3 ° periodo',
        1123:   ' 4 ° periodo - 1 ° Sangue',
        1124:   ' 5 ° Periodo - 1 ° Sangue',
        1125:   ' Entrambe le squadre di uccidere un drago',
        1126:   ' Entrambe le squadre To Kill a Baron',
        1127:   ' Entrambe le squadre Per uccidere un inibitore',
        1128:   ' Under / Over Baroni Ucciso',
        1129:   ' Under / Over Baroni Ucciso - 1 ° Periodo',
        1130:   ' Under / Over Baroni Ucciso - 2 ° periodo',
        1131:   ' Under / Over Baroni Ucciso - 3 ° periodo',
        1132:   ' Under / Over Dragons Ucciso',
        1133:   ' Under / Over Dragons Ucciso - 1 ° Periodo',
        1134:   ' Under / Over Dragons Ucciso - 2 ° periodo',
        1135:   ' Under / Over Dragons Ucciso - 3 ° periodo',
        1136:   ' Under / Over 180 \ s',
        1137:   ' First 180',
        1138:   ' Ultima 180',
        1139:   ' Under / Over più alto punteggio Checkout',
        1140:   ' La maggior parte dei 180 \ s',
        1141:   ' Under / Over alto Checkout Score - Squadra in casa',
        1142:   ' Under / Over alto Checkout Score - Team Lontano',
        1143:   ' Primo giocatore a segnare Touchdown',
        1144:   ' Ultimo giocatore a segnare Touchdown',
        1145:   ' Primo giocatore a segnare Touchdown - Squadra in casa',
        1146:   ' Primo giocatore a segnare Touchdown - Team Lontano',
        1147:   ' Under / Over Kills - 4 ° periodo',
        1148:   ' Under / Over Kills - 5 ° Periodo',
        1149:   ' Asian Handicap Kills - 1 ° Periodo',
        1150:   ' Handicap Asiatico Kills - 2 ° periodo',
        1151:   ' Asian Handicap Kills - 3 ° periodo',
        1152:   ' Asian Handicap Kills - 4 ° periodo',
        1153:   ' Asian Handicap Kills - 5 ° Periodo',
        1154:   ' Under / Esattamente / Oltre 180 \ s',
        1155:   ' Under / Over Totale di Esattamente / 180 \ s - Squadra in casa',
        1156:   ' Under / Esattamente / Over Totale 180 \ s - Team Lontano',
        1157:   ' La maggior parte delle estrazioni',
        1158:   ' First Try Scorer',
        1159:   ' Ultimo tentativo Scorer',
        1160:   ' In qualsiasi momento Prova Scorer',
        1162:   ' Under / Over tous Destroyed - 1 ° Periodo',
        1163:   ' Under / Over tous Destroyed - 2 ° periodo',
        1164:   ' Under / Over tous Destroyed - 3 ° periodo',
        1165:   ' 1 ° Periodo - 1 ° Baron',
        1166:   ' 2 ° periodo - 1 ° Baron',
        1167:   ' 3 ° periodo - 1 ° Baron',
        1168:   ' 4 ° periodo - 1 ° Baron',
        1169:   ' 5 ° Periodo - 1 ° Baron',
        1170:   ' 1 ° Periodo - 1 ° Drago',
        1171:   ' 2 ° periodo - 1 ° Drago',
        1172:   ' 3 ° periodo - 1 ° Drago',
        1173:   ' 4 ° periodo - 1 ° Drago',
        1174:   ' 5 ° Periodo - 1 ° Drago',
        1175:   ' La maggior parte dei Kills - 1 ° Periodo',
        1176:   ' La maggior parte dei Kills - 2 ° periodo',
        1177:   ' La maggior parte dei Kills - 3 ° periodo',
        1178:   ' La maggior parte dei Kills - 4 ° periodo',
        1179:   ' La maggior parte dei Kills - 5 ° Periodo',
        1180:   ' La maggior parte dei Kills - 6 ° Periodo',
        1181:   ' La maggior parte dei Kills - 7 ° Periodo',
        1182:   ' Giocatore a segnare 2 o più Trys',
        1183:   ' Giocatore a segnare 3 o più Trys',
        1184:   ' Non per vincere la Leauge',
        1185:   ' Under / Esattamente / Over compresi gli straordinari - Squadra in casa',
        1186:   ' Under / Esattamente / Over compresi gli straordinari - Team Lontano',
        1187:   ' Under / Esattamente / Over compresi gli straordinari',
        1188:   ' Under / Over Sostituzioni',
        1189:   ' Under / Over Sostituzioni - 1 ° Periodo',
        1190:   ' Ultimo giocatore a segnare Touchdown - Squadra in casa',
        1191:   ' Ultimo giocatore a segnare Touchdown - Team Lontano',
        1192:   ' Primo giocatore di colpire un Asso',
        1193:   ' Under / Over Player Quarterback Passing Yards',
        1194:   ' Under / Over giocatore Touchdowns Passing',
        1195:   ' Meno / di più Longest Player Passo Completamento',
        1196:   ' Under / Over giocatore che riceve Yards',
        1197:   ' Under / Over Player Intercettazioni',
        1198:   ' Under / Over Player Kicking Punti',
        1199:   ' Sotto Rushing / Over Player & Ricezione Yards',
        1200:   ' Under / Over Player Rushing Yards',
        1201:   ' Under / Over Player Longest Reception',
        1202:   ' Under / Over Player Ricevimenti',
        1203:   ' Under / Over giocatore di campo Realizzato',
        1204:   ' Under / Over giocatore di campo Tentativi',
        1205:   ' Under / Over Player Quarterback Touchdowns Passing',
        1206:   ' Sotto Completamenti Reception / ricevitore Nel corso Player',
        1207:   ' Under / Over Player Quarterback Positivo Completato',
        1208:   ' Under / Over Player Rushing Touchdowns',
        1209:   ' Sotto Tentativi Reception / ricevitore Nel corso Player',
        1210:   ' Under / Over Player Quarterback Positivo Tentativo',
        1211:   ' 1X2 Falli',
        1212:   ' Doppia Chance Falli',
        1213:   ' Under / Over Falli - 1 ° Periodo',
        1214:   ' Under / Over Falli - 2 ° periodo',
        1215:   ' Handicap Asiatico Falli',
        1216:   ' Under / Over Falli - Squadra in casa',
        1217:   ' Under / Over Falli - Team Lontano',
        1218:   ' Sotto Tentativi / Over Player Rush',
        1219:   ' Under / Over giocatore Touchdowns Ricezione',
        1222:   ' Under / Over rimesse laterali - Squadra in casa',
        1223:   ' Under / Over rimesse laterali - Team Lontano',
        1224:   ' 1X2 rimesse laterali',
        1225:   ' Doppia Chance rimesse laterali',
        1226:   ' Under / Over rimesse laterali - 1 ° Periodo',
        1227:   ' Rimesse laterali Handicap',
        1228:   ' Rimesse laterali Handicap - 1 ° Periodo',
        1229:   ' Under / Shots Oltre Target - Squadra in casa',
        1230:   ' Under / Shots Oltre Target - squadra Lontano',
        1231:   ' Colpi di handicap asiatico sul bersaglio',
        1232:   ' Colpi di Handicap Asiatico On Target - 1 ° Periodo',
        1233:   ' Under / Over tiri in porta - 1 ° Periodo',
        1234:   ' 1X2 tiri in porta',
        1235:   " Scatti Doppia chance Sull'obiettivo",
        1236:   ' Under / Over Totale Shots - squadra di casa',
        1237:   ' Under / Over Totale Shots - Team Lontano',
        1238:   ' Shots Doppia Chance totali',
        1239:   ' Shots 1X2 Totale',
        1240:   ' Asian Handicap Shots totali',
        1241:   ' Asian Handicap Shots Totale - 1 ° Periodo',
        1242:   ' Under / Over Totale Shots - 1 ° Periodo',
        1243:   ' Doppia chance Corners',
        1244:   ' Doppia chance Corners - 1 ° Periodo',
        1245:   ' Angoli da tutti e 4 gli angoli del Pitch',
        1246:   ' Doppia Chance Ammonizioni - 1 ° Periodo',
        1247:   ' 1X2 Ammonizioni - 1 ° Periodo',
        1248:   ' Asian Handicap Ammonizioni - 1 ° Periodo',
        1249:   ' Doppia Chance & Under / Over',
        1250:   ' Doppia Chance & entrambe le squadre',
        1251:   ' 1 ° Periodo - 1 ° Inhibitor',
        1252:   ' 2 ° periodo - 1 ° Inhibitor',
        1253:   ' 3 ° periodo - 1 ° Inhibitor',
        1254:   ' 4 ° periodo - 1 ° Inhibitor',
        1255:   ' 5 ° Periodo - 1 ° Inhibitor',
        1256:   ' Entrambe le squadre di uccidere un barone - 1 ° Periodo',
        1257:   ' Entrambe le squadre di uccidere un barone - 2 ° periodo',
        1258:   ' Entrambe le squadre di uccidere un barone - 3 ° periodo',
        1259:   ' Entrambe le squadre di uccidere un Baron - 4 ° periodo',
        1260:   ' Entrambe le squadre di uccidere un barone - 5 ° Periodo',
        1261:   ' Entrambe le squadre di uccidere un drago - 4 ° periodo',
        1262:   ' Entrambe le squadre di uccidere un drago - 5 ° Periodo',
        1263:   ' Entrambe le squadre per uccidere un inibitore - 4 ° periodo',
        1264:   ' Entrambe le squadre per uccidere un inibitore - 5 ° Periodo',
        1265:   ' Ci sarà un asso Prossima Partita?',
        1266:   ' Ci sarà doppio fallo Prossima Partita?',
        1267:   ' Odd / Falli Anche',
        1268:   ' Asian Handicap Possesso palla',
        1269:   ' Doppia Chance Fuorigioco',
        1270:   ' Asian Handicap Fuorigioco',
        1271:   ' Under / Over Fuorigioco - Squadra in casa',
        1272:   ' Under / Over Fuorigioco - Team Lontano',
        1273:   ' 1X2 Fuorigioco',
        1274:   ' Under / Over Kills primo periodo - Squadra in casa',
        1275:   ' Under / Over Kills 2 ° periodo - Squadra in casa',
        1276:   ' Under / Over Kills 3 ° periodo - Squadra in casa',
        1277:   ' Under / Over Kills 1 ° Periodo - Team Lontano',
        1278:   ' Under / Over Kills 2 ° periodo - Team Lontano',
        1279:   ' Under / Over Kills 3 ° periodo - Team Lontano',
        1280:   ' Under / Over Wides',
        1281:   ' Sotto Ducks / Over',
        1282:   ' Under / Over Wickets',
        1283:   ' Under / Over Partita Fours - Squadra in casa',
        1284:   ' Under / Over Partita a sei posti - Squadra in casa',
        1285:   ' Under / Over Partita Fours - Team Lontano',
        1286:   ' Under / Over Partita a sei posti - Team Lontano',
        1287:   ' Under / Over Extra',
        1288:   ' Fall Of prima Wicket - Squadra in casa',
        1289:   ' Fall Of prima Wicket - Team Lontano',
        1290:   ' Squadra Di Miglior battitore',
        1291:   ' Under / Over Partita sei posti della squadra di casa - 1 ° Inning',
        1292:   ' Under / Over Partita sei posti Squadra ospite - 1 ° Inning',
        1293:   ' Under / Over Partita Fours della squadra di casa - 1 ° Inning',
        1294:   ' Under / Over Incontro Fours della squadra ospite - 1 ° Inning',
        1295:   ' Under / Over Miglior battitore',
        1296:   ' 1 ° inning 1 ° corso Licenziamento - Squadra in casa',
        1297:   ' 1 ° Inning 2 ° corso Licenziamento - Squadra in casa',
        1298:   ' 1 ° Inning 3 ° corso Licenziamento - Squadra in casa',
        1299:   ' 1 ° Inning 2 ° corso Licenziamento - Team Lontano',
        1300:   ' 1 ° Inning 3 ° corso Licenziamento - Team Lontano',
        1301:   ' 1 ° Inning 1 ° corso Licenziamento - Team Lontano',
        1302:   ' Miglior punteggio in corso - Under / Over',
        1303:   ' 1 ° Inning 2 ° Negli Under / Over - Squadra in casa',
        1304:   ' 1 ° Inning 3 ° Negli Under / Over - Squadra in casa',
        1306:   ' 1 ° Inning 3 ° Negli Under / Over - Team Lontano',
        1307:   ' 1 ° Inning 2 ° Negli Under / Over - Team Lontano',
        1309:   ' Margine Vittoria - Squadre',
        1310:   ' Margine Vittoria - Squadre compresi gli straordinari',
        1312:   ' Margine Vittoria esatta',
        1314:   ' Vincere Ranges Margine',
        1315:   ' Margine di vittoria esatta - Squadre',
        1316:   ' Margine Vittoria - squadre con qualsiasi altro risultato',
        1318:   ' Asiatici Falli disabili - 1 ° Periodo',
        1319:   ' Asian Handicap Fuorigioco - 1 ° Periodo',
        1320:   ' Handicap Asiatico Fuorigioco - 2 ° periodo',
        1321:   ' Asiatici Falli disabili - 2 ° periodo',
        1322:   ' Colpi di Handicap Asiatico On Target - 2 ° periodo',
        1324:   ' Under / Over Fuorigioco - 1 ° Periodo',
        1325:   ' Under / Over Fuorigioco - 2 ° periodo',
        1326:   ' prima Fallo',
        1327:   ' In primo luogo a due puntatori',
        1328:   ' Primi tre Pointer',
        1329:   ' Ultimo a due puntatori',
        1330:   ' Ultima Three Pointer',
        1331:   ' Tiro libero Ultima Segnato',
        1332:   ' Lancerà per primo libero Segnato',
        1333:   ' prima Rebound',
        1334:   ' Ultima Timeout',
        1335:   ' Margine Vittoria squadre - 1 ° Periodo',
        1336:   ' Margine Vittoria squadre - 2 ° periodo',
        1337:   ' Margine Vittoria squadre - 3 ° periodo',
        1338:   ' Margine Vittoria squadre - 4 ° periodo',
        1339:   ' Margine Vittoria squadre - 5 ° Periodo',
        1340:   ' Ultima Fallo',
        1341:   ' Under / Over 10 ° Periodo',
        1342:   ' Under / Over 11 Periodo',
        1343:   ' Under / Over 12 Periodo',
        1344:   ' Under / Over 13 Periodo',
        1345:   ' Under / Over 14 Periodo',
        1346:   ' Under / Over 15 Periodo',
        1347:   ' Under / Over 16 Periodo',
        1348:   ' Under / Over 17 Periodo',
        1349:   ' Under / Over 18 Periodo',
        1350:   ' Under / Over 19 Periodo',
        1351:   ' Under / Over 20 Periodo',
        1352:   ' Under / Over 21 ° Periodo',
        1353:   ' Under / Over 22 Periodo',
        1354:   ' Under / Over 23 Periodo',
        1355:   ' Under / Over 24 Periodo',
        1356:   ' Under / Over 25 Periodo',
        1357:   ' Under / Over 26 Periodo',
        1358:   ' Under / Over 27 Periodo',
        1359:   ' Under / Over 28 Periodo',
        1360:   ' Under / Over 29 Periodo',
        1361:   ' Under / Over 30 Periodo',
        1362:   ' Under / Over 31 Periodo',
        1363:   ' Under / Over 32 ° Periodo',
        1364:   ' Under / Over Periodo 33 °',
        1365:   ' Under / Over 34 ° Periodo',
        1366:   ' Under / Over 35 ° Periodo',
        1367:   ' Asian Handicap 11 Periodo',
        1368:   ' Asian Handicap 12 ° Periodo',
        1369:   ' Asian Handicap 13 Periodo',
        1370:   ' Asian Handicap 14 Periodo',
        1371:   ' Asian Handicap 15 Periodo',
        1372:   ' Asian Handicap 16 Periodo',
        1373:   ' Asian Handicap 17 Periodo',
        1374:   ' Asian Handicap 18 Periodo',
        1375:   ' Asian Handicap 19 Periodo',
        1376:   ' Asian Handicap 20 Periodo',
        1380:   ' Asian Handicap 24 Periodo',
        1381:   ' Asian Handicap 25 Periodo',
        1382:   ' Asian Handicap 26 Periodo',
        1383:   ' Asian Handicap 27 Periodo',
        1384:   ' Asian Handicap 28 Periodo',
        1385:   ' Asian Handicap 29 Periodo',
        1386:   ' Asian Handicap 30 Periodo',
        1390:   ' Asian Handicap 34 ° Periodo',
        1391:   ' Asian Handicap 35 ° Periodo',
        1392:   ' Alla fine in Top 10',
        1393:   ' Per rimanere',
        1394:   ' Asian Handicap 10 ° Periodo',
        1395:   ' Under / Over Player Points Powerplay',
        1396:   ' Under / Over Player Plus / Minus',
        1397:   ' Sotto Shots / Over Player',
        1398:   ' Top Victorian Club',
        1399:   ' Sotto Outs / Over Run',
        1400:   ' Under / Over Frames',
        1401:   ' Top Winner 20',
        1402:   ' 1st Round leader',
        1404:   ' 1st Round Six Shooter - Gruppo A',
        1406:   ' 1st Round 3 palle',
        1407:   ' 1st Round 18 buche match-up',
        1408:   ' partita Handicap',
        1409:   ' Squadra Con Top Bowler',
        1410:   ' Per vincere il Toss e la partita',
        1411:   ' Under / Over Torrette Destroyed',
        1412:   ' Under / Over Torrette Destroyed - 1 ° Periodo',
        1413:   ' Under / Over Torrette Destroyed - 2 ° periodo',
        1414:   ' Under / Over Torrette Destroyed - 3 ° periodo',
        1415:   ' Più alto tacco al 1 ° corso',
        1416:   ' Squadra in casa Under / Over Runs - 1 ° corso al 6 Nel corso',
        1417:   ' Posizione squadra Under / Over Runs - 1 ° corso al 6 Nel corso',
        1418:   " Squadra in casa Under / Over Runs - 1 ° corso all'8 corso",
        1419:   " Posizione squadra Under / Over Runs - 1 ° corso all'8 corso",
        1420:   " Squadra in casa Under / Over Runs - 1 ° corso al 10 ° corso",
        1421:   ' Posizione squadra Under / Over Runs - 1 ° corso al 10 ° corso',
        1422:   ' Per Chase Oltre 180 corre e squadra a vincere',
        1423:   ' Come gestisce molti saranno segnati dalla prima palla',
        1424:   ' Under / Over Partita Stumpings',
        1425:   ' 1 ° metodo Wicket',
        1426:   ' Prima palla della partita',
        1427:   ' Under / Over - 1 ° Inning',
        1428:   ' Top Club non vittoriana',
        1429:   ' Gran finale Vincitore',
        1431:   ' Per raggiungere il Grand Final',
        1432:   ' Per finire Not In Top 8',
        1434:   ' Under / Over Kills 4 ° periodo - Team Lontano',
        1435:   ' Under / Over Kills 5 ° Periodo - Team Lontano',
        1436:   ' Under / Over Kills 4 ° periodo - Squadra in casa',
        1437:   ' Under / Over Kills 5 ° Periodo - Squadra in casa',
        1438:    " Under / Over Temperature All'aeroporto (Celsius)",
        1439:   ' Asian Handicap - Full Time',
        1440:   ' Under / Over valore di tutti numeri estratti',
        1441:   ' Under / Over valore di tutti i numeri dispari Drawn',
        1442:   ' Under / Over valore di tutti i numeri pari Drawn',
        1443:   ' Più Anche sono disegnati dei numeri dispari',
        1444:   ' Numeri consecutivi sarà disegnato',
        1445:   ' Under / Esattamente / Over - Numero di dispari palline estratte',
        1446:   ' Under / Esattamente / Over - numero di palline numerate Anche Drawn',
        1447:   ' Somma di 5 Bonus Balls',
        1448:   ' Prima Bonus pallina estratta - Pari / Dispari',
        1449:   ' Ultimo Bonus pallina estratta - Pari / Dispari',
        1450:   ' Primo Bonus Ball',
        1451:   ' Ultima Bonus Ball',
        1452:   ' Il più basso di 5 palline bonus',
        1453:   ' Bonus Ball - Pari / Dispari',
        1454:   ' Numeri identici sarà disegnato',
        1455:   ' Numero più alto Drawn - Pari / Dispari',
        1456:   ' Numero più basso Drawn - Pari / Dispari',
        1457:   ' Palla 1 - Under / Over',
        1458:   ' Palla 1 - Pari / Dispari',
        1459:   ' Ball 2 - Under / Over',
        1460:   ' Ball 2 - Pari / Dispari',
        1461:   ' Ball 3 - Under / Over',
        1462:   ' Ball 3 - Pari / Dispari',
        1463:   ' Palla 4 - Under / Over',
        1464:   ' Palla 4 - Pari / Dispari',
        1465:   ' Palla 5 - Under / Over',
        1466:   ' Palla 5 - Pari / Dispari',
        1467:   ' Palla 6 - Under / Over',
        1468:   ' Palla 6 - Pari / Dispari',
        1469:   ' Palla 7 - Under / Over',
        1470:   ' Palla 7 - Pari / Dispari',
        1471:   ' Palla 8 - Under / Over',
        1472:   ' Palla 8 - Pari / Dispari',
        1473:   ' Palla 9 - Under / Over',
        1474:   ' Palla 9 - Pari / Dispari',
        1475:   ' Palla 10 - Under / Over',
        1476:   ' Palla 10 - Pari / Dispari',
        1477:   ' Palla 11 - Under / Over',
        1478:   ' Palla 11 - Pari / Dispari',
        1479:   ' Palla 12 - Under / Over',
        1480:   ' Palla 12 - Pari / Dispari',
        1481:   ' Palla 13 - Under / Over',
        1482:   ' Palla 13 - Pari / Dispari',
        1483:   ' Palla 14 - Under / Over',
        1484:   ' Palla 14 - Pari / Dispari',
        1485:   ' Palla 15 - Under / Over',
        1486:   ' Palla 15 - Pari / Dispari',
        1487:   ' Palla 16 - Under / Over',
        1488:   ' Palla 16 - Pari / Dispari',
        1489:   ' Palla 17 - Under / Over',
        1490:   ' Palla 17 - Pari / Dispari',
        1491:   ' Palla 18 - Under / Over',
        1492:   ' Palla 18 - Pari / Dispari',
        1493:   ' Palla 19 - Under / Over',
        1494:   ' Palla 19 - Pari / Dispari',
        1495:   ' Palla 20 - Under / Over',
        1496:   ' Palla 20 - Pari / Dispari',
        1497:   ' Almeno uno dei numeri estratti è divisibile per 3',
        1498:   ' Almeno uno dei numeri estratti è divisibile per 4',
        1499:   ' Almeno uno dei numeri estratti è divisibile per 5',
        1500:   ' Almeno uno dei numeri estratti è divisibile per 7',
        1501:   ' Almeno uno dei numeri estratti è divisibile per 10',
        1502:   ' Almeno uno dei numeri estratti è divisibile per 15',
        1503:   ' Almeno uno dei numeri estratti è divisibile per 20',
        1504:   ' Numero più alto Drawn - Under / Over',
        1505:   ' Valore totale dei più bassi e più alti numeri Drawn - Under / Over',
        1506:   ' Numero più basso Drawn - Under / Over',
        1507:   ' Differenza tra il più alto e il più basso numero Drawn - Under / Over',
        1508:   ' Valore totale del più basso e più alti numeri Drawn - Pari / Dispari',
        1509:   ' Differenza tra il più alto e il più basso numero Drawn - Pari / Dispari',
        1510:   ' In primo numero estratto è maggiore di The Last numero estratto',
        1511:   ' Ultimo numero estratto - Pari / Dispari',
        1512:   ' In primo numero estratto - Pari / Dispari',
        1513:   ' Tutti i numeri estratti Anche',
        1514:   ' Tutti i numeri estratti dispari',
        1515:   ' Numero di disegnare',
        1520:   ' 4 ° periodo - 1 ° Torretta',
        1521:   ' 5 ° Periodo - 1 ° Torretta',
        1523:   ' Under / Over Baroni Ucciso - 4 ° periodo',
        1524:   ' Under / Over Baroni Ucciso - 5 ° Periodo',
        1525:   ' Under / Over Dragons Ucciso - 4 ° periodo',
        1526:   ' Under / Over Dragons Ucciso - 5 ° Periodo',
        1527:   ' Under / Over Torrette Destroyed - 4 ° periodo',
        1528:   ' Under / Over Torrette Destroyed - 5 ° Periodo',
        1529:   ' 4 ° periodo Durata',
        1530:   ' 5 ° Periodo Durata',
        1531:   ' Pari / Dispari Kills - 4 ° periodo',
        1532:   ' Pari / Dispari Kills - 5 ° Periodo',
        1533:   ' Under / Over Inibitori - 1 ° Periodo',
        1534:   ' Under / Over Inibitori - 2 ° periodo',
        1535:   " Temperatura All'aeroporto a Range (Celsius)",
        1536:   ' Sotto Mappe / Oltre',
        1537:   ' Squadra in casa per vincere e Under / Over - compresi gli straordinari',
        1538:   ' Posizione squadra per vincere e Under / Over - compresi gli straordinari',
        1539:   ' Vincitore rotonda',
        1540:   ' Totale Incontro Gambe Under / Over',
        1541:   ' Rounds Handicap',
        1542:   ' 1st Leg Vincitore',
        1543:   ' Primo a vincere 3 gambe',
        1546:   ' 4 ° periodo - 1 ° Roshan',
        1547:   ' 5 ° Periodo - 1 ° Roshan',
        1548:   ' 4 ° periodo - 1 ° Caserma',
        1549:   ' 5 ° Periodo - 1 ° Caserma',
        1550:   ' 4 ° periodo - 1 ° tou',
        1551:   ' 5 ° Periodo - 1 ° tou',
        1552:   ' Asian Under / Over Corners',
        1553:   ' Entrambe le squadre segnano numero di punti - 1 ° Periodo',
        1554:   ' Entrambe le squadre segnano numero di punti - 1 ° semestre',
        1555:   ' Under / Over 5 ° Periodo - Squadra in casa',
        1556:   ' Under / Over 5 ° Periodo - Team Lontano',
        1558:   ' Punti handicap asiatico',
        1559:   ' Vincitore Coppa',
        1561:   ' 1 ° 3 inning Vincitore',
        1562:   ' 1 ° 3 inning Under / Over',
        1563:   ' Ora Segnare',
        1564:   ' Ora Segnare - Squadra in casa',
        1565:   ' Ora Segnare - Team Lontano',
        1566:   ' Match Time Risultato',
        1567:   ' Top Nazionalità - Sud Africa',
        1568:   ' Top Nazionalità - Lo spagnolo',
        1569:   ' Top Nazionalità - irlandese',
        1570:   ' Top Nazionalità - Sud America',
        1571:   ' Top Nazionalità - Canadian',
        1572:   ' Top Nazionalità - Englishman',
        1573:   ' Top Nazionalità - continentale europea',
        1575:   ' Fare Cut',
        1576:   ' Per la signorina Cut',
        1577:   ' Top Nazionalità - americana',
        1578:   ' Nazionalità del vincitore',
        1579:   ' Asian Punti disabili - 1 ° Periodo',
        1580:   ' Asian Punti disabili - 2 ° periodo',
        1581:   ' Asian Punti disabili - 3 ° periodo',
        1583:   ' Top Team Bowler 1 ° Inning - Squadra in casa',
        1584:   ' Top Team Bowler 1 ° Inning - Team Lontano',
        1585:   ' Top Team Battitore 1 ° Inning - Squadra in casa',
        1586:   ' Top Team Battitore 1 ° Inning - Team Lontano',
        1587:   ' Ci sarà un asso punto successivo',
        1589:   ' Ci sarà un doppio errore punto successivo',
        1591:   ' Under / Over - 1 ° corso',
        1592:   ' Per vittoria in campionato',
        1593:   ' Divisione Win Per',
        1594:   ' La maggior parte dei Hits - compresi gli straordinari',
        1595:   ' Combatti per andare lontano',
        1596:   ' Quando sarà la fine lotta',
        1597:   ' lotta Esito',
        1598:   ' giro di puntate',
        1599:   ' Top 1 ° Hit',
        1600:   ' Fondo prima Hit',
        1601:   ' Top 1st Run',
        1602:   ' Fondo prima Run',
        1603:   ' La maggior parte colpisce in primo Inning',
        1605:   ' Squadra con il più alto punteggio Inning',
        1606:   ' Entrambe le squadre segnano 2 o più gol',
        1607:   ' Entrambe le squadre segnano 3 o più gol',
        1608:   ' Squadra a vincere il maggior numero di periodi',
        1609:   ' Asian Handicap Full Time - 1 ° Periodo',
        1610:   ' Asian Handicap Full Time - 2 ° periodo',
        1611:   ' Asian Handicap Full Time - 3 ° periodo',
        1612:   ' MVP',
        1614:   ' Per Conference Win',
        1615:   ' Vincere Conference',
        1617:   ' Stagione Player Specials',
        1618:   ' 1 ° 5 inning Winner - 12',
        1619:   ' 1 ° Periodo - Parziale Vincitore Home / Lontano',
        1620:   ' 1 ° Periodo - Halftime Asian Handicap',
        1621:   ' 2 ° periodo - Fine del primo tempo Vincitore Home / Lontano',
        1622:   ' 2 ° periodo - Halftime Asian Handicap',
        1624:   ' Sotto Corre / Over Player',
        1625:   ' Sotto Singles / Over Player',
        1626:   ' Under / Over Player Home Runs',
        1627:   ' Sotto Basi / Over Player Stolen',
        1628:   ' Under / Over giocatore raddoppia',
        1629:   ' Under / Over Player Triple',
        1630:   ' Brocca per registrare la vittoria',
        1631:   ' Under / Over Player Totale Basi',
        1635:   ' Under / Over Esegue Pitcher guadagnati',
        1636:   ' Under / Over Hits Pitcher ammessi',
        1637:   ' Under / Over Strikeouts Pitcher',
        1638:   ' squadra Classificazione',
        1639:   ' Young Rider Classificazione',
        1640:   ' Classifica a punti',
        1641:   ' Re delle montagne',
        1642:   ' Fase 1',
        1649:   ' giro più veloce',
        1650:   ' macchina vincente',
        1651:   ' Primo Pilota Ritirato',
        1652:   ' Qualificazioni macchina vincente',
        1653:   ' Più veloce nelle prove 1',
        1654:   ' punti Finish',
        1655:   ' Il più veloce Qualifier',
        1657:   ' Fase 3',
        1659:   ' Primo costruttore pensionamento',
        1660:   ' Griglia Posizione del vincitore',
        1661:   ' Safety Car Periodo durante la gara',
        1662:   ' Numero di driver classificati',
        1663:   ' Virtuale Safety Car Periodo durante la gara',
        1664:   ' Hole Score',
        1667:   ' To Win Hole',
        1670:   ' Partita legato',
        1672:   ' Under / Over Goals - 2 ° tempo',
        1673:   ' Pari / Dispari Gironi 4 ° periodo',
        1674:   ' Pari / Dispari Gironi 5 ° Periodo',
        1675:   ' 2nd Round leader',
        1676:   ' Entrambe le squadre segnano - compresi gli straordinari',
        1677:   ' Ultima squadra a segnare - compresi gli straordinari',
        1678:   ' Top Nazionalità - Tedesco',
        1679:   ' Top Nazionalità - Austrian',
        1680:   ' Top Nazionalità - neozelandese',
        1681:   ' In alto a sinistra Player Handed',
        1682:   ' Top Amateur',
        1683:   ' Top Nazionalità - Coreano',
        1684:   ' Top Nazionalità - scandinavi',
        1685:   ' Top Nazionalità - Frenchman',
        1686:   ' Top ex vincitore',
        1687:   ' Top Nazionalità - Svedese',
        1688:   ' Top Nazionalità - giapponese',
        1689:   ' Top Nazionalità - Scotsman',
        1690:   ' Top Nazionalità - Belga',
        1691:   ' Europea 4 ° periodo Handicap',
        1692:   ' European Handicap 3 ° periodo',
        1693:   ' European Handicap 2 ° periodo',
        1694:   ' European Handicap 1 ° Periodo',
        1695:   ' Punteggio rotondo',
        1696:   ' Torneo punteggio vincente',
        1697:   ' Leading Score dopo round',
        1698:   ' A colpire il Fairway Con tee shot',
        1699:   ' Per colpire Verde In regolamento',
        1700:   ' 3rd Round leader',
        1702:   ' Under / Over giocatore tenta Passing',
        1704:   ' Touchdown il 1 ° periodo',
        1705:   ' Touchdown su 2 ° periodo',
        1706:   ' Touchdown su 3 ° periodo',
        1707:   ' Touchdown su 4 ° periodo',
        1708:   ' Under / Esattamente / Over - squadra di casa Touchdowns',
        1709:   ' Under / Esattamente / Over - Posizione squadra Touchdowns',
        1710:   ' Under / Esattamente / Over - Squadra di casa 1 ° semestre Touchdowns',
        1711:   ' Under / Esattamente / Over - Posizione squadra 1 ° semestre Touchdowns',
        1712:   ' 16 ° girone Vincitore - 4 ° periodo',
        1713:   ' 16 ° girone Vincitore - 5 ° Periodo',
        1714:   ' Under / Over tous Destroyed - 4 ° periodo',
        1715:   ' Under / Over tous Destroyed - 5 ° Periodo',
        1722:   ' Impostare su Fine per Nil',
        1723:   ' Under / Over Kills - 1 ° periodo - 1st Round',
        1724:   ' Under / Over Kills - 2 ° periodo - 1st Round',
        1728:   ' Entrambe le squadre segnano - Tempo Extra',
        1729:   ' Doppia Chance - Extra Time',
        1730:   ' Il gioco Vai a Super Over?',
        1731:   ' Doppia Chance 5 ° Periodo',
        1732:   ' Doppia Chance 6 ° Periodo',
        1733:   ' Doppia Chance 7 Periodo',
        1734:   ' Doppia Chance 8 ° Periodo',
        1736:   ' Numero di piste 1 ° Inning',
        1737:   ' Numero di piste 2 ° Inning',
        1738:   ' Numero di piste 3 ° Inning',
        1739:   ' Numero di piste 4 ° Inning',
        1740:   ' Numero di piste 5 ° Inning',
        1741:   ' Numero di piste 6 ° Inning',
        1742:   ' Numero di piste 7 ° Inning',
        1743:   ' Numero di piste 8 ° Inning',
        1744:   ' Un Cinquanta da segnati nel 1 ° inning',
        1745:   ' Fall Of prima Wicket',
        1747:   ' Avanti Man Out',
        1748:   ' Partita completato',
        1749:   ' Century Segnato',
        1750:   ' Ha segnato Secolo - 1 ° Inning',
        1752:   ' Posizione squadra prima Licenziamento 6-Way',
        1753:   ' Top Partita Batsman',
        1754:   ' A segnare un rigore',
        1758:   " 6 ° uomo dell'anno",
        1760:   ' Rookie of the Year',
        1761:   ' MVP della regular season',
        1763:   ' Battitore a segnare un centinaio in 1 ° inning',
        1765:   ' 9 Dart Fine nella partita',
        1766:   ' Tempo di prima prova',
        1767:   ' Tempo di ultimo tentativo',
        1770:   ' 9 Dart Fine nella partita - Squadra in casa',
        1771:   ' 9 Dart Fine nella partita - Team Lontano',
        1772:   ' Top Run Scorer',
        1773:   ' Top Run Scorer - Squadra in casa',
        1774:   ' Top Run Scorer - Team Lontano',
        1775:   ' Squadra di Top Scorer Run',
        1776:   ' Under / Over Punts',
        1777:   ' Under / Over Sacchi',
        1778:   ' Under / Over 1st Downs',
        1779:   ' 1 ° squadra per ottenere un primo down',
        1780:   ' Obiettivi 1 ° semestre Under / Over Field - Squadra in casa',
        1781:   ' Obiettivi 1 ° semestre Under / Over Field - squadra Lontano',
        1782:   ' Under / Esattamente / Over - Campo Obiettivi 1 ° semestre',
        1783:   ' Under / Over Touchdowns 1 ° Periodo',
        1784:   ' Sotto Sanzioni / Oltre Accettato',
        1785:   ' La maggior parte dei Kills nel match',
        1786:   ' Squadra con La maggior parte dei Kills nel match',
        1787:   ' Sotto Tentativi / Over Passo',
        1788:   ' Under / Over Passo Completamenti',
        1789:   ' Under / Over Passing Yards',
        1790:   ' Sotto Tentativi / Over Rush',
        1791:   ' Under / Over Rush Yards',
        1792:   ' Under / Over Player Arnesi e Assist',
        1794:   ' Squadra a Punt Prima',
        1795:   ' 1 ° Periodo 1X2 E Under / Over',
        1796:   ' 1X2 Assist',
        1797:   ' Doppia chance Assist',
        1798:   ' Squadra in testa dopo ogni periodo di',
        1799:   ' Squadra con la maggior parte Passing Yards',
        1800:   ' Squadra con la maggior parte Rushing Yards',
        1801:   ' Il successo 2 Conversione Point',
        1802:   ' Under / Over passaggi touchdown',
        1803:   ' Under / Over campo Obiettivi 1 ° Periodo',
        1804:   ' Punteggio in ultimi 2 minuti del 1 ° semestre',
        1805:   ' Squadra per la ricezione di apertura Kick Off',
        1806:   ' Under / Over squadra di casa Touchdowns - 1 ° periodo',
        1807:   ' Under / Over Squadra Touchdowns - 1 ° periodo',
        1808:   ' Under / Esattamente / Oltre Squadra di casa Touchdowns - 1 ° periodo',
        1809:   ' Under / Esattamente / Over Squadra fuori Touchdowns - 1 ° periodo',
        1810:   ' Under / Over squadra di casa di campo Obiettivi - 1 ° periodo',
        1812:   ' Under / Over gol della squadra ospite di campo - 1 ° periodo',
        1813:   ' Squadra in trasferta di vincere ogni trimestre',
        1814:   ' Squadra in casa di vincere ogni trimestre',
        1815:   ' Prima offensiva Play of the Game',
        1816:   ' To Win Quarter',
        1817:   ' Per raggiungere la finale',
        1818:   ' Squadra in casa a segnare 25 Gol',
        1819:   ' Squadra in casa a segnare 30 Gol',
        1820:   ' Squadra in trasferta a segnare 25 Gol',
        1821:   ' Squadra in trasferta a segnare 30 Gol',
        1822:   ' Inizio squadra a vincere due metà',
        1823:   ' Lontano squadra a vincere due metà',
        1824:   ' Fase di eliminazione',
        1825:   ' Under / Over Assist',
        1826:   ' Under / Over Rimbalzi',
        1827:   ' Under / Over casa Assist di squadra',
        1828:   ' Under / Over Squadra fuori Assist',
        1829:   ' Under / Over squadra di casa Rimbalzi',
        1830:   ' Under / Over Squadra ospite Rimbalzi',
        1831:   ' 1X2 3 punte Materiale',
        1832:   ' Under / Over 3 punte Materiale',
        1833:   ' Under / Over squadra di casa 3 punti fatti',
        1834:   ' Under / Over squadra 3 punti fatti',
        1835:   ' Asian Handicap 3 punte Materiale',
        1841:   ' prime Sostituzioni',
        1842:   ' 2 ° gol durante la partita',
        1843:   ' 3 ° gol nella partita',
        1846:   ' 60 Minute Risultato',
        1847:   ' 75 Risultato Minute',
        1848:   ' Gol segnato nei primi 5 minuti?',
        1849:   ' Primo passaggio in the Match',
        1850:   ' Disegnare per tempo',
        1851:   ' Guidato gol durante la partita?',
        1852:   ' Under / Over Goal Minuti',
        1853:   ' Under / Over Tie Break nel match',
        1854:   ' Sotto punti / Over Pausa',
        1855:   ' Under / Over punti di rottura della squadra di casa',
        1856:   ' Under / Over punti di rottura della squadra Lontano',
        1857:   ' Under / Over alto Quarter Scoring',
        1858:   ' Under / Over basso Quarter Scoring',
        1859:   ' Mete per 3-Way',
        1860:   ' 10 Minute Margine Vittoria 5-Way',
        1861:   ' La maggior parte delle perdite della squadra',
        1862:   ' Un sub segnerà nel gioco',
        1863:   ' Win Tutti i periodi - Squadra in casa',
        1864:   ' Win Tutti i periodi - squadra Lontano',
        1865:   ' I primi 10 minuti Tentativi Under / Over',
        1866:   ' 15 Minute Under / Over - Corners',
        1867:   ' 2 Gol in una riga dal Team',
        1868:   ' 3 Gol in una riga dal Team',
        1869:   ' Mete per convertiti',
        1872:   ' Testa di serie Finalista?',
        1873:   ' Wild Card in finale?',
        1874:   ' Vincere Mezza - Vincitore, Tennis',
        1875:   ' Vincere Quarter - Vincitore, Tennis',
        1881:   ' Buca in uno',
        1882:   ' Ci sarà un Play-off?',
        1883:   ' 72 Hole coincidenti',
        1884:   ' 54 Hole coincidenti',
        1885:   ' Numero 1 draft',
        1886:   ' 1X2 e Doppia Chance',
        1887:   ' Squadra in casa per vincere \\ Draw e entrambe le squadre',
        1888:   ' Posizione squadra per vincere \\ Draw e entrambe le squadre',
        1889:   ' Margine Vittoria - 1 ° semestre',
        1891:   ' Lega americana Cy Young',
        1892:   ' American League MVP',
        1893:   ' National League Cy Young',
        1894:   ' National League MVP',
        1895:   ' Brocca per registrare il maggior numero di vittorie Regular Season',
        1896:   ' Lettore di colpire la maggior parte dei Regular Season funzionamenti domestici',
        1897:   ' Will gioco Deciso nei tempi supplementari?',
        1898:   ' Stagione regolare Vincere%',
        1900:   ' Player Capocannoniere',
        1901:   ' Under / Over Arnesi',
        1902:   ' Numero 2 draft',
    }
}