export const ru = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Виды спорта',
    live: 'ЖИТЬ',
    inplay: 'ЖИТЬ',
    today: 'Сегодня',
    upcoming: 'предстоящий',
    tomorrow: 'Завтра',
    highlights: 'Особенности',
    results: 'Полученные результаты',
    lastMinute: 'Последняя минута',
    liveCasino: 'Live Casino',
    casino: 'Казино',
    myBets: 'Мои ставки',
    home: 'Дом',
    login: 'Авторизоваться',
    signup: 'Зарегистрироваться',
    betslip: 'КУПОН',
    search: 'Поиск',
    searchHere: 'Поищи здесь',
    transaction: 'Сделка',
    changePassword: 'Измени пароль',
    logout: 'Выйти',
    single: 'Одинокий',
    multiple: 'Несколько',
    possibleWin: 'Возможная победа',
    amount: 'Количество',
    ok: 'ОК',
    totalOdds: 'Общие коэффициенты',
    odd: 'Странный',
    totalPossibleWin: 'Общий возможный выигрыш',
    placeBet: 'Сделать ставку',
    fastBet: 'Быстрый держать пари',
    fast: 'Быстрый',
    bet: 'держать пари',
    username: 'Имя пользователя',
    password: 'Пароль',
    stayLoggedIn: 'Оставаться в системе',
    all: 'Все',
    "in game": 'В игре',
    won: 'Победил',
    lost: 'Потерянный',
    refund: 'Возврат',
    date: 'Дата',
    winAmount: 'сумма выигрыша',
    stake: 'доля',
    pick: 'Выбирать',
    myAccount: 'Мой аккаунт',
    football: 'Футбол',
    basketball: 'Баскетбол',
    iceHockey: 'Хоккей',
    tennis: 'Большой теннис',
    volleyball: 'Волейбол',
    boxing: 'Заниматься боксом',
    americanFootball: 'американский футбол',
    baseball: 'Бейсбол',
    hockey:	'Хоккей',
    tableTennis: 'Настольный теннис',
    badminton: 'Бадминтон',
    cricket: 'Крикет',
    futsal:	'Футзал',
    golf: 'Гольф',
    handball: 'Гандбол',
    rugbyLeagues: 'Лиги регби',
    pickCanNotBeCombined: 'Этот выбор не может быть объединен',
    matchStopped: 'матч остановлен',
    favorites: 'Избранные',
    cashout: 'Обналичить',
    "in progress": 'В ходе выполнения',
    rejected: 'Отклоненный',
    tip: 'Кончик',
    noGoal: 'Нет цели',
    yes: 'да',
    over: 'Над',
    under: 'Под',
    even: 'Четный',
    areYouSureWantCashout: 'Вы уверены, что Вы хотите Выплату?',
    noGoal: 'Нет',
    bonus: 'бонус',
    termsAndConditon: 'Условия и положения',
    aboutUs: 'О нас',
    contactUs: 'Свяжитесь с нами',
    payments: 'Платежи',
    howPlacebet: 'Как разместить ставку',
    deposit: 'депозит',
    credit: 'Кредит',
    debit: 'Списание средств',
    withdraw: 'Отзывать',
    totalOdd: 'Всего Одд',
    totalStake: 'Итого Доля',
    topGames: 'Лучшие игры',
    allGames: 'Все игры',
    canNotBeCombined: 'Это Пика не могут быть объединены',
    betSettled: 'Ставка рассчитана',
    betSuspended: 'Bet Подвесные',
    insufficientBalance: 'Недостаточный баланс',
    oldPassword: 'Прежний пароль',
    newPassword: 'Новый пароль',
    confirmPassword: 'Подтвердите новый пароль',
    change: 'Изменять',
    fromDate: 'С даты',
    toDate: 'На сегодняшний день',
    activityType: 'Вид деятельности',
    show: 'Показывать',
    gameType: 'Тип игры',
    repeatBet: 'Повторить ставки',
    someThingWrong: 'Что-то пошло не так',
    status: 'Статус',
    coupon: 'Купон',
    acceptOddChangesText: 'Вы должны принять изменения, чтобы иметь возможность сделать ставку',
    turnOffAway: 'Выключите все равно',
    acceptOddChanges: 'Принимать нечетные изменения',
    maxWinAmount: 'Максимальная сумма выигрыша',
    maxOdd: 'Макс нечетным',
    dateTime: 'Дата / время',
    type: 'Тип',
    refreshCashout: 'Обновить Cashout',
    vendor: 'Продавец',
    stakeLowerThen1: 'Ставка должна быть меньше',
    stakeGreaterThen1: 'Ставка должна быть больше',
    ESports: 'Esports',
    KSports: 'Ksports',
    promotion: 'Продвижение',
    customerServicePage: 'Страница обслуживания клиентов',
    decimal: 'Десятичный',
    fraction: 'Доля',
    americanOdds: 'Американские шансы',
    nothingFound: 'Ничего не найдено',
    noLiveMatch: 'Ни один живой матч не доступен',
    sportBook: 'Спортивная книга',

    marketNames: {
        1: ' 1х2',
        2: ' Под более',
        238: ' Оставшийся матч',
        59: 'Следующий гол',
        7: ' Двойной шанс',
        52: ' Ничья-без ставок',
        226: ' 12 Включая Сверхурочные',
        13: ' Европейский гандикап',
        4: 'HT / FT',
        17: ' Обе команды забьют',
        59: ' Следующий гол',
        28: ' Меньше / Больше включая сверхурочные',
        247: ' Оставшийся 1-й тайм',
    },
    tabNames: {
        'All': 'Bce',
        'Main Markets': 'Основные рынки',
        'Totals': 'общие данные',
        '1st Half': '1-й тайм',
        '2nd Half': '2-й тайм',
        'Home Team': 'Домашняя команда',
        'Away Team': 'команда гостей',
        'All Periods': 'Все периоды',
        'Results': 'Полученные результаты',
        'Home / Away': 'Главная / Away',
    },
    markets: {
        1:  '1x2',
        2: 'Под более0',
        3: 'Азиатский гандикап0',
        5: 'Чет / нечет0',
        6: 'Точный счет0',
        7: 'Двойной шанс0',
        9: ' Счёт первый период0',
        11: 'Всего уголки0',
        12: 'Всего фолы0',
        13: 'Европейский гандикап0',
        16: 'Первый гол0',
        17: 'Обе команды забьют0',
        19: 'Первая карта0',
        21: 'Под / За 1-й период0',
        22: 'Гости команда забьет0',
        23: 'Главная забьет0',
        25: 'Двойной шанс Первая половина матча завершилась0',
        29: 'Меньше / Больше раундов0',
        30: 'Над / под Уголки - Home Team0',
        31: 'Над / под Уголки - Away Team0',
        34: 'Главная команда забьет в обоих таймах0',
        35: 'Команда гостей забьет в обоих таймах0',
        41: '1-й период Победитель0',
        42: '2-й период Победитель0',
        43: '3-й период Победитель0',
        44: 'Четвёртый Период Победитель0',
        45: 'Под / За 2-й период0',
        46: 'Под / За 3-й период0',
        47: 'Под / За 4 Периода0',
        48: 'Под / За 5 Периода0',
        49: 'Период пятого Победитель0',
        50: '1X2 включая сверхурочные0',
        51: 'Чет / нечет включая сверхурочные0',
        52: '120',
        53: 'Азиатский гандикап Тайм0',
        55: 'Первая команда забьет 1-й период0',
        56: 'Последняя команда забьет0',
        57: 'Последняя команда забьет Тайм0',
        59: 'Следующий гол0',
        61: 'Европейский гандикап Тайм0',
        62: 'Чет / нечет Тайм0',
        63: '12 Тайм0',
        64: 'Азиатский гандикап первого периода0',
        65: 'Азиатский гандикап второй период0',
        66: 'Азиатский гандикап третий период0',
        67: 'Азиатский гандикап четвёртый Период0',
        68: 'Азиатский гандикап пятый период0',
        69: 'Будет ли игра к сверхурочной?0',
        70: 'Самый результативный Период0',
        71: 'Половина с наивысшим счетом0',
        72: 'Первый период чет / нечет0',
        73: 'Период второй чет / нечет0',
        74: '3-й период чет / нечет0',
        75: 'Четвёртый Период чет / нечет0',
        76: 'Период пятого чет / нечет0',
        77: 'Над / под Тайм0',
        78: 'Забьет больше Шестерки0',
        79: 'Будет ли назначен пенальти?0',
        80: 'Первый угол0',
        81: 'Последний Corner0',
        82: 'Будет ли красная карточка будет показана?0',
        83: 'Для того, чтобы сохранить чистый лист0',
        84: 'Выиграет в обоих таймах0',
        85: 'To Win сзади0',
        86: 'Выиграет всухую0',
        88: 'Квалифицируется0',
        89: 'Будет ли первая попытка быть конвертированы?0',
        90: 'Будет ли матч быть решен в 5-м сете?0',
        91: 'В каком Half Первая цель будет забито?0',
        92: 'Первая команда забьет 2-й тайм0',
        95: 'уголки форы0',
        96: 'Команда гостей выиграет всухую0',
        97: 'Главная Команда выиграет всухую0',
        98: 'Главная Команда Чтобы сохранить чистый лист0',
        99: 'На выезде команда держать чистый лист0',
        100: 'Точный счет второго периода0',
        101: 'Под / Over - Home Team0',
        102: 'Под / Over - Away Team0',
        104: 'Команда гостей выиграет в обоих таймах0',
        105: 'Главная команда выиграет в обоих таймах0',
        106: 'Главная Команда выиграет из-за0',
        107: 'Команда гостей выиграет сзади0',
        108: 'Главная забьет с пенальти0',
        109: 'Команда гостей забьет с пенальти0',
        110: 'Команда гостей выиграет не менее половины0',
        111: 'Главная команда, чтобы выиграть не менее половины0',
        113: 'Обе команды забьют 1-й тайм0',
        114: 'Любой Score игрока по крайней мере, 2 гола0',
        117: 'Любой результативный игрок, по крайней мере 3 гола0',
        122: 'Команда Scoring первых, чтобы выиграть матч0',
        123: 'На выезде команда забьет первый и выиграть матч0',
        124: 'Главная команда забьет первый и выиграть матч0',
        128: 'Количество голов0',
        129: 'Над / под Уголки - 1-й половины0',
        132: 'Над / под Удары в створ0',
        133: 'Меньше / Больше Всего снимков0',
        134: 'Количество голов 1-й тайм0',
        136: 'Под / Над офсайдов0',
        137: 'Главная Выиграй Нет Bet0',
        138: 'Вдали Win Нет Bet0',
        139: 'В какой половине дома команда забьет первый гол?0',
        140: 'В какой половине Ушел команда забьет первый гол?0',
        143: 'В какой половине Хозяева забьет больше голов?0',
        144: 'В какой половине команда гостей забьет больше голов?0',
        145: 'Главная Команда тотал В 1-м тайме0',
        146: 'Команда гостей Количество голов в 1-м тайме0',
        147: 'Команда гостей Количество голов в 2-й тайм0',
        148: 'Главная Команда тотал во 2-й половине0',
        149: 'Главная Команда тотал0',
        150: 'Команда гостей тотал0',
        151: 'Двойной шанс второй тайм0',
        153: 'Под / За 1-й период - Home Team0',
        154: 'Под / За 2-й период - Home Team0',
        155: 'Под / За 1-й период - команда гостей0',
        156: 'Под / За 2-й период - команда гостей0',
        157: 'Меньше / Больше желтых карточек - 1-й половины0',
        158: 'Над / под Желтые карточки0',
        159: 'Будет тай-брейк в матче?0',
        160: 'Победитель гонки0',
        161: '10 Минута Результат0',
        162: '10 минуты больше / меньше0',
        163: 'Число голов 2-й тайм0',
        164: 'Будет тай-брейк в первом сете?0',
        165: 'Под / над множествами0',
        166: 'Над / под игры0',
        168: 'Будет автогол?0',
        169: 'Забьет в обоих таймах0',
        170: 'Чет / нечет Уголки0',
        171: 'Выиграют полудуплексный0',
        175: 'Будет тай-брейк во втором сете?0',
        176: 'Будет тай-брейк в третьем сете?0',
        177: 'Будет тай-брейк в четвертом сете?0',
        178: 'Будет тай-брейк в пятом сете?0',
        180: 'Над / под вбрасывания0',
        181: 'Над / под Желтые карточки - Главная команда0',
        182: 'Над / под Желтые карточки - Home Team 1 полугодие0',
        183: 'Над / под Желтые карточки - Home Team 2-й тайм0',
        184: 'Меньше / Больше желтых карточек - Away Team0',
        185: 'Меньше / Больше желтых карточек - Away Team 1 полугодие0',
        186: 'Меньше / Больше желтых карточек - Away Team 2-й тайм0',
        187: 'Меньше / Больше желтых карточек - 2-й тайм0',
        188: 'Первая желтая карточка0',
        190: 'Первая желтая карточка вторая половина0',
        191: 'Будет ли какой-нибудь игрок хет-трик?0',
        192: 'Будет ли гол забито в обоих таймах?0',
        194: 'Главная Команда Чтобы сохранить чистый лист 1-й период0',
        195: 'Главная Команда Чтобы сохранить чистый лист 2-й период0',
        196: 'На выезде команда держать чистый лист 1-й период0',
        197: 'На выезде команда держать чистый лист 2-й период0',
        198: 'Чет / нечет - хозяева0',
        199: 'Чет / нечет - Away Team0',
        200: 'Будет ли Первый гол команды Win?0',
        201: 'Азиатские Игры для инвалидов0',
        202: '1-й период Победитель Home / Away0',
        203: '2-й период Победитель Home / Away0',
        204: '3-й период Победитель Home / Away0',
        205: 'Четвёртый Период Победитель Главный / Away0',
        206: 'Период пятый победитель Home / Away0',
        207: '15 Минута Результат0',
        208: '30 Минута Результат0',
        209: 'Всего Уголки - первый тайм0',
        210: 'Всего Уголки - вторая половина0',
        211: 'Обе команды забьют 2-й тайм0',
        212: 'Обе команды забьют в обоих таймах0',
        213: 'Ничья в обоих таймах0',
        214: 'Под / оверкарт0',
        215: 'На выезде команда забьет 1-й тайм0',
        216: 'Гости команда забьет 2-й тайм0',
        217: 'Точный счет третьего периода0',
        218: 'Главная команда забьет 1-й тайм0',
        219: 'Главная команда забьет 2-й тайм0',
        220: 'Под / Over - Away Team включая сверхурочные0',
        221: 'Меньше / Больше - Home Team включая сверхурочные0',
        222: 'Под / За 3-й период - команда гостей0',
        223: 'Под / За 3-й период - Home Team0',
        224: 'Двойной шанс третий период0',
        225: 'Двойной шанс четвёртого Период0',
        226: '12 Включая Сверхурочные0',
        227: 'Победитель гонки 2 места0',
        228: 'Победитель гонки 3 места0',
        229: 'Победитель гонки 4 мест0',
        233: 'To Win не всухую0',
        235: 'Первые 5 Innings Победитель0',
        236: 'Первые 5 Innings больше / меньше0',
        237: 'Будет Там быть забьет в 1-й Inning0',
        238: 'Оставшийся матч0',
        239: 'Цели Диапазон0',
        242: '1-й период чет / нечет - хозяева0',
        243: 'Первый период чет / нечет - Away Team0',
        244: 'Самая высокая Partnership Открытие0',
        245: 'Цель / Нет цели0',
        247: 'Оставшийся 1-й тайм0',
        250: 'Уголки фора - 1-й тайм0',
        254: 'Диапазон карты0',
        255: 'Время 1-й карты0',
        256: 'Главная Команда - Карты Диапазон0',
        257: 'На выезде команда - карты Диапазон0',
        258: 'уголки Диапазон0',
        259: 'Команда с наибольшим Уголки0',
        260: 'Команда с наибольшим 1-й тайм Уголки0',
        261: 'Команда с наибольшим 2-й тайм Уголки0',
        262: 'Главная Команда - Уголки Range0',
        263: 'На выезде команда - Уголки Range0',
        264: 'Главная Команда - первая половина Уголки Диапазон0',
        265: 'На выезде команда - первая половина Уголки Диапазон0',
        266: 'Главная Команда - вторая половина Уголки Диапазон0',
        267: 'На выезде команда - вторая половина Уголки Диапазон0',
        269: 'Цели Диапазон первый период0',
        271: 'шанс Mix0',
        274: 'Победитель0',
        275: 'Цели Диапазон второго периода0',
        278: 'Цели Диапазон Главная Команда0',
        279: 'Цели Диапазон Away Team0',
        281: 'Первые 5 Innings Азиатский гандикап0',
        282: '1X2 Тайм0',
        283: 'Азиатский гандикап второй тайм0',
        284: '1X2 второй тайм0',
        285: 'Чет / нечет вторая половина0',
        286: '12 вторая половина0',
        287: 'Под / За 4 период - Home Team0',
        288: 'Под / За 4 период - команда гостей0',
        289: 'Период второй чет / нечет - хозяева0',
        290: '3-й период чет / нечет - хозяева0',
        291: 'Четвёртый Период чет / нечет - хозява0',
        292: 'Период второй чет / нечет - Away Team0',
        293: '3-й период чет / нечет - Away Team0',
        294: 'Четвёртый Период чет / нечет - Away Team0',
        297: 'SP0',
        299: 'Точка Winner0',
        300: 'Игра Winner0',
        301: 'Цель Перед0',
        302: 'Цель После0',
        304: 'Команда с самым результативным периодом0',
        305: 'Углы - Меньше / Ровно / Over0',
        306: 'Первый гол Последний гол0',
        307: 'Европейские Игры для инвалидов0',
        308: 'Под / Над самой результативной период0',
        309: 'Под / За Низшая Scoring период0',
        310: 'Серия Winner0',
        317: 'Под / Ровно / Over - 1-й период0',
        318: 'Под / Ровно / Over - 2-й период0',
        319: 'Под / Ровно / Over - 3-й период0',
        320: 'Под / Ровно / Over - 4-й период0',
        322: 'Под / Ровно / Over0',
        329: 'Первый период гонки Для0',
        330: 'Период второй гонки Для0',
        331: '3-й период гонки Чтобы0',
        332: 'Четвёртый Период гонка Для0',
        333: 'Пятый период гонки Чтобы0',
        337: 'Под / Over - 2-й тайм0',
        338: 'Следующая цель первого периода0',
        339: 'Следующая цель второго периода0',
        341: 'Race Для0',
        342: 'Азиатский гандикап включая сверхурочные0',
        343: 'Игра Диапазон первого периода0',
        344: 'Игра Диапазон второй период0',
        345: 'Игра Диапазон третьего периода0',
        346: 'Игра Диапазон четвёртый Период0',
        347: 'Игра Диапазон пятого периода0',
        348: 'Шестых Период Победитель0',
        349: 'Седьмой Период Победитель0',
        350: 'Период шестой чет / нечет0',
        351: 'Седьмой Период чет / нечет0',
        352: 'Под / За 6 Периода0',
        353: 'Под / За 7 Периода0',
        354: 'Меньше / Больше Тайм - Home Team0',
        355: 'Меньше / Больше Тайм - Away Team0',
        356: 'Метод Увольнение 6-Way0',
        357: 'Увольнение метод0',
        366: 'Запускает чет / нечет в Over - Home Team0',
        367: 'Всего Запускает чет / нечет в Over - Away Team0',
        370: 'Общее Работает в Over - Home Team0',
        371: 'Всего Запускается в Over - Away Team0',
        386: 'Европейский гандикап включая сверхурочные0',
        387: 'Чет / нечет - Главная команда, включая сверхурочные0',
        388: 'Чет / нечет - На выезде команда, включая сверхурочные0',
        390: 'HT / FT включая сверхурочные0',
        391: 'Следующая цель - Дополнительное время0',
        392: 'Главная Команда Пенальти0',
        393: 'Вдали Penalty Shootout команды0',
        394: 'Счет четвёртый Период0',
        395: 'Счёт пятый период0',
        398: 'Чет / нечет карты0',
        400: 'Чет / нечет карты 1-й тайм0',
        401: 'Под / За Уголки 1 полугодие - Home Team0',
        402: 'Меньше / Больше Уголки 1-м тайме - команда гостей0',
        403: 'Над / под Уголки 2-й тайм0',
        404: 'Под / Над Уголки 2-й тайм - Home Team0',
        405: 'Меньше / Больше Уголки 2-й тайм - Away Team0',
        406: 'Чет / нечет Уголки первая половина0',
        407: 'Азиатский гандикап карты0',
        408: 'Азиатский гандикап карты 1-й тайм0',
        409: '1X2 уголки0',
        410: '1X2 Уголки первая половина0',
        411: '12 уголки0',
        414: 'Под / оверкарт 1 полугодие0',
        415: '1X2 И Обе команды забьют0',
        416: 'Под / Over - Дополнительное время0',
        417: 'Под / Over - Extra Time 1 полугодие0',
        419: '1X2 - Дополнительное время0',
        427: '1X2 И больше / меньше0',
        428: 'Многократное Счёт0',
        430: 'Под / Ровно / Over - 1-й половины0',
        431: 'Под / Ровно / Over - 2-й тайм0',
        433: 'Европейские Фора Уголки0',
        434: 'Восьмой Период Победитель0',
        435: 'Девятый Период Победитель0',
        436: 'Под / За 8 Периода0',
        437: 'Под / За 9 Периода0',
        438: 'Всего Запускает Odd / Even в Овер0',
        439: 'Всего Запускается в Овер0',
        445: 'Шестых Период Победитель Главная / Away0',
        446: 'Седьмой Период Победитель Главный / Away0',
        447: 'Азиатский гандикап шестой период0',
        448: 'Азиатский гандикап седьмой период0',
        449: 'Азиатский гандикап восьмой Период0',
        450: 'Азиатский гандикап девятый Период0',
        451: 'Восьмой Период Победитель Главный / Away0',
        452: 'Девятый Период Победитель Главный / Away0',
        453: 'Первый счет Method0',
        454: 'Первый метод Score - Home Team0',
        455: 'Первый счет Method - Away Team0',
        456: 'Двойной шанс 1-й период0',
        457: 'Двойной шанс второй период0',
        459: 'Главная команда забьет в каждой четверти0',
        460: 'Команда гостей забьет в каждой четверти0',
        462: 'Топ 5 Winner0',
        463: 'Топ 10 Победитель0',
        464: '12 вторая половина включая сверхурочные0',
        465: 'Четвёртый Период Победитель Главный / Away включая сверхурочный0',
        466: 'Под / За четвёртую период, включая сверхурочные0',
        467: 'Азиатский гандикап четвёртую период, включая сверхурочные0',
        468: 'Азиатский гандикап второй половины включая сверхурочные0',
        469: 'Больше / меньше 2-й тайм включая сверхурочные0',
        472: 'Будет ли игра Перейти на выбывание?0',
        475: 'Под / Over Запускает В Over - Home Team 1-й иннинг0',
        476: 'Над / под Runs In Over - Away Team 1-й иннинг0',
        477: 'Под / Over Запускает В Over - Home Team 2-й иннинг0',
        478: 'Над / под Runs In Over - Away Team 2-й иннинг0',
        479: 'Чет / нечет Runs In Over - Home Team 1-й иннинг0',
        480: 'Чет / нечет Runs In Over - Away Team 1-й иннинг0',
        481: 'Чет / нечет Runs In Over - Home Team 2-й иннинг0',
        482: 'Чет / нечет Runs In Over - Away Team 2-й иннинг0',
        483: 'Будет Главная Команда Get калитки в Over - 1-й иннинг?0',
        484: 'Буду Away Team Get калитка в Over - первый иннинг?0',
        485: 'Будет Главная Команда Get калитки в Over - 2-й иннинг?0',
        486: 'Будешь Away Team Get калитка в Over - второй Inning?0',
        523: 'Под / Over And Обе команды забьют0',
        524: 'Первые 7 Innings Победитель0',
        525: 'Первые 7 Innings больше / меньше0',
        526: 'Первые 7 Innings Азиатский гандикап0',
        529: 'Чет / нечет второй половины включая сверхурочные0',
        553: 'Меньше / Больше выбывание Пенальти Взятые0',
        554: '12 выбывание0',
        555: 'Азиатский гандикап выбывание0',
        556: 'Счёт на выбывание0',
        557: 'Над / под выбывание0',
        558: 'Меньше / Больше выбывание - Home Team0',
        559: 'Меньше / Больше выбывание - Away Team0',
        560: 'Команда Чтобы принять Последнее выбывание казнь0',
        563: 'Гонка В том числе сверхурочные0',
        564: '1X2 И больше / меньше 5 способов0',
        566: 'Десятый Период Winner Главного / Away0',
        567: '11 Период Winner Главная / Away0',
        568: '12 Период Winner Главная / Away0',
        569: '13 Период Winner Главная / Away0',
        570: 'Четырнадцатого Период Победитель Главная / Away0',
        571: 'Пятнадцатый Период Победитель Главная / Away0',
        573: 'Шестнадцатого Период Победитель Главная / Away0',
        574: 'Семнадцатый Период Победитель Главная / Away0',
        575: 'Восемнадцатый Период Победитель Главная / Away0',
        576: 'Девятнадцатый Период Победитель Главная / Away0',
        577: '10 минуты над / под - Уголки0',
        578: '10 минута над / под - карты0',
        579: 'Следующий Уголок0',
        586: 'Закончить в Top Half0',
        587: 'Закончить в нижней половине0',
        588: 'Для Дно0',
        589: 'Не закончить в Top 40',
        590: 'Для низведена0',
        591: 'Не быть понижены0',
        592: 'Для того, чтобы достичь окончательного0',
        593: 'Финиширует в Дно 60',
        594: 'Для того, чтобы быть Повышен0',
        595: 'Первый гол Время0',
        596: 'Цели Диапазон Главная Команда первого периода0',
        597: 'Цели Диапазон Главная Команда второго периода0',
        598: 'Цели Диапазон Away Team 1-й период0',
        599: 'Цели Диапазон Away Team 2-й период0',
        600: 'Первый период Победитель и обе команды забьют0',
        601: 'HT / FT Двойной шанс0',
        602: 'Счёт включая сверхурочные0',
        603: 'Отрицательный HT / FT0',
        604: 'Цели Диапазон Главная Команда И Периоды0',
        605: 'Цели Диапазон Вдали Команда обоих периодов0',
        606: 'Победитель и цели Тип One0',
        607: 'Двойной шанс и цели0',
        608: 'Победитель и цели Тип Два0',
        609: 'Обе команды Score Чтобы и цели0',
        610: 'Двойной шанс Mix0',
        628: 'Финишировать в Топ 70',
        629: 'Финишировать в Топ 60',
        630: 'Финишировать в Топ 30',
        631: 'Финишировать в Топ 20',
        632: 'Закончить в Top 40',
        633: 'Финишировать в Топ 50',
        634: 'Чет / нечет раундов 1-й период0',
        635: 'Чет / нечет раундов 2-й период0',
        636: 'Чет / нечет раундов 3-й период0',
        637: 'Будет Там Быть Ace?0',
        638: 'Будет Там Быть нож убийство?0',
        639: 'Первый раунд Победитель0',
        640: 'Шестнадцатый раунд Победитель0',
        641: 'Будет ли нож убить? - первый период0',
        642: 'Будет ли нож убить? - второй период0',
        643: 'Будет ли нож убить? - третий период0',
        644: 'Будет Там Быть Ace? - первый период0',
        645: 'Будет Там Быть Ace? - второй период0',
        646: 'Будет Там Быть Ace? - третий период0',
        647: 'Будет Там Быть сверхурочные? - первый период0',
        648: 'Будет Там Быть сверхурочные? - второй период0',
        649: 'Будет Там Быть сверхурочные? - третий период0',
        650: 'Шестнадцатый раунд Победитель - 1-й период0',
        651: 'Шестнадцатый раунд Победитель - второй период0',
        652: 'Шестнадцатый раунд Победитель - третий период0',
        653: 'Первый раунд Победитель - первый период0',
        654: 'Первый раунд Победитель - второй период0',
        655: 'Первый раунд Победитель - третий период0',
        660: 'Игра Продолжительность0',
        662: 'Чет / нечет раундов0',
        663: 'Первый период Продолжительность0',
        664: '2-й период Продолжительность0',
        665: '3-й период Продолжительность0',
        666: '1-й период - первые Рошане0',
        667: '2-й период - первые Рошане0',
        668: 'Третий период - первые Рошане0',
        669: '1-й период - первая кровь0',
        670: '2-й период - первая кровь0',
        671: 'Третий период - первая кровь0',
        672: 'Первый период - первые доу0',
        673: '2-й период - первые доу0',
        674: 'Третий период - первые доу0',
        675: '1-й период - 1-й Barracks0',
        677: '2-й период - 1-й Barracks0',
        678: '3-й период - 1-й Barracks0',
        679: 'Первый период - первый револьверный0',
        680: '2-й период - первый револьверный0',
        681: 'Третий период - первый револьверный0',
        682: 'Race Для Kills - первый период0',
        683: 'Race Для Kills - второй период0',
        684: 'Race Для Kills - третий период0',
        685: 'Обе команды, чтобы убить дракона - 1-й период0',
        686: 'Обе команды, чтобы убить дракона - 2-й период0',
        687: 'Обе команды, чтобы убить дракона - 3-й период0',
        688: 'Обе команды, чтобы убить Ингибитор - 1-й период0',
        689: 'Обе команды, чтобы убить Ингибитор - 2-й период0',
        690: 'Обе команды, чтобы убить Ингибитор - 3-й период0',
        691: 'Чет / нечет убийства0',
        692: 'Чет / нечет Убивает - 1-й период0',
        693: 'Чет / нечет Убивает - 2-й период0',
        694: 'Чет / нечет Убивает - 3-й период0',
        695: 'HT / FT и цели0',
        697: 'Финишировать в Топ 80',
        711: 'Игрок забьет гол в любое время0',
        712: 'Первый игрок0',
        713: 'Последний игрок0',
        714: 'Игрок забьет 2 или больше голов0',
        715: 'Игрок забьет 3 или больше голов0',
        727: '1-й период Обе команды забьют0',
        728: '2-й период Обе команды забьют0',
        729: '3-й период Обе команды забьют0',
        730: 'Четвёртую Период Обе команды забьют0',
        731: 'Первый период Главная забьет0',
        732: '2-й период Главная забьет0',
        733: '3-й период Главная забьет0',
        734: 'Четвёртый Период Главных забьет0',
        735: '1-й период Вдали забьет0',
        736: '2-й период Вдали забьет0',
        737: '3-й период Вдали забьет0',
        738: 'Четвёртый Период Вдали забьет0',
        751: 'Первая половина гонки Чтобы0',
        752: 'Вторая половина гонки To - включая сверхурочные0',
        754: 'Игра в двойке0',
        755: 'Дополнительное время Уголки - Меньше / Ровно / Over0',
        756: 'Под / ПОВЫШЕНИЕМ Дополнительного времени Уголков0',
        757: 'Азиатский гандикап - Дополнительное время0',
        758: '10 минуты больше / меньше - Free Kicks0',
        759: '10 минуты больше / меньше - вбрасывания0',
        760: '10 минуты больше / меньше - Гол Kicks0',
        762: 'Для того, чтобы выиграть Toss0',
        763: 'Меньше / Больше Работает в матче0',
        765: 'Забьет больше четверок0',
        766: 'Под / Over - Match Фур0',
        767: 'Под / Over - Match Sixes0',
        768: 'Пятидесятилетней Чтобы забито в матче0',
        769: 'Сто к забито в матче0',
        770: 'Большинство Ушли Run0',
        791: 'Топ Batsman - Главная команда0',
        792: 'Top Batsman - Away Team0',
        793: 'Top Bowler - Home Team0',
        794: 'Top Bowler - Away Team0',
        795: 'Человек матча0',
        806: 'Batsman забьют пятьдесят в матче0',
        808: 'Гонка К 10 прогонов0',
        809: 'Самый 1-6 Overs Score0',
        813: 'Игрок забьет больше Шестерки0',
        814: 'Самый высокий Индивидуальные Score0',
        819: 'Для того, чтобы иметь перерыв момент в игре0',
        820: 'Гонка к углам0',
        822: 'Лучшие новые команды0',
        824: 'Игрок бронируются0',
        825: 'Игрок будет отправлен0',
        834: 'Дополнительные очки Set0',
        835: 'Азиатский больше / меньше0',
        836: 'Азиатский / Меньше первый период0',
        837: '1-й тайм хозяева выиграет всухую0',
        838: 'Первая половина команды гостей выиграет всухую0',
        839: 'Первая половина 1X2 И больше / меньше0',
        840: '1-й период Главная Команда выиграет всухую0',
        841: 'Первый период команды гостей выиграет всухую0',
        842: 'Под / Ровно / Over - Home Team0',
        843: 'Под / Ровно / Over - Away Team0',
        844: 'Первая команда забьет - Главная / Away0',
        845: 'Команда Для вызова First Time Out0',
        846: 'Под / Over Hits - включая сверхурочные0',
        849: 'Под / За 5 Inning0',
        851: 'Команда с самой результативной половиной0',
        852: 'Либо команды забьют 3 Неразделенный раз0',
        853: '12 И больше / меньше0',
        854: 'Специальная команда или оборонительный Приземление Забил0',
        855: 'Очки Диапазон0',
        859: '147 Break In Match0',
        861: 'Первый Score Method 3 Way - Home Team0',
        862: 'Первый счет Метод 3 Way - Away Team0',
        863: 'Команда с самым результативным Периодом -1X20',
        864: 'Команда первого гола0',
        865: 'Команда Последний Goalscorer0',
        866: 'Азиатские наборы для инвалидов0',
        870: 'Первый Score Метод 6 Way Home / Away0',
        878: 'Цели Диапазон третьего периода0',
        879: 'Над / под Попыток0',
        880: 'Первый тайм Уголки - Меньше / Ровно / Более0',
        881: 'Первый гол Метод0',
        882: 'Кол-во пунктов0',
        891: 'Команда забьет Longest Приземление0',
        892: 'Команда забьет Наибольшая полевая цель0',
        893: 'Серия Приземление Состав исполнителей0',
        894: 'Кратчайший Приземление Состав исполнителей0',
        895: 'Серия Field Goal Забил0',
        896: 'Время первой команды Цели0',
        897: 'Игра Score После 2 очков0',
        898: 'Игра Score После 3-х очков0',
        899: 'Игра Score После 4-х очков0',
        900: 'Игра Для Score0',
        901: 'Первый период Свинец После0',
        902: '2-й период Свинец После0',
        903: '3-й период Свинец После0',
        904: 'Четвёртый Период Lead После0',
        905: 'Пятый Период Lead После0',
        906: 'Оценка После 4-х игр0',
        907: 'Оценка После 6 игр0',
        908: 'Для того, чтобы служить в Разрыв Set0',
        909: 'Главная выиграть в двух сетах0',
        910: 'Вдали выиграть в двух сетах0',
        911: 'Оценка После 2-х наборов0',
        912: 'Оценка После 3-х наборов0',
        914: 'Под / над полем Цели Состав - 1-й половины0',
        915: 'Над / под тачдаунов Состав - 1-й половины0',
        916: 'Над / под Главная Команда приземлений - 1-й половины0',
        917: 'Над / под гостях команда приземлений - 1-й половины0',
        920: 'Над / под приземлений0',
        921: 'Под / Ровно / Over - тачдауны0',
        922: 'Меньше / Больше полевых целей0',
        923: 'Под / ТОЧНО / Over - Поле целей0',
        924: 'Время первого поля Гол забил0',
        925: 'Время первого Touchdown Состав исполнителей0',
        926: 'Время первого показателя0',
        927: 'Главная команда над / под тачдауны0',
        928: 'На выезде команды над / под тачдауны0',
        929: 'Главная команда под / над полем цели0',
        930: 'Команда гостей больше / меньше полевые головы0',
        932: 'Команда, чтобы иметь Первые тренеры Вызова0',
        933: 'Команда для Commit Первых обслуживаемого штрафа0',
        934: 'Безопасность Состав исполнителей0',
        935: 'Европейские карты фора0',
        936: 'Азиатский / Меньше карты0',
        938: 'Меньше / Больше карт - Home Team0',
        939: 'Меньше / Больше карт - Away Team0',
        940: 'Время первых карт0',
        941: 'Следующий Игрок забьет0',
        942: 'Top Национальность - Азиатский0',
        943: 'Top Национальность - Австралийский0',
        944: 'Top Национальность - Европейский0',
        945: 'Top Гражданство - Великобритания и Ирландия0',
        946: 'Лучшая Национальность - Остальной мир0',
        947: 'Над / под тузов0',
        948: 'Над / под тузов - Home Team0',
        949: 'Точное число тузов0',
        950: 'Меньше / Больше тузов - Away Team0',
        951: 'Правильное количество тузов0',
        955: '1X2 Aces0',
        956: 'Точное количество тузов - Home Team0',
        957: 'Точное количество тузов - Away Team0',
        958: 'Тузы Азиатский гандикап0',
        959: 'Race Для Aces0',
        960: 'Меньше / Больше тузов - 1-й период0',
        976: '1X2 Aces - первый период0',
        982: 'Тузы Азиатский гандикап - первый период0',
        986: '12 Aces0',
        989: 'Меньше / Больше убийств - 1-й период0',
        990: 'Меньше / Больше убийств - 2-й период0',
        991: 'Меньше / Больше убийств - 3-й период0',
        992: 'Следующий тачдаун - хозяева0',
        993: 'Следующий тачдаун - Away Team0',
        994: 'Следующая тачдаун0',
        995: 'Игрок забьет Приземление В любое время0',
        996: 'Игрок забьет 2 или более приземлений0',
        997: 'Игрок забьет 3 или более приземлений0',
        998: 'Первый матч Угол - 120',
        999: 'Последний матч Угловой - 120',
        1000: 'Количество комплектов0',
        1001: 'Больше / меньше двойных ошибок0',
        1002: 'Больше / меньше двойных ошибок - Away Team0',
        1003: 'Больше / меньше двойных ошибок - Home Team0',
        1004: 'Больше / меньше двойных ошибок - 1-й период0',
        1010: '12 двойных ошибок0',
        1011: '12 двойных ошибок - первый период0',
        1013: '1X2 двойных ошибок0',
        1016: 'Точное количество двойных ошибок0',
        1019: 'Точное количество двойных ошибок - Home Team0',
        1020: 'Точное количество двойных ошибок - Away Team0',
        1027: 'Правильное количество двойных ошибок0',
        1030: 'Время первого уголка0',
        1031: 'Несколько углов0',
        1034: 'Двадцатый Период Победитель Главная / Away0',
        1035: '21th Период Winner Главная / Away0',
        1036: '22th Период Winner Главная / Away0',
        1037: '23th Период Winner Главная / Away0',
        1038: 'Период двадцать четвёртой Победитель Главный / Away0',
        1039: 'Период двадцать пятого Победитель Главный / Away0',
        1040: 'Период двадцать шестого Winner Главная / Away0',
        1041: 'Период двадцать седьмой Winner Главная / Away0',
        1042: 'Период двадцать восьмой Победитель Главная / Away0',
        1043: 'Двадцать девятый Период Winner Главная / Away0',
        1044: 'Тридцатый Период Победитель Главный / Away0',
        1046: 'Период Победитель 32-го Главная / Away0',
        1047: 'Период 33-й победитель Home / Away0',
        1050: 'Гонка до 20 очков0',
        1051: 'Под / За 2-й тайм - Home Team0',
        1052: 'Меньше / Больше 2-й тайм - Away Team0',
        1053: 'Asian больше / меньше 2-й период0',
        1054: 'Asian больше / меньше третий период0',
        1055: 'Азиатский / Меньше четвёртый Период0',
        1061: 'Азиатский / Меньше включая сверхурочные0',
        1062: 'Самая результативная половина - хозяева0',
        1063: 'Половина с наивысшим счетом - Команда гостей0',
        1065: 'Главная Команда - Первый Goalscorer0',
        1066: 'Главная Команда - Last Goalscorer0',
        1067: 'На выезде команда - Первый Goalscorer0',
        1068: 'На выезде команда - Последний Goalscorer0',
        1069: 'Под Баллов / За игрока0',
        1070: 'Над / под Blocks игрока0',
        1071: 'Над / под Assists игрока0',
        1072: 'Над / под игроком отскакивает0',
        1073: 'Над / под игроком Steals0',
        1074: 'Над / под игроком Обороты0',
        1075: 'Меньше / Больше игрока 3 Очки Made0',
        1076: 'Меньше / Больше 3 Игрок Очки Покушение0',
        1077: 'Меньше / Больше игрока 2 Очки Made0',
        1078: 'Меньше / Больше Игрок 2 Очки Покушение0',
        1079: 'Меньше / Больше игрока штрафных бросков0',
        1080: 'Меньше / Больше игрок штрафных бросков Покушение0',
        1081: '1X2 Желтые карточки0',
        1082: 'Двойной шанс Жёлтый0',
        1083: 'Азиатский гандикап Желтые карточки0',
        1084: 'Счет Желтые карточки0',
        1085: 'Над / под красными карточками0',
        1086: 'Под / Ровно / очки За Бронирование0',
        1087: 'Под / Ровно / очки За Бронирование - Главная Команда0',
        1088: 'Под / Ровно / За Бронирование Очки - Away Team0',
        1089: 'Над / под игроком Steals и блоки0',
        1090: 'Под Баллов / За игрока0',
        1091: 'Над / под игроком передач и отскакивают0',
        1092: 'Под / над точками плеер и отскакивает0',
        1093: 'Над / под очков игрока и помогает0',
        1094: 'Игрок Двухместный0',
        1095: 'Игрок трипл-дабл0',
        1096: 'Под / Ровно / За Бронирование очки - 1-й период0',
        1100: 'Набор Winner0',
        1101: 'Над / под 180 \ S в наборе0',
        1102: 'Set / Победитель Leg0',
        1103: 'Set / Leg Всего Кассовые Меньше / Больше0',
        1105: 'Меньше / Больше Всего 180 \ s - Главная Команда0',
        1106: 'Меньше / Больше Всего 180 \ с - Away Team0',
        1112: 'В разделе Цели / За игрока0',
        1115: 'В разделе Цели / За игрока разрешено0',
        1120: 'Под / Over раундам - ​​1-й период0',
        1121: 'Меньше / Больше раундов - 2-й период0',
        1122: 'Меньше / Больше раундов - 3-й период0',
        1123: 'Четвёртый период - первая кровь0',
        1124: 'Пятый период - первая кровь0',
        1125: 'Обе команды Убить дракона0',
        1126: 'Обе команды убить Baron0',
        1127: 'Обе команды убить Ингибитор0',
        1128: 'Под / Над баронов Убиенного0',
        1129: 'Меньше / Больше бароны Убитый - 1-й период0',
        1130: 'Меньше / Больше бароны Убитый - 2-й период0',
        1131: 'Меньше / Больше бароны Убитый - 3-й период0',
        1132: 'Над / под Dragons Убиенного0',
        1133: 'Меньше / Больше Драконов Убитый - 1-й период0',
        1134: 'Меньше / Больше Драконов Убитый - 2-й период0',
        1135: 'Меньше / Больше Драконов Убитый - 3-й период0',
        1136: 'Под / более 180 \ сек0',
        1137: 'Во-первых 1800',
        1138: 'Последний 1800',
        1139: 'Над / под заказ Лучшей Score0',
        1140: 'Большинство 180 \ s0',
        1141: 'Под / Over Лучшей Checkout Score - Home Team0',
        1142: 'Над / под заказ Лучшей Score - команда гостей0',
        1143: 'Первый игрок Приземление0',
        1144: 'Последний игрок Приземление0',
        1145: 'Первый игрок Приземление - Home Team0',
        1146: 'Первый игрок Приземление - Away Team0',
        1147: 'Меньше / Больше убийств - 4-ый период0',
        1148: 'Меньше / Больше убийств - 5-ый период0',
        1149: 'Азиатский гандикап Убивает - первый период0',
        1150: 'Азиатский гандикап убивает - второй период0',
        1151: 'Азиатский гандикап убивает - третий период0',
        1152: 'Азиатский гандикап Убивает - четвёртый период0',
        1153: 'Азиатский гандикап убивает - пятый период0',
        1154: 'Под / ТОЧНО / Более 180 \ с0',
        1155: 'Под / Ровно / За Всего 180 \ s - Главная Команда0',
        1156: 'Под / Ровно / Больше Тотал 180 \ S - Away Team0',
        1157: 'Большинство Кассовые0',
        1158: 'Во-первых Try Scorer0',
        1159: 'Последний Try Scorer0',
        1160: 'В любое время Попробуйте бомбардир0',
        1162: 'Под / Над Tous Уничтожен - 1-й период0',
        1163: 'Под / Над Tous Разрушенного - 2-й период0',
        1164: 'Под / Над Tous Разрушенного - 3-й период0',
        1165: 'Первый период - первый барон0',
        1166: '2-й период - первый Baron0',
        1167: 'Третий период - первый барон0',
        1168: 'Четвёртый период - первый барон0',
        1169: 'Пятый период - первый барон0',
        1170: 'Первый период - первый дракон0',
        1171: '2-й период - первый дракон0',
        1172: 'Третий период - первый дракон0',
        1173: 'Четвёртый период - первый дракон0',
        1174: 'Пятый период - первый дракон0',
        1175: 'Большинство убийств - первый период0',
        1176: 'Большинство убийств - 2-й период0',
        1177: 'Большинство убийств - третий период0',
        1178: 'Большинство убийств - четвёртый период0',
        1179: 'Большинство убийств - пятый период0',
        1180: 'Большинство убийств - шестой период0',
        1181: 'Большинство убийств - седьмой период0',
        1182: 'Игрок забьет 2 или более TRYS0',
        1183: 'Игрок забьет 3 или более TRYS0',
        1184: 'Не Чтобы выиграть Leauge0',
        1185: 'Под / Ровно / Over включая сверхурочные - Home Team0',
        1186: 'Под / Ровно / За включая сверхурочные - Away Team0',
        1187: 'Под / Ровно / над включая овертайм0',
        1188: 'Над / под Замены0',
        1189: 'Над / под Замену - 1-й период0',
        1190: 'Последний игрок Приземление - Home Team0',
        1191: 'Последний игрок Приземление - Away Team0',
        1192: 'Первый игрок ударить туз0',
        1193: 'Меньше / Больше Игрок Куортербек Передача Yards0',
        1194: 'Меньше / Больше игроков ушедших приземлений0',
        1195: 'Над / под Longest игрока Pass Завершения0',
        1196: 'Меньше / Больше игрока Прием Yards0',
        1197: 'Над / под игроком перехваты0',
        1198: 'Меньше / Больше Игрок Ноги Очки0',
        1199: 'Меньше / Больше Игрок Стремительное & Прием Yards0',
        1200: 'Меньше / Больше игрока Стремительное Yards0',
        1201: 'Меньше / Больше Игрок Longest прием0',
        1202: 'Над / под игроком Приемы0',
        1203: 'Меньше / Больше полевого игрока Цели Сделано0',
        1204: 'Меньше / Больше полевой игрок Цели Попытанной0',
        1205: 'Меньше / Больше игрок квотербек ушедших приземлений0',
        1206: 'Меньше / Больше игрока приемника приема пополнений0',
        1207: 'Меньше / Больше игрока Quarterback Пропуска Завершенный0',
        1208: 'Меньше / Больше игрока Стремительное приземлений0',
        1209: 'Меньше / Больше игрока приемника приема Попыток0',
        1210: 'Меньше / Больше игрока Quarterback Пропуска Покушение0',
        1211: '1X2 Нарушения0',
        1212: 'Двойной шанс Фолы0',
        1213: 'Меньше / Больше Нарушения - 1-й период0',
        1214: 'Меньше / Больше Нарушения - 2-й период0',
        1215: 'Азиатский гандикап Фолы0',
        1216: 'Меньше / Больше Нарушения - Home Team0',
        1217: 'Меньше / Больше Нарушения - Away Team0',
        1218: 'Меньше / Больше игрока Rush Попытки0',
        1219: 'Меньше / Больше игрок Прием приземления0',
        1222: 'Над / под вбрасывания - Home Team0',
        1223: 'Меньше / Больше вбрасывания - Away Team0',
        1224: '1X2 Throw-Ins0',
        1225: 'Двойной шанс Throw-Ins0',
        1226: 'Меньше / Больше вбрасывания - 1-й период0',
        1227: 'Вбрасывания Фора0',
        1228: 'Вбрасывания Фора - 1-й период0',
        1229: 'Под / Над Броски по Target - Home Team0',
        1230: 'Меньше / Больше ударов в створ ворот - команда гостей0',
        1231: 'Азиатские Выстрелы для инвалидов на Target0',
        1232: 'Азиатские Выстрелы инвалидов On Target - первый период0',
        1233: 'Меньше / Больше ударов в створ ворот - 1-й период0',
        1234: '1X2 Удары в створ0',
        1235: 'Двойной шанс Удары в створ0',
        1236: 'Меньше / Больше Всего снимков - Home Team0',
        1237: 'Меньше / Больше Всего снимков - команда гостей0',
        1238: 'Двойной шанс Всего бросков0',
        1239: '1X2 Всего бросков0',
        1240: 'Азиатские форы Всего бросков0',
        1241: 'Азиатские форы Всего бросков - 1-й период0',
        1242: 'Меньше / Больше Всего снимков - 1-й период0',
        1243: 'Двойной шанс Уголки0',
        1244: 'Двойной шанс Уголки - 1-й период0',
        1245: 'Углы от всех 4-х углов тангажа0',
        1246: 'Двойной шанс Жёлтый - первый период0',
        1247: '1X2 Жёлтые - первый период0',
        1248: 'Азиатский гандикап Жёлтый - первый период0',
        1249: 'Двойной шанс и больше / меньше0',
        1250: 'Двойной шанс и Обе команды забьют0',
        1251: 'Первый период - первый Ингибитор0',
        1252: '2-й период - первый Ингибитор0',
        1253: 'Третий период - первый Ингибитор0',
        1254: 'Четвёртый период - первый Ингибитор0',
        1255: 'Пятый период - первый Ингибитор0',
        1256: 'Обе команды, чтобы убить барона - первый период0',
        1257: 'Обе команды убить Baron - второй период0',
        1258: 'Обе команды убить Baron - третий период0',
        1259: 'Обе команды убить Baron - четвёртый период0',
        1260: 'Обе команды, чтобы убить барона - пятый период0',
        1261: 'Обе команды, чтобы убить дракона - 4-й Период0',
        1262: 'Обе команды, чтобы убить дракона - 5-ый период0',
        1263: 'Обе команды, чтобы убить Ингибитор - 4-й Период0',
        1264: 'Обе команды, чтобы убить Ингибитор - 5-ый период0',
        1265: 'Будет Там Быть Ace Следующая игра?0',
        1266: 'Будет Там Be Double Fault Следующая игра?0',
        1267: 'Чет / нечет Фолы0',
        1268: 'Азиатский Гандикап Тайм Болл0',
        1269: 'Двойной шанс Офсайды0',
        1270: 'Азиатский гандикап Офсайды0',
        1271: 'Меньше / Больше офсайдов - Home Team0',
        1272: 'Меньше / Больше офсайдов - Away Team0',
        1273: '1X2 Офсайды0',
        1274: 'Под / За Kills 1 период - Home Team0',
        1275: 'Под / Over Kills 2 Периода - Home Team0',
        1276: 'Над / под Kills 3 Период - Home Team0',
        1277: 'Меньше / Больше убийств 1-й период - Away Team0',
        1278: 'Меньше / Больше Kills 2-й период - команда гостей0',
        1279: 'Меньше / Больше убийств 3-й период - Away Team0',
        1280: 'Под / Over Самый широкий0',
        1281: 'Над / под Утки0',
        1282: 'Над / под Калитки0',
        1283: 'Над / под спичками Фур - Главная команда0',
        1284: 'Под / За матч шестерок - Home Team0',
        1285: 'Меньше / Больше матча четвереньках - Away Team0',
        1286: 'Меньше / Больше матча шестерок - Away Team0',
        1287: 'Над / под Extras0',
        1288: 'Падение 1 калитка - Home Team0',
        1289: 'Падение 1 калитка - Away Team0',
        1290: 'Команда топ бэтсмен0',
        1291: 'Меньше / Больше матч шестерки Home Team - 1 Inning0',
        1292: 'Меньше / Больше матча шестерок Away Team - 1 Inning0',
        1293: 'Меньше / Больше матча Фур Home Team - 1 Inning0',
        1294: 'Над / под Совпадение Фур Away Team - 1 Inning0',
        1295: 'Под / над верхним бэтсменом0',
        1296: 'Первый Inning 1st Over Увольнение - Home Team0',
        1297: 'Первый Inning второй Over Увольнение - Главная команда0',
        1298: 'Первый Inning третий Over Увольнение - Главная команда0',
        1299: 'Первый Inning второй Over Увольнение - Away Team0',
        1300: 'Первый Inning третий Over Увольнение - Away Team0',
        1301: 'Первый Inning первый Over Увольнение - Away Team0',
        1302: 'Самая результативная Over - Меньше / Больше0',
        1303: 'Первый Inning второй Больше Меньше / Больше - Home Team0',
        1304: 'Первый Inning третий Больше Меньше / Больше - Home Team0',
        1306: 'Первый Inning третий Больше Меньше / Больше - Away Team0',
        1307: 'Первый Inning второй Больше Меньше / Больше - Away Team0',
        1309: 'Выигрышные Margin - Команды0',
        1310: 'Победа Маржа - Команды включая сверхурочные0',
        1312: 'Победа Маржа Exact0',
        1314: 'Победа Ranges Margin0',
        1315: 'Выигрышные Margin Exact - Команды0',
        1316: 'Победа Margin - команды с любым другим показателем0',
        1318: 'Азиатские Фолы инвалидов - первый период0',
        1319: 'Азиатский гандикап Офсайды - первый период0',
        1320: 'Азиатский гандикап Офсайды - 2-й период0',
        1321: 'Азиатские Фолы инвалидов - второй период0',
        1322: 'Азиатские Выстрелы инвалидов On Target - второй период0',
        1324: 'Меньше / Больше офсайдов - 1-й период0',
        1325: 'Меньше / Больше офсайдов - 2-й период0',
        1326: 'Первый фол0',
        1327: 'Первые два указателя0',
        1328: 'Первые три указателя0',
        1329: 'Последние два указателя0',
        1330: 'Последние три указателя0',
        1331: 'Последняя штрафной бросок Состав исполнители0',
        1332: 'Первый Свободный бросок Забил0',
        1333: 'Первый отскоку0',
        1334: 'Последний тайм-аут0',
        1335: 'Победа Маржа команды - 1-й период0',
        1336: 'Победа Маржа команды - 2-й период0',
        1337: 'Победа команды Margin - 3-й период0',
        1338: 'Победа Маржа команды - 4-ый период0',
        1339: 'Победа команды Margin - 5-ый период0',
        1340: 'Последний Фол0',
        1341: 'Под / За 10 Периода0',
        1342: 'Под / За 11 Периода0',
        1343: 'Под / За 12 Периода0',
        1344: 'Под / За 13 Периода0',
        1345: 'Под / За 14 Периода0',
        1346: 'Под / За 15 Периода0',
        1347: 'Под / За 16 Периода0',
        1348: 'Под / За 17 Периода0',
        1349: 'Под / За 18 Периода0',
        1350: 'Под / За 19 Периода0',
        1351: 'Под / За 20 Периода0',
        1352: 'Под / За 21 Периода0',
        1353: 'Под / За 22 Периода0',
        1354: 'Под / За 23 Периода0',
        1355: 'Под / За 24 Периода0',
        1356: 'Под / За 25 Периода0',
        1357: 'Под / За 26 Периода0',
        1358: 'Под / За 27 Периода0',
        1359: 'Под / За 28 Периода0',
        1360: 'Под / За 29 Периода0',
        1361: 'Под / За 30 Периода0',
        1362: 'Под / За 31 Периода0',
        1363: 'Под / За 32-й период0',
        1364: 'Под / За 33-й период0',
        1365: 'Под / За 34-й период0',
        1366: 'Под / За 35-й период0',
        1367: 'Азиатский гандикап 11 Период0',
        1368: 'Азиатский гандикап 12 Период0',
        1369: 'Азиатский гандикап 13 Период0',
        1370: 'Азиатский гандикап четырнадцатый период0',
        1371: 'Азиатский гандикап пятнадцатого Период0',
        1372: 'Азиатский гандикап шестнадцатого Период0',
        1373: 'Азиатский гандикап семнадцатый период0',
        1374: 'Азиатский гандикап восемнадцатый период0',
        1375: 'Азиатский гандикап девятнадцатом Период0',
        1376: 'Азиатский гандикап двадцатом Период0',
        1380: 'Азиатский гандикап двадцать четвёртый период0',
        1381: 'Азиатский гандикап двадцать пятый период0',
        1382: 'Азиатский гандикап двадцать шестой период0',
        1383: 'Азиатский гандикап двадцать седьмой период0',
        1384: 'Азиатский гандикап двадцать восьмой период0',
        1385: 'Азиатский гандикап двадцать девятый Период0',
        1386: 'Азиатский гандикап тридцатого Период0',
        1390: 'Азиатский гандикап тридцать четвёртый Период0',
        1391: 'Азиатский гандикап тридцать пятый период0',
        1392: 'Финишировать в Топ 100',
        1393: 'Чтобы оставаться0',
        1394: 'Азиатский гандикап десятого Период0',
        1395: 'Меньше / Больше игрока PowerPlay Очки0',
        1396: 'Под / Over Player Plus / Minus0',
        1397: 'Под Shots / За игрока0',
        1398: 'Топ Викторианский клуб0',
        1399: 'Под / Over Run Outs0',
        1400: 'Над / под Frames0',
        1401: 'Топ 20 Победитель0',
        1402: 'Первый раунд Лидер0',
        1404: 'Первый раунд Шесть Shooter - Группа A0',
        1406: '1-й тур 3 Balls0',
        1407: 'Первый раунд 18 Hole Match-Ups0',
        1408: 'матч фора0',
        1409: 'Команда с Top Bowler0',
        1410: 'Для того, чтобы выиграть Toss и матч0',
        1411: 'Под / Над турели Разрушенный0',
        1412: 'Над / под турели Destroyed - 1-й период0',
        1413: 'Над / под турели Destroyed - 2-й период0',
        1414: 'Над / под турели Destroyed - 3-й период0',
        1415: 'Самый высокий средний балл После 1-Over0',
        1416: 'Главная команда больше / меньше Runs - 1-й к 6-Over0',
        1417: 'Команда гостей больше / меньше Runs - первый к 6-Over0',
        1418: 'Главная команда больше / меньше Runs - 1-ое к 8-Over0',
        1419: 'Команда гостей больше / меньше Runs - первый к 8-Over0',
        1420: 'Главная команда больше / меньше Runs - 1-й к 10-Over0',
        1421: 'Команда гостей больше / меньше Runs - первый Более 10-Over0',
        1422: 'Для Chase Более 180 ранов и команда, чтобы выиграть0',
        1423: 'Сколько Запускается будет забито от первого мяча0',
        1424: 'Над / под спичек Stumpings0',
        1425: 'Первый метод Калитка0',
        1426: 'Первый мяч матча0',
        1427: 'Под / Over - 1 Inning0',
        1428: 'Top Non Викторианский клуб0',
        1429: 'Победитель Гранд Финала0',
        1431: 'Достигнуть суперфинал0',
        1432: 'Не закончить в Top 80',
        1434: 'Меньше / Больше убийств 4 Периода - Away Team0',
        1435: 'Меньше / Больше убийства 5 Период - команда гостей0',
        1436: 'Больше / меньше убийств 4 Периода - Home Team0',
        1437: 'Больше / меньше убийств 5 Период - Home Team0',
        1438: 'Под / над температурой в аэропорту (по Цельсию)0',
        1439: 'Азиатский гандикап - полный рабочий день0',
        1440: 'Меньше / Больше стоимости всех номеров Нарисованные0',
        1441: 'Меньше / Больше стоимости всех номеров Нечетных Нарисованные0',
        1442: 'Меньше / Больше Значение всех четных чисел Нарисованные0',
        1443: 'Больше даже нарисованы, чем нечетные0',
        1444: 'Последовательные номера будут нарисованы0',
        1445: 'Под / Ровно / Over - число нечетных пронумерованных шаров Нарисованные0',
        1446: 'Под / Ровно / Over - Число Даже пронумерованных шаров Нарисованные0',
        1447: 'Сумма 5 бонусных балов0',
        1448: 'Бонус на первый бал Ничьи - чет / нечет0',
        1449: 'Последний бонус Болл Ничьи - чет / нечет0',
        1450: 'Бонус на первый бал0',
        1451: 'Последний бонус Болл0',
        1452: 'Самые низкие 5 бонусных балов0',
        1453: 'Bonus Ball - чет / нечет0',
        1454: 'Одинаковые номера будут нарисованы0',
        1455: 'Наибольшее число Рисованные - чет / нечет0',
        1456: 'Наименьшее количество Нарисованные - чет / нечет0',
        1457: 'Шаровые 1 - больше / меньше0',
        1458: 'Шар 1 - чет / нечет0',
        1459: 'Ball 2 - больше / меньше0',
        1460: 'Ball 2 - чет / нечет0',
        1461: 'Шар 3 - больше / меньше0',
        1462: 'Шар 3 - чет / нечет0',
        1463: 'Шаровые 4 - больше / меньше0',
        1464: 'Шар 4 - чет / нечет0',
        1465: 'Шаровые 5 - больше / меньше0',
        1466: 'Шаровые 5 - чет / нечет0',
        1467: 'Шаровые 6 - больше / меньше0',
        1468: 'Шаровые 6 - чет / нечет0',
        1469: 'Бал 7 - больше / меньше0',
        1470: 'Бал 7 - чет / нечет0',
        1471: 'Шаровые 8 - больше / меньше0',
        1472: 'Шар 8 - чет / нечет0',
        1473: 'Бал 9 - больше / меньше0',
        1474: 'Бал 9 - чет / нечет0',
        1475: 'Мяч 10 - больше / меньше0',
        1476: 'Шар 10 - чет / нечет0',
        1477: 'Мяч 11 - больше / меньше0',
        1478: 'Бал 11 - чет / нечет0',
        1479: 'Бал 12 - больше / меньше0',
        1480: 'Шар 12 - чет / нечет0',
        1481: 'Бал 13 - больше / меньше0',
        1482: 'Бал 13 - чет / нечет0',
        1483: 'Бал 14 - больше / меньше0',
        1484: 'Бал 14 - чет / нечет0',
        1485: 'Мяч 15 - больше / меньше0',
        1486: 'Бал 15 - чет / нечет0',
        1487: 'Мяч 16 - больше / меньше0',
        1488: 'Шаровой 16 - чет / нечет0',
        1489: 'Шар 17 - больше / меньше0',
        1490: 'Шар 17 - чет / нечет0',
        1491: 'Бал 18 - больше / меньше0',
        1492: 'Бал 18 - чет / нечет0',
        1493: 'Шаровой 19 - больше / меньше0',
        1494: 'Шаровой 19 - чет / нечет0',
        1495: 'Мяч 20 - больше / меньше0',
        1496: 'Шар 20 - чет / нечет0',
        1497: 'По крайней мере одно из чисел Drawn делится на 30',
        1498: 'По крайней мере одно из чисел Drawn делится на 40',
        1499: 'По крайней мере одно из чисел Drawn делится на 50',
        1500: 'По крайней мере одно из чисел Drawn делится на 70',
        1501: 'По крайней мере одно из чисел Drawn делится на 100',
        1502: 'По крайней мере одно из чисел Drawn кратно 150',
        1503: 'По крайней мере одно из чисел Drawn делится на 200',
        1504: 'Самое высокое выпавшее число - больше / меньше0',
        1505: 'Общая стоимость Наименьшее и наибольшее число Ничьи - больше / меньше0',
        1506: 'Самое низкое выпавшее число - больше / меньше0',
        1507: 'Разница между самыми высокими и самыми низкими номерами Нарисованные - больше / меньше0',
        1508: 'Общая стоимость Наименьшее и наибольшее число Ничьи - чет / нечет0',
        1509: 'Разница между самыми высокими и самыми низкими номерами Нарисованные - чет / нечет0',
        1510: 'Первый номер Ничьи больше, чем последний номер Нарисованный0',
        1511: 'Последний номер Ничьи - чет / нечет0',
        1512: 'Первый номер Ничьи - чет / нечет0',
        1513: 'Все выпадали номера Даже0',
        1514: 'Все Рисованные нечетные номера0',
        1515: 'Количество Чтобы Draw0',
        1520: 'Четвёртый период - первый револьверный0',
        1521: 'Пятый период - первый револьверный0',
        1523: 'Меньше / Больше бароны Убитый - 4-й период0',
        1524: 'Меньше / Больше бароны Убитый - 5-й период0',
        1525: 'Меньше / Больше Драконов Убитый - 4-го периода0',
        1526: 'Меньше / Больше Драконов Убитый - 5-ый период0',
        1527: 'Над / под турели Destroyed - 4-й период0',
        1528: 'Над / под турели Destroyed - 5-й период0',
        1529: 'Четвёртый Период Длительность0',
        1530: 'Пятый Период Продолжительность0',
        1531: 'Чет / нечет Убивает - 4-ый период0',
        1532: 'Чет / нечет Убивает - 5-ый период0',
        1533: 'Меньше / Больше ингибиторов - 1-й период0',
        1534: 'Меньше / Больше ингибиторов - 2-й период0',
        1535: 'Температура В аэропорту в диапазоне (по Цельсию)0',
        1536: 'Над / под Карты0',
        1537: 'Главная Команда выиграет и больше / меньше - в том числе сверхурочные0',
        1538: 'Команда гостей выиграет и больше / меньше - в том числе сверхурочные0',
        1539: 'Круглый Победитель0',
        1540: 'Ноги Всего Совпадение над / под0',
        1541: 'раундов форы0',
        1542: 'Первый Leg Победитель0',
        1543: 'Во-первых, чтобы выиграть 3 ноги0',
        1546: 'Четвёртый период - первые Рошане0',
        1547: 'Пятый период - первые Рошане0',
        1548: 'Четвёртый период - 1-й Barracks0',
        1549: 'Пятый период - 1-й Barracks0',
        1550: 'Четвёртый период - первые доу0',
        1551: 'Пятый период - первые доу0',
        1552: 'Азиат над / под Уголки0',
        1553: 'Обе команды забьют количество очков - 1-й период0',
        1554: 'Обе команды забьют количество очков - 1-й тайм0',
        1555: 'Под / За 5 период - Home Team0',
        1556: 'Под / За 5 период - команда гостей0',
        1558: 'Азиатские Очки для инвалидов0',
        1559: 'Победитель Кубка0',
        1561: 'Первые 3 Innings Победитель0',
        1562: 'Первые 3 Innings больше / меньше0',
        1563: 'Время подсчета очков0',
        1564: 'Время подсчета очков - хозяева0',
        1565: 'Время подсчета очков - команды гостей0',
        1566: 'Матч Время Результат0',
        1567: 'Top Национальность - Южноафриканский0',
        1568: 'Лучшая Национальность - испанец0',
        1569: 'Top Национальность - Irish0',
        1570: 'Top Национальность - Южноамериканская0',
        1571: 'Top Национальность - Canadian0',
        1572: 'Лучшая Национальность - Англичанин0',
        1573: 'Top Национальность - континентальная Европа0',
        1575: 'Для того, чтобы сделать Cut0',
        1576: 'Чтобы Miss Cut0',
        1577: 'Лучшая Национальность - Американская0',
        1578: 'Национальность победителя0',
        1579: 'Азиатские Очки для инвалидов - 1-й период0',
        1580: 'Азиатские Очки для инвалидов - 2-й период0',
        1581: 'Азиатские Очки для инвалидов - 3-й период0',
        1583: 'Top Team Bowler первый Inning - хозяева0',
        1584: 'Top Team Bowler первый Inning - Away Team0',
        1585: 'Top Team Batsman первый Inning - хозяева0',
        1586: 'Top Team Batsman первый Inning - Away Team0',
        1587: 'Будет ли Ace Next Point0',
        1589: 'Будет ли Double Fault Next Point0',
        1591: 'Под / Over - 1-Over0',
        1592: 'Для Win Лиги0',
        1593: 'Win Отделу0',
        1594: 'Большинство Хиты - включая сверхурочные0',
        1595: 'Борьба с Go The Distance0',
        1596: 'Когда будет бой End0',
        1597: 'Борьба Результат0',
        1598: 'Круглый ставок0',
        1599: 'Топ первый удар0',
        1600: 'Дно первый удар0',
        1601: 'Топ первый Run0',
        1602: 'Дно первый Run0',
        1603: 'Большинство хитов в 1 Inning0',
        1605: 'Команда с самой результативной Inning0',
        1606: 'Обе команды забьют 2 и более голов0',
        1607: 'Обе команды забьют 3 или больше голов0',
        1608: 'Команда, чтобы выиграть Большинство Periods0',
        1609: 'Азиатский гандикап полного рабочего дня - первый период0',
        1610: 'Азиатский гандикап полного рабочего дня - 2-й период0',
        1611: 'Азиатский гандикап полный рабочий день - третий период0',
        1612: 'MVP0',
        1614: 'Для Win конференции0',
        1615: 'Победа конференции0',
        1617: 'Сезон Скидка игрока0',
        1618: 'Первые 5 Innings Победитель - 120',
        1619: '1-й период - Первая половина матча завершилась Winner Главная / Away0',
        1620: '1-й период - Тайм Азиатский гандикап0',
        1621: '2-й период - Первая половина матча завершилась Winner Главная / Away0',
        1622: '2-й период - Тайм Азиатский гандикап0',
        1624: 'Под прогонов / За игрока0',
        1625: 'Под людей / За игрока0',
        1626: 'Меньше / Больше игрок Главных прогонов0',
        1627: 'Меньше / Больше игрока украденных баз0',
        1628: 'Над / под игроком двойники0',
        1629: 'Над / под игроком тройки0',
        1630: 'Кувшин Для записи Win0',
        1631: 'Больше / меньше Игрок Всего Основ0',
        1635: 'Над / под Кувшин прогонов Заработанные0',
        1636: 'Над / под Кувшин Hits разрешено0',
        1637: 'Над / под Кувшин вычеркиванием0',
        1638: 'Команда Классификация0',
        1639: 'Young Rider Классификация0',
        1640: 'Очки Классификация0',
        1641: 'Король горы0',
        1642: 'Этап 10',
        1649: 'Самый быстрый круг0',
        1650: 'Победа автомобилей0',
        1651: 'Первый водитель уходит в отставку0',
        1652: 'Отборочные Выиграть автомобиль0',
        1653: 'Самый быстрый на практике 10',
        1654: 'Очки Finish0',
        1655: 'Самый быстрый Классификатор0',
        1657: 'Этап 30',
        1659: 'Первый конструктор Отставка0',
        1660: 'Сетка Позиция победителя0',
        1661: 'Безопасность Период автомобиля во время гонки0',
        1662: 'Количество водителей объявления0',
        1663: 'Виртуальная безопасность Период автомобиля во время гонки0',
        1664: 'Отверстие Score0',
        1667: 'Для Win Hole0',
        1670: 'связали Match0',
        1672: 'Меньше / Больше голов - 2-й тайм0',
        1673: 'Чет / нечет раундов 4-го периода0',
        1674: 'Чет / нечет раундов 5-ый период0',
        1675: 'Круглый Лидер второй0',
        1676: 'Обе команды забьют - включая сверхурочные0',
        1677: 'Последняя команда забьет - включая сверхурочные0',
        1678: 'Top Национальность - Немецкая0',
        1679: 'Top Национальность - австриец0',
        1680: 'Top Национальность - новозеландец0',
        1681: 'Лучшая левша игрок0',
        1682: 'Лучшие любительские0',
        1683: 'Top Национальность - корейский0',
        1684: 'Лучшая Национальность - Скандинавская0',
        1685: 'Лучшая Национальность - француз0',
        1686: 'Топ Бывший победитель0',
        1687: 'Top Национальность - швед0',
        1688: 'Top Национальность - Японский0',
        1689: 'Top Национальность - шотландец0',
        1690: 'Top Национальность - Бельгийский0',
        1691: 'Европейский гандикап четвёртого Период0',
        1692: 'Европейский гандикап третий период0',
        1693: 'Европейский гандикап второй период0',
        1694: 'Европейский гандикап первый период0',
        1695: 'Круглый Score0',
        1696: 'Турнир Победы Score0',
        1697: 'Ведущий показатель После раунда0',
        1698: 'Ударить Фарватер С тройник выстрел0',
        1699: 'Для Hit Green В Правилах0',
        1700: 'Третий раунд Лидер0',
        1702: 'Больше / меньше игроков ушедших Попыток0',
        1704: 'Приземление на 1-й период0',
        1705: 'Приземление на 2-й период0',
        1706: 'Приземление на 3-й период0',
        1707: 'Приземление на 4-й период0',
        1708: 'Под / Ровно / Over - Home Team приземлений0',
        1709: 'Под / Ровно / Over - Away Team приземлений0',
        1710: 'Под / Ровно / Over - Главная Команда 1-й тайм приземления0',
        1711: 'Под / Ровно / Over - Away Team первый тайм приземлений0',
        1712: 'Шестнадцатый раунд Победитель - четвёртый Период0',
        1713: 'Шестнадцатый раунд Победитель - пятый период0',
        1714: 'Под / Над Tous Разрушенного - 4-й период0',
        1715: 'Под / Над Tous Разрушенного - 5-й период0',
        1722: 'Установите Finish всухую0',
        1723: 'Меньше / Больше убийств - 1-й период - 1-й раунд0',
        1724: 'Меньше / Больше убийств - 2-й период - 1-й раунд0',
        1728: 'Обе команды забьют - Дополнительное время0',
        1729: 'Двойной шанс - Дополнительное время0',
        1730: 'Будет ли игра Go To Супер Over?0',
        1731: 'Двойной шанс пятый период0',
        1732: 'Двойной шанс шестой период0',
        1733: 'Двойной шанс седьмой период0',
        1734: 'Двойной шанс восьмой период0',
        1736: 'Количество прогонов первого иннинга0',
        1737: 'Количество прогонов 2-й иннинг0',
        1738: 'Количество прогонов третьего иннинга0',
        1739: 'Количество прогонов 4-й иннинг0',
        1740: 'Количество прогонов 5-й иннинг0',
        1741: 'Количество прогонов 6-й иннинг0',
        1742: 'Количество прогонов 7-й иннинг0',
        1743: 'Количество прогонов 8-й иннинг0',
        1744: 'Пятидесятилетней быть забито в 1-й Innings0',
        1745: 'Падение 1 калитка0',
        1747: 'Следующий Человек Out0',
        1748: 'Завершена Match0',
        1749: 'В. Состав исполнителей0',
        1750: 'В. Состав исполнителей - первая Inning0',
        1752: 'На выезде команды Первого Увольнение 6-Way0',
        1753: 'Top Match Batsman0',
        1754: 'Забьет пенальти0',
        1758: 'Шестые Люди года0',
        1760: 'Новичок года0',
        1761: 'Регулярный чемпионат MVP0',
        1763: 'Batsman забить сто в 1-й Innings0',
        1765: '9 Дарт Готово в матче0',
        1766: 'Время первой попытки0',
        1767: 'Время последнего Try0',
        1770: '9 Дарт Готово в матче - хозяева0',
        1771: '9 Дарт Готово в матче - На выезде команда0',
        1772: 'Top Run Scorer0',
        1773: 'Top Run Scorer - Главная команда0',
        1774: 'Top Run Scorer - Away Team0',
        1775: 'Команда Top Run Скорера0',
        1776: 'Под / Над плоскодонки0',
        1777: 'Над / под Сакс0',
        1778: 'Над / под 1-й Даунс0',
        1779: 'Первая команда, чтобы получить первый даун0',
        1780: 'Первый тайм / над полем цели - Главная Команда0',
        1781: 'Первый тайм / над полем цели - Away Team0',
        1782: 'Под / Ровно / Over - поле целей 1-м тайме0',
        1783: 'Над / под приземлениями 1-й период0',
        1784: 'Над / под наказаниями Принято0',
        1785: 'Большинство убийств в матче0',
        1786: 'Команда с наибольшим количеством убийств в матче0',
        1787: 'Под / Over Pass Попытки0',
        1788: 'Под / Over Pass пополнений0',
        1789: 'Меньше / Больше Передача Yards0',
        1790: 'Меньше / Больше Rush Попытки0',
        1791: 'Под / Over Rush Yards0',
        1792: 'Над / под игроком снастей и Assist0',
        1794: 'Команда выбивать Первый0',
        1795: 'Первый период 1X2 и больше / меньше0',
        1796: '1X2 Передачи0',
        1797: 'Двойной шанс Содействует0',
        1798: 'Команда Привести После каждого периода0',
        1799: 'Команда с большинством Передача Yards0',
        1800: 'Команда с большинством Стремительное Yards0',
        1801: 'Успешное преобразование 2 точки0',
        1802: 'Меньше / Больше тачдаунов0',
        1803: 'Под / над полем Цель 1-й период0',
        1804: 'Счет в последние 2 минуты 1-й половины0',
        1805: 'Команда на получение Открытия Стартового0',
        1806: 'Меньше / Больше Home Team приземлений - 1-й период0',
        1807: 'Под / Over Away Team приземления - 1-й период0',
        1808: 'Под / Ровно / Более Главная Команда тачдаунов - первый период0',
        1809: 'Под / Ровно / Over Away Team тачдаунов - первый период0',
        1810: 'Меньше / Больше Главная Команда полевых целей - 1-й период0',
        1812: 'Под / Над Away Team Поле голов - 1-й период0',
        1813: 'На выезде команда выиграть каждый квартал0',
        1814: 'Главная команда выиграть каждый квартал0',
        1815: 'Первый Наступление Играть в игры0',
        1816: 'Чтобы выиграть четверть0',
        1817: 'Пройдут в Финал0',
        1818: 'Главная команда забьет 25 голов0',
        1819: 'Главная команда забьет 30 голов0',
        1820: 'На выезде команда забьет 25 голов0',
        1821: 'На выезде команда забьет 30 голов0',
        1822: 'Главная Команда выиграет полудуплексный0',
        1823: 'Команда гостей выиграет полудуплексном0',
        1824: 'Стадия ликвидации0',
        1825: 'Над / под Assists0',
        1826: 'Над / под подборы0',
        1827: 'Меньше / Больше Главная команда Assists0',
        1828: 'Под / Over Away Team Assists0',
        1829: 'Под / Over Home Team отскакивает0',
        1830: 'Под / Over Away Team отскакивает0',
        1831: '1X2 3 Очки Сделано0',
        1832: 'Под / Over 3 Очки Made0',
        1833: 'Меньше / Больше Home Team 3 очка Сделано0',
        1834: 'Меньше / Больше Вдали Команда 3 Очков Made0',
        1835: 'Гандикап 3 очка Сделано0',
        1841: 'Первые Замены0',
        1842: 'Второй гол в матче0',
        1843: 'Третий гол в матче0',
        1846: '60 Минута Результат0',
        1847: '75 Минута Результат0',
        1848: 'Гол, забитый в первые 5 минут?0',
        1849: 'Во-первых Вбрасывание в матче0',
        1850: 'Ничья в полудуплексном0',
        1851: 'Возглавил гол в матче?0',
        1852: 'Под / Over Goal минут0',
        1853: 'Под / Over Тайбрейк в матче0',
        1854: 'Под точками / размыкания0',
        1855: 'Под / Выломайте Очки Home Team0',
        1856: 'Под / Выломайте Очки Away Team0',
        1857: 'Меньше / Больше самой результативной четверть0',
        1858: 'Меньше / Больше Низшая Scoring Quarter0',
        1859: 'Большинство Пытается 3-Way0',
        1860: '10-минутная Победа Маржа 5-полосной0',
        1861: 'Большинство Потери команды0',
        1862: 'Субпроект забьет в игре0',
        1863: 'Победа Все Periods --хозяева0',
        1864: 'Win Всех периоды - Away Team0',
        1865: 'Первые 10 минут над / под Старается0',
        1866: '15 минута над / под - Уголки0',
        1867: '2 гола подряд команда0',
        1868: '3 гола подряд команда0',
        1869: 'Большинство Преобразованные Старается0',
        1872: 'Unseeded Финалист?0',
        1873: 'Wild Card в финал?0',
        1874: 'Победа Half - Теннис Поединок0',
        1875: 'Победа четверть - Теннис Поединок0',
        1881: 'Отверстие в одном0',
        1882: 'Будет Там Быть Стыковые?0',
        1883: '72 Отверстие Match Ups0',
        1884: '54 Отверстие Match Ups0',
        1885: 'Номер 1 драфт0',
        1886: '1X2 и Двойной шанс0',
        1887: 'Главная команда, чтобы выиграть \\ Draw и Обе команды забьют0',
        1888: 'На выезде команда, чтобы выиграть \\ Draw и Обе команды забьют0',
        1889: 'Победа Маржа - первая половина0',
        1891: 'Американская лига Сая Янга0',
        1892: 'Американская лига MVP0',
        1893: 'Национальная лига Сая Янга0',
        1894: 'Национальная лига MVP0',
        1895: 'Кувшин к записи Самый Регулярный сезон Wins0',
        1896: 'Игрок ударить Самый Регулярный сезон хоум-ранов0',
        1897: 'Будет ли игра Решил в дополнительное время?0',
        1898: 'Регулярный сезон Победа%0',
        1900: 'Игрок Лучший бомбардир0',
        1901: 'Над / под снасти0',
        1902: 'Номер 2 драфта0',
    }
};
