import { toastr } from 'react-redux-toastr';
import Util from '../../helper/Util';
import { isMobileOnly } from 'react-device-detect';
import casinoService from '../../services/casinoService';
import jwtService from '../../services/jwtService';
import * as Actions from './actionTypes';
import { setLoading } from './general.actions';
import { logoutUser } from './user.actions';
import { lobby_id } from '../../config/general.config';
import { Translate } from '../../localization';
let casinoGameTimer;

// Get casino games list
// export const getCasinoGames = () => {
//     return (dispatch) => {
//         casinoService
//             .getCasinoGames()
//             .then((games) => {
//                 games = games.map(game => game = ({ ...game, imgURL: game.images[1].url }))
//                 dispatch(setCasinoGames(games));
//             })
//             .catch((error) => {
//                 console.error(error);
//                 if (error && error.response && error.response.status && error.response.status === 401) {
//                     Util.handleRepeatedLogin(error.response);
//                 } else {
//                     toastr.error('', 'Something went wrong.');
//                 }
//             });
//     };
// };

export const getCasinoGames = (filters, loddy = false, callBackLoading) => {
    return (dispatch) => {
        if (filters.page == 0) {
            dispatch(clearFilteredGames());
            dispatch(setCasinoLoading(true));
        };
        casinoService
            .getCasinoGames(filters)
            .then((res) => {
                if (loddy) {
                    let games = { [filters.category]: res.results };
                    dispatch(setCasinoGames(games));
                    callBackLoading();
                } else {
                    dispatch(setCasinoFilteredGames(res.results, res.count));
                }
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};

export const getLiveCasinoGames = (filters, loddy = false, callBackLoading) => {
    return (dispatch) => {
        if (filters.page == 0) {
            dispatch(clearFilteredGames());
            dispatch(setCasinoLoading(true));
        };
        casinoService
            .getCasinoGames(filters)
            .then((res) => {
                if (loddy) {
                    let games = { [filters.search]: res.results };
                    dispatch(setCasinoGames(games));
                    callBackLoading();
                } else {
                    dispatch(setCasinoFilteredGames(res.results, res.count));
                }
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};

export const getCasinoCategories = () => {
    return (dispatch) => {
        casinoService
            .getCasinoCategories()
            .then((categories) => {
                dispatch({ type: Actions.SET_CASINO_CATEGORIES, categories });
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};

export const getCasinoProviders = (callBackLoading) => {
    return (dispatch) => {
        casinoService
            .getCasinoProviders()
            .then((providers) => {
                dispatch({ type: Actions.SET_CASINO_PROVIDERS, providers });
                if (callBackLoading) callBackLoading();
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
                if (callBackLoading) callBackLoading();
            });
    };
};

export const setCasinoSearchValue = (searchVal) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_SEARCH_VALUE, searchVal });
    };
};

export const getGGCasinoGames = () => {
    return (dispatch) => {
        casinoService.initGgSlotUser().then((res) => {
            casinoService
                .getAllGgGamesList()
                .then((games) => {
                    dispatch(setCasinoGames(games.data));
                });
        }).catch((error) => {
            console.error(error);
            if (error && error.response && error.response.status && error.response.status === 401) {
                Util.handleRepeatedLogin(error.response);
            } else {
                toastr.error('', 'Something went wrong.');
            }
        });
    };
};
// Set casino games list
export const setCasinoGames = (games) => {
    clearInterval(casinoGameTimer);
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_GAMES, games });
    };
};

export const setCasinoFilteredGames = (games, count) => {
    clearInterval(casinoGameTimer);
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_FILTERED_GAMES, games, count });
    };
};

export const setCasinoLoading = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_LOADING, value });
    };
};

export const clearFilteredGames = (games, count) => {
    clearInterval(casinoGameTimer);
    return (dispatch) => {
        dispatch({ type: Actions.CLEAR_FILTERED_GAMES });
    };
};

// Get live casino games list
// export const getLiveCasinoGames = () => {
//     return (dispatch) => {
//         casinoService
//             .getLiveCasinoGames()
//             .then((games) => {
//                 games = games.map(game => game = ({ ...game, imgURL: game.images[1].url }))
//                 dispatch(setLiveCasinoGames(games));
//             })
//             .catch((error) => {
//                 console.error(error);
//                 if (error && error.response && error.response.status && error.response.status === 401) {
//                     Util.handleRepeatedLogin(error.response);
//                 } else {
//                     toastr.error('', 'Something went wrong.');
//                 }
//             });
//     };
// };

// Set live casino games list
export const setLiveCasinoGames = (games) => {
    clearInterval(casinoGameTimer);
    return (dispatch) => {
        dispatch({ type: Actions.SET_LIVE_CASINO_GAMES, games });
    };
};

// Set casino active category
export const setCasinoActiveCategory = (category) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_ACTIVE_CATEGORY, category });
    };
};

// Set live casino active category
export const setLiveCasinoActiveCategory = (category) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LIVE_CASINO_ACTIVE_CATEGORY, category });
    };
};

// Set search started
export const setSearchStarted = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_SEARCH_STARTED, value });
    };
};

// Set live search started
export const setLiveSearchStarted = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LIVE_CASINO_SEARCH_STARTED, value });
    };
};

// Serach game by name
export const searchCasino = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_SEARCH, value });
    };
};

// Serach live game by name
export const searchLiveCasino = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LIVE_CASINO_SEARCH, value });
    };
};


// Get game data
export const getSlotGameData = (game, type, history) => {
    return (dispatch, getState) => {

        let language = getState().general.language;
        let country = getState().general.country;
        let userData = getState().user.data;
        // let gameName = game.menu_title;
        let currency = userData && userData.currency ? userData.currency === 'EUR' ? 'USD' : userData.currency : 'USD';
        casinoService
            .getGameData(game, language, country, currency, type)
            .then((data) => {
                dispatch(setSlotGameData(data));
                let game_url = data.url;
                sessionStorage.setItem('casino_url', game_url);
                history.push(`/${Translate.getLanguage()}/d/casino/game/${game.game_id}`);
                dispatch(setLoading(false));
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};

// Set game data
export const setSlotGameData = (data) => {
    // If user is playing a game in casion, refresh token every 30 minutes
    let time = 30 * 60 * 1000;
    if (!casinoGameTimer) {
        casinoGameTimer = setInterval(() => {
            let { access_token } = jwtService.getAccessToken();
            jwtService.refreshToken(access_token);
            let now = new Date();
            sessionStorage.setItem('last_request_date', now);
        }, time);
    }

    return (dispatch) => {
        dispatch({ type: Actions.SET_GAME_DATA, data });
    };
};

// Get live game data
export const getLiveGameData = (game, history) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let country = getState().general.country;
        let userData = getState().user.data;
        let currency = userData && userData.currency ? userData.currency === 'EUR' ? 'USD' : userData.currency : 'USD';
        let gameName = game.name;
        dispatch(setLoading(true));
        casinoService
            .getGameData(gameName, language, country, currency, game)
            .then((data) => {
                dispatch(setLiveGameData(data));
                sessionStorage.setItem('casino_url', data.url);
                if (!isMobileOnly) history.push(`/${Translate.getLanguage()}/d/live-casino/game/${game.id}`)
                else history.push(`/live-casino/game/${game.id}`)
                // window.location.href = data.url;
                dispatch(setLoading(false));
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else if (error.response.status && error.response.status === 403) {
                    if (history.location.pathname !== 'live-casino') {
                        if (isMobileOnly) history.push('/live-casino');
                        else history.push(`/${Translate.getLanguage()}/d/live-casino`);
                        history.push(`/${Translate.getLanguage()}/d/live-casino`);
                    }
                    toastr.error('', 'The casino is disabled for you. Please contact your agent!');
                } else if (error.response.status && error.response.status === 503) {
                    toastr.error('', 'Server Timed out.');
                } else {
                    toastr.error('', 'Something went wrong.');
                }
                dispatch(setLoading(false));
            });
    };
};

// export const geSlotGameData = (id, history) => {
//   debugger;
//     return (dispatch, getState) => {
//         let hash = getState().user.data.user_hash;
//         dispatch(setLoading(true));
//         casinoService
//             .getLiveGameData(id, hash)
//             .then((data) => {
//                 dispatch(setLiveGameData(data));
//                 sessionStorage.setItem('casino_url', data.url);
//                 window.location.href = data.url;
//                 dispatch(setLoading(false));
//             })
//             .catch((error) => {
//                 console.error(error);
//                 if (error && error.response && error.response.status && error.response.status === 401) {
//                     Util.handleRepeatedLogin(error.response);
//                 } else if (error.response.status && error.response.status === 403) {
//                     if (history.location.pathname !== '/casino') {
//                         history.push('/casino');
//                     }
//                     toastr.error('', 'The casino is disabled for you. Please contact your agent!');
//                 } else if (error.response.status && error.response.status === 503) {
//                     toastr.error('', 'Server Timed out.');
//                 } else {
//                     toastr.error('', 'Something went wrong.');
//                 }
//                 dispatch(setLoading(false));
//             });
//     };
// };

// Set live game data
export const setLiveGameData = (data) => {
    // If user is playing a game in casion, refresh token every 30 minutes
    let time = 30 * 60 * 1000;
    if (!casinoGameTimer) {
        casinoGameTimer = setInterval(() => {
            let { access_token } = jwtService.getAccessToken();
            jwtService.refreshToken(access_token);
            let now = new Date();
            sessionStorage.setItem('last_request_date', now);
        }, time);
    }

    return (dispatch) => {
        dispatch({ type: Actions.SET_LIVE_GAME_DATA, data });
    };
};

export const clearCasinoGames = () => {
    return (dispatch) => {
        dispatch({ type: Actions.CLEAR_CASINO_GAMES });
    };
};

// Clear live casino games
export const clearLiveCasinoGames = () => {
    return (dispatch) => {
        dispatch({ type: Actions.CLEAR_LIVE_CASINO_GAMES });
    };
};


//Getting Virtual sports
export const getVirtualSports = () => {
    return (dispatch) => {
        casinoService
            .getVirtualSports()
            .then((sports) => {
                dispatch(setVirtualSports(sports));
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};

// Get live game data
export const getVirtualSportsData = (game, history) => {
    return (dispatch, getState) => {
        let language = getState().general.language;
        let country = getState().general.country;
        let userData = getState().user.data;
        let currency = userData && userData.currency ? userData.currency : 'EUR';
        dispatch(setLoading(true));
        casinoService
            .getGameData(game, language, country, currency)
            .then((data) => {
                dispatch(setLiveGameData(data));
                sessionStorage.setItem('casino_url', data.url);
                window.location.href = data.url;
                dispatch(setLoading(false));
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else if (error.response.status && error.response.status === 403) {
                    if (history.location.pathname !== 'virtual-sports') {
                        if (isMobileOnly) history.push('/virtual-sports');
                        else history.push('/d/virtual-sports');
                        history.push('/d/virtual-sports');
                    }
                    toastr.error('', 'The virtual sport is disabled for you. Please contact your agent!');
                } else if (error.response.status && error.response.status === 503) {
                    toastr.error('', 'Server Timed out.');
                } else {
                    toastr.error('', 'Something went wrong.');
                }
                dispatch(setLoading(false));
            });
    };
};

// Set virtual sports list
export const setVirtualSports = (sports) => {
    // clearInterval(casinoGameTimer);
    return (dispatch) => {
        dispatch({ type: Actions.SET_VIRTUAL_SPORTS, sports });
    };
};

//CLEAR VIRTUAL SPORTS
export const clearVirtualSports = () => {
    return (dispatch) => {
        dispatch({ type: Actions.CLEAR_VIRTUAL_SPORTS });
    };
};

// Set virtual search started
export const setVirtualSearchStarted = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_VIRTUAL_SEARCH_STARTED, value });
    };
};

// Serach virtual sport by name
export const searchVirtualSports = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_VIRTUAL_SPORTS_SEARCH, value });
    };
};

// Set virtual sports active category
export const setVirtualSportsCategory = (category) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_VIRTUAL_SPORTS_CATEGORY, category });
    };
};


export const getLobbyURL = (type) => {
    return (dispatch) => {
        casinoService
            .getLobbyURL(type)
            .then((data) => {
                const lobbyUrl = type === 'ezugi' || type === 'evo' ? data : data.url;
                dispatch(setCasinoLobby(lobbyUrl));
            })
            .catch((error) => {
                console.error(error);
                if (error && error.response && error.response.status && error.response.status === 401) {
                    Util.handleRepeatedLogin(error.response);
                } else {
                    toastr.error('', 'Something went wrong.');
                }
            });
    };
};


export const setCasinoLobby = (url) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_CASINO_LOBBY, url });
    };
};

export const initGgSlotUser = () => {
    return (dispatch) => {
        dispatch(setLoadingGgSlot(true));
        casinoService.initGgSlotUser().then((res) => {
            casinoService.ggSlotURL(lobby_id).then((data) => {
                dispatch(setLoadingGgSlot(false));
                //  window.location.href = data;
            });
        }).catch((error) => {
            console.error(error);
            if (error && error.response && error.response.status && error.response.status === 401) {
                Util.handleRepeatedLogin(error.response);
            } else {
                toastr.error('', 'Something went wrong.');
            }
        });

    };
};

export const setLoadingGgSlot = (value) => {
    return (dispatch) => {
        dispatch({ type: Actions.SET_LOADING_SLOT, value });
    };
};