export const pt = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    en:  'EN',
    tr:  'TR',
    fr:  'FR',
    de:  'DE',
    ru:  'RU',
    nl:  'NL',
    th:  'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: 'Esportes',
    live: 'VIVER',
    inplay: 'VIVER',
    today: 'Hoje',
    upcoming: 'Por vir',
    tomorrow: 'Amanhã',
    highlights: 'Luzes',
    results: 'Resultados',
    lastMinute: 'Último minuto',
    liveCasino: 'Casino ao vivo',
    casino: 'Cassino',
    myBets: 'As minhas apostas',
    home: 'Casa',
    login: 'Conecte-se',
    signup: 'Inscrever-se',
    betslip: 'Boletim de apostas',
    search: 'Procurar',
    searchHere: 'Procure aqui',
    transaction: 'Transação',
    changePassword: 'Alterar a senha',
    logout: 'Sair',
    single: 'Solteiro',
    multiple: 'Múltiplo',
    possibleWin: 'Possível vitória',
    amount: 'Resultar',
    ok: 'OK',
    totalOdds: 'Total de probabilidades',
    odd: 'Chance',
    totalPossibleWin: 'Win total possível',
    placeBet: 'Colocar aposta',
    fastBet: 'Rápido Aposta',
    fast: 'Rápido',
    bet: 'Aposta',
    username: 'Nome do usuário',
    password: 'Senha',
    stayLoggedIn: 'Permaneça logado',
    all: 'Tudo',
    "in game": 'No jogo',
    won: 'Ganhou',
    lost: 'Perdido',
    refund: 'Reembolso',
    date: 'Data',
    winAmount: 'Uma quantidade da vitória',
    stake: 'Estaca',
    pick: 'Escolha',
    myAccount: 'Minha conta',
    football: 'Futebol americano',
    basketball: 'Basquetebol',
    iceHockey: 'Hockey no gelo',
    tennis: 'Tênis',
    volleyball: 'Vôlei',
    boxing: 'Boxe',
    americanFootball: 'futebol americano',
    baseball: 'Baseball.',
    hockey: 'Hóquei',
    tableTennis: 'Tênis de mesa',
    badminton: 'Badminton',
    cricket: 'Grilo',
    futsal: 'Futsal',
    golf: 'Golfe',
    handball: 'Handebol',
    rugbyLeagues: 'Ligas de rugby',
    pickCanNotBeCombined: 'Esta escolha não pode ser combinado',
    matchStopped: 'Jogo parado',
    favorites: 'Favoritos',
    cashout: 'Cashout',
    "in progress": 'Em andamento',
    rejected: 'Rejeitado',
    tip: 'Dica',
    noGoal: 'Nenhuma meta',
    yes: 'Sim',
    over: 'Sobre',
    under: 'Sob',
    even: 'Até',
    areYouSureWantCashout: 'Tem certeza de que quer saque?',
    noGoal: 'Não',
    bonus: 'Bônus',
    termsAndConditon: 'Termos e Condições',
    aboutUs: 'Sobre nós',
    contactUs: 'Contate-Nos',
    payments: 'Pagamentos',
    howPlacebet: 'Como colocar aposta',
    deposit: 'Depósito',
    credit: 'Crédito',
    debit: 'Débito',
    withdraw: 'Retirar',
    totalOdd: 'Total de Odd',
    totalStake: 'Participação total',
    topGames: 'Top Games',
    allGames: 'Todos os jogos',
    canNotBeCombined: 'Esta escolha não pode ser combinado',
    betSettled: 'Bet Saldado',
    betSuspended: 'bet Suspenso',
    insufficientBalance: 'Saldo insuficiente',
    oldPassword: 'Senha Antiga',
    newPassword: 'Nova Senha',
    confirmPassword: 'Confirme a nova senha',
    change: 'Mudar',
    fromDate: 'Da data',
    toDate: 'Até a presente data',
    activityType: 'Tipo de atividade',
    show: 'Mostrar',
    gameType: 'Tipo de jogo',
    repeatBet: 'Repita Bet',
    someThingWrong: 'Algo deu errado',
    status: 'Status',
    coupon: 'Cupom',
    acceptOddChangesText: 'Você precisa aceitar mudanças para ser capaz de colocar uma aposta',
    turnOffAway: 'Desligue qualquer maneira',
    acceptOddChanges: 'Aceitar as mudanças ímpares',
    maxWinAmount: 'Max montante vitória',
    maxOdd: 'Max estranha',
    dateTime: 'Data hora',
    type: 'Modelo',
    refreshCashout: 'Atualizar Cashout',
    vendor: 'Fornecedor',
    stakeLowerThen1: 'O jogo deve ser inferior a',
    stakeGreaterThen1: 'O jogo deve ser maior, em seguida',
    ESports: 'Esports',
    KSports: 'Ksports',
    promotion: 'Promoção',
    customerServicePage: 'Página de atendimento ao cliente',
    decimal: 'Decimal',
    fraction: 'Fração',
    americanOdds: 'Probabilidades americanas',
    nothingFound: 'Nada encontrado',
    noLiveMatch: 'Nenhuma correspondência ao vivo está disponível',
    sportBook: 'Livro de esporte',     
    marketNames: {
        1: '1x2', 
        2: 'Sob / Over', 
        238: 'restante jogo', 
        59: 'Próxima meta', 
        7: 'Chance dupla', 
        52: 'Empate não tem aposta', 
        226: '12, incluindo o prolongamento', 
        13: 'Handicap Europeia', 
        4: 'HT / FT', 
        17: 'Ambos Score Teams Para', 
        59: 'Próxima meta', 
        28: 'Sob / Over incluindo as horas extraordinárias', 
        247: 'Restante 1º Semestre', 
        
    },
    tabNames: {
        'All': 'Tudo',
        'Main Markets': 'principais Mercados',
        'Totals': 'totais',
        '1st Half': '1 ª metade',
        '2nd Half': '2ª Parte',
        'Home Team': 'Home Team',
        'Away Team': 'Time visitante',
        'All Periods': 'Todos os Períodos',
        'Results': 'Resultados',
        'Home / Away': 'Casa / Fora',
    },
    markets: {
       1:  '1x2',
       2: 'Sob / Over',
       3: 'Asian Handicap',
       5: 'Ímpar Par',
       6: 'Pontuação correta',
       7: 'Chance dupla',
       9: ' Resultado Correcto 1º Período',
       11: 'total de Corners',
       12: 'Total de faltas cometidas',
       13: 'Handicap Europeia',
       16: 'Primeira equipa a marcar',
       17: 'Ambos Score Teams Para',
       19: 'primeiro cartão',
       21: 'Sob / Over 1º Período',
       22: 'Longe equipa a marcar',
       23: 'Início equipa a marcar',
       25: 'Chance dupla de meio expediente',
       29: 'Sob / Over Rondas',
       30: 'Sob / Over Corners - Home Team',
       31: 'Sob / Over Corners - Team Longe',
       34: 'Home Team a marcar em ambas as metades',
       35: 'Longe Equipa a marca nas duas partes',
       41: '1º Período Winner',
       42: '2º Período Winner',
       43: '3º Período Winner',
       44: '4º Período Winner',
       45: 'Sob / Over 2º Período',
       46: 'Sob / Over 3º Período',
       47: 'Sob / Over 4º Período',
       48: 'Sob / Over 5º Período',
       49: '5º Período Winner',
       50: '1X2 incluindo as horas extraordinárias',
       51: 'Odd / Even Overtime Incluindo',
       52: '12',
       53: 'Asian Handicap Halftime',
       55: 'Primeira equipa a marcar 1º Período',
       56: 'Última equipa a marcar',
       57: 'Última equipe a resultado verificado ao intervalo',
       59: 'Próxima meta',
       61: 'Europeu Intervalo Handicap',
       62: 'Odd / Even Halftime',
       63: '12 Halftime',
       64: 'Asian Handicap 1º Período',
       65: 'Asian Handicap 2º Período',
       66: 'Asian Handicap Período 3º',
       67: '4º Período Asian Handicap',
       68: 'Asian Handicap 5º Período',
       69: 'Será que o Overtime jogo ir para?',
       70: 'Período de maior pontuação',
       71: 'Maior número de gols Meio',
       72: '1º Período / ímpar',
       73: '2º Período / ímpar',
       74: '3º Período / ímpar',
       75: '4º Período / ímpar',
       76: '5º Período / ímpar',
       77: 'Sob / Over Halftime',
       78: 'Para marcar o maior Sixes',
       79: 'Será uma penalidade ser atribuído?',
       80: 'primeiro Canto',
       81: 'Última Canto',
       82: 'Será um cartão vermelho ser mostrado?',
       83: 'Para manter uma folha limpa',
       84: 'Para ganhar as duas partes',
       85: 'Para Win From Behind',
       86: 'Para vencer para Nil',
       88: 'Qualificar',
       89: 'Vai primeira tentativa ser convertido?',
       90: 'Será o encontro decidido no 5 ° set?',
       91: 'Em O Meio Primeiro Golo serão marcados?',
       92: 'Primeira equipa a marcar 2ª Parte',
       95: 'Corners Handicap',
       96: 'Longe Equipe Win Para Nil',
       97: 'Home Team Win Para Nil',
       98: 'Início equipe para manter Sheet A Clean',
       99: 'Longe equipe para manter Sheet A Clean',
       100: 'Resultado Correcto 2º Período',
       101: 'Sob / Over - Home Team',
       102: 'Sob / Over - Team Longe',
       104: 'Longe equipe para ganhar ambas as metades',
       105: 'Home Team Para ganhar as duas partes',
       106: 'Home Team To Win From Behind',
       107: 'Longe equipe para ganhar From Behind',
       108: 'Início equipa a marcar de pênalti',
       109: 'Longe equipa a marcar de pênalti',
       110: 'Longe equipe para ganhar pelo menos metade',
       111: 'Início equipe para ganhar pelo menos metade',
       113: 'As duas equipas marcam 1º Semestre',
       114: 'Qualquer jogador a marcar pelo menos 2 Metas',
       117: 'Qualquer jogador a marcar pelo menos 3 golos',
       122: 'Equipe Scoring Primeiro para vencer a partida',
       123: 'Longe equipa a marcar primeiro e ganhar o jogo',
       124: 'Home Team Para marcar primeiro e ganhar o jogo',
       128: 'Número de Metas',
       129: 'Sob / Over Corners - 1º Semestre',
       132: 'Sob / Over Remates à baliza',
       133: 'Sob / Over Total de Tiros',
       134: 'Número de Metas 1º Semestre',
       136: 'Sob / Over Offsides',
       137: 'Vitória em casa Sem Bet',
       138: 'Longe Win Nenhuma aposta',
       139: 'Em que metade equipa da casa vai marcar primeiro objetivo?',
       140: 'Em qual parte Longe equipa vai marcar primeiro gol?',
       143: 'Em que metade equipa da casa vai marcar mais golos?',
       144: 'Em qual parte Longe equipa vai marcar mais golos?',
       145: 'Home Team número de gols no 1º semestre',
       146: 'Longe da equipe número de gols no 1º semestre',
       147: 'Longe da equipe número de gols na 2ª parte',
       148: 'Home Team número de gols na 2ª parte',
       149: 'Home Team número de gols',
       150: 'Longe da equipe número de gols',
       151: 'Chance dupla 2º Semestre',
       153: 'Sob / Over 1º Período - Home Team',
       154: 'Sob / Over 2º Período - Home Team',
       155: 'Sob / Over 1º Período - Team Longe',
       156: 'Sob / Over 2º Período - Team Longe',
       157: 'Sob / Over Amarelos - 1º Semestre',
       158: 'Sob / Over Amarelos',
       159: 'Será um tie-break no encontro?',
       160: 'Vencedor da corrida',
       161: '10 Resultado Minuto',
       162: '10 minuto sob / sobre',
       163: 'Número de Metas 2º semestre',
       164: 'Será um tie-break no primeiro set?',
       165: 'Sob / Over Sets',
       166: 'Sob / Over Jogos',
       168: 'Será um Gol?',
       169: 'Para marcar em ambas as metades',
       170: 'Ímpar / Par Corners',
       171: 'Para ganhar de qualquer meio',
       175: 'Será um tie-break no segundo set?',
       176: 'Será um tie-break no terceiro set?',
       177: 'Será um tie-break no quarto set?',
       178: 'Será um tie-break no quinto set?',
       180: 'Sob / Over arremessos laterais',
       181: 'Sob / Over Amarelos - Home Team',
       182: 'Sob / Over Amarelos - Home Team 1º Semestre',
       183: 'Sob / Over Amarelos - Home Team 2º Semestre',
       184: 'Sob / Over Amarelos - Team Longe',
       185: 'Sob / Over Amarelos - Visitante 1º Semestre',
       186: 'Sob / Over Amarelos - Visitante 2º Semestre',
       187: 'Sob / Over Amarelos - 2º semestre',
       188: 'Primeiro Cartão Amarelo',
       190: 'Primeiro Cartão Amarelo 2º Semestre',
       191: 'Será que algum jogador marcar um hat-trick?',
       192: 'Será um objetivo ser marcado nas duas partes?',
       194: 'Início equipe para manter A Clean Sheet 1º Período',
       195: 'Início equipe para manter A Clean Sheet 2º Período',
       196: 'Longe equipe para manter A Clean Sheet 1º Período',
       197: 'Longe equipe para manter A Clean Sheet 2º Período',
       198: 'Odd / Even - Team Início',
       199: 'Odd / Even - Team Longe',
       200: 'Será a primeira pontuação da equipe Win?',
       201: 'Jogos Asiáticos Handicap',
       202: '1º Período Winner Casa / Fora',
       203: '2º Período Winner Casa / Fora',
       204: '3º Período Winner Casa / Fora',
       205: '4º Período Winner Casa / Fora',
       206: '5º Período Winner Casa / Fora',
       207: '15 Resultado Minuto',
       208: '30 Resultado Minuto',
       209: 'Total de Cantos - 1º Semestre',
       210: 'Total de Cantos - 2º semestre',
       211: 'As duas equipas marcam 2º Semestre',
       212: 'Ambas as Equipas marca nas duas partes',
       213: 'Empate em ambas as partes',
       214: 'Sob / Over Cartões',
       215: 'Longe equipa a marcar 1º Semestre',
       216: 'Longe equipa a marcar 2ª Parte',
       217: 'Resultado Correcto 3º Período',
       218: 'Início equipa a marcar 1º Semestre',
       219: 'Início equipa a marcar 2ª Parte',
       220: 'Sob / Over - Longe Overtime equipe, incluindo',
       221: 'Sob / Over - Home Team incluindo as horas extraordinárias',
       222: 'Sob / Over 3º Período - Team Longe',
       223: 'Sob / Over 3º Período - Home Team',
       224: 'Chance dupla Período 3º',
       225: 'Chance dupla 4º Período',
       226: '12, incluindo o prolongamento',
       227: 'Vencedor da corrida 2 lugares',
       228: 'Vencedor da corrida 3 Lugares',
       229: 'Vencedor da corrida 4 Places',
       233: 'Para Win Não Nil',
       235: '1º 5 innings Winner',
       236: '1º 5 innings Under / Over',
       237: 'Haverá uma pontuação na 1ª Inning',
       238: 'restante Jogo',
       239: 'metas Gama',
       242: '1º Período Odd / Even - Home Team',
       243: '1º Período Odd / Even - Team Longe',
       244: 'Parceria maior abertura',
       245: 'Golo / Sem meta',
       247: 'Restante 1º Semestre',
       250: 'Corners Handicap - 1º Semestre',
       254: 'Cartões Gama',
       255: 'Time Of primeiro cartão',
       256: 'Home Team - Cartões Gama',
       257: 'Longe Team - Cartões Gama',
       258: 'Corners Gama',
       259: 'Equipe Com A maioria Corners',
       260: 'Equipe Com A maioria do 1º Semestre Corners',
       261: 'Equipe Com A maioria dos 2º semestre Corners',
       262: 'Home Team - Corners Gama',
       263: 'Longe Team - Corners Gama',
       264: 'Home Team - 1º Semestre Corners Gama',
       265: 'Longe Team - 1º Semestre Corners Gama',
       266: 'Home Team - 2º semestre Faixa Corners',
       267: 'Longe Team - 2º Semestre Faixa Corners',
       269: 'Metas Faixa 1º Período',
       271: 'possibilidade Mix',
       274: 'Vencedor',
       275: 'Metas Faixa 2º Período',
       278: 'Metas Faixa Equipe Início',
       279: 'Metas de gama, afastando Equipe',
       281: '1º 5 innings Asian Handicap',
       282: '1X2 Halftime',
       283: 'Asian Handicap 2º Semestre',
       284: '1X2 2º Semestre',
       285: 'Par / Ímpar 2ª Parte',
       286: '12 2ª Parte',
       287: 'Sob / Over 4º Período - Home Team',
       288: 'Sob / Over 4º Período - Team Longe',
       289: '2º Período Odd / Even - Home Team',
       290: '3º Período Odd / Even - Home Team',
       291: '4º Período Odd / Even - Home Team',
       292: '2º Período Odd / Even - Team Longe',
       293: '3º Período Odd / Even - Team Longe',
       294: '4º Período Odd / Even - Team Longe',
       297: 'SP',
       299: 'Vencedor ponto',
       300: 'Vencedor do jogo',
       301: 'meta antes',
       302: 'Goal Depois',
       304: 'Equipe com o maior período Scoring',
       305: 'Corners - Under / Exatamente / Over',
       306: 'Primeiro Golo Última Goal',
       307: 'Jogos Europeus Handicap',
       308: 'Sob / Over mais alto Período Scoring',
       309: 'Sob / Over mais baixo Período Scoring',
       310: 'Vencedor Series',
       317: 'Sob / Exatamente / Over - 1º Período',
       318: 'Sob / Exatamente / Over - 2º Período',
       319: 'Sob / Exatamente / Over - 3º Período',
       320: 'Sob / Exatamente / Over - 4º Período',
       322: 'Sob / Exatamente / Over',
       329: '1º Período Race To',
       330: '2º Período Race To',
       331: '3º Período Race To',
       332: '4º Período Race To',
       333: '5º Período Race To',
       337: 'Sob / Over - 2º semestre',
       338: 'Próximo objetivo 1º Período',
       339: 'Próxima meta 2º Período',
       341: 'race To',
       342: 'Asian Overtime Handicap Incluindo',
       343: 'Jogo Gama 1º Período',
       344: 'Jogo Gama 2º Período',
       345: 'Jogo Gama Período 3º',
       346: 'Jogo Faixa 4º Período',
       347: 'Jogo Faixa 5º Período',
       348: '6º Período Winner',
       349: 'Período 7º Winner',
       350: '6º Período / ímpar',
       351: '7º Período / ímpar',
       352: 'Sob / Over 6º Período',
       353: 'Sob / Over 7º Período',
       354: 'Sob / Over Halftime - Home Team',
       355: 'Sob / Over Halftime - Team Longe',
       356: 'Método de demissão 6-Way',
       357: 'demissão Método',
       366: 'Executa / ímpar em Over - Home Team',
       367: 'Total de Corridas / ímpar em Over - Team Longe',
       370: 'Total de Corridas em Over - Home Team',
       371: 'Total de Corridas em Over - Equipe Longe',
       386: 'Europeu Handicap incluindo as horas extraordinárias',
       387: 'Odd / Even - Home Team incluindo as horas extraordinárias',
       388: 'Odd / Even - Longe Overtime equipe, incluindo',
       390: 'HT / Overtime FT Incluindo',
       391: 'Próximo objetivo - Tempo Extra',
       392: 'Home Team Shootout Penalty',
       393: 'Longe Equipe pênaltis',
       394: 'Resultado Correcto 4º Período',
       395: 'Resultado Correcto 5º Período',
       398: 'Odd / cartões Mesmo',
       400: 'Ímpar / Par Cartões 1º Semestre',
       401: 'Sob / Mais cantos na 1ª parte - Home Team',
       402: 'Sob / Over cantos na 1ª parte - Team Longe',
       403: 'Sob / Over cantos na 2ª parte',
       404: 'Sob / Mais cantos na 2ª parte - Home Team',
       405: 'Sob / Over cantos na 2ª parte - Team Longe',
       406: 'Odd / Even cantos na 1ª parte',
       407: 'Asian Handicap Cartões',
       408: 'Asian Handicap Cartões 1º Semestre',
       409: '1X2 Corners',
       410: '1X2 cantos na 1ª parte',
       411: '12 Corners',
       414: 'Sob / Cartões Com 1º Semestre',
       415: '1X2 E Ambas as Equipas Marcam',
       416: 'Sob / Over - Tempo Extra',
       417: 'Sob / Over - extra Tempo 1º Semestre',
       419: '1X2 - Tempo Extra',
       427: '1X2 E Under / Over',
       428: 'Multiple Resultado Correcto',
       430: 'Sob / Exatamente / Over - 1º Semestre',
       431: 'Sob / Exatamente / Over - 2º semestre',
       433: 'Corners europeus Handicap',
       434: '8º Período Winner',
       435: '9º Período Winner',
       436: 'Sob / Over 8º Período',
       437: 'Sob / Over 9º Período',
       438: 'Total de Corridas / ímpar em Over',
       439: 'Total de Corridas em Over',
       445: '6º Período Winner Casa / Fora',
       446: 'Período 7º Winner Casa / Fora',
       447: '6º Período Asian Handicap',
       448: '7º Período Asian Handicap',
       449: '8º Período Asian Handicap',
       450: 'Asian Handicap 9º Período',
       451: '8º Período Winner Casa / Fora',
       452: '9º Período Winner Casa / Fora',
       453: 'Primeiro Pontuação Método',
       454: 'Primeiro Pontuação Método - Home Team',
       455: 'Primeiro Pontuação Método - Team Longe',
       456: 'Chance dupla 1 ° Período',
       457: 'Chance dupla 2º Período',
       459: 'Início equipa a marcar em todos os trimestres',
       460: 'Longe equipa a marcar em todos os trimestres',
       462: '5 Winner Top',
       463: 'Vencedor do Top 10',
       464: '12 2º Overtime Metade Incluindo',
       465: '4º Período Winner Início / Overtime distância, incluindo',
       466: 'Sob / Over 4º Período incluindo as horas extraordinárias',
       467: 'Asian Handicap 4º Período incluindo as horas extraordinárias',
       468: 'Asian Handicap 2º Semestre incluindo as horas extraordinárias',
       469: 'Sob / Over 2º Semestre incluindo as horas extraordinárias',
       472: 'Will jogo ir para Shootout?',
       475: 'Sob / Over Runs In Over - Home Team 1º Inning',
       476: 'Sob / Over Runs In Over - Longe Equipe 1º Inning',
       477: 'Sob / Over Runs In Over - Home Team 2 Inning',
       478: 'Sob / Over Runs In Over - Longe Equipe 2 Inning',
       479: 'Odd / Even Runs In Over - Home Team 1º Inning',
       480: 'Odd / Even Runs In Over - Longe Equipe 1º Inning',
       481: 'Odd / Even Runs In Over - Home Team 2º Inning',
       482: 'Odd / Even Runs In Over - Longe Equipe 2 Inning',
       483: 'Vai Home Team Get A Wicket em Over - 1º Inning?',
       484: 'Will Longe equipe obter um Wicket em Over - 1º Inning?',
       485: 'Vai Home Team Get A Wicket em Over - 2º Inning?',
       486: 'Will Longe equipe obter um Wicket em Over - 2º Inning?',
       523: 'Sob / Over e ambos Score Teams Para',
       524: '1º 7 Innings Winner',
       525: '1º 7 Innings Under / Over',
       526: '1º 7 Innings Asian Handicap',
       529: 'Par / Ímpar 2ª Overtime Metade Incluindo',
       553: 'Sob Penalidades / Over Shootout Tomado',
       554: '12 Shootout',
       555: 'Asian Handicap Shootout',
       556: 'Shootout Resultado Correcto',
       557: 'Sob / Over Shootout',
       558: 'Sob / Over Shootout - Home Team',
       559: 'Sob / Over Shootout - Team Longe',
       560: 'Equipe tomar Última Shootout Penalty',
       563: 'Race To incluindo as horas extraordinárias',
       564: '1X2 E Under / Over 5 vias',
       566: '10º Período Winner Casa / Fora',
       567: '11º Período Winner Casa / Fora',
       568: '12º Período Winner Casa / Fora',
       569: '13º Período Winner Casa / Fora',
       570: '14º Período Winner Casa / Fora',
       571: 'Período 15 Winner Casa / Fora',
       573: 'Período de 16 de Winner Casa / Fora',
       574: '17º Período Winner Casa / Fora',
       575: '18º Período Winner Casa / Fora',
       576: 'Período 19 Winner Casa / Fora',
       577: '10 Minute Under / Over - Corners',
       578: '10 Minute Under / Over - Cartões',
       579: 'próxima esquina',
       586: 'Para terminar em metade superior',
       587: 'Para terminar em último Meio',
       588: 'Para terminar inferior',
       589: 'Não para terminar em Top 4',
       590: 'Para ser relegada',
       591: 'Não a ser despromovida',
       592: 'Para final Alcance',
       593: 'Para terminar em último 6',
       594: 'Para ser promovido',
       595: 'First Time Goal',
       596: 'Metas Faixa Home Team 1º Período',
       597: 'Metas Faixa Home Team 2º Período',
       598: 'Metas de gama, afastando Equipe 1º Período',
       599: 'Metas de gama, afastando Equipe 2º Período',
       600: '1º Período Winner e ambos Score Teams Para',
       601: 'HT / FT Chance dupla',
       602: 'Resultado Correcto incluindo as horas extraordinárias',
       603: 'HT negativo / FT',
       604: 'Metas Faixa Equipe Início ambos os períodos',
       605: 'Metas gama Equipe Visitante ambos os períodos',
       606: 'Winner e metas Tipo Um',
       607: 'Chance dupla e metas',
       608: 'Winner e metas tipo dois',
       609: 'As duas equipas marcam e metas',
       610: 'Duplo Mix Possibilidade',
       628: 'Para terminar em Top 7',
       629: 'Para terminar em Top 6',
       630: 'Para terminar em Top 3',
       631: 'Para terminar em Top 2',
       632: 'Para terminar em Top 4',
       633: 'Para terminar em Top 5',
       634: 'Odd / Even Rondas 1º Período',
       635: 'Odd / Even Rondas 2º Período',
       636: 'Odd / Even Rondas 3º Período',
       637: 'Haverá Um Ás?',
       638: 'Haverá uma faca matar?',
       639: 'Vencedor do 1º Rodada',
       640: 'Vencedor 16ª Rodada',
       641: 'Haverá uma morte faca? - 1º Período',
       642: 'Haverá uma morte faca? - 2º Período',
       643: 'Haverá uma morte faca? - 3º Período',
       644: 'Haverá Um Ás? - 1º Período',
       645: 'Haverá Um Ás? - 2º Período',
       646: 'Haverá Um Ás? - 3º Período',
       647: 'Haverá Overtime? - 1º Período',
       648: 'Haverá Overtime? - 2º Período',
       649: 'Haverá Overtime? - 3º Período',
       650: '16ª rodada Vencedor - 1º Período',
       651: '16ª rodada Vencedor - 2º Período',
       652: '16ª rodada Vencedor - 3º Período',
       653: '1a rodada Vencedor - 1º Período',
       654: '1a rodada Vencedor - 2º Período',
       655: '1a rodada Vencedor - 3º Período',
       660: 'jogo Duração',
       662: 'Ímpar / Rondas Mesmo',
       663: '1º Período Duração',
       664: '2º Período Duração',
       665: '3º Período Duração',
       666: '1º Período - 1º Roshan',
       667: '2º Período - 1º Roshan',
       668: 'Período 3a-1o Roshan',
       669: '1º Período - 1º Sangue',
       670: '2º Período - 1º Sangue',
       671: '3º Período - 1º Sangue',
       672: '1º Período - 1º tou',
       673: '2º Período - 1º tou',
       674: '3º Período - 1º tou',
       675: '1º Período - 1º Quartel',
       677: '2º Período - 1º Quartel',
       678: 'Período 3º - 1º Quartel',
       679: '1º Período - 1º Turret',
       680: '2º Período - 1º Turret',
       681: 'Período terceiro-primeiro Turret',
       682: 'Race To Kills - 1º Período',
       683: 'Race To Kills - 2º Período',
       684: 'Race To Kills - 3º Período',
       685: 'Ambas as equipes para matar um dragão - 1º Período',
       686: 'Ambas as equipes para matar um dragão - 2º Período',
       687: 'Ambas as equipes para matar um dragão - 3º Período',
       688: 'Ambas as equipes para matar um inibidor - 1º Período',
       689: 'Ambas as equipes para matar um inibidor - 2º Período',
       690: 'Ambas as equipes para matar um inibidor - 3º Período',
       691: 'Ímpar / Par Kills',
       692: 'Odd / Even Kills - 1º Período',
       693: 'Odd / Even Kills - 2º Período',
       694: 'Odd / Even Kills - 3º Período',
       695: 'HT / FT e metas',
       697: 'Para terminar em Top 8',
       711: 'Jogador a marcar em qualquer momento',
       712: 'Primeiro jogador a marcar',
       713: 'Último jogador a marcar',
       714: 'Jogador a marcar 2 ou mais gols',
       715: 'Jogador a marcar 3 ou mais gols',
       727: '1º Período duas equipas marcam',
       728: '2º Período duas equipas marcam',
       729: '3º Período duas equipas marcam',
       730: '4º Período duas equipas marcam',
       731: '1º Período Início equipa a marcar',
       732: '2º Período Início equipa a marcar',
       733: '3º Período Início equipa a marcar',
       734: '4º Período Início equipa a marcar',
       735: '1º Período Longe equipa a marcar',
       736: '2º Período Longe equipa a marcar',
       737: '3º Período Longe equipa a marcar',
       738: '4º Período Longe equipa a marcar',
       751: '1º Semestre Race To',
       752: '2ª Parte Race To - incluindo as horas extraordinárias',
       754: 'Jogo Para Deuce',
       755: 'Extras Corners Tempo - Under / Exatamente / Over',
       756: 'Sob / Extra Ultrapassagem Tempo Corners',
       757: 'Handicap Asiático - Tempo Extra',
       758: '10 Minute Under / Over - pontapés livres',
       759: '10 Minute Under / Over - throw-ins',
       760: '10 Minute Under / Over - pontapés de baliza',
       762: 'Para ganhar o Toss',
       763: 'Sob / Over Executa Em Jogo',
       765: 'Para marcar o maior Fours',
       766: 'Sob / Over - Match Fours',
       767: 'Sob / Over - Match Sixes',
       768: 'A Fifty a ser marcados no jogo',
       769: 'A Hundred a ser marcados no jogo',
       770: 'A maioria dos Outs Run',
       791: 'Melhor Rebatedor - Home Team',
       792: 'Melhor Rebatedor - Team Longe',
       793: 'Top Bowler - Home Team',
       794: 'Top Bowler - Team Longe',
       795: 'Homem do jogo',
       806: 'Batedor para marcar um Cinquenta no jogo',
       808: 'Race To 10 Runs',
       809: 'Máximas 1º 6 Overs Pontuação',
       813: 'Jogador que marcar o maior Sixes',
       814: 'Maior pontuação individual',
       819: 'Para ter uma pausa ponto no jogo',
       820: 'Corrida para Corners',
       822: 'Top New Team',
       824: 'Jogador de ser reservado',
       825: 'Jogador a ser expulso',
       834: 'Definir pontos extras',
       835: 'Asian Under / Over',
       836: 'Asian Under / Over 1º Período',
       837: '1º Semestre Equipe Vitória em casa a zero',
       838: '1º Semestre Longe Equipe Win Para Nil',
       839: '1º Semestre 1X2 E Under / Over',
       840: '1º Período Home Team Win Para Nil',
       841: '1º Período Longe Equipe Win Para Nil',
       842: 'Sob / Exatamente / Over - Home Team',
       843: 'Sob / Exatamente / Over - Team Longe',
       844: 'Primeira equipa a marcar - Casa / Fora',
       845: 'Equipe de chamar First Time Out',
       846: 'Sob / Over Hits - incluindo as horas extraordinárias',
       849: 'Sob / Over 5ª Inning',
       851: 'Equipe com maior número de gols Meio',
       852: 'De qualquer equipa a marcar 3 Unanswered Tempos',
       853: '12 E Sob / Over',
       854: 'Equipe especial ou Touchdown defensiva Marcou',
       855: 'Pontos Gama',
       859: '147 Break In Jogo',
       861: 'Primeiro Pontuação Método 3 Way - Home Team',
       862: 'Primeiro Pontuação Método 3 Way - Team Longe',
       863: 'Equipe com mais alto -1X2 Período Scoring',
       864: 'Equipe Primeiro Marcador',
       865: 'Equipe Último Marcador',
       866: 'Conjuntos Handicap asiático',
       870: 'Primeiro Pontuação Método 6 Way Home / Longe',
       878: 'Metas Faixa Período 3º',
       879: 'Sob / Over Tries',
       880: 'Primeiro semestre Corners - Under / Exatamente / Mais',
       881: 'Primeiro Golo Método',
       882: 'Número de pontos',
       891: 'Equipa a marcar Longest Touchdown',
       892: 'Equipa a marcar gol Longest campo',
       893: 'Longest Touchdown Marcou',
       894: 'Shortest Touchdown Marcou',
       895: 'Goal Longest Campo Marcou',
       896: 'Time Of First Team Goal',
       897: 'Pontuação Jogo Após 2 Pontos',
       898: 'Score jogo Depois de 3 Pontos',
       899: 'Pontuação Jogo Após 4 Pontos',
       900: 'Jogo para marcar',
       901: '1º Período Depois de chumbo',
       902: '2º Período Depois de chumbo',
       903: '3º Período Depois de chumbo',
       904: '4º Período Depois de chumbo',
       905: '5º Período Depois de chumbo',
       906: 'Gol aos 4 Jogos',
       907: 'Gol aos 6 Jogos',
       908: 'Para quebrar Sirva em Set',
       909: 'Home To Win em dois sets',
       910: 'Longe para vencer em dois sets',
       911: 'Pontuação Após 2 Sets',
       912: 'Pontuação Após 3 Sets',
       914: 'Sob Metas / sobre o campo marcados - 1º Semestre',
       915: 'Sob / Over Touchdowns marcados - 1º Semestre',
       916: 'Sob / Over da equipe Home Touchdowns - 1º Semestre',
       917: 'Sob / Over Time visitante Touchdowns - 1º Semestre',
       920: 'Sob / Over Touchdowns',
       921: 'Sob / Exatamente / Over - Touchdowns',
       922: 'Sob Metas / sobre o campo',
       923: 'Sob / Exatamente / Over - metas de campo',
       924: 'Time Of Goal primeiro campo Marcou',
       925: 'Tempo do primeiro Touchdown Marcou',
       926: 'Time Of Primeiro Pontuação',
       927: 'Home Team Under / Over Touchdowns',
       928: 'Longe da equipe Under / Over Touchdowns',
       929: 'Home Team Under / Metas sobre o campo',
       930: 'Golos da equipa Under / Over Campo',
       932: 'Equipe para ter o primeiro Coaches Desafio',
       933: 'Equipe para Commit Primeira aceitado Penalty',
       934: 'segurança Marcou',
       935: 'Cartões Europeia Handicap',
       936: 'Asian Under / Over Cartões',
       938: 'Sob / Over Cards - Home Team',
       939: 'Sob / Over Cards - Equipe Longe',
       940: 'Tempo do primeiro cartão',
       941: 'Próximo jogador a marcar',
       942: 'Top Nacionalidade - Asian',
       943: 'Top Nacionalidade - Australian',
       944: 'Top Nacionalidade - European',
       945: 'Top Nacionalidade - Grã-Bretanha e Irlanda',
       946: 'Top Nacionalidade - resto do mundo',
       947: 'Sob / Over Aces',
       948: 'Sob / Over Aces - Home Team',
       949: 'Número exato de Aces',
       950: 'Sob / Over Aces - Team Longe',
       951: 'Número correto de Aces',
       955: '1X2 Aces',
       956: 'Número exato de Aces - Home Team',
       957: 'Número exato de Aces - Team Longe',
       958: 'Aces Asian Handicap',
       959: 'Race To Aces',
       960: 'Sob / Over Aces - 1º Período',
       976: '1X2 Aces - 1º Período',
       982: 'Aces Asian Handicap - 1º Período',
       986: '12 Aces',
       989: 'Sob / Over Kills - 1º Período',
       990: 'Sob / Over Kills - 2º Período',
       991: 'Sob / Over Kills - 3º Período',
       992: 'Próxima Touchdown Marcador - Home Team',
       993: 'Próxima Touchdown Marcador - Team Longe',
       994: 'Próxima Touchdown Marcador',
       995: 'Jogador que marcar o Touchdown Em qualquer momento',
       996: 'Jogador a marcar 2 ou mais Touchdowns',
       997: 'Jogador a marcar 3 ou mais Touchdowns',
       998: 'Primeiro Canto Partida - 12',
       999: 'Último jogo Corner - 12',
       1000: 'Número de conjuntos',
       1001: 'Sob Falhas / mais do dobro',
       1002: 'Sob Falhas / Over Casal - Equipe Longe',
       1003: 'Sob Falhas / Over Casal - Home Team',
       1004: 'Sob Falhas / Over Casal - 1º Período',
       1010: '12 duplas faltas',
       1011: '12 duplas faltas - 1º Período',
       1013: 'Falhas 1X2 Duplo',
       1016: 'Número exato de duplas faltas',
       1019: 'Número exato de duplas faltas - Home Team',
       1020: 'Número exato de duplas faltas - Team Longe',
       1027: 'Número correto de duplas faltas',
       1030: 'Time Of primeira curva',
       1031: 'vários Cantos',
       1034: '20º Período Winner Casa / Fora',
       1035: 'Período de 21 de Winner Casa / Fora',
       1036: 'Período 22th Winner Casa / Fora',
       1037: 'Período 23 Winner Casa / Fora',
       1038: 'Período 24 Winner Casa / Fora',
       1039: 'Período 25 Winner Casa / Fora',
       1040: '26º Período Winner Casa / Fora',
       1041: 'Período 27 Winner Casa / Fora',
       1042: 'Período 28 Winner Casa / Fora',
       1043: 'Período 29 Winner Casa / Fora',
       1044: 'Período 30 Winner Casa / Fora',
       1046: 'Período 32th Winner Casa / Fora',
       1047: 'Período 33th Winner Casa / Fora',
       1050: 'Corrida a 20 pontos',
       1051: 'Sob / Over 2º tempo - Home Team',
       1052: 'Sob / Over 2ª Parte - Team Longe',
       1053: 'Asian Under / Over 2º Período',
       1054: 'Asian Under / Over 3º Período',
       1055: 'Asian Under / Over 4º Período',
       1061: 'Asian Under / Over incluindo as horas extraordinárias',
       1062: 'Maior número de gols Half - Home Team',
       1063: 'Maior número de gols Half - Equipe Longe',
       1065: 'Home Team - Primeiro Marcador',
       1066: 'Home Team - Último Marcador',
       1067: 'Longe Team - Primeiro Marcador',
       1068: 'Longe Team - Último Marcador',
       1069: 'Sob Pontos / Over do jogador',
       1070: 'Sob sobre blocos / Player',
       1071: 'Sob Mais Assistências / Player',
       1072: 'Sob / Over Jogador Rebounds',
       1073: 'Sob / Over Jogador Rouba',
       1074: 'Sob / Over Jogador Turnovers',
       1075: 'Sob / Over do jogador 3 pontos feitos',
       1076: 'Sob / Over Jogador 3 Pontos Tentativa',
       1077: 'Sob / Over Jogador 2 pontos feitos',
       1078: 'Sob / Over Jogador 2 Pontos Tentativa',
       1079: 'Sob / Over Jogador lances livres Feito',
       1080: 'Sob / Over Jogador lances livres tentados',
       1081: '1X2 Amarelos',
       1082: 'Chance dupla Amarelos',
       1083: 'Asian Handicap Amarelos',
       1084: 'Resultado Correcto Amarelos',
       1085: 'Sob / Over Vermelhos',
       1086: 'Sob / Exatamente / pontos sobre reserva',
       1087: 'Sob / Exatamente / sobre reserva Pontos - Início da equipe',
       1088: 'Sob / Exatamente / Over reserva Pontos - Equipe Longe',
       1089: 'Sob Mais de roubos / Player e bloqueia',
       1090: 'Sob Pontos / Over do jogador',
       1091: 'Sob Mais Assistências / Player e recua',
       1092: 'Sob / Over Pontos e recua jogador',
       1093: 'Sob / Over Pontos e auxilia jogador',
       1094: 'Jogador Duplo',
       1095: 'Jogador Triple Double',
       1096: 'Sob / Exatamente / Over reserva Pontos - 1º Período',
       1100: 'Set Winner',
       1101: 'Sob / Over 180 \ s em um conjunto',
       1102: 'Set / Winner Leg',
       1103: 'Set / Leg Total de Checkouts Under / Over',
       1105: 'Sob / Over total de 180 \ s - Início da equipe',
       1106: 'Sob / Over Total de 180 \ s - Team Longe',
       1112: 'Sob Metas / Over do jogador',
       1115: 'Sob Metas / Over do jogador admitidos',
       1120: 'Sob / Over Rondas - 1º Período',
       1121: 'Sob / Over Rondas - 2º Período',
       1122: 'Sob / Over Rondas - 3º Período',
       1123: '4º Período - 1º Sangue',
       1124: '5º período - 1º Sangue',
       1125: 'Ambas as equipes para matar um dragão',
       1126: 'Ambas as equipes para matar um Baron',
       1127: 'Ambas as equipes para matar um inibidor',
       1128: 'Sob / Over Barons Slain',
       1129: 'Sob / Over Barons Slain - 1º Período',
       1130: 'Sob / Over Barons Slain - 2º Período',
       1131: 'Sob / Over Barons Slain - 3º Período',
       1132: 'Sob / Over Dragons Slain',
       1133: 'Sob / Over Dragons Slain - 1º Período',
       1134: 'Sob / Over Dragons Slain - 2º Período',
       1135: 'Sob / Over Dragons Slain - 3º Período',
       1136: 'Sob / Over 180 \ s',
       1137: 'primeiro 180',
       1138: 'Última 180',
       1139: 'Sob / Over mais alto Caixa Pontuação',
       1140: 'A maioria dos 180 \ s',
       1141: 'Sob / Over mais alto Caixa Score - Home Team',
       1142: 'Sob / Over mais alto Caixa Score - Team Longe',
       1143: 'Primeiro jogador a Touchdown Pontuação',
       1144: 'Último jogador a Touchdown Pontuação',
       1145: 'Primeiro jogador a marcar Touchdown - Home Team',
       1146: 'Primeiro jogador a marcar Touchdown - Team Longe',
       1147: 'Sob / Over Kills - 4º Período',
       1148: 'Sob / Over Kills - 5º Período',
       1149: 'Asian Handicap Kills - 1º Período',
       1150: 'Asian Handicap Kills - 2º Período',
       1151: 'Asian Handicap Kills - 3º Período',
       1152: 'Asian Handicap Kills - 4º Período',
       1153: 'Asian Handicap Kills - 5º Período',
       1154: 'Sob / Exatamente / Mais de 180 \ s',
       1155: 'Sob / Exatamente / sobre o total de 180 \ s - Início da equipe',
       1156: 'Sob / Exatamente / Over total de 180 \ s - Team Longe',
       1157: 'A maioria dos checkouts',
       1158: 'Primeira tentativa Marcador',
       1159: 'Última tentativa Marcador',
       1160: 'A qualquer momento Tente Marcador',
       1162: 'Sob / Over tous Destruído - 1º Período',
       1163: 'Sob / Over tous Destruído - 2º Período',
       1164: 'Sob / Over tous Destruído - 3º Período',
       1165: '1º Período - 1º Barão',
       1166: '2º Período - 1º Barão',
       1167: 'Período 3a-1o Baron',
       1168: '4º Período - 1º Barão',
       1169: 'Período quinto-primeira Baron',
       1170: '1º Período - 1º dragão',
       1171: '2º Período - 1º dragão',
       1172: 'Período 3a-1o Dragão',
       1173: '4º Período - 1º dragão',
       1174: 'Período quinto-primeiro Dragão',
       1175: 'A maioria Kills - 1º Período',
       1176: 'A maioria Kills - 2º Período',
       1177: 'A maioria Kills - 3º Período',
       1178: 'A maioria Kills - 4º Período',
       1179: 'A maioria Kills - 5º Período',
       1180: 'A maioria Kills - 6º Período',
       1181: 'A maioria Kills - 7º Período',
       1182: 'Jogador a marcar 2 ou mais Trys',
       1183: 'Jogador a marcar 3 ou mais Trys',
       1184: 'Não para ganhar o Leauge',
       1185: 'Sob / Exatamente / Overtime Ao longo Incluindo - Home Team',
       1186: 'Sob / Exatamente / Overtime Ao longo Incluindo - Team Longe',
       1187: 'Sob / Exatamente / Overtime Ao longo Incluindo',
       1188: 'Sob / Over Substituições',
       1189: 'Sob / Over Substituições - 1º Período',
       1190: 'Último jogador a marcar Touchdown - Home Team',
       1191: 'Último jogador a marcar Touchdown - Team Longe',
       1192: 'Primeiro jogador a bater um Ás',
       1193: 'Sob / Over Jogador Quarterback Passando Yards',
       1194: 'Sob Touchdowns Passando / Over do jogador',
       1195: 'Sob / Over Longest Jogador passagem Conclusão',
       1196: 'Sob / Over jogador que recebe Yards',
       1197: 'Sob / Over Jogador Interceptions',
       1198: 'Sob / Over Jogador Chutar Pontos',
       1199: 'Sob / Over Jogador Rushing & Receber Yards',
       1200: 'Sob / Over Jogador jardas',
       1201: 'Sob / Over Jogador Longest Recepção',
       1202: 'Sob / Over Jogador Recepções',
       1203: 'Sob Metas / sobre o campo Jogador feito',
       1204: 'Sob Metas / sobre o campo Jogador tentados',
       1205: 'Sob Touchdowns Passando / Over Jogador Quarterback',
       1206: 'Sob Conclusões Recepção / receptor Ao longo do Jogador',
       1207: 'Sob / Over Jogador estratego passa Concluído',
       1208: 'Sob / Over Jogador Rushing Touchdowns',
       1209: 'Sob tentativas Recepção / receptor Ao longo do Jogador',
       1210: 'Sob / Over Jogador estratego passa Tentativa',
       1211: '1X2 Faltas',
       1212: 'Chance dupla Faltas',
       1213: 'Sob / Over Faltas - 1º Período',
       1214: 'Sob / Over Faltas - 2º Período',
       1215: 'Asian Handicap Faltas',
       1216: 'Sob / Over Faltas - Home Team',
       1217: 'Sob / Over Faltas - Team Longe',
       1218: 'Sob Tentativas / Over Jogador do Rush',
       1219: 'Sob Touchdowns Receber / Over do jogador',
       1222: 'Sob / Over arremessos laterais - Home Team',
       1223: 'Sob / Over arremessos laterais - Team Longe',
       1224: '1X2 lançamentos laterais',
       1225: 'Chance dupla arremessos laterais',
       1226: 'Sob / Over arremessos laterais - 1º Período',
       1227: 'Throw-ins Handicap',
       1228: 'Lance-ins Handicap - 1º Período',
       1229: 'Sob / Over Remates à baliza - Home Team',
       1230: 'Sob / Over Remates à baliza - Team Longe',
       1231: 'Tiros Handicap asiático no alvo',
       1232: 'Tiros Handicap asiático no alvo - 1º Período',
       1233: 'Sob / Over Remates à baliza - 1º Período',
       1234: '1X2 Remates à baliza',
       1235: 'Tiros Hipótese dupla no alvo',
       1236: 'Sob / Over Total de Tiros - Home Team',
       1237: 'Sob / Over Total de Tiros - Team Longe',
       1238: 'Chance dupla Total de Tiros',
       1239: '1X2 Total de Tiros',
       1240: 'Asian Handicap Total de Tiros',
       1241: 'Asian Handicap Total de Tiros - 1º Período',
       1242: 'Sob / Over Total de Tiros - 1º Período',
       1243: 'Dupla possibilidade Corners',
       1244: 'Dupla possibilidade Corners - 1º Período',
       1245: 'Cantos de todos os 4 cantos do campo',
       1246: 'Chance dupla Amarelos - 1º Período',
       1247: '1X2 Amarelos - 1º Período',
       1248: 'Asian Handicap Amarelos - 1º Período',
       1249: 'Chance dupla & Under / Over',
       1250: 'Chance dupla & Ambos Score Teams Para',
       1251: '1º Período - 1º Inhibitor',
       1252: '2º Período - 1º Inhibitor',
       1253: '3º Período - 1º Inhibitor',
       1254: '4º Período - 1º Inhibitor',
       1255: '5º período - 1º Inhibitor',
       1256: 'Ambas as equipes para matar um Baron - 1º Período',
       1257: 'Ambas as equipes para matar um Baron - 2º Período',
       1258: 'Ambas as equipes para matar um Baron - 3º Período',
       1259: 'Ambas as equipes para matar um Baron - 4º Período',
       1260: 'Ambas as equipes para matar um Baron - 5º Período',
       1261: 'Ambas as equipes para matar um dragão - 4º Período',
       1262: 'Ambas as equipes para matar um dragão - 5º Período',
       1263: 'Ambas as equipes para matar um inibidor - 4º Período',
       1264: 'Ambas as equipes para matar um inibidor - 5º Período',
       1265: 'Haverá Um Ás Próximo Jogo?',
       1266: 'Haverá Falha dobro Próximo Jogo?',
       1267: 'Ímpar / Faltas Mesmo',
       1268: 'Asian Posse Handicap bola',
       1269: 'Chance dupla Offsides',
       1270: 'Asian Handicap Offsides',
       1271: 'Sob / Over Offsides - Home Team',
       1272: 'Sob / Over Offsides - Team Longe',
       1273: '1X2 Offsides',
       1274: 'Sob / Over Kills 1º Período - Home Team',
       1275: 'Sob / Over Kills 2º Período - Home Team',
       1276: 'Sob / Over Kills 3º Período - Home Team',
       1277: 'Sob / Over Kills 1º Período - Team Longe',
       1278: 'Sob / Over Kills 2º Período - Team Longe',
       1279: 'Sob / Over Kills 3º Período - Team Longe',
       1280: 'Sob / Over Wides',
       1281: 'Sob / Mais de Patos',
       1282: 'Sob / Over Wickets',
       1283: 'Sob / Over Jogo Fours - Home Team',
       1284: 'Sob / Over Jogo Seis - Home Team',
       1285: 'Sob / Over Jogo Fours - Team Longe',
       1286: 'Sob / Over Jogo Seis - Team Longe',
       1287: 'Sob / Over Extras',
       1288: 'Queda de 1 Wicket - Home Team',
       1289: 'Queda do 1º Wicket - Team Longe',
       1290: 'Equipe de Melhor Rebatedor',
       1291: 'Sob / Over Jogo Seis Home Team - 1º Inning',
       1292: 'Sob / Over Jogo Seis Longe Team - 1º Inning',
       1293: 'Sob / Over Jogo Fours Home Team - 1º Inning',
       1294: 'Sob / Over Fours partida fora de casa da equipe - 1ª Inning',
       1295: 'Sob / Over Top batedor',
       1296: '1º turno 1º Durante Demissão - Home Team',
       1297: '1º Inning 2º Nos Demissão - Home Team',
       1298: '1º Inning 3 Ao longo Demissão - Home Team',
       1299: '1º Inning 2º Nos Demissão - Team Longe',
       1300: '1º Inning 3ª Ao longo Demissão - Team Longe',
       1301: '1º Inning 1º Durante Demissão - Team Longe',
       1302: 'Maior número de gols Over - Under / Over',
       1303: '1º Inning 2 Over Under / Over - Home Team',
       1304: '1º Inning 3 Over Under / Over - Home Team',
       1306: '1º Inning 3 Over Under / Over - Equipe Longe',
       1307: '1º Inning 2 Over Under / Over - Team Longe',
       1309: 'Margem de vitória - Equipes',
       1310: 'Margem de vitória - Teams incluindo as horas extraordinárias',
       1312: 'Margem de vitória Exact',
       1314: 'Ganhar Ranges Margem',
       1315: 'Margem de vitória Exact - Equipes',
       1316: 'Margem de vitória - Equipes com qualquer outro Score',
       1318: 'Faltas Handicap Asiático - 1º Período',
       1319: 'Asian Handicap Offsides - 1º Período',
       1320: 'Asian Handicap Offsides - 2º Período',
       1321: 'Faltas Handicap asiáticos - 2º Período',
       1322: 'Tiros Handicap asiático no alvo - 2º Período',
       1324: 'Sob / Over Offsides - 1º Período',
       1325: 'Sob / Over Offsides - 2º Período',
       1326: 'primeiro Foul',
       1327: 'Primeiro Dois Pointer',
       1328: 'Primeiro Três Pointer',
       1329: 'Últimos Dois Pointer',
       1330: 'Última Três Pointer',
       1331: 'Lance Última gratuito Marcou',
       1332: 'Atira Primeiro gratuito Marcou',
       1333: 'primeiro Rebound',
       1334: 'Última Timeout',
       1335: 'Margem de vitória Equipas - 1º Período',
       1336: 'Margem de vitória Equipes - 2º Período',
       1337: 'Margem de vitória Equipes - 3º Período',
       1338: 'Margem de vitória Equipes - 4º Período',
       1339: 'Margem de vitória Teams - 5º Período',
       1340: 'Última Foul',
       1341: 'Sob / Over 10 Período',
       1342: 'Sob / Over 11 Período',
       1343: 'Sob / Over 12 Período',
       1344: 'Sob / Over 13 Período',
       1345: 'Sob / Over 14 Período',
       1346: 'Sob / Over Período 15',
       1347: 'Sob / Over Período 16',
       1348: 'Sob / Over 17 Período',
       1349: 'Sob / Over 18 Período',
       1350: 'Sob / Over 19 Período',
       1351: 'Sob / Over 20 Período',
       1352: 'Sob / Over Período 21',
       1353: 'Sob / Over 22 Período',
       1354: 'Sob / Over Período 23',
       1355: 'Sob / Over Período 24',
       1356: 'Sob / Over Período 25',
       1357: 'Sob / Over 26 Período',
       1358: 'Sob / Over Período 27',
       1359: 'Sob / Over período de 28',
       1360: 'Sob / Over Período 29',
       1361: 'Sob / Over Período 30',
       1362: 'Sob / Over 31 Período',
       1363: 'Sob / Over Período 32',
       1364: 'Sob / Over Período 33',
       1365: 'Sob / Over Período 34',
       1366: 'Sob / Over Período 35',
       1367: 'Asian Handicap 11º Período',
       1368: 'Asian Handicap 12º Período',
       1369: 'Asian Handicap 13º Período',
       1370: 'Asian Handicap 14º Período',
       1371: 'Asian Handicap Período 15',
       1372: 'Asian Handicap Período 16',
       1373: 'Asian Handicap Período 17',
       1374: 'Asian Handicap Período 18',
       1375: 'Asian Handicap Período 19',
       1376: 'Asian Handicap Período 20',
       1380: 'Asian Handicap Período 24',
       1381: 'Asian Handicap Período 25',
       1382: 'Asian Handicap 26º Período',
       1383: 'Asian Handicap Período 27',
       1384: 'Asian Handicap período de 28',
       1385: 'Asian Handicap Período 29',
       1386: 'Asian Handicap Período 30',
       1390: 'Asian Handicap Período 34',
       1391: 'Asian Handicap Período 35',
       1392: 'Para terminar em Top 10',
       1393: 'Para se manter',
       1394: 'Asian Handicap 10º Período',
       1395: 'Sob / Over Jogador Powerplay Pontos',
       1396: 'Sob / Over Jogador Plus / Minus',
       1397: 'Sob Shots / Over do jogador',
       1398: 'Top vitoriana Clube',
       1399: 'Sob / Over Run Outs',
       1400: 'Sob / Over Frames',
       1401: '20 Vencedor do Top',
       1402: '1º Líder Rodada',
       1404: '1ª Rodada Six Shooter - Grupo A',
       1406: '1º Round 3 Balls',
       1407: '1ª Rodada 18 buracos Match-Ups',
       1408: 'jogo Handicap',
       1409: 'Equipe Com Top Bowler',
       1410: 'Para ganhar o lance eo jogo',
       1411: 'Sob / Over Torres Destruído',
       1412: 'Sob / Over Torres Destruído - 1º Período',
       1413: 'Sob / Over Torres Destruído - 2º Período',
       1414: 'Sob / Over Torres Destruído - 3º Período',
       1415: 'Maior Pontuação Após 1º Ao longo',
       1416: 'Home Team Under / Over Runs - 1º Sobre a 6 de longo',
       1417: 'Longe da equipe Under / Over Runs - 1º Sobre a 6 de longo',
       1418: 'Home Team Under / Over Runs - 1º Durante a 8ª Ao longo',
       1419: 'Longe da equipe Under / Over Runs - 1º Durante a 8ª Ao longo',
       1420: 'Home Team Under / Over Runs - 1º até 10 Acima',
       1421: 'Longe da equipe Under / Over Runs - 1º até 10 Acima',
       1422: 'Chase mais de 180 corridas e equipe para ganhar',
       1423: 'Como corre muitos vão ser marcados a partir da primeira Bola',
       1424: 'Sob / Over Jogo stumpings',
       1425: '1 Método Wicket',
       1426: 'Primeira Bola Melhor em Campo',
       1427: 'Sob / Over - 1º Inning',
       1428: 'Top Não vitoriana Clube',
       1429: 'Grande Vencedor da Final',
       1431: 'Para chegar à Grande Final',
       1432: 'Não para terminar em Top 8',
       1434: 'Sob / Over Kills 4º Período - Team Longe',
       1435: 'Sob / Over Kills 5º período - Team Longe',
       1436: 'Sob / Over Kills 4º Período - Home Team',
       1437: 'Sob / Over Kills 5º período - Home Team',
       1438: 'Sob / Over temperatura a Aeroporto (Celsius)',
       1439: 'Asian Handicap - Full Time',
       1440: 'Sob / Over valor de todas números sorteados',
       1441: 'Sob / Over Valor de todos os números ímpares Drawn',
       1442: 'Sob / Over Valor de todos os números Mesmo Drawn',
       1443: 'Mais ainda são atraídas do que números ímpares',
       1444: 'Números consecutivos serão sorteados',
       1445: 'Sob / Exatamente / Over - Número de Odd bolas numeradas Drawn',
       1446: 'Sob / Exatamente / Over - número de bolas numeradas Mesmo Drawn',
       1447: 'Soma de Balls 5 Bônus',
       1448: 'Bola primeiro Bonus tirado - Par / Ímpar',
       1449: 'Bola Última Bonus tirado - Par / Ímpar',
       1450: 'Primeira Bola Bonus',
       1451: 'Última Bola Bonus',
       1452: 'O mais baixo de 5 bolas de bónus',
       1453: 'Bola Bonus - Par / Ímpar',
       1454: 'Números idênticos serão sorteados',
       1455: 'Maior número tirado - Par / Ímpar',
       1456: 'Menor número tirado - Par / Ímpar',
       1457: 'Bola 1 - Sob / Over',
       1458: 'Bola 1 - Par / Ímpar',
       1459: 'Ball 2 - Sob / Over',
       1460: 'Ball 2 - Par / Ímpar',
       1461: 'Ball 3 - Sob / Over',
       1462: 'Ball 3 - Par / Ímpar',
       1463: 'Bola 4 - Sob / Over',
       1464: 'Bola 4 - Par / Ímpar',
       1465: 'Bola 5 - Sob / Over',
       1466: 'Bola 5 - Par / Ímpar',
       1467: 'Bola 6 - Sob / Over',
       1468: 'Bola 6 - Par / Ímpar',
       1469: 'Bola 7 - Sob / Over',
       1470: 'Bola 7 - Par / Ímpar',
       1471: 'Bola 8 - Sob / Over',
       1472: 'Bola 8 - Par / Ímpar',
       1473: 'Bola 9 - Sob / Over',
       1474: 'Bola 9 - Par / Ímpar',
       1475: 'Bola 10 - Under / Over',
       1476: 'Bola 10 - Par / Ímpar',
       1477: 'Bola 11 - Under / Over',
       1478: 'Bola 11 - Par / Ímpar',
       1479: 'Bola 12 - Under / Over',
       1480: 'Bola 12 - Par / Ímpar',
       1481: 'Bola 13 - Under / Over',
       1482: 'Bola 13 - Par / Ímpar',
       1483: 'Bola 14 - Under / Over',
       1484: 'Bola 14 - Par / Ímpar',
       1485: 'Bola 15 - Under / Over',
       1486: 'Bola 15 - Par / Ímpar',
       1487: 'Bola 16 - Under / Over',
       1488: 'Bola 16 - Par / Ímpar',
       1489: 'Bola 17 - Under / Over',
       1490: 'Bola 17 - Par / Ímpar',
       1491: 'Bola 18 - Under / Over',
       1492: 'Bola 18 - Par / Ímpar',
       1493: 'Bola 19 - Under / Over',
       1494: 'Bola 19 - Par / Ímpar',
       1495: 'Bola 20 - Under / Over',
       1496: 'Bola 20 - Par / Ímpar',
       1497: 'Pelo menos um dos os números sorteados é divisível por 3',
       1498: 'Pelo menos um dos os números sorteados é divisível por 4',
       1499: 'Pelo menos um dos os números sorteados é divisível por 5',
       1500: 'Pelo menos um dos os números sorteados é divisível por 7',
       1501: 'Pelo menos um dos os números sorteados é divisível por 10',
       1502: 'Pelo menos um dos os números sorteados é divisível por 15',
       1503: 'Pelo menos um dos os números sorteados é divisível por 20',
       1504: 'Maior número tirado - Under / Over',
       1505: 'Valor Total do menor e maior números sorteados - Under / Over',
       1506: 'Menor número tirado - Under / Over',
       1507: 'Diferença entre o maior e os números mais baixos tirado - Under / Over',
       1508: 'Valor Total do menor e maior números sorteados - Par / Ímpar',
       1509: 'Diferença entre o maior e os números mais baixos tirado - Par / Ímpar',
       1510: 'Primeiro número tirado Maior do que é o último número Drawn',
       1511: 'Último número tirado - Par / Ímpar',
       1512: 'Primeiro número tirado - Par / Ímpar',
       1513: 'Todos os números sorteados Mesmo',
       1514: 'Todos os números sorteados Odd',
       1515: 'Número de desenhar',
       1520: '4º Período - 1º Turret',
       1521: 'Período quinto-primeira Turret',
       1523: 'Sob / Over Barons Slain - 4º Período',
       1524: 'Sob / Over Barons Slain - 5º Período',
       1525: 'Sob / Over Dragons Slain - 4º Período',
       1526: 'Sob / Over Dragons Slain - 5º Período',
       1527: 'Sob / Over Torres Destruído - 4º Período',
       1528: 'Sob / Over Torres Destruído - 5º Período',
       1529: '4º Período Duração',
       1530: '5º Período Duração',
       1531: 'Odd / Even Kills - 4º Período',
       1532: 'Odd / Even Kills - 5º Período',
       1533: 'Sob / Over Inibidores - 1º Período',
       1534: 'Sob / Over Inibidores - 2º Período',
       1535: 'Temperatura no aeroporto em Gama (Celsius)',
       1536: 'Sob / Over Mapas',
       1537: 'Home Team To Win & Under / Over - incluindo as horas extraordinárias',
       1538: 'Longe equipe para ganhar & Under / Over - incluindo as horas extraordinárias',
       1539: 'Vencedor rodada',
       1540: 'Pernas total Jogo Sob / Over',
       1541: 'Rondas Handicap',
       1542: 'Vencedor 1ª Etapa',
       1543: 'Primeiro a ganhar 3 Legs',
       1546: '4º Período - 1º Roshan',
       1547: 'Período quinto-primeiro Roshan',
       1548: '4º Período - 1º Quartel',
       1549: 'Período 5 - 1º Quartel',
       1550: '4º Período - 1º tou',
       1551: '5º período - 1º tou',
       1552: 'Asian Under / Over Corners',
       1553: 'As duas equipas marcam número de pontos - 1º Período',
       1554: 'As duas equipas marcam número de pontos - 1º Semestre',
       1555: 'Sob / Over 5º período - Home Team',
       1556: 'Sob / Over 5º período - Team Longe',
       1558: 'Pontos Handicap asiático',
       1559: 'Cup Winner',
       1561: '1º 3 Innings Winner',
       1562: '1 3 Innings Under / Over',
       1563: 'Time Of Scoring',
       1564: 'Time Of Scoring - Home Team',
       1565: 'Time Of Scoring - Team Longe',
       1566: 'Resultado Tempo jogo',
       1567: 'Top Nacionalidade - Sul-Africano',
       1568: 'Top Nacionalidade - O espanhol',
       1569: 'Top Nacionalidade - Irish',
       1570: 'Top Nacionalidade - South América',
       1571: 'Top Nacionalidade - Canadian',
       1572: 'Top Nacionalidade - inglês',
       1573: 'Top Nacionalidade - European Continental',
       1575: 'Fazer Cut',
       1576: 'Para srta Corte',
       1577: 'Top Nacionalidade - American',
       1578: 'Nacionalidade do vencedor',
       1579: 'Pontos Handicap Asiático - 1º Período',
       1580: 'Pontos Handicap asiáticos - 2º Período',
       1581: 'Pontos Handicap Asiático - 3º Período',
       1583: 'Top Team Bowler primeira Inning - Home Team',
       1584: 'Top Team Bowler 1º Inning - Team Longe',
       1585: 'Top Team batedor primeira Inning - Home Team',
       1586: 'Top Team batedor primeira Inning - Team Longe',
       1587: 'Haverá um Ás Next Point',
       1589: 'Haverá um Double Fault Next Point',
       1591: 'Sob / Over - 1º Ao longo',
       1592: 'Para Win League',
       1593: 'Para Win Divisão',
       1594: 'A maioria dos hits - incluindo as horas extraordinárias',
       1595: 'Luta para ir à distância',
       1596: 'Quando é que o End Luta',
       1597: 'Resultado luta',
       1598: 'rodada de apostas',
       1599: 'Top primeiro Hit',
       1600: 'Inferior 1º Hit',
       1601: 'Top 1º Run',
       1602: 'Inferior 1º Run',
       1603: 'A maioria dos acessos no 1º Inning',
       1605: 'Equipe com maior número de gols Inning',
       1606: 'As duas equipas marcam 2 ou mais golos',
       1607: 'As duas equipas marcam 3 ou mais gols',
       1608: 'Equipe a vencer o mais Períodos',
       1609: 'Asian Handicap Full Time - 1º Período',
       1610: 'Asian Handicap Full Time - 2º Período',
       1611: 'Asian Handicap Full Time - 3º Período',
       1612: 'MVP',
       1614: 'Para Conference Win',
       1615: 'winning Conference',
       1617: 'Temporada Specials jogador',
       1618: '1 5 innings Winner - 12',
       1619: '1º Período - Halftime Winner Casa / Fora',
       1620: '1º Período - Halftime Asian Handicap',
       1621: '2º Período - Halftime Winner Casa / Fora',
       1622: '2º Período - Halftime Asian Handicap',
       1624: 'Sob Runs / Over do jogador',
       1625: 'Sob Singles / Over do jogador',
       1626: 'Sob / Over Jogador home runs',
       1627: 'Sob Bases / Over Jogador Stolen',
       1628: 'Sob / Over jogador dobra',
       1629: 'Sob / Over Jogador Triples',
       1630: 'Pitcher para gravar o Win',
       1631: 'Sob / Over Jogador Total de Bases',
       1635: 'Sob Runs Pitcher / Mais de Ganhos',
       1636: 'Sob batidas Pitcher / Mais admitidos',
       1637: 'Sob Strikeouts Pitcher / Mais',
       1638: 'equipe Classification',
       1639: 'Jovem Cavaleiro Classification',
       1640: 'pontos de Classificação',
       1641: 'Rei das Montanhas',
       1642: 'Estágio 1',
       1649: 'Fastest Lap',
       1650: 'carro vencedor',
       1651: 'Primeiro piloto a se aposentar',
       1652: 'Qualificar carro vencedor',
       1653: 'Mais rápido nos treinos 1',
       1654: 'pontos Finish',
       1655: 'Fastest Qualifier',
       1657: 'fase 3',
       1659: 'Primeiro Construtor aposentadoria',
       1660: 'Grade Posição do Winner',
       1661: 'Período Safety Car durante a raça',
       1662: 'Número de drivers classificados',
       1663: 'Período Safety Car Virtual Durante raça',
       1664: 'buraco Pontuação',
       1667: 'Para Win Buraco',
       1670: 'Jogo empatado',
       1672: 'Sob / Mais de Metas - 2º semestre',
       1673: 'Odd / Even Rondas 4º Período',
       1674: 'Odd / Even Rondas 5º Período',
       1675: '2º Líder Rodada',
       1676: 'As duas equipas marcam - incluindo as horas extraordinárias',
       1677: 'Última equipa a marcar - incluindo as horas extraordinárias',
       1678: 'Top Nacionalidade - German',
       1679: 'Top Nacionalidade - austríaca',
       1680: 'Top Nacionalidade - neozelandês',
       1681: 'Top Left Handed Jogador',
       1682: 'Top Amateur',
       1683: 'Top Nacionalidade - Coreano',
       1684: 'Top Nacionalidade - Scandinavian',
       1685: 'Top Nacionalidade - O francês',
       1686: 'Vencedor Anterior Melhor',
       1687: 'Top Nacionalidade - sueco',
       1688: 'Top Nacionalidade - Japanese',
       1689: 'Top Nacionalidade - Scotsman',
       1690: 'Top Nacionalidade - Belga',
       1691: '4º Período Europeia Handicap',
       1692: 'Europeu Handicap Período 3º',
       1693: 'Europeu Handicap 2º Período',
       1694: 'Handicap Europeia 1º Período',
       1695: 'Pontuação rodada',
       1696: 'Torneio pontuação vencedora',
       1697: 'Levando gol aos Rodada',
       1698: 'Para Hit The Fairway Com tee shot',
       1699: 'Para Bater Verde regulamento Em',
       1700: '3º Líder Rodada',
       1702: 'Sob tentativas Passando / Over do jogador',
       1704: 'Touchdown em 1º Período',
       1705: 'Touchdown no 2º Período',
       1706: 'Touchdown no 3º Período',
       1707: 'Touchdown no 4º Período',
       1708: 'Sob / Exatamente / Over - Home Team Touchdowns',
       1709: 'Sob / Exatamente / Over - Visitante Touchdowns',
       1710: 'Sob / Exatamente / Over - equipe Home 1º Semestre Touchdowns',
       1711: 'Sob / Exatamente / Over - Visitante 1º Semestre Touchdowns',
       1712: '16ª rodada Vencedor - 4º Período',
       1713: '16ª rodada Vencedor - 5º Período',
       1714: 'Sob / Over tous Destruído - 4º Período',
       1715: 'Sob / Over tous Destruído - 5º Período',
       1722: 'Definido para Concluir para Nil',
       1723: 'Sob / Over Kills - 1º Período - 1ª Rodada',
       1724: 'Sob / Over Kills - 2º Período - 1ª Rodada',
       1728: 'As duas equipas marcam - prorrogação',
       1729: 'Chance dupla - Tempo Extra',
       1730: 'Será que o jogo ir para a Super Over?',
       1731: 'Chance dupla 5º Período',
       1732: 'Chance dupla 6º Período',
       1733: 'Chance dupla Período 7º',
       1734: 'Chance dupla Período 8º',
       1736: 'Número de execuções 1º Inning',
       1737: 'Número de execuções 2 Inning',
       1738: 'Número de execuções 3 Inning',
       1739: 'Número de execuções 4 Inning',
       1740: 'Número de execuções 5 Inning',
       1741: 'Número de corridas 6a vez',
       1742: 'Número de execuções 7º Inning',
       1743: 'Número de execuções 8ª Inning',
       1744: 'A Cinqüenta a ser marcados na 1ª Innings',
       1745: 'Queda do 1º Wicket',
       1747: 'Próxima Man Out',
       1748: 'Jogo concluída',
       1749: 'Century Marcou',
       1750: 'Century marcados - 1º Inning',
       1752: 'Longe Primeira equipe Demissão 6-Way',
       1753: 'Top Jogo batedor',
       1754: 'Para marcar um Penalty',
       1758: '6ª Homem do Ano',
       1760: 'Recruta do Ano',
       1761: 'Temporada MVP regular',
       1763: 'Batedor a marcar um Hundred em 1º Innings',
       1765: '9 Dart Concluir no jogo',
       1766: 'Tempo de primeira tentativa',
       1767: 'Hora da última Try',
       1770: '9 Dart Concluir na Partida - Home Team',
       1771: '9 Dart Concluir no Jogo - Team Longe',
       1772: 'Top Run Marcador',
       1773: 'Top Run Marcador - Home Team',
       1774: 'Top Run Marcador - Team Longe',
       1775: 'Equipe de Top Run Marcador',
       1776: 'Sob / Over Pontapés',
       1777: 'Sob / Over Sacks',
       1778: 'Sob / Over 1º Downs',
       1779: '1ª equipe para obter um first down',
       1780: 'Metas 1º Semestre Under / Over Field - Início da equipe',
       1781: 'Metas 1º Semestre Under / Over Field - Equipe Longe',
       1782: 'Sob / Exatamente / Over - Campo Metas 1º Semestre',
       1783: 'Sob / Over Touchdowns 1º Período',
       1784: 'Sob / Over Penalidades Aceito',
       1785: 'A maioria das mortes em Match',
       1786: 'Equipe com mais mata no jogo',
       1787: 'Sob Tentativas / Over Passe',
       1788: 'Sob / Over passagem Conclusões',
       1789: 'Sob / Over Passando Yards',
       1790: 'Sob Tentativas / Over do Rush',
       1791: 'Sob / Over do Rush Yards',
       1792: 'Sob / Over Jogador tackles e Assist',
       1794: 'Equipe para Punt Primeira',
       1795: '1º Período 1X2 E Under / Over',
       1796: '1X2 Assistências',
       1797: 'Dupla possibilidade Assistências',
       1798: 'Equipe a liderança depois de cada período de',
       1799: 'Equipe com mais Passing Yards',
       1800: 'Equipe com mais jardas',
       1801: 'Bem sucedida 2 conversão de ponto',
       1802: 'Sob / Over passes de touchdown',
       1803: 'Sob / Over Campo Metas 1º Período',
       1804: 'Pontuação em 2 últimos minutos do 1º semestre',
       1805: 'Equipe para receber Abrindo Kick Off',
       1806: 'Sob / Over Home Team Touchdowns - 1º período',
       1807: 'Sob / Over Longe da equipe Touchdowns - 1º período',
       1808: 'Sob / Exatamente / Mais de equipe Home Touchdowns - 1º período',
       1809: 'Sob / Exatamente / Over Fora Touchdowns Equipe - 1º período',
       1810: 'Sob Metas / Over Time da casa de campo - 1º período',
       1812: 'Sob / Over Golos da equipa de campo - 1º período',
       1813: 'Time visitante, Win cada trimestre',
       1814: 'Início equipa para ganhar a cada trimestre',
       1815: 'Primeira ofensiva jogo do jogo',
       1816: 'Para Win Quarter',
       1817: 'Para chegar à final',
       1818: 'Início equipa a marcar 25 Gols',
       1819: 'Início equipa a marcar 30 Gols',
       1820: 'Time visitante, empatando 25 Gols',
       1821: 'Time visitante, empatando 30 Gols',
       1822: 'Início equipe para ganhar cada parte',
       1823: 'Longe equipe para ganhar cada parte',
       1824: 'Estágio de Eliminação',
       1825: 'Sob / Over Assistências',
       1826: 'Sob / Over Rebounds',
       1827: 'Sob / Over Início Assistências equipe',
       1828: 'Sob / Over Time visitante Assistências',
       1829: 'Sob / Over Home Team Rebounds',
       1830: 'Sob / Over Time visitante Rebounds',
       1831: '1X2 3 pontos feitos',
       1832: 'Sob / Over 3 pontos feitos',
       1833: 'Sob / Team Sobre Home 3 pontos feitos',
       1834: 'Sob / Over Equipe Visitante 3 pontos feitos',
       1835: 'Asian Handicap 3 pontos feitos',
       1841: 'primeiros Substituições',
       1842: 'Segundo gol no jogo',
       1843: '3º gol no jogo',
       1846: '60 Resultado Minuto',
       1847: '75 Resultado Minuto',
       1848: 'Gol marcado nos primeiros 5 minutos?',
       1849: 'Primeiro lance-in no Jogo',
       1850: 'Desenhar em qualquer meio',
       1851: 'Chefiada golo no jogo?',
       1852: 'Sob / Over Minutos',
       1853: 'Sob / Over Tie Break no jogo',
       1854: 'Sob pontos / durante as férias',
       1855: 'Sob / Over Pontos de Quebra Home Team',
       1856: 'Sob / Over quebra pontos da equipe afastado',
       1857: 'Sob / Over mais alto Quarto com a pontuação',
       1858: 'Sob / Over mais baixo Quarto com a pontuação',
       1859: 'A maioria dos Tries 3-Way',
       1860: '10 Minuto Margem de vencimento 5-Way',
       1861: 'A maioria das perdas Equipe',
       1862: 'A Sub vai marcar no jogo',
       1863: 'Win todos os períodos - Início da equipe',
       1864: 'Win todos os períodos - Equipe Longe',
       1865: 'Primeiros 10 minutos Under / Over Tries',
       1866: '15 Minutos Sob / Over - Corners',
       1867: '2 gols em uma fila por Equipe',
       1868: '3 gols em uma fila por Equipe',
       1869: 'A maioria dos Tries convertidos',
       1872: 'Unseeded Finalista?',
       1873: 'Wild Card para chegar a final?',
       1874: 'Ganhar Half - Ténis Outright',
       1875: 'Ganhar Quarter - Ténis Outright',
       1881: 'Hole In One',
       1882: 'Haverá um Play-off?',
       1883: '72 Buraco Jogo Ups',
       1884: '54 Buraco Jogo Ups',
       1885: 'Número 1 draft pick',
       1886: '1X2 e Chance dupla',
       1887: 'Home Team to Win \\ Draw e duas equipas marcam',
       1888: 'Longe equipe para ganhar \\ Draw e duas equipas marcam',
       1889: 'Margem de vitória - 1º Semestre',
       1891: 'American League Cy Young',
       1892: 'American League MVP',
       1893: 'National League Cy Young',
       1894: 'National League MVP',
       1895: 'Pitcher para registrar o maior número de vitórias Regular Season',
       1896: 'Jogador atingir os mais Regular Season home runs',
       1897: 'Will Jogo Decidiu no tempo extra?',
       1898: 'temporada regular Winning%',
       1900: 'Jogador Melhor marcador',
       1901: 'Sob / Over Tackles',
       1902: 'Número 2 draft',
    }
}