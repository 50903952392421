export const STATIC_MARKET = {
    "id_228": {
        "name_en": "{$competitor2} total (incl. overtime)",
        "name_aa": "{$competitor2} الإجمالي (بما في ذلك الوقت الإضافي)",
        "name_de": "{$competitor2} Total (inkl. Verlängerung)",
        "name_nl": "{$competitor2} totaal (verlenging inbegrepen)",
        "name_pl": "{$competitor2} Suma (włącznie z dogrywką)",
        "name_ro": "Total (incl. prelungiri) {$competitor2}",
        "name_ru": "{$competitor2} тотал (включая овертайм)",
        "name_tr": "{$competitor2} toplam gol (uzatmalar dahil)",
        "name_se": "{$competitor2} totalt (inkl. övertid)",
        "name_el": "{$competitor2} -  σύνολο (παράταση συμπ.)"
    },
    "id_328": {
        "name_en": "Total maps",
        "name_aa": "إجمالي الخرائط",
        "name_de": "Anzahl Maps",
        "name_nl": "Totaal maps",
        "name_pl": "Suma map",
        "name_ro": "Total hărți",
        "name_ru": "Тотал карт",
        "name_tr": "Toplam harita",
        "name_se": "Totalt antal kartor",
        "name_el": "Σύνολο maps"
    },
    "id_475": {
        "name_en": "Try draw no bet",
        "name_aa": "حاول لا يوجد رهان",
        "name_de": "Versuch unentschieden, keine Wette",
        "name_nl": "Try draw no bet",
        "name_pl": "Przyłożenie remis bez zakładu",
        "name_ro": "Încercări egal pariu nul",
        "name_ru": "Попытка ничья ставки нет",
        "name_tr": "Beraberlikte iade dene",
        "name_se": "Försök oavgjort",
        "name_el": "Try  Ισοπαλία όχι στοιχήμα"
    },
    "id_566": {
        "name_en": "15 minutes - {!cornernr} corner from {from} to {to}",
        "name_aa": "15 دقيقة - الزاوية {!cornernr} من {from} إلى {to}",
        "name_de": "15 Minuten - {!cornernr} Ecke von {from} bis {to}",
        "name_nl": "15 minutes - {cornernr} hoekschop van {from} tot {to}",
        "name_pl": "15 minut - {!cornernr} rzut rożny od {from} do {to}",
        "name_ro": "15 minute - cornerul {!cornernr} de la {from} la {to}",
        "name_ru": "15 минут - {!cornernr} угловой с {from} до {to}",
        "name_tr": "15 dakika - {!cornernr}. korner {from} den {to} a",
        "name_se": "15 minuter - {!cornernr} hörnor från {from} till {to}",
        "name_el": "15 λεπτά - {!cornernr}  κόρνερ από {from} μέχρι {to}"
    },
    "id_63": {
        "name_en": "1st half - double chance",
        "name_aa": "الشوط الأول - فرصة مزدوجة",
        "name_de": "1. Halbzeit - Doppelte Chance",
        "name_nl": "1e helft - dubbele kans",
        "name_pl": "1. Połowa - Podwójna szansa",
        "name_ro": "Prima repriză - șansă dublă",
        "name_ru": "1-я половина - двойной шанс",
        "name_tr": "İlk Yarı - çifte şans",
        "name_se": "1:a halvlek - dubbelchans",
        "name_el": "1ο ημίχρονο - διπλή ευκαιρία"
    },
    "id_1088": {
        "name_en": "Scoring type {!pointnr} point (incl. overtime)",
        "name_aa": "نوعية التسجيل {!pointnr} نقطة (incl. overtime)",
        "name_de": "Scoring-Typ {!pointnr} Punkt (inkl. Verlängerung)",
        "name_nl": "Scoringstype {pointnr} punt (incl. extra tijd)",
        "name_pl": "Typ wyniku {!pointnr} punkt (włącznie z dogrywką)",
        "name_ro": "Tipul înscrierii punctului {!pointnr} (incl. prelungiri)",
        "name_ru": "Тип счета {!pointnr} очков (включая овертайм)",
        "name_tr": "Sayı yapma şekli {!pointnr}. sayı (uzatmalar dahil)",
        "name_se": "Poängtyp {!pointnr} poäng (inkl. förlängning)",
        "name_el": "Τρόπος  επίτευξης -   {!pointnr} πόντος (παράταση συμπεριλαμβάνεται)"
    },
    "id_1123": {
        "name_en": "{!inningnr} inning - Winner",
        "name_aa": "{!inningnr} inning - Winner",
        "name_de": "{!inningnr} inning - Winner",
        "name_nl": "{!inningnr}e inning - Winnaar",
        "name_pl": "{!inningnr} inning - Winner",
        "name_ro": "{!inningnr} inning - Winner",
        "name_ru": "{!inningnr} иннинг - Победитель",
        "name_tr": "{!inningnr} inning - Winner",
        "name_se": "{!inningnr} inning - Winner",
        "name_el": "{!inningnr} inning - Νικητής"
    },
    "id_924": {
        "name_en": "{%player} total 3-point field goals (incl. overtime)",
        "name_aa": "{!player} total 3-point field goals (incl. overtime)",
        "name_de": "{!player} 3-Punkte Field Goals Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal driepunter-veldgoals",
        "name_pl": "{!player} total 3-point field goals (incl. overtime)",
        "name_ro": "{!player} total 3-point field goals (incl. overtime)",
        "name_ru": "{!player} тотал 3-очковых голов с игры",
        "name_tr": "{!player} total 3-point field goals (incl. overtime)",
        "name_se": "{!player} total 3-point field goals (incl. overtime)",
        "name_el": "{!player} σύνολο 3 πόντα"
    },
    "id_972": {
        "name_en": "{$competitor1} 10/3 sixes head2head spread",
        "name_aa": "{$competitor1} 10/3 sixes head2head spread",
        "name_de": "{$competitor1} 10/3 sixes head2head spread",
        "name_nl": "{$competitor1} 10/3 zespunters onderling spread",
        "name_pl": "{$competitor1} 10/3 sixes head2head spread",
        "name_ro": "{$competitor1} 10/3 sixes head2head spread",
        "name_ru": "{$competitor1} 10/3 шести ранов очные встречи спред",
        "name_tr": "{$competitor1} 10/3 sixes head2head spread",
        "name_se": "{$competitor1} 10/3 sixes head2head spread",
        "name_el": "{$competitor1} 10/3 sixes head2head spread"
    },
    "id_141": {
        "name_en": "{$competitor2} total bookings",
        "name_aa": "{$competitor2} إجمالي الحجوزات",
        "name_de": "{$competitor2} Gesamtanzahl Karten",
        "name_nl": "Totaal aantal kaarten {$competitor2}",
        "name_pl": "{$competitor2} Suma kartek",
        "name_ro": "Total cartonașe {$competitor2}",
        "name_ru": "{$competitor2} тотал карточек",
        "name_tr": "{$competitor2} toplam kart sayısı",
        "name_se": "{$competitor2} totalt antal kort",
        "name_el": "{$competitor2} σύνολο κάρτες"
    },
    "id_906": {
        "name_en": "Championship free text market",
        "name_aa": "Championship free text market",
        "name_de": "Championship free text market",
        "name_nl": "Championship free text market",
        "name_pl": "Championship free text market",
        "name_ro": "Championship free text market",
        "name_ru": "Championship free text market",
        "name_tr": "Championship free text market",
        "name_se": "Championship free text market",
        "name_el": "Championship free text market"
    },
    "id_891": {
        "name_en": "{!goalnr} goalscorer & 1x2",
        "name_aa": "{!goalnr} هداف و1X2",
        "name_de": "{!goalnr} Torschütze & 1x2",
        "name_nl": "{!goalnr} doelpuntenmaker & 1x2",
        "name_pl": "{!goalnr} strzelec gola & 1x2",
        "name_ro": "Marcator {!goalnr} & 1x2",
        "name_ru": "{!goalnr} бомбардир и 1х2",
        "name_tr": "{!goalnr} golü atan & 1x2",
        "name_se": "{!goalnr} målgörare & 1x2",
        "name_el": "{!goalnr} σκόρερ και 1Χ2"
    },
    "id_999": {
        "name_en": "{%player} total player performance spread",
        "name_aa": "{!player} total player performance spread",
        "name_de": "{!player} total player performance spread",
        "name_nl": "{!player} totaal spelerrendement spread",
        "name_pl": "{!player} total player performance spread",
        "name_ro": "{!player} total player performance spread",
        "name_ru": "{!player} тотал результативность игрока спред",
        "name_tr": "{!player} total player performance spread",
        "name_se": "{!player} total player performance spread",
        "name_el": "{!player} total player performance spread"
    },
    "id_797": {
        "name_en": "{$event} matchday {matchday} - home teams total",
        "name_aa": "{$event} يوم المباراة {matchday} - إجمالي فرق المنزل",
        "name_de": "{$event} Spieltag {matchday} - Heimmannschaften Total",
        "name_nl": "{$event} matchday {matchday} - thuisspelende ploegen totaal",
        "name_pl": "{$event} matchday {matchday} - gospodarze suma",
        "name_ro": "{$event} etapă {matchday} - gazde total",
        "name_ru": "{$event} игровой день {matchday} - хозяева итого",
        "name_tr": "{$event} maç günü {matchday} - ev sahibi toplam",
        "name_se": "{$event} - hemmalaget totalt",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο - γηπεδούχος"
    },
    "id_207": {
        "name_en": "{!setnr} set - correct score",
        "name_aa": "{!setnr} مجموعة - النتيجة الصحيحة",
        "name_de": "{!setnr} Satz - Genaues Ergebnis",
        "name_nl": "{setnr} set - juiste uitslag",
        "name_pl": "{!setnr} set - dokładny wynik",
        "name_ro": "Set {!setnr} - scor corect",
        "name_ru": "{!setnr} сет - точный счет",
        "name_tr": "{!setnr}. set - doğru skor",
        "name_se": "{!setnr} set - rätt resultat",
        "name_el": "{!setnr} σετ - ακριβές σκορ"
    },
    "id_1134": {
        "name_en": "{!inningnr} innings - {%player1} & {%player2} dismissal method",
        "name_aa": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_de": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_nl": "{!inningnr} innings - {!player1} & {!player2} dismissal-methode",
        "name_pl": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_ro": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_ru": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_tr": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_se": "{!inningnr} innings - {!player1} & {!player2} dismissal method",
        "name_el": "{!inningnr} innings - {!player1} & {!player2} dismissal method"
    },
    "id_608": {
        "name_en": "{!inningnr} innings - odd/even",
        "name_aa": "{!inningnr} أدوار - فردي / زوجي",
        "name_de": "{!inningnr} Inning – Gerade/ungerade",
        "name_nl": "{inningnr} innings - even/oneven",
        "name_pl": "{!inningnr} innings - parzyste/nieparzyste",
        "name_ro": "{!inningnr} innings - impar/par",
        "name_ru": "{!inningnr} иннинг - чет/нечет",
        "name_tr": "{!inningnr}. devre - tek/çift",
        "name_se": "{!inningnr} innings - udda/jämnt",
        "name_el": "{!inningnr} innings – μονά/ζυγά"
    },
    "id_597": {
        "name_en": "5 minutes - total bookings from {from} to {to}",
        "name_aa": "5 دقائق - إجمالي الحجوزات من {from} إلى {to}",
        "name_de": "5 Minuten - Gesamtanzahl Karten von {from} bis {to}",
        "name_nl": "5 minutes - totaal aantal kaarten van {from} tot {to}",
        "name_pl": "5 minut - suma kartek od {from} do {to}",
        "name_ro": "5 minute - total cartonașe de la {from} la {to}",
        "name_ru": "5 минут - тотал карточек от {from} до {to}",
        "name_tr": "5 dakika - toplam kart {from} dan {to} a",
        "name_se": "5 minuter - totalt antal kort från {from} till {to}",
        "name_el": "5 λεπτά - σύνολο κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_195": {
        "name_en": "Will there be a tiebreak",
        "name_aa": "سيكون هناك شوط كسر التعادل",
        "name_de": "Wird es ein Tiebreak geben",
        "name_nl": "Zal er een tiebreak nodig zijn",
        "name_pl": "Czy będzie Tiebreak",
        "name_ro": "Va fi un tiebreak",
        "name_ru": "Будет ли тайбрейк",
        "name_tr": "Tiebreak olacak mı",
        "name_se": "Tiebreak i matchen",
        "name_el": "Θα υπάρξει τάι-μπρέικ"
    },
    "id_1078": {
        "name_en": "{!inningnr} inning - {$competitor2} total pitches",
        "name_aa": "{!inningnr} inning - {$competitor2} total pitches",
        "name_de": "{!inningnr} inning - {$competitor2} total pitches",
        "name_nl": "{inningnr} inning - totaal aantal worpen {$competitor2}",
        "name_pl": "{!inningnr} inning - {$competitor2} total pitches",
        "name_ro": "{!inningnr} inning - {$competitor2} total pitches",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал поданных подач питчером",
        "name_tr": "{!inningnr} inning - {$competitor2} total pitches",
        "name_se": "{!inningnr} inning - {$competitor2} total pitches",
        "name_el": "{!inningnr} inning - {$competitor2} σύνολο pitches"
    },
    "id_760": {
        "name_en": "Player pass completions (incl. overtime)",
        "name_aa": "لاعب تمرير الإكمالات",
        "name_de": "Spieler Pass Completions (inkl. Verlängerung)",
        "name_nl": "Speler pass completions",
        "name_pl": "Player pass completions (incl. overtime)",
        "name_ro": "Pase complete jucător",
        "name_ru": "Игрок набравший больше пойманных пасов",
        "name_tr": "Başarılı oyuncu pasları",
        "name_se": "Spelare - fullständiga passningar",
        "name_el": "Παίχτης pass completions"
    },
    "id_467": {
        "name_en": "{!scorenr} scoring play",
        "name_aa": "{!scorenr} سجل اللعب",
        "name_de": "{!scorenr} Scoring-Spiel",
        "name_nl": "{scorenr} scoring play",
        "name_pl": "{!scorenr} sposób zdobycia punktów",
        "name_ro": "Înscrierea {!scorenr}",
        "name_ru": "{!scorenr} забивающая игра",
        "name_tr": "{!scorenr}. skor oyun",
        "name_se": "{!scorenr} scoring play",
        "name_el": "{!scorenr} scoring play"
    },
    "id_846": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_aa": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_de": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor1} total interval",
        "name_ru": "{!inningnr} иннингов овер {overnr} - {$competitor1} тотал спред",
        "name_tr": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_se": "{!inningnr} innings over {overnr} - {$competitor1} total spread",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} total spread"
    },
    "id_92": {
        "name_en": "2nd half - {$competitor2} total",
        "name_aa": "الشوط الثاني - {$competitor2} الإجمالي",
        "name_de": "2. Halbzeit - {$competitor2} Total",
        "name_nl": "2e helft - {$competitor2} totaal",
        "name_pl": "2. Połowa - {$competitor2} Suma",
        "name_ro": "A 2-a repriză - {$competitor2} total",
        "name_ru": "2ая половина - тотал {$competitor2}",
        "name_tr": "İkinci yarı - {$competitor2} toplam gol",
        "name_se": "2:a halvlek - {$competitor2} totalt",
        "name_el": "2ο ημίχρονο -  {$competitor2} Σύνολο Γκολ"
    },
    "id_357": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} total",
        "name_aa": "{!inningnr} أدوار المبلغ {overnr}- {$competitor2} المجموع",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor2} Total",
        "name_nl": "{inningnr} innings over {overnr} - totaal {$competitor2}",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} suma",
        "name_ro": "{!inningnr} innings over {overnr} - total {$competitor2}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor2} тотал",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor2} toplam",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor2} totalt",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} σύνολο"
    },
    "id_120": {
        "name_en": "Overtime 1st half - handicap",
        "name_aa": "الوقت الإضافي في النصف الأول - عائق",
        "name_de": "Verlängerung 1. Halbzeit - Handicap",
        "name_nl": "Eerste helft verlenging - handicap",
        "name_pl": "Dogrywka 1. połowa - handicap",
        "name_ro": "Prelungiri Repriza 1 - handicap",
        "name_ru": "Овертайм 1ая половина - гандикап",
        "name_tr": "İlk uzatma devresi - handikap",
        "name_se": "Övertid 1:a halvlek - handikapp",
        "name_el": "Παράταση 1ο ημίχρονο - Χάντικαπ"
    },
    "id_440": {
        "name_en": "{$competitor2} to score in all periods",
        "name_aa": "{$competitor2} للتسجيل في جميع الفترات",
        "name_de": "{$competitor2} wird in allen Perioden punkten",
        "name_nl": "{$competitor2} scoort in alle periodes",
        "name_pl": "{$competitor2} zapunktuje we wszystkich częściach gry",
        "name_ro": "{$competitor2} să înscrie în toate reprizele",
        "name_ru": "{$competitor2} забить во всех периодах",
        "name_tr": "{$competitor2} her periyotta sayı yapar",
        "name_se": "{$competitor2} att göra mål i alla perioder",
        "name_el": "{$competitor2} να σκοράρει σε όλες τις περιόδους"
    },
    "id_675": {
        "name_en": "{!inningnr} innings - {$competitor2} top batter",
        "name_aa": "{!inningnr} الأدوار - {$competitor2} أعلى الخليط",
        "name_de": "{!inningnr} Inning - {$competitor2} Bester Batter",
        "name_nl": "{inningnr} innings - beste slagman {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} najlepszy batter",
        "name_ro": "{!inningnr} innings - {$competitor2} top batter",
        "name_ru": "{!inningnr} иннингов - {$competitor2} лучший бэттер",
        "name_tr": "{!inningnr}. devre - {$competitor2} en iyi vurucu",
        "name_se": "{!inningnr} innings - {$competitor2} bästa slagman",
        "name_el": "{!inningnr} innings - {$competitor2} top batter"
    },
    "id_420": {
        "name_en": "Which team to score (incl. overtime and penalties)",
        "name_aa": "أي فريق يسجل (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Welche Mannschaft trifft (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Welk team scoort (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Który zespół strzeli (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Care echipă înscrie (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Какая команда забьет (включая овертайм и пенальти)",
        "name_tr": "Hangi takım gol atar (uzatmalar ve penaltılar dahil)",
        "name_se": "Vilket lag gör mål (inkl. övertid och straffar)",
        "name_el": "Ποια ομάδα θα σκοράρει (παράταση και πέναλτι συμπ.)"
    },
    "id_1010": {
        "name_en": "{%competitor} total eagles",
        "name_aa": "{!competitor} total eagles",
        "name_de": "{!competitor} Eagles Gesamt",
        "name_nl": "totaal aantal eagles {!competitor}",
        "name_pl": "{!competitor} total eagles",
        "name_ro": "{!competitor} total eagles",
        "name_ru": "{!competitor} total eagles",
        "name_tr": "{!competitor} total eagles",
        "name_se": "{!competitor} total eagles",
        "name_el": "{!competitor} total eagles"
    },
    "id_231": {
        "name_en": "2nd half - handicap (incl. overtime)",
        "name_aa": "الشوط الثاني -هانديكاب (بما في ذلك الوقت الإضافي)",
        "name_de": "2. Halbzeit - Handicap (inkl. Verlängerung)",
        "name_nl": "Tweede helft - handicap (verlenging inbegrepen)",
        "name_pl": "2. połowa - handicap (włącznie z dogrywką)",
        "name_ro": "Repriza 2 - handicap (incl. prelungiri)",
        "name_ru": "2ая половина - гандикап (включая овертайм)",
        "name_tr": "İkinci yarı - handikap (uzatmalar dahil)",
        "name_se": "2:a halvlek - handikapp (inkl. övertid)",
        "name_el": "2ο ημίχρονο - χάντικαπ (παράταση συμπ.)"
    },
    "id_1151": {
        "name_en": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_aa": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_de": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_nl": "Overs 0 tot {overnr} - {$competitor2} totaal ({ballcount} ball)",
        "name_pl": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_ro": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_ru": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_tr": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_se": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)",
        "name_el": "Overs 0 to {overnr} - {$competitor2} total ({ballcount} ball)"
    },
    "id_168": {
        "name_en": "{$competitor2} total corners",
        "name_aa": "{$competitor2} مجموع الزوايا",
        "name_de": "{$competitor2} Gesamtanzahl Ecken",
        "name_nl": "Totaal aantal hoekschoppen {$competitor2}",
        "name_pl": "{$competitor2} Suma rzutów rożnych",
        "name_ro": "Total cornere {$competitor2}",
        "name_ru": "{$competitor2} тотал угловых",
        "name_tr": "{$competitor2} toplam korner sayısı",
        "name_se": "{$competitor2} totalt antal hörnor",
        "name_el": "{$competitor2} σύνολο κόρνερ"
    },
    "id_493": {
        "name_en": "Frame handicap",
        "name_aa": "هانديكاب الإطار",
        "name_de": "Frame Handicap",
        "name_nl": "Frame handicap",
        "name_pl": "Frejmy Handicap",
        "name_ro": "Handicap frame-uri",
        "name_ru": "Гандикап фреймов",
        "name_tr": "Frame handikapı",
        "name_se": "Frame handikapp",
        "name_el": "Frame χάντικαπ"
    },
    "id_736": {
        "name_en": "{!mapnr} map 1st half - winner",
        "name_aa": "{!mapnr} خريطة الشوط الأول - الفائز",
        "name_de": "{!mapnr} Map 1te Hälfte - Sieger",
        "name_nl": "{mapnr} map eerste helft - winnaar",
        "name_pl": "{!mapnr} mapa 1. połowa - zwycięzca",
        "name_ro": "Harta {!mapnr} repriza 1 - câștigător",
        "name_ru": "{!mapnr} карта 1-й тайм - победитель",
        "name_tr": "{!mapnr}. harita ilk yarı - kazanan",
        "name_se": "{!mapnr} karta 1:a halvlek - vinnare",
        "name_el": "{!mapnr} map 1st half - νικητής"
    },
    "id_1092": {
        "name_en": "Winner of group",
        "name_aa": "Winner of group",
        "name_de": "Winner of group",
        "name_nl": "Winnaar van groep",
        "name_pl": "Winner of group",
        "name_ro": "Winner of group",
        "name_ru": "Победитель группы",
        "name_tr": "Winner of group",
        "name_se": "Winner of group",
        "name_el": "Νικητής ομίλου"
    },
    "id_965": {
        "name_en": "{$competitor1} to lead by {points}",
        "name_aa": "{$competitor1} to lead by {points}",
        "name_de": "{$competitor1} to lead by {points}",
        "name_nl": "{$competitor1} staat {points} punten voor",
        "name_pl": "{$competitor1} to lead by {points}",
        "name_ro": "{$competitor1} to lead by {points}",
        "name_ru": "{$competitor1} лидирует на {points}",
        "name_tr": "{$competitor1} to lead by {points}",
        "name_se": "{$competitor1} to lead by {points}",
        "name_el": "{$competitor1} να προηγείται με  {points}"
    },
    "id_365": {
        "name_en": "Set handicap {hcp}",
        "name_aa": "تعيين إعاقة {hcp}",
        "name_de": "Satz Handicap {hcp}",
        "name_nl": "Set handicap {hcp}",
        "name_pl": "Sety handicap {hcp}",
        "name_ro": "Handicap set {hcp}",
        "name_ru": "Гандикап на сет {hcp}",
        "name_tr": "Set handikapı {hcp}",
        "name_se": "Set handikapp {hcp}",
        "name_el": "{hcp} χάντικαπ σετ"
    },
    "id_64": {
        "name_en": "1st half - draw no bet",
        "name_aa": "الشوط الأول - رسم لا رهان",
        "name_de": "1. Halbzeit - unentschieden, keine Wette",
        "name_nl": "1e helft - draw no bet",
        "name_pl": "1. Połowa - Remis bez zakładu",
        "name_ro": "Prima repriză - egal pariu nul",
        "name_ru": "1-я половина - ничья ставки нет",
        "name_tr": "İlk yarı - beraberlikte iade",
        "name_se": "1:a halvlek - Insatsen tillbaka vid oavgjort",
        "name_el": "1ο ημίχρονο - ισοπαλία όχι στοίχημα"
    },
    "id_839": {
        "name_en": "{!inningnr} innings - {$competitor1} total spread",
        "name_aa": "{!inningnr} innings - {$competitor1} total spread",
        "name_de": "{!inningnr} innings - {$competitor1} total spread",
        "name_nl": "{inningnr} innings - {$competitor1} total spread",
        "name_pl": "{!inningnr} innings - {$competitor1} total spread",
        "name_ro": "{!inningnr} innings - {$competitor1} total interval",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал спред",
        "name_tr": "{!inningnr} innings - {$competitor1} total spread",
        "name_se": "{!inningnr} innings - {$competitor1} total spread",
        "name_el": "{!inningnr} innings - {$competitor1} total spread"
    },
    "id_877": {
        "name_en": "{!inningnr} innings - {$competitor1} total",
        "name_aa": "{!inningnr} أدوار - {$competitor1} الإجمالي",
        "name_de": "{!inningnr} Innings - {$competitor1} insgesamt",
        "name_nl": "{!inningnr} innings - Totaal {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma",
        "name_ro": "Innings {!inningnr} - {$competitor1} total",
        "name_ru": "{!inningnr} иннинги - {$competitor1} итог",
        "name_tr": "{!inningnr} devre - {$competitor1} toplam",
        "name_se": "{!inningnr} innings - {$competitor1} totalt",
        "name_el": "{!inningnr} innings - {$competitor1} total"
    },
    "id_748": {
        "name_en": "{!inningnr} inning - {$competitor2} total",
        "name_aa": "{!inningnr} الشوط - {$competitor2} الإجمالي",
        "name_de": "{!inningnr} Inning - {$competitor2} Total",
        "name_nl": "{inningnr} inning - {$competitor2} totaal",
        "name_pl": "{!inningnr} inning - {$competitor2} suma",
        "name_ro": "{!inningnr} inning - {$competitor2} total",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam",
        "name_se": "{!inningnr} inning - {$competitor2} totalt",
        "name_el": "{!inningnr} inning - {$competitor2} total"
    },
    "id_1093": {
        "name_en": "Fastest lap",
        "name_aa": "Fastest lap",
        "name_de": "Fastest lap",
        "name_nl": "Snelste ronde",
        "name_pl": "Fastest lap",
        "name_ro": "Fastest lap",
        "name_ru": "Самый быстрый круг",
        "name_tr": "Fastest lap",
        "name_se": "Fastest lap",
        "name_el": "Ταχύτερος γύρος"
    },
    "id_103": {
        "name_en": "15 minutes - {!goalnr} goal from {from} to {to}",
        "name_aa": "15 دقيقة - {!goalnr} هدف من {from} إلى {to}",
        "name_de": "15 Minuten - {!goalnr} Tor von {from} bis {to}",
        "name_nl": "15 minuten - {goalnr} doelpunt van {from} tot {to}",
        "name_pl": "15 minut - {!goalnr} gol od {from} do {to}",
        "name_ro": "15 minute - golul {!goalnr} de la {from} la {to}",
        "name_ru": "15 минут - {!goalnr} гол от {from} до {to}",
        "name_tr": "15 dakika - {!goalnr} gol {from} den {to} a",
        "name_se": "15 minuter - {!goalnr} mål från {from} till {to}",
        "name_el": "15 λεπτά -  {!goalnr} γκολ από {from} μέχρι {to}"
    },
    "id_804": {
        "name_en": "{$event} - winner",
        "name_aa": "{$event} - الفائز",
        "name_de": "{$event} - Sieger",
        "name_nl": "{$event} - winnaar",
        "name_pl": "{$event} - zwycięzca",
        "name_ro": "{$event} - câștigătoare",
        "name_ru": "{$event} - победитель",
        "name_tr": "{$event} - kazanan",
        "name_se": "{$event} - vinnare",
        "name_el": "{$event} - νικητής"
    },
    "id_828": {
        "name_en": "Bonus ball single digit",
        "name_aa": "مكافأة الكرة رقم واحد",
        "name_de": "Zustzzahl einstellig",
        "name_nl": "Eencijferige reservebal",
        "name_pl": "Bonus ball single digit",
        "name_ro": "Bilă bonus o singură cifră",
        "name_ru": "Бонус бол одна цифра",
        "name_tr": "Bonus ball tek hane",
        "name_se": "Bonusboll ensiffrig",
        "name_el": "Bonus ball single digit"
    },
    "id_102": {
        "name_en": "15 minutes - 1x2 from {from} to {to}",
        "name_aa": "15 دقيقة - 1 × 2 من {from} إلى {to}",
        "name_de": "15 Minuten - 1x2 von{from} bis {to}",
        "name_nl": "15 minuten - 1x2 van {from} tot {to}",
        "name_pl": "15 minut - 1x2 od {from} do {to}",
        "name_ro": "15 minute - 1x2 de la {from} la {to}",
        "name_ru": "15 минут - 1х2 от {from} до {to}",
        "name_tr": "15 dakika - 1x2 {from}'dan {to}'a kadar",
        "name_se": "15 minuter - 1x2 från {from} till {to}",
        "name_el": "15 λεπτά - 1Χ2 από  {from} μέχρι {to}"
    },
    "id_808": {
        "name_en": "{$event} group {group} - group winner",
        "name_aa": "{$event} المجموعة {group} - الفائز مجموعة",
        "name_de": "{$event} Gruppe {group} - Gruppensieger",
        "name_nl": "{$event} groep {group} - groepswinnaar",
        "name_pl": "{$event} grupa {group} - zwycięzca grupy",
        "name_ro": "{$event} grupa {group} - câștigătoarea grupei",
        "name_ru": "{$event} группа {group} - победитель группы",
        "name_tr": "{$event} - grup {group} - grup kazananı",
        "name_se": "{$event} - gruppvinnare",
        "name_el": "{$event} Ομίλου {group} - νικητής ομίλων"
    },
    "id_204": {
        "name_en": "{!setnr} set - total games",
        "name_aa": "{!setnr} مجموعة - مجموع الألعاب",
        "name_de": "{!setnr} Satz - Gesamtanzahl Spiele",
        "name_nl": "{setnr} set - totaal aantal games",
        "name_pl": "{!setnr} set - suma gemów",
        "name_ro": "Set {!setnr} - total game-uri",
        "name_ru": "{!setnr} сет - тотал геймов",
        "name_tr": "{!setnr}. set - toplam oyun",
        "name_se": "{!setnr} set - totalt antal games",
        "name_el": "{!setnr} σετ - σύνολο γκέιμ"
    },
    "id_290": {
        "name_en": "Winning margin (incl. overtime)",
        "name_aa": "هامش الربح (بما في ذلك الوقت الإضافي)",
        "name_de": "Gewinnspanne (inkl. Verlängerung)",
        "name_nl": "Winstmarge (verlenging inbegrepen)",
        "name_pl": "Margines zwycięstwa (włącznie z dogrywką)",
        "name_ro": "Diferență victorie (incl. prelungiri)",
        "name_ru": "Победа с разницей (включая овертайм)",
        "name_tr": "Kazanma farkı (uzatmalar dahil)",
        "name_se": "Vinstmarginal (inkl. övertid)",
        "name_el": "Περιθώριο νίκης (παράταση συμπ.)"
    },
    "id_1131": {
        "name_en": "Both teams to score {milestone}",
        "name_aa": "Both teams to score {milestone}",
        "name_de": "Both teams to score {milestone}",
        "name_nl": "Beide teams scoren {milestone}",
        "name_pl": "Both teams to score {milestone}",
        "name_ro": "Both teams to score {milestone}",
        "name_ru": "Both teams to score {milestone}",
        "name_tr": "Both teams to score {milestone}",
        "name_se": "Both teams to score {milestone}",
        "name_el": "Να σκοράρουν και οι 2 ομάδες {milestone}"
    },
    "id_1143": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_aa": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_de": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_nl": "{!inningnr} innings over {overnr} - {$competitor1} scoort een boundary vier & een zes",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_ru": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_tr": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_se": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} to score a boundary four & a six"
    },
    "id_466": {
        "name_en": "Overtime - {$competitor2} no bet",
        "name_aa": "الوقت الإضافي - {$competitor2} لا يوجد رهان",
        "name_de": "Verlängerung - {$competitor2} keine Wette",
        "name_nl": "Verlenging - {$competitor2} no bet",
        "name_pl": "Dogrywka - {$competitor2} bez zakładu",
        "name_ro": "Prelungiri - {$competitor2} pariu nul",
        "name_ru": "Овертайм - на {$competitor2} нет ставки",
        "name_tr": "Uzatmalar - {$competitor2} kazanırsa iade",
        "name_se": "Övertid - {$competitor2} inget spel",
        "name_el": "Παράταση - {$competitor2} ισοπαλία επιστροφή στοιχήματος"
    },
    "id_1089": {
        "name_en": "Any team scoring type {!pointnr} point (incl. overtime)",
        "name_aa": "نوعية تسجيل أي فريق  نقطة (incl. overtime)",
        "name_de": "Jeder Team Scoring-Typ {!pointnr} Punkt (inkl. Verlängerung)",
        "name_nl": "Willekeurig team scoringstype {pointnr} punt (incl. extra tijd)",
        "name_pl": "Którykolwiek zespół typ wyniku {!pointnr} punkt (włącznie z dogrywką)",
        "name_ro": "Oricare echipă tipul înscrierii punctului {!pointnr} (incl. prelungiri)",
        "name_ru": "Тип счета любой команды {!pointnr} очков (включая овертайм)",
        "name_tr": "Herhangi bir takım sayı yapma şekli {!pointnr}. sayı (uzatmalar dahil)",
        "name_se": "Vilket lag som helsts poängtyp {!pointnr} poäng (inkl. förlängning)",
        "name_el": "Οποιαδήποτε ομάδα, τρόπος  επίτευξης -  {!pointnr} πόντος (παράταση συμπεριλαμβάνεται)"
    },
    "id_387": {
        "name_en": "{!setnr} set - most 180s",
        "name_aa": "مجموعة {!setnr} - معظم 180",
        "name_de": "{!setnr} Satz - Die meisten 180s",
        "name_nl": "{setnr} set - meeste 180s",
        "name_pl": "{!setnr} set - najwięcej 180s",
        "name_ro": "Set {!setnr} - cele mai multe 180-uri",
        "name_ru": "{!setnr} сет - большинство 180",
        "name_tr": "{!setnr}. set - en çok yapılıcak 180",
        "name_se": "{!setnr} set - flest 180:or",
        "name_el": "{!setnr} set - περισσότερα 180"
    },
    "id_279": {
        "name_en": "Innings 1 to 5th top - 1x2",
        "name_aa": "ألدور 1إلى5 أعلى- 1x2",
        "name_de": "Innings 1 bis Anfang 5tes - 1x2",
        "name_nl": "Eerste helft (top) Inning 1 tot 5 - 1x2",
        "name_pl": "Innings 1 do początku 5. - 1x2",
        "name_ro": "Innings de la 1 la 5 top - 1x2",
        "name_ru": "Иннинги с 1 до 5го верха - 1x2",
        "name_tr": "Devreler 1den 5in sonuna - 1x2",
        "name_se": "Innings 1 till 5th topp - 1x2",
        "name_el": "Innings 1 σε 5 top - 1Χ2"
    },
    "id_351": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - 1x2",
        "name_aa": "{!inningnr} أدوار المبالغ 0 إلى {overnr} - 1X2",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - 1x2",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - 1x2",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - 1x2",
        "name_ro": "{!inningnr} innings overs de la 0 la {overnr}- 1x2",
        "name_ru": "{!inningnr} иннинг оверы от 0 до {overnr} - 1x2",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over - 1x2",
        "name_se": "{!inningnr} innings över 0 till {overnr} - 1x2",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - 1x2"
    },
    "id_257": {
        "name_en": "US Spread (incl. extra innings)",
        "name_aa": "انتشار الولايات المتحدة (بما في ذلك أدوار إضافية)",
        "name_de": "US Spread (inkl. Extra Innings)",
        "name_nl": "US spread (extra innings inbegrepen)",
        "name_pl": "US Spread (włącznie z extra innings)",
        "name_ro": "Interval US (incl. inning-uri extra)",
        "name_ru": "США спред (включая дополнительные иннинги)",
        "name_tr": "US spread (uzatma devreleri dahil)",
        "name_se": "US spread (inkl. extra innings)",
        "name_el": "Αμερικάνικο Σπρεντ (έξτρα innings συμπ.)"
    },
    "id_889": {
        "name_en": "Anytime goalscorer & correct score",
        "name_aa": "في أي وقت هداف والنتيجة الصحيحة",
        "name_de": "Torschütze(n) & richtiger Punktestand",
        "name_nl": "Doelpuntenmaker & juiste uitslag",
        "name_pl": "Strzelec gola & dokładny wynik",
        "name_ro": "Marcator oricând & scor corect",
        "name_ru": "Любая минута бомбардир и точный счёт",
        "name_tr": "Her zaman golü atan & doğru skor",
        "name_se": "När som helst målgörare & korrekt resultat",
        "name_el": "Να σκοράρει οποτεδήποτε (90 λεπτά)και ακριβές σκορ"
    },
    "id_1138": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_aa": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_de": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_nl": "{!inningnr} innings over {overnr} - {deliverynr} delivery {$competitor1} is een wicket",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_ro": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_ru": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_tr": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_se": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} to be a wicket"
    },
    "id_642": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} dismissal",
        "name_aa": "{!inningnr} أدوار أكثر من {overnr} - {$competitor2} فصل",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor2} Dismissal",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor2} dismissal",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} wyeliminowanie",
        "name_ro": "{!inningnr} innings over {overnr} - demitere {$competitor2}",
        "name_ru": "{!inningnr} иннингов {overnr} овер - {$competitor2} выбиваний",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor2} oyundan çıkarılma",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor2} avvisningar",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} dismissal"
    },
    "id_36": {
        "name_en": "Total & both teams to score",
        "name_aa": "مجموع و كلا الفريقين ليسجل",
        "name_de": "Total & Beide Mannschaften treffen",
        "name_nl": "Totaal & beide teams scoren",
        "name_pl": "Suma & Oba zespoły zdobędą gola",
        "name_ro": "Total & smbele echipe să înscrie",
        "name_ru": "Тотал и обе команды забьют",
        "name_tr": "Toplam gol ve karşılıklı gol",
        "name_se": "Totalt & båda lagen gör mål",
        "name_el": "Σύνολο Γκολ  και να σκοράρουν και οι 2 ομάδες"
    },
    "id_900": {
        "name_en": "{$competitor1} {!c1goalnr} goalscorer",
        "name_aa": "{$competitor1} {!c1goalnr} goalscorer",
        "name_de": "{$competitor1} {!c1goalnr} Torschütze",
        "name_nl": "{$competitor1} {!c1goalnr} doelpuntenmaker",
        "name_pl": "{$competitor1} {!c1goalnr} goalscorer",
        "name_ro": "{$competitor1} marcator {!c1goalnr}",
        "name_ru": "{$competitor1} {!c1goalnr} бомбардир",
        "name_tr": "{$competitor1} {!c1goalnr} goalscorer",
        "name_se": "{$competitor1} {!c1goalnr} målgörare",
        "name_el": "{$competitor1} {!c1goalnr} σκόρερ"
    },
    "id_49": {
        "name_en": "{$competitor2} to win both halves",
        "name_aa": "{$competitor2} للفوز كل شوط",
        "name_de": "{$competitor2} wird beide Hälften gewinnen",
        "name_nl": "{$competitor2} wint beide helften",
        "name_pl": "{$competitor2} wygra obie połowy",
        "name_ro": "{$competitor2} să câștige ambele reprize",
        "name_ru": "{$competitor2} выиграет обе половины",
        "name_tr": "{$competitor2} her iki devreyi de kazanma",
        "name_se": "{$competitor2} vinna båda halvlekarna",
        "name_el": "{$competitor2} να κερδίσει και στα 2 ημίχρονα"
    },
    "id_1098": {
        "name_en": "{%competitor} total overtakings",
        "name_aa": "{!competitor} total overtakings",
        "name_de": "{!competitor} total overtakings",
        "name_nl": "Aantal inhaalmanoeuvres {!competitor}",
        "name_pl": "{!competitor} total overtakings",
        "name_ro": "{!competitor} total overtakings",
        "name_ru": "{!competitor} тотал обгонов",
        "name_tr": "{!competitor} total overtakings",
        "name_se": "{!competitor} total overtakings",
        "name_el": "{!competitor} σύνολο προσπεράσεις"
    },
    "id_280": {
        "name_en": "Innings 1 to 5th top - handicap",
        "name_aa": "ألدور 1إلى5 أعلى -هانديكاب",
        "name_de": "Innings 1 bis Anfang 5tes - Handicap",
        "name_nl": "Eerste helft (top) Inning 1 tot 5 - handicap",
        "name_pl": "Innings 1 do początku 5. - handicap",
        "name_ro": "Innings de la 1 la 5 top - handicap",
        "name_ru": "Иннинги с 1 до 5го верха - гандикап",
        "name_tr": "Devreler 1den 5in sonuna - handikap",
        "name_se": "Innings 1 till 5th topp - handikapp",
        "name_el": "Innings 1 σε 5 top - Χάντικαπ"
    },
    "id_298": {
        "name_en": "Point range",
        "name_aa": "نطاق النقطة",
        "name_de": "Punktebereich",
        "name_nl": "Punten range",
        "name_pl": "Zakres liczby punktów",
        "name_ro": "Interval puncte",
        "name_ru": "Диапазон очков",
        "name_tr": "Toplam sayı aralığı",
        "name_se": "Poängintervall",
        "name_el": "Εύρος πόντων"
    },
    "id_167": {
        "name_en": "{$competitor1} total corners",
        "name_aa": "{$competitor1} مجموع الزوايا",
        "name_de": "{$competitor1} Gesamtanzahl Ecken",
        "name_nl": "Totaal aantal hoekschoppen {$competitor1}",
        "name_pl": "{$competitor1} Suma rzutów rożnych",
        "name_ro": "Total cornere {$competitor1}",
        "name_ru": "{$competitor1} тотал угловых",
        "name_tr": "{$competitor1} toplam kornerler",
        "name_se": "{$competitor1} totalt antal hörnor",
        "name_el": "{$competitor1} σύνολο κόρνερ"
    },
    "id_916": {
        "name_en": "{%player} total passing touchdowns (incl. overtime)",
        "name_aa": "{!player} total passing touchdowns (incl. overtime)",
        "name_de": "{!player} Passing Touchdowns Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal passing touchdowns",
        "name_pl": "{!player} total passing touchdowns (incl. overtime)",
        "name_ro": "{!player} total passing touchdowns (incl. overtime)",
        "name_ru": "{!player} тотал тачдаунов после паса",
        "name_tr": "{!player} total passing touchdowns (incl. overtime)",
        "name_se": "{!player} total passing touchdowns (incl. overtime)",
        "name_el": "{!player} total passing touchdowns"
    },
    "id_147": {
        "name_en": "{$competitor1} sending off",
        "name_aa": "{$competitor1} طرد",
        "name_de": "{$competitor1} Platzverweise",
        "name_nl": "Uitsluitingen {$competitor1}",
        "name_pl": "{$competitor1} czerwona kartka",
        "name_ro": "Eliminare {$competitor1}",
        "name_ru": "Удаление у {$competitor1}",
        "name_tr": "{$competitor1} takımında oyundan atılan olur",
        "name_se": "{$competitor1} utvisning",
        "name_el": "{$competitor1} να αποβληθεί παίχτης"
    },
    "id_665": {
        "name_en": "{!daynr} day session {sessionnr} - total",
        "name_aa": "{!daynr} جلسة يوم {sessionnr} - الإجمالي",
        "name_de": "{!daynr} Tagessession {sessionnr} - Total",
        "name_nl": "{daynr} day session {sessionnr} - totaal",
        "name_pl": "{!daynr} dzień sesja {sessionnr} - suma",
        "name_ro": "Ziua {!daynr} sesiunea {sessionnr} - total",
        "name_ru": "{!daynr} день {sessionnr} сессия - тотал",
        "name_tr": "{!daynr}. gün {sessionnr}. session - topla",
        "name_se": "{!daynr} dags session {sessionnr} - totalt",
        "name_el": "{!daynr} day session {sessionnr} - total"
    },
    "id_348": {
        "name_en": "{!inningnr} innings - run range in the highest scoring over",
        "name_aa": "{!inningnr} أدوار - مجموعة التشغيل في أعلى الدرجات على",
        "name_de": "{!inningnr} Inning - Laufbereich im  Over mit den meisten Punkten",
        "name_nl": "{inningnr} innings - run range in de over met hoogste score",
        "name_pl": "{!inningnr} innings - zakres liczby runs w najwyżej punktowanym over",
        "name_ro": "{!inningnr} innings - interval run-uri în over-ul cu cele mai multe înscrieri",
        "name_ru": "{!inningnr} иннинг - дистанция пробежек в самом результативном овере",
        "name_tr": "{!inningnr}. devre - en yüksek skorda koşu aralığı",
        "name_se": "{!inningnr} innings - run-intervall",
        "name_el": "{!inningnr} innings - run range με το υψηλότερο over"
    },
    "id_849": {
        "name_en": "Any team winning margin (incl. overtime)",
        "name_aa": "أي هامش الفوز للفريق (بما في ذلك العمل الإضافي)",
        "name_de": "Jedes Team Vorsprung (inkl. Verlängerung)",
        "name_nl": "Winstmarge (verlenging inbegrepen)",
        "name_pl": "Którakolwiek drużyna Margines zwycięstwa (włączając dogrywkę)",
        "name_ro": "Avantajul câștigătoarei oricare echipă (incl. prelungiri)",
        "name_ru": "Преимущество одной из команд (включая овертайм)",
        "name_tr": "Herhangi bir takım kazanç marjı (uzatma dahil)",
        "name_se": "Något lag vinstmarginal (inkl. övertid)",
        "name_el": "Οποιαδήποτε ομάδα περιθώριο νίκης  (παράταση συμπεριλαμβάνεται)"
    },
    "id_33": {
        "name_en": "{$competitor1} win to nil",
        "name_aa": "{$competitor1} الفوز لللا شيء",
        "name_de": "{$competitor1} gewinnt zu null",
        "name_nl": "{$competitor1} wint zonder tegengoal",
        "name_pl": "{$competitor1} wygra do zera",
        "name_ro": "{$competitor1} victorie la 0",
        "name_ru": "{$competitor1} выиграет к нолю",
        "name_tr": "{$competitor1} gol yemeden kazanır",
        "name_se": "{$competitor1} vinner och håller nollan",
        "name_el": "{$competitor1} να κερδίσει με μηδέν παθητικό"
    },
    "id_511": {
        "name_en": "{!framenr} frame - {$competitor2} break 50+",
        "name_aa": "إطار {!framenr} - {$competitor2} استراحة 50+",
        "name_de": "{!framenr} Frame - {$competitor2} Break 50+",
        "name_nl": "{framenr} frame - {$competitor2} break 50+",
        "name_pl": "{!framenr} frejm - {$competitor2} brejk 50+",
        "name_ro": "Frame-ul {!framenr} - {$competitor2} break 50+",
        "name_ru": "{!framenr} фрейм - {$competitor2} брейк 50+",
        "name_tr": "{!framenr}. frame -  {$competitor2} 50+ break",
        "name_se": "{!framenr} frame - {$competitor2} break 50+",
        "name_el": "{!framenr} frame -  {$competitor2} μπρέικ 50+"
    },
    "id_1043": {
        "name_en": "{!inningnr} inning - total hits",
        "name_aa": "{!inningnr} inning - total hits",
        "name_de": "{!inningnr} inning - total hits",
        "name_nl": "{inningnr} inning - totaal aantal slagen",
        "name_pl": "{!inningnr} inning - total hits",
        "name_ro": "{!inningnr} inning - total hits",
        "name_ru": "{!inningnr} иннингов - тотал хитс",
        "name_tr": "{!inningnr} inning - total hits",
        "name_se": "{!inningnr} inning - totalt antal hits",
        "name_el": "{!inningnr} inning - total hits"
    },
    "id_1142": {
        "name_en": "{!inningnr} innings over {overnr} - {%player1} & {%player2} over {total}",
        "name_aa": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_de": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_nl": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_pl": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_ro": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_ru": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_tr": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_se": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}",
        "name_el": "{!inningnr} innings over {overnr} - {!player1} & {!player2} over {total}"
    },
    "id_529": {
        "name_en": "{!periodnr} period - double chance",
        "name_aa": "{!periodnr} الفترة - فرصة مزدوجة",
        "name_de": "{!periodnr} Periode - Doppelte Chance",
        "name_nl": "{periodnr} periode - dubbele kans",
        "name_pl": "{!periodnr} część gry - podwójna szansa",
        "name_ro": "Repriza {!periodnr} - șansă dublă",
        "name_ru": "{!periodnr} период - двойной шанс",
        "name_tr": "{!periodnr}. periyod - çifte şans",
        "name_se": "{!periodnr} period - dubbelchans",
        "name_el": "{!periodnr} περίοδος - διπλή ευκαιρία"
    },
    "id_186": {
        "name_en": "Winner",
        "name_aa": "الفائز",
        "name_de": "Gewinner",
        "name_nl": "Winnaar",
        "name_pl": "Zwycięzca",
        "name_ro": "Câștigătoare",
        "name_ru": "Победитель",
        "name_tr": "Kazanan",
        "name_se": "Vinnare",
        "name_el": "Νικητής"
    },
    "id_275": {
        "name_en": "Innings 1 to 5 - handicap",
        "name_aa": "ألدور 1إلى5 - هانديكاب",
        "name_de": "Innings 1 bis 5 - Handicap",
        "name_nl": "Inning 1 tot 5 - handicap",
        "name_pl": "Innings 1 do 5 - handicap",
        "name_ro": "Innings de la 1 la 5 - handicap",
        "name_ru": "Иннинги с 1 до 5 - гандикап",
        "name_tr": "Devreler 1 den 5 e - handikap",
        "name_se": "Innings 1 till 5 - handikapp",
        "name_el": "Innings 1 σε 5 -  Χάντικαπ"
    },
    "id_1081": {
        "name_en": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_aa": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_de": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_nl": "{inningnr} inning - {$competitor1} maakt een double of triple play",
        "name_pl": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_ro": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_ru": "{!inningnr} иннинг - {$competitor1} запишет дабл или трипл матч",
        "name_tr": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_se": "{!inningnr} inning - {$competitor1} to record a double or triple play",
        "name_el": "{!inningnr} inning - {$competitor1} to record a double or triple play"
    },
    "id_1042": {
        "name_en": "{$competitor2} total hits (incl. extra innings)",
        "name_aa": "{$competitor2} total hits (incl. extra innings)",
        "name_de": "{$competitor2} Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "{$competitor2} totaal aantal slagen (incl. extra innings)",
        "name_pl": "{$competitor2} total hits (incl. extra innings)",
        "name_ro": "{$competitor2} total hits (incl. extra innings)",
        "name_ru": "{$competitor2} тотал хитов (включая экстра иннинги)",
        "name_tr": "{$competitor2} total hits (incl. extra innings)",
        "name_se": "{$competitor2} totalt antal hits (inkl. extra innings)",
        "name_el": "{$competitor2} total hits (incl. extra innings)"
    },
    "id_325": {
        "name_en": "{!setnr} set end {endnr} - odd/even",
        "name_aa": "{!setnr}  وضع نهاية {endnr} - فردي / زوجي",
        "name_de": "{!setnr} Satzende {endnr} - Gerade/ungerade",
        "name_nl": "{setnr} set end {endnr} - even/oneven",
        "name_pl": "{!setnr} set koniec {endnr} - parzyste/nieparzyste",
        "name_ro": "Set-ul {!setnr} end-ul {endnr} - impar/par",
        "name_ru": "{!setnr} сет {endnr} конец - чет/нечет",
        "name_tr": "{!setnr}. set {endnr}. end - tek/çift",
        "name_se": "{!setnr} set slut {endnr} - udda/jämnt",
        "name_el": "{!setnr} set end {endnr} - μονά/ζυγά"
    },
    "id_172": {
        "name_en": "Odd/even corners",
        "name_aa": "زوايا فردي / زوجي",
        "name_de": "Gerade/ungerade Ecken",
        "name_nl": "Even/oneven aantal hoekschoppen",
        "name_pl": "Parzyste/Nieparzyste rzuty rożne",
        "name_ro": "Număr impar/par de cornere",
        "name_ru": "Чет/Нечет угловые",
        "name_tr": "tek/çift kornerler",
        "name_se": "Udda/jämnt hörnor",
        "name_el": "Μονά/Ζυγά κόρνερ"
    },
    "id_139": {
        "name_en": "Total bookings",
        "name_aa": "إجمالي الحجوزات",
        "name_de": "Gesamtanzahl Karten",
        "name_nl": "Totaal aantal kaarten",
        "name_pl": "Suma kartek",
        "name_ro": "Total cartonașe",
        "name_ru": "Тотал карточек",
        "name_tr": "Toplam kart sayısı",
        "name_se": "Totalt antal kort",
        "name_el": "Σύνολο κάρτες"
    },
    "id_908": {
        "name_en": "Free text multiwinner market",
        "name_aa": "Free text multiwinner market",
        "name_de": "Free text multiwinner market",
        "name_nl": "Free text multiwinner market",
        "name_pl": "Free text multiwinner market",
        "name_ro": "Free text multiwinner market",
        "name_ru": "Free text multiwinner market",
        "name_tr": "Free text multiwinner market",
        "name_se": "Free text multiwinner market",
        "name_el": "Free text multiwinner market"
    },
    "id_843": {
        "name_en": "{!inningnr} innings - {%player} total spread",
        "name_aa": "{!inningnr} innings - {!player} total spread",
        "name_de": "{!inningnr} innings - {!player} total spread",
        "name_nl": "{!inningnr} innings - {!player} total spread",
        "name_pl": "{!inningnr} innings - {!player} total spread",
        "name_ro": "{!inningnr} innings - {!player} total interval",
        "name_ru": "{!inningnr} иннингов - {!player} тотал спред",
        "name_tr": "{!inningnr} innings - {!player} total spread",
        "name_se": "{!inningnr} innings - {!player} total spread",
        "name_el": "{!inningnr} innings - {!player} total spread"
    },
    "id_1075": {
        "name_en": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_aa": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_de": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_nl": "{inningnr} inning - exact aantal homeruns {$competitor2}",
        "name_pl": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_ro": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_ru": "{!inningnr} иннинг - {$competitor2} точные хоум-раны",
        "name_tr": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_se": "{!inningnr} inning - {$competitor2} exact home runs",
        "name_el": "{!inningnr} inning - {$competitor2} ακριβής αριθμός home runs"
    },
    "id_451": {
        "name_en": "{!periodnr} period - {$competitor2} exact goals",
        "name_aa": "فترة {!periodnr} - {$competitor2} الأهداف المحددة",
        "name_de": "{!periodnr} Periode - {$competitor2} Genaue Anzahl Tore",
        "name_nl": "{periodnr} periode - exact aantal doelpunten {$competitor2}",
        "name_pl": "{!periodnr} część gry - {$competitor2} dokładna liczba goli",
        "name_ro": "Repriza {!periodnr} - goluri exact {$competitor2}",
        "name_ru": "{!periodnr} период - {$competitor2} точное число голов",
        "name_tr": "{!periodnr}. periyot - {$competitor2} doğru skor",
        "name_se": "{!periodnr} period - {$competitor2} exakt antal mål",
        "name_el": "{!periodnr} περίοδος - {$competitor2} ακριβής αριθμός γκολ"
    },
    "id_1626": {
        "name_en": "{$competitor2} 2 goals in a row",
        "name_aa": "{$competitor2} 2 goals in a row",
        "name_de": "{$competitor2} 2 goals in a row",
        "name_nl": "{$competitor2} 2 goals in a row",
        "name_pl": "{$competitor2} 2 goals in a row",
        "name_ro": "{$competitor2} 2 goals in a row",
        "name_ru": "{$competitor2} 2 goals in a row",
        "name_tr": "{$competitor2} 2 goals in a row",
        "name_se": "{$competitor2} 2 goals in a row",
        "name_el": "{$competitor2} 2 goals in a row"
    },
    "id_462": {
        "name_en": "{!periodnr} period - odd/even",
        "name_aa": "فترة {!periodnr}- فردي / زوجي",
        "name_de": "{!periodnr} Periode - Gerade/ungerade",
        "name_nl": "{periodnr} periode - even/oneven",
        "name_pl": "{!periodnr} część gry - parzyste/nieparzyste",
        "name_ro": "Repriza {!periodnr} - impar/par",
        "name_ru": "{!periodnr} период - чет/нечет",
        "name_tr": "{!periodnr}. periyot - tek/çift",
        "name_se": "{!periodnr} period - udda/jämnt",
        "name_el": "{!periodnr} περίοδος - μονά/ζυγά"
    },
    "id_18": {
        "name_en": "Total",
        "name_aa": "مجموع",
        "name_de": "Total",
        "name_nl": "Totaal",
        "name_pl": "Suma",
        "name_ro": "Total",
        "name_ru": "Тотал",
        "name_tr": "Toplam gol",
        "name_se": "Totalt",
        "name_el": "Σύνολο Γκολ"
    },
    "id_883": {
        "name_en": "1 minute - total from {from} to {to}",
        "name_aa": "دقيقة واحدة - الإجمالي من {from} إلى {to}",
        "name_de": "1 Minute - insgesamt von {from} bis {to}",
        "name_nl": "1 minuut - totaal van {from} tot {to}",
        "name_pl": "1 minute - total from {from} to {to}",
        "name_ro": "1 minut - total de la {from} la {to}",
        "name_ru": "1 минута - итого с {from} по {to}",
        "name_tr": "1 dakika - {from}'dan {to}'a kadar toplam gol",
        "name_se": "1 minuter - totala från {from} till {to}",
        "name_el": "1 λεπτό - σύνολο από {from} μέχρι {to}"
    },
    "id_435": {
        "name_en": "{$competitor1} to win all periods",
        "name_aa": "{$competitor1} للفوز بجميع الفترات",
        "name_de": "{$competitor1} wird alle Perioden gewinnen",
        "name_nl": "{$competitor1} wint alle periodes",
        "name_pl": "{$competitor1} wygra wszystkie części gry",
        "name_ro": "{$competitor1} să câștige toate reprizele",
        "name_ru": "{$competitor1} выиграть все периоды",
        "name_tr": "{$competitor1} tüm periyotları kazanır",
        "name_se": "{$competitor1} att vinna alla perioder",
        "name_el": "{$competitor1} να κερδίσει όλες τις περιόδους"
    },
    "id_625": {
        "name_en": "{!mapnr} map - player with most deaths (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - لاعب مع معظم الوفيات (بما في ذلك العمل الإضافي)",
        "name_de": "{!mapnr} Map - Spieler mit den meisten Deaths (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - speler met de meeste deaths (verlenging inbegrepen)",
        "name_pl": "{!mapnr} mapa - zawodnik z największą liczbą śmierci (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - jucătorul cu cele mai multe deaths (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - игрок с наибольшим числом смертей (включая овертайм)",
        "name_tr": "{!mapnr}. harita - en çok death olan oyuncu (uzatmalar dahil)",
        "name_se": "{!mapnr} karta - spelare med flest deaths (inkl. övertid)",
        "name_el": "{!mapnr} map - παίχτης με περισσότερους θανάτους παράταση συμπεριλαμβάνεται"
    },
    "id_866": {
        "name_en": "Win",
        "name_aa": "Win",
        "name_de": "Win",
        "name_nl": "Win",
        "name_pl": "Win",
        "name_ro": "Victorie",
        "name_ru": "Побед",
        "name_tr": "Win",
        "name_se": "Vinnare",
        "name_el": "Νίκη"
    },
    "id_756": {
        "name_en": "{!quarternr} quarter - {$competitor1} total",
        "name_aa": "الربع {!quarternr}- {$competitor1} مجموعه",
        "name_de": "{!quarternr} Viertel - {$competitor1} Total",
        "name_nl": "{quarternr} kwart - {$competitor1} totaal",
        "name_pl": "{!quarternr} kwarta - {$competitor1} suma",
        "name_ro": "Sfertul {!quarternr} - {$competitor1} total",
        "name_ru": "{!quarternr} четверть - {$competitor1} тотал",
        "name_tr": "{!quarternr} çeyrek - {$competitor1} toplam",
        "name_se": "{!quarternr} quarter - {$competitor1} totalt",
        "name_el": "{!quarternr} περιόδος - {$competitor1} σύνολο"
    },
    "id_1028": {
        "name_en": "Holes {from} to {to} - 2 ball (1x2)",
        "name_aa": "Holes {from} to {to} - 2 ball (1x2)",
        "name_de": "Holes {from} to {to} - 2 ball (1x2)",
        "name_nl": "Holes {from} tot {to} - 2 ball (1x2)",
        "name_pl": "Holes {from} to {to} - 2 ball (1x2)",
        "name_ro": "Holes {from} to {to} - 2 ball (1x2)",
        "name_ru": "Holes {from} to {to} - 2 ball (1x2)",
        "name_tr": "Holes {from} to {to} - 2 ball (1x2)",
        "name_se": "Holes {from} to {to} - 2 ball (1x2)",
        "name_el": "Holes {from} to {to} - 2 ball (1x2)"
    },
    "id_718": {
        "name_en": "{!inningnr} innings - {!dismissalnr} dismissal method (extended)",
        "name_aa": "{!inningnr} أدوار - {!dismissalnr} طريقة الفصل (ممتدة)",
        "name_de": "{!inningnr} Inning - {!dismissalnr} Dismissal Methode (erweitert)",
        "name_nl": "{inningnr} innings - {dismissalnr} dismissal methode (uitgebreid)",
        "name_pl": "{!inningnr} innings - {!dismissalnr} metoda wyeliminowania (rozszerzony)",
        "name_ro": "{!inningnr} innings - metoda demiterii {!dismissalnr} (extins)",
        "name_ru": "{!inningnr} иннингов - {!dismissalnr} методов выбиваний (длительный)",
        "name_tr": "{!inningnr}. devre - {!dismissalnr}. çıkarılma metodu (uzatılmış)",
        "name_se": "{!inningnr} innings - {!dismissalnr} avvisande metod (förlängd)",
        "name_el": "{!inningnr} innings - {!dismissalnr} dismissal method (extended)"
    },
    "id_314": {
        "name_en": "Total sets",
        "name_aa": "مجموع المجموعات",
        "name_de": "Gesamtanzahl Sätze",
        "name_nl": "Totaal aantal sets",
        "name_pl": "Suma setów",
        "name_ro": "Total set-uri",
        "name_ru": "Тотал сетов",
        "name_tr": "Toplam set",
        "name_se": "Totalt antal sets",
        "name_el": "Σύνολο σετ"
    },
    "id_505": {
        "name_en": "{!framenr} frame - highest break",
        "name_aa": "إطار {!framenr} - أعلى فاصل",
        "name_de": "{!framenr} Frame - Höchstes Break",
        "name_nl": "{framenr} frame - hoogste break",
        "name_pl": "{!framenr} frejm - najwyższy brejk",
        "name_ro": "Frame-ul {!framenr} - cel mai mare break",
        "name_ru": "{!framenr} фрейм - наивысший брейк",
        "name_tr": "{!framenr}. frame - en yüksek break",
        "name_se": "{!framenr} frame - högsta break",
        "name_el": "{!framenr} frame - μεγαλύτερο μπρέικ"
    },
    "id_251": {
        "name_en": "Winner (incl. extra innings)",
        "name_aa": "الفائز (بما في ذلك أدوار إضافية)",
        "name_de": "Gewinner (inkl. Extra Innings)",
        "name_nl": "Winnaar (extra innings inbegrepen)",
        "name_pl": "Zwycięzca (włącznie z extra innings)",
        "name_ro": "Câștigător (incl. inning-uri extra)",
        "name_ru": "Победитель (включая дополнительные иннинги)",
        "name_tr": "Kazanan (uzatmalar dahil)",
        "name_se": "Vinnare (inkl. extra innings)",
        "name_el": "Νικητής (παράταση συμπ.)"
    },
    "id_535": {
        "name_en": "Short term free text market",
        "name_aa": "Short term free text market",
        "name_de": "Short term free text market",
        "name_nl": "Short term free text market",
        "name_pl": "Short term free text market",
        "name_ro": "Short term free text market",
        "name_ru": "Short term free text market",
        "name_tr": "Short term free text market",
        "name_se": "Short term free text market",
        "name_el": "Short term free text market"
    },
    "id_50": {
        "name_en": "{$competitor1} to win either half",
        "name_aa": "{$competitor1} للفوز إما الشوط",
        "name_de": "{$competitor1}Sieger in einer der Halbzeiten",
        "name_nl": "{$competitor1} wint minstens een helft",
        "name_pl": "{$competitor1} wygra jakąkolwiek połowę",
        "name_ro": "{$competitor1} să câștige oricare repriză",
        "name_ru": "{$competitor1} выиграет любой тайм",
        "name_tr": "{$competitor1} en az bir yarıyı kazanır",
        "name_se": "{$competitor1} vinna minst en av halvlekarna",
        "name_el": "{$competitor1} να κερδίσει ένα ημίχρονο"
    },
    "id_888": {
        "name_en": "Anytime goalscorer & 1x2",
        "name_aa": "في أي وقت هداف و1X2",
        "name_de": "Torschütze(n) & 1x2",
        "name_nl": "Doelpuntenmaker & 1x2",
        "name_pl": "Strzelec gola & 1x2",
        "name_ro": "Marcator oricând & 1x2",
        "name_ru": "Любая минута бомбардир и 1х2",
        "name_tr": "Her zaman golü atan & 1x2",
        "name_se": "När som helst målgörare & 1x2",
        "name_el": "Να σκοράρει οποτεδήποτε (90 λεπτά)  και 1Χ2"
    },
    "id_875": {
        "name_en": "{!inningnr} innings - {$competitor1} total at {!dismissalnr} dismissal",
        "name_aa": "أدوار {!inningnr} - {$competitor1} مجموعه في فصل {!dismissalnr}",
        "name_de": "{!inningnr} innings - {$competitor1} total at {!dismissalnr} dismissal",
        "name_nl": "{!inningnr} innings - Totaal {$competitor1} bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor1} suma przy {!dismissalnr} wyeliminowaniu",
        "name_ro": "Innings {!inningnr} - {$competitor1} total la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал на {!dismissalnr} выбываний",
        "name_tr": "{!inningnr} devre - {$competitor1} toplamda {!dismissalnr} dismissal",
        "name_se": "{!inningnr} innings - {$competitor1} totala vid {!dismissalnr} avvisning",
        "name_el": "{!inningnr} innings - {$competitor1} total at {!dismissalnr} dismissal"
    },
    "id_833": {
        "name_en": "Standard bet (incl. bonus ball in same drum)",
        "name_aa": "الرهان القياسي (بما في ذلك الكرة المكافأة في نفس الأسطوانة)",
        "name_de": "Standard bet (incl. bonus ball in same drum)",
        "name_nl": "Standaard weddenschap (reservebal inbegrepen in dezelfde koker)",
        "name_pl": "Standard bet (incl. bonus ball in same drum)",
        "name_ro": "Pariu standard (incl. bilă bonus în aceeași urnă)",
        "name_ru": "Обычная ставка (вкл. бонус бол в эту же лунку)",
        "name_tr": "Standart bahis (bonus ball'u aynı drum'a dahil et)",
        "name_se": "Standardinsats (inkl. bonusboll i samma trumma)",
        "name_el": "Standard bet (συμπερ. bonus ball in same drum)"
    },
    "id_713": {
        "name_en": "Most catches",
        "name_aa": "معظم المصيد",
        "name_de": "Meiste Paraden",
        "name_nl": "Meeste catches",
        "name_pl": "Najwięcej catches",
        "name_ro": "Cele mai multe rețineri",
        "name_ru": "Большинство поимок",
        "name_tr": "En çok yakalama",
        "name_se": "Flest fångster",
        "name_el": "Most keeper catches"
    },
    "id_431": {
        "name_en": "Correct score",
        "name_aa": "نتيجة صحيحة",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste score",
        "name_pl": "Dokładny wynik",
        "name_ro": "Scor corect",
        "name_ru": "Точный счет",
        "name_tr": "Maç sonucu",
        "name_se": "Rätt resultat",
        "name_el": "Ακριβές σκορ"
    },
    "id_554": {
        "name_en": "{!mapnr} map - kill draw no bet",
        "name_aa": "{!mapnr} خريطة - اقتل التعادل لا رهان",
        "name_de": "{!mapnr} Map - Kill unentschieden, keine Wette",
        "name_nl": "{mapnr} map - kill draw no bet",
        "name_pl": "{!mapnr} mapa - zabójstwa zakład bez remisu",
        "name_ro": "Harta {!mapnr} - egalitate kill-uri pariu nul",
        "name_ru": "{!mapnr} карта - убийство ничья ставки нет",
        "name_tr": "{!mapnr}. harita - kill beraberlik iade",
        "name_se": "{!mapnr} karta - kill insatsen tillbaka vid oavgjort",
        "name_el": "{!mapnr} map - kill Ισοπαλία όχι στοιχήμα"
    },
    "id_817": {
        "name_en": "{!inningnr} innings - {$competitor2} {!dismissalnr} dismissal method (extended)",
        "name_aa": "{!inningnr} أدوار - {$competitor2} {!dismissalnr} طريقة الفصل (ممتدة)",
        "name_de": "{!inningnr} innings - {$competitor2} {!dismissalnr} dismissal method (extended)",
        "name_nl": "{inningnr} innings - {$competitor2} {dismissalnr} dismissal methode (uitgebreid)",
        "name_pl": "{!inningnr} innings - {$competitor2} {!dismissalnr} metoda wyeliminowania (rozszerzony)",
        "name_ro": "Innings {!inningnr} - {$competitor2} metoda demiterii {!dismissalnr} (extins)",
        "name_ru": "{!inningnr} иннингов - {$competitor2} {!dismissalnr} методов выбиваний (расширенный)",
        "name_tr": "{!inningnr} devre - {$competitor2} - {!dismissalnr} çıkarılma method (uzatılmış)",
        "name_se": "{!inningnr} innings - {$competitor2} {!dismissalnr} uppsägnings metod (förlängd)",
        "name_el": "{!inningnr} innings - {$competitor2} {!dismissalnr} μέθοδος αποβολής"
    },
    "id_300": {
        "name_en": "Race to {pointnr} points",
        "name_aa": "سباق إلى {pointnr} نقاط",
        "name_de": "Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "Race naar {pointnr} punten",
        "name_pl": "Kto pierwszy do {pointnr} punktów",
        "name_ro": "Primul la {pointnr} puncte",
        "name_ru": "Гонка до {pointnr} очков",
        "name_tr": "{pointnr}. sayının çekişmesi",
        "name_se": "Först till {pointnr} poäng",
        "name_el": "Ποιος θα φτάσει 1ος στους {pointnr} πόντους"
    },
    "id_785": {
        "name_en": "Pitcher strikeouts (incl. extra innings)",
        "name_aa": "قذفة جرة",
        "name_de": "Pitcher Strikeouts (inkl. Extra Innings)",
        "name_nl": "Werper strikeouts",
        "name_pl": "Pitcher strikeouts (incl. extra innings)",
        "name_ro": "Strikeouts pitcher",
        "name_ru": "Питчер страйк-аут",
        "name_tr": "Atıcı strikeout yapar",
        "name_se": "Pitcher strikeouts",
        "name_el": "Pitcher strikeouts"
    },
    "id_1037": {
        "name_en": "Race to {goals} goals",
        "name_aa": "Race to {goals} goals",
        "name_de": "Race to {goals} goals",
        "name_nl": "Race naar {goals} goals",
        "name_pl": "Race to {goals} goals",
        "name_ro": "Race to {goals} goals",
        "name_ru": "Рэйс к {goals} голам",
        "name_tr": "Race to {goals} goals",
        "name_se": "Först till {goals} mål",
        "name_el": "Ποιος θα φτάσει 1ος τα  {goals} γκολ"
    },
    "id_773": {
        "name_en": "Player steals (incl. overtime)",
        "name_aa": "لاعب يسرق",
        "name_de": "Spieler Steals (inkl. Verlängerung)",
        "name_nl": "Speler steals",
        "name_pl": "Player steals (incl. overtime)",
        "name_ro": "Steal-uri jucător",
        "name_ru": "Игрок совершивший больше перехватов",
        "name_tr": "Oyuncu top çalmaları",
        "name_se": "Spelaren stjäl",
        "name_el": "Παίχτης κλεψίματα"
    },
    "id_378": {
        "name_en": "{!setnr} set leg {legnr} - point range on {!visitnr} visit",
        "name_aa": "{!setnr}مجموعة الساق {legnr} -زيارة",
        "name_de": "{!setnr} Satz Runde {legnr} - Punktebereich im {!visitnr} Visit",
        "name_nl": "{setnr} set leg {legnr} - score in de {visitnr} beurt",
        "name_pl": "{!setnr} set leg {legnr} - zakres liczby punktów przy {!visitnr} wizycie",
        "name_ro": "Set {!setnr} leg {legnr} - interval puncte în {!visitnr} vizite",
        "name_ru": "{!setnr} сет {legnr} этап - диапазон очков в {!visitnr} подходе",
        "name_tr": "{!setnr}. set {legnr}. ayak- {!visitnr}. visitte sayı aralığı",
        "name_se": "{!setnr} set legs {legnr} - poängintervall på {!visitnr} omgångar",
        "name_el": "{!setnr} set, {legnr} leg - Εύρος  πόντων σε {!visitnr} επίσκεψη"
    },
    "id_344": {
        "name_en": "{!inningnr} innings - {!dismissalnr} dismissal method (limited)",
        "name_aa": "أدوار {!inningnr} - {!dismissalnr}  طريقة إقالة (محدودة)",
        "name_de": "{!inningnr} Inning - {!dismissalnr} Dismissal Methode (begrenzt)",
        "name_nl": "{inningnr} innings - {dismissalnr} dismissal methode (gelimiteerd)",
        "name_pl": "{!inningnr} innings - {!dismissalnr} metoda wyeliminowania (ograniczona)",
        "name_ro": "{!inningnr} innings - metoda demiterii {!dismissalnr} (limitat)",
        "name_ru": "{!inningnr} иннингов - {!dismissalnr} методов выбивания (ограниченно)",
        "name_tr": "{!inningnr}. devre - {!dismissalnr}. oyundan çıkarılma methodu (sınırlı)",
        "name_se": "{!inningnr} innings - {!dismissalnr} avvisande metod (begränsat)",
        "name_el": "{!inningnr} innings - {!dismissalnr} dismissal method (limited)"
    },
    "id_754": {
        "name_en": "{!quarternr} quarter - which team wins the rest",
        "name_aa": "الربع {!quarternr}- الفريق الذي يفوز في بقية",
        "name_de": "{!quarternr} Viertel - Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "{quarternr} kwart - welk team wint het restant",
        "name_pl": "{!quarternr} kwarta - który zespół wygra resztę",
        "name_ro": "Sfertul {!quarternr} - care echipă câștigă restul",
        "name_ru": "{!quarternr} четверть - какая команда выиграет остаток матча",
        "name_tr": "{!quarternr} çeyrek - maçın kalanını hangi takım kazanır",
        "name_se": "{!quarternr} quarter - vilket lag vinner resten",
        "name_el": "{!quarternr} περιόδος - ποια ομάδα θα κερδίσει το υπόλοιπο"
    },
    "id_1106": {
        "name_en": "{%team} total overtakings",
        "name_aa": "{!team} total overtakings",
        "name_de": "{!team} total overtakings",
        "name_nl": "Aantal inhaalmanoeuvres {!team}",
        "name_pl": "{!team} total overtakings",
        "name_ro": "{!team} total overtakings",
        "name_ru": "{!team} тотал обгонов",
        "name_tr": "{!team} total overtakings",
        "name_se": "{!team} total overtakings",
        "name_el": "{!team} σύνολο προσπεράσεων"
    },
    "id_780": {
        "name_en": "Player tackles",
        "name_aa": "لاعب يعالج",
        "name_de": "Spieler Tackles",
        "name_nl": "Speler tackles",
        "name_pl": "Player tackles",
        "name_ro": "Placaje jucător",
        "name_ru": "Игрок совершивший больше остановок игрока",
        "name_tr": "Oyuncu top çalmaları",
        "name_se": "Spelartacklingar",
        "name_el": "Παίχτης tackles"
    },
    "id_728": {
        "name_en": "{!mapnr} map - total towers",
        "name_aa": "{!mapnr} خريطة - إجمالي الأبراج",
        "name_de": "{!mapnr} Map - Towers gesamt",
        "name_nl": "{mapnr} map - totaal aantal towers",
        "name_pl": "{!mapnr} mapa - suma wież",
        "name_ro": "Harta {!mapnr} - total towers",
        "name_ru": "{!mapnr} карта - тотал башен",
        "name_tr": "{!mapnr}. harita - toplam kule",
        "name_se": "{!mapnr} karta - totalt antal towers",
        "name_el": "{!mapnr} map - total towers"
    },
    "id_911": {
        "name_en": "Will the fight go the distance",
        "name_aa": "Will the fight go the distance",
        "name_de": "Will the fight go the distance",
        "name_nl": "Zal de wedstrijd volledig worden uitgevochten (alle rondes)?",
        "name_pl": "Will the fight go the distance",
        "name_ro": "Will the fight go the distance",
        "name_ru": "Будет ли бой продолжительным",
        "name_tr": "Will the fight go the distance",
        "name_se": "Går fighten tiden ut",
        "name_el": "Will the fight go the distance"
    },
    "id_191": {
        "name_en": "{$competitor2} total games",
        "name_aa": "{$competitor2} إجمالي الألعاب",
        "name_de": "{$competitor2} Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games {$competitor2}",
        "name_pl": "{$competitor2} Suma gemów",
        "name_ro": "Total game-uri {$competitor2}",
        "name_ru": "{$competitor2} всего игр",
        "name_tr": "{$competitor2} toplam oyun",
        "name_se": "{$competitor2} totalt antal games",
        "name_el": "{$competitor2}  σύνολο γκέιμ"
    },
    "id_174": {
        "name_en": "1st half - {!cornernr} corner",
        "name_aa": "النصف الأول - الزاوية {!cornernr}",
        "name_de": "1. Halbzeit - {!cornernr} Ecke",
        "name_nl": "Eerste helft - {cornernr} hoekschop",
        "name_pl": "1. Połowa - {!cornernr} Rzut rożny",
        "name_ro": "Repriza 1 - cornerul {!cornernr}",
        "name_ru": "1ая половина - {!cornernr} угловой",
        "name_tr": "İlk yarı - {!cornernr} korner",
        "name_se": "1:a halvlek - {!cornernr} hörnor",
        "name_el": "1ο ημίχρονο - {!cornernr} κόρνερ"
    },
    "id_791": {
        "name_en": "Player handballs",
        "name_aa": "لاعب كرة اليد",
        "name_de": "Spielerhandbälle",
        "name_nl": "Speler handballs",
        "name_pl": "Player handballs",
        "name_ro": "Pase cu mâna jucător",
        "name_ru": "Игрок мяч руками",
        "name_tr": "Player handballs",
        "name_se": "Spelare handbollar",
        "name_el": "Παίχτης handballs"
    },
    "id_694": {
        "name_en": "Which team wins the coin toss",
        "name_aa": "الفريق الذي يفوز في رمي العملة",
        "name_de": "Welches Team gewinnt den Münzwurf",
        "name_nl": "Welk team wint de toss",
        "name_pl": "Który zespół wygra rzut monetą",
        "name_ro": "Care echipă câștigă aruncarea banului",
        "name_ru": "Какая команда выиграет подбрасывание монеты",
        "name_tr": "Yazı-turayı hangi takım kazanır",
        "name_se": "Vilket lag vinner myntkastningen",
        "name_el": "Ποια ομάδα θα κερδίσει το στρίψιμο του νομίσματος"
    },
    "id_109": {
        "name_en": "5 minutes - {!goalnr} goal from {from} to {to}",
        "name_aa": "5 دقائق - {!goalnr} هدف من {from} إلى {to}",
        "name_de": "5 Minuten - {!goalnr} Tor von {from} bis {to}",
        "name_nl": "5 minuten - {goalnr} doelpunt van {from} tot {to}",
        "name_pl": "5 minut - {!goalnr} gol od {from} do {to}",
        "name_ro": "5 minute - golul {!goalnr} de la {from} la {to}",
        "name_ru": "5 минут - {!goalnr} гол от {from} до {to}",
        "name_tr": "5 dakika - {!goalnr} gol {from} den {to} a",
        "name_se": "5 minuter - {!goalnr} mål från {from} till {to}",
        "name_el": "5 λεπτά -  {!goalnr} γκολ από {from} μέχρι {to}"
    },
    "id_332": {
        "name_en": "{!mapnr} map - total rounds (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - مجموع جولات (بما في ذلك العمل الإضافي)",
        "name_de": "{!mapnr} Map - Anzahl Runden (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - totaal rounds (overtime inbegrepen)",
        "name_pl": "{!mapnr} mapa - suma rund (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - total runde (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - тотал раунда (включая овертайм)",
        "name_tr": "{mapnr}. harita - toplam raund ( uzatma süreleri dahil)",
        "name_se": "{!mapnr} karta - totalt rundor (inkl. övertid)",
        "name_el": "{!mapnr} map - σύνολο rounds (παράταση συμπ.)"
    },
    "id_782": {
        "name_en": "Batter home runs (incl. extra innings)",
        "name_aa": "يدير العجين المنزل",
        "name_de": "Schlagmann Home Runs (inkl. Extra Innings)",
        "name_nl": "Slagman home runs",
        "name_pl": "Batter home runs (incl. extra innings)",
        "name_ro": "Home run-uri batter",
        "name_ru": "Бэттер хоум раны",
        "name_tr": "Vurucu home koşuları",
        "name_se": "Slagman gör homerun",
        "name_el": "Batter hits"
    },
    "id_156": {
        "name_en": "1st half - {$competitor1} exact bookings",
        "name_aa": "النصف الأول - {$competitor1} الحجوزات الدقيقة",
        "name_de": "1. Halbzeit -  {$competitor1} Genaue Anzahl Karten",
        "name_nl": "Eerste helft - exact aantal kaarten {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} Dokładna liczba kartek",
        "name_ro": "Repriza 1 - cartonașe exact {$competitor1}",
        "name_ru": "1ая половина - {$competitor1} точное число карточек",
        "name_tr": "İlk yarı - {$competitor1} doğru kart sayısı",
        "name_se": "1:a halvlek - {$competitor1} exakta antal kort",
        "name_el": "1ο ημίχρονο - {$competitor1} ακριβής αριθμός καρτών"
    },
    "id_452": {
        "name_en": "{!periodnr} period - both teams to score",
        "name_aa": "فترة {!periodnr} - كلا الفريقين يسجل",
        "name_de": "{!periodnr} Periode - Beide Mannschaften treffen",
        "name_nl": "{periodnr} periode - beide teams scoren",
        "name_pl": "{!periodnr} część gry - oba zespoły strzelają",
        "name_ro": "Repriza {!periodnr} - ambele echipe să înscrie",
        "name_ru": "{!periodnr} период - обе команды забьют",
        "name_tr": "{!periodnr}. periyot - her iki takımda gol atar",
        "name_se": "{!periodnr} period - båda lagen gör mål",
        "name_el": "{!periodnr} περίοδος - να σκοράρουν και οι 2 ομάδες"
    },
    "id_988": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_de": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - {$competitor1} supremacy spread",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_ru": "{!inningnr} иннингов больше 0 до {overnr} - {$competitor1} supremacy спред",
        "name_tr": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} supremacy spread"
    },
    "id_377": {
        "name_en": "{!setnr} set leg {legnr} - highest scoring player on {!visitnr} visits",
        "name_aa": "{!setnr}مجموعة الساق {legnr} - أعلى لاعب سجل في مرة {visitnr!}",
        "name_de": "{!setnr} Satz Runde {legnr} - Spieler mit den meisten Punkten im {!visitnr} Visit",
        "name_nl": "{setnr} set leg {legnr} - speler met de hoogste score na {visitnr} beurten",
        "name_pl": "{!setnr} set leg {legnr} - zawodnik z największą liczbą punktów przy {!visitnr} wizycie",
        "name_ro": "Set {!setnr} leg {legnr} - jucătorul cu cele mai multe puncte în {!visitnr} vizite",
        "name_ru": "{!setnr} сет {legnr} этап - самый результативный игрок в {!visitnr} подходе",
        "name_tr": "{!setnr}. set {legnr}. ayak- {!visitnr}. visitte en yüksek skoru yapan oyuncu",
        "name_se": "{!setnr} set leg {legnr} - spelare med högst poäng på {!visitnr} omgångar",
        "name_el": "{!setnr} set, {legnr} leg - παίχτης με περισσότερους πόντους σε {!visitnr} επισκέψεις"
    },
    "id_494": {
        "name_en": "Total frames",
        "name_aa": "مجموع الإطارات",
        "name_de": "Total Frames",
        "name_nl": "Totaal aantal frames",
        "name_pl": "Suma frejmów",
        "name_ro": "Total frame-uri",
        "name_ru": "Тотал фреймов",
        "name_tr": "Toplam frameler",
        "name_se": "Totalt antal frames",
        "name_el": "Σύνολο frames"
    },
    "id_508": {
        "name_en": "{!framenr} frame - {$competitor2} break 100+",
        "name_aa": "إطار {!framenr} - {$competitor2} استراحة 100+",
        "name_de": "{!framenr} Frame - {$competitor2} Break 100+",
        "name_nl": "{framenr} frame - {$competitor2} break 100+",
        "name_pl": "{!framenr} frejm - {$competitor2} brejk 100+",
        "name_ro": "Frame-ul {!framenr} - {$competitor2} break 100+",
        "name_ru": "{!framenr} фрейм - {$competitor2} брейк 100+",
        "name_tr": "{!framenr}. frame -  {$competitor2} 100+ break",
        "name_se": "{!framenr} frame - {$competitor2} break 100+",
        "name_el": "{!framenr} frame - {$competitor2}  μπρέικ 100+"
    },
    "id_838": {
        "name_en": "{$competitor2}/{$competitor1} supremacy",
        "name_aa": "{$competitor2}/{$competitor1} supremacy",
        "name_de": "{$competitor2}/{$competitor1} supremacy",
        "name_nl": "{$competitor2}/{$competitor1} supremacy",
        "name_pl": "{$competitor2}/{$competitor1} supremacy",
        "name_ro": "{$competitor2}/{$competitor1} supremație",
        "name_ru": "{$competitor2}/{$competitor1} превосходство",
        "name_tr": "{$competitor2}/{$competitor1} supremacy",
        "name_se": "{$competitor2}/{$competitor1} supremacy",
        "name_el": "{$competitor2}/{$competitor1} supremacy"
    },
    "id_230": {
        "name_en": "Race to {pointnr} points (incl. overtime)",
        "name_aa": "سباق إلى {pointnr} نقاط (بما في ذلك الوقت الإضافي)",
        "name_de": "Wer erreicht zuerst {pointnr} Punkte (inkl. Verlängerung)?",
        "name_nl": "Race naar {pointnr} punten (verlenging inbegrepen)",
        "name_pl": "Kto pierwszy do {pointnr} punktów (włącznie z dogrywką)",
        "name_ro": "Primii la {pointnr} puncte (incl. prelungiri)",
        "name_ru": "Гонка до {pointnr} очков (включая овертайм)",
        "name_tr": "{pointnr}. sayı çekişmesi (uzatmalar dahil)",
        "name_se": "Först till {pointnr} poäng (inkl. övertid)",
        "name_el": "Ποιος θα φτάσει 1ος  στους {pointnr} πόντους (παράταση συμπ.)"
    },
    "id_1076": {
        "name_en": "{!inningnr} inning - total pitches",
        "name_aa": "{!inningnr} inning - total pitches",
        "name_de": "{!inningnr} inning - total pitches",
        "name_nl": "{inningnr} inning - totaal aantal worpen",
        "name_pl": "{!inningnr} inning - total pitches",
        "name_ro": "{!inningnr} inning - total pitches",
        "name_ru": "{!inningnr} иннинг - тотал поданных подач питчером",
        "name_tr": "{!inningnr} inning - total pitches",
        "name_se": "{!inningnr} inning - total pitches",
        "name_el": "{!inningnr} inning - total pitches"
    },
    "id_552": {
        "name_en": "1st half - multigoals",
        "name_aa": "الشوط الأول - أهداف متعددة",
        "name_de": "1. Halbzeit - Gesamtzahl der Tore im Spiel",
        "name_nl": "Eerste helft - multigoals",
        "name_pl": "1. Połowa - multi-gole",
        "name_ro": "Repriza 1 - multigoluri",
        "name_ru": "1ая половина - мултиголы",
        "name_tr": "İlk yarı - birden fazla gol",
        "name_se": "1:a halvlek - multimål",
        "name_el": "1ο ημίχρονο - Γκολ (πολλαπλών επιλογών)"
    },
    "id_574": {
        "name_en": "10 minutes - corner handicap from {from} to {to}",
        "name_aa": "10 دقائق - هانديكاب في الزاوية من {from} إلى {to}",
        "name_de": "10 Minuten - Ecken Handicap von {from} bis {to}",
        "name_nl": "10 minutes - hoekschop handicap van {from} tot {to}",
        "name_pl": "10 minut - rzut rożny handicap od {from} do {to}",
        "name_ro": "10 minute - handicap cornere de la {from} la {to}",
        "name_ru": "10 минут - угловой гандикап с {from} до {to}",
        "name_tr": "10 dakika - korner handikap {from} dan {to} a",
        "name_se": "10 minuter - hörnor handikapp från {from} till {to}",
        "name_el": "10 λεπτά - κόρνερ χάντικαπ από {from} μέχρι {to}"
    },
    "id_609": {
        "name_en": "Team with highest scoring quarter",
        "name_aa": "فريق مع أعلى الربع التهديف",
        "name_de": "Welches Team gewinnt das Viertel mit den meisten Punkten",
        "name_nl": "Team met het kwart met de hoogste score",
        "name_pl": "Zespół z nawyżej punktującą kwartą",
        "name_ro": "Echipa care înscrie cel mai mult într-un sfert",
        "name_ru": "Команда с самой результативной четвертью",
        "name_tr": "Çeyrekte en çok sayı yapan takım",
        "name_se": "Lag med högst poäng under quarter",
        "name_el": "Ομάδα με περισσότερους πόντους σε περίοδο"
    },
    "id_393": {
        "name_en": "{!setnr} set leg {legnr} - {$competitor2} to score a 180",
        "name_aa": "{!setnr} مجموعة الساق {legnr} - {$competitor2} ليسجل 180",
        "name_de": "{!setnr} Satz Runde {legnr} - {$competitor2} erzielt eine 180",
        "name_nl": "{setnr} set leg {legnr} - {$competitor2} gooit een 180",
        "name_pl": "{!setnr} set leg {legnr} - {$competitor2} osiągnie 180",
        "name_ro": "Set {!setnr} leg {legnr} - {$competitor2} să înscrie un 180",
        "name_ru": "{!setnr} сет {legnr} этап - {$competitor2} набрать 180",
        "name_tr": "{!setnr}. set {legnr}. ayak - {$competitor2} 180 yapar",
        "name_se": "{!setnr} set legs {legnr} - {$competitor2} att kasta en 180:a",
        "name_el": "{!setnr} set, {legnr} leg - {$competitor2}να σκοράρει 180άρι"
    },
    "id_16": {
        "name_en": "Handicap",
        "name_aa": "هانديكاب",
        "name_de": "Handicap",
        "name_nl": "Handicap",
        "name_pl": "Handicap",
        "name_ro": "Handicap",
        "name_ru": "Гандикап",
        "name_tr": "Handikap",
        "name_se": "Handikapp",
        "name_el": "Χάντικαπ"
    },
    "id_1031": {
        "name_en": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_aa": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_de": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_nl": "Holes {from} tot {to} - 2 ball (1x2) meeste pars",
        "name_pl": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_ro": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_ru": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_tr": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_se": "Holes {from} to {to} - 2 ball (1x2) most pars",
        "name_el": "Holes {from} to {to} - 2 ball (1x2) most pars"
    },
    "id_633": {
        "name_en": "{!mapnr} map round {roundnr} - player with {!killnr} kill",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - لاعب مع {!killnr} يقتل",
        "name_de": "{!mapnr} Map Runde {roundnr} - Spieler mit {!killnr} Kill",
        "name_nl": "{mapnr} map round {roundnr} - speler met de {killnr} kill",
        "name_pl": "{!mapnr} mapa runda {roundnr} - zawodnik z {!killnr} zabójstwem",
        "name_ro": "Harta {!mapnr} runda {roundnr} - jucătorul cu kill-ul {!killnr}",
        "name_ru": "{!mapnr} карта {roundnr} раунд - игрок с {!killnr} убийствами",
        "name_tr": "{!mapnr}. map {roundnr}. raund - {!killnr}. kill yapan oyuncu",
        "name_se": "{!mapnr} kartrunda {roundnr} - spelare med {!killnr} kill",
        "name_el": "{!mapnr} map, γύρος {roundnr} - παίχτης με  {!killnr} kill"
    },
    "id_479": {
        "name_en": "{$competitor1} total tries",
        "name_aa": "{$competitor1} إجمالي المحاولات",
        "name_de": "{$competitor1} Total Versuche",
        "name_nl": "Totaal aantal tries {$competitor1}",
        "name_pl": "{$competitor1} suma przyłożeń",
        "name_ro": "Total încercări {$competitor1}",
        "name_ru": "{$competitor1} тотал попыток",
        "name_tr": "{$competitor1} toplam try",
        "name_se": "{$competitor1} totalt antal försök",
        "name_el": "{$competitor1}  Σύνολο tries"
    },
    "id_327": {
        "name_en": "Map handicap",
        "name_aa": "خريطة هانديكاب",
        "name_de": "Map Handicap",
        "name_nl": "Map handicap",
        "name_pl": "Mapa handicap",
        "name_ro": "Handicap hartă",
        "name_ru": "Гандикап на карту",
        "name_tr": "Harita handikapı",
        "name_se": "Karta handikapp",
        "name_el": "Map  χάντικαπ"
    },
    "id_40": {
        "name_en": "Anytime goalscorer",
        "name_aa": "في أي وقت هداف",
        "name_de": "Torschütze(n)",
        "name_nl": "Doelpuntenmaker",
        "name_pl": "Strzelec gola",
        "name_ro": "Să înscrie oricând",
        "name_ru": "Автор гола - за все время",
        "name_tr": "Gol atar",
        "name_se": "När som helst målgörare",
        "name_el": "Να σκοράρει οποτεδήποτε (90 λεπτά)"
    },
    "id_576": {
        "name_en": "10 minutes - {$competitor1} total corners from {from} to {to}",
        "name_aa": "10 دقائق - {$competitor1} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "10 Minuten - {$competitor1} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "10 minutes - totaal aantal hoekschoppen {$competitor1} van {from} tot {to}",
        "name_pl": "10 minut - {$competitor1} suma rzutów rożnych od {from} do {to}",
        "name_ro": "10 minute - {$competitor1} total cornere de la {from} la {to}",
        "name_ru": "10 минут - {$competitor1} всего угловых с {from} до {to}",
        "name_tr": "10 dakika - {$competitor1} toplam korner sayısı  {from} dan {to} a",
        "name_se": "10 minuter - {$competitor1} totalt antal hörnor från {from} till {to}",
        "name_el": "10 λεπτά - {$competitor1}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_533": {
        "name_en": "Frames 1 to {framenr} - correct score",
        "name_aa": "الإطارات 1 إلى {framenr} - النتيجة الصحيحة",
        "name_de": "Frames 1 bis {framenr} - Genaues Ergebnis",
        "name_nl": "Frame 1 tot {framenr} - juiste score",
        "name_pl": "Frejmy 1 do {framenr} - Dokładny wynik",
        "name_ro": "Frame-urile de la 1 la {framenr} - scor corect",
        "name_ru": "Фреймы 1 до {framenr} - точный счет",
        "name_tr": "İlk Franmeden {framenr} e  - doğru skor",
        "name_se": "Frames 1 till {framenr} - rätt resultat",
        "name_el": "Frames 1 σε {framenr} - ακριβές σκορ"
    },
    "id_669": {
        "name_en": "{!inningnr} innings - {$competitor2} total run outs",
        "name_aa": "{!inningnr} أدوار - {$competitor2} إجمالي فترات الركض",
        "name_de": "{!inningnr} Inning - {$competitor2} Run outs gesamt",
        "name_nl": "{inningnr} innings - totaal aantal run outs {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma run outs",
        "name_ro": "{!inningnr} innings - {$competitor2} total run outs",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал ран-аутов",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam dışarı koşu",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal run outs",
        "name_el": "{!inningnr} innings - {$competitor2} total run outs"
    },
    "id_90": {
        "name_en": "2nd half - total",
        "name_aa": "الشوط الثاني - المجموع",
        "name_de": "2. Halbzeit - Total",
        "name_nl": "2e helft - totaal",
        "name_pl": "2. Połowa - Suma",
        "name_ro": "A 2-a repriză - total",
        "name_ru": "2ая половина - тотал",
        "name_tr": "İkinci yarı - toplam gol",
        "name_se": "2:a halvlek - totalt",
        "name_el": "2ο ημίχρονο -  Σύνολο Γκολ"
    },
    "id_678": {
        "name_en": "{!inningnr} innings - {$competitor1} last player standing",
        "name_aa": "{!inningnr} أدوار - {$competitor1} آخر لاعب يقف",
        "name_de": "{!inningnr} Inning - {$competitor1} Letzter Spieler",
        "name_nl": "{inningnr} innings - laatste speler {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} ostatni zawodnik",
        "name_ro": "{!inningnr} innings - {$competitor1} ultimul jucător în picioare",
        "name_ru": "{!inningnr} иннинг - {$competitor1} последний игрок",
        "name_tr": "{!inningnr}. devre - {$competitor1} - son kalan oyuncu",
        "name_se": "{!inningnr} innings - {$competitor1} sista spelaren står",
        "name_el": "{!inningnr} innings - {$competitor1} last player standing"
    },
    "id_386": {
        "name_en": "{$competitor2} total 180s",
        "name_aa": "{$competitor2} إجمالي 180 ثانية",
        "name_de": "{$competitor2} Total 180s",
        "name_nl": "Totaal aantal 180s {$competitor2}",
        "name_pl": "{$competitor2} Suma 180s",
        "name_ro": "Total 180-uri {$competitor2}",
        "name_ru": "{$competitor2} тотал 180ти",
        "name_tr": "{$competitor2} toplam 180ler",
        "name_se": "{$competitor2} totalt antal 180:or",
        "name_el": "{$competitor2} σύνολο 180"
    },
    "id_870": {
        "name_en": "Exacta",
        "name_aa": "Exacta",
        "name_de": "Exacta",
        "name_nl": "Exacta",
        "name_pl": "Exacta",
        "name_ro": "Exacta",
        "name_ru": "Exacta",
        "name_tr": "Exacta",
        "name_se": "Exacta",
        "name_el": "Exacta"
    },
    "id_723": {
        "name_en": "Will there be a rampage",
        "name_aa": "هل سيكون هناك هياج",
        "name_de": "Wird es ein Rampage geben",
        "name_nl": "Komt er een rampage",
        "name_pl": "Czy będzie rampage?",
        "name_ro": "Va fi un rampage",
        "name_ru": "Случится ли буйство",
        "name_tr": "Hücum olucak mı",
        "name_se": "Blir det rampage",
        "name_el": "Will there be a rampage"
    },
    "id_844": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread",
        "name_de": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - {$competitor1} total spread",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread",
        "name_ro": "{!inningnr} innings overs 0 la {overnr} - {$competitor1} total interval",
        "name_ru": "{!inningnr} иннингов оверов 0 до {overnr} - {$competitor1} тотал спред",
        "name_tr": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread",
        "name_se": "{!inningnr} innings overs 0 till {overnr} - {$competitor1} total spread",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total spread"
    },
    "id_605": {
        "name_en": "{!inningnr} innings - total",
        "name_aa": "{!inningnr} أدوار - المجموع",
        "name_de": "{!inningnr} Inning – Total",
        "name_nl": "{inningnr} innings - totaal",
        "name_pl": "{!inningnr} innings - suma",
        "name_ro": "{!inningnr} innings - total",
        "name_ru": "{!inningnr} иннинг - тотал",
        "name_tr": "{!inningnr}. devre - toplam",
        "name_se": "{!inningnr} innings - totalt",
        "name_el": "{!inningnr} innings – σύνολο"
    },
    "id_2": {
        "name_en": "To qualify",
        "name_aa": "للتأهل",
        "name_de": "Qualifiziert sich",
        "name_nl": "Te kwalificeren",
        "name_pl": "Zakwalifikowanie się",
        "name_ro": "Să se califice",
        "name_ru": "Квалифицируется",
        "name_tr": "Finale çıkan",
        "name_se": "Att kvalificera",
        "name_el": "Ποιος θα προκριθεί"
    },
    "id_724": {
        "name_en": "Will there be an ultra kill",
        "name_aa": "سيكون هناك قتل فائقة",
        "name_de": "Wird es ein Ultra-Kill geben",
        "name_nl": "Komt er een ultra kill",
        "name_pl": "Czy będzie ultra kill?",
        "name_ro": "Va fi un ultra kill",
        "name_ru": "Случится ли ультра килл",
        "name_tr": "Ultra kill olucak mı",
        "name_se": "Blir det ultra kill",
        "name_el": "Will there be an ultra kill"
    },
    "id_902": {
        "name_en": "Next scoring play",
        "name_aa": "Next scoring play",
        "name_de": "Next scoring play",
        "name_nl": "Volgende scoringsspel",
        "name_pl": "Next scoring play",
        "name_ro": "Next scoring play",
        "name_ru": "Следующая результативная игра",
        "name_tr": "Next scoring play",
        "name_se": "Nästa poängspel",
        "name_el": "Επόμενο scoring play"
    },
    "id_1145": {
        "name_en": "{!inningnr} innings - {%player1} or {%player2} to survive a review",
        "name_aa": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_de": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_nl": "{!inningnr} innings - {!player1} of {!player2} overleeft een review",
        "name_pl": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_ro": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_ru": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_tr": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_se": "{!inningnr} innings - {!player1} or {!player2} to survive a review",
        "name_el": "{!inningnr} innings - {!player1} or {!player2} to survive a review"
    },
    "id_757": {
        "name_en": "{!quarternr} quarter - {$competitor2} total",
        "name_aa": "الربع {!quarternr} - {$competitor2} مجموعه",
        "name_de": "{!quarternr} Viertel - {$competitor2} Total",
        "name_nl": "{quarternr} kwart - {$competitor2} totaal",
        "name_pl": "{!quarternr} kwarta - {$competitor2} suma",
        "name_ro": "Sfertul {!quarternr} - {$competitor2} total",
        "name_ru": "{!quarternr} четверть - {$competitor2} тотал",
        "name_tr": "{!quarternr} çeyrek - {$competitor2} toplam",
        "name_se": "{!quarternr} quarter - {$competitor2} totalt",
        "name_el": "{!quarternr} περιόδος - {$competitor2} σύνολο"
    },
    "id_534": {
        "name_en": "Championship free text market",
        "name_aa": "Championship free text market",
        "name_de": "Championship free text market",
        "name_nl": "Championship free text market",
        "name_pl": "Championship free text market",
        "name_ro": "Championship free text market",
        "name_ru": "Championship free text market",
        "name_tr": "Championship free text market",
        "name_se": "Championship free text market",
        "name_el": "Championship free text market"
    },
    "id_439": {
        "name_en": "{$competitor1} to score in all periods",
        "name_aa": "{$competitor1} للتسجيل في جميع الفترات",
        "name_de": "{$competitor1} wird in allen Perioden punkten",
        "name_nl": "{$competitor1}scoort in alle periodes",
        "name_pl": "{$competitor1} zapunktuje we wszystkich częściach gry",
        "name_ro": "{$competitor1} să înscrie în toate reprizele",
        "name_ru": "{$competitor1} забить во всех периодах",
        "name_tr": "{$competitor1} her periyotta sayı yapar",
        "name_se": "{$competitor1} att göra mål i alla perioder",
        "name_el": "{$competitor1} να σκοράρει σε όλες τις περιόδους"
    },
    "id_577": {
        "name_en": "10 minutes - {$competitor2} total corners from {from} to {to}",
        "name_aa": "10 دقائق - {$competitor2} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "10 Minuten - {$competitor2} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "10 minutes - totaal aantal hoekschoppen {$competitor2} van {from} tot {to}",
        "name_pl": "10 minut - {$competitor2} suma rzutów rożnych od {from} do {to}",
        "name_ro": "10 minute - {$competitor2} total cornere de la {from} la {to}",
        "name_ru": "10 минут - {$competitor2} всего угловых с {from} до {to}",
        "name_tr": "10 dakika - {$competitor2} toplam korner sayısı {from} dan {to} a",
        "name_se": "10 minuter - {$competitor2} totalt antal hörnor från {from} till {to}",
        "name_el": "10 λεπτά - {$competitor2}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_237": {
        "name_en": "Point handicap",
        "name_aa": "هانديكاب نقطة",
        "name_de": "Punkte Handicap",
        "name_nl": "Punten handicap",
        "name_pl": "Punkty handicap",
        "name_ro": "Handicap puncte",
        "name_ru": "Гандикап очков",
        "name_tr": "Sayı handikap",
        "name_se": "Poäng handikapp",
        "name_el": "Χάντικαπ πόντων"
    },
    "id_368": {
        "name_en": "Odd/even sets",
        "name_aa": "فردي / زوجي مجموعات",
        "name_de": "Gerade/ungerade Sätze",
        "name_nl": "Even/oneven sets",
        "name_pl": "Parzyste/Nieparzyste sety",
        "name_ro": "Set-uri impar/par",
        "name_ru": "Сеты чет/нечет",
        "name_tr": "Tek/çift setler",
        "name_se": "Udda/jämnt sets",
        "name_el": "Μονά/Ζυγά σετ"
    },
    "id_1050": {
        "name_en": "Hit on {!pitchnr} pitch",
        "name_aa": "Hit on {!pitchnr} pitch",
        "name_de": "Hit on {!pitchnr} pitch",
        "name_nl": "Geraakt bij {!pitchnr} worp",
        "name_pl": "Hit on {!pitchnr} pitch",
        "name_ro": "Hit on {!pitchnr} pitch",
        "name_ru": "Хитов {!pitchnr} поданных подач питчером",
        "name_tr": "Hit on {!pitchnr} pitch",
        "name_se": "Hit på {!pitchnr} pitch",
        "name_el": "Hit on {!pitchnr} pitch"
    },
    "id_919": {
        "name_en": "{%player} total receiving yards (incl. overtime)",
        "name_aa": "{!player} total receiving yards (incl. overtime)",
        "name_de": "{!player} Receiving Yards Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal receiving yards",
        "name_pl": "{!player} total receiving yards (incl. overtime)",
        "name_ro": "{!player} total receiving yards (incl. overtime)",
        "name_ru": "{!player} тотал полученных ярдов",
        "name_tr": "{!player} total receiving yards (incl. overtime)",
        "name_se": "{!player} total receiving yards (incl. overtime)",
        "name_el": "{!player} total receiving yards"
    },
    "id_446": {
        "name_en": "{!periodnr} period - total",
        "name_aa": "فترة {!periodnr} - مجموعه",
        "name_de": "{!periodnr} Periode - Total",
        "name_nl": "{periodnr} periode - totaal",
        "name_pl": "{!periodnr} część gry - suma",
        "name_ro": "Repriza {!periodnr} - total",
        "name_ru": "{!periodnr} период - тотал",
        "name_tr": "{!periodnr} . periyot - toplam",
        "name_se": "{!periodnr} period - totalt",
        "name_el": "{!periodnr} περίοδος - σύνολο"
    },
    "id_385": {
        "name_en": "{$competitor1} total 180s",
        "name_aa": "{$competitor1} إجمالي 180 ثانية",
        "name_de": "{$competitor1} Total 180s",
        "name_nl": "Totaal aantal 180s {$competitor1}",
        "name_pl": "{$competitor1} Suma 180s",
        "name_ro": "Total 180-uri {$competitor1}",
        "name_ru": "{$competitor1} тотал 180ти",
        "name_tr": "{$competitor1} toplam 180ler",
        "name_se": "{$competitor1} totalt antal 180:or",
        "name_el": "{$competitor1} σύνολο 180"
    },
    "id_1069": {
        "name_en": "Any team win to nil (incl. extra innings)",
        "name_aa": "Any team win to nil (incl. extra innings)",
        "name_de": "Gewinnt ein Team zu Null (inkl. Extra Innings)",
        "name_nl": "Willekeurig team houdt de nul (incl. extra innings)",
        "name_pl": "Any team win to nil (incl. extra innings)",
        "name_ro": "Any team win to nil (incl. extra innings)",
        "name_ru": "Сухая победа любой команды (включая экстра иннинги)",
        "name_tr": "Any team win to nil (incl. extra innings)",
        "name_se": "Any team win to nil (incl. extra innings)",
        "name_el": "Οποιαδήποτε ομάδα να νικήσει με μηδέν παθητικό  (συμπ. extra innings)"
    },
    "id_1023": {
        "name_en": "Hole {holenr} - 2 ball (1x2)",
        "name_aa": "Hole {holenr} - 2 ball (1x2)",
        "name_de": "Hole {holenr} - 2 ball (1x2)",
        "name_nl": "Hole {holenr} - 2 ball (1x2)",
        "name_pl": "Hole {holenr} - 2 ball (1x2)",
        "name_ro": "Hole {holenr} - 2 ball (1x2)",
        "name_ru": "Hole {holenr} - 2 ball (1x2)",
        "name_tr": "Hole {holenr} - 2 ball (1x2)",
        "name_se": "Hole {holenr} - 2 ball (1x2)",
        "name_el": "Hole {holenr} - 2 ball (1x2)"
    },
    "id_430": {
        "name_en": "Correct score [{score}]",
        "name_aa": "النتيجة الصحيحة [{score}]",
        "name_de": "Genaues Ergebnis [{score}]",
        "name_nl": "Juiste score [{score}]",
        "name_pl": "Dokładny wynik [{score}]",
        "name_ro": "Scor corect [{score}]",
        "name_ru": "Точный счет [{score}]",
        "name_tr": "Maç sonucu [{score}]",
        "name_se": "Rätt resultat [{score}]",
        "name_el": "Ακριβές σκορ [{score}]"
    },
    "id_330": {
        "name_en": "{!mapnr} map - winner (incl. overtime)",
        "name_aa": "خريطة   {!mapnr} - الفائز (بما في ذلك الوقت الإضافي)",
        "name_de": "{!mapnr} Map - Gewinner (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - winnaar (overtime inbegrepen)",
        "name_pl": "{!mapnr} mapa - zwycięzca (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - câștigător (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - победитель (включая овертайм)",
        "name_tr": "{mapnr}. harita - kazanan (uzatma süreleri dahil)",
        "name_se": "{!mapnr} karta - vinnare (inkl. övertid)",
        "name_el": "{!mapnr} map - νικητής (παράταση συμπ.)"
    },
    "id_1021": {
        "name_en": "{%competitor} odd/even strokes",
        "name_aa": "{!competitor} odd/even strokes",
        "name_de": "{!competitor} odd/even strokes",
        "name_nl": "oneven/even slagen {!competitor}",
        "name_pl": "{!competitor} odd/even strokes",
        "name_ro": "{!competitor} odd/even strokes",
        "name_ru": "{!competitor} odd/even strokes",
        "name_tr": "{!competitor} odd/even strokes",
        "name_se": "{!competitor} odd/even strokes",
        "name_el": "{!competitor} μονά/ζυγά strokes"
    },
    "id_1141": {
        "name_en": "{!inningnr} innings over {overnr} - {%player1} & {%player2} to hit a boundary",
        "name_aa": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_de": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_nl": "{!inningnr} innings over {overnr} - {!player1} & {!player2} slaan een boundary",
        "name_pl": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_ro": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_ru": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_tr": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_se": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary",
        "name_el": "{!inningnr} innings over {overnr} - {!player1} & {!player2} to hit a boundary"
    },
    "id_293": {
        "name_en": "2nd half - 1x2 (incl. overtime)",
        "name_aa": "النصف الثاني - 1 × 2 (بما في ذلك الوقت الإضافي)",
        "name_de": "2. Halbzeit - 1x2 (inkl. Verlängerung)",
        "name_nl": "Tweede helft - 1x2 (verlenging inbegrepen)",
        "name_pl": "2. Połowa - 1x2 (włącznie z dogrywką)",
        "name_ro": "Repriza 2 - 1x2 (incl. prelungiri)",
        "name_ru": "2ая половина - 1x2 (включая овертайм)",
        "name_tr": "İkinci yarı - 1x2 (uzatmalar dahil)",
        "name_se": "2:a halvlek - 1x2 (inkl. övertid)",
        "name_el": "2ο ημίχρονο - 1Χ2 (παράταση συμπ.)"
    },
    "id_579": {
        "name_en": "5 minutes - corner 1x2 from {from} to {to}",
        "name_aa": "5 دقائق - الزاوية 1 × 2 من {from} إلى {to}",
        "name_de": "5 Minuten - Ecken 1x2 von {from} bis {to}",
        "name_nl": "5 minutes - hoekschop 1x2 van {from} tot {to}",
        "name_pl": "5 minut - rzuty rożne 1x2 od {from} do {to}",
        "name_ro": "5 minute - corner 1x2 de la {from} la {to}",
        "name_ru": "5 минут - угловой 1х2 с {from} до {to}",
        "name_tr": "5 dakika - korner 1x2 {from} dan {to} a",
        "name_se": "5 minuter - hörnor 1x2 från {from} till {to}",
        "name_el": "5 λεπτά - κόρνερ 1Χ2 από {from} μέχρι {to}"
    },
    "id_688": {
        "name_en": "All-rounder head2head (1x2)",
        "name_aa": "كل مستدير وجها لوجه (1X2)",
        "name_de": "All-rounder head2head (1x2)",
        "name_nl": "All-rounder head2head (1x2)",
        "name_pl": "All-rounder head2head (1x2)",
        "name_ro": "Confruntare directă all-rounder (1x2)",
        "name_ru": "Олл-раундер единоборство (1x2)",
        "name_tr": "All-rounder head2head (1x2)",
        "name_se": "All-rounder head2head (1x2)",
        "name_el": "All-rounder head2head (1x2)"
    },
    "id_583": {
        "name_en": "5 minutes - {$competitor1} total corners from {from} to {to}",
        "name_aa": "5 دقائق - {$competitor1} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "5 Minuten - {$competitor1} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "5 minutes - totaal aantal hoekschoppen {$competitor1} van {from} tot {to}",
        "name_pl": "5 minut - {$competitor1} suma rzutów rożnych od {from} do {to}",
        "name_ro": "5 minute - {$competitor1} total cornere de la {from} la {to}",
        "name_ru": "5 минут - {$competitor1} всего угловых с {from} до {to}",
        "name_tr": "5 dakika - {$competitor1} toplam korner sayısı {from} dan {to} a",
        "name_se": "5 minuter - {$competitor1} totalt antal hörnor från {from} till {to}",
        "name_el": "5 λεπτά - {$competitor1}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_903": {
        "name_en": "Next score (incl. overtime)",
        "name_aa": "Next score (incl. overtime)",
        "name_de": "Nächster Punkt (inkl. Verlängerung)",
        "name_nl": "Volgende score (incl. extra tijd)",
        "name_pl": "Next score (incl. overtime)",
        "name_ro": "Next score (incl. overtime)",
        "name_ru": "Следующий счет (включая овертайм)",
        "name_tr": "Next score (incl. overtime)",
        "name_se": "Nästa poäng (inkl. övertid)",
        "name_el": "Επόμενο σκοράρισμα -παράταση συμπεριλαμβάνεται"
    },
    "id_765": {
        "name_en": "Player receiving yards (incl. overtime)",
        "name_aa": "لاعب تلقي ياردة",
        "name_de": "Spieler Receiving Yards (inkl. Verlängerung)",
        "name_nl": "Speler receiving yards",
        "name_pl": "Player receiving yards (incl. overtime)",
        "name_ro": "Yarzi primire jucător",
        "name_ru": "Игрок принимающий ярдов",
        "name_tr": "Oyuncu yardları geçiyor",
        "name_se": "Spelare - mottagningsyards",
        "name_el": "Παίχτης rushing yards"
    },
    "id_559": {
        "name_en": "Free text market",
        "name_aa": "Free text market",
        "name_de": "Free text market",
        "name_nl": "Free text market",
        "name_pl": "Free text market",
        "name_ro": "Free text market",
        "name_ru": "Free text market",
        "name_tr": "Free text market",
        "name_se": "Free text market",
        "name_el": "Free text market"
    },
    "id_135": {
        "name_en": "Penalty shootout - correct score",
        "name_aa": "ركلات الترجيح - النتيجة الصحيحة",
        "name_de": "Elfmeterschießen - Genaues Ergebnis",
        "name_nl": "Strafschoppenserie - juiste uitslag",
        "name_pl": "Rzuty karne - dokładny wynik",
        "name_ro": "Lovituri de departajare - scor corect",
        "name_ru": "Серия пенальти - точный счет",
        "name_tr": "Penaltılar - doğru skor",
        "name_se": "Straffar - rätt resultat",
        "name_el": "Διαδικασία πέναλτι - Ακριβές σκορ"
    },
    "id_767": {
        "name_en": "Player receptions (incl. overtime)",
        "name_aa": "حفلات الاستقبال لاعب",
        "name_de": "Spieler Receptions (inkl. Verlängerung)",
        "name_nl": "Speler receptions",
        "name_pl": "Player receptions (incl. overtime)",
        "name_ro": "Recepții jucător",
        "name_ru": "Игрок совершивший больше приемов мяча",
        "name_tr": "Oyuncu resepsiyonları",
        "name_se": "Spelarmottagningar",
        "name_el": "Παίχτης receptions"
    },
    "id_104": {
        "name_en": "15 minutes - total from {from} to {to}",
        "name_aa": "15 دقيقة - الإجمالي من {from} إلى {to}",
        "name_de": "15 Minuten - Total von {from} bis {to}",
        "name_nl": "15 minuten - totaal van {from} tot {to}",
        "name_pl": "15 minut - suma od {from} do {to}",
        "name_ro": "15 minute - total de la {from} la {to}",
        "name_ru": "15 минут - тотал от {from} до {to}",
        "name_tr": "15 dakika - {from}'dan {to}'a kadar toplam gol",
        "name_se": "15 minuter - totalt från {from} till {to}",
        "name_el": "15 λεπτά - σύνολο από {from} μέχρι {to}"
    },
    "id_13": {
        "name_en": "{$competitor2} no bet",
        "name_aa": "{$competitor2} لا رهان",
        "name_de": "{$competitor2} keine Wette",
        "name_nl": "{$competitor2} no bet",
        "name_pl": "{$competitor2} bez zakładu",
        "name_ro": "{$competitor2} pariu nul",
        "name_ru": "Нет ставки на {$competitor2}",
        "name_tr": "{$competitor2} kazanırsa iade",
        "name_se": "{$competitor2} inget spel",
        "name_el": "{$competitor2}  Ισοπαλία όχι στοιχήμα"
    },
    "id_121": {
        "name_en": "Overtime 1st half - correct score [{score}]",
        "name_aa": "الوقت الإضافي في الشوط الأول - النتيجة الصحيحة [{score}]",
        "name_de": "Verlängerung 1. Halbzeit - Genaues Ergebnis [{score}]",
        "name_nl": "Eerste helft verlenging - juiste uitslag [{score}]",
        "name_pl": "Dogrywka 1. połowa - dokładny wynik [{score}]",
        "name_ro": "Prelungiri Repriza 1 - scor corect [{score}]",
        "name_ru": "Овертайм 1ая половина - точный счет [{score}]",
        "name_tr": "İlk uzatma devresi - doğru skor [{score}]",
        "name_se": "Övertid 1:a halvlek - rätt resultat [{score}]",
        "name_el": "Παράταση 1ο ημίχρονο - Ακριβές σκορ [{score}]"
    },
    "id_341": {
        "name_en": "Will there be a super over",
        "name_aa": "سوف يكون هناك سوبر أكثر",
        "name_de": "Wird es ein Super over geben?",
        "name_nl": "Zal er een super over nodig zijn",
        "name_pl": "Czy będzie super over?",
        "name_ro": "Va fi un super over",
        "name_ru": "Будет ли супер овер",
        "name_tr": "Super over olur mu",
        "name_se": "Blir det super over",
        "name_el": "Θα υπάρξει super over"
    },
    "id_349": {
        "name_en": "{!inningnr} innings - {$competitor1} total at {!dismissalnr} dismissal",
        "name_aa": "أدوار {!inningnr} - {$competitor1} مجموعه في فصل {dismissalnr!}",
        "name_de": "{!inningnr} Inning - {$competitor1} Total im {!dismissalnr} Dismissal",
        "name_nl": "{inningnr} innings - totaal {$competitor1} bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor1} suma przy {!dismissalnr} wyeliminowaniu",
        "name_ro": "{!inningnr} innings - total {$competitor1} la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал при {!dismissalnr} выбиваний",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam {!dismissalnr}. oyundan çıkarılma",
        "name_se": "{!inningnr} innings - {$competitor1} totala vid {!dismissalnr} avvisande",
        "name_el": "{!inningnr} innings - {$competitor1} σύνολο στο {!dismissalnr} dismissal"
    },
    "id_879": {
        "name_en": "{$competitor2} to win",
        "name_aa": "{$competitor2} للفوز",
        "name_de": "{$competitor2} wird gewinnen",
        "name_nl": "{$competitor2} wint",
        "name_pl": "{$competitor2} to win",
        "name_ro": "{$competitor2} să câștige",
        "name_ru": "{$competitor2} победит",
        "name_tr": "{$competitor2} kazanan",
        "name_se": "{$competitor2} att vinna",
        "name_el": "{$competitor2} να κερδίσει"
    },
    "id_648": {
        "name_en": "Most sixes",
        "name_aa": "معظم الستات",
        "name_de": "Meiste Sixes",
        "name_nl": "Meeste sixes",
        "name_pl": "Najwięcej szóstek",
        "name_ro": "Cele mai multe sixes",
        "name_ru": "Большинство шести ранов",
        "name_tr": "Maksimum 6 puan vuruşları",
        "name_se": "Flest sixes",
        "name_el": "Περισσότερα sixes"
    },
    "id_1064": {
        "name_en": "{!drivenr} drive play {playnr} - {%competitor} new first down",
        "name_aa": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_de": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_nl": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_pl": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_ro": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_ru": "{!drivenr} драйв плей {playnr} - {!competitor} новый первый даун",
        "name_tr": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_se": "{!drivenr} drive play {playnr} - {!competitor} new first down",
        "name_el": "{!drivenr} drive play {playnr} - {!competitor} new first down"
    },
    "id_379": {
        "name_en": "{!setnr} set leg {legnr} - checkout score {score}+",
        "name_aa": "{!setnr} وضع الساق {legnr} - الخروج بنتيجة {score} +",
        "name_de": "{!setnr} Satz Runde {legnr} - Checkout Ergebnis {score}+",
        "name_nl": "{setnr} set leg {legnr} - checkout score {score}+",
        "name_pl": "{!setnr} set leg {legnr} - checkout score {score}+",
        "name_ro": "Set {!setnr} leg {legnr} - scor checkout {score}+",
        "name_ru": "{!setnr} сет {legnr} этап - чек-аут счет {score}+",
        "name_tr": "{!setnr}.. set {legnr}. ayak - checkout skor {score}+",
        "name_se": "{!setnr} set leg {legnr} - checkout-poäng {score}+",
        "name_el": "{!setnr} set, {legnr} leg - checkout score {score}+"
    },
    "id_914": {
        "name_en": "{%player} total passing yards (incl. overtime)",
        "name_aa": "{!player} total passing yards (incl. overtime)",
        "name_de": "{!player} Passing Yards Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal passing yards",
        "name_pl": "{!player} total passing yards (incl. overtime)",
        "name_ro": "{!player} total passing yards (incl. overtime)",
        "name_ru": "{!player} тотал пасов в ярдах",
        "name_tr": "{!player} total passing yards (incl. overtime)",
        "name_se": "{!player} total passing yards (incl. overtime)",
        "name_el": "{!player} total passing yards"
    },
    "id_353": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total",
        "name_aa": "{!inningnr} أدوار المبالغ 0 إلى {overnr}- {$competitor2} المجموع",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor2} Total",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - totaal {$competitor2}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor2} suma",
        "name_ro": "{!inningnr} innings overs de la 0 la {overnr} - {$competitor2}",
        "name_ru": "{!inningnr} иннинг оверы от 0 до {overnr} - {$competitor2} тотал",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor2} toplam",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor2} totalt",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} σύνολο"
    },
    "id_537": {
        "name_en": "3-ball",
        "name_aa": "3-الكرة",
        "name_de": "3-Ball",
        "name_nl": "3-ball",
        "name_pl": "Trójka",
        "name_ro": "3-ball",
        "name_ru": "3ий мяч",
        "name_tr": "3-top",
        "name_se": "3-ball",
        "name_el": "3 μπάλες"
    },
    "id_753": {
        "name_en": "{!quarternr} quarter - double chance",
        "name_aa": "الربع {!quarternr}- فرصة مزدوجة",
        "name_de": "{!quarternr} Viertel - Doppelte Chance",
        "name_nl": "{quarternr} kwart - dubbele kans",
        "name_pl": "{!quarternr} kwarta - podwójna szansa",
        "name_ro": "Sfertul {!quarternr} - șansă dublă",
        "name_ru": "{!quarternr} четверть - двойной шанс",
        "name_tr": "{!quarternr} çeyrek - çifte şans",
        "name_se": "{!quarternr} quarter - dubbelchans",
        "name_el": "{!quarternr} περιόδος - διπλή ευκαιρία"
    },
    "id_750": {
        "name_en": "{!inningnr} inning - {$competitor2} to score",
        "name_aa": "{!inningnr} الشوط - {$competitor2} ليسجل",
        "name_de": "{!inningnr} Inning - {$competitor2} punktet",
        "name_nl": "{inningnr} inning - {$competitor2} scoort",
        "name_pl": "{!inningnr} inning - {$competitor2} zdobywa punkty",
        "name_ro": "{!inningnr} inning - să înscrie {$competitor2}",
        "name_ru": "{!inningnr} иннинг - {$competitor2} забьет",
        "name_tr": "{!inningnr}. devre - {$competitor2} sayı yapar",
        "name_se": "{!inningnr} inning - {$competitor2} ta poäng",
        "name_el": "{!inningnr} inning - {$competitor2} να σκοράρει"
    },
    "id_871": {
        "name_en": "Quinella",
        "name_aa": "Quinella",
        "name_de": "Quinella",
        "name_nl": "Quinella",
        "name_pl": "Quinella",
        "name_ro": "Quinella",
        "name_ru": "Quinella",
        "name_tr": "Quinella",
        "name_se": "Quinella",
        "name_el": "Quinella"
    },
    "id_649": {
        "name_en": "{!inningnr} innings - {$competitor1} total dismissals",
        "name_aa": "{!inningnr} أدوار - {$competitor1} فصل تام",
        "name_de": "{!inningnr} Inning - {$competitor1} Dismissals Gesamt",
        "name_nl": "{inningnr} innings - totaal aantal dismissals {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma wyeliminowań",
        "name_ro": "{!inningnr} innings - total demiteri {$competitor1}",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал выбиваний",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam oyundan çıkarma",
        "name_se": "{!inningnr} innings - {$competitor1} totalt antal  avvisningar",
        "name_el": "{!inningnr} innings - {$competitor1} total dismissals"
    },
    "id_1102": {
        "name_en": "Top {winners} (teams)",
        "name_aa": "Top {winners} (teams)",
        "name_de": "Top {winners} (teams)",
        "name_nl": "Beste {winners} (teams)",
        "name_pl": "Top {winners} (teams)",
        "name_ro": "Top {winners} (teams)",
        "name_ru": "Топ {winners} (команды)",
        "name_tr": "Top {winners} (teams)",
        "name_se": "Top {winners} (teams)",
        "name_el": "Top {winners} (teams)"
    },
    "id_1608": {
        "name_en": "Both team to score or {total} total",
        "name_aa": "Both team to score or {total} total",
        "name_de": "Both team to score or {total} total",
        "name_nl": "Both team to score or {total} total",
        "name_pl": "Both team to score or {total} total",
        "name_ro": "Both team to score or {total} total",
        "name_ru": "Both team to score or {total} total",
        "name_tr": "Both team to score or {total} total",
        "name_se": "Both team to score or {total} total",
        "name_el": "Both team to score or {total} total"
    },
    "id_371": {
        "name_en": "{!setnr} set - leg handicap",
        "name_aa": "مجموعة {!setnr} - الإعاقة الساق",
        "name_de": "{!setnr} Satz - Runden Handicap",
        "name_nl": "{setnr} set - leg handicap",
        "name_pl": "{!setnr} set - legi handicap",
        "name_ro": "Set {!setnr} - handicap leg",
        "name_ru": "{!setnr} сет - этап гандикап",
        "name_tr": "{!setnr}. set - ayak handikabı",
        "name_se": "{!setnr} set - leg handikapp",
        "name_el": "{!setnr} set - leg χάντικαπ"
    },
    "id_198": {
        "name_en": "Odd/even games",
        "name_aa": "فردي / زوجي الألعاب",
        "name_de": "Gerade/ungerade Spiele",
        "name_nl": "Even/oneven aantal games",
        "name_pl": "Parzyste/Nieparzyste gemy",
        "name_ro": "Game-uri impar/par",
        "name_ru": "Геймы чет/нечет",
        "name_tr": "Toplam oyun tek/çift",
        "name_se": "Udda/jämnt games",
        "name_el": "Μονά/Ζυγά γκέιμ"
    },
    "id_395": {
        "name_en": "{!mapnr} map - winner",
        "name_aa": "خريطة {!mapnr} - الفائز",
        "name_de": "{!mapnr} Map - Gewinner",
        "name_nl": "{mapnr} map - winnaar",
        "name_pl": "{!mapnr} mapa - zwycięzca",
        "name_ro": "Harta {!mapnr} - câștigător",
        "name_ru": "{!mapnr} карта - победитель",
        "name_tr": "{!mapnr}. harita - kazanan",
        "name_se": "{!mapnr} karta - vinnare",
        "name_el": "{!mapnr} map - νικητής"
    },
    "id_543": {
        "name_en": "2nd half - 1x2 & both teams to score",
        "name_aa": "الشوط الثاني - 1 × 2 وكلا الفريقين للتسجيل",
        "name_de": "2. Halbzeit - 1x2  & Beide Mannschaften treffen",
        "name_nl": "Tweede helft - 1x2 & beide teams scoren",
        "name_pl": "2. Połowa - 1x2 & oba zespoły strzelą",
        "name_ro": "Repriza 2 - 1x2 & ambele echipe să înscrie",
        "name_ru": "2ая половина - 1x2 и обе команды забьют",
        "name_tr": "İkinci yarı - 1x2 & her iki takımda gol atar",
        "name_se": "2:a halvlek - 1x2 & båda lagen gör mål",
        "name_el": "2ο ημίχρονο - 1Χ2 και να σκοράρουν και οι 2 ομάδες"
    },
    "id_54": {
        "name_en": "{$competitor2} highest scoring half",
        "name_aa": "{$competitor2}أعلى التهديف الشوط",
        "name_de": "{$competitor2} Halbzeit mit den meisten Toren",
        "name_nl": "{$competitor2} wint helft met de meeste doelpunten",
        "name_pl": "{$competitor2} Połowa z największą liczbą goli",
        "name_ro": "{$competitor2} repriza cu cele mai multe goluri",
        "name_ru": "{$competitor2} самый результативный тайм",
        "name_tr": "{$competitor2} en gollü devre",
        "name_se": "{$competitor2} halvlek med flest mål",
        "name_el": "{$competitor2} ημίχρονο με περισσότερα γκολ"
    },
    "id_788": {
        "name_en": "Batter runs + runs batted in (incl. extra innings)",
        "name_aa": "يدير الخليط + يدير يضرب في",
        "name_de": "Schlagmann Läufe + Läufe eingeschlagen (inkl. Extra Innings)",
        "name_nl": "Slagman runs + runs batted in",
        "name_pl": "Batter runs + runs batted in (incl. extra innings)",
        "name_ro": "Run-uri batter + run-uri bătute înăuntru",
        "name_ru": "Бэттер раны + раны отбитые",
        "name_tr": "Vurucu koşuları + sayı koşuları",
        "name_se": "Slagman runs + runs batted in",
        "name_el": "Batter runs + runs batted"
    },
    "id_1016": {
        "name_en": "Total eagles",
        "name_aa": "Total eagles",
        "name_de": "Eagles Gesamt",
        "name_nl": "Totaal aantal eagles",
        "name_pl": "Total eagles",
        "name_ro": "Total eagles",
        "name_ru": "Total eagles",
        "name_tr": "Total eagles",
        "name_se": "Total eagles",
        "name_el": "Total eagles"
    },
    "id_316": {
        "name_en": "{!setnr} set - draw no bet",
        "name_aa": "مجموعة {setnr!} - رسم أي رهان",
        "name_de": "{!setnr} Satz - unentschieden, keine Wette",
        "name_nl": "{setnr} set - draw no bet",
        "name_pl": "{!setnr} set - remis bez zakładu",
        "name_ro": "Set-ul {!setnr} - egal pariu nul",
        "name_ru": "{!setnr} сет - ничья ставки нет",
        "name_tr": "{!setnr}. set - beraberlikte iade",
        "name_se": "{!setnr} set - oavgjort",
        "name_el": "{!setnr} σετ -Ισοπαλία όχι στοιχήμα"
    },
    "id_618": {
        "name_en": "1st half - {$competitor1} odd/even",
        "name_aa": "الشوط الأول - {$competitor1} فردي / زوجي",
        "name_de": "1. Halbzeit - {$competitor1} Gerade/ungerade",
        "name_nl": "Eerste helft - {$competitor1} even/oneven",
        "name_pl": "1. Połowa - {$competitor1} Parzyste/Nieparzyste",
        "name_ro": "Repriza 1 - {$competitor1} impar/par",
        "name_ru": "1ая половина - {$competitor1} чет/нечет",
        "name_tr": "İlk yarı - {$competitor1} tek/çift",
        "name_se": "1:a halvlek - {$competitor1} udda/jämnt",
        "name_el": "1ο ημίχρονο - {$competitor1} μονά/ζυγά"
    },
    "id_647": {
        "name_en": "Most fours",
        "name_aa": "معظم أربعات",
        "name_de": "Meiste Fours",
        "name_nl": "Meeste fours",
        "name_pl": "Najwięcej czwórek",
        "name_ro": "Cele mai multe fours",
        "name_ru": "Большинство 4 ранов",
        "name_tr": "Maksimum 4 puan vuruşları",
        "name_se": "Flest fours",
        "name_el": "Περισσότερα fours"
    },
    "id_484": {
        "name_en": "1st half - try draw no bet",
        "name_aa": "الشوط الأول - حاول عدم الرهان",
        "name_de": "1. Halbzeit - Versuch unentschieden, keine Wette",
        "name_nl": "Eerste helft - try draw no bet",
        "name_pl": "1. Połowa - przyłożenie remis bez zakładu",
        "name_ro": "Repriza 1 - încercări egal pariu nul",
        "name_ru": "1ая половина - попытка ничья ставки нет",
        "name_tr": "İlk yarı - try beraberlikte iade",
        "name_se": "1:a halvlek - försök insatsen tillbaka vid oavgjort",
        "name_el": "1ο ημίχρονο - try Ισοπαλία όχι στοιχήμα"
    },
    "id_1135": {
        "name_en": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_aa": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_de": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_nl": "Welk team heeft de hoogste 1e partnership & 1e over & overs 0 tot {overnr}",
        "name_pl": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_ro": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_ru": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_tr": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_se": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}",
        "name_el": "Which team has the highest 1st partnership & 1st over & overs 0 to {overnr}"
    },
    "id_297": {
        "name_en": "Total (over-exact-under)",
        "name_aa": "الإجمالي (أكثر من اللازم بشكل دقيق)",
        "name_de": "Total (darüber-exakt-unter)",
        "name_nl": "Totaal (boven-exact-onder)",
        "name_pl": "Suma (ponad-dokładnie-poniżej)",
        "name_ro": "Total (peste-exact-sub)",
        "name_ru": "Тотал (больше-точно-меньше)",
        "name_tr": "Toplam gol (üstünde-tam olarak-altında)",
        "name_se": "Totalt (över-exakt-under)",
        "name_el": "Σύνολο (over-ακριβώς-under)"
    },
    "id_872": {
        "name_en": "Trifecta",
        "name_aa": "Trifecta",
        "name_de": "Trifecta",
        "name_nl": "Trifecta",
        "name_pl": "Trifecta",
        "name_ro": "Trifecta",
        "name_ru": "Trifecta",
        "name_tr": "Trifecta",
        "name_se": "Trifecta",
        "name_el": "Trifecta"
    },
    "id_740": {
        "name_en": "Total scoreless innings",
        "name_aa": "مجموع أدوار بالتعادل السلبي",
        "name_de": "Torlose Innings gesamt",
        "name_nl": "Totaal aantal innings zonder score",
        "name_pl": "Suma inninngs bez punktów",
        "name_ro": "Total innings fără înscrieri",
        "name_ru": "Всего иннингов без очков",
        "name_tr": "Sayı olmayan devre sayısı",
        "name_se": "Totalt poänglösa innings",
        "name_el": "Total scoreless innings"
    },
    "id_1634": {
        "name_en": "1x2 (1st half) & Double chance",
        "name_aa": "1x2 (1st half) & Double chance",
        "name_de": "1x2 (1st half) & Double chance",
        "name_nl": "1x2 (1st half) & Double chance",
        "name_pl": "1x2 (1st half) & Double chance",
        "name_ro": "1x2 (1st half) & Double chance",
        "name_ru": "1x2 (1st half) & Double chance",
        "name_tr": "1x2 (1st half) & Double chance",
        "name_se": "1x2 (1st half) & Double chance",
        "name_el": "1x2 (1st half) & Double chance"
    },
    "id_834": {
        "name_en": "Standard bet (incl. bonus ball in additional drum)",
        "name_aa": "الرهان القياسي (بما في ذلك الكرة الإضافية في أسطوانة إضافية)",
        "name_de": "Standard bet (incl. bonus ball in additional drum)",
        "name_nl": "Standaard weddenschap (reservebal inbegrepen in aanvullende koker)",
        "name_pl": "Standard bet (incl. bonus ball in additional drum)",
        "name_ro": "Pariu standard (incl. bilă bonus în urnă adițională)",
        "name_ru": "Обычная ставка (вкл. бонус бол в дополнительную лунку)",
        "name_tr": "Standart bahis (bonus ball'u ek drum'a dahil et)",
        "name_se": "Standardinsats (inkl. bonusboll i en extra trummma)",
        "name_el": "Standard bet (συμπερ. bonus ball in additional drum)"
    },
    "id_309": {
        "name_en": "{!setnr} set - point handicap",
        "name_aa": "مجموعة {!setnr} - هانديكاب نقطة",
        "name_de": "{!setnr} Satz - Punkte Handicap",
        "name_nl": "{setnr} set - punten handicap",
        "name_pl": "{!setnr} set - punkty handicap",
        "name_ro": "Set-ul {!setnr} - handicap puncte",
        "name_ru": "{!setnr} сет - гандикап очков",
        "name_tr": "{!setnr}. set - handikatp sayı",
        "name_se": "{!setnr} set - poäng handikapp",
        "name_el": "{!setnr} σετ - χάντικαπ πόντων"
    },
    "id_189": {
        "name_en": "Total games",
        "name_aa": "مجموع الألعاب",
        "name_de": "Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games",
        "name_pl": "Suma gemów",
        "name_ro": "Total game-uri",
        "name_ru": "Тотал геймов",
        "name_tr": "Maç sonu toplam oyun",
        "name_se": "Totalt antal games",
        "name_el": "Σύνολο γκέιμ"
    },
    "id_1129": {
        "name_en": "{!inningnr} innings - {%player1} + {%player2} total",
        "name_aa": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_de": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_nl": "{!inningnr} innings - {!player1} + {!player2} totaal",
        "name_pl": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_ro": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_ru": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_tr": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_se": "{!inningnr} innings - {!player1} + {!player2} total",
        "name_el": "{!inningnr} innings - {!player1} + {!player2} total"
    },
    "id_256": {
        "name_en": "Handicap (incl. extra innings)",
        "name_aa": "هانديكاب (بما في ذلك أدوار إضافية)",
        "name_de": "Handicap (inkl. Extra Innings)",
        "name_nl": "Handicap (extra innings inbegrepen)",
        "name_pl": "Handicap (włącznie z extra innings)",
        "name_ro": "Handicap (incl. inning-uri extra)",
        "name_ru": "Гандикап (включая дополнительные иннинги)",
        "name_tr": "Handikap (uzatmalar dahil)",
        "name_se": "Handikapp (inkl. extra innings)",
        "name_el": "Χάντικαπ (έξτρα innings συμπ.)"
    },
    "id_752": {
        "name_en": "{!mapnr} map - {!xth} shrine",
        "name_aa": "{!mapnr} خريطة - {!xth} المزار",
        "name_de": "{!mapnr} Map - {!xth} Shrine",
        "name_nl": "{mapnr} map - {xth} shrine",
        "name_pl": "{!mapnr} mapa - {!xth} shrine (świątynia)",
        "name_ro": "Harta {!mapnr} - {!xth} shrine",
        "name_ru": "{!mapnr} карта - {!xth} святыня",
        "name_tr": "{!mapnr}. harita - {!xth}. tapınak",
        "name_se": "{!mapnr} karta - {!xth} shrine",
        "name_el": "{!mapnr} map - {!xth} shrine"
    },
    "id_1609": {
        "name_en": "1x2 and Both team to score and {total} total",
        "name_aa": "1x2 and Both team to score and {total} total",
        "name_de": "1x2 and Both team to score and {total} total",
        "name_nl": "1x2 and Both team to score and {total} total",
        "name_pl": "1x2 and Both team to score and {total} total",
        "name_ro": "1x2 and Both team to score and {total} total",
        "name_ru": "1x2 and Both team to score and {total} total",
        "name_tr": "1x2 and Both team to score and {total} total",
        "name_se": "1x2 and Both team to score and {total} total",
        "name_el": "1x2 and Both team to score and {total} total"
    },
    "id_626": {
        "name_en": "{!mapnr} map round {roundnr} - total kills",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - إجمالي القتلى",
        "name_de": "{!mapnr} Map Runde {roundnr} - Total Kills",
        "name_nl": "{mapnr} map round {roundnr} - totaal aantal kills",
        "name_pl": "{!mapnr} mapa runda {roundnr} - suma zabójstw",
        "name_ro": "Harta {!mapnr} runda {roundnr} - total kills",
        "name_ru": "{!mapnr} карта {roundnr} раунд - тотал убийств",
        "name_tr": "{!mapnr}. map {roundnr}. raund - toplam kill",
        "name_se": "{!mapnr} kartrunda {roundnr} - totalt antal kills",
        "name_el": "{!mapnr} map, γύρος {roundnr} - total kills"
    },
    "id_570": {
        "name_en": "15 minutes - {$competitor2} total corners from {from} to {to}",
        "name_aa": "15 دقيقة - {$competitor2} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "15 Minuten - {$competitor2} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "15 minutes - totaal aantal hoekschoppen {$competitor2} van {from} tot {to}",
        "name_pl": "15 minut - {$competitor2} suma rzutów rożnych od {from} do {to}",
        "name_ro": "15 minute - {$competitor2} total cornere de la {from} la {to}",
        "name_ru": "15 минут - {$competitor2} всего угловых с {from} до {to}",
        "name_tr": "15 dakika - {$competitor2} toplam korner sayısı {from} den {to} a",
        "name_se": "15 minuter - {$competitor2} totalt antal hörnor från {from} till {to}",
        "name_el": "15 λεπτά - {$competitor2}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_269": {
        "name_en": "{$competitor1} to bat in 9th inning",
        "name_aa": "{$competitor1} أن يضرب في الدور التاسع",
        "name_de": "Wird {$competitor1} im 9. Inning geschlagen?",
        "name_nl": "{$competitor1} komt aan slag in de 9e inning",
        "name_pl": "{$competitor1} to bat w 9. inning",
        "name_ro": "{$competitor1} să lovească mingea în al 9-lea inning",
        "name_ru": "{$competitor1} отобьёт в 9-м иннинге",
        "name_tr": "{$competitor1}'ın 9. devredeki vuruşu",
        "name_se": "{$competitor1} att slå i 9:e inningen",
        "name_el": "{$competitor1} to bat στο 9ο inning"
    },
    "id_407": {
        "name_en": "{!goalnr} goal (incl. overtime and penalties)",
        "name_aa": "{!goalnr} الهدف (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{!goalnr} Tor (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{goalnr} doelpunt (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{!goalnr} gol (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Golul {!goalnr} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{!goalnr} гол (включая овертайм и пенальти)",
        "name_tr": "{!goalnr}. gol (uzatmalar ve penaltılar dahil)",
        "name_se": "{!goalnr} mål (inkl. övertid och straffar)",
        "name_el": "{!goalnr} γκολ (παράταση και πέναλτι συμπ.)"
    },
    "id_417": {
        "name_en": "{$competitor1} exact goals (incl. overtime and penalties)",
        "name_aa": "{$competitor1} أهداف دقيقة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor1} Genaue Anzahl Tore (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Exact aantal doelpunten {$competitor1} (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor1} dokładna liczba goli (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Goluri exact {$competitor1} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor1} точное число голов (включая овертайм и пенальти)",
        "name_tr": "{$competitor1} doğru skor (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor1} exakt antal mål (inkl. övertid och straffar)",
        "name_el": "{$competitor1} Ακριβής αριθμός γκολ  (παράταση και πέναλτι συμπ.)"
    },
    "id_1604": {
        "name_en": "Multiscore-4",
        "name_aa": "Multiscore-4",
        "name_de": "Multiscore-4",
        "name_nl": "Multiscore-4",
        "name_pl": "Multiscore-4",
        "name_ro": "Multiscore-4",
        "name_ru": "Multiscore-4",
        "name_tr": "Multiscore-4",
        "name_se": "Multiscore-4",
        "name_el": "Multiscore-4"
    },
    "id_1057": {
        "name_en": "{!quarternr} quarter - race to {pointnr} points",
        "name_aa": "السباق إلى - الربع {pointnr} نقط",
        "name_de": "{!quarternr} Viertel - Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "{quarternr} kwart - race naar {pointnr} punten",
        "name_pl": "{!quarternr} kwarta - kto pierwszy do {pointnr} punktów",
        "name_ro": "Sfertul {!quarternr} - cursa până la {pointnr} puncte",
        "name_ru": "{!quarternr} четверть - догон до {pointnr} очков",
        "name_tr": "{!quarternr} çeyrek - {pointnr} sayıya ilk ulaşacak olan",
        "name_se": "{!quarternr} quartern - först till {pointnr} poäng",
        "name_el": "{!quarternr} περίοδος - Ποιος θα φτάσει 1ος  στους {pointnr} πόντους"
    },
    "id_412": {
        "name_en": "Total (incl. overtime and penalties)",
        "name_aa": "المجموع (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Suma (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Total (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Тотал (включая овертайм и пенальти)",
        "name_tr": "Toplam gol (uzatmalar ve penaltılar dahil)",
        "name_se": "Totalt (inkl. övertid och straffar)",
        "name_el": "Σύνολο (παράταση και πέναλτι συμπ.)"
    },
    "id_79": {
        "name_en": "1st half - 1x2 & total",
        "name_aa": "الشوط الأول - 1x2 والإجمالي",
        "name_de": "1. Halbzeit- 1x2 & Total",
        "name_nl": "1e helft - 1x2 & totaal",
        "name_pl": "1. Połowa - 1x2 & Suma",
        "name_ro": "Prima repriză - 1x2 & total",
        "name_ru": "1ая половина - 1х2 и тотал",
        "name_tr": "ilk yarı - kazanan & gol sayısı",
        "name_se": "1:a halvlek - 1x2 & totalt",
        "name_el": "1ο ημίχρονο - 1Χ2 και σύνολο"
    },
    "id_589": {
        "name_en": "15 minutes - {$competitor1} sending off from {from} to {to}",
        "name_aa": "15 دقيقة - {$competitor1} طرد من {from} إلى {to}",
        "name_de": "15 Minuten - {$competitor1} Platzverweise from {from} to {to}",
        "name_nl": "15 minutes - uitsluiting {$competitor1} van {from} tot {to}",
        "name_pl": "15 minut - {$competitor1} czerwona kartka od {from} do {to}",
        "name_ro": "15 minute - {$competitor1} eliminare de la {from} la {to}",
        "name_ru": "15 минут - {$competitor1} удаление с {from} до {to}",
        "name_tr": "15 dakika - {$competitor1} oyundan atılma {from} dan {to} a",
        "name_se": "15 minuter - {$competitor1} utvisning från {from} till {to}",
        "name_el": "15 λεπτά - {$competitor1}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_352": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total",
        "name_aa": "{!inningnr} أدوار المبالغ 0 إلى {overnr} - {$competitor1} المجموع",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor1} Total",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - totaal {$competitor1}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor1} suma",
        "name_ro": "{!inningnr} innings overs de la 0 la {overnr} - total {$competitor1}",
        "name_ru": "{!inningnr} иннинг оверы от 0 до {overnr} - {$competitor1} тотал",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor1} toplam",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor1} totalt",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} σύνολο"
    },
    "id_671": {
        "name_en": "{!inningnr} innings - {$competitor2} total in the highest scoring over",
        "name_aa": "{!inningnr} أدوار - {$competitor2} الإجمالي في أعلى الدرجات",
        "name_de": "{!inningnr} Inning - {$competitor2} Total im Over mit dem höchsten Score",
        "name_nl": "{inningnr} innings - {$competitor2} totaal in de over met de hoogste score",
        "name_pl": "{!inningnr} innings - {$competitor2} suma w najwyżej punktującym over",
        "name_ro": "{!inningnr} innings - {$competitor2} total în over-ul cu cel mai bun scor",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал в самом результативном овере",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplamda en yüksek sayı yapan",
        "name_se": "{!inningnr} innings - {$competitor2} totalt över högst poäng",
        "name_el": "{!inningnr} innings - {$competitor2} total in the highest scoring over"
    },
    "id_885": {
        "name_en": "1 minute - total bookings from {from} to {to}",
        "name_aa": "دقيقة واحدة - إجمالي الحجوزات من {from} إلى {to}",
        "name_de": "1 Minute - insgesamte Karten von {from} bis {to}",
        "name_nl": "1 minuut - totaal aantal kaarten van {from} tot {to}",
        "name_pl": "1 minute - total bookings from {from} to {to}",
        "name_ro": "1 minut - total cartonașe de la {from} la {to}",
        "name_ru": "1 минута - итого карт с {from} по {to}",
        "name_tr": "1 dakika - toplam rezervasyon {from}'dan {to}'a kadar",
        "name_se": "1 minuter - totalt antal kort från {from} till {to}",
        "name_el": "1 λεπτό - σύνολο κάρτες από {from} μέχρι {to}"
    },
    "id_473": {
        "name_en": "Try 1x2",
        "name_aa": "حاول 1X2",
        "name_de": "Versuch 1x2",
        "name_nl": "Try 1x2",
        "name_pl": "Przyłożenie 1x2",
        "name_ro": "Încercări 1x2",
        "name_ru": "Попытка 1x2",
        "name_tr": "Try 1x2",
        "name_se": "Försök 1z2",
        "name_el": "Try 1X2"
    },
    "id_273": {
        "name_en": "Exact runs in highest scoring inning",
        "name_aa": "يدير بالضبط في أعلى سجل التهديف",
        "name_de": "Genaue Anzahl der Läufe im Inning mit den meisten Punkten",
        "name_nl": "Exact aantal runs in de inning met de hoogste score",
        "name_pl": "Dokładna liczba runs w najwyżej punktowanym inning",
        "name_ro": "Run-uri exact în inning-ul cu cele mai multe înscrieri",
        "name_ru": "Точное число пробежек в самом результативном иннинге",
        "name_tr": "En yüksek skorlu devredeki koşu sayısı",
        "name_se": "Exakt antal runs i inning med flest poäng",
        "name_el": "Ακριβές αριθμός runs στο inning με υψηλότερο σκορ"
    },
    "id_372": {
        "name_en": "{!setnr} set - total legs",
        "name_aa": "مجموعة {!setnr} - مجموع الساقين",
        "name_de": "{!setnr} Satz - Total Runden",
        "name_nl": "{setnr} set - totaal legs",
        "name_pl": "{!setnr} set - suma legów",
        "name_ro": "Set {!setnr} - total legs",
        "name_ru": "{!setnr} сет - тотал этапов",
        "name_tr": "{!setnr}. set - toplam ayak",
        "name_se": "{!setnr} set - totalt antal legs",
        "name_el": "{!setnr} set - σύνολο legs"
    },
    "id_683": {
        "name_en": "Top batter",
        "name_aa": "الخليط العلوي",
        "name_de": "Bester Batter",
        "name_nl": "Beste slagman",
        "name_pl": "Najlepszy batter",
        "name_ro": "Top batter",
        "name_ru": "Лучший бэттер",
        "name_tr": "En iyi vurucu",
        "name_se": "Bästa slagman",
        "name_el": "Top batter"
    },
    "id_399": {
        "name_en": "{$competitor1} goal range",
        "name_aa": "{$competitor1} نطاق الأهداف",
        "name_de": "{$competitor1} Tordifferenz",
        "name_nl": "doelpunten range {$competitor1}",
        "name_pl": "{$competitor1} zakres liczby goli",
        "name_ro": "Interval goluri {$competitor1}",
        "name_ru": "{$competitor1} диапазон голов",
        "name_tr": "{$competitor1} gol aralığı",
        "name_se": "{$competitor1} målrad",
        "name_el": "{$competitor1} Εύρος των γκολ"
    },
    "id_659": {
        "name_en": "{!inningnr} innings - {$competitor2} total fours",
        "name_aa": "{!inningnr} أدوار - {$competitor2} مجموع أربعات",
        "name_de": "{!inningnr} Inning - {$competitor2} Fours gesamt",
        "name_nl": "{inningnr} innings - totaal aantal fours {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma czwórek",
        "name_ro": "{!inningnr} innings - {$competitor2} total fours",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал 4 ранов",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam fours",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal fours",
        "name_el": "{!inningnr} innings - {$competitor2} total fours"
    },
    "id_8": {
        "name_en": "({!goalnr}) Next Goal",
        "name_aa": "({!goalnr}) الهدف",
        "name_de": "({!goalnr}) Nächstes Tor",
        "name_nl": "({!goalnr}) Volgende Goal",
        "name_pl": "({!goalnr}) gol",
        "name_ro": "Golul ({!goalnr})",
        "name_ru": "({!goalnr}) гол",
        "name_tr": "({!goalnr}) Gelecek Gol",
        "name_se": "({!goalnr}) mål",
        "name_el": "({!goalnr}) γκολ"
    },
    "id_454": {
        "name_en": "{!periodnr} period - {$competitor1} clean sheet",
        "name_aa": "فترة {!periodnr} - {$competitor1} شباكه نظيفة",
        "name_de": "{!periodnr} Periode - {$competitor1} ohne Gegentreffer",
        "name_nl": "{periodnr} periode - {$competitor1} houdt doel schoon",
        "name_pl": "{!periodnr} część gry - {$competitor1} czyste konto",
        "name_ro": "Repriza {!periodnr} - {$competitor1} fără gol primit",
        "name_ru": "{!periodnr} период - {$competitor1} не пропустит",
        "name_tr": "{!periodnr}. periyot - {$competitor1} gol yemez",
        "name_se": "{!periodnr} period - {$competitor1} håller nollan",
        "name_el": "{!periodnr} περίοδος - {$competitor1} να μη δεχτεί γκολ"
    },
    "id_886": {
        "name_en": "1 minute - total offsides from {from} to {to}",
        "name_aa": "دقيقة واحدة - إجمالي الإزاحات من {from} إلى {to}",
        "name_de": "1 Minute - insgesamte Abseits von {from} bis {to}",
        "name_nl": "1 minuut - totaal aantal buitenspelgevallen van {from} tot {to}",
        "name_pl": "1 minute - total offsides from {from} to {to}",
        "name_ro": "1 minut - total ofsaiduri de la {from} la {to}",
        "name_ru": "1 минута - итого офсайдов {from} по {to}",
        "name_tr": "1 dakika - toplam ofsaytlar {from}'dan {to}'a kadar",
        "name_se": "1 minuter - totalt antal offsides från {from} till {to}",
        "name_el": "1 λεπτό - σύνολο οφσάιντ από {from} μέχρι {to}"
    },
    "id_496": {
        "name_en": "Race to {framenr} frames",
        "name_aa": "سباق إلى {framenr} إطارات",
        "name_de": "Wer erreicht zuerst {framenr} Frames?",
        "name_nl": "Race naar {framenr} frames",
        "name_pl": "Kto pierwszy z {framenr} frejmami",
        "name_ro": "Primul la {framenr} frame-uri",
        "name_ru": "Гонка до {framenr} фреймов",
        "name_tr": "{framenr}. frame çekişmesi",
        "name_se": "Först till {framenr} frames",
        "name_el": "Ποιος θα φτάσει 1ος τα {framenr} frames"
    },
    "id_303": {
        "name_en": "{!quarternr} quarter - handicap",
        "name_aa": "الربع {!quarternr} - هانديكاب",
        "name_de": "{!quarternr} Viertel - Handicap",
        "name_nl": "{quarternr} kwart - handicap",
        "name_pl": "{!quarternr} kwarta - handicap",
        "name_ro": "Sfertul {!quarternr} - handicap",
        "name_ru": "{!quarternr} четверть - гандикап",
        "name_tr": "{!quarternr} çeyrek - handikap",
        "name_se": "{!quarternr} quarter - handikapp",
        "name_el": "{!quarternr} περίοδος - χάντικαπ"
    },
    "id_751": {
        "name_en": "{!inningnr} inning - both teams to score",
        "name_aa": "{!inningnr} الشوط - كلا الفريقين يسجيل",
        "name_de": "{!inningnr} Inning - Beide Mannschaften treffen",
        "name_nl": "{inningnr} inning - beide teams scoren",
        "name_pl": "{!inningnr} inning - oba zespoły punktują",
        "name_ro": "{!inningnr} inning - ambele echipe să înscrie",
        "name_ru": "{!inningnr} иннинг - обе команды забьют",
        "name_tr": "{!inningnr}. devre - her iki takımda sayı yapar",
        "name_se": "{!inningnr} inning - båda lagen tar poäng",
        "name_el": "{!inningnr} inning - και οι 2 να σκοράρουν"
    },
    "id_66": {
        "name_en": "1st half - handicap",
        "name_aa": "النصف الأول - هانديكاب",
        "name_de": "1. Halbzeit - Handicap",
        "name_nl": "1e helft - handicap",
        "name_pl": "1. Połowa - handicap",
        "name_ro": "Prima repriză - handicap",
        "name_ru": "1ая половина - гандикап",
        "name_tr": "İlk Yarı - handikap",
        "name_se": "1:a halvlek - handikapp",
        "name_el": "1ο ημίχρονο -  χάντικαπ"
    },
    "id_268": {
        "name_en": "Will there be an extra inning",
        "name_aa": "سوف يكون هناك شوط إضافي",
        "name_de": "Wird es ein Extra-Inning geben?",
        "name_nl": "Zal er een extra inning nodig zijn",
        "name_pl": "Czy będzie extra inning?",
        "name_ro": "Va fi un extra inning",
        "name_ru": "Будет ли дополнительный иннинг",
        "name_tr": "Uzatma devresi olucak mı",
        "name_se": "Att gå till extra innings",
        "name_el": "Θα υπάρξει έξτρα inning"
    },
    "id_803": {
        "name_en": "{$event} matchday {matchday} - total away team wins",
        "name_aa": "{$event} {matchday} - مجموع بعيدا انتصارات الفريق",
        "name_de": "{$event} Spieltag {matchday} - Siege Auswärtsmannschaften Gesamt",
        "name_nl": "{$event} matchday {matchday} - totaal aantal overwinningen uitspelende ploeg",
        "name_pl": "{$event} matchday {matchday} - suma zwycięstw goście",
        "name_ro": "{$event} etapă {matchday} - total victorii oaspeți",
        "name_ru": "{$event} игровой день {matchday} - итого победы гостей",
        "name_tr": "{$event} maç günü {matchday} - toplam deplasman takımı kazanma sayısı",
        "name_se": "{$event} - bortalagets totalt vinster",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο - να κερδίσει φιλοξενούμενη"
    },
    "id_170": {
        "name_en": "{$competitor1} corner range",
        "name_aa": "{$competitor1} نطاق الزاوية",
        "name_de": "{$competitor1} Anzahlbereich Ecken",
        "name_nl": "Hoekschoppen range {$competitor1}",
        "name_pl": "{$competitor1} zakres liczby rzutów rożnych",
        "name_ro": "Interval cornere {$competitor1}",
        "name_ru": "Количество угловых {$competitor1}",
        "name_tr": "{$competitor1} korner aralığı",
        "name_se": "{$competitor1} Hörnintervall",
        "name_el": "{$competitor1}  Εύρος των κόρνερ"
    },
    "id_890": {
        "name_en": "{!goalnr} goalscorer & correct score",
        "name_aa": "{!goalnr} هداف والنتيجة الصحيحة",
        "name_de": "{!goalnr} Torschütze & richtiger Punktestand",
        "name_nl": "{!goalnr} doelpuntenmaker & juiste uitslag",
        "name_pl": "{!goalnr} strzelec gola & dokładny wynik",
        "name_ro": "Marcator {!goalnr} & scor corect",
        "name_ru": "{!goalnr} бомбардир и точный счёт",
        "name_tr": "{!goalnr} golü atan & doğru skor",
        "name_se": "{!goalnr} målgörare & korrekt resultat",
        "name_el": "{!goalnr} σκόρερ και ακριβές σκορ"
    },
    "id_910": {
        "name_en": "Winning method",
        "name_aa": "Winning method",
        "name_de": "Winning method",
        "name_nl": "Winstmethode",
        "name_pl": "Winning method",
        "name_ro": "Winning method",
        "name_ru": "Метод победы",
        "name_tr": "Winning method",
        "name_se": "Vinstmetod",
        "name_el": "Μέθοδος Νίκης"
    },
    "id_1612": {
        "name_en": "1x2 in 5 minutes",
        "name_aa": "1x2 in 5 minutes",
        "name_de": "1x2 in 5 minutes",
        "name_nl": "1x2 in 5 minutes",
        "name_pl": "1x2 in 5 minutes",
        "name_ro": "1x2 in 5 minutes",
        "name_ru": "1x2 in 5 minutes",
        "name_tr": "1x2 in 5 minutes",
        "name_se": "1x2 in 5 minutes",
        "name_el": "1x2 in 5 minutes"
    },
    "id_509": {
        "name_en": "{!framenr} frame - break 50+",
        "name_aa": "إطار {!framenr}- استراحة 50+",
        "name_de": "{!framenr} Frame - Break 50+",
        "name_nl": "{framenr} frame - break 50+",
        "name_pl": "{!framenr} frejm - brejk 50+",
        "name_ro": "Frame-ul {!framenr} - break 50+",
        "name_ru": "{!framenr} фрейм - брейк 50+",
        "name_tr": "{!framenr}. frame -  50+ break",
        "name_se": "{!framenr} frame - break 50+",
        "name_el": "{!framenr} frame - μπρέικ 50+"
    },
    "id_108": {
        "name_en": "5 minutes - 1x2 from {from} to {to}",
        "name_aa": "5 دقائق - 1 × 2 من {from} إلى {to}",
        "name_de": "5 Minuten - 1x2 von{from} bis {to}",
        "name_nl": "5 minuten - 1x2 van {from} tot {to}",
        "name_pl": "5 minut - 1x2 od {from} do {to}",
        "name_ro": "5 minute - 1x2 de la {from} la {to}",
        "name_ru": "5 минут - 1х2 от {from} до {to}",
        "name_tr": "5 dakika - 1x2 {from}'dan {to}'a kadar",
        "name_se": "5 minuter - 1x2 från {from} till {to}",
        "name_el": "5 λεπτά - 1Χ2 από  {from} μέχρι {to}"
    },
    "id_301": {
        "name_en": "{!quarternr} quarter - winning margin",
        "name_aa": "{!quarternr} الربع - الحائز على هامش",
        "name_de": "{!quarternr} Viertel - Gewinnspanne",
        "name_nl": "{quarternr} kwart - winstmarge",
        "name_pl": "{!quarternr} kwarta - margines zwycięstwa",
        "name_ro": "Sfertul {!quarternr} - diferență victorie",
        "name_ru": "{!quarternr} четверть - победа с разницей",
        "name_tr": "{!quarternr} çeyrek - Kazanma farkı",
        "name_se": "{!quarternr} quarter - vinstmarginal",
        "name_el": "{!quarternr} περίοδος - περιθώριο νίκης"
    },
    "id_437": {
        "name_en": "{$competitor1} to win any period",
        "name_aa": "{$competitor1} للفوز بأي فترة",
        "name_de": "{$competitor1} wird irgendeine Periode gewinnen",
        "name_nl": "{$competitor1} wint een periode",
        "name_pl": "{$competitor1} wygra którąkolwiek część gry",
        "name_ro": "{$competitor1} să câștige oricare repriză",
        "name_ru": "{$competitor1} выиграть любой период",
        "name_tr": "{$competitor1} herhangi bir periyodu kazanır",
        "name_se": "{$competitor1} att vinna någon period",
        "name_el": "{$competitor1} να κερδίσει οποιαδήποτε περίοδο"
    },
    "id_323": {
        "name_en": "{!setnr} set end {endnr} - total",
        "name_aa": "{!setnr}  وضع نهاية {endnr} - مجموعه",
        "name_de": "{!setnr} Satzende {endnr} - Total",
        "name_nl": "{setnr} set end {endnr} - totaal",
        "name_pl": "{!setnr} set koniec {endnr} - suma",
        "name_ro": "Set-ul {!setnr} end-ul {endnr} - total",
        "name_ru": "{!setnr} сет {endnr} конец - тотал",
        "name_tr": "{!setnr}. set {endnr}. end - toplam",
        "name_se": "{!setnr} set slut {endnr} - totalt",
        "name_el": "{!setnr} set end {endnr} - Σύνολο"
    },
    "id_194": {
        "name_en": "Any set to nil",
        "name_aa": "أي مجموعة من الصفر",
        "name_de": "Jeder Satz zu null",
        "name_nl": "Een set zonder game tegen",
        "name_pl": "Którykolwiek set do zera",
        "name_ro": "Orice set la zero",
        "name_ru": "Любой сет к нолю",
        "name_tr": "Sıfıra karşı herhangi set",
        "name_se": "Vilket set som helst mot noll",
        "name_el": "Οποιοδήποτε σετ χωρίς να χάσει πόντο"
    },
    "id_1020": {
        "name_en": "Total 2+ over par",
        "name_aa": "Total 2+ over par",
        "name_de": "Total 2+ over par",
        "name_nl": "Totaal 2+ boven par",
        "name_pl": "Total 2+ over par",
        "name_ro": "Total 2+ over par",
        "name_ru": "Total 2+ over par",
        "name_tr": "Total 2+ over par",
        "name_se": "Total 2+ over par",
        "name_el": "Total 2+ over par"
    },
    "id_628": {
        "name_en": "{!mapnr} map round {roundnr} - {!killnr} kill",
        "name_aa": "{!mapnr} جولة مستديرة {roundnr} - {!killnr} قتل",
        "name_de": "{!mapnr} Map Runde {roundnr} - {!killnr} Kill",
        "name_nl": "{mapnr} map round {roundnr} - {killnr} kill",
        "name_pl": "{!mapnr} mapa runda {roundnr} - {!killnr} zabójstwo",
        "name_ro": "Harta {!mapnr} runda {roundnr} - kill {!killnr}",
        "name_ru": "{!mapnr} карта {roundnr} раунд - {!killnr} убийств",
        "name_tr": "{!mapnr}. map {roundnr}. raund - {!killnr}. kill",
        "name_se": "{!mapnr} kartrunda {roundnr} - {!killnr} kill",
        "name_el": "{!mapnr} map, γύρος {roundnr} - {!killnr} kill"
    },
    "id_115": {
        "name_en": "Overtime - {!goalnr} goal",
        "name_aa": "الوقت الإضافي - {!goalnr} هدف",
        "name_de": "Verlängerung - {!goalnr} Tor",
        "name_nl": "Verlenging - {goalnr} doelpunt",
        "name_pl": "Dogrywka - {!goalnr} gol",
        "name_ro": "Prelungiri - golul {!goalnr}",
        "name_ru": "Овертайм - {!goalnr} гол",
        "name_tr": "Uzatma - {!goalnr} gol",
        "name_se": "Övertid - {!goalnr} mål",
        "name_el": "Παράταση - {!goalnr} γκολ"
    },
    "id_342": {
        "name_en": "Will there be a tie",
        "name_aa": "سوف يكون هناك ربطة عنق",
        "name_de": "Wird es ein Gleichstand geben?",
        "name_nl": "Wordt het een gelijkspel",
        "name_pl": "Czy będzie remis?",
        "name_ro": "Va fi egalitate",
        "name_ru": "Будет ли ничья",
        "name_tr": "Tie olur mu",
        "name_se": "Blir det tie",
        "name_el": "Θα υπάρξει tie"
    },
    "id_258": {
        "name_en": "Total (incl. extra innings)",
        "name_aa": "مجموع (بما في ذلك أدوار إضافية)",
        "name_de": "Total (inkl. Extra Innings)",
        "name_nl": "Totaal (extra innings inbegrepen)",
        "name_pl": "Suma (włącznie z extra innings)",
        "name_ro": "Total (incl. inning-uri extra)",
        "name_ru": "Тотал (включая дополнительные иннинги)",
        "name_tr": "Toplam (uzatmalar dahil)",
        "name_se": "Totalt (inkl. extra innings)",
        "name_el": "Σύνολο (έξτρα innings συμπ.)"
    },
    "id_422": {
        "name_en": "{$competitor2} clean sheet (incl. overtime and penalties)",
        "name_aa": "{$competitor2} ورقة نظيفة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor2} ohne Gegentreffer (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{$competitor2} houdt doel schoon (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor2} czyste konto (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "{$competitor2} fără gol primit (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor2} не пропустит (включая овертайм и пенальти)",
        "name_tr": "{$competitor2} gol yemeden tamamlar (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor2} håller nollan (inkl. övertid och straffar)",
        "name_el": "{$competitor2} (παράταση και πέναλτι συμπ.)"
    },
    "id_640": {
        "name_en": "Total sixes",
        "name_aa": "مجموع الستات",
        "name_de": "Sixes Gesamt",
        "name_nl": "Totaal aantal sixes",
        "name_pl": "Suma szóstek",
        "name_ro": "Total sixes",
        "name_ru": "Тотал шести ранов",
        "name_tr": "Toplam sixes",
        "name_se": "Totalt antal sixes",
        "name_el": "Total sixes"
    },
    "id_932": {
        "name_en": "Total field goals made (incl. overtime)",
        "name_aa": "Total field goals made (incl. overtime)",
        "name_de": "Field Goals Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal veldgoals (incl. verlenging)",
        "name_pl": "Total field goals made (incl. overtime)",
        "name_ro": "Total field goals made (incl. overtime)",
        "name_ru": "Тотал голов с игры (включая овертайм)",
        "name_tr": "Total field goals made (incl. overtime)",
        "name_se": "Totalt antal field goals (inkl. övertid)",
        "name_el": "Σύνολο touchdowns -εύστοχα -  (παράταση συμπ.)"
    },
    "id_1071": {
        "name_en": "{!inningnr} inning - most balls",
        "name_aa": "{!inningnr} inning - most balls",
        "name_de": "{!inningnr} inning - most balls",
        "name_nl": "{inningnr} inning - meeste wijdballen",
        "name_pl": "{!inningnr} inning - most balls",
        "name_ro": "{!inningnr} inning - most balls",
        "name_ru": "{!inningnr} иннинг - наибольшее число мячей",
        "name_tr": "{!inningnr} inning - most balls",
        "name_se": "{!inningnr} inning - most balls",
        "name_el": "{!inningnr} inning - περισσότερα  balls"
    },
    "id_503": {
        "name_en": "{!framenr} frame - race to {pointnr} points",
        "name_aa": "إطار {framenr!} - سباق إلى نقاط {pointnr}",
        "name_de": "{!framenr} Frame - Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "{framenr} frame - race naar {pointnr} punten",
        "name_pl": "{!framenr} frejm - kto pierwszy do {pointnr} punktów",
        "name_ro": "Frame-ul {!framenr} - primul la {pointnr} puncte",
        "name_ru": "{!framenr} фрейм - гонка до {pointnr} очков",
        "name_tr": "{!framenr}. frame - {pointnr}. sayı çekişmesi",
        "name_se": "{!framenr} frame - först till {pointnr} poäng",
        "name_el": "{!framenr} frame - Ποιος θα φτάσει 1ος  στους  {pointnr}  πόντους"
    },
    "id_311": {
        "name_en": "{!setnr} set - odd/even",
        "name_aa": "{!setnr}  مجموعة - فردي / زوجي",
        "name_de": "{!setnr} Satz - Gerade/ungerade",
        "name_nl": "{setnr} set - even/oneven",
        "name_pl": "{!setnr} set - Parzyste/Nieparzyste",
        "name_ro": "Set-ul {!setnr} - impar/par",
        "name_ru": "{!setnr} сет - чет/нечет",
        "name_tr": "{!setnr}. set - tek/çift",
        "name_se": "{!setnr} set - udda/jämnt",
        "name_el": "{!setnr} σετ - μονά/ζυγά"
    },
    "id_131": {
        "name_en": "Penalty shootout - odd/even",
        "name_aa": "ركلات الترجيح -فردي/ زوجي",
        "name_de": "Elfmeterschießen - Gerade/ungerade",
        "name_nl": "Strafschoppenserie - even/oneven",
        "name_pl": "Rzuty karne - Parzyste/Nieparzyste",
        "name_ro": "Lovituri de departajare - impar/par",
        "name_ru": "Серия пенальти - чет/нечет",
        "name_tr": "Penaltı atışları - tek/çift",
        "name_se": "Straffar - udda/jämnt",
        "name_el": "Διαδικασία πέναλτι - Μονά/Ζυγά"
    },
    "id_419": {
        "name_en": "Odd/even (incl. overtime and penalties)",
        "name_aa": "فردي / زوجي (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Gerade/ungerade (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Even/oneven (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Parzyste/Nieparzyste (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Impar/par (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Чет/Нечет (включая овертайм и пенальти)",
        "name_tr": "tek/çift (uzatmalar ve penaltılar dahil)",
        "name_se": "Udda/jämnt (inkl. övertid och straffar)",
        "name_el": "Μονά/Ζυγά (παράταση και πέναλτι συμπ.)"
    },
    "id_48": {
        "name_en": "{$competitor1} to win both halves",
        "name_aa": "{$competitor1}للفوز كل شوط",
        "name_de": "{$competitor1} wird beide Hälften gewinnen",
        "name_nl": "{$competitor1} wint beide helften",
        "name_pl": "{$competitor1} wygra obie połowy",
        "name_ro": "{$competitor1} să câștige ambele reprize",
        "name_ru": "{$competitor1} выиграет обе половины",
        "name_tr": "{$competitor1} her iki devreyi de kazanma",
        "name_se": "{$competitor1} vinna båda halvlekarna",
        "name_el": "{$competitor1} να κερδίσει και στα 2 ημίχρονα"
    },
    "id_142": {
        "name_en": "Exact bookings",
        "name_aa": "الحجوزات بالضبط",
        "name_de": "Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten",
        "name_pl": "Dokładna liczba kartek",
        "name_ro": "Cartonașe exact",
        "name_ru": "Точное число карточек",
        "name_tr": "Doğru kart sayısı",
        "name_se": "Exakta antal kort",
        "name_el": "Ακριβής αριθμός κάρτες"
    },
    "id_9": {
        "name_en": "Last goal",
        "name_aa": "الهدف الأخير",
        "name_de": "letztes Tor",
        "name_nl": "Laatste doelpunt",
        "name_pl": "Ostatni gol",
        "name_ro": "Ultimul gol",
        "name_ru": "Последний гол",
        "name_tr": "Son gol",
        "name_se": "Sista målet",
        "name_el": "Τελευταίο γκολ"
    },
    "id_632": {
        "name_en": "{!mapnr} map round {roundnr} - bomb defused",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - تم تفكيك القنبلة",
        "name_de": "{!mapnr} Map Runde {roundnr} - Bombe entschärft",
        "name_nl": "{mapnr} map round {roundnr} - bom ontmanteld",
        "name_pl": "{!mapnr} mapa runda {roundnr} - bomby rozbrojone",
        "name_ro": "Harta {!mapnr} runda {roundnr} - bomb defused",
        "name_ru": "{!mapnr} карта {roundnr} раунд - бомб разряжено",
        "name_tr": "{!mapnr}. map {roundnr}. raund - bomba etkisiz hale getirildi",
        "name_se": "{!mapnr} kartrunda {roundnr} - bomb desarmerad",
        "name_el": "{!mapnr} map, γύρος {roundnr} - bomb defused"
    },
    "id_1008": {
        "name_en": "{%competitor} total strokes",
        "name_aa": "{!competitor} total strokes",
        "name_de": "{!competitor} Strokes Gesamt",
        "name_nl": "totaal aantal slagen {!competitor}",
        "name_pl": "{!competitor} total strokes",
        "name_ro": "{!competitor} total strokes",
        "name_ru": "{!competitor} total strokes",
        "name_tr": "{!competitor} total strokes",
        "name_se": "{!competitor} total strokes",
        "name_el": "{!competitor} total strokes"
    },
    "id_118": {
        "name_en": "Overtime - correct score [{score}]",
        "name_aa": "الوقت الإضافي - النتيجة الصحيحة [{score}]",
        "name_de": "Verlängerung - Genaues Ergebnis [{score}]",
        "name_nl": "Verlenging - juiste uitslag [{score}]",
        "name_pl": "Dogrywka - dokładny wynik [{score}]",
        "name_ro": "Prelungiri - scor corect [{score}]",
        "name_ru": "Овертайм - точный счет [{score}]",
        "name_tr": "Uzatma - Doğru skor [{score}]",
        "name_se": "Övertid - rätt resultat [{score}]",
        "name_el": "Παράταση - Ακριβές σκορ [{score}]"
    },
    "id_1026": {
        "name_en": "Hole {holenr} - {%competitor} under par",
        "name_aa": "Hole {holenr} - {!competitor} under par",
        "name_de": "Hole {holenr} - {!competitor} under par",
        "name_nl": "Hole {holenr} - {!competitor} onder par",
        "name_pl": "Hole {holenr} - {!competitor} under par",
        "name_ro": "Hole {holenr} - {!competitor} under par",
        "name_ru": "Hole {holenr} - {!competitor} under par",
        "name_tr": "Hole {holenr} - {!competitor} under par",
        "name_se": "Hole {holenr} - {!competitor} under par",
        "name_el": "Hole {holenr} - {!competitor} under par"
    },
    "id_795": {
        "name_en": "Player run meters",
        "name_aa": "تشغيل متر متر",
        "name_de": "Spieler-Läufe Meter",
        "name_nl": "Speler run meters",
        "name_pl": "Player run meters",
        "name_ro": "Metri run jucător",
        "name_ru": "Игрок раны в метрах",
        "name_tr": "Oyuncu metre koşar",
        "name_se": "Spelaren runmeter",
        "name_el": "Παίχτης run meters"
    },
    "id_59": {
        "name_en": "Both halves under {total}",
        "name_aa": "كلا النصفين تحت {total}",
        "name_de": "Beide Hälften unter {total}",
        "name_nl": "Beide helften minder dan {total}",
        "name_pl": "Obie połowy poniżej {total}",
        "name_ro": "Ambele reprize sub {total}",
        "name_ru": "Обе половины меньше {total}",
        "name_tr": "Her iki yarı {total} altı",
        "name_se": "Båda halvlekarna under {total}",
        "name_el": "Και τα δύο ημίχρονα under {total}"
    },
    "id_699": {
        "name_en": "Team with top bowler",
        "name_aa": "فريق مع الرامى العلوي",
        "name_de": "Mannschaft mit dem besten Bowler",
        "name_nl": "Team met de beste werper",
        "name_pl": "Drużyna z najlepszym bowler",
        "name_ro": "Echipa cu top bowler",
        "name_ru": "Команда с лучшим боулером",
        "name_tr": "En iyi atıcının olduğu takım",
        "name_se": "Lag med bästa bowler",
        "name_el": "Team with top bowler"
    },
    "id_819": {
        "name_en": "Halftime/fulltime & 1st half total",
        "name_aa": "نصف الوقت / بدوام كامل و النصف الأول",
        "name_de": "Halbzeit/reg. Spielzeit & Total 1. Hälfte",
        "name_nl": "Ruststand/eindstand & eerste helft totaal",
        "name_pl": "Połowa/Reg. czas gry & 1. połowa suma",
        "name_ro": "Pauză/final & total repriza 1",
        "name_ru": "После первого тайма/общее время и 1-й тайм итог",
        "name_tr": "İlkyarı/maç sonucu & birinci devre toplam",
        "name_se": "Halvtid/fulltid & 1:a halvlek - totalt",
        "name_el": "Ημίχρονο-Τελικό και Σύνολο 1ο ημίχρονο"
    },
    "id_774": {
        "name_en": "Player 3-point field goals (incl. overtime)",
        "name_aa": "لاعب مجال أهداف 3 نقاط",
        "name_de": "Spieler 3-Punkt Field Goals (inkl. Verlängerung)",
        "name_nl": "Speler 3-punt veldgoals",
        "name_pl": "Player 3-point field goals (incl. overtime)",
        "name_ro": "Field goluri de 3 puncte jucător",
        "name_ru": "Игрок забивший больше 3-очковых голов с поля",
        "name_tr": "Oyuncu 3-puan saha golleri",
        "name_se": "Spelar 3-poängs field goal",
        "name_el": "Παίχτης 3 πόντα"
    },
    "id_261": {
        "name_en": "{$competitor2} total (incl. extra innings)",
        "name_aa": "{$competitor2} مجموعه (بما في ذلك أدوار إضافية)",
        "name_de": "{$competitor2} Total (inkl. Extra Innings)",
        "name_nl": "{$competitor2} totaal (extra innings inbegrepen)",
        "name_pl": "{$competitor2} suma (włącznie z extra innings)",
        "name_ro": "Total (incl. inning-uri extra) {$competitor2}",
        "name_ru": "{$competitor2} тотал (включая дополнительные иннинги)",
        "name_tr": "{$competitor2} toplam (uzatmalar dahil)",
        "name_se": "{$competitor2} totalt (inkl. extra innings)",
        "name_el": "σύνολο {$competitor2} (έξτρα innings συμπ.)"
    },
    "id_222": {
        "name_en": "{!scorenr} scoring type (incl. overtime)",
        "name_aa": "{!scorenr} نوع التسجيل (بما في ذلك العمل الإضافي)",
        "name_de": "{!scorenr} Score-Typ (inkl. Verlängerung)",
        "name_nl": "{scorenr} type score (verlenging inbegrepen)",
        "name_pl": "{!scorenr} typ wyniku (włącznie z dogrywką)",
        "name_ro": "Metoda de înscriere {!scorenr} (incl. prelungiri)",
        "name_ru": "{!scorenr} тип счета (включая овертайм)",
        "name_tr": "{!scorenr}. sayı yapma şekli (uzatmalar dahil)",
        "name_se": "{!scorenr} resultat typ (inkl. övertid)",
        "name_el": "{!scorenr} τύπος σκορ (παράταση συμπ.)"
    },
    "id_264": {
        "name_en": "Odd/even (incl. extra innings)",
        "name_aa": "فردي / زوجي(بما في ذلك أدوار إضافية)",
        "name_de": "Gerade/ungerade (inkl. Extra Innings)",
        "name_nl": "Even/oneven (extra innings inbegrepen)",
        "name_pl": "Parzyste/Nieparzyste (włącznie z extra innings)",
        "name_ro": "Impar/par (incl. inning-uri extra)",
        "name_ru": "Чет/Нечет (включая дополнительные иннинги)",
        "name_tr": "Tek/çift (uzatmalar dahil)",
        "name_se": "Udda/jämnt (inkl. extra innings)",
        "name_el": "Μονά/Ζυγά (έξτρα innings συμπ.)"
    },
    "id_106": {
        "name_en": "10 minutes - {!goalnr} goal from {from} to {to}",
        "name_aa": "10 دقائق - {!goalnr} هدف من {from} إلى {to}",
        "name_de": "10 Minuten - {!goalnr} Tor von {from} bis {to}",
        "name_nl": "10 minuten - {goalnr} doelpunt van {from} tot {to}",
        "name_pl": "10 minut - {!goalnr} gol od {from} do {to}",
        "name_ro": "10 minute - golul {!goalnr} de la {from} la {to}",
        "name_ru": "10 минут - {!goalnr} гол от {from} до {to}",
        "name_tr": "10 dakika - {!goalnr} gol {from} den {to} a",
        "name_se": "10 minuter - {!goalnr} mål från {from} till {to}",
        "name_el": "10 λεπτά -  {!goalnr} γκολ από {from} μέχρι {to}"
    },
    "id_1615": {
        "name_en": "1x2 in 20 minutes",
        "name_aa": "1x2 in 20 minutes",
        "name_de": "1x2 in 20 minutes",
        "name_nl": "1x2 in 20 minutes",
        "name_pl": "1x2 in 20 minutes",
        "name_ro": "1x2 in 20 minutes",
        "name_ru": "1x2 in 20 minutes",
        "name_tr": "1x2 in 20 minutes",
        "name_se": "1x2 in 20 minutes",
        "name_el": "1x2 in 20 minutes"
    },
    "id_599": {
        "name_en": "5 minutes - {$competitor1} sending off from {from} to {to}",
        "name_aa": "5 دقائق - {$competitor1} طرد من {from} إلى {to}",
        "name_de": "5 Minuten - {$competitor1} Platzverweise von {from} bis {to}",
        "name_nl": "5 minutes - uitsluiting {$competitor1} van {from} tot {to}",
        "name_pl": "5 minut - {$competitor1} czerwona kartka od {from} do {to}",
        "name_ro": "5 minute - {$competitor1} eliminare de la {from} la {to}",
        "name_ru": "5 минут - {$competitor1} удаление с {from} до {to}",
        "name_tr": "5 dakika - {$competitor1} oyundan atılma {from} dan {to} a",
        "name_se": "5 minuter - {$competitor1} utvisning från {from} till {to}",
        "name_el": "5 λεπτά - {$competitor1}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_169": {
        "name_en": "Corner range",
        "name_aa": "نطاق الزاوية",
        "name_de": "Eckenanzahlbereich",
        "name_nl": "Hoekschoppen range",
        "name_pl": "Zakres liczby rzutów rożnych",
        "name_ro": "Interval cornere",
        "name_ru": "Количество угловых",
        "name_tr": "Korner aralığı",
        "name_se": "Hörnintervall",
        "name_el": "Εύρος των κόρνερ"
    },
    "id_110": {
        "name_en": "5 minutes - total from {from} to {to}",
        "name_aa": "5 دقائق - الإجمالي من {from} إلى {to}",
        "name_de": "5 Minuten - Total von {from} bis {to}",
        "name_nl": "5 minuten - totaal van {from} tot {to}",
        "name_pl": "5 minut - suma od {from} do {to}",
        "name_ro": "5 minute - total de la {from} la {to}",
        "name_ru": "5 минут - тотал от {from} до {to}",
        "name_tr": "5 dakika -{from}'dan {to}'a kadar toplam gol",
        "name_se": "5 minuter - totalt från {from} till {to}",
        "name_el": "5 λεπτά - σύνολο από {from} μέχρι {to}"
    },
    "id_1": {
        "name_en": "1x2",
        "name_aa": "1X2",
        "name_de": "1x2",
        "name_nl": "1x2",
        "name_pl": "1x2",
        "name_ro": "1x2",
        "name_ru": "1x2",
        "name_tr": "1x2",
        "name_se": "1x2",
        "name_el": "1X2"
    },
    "id_1617": {
        "name_en": "1x2 in 60 minutes",
        "name_aa": "1x2 in 60 minutes",
        "name_de": "1x2 in 60 minutes",
        "name_nl": "1x2 in 60 minutes",
        "name_pl": "1x2 in 60 minutes",
        "name_ro": "1x2 in 60 minutes",
        "name_ru": "1x2 in 60 minutes",
        "name_tr": "1x2 in 60 minutes",
        "name_se": "1x2 in 60 minutes",
        "name_el": "1x2 in 60 minutes"
    },
    "id_202": {
        "name_en": "{!setnr} set - winner",
        "name_aa": "{!setnr} مجموعة - الفائز",
        "name_de": "{!setnr} Satz - Gewinner",
        "name_nl": "{setnr} set - winnaar",
        "name_pl": "{!setnr} set - zwycięzca",
        "name_ro": "Set {!setnr} - câștigător",
        "name_ru": "{!setnr} сет - победитель",
        "name_tr": "{!setnr}. set - kazananı",
        "name_se": "{!setnr} set - vinnare",
        "name_el": "{!setnr} σετ - νικητής"
    },
    "id_761": {
        "name_en": "Player passing touchdowns (incl. overtime)",
        "name_aa": "لمس أرض يمر لاعب",
        "name_de": "Spieler Passing Touchdowns (inkl. Verlängerung)",
        "name_nl": "Speler passing touchdowns",
        "name_pl": "Player passing touchdowns (incl. overtime)",
        "name_ro": "Pase touchdown jucător",
        "name_ru": "Игрок набравший больше тачдаунов",
        "name_tr": "Oyuncu touchdownlar asistleri",
        "name_se": "Spelare - passningtouchdowns",
        "name_el": "Παίχτης passing touchdowns"
    },
    "id_703": {
        "name_en": "Rabbit total",
        "name_aa": "مجموع الارانب",
        "name_de": "Rabbit gesamt",
        "name_nl": "Rabbit totaal",
        "name_pl": "Rabbit total",
        "name_ro": "Total rabbit",
        "name_ru": "Плохой игрок тотал",
        "name_tr": "Toplam rabbit",
        "name_se": "Rabbit totalt",
        "name_el": "Rabbit total"
    },
    "id_1097": {
        "name_en": "Grid position range of the winner",
        "name_aa": "Grid position range of the winner",
        "name_de": "Grid position range of the winner",
        "name_nl": "Startpositie van de uiteindelijke winnaar",
        "name_pl": "Grid position range of the winner",
        "name_ro": "Grid position range of the winner",
        "name_ru": "Диапазон поул-позиции победителя",
        "name_tr": "Grid position range of the winner",
        "name_se": "Grid position range of the winner",
        "name_el": "Θέση νικητή"
    },
    "id_852": {
        "name_en": "{$competitor1} to win exactly 2 sets",
        "name_aa": "{$competitor1} للفوز بمجموعتين بالضبط",
        "name_de": "{$competitor1} wird genau zwei Sätze gewinnen",
        "name_nl": "{$competitor1} wint precies 2 sets",
        "name_pl": "{$competitor1} wygra dokładnie 2 sety",
        "name_ro": "{$competitor1} să câștige exact 2 seturi",
        "name_ru": "{$competitor1} выиграть точно 2 сэт",
        "name_tr": "{$competitor1} sadece 2 set kazanır",
        "name_se": "{$competitor1} att vinna exakt 2 set",
        "name_el": "{$competitor1} να κερδίσει μόνο 2 σετ"
    },
    "id_262": {
        "name_en": "Total (over-exact-under) (incl. extra innings)",
        "name_aa": "مجموع (أكثر بالضبط تحت) (بما في ذلك أدوار إضافية)",
        "name_de": "Total (darüber-exakt-unter) (inkl. Extra Innings)",
        "name_nl": "Totaal (boven-exact-onder) (extra innings inbegrepen)",
        "name_pl": "Suma (ponad-dokładnie-poniżej) (włącznie z extra innings)",
        "name_ro": "Total (peste-exact-sub) (incl. inning-uri extra)",
        "name_ru": "Тотал (больше-точно-меньше) (включая дополнительные иннинги)",
        "name_tr": "Toplam (üst-tam-alt) (uzatmalar dahil)",
        "name_se": "Totalt (över-exakt-under)(inkl. extra innings)",
        "name_el": "Σύνολο (over-ακριβώς-under) (έξτρα innings συμπ.)"
    },
    "id_390": {
        "name_en": "{!setnr} set - {$competitor2} total 180s",
        "name_aa": "مجموعة {!setnr} - {$competitor2} إجمالي 180",
        "name_de": "{!setnr} Satz - {$competitor2} Total 180s",
        "name_nl": "{setnr} set - totaal aantal 180s {$competitor2}",
        "name_pl": "{!setnr} set - {$competitor2} suma 180s",
        "name_ro": "Set {!setnr} - total 180-uri {$competitor2}",
        "name_ru": "{!setnr} сет - {$competitor2} тотал 180ти",
        "name_tr": "{!setnr}. set - {$competitor2} toplam 180ler",
        "name_se": "{!setnr} set - {$competitor2} totalt antal 180:or",
        "name_el": "{!setnr} set - {$competitor2} σύνολο 180"
    },
    "id_286": {
        "name_en": "Innings {from} to {to} - {$competitor2} total",
        "name_aa": "ألدور{from} إلى{to}- {$competitor2} المجموع",
        "name_de": "Innings {from} bis {to} - {$competitor2} Total",
        "name_nl": "Inning {from} tot {to} - {$competitor2} totaal",
        "name_pl": "Innings {from} do {to} - {$competitor2} suma",
        "name_ro": "Innings de la {from} la {to} - total {$competitor2}",
        "name_ru": "Иннинги {from} до {to} - {$competitor2} тотал",
        "name_tr": "Devreler {from} den {to} e - {$competitor2} toplam",
        "name_se": "Innings {from} till {to} - {$competitor2} totalt",
        "name_el": "Innings {from} μέχρι {to} - σύνολο {$competitor2}"
    },
    "id_610": {
        "name_en": "1x2 (incl. overtime)",
        "name_aa": "1 × 2 (بما في ذلك الوقت الإضافي)",
        "name_de": "1x2 (inkl. Verlängerung)",
        "name_nl": "1x2 (verlenging inbegrepen)",
        "name_pl": "1x2 (włącznie z dogrywką)",
        "name_ro": "1x2 (incl. prelungiri)",
        "name_ru": "1x2 (включая овертайм)",
        "name_tr": "1x2 (uzatmalar dahil)",
        "name_se": "1x2 (inkl. övertid)",
        "name_el": "1Χ2 παράταση συμπεριλαμβάνεται"
    },
    "id_557": {
        "name_en": "{!mapnr} map - {!xth} baron",
        "name_aa": "{!mapnr} خريطة - {!xth} بارون",
        "name_de": "{!mapnr} Map - {!xth} Baron",
        "name_nl": "{mapnr} map - {xth} baron",
        "name_pl": "{!mapnr} mapa - {!xth} baron",
        "name_ro": "Harta {!mapnr} - baron {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} барон",
        "name_tr": "{!mapnr}. harita- {!xth} baron",
        "name_se": "{!mapnr} karta - {!xth} baron",
        "name_el": "{!mapnr} map - {!xth} baron"
    },
    "id_829": {
        "name_en": "Bonus ball equals any regular ball",
        "name_aa": "الكرة المكافئة تساوي أي كرة منتظمة",
        "name_de": "Zusatzzahl entspricht einer regulären Zahl",
        "name_nl": "Reservebal gelijk aan willekeurige gewone bal",
        "name_pl": "Bonus ball equals any regular ball",
        "name_ro": "Bilă bonus egalează orice bilă normală",
        "name_ru": "Бонус бол равно любому обычному шару",
        "name_tr": "Bonus ball hiç bir regular ball'a eşit değil",
        "name_se": "Bonusboll är lika med någon av de vanliga bollarna",
        "name_el": "Bonus ball equals any regular ball"
    },
    "id_1627": {
        "name_en": "{$competitor2} 3 goals in a row",
        "name_aa": "{$competitor2} 3 goals in a row",
        "name_de": "{$competitor2} 3 goals in a row",
        "name_nl": "{$competitor2} 3 goals in a row",
        "name_pl": "{$competitor2} 3 goals in a row",
        "name_ro": "{$competitor2} 3 goals in a row",
        "name_ru": "{$competitor2} 3 goals in a row",
        "name_tr": "{$competitor2} 3 goals in a row",
        "name_se": "{$competitor2} 3 goals in a row",
        "name_el": "{$competitor2} 3 goals in a row"
    },
    "id_136": {
        "name_en": "Booking 1x2",
        "name_aa": "الحجز 1x2",
        "name_de": "Karten 1x2",
        "name_nl": "Kaarten 1x2",
        "name_pl": "Kartki 1x2",
        "name_ro": "Cartonaș 1x2",
        "name_ru": "Карточки 1х2",
        "name_tr": "Kartlar 1x2",
        "name_se": "Kort 1x2",
        "name_el": "Κάρτες 1Χ2"
    },
    "id_960": {
        "name_en": "{!quarternr} quarter - last point",
        "name_aa": "{!quarternr} quarter - last point",
        "name_de": "{!quarternr} Viertel - Letzter Punkt",
        "name_nl": "{quarternr} kwart - laatste punt",
        "name_pl": "{!quarternr} kwarta - ostatni punkt",
        "name_ro": "{!quarternr} quarter - last point",
        "name_ru": "{!quarternr} четверть - последнее очко",
        "name_tr": "{!quarternr} quarter - last point",
        "name_se": "{!quarternr} quarter - last point",
        "name_el": "{!quarternr} περίοδος - τελευταίος πόντος"
    },
    "id_1128": {
        "name_en": "{!inningnr} innings - {%player1} & {%player2} to score {milestone} & {%player3} over {total} dismissals",
        "name_aa": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_de": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_nl": "{!inningnr} innings - {!player1} & {!player2} scoren {milestone} & {!player3} over {total} dismissals",
        "name_pl": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_ro": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_ru": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_tr": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_se": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals",
        "name_el": "{!inningnr} innings - {!player1} & {!player2} to score {milestone} & {!player3} over {total} dismissals"
    },
    "id_1067": {
        "name_en": "Winner & total (incl. extra innings)",
        "name_aa": "Winner & total (incl. extra innings)",
        "name_de": "Sieger & Total (inkl. Extra Innings)",
        "name_nl": "Winnaar & totaal (incl. extra innings)",
        "name_pl": "Winner & total (incl. extra innings)",
        "name_ro": "Winner & total (incl. extra innings)",
        "name_ru": "Победитель и тотал (включая экстра иннинги)",
        "name_tr": "Winner & total (incl. extra innings)",
        "name_se": "Vinnare och totalt (inkl. extra innings)",
        "name_el": "Νικητής και σύνολο (συμπ. extra innings)"
    },
    "id_880": {
        "name_en": "{$competitor1} to win",
        "name_aa": "{$competitor1} للفوز",
        "name_de": "{$competitor1} wird gewinnen",
        "name_nl": "{$competitor1} wint",
        "name_pl": "{$competitor1} to win",
        "name_ro": "{$competitor1} să câștige",
        "name_ru": "{$competitor1} победит",
        "name_tr": "{$competitor1} kazanan",
        "name_se": "{$competitor1} att vinna",
        "name_el": "{$competitor1} να κερδίσει"
    },
    "id_116": {
        "name_en": "Overtime - total",
        "name_aa": "الوقت الإضافي - المجموع",
        "name_de": "Verlängerung - Total",
        "name_nl": "Verlenging - totaal",
        "name_pl": "Dogrywka - suma",
        "name_ro": "Prelungiri - total",
        "name_ru": "Овертайм - тотал",
        "name_tr": "Uzatma - toplam gol",
        "name_se": "Övertid - totalt",
        "name_el": "Παράταση - Σύνολο Γκολ"
    },
    "id_832": {
        "name_en": "Standard bet",
        "name_aa": "الرهان القياسي",
        "name_de": "Standard bet",
        "name_nl": "Standaard weddenschap",
        "name_pl": "Standard bet",
        "name_ro": "Pariu standard",
        "name_ru": "Обычная ставка",
        "name_tr": "Standart bahis",
        "name_se": "Standardinsats",
        "name_el": "Standard bet"
    },
    "id_326": {
        "name_en": "Map handicap {hcp}",
        "name_aa": "خريطة الإعاقة {hcp}",
        "name_de": "Map Handicap {hcp}",
        "name_nl": "Map handicap {hcp}",
        "name_pl": "Mapa handicap {hcp}",
        "name_ro": "Handicap hartă {hcp}",
        "name_ru": "Гандикап на карту {hcp}",
        "name_tr": "Harita handikapı {hcp}",
        "name_se": "Karta handikapp {hcp}",
        "name_el": "Map {hcp} χάντικαπ"
    },
    "id_775": {
        "name_en": "Player goals",
        "name_aa": "أهداف اللاعب",
        "name_de": "Spieler Tore",
        "name_nl": "Speler goals",
        "name_pl": "Player goals",
        "name_ro": "Goluri jucător",
        "name_ru": "Игрок забивший больше голов",
        "name_tr": "Oyuncu golleri",
        "name_se": "Spelarmål",
        "name_el": "Παίχτης γκολ"
    },
    "id_1623": {
        "name_en": "Score in first 60 min",
        "name_aa": "Score in first 60 min",
        "name_de": "Score in first 60 min",
        "name_nl": "Score in first 60 min",
        "name_pl": "Score in first 60 min",
        "name_ro": "Score in first 60 min",
        "name_ru": "Score in first 60 min",
        "name_tr": "Score in first 60 min",
        "name_se": "Score in first 60 min",
        "name_el": "Score in first 60 min"
    },
    "id_1046": {
        "name_en": "Innings 1 to 5 - total hits",
        "name_aa": "Innings 1 to 5 - total hits",
        "name_de": "Innings 1 to 5 - total hits",
        "name_nl": "Innings 1 tot 5 - totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - total hits",
        "name_ro": "Innings 1 to 5 - total hits",
        "name_ru": "Иннинги с 1 по 5 - тотал хитов",
        "name_tr": "Innings 1 to 5 - total hits",
        "name_se": "Inning 1 till 5 - totalt antal hits",
        "name_el": "Innings 1 to 5 - total hits"
    },
    "id_315": {
        "name_en": "{!setnr} set - 1x2",
        "name_aa": "مجموعة {setnr!} - 1X2",
        "name_de": "{!setnr} Satz - 1x2",
        "name_nl": "{setnr} set - 1x2",
        "name_pl": "{!setnr} set - 1x2",
        "name_ro": "Set-ul {!setnr} - 1x2",
        "name_ru": "{!setnr} сет - 1x2",
        "name_tr": "{!setnr}. set - 1x2",
        "name_se": "{!setnr} set - 1x2",
        "name_el": "{!setnr} σετ - 1Χ2"
    },
    "id_744": {
        "name_en": "Innings {from} to {to} - handicap",
        "name_aa": "أدوار {from} إلى {to} -هانديكاب",
        "name_de": "Innings {from} bis {to} - Handicap",
        "name_nl": "Innings {from} tot {to} - handicap",
        "name_pl": "Innings {from} do {to} - handicap",
        "name_ro": "Innings de la {from} la {to} - handicap",
        "name_ru": "Иннинги от {from} до {to} - гандикап",
        "name_tr": "Devre {from} den {to} e - handikap",
        "name_se": "Innings {from} till {to} - handikapp",
        "name_el": "Innings {from} to {to} - handicap"
    },
    "id_685": {
        "name_en": "Player of the match",
        "name_aa": "لاعب المباراة",
        "name_de": "Spieler des Spiels",
        "name_nl": "Man van de wedstrijd",
        "name_pl": "Zawodnik meczu",
        "name_ro": "Jucătorul meciului",
        "name_ru": "Игрок матча",
        "name_tr": "Maçın adamı",
        "name_se": "Matchens spelare",
        "name_el": "Παίχτης του αγώνα"
    },
    "id_921": {
        "name_en": "{%player} total points (incl. overtime)",
        "name_aa": "{!player} total points (incl. overtime)",
        "name_de": "{!player} Punkte Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal punten",
        "name_pl": "{!player} total points (incl. overtime)",
        "name_ro": "{!player} total points (incl. overtime)",
        "name_ru": "{!player} тотал очков",
        "name_tr": "{!player} total points (incl. overtime)",
        "name_se": "{!player} total points (incl. overtime)",
        "name_el": "{!player} σύνολο πόντων"
    },
    "id_1068": {
        "name_en": "Will there be a grand slam (incl. extra innings)",
        "name_aa": "Will there be a grand slam (incl. extra innings)",
        "name_de": "Wird es einen Grand Slam geben (inkl. Extra Innings)",
        "name_nl": "Zal er een grand slam gescoord worden (incl. extra innings)?",
        "name_pl": "Will there be a grand slam (incl. extra innings)",
        "name_ro": "Will there be a grand slam (incl. extra innings)",
        "name_ru": "Будет ли гранд-слэм (включая экстра иннинги)",
        "name_tr": "Will there be a grand slam (incl. extra innings)",
        "name_se": "Will there be a grand slam (incl. extra innings)",
        "name_el": "Θα υπάρξει γκραν σλαμ (συμπ. extra innings)"
    },
    "id_580": {
        "name_en": "5 minutes - {!cornernr} corner from {from} to {to}",
        "name_aa": "5 دقائق - الزاوية {!cornernr} من {from} إلى {to}",
        "name_de": "5 Minuten - {!cornernr} Ecke von {from} bis {to}",
        "name_nl": "5 minutes - {cornernr} hoekschop van {from} tot {to}",
        "name_pl": "5 minut - {!cornernr} rzut rożny od {from} do {to}",
        "name_ro": "5 minute - cornerul {!cornernr} de la {from} la {to}",
        "name_ru": "5 минут - {!cornernr} угловой с {from} до {to}",
        "name_tr": "5 dakika - {!cornernr}. korner {from} dan {to} a",
        "name_se": "5 minuter - {!cornernr} hörnor från {from} till {to}",
        "name_el": "5 λεπτά - {!cornernr}  κόρνερ από {from} μέχρι {to}"
    },
    "id_783": {
        "name_en": "Batter runs batted in (incl. extra innings)",
        "name_aa": "يدير الخليط يضرب في",
        "name_de": "Schlagmann Läufe eingeschlagen (inkl. Extra Innings)",
        "name_nl": "Slagman runs batted in",
        "name_pl": "Batter runs batted in (incl. extra innings)",
        "name_ro": "Run-uri batter bătute înăuntru",
        "name_ru": "Бэттер раны отбитые",
        "name_tr": "Vurucu sayı koşusu için vuruşu yapar",
        "name_se": "Slagman \"runs batted in\"",
        "name_el": "Batter hits"
    },
    "id_421": {
        "name_en": "{$competitor1} clean sheet (incl. overtime and penalties)",
        "name_aa": "{$competitor1} ورقة نظيفة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor1} ohne Gegentreffer (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{$competitor1} houdt doel schoon (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor1} czyste konto (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "{$competitor1} fără gol primit (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor1} не пропустит (включая овертайм и пенальти)",
        "name_tr": "{$competitor1} gol yemeden tamamlar (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor1} håller nollan (inkl. övertid och straffar)",
        "name_el": "{$competitor1} (παράταση και πέναλτι συμπ.)"
    },
    "id_739": {
        "name_en": "When will the {!runnr} run be scored (incl. extra innings)",
        "name_aa": "متى سيتم تسجيل {!runnr} (بما في ذلك الجولات الإضافية)",
        "name_de": "Wann wird der {!runnr} Run erzielt (inkl. Extra-Innings)",
        "name_nl": "Wanneer wordt de {runnr} run gescoord (extra innings inbegrepen)",
        "name_pl": "Kiedy zostanie zdobyty {!runnr} run (włącznie z extra innings)",
        "name_ro": "Când se va înscrie run-ul{!runnr} (incl. extra innings)",
        "name_ru": "Когда будет сделана {!runnr} пробежка (включая дополнительные иннинги)",
        "name_tr": "{!runnr}. koşu ne xaman olur (uzatmalar dahil)",
        "name_se": "Kommer {!runnr} run att räknas  (inck. extra innings)",
        "name_el": "When will the {!runnr} run be scored (incl. extra innings)"
    },
    "id_876": {
        "name_en": "{!inningnr} innings - {$competitor2} total at {!dismissalnr} dismissal",
        "name_aa": "أدوار {!inningnr} - {$competitor2} مجموعه في فصل {!dismissalnr}",
        "name_de": "{!inningnr} innings - {$competitor2} total at {!dismissalnr} dismissal",
        "name_nl": "{!inningnr} innings - Totaal {$competitor2} bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor2} suma przy {!dismissalnr} wyeliminowaniu",
        "name_ro": "Innings {!inningnr} - {$competitor2} total la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал на {!dismissalnr} выбываний",
        "name_tr": "{!inningnr} devre - {$competitor2} toplamda {!dismissalnr} dismissal",
        "name_se": "{!inningnr} innings - {$competitor2} totala vid {!dismissalnr} avvisning",
        "name_el": "{!inningnr} innings - {$competitor2} total at {!dismissalnr} dismissal"
    },
    "id_917": {
        "name_en": "{%player} total carries (incl. overtime)",
        "name_aa": "{!player} total carries (incl. overtime)",
        "name_de": "{!player} Carries Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal carries",
        "name_pl": "{!player} total carries (incl. overtime)",
        "name_ro": "{!player} total carries (incl. overtime)",
        "name_ru": "{!player} тотал carries",
        "name_tr": "{!player} total carries (incl. overtime)",
        "name_se": "{!player} total carries (incl. overtime)",
        "name_el": "{!player} total carries"
    },
    "id_499": {
        "name_en": "{!framenr} frame - winner",
        "name_aa": "إطار {framenr!}- فائز",
        "name_de": "{!framenr} Frame - Gewinner",
        "name_nl": "{framenr} frame - winnaar",
        "name_pl": "{!framenr} frejm - zwycięzca",
        "name_ro": "Frame-ul {!framenr} - câștigător",
        "name_ru": "{!framenr} фрейм - победитель",
        "name_tr": "{!framenr}. frame - kazanan",
        "name_se": "{!framenr} frame - vinnare",
        "name_el": "{!framenr} frame - Νικητής"
    },
    "id_809": {
        "name_en": "{$event} - qualify to play offs",
        "name_aa": "{$event} - مؤهل للعب مباريات",
        "name_de": "{$event} - qualifiziert sich für die Playoffs",
        "name_nl": "{$event} - kwalificeert zich voor play offs",
        "name_pl": "{$event} - kwal. do playoffs",
        "name_ro": "{$event} - calificare în play offs",
        "name_ru": "{$event} - отбор в плей-офф",
        "name_tr": "{$event} - play offlar oynamaya hak kazanmış",
        "name_se": "{$event} - kvalificera sig till slutspel",
        "name_el": "{$event} - πρόκριση στα playoffs"
    },
    "id_31": {
        "name_en": "{$competitor1} clean sheet",
        "name_aa": "{$competitor1} شباكه نظيفة",
        "name_de": "{$competitor1} ohne Gegentreffer",
        "name_nl": "{$competitor1} houdt doel schoon",
        "name_pl": "{$competitor1} czyste konto",
        "name_ro": "{$competitor1} fără gol primit",
        "name_ru": "{$competitor1} не пропустит",
        "name_tr": "{$competitor1} gol yemez",
        "name_se": "{$competitor1} håller nollan",
        "name_el": "{$competitor1} να μη δεχτεί γκολ"
    },
    "id_266": {
        "name_en": "{!runnr} run (incl. extra innings)",
        "name_aa": "{!runnr}  المدى (بما في ذلك أدوار إضافية)",
        "name_de": "{!runnr} Lauf (inkl. Extra Innings)",
        "name_nl": "{runnr} run (extra innings inbegrepen)",
        "name_pl": "{!runnr} run (włącznie z extra innings)",
        "name_ro": "Run-ul {!runnr} (incl. inning-uri extra)",
        "name_ru": "{!runnr} пробежка (включая дополнительные иннинги)",
        "name_tr": "{!runnr}. koşu (uzatmalar dahil)",
        "name_se": "{!runnr} run (inkl. extra innings)",
        "name_el": "{!runnr} run  (έξτρα innings συμπ.)"
    },
    "id_613": {
        "name_en": "{!quarternr} quarter - draw no bet (incl. overtime)",
        "name_aa": "الربع {!quarternr}- لا رهان(بما في ذلك الوقت الإضافي)",
        "name_de": "{!quarternr} Viertel - unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - draw no bet (verlenging inbegrepen)",
        "name_pl": "{!quarternr} kwarta - remis bez zakładu (włącznie z dogrywką)",
        "name_ro": "Sfertul {!quarternr} - egal pariu nul (incl. prelungiri)",
        "name_ru": "{!quarternr} четверть - ничья ставки нет (включая овертайм)",
        "name_tr": "{!quarternr}. çeyrek - beraberlikte iade (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - oavgjort (inkl. övertid)",
        "name_el": "{!quarternr} περίοδος - Ισοπαλία όχι στοιχήμα συμπεριλαμβάνεται"
    },
    "id_638": {
        "name_en": "{!inningnr} innings - {%player} total",
        "name_aa": "{!inningnr} أدوار - {player} الإجمالي",
        "name_de": "{!inningnr} Inning - {!player} Gesamt",
        "name_nl": "{!inningnr} innings - {!player} totaal",
        "name_pl": "{!inningnr} innings - {!player} suma",
        "name_ro": "{!inningnr} innings - total {!player}",
        "name_ru": "{!inningnr} иннинг - {!player} тотал",
        "name_tr": "{!inningnr}. devre - {!player} toplam",
        "name_se": "{!inningnr} innings - {!player} totalt",
        "name_el": "{!inningnr} innings - {!player} total"
    },
    "id_696": {
        "name_en": "Total wides",
        "name_aa": "مجموع الأوجه",
        "name_de": "Wides gesamt",
        "name_nl": "Totaal aantal wides",
        "name_pl": "Total wides",
        "name_ro": "Total wides",
        "name_ru": "Тотал уайд-болов",
        "name_tr": "Toplam wide",
        "name_se": "Totalt wides",
        "name_el": "Total wides"
    },
    "id_380": {
        "name_en": "{!setnr} set leg {legnr} - checkout colour",
        "name_aa": "{!setnr} مجموعة الساق {legnr} - الخروج اللون",
        "name_de": "{!setnr} Satz Runde {legnr} - Checkout Farbe",
        "name_nl": "{setnr} set leg {legnr} - checkout kleur",
        "name_pl": "{!setnr} set leg {legnr} - checkout kolor",
        "name_ro": "Set {!setnr} leg {legnr} - culoare checkout",
        "name_ru": "{!setnr} сет {legnr} этап - чек-аут цвет",
        "name_tr": "{!setnr}.. set {legnr}. ayak - checkout renk",
        "name_se": "{!setnr} set legs {legnr} - checkout-färg",
        "name_el": "{!setnr} set, {legnr} leg - checkout colour"
    },
    "id_666": {
        "name_en": "{!inningnr} innings - {$competitor1} total extras",
        "name_aa": "{!inningnr} أدوار - {$competitor1} إجمالي الإضافات",
        "name_de": "{!inningnr} Inning - {$competitor1} Extras gesamt",
        "name_nl": "{inningnr} innings - totaal aantal extras {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma extras",
        "name_ro": "{!inningnr} innings - {$competitor1} total extras",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал дополнительных ранов",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam ekstralar",
        "name_se": "{!inningnr} innings - {$competitor1} totalt antal extras",
        "name_el": "{!inningnr} innings - {$competitor1} total extras"
    },
    "id_1056": {
        "name_en": "{!setnr} set - winner & total",
        "name_aa": "{!setnr} set - winner & total",
        "name_de": "{!setnr} set - winner & total",
        "name_nl": "{setnr} set - winnaar & totaal",
        "name_pl": "{!setnr} set - winner & total",
        "name_ro": "{!setnr} set - winner & total",
        "name_ru": "{!setnr} сетов - победитель и тотал",
        "name_tr": "{!setnr} set - winner & total",
        "name_se": "{!setnr} set - vinnare och totalt",
        "name_el": "{!setnr} σύνολο - νικητής  & σύνολο"
    },
    "id_1073": {
        "name_en": "{!inningnr} inning - exact home runs",
        "name_aa": "{!inningnr} inning - exact home runs",
        "name_de": "{!inningnr} inning - exact home runs",
        "name_nl": "{inningnr} inning - exact aantal homeruns",
        "name_pl": "{!inningnr} inning - exact home runs",
        "name_ro": "{!inningnr} inning - exact home runs",
        "name_ru": "{!inningnr} иннинг - точные хоум-раны",
        "name_tr": "{!inningnr} inning - exact home runs",
        "name_se": "{!inningnr} inning - exact home runs",
        "name_el": "{!inningnr} inning - ακριβής αριθμός runs"
    },
    "id_631": {
        "name_en": "{!mapnr} map round {roundnr} - bomb planted",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - قنبلة مزروعة",
        "name_de": "{!mapnr} Map Runde {roundnr} - Bombe platziert",
        "name_nl": "{mapnr} map round {roundnr} - bom geplaatst",
        "name_pl": "{!mapnr} mapa runda {roundnr} - bomby podłożone",
        "name_ro": "Harta {!mapnr} runda {roundnr} - bomb planted",
        "name_ru": "{!mapnr} карта {roundnr} раунд - бомб устанавлено",
        "name_tr": "{!mapnr}. map {roundnr}. raund - bomba kuruldu",
        "name_se": "{!mapnr} kartrunda {roundnr} - bomb plantad",
        "name_el": "{!mapnr} map, γύρος {roundnr} - bomb planted"
    },
    "id_971": {
        "name_en": "Multi sixes spread",
        "name_aa": "Multi sixes spread",
        "name_de": "Multi sixes spread",
        "name_nl": "Multi zespunters spread",
        "name_pl": "Multi sixes spread",
        "name_ro": "Multi sixes spread",
        "name_ru": "Мульти спред шести ранов",
        "name_tr": "Multi sixes spread",
        "name_se": "Multi sixes spread",
        "name_el": "Multi sixes spread"
    },
    "id_725": {
        "name_en": "{!mapnr} map - duration",
        "name_aa": "{!mapnr} خريطة - المدة",
        "name_de": "{!mapnr} Map - Dauer",
        "name_nl": "{mapnr} map - duration",
        "name_pl": "{!mapnr} mapa - czas trwania",
        "name_ro": "Harta {!mapnr} - durată",
        "name_ru": "{!mapnr} карта - длительность",
        "name_tr": "{!mapnr}. harita - süre",
        "name_se": "{!mapnr} karta - tid",
        "name_el": "{!mapnr} map - duration"
    },
    "id_180": {
        "name_en": "1st half - {$competitor1} exact corners",
        "name_aa": "النصف الأول - {$competitor1} من الزوايا الصحيحة",
        "name_de": "1. Halbzeit - {$competitor1} Genaue Anzahl",
        "name_nl": "Eerste helft - Exact aantal hoekschoppen {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} dokładna liczba rzutów rożnych",
        "name_ro": "Repriza 1 - cornere exact {$competitor1}",
        "name_ru": "1ая половина - точное количество угловых {$competitor1}",
        "name_tr": "İlk yarı - {$competitor1} doğru korner sayısı",
        "name_se": "1:a halvlek - {$competitor1} exakt antal hörnor",
        "name_el": "1ο ημίχρονο - {$competitor1} ακριβής αριθμός κόρνερ"
    },
    "id_133": {
        "name_en": "Penalty shootout - {$competitor2} odd/even",
        "name_aa": "ركلات الترجيح - {$competitor2} فردي / زوجي",
        "name_de": "Elfmeterschießen - {$competitor2} Gerade/ungerade",
        "name_nl": "Strafschoppenserie - {$competitor2} even/oneven",
        "name_pl": "Rzuty karne - {$competitor2} parzysta/nieparzysta",
        "name_ro": "Lovituri de departajare - impar/par {$competitor2}",
        "name_ru": "Серия пенальти - {$competitor2} чет/нечет",
        "name_tr": "Penaltı atışları - {$competitor2} tek/çift",
        "name_se": "Straffar - {$competitor2} udda/jämnt",
        "name_el": "Διαδικασία πέναλτι - {$competitor2} Μονά/Ζυγά"
    },
    "id_427": {
        "name_en": "Correct score (incl. overtime and penalties)",
        "name_aa": "النتيجة الصحيحة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Genaues Ergebnis (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Juiste score (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Dokładny wynik (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Scor corect (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Точный счет (включая овертайм и пенальти)",
        "name_tr": "Maç sonucu (uzatmalar ve penaltılar dahil)",
        "name_se": "Rätt resultat (inkl. övertid och straffar)",
        "name_el": "Ακριβές σκορ (παράταση και πέναλτι συμπ.)"
    },
    "id_80": {
        "name_en": "1st half - correct score [{score}]",
        "name_aa": "النصف الأول - النتيجة الصحيحة [{score}]",
        "name_de": "1. Halbzeit- Genaues Ergebnis [{score}]",
        "name_nl": "1e helft - juiste uitslag [{score}]",
        "name_pl": "1. Połowa - Dokładny wynik [{score}]",
        "name_ro": "Prima repriză - scor corect [{score}]",
        "name_ru": "1ая половина - точный счет [{score}]",
        "name_tr": "İlk yarı -  skor [{score}]",
        "name_se": "1:a halvlek - rätt resultat [{score}]",
        "name_el": "1ο ημίχρονο - ακριβές σκορ [{score}]"
    },
    "id_676": {
        "name_en": "{!inningnr} innings - {$competitor1} top bowler",
        "name_aa": "{!inningnr} أدوار - {$competitor1} قمة الرامى",
        "name_de": "{!inningnr} Inning - {$competitor1} Bester Bowler",
        "name_nl": "{inningnr} innings - beste werper {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} najlepszy bowler",
        "name_ro": "{!inningnr} innings - {$competitor1} top bowler",
        "name_ru": "{!inningnr} иннинг - {$competitor1} лучший боулер",
        "name_tr": "{!inningnr}. devre - {$competitor1} en iyi atıcı",
        "name_se": "{!inningnr} innings - {$competitor1} topp bowler",
        "name_el": "{!inningnr} innings - {$competitor1} top bowler"
    },
    "id_527": {
        "name_en": "{!setnr} set - handicap",
        "name_aa": "{!setnr} مجموعة -هانديكاب",
        "name_de": "{!setnr} Satz - Handicap",
        "name_nl": "{setnr} set - handicap",
        "name_pl": "{!setnr} set - handicap",
        "name_ro": "Setul {!setnr} - handicap",
        "name_ru": "{!setnr} сет - гандикап",
        "name_tr": "{!setnr}. set - handikap",
        "name_se": "{!setnr} set - handikapp",
        "name_el": "{!setnr} σετ - χαντικάπ"
    },
    "id_1044": {
        "name_en": "{!inningnr} inning - {$competitor1} total hits",
        "name_aa": "{!inningnr} inning - {$competitor1} total hits",
        "name_de": "{!inningnr} inning - {$competitor1} total hits",
        "name_nl": "{inningnr} inning - {$competitor1} totaal aantal slagen",
        "name_pl": "{!inningnr} inning - {$competitor1} total hits",
        "name_ro": "{!inningnr} inning - {$competitor1} total hits",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал хитс",
        "name_tr": "{!inningnr} inning - {$competitor1} total hits",
        "name_se": "{!inningnr} inning - {$competitor1} totalt antal hits",
        "name_el": "{!inningnr} inning - {$competitor1} total hits"
    },
    "id_241": {
        "name_en": "Exact games",
        "name_aa": "مباريات بالضبط",
        "name_de": "Genaue Anzahl Spiele",
        "name_nl": "Exact aantal games",
        "name_pl": "Dokładna liczba gemów",
        "name_ro": "Game-uri exact",
        "name_ru": "Точные игры",
        "name_tr": "Doğru oyun skoru",
        "name_se": "Exakta games",
        "name_el": "Ακριβής αριθμός γκέιμ"
    },
    "id_6": {
        "name_en": "Which team kicks off",
        "name_aa": "أي فريق ينطلق",
        "name_de": "Wer hat Anstoß?",
        "name_nl": "Welk team trapt af",
        "name_pl": "Który zespół rozpocznie?",
        "name_ro": "Care echipă are lovitura de start",
        "name_ru": "Какая команда начнет игру",
        "name_tr": "Maça başlayan takım",
        "name_se": "Vilket lag tar avspark",
        "name_el": "Ποια ομάδα θα πάρει τη σέντρα"
    },
    "id_714": {
        "name_en": "Most stumpings",
        "name_aa": "معظم الاوراق",
        "name_de": "Meiste Stumpings",
        "name_nl": "Meeste stumpings",
        "name_pl": "Najwięcej stumpings",
        "name_ro": "Cele mai multe stumpings",
        "name_ru": "Большинство выбиваний из игры",
        "name_tr": "En çok stumpings",
        "name_se": "Flest stumpings",
        "name_el": "Most stumpings"
    },
    "id_1012": {
        "name_en": "{%competitor} total pars",
        "name_aa": "{!competitor} total pars",
        "name_de": "{!competitor} Pars Gesamt",
        "name_nl": "totaal aantal pars {!competitor}",
        "name_pl": "{!competitor} total pars",
        "name_ro": "{!competitor} total pars",
        "name_ru": "{!competitor} total pars",
        "name_tr": "{!competitor} total pars",
        "name_se": "{!competitor} total pars",
        "name_el": "{!competitor} total pars"
    },
    "id_821": {
        "name_en": "Draw sum odd/even",
        "name_aa": "رسم مبلغ فردي / زوجي",
        "name_de": "Unentschieden Summe ungerade/gerade",
        "name_nl": "Som trekking even/oneven",
        "name_pl": "Draw sum odd/even",
        "name_ro": "Sumă tragere impar/par",
        "name_ru": "Ничья сумма нечетная/четная",
        "name_tr": "Draw toplam tek/çift",
        "name_se": "Oavgjord summa udda/jämnt",
        "name_el": "Draw sum μονά/ζυγά"
    },
    "id_763": {
        "name_en": "Player rushing touchdowns (incl. overtime)",
        "name_aa": "لمس أرض يهرع لاعب",
        "name_de": "Spieler Rushing Touchdowns (inkl. Verlängerung)",
        "name_nl": "Speler rushing touchdowns",
        "name_pl": "Player rushing touchdowns (incl. overtime)",
        "name_ro": "Rushing touchdowns jucător",
        "name_ru": "Игрок сделавший больше выносов мяча с тачдаунами",
        "name_tr": "Oyuncu tocuhdown için koşuyor",
        "name_se": "Spelaren - springtouchdowns",
        "name_el": "Παίχτης rushing touchdowns"
    },
    "id_482": {
        "name_en": "1st half - try 1x2",
        "name_aa": "الشوط الاول - حاول 1x2",
        "name_de": "1. Halbzeit - Versuch 1x2",
        "name_nl": "Eerste helft - try 1x2",
        "name_pl": "1. Połowa - przyłożenie 1x2",
        "name_ro": "Repriza 1 - încercări 1x2",
        "name_ru": "1ая половина - попытка 1x2",
        "name_tr": "İlk yarı - try 1x2",
        "name_se": "1:a halvlek - försök 1x2",
        "name_el": "1ο ημίχρονο - try 1X2"
    },
    "id_905": {
        "name_en": "1st half - next score",
        "name_aa": "1st half - next score",
        "name_de": "1st half - next score",
        "name_nl": "1e helft - volgende score",
        "name_pl": "1st half - next score",
        "name_ro": "1st half - next score",
        "name_ru": "1-й тайм - следующий счёт",
        "name_tr": "1st half - next score",
        "name_se": "1:a halvlek - nästa poäng",
        "name_el": "1ο ημίχρονο - επόμενο σκοράρισμα"
    },
    "id_706": {
        "name_en": "{!inningnr} innings - {$competitor1} total ducks",
        "name_aa": "{!inningnr} أدوار - {$competitor1} إجمالي البط",
        "name_de": "{!inningnr} Inning - {$competitor1} Ducks gesamt",
        "name_nl": "{inningnr} innings - totaal aantal ducks {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} total ducks",
        "name_ro": "{!inningnr} innings - {$competitor1} total ducks",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал бэтсмен с нулевым результатом",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam duck",
        "name_se": "{!inningnr} innings - {$competitor1} totalt antal ducks",
        "name_el": "{!inningnr} innings - {$competitor1} total ducks"
    },
    "id_481": {
        "name_en": "Odd/even tries",
        "name_aa": "فردي / زوجي المحاولات",
        "name_de": "Gerade/ungerade Versuche",
        "name_nl": "Even/oneven aantal tries",
        "name_pl": "Parzyste/Nieparzyste przyłożenia",
        "name_ro": "Încercări impar/par",
        "name_ru": "Чет/Нечет попыток",
        "name_tr": "tek/çift try",
        "name_se": "Udda/jämnt försök",
        "name_el": "Μονά/Ζυγά tries"
    },
    "id_187": {
        "name_en": "Game handicap",
        "name_aa": "لعبة هانديكاب",
        "name_de": "Spiel Handicap",
        "name_nl": "Game handicap",
        "name_pl": "Gemy Handicap",
        "name_ro": "Handicap game-uri",
        "name_ru": "Гандикап на гейм",
        "name_tr": "Toplam oyun handikapı",
        "name_se": "Game handikapp",
        "name_el": "Χάντικαπ γκέιμ"
    },
    "id_998": {
        "name_en": "Man of the match player performance spread",
        "name_aa": "Man of the match player performance spread",
        "name_de": "Man of the match player performance spread",
        "name_nl": "Man van de wedstrijd spelerrendement spread",
        "name_pl": "Man of the match player performance spread",
        "name_ro": "Man of the match player performance spread",
        "name_ru": "Игрок матча результативность игрока спред",
        "name_tr": "Man of the match player performance spread",
        "name_se": "Man of the match player performance spread",
        "name_el": "Man of the match player performance spread"
    },
    "id_450": {
        "name_en": "{!periodnr} period - {$competitor1} exact goals",
        "name_aa": "فترة {!periodnr} - {$competitor1} الأهداف المحددة",
        "name_de": "{!periodnr} Periode - {$competitor1} Genaue Anzahl Tore",
        "name_nl": "{periodnr} periode - exact aantal doelpunten {$competitor1}",
        "name_pl": "{!periodnr} część gry - {$competitor1} dokładna liczba goli",
        "name_ro": "Repriza {!periodnr} - goluri exact {$competitor1}",
        "name_ru": "{!periodnr} период - {$competitor1} точное число голов",
        "name_tr": "{!periodnr}. periyot - {$competitor1} doğru skor",
        "name_se": "{!periodnr} period - {$competitor1} exakt antal mål",
        "name_el": "{!periodnr} περίοδος - {$competitor1} ακριβής αριθμός γκολ"
    },
    "id_155": {
        "name_en": "1st half - exact bookings",
        "name_aa": "الشوط الأول - الحجوزات بالضبط",
        "name_de": "1. Halbzeit - Genaue Anzahl Karten",
        "name_nl": "Eerste helft -  exact aantal kaarten",
        "name_pl": "1. Połowa - Dokładna liczba kartek",
        "name_ro": "Repriza 1 - cartonașe exact",
        "name_ru": "1ая половина - Точное число карточек",
        "name_tr": "İlk yarı - doğru kart sayısı",
        "name_se": "1:a halvlek - exakta antal kort",
        "name_el": "1ο ημίχρονο - ακριβής αριθμός κάρτες"
    },
    "id_796": {
        "name_en": "Player runs",
        "name_aa": "يدير لاعب",
        "name_de": "Spieler-Läufe",
        "name_nl": "Speler runs",
        "name_pl": "Player runs",
        "name_ro": "Run-uri jucător",
        "name_ru": "Игрок раны",
        "name_tr": "Oyuncu koşar",
        "name_se": "Spelare runs",
        "name_el": "Παίχτης runs"
    },
    "id_206": {
        "name_en": "{!setnr} set - will there be a tiebreak",
        "name_aa": "{!setnr} مجموعة - سيكون هناك تعادل",
        "name_de": "{!setnr} Satz - Wird es ein Tiebreak geben",
        "name_nl": "{setnr} set - zal er een tiebreak nodig zijn",
        "name_pl": "{!setnr} set - czy będzie tiebreak",
        "name_ro": "Set {!setnr} - va fi un tiebreak",
        "name_ru": "{!setnr} сет - будет ли тайбрейк",
        "name_tr": "{!setnr}. set - tiebreak olacak mı",
        "name_se": "{!setnr} set - tiebreak i matchen",
        "name_el": "{!setnr} σετ - θα υπάρξει τάι-μπρέικ"
    },
    "id_679": {
        "name_en": "{!inningnr} innings - {$competitor2} last player standing",
        "name_aa": "{!inningnr} أدوار - {$competitor2} آخر لاعب يقف",
        "name_de": "{!inningnr} Inning - {$competitor2} Letzter Spieler",
        "name_nl": "{inningnr} innings - laatste speler {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} ostatni zawodnik",
        "name_ro": "{!inningnr} innings - {$competitor2} ultimul jucător în picioare",
        "name_ru": "{!inningnr} иннинг - {$competitor2} последний игрок",
        "name_tr": "{!inningnr}. devre - {$competitor2} - son kalan oyuncu",
        "name_se": "{!inningnr} innings - {$competitor2} sista spelaren står",
        "name_el": "{!inningnr} innings - {$competitor2} last player standing"
    },
    "id_442": {
        "name_en": "All periods under {total}",
        "name_aa": "جميع الفترات تحت {total}",
        "name_de": "Alle Perioden Unter {total}",
        "name_nl": "Alle periodes onder {total}",
        "name_pl": "Wszystkie części gry poniżej {total}",
        "name_ro": "Toate reprizele sub {total}",
        "name_ru": "Все периоды меньше {total}",
        "name_tr": "Bütün periyotlar {total} altı",
        "name_se": "Alla perioder under {total}",
        "name_el": "Όλες οι περίοδοι under {total}"
    },
    "id_934": {
        "name_en": "Total sacks (incl. overtime)",
        "name_aa": "Total sacks (incl. overtime)",
        "name_de": "Sacks Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal sacks (incl. verlenging)",
        "name_pl": "Total sacks (incl. overtime)",
        "name_ro": "Total sacks (incl. overtime)",
        "name_ru": "Тотал баз (включая овертайм)",
        "name_tr": "Total sacks (incl. overtime)",
        "name_se": "Totalt antal sacks (inkl. övertid)",
        "name_el": "Σύνολο sacks (παράταση συμπ.)"
    },
    "id_836": {
        "name_en": "{$competitor2} windex",
        "name_aa": "{$competitor2} windex",
        "name_de": "{$competitor2} windex",
        "name_nl": "{$competitor2} windex",
        "name_pl": "{$competitor2} windex",
        "name_ro": "{$competitor2} windex",
        "name_ru": "{$competitor2} виндекс",
        "name_tr": "{$competitor2} windex",
        "name_se": "{$competitor2} windex",
        "name_el": "{$competitor2} windex"
    },
    "id_687": {
        "name_en": "Bowler head2head (1x2)",
        "name_aa": "الرامي وجها لوجه (1x2)",
        "name_de": "Bowler head2head (1x2)",
        "name_nl": "Werper head2head (1x2)",
        "name_pl": "Bowler head2head (1x2)",
        "name_ro": "Confruntare directă bowler (1x2)",
        "name_ru": "Боулер единоборство (1x2)",
        "name_tr": "Atıcı head2head (1x2)",
        "name_se": "Bowler head2head (1x2)",
        "name_el": "Bowler head2head (1x2)"
    },
    "id_1140": {
        "name_en": "{!inningnr} innings - {%player1} or {%player2} dismissal method",
        "name_aa": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_de": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_nl": "{!inningnr} innings - {!player1} of {!player2} dismissal-methode",
        "name_pl": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_ro": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_ru": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_tr": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_se": "{!inningnr} innings - {!player1} or {!player2} dismissal method",
        "name_el": "{!inningnr} innings - {!player1} or {!player2} dismissal method"
    },
    "id_455": {
        "name_en": "{!periodnr} period - {$competitor2} clean sheet",
        "name_aa": "فترة {!periodnr} - {$competitor2} شباكه نظيفة",
        "name_de": "{!periodnr} Periode - {$competitor2} ohne Gegentreffer",
        "name_nl": "{periodnr} periode - {$competitor2} houdt doel schoon",
        "name_pl": "{!periodnr} część gry - {$competitor2} czyste konto",
        "name_ro": "Repriza {!periodnr} - {$competitor2} fără gol primit",
        "name_ru": "{!periodnr} период - {$competitor2} не пропустит",
        "name_tr": "{!periodnr}. periyot - {$competitor2} gol yemez",
        "name_se": "{!periodnr} period - {$competitor2} håller nollan",
        "name_el": "{!periodnr} περίοδος - {$competitor2} να μη δεχτεί γκολ"
    },
    "id_62": {
        "name_en": "1st half - ({!goalnr}) Next Goal",
        "name_aa": "هدف النصف الأول - ({!goalnr})",
        "name_de": "1. Halbzeit - ({!goalnr}) Nächstes Tor",
        "name_nl": "1e Helft - ({!goalnr}) Volgende Goal",
        "name_pl": "1. Połowa - ({!goalnr}) gol",
        "name_ro": "Prima repriză - golul ({!goalnr})",
        "name_ru": "1-я половина - ({!goalnr}) гол",
        "name_tr": "Ilk Yari - ({!goalnr}) Gelecek Gol",
        "name_se": "1:a halvlek - ({!goalnr}) målet",
        "name_el": "1ο ημίχρονο - ({!goalnr}) γκολ"
    },
    "id_1100": {
        "name_en": "Total overtakings",
        "name_aa": "Total overtakings",
        "name_de": "Total overtakings",
        "name_nl": "Totaal aantal inhaalmanoeuvres",
        "name_pl": "Total overtakings",
        "name_ro": "Total overtakings",
        "name_ru": "Тотал обгонов",
        "name_tr": "Total overtakings",
        "name_se": "Total overtakings",
        "name_el": "Σύνολο προσπεράσεων"
    },
    "id_933": {
        "name_en": "Total turnovers (incl. overtime)",
        "name_aa": "Total turnovers (incl. overtime)",
        "name_de": "Ballverluste Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal turnovers (incl. verlenging)",
        "name_pl": "Total turnovers (incl. overtime)",
        "name_ro": "Total turnovers (incl. overtime)",
        "name_ru": "Тотал потери мяча (включая овертайм)",
        "name_tr": "Total turnovers (incl. overtime)",
        "name_se": "Totalt antal turnovers (inkl. övertid)",
        "name_el": "Σύνολο turnovers (παράταση συμπ.)"
    },
    "id_151": {
        "name_en": "1st half - total booking points",
        "name_aa": "الشوط الأول - مجموع نقاط الحجز",
        "name_de": "1. Halbzeit - Gesamtanzahl Strafpunkte",
        "name_nl": "Eerste helft - totaal aantal booking points",
        "name_pl": "1. Połowa - Suma punktów karnych",
        "name_ro": "Repriza 1 - total puncte cartonașe",
        "name_ru": "1ая половина - Общие зачетные очки",
        "name_tr": "İlk yarı - toplam kart ceza puanı",
        "name_se": "1:a halvlek - totalt antal kortpoäng",
        "name_el": "1ο ημίχρονο - σύνολο πόντων κάρτες"
    },
    "id_779": {
        "name_en": "Player touches",
        "name_aa": "لاعب اللمسات",
        "name_de": "Spieler Berührungen",
        "name_nl": "Speler touches",
        "name_pl": "Player touches",
        "name_ro": "Atingeri jucător",
        "name_ru": "Игрок совершивший больше касаний",
        "name_tr": "Oyuncu touchları",
        "name_se": "Spelare \"touches\"",
        "name_el": "Παίχτης touches"
    },
    "id_1013": {
        "name_en": "{%competitor} total bogeys",
        "name_aa": "{!competitor} total bogeys",
        "name_de": "{!competitor} Bogeys Gesamt",
        "name_nl": "totaal aantal bogeys {!competitor}",
        "name_pl": "{!competitor} total bogeys",
        "name_ro": "{!competitor} total bogeys",
        "name_ru": "{!competitor} total bogeys",
        "name_tr": "{!competitor} total bogeys",
        "name_se": "{!competitor} total bogeys",
        "name_el": "{!competitor} total bogeys"
    },
    "id_274": {
        "name_en": "Innings 1 to 5 - 1x2",
        "name_aa": "ألدور 1إلى5 - 1X2",
        "name_de": "Innings 1 bis 5 - 1x2",
        "name_nl": "Inning 1 tot 5 - 1x2",
        "name_pl": "Innings 1 do 5 - 1x2",
        "name_ro": "Innings de la 1 la 5 - 1x2",
        "name_ru": "Иннинги с 1 до 5 - 1x2",
        "name_tr": "Devreler 1 den 5 e - 1x2",
        "name_se": "Innings 1 till 5 - 1x2",
        "name_el": "Innings 1 σε 5 - 1X2"
    },
    "id_510": {
        "name_en": "{!framenr} frame - {$competitor1} break 50+",
        "name_aa": "إطار {!framenr} - {$competitor1} استراحة 50+",
        "name_de": "{!framenr} Frame - {$competitor1} Break 50+",
        "name_nl": "{framenr} frame - {$competitor1} break 50+",
        "name_pl": "{!framenr} frejm - {$competitor1} brejk 50+",
        "name_ro": "Frame-ul {!framenr} - {$competitor1} break 50+",
        "name_ru": "{!framenr} фрейм - {$competitor1} брейк 50+",
        "name_tr": "{!framenr}. frame -  {$competitor1} 50+ break",
        "name_se": "{!framenr} frame - {$competitor1} break 50+",
        "name_el": "{!framenr} frame -  {$competitor1} μπρέικ 50+"
    },
    "id_1136": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_aa": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_de": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_nl": "{!inningnr} innings over {overnr} - {deliverynr} delivery {$competitor1} exact aantal runs",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_ro": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_ru": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_tr": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_se": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} exact runs"
    },
    "id_1148": {
        "name_en": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_aa": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_de": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_nl": "{overnr} over - {$competitor1} totaal ({ballcount} ball)",
        "name_pl": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_ro": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_ru": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_tr": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_se": "{!overnr} over - {$competitor1} total ({ballcount} ball)",
        "name_el": "{!overnr} over - {$competitor1} total ({ballcount} ball)"
    },
    "id_1086": {
        "name_en": "Total speed of {!pitchnr} pitch",
        "name_aa": "Total speed of {!pitchnr} pitch",
        "name_de": "Total speed of {!pitchnr} pitch",
        "name_nl": "Totale snelheid van {pitchnr} worp",
        "name_pl": "Total speed of {!pitchnr} pitch",
        "name_ro": "Total speed of {!pitchnr} pitch",
        "name_ru": "Общая скорость {!pitchnr} питчера",
        "name_tr": "Total speed of {!pitchnr} pitch",
        "name_se": "Total speed of {!pitchnr} pitch",
        "name_el": "Total speed of {!pitchnr} pitch"
    },
    "id_105": {
        "name_en": "10 minutes - 1x2 from {from} to {to}",
        "name_aa": "10 دقائق - 1 × 2 من {from} إلى {to}",
        "name_de": "10 Minuten - 1x2 von{from} bis {to}",
        "name_nl": "10 minuten - 1x2 van {from} tot {to}",
        "name_pl": "10 minut - 1x2 od {from} do {to}",
        "name_ro": "10 minute - 1x2 de la {from} la {to}",
        "name_ru": "10 минут - 1х2 от {from} до {to}",
        "name_tr": "10 dakika - 1x2 {from}'dan {to}'a kadar",
        "name_se": "10 minuter - 1x2 från {from} till {to}",
        "name_el": "10 λεπτά - 1Χ2 από  {from} μέχρι {to}"
    },
    "id_271": {
        "name_en": "Team with highest scoring inning",
        "name_aa": "فريق مع أعلى الدرجات الدور",
        "name_de": "Welches Team gewinnt das Inning mit den meisten Punkten",
        "name_nl": "Team met de inning met de hoogste score",
        "name_pl": "Zespół z najwyżej punktującym inning",
        "name_ro": "Echipa care înscrie cel mai mult într-un inning",
        "name_ru": "Команда с самым результативным иннингом",
        "name_tr": "En çok skorun olduğu devreyi kazanan takım",
        "name_se": "Lag med inning med flest poäng",
        "name_el": "Ομάδα με τα υψηλότερο σκορ (inning)"
    },
    "id_644": {
        "name_en": "{!inningnr} innings - {%player} total sixes",
        "name_aa": "{!inningnr} أدوار - {player} مجموع الستات",
        "name_de": "{!inningnr} Inning - {!player} Sixes gesamt",
        "name_nl": "{!inningnr} innings - {!player} totaal aantal sixes",
        "name_pl": "{!inningnr} innings - {!player} suma szóstek",
        "name_ro": "{!inningnr} innings - total sixes {!player}",
        "name_ru": "{!inningnr} иннингов - {!player} шести ранов",
        "name_tr": "{!inningnr}. devre - {!player} toplam sixes",
        "name_se": "{!inningnr} innings - {!player} totala sixes",
        "name_el": "{!inningnr} innings - {!player} total sixes"
    },
    "id_87": {
        "name_en": "2nd half - handicap",
        "name_aa": "االشوط الثاني - العائق",
        "name_de": "2. Halbzeit - Handicap",
        "name_nl": "2e helft - handicap",
        "name_pl": "2. Połowa - handicap",
        "name_ro": "A 2-a repriză - handicap",
        "name_ru": "2ая половина - гандикап",
        "name_tr": "İkinci yarı - handikap",
        "name_se": "2:a halvlek - handikapp",
        "name_el": "2ο ημίχρονο -  χάντικαπ"
    },
    "id_445": {
        "name_en": "{!periodnr} period - handicap {hcp}",
        "name_aa": "فترة {!periodnr} - هانديكاب {hcp}",
        "name_de": "{!periodnr} Periode - Handicap {hcp}",
        "name_nl": "{periodnr} periode - handicap {hcp}",
        "name_pl": "{!periodnr} część gry - handicap {hcp}",
        "name_ro": "Repriza {!periodnr} - handicap {hcp}",
        "name_ru": "{!periodnr} период - гандикап {hcp}",
        "name_tr": "{!periodnr} . periyot - handikap {hcp}",
        "name_se": "{!periodnr} period - handikapp {hcp}",
        "name_el": "{!periodnr} περίοδος - {hcp}  χάντικαπ"
    },
    "id_707": {
        "name_en": "{!inningnr} innings - {$competitor2} total ducks",
        "name_aa": "{!inningnr} أدوار - {$competitor2} إجمالي البط",
        "name_de": "{!inningnr} Inning - {$competitor2} Ducks gesamt",
        "name_nl": "{inningnr} innings - totaal aantal ducks {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} total ducks",
        "name_ro": "{!inningnr} innings - {$competitor2} total ducks",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал бэтсмен с нулевым результатом",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam duck",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal ducks",
        "name_el": "{!inningnr} innings - {$competitor2} total ducks"
    },
    "id_75": {
        "name_en": "1st half - both teams to score",
        "name_aa": "الشوط الأول - كلا الفريقين ليسجل",
        "name_de": "1. Halbzeit - Beide Mannschaften treffen",
        "name_nl": "1e helft - beide teams scoren",
        "name_pl": "1. Połowa - Oba zespoły zdobędą gola",
        "name_ro": "Prima repriză - ambele echipe să înscrie",
        "name_ru": "1ая половина - обе команды забьют",
        "name_tr": "İlk yarı - her iki takım da gol atar",
        "name_se": "1:a halvlek - båda lagen gör mål",
        "name_el": "1ο ημίχρονο - να σκοράρουν και οι 2 ομάδες"
    },
    "id_76": {
        "name_en": "1st half - {$competitor1} clean sheet",
        "name_aa": "النصف الأول - {$competitor1} ورقة نظيفة",
        "name_de": "1. Halbzeit- {$competitor1} ohne Gegentreffer",
        "name_nl": "1e helft - {$competitor1} houdt doel schoon",
        "name_pl": "1. Połowa - {$competitor1} czyste konto",
        "name_ro": "Prima repriză - {$competitor1} fără gol primit",
        "name_ru": "1ая половина - {$competitor1} не пропустит",
        "name_tr": "İlk Yarı - {$competitor1} gol yemez",
        "name_se": "1:a halvlek - {$competitor1} håller nollan",
        "name_el": "1ο ημίχρονο - {$competitor1} να μη δεχτεί γκολ"
    },
    "id_97": {
        "name_en": "2nd half - {$competitor2} clean sheet",
        "name_aa": "الشوط الثاني - {$competitor2} ورقة نظيفة",
        "name_de": "2. Halbzeit - {$competitor2} ohne Gegentreffer",
        "name_nl": "2e helft - {$competitor2} houdt doel schoon",
        "name_pl": "2. Połowa - {$competitor2} czyste konto",
        "name_ro": "A 2-a repriză - {$competitor2} fără gol primit",
        "name_ru": "2ая половина - {$competitor2} не пропустит",
        "name_tr": "İkinci yarı - {$competitor2} gol yemez",
        "name_se": "2:a halvlek - {$competitor2} håller nollan",
        "name_el": "2ο ημίχρονο -  {$competitor2} να μη δεχτεί γκολ"
    },
    "id_645": {
        "name_en": "{!inningnr} innings over {overnr} - 1x2",
        "name_aa": "{!inningnr} أدوار فوق {overnr} - 1x2",
        "name_de": "{!inningnr} Inning Over {overnr} - 1x2",
        "name_nl": "{inningnr} innings over {overnr} - 1x2",
        "name_pl": "{!inningnr} innings over {overnr} - 1x2",
        "name_ro": "{!inningnr} innings over {overnr} - 1x2",
        "name_ru": "{!inningnr} иннинг {overnr} овер - 1x2",
        "name_tr": "{!inningnr}. devre {overnr}. over -",
        "name_se": "{!inningnr} innings över {overnr} - 1x2",
        "name_el": "{!inningnr} innings over {overnr} - 1x2"
    },
    "id_1011": {
        "name_en": "{%competitor} total birdies",
        "name_aa": "{!competitor} total birdies",
        "name_de": "{!competitor} Birdies Gesamt",
        "name_nl": "totaal aantal birdies {!competitor}",
        "name_pl": "{!competitor} total birdies",
        "name_ro": "{!competitor} total birdies",
        "name_ru": "{!competitor} total birdies",
        "name_tr": "{!competitor} total birdies",
        "name_se": "{!competitor} total birdies",
        "name_el": "{!competitor} total birdies"
    },
    "id_639": {
        "name_en": "Total fours",
        "name_aa": "مجموع أربعات",
        "name_de": "Fours Gesamt",
        "name_nl": "Totaal aantal fours",
        "name_pl": "Suma czwórek",
        "name_ro": "Total fours",
        "name_ru": "Тотал 4 ранов",
        "name_tr": "Toplam fours",
        "name_se": "Totalt antal fours",
        "name_el": "Total fours"
    },
    "id_84": {
        "name_en": "2nd half - ({!goalnr}) goal",
        "name_aa": "الشوط الثاني - ({!goalnr})هدف",
        "name_de": "2. Halbzeit - ({!goalnr}) Tor",
        "name_nl": "2e helft - ({!goalnr}) doelpunt",
        "name_pl": "2. Połowa - ({!goalnr}) gol",
        "name_ro": "A 2-a repriză - golul ({!goalnr})",
        "name_ru": "2ая половина - ({!goalnr}) гол",
        "name_tr": "ikinci yarı - ({!goalnr}) gol",
        "name_se": "2:a halvlek - ({!goalnr}) mål",
        "name_el": "2ο ημίχρονο - ({!goalnr}) γκολ"
    },
    "id_60": {
        "name_en": "1st half - 1x2",
        "name_aa": "2x1-الشوط الأول",
        "name_de": "1. Halbzeit - 1x2",
        "name_nl": "1e helft - 1x2",
        "name_pl": "1. Połowa - 1x2",
        "name_ro": "Prima repriză - 1x2",
        "name_ru": "1-я половина - 1х2",
        "name_tr": "İlk yarı - 1x2",
        "name_se": "1:a halvlek - 1x2",
        "name_el": "1o ημίχρονο - 1Χ2"
    },
    "id_502": {
        "name_en": "{!framenr} frame - odd/even",
        "name_aa": "إطار {framenr!}- فردي / زوجي",
        "name_de": "{!framenr} Frame - Gerade/ungerade",
        "name_nl": "{framenr} frame - even/oneven",
        "name_pl": "{!framenr} frejm - parzyste/nieparzyste",
        "name_ro": "Frame-ul {!framenr} - impar/par",
        "name_ru": "{!framenr} фрейм - чет/нечет",
        "name_tr": "{!framenr}. frame - tek/çift",
        "name_se": "{!framenr} frame - udda/jämnt",
        "name_el": "{!framenr} frame - Μονά/Ζυγά"
    },
    "id_143": {
        "name_en": "{$competitor1} exact bookings",
        "name_aa": "{$competitor1} الحجوزات الدقيقة",
        "name_de": "{$competitor1} Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten {$competitor1}",
        "name_pl": "{$competitor1} Dokładna liczba kartek",
        "name_ro": "Cartonașe exact {$competitor1}",
        "name_ru": "{$competitor1} точное число карточек",
        "name_tr": "{$competitor1} doğru kart sayısı",
        "name_se": "{$competitor1} exakta antal kort",
        "name_el": "{$competitor1} ακριβής αριθμός καρτών"
    },
    "id_117": {
        "name_en": "Overtime - handicap",
        "name_aa": "الوقت الإضافي - هانديكاب",
        "name_de": "Verlängerung - Handicap",
        "name_nl": "Verlenging - handicap",
        "name_pl": "Dogrywka - handicap",
        "name_ro": "Prelungiri - handicap",
        "name_ru": "Овертайм - гандикап",
        "name_tr": "Uzatma - handikap",
        "name_se": "Övertid - handikapp",
        "name_el": "Παράταση - Χάντικαπ"
    },
    "id_144": {
        "name_en": "{$competitor2} exact bookings",
        "name_aa": "{$competitor2} الحجوزات الدقيقة",
        "name_de": "{$competitor2} Genaue Anzahl Karten",
        "name_nl": "Exact aantal kaarten {$competitor2}",
        "name_pl": "{$competitor2} Dokładna liczba kartek",
        "name_ro": "Cartonașe exact {$competitor2}",
        "name_ru": "{$competitor2} точное число карточек",
        "name_tr": "{$competitor2} toplam kart sayısı",
        "name_se": "{$competitor2} exakt antal kort",
        "name_el": "{$competitor2} ακριβής αριθμός καρτών"
    },
    "id_847": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_aa": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_de": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor2} total interval",
        "name_ru": "{!inningnr} иннингов овер {overnr} - {$competitor2} тотал спред",
        "name_tr": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_se": "{!inningnr} innings over {overnr} - {$competitor2} total spread",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} total spread"
    },
    "id_657": {
        "name_en": "{!inningnr} innings - {!dismissalnr} batter out",
        "name_aa": "{!inningnr} أدوار - {!dismissalnr} هرب",
        "name_de": "{!inningnr} Inning - {!dismissalnr} Batter out",
        "name_nl": "{inningnr} innings - {dismissalnr} slagman uit",
        "name_pl": "{!inningnr} innings - {!dismissalnr} batter out",
        "name_ro": "{!inningnr} innings - batter {!dismissalnr} out",
        "name_ru": "{!inningnr} иннингов - {!dismissalnr} бэттер аут",
        "name_tr": "{!inningnr}. devre -  {!dismissalnr}. vurucu out",
        "name_se": "{!inningnr} innings - {!dismissalnr} slagman ut",
        "name_el": "{!inningnr} innings - {!dismissalnr} batter out"
    },
    "id_686": {
        "name_en": "Batter head2head (1x2)",
        "name_aa": "الخليط وجها لوجه (1x2)",
        "name_de": "Batter head2head (1x2)",
        "name_nl": "Slagman head2head (1x2)",
        "name_pl": "Batter head2head (1x2)",
        "name_ro": "Confruntare directă batter (1x2)",
        "name_ru": "Бэттер очные встречи (1x2)",
        "name_tr": "Vurucu head2head (1x2)",
        "name_se": "Slagman head2head (1x2)",
        "name_el": "Batter head2head (1x2)"
    },
    "id_148": {
        "name_en": "{$competitor2} sending off",
        "name_aa": "{$competitor2} طرد",
        "name_de": "{$competitor2} Platzverweise",
        "name_nl": "Uitsluitingen {$competitor2}",
        "name_pl": "{$competitor2} czerwona kartka",
        "name_ro": "Eliminare {$competitor2}",
        "name_ru": "Удаление у {$competitor2}",
        "name_tr": "{$competitor2} takımında oyundan atılan olur",
        "name_se": "{$competitor2} utvisning",
        "name_el": "{$competitor2} να αποβληθεί παίχτης"
    },
    "id_61": {
        "name_en": "1st Half - Remaining Match",
        "name_aa": "الشوط الأول - الفريق الذي يفوز بقية",
        "name_de": "1. Halbzeit - Nullstandswette",
        "name_nl": "1e Helft - Rest van de Match",
        "name_pl": "1. Połowa - Który zespół wygra resztę meczu?",
        "name_ro": "Prima repriză - care echipă va câștiga restul",
        "name_ru": "1-я половина - кто выиграет остаток",
        "name_tr": "Ilk Yari - Sifir skor bahis",
        "name_se": "1:a halvlek - vilket lag vinner resten",
        "name_el": "1ο ημίχρονο - ποια ομάδα θα κερδίσει το υπόλοιπο"
    },
    "id_735": {
        "name_en": "{!mapnr} map - player with highest creep score",
        "name_aa": "{!mapnr} خريطة - لاعب ذو أعلى نقاط زحف",
        "name_de": "{!mapnr} Map - Spieler mit dem höchsten Creep-Score",
        "name_nl": "{mapnr} map - speler met de hoogste creep score",
        "name_pl": "{!mapnr} mapa - zawodik z największą ilością zabitych minionów (cs)",
        "name_ro": "Harta {!mapnr} - jucătorul cu cel mai bun scor la creepi",
        "name_ru": "{!mapnr} карта - игрок с наивысшим крип статом",
        "name_tr": "{!mapnr}. harita - en yüksek creep scoru yapacak oyuncu",
        "name_se": "{!mapnr} karta - spelare med högst krälpoäng",
        "name_el": "{!mapnr} map - player with highest creep score"
    },
    "id_449": {
        "name_en": "{!periodnr} period - exact goals",
        "name_aa": "فترة {!periodnr} - الأهداف المحددة",
        "name_de": "{!periodnr} Periode - Genaue Anzahl Tore",
        "name_nl": "{periodnr} periode - exact aantal doelpunten",
        "name_pl": "{!periodnr} część gry - dokładna liczba goli",
        "name_ro": "Repriza {!periodnr} - goluri exact",
        "name_ru": "{!periodnr} период - точное число голов",
        "name_tr": "{!periodnr}. periyot - doğru skor",
        "name_se": "{!periodnr} period - exakt antal mål",
        "name_el": "{!periodnr} περίοδος - ακριβής αριθμός γκολ"
    },
    "id_401": {
        "name_en": "Correct score [{score}]",
        "name_aa": "النتيجة الصحيحة [{score}]",
        "name_de": "Genaues Ergebnis [{score}]",
        "name_nl": "Juiste score [{score}]",
        "name_pl": "Dokładny wynik [{score}]",
        "name_ro": "Scor corect [{score}]",
        "name_ru": "Точный счет [{score}]",
        "name_tr": "Doğru skor [{score}]",
        "name_se": "Rätt resultat [{score}]",
        "name_el": "Ακριβές σκορ [{score}]"
    },
    "id_882": {
        "name_en": "{%player} to score (incl. overtime)",
        "name_aa": "{!player} للتسجيل (بما في ذلك الوقت الإضافي)",
        "name_de": "{!player} wird punkten (inkl. Verlängerung)",
        "name_nl": "{!player} scoort (verlenging inbegrepen)",
        "name_pl": "{!player} to score (incl. overtime)",
        "name_ro": "{!player} să înscrie (incl. prelungiri)",
        "name_ru": "{!player} забьёт (incl. overtime)",
        "name_tr": "{!player} to score (incl. overtime)",
        "name_se": "{!player} att göra mål (inkl. övertid)",
        "name_el": "{!player} να σκοράρει, παράταση συμπεριλαμβάνεται"
    },
    "id_1032": {
        "name_en": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_aa": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_de": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_nl": "Holes {from} tot {to} - 2 ball (1x2) meeste bogeys",
        "name_pl": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_ro": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_ru": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_tr": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_se": "Holes {from} to {to} - 2 ball (1x2) most bogeys",
        "name_el": "Holes {from} to {to} - 2 ball (1x2) most bogeys"
    },
    "id_253": {
        "name_en": "Which team wins the rest of the match (incl. extra innings)",
        "name_aa": "الفريق الذي يفوز بقية المباراة (بما في ذلك أدوار إضافية)",
        "name_de": "Wer gewinnt die verbleibende Spielzeit (inkl. Extra Innings)?",
        "name_nl": "Welk team wint het restant van de wedstrijd (extra innings inbegrepen)",
        "name_pl": "Który zespół wygra resztę meczu (włącznie z extra innings)",
        "name_ro": "Care echipă câștigă restul partidei  (incl. inning-uri extra)",
        "name_ru": "Какая команда выиграет остаток матча (включая дополнительные иннинги)",
        "name_tr": "Maçın kalanını hangi takım kazanır (uzatmalar dahil)",
        "name_se": "Vilket lag vinner resten av matchen (inkl. extra innings)",
        "name_el": "Ποια ομάδα θα κερδίσει το υπόλοιπο του αγώνα (έξτρα innings συμπ.)"
    },
    "id_38": {
        "name_en": "{!goalnr} goalscorer",
        "name_aa": "{!goalnr} هداف",
        "name_de": "{!goalnr} Torschütze",
        "name_nl": "{goalnr} doelpuntenmaker",
        "name_pl": "{!goalnr} strzelec gola",
        "name_ro": "Marcator {!goalnr}",
        "name_ru": "автор {!goalnr} гола",
        "name_tr": "{!goalnr} gol atan",
        "name_se": "{!goalnr} målgörare",
        "name_el": "{!goalnr} Σκόρερ"
    },
    "id_254": {
        "name_en": "Handicap {hcp} (incl. extra innings)",
        "name_aa": "هانديكاب {hcp} (بما في ذلك أدوار إضافية)",
        "name_de": "Handicap {hcp} (inkl. Extra Innings)",
        "name_nl": "Handicap {hcp} (extra innings inbegrepen)",
        "name_pl": "Handicap {hcp} (włącznie z extra innings)",
        "name_ro": "Handicap {hcp} (incl. inning-uri extra)",
        "name_ru": "Гандикап {hcp} (включая дополнительные иннинги)",
        "name_tr": "Handikap {hcp} (uzatmalar dahil)",
        "name_se": "Handikapp {hcp} (inkl. extra innings)",
        "name_el": "{hcp} χάντικαπ  (έξτρα innings συμπ.)"
    },
    "id_1054": {
        "name_en": "{%player} to strike out {!appearancenr} time at bat",
        "name_aa": "{!player} to strike out {!appearancenr} time at bat",
        "name_de": "{!player} to strike out {!appearancenr} time at bat",
        "name_nl": "{!player} uitgeworpen {!appearancenr} keer aan slag",
        "name_pl": "{!player} to strike out {!appearancenr} time at bat",
        "name_ro": "{!player} to strike out {!appearancenr} time at bat",
        "name_ru": "{!player} выбьет {!appearancenr} игрой битой",
        "name_tr": "{!player} to strike out {!appearancenr} time at bat",
        "name_se": "{!player} att strike out {!appearancenr} tid som slagman",
        "name_el": "{!player} to strike out {!appearancenr} time at bat"
    },
    "id_460": {
        "name_en": "{!periodnr} period - handicap",
        "name_aa": "فترة {!periodnr} -هانديكاب",
        "name_de": "{!periodnr} Periode - Handicap",
        "name_nl": "{periodnr} periode - handicap",
        "name_pl": "{!periodnr} część gry - handicap",
        "name_ro": "Repriza {!periodnr} - handicap",
        "name_ru": "{!periodnr} период - гандикап",
        "name_tr": "{!periodnr}. periyot - handikap",
        "name_se": "{!periodnr} period - handikapp",
        "name_el": "{!periodnr} περίοδος - χάντικαπ"
    },
    "id_28": {
        "name_en": "{$competitor2} odd/even",
        "name_aa": "{$competitor2} فردي / زوجي",
        "name_de": "{$competitor2} Gerade/ungerade",
        "name_nl": "{$competitor2} even/oneven",
        "name_pl": "{$competitor2} Parzyste/Nieparzyste",
        "name_ro": "Impar/par {$competitor2}",
        "name_ru": "{$competitor2} чет/нечет",
        "name_tr": "{$competitor2} tek/çift",
        "name_se": "{$competitor2} udda/jämnt",
        "name_el": "{$competitor2} Μονά/Ζυγά"
    },
    "id_255": {
        "name_en": "Winning margin (incl. extra innings)",
        "name_aa": "الفوز الهامش (بما في ذلك أدوار إضافية)",
        "name_de": "Gewinnspanne (inkl. Extra Innings)",
        "name_nl": "Winstmarge (extra innings inbegrepen)",
        "name_pl": "Margines zwycięstwa (włącznie z extra innings)",
        "name_ro": "Diferență victorie (incl. inning-uri extra)",
        "name_ru": "Победа с разницей (включая дополнительные иннинги)",
        "name_tr": "Kazanma farkı (uzatmalar dahil)",
        "name_se": "Vinstmarginal (inkl. extra innings)",
        "name_el": "Περιθώριο νίκης (έξτρα innings συμπ.)"
    },
    "id_573": {
        "name_en": "10 minutes - {!cornernr} corner from {from} to {to}",
        "name_aa": "10 دقائق - الزاوية {!cornernr} من {from} إلى {to}",
        "name_de": "10 Minuten - {!cornernr} Ecke von {from} bis {to}",
        "name_nl": "10 minutes - {cornernr} hoekschop van {from} tot {to}",
        "name_pl": "10 minut - {!cornernr} rzut rożny od {from} do {to}",
        "name_ro": "10 minute - cornerul {!cornernr} de la {from} la {to}",
        "name_ru": "10 минут - {!cornernr} угловой с {from} до {to}",
        "name_tr": "10 dakika - {!cornernr}. korner {from} den {to} a",
        "name_se": "10 minuter - {!cornernr} hörnor från {from} till {to}",
        "name_el": "10 λεπτά - {!cornernr}  κόρνερ από {from} μέχρι {to}"
    },
    "id_1035": {
        "name_en": "Holes {from} to {to} - 3 ball most bogeys",
        "name_aa": "Holes {from} to {to} - 3 ball most bogeys",
        "name_de": "Holes {from} to {to} - 3 ball most bogeys",
        "name_nl": "Holes {from} tot {to} - 3 ball meeste bogeys",
        "name_pl": "Holes {from} to {to} - 3 ball most bogeys",
        "name_ro": "Holes {from} to {to} - 3 ball most bogeys",
        "name_ru": "Holes {from} to {to} - 3 ball most bogeys",
        "name_tr": "Holes {from} to {to} - 3 ball most bogeys",
        "name_se": "Holes {from} to {to} - 3 ball most bogeys",
        "name_el": "Holes {from} to {to} - 3 ball most bogeys"
    },
    "id_978": {
        "name_en": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_aa": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_de": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_nl": "{inningnr} innings - {$competitor1} totaal aantal vierpunters spread",
        "name_pl": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_ro": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал 4 ранов",
        "name_tr": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_se": "{!inningnr} innings - {$competitor1} total fours spread",
        "name_el": "{!inningnr} innings - {$competitor1} total fours spread"
    },
    "id_359": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} odd/even",
        "name_aa": "{!inningnr} أدوار على {overnr} - {$competitor1} فردي / زوجي",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor1} Gerade/ungerade",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor1} even/oneven",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} parzyste/nieprzyste",
        "name_ro": "{!inningnr} innings over {overnr} - impar/par {$competitor1}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor1} чет/нечет",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor1} tek/çift",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor1} udda/jämnt",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} μονά/ζυγά"
    },
    "id_1158": {
        "name_en": "2 ball",
        "name_aa": "2 ball",
        "name_de": "2 ball",
        "name_nl": "2 ball",
        "name_pl": "2 ball",
        "name_ro": "2 ball",
        "name_ru": "2 ball",
        "name_tr": "2 ball",
        "name_se": "2 ball",
        "name_el": "2 ball"
    },
    "id_584": {
        "name_en": "5 minutes - {$competitor2} total corners from {from} to {to}",
        "name_aa": "5 دقائق - {$competitor2} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "5 Minuten - {$competitor2} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "5 minutes - totaal aantal hoekschoppen {$competitor2} van {from} tot {to}",
        "name_pl": "5 minut - {$competitor2} suma rzutów rożnych od {from} do {to}",
        "name_ro": "5 minute - {$competitor2} total cornere de la {from} la {to}",
        "name_ru": "5 минут - {$competitor2} всего угловых с {from} до {to}",
        "name_tr": "5 dakika - {$competitor2} toplam korner sayısı {from} dan {to} a",
        "name_se": "5 minuter - {$competitor2} totalt antal hörnor från {from} till {to}",
        "name_el": "5 λεπτά - {$competitor2}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_470": {
        "name_en": "1st half - winning margin",
        "name_aa": "الشوط الأول - هامش الفوز",
        "name_de": "1. Halbzeit - Gewinnspanne",
        "name_nl": "Eerste helft - winstmarge",
        "name_pl": "1. Połowa - margines zwycięstwa",
        "name_ro": "Repriza 1 - diferență victorie",
        "name_ru": "1ая половина - победа с разницей",
        "name_tr": "İlk yarı - Kazanma farkı",
        "name_se": "1:a halvlek - vinstmarginal",
        "name_el": "1ο ημίχρονο - περιθώριο νίκης"
    },
    "id_1082": {
        "name_en": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_aa": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_de": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_nl": "{inningnr} inning - {$competitor2} maakt een double of triple play",
        "name_pl": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_ro": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_ru": "{!inningnr} иннинг - {$competitor2} запишет дабл или трипл матч",
        "name_tr": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_se": "{!inningnr} inning - {$competitor2} to record a double or triple play",
        "name_el": "{!inningnr} inning - {$competitor2} to record a double or triple play"
    },
    "id_989": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_de": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - {$competitor2} supremacy spread",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_ro": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_ru": "{!inningnr} иннингов больше 0 до {overnr} - {$competitor2} supremacy спред",
        "name_tr": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_se": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} supremacy spread"
    },
    "id_1055": {
        "name_en": "Winner & total",
        "name_aa": "Winner & total",
        "name_de": "Winner & total",
        "name_nl": "Winnaar & totaal",
        "name_pl": "Winner & total",
        "name_ro": "Winner & total",
        "name_ru": "Победитель и тотал",
        "name_tr": "Winner & total",
        "name_se": "Vinnare och totalt",
        "name_el": "Νικητής & σύνολο"
    },
    "id_1624": {
        "name_en": "{$competitor1} 2 goals in a row",
        "name_aa": "{$competitor1} 2 goals in a row",
        "name_de": "{$competitor1} 2 goals in a row",
        "name_nl": "{$competitor1} 2 goals in a row",
        "name_pl": "{$competitor1} 2 goals in a row",
        "name_ro": "{$competitor1} 2 goals in a row",
        "name_ru": "{$competitor1} 2 goals in a row",
        "name_tr": "{$competitor1} 2 goals in a row",
        "name_se": "{$competitor1} 2 goals in a row",
        "name_el": "{$competitor1} 2 goals in a row"
    },
    "id_940": {
        "name_en": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_aa": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_de": "{!fieldgoalnr} Field Goal verwandelt (inkl. Verlängerung)",
        "name_nl": "{fieldgoalnr} veldgoal gescoord (incl. verlenging)",
        "name_pl": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_ro": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_ru": "{!fieldgoalnr} голов с игры (включая овертайм)",
        "name_tr": "{!fieldgoalnr} field goal made (incl. overtime)",
        "name_se": "{!fieldgoalnr} gjorda field goals (inkl. övertid)",
        "name_el": "{!fieldgoalnr} field goal εύστοχα  (παράταση συμπ.)"
    },
    "id_397": {
        "name_en": "{!mapnr} map - {!xth} tower",
        "name_aa": "خريطة {!mapnr} - {!xth} برج",
        "name_de": "{!mapnr} Map - {!xth} Tower",
        "name_nl": "{mapnr} map - {xth} tower",
        "name_pl": "{!mapnr} mapa - {!xth} wieża",
        "name_ro": "Harta {!mapnr} - tower {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} башня",
        "name_tr": "{!mapnr}. harita - {!xth} kule",
        "name_se": "{!mapnr} karta - {!xth} tower",
        "name_el": "{!mapnr} map - {!xth} tower"
    },
    "id_1620": {
        "name_en": "Score in first 15 min",
        "name_aa": "Score in first 15 min",
        "name_de": "Score in first 15 min",
        "name_nl": "Score in first 15 min",
        "name_pl": "Score in first 15 min",
        "name_ro": "Score in first 15 min",
        "name_ru": "Score in first 15 min",
        "name_tr": "Score in first 15 min",
        "name_se": "Score in first 15 min",
        "name_el": "Score in first 15 min"
    },
    "id_114": {
        "name_en": "Overtime - which team wins the rest",
        "name_aa": "الوقت الإضافي - الفريق الذي يفوز بقية",
        "name_de": "Verlängerung - Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "Verlenging - welk team wint het restant",
        "name_pl": "Dogrywka - który zespół wygra resztę",
        "name_ro": "Prelungiri - care echipă va câștiga restul",
        "name_ru": "Овертайм - какая команда выиграет остаток матча",
        "name_tr": "Uzatma - hangi takım kalan sürede kazanacak",
        "name_se": "Övertid - vilket lag vinner resten",
        "name_el": "Παράταση - Ποια ομάδα θα κερδίσει το υπόλοιπο"
    },
    "id_281": {
        "name_en": "Innings 1 to 5th top - total",
        "name_aa": "ألدور 1إلى5 أعلى - المجموع",
        "name_de": "Innings 1 bis Anfang 5tes - Total",
        "name_nl": "Eerste helft (top) Inning 1 tot 5 - totaal",
        "name_pl": "Innings 1 do początku 5. - suma",
        "name_ro": "Innings de la 1 la 5 top - total",
        "name_ru": "Иннинги с 1 до 5го верха - тотал",
        "name_tr": "Devreler 1den 5in sonuna - toplam",
        "name_se": "Innings 1 till 5th topp - totalt",
        "name_el": "Innings 1 σε 5 top - Σύνολο"
    },
    "id_594": {
        "name_en": "10 minutes - {$competitor1} sending off from {from} to {to}",
        "name_aa": "10 دقائق - {$competitor1} طرد من {from} إلى {to}",
        "name_de": "10 Minuten - {$competitor1} Platzverweise from {from} to {to}",
        "name_nl": "10 minutes - uitsluiting {$competitor1} van {from} tot {to}",
        "name_pl": "10 minut - {$competitor1} czerwona kartka od {from} do {to}",
        "name_ro": "10 minute - {$competitor1} eliminare de la {from} la {to}",
        "name_ru": "10 минут - {$competitor1} удаление с {from} до {to}",
        "name_tr": "10 dakika - {$competitor1} oyundan atılma {from} dan {to} a",
        "name_se": "10 minuter - {$competitor1} utvisning från {from} till {to}",
        "name_el": "10 λεπτά - {$competitor1}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_680": {
        "name_en": "Most extras",
        "name_aa": "معظم الاضافات",
        "name_de": "Meiste Extras",
        "name_nl": "Meeste extras",
        "name_pl": "Najwięcej extras",
        "name_ro": "Cele mai multe extras",
        "name_ru": "Большинство дополнительных ранов",
        "name_tr": "Maksimum ekstralar",
        "name_se": "Flest extras",
        "name_el": "Most extras"
    },
    "id_691": {
        "name_en": "{!inningnr} innings - {$competitor2} odd/even",
        "name_aa": "{!inningnr} أدوار - {$competitor2} فردي / زوجي",
        "name_de": "{!inningnr} Inning - {$competitor2} Gerade/ungerade",
        "name_nl": "{inningnr} innings - {$competitor2} even/oneven",
        "name_pl": "{!inningnr} innings - {$competitor2} parzyste/nieparzyste",
        "name_ro": "{!inningnr} innings - {$competitor2} impar/par",
        "name_ru": "{!inningnr} иннинг - {$competitor2} чет/нечет",
        "name_tr": "{!inningnr}. devre - {$competitor2} tek çift",
        "name_se": "{!inningnr} innings - {$competitor2} udda/jämnt",
        "name_el": "{!inningnr} innings - {$competitor2} μονά - ζυγά"
    },
    "id_94": {
        "name_en": "2nd half - odd/even",
        "name_aa": "الشوط الثاني -فردي/ زوجي",
        "name_de": "2. Halbzeit - Gerade/ungerade",
        "name_nl": "2e helft - even/oneven",
        "name_pl": "2. Połowa - Parzyste/Nieparzyste",
        "name_ro": "A 2-a repriză - impar/par",
        "name_ru": "2ая половина - чет/нечет",
        "name_tr": "İkinci yarı - tek/çift",
        "name_se": "2:a halvlek - udda/jämnt",
        "name_el": "2ο ημίχρονο - μονά/ζυγά"
    },
    "id_992": {
        "name_en": "Total wides spread",
        "name_aa": "Total wides spread",
        "name_de": "Total wides spread",
        "name_nl": "Totaal aantal wides spread",
        "name_pl": "Total wides spread",
        "name_ro": "Total wides spread",
        "name_ru": "Тотал уайд спред",
        "name_tr": "Total wides spread",
        "name_se": "Total wides spread",
        "name_el": "Total wides spread"
    },
    "id_652": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} boundary",
        "name_aa": "{!inningnr} أدوار على {overnr} - {$competitor1} الحدود",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor1} Boundary",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor1} boundary",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} boundary",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor1} limită",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor1} граница",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor1} boundary",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor1} hästlängd",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} boundary"
    },
    "id_272": {
        "name_en": "Highest scoring inning",
        "name_aa": "أعلى درجات التسجيل",
        "name_de": "Inning mit den meisten Punkten",
        "name_nl": "Inning met de hoogste score",
        "name_pl": "Najwyżej punktujący inning",
        "name_ro": "Inning-ul cu cele mai multe înscrieri",
        "name_ru": "Самый результативный иннинг",
        "name_tr": "En çok skorun olduğu devre",
        "name_se": "Inning med flest poäng",
        "name_el": "Ιnning με υψηλότερο σκορ"
    },
    "id_602": {
        "name_en": "1st half - race to {cornernr} corners",
        "name_aa": "الشوط الأول - سباق إلى زوايا {cornernr}",
        "name_de": "1. Halbzeit - Wer erreicht zuerst {cornernr} Ecken",
        "name_nl": "Eerste helft - race naar {cornernr} hoekschoppen",
        "name_pl": "1. Połowa - kto pierwszy z {cornernr} rzutami rożnymi",
        "name_ro": "Repriza 1 - primii la {cornernr} cornere",
        "name_ru": "1ая половина - гонка до {cornernr} угловых",
        "name_tr": "İlk yarı - {cornernr}. kornerin çekişmesi",
        "name_se": "1:a halvlek - först till {cornernr} hörnor",
        "name_el": "1ο ημίχρονο - Ποιος θα φτάσει 1ος  στα  {cornernr} κόρνερ"
    },
    "id_807": {
        "name_en": "{$event} - head2head",
        "name_aa": "{$event} - وجها لوجه",
        "name_de": "{$event} - Head 2 Head",
        "name_nl": "{$event} - head2head",
        "name_pl": "{$event} - head2head",
        "name_ro": "{$event} - rezultate directe",
        "name_ru": "{$event} - один на один",
        "name_tr": "{$event} - head2head",
        "name_se": "{$event} - head2head",
        "name_el": "{$event} - μεταξύ τους αγώνες"
    },
    "id_816": {
        "name_en": "{!inningnr} innings - {$competitor1} {!dismissalnr} dismissal method (extended)",
        "name_aa": "{!inningnr} أدوار - {$competitor1} {!dismissalnr} طريقة الفصل (ممتدة)",
        "name_de": "{!inningnr} innings - {$competitor1} {!dismissalnr} dismissal method (extended)",
        "name_nl": "{inningnr} innings - {$competitor1} {dismissalnr} dismissal methode (uitgebreid)",
        "name_pl": "{!inningnr} innings - {$competitor1} {!dismissalnr} metoda wyeliminowania (rozszerzony)",
        "name_ro": "Innings {!inningnr} - {$competitor1} metoda demiterii {!dismissalnr} (extins)",
        "name_ru": "{!inningnr} иннингов - {$competitor1} {!dismissalnr} методов выбиваний (расширенный)",
        "name_tr": "{!inningnr} devre - {$competitor1} - {!dismissalnr} çıkarılma method (uzatılmış)",
        "name_se": "{!inningnr} innings - {$competitor1} {!dismissalnr} uppsägnings metod (förlängd)",
        "name_el": "{!inningnr} innings - {$competitor1} {!dismissalnr} μέθοδος αποβολής"
    },
    "id_98": {
        "name_en": "2nd half - correct score",
        "name_aa": "الشوط الثاني - النتيجة الصحيحة",
        "name_de": "2. Halbzeit - Genaues Ergebnis",
        "name_nl": "2e helft - juiste uitslag",
        "name_pl": "2. Połowa - Dokładny wynik",
        "name_ro": "A 2-a repriză - scor corect",
        "name_ru": "2ая половина - точный счет",
        "name_tr": "ikinci yarı - skor",
        "name_se": "2:a halvlek - rätt resultat",
        "name_el": "2ο ημίχρονο - ακριβές σκορ"
    },
    "id_192": {
        "name_en": "{$competitor1} to win a set",
        "name_aa": "{$competitor1} للفوز بمجموعة",
        "name_de": "{$competitor1} wird einen Satz gewinnen",
        "name_nl": "{$competitor1} wint een set",
        "name_pl": "{$competitor1} wygra seta",
        "name_ro": "Să câștige un set {$competitor1}",
        "name_ru": "{$competitor1} выиграет сет",
        "name_tr": "{$competitor1} set kazanır",
        "name_se": "{$competitor1} att vinna set",
        "name_el": "{$competitor1} να κερδίσει ένα σετ"
    },
    "id_53": {
        "name_en": "{$competitor1} highest scoring half",
        "name_aa": "{$competitor1}أعلى التهديف الشوط",
        "name_de": "{$competitor1} Halbzeit mit den meisten Toren",
        "name_nl": "{$competitor1} wint helft met de meeste doelpunten",
        "name_pl": "{$competitor1} Połowa z największą liczbą goli",
        "name_ro": "{$competitor1} repriza cu cele mai multe goluri",
        "name_ru": "{$competitor1} самая результативная половина",
        "name_tr": "{$competitor1} en gollü devre",
        "name_se": "{$competitor1} halvlek med flest mål",
        "name_el": "{$competitor1} ημίχρονο με περισσότερα γκολ"
    },
    "id_670": {
        "name_en": "{!inningnr} innings - {$competitor1} total in the highest scoring over",
        "name_aa": "{!inningnr} أدوار - {$competitor1} الإجمالي في أعلى الدرجات",
        "name_de": "{!inningnr} Inning - {$competitor1} Total im Over mit dem höchsten Score",
        "name_nl": "{inningnr} innings - {$competitor1} totaal in de over met de hoogste score",
        "name_pl": "{!inningnr} innings - {$competitor1} suma w najwyżej punktującym over",
        "name_ro": "{!inningnr} innings - {$competitor1} total în over-ul cu cel mai bun scor",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал в самом результативном овере",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplamda en yüksek sayı yapan",
        "name_se": "{!inningnr} innings - {$competitor1} totalt över högst poäng",
        "name_el": "{!inningnr} innings - {$competitor1} total in the highest scoring over"
    },
    "id_553": {
        "name_en": "2nd half - multigoals",
        "name_aa": "الشوط الثاني - أهداف متعددة",
        "name_de": "2. Halbzeit - Gesamtzahl der Tore im Spiel",
        "name_nl": "Tweede helft - multigoals",
        "name_pl": "2. Połowa - multi-gole",
        "name_ro": "Repriza 2 - multigoluri",
        "name_ru": "2ая половина - мултиголы",
        "name_tr": "İkinci yarı - birden fazla gol",
        "name_se": "2:a halvlek - multimål",
        "name_el": "2ο ημίχρονο - Γκολ (πολλαπλών επιλογών)"
    },
    "id_432": {
        "name_en": "Highest scoring period",
        "name_aa": "أعلى فترة تسجيل",
        "name_de": "Periode mit den meisten Toren",
        "name_nl": "Periode met de hoogste score",
        "name_pl": "Najwyżej punktująca część gry",
        "name_ro": "Repriza cu cele mai multe înscrieri",
        "name_ru": "Самый результативный период",
        "name_tr": "En çok sayı olan periyot",
        "name_se": "Period med högst poäng",
        "name_el": "Περίοδος με υψηλότερο σκορ"
    },
    "id_848": {
        "name_en": "{$event} - top {pos} exact order",
        "name_aa": "{$event} - أعلى {pos} الترتيب الدقيق",
        "name_de": "{$event} - Spitze {pos} genaue Reihenfolge",
        "name_nl": "{$event} - top {pos} juiste volgorde",
        "name_pl": "{$event} - najlepsza {pos} dokładna kolejność",
        "name_ro": "{$event} - top {pos} ordine exactă",
        "name_ru": "{$event} - верхняя часть {pos} точный порядок",
        "name_tr": "{$event} - top {pos} kesin order",
        "name_se": "{$event} - topp {pos} exakta besked",
        "name_el": "{$event} - top {pos} ακριβής σειρά"
    },
    "id_331": {
        "name_en": "{!mapnr} map - round handicap (incl. overtime)",
        "name_aa": "خريطة   {!mapnr} - جولة هانديكاب (بما في ذلك العمل الإضافي)",
        "name_de": "{!mapnr} Map - Runden Handicap (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - round handicap (overtime inbegrepen)",
        "name_pl": "{!mapnr} mapa - rundy handicap (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - handicap rundă (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - гандикап раунда (включая овертайм)",
        "name_tr": "{mapnr}. harita - raund handikapı (uzatma süreleri dahil)",
        "name_se": "{!mapnr} karta - rundor handikapp (inkl. övertid)",
        "name_el": "{!mapnr} map - round χάντικαπ (παράταση συμπ.)"
    },
    "id_217": {
        "name_en": "{!setnr} set game {gamenr} - first {pointnr} points winner",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - لأول مرة {pointnr} نقاط الفائز",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Gewinner der ersten {pointnr} Punkte",
        "name_nl": "{setnr} set game {gamenr} - winnaar eerste {pointnr} punten",
        "name_pl": "{!setnr} set gem {gamenr}  - pierwsze {pointnr} punktów zwycięzca",
        "name_ro": "Set {!setnr} game {gamenr} - câștigătorul primelor {pointnr} puncte",
        "name_ru": "{!setnr} сет {gamenr} гейм - победитель первых {pointnr} очков",
        "name_tr": "{!setnr}. set {gamenr}. oyun - ilk {pointnr} sayının kazananı",
        "name_se": "{!setnr} set game {gamenr} - första {pointnr} poäng vinnare",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - πρώτοι {pointnr} πόντοι"
    },
    "id_567": {
        "name_en": "15 minutes - corner handicap from {from} to {to}",
        "name_aa": "15 دقيقة - هانديكاب في الزاوية من {from} إلى {to}",
        "name_de": "15 Minuten - Ecken Handicap von {from} bis {to}",
        "name_nl": "15 minutes - hoekschop handicap van {from} tot {to}",
        "name_pl": "15 minut - rzut rożny handicap od {from} do {to}",
        "name_ro": "15 minute - handicap cornere de la {from} la {to}",
        "name_ru": "15 минут - угловой гандикап с {from} до {to}",
        "name_tr": "15 dakika - korner handikap {from} dan {to} a",
        "name_se": "15 minuter - hörnor handikapp från {from} till {to}",
        "name_el": "15 λεπτά - κόρνερ χάντικαπ από {from} μέχρι {to}"
    },
    "id_766": {
        "name_en": "Player receiving touchdowns (incl. overtime)",
        "name_aa": "لاعب تلقي لمس أرض",
        "name_de": "Spieler Receiving Touchdowns (inkl. Verlängerung)",
        "name_nl": "Speler receiving touchdowns",
        "name_pl": "Player receiving touchdowns (incl. overtime)",
        "name_ro": "Yarzi primire touchdowns jucător",
        "name_ru": "Игрок принимающий тачдаунов",
        "name_tr": "Oyuncu touchdown alıyor",
        "name_se": "Spelare - mottagningstouchdowns",
        "name_el": "Παίχτης receiving touchdowns"
    },
    "id_515": {
        "name_en": "{!framenr} frame - player to pot last ball",
        "name_aa": "إطار {framenr!}- لاعب للعب الكرة الأخيرة",
        "name_de": "{!framenr} Frame - Spieler locht letzte Kugel ein",
        "name_nl": "{framenr} frame - speler die de laatste bal pot",
        "name_pl": "{!framenr} frejm - zawodnik z ostatnią wbitą kulą",
        "name_ro": "Frame-ul {!framenr} - jucătorul care introduce ultima bilă",
        "name_ru": "{!framenr} фрейм - игрок, который загонит в лузу последний шар",
        "name_tr": "{!framenr}. frame - oyuncu pota son top",
        "name_se": "{!framenr} frame - spelare som sätter sista bollen",
        "name_el": "{!framenr} frame - παίχτης που θα κάνει pot τελευταία μπάλα"
    },
    "id_768": {
        "name_en": "Player points (incl. overtime)",
        "name_aa": "نقاط اللاعب",
        "name_de": "Spieler Punkte (inkl. Verlängerung)",
        "name_nl": "Speler punten",
        "name_pl": "Player points (incl. overtime)",
        "name_ro": "Puncte jucător",
        "name_ru": "Игрок набравший больше очков",
        "name_tr": "Oyuncu puanları",
        "name_se": "Spelarpoäng",
        "name_el": "Παίχτης πόντοι"
    },
    "id_426": {
        "name_en": "{!periodnr} period 1x2 & winner (incl. overtime and penalties)",
        "name_aa": "{!periodnr} الفترة 1 × 2 والفائز (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{!periodnr} Periode 1x2 & Gewinner (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{periodnr} periode 1x2 & winnaar (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{!periodnr} część gry 1x2 & zwycięzca (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Repriza {!periodnr} 1x2 & câștigătoare (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{!periodnr} период 1х2 и победитель (включая овертайм и пенальти)",
        "name_tr": "{!periodnr}. periyot 1x2 & kazanan (uzatmalar ve penaltılar dahil)",
        "name_se": "{!periodnr} period 1x2 & vinnare (inkl. övertid och straffar)",
        "name_el": "{!periodnr} περίοδος και νικητής (παράταση και πέναλτι συμπ.)"
    },
    "id_909": {
        "name_en": "{!touchdownnr} touchdown scorer (incl. overtime)",
        "name_aa": "{!touchdownnr} touchdown scorer (incl. overtime)",
        "name_de": "{!touchdownnr} Touchdown-Scorer (inkl. Verlängerung)",
        "name_nl": "{touchdownnr} touchdown scorer (incl. extra tijd)",
        "name_pl": "{!touchdownnr} touchdown scorer (incl. overtime)",
        "name_ro": "{!touchdownnr} touchdown scorer (incl. overtime)",
        "name_ru": "{!touchdownnr} тачдаун бомбардир (включая овертайм)",
        "name_tr": "{!touchdownnr} touchdown scorer (incl. overtime)",
        "name_se": "{!touchdownnr} touchdown-görare (inkl. övertid)",
        "name_el": "{!touchdownnr} touchdown scorer (incl. overtime)"
    },
    "id_569": {
        "name_en": "15 minutes - {$competitor1} total corners from {from} to {to}",
        "name_aa": "15 دقيقة - {$competitor1} إجمالي الزوايا من {from} إلى {to}",
        "name_de": "15 Minuten - {$competitor1} Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "15 minutes - totaal aantal hoekschoppen {$competitor1} van {from} tot {to}",
        "name_pl": "15 minut - {$competitor1} suma rzutów rożnych od {from} do {to}",
        "name_ro": "15 minute - {$competitor1} total cornere de la {from} la {to}",
        "name_ru": "15 минут - {$competitor1} всего угловых с {from} до {to}",
        "name_tr": "15 dakika - {$competitor1} toplam korner sayısı {from} den {to} a",
        "name_se": "15 minuter - {$competitor1} totalt antal hörnor från {from} till {to}",
        "name_el": "15 λεπτά - {$competitor1}  σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_289": {
        "name_en": "Which team wins the jump ball",
        "name_aa": "الفريق الذي يفوز في كرة القفز",
        "name_de": "Wer gewinnt den Sprungball?",
        "name_nl": "Welk team wint de sprongbal",
        "name_pl": "Który zespół wygra rzut sędziowski?",
        "name_ro": "Care echipă câștigă punerea în joc",
        "name_ru": "Какая команда выиграет спорный мяч",
        "name_tr": "Hava atışını hangi takım kazanır",
        "name_se": "Vilket lag vinner uppkastet",
        "name_el": "Ποια ομάδα θα κερδίσει το τζάμπολ"
    },
    "id_656": {
        "name_en": "{!inningnr} innings - {!dismissalnr} partnership 1x2",
        "name_aa": "{!inningnr} أدوار - {!dismissalnr} مشاركة 1 × 2",
        "name_de": "{!inningnr} Inning - {!dismissalnr} Partnerschaft 1x2",
        "name_nl": "{inningnr} innings - {dismissalnr} partnership 1x2",
        "name_pl": "{!inningnr} innings - {!dismissalnr} partnership 1x2",
        "name_ro": "{!inningnr} innings - 1x2 parteneriat {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {!dismissalnr} сотрудничество 1x2",
        "name_tr": "{!inningnr}. devre - {!dismissalnr}. partnership 1x2",
        "name_se": "{!inningnr} innings - {!dismissalnr} partnerskap 1x2",
        "name_el": "{!inningnr} innings - {!dismissalnr} partnership 1x2"
    },
    "id_329": {
        "name_en": "Correct score (in maps)",
        "name_aa": "النتيجة الصحيحة (في الخرائط)",
        "name_de": "Genaues Ergebnis (in Maps)",
        "name_nl": "Juiste score (in maps)",
        "name_pl": "Dokładny wynik ( w mapach)",
        "name_ro": "Scor corect (în hărți)",
        "name_ru": "Точный счет (в картах)",
        "name_tr": "Doğru skor (haritada)",
        "name_se": "Rätt resultat (i kartor)",
        "name_el": "Ακριβές σκορ (maps)"
    },
    "id_1120": {
        "name_en": "Total home runs (incl. extra innings)",
        "name_aa": "Total home runs (incl. extra innings)",
        "name_de": "Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns (incl. extra innings)",
        "name_pl": "Total home runs (incl. extra innings)",
        "name_ro": "Total home runs (incl. extra innings)",
        "name_ru": "Тотал хоум раны (включая экстра иннинги)",
        "name_tr": "Total home runs (incl. extra innings)",
        "name_se": "Total home runs (incl. extra innings)",
        "name_el": "Σύνολο home runs (συμπ. extra innings)"
    },
    "id_1029": {
        "name_en": "Holes {from} to {to} - 2 ball (handicap)",
        "name_aa": "Holes {from} to {to} - 2 ball (handicap)",
        "name_de": "Holes {from} to {to} - 2 ball (handicap)",
        "name_nl": "Holes {from} tot {to} - 2 ball (handicap)",
        "name_pl": "Holes {from} to {to} - 2 ball (handicap)",
        "name_ro": "Holes {from} to {to} - 2 ball (handicap)",
        "name_ru": "Holes {from} to {to} - 2 ball (handicap)",
        "name_tr": "Holes {from} to {to} - 2 ball (handicap)",
        "name_se": "Holes {from} to {to} - 2 ball (handicap)",
        "name_el": "Holes {from} to {to} - 2 ball (handicap)"
    },
    "id_306": {
        "name_en": "How many sets will be decided by extra points",
        "name_aa": "كم عدد المجموعات التي تقررها نقاط اضافية",
        "name_de": "Wie viele Sätze werden durch Extrapunkte entschieden",
        "name_nl": "Hoeveel sets worden beslist door extra punten",
        "name_pl": "Ile setów będzie rozstrzygniętych przez ekstra punkty",
        "name_ro": "Câte set-uri se vor decide prin puncte extra",
        "name_ru": "Сколько сетов будут определены дополнительными очками",
        "name_tr": "Ekstra sayılarla kaç sete karar verilecek",
        "name_se": "Hur många sets avgörs med extra poäng",
        "name_el": "Σε πόσα σετ θα χρειαστούν έξτρα πόντοι"
    },
    "id_593": {
        "name_en": "10 minutes - sending off from {from} to {to}",
        "name_aa": "10 دقائق - طرد من {from} إلى {to}",
        "name_de": "10 Minuten - Platzverweise von {from} bis {to}",
        "name_nl": "10 minutes - uitsluiting van {from} tot {to}",
        "name_pl": "10 minut - czerwona kartka od {from} do {to}",
        "name_ro": "10 minute - eliminare de la {from} la {to}",
        "name_ru": "10 минут - удаление с {from} до {to}",
        "name_tr": "10 dakika - oyundan atılma {from} dan {to} a",
        "name_se": "10 minuter - utvisning från {from} till {to}",
        "name_el": "10 λεπτά - να αποβληθεί από {from} μέχρι {to}"
    },
    "id_232": {
        "name_en": "2nd half - total (incl. overtime)",
        "name_aa": "الشوط الثاني - الإجمالي (بما في ذلك الوقت الإضافي)",
        "name_de": "2. Halbzeit - Total (inkl. Verlängerung)",
        "name_nl": "Tweede helft - totaal (verlenging inbegrepen)",
        "name_pl": "2. połowa - suma (włącznie z dogrywką)",
        "name_ro": "Repriza 2 - total (incl. prelungiri)",
        "name_ru": "2ая половина - тотал (включая овертайм)",
        "name_tr": "İkinci yarı - toplam gol (uzatmalar dahil)",
        "name_se": "2:a halvlek - totalt (inkl. övertid)",
        "name_el": "2ο ημίχρονο - σύνολο (παράταση συμπ.)"
    },
    "id_1133": {
        "name_en": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_aa": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_de": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_nl": "{!inningsnr} innings over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_pl": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_ro": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_ru": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_tr": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_se": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}",
        "name_el": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor2} over {total}"
    },
    "id_1602": {
        "name_en": "Double chance & Multigoals",
        "name_aa": "Double chance & Multigoals",
        "name_de": "Double chance & Multigoals",
        "name_nl": "Double chance & Multigoals",
        "name_pl": "Double chance & Multigoals",
        "name_ro": "Double chance & Multigoals",
        "name_ru": "Double chance & Multigoals",
        "name_tr": "Double chance & Multigoals",
        "name_se": "Double chance & Multigoals",
        "name_el": "Double chance & Multigoals"
    },
    "id_448": {
        "name_en": "{!periodnr} period - {$competitor2} total",
        "name_aa": "فترة {!periodnr} - {$competitor2} مجموعه",
        "name_de": "{!periodnr} Periode - {$competitor2} Total",
        "name_nl": "{periodnr} periode - totaal {$competitor2}",
        "name_pl": "{!periodnr} część gry - {$competitor2} suma",
        "name_ro": "Repriza {!periodnr} - total {$competitor2}",
        "name_ru": "{!periodnr} период - {$competitor2} тотал",
        "name_tr": "{!periodnr} . periyot - {$competitor2} toplam",
        "name_se": "{!periodnr} period - {$competitor2} totalt",
        "name_el": "{!periodnr} περίοδος - {$competitor2} σύνολο"
    },
    "id_1632": {
        "name_en": "1st half - Double chance and Total Halftime or Fulltime",
        "name_aa": "1st half - Double chance and Total Halftime or Fulltime",
        "name_de": "1st half - Double chance and Total Halftime or Fulltime",
        "name_nl": "1st half - Double chance and Total Halftime or Fulltime",
        "name_pl": "1st half - Double chance and Total Halftime or Fulltime",
        "name_ro": "1st half - Double chance and Total Halftime or Fulltime",
        "name_ru": "1st half - Double chance and Total Halftime or Fulltime",
        "name_tr": "1st half - Double chance and Total Halftime or Fulltime",
        "name_se": "1st half - Double chance and Total Halftime or Fulltime",
        "name_el": "1st half - Double chance and Total Halftime or Fulltime"
    },
    "id_130": {
        "name_en": "Penalty shootout - exact goals",
        "name_aa": "ركلات الترجيح - الأهداف المحددة",
        "name_de": "Elfmeterschießen - Genaue Anzahl Tore",
        "name_nl": "Strafschoppenserie - exact aantal doelpunten",
        "name_pl": "Rzuty karne - liczba goli",
        "name_ro": "Lovituri de departajare - goluri exact",
        "name_ru": "Серия пенальти - точное число голов",
        "name_tr": "Penaltılar - doğru skor",
        "name_se": "Straffar - exakt antal mål",
        "name_el": "Διαδικασία πέναλτι - Ακριβής αριθμός γκολ"
    },
    "id_878": {
        "name_en": "{!inningnr} innings - {$competitor2} total",
        "name_aa": "{!inningnr} أدوار - {$competitor2} الإجمالي",
        "name_de": "{!inningnr} Innings - {$competitor2} insgesamt",
        "name_nl": "{!inningnr} innings - Totaal {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma",
        "name_ro": "Innings {!inningnr} - {$competitor2} total",
        "name_ru": "{!inningnr} иннинги - {$competitor2} итог",
        "name_tr": "{!inningnr} devre - {$competitor2} toplam",
        "name_se": "{!inningnr} innings - {$competitor2} totalt",
        "name_el": "{!inningnr} innings - {$competitor2} total"
    },
    "id_681": {
        "name_en": "Most run outs",
        "name_aa": "معظم الجري خارج",
        "name_de": "Meiste Run outs",
        "name_nl": "Meeste run outs",
        "name_pl": "Najwięcej run outs",
        "name_ro": "Cele mai multe run outs",
        "name_ru": "Большинство ран-аутов",
        "name_tr": "En çok dışarı koşu",
        "name_se": "Flest run outs",
        "name_el": "Most run outs"
    },
    "id_366": {
        "name_en": "Leg handicap",
        "name_aa": "هانديكاب الساق",
        "name_de": "Runden handicap",
        "name_nl": "Leg handicap",
        "name_pl": "Legi handicap",
        "name_ro": "Handicap leg",
        "name_ru": "Гандикап этапов",
        "name_tr": "Ayak handikabı",
        "name_se": "Leg handikapp",
        "name_el": "Leg χάντικαπ"
    },
    "id_551": {
        "name_en": "Multiscores",
        "name_aa": "نتائج متعددة",
        "name_de": "Endergebnis",
        "name_nl": "Multiscores",
        "name_pl": "Multi-wyniki",
        "name_ro": "Multiscoruri",
        "name_ru": "Мультисчет",
        "name_tr": "Toplam skor",
        "name_se": "Multiscores",
        "name_el": "Γκολ (πολλαπλά)"
    },
    "id_1150": {
        "name_en": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_aa": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_de": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_nl": "Overs 0 tot {overnr} - {$competitor1} totaal ({ballcount} ball)",
        "name_pl": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_ro": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_ru": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_tr": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_se": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)",
        "name_el": "Overs 0 to {overnr} - {$competitor1} total ({ballcount} ball)"
    },
    "id_743": {
        "name_en": "Innings {from} to {to} - 1x2",
        "name_aa": "أوقات من {from} إلى {to} - 1 × 2",
        "name_de": "Innings {from} bis {to} - 1x2",
        "name_nl": "Innings {from} tot {to} - 1x2",
        "name_pl": "Innings {from} do {to} - 1x2",
        "name_ro": "Innings de la {from} la {to} - 1x2",
        "name_ru": "Иннинги от {from} до {to} - 1х2",
        "name_tr": "Devre {from} den {to} e - 1x2",
        "name_se": "Innings {from} till {to} - 1x2",
        "name_el": "Innings {from} to {to} - 1x2"
    },
    "id_737": {
        "name_en": "Maximum consecutive runs by either team",
        "name_aa": "يدير القصوى متتالية من قبل أي فريق",
        "name_de": "Maximale aufeinanderfolgende Läufe von beiden Teams",
        "name_nl": "Maximaal aantal opeenvolgende runs",
        "name_pl": "Maksymalna liczba kolejnych runs dowolego zespołu",
        "name_ro": "Numărul maxim de run-uri consecutive de către oricare echipă",
        "name_ru": "Максимум последовательных пробежек у любой команды",
        "name_tr": "Maksimum ardarda koşu",
        "name_se": "Maximala runs av något lag i följd",
        "name_el": "Μάξιμουμ συνεχόμενα runs από οποιοδήποτε ομάδα"
    },
    "id_283": {
        "name_en": "Innings 1 to 5th top - {$competitor2} total",
        "name_aa": "ألدور 1إلى5 أعلى - {$competitor2} الإجمالي",
        "name_de": "Innings 1 bis Anfang 5tes - {$competitor2} Total",
        "name_nl": "Eerste helft (top) Inning 1 tot 5 - {$competitor2} totaal",
        "name_pl": "Innings 1 do początku 5. - {$competitor2} suma",
        "name_ro": "Innings de la 1 la 5 top - total {$competitor2}",
        "name_ru": "Иннинги с 1 до 5го верха - {$competitor2} тотал",
        "name_tr": "Devreler 1den 5in sonuna - {$competitor2} toplam",
        "name_se": "Innings 1 till 5th topp - {$competitor2} totalt",
        "name_el": "Innings 1 σε 5 top - σύνολο {$competitor2}"
    },
    "id_981": {
        "name_en": "{$competitor2} joy of six spread",
        "name_aa": "{$competitor2} joy of six spread",
        "name_de": "{$competitor2} joy of six spread",
        "name_nl": "{$competitor2} joy van zespunter spread",
        "name_pl": "{$competitor2} joy of six spread",
        "name_ro": "{$competitor2} joy of six spread",
        "name_ru": "{$competitor2} радость шести ранов спред",
        "name_tr": "{$competitor2} joy of six spread",
        "name_se": "{$competitor2} joy of six spread",
        "name_el": "{$competitor2} joy of six spread"
    },
    "id_500": {
        "name_en": "{!framenr} frame - point handicap",
        "name_aa": "إطار {framenr!} -هانديكاب نقطة",
        "name_de": "{!framenr} Frame - Punkte Handicap",
        "name_nl": "{framenr} frame - punten handicap",
        "name_pl": "{!framenr} frejm - punkty hndicap",
        "name_ro": "Frame-ul {!framenr} - handicap puncte",
        "name_ru": "{!framenr} фрейм - гандикап очков",
        "name_tr": "{!framenr}. frame - sayı handikapı",
        "name_se": "{!framenr} frame - poäng handikapp",
        "name_el": "{!framenr} frame - Χάντικαπ πόντων"
    },
    "id_324": {
        "name_en": "{!setnr} set end {endnr} - exact points",
        "name_aa": "{!setnr} setnr وضع نهاية {endnr} - نقطة بالضبط",
        "name_de": "{!setnr} Satzende {endnr} - Genaue Anzahl Punkte",
        "name_nl": "{setnr} set end {endnr} - exact aantal punten",
        "name_pl": "{!setnr} set koniec {endnr} - dokładna liczba punktów",
        "name_ro": "Set-ul {!setnr} end-ul {endnr} - puncte exact",
        "name_ru": "{!setnr} сет {endnr} конец - точное количество очков",
        "name_tr": "{!setnr}. set {endnr}. end - doğru skor",
        "name_se": "{!setnr} set slut {endnr} - exakt antal poäng",
        "name_el": "{!setnr} set end {endnr} - Ακριβής αριθμός πόντων"
    },
    "id_1058": {
        "name_en": "{!scorenr} scoring type",
        "name_aa": "{!scorenr} scoring type",
        "name_de": "{!scorenr} scoring type",
        "name_nl": "{scorenr} goaltype",
        "name_pl": "{!scorenr} typ wyniku",
        "name_ro": "{!scorenr} scoring type",
        "name_ru": "{!scorenr} тип счета",
        "name_tr": "{!scorenr} scoring type",
        "name_se": "{!scorenr} poängtypen",
        "name_el": "{!scorenr} τύπος σκοραρίσματος"
    },
    "id_438": {
        "name_en": "{$competitor2} to win any period",
        "name_aa": "{$competitor2} للفوز بأي فترة",
        "name_de": "{$competitor2} wird irgendeine Periode gewinnen",
        "name_nl": "{$competitor2}wint een periode",
        "name_pl": "{$competitor2} wygra którąkolwiek część gry",
        "name_ro": "{$competitor2} să câștige oricare repriză",
        "name_ru": "{$competitor2} выиграть любой период",
        "name_tr": "{$competitor2} herhangi bir periyodu kazanır",
        "name_se": "{$competitor2} att vinna någon period",
        "name_el": "{$competitor2} να κερδίσει οποιαδήποτε περίοδο"
    },
    "id_771": {
        "name_en": "Player blocks (incl. overtime)",
        "name_aa": "كتل لاعب",
        "name_de": "Spieler Blöcke (inkl. Verlängerung)",
        "name_nl": "Speler blocks",
        "name_pl": "Player blocks (incl. overtime)",
        "name_ro": "Blocări jucător",
        "name_ru": "Игрок сделавший больше блоков",
        "name_tr": "Oyuncu bloklamaları",
        "name_se": "Spelarblock",
        "name_el": "Παίχτης μπλοκ"
    },
    "id_997": {
        "name_en": "Launch spread",
        "name_aa": "Launch spread",
        "name_de": "Launch spread",
        "name_nl": "Launch spread",
        "name_pl": "Launch spread",
        "name_ro": "Launch spread",
        "name_ru": "Лаунч спред",
        "name_tr": "Launch spread",
        "name_se": "Launch spread",
        "name_el": "Launch spread"
    },
    "id_1603": {
        "name_en": "Multiscore-3",
        "name_aa": "Multiscore-3",
        "name_de": "Multiscore-3",
        "name_nl": "Multiscore-3",
        "name_pl": "Multiscore-3",
        "name_ro": "Multiscore-3",
        "name_ru": "Multiscore-3",
        "name_tr": "Multiscore-3",
        "name_se": "Multiscore-3",
        "name_el": "Multiscore-3"
    },
    "id_830": {
        "name_en": "Bonus ball range",
        "name_aa": "نطاق الكرة المكافئة",
        "name_de": "Zusatzzahl Bereich",
        "name_nl": "Reservebal range",
        "name_pl": "Bonus ball range",
        "name_ro": "Bilă bonus interval",
        "name_ru": "Бонус бол разница",
        "name_tr": "Bonus ball aralık",
        "name_se": "Bonusbollintervall",
        "name_el": "Bonus ball range"
    },
    "id_1144": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_aa": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_de": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_nl": "{!inningnr} innings over {overnr} - {$competitor2} scoort een boundary vier & een zes",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_ru": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_tr": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_se": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} to score a boundary four & a six"
    },
    "id_208": {
        "name_en": "{!setnr} set - race to {games} games",
        "name_aa": "تم تعيين {!setnr} - سباق على ألعاب {games}",
        "name_de": "{!setnr} Satz - Zuerst {games} Spiele",
        "name_nl": "{setnr} set - race naar {games} games",
        "name_pl": "{!setnr} set - pierwszy z {games} gemami",
        "name_ro": "Set {!setnr} - primul la {games} game-uri",
        "name_ru": "{!setnr} сет - гонка до {games} геймов",
        "name_tr": "{!setnr}. set - {games} oyun çekişmesi",
        "name_se": "{!setnr} set - först till {games} games",
        "name_el": "{!setnr} σετ - Ποιος θα φτάσει 1ος τα {games} γκέιμ"
    },
    "id_1066": {
        "name_en": "{!drivenr} drive - {%competitor} result",
        "name_aa": "{!drivenr} drive - {!competitor} result",
        "name_de": "{!drivenr} drive - {!competitor} result",
        "name_nl": "{!drivenr} drive - {!competitor} resultaat",
        "name_pl": "{!drivenr} drive - {!competitor} result",
        "name_ro": "{!drivenr} drive - {!competitor} result",
        "name_ru": "{!drivenr} драйв - {!competitor} результат",
        "name_tr": "{!drivenr} drive - {!competitor} result",
        "name_se": "{!drivenr} drive - {!competitor} result",
        "name_el": "{!drivenr} drive - {!competitor} αποτέλεσμα"
    },
    "id_334": {
        "name_en": "{!mapnr} map - 1x2",
        "name_aa": "خريطة {!mapnr} - 1X2",
        "name_de": "{!mapnr} Map - 1x2",
        "name_nl": "{mapnr} map - 1x2",
        "name_pl": "{!mapnr} mapa - 1x2",
        "name_ro": "Harta {!mapnr} - 1x2",
        "name_ru": "{!mapnr} карта - 1x2",
        "name_tr": "{!mapnr}. map - 1x2",
        "name_se": "{!mapnr} karta - 1x2",
        "name_el": "{!mapnr} map - 1X2"
    },
    "id_630": {
        "name_en": "{!mapnr} map round {roundnr} - {$competitor2} total kills",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - {$competitor2}إجمالي القتلى",
        "name_de": "{!mapnr} Map Runde {roundnr} - {$competitor2} Total Kills",
        "name_nl": "{mapnr} map round {roundnr} - totaal aantal kills {$competitor2}",
        "name_pl": "{!mapnr} mapa runda {roundnr} - {$competitor2} suma zabójstw",
        "name_ro": "Harta {!mapnr} runda {roundnr} - total kills {$competitor2}",
        "name_ru": "{!mapnr} карта {roundnr} раунд - {$competitor2} тотал убийств",
        "name_tr": "{!mapnr}. map {roundnr}. raund - {$competitor2} toplam kill",
        "name_se": "{!mapnr} kartrunda {roundnr} - {$competitor2} totalt antal kills",
        "name_el": "{!mapnr} map, γύρος {roundnr} - {$competitor2} total kills"
    },
    "id_985": {
        "name_en": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_aa": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_de": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_nl": "{inningnr} innings - {$competitor2} totaal aantal {upsnr} ups spread",
        "name_pl": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_ro": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал {upsnr} ups спред",
        "name_tr": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_se": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread",
        "name_el": "{!inningnr} innings - {$competitor2} total {upsnr} ups spread"
    },
    "id_746": {
        "name_en": "{!inningnr} inning - handicap",
        "name_aa": "{!inningnr} الشوط - هانديكاب",
        "name_de": "{!inningnr} Inning - Handicap",
        "name_nl": "{inningnr} inning - handicap",
        "name_pl": "{!inningnr} inning - handicap",
        "name_ro": "{!inningnr} inning - handicap",
        "name_ru": "{!inningnr} иннинг - гандикап",
        "name_tr": "{!inningnr}. devre - handikap",
        "name_se": "{!inningnr} inning - handikapp",
        "name_el": "{!inningnr} inning - handicap"
    },
    "id_1080": {
        "name_en": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_aa": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_de": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_nl": "{inningnr} inning - exact aantal strike-outs {$competitor2}",
        "name_pl": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_ro": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_ru": "{!inningnr} иннинг - {$competitor2} точные страйк-ауты",
        "name_tr": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_se": "{!inningnr} inning - {$competitor2} exact strikeouts",
        "name_el": "{!inningnr} inning - {$competitor2} ακριβής αριθμός  strikeouts"
    },
    "id_1622": {
        "name_en": "Score in first 30 min",
        "name_aa": "Score in first 30 min",
        "name_de": "Score in first 30 min",
        "name_nl": "Score in first 30 min",
        "name_pl": "Score in first 30 min",
        "name_ro": "Score in first 30 min",
        "name_ru": "Score in first 30 min",
        "name_tr": "Score in first 30 min",
        "name_se": "Score in first 30 min",
        "name_el": "Score in first 30 min"
    },
    "id_887": {
        "name_en": "1 minute - total penalties awarded from {from} to {to}",
        "name_aa": "دقيقة واحدة - مجموع العقوبات الممنوحة من {from} إلى {to}",
        "name_de": "1 Minute - insgesamte Strafstöße von {from} bis {to}",
        "name_nl": "1 minuut - totaal aantal strafschoppen toegekend van {from} tot {to}",
        "name_pl": "1 minute - total penalties awarded from {from} to {to}",
        "name_ro": "1 minut - total penaltiuri acordate de la {from} la {to}",
        "name_ru": "1 минута - итого назначенных пенальти с {from} по {to}",
        "name_tr": "1 dakika - toplam penaltı kararı {from}'dan {to}'a kadar",
        "name_se": "1 minute - totala straffar tilldelats från {from} till {to}",
        "name_el": "1 λεπτό - σύνολο πέναλτι  από {from} μέχρι {to}"
    },
    "id_478": {
        "name_en": "Total tries",
        "name_aa": "مجموع محاولات",
        "name_de": "Total Versuche",
        "name_nl": "Totaal aantal tries",
        "name_pl": "Suma przyłożeń",
        "name_ro": "Total încercări",
        "name_ru": "Тотал попыток",
        "name_tr": "Toplam try",
        "name_se": "Totalt antal försök",
        "name_el": "Σύνολο tries"
    },
    "id_578": {
        "name_en": "10 minutes - odd/even corners from {from} to {to}",
        "name_aa": "10 دقائق - فردي / زوجي زوايا من {from} إلى {to}",
        "name_de": "10 Minuten - Gerade/ungerade Ecken von {from} bis {to}",
        "name_nl": "10 minutes - even/oneven aantal hoekschoppen van {from} tot {to}",
        "name_pl": "10 minut - parzyste/nieparzyste rzuty rożne od {from} do {to}",
        "name_ro": "10 minute - număr de cornere impar/par de la {from} la {to}",
        "name_ru": "10 минут - чет/нечет угловые с {from} до {to}",
        "name_tr": "10 dakika - tek/çift korner {from} dan {to} a",
        "name_se": "10 minuter - udda/jämnt hörnor från {from} till {to}",
        "name_el": "10 λεπτά - μονά/ζυγά κόρνερ από {from} μέχρι {to}"
    },
    "id_695": {
        "name_en": "Total ducks",
        "name_aa": "البط الكلي",
        "name_de": "Ducks gesamt",
        "name_nl": "Totaal aantal ducks",
        "name_pl": "Total ducks",
        "name_ro": "Total ducks",
        "name_ru": "Тотал бэтсмен с нулевым результатом",
        "name_tr": "Toplam duck",
        "name_se": "Totalt ducks",
        "name_el": "Total ducks"
    },
    "id_137": {
        "name_en": "{!bookingnr} booking",
        "name_aa": "{!bookingnr} الحجز",
        "name_de": "{!bookingnr} Karten",
        "name_nl": "{bookingnr} kaart",
        "name_pl": "{!bookingnr} Kartka",
        "name_ro": "Cartonașul {!bookingnr}",
        "name_ru": "{bookingnr} карточка",
        "name_tr": "{!bookingnr} kart",
        "name_se": "{!bookingnr} kort",
        "name_el": "{!bookingnr} κάρτα"
    },
    "id_974": {
        "name_en": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_aa": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_de": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_nl": "{inningnr} innings - {$competitor1} totaal aantal zespunters spread",
        "name_pl": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_ro": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал спред шести ранов",
        "name_tr": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_se": "{!inningnr} innings - {$competitor1} total sixes spread",
        "name_el": "{!inningnr} innings - {$competitor1} total sixes spread"
    },
    "id_113": {
        "name_en": "Overtime - 1x2",
        "name_aa": "الوقت الإضافي - 1x2",
        "name_de": "Verlängerung - 1x2",
        "name_nl": "Verlenging - 1x2",
        "name_pl": "Dogrywka - 1x2",
        "name_ro": "Prelungiri - 1x2",
        "name_ru": "Овертайм - 1х2",
        "name_tr": "Uzatmalar - 1x2",
        "name_se": "Övertid - 1x2",
        "name_el": "Παράταση - 1Χ2"
    },
    "id_860": {
        "name_en": "{$competitor1} or both teams to score",
        "name_aa": "{$competitor1} أو كلا الفريقين للتسجيل",
        "name_de": "{$competitor1} oder beide Teams werden punkten",
        "name_nl": "{$competitor1} of beide teams scoren",
        "name_pl": "{$competitor1} lub oba zespoły punktują",
        "name_ro": "{$competitor1} sau ambele echipe să înscrie",
        "name_ru": "{$competitor1} или обе команды забьют",
        "name_tr": "{$competitor1} ya da iki takımda gol atar",
        "name_se": "{$competitor1} elr båda lagen gör mål",
        "name_el": "{$competitor1} ή και οι 2 ομάδες να σκοράρουν"
    },
    "id_841": {
        "name_en": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal",
        "name_aa": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal",
        "name_de": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal",
        "name_nl": "{inningnr} innings - {$competitor1} total spread bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal",
        "name_ro": "{!inningnr} innings - {$competitor1} total interval la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал спред на {!dismissalnr}выбиваний",
        "name_tr": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal",
        "name_se": "{!inningnr} innings - {$competitor1} total spread vid {!dismissalnr} utslagen",
        "name_el": "{!inningnr} innings - {$competitor1} total spread at {!dismissalnr} dismissal"
    },
    "id_600": {
        "name_en": "5 minutes - {$competitor2} sending off from {from} to {to}",
        "name_aa": "5 دقائق - {$competitor2} طرد من {from} إلى {to}",
        "name_de": "5 Minuten - {$competitor2} Platzverweise von {from} bis {to}",
        "name_nl": "5 minutes - uitsluiting {$competitor2} van {from} tot {to}",
        "name_pl": "5 minut - {$competitor2} czerwona kartka od {from} do {to}",
        "name_ro": "5 minute - {$competitor2} eliminare de la {from} la {to}",
        "name_ru": "5 минут - {$competitor2} удаление с {from} до {to}",
        "name_tr": "5 dakika - {$competitor2} oyundan atılma {from} dan {to} a",
        "name_se": "5 minuter - {$competitor2} utvisning från {from} till {to}",
        "name_el": "5 λεπτά - {$competitor2}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_30": {
        "name_en": "Which team to score",
        "name_aa": "الفريق الذي يسجل",
        "name_de": "Welche Mannschaft trifft",
        "name_nl": "Welk team scoort",
        "name_pl": "Który zespół zdobędzie gola",
        "name_ro": "Care echipă va înscrie",
        "name_ru": "Какая команда забьет",
        "name_tr": "Golü atacak takım",
        "name_se": "Vilket lag att göra mål",
        "name_el": "Ποια ομάδα θα σκοράρει"
    },
    "id_35": {
        "name_en": "1x2 & both teams to score",
        "name_aa": "1x2 وكلا الفريقين يسجل",
        "name_de": "1x2 & Beide Mannschaften treffen",
        "name_nl": "1x2 & beide teams scoren",
        "name_pl": "1x2 & Oba zespoły zdobędą gola",
        "name_ro": "1x2 & ambele echipe să înscrie",
        "name_ru": "1x2 и обе команды забьют",
        "name_tr": "1x2 & her iki takım da gol bulur",
        "name_se": "1x2 & båda lagen gör mål",
        "name_el": "1Χ2 και να σκοράρουν και οι 2 ομάδες"
    },
    "id_973": {
        "name_en": "{$competitor2} 10/3 sixes head2head spread",
        "name_aa": "{$competitor2} 10/3 sixes head2head spread",
        "name_de": "{$competitor2} 10/3 sixes head2head spread",
        "name_nl": "{$competitor2} 10/3 zespunters onderling spread",
        "name_pl": "{$competitor2} 10/3 sixes head2head spread",
        "name_ro": "{$competitor2} 10/3 sixes head2head spread",
        "name_ru": "{$competitor2} 10/3 шести ранов очные встречи спред",
        "name_tr": "{$competitor2} 10/3 sixes head2head spread",
        "name_se": "{$competitor2} 10/3 sixes head2head spread",
        "name_el": "{$competitor2} 10/3 sixes head2head spread"
    },
    "id_277": {
        "name_en": "Innings 1 to 5 - {$competitor1} total",
        "name_aa": "أدوار 1-5 - {$competitor1} مجموعه",
        "name_de": "Innings 1 bis 5 - {$competitor1} Total",
        "name_nl": "Inning 1 tot 5 - {$competitor1} totaal",
        "name_pl": "Innings 1 do 5 - {$competitor1} suma",
        "name_ro": "Innings de la 1 la 5 - total {$competitor1}",
        "name_ru": "Иннинги с 1 до 5 - {$competitor1} тотал",
        "name_tr": "Devreler 1 den 5 e - {$competitor1} toplam",
        "name_se": "Innings 1 till 5 - {$competitor1} totalt",
        "name_el": "Innings 1 σε 5 - σύνολο {$competitor1}"
    },
    "id_52": {
        "name_en": "Highest scoring half",
        "name_aa": "أعلى التهديف الشوط",
        "name_de": "Halbzeit mit den meisten Toren",
        "name_nl": "Helft met de meeste doelpunten",
        "name_pl": "Połowa z największą liczbą goli",
        "name_ro": "Repriza cu cele mai multe goluri",
        "name_ru": "Самый результативный тайм",
        "name_tr": "En fazla gollü devre",
        "name_se": "Halvlek med flest mål",
        "name_el": "Ημίχρονο με περισσότερα γκολ"
    },
    "id_898": {
        "name_en": "Player to score 2+",
        "name_aa": "Player to score 2+",
        "name_de": "Player to score 2+",
        "name_nl": "Speler scoort meer dan 2 keer",
        "name_pl": "Player to score 2+",
        "name_ro": "Jucătorul să înscrie 2+",
        "name_ru": "Игрок забьет 2+",
        "name_tr": "Player to score 2+",
        "name_se": "Spelare att göra 2+ mål",
        "name_el": "να σκοράρει 2+"
    },
    "id_247": {
        "name_en": "{!gamenr} game - total points",
        "name_aa": "لعبة {!gamenr} - مجموع النقاط",
        "name_de": "{!gamenr} Spiel - Anzahl Punkte",
        "name_nl": "{gamenr} game - totaal aantal punten",
        "name_pl": "{!gamenr} gem - suma punktów",
        "name_ro": "Game-ul {!gamenr} - total puncte",
        "name_ru": "{!gamenr} игра - тотал очков",
        "name_tr": "{!gamenr}. oyun - toplam sayı",
        "name_se": "{!gamenr} game - totalt poäng",
        "name_el": "{!gamenr} γκέιμ - σύνολο πόντων"
    },
    "id_614": {
        "name_en": "{!quarternr} quarter - handicap (incl. overtime)",
        "name_aa": "الربع {!quarternr} - عائق (بما في ذلك العمل الإضافي)",
        "name_de": "{!quarternr} Viertel - Handicap (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - handicap (verlenging inbegrepen)",
        "name_pl": "{!quarternr} kwarta - handicap (włącznie z dogrywką)",
        "name_ro": "Sfertul {!quarternr} - handicap (incl. prelungiri)",
        "name_ru": "{!quarternr} четверть - гандикап (включая овертайм)",
        "name_tr": "{!quarternr}. çeyrek - handikap (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - handikapp (inkl. övertid)",
        "name_el": "{!quarternr} περίοδος - χάντικαπ παράταση συμπεριλαμβάνεται"
    },
    "id_24": {
        "name_en": "{$competitor2} exact goals",
        "name_aa": "{$competitor2} الأهداف المحددة",
        "name_de": "{$competitor2} Genaue Anzahl Tore",
        "name_nl": "{$competitor2} exact aantal doelpunten",
        "name_pl": "{$competitor2} Dokładna liczba goli",
        "name_ro": "Goluri exact {$competitor2}",
        "name_ru": "Точное число голов {$competitor2}",
        "name_tr": "{$competitor2} Kesin hedefler",
        "name_se": "{$competitor2} exakt antal mål",
        "name_el": "Ακριβής αριθμός γκολ {$competitor2}"
    },
    "id_212": {
        "name_en": "{!setnr} set game {gamenr} - to deuce",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - إلى شيطان",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Einstand",
        "name_nl": "{setnr} set game {gamenr} - naar deuce",
        "name_pl": "{!setnr} set gem {gamenr} - równowaga",
        "name_ro": "Set {!setnr} game {gamenr} - să egaleze",
        "name_ru": "{!setnr} сет {gamenr} гейм - равный счет (deuce)",
        "name_tr": "{!setnr}. set {gamenr}. oyun - eşitlik",
        "name_se": "{!setnr} set game {gamenr} - till deuce",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - ''deuce''"
    },
    "id_1040": {
        "name_en": "Total hits (incl. extra innings)",
        "name_aa": "Total hits (incl. extra innings)",
        "name_de": "Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal slagen (incl. extra innings)",
        "name_pl": "Total hits (incl. extra innings)",
        "name_ro": "Total hits (incl. extra innings)",
        "name_ru": "Тотал хитов  (включая экстра иннинги)",
        "name_tr": "Total hits (incl. extra innings)",
        "name_se": "Totalt antal hits (inkl. extra innings)",
        "name_el": "Total hits (incl. extra innings)"
    },
    "id_810": {
        "name_en": "{$event} group {group} - top {pos} exact order",
        "name_aa": "{$event} المجموعة {group} - أعلى {pos} الترتيب الدقيق",
        "name_de": "{$event} group {group} - Spitze {pos} genaue Reihenfolge",
        "name_nl": "{$event} groep {group} - top {pos} juiste volgorde",
        "name_pl": "{$event} grupa {group} - najlepsza {pos} dokładna kolejność",
        "name_ro": "{$event} grupa {group} - top {pos} în ordine exactă",
        "name_ru": "{$event} группа {group} - верхняя часть {pos} точный порядок",
        "name_tr": "{$event} -  grup {group} - top {pos} kesin order",
        "name_se": "{$event} - topp {pos} exakta besked",
        "name_el": "{$event} Ομίλου {group} - top {pos} ακριβής σειρά"
    },
    "id_246": {
        "name_en": "{!gamenr} game - point handicap",
        "name_aa": "لعبة{!gamenr} - هانديكاب نقطة",
        "name_de": "{!gamenr} Spiel - Punkte Handicap",
        "name_nl": "{gamenr} game - punten handicap",
        "name_pl": "{!gamenr} gem - punkty handicap",
        "name_ro": "Game-ul {!gamenr} - handicap puncte",
        "name_ru": "{!gamenr} игра - гандикап очков",
        "name_tr": "{!gamenr}. oyun - handikaplı sayı",
        "name_se": "{!gamenr} game - poäng handikapp",
        "name_el": "{!gamenr} γκέιμ - χάντικαπ πόντων"
    },
    "id_698": {
        "name_en": "Team with top batter",
        "name_aa": "فريق مع الخليط العلوي",
        "name_de": "Mannschaft mit dem besten Batter",
        "name_nl": "Team met de beste slagman",
        "name_pl": "Drużyna z najlepszym batter",
        "name_ro": "Echipa cu top batter",
        "name_ru": "Команда с лучшим бэттером",
        "name_tr": "En iyi vurucunun olduğu takım",
        "name_se": "Lag med bästa slagman",
        "name_el": "Team with top batter"
    },
    "id_295": {
        "name_en": "2nd half - odd/even (incl. overtime)",
        "name_aa": "النصف الثاني -فردي / زوجي (بما في ذلك الوقت الإضافي)",
        "name_de": "2. Halbzeit - Gerade/ungerade (inkl. Extra Innings)",
        "name_nl": "Tweede helft - even/oneven (verlenging inbegrepen)",
        "name_pl": "2. Połowa - parzyste/nieparzyste (włącznie z dogrywką)",
        "name_ro": "Repriza 2 - impar/par (incl. prelungiri)",
        "name_ru": "2ая половина - чет/нечет (включая овертайм)",
        "name_tr": "İkinci yarı - tek/çift (uzatma dahil)",
        "name_se": "2:a halvlek - udda/jämnt (inkl. övertid)",
        "name_el": "2ο ημίχρονο -μονά/ζυγά (παράταση συμπ.)"
    },
    "id_489": {
        "name_en": "1st half - {$competitor2} total tries",
        "name_aa": "الشوط الأول - إجمالي محاولات {$competitor2}",
        "name_de": "1. Halbzeit - {$competitor2} Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} suma przyłożeń",
        "name_ro": "Repriza 1 - {$competitor2} total încercări",
        "name_ru": "1ая половина - {$competitor2} тотал попыток",
        "name_tr": "İlk yarı - {$competitor2} toplam try",
        "name_se": "1:a halvlek - {$competitor2} totalt antal försök",
        "name_el": "1ο ημίχρονο - {$competitor2} σύνολο tries"
    },
    "id_1027": {
        "name_en": "Holes {from} to {to} - 3 ball",
        "name_aa": "Holes {from} to {to} - 3 ball",
        "name_de": "Holes {from} to {to} - 3 ball",
        "name_nl": "Holes {from} tot {to} - 3 ball",
        "name_pl": "Holes {from} to {to} - 3 ball",
        "name_ro": "Holes {from} to {to} - 3 ball",
        "name_ru": "Holes {from} to {to} - 3 ball",
        "name_tr": "Holes {from} to {to} - 3 ball",
        "name_se": "Holes {from} to {to} - 3 ball",
        "name_el": "Holes {from} to {to} - 3 ball"
    },
    "id_612": {
        "name_en": "Draw no bet (incl. overtime)",
        "name_aa": "لا رهان (بما في ذلك الوقت الإضافي)",
        "name_de": "unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "Draw no bet (verlenging inbegrepen)",
        "name_pl": "Remis bez zakładu (włącznie z dogrywką)",
        "name_ro": "Egal pariu nul (incl. prelungiri)",
        "name_ru": "Ничья ставки нет (включая овертайм)",
        "name_tr": "Beraberlikte iade (uzatmalar dahil)",
        "name_se": "Oavgjort (inkl. övertid)",
        "name_el": "Ισοπαλία όχι στοιχήμα παράταση συμπεριλαμβάνεται"
    },
    "id_896": {
        "name_en": "{!scorenr} scoring type",
        "name_aa": "{!scorenr} scoring type",
        "name_de": "{!scorenr} scoring type",
        "name_nl": "{!scorenr} scoringstype",
        "name_pl": "{!scorenr} typ wyniku",
        "name_ro": "metoda înscrierii {!scorenr}",
        "name_ru": "{!scorenr} тип счета",
        "name_tr": "{!scorenr} scoring type",
        "name_se": "{!scorenr} poängtyp",
        "name_el": "{!scorenr} γκολ (τύπος)"
    },
    "id_764": {
        "name_en": "Player rushing yards (incl. overtime)",
        "name_aa": "لاعب يهرع ياردة",
        "name_de": "Spieler Rushing Yards (inkl. Verlängerung)",
        "name_nl": "Speler rushing yards",
        "name_pl": "Player rushing yards (incl. overtime)",
        "name_ro": "Yarzi rushing jucător",
        "name_ru": "Игрок сделавший больше выносов мяча ярдами",
        "name_tr": "Oyuncu yardları koşuyor",
        "name_se": "Spelare - springyards",
        "name_el": "Παίχτης rushing yards"
    },
    "id_1070": {
        "name_en": "{!inningnr} inning - most strikes",
        "name_aa": "{!inningnr} inning - most strikes",
        "name_de": "{!inningnr} inning - most strikes",
        "name_nl": "{inningnr} inning - meeste slagballen",
        "name_pl": "{!inningnr} inning - most strikes",
        "name_ro": "{!inningnr} inning - most strikes",
        "name_ru": "{!inningnr} иннинг - наибольшее число страйков",
        "name_tr": "{!inningnr} inning - most strikes",
        "name_se": "{!inningnr} inning - most strikes",
        "name_el": "{!inningnr} inning - περισσότερα strikes"
    },
    "id_338": {
        "name_en": "{!mapnr} map round {roundnr} - winner",
        "name_aa": "{!mapnr} خريطة جولة {roundnr} - الفائز",
        "name_de": "{!mapnr} Map Runde {roundnr}- Gewinner",
        "name_nl": "{mapnr} map - round {roundnr} - winnaar",
        "name_pl": "{!mapnr} mapa runda {roundnr} - zwycięzca",
        "name_ro": "Harta {!mapnr}runda {roundnr} - câștigător",
        "name_ru": "{!mapnr} карта {roundnr} раунд - победитель",
        "name_tr": "{!mapnr}. harita {roundnr}. raund - kazanan",
        "name_se": "{!mapnr} kartrunda {roundnr} - vinnare",
        "name_el": "{!mapnr} map, {roundnr} round - Νικητής"
    },
    "id_777": {
        "name_en": "Player shots on goal",
        "name_aa": "لقطات لاعب على الهدف",
        "name_de": "Spieler Schüsse aufs Tor",
        "name_nl": "Speler schoten op doel",
        "name_pl": "Player shots on goal",
        "name_ro": "Lovituri pe poartă jucător",
        "name_ru": "Игрок совершивший больше ударов по воротам",
        "name_tr": "OYuncu gol olan şutları",
        "name_se": "Spelare skott på mål",
        "name_el": "Παίχτης σουτ εντός στόχου"
    },
    "id_1500": {
        "name_en": "Rest of Total match",
        "name_aa": "Rest of Total match",
        "name_de": "Rest of Total match",
        "name_nl": "Rest of Total match",
        "name_pl": "Rest of Total match",
        "name_ro": "Rest of Total match",
        "name_ru": "Rest of Total match",
        "name_tr": "Rest of Total match",
        "name_se": "Rest of Total match",
        "name_el": "Rest of Total match"
    },
    "id_758": {
        "name_en": "{!quarternr} quarter - winning margin",
        "name_aa": "الربع {!quarternr} - الحائز على هامش",
        "name_de": "{!quarternr} Viertel - Gewinnspanne",
        "name_nl": "{quarternr} kwart - winstmarge",
        "name_pl": "{!quarternr} kwarta - margines zwycięstwa",
        "name_ro": "Sfertul {!quarternr} - diferență victorie",
        "name_ru": "{!quarternr} четверть - победа с разницей",
        "name_tr": "{!quarternr} çeyrek - Kazanma farkı",
        "name_se": "{!quarternr} quarter - vinstmarginal",
        "name_el": "{!quarternr} περιόδος - περιθώριο νίκης"
    },
    "id_684": {
        "name_en": "Top bowler",
        "name_aa": "قمة الرامى",
        "name_de": "Bester Bowler",
        "name_nl": "Beste werper",
        "name_pl": "Najlepszy bowler",
        "name_ro": "Top bowler",
        "name_ru": "Лучший боулер",
        "name_tr": "En iyi atıcı",
        "name_se": "Bästa bowler",
        "name_el": "Top bowler"
    },
    "id_727": {
        "name_en": "{!mapnr} map - total aegis",
        "name_aa": "{!mapnr} خريطة - إجمالي المعلومات",
        "name_de": "{!mapnr} Map - Aegis gesamt",
        "name_nl": "{mapnr} map - totaal aantal aegis",
        "name_pl": "{!mapnr} mapa - suma aegis",
        "name_ro": "Harta {!mapnr} - total aegis",
        "name_ru": "{!mapnr} карта - тотал аегисов",
        "name_tr": "{!mapnr}. harita - kalkan",
        "name_se": "{!mapnr} karta - totalt antal aegis",
        "name_el": "{!mapnr} map - total aegis"
    },
    "id_651": {
        "name_en": "{!inningnr} innings - {%player} dismissal method",
        "name_aa": "{!inningnr} أدوار - {player} طريقة الفصل",
        "name_de": "{!inningnr} Inning - {!player} Dismissal Methode",
        "name_nl": "{inningnr} innings - {player} dismissal methode",
        "name_pl": "{!inningnr} innings - {!player} sposób eliminacji",
        "name_ro": "{!inningnr} innings - {!player} metoda demiterii",
        "name_ru": "{!inningnr} иннингов - {!player} методов выбиваний",
        "name_tr": "{!inningnr} innings - {!player} oyundan çıkarılma metodu",
        "name_se": "{!inningnr} innings - {!player} avvisande metod",
        "name_el": "{!inningnr} innings - {!player} dismissal method"
    },
    "id_1007": {
        "name_en": "2 ball (handicap)",
        "name_aa": "2 ball (handicap)",
        "name_de": "2 ball (handicap)",
        "name_nl": "2 ball (handicap)",
        "name_pl": "2 ball (handicap)",
        "name_ro": "2 ball (handicap)",
        "name_ru": "2 ball (handicap)",
        "name_tr": "2 ball (handicap)",
        "name_se": "2 ball (handicap)",
        "name_el": "2 ball (handicap)"
    },
    "id_145": {
        "name_en": "Booking point range",
        "name_aa": "نطاق نقطة الحجز",
        "name_de": "Strafpunktebereich",
        "name_nl": "Booking point range",
        "name_pl": "Zakres liczby punktów karnych",
        "name_ro": "Interval puncte cartonașe",
        "name_ru": "Количество зачетных очков",
        "name_tr": "Kart ceza puanı aralığı",
        "name_se": "Kort - poängintervall",
        "name_el": "Εύρος πόντων στις καρτες"
    },
    "id_107": {
        "name_en": "10 minutes - total from {from} to {to}",
        "name_aa": "10 دقائق - الإجمالي من {from} إلى {to}",
        "name_de": "10 Minuten - Total von {from} bis {to}",
        "name_nl": "10 minuten - totaal van {from} tot {to}",
        "name_pl": "10 minut - suma od {from} do {to}",
        "name_ro": "10 minute - total de la {from} la {to}",
        "name_ru": "10 минут - тотал от {from} до {to}",
        "name_tr": "10 dakika -{from}'dan {to}'a kadar toplam gol",
        "name_se": "10 minuter - totalt från {from} till {to}",
        "name_el": "10 λεπτά - σύνολο από {from} μέχρι {to}"
    },
    "id_265": {
        "name_en": "1x2 & total (incl. extra innings)",
        "name_aa": "1X2 ومجموع (بما في ذلك أدوار إضافية)",
        "name_de": "1x2 & Total (inkl. Extra Innings)",
        "name_nl": "1x2 & totaal (extra innings inbegrepen)",
        "name_pl": "1x2 & suma (włącznie z extra innings)",
        "name_ro": "1x2 & total (incl. inning-uri extra)",
        "name_ru": "1х2 и тотал (включая дополнительные иннинги)",
        "name_tr": "1x2 & toplam (uzatmalar dahil)",
        "name_se": "1x2 & totalt (inkl. extra innings)",
        "name_el": "1Χ2 και σύνολο (έξτρα innings συμπ.)"
    },
    "id_977": {
        "name_en": "Multi fours spread",
        "name_aa": "Multi fours spread",
        "name_de": "Multi fours spread",
        "name_nl": "Multi vierpunters spread",
        "name_pl": "Multi fours spread",
        "name_ro": "Multi fours spread",
        "name_ru": "Мульти спред 4 ранов",
        "name_tr": "Multi fours spread",
        "name_se": "Multi fours spread",
        "name_el": "Multi fours spread"
    },
    "id_146": {
        "name_en": "Sending off",
        "name_aa": "طرد",
        "name_de": "Platzverweise",
        "name_nl": "Uitsluitingen",
        "name_pl": "Czerwona kartka",
        "name_ro": "Eliminare",
        "name_ru": "Удаление",
        "name_tr": "Oyundan atılma",
        "name_se": "Utvisning",
        "name_el": "Να αποβληθεί παίχτης"
    },
    "id_179": {
        "name_en": "1st half - {$competitor2} total corners",
        "name_aa": "النصف الأول - {$competitor2} مجموع الزوايا",
        "name_de": "1. Halbzeit - {$competitor2} Gesamtanzahl Ecken",
        "name_nl": "Eerste helft - totaal aantal hoekschoppen {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} Suma rzutów rożnych",
        "name_ro": "Repriza 1 - total cornere {$competitor2}",
        "name_ru": "1ая половина - тотал угловых {$competitor2}",
        "name_tr": "İlk yarı - {$competitor2} toplam korner sayısı",
        "name_se": "1:a halvlek - {$competitor2} totalt antal hörnor",
        "name_el": "1ο ημίχρονο - {$competitor2} σύνολο κόρνερ"
    },
    "id_171": {
        "name_en": "{$competitor2} corner range",
        "name_aa": "{$competitor2} نطاق الزاوية",
        "name_de": "{$competitor2} Anzahlbereich Ecken",
        "name_nl": "Hoekschoppen range {$competitor2}",
        "name_pl": "{$competitor2} zakres liczby rzutów rożnych",
        "name_ro": "Interval cornere {$competitor2}",
        "name_ru": "Количество угловых {$competitor2}",
        "name_tr": "{$competitor2} korner aralığı",
        "name_se": "{$competitor2} Hörnintervall",
        "name_el": "{$competitor2}  Εύρος των κόρνερ"
    },
    "id_458": {
        "name_en": "{!periodnr} period - which team wins the rest",
        "name_aa": "فترة {!periodnr}- الفريق الذي يفوز في بقية",
        "name_de": "{!periodnr} Periode- Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "{periodnr} periode - welk team wint het restant",
        "name_pl": "{!periodnr} część gry - który zespół wygra resztę",
        "name_ro": "Repriza {!periodnr} - care echipă câștigă restul",
        "name_ru": "{!periodnr} период - какая команда выиграет остаток матча",
        "name_tr": "{!periodnr}. periyot - maçın kalanında kim kazanır",
        "name_se": "{!periodnr} period - vilket lag vinner resten",
        "name_el": "{!periodnr} περίοδος - ποια ομάδα θα κερδίσει το υπόλοιπο"
    },
    "id_383": {
        "name_en": "180s handicap",
        "name_aa": "180 ثانية هانديكاب",
        "name_de": "180s Handicap",
        "name_nl": "180s handicap",
        "name_pl": "180s handicap",
        "name_ro": "Handicap 180-uri",
        "name_ru": "Гандикап 180ти",
        "name_tr": "180 handikapı",
        "name_se": "180:or handikapp",
        "name_el": "180 χάντικαπ"
    },
    "id_823": {
        "name_en": "Draw sum range",
        "name_aa": "رسم نطاق المجموع",
        "name_de": "Unentschieden Summe Bereich",
        "name_nl": "Trekking som range",
        "name_pl": "Draw sum range",
        "name_ro": "Sumă tragere interval",
        "name_ru": "Ничья сумма разница",
        "name_tr": "Draw toplam aralık",
        "name_se": "Oavgjord radsumma",
        "name_el": "Draw sum range"
    },
    "id_815": {
        "name_en": "{!mapnr} map - assist draw no bet",
        "name_aa": "{!mapnr} خريطة - مساعدة في رسم أي رهان",
        "name_de": "{!mapnr} map - assist draw no bet",
        "name_nl": "{mapnr} map - assist draw no bet",
        "name_pl": "{!mapnr} mapa - asysty zakład bez remisu",
        "name_ro": "Harta {!mapnr} - asisturi egalitate pariu nul",
        "name_ru": "{!mapnr} карта - ставка на победу без ничьей",
        "name_tr": "{!mapnr} - harita - asist draw no bet",
        "name_se": "{!mapnr} karta - assister oavgjorda",
        "name_el": "{!mapnr} map - ασσίστ Ισοπαλία όχι στοιχήμα"
    },
    "id_360": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} odd/even",
        "name_aa": "{!inningnr} أدوار على {overnr} - {$competitor2} فردي / زوجي",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor2} Gerade/ungerade",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor2} even/oneven",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} parzyste/nieprzyste",
        "name_ro": "{!inningnr} innings over {overnr} - impar/par {$competitor2}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor2} чет/нечет",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor2} tek/çift",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor2} udda/jämnt",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} μονά/ζυγά"
    },
    "id_1147": {
        "name_en": "{%player} to score {milestone} & take a wicket & a catch",
        "name_aa": "{!player} to score {milestone} & take a wicket & a catch",
        "name_de": "{!player} to score {milestone} & take a wicket & a catch",
        "name_nl": "{!player} scoort {milestone} & neemt een wicket & een vangbal",
        "name_pl": "{!player} to score {milestone} & take a wicket & a catch",
        "name_ro": "{!player} to score {milestone} & take a wicket & a catch",
        "name_ru": "{!player} to score {milestone} & take a wicket & a catch",
        "name_tr": "{!player} to score {milestone} & take a wicket & a catch",
        "name_se": "{!player} to score {milestone} & take a wicket & a catch",
        "name_el": "{!player} to score {milestone} & take a wicket & a catch"
    },
    "id_1022": {
        "name_en": "Hole {holenr} - 3 ball",
        "name_aa": "Hole {holenr} - 3 ball",
        "name_de": "Hole {holenr} - 3 ball",
        "name_nl": "Hole {holenr} - 3 ball",
        "name_pl": "Hole {holenr} - 3 ball",
        "name_ro": "Hole {holenr} - 3 ball",
        "name_ru": "Hole {holenr} - 3 ball",
        "name_tr": "Hole {holenr} - 3 ball",
        "name_se": "Hole {holenr} - 3 ball",
        "name_el": "Hole {holenr} - 3 ball"
    },
    "id_615": {
        "name_en": "{!quarternr} quarter - total (incl. overtime)",
        "name_aa": "الربع {!quarternr} - مجموع (بما في ذلك العمل الإضافي)",
        "name_de": "{!quarternr} Viertel - Total (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - totaal (verlenging inbegrepen)",
        "name_pl": "{!quarternr} kwarta - suma (włącznie z dogrywką)",
        "name_ro": "Sfertul {!quarternr} - total (incl. prelungiri)",
        "name_ru": "{!quarternr} четверть - тотал (включая овертайм)",
        "name_tr": "{!quarternr}. çeyrek - toplam (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - totalt (inkl. övertid)",
        "name_el": "{!quarternr} περίοδος - σύνολο παράταση συμπεριλαμβάνεται"
    },
    "id_927": {
        "name_en": "{%player} total batter runs + runs batted in (incl. extra innings)",
        "name_aa": "{!player} total batter runs + runs batted in (incl. extra innings)",
        "name_de": "{!player} Batter Runs + Runs batted in Gesamt (inkl. Extra Innings)",
        "name_nl": "{!player} totaal aantal slagman runs + ingeslagen runs",
        "name_pl": "{!player} total batter runs + runs batted in (incl. extra innings)",
        "name_ro": "{!player} total batter runs + runs batted in (incl. extra innings)",
        "name_ru": "{!player} Бэттер раны + раны отбитые",
        "name_tr": "{!player} total batter runs + runs batted in (incl. extra innings)",
        "name_se": "{!player} total batter runs + runs batted in (incl. extra innings)",
        "name_el": "{!player} total batter runs + runs batted in"
    },
    "id_643": {
        "name_en": "{!inningnr} innings - {%player} total fours",
        "name_aa": "{!inningnr} أدوار - {player} مجموع أربعات",
        "name_de": "{!inningnr} Inning - {!player} Fours gesamt",
        "name_nl": "{!inningnr} innings - {!player} totaal aantal fours",
        "name_pl": "{!inningnr} innings - {!player} suma czwórek",
        "name_ro": "{!inningnr} innings - total fours {!player}",
        "name_ru": "{!inningnr} иннингов - {!player} 4 ранов",
        "name_tr": "{!inningnr}. devre - {!player} toplam fours",
        "name_se": "{!inningnr} innings - {!player} totala fours",
        "name_el": "{!inningnr} innings - {!player} total fours"
    },
    "id_392": {
        "name_en": "{!setnr} set leg {legnr} - {$competitor1} to score a 180",
        "name_aa": "{!setnr} مجموعة الساق {legnr}- {$competitor1} ليسجل 180",
        "name_de": "{!setnr} Satz Runde {legnr} - {$competitor1} erzielt eine 180",
        "name_nl": "{setnr} set leg {legnr} - {$competitor1} gooit een 180",
        "name_pl": "{!setnr} set leg {legnr} - {$competitor1} osiągnie 180",
        "name_ro": "Set {!setnr} leg {legnr} - {$competitor1} să înscrie un 180",
        "name_ru": "{!setnr} сет {legnr} этап - {$competitor1} набрать 180",
        "name_tr": "{!setnr}. set {legnr}. ayak - {$competitor1} 180 yapar",
        "name_se": "{!setnr} set legs {legnr} - {$competitor1} att kasta en 180:a",
        "name_el": "{!setnr} set, {legnr} leg - {$competitor1} να σκοράρει 180άρι"
    },
    "id_1090": {
        "name_en": "{!quarternr} quarter - {!pointnr} point",
        "name_aa": "الربع -  نقطة",
        "name_de": "{!quarternr} Viertel - {!pointnr} Punkt",
        "name_nl": "{quarternr} kwart  - {pointnr} punt",
        "name_pl": "{!quarternr} kwarta - {!pointnr} punkt",
        "name_ro": "Sfertul {!quarternr} - punctul {!pointnr}",
        "name_ru": "{!quarternr} четверть - {!pointnr} очков",
        "name_tr": "{!quarternr} çeyrek - {!pointnr} sayı",
        "name_se": "{!quarternr} quartern - {!pointnr} poäng",
        "name_el": "{!quarternr} περίοδος - {!pointnr} πόντος"
    },
    "id_716": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} run range",
        "name_aa": "{!inningnr} أدوار أكثر من {overnr} - {$competitor2} نطاق تشغيل",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor2} Laufbereich",
        "name_nl": "{inningnr} innings over {overnr} - run range {$competitor2}",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} zakres liczby runs",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor2} interval run-uri",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor2} дистанция пробежек",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor2} koşu aralığı",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor2} run-intervall",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} run range"
    },
    "id_201": {
        "name_en": "Double result (1st set/match)",
        "name_aa": "نتيجة مزدوجة (المجموعة الأولى / المباراة)",
        "name_de": "Doppeltes Ergebnis (1. Satz / Spiel)",
        "name_nl": "Dubbel resultaat (eerste set/match)",
        "name_pl": "Podwójny wynik ( 1. set/mecz)",
        "name_ro": "Rezultat dublu (set-ul 1/meci)",
        "name_ru": "Двойной результат (1ый сет/матч)",
        "name_tr": "Çifte sonuç bahisi (İlk set/maç)",
        "name_se": "Dubbla resultat (1:a set/match)",
        "name_el": "Διπλή ευκαιρία (1ο σετ/αγώνας)"
    },
    "id_858": {
        "name_en": "{$competitor2} or over {total}",
        "name_aa": "{$competitor2} أو أكثر من {total}",
        "name_de": "{$competitor2} oder über {total}",
        "name_nl": "{$competitor2} of boven {total}",
        "name_pl": "{$competitor2} lub ponad {total}",
        "name_ro": "{$competitor2} sau peste {total}",
        "name_ru": "{$competitor2} или больше {total}",
        "name_tr": "{$competitor2} ya da üst {total}",
        "name_se": "{$competitor2} elr över {total}",
        "name_el": "{$competitor2} ή over  {total}"
    },
    "id_568": {
        "name_en": "15 minutes - total corners from {from} to {to}",
        "name_aa": "15 دقيقة - مجموع زوايا من {from} إلى {to}",
        "name_de": "15 Minuten - Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "15 minutes - totaal aantal hoekschoppen van {from} tot {to}",
        "name_pl": "15 minut - suma rzutów rożnych od {from} do {to}",
        "name_ro": "15 minute - total cornere de la {from} la {to}",
        "name_ru": "15 минут - тотал угловых с {from} до {to}",
        "name_tr": "15 dakika - toplam korner sayısı {from} dan {to} a",
        "name_se": "15 minuter - totalt atal hörnor från {from} till {to}",
        "name_el": "15 λεπτά - σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_77": {
        "name_en": "1st half - {$competitor2} clean sheet",
        "name_aa": "النصف الأول - {$competitor2} ورقة نظيفة",
        "name_de": "1. Halbzeit- {$competitor2} ohne Gegentreffer",
        "name_nl": "1e helft - {$competitor2} houdt doel schoon",
        "name_pl": "1. Połowa - {$competitor2} czyste konto",
        "name_ro": "Prima repriză - {$competitor2} fără gol primit",
        "name_ru": "1ая половина - {$competitor2} не пропустит",
        "name_tr": "İlk Yarı - {$competitor2} gol yemez",
        "name_se": "1:a halvlek - {$competitor2} håller nollan",
        "name_el": "1ο ημίχρονο - {$competitor2} να μη δεχτεί γκολ"
    },
    "id_619": {
        "name_en": "1st half - {$competitor2} odd/even",
        "name_aa": "الشوط الأول - {$competitor2} فردي / زوجي",
        "name_de": "1. Halbzeit - {$competitor2} Gerade/ungerade",
        "name_nl": "Eerste helft - {$competitor2} even/oneven",
        "name_pl": "1. Połowa - 1. Połowa - {$competitor2} Parzyste/Nieparzyste",
        "name_ro": "Repriza 1 - {$competitor2} impar/par",
        "name_ru": "1-й тайм - {$competitor2} чет/нечет",
        "name_tr": "İlk yarı - {$competitor2} tek/çift",
        "name_se": "1:a halvlek - {$competitor2} udda/jämnt",
        "name_el": "1ο ημίχρονο - {$competitor2} μονά/ζυγά"
    },
    "id_1002": {
        "name_en": "{!inningnr} innings - {%player} {upsnr} ups spread",
        "name_aa": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_de": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_nl": "{inningnr} innings - {!player} {upsnr} ups spread",
        "name_pl": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_ro": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_ru": "{!inningnr} иннингов - {!player} {upsnr} ups спред",
        "name_tr": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_se": "{!inningnr} innings - {!player} {upsnr} ups spread",
        "name_el": "{!inningnr} innings - {!player} {upsnr} ups spread"
    },
    "id_881": {
        "name_en": "Any team to win",
        "name_aa": "أي فريق للفوز",
        "name_de": "Eines der Teams gewinnt",
        "name_nl": "We krijgen een winnaar",
        "name_pl": "Any team to win",
        "name_ro": "Oricare echipă să câștige",
        "name_ru": "Одна из команд победит",
        "name_tr": "Kazanan herhangi bir takım",
        "name_se": "Vilket lag som helst att vinna",
        "name_el": "οποιαδήποτε ομάδα να κερδίσει"
    },
    "id_250": {
        "name_en": "{!gamenr} game - race to {pointnr} points",
        "name_aa": "لعبة {!gamenr} - سباق إلى {pointnr} نقاط",
        "name_de": "{!gamenr} Spiel - Wer erreicht zuerst {pointnr} Punkte?",
        "name_nl": "{gamenr} game - race naar {pointnr} punten",
        "name_pl": "{!gamenr} gem - kto pierwszy do {pointnr} punktów",
        "name_ro": "Game-ul {!gamenr} - primul la {pointnr} puncte",
        "name_ru": "{!gamenr} игра - гонка до {pointnr} очков",
        "name_tr": "{!gamenr}. oyun - {pointnr}. sayı çekişmesi",
        "name_se": "{!gamenr} game - först till {pointnr} poäng",
        "name_el": "{!gamenr} γκέιμ - Ποιος θα φτάσει 1ος  στους {pointnr} πόντους"
    },
    "id_1139": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_aa": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_de": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_nl": "{!inningnr} innings over {overnr} - {deliverynr} delivery {$competitor2} is een wicket",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_ro": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_ru": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_tr": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_se": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} to be a wicket"
    },
    "id_91": {
        "name_en": "2nd half - {$competitor1} total",
        "name_aa": "الشوط الثاني - {$competitor1} الإجمالي",
        "name_de": "2. Halbzeit - {$competitor1} Total",
        "name_nl": "2e helft - {$competitor1} totaal",
        "name_pl": "2. Połowa - {$competitor1} Suma",
        "name_ro": "A 2-a repriză - {$competitor1} total",
        "name_ru": "2ая половина - тотал {$competitor1}",
        "name_tr": "İkinci yarı - {$competitor1} toplam gol",
        "name_se": "2:a halvlek - {$competitor1} totalt",
        "name_el": "2ο ημίχρονο -  {$competitor1} Σύνολο Γκολ"
    },
    "id_653": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor2} boundary",
        "name_aa": "{!inningnr} أدوار على {overnr} - {$competitor2} الحدود",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor2} Boundary",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor2} boundary",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor2} boundary",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor2} limită",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor2} граница",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor2} boundary",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor2} hästlängd",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor2} boundary"
    },
    "id_252": {
        "name_en": "1x2 (incl. extra innings)",
        "name_aa": "1x2 (بما في ذلك أدوار إضافية)",
        "name_de": "1x2 (inkl. Extra Innings)",
        "name_nl": "1x2 (extra innings inbegrepen)",
        "name_pl": "1x2 (włącznie z extra innings)",
        "name_ro": "1x2 (incl. inning-uri extra)",
        "name_ru": "1x2 (включая дополнительные иннинги)",
        "name_tr": "1x2 (uzatmalar dahil)",
        "name_se": "1x2 (inkl. extra innings)",
        "name_el": "1Χ2 (έξτρα innings συμπ.)"
    },
    "id_925": {
        "name_en": "{%player} total pitcher strikeouts (incl. extra innings)",
        "name_aa": "{!player} total pitcher strikeouts (incl. extra innings)",
        "name_de": "{!player} Pitcher Strikeouts (inkl. Extra Innings)",
        "name_nl": "{!player} totaal aantal werper strike-outs",
        "name_pl": "{!player} total pitcher strikeouts (incl. extra innings)",
        "name_ro": "{!player} total pitcher strikeouts (incl. extra innings)",
        "name_ru": "{!player} тотал питчер страйкауты",
        "name_tr": "{!player} total pitcher strikeouts (incl. extra innings)",
        "name_se": "{!player} total pitcher strikeouts (incl. extra innings)",
        "name_el": "{!player} total pitcher strikeouts"
    },
    "id_305": {
        "name_en": "{!quarternr} quarter - race to {pointnr} points",
        "name_aa": "{!quarternr} الربع - سباق إلى {pointnr} نقاط",
        "name_de": "{!quarternr} Viertel - Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "{quarternr} kwart - race naar {pointnr} punten",
        "name_pl": "{!quarternr} kwarta - kto pierwszy do {pointnr} punktów",
        "name_ro": "Sfertul {!quarternr} - primul la {pointnr} puncte",
        "name_ru": "{!quarternr} четверть - догон до {pointnr} очков",
        "name_tr": "{!quarternr}. çeyrek - {pointnr} sayıya ilk ulaşacak olan",
        "name_se": "{!quarternr} quarter - först till {pointnr} poäng",
        "name_el": "{!quarternr} περίοδος - Ποιος θα φτάσει 1ος στους {pointnr} πόντους"
    },
    "id_81": {
        "name_en": "1st half - correct score",
        "name_aa": "الشوط الأول - النتيجة الصحيحة",
        "name_de": "1. Halbzeit- Genaues Ergebnis",
        "name_nl": "1e helft - juiste uitslag",
        "name_pl": "1. Połowa - Dokładny wynik",
        "name_ro": "Prima repriză - scor corect",
        "name_ru": "1ая половина - точный счет",
        "name_tr": "İlk yarı -  skor",
        "name_se": "1:a halvlek - rätt resultat",
        "name_el": "1ο ημίχρονο - ακριβές σκορ"
    },
    "id_457": {
        "name_en": "{!periodnr} period - last team to score",
        "name_aa": "فترة {!periodnr} - آخر فريق يسجل",
        "name_de": "{!periodnr} Periode - Letzte Mannschaft trifft",
        "name_nl": "{periodnr} periode - laatst scorende team",
        "name_pl": "{!periodnr} część gry - ostatni punktujący zespół",
        "name_ro": "Repriza {!periodnr} - ultima echipă care înscrie",
        "name_ru": "{!periodnr} период - команда, которая забьёт последней",
        "name_tr": "{!periodnr}. periyot - son golü kim atar",
        "name_se": "{!periodnr} period - sista lag att göra mål",
        "name_el": "{!periodnr} περίοδος - να σκοράρει τελευταίος"
    },
    "id_1060": {
        "name_en": "{$competitor2} {!c2scorenr} scoring type",
        "name_aa": "{$competitor2} {!c2scorenr} scoring type",
        "name_de": "{$competitor2} {!c2scorenr} scoring type",
        "name_nl": "{$competitor2} {!c2scorenr} goaltype",
        "name_pl": "{$competitor2} {!c2scorenr} typ wyniku",
        "name_ro": "{$competitor2} {!c2scorenr} scoring type",
        "name_ru": "{$competitor2} {!c2scorenr} тип счета",
        "name_tr": "{$competitor2} {!c2scorenr} scoring type",
        "name_se": "{$competitor2} {!c2scorenr} poängtypen",
        "name_el": "{$competitor2} {!c2scorenr} τύπος σκοραρίσματος"
    },
    "id_1616": {
        "name_en": "1x2 in 30 minutes",
        "name_aa": "1x2 in 30 minutes",
        "name_de": "1x2 in 30 minutes",
        "name_nl": "1x2 in 30 minutes",
        "name_pl": "1x2 in 30 minutes",
        "name_ro": "1x2 in 30 minutes",
        "name_ru": "1x2 in 30 minutes",
        "name_tr": "1x2 in 30 minutes",
        "name_se": "1x2 in 30 minutes",
        "name_el": "1x2 in 30 minutes"
    },
    "id_88": {
        "name_en": "2nd half - handicap",
        "name_aa": "الشوط الثاني - هانديكاب",
        "name_de": "2. Halbzeit - Handicap",
        "name_nl": "2e helft - handicap",
        "name_pl": "2. Połowa - handicap",
        "name_ro": "A 2-a repriză - handicap",
        "name_ru": "2ая половина - гандикап",
        "name_tr": "İkinci yarı - handikap",
        "name_se": "2:a halvlek - handikapp",
        "name_el": "2ο ημίχρονο -   χάντικαπ"
    },
    "id_173": {
        "name_en": "1st half - corner 1x2",
        "name_aa": "الشوط الأول - الزاوية 1x2",
        "name_de": "1. Halbzeit - Ecken 1x2",
        "name_nl": "Eerste helft - hoekschop 1x2",
        "name_pl": "1. Połowa - Rzut rożny 1x2",
        "name_ro": "Repriza 1 - corner 1x2",
        "name_ru": "1ая половина - угловые 1х2",
        "name_tr": "İlk yarı - korner 1x2",
        "name_se": "1:a halvlek - hörnor 1x2",
        "name_el": "1ο ημίχρονο - κόρνερ 1Χ2"
    },
    "id_778": {
        "name_en": "Player passes",
        "name_aa": "لاعب يمر",
        "name_de": "Spieler Pässe",
        "name_nl": "Speler passes",
        "name_pl": "Player passes",
        "name_ro": "Pase jucător",
        "name_ru": "Игрок совершивший больше пасов",
        "name_tr": "Oyuncu pasları",
        "name_se": "Spelarpassningar",
        "name_el": "Παίχτης πάσες"
    },
    "id_441": {
        "name_en": "All periods over {total}",
        "name_aa": "جميع الفترات على {total}",
        "name_de": "Alle Perioden Über {total}",
        "name_nl": "Alle periodes boven {total}",
        "name_pl": "Wszystkie części gry ponad {total}",
        "name_ro": "Toate reprizele peste {total}",
        "name_ru": "Все периоды больше {total}",
        "name_tr": "Bütün periyotlar {total} üstü",
        "name_se": "Alla perioder över {total}",
        "name_el": "Όλες οι περίοδοι over {total}"
    },
    "id_1053": {
        "name_en": "Result of {%player} {!appearancenr} time at bat",
        "name_aa": "Result of {!player} {!appearancenr} time at bat",
        "name_de": "Result of {!player} {!appearancenr} time at bat",
        "name_nl": "Resultaat van {!player} {!appearancenr} keer aan slag",
        "name_pl": "Result of {!player} {!appearancenr} time at bat",
        "name_ro": "Result of {!player} {!appearancenr} time at bat",
        "name_ru": "Результат {!player} {!appearancenr} игрой битой",
        "name_tr": "Result of {!player} {!appearancenr} time at bat",
        "name_se": "Result för {!player} {!appearancenr} tid som slagman",
        "name_el": "Result of {!player} {!appearancenr} time at bat"
    },
    "id_140": {
        "name_en": "{$competitor1} total bookings",
        "name_aa": "{$competitor1} إجمالي الحجوزات",
        "name_de": "{$competitor1} Gesamtanzahl Karten",
        "name_nl": "Totaal aantal kaarten {$competitor1}",
        "name_pl": "{$competitor1} Suma kartek",
        "name_ro": "Total cartonașe {$competitor1}",
        "name_ru": "{$competitor1} тотал карточек",
        "name_tr": "{$competitor1} toplam kart sayısı",
        "name_se": "{$competitor1} totalt antal kort",
        "name_el": "{$competitor1} σύνολο κάρτες"
    },
    "id_629": {
        "name_en": "{!mapnr} map round {roundnr} - {$competitor1} total kills",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - {$competitor1} إجمالي القتلى",
        "name_de": "{!mapnr} Map Runde {roundnr} - {$competitor1} Total Kills",
        "name_nl": "{mapnr} map round {roundnr} - totaal aantal kills {$competitor1}",
        "name_pl": "{!mapnr} mapa runda {roundnr} - {$competitor1} suma zabójstw",
        "name_ro": "Harta {!mapnr} runda {roundnr} - total kills {$competitor1}",
        "name_ru": "{!mapnr} карта {roundnr} раунд - {$competitor1} тотал убийств",
        "name_tr": "{!mapnr}. map {roundnr}. raund - {$competitor1} toplam kill",
        "name_se": "{!mapnr} kartrunda {roundnr} - {$competitor1} totalt antal kills",
        "name_el": "{!mapnr} map, γύρος {roundnr} - {$competitor1} total kills"
    },
    "id_827": {
        "name_en": "Bonus ball sum total",
        "name_aa": "مجموع الكرة المكافئة",
        "name_de": "Zusatzzahl Summe insgesamt",
        "name_nl": "Som reservebal totaal",
        "name_pl": "Bonus ball sum total",
        "name_ro": "Sumă bilă bonus total",
        "name_ru": "Бонус бол сумма итог",
        "name_tr": "Bonus ball toplam, toplam sayı",
        "name_se": "Bonusboll summa totalt",
        "name_el": "Bonus ball sum σύνολο"
    },
    "id_234": {
        "name_en": "Highest scoring quarter",
        "name_aa": "أعلى ربع التهديف",
        "name_de": "Viertel mit den meisten Punkten",
        "name_nl": "Kwart met de hoogste score",
        "name_pl": "Kwarta z największą liczbą punktów",
        "name_ro": "Sfertul cu cele mai multe înscrieri",
        "name_ru": "Самая результативная четверть",
        "name_tr": "En fazla sayı olan çeyrek",
        "name_se": "Quarter med högst poäng",
        "name_el": "Περίοδος με περισσότερους πόντους"
    },
    "id_27": {
        "name_en": "{$competitor1} odd/even",
        "name_aa": "{$competitor1} فردي / زوجي",
        "name_de": "{$competitor1} Gerade/ungerade",
        "name_nl": "{$competitor1} even/oneven",
        "name_pl": "{$competitor1} Parzyste/Nieparzyste",
        "name_ro": "Impar/par {$competitor1}",
        "name_ru": "{$competitor1} чет/нечет",
        "name_tr": "{$competitor1} tek/çift",
        "name_se": "{$competitor1} udda/jämnt",
        "name_el": "{$competitor1} Μονά/Ζυγά"
    },
    "id_1107": {
        "name_en": "Laps {from} to {to} - fastest lap",
        "name_aa": "Laps {from} to {to} - fastest lap",
        "name_de": "Laps {from} to {to} - fastest lap",
        "name_nl": "Ronde {from} tot {to} - snelste ronde",
        "name_pl": "Laps {from} to {to} - fastest lap",
        "name_ro": "Laps {from} to {to} - fastest lap",
        "name_ru": "Кругов {from} до {to} - самый быстрый круг",
        "name_tr": "Laps {from} to {to} - fastest lap",
        "name_se": "Laps {from} to {to} - fastest lap",
        "name_el": "Γύροι {from} έως  {to} - ταχύτερος γύρος"
    },
    "id_805": {
        "name_en": "{$event} - top {pos} finish",
        "name_aa": "{$event} - أعلى {pos} النهاية",
        "name_de": "{$event} - top {pos} finish",
        "name_nl": "{$event} - top {pos} finish",
        "name_pl": "{$event} - najlepsza {pos} na koniec",
        "name_ro": "{$event} - să termine în top {pos}",
        "name_ru": "{$event} - верхняя часть {pos} окончание",
        "name_tr": "{$event} - üst {pos} bitiş",
        "name_se": "{$event} - topp {pos} slut",
        "name_el": "{$event} - top {pos} finish"
    },
    "id_507": {
        "name_en": "{!framenr} frame - {$competitor1} break 100+",
        "name_aa": "إطار {!framenr} - {$competitor1} استراحة 100+",
        "name_de": "{!framenr} Frame - {$competitor1} Break 100+",
        "name_nl": "{framenr} frame - {$competitor1} break 100+",
        "name_pl": "{!framenr} frejm - {$competitor1} brejk 100+",
        "name_ro": "Frame-ul {!framenr} - {$competitor1} break 100+",
        "name_ru": "{!framenr} фрейм - {$competitor1} брейк 100+",
        "name_tr": "{!framenr}. frame -  {$competitor1} 100+ break",
        "name_se": "{!framenr} frame - {$competitor1} break 100+",
        "name_el": "{!framenr} frame - {$competitor1}  μπρείκ 100+"
    },
    "id_96": {
        "name_en": "2nd half - {$competitor1} clean sheet",
        "name_aa": "الشوط الثاني - {$competitor1} ورقة نظيفة",
        "name_de": "2. Halbzeit - {$competitor1} ohne Gegentreffer",
        "name_nl": "2e helft - {$competitor1} houdt doel schoon",
        "name_pl": "2. Połowa - {$competitor1} czyste konto",
        "name_ro": "A 2-a repriză - {$competitor1} fără gol primit",
        "name_ru": "2ая половина - {$competitor1} не пропустит",
        "name_tr": "İkinci yarı - {$competitor1} gol yemez",
        "name_se": "2:a halvlek - {$competitor1} håller nollan",
        "name_el": "2ο ημίχρονο -  {$competitor1}  να μη δεχτεί γκολ"
    },
    "id_622": {
        "name_en": "{!mapnr} map - total headshots (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - مجموع الصور الشخصية (بما في ذلك الوقت الإضافي)",
        "name_de": "{!mapnr} Map - Total Kopfschüsse (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - totaal headshots (verlenging inbegrepen)",
        "name_pl": "{!mapnr} mapa - suma strzałów w głowę (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - total headshots (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - тотал выстрелов в голову (включая овертайм)",
        "name_tr": "{!mapnr}. harita - toplam headshot (uzatmalar dahil)",
        "name_se": "{!mapnr} karta - totalt headshots (inkl. övertid)",
        "name_el": "{!mapnr} map - total headshots παράταση συμπεριλαμβάνεται"
    },
    "id_224": {
        "name_en": "US spread (incl. overtime)",
        "name_aa": "انتشار الولايات المتحدة (بما في ذلك الوقت الإضافي)",
        "name_de": "US Spread (inkl. Verlängerung)",
        "name_nl": "US spread (verlenging inbegrepen)",
        "name_pl": "US spread (włącznie z dogrywką)",
        "name_ro": "Interval US (incl. prelungiri)",
        "name_ru": "США спред (включая овертайм)",
        "name_tr": "US spread (uzatmalar dahil)",
        "name_se": "US spread (inkl. övertid)",
        "name_el": "Αμερικάνικο σπρεντ (παράταση συμπ.)"
    },
    "id_956": {
        "name_en": "{!timeoutnr} timeout",
        "name_aa": "{!timeoutnr} timeout",
        "name_de": "{!timeoutnr} timeout",
        "name_nl": "{timeoutnr} timeout",
        "name_pl": "{!timeoutnr} timeout",
        "name_ro": "{!timeoutnr} timeout",
        "name_ru": "{!timeoutnr} тайм-аут",
        "name_tr": "{!timeoutnr} timeout",
        "name_se": "{!timeoutnr} timeout",
        "name_el": "{!timeoutnr} τάιμ-άουτ"
    },
    "id_471": {
        "name_en": "1st half - point range",
        "name_aa": "الشوط الأول - مجموعة نقطة",
        "name_de": "1. Halbzeit - Punktebereich",
        "name_nl": "Eerste helft - punten range",
        "name_pl": "1. Połowa - zakres liczby punktów",
        "name_ro": "Repriza 1 - interval puncte",
        "name_ru": "1ая половина - диапазон очков",
        "name_tr": "İlk yarı - sayı aralığı",
        "name_se": "1:a halvlek - poängintervall",
        "name_el": "1ο ημίχρονο -Εύρος  της νίκης"
    },
    "id_132": {
        "name_en": "Penalty shootout - {$competitor1} odd/even",
        "name_aa": "ركلات الترجيح - {$competitor1} فردي / زوجي",
        "name_de": "Elfmeterschießen - {$competitor1} Gerade/ungerade",
        "name_nl": "Strafschoppenserie - {$competitor1} even/oneven",
        "name_pl": "Rzuty karne - {$competitor1} parzysta/nieparzysta",
        "name_ro": "Lovituri de departajare - impar/par {$competitor1}",
        "name_ru": "Серия пенальти - {$competitor1} чет/нечет",
        "name_tr": "Penaltı atışları - {$competitor1} tek/çift",
        "name_se": "Straffar - {$competitor1} udda/jämnt",
        "name_el": "Διαδικασία πέναλτι -{$competitor1}  Μονά/Ζυγά"
    },
    "id_831": {
        "name_en": "Draw sum total (incl. bonus ball)",
        "name_aa": "رسم المجموع الكلي (بما في ذلك الكرة المكافأة)",
        "name_de": "Gezogene Summe insgesamt (inkl. Zusatzzahl)",
        "name_nl": "Totale som trekking (reservebal inbegrepen)",
        "name_pl": "Draw sum total (incl. bonus ball)",
        "name_ro": "Sumă tragere total (incl. bilă bonus)",
        "name_ru": "Ничья сумма итог (вкл.бонус бол)",
        "name_tr": "Draw toplam , toplam sayı (bonus ball dahil)",
        "name_se": "Oavgjord summa totala (inkl. bonusboll)",
        "name_el": "Draw sum σύνολο (συμπερ. bonus ball)"
    },
    "id_634": {
        "name_en": "Team with highest scoring half",
        "name_aa": "فريق ذو أعلى عدد من النقاط",
        "name_de": "Welches Team gewinnt die Halbzeit mit den meisten Punkten",
        "name_nl": "Team met de helft met de hoogste score",
        "name_pl": "Zespół z najwyżej punktującą połową",
        "name_ro": "Echipa cu cele mai multe înscrieri într-o repriză",
        "name_ru": "Команда с самым результативным таймом",
        "name_tr": "Devrede en yüksek sayıyı yapan takım",
        "name_se": "Lag med flest kills under halvlek",
        "name_el": "Ομάδα με περισσότερα γκολ στο ημίχρονο"
    },
    "id_970": {
        "name_en": "Total sixes spread",
        "name_aa": "Total sixes spread",
        "name_de": "Total sixes spread",
        "name_nl": "Totaal aantal zespunters spread",
        "name_pl": "Total sixes spread",
        "name_ro": "Total sixes spread",
        "name_ru": "Тотал спред шести ранов",
        "name_tr": "Total sixes spread",
        "name_se": "Total sixes spread",
        "name_el": "Total sixes spread"
    },
    "id_650": {
        "name_en": "{!inningnr} innings - {$competitor2} total dismissals",
        "name_aa": "{!inningnr} أدوار - {$competitor2} فصل تام",
        "name_de": "{!inningnr} Inning - {$competitor2} Dismissals Gesamt",
        "name_nl": "{inningnr} innings - totaal aantal dismissals {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma wyeliminowań",
        "name_ro": "{!inningnr} innings - total demiteri {$competitor2}",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал выбиваний",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam oyundan çıkarma",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal avvisningar",
        "name_el": "{!inningnr} innings - {$competitor2} total dismissals"
    },
    "id_1101": {
        "name_en": "Winner (teams)",
        "name_aa": "Winner (teams)",
        "name_de": "Winner (teams)",
        "name_nl": "Winnaar (teams)",
        "name_pl": "Winner (teams)",
        "name_ro": "Winner (teams)",
        "name_ru": "Победитель (команды)",
        "name_tr": "Winner (teams)",
        "name_se": "Winner (teams)",
        "name_el": "Νικητής (teams)"
    },
    "id_152": {
        "name_en": "1st half - total bookings",
        "name_aa": "الشوط الأول - إجمالي الحجوزات",
        "name_de": "1. Halbzeit - Gesamtanzahl Karten",
        "name_nl": "Eerste helft - totaal aantal kaarten",
        "name_pl": "1. Połowa - Suma kartek",
        "name_ro": "Repriza 1 - total cartonașe",
        "name_ru": "1ая половина - Тотал карточек",
        "name_tr": "İlk yarı - toplam kart sayısı",
        "name_se": "1:a halvlek - totalt antal kort",
        "name_el": "1ο ημίχρονο - σύνολο κάρτες"
    },
    "id_1130": {
        "name_en": "{!inningnr} innings - {%player1} + {%player2} + {%player3} total",
        "name_aa": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_de": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_nl": "{!inningnr} innings - {!player1} + {!player2} + {!player3} totaal",
        "name_pl": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_ro": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_ru": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_tr": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_se": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total",
        "name_el": "{!inningnr} innings - {!player1} + {!player2} + {!player3} total"
    },
    "id_126": {
        "name_en": "Penalty shootout - winning margin",
        "name_aa": "ركلات الترجيح - هامش الفوز",
        "name_de": "Elfmeterschießen - Gewinnspanne",
        "name_nl": "Strafschoppenserie - winstmarge",
        "name_pl": "Rzuty karne - margines zwycięstwa",
        "name_ro": "Lovituri de departajare - diferență victorie",
        "name_ru": "Серия пенальти - победа с разницей",
        "name_tr": "Penaltı atışları - Kazanma farkı",
        "name_se": "Straffar - vinstmarginal",
        "name_el": "Διαδικασία πέναλτι - Περιθώριο νίκης"
    },
    "id_418": {
        "name_en": "{$competitor2} exact goals (incl. overtime and penalties)",
        "name_aa": "{$competitor2} أهداف دقيقة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor2} Genaue Anzahl Tore (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Exact aantal doelpunten {$competitor2} (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor2} dokładna liczba goli (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Goluri exact {$competitor2} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor2} точное число голов (включая овертайм и пенальти)",
        "name_tr": "{$competitor2} doğru skor (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor2} exakt antal mål (inkl. övertid och straffar)",
        "name_el": "{$competitor2} Ακριβής αριθμός γκολ  (παράταση και πέναλτι συμπ.)"
    },
    "id_824": {
        "name_en": "Bonus ball odd/even",
        "name_aa": "مكافأة الكرة فردي / زوجي",
        "name_de": "Zusatzzahl ungerade/gerade",
        "name_nl": "Reservebal even/oneven",
        "name_pl": "Bonus ball odd/even",
        "name_ro": "Bilă bonus impar/par",
        "name_ru": "Бонус бол нечетная/четная",
        "name_tr": "Bonus ball tek/çift",
        "name_se": "Bonusboll udda/jämnt",
        "name_el": "Bonus ball μονά/ζυγά"
    },
    "id_662": {
        "name_en": "{!inningnr} innings - {%player} to score {milestone}",
        "name_aa": "{!inningnr} أدوار - {player} ليسجل {milestone}",
        "name_de": "{!inningnr} Inning - {!player} erzielt {milestone}",
        "name_nl": "{inningnr} innings - {player} dat een {milestone} scoort",
        "name_pl": "{!inningnr} innings - {!player} zdobędzie {milestone}",
        "name_ro": "{!inningnr} innings - {!player} să înscrie {milestone}",
        "name_ru": "{!inningnr} иннинг - {!player} наберёт {milestone}",
        "name_tr": "{!inningnr}. devre - {!player} oyuncusu sayı {milestone} yapar",
        "name_se": "{!inningnr} innings - {!player} ta poäng {milestone}",
        "name_el": "{!inningnr} innings - {!player} to score {milestone}"
    },
    "id_964": {
        "name_en": "Any team total maximum consecutive points",
        "name_aa": "Any team total maximum consecutive points",
        "name_de": "Any team total maximum consecutive points",
        "name_nl": "Willekeurig team totaal aantal maximum opeenvolgende punten",
        "name_pl": "Any team total maximum consecutive points",
        "name_ro": "Any team total maximum consecutive points",
        "name_ru": "Любая команда тотал максимум очков подряд",
        "name_tr": "Any team total maximum consecutive points",
        "name_se": "Any team total maximum consecutive points",
        "name_el": "Οποιαδήποτε ομάδα - total maximum consecutive points"
    },
    "id_346": {
        "name_en": "{!inningnr} innings - {$competitor2} run range",
        "name_aa": "أدوار {!inningnr} - {$competitor2} نطاق المدى",
        "name_de": "{!inningnr} Inning - {$competitor2} Laufbereich",
        "name_nl": "{inningnr} innings - run range {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} zakres liczby runs",
        "name_ro": "{!inningnr} innings - interval run-uri {$competitor2}",
        "name_ru": "{!inningnr} иннинг - {$competitor2} дистанция пробежек",
        "name_tr": "{!inningnr}. devre - {$competitor2} koşu aralığı",
        "name_se": "{!inningnr} innings - {$competitor2} run-intervall",
        "name_el": "{!inningnr} innings - {$competitor2} run range"
    },
    "id_396": {
        "name_en": "{!mapnr} map - {!xth} aegis",
        "name_aa": "خريطة {!mapnr} - {!xth} رعاية",
        "name_de": "{!mapnr} Map - {!xth} Aegis",
        "name_nl": "{mapnr} map - {xth} aegis",
        "name_pl": "{!mapnr} mapa - {!xth} aegis",
        "name_ro": "Harta {!mapnr} - aegis {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} аегисов",
        "name_tr": "{!mapnr}. harita - {!xth} kalkan",
        "name_se": "{!mapnr} karta - {!xth} aegis",
        "name_el": "{!mapnr} map - {!xth} aegis"
    },
    "id_128": {
        "name_en": "Penalty shootout - {$competitor1} total",
        "name_aa": "ركلات الترجيح - {$competitor1} مجموعه",
        "name_de": "Elfmeterschießen - {$competitor1} Total",
        "name_nl": "Strafschoppenserie - {$competitor1} totaal",
        "name_pl": "Rzuty karne - {$competitor1} suma",
        "name_ro": "Lovituri de departajare - total {$competitor1}",
        "name_ru": "Серия пенальти - тотал {$competitor1}",
        "name_tr": "Penaltı atışları - {$competitor1} toplam gol",
        "name_se": "Straffar - {$competitor1} totalt",
        "name_el": "Διαδικασία πέναλτι - {$competitor1} Σύνολο Γκολ"
    },
    "id_715": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} run range",
        "name_aa": "{!inningnr} أدوار أكثر من {overnr} - {$competitor1} نطاق تشغيل",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor1} Laufbereich",
        "name_nl": "{inningnr} innings over {overnr} - run range {$competitor1}",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} zakres liczby runs",
        "name_ro": "{!inningnr} innings over {overnr} - {$competitor1} interval run-uri",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor1} дистанция пробежек",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor1} koşu aralığı",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor1} run-intervall",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} run range"
    },
    "id_239": {
        "name_en": "How many games will be decided by extra points",
        "name_aa": "كم عدد الألعاب التي تقررها نقاط اضافية",
        "name_de": "Wie viele Spiele werden durch Extrapunkte entschieden",
        "name_nl": "Hoeveel games worden beslist door extra punten",
        "name_pl": "Ile gemów będzie rozstrzygniętych przez ekstra punkty",
        "name_ro": "Câte game-uri se vor decide prin puncte extra",
        "name_ru": "Сколько игр будут определены дополнительными очками",
        "name_tr": "Ekstra sayılarla kaç oyun belirlenecek",
        "name_se": "Hur många games kommer att avgöra med extra poäng",
        "name_el": "Σε πόσα γκέιμ θα χρειαστούν έξτρα πόντοι"
    },
    "id_800": {
        "name_en": "{$event} matchday {matchday} - most goals home or away teams",
        "name_aa": "{$event} يوم المباراة {matchday} - معظم الأهداف في المنزل أو في الفريق البعيد",
        "name_de": "{$event} Spieltag {matchday} - Meiste Tore Heim- oder Auswärtsmannschaften",
        "name_nl": "{$event} matchday {matchday} - meeste doelpunten thuis- of uitspelende ploegen",
        "name_pl": "{$event} matchday {matchday} - więcej goli gospodarze lub goście",
        "name_ro": "{$event} etapă {matchday} - cele mai multe goluri gazde sau oaspeți",
        "name_ru": "{$event} игровой день {matchday} - больше голов хозяева или гости",
        "name_tr": "{$event} maç günü {matchday} - en çok sayıda gol ev sahibi veya deplasman takımları",
        "name_se": "{$event} - flest mål hemmalaget elr bortalaget",
        "name_el": "{$event} Αγωνιστική {matchday} - περισσότερα γκολ γηπεδούχος ή φιλοξενούμενος"
    },
    "id_361": {
        "name_en": "1st over - odd/even",
        "name_aa": "الأول على - فردي / زوجي",
        "name_de": "1. Over - Gerade/ungerade",
        "name_nl": "eerste over - even/oneven",
        "name_pl": "1. Over - parzyste/nieprzyste",
        "name_ro": "Over-ul 1 - impar/par",
        "name_ru": "1ый овер - чет/нечет",
        "name_tr": "Birinci over - tek/çift",
        "name_se": "1:a över - udda/jämnt",
        "name_el": "1st over - μονά/ζυγά"
    },
    "id_673": {
        "name_en": "{!inningnr} innings - {$competitor2} exact runs",
        "name_aa": "{!inningnr} أدوار - {$competitor2} تشغيل دقيق",
        "name_de": "{!inningnr} Inning - {$competitor2} Genaue Anzahl Runs",
        "name_nl": "{inningnr} innings - exact aantal runs {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} dokładna liczba runs",
        "name_ro": "{!inningnr} innings - {$competitor2} runs exact",
        "name_ru": "{!inningnr} иннинг - {$competitor2} точное число пробежек",
        "name_tr": "{!inningnr}. devre - {$competitor2} net koşu",
        "name_se": "{!inningnr} innings - {$competitor2} exakt antal runs",
        "name_el": "{!inningnr} innings - {$competitor2} exact runs"
    },
    "id_71": {
        "name_en": "1st half - exact goals",
        "name_aa": "الشوط الأول - الأهداف المحددة",
        "name_de": "1. Halbzeit - Genaue Anzahl Tore",
        "name_nl": "1e helft - exact aantal doelpunten",
        "name_pl": "1. Połowa - Dokładna liczba goli",
        "name_ro": "Prima repriză - goluri exact",
        "name_ru": "1ая половина - точное число голов",
        "name_tr": "ilk yarı - doğru skor",
        "name_se": "1:a halvlek - exakta mål",
        "name_el": "1ο ημίχρονο - ακριβής αριθμός γκολ"
    },
    "id_337": {
        "name_en": "{!mapnr} map knife round - winner",
        "name_aa": "خريطة سكين جولة {!mapnr} - الفائز",
        "name_de": "{!mapnr} Map Messer Runde - Gewinner",
        "name_nl": "{mapnr} map knife round - winnaar",
        "name_pl": "{!mapnr} mapa knife runda - zwycięzca",
        "name_ro": "Harta {!mapnr} runda de cuțit - câștigător",
        "name_ru": "{!mapnr} карта раунд с ножом - победитель",
        "name_tr": "{!mapnr}. harita bıçak raundu - kazanan",
        "name_se": "{!mapnr} karta knivrunda - vinnare",
        "name_el": "{!mapnr} map knife round - Νικητής"
    },
    "id_637": {
        "name_en": "1st half - {$competitor2} goal range",
        "name_aa": "الشوط الأول - مجموعة الأهداف {$competitor2}",
        "name_de": "1. Halbzeit - {$competitor2} Tordifferenz",
        "name_nl": "Eerste helft - doelpunten range {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} zakres liczby goli",
        "name_ro": "Repriza 1 - interval goluri {$competitor2}",
        "name_ru": "1-й тайм - {$competitor2} интервал голов",
        "name_tr": "İlk yarı - {$competitor2} gol aralığı",
        "name_se": "1:a halvlek - {$competitor2} målrad",
        "name_el": "1ο ημίχρονο - {$competitor2} Εύρος των γκολ"
    },
    "id_708": {
        "name_en": "{!inningnr} innings - {%player} total dismissals",
        "name_aa": "{player} إجمالي عمليات الفصل",
        "name_de": "{!inningnr} Inning - {!player} Dismissals gesamt",
        "name_nl": "{!inningnr} innings - {!player} totaal aantal dismissals",
        "name_pl": "{!inningnr} innings - {!player} suma wyeliminowań",
        "name_ro": "{!inningnr} innings - {!player} total demiteri",
        "name_ru": "{!inningnr} иннингов - {!player} тотал выбиваний",
        "name_tr": "{!inningnr} devre - {!player} toplam çıkarılma",
        "name_se": "{!inningnr} innings - {!player} totalt avvisande",
        "name_el": "{!inningnr} inning - {!player} total dismissals"
    },
    "id_526": {
        "name_en": "Will there be a 5th set",
        "name_aa": "هل سيكون هناك مجموعة 5",
        "name_de": "Wird es einen 5ten Satz geben?",
        "name_nl": "Zal er een vijfde set nodig zijn",
        "name_pl": "Czy będzie 5. set?",
        "name_ro": "Va fi un al 5-lea set",
        "name_ru": "Будет ли 5ый сет",
        "name_tr": "5. set oynanıcak mı",
        "name_se": "Blir det ett 5:e set",
        "name_el": "Θα υπάρξει 5ο σετ"
    },
    "id_1087": {
        "name_en": "Exact home runs (incl. extra innings)",
        "name_aa": "Exact home runs (incl. extra innings)",
        "name_de": "Genaue Anzahl Home Runs (inkl. Extra Innings)",
        "name_nl": "exact aantal homeruns (incl. extra innings)",
        "name_pl": "Exact home runs (incl. extra innings)",
        "name_ro": "Exact home runs (incl. extra innings)",
        "name_ru": "Прямые хоум раны (включая экстра иннинги)",
        "name_tr": "Exact home runs (incl. extra innings)",
        "name_se": "Exact home runs (incl. extra innings)",
        "name_el": "Ακριβής αριθμός home runs  (συμπ. extra innings)"
    },
    "id_969": {
        "name_en": "Player to score a point",
        "name_aa": "Player to score a point",
        "name_de": "Player to score a point",
        "name_nl": "Scorende speler",
        "name_pl": "Player to score a point",
        "name_ro": "Player to score a point",
        "name_ru": "Игрок забьет",
        "name_tr": "Player to score a point",
        "name_se": "Spelare att göra poäng",
        "name_el": "Παίχτης να σκοράρει πόντο"
    },
    "id_163": {
        "name_en": "{!cornernr} corner",
        "name_aa": "{!cornernr} الزاوية",
        "name_de": "{!cornernr} Ecke",
        "name_nl": "{cornernr} hoekschop",
        "name_pl": "{!cornernr} Rzut rożny",
        "name_ro": "Cornerul {!cornernr}",
        "name_ru": "{!cornernr} угловой",
        "name_tr": "{!cornernr} korner",
        "name_se": "{!cornernr} hörnor",
        "name_el": "{!cornernr} κόρνερ"
    },
    "id_492": {
        "name_en": "Will there be a deciding frame",
        "name_aa": "هل سيكون هناك إطار مقرر",
        "name_de": "Wird es ein entscheidendes Frame geben?",
        "name_nl": "Zal er een beslissende frame nodig zijn",
        "name_pl": "Czy będzie rozstrzygający frejm",
        "name_ro": "Va fi un frame decisiv",
        "name_ru": "Будет ли решающий фрейм",
        "name_tr": "Karar periyodu olucak mı?",
        "name_se": "Blir det en avgörande frame",
        "name_el": "Θα υπάρξει deciding frame"
    },
    "id_979": {
        "name_en": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_aa": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_de": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_nl": "{inningnr} innings - {$competitor2} totaal aantal vierpunters spread",
        "name_pl": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_ro": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал 4 ранов",
        "name_tr": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_se": "{!inningnr} innings - {$competitor2} total fours spread",
        "name_el": "{!inningnr} innings - {$competitor2} total fours spread"
    },
    "id_907": {
        "name_en": "Short term free text market",
        "name_aa": "Short term free text market",
        "name_de": "Short term free text market",
        "name_nl": "Short term free text market",
        "name_pl": "Short term free text market",
        "name_ro": "Short term free text market",
        "name_ru": "Short term free text market",
        "name_tr": "Short term free text market",
        "name_se": "Short term free text market",
        "name_el": "Short term free text market"
    },
    "id_5": {
        "name_en": "Winning method",
        "name_aa": "طريقة الفوز",
        "name_de": "Womit wird das Spiel entschieden?",
        "name_nl": "Manier om te winnen",
        "name_pl": "W jaki sposób rozstrzygnie się mecz?",
        "name_ro": "Metoda victoriei",
        "name_ru": "Способ победы",
        "name_tr": "Kazanma Yöntemi",
        "name_se": "Vinstmetod",
        "name_el": "Μέθοδος νίκης"
    },
    "id_790": {
        "name_en": "Player marks",
        "name_aa": "علامات لاعب",
        "name_de": "Spieler-Noten",
        "name_nl": "Speler marks",
        "name_pl": "Player marks",
        "name_ro": "Trasări jucător",
        "name_ru": "Игрок линии",
        "name_tr": "Oyuncu mark eder",
        "name_se": "Spelare markerar",
        "name_el": "Παίχτης marks"
    },
    "id_1125": {
        "name_en": "{!inningnr} innings - {%player1} to score {milestone} & {%player2} over {total} dismissals",
        "name_aa": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_de": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_nl": "{!inningnr} innings - {!player1} scoort {milestone} & {!player2} over {total} dismissals",
        "name_pl": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_ro": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_ru": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_tr": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_se": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals",
        "name_el": "{!inningnr} innings - {!player1} to score {milestone} & {!player2} over {total} dismissals"
    },
    "id_700": {
        "name_en": "{!inningnr} innings - any player to score {milestone}",
        "name_aa": "{!inningnr} الأدوار - أي لاعب يسجل {milestone}",
        "name_de": "{!inningnr} Inning - Jeder Spieler erzielt {milestone}",
        "name_nl": "{inningnr} innings - er wordt een {milestone} gescoord",
        "name_pl": "{!inningnr} innings - którykolwiek zawodnik zdobędzie {milestone}",
        "name_ro": "{!inningnr} innings - oricare jucător să înscrie {milestone}",
        "name_ru": "{!inningnr} иннинг - любой игрок наберёт {milestone}",
        "name_tr": "{!inningnr}. devre - herhangi bir oyuncu sayı {milestone} yapar",
        "name_se": "{!inningnr} innings - vilken spelare att ta poäng {milestone}",
        "name_el": "{!inningnr} innings - any player to score {milestone}"
    },
    "id_498": {
        "name_en": "Frames 1 to {framenr} - 1x2",
        "name_aa": "الإطارات 1 إلى {framenr} - 1 × 2",
        "name_de": "Frames 1 zu {framenr} - 1x2",
        "name_nl": "Frame 1 tot {framenr} 1x2",
        "name_pl": "Frejmy 1 do {framenr} - 1x2",
        "name_ro": "Frame-urile de la 1 la {framenr} - 1x2",
        "name_ru": "Фреймы 1 до {framenr} - 1x2",
        "name_tr": "İlk Franmeden {framenr} e - 1x2",
        "name_se": "Frames 1 till {framenr} - 1x2",
        "name_el": "Frames 1 σε {framenr} - 1x2"
    },
    "id_556": {
        "name_en": "{!mapnr} map - {!xth} dragon",
        "name_aa": "{!mapnr} خريطة - {!xth} تنين",
        "name_de": "{!mapnr} Map - {!xth} Drache",
        "name_nl": "{mapnr} map - {xth} dragon",
        "name_pl": "{!mapnr} mapa - {!xth} smok",
        "name_ro": "Harta {!mapnr} - drgon {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} дракон",
        "name_tr": "{!mapnr}. harita- {!xth} dragon",
        "name_se": "{!mapnr} karta - {!xth} dragon",
        "name_el": "{!mapnr} map - {!xth} dragon"
    },
    "id_731": {
        "name_en": "{!mapnr} map - race to {xth} kills",
        "name_aa": "{!mapnr} خريطة - السباق إلى {xth} يقتل",
        "name_de": "{!mapnr} Map - Wer erreicht zuerst {xth} Kills",
        "name_nl": "{mapnr} map - race naar {xth} kills",
        "name_pl": "{!mapnr} mapa - kto pierwszy z {xth} zabójstwami",
        "name_ro": "Harta {!mapnr} - primul la {xth} kills",
        "name_ru": "{!mapnr} карта - гонка до {xth} убийств",
        "name_tr": "{!mapnr}. harita - {xth}. kill çekşmesi",
        "name_se": "{!mapnr} karta - först till {xth} kills",
        "name_el": "{!mapnr} map - Ποιος θα φτάσει 1ος  {xth} kills"
    },
    "id_165": {
        "name_en": "Corner handicap",
        "name_aa": "هانديكاب الزاوية",
        "name_de": "Ecken Handicap",
        "name_nl": "Hoekschop handicap",
        "name_pl": "Rzuty rożne Handicap",
        "name_ro": "Handicap cornere",
        "name_ru": "Угловой гандикап",
        "name_tr": "Korner handikap",
        "name_se": "Hörnor handikapp",
        "name_el": "Χάντικαπ κόρνερ"
    },
    "id_153": {
        "name_en": "1st half - {$competitor1} total bookings",
        "name_aa": "النصف الأول - إجمالي الحجوزات {$competitor1}",
        "name_de": "1. Halbzeit - {$competitor1} Gesamtanzahl Karten",
        "name_nl": "Eerste helft -  totaal aantal kaarten {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} Suma kartek",
        "name_ro": "Repriza 1 - total cartonașe {$competitor1}",
        "name_ru": "1ая половина - {$competitor1} тотал карточек",
        "name_tr": "İlk yarı - {$competitor1} kart sayısı",
        "name_se": "1:a halvlek - {$competitor1} totalt antal kort",
        "name_el": "1ο ημίχρονο - {$competitor1} σύνολο κάρτες"
    },
    "id_119": {
        "name_en": "Overtime 1st half - 1x2",
        "name_aa": "الوقت الإضافي الشوط الأول - 1x2",
        "name_de": "Verlängerung 1. Halbzeit - 1x2",
        "name_nl": "Eerste helft verlenging - 1x2",
        "name_pl": "Dogrywka 1. połowa - 1x2",
        "name_ro": "Prelungiri Repriza 1 - 1x2",
        "name_ru": "Овертайм 1ая половина - 1х2",
        "name_tr": "İlk uzatma devresi - 1x2",
        "name_se": "Övertid 1:a halvlek - 1x2",
        "name_el": "Παράταση 1ο ημίχρονο - 1Χ2"
    },
    "id_404": {
        "name_en": "{!goalnr} goal (incl. overtime)",
        "name_aa": "{!goalnr} الهدف (بما في ذلك الوقت الإضافي)",
        "name_de": "{!goalnr} Tor (inkl. Verlängerung)",
        "name_nl": "{goalnr} doelpunt (verlenging inbegrepen)",
        "name_pl": "{!goalnr} gol (włącznie z dogrywką)",
        "name_ro": "Golul {!goalnr} (incl. prelungiri)",
        "name_ru": "{!goalnr} гол (включая овертайм)",
        "name_tr": "{!goalnr}. gol (uzatmalar dahil)",
        "name_se": "{!goalnr} mål (inkl. övertid)",
        "name_el": "{!goalnr} γκολ (παράταση συμπ.)"
    },
    "id_4": {
        "name_en": "Which team will win the 3rd place final",
        "name_aa": "الفريق الذي سيفوز في المركز الثالث النهائي",
        "name_de": "Welches Team gewinnt Spiel um Platz 3",
        "name_nl": "Welk team wint de strijd om de 3e plaats",
        "name_pl": "Który zespół wygra mecz o trzecie miejsce",
        "name_ro": "Care echipă va caștiga finala pentru locul 3",
        "name_ru": "Какая команда выиграет матч за 3 место",
        "name_tr": "Finalde 3. olacak takım",
        "name_se": "Vilket lag vinner matchen om tredjepris",
        "name_el": "Ποια ομάδα θα κερδίσει το μικρό τελικό"
    },
    "id_825": {
        "name_en": "Bonus ball sum odd/even",
        "name_aa": "مكافأة الكرة مبلغ فردي / زوجي",
        "name_de": "Zusatzzahl Summe ungerade/gerade",
        "name_nl": "Som reservebal even/oneven",
        "name_pl": "Bonus ball sum odd/even",
        "name_ro": "Sumă bilă bonus impar/par",
        "name_ru": "Бонус бол сумма нечетная/четная",
        "name_tr": "Bonus ball toplam tek/çift",
        "name_se": "Bonusbollsumma udda/jämnt",
        "name_el": "Bonus ball sum μονά/ζυγά"
    },
    "id_175": {
        "name_en": "1st half - last corner",
        "name_aa": "الشوط الأول - الزاوية الأخيرة",
        "name_de": "1. Halbzeit - Letzte Ecke",
        "name_nl": "Eerste helft - laatste hoekschop",
        "name_pl": "1. Połowa - Ostatni Rzut rożny",
        "name_ro": "Repriza 1 - ultimul corner",
        "name_ru": "1ая половина - последний угловой",
        "name_tr": "İlk yarı - son korner",
        "name_se": "1:a halvlek - sista hörnan",
        "name_el": "1ο ημίχρονο - τελευταίο κόρνερ"
    },
    "id_542": {
        "name_en": "1st half - double chance & both teams to score",
        "name_aa": "الشوط الأول - فرصة مزدوجة وكلا الفريقين للتسجيل",
        "name_de": "1. Halbzeit - Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Eerste helft - dubbele kans & beide teams scoren",
        "name_pl": "1. Połowa - Podwójna szansa & oba zespoły strzelą",
        "name_ro": "Repriza 1 - șansă dublă & ambele echipe să înscrie",
        "name_ru": "1ая половина - двойной шанс и обе команды забьют",
        "name_tr": "İlk yarı - çifte şans & her iki takımda gol atar",
        "name_se": "1:a halvlek - dubbelchans & båda lagen gör mål",
        "name_el": "1ο ημίχρονο - διπλή ευκαιρία και να σκοράρουν και οι 2 ομάδες"
    },
    "id_227": {
        "name_en": "{$competitor1} total (incl. overtime)",
        "name_aa": "{$competitor1} الإجمالي (بما في ذلك الوقت الإضافي)",
        "name_de": "{$competitor1} Total (inkl. Verlängerung)",
        "name_nl": "{$competitor1} totaal (verlenging inbegrepen)",
        "name_pl": "{$competitor1} Suma (włącznie z dogrywką)",
        "name_ro": "Total (incl. prelungiri) {$competitor1}",
        "name_ru": "{$competitor1} тотал (включая овертайм)",
        "name_tr": "{$competitor1} toplam gol (uzatmalar dahil)",
        "name_se": "{$competitor1} totalt (inkl. övertid)",
        "name_el": "{$competitor1} - σύνολο (παράταση συμπ.)"
    },
    "id_406": {
        "name_en": "Winner (incl. overtime and penalties)",
        "name_aa": "الفائز (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Gewinner (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Winnaar (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Zwycięzca (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Câștigătoare (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Победитель (включая овертайм и пенальти)",
        "name_tr": "Kazanan (uzatmalar ve penaltılar dahil)",
        "name_se": "Vinnare (inkl. övertid och straffar)",
        "name_el": "Νικητής (παράταση και πέναλτι συμπ.)"
    },
    "id_792": {
        "name_en": "Player disposals",
        "name_aa": "التخلص من لاعب",
        "name_de": "Spieler-Abgänge",
        "name_nl": "Speler disposals",
        "name_pl": "Player disposals",
        "name_ro": "Pase jucător",
        "name_ru": "Игрок расстановка",
        "name_tr": "Oyuncu kurtarır",
        "name_se": "Spelarens avyttringar",
        "name_el": "Παίχτης disposals"
    },
    "id_901": {
        "name_en": "{$competitor2} {!c2goalnr} goalscorer",
        "name_aa": "{$competitor2} {!c2goalnr} goalscorer",
        "name_de": "{$competitor2} {!c2goalnr} Torschütze",
        "name_nl": "{$competitor2} {!c2goalnr} doelpuntenmaker",
        "name_pl": "{$competitor2} {!c2goalnr} goalscorer",
        "name_ro": "{$competitor2} marcator {!c2goalnr}",
        "name_ru": "{$competitor2} {!c2goalnr} бомбардир",
        "name_tr": "{$competitor2} {!c2goalnr} goalscorer",
        "name_se": "{$competitor2} {!c2goalnr} målgörare",
        "name_el": "{$competitor2} {!c2goalnr} σκόρερ"
    },
    "id_555": {
        "name_en": "{!mapnr} map - kill handicap",
        "name_aa": "{!mapnr} خريطة - اقتل هانديكاب",
        "name_de": "{!mapnr} Map - Kill Handicap",
        "name_nl": "{mapnr} map - kill handicap",
        "name_pl": "{!mapnr} mapa - zabójstwa handicap",
        "name_ro": "Harta {!mapnr} - handicap kill-uri",
        "name_ru": "{!mapnr} карта - гандикап убийств",
        "name_tr": "{!mapnr}. harita - kill handikap",
        "name_se": "{!mapnr} karta - kill handikapp",
        "name_el": "{!mapnr} map - kill χάντικαπ"
    },
    "id_976": {
        "name_en": "Total fours spread",
        "name_aa": "Total fours spread",
        "name_de": "Total fours spread",
        "name_nl": "Totaal aantal vierpunters spread",
        "name_pl": "Total fours spread",
        "name_ro": "Total fours spread",
        "name_ru": "Тотал спред 4 ранов",
        "name_tr": "Total fours spread",
        "name_se": "Total fours spread",
        "name_el": "Total fours spread"
    },
    "id_248": {
        "name_en": "{!gamenr} game - odd/even",
        "name_aa": "{!gamenr} اللعبة - فردي / زوجي",
        "name_de": "{!gamenr} Spiel - Gerade/ungerade",
        "name_nl": "{gamenr} game - even/oneven",
        "name_pl": "{!gamenr} gem - parzyste/nieparzyste",
        "name_ro": "Game-ul {!gamenr} - impar/par",
        "name_ru": "{!gamenr} игра - чет/нечет",
        "name_tr": "{!gamenr}. oyun - tek/çift",
        "name_se": "{!gamenr} game - udda/jämnt",
        "name_el": "{!gamenr} γκέιμ - μονά/ζυγά"
    },
    "id_582": {
        "name_en": "5 minutes - total corners from {from} to {to}",
        "name_aa": "5 دقائق - الزوايا الكلية من {from} إلى {to}",
        "name_de": "5 Minuten - Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "5 minutes - totaal aantal hoekschoppen van {from} tot {to}",
        "name_pl": "5 minut - suma rzutów rożnych od {from} do {to}",
        "name_ro": "5 minute - total cornere de la {from} la {to}",
        "name_ru": "5 минут - тотал угловых с {from} до {to}",
        "name_tr": "5 takika - toplam korner sayısı {from} dan {to} a",
        "name_se": "5 minuter - totalt antal hörnor från {from} till {to}",
        "name_el": "5  λεπτά - σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_381": {
        "name_en": "Most 180s",
        "name_aa": "معظم ثانية180",
        "name_de": "Die meisten 180s",
        "name_nl": "Meeste 180s",
        "name_pl": "Najwięcej 180s",
        "name_ro": "Cele mai multe 180-uri",
        "name_ru": "Большинство 180",
        "name_tr": "En çok 180",
        "name_se": "Flest 180:or",
        "name_el": "Περισσότερα 180άρια"
    },
    "id_894": {
        "name_en": "{!setnr} set tiebreak - correct score",
        "name_aa": "{!setnr} وضع شوط كسر التعادل - النتيجة الصحيحة",
        "name_de": "{!setnr} Satz tiebreak - richtiger Punktestand",
        "name_nl": "{!setnr} set tiebreak - juiste score",
        "name_pl": "{!setnr} set tiebreak - correct score",
        "name_ro": "Set {!setnr} tiebreak - scor corect",
        "name_ru": "{!setnr} сет тайбрейк - правильный счет",
        "name_tr": "{!setnr} set tiebreak - correct score",
        "name_se": "{!setnr} set tiebreak - rätt resultat",
        "name_el": "{!setnr} σετ τάι-μπρέικ - ακριβές σκορ"
    },
    "id_538": {
        "name_en": "Head2head (1x2)",
        "name_aa": "رأس الرأس (1 × 2)",
        "name_de": "Head2head (1x2)",
        "name_nl": "Head2head (1x2)",
        "name_pl": "Head2head (1x2)",
        "name_ro": "Confruntare directă (1x2)",
        "name_ru": "Единоборство (1x2)",
        "name_tr": "Head to head (1x2)",
        "name_se": "Head2head (1x2)",
        "name_el": "Μεταξύ τους 1Χ2"
    },
    "id_1025": {
        "name_en": "Hole {holenr} - any player under par",
        "name_aa": "Hole {holenr} - any player under par",
        "name_de": "Hole {holenr} - any player under par",
        "name_nl": "Hole {holenr} - willekeurige speler onder par",
        "name_pl": "Hole {holenr} - any player under par",
        "name_ro": "Hole {holenr} - any player under par",
        "name_ru": "Hole {holenr} - any player under par",
        "name_tr": "Hole {holenr} - any player under par",
        "name_se": "Hole {holenr} - any player under par",
        "name_el": "Hole {holenr} - any player under par"
    },
    "id_541": {
        "name_en": "Double chance (match) & 2nd half both teams score",
        "name_aa": "فرصة مزدوجة (مباراة) و الشوط الثاني كلا الفريقين يسجلان",
        "name_de": "Doppelte Chance (Spiel) & 2. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Dubbele kans (match) & beide teams scoren in tweede helft",
        "name_pl": "Podwójna szansa (mecz) & 2. połowa oba zespoły strzelą",
        "name_ro": "Șansă dublă (meci) & repriza 2 ambele echipe să înscrie",
        "name_ru": "Двойной шанс (матч) и 2ая половина обе команды забьют",
        "name_tr": "Çifte şans (maç) & ikinci yarı her iki takımda gol atar",
        "name_se": "Dubbelchans (match) & 2:a halvlek gör båda lagen mål",
        "name_el": "Διπλή ευκαιρία (αγώνας) και να σκοράρουν και οι 2 ομάδες στο 2ο ημίχρονο"
    },
    "id_826": {
        "name_en": "Bonus ball total",
        "name_aa": "مجموع الكرة المكافئة",
        "name_de": "Zusatzzahl insgesamt",
        "name_nl": "Reservebal totaal",
        "name_pl": "Bonus ball total",
        "name_ro": "Bilă bonus total",
        "name_ru": "Бонус бол итог",
        "name_tr": "Bonus ball toplam",
        "name_se": "Bonusboll totalt",
        "name_el": "Bonus ball σύνολο"
    },
    "id_624": {
        "name_en": "{!mapnr} map - player with most kills (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - لاعب مع معظم يقتل (بما في ذلك العمل الإضافي)",
        "name_de": "{!mapnr} Map - Spieler mit den meisten Kills (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - speler met de meeste kills (verlenging inbegrepen)",
        "name_pl": "{!mapnr} mapa - zawodnik z największą liczbą zabójstw (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - jucătorul cu cele mai multe kills (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - игрок с наибольшим числом убийств (включая овертайм)",
        "name_tr": "{!mapnr}. harita - en çok kill olan oyuncu (uzatmalar dahil)",
        "name_se": "{!mapnr} karta - spelare med flest kills (inkl. övertid)",
        "name_el": "{!mapnr} map - παίχτης με περισσότερα kills παράταση συμπεριλαμβάνεται"
    },
    "id_759": {
        "name_en": "Player passing yards (incl. overtime)",
        "name_aa": "لاعب يمر ياردة",
        "name_de": "Spieler Passing Yards (inkl. Verlängerung)",
        "name_nl": "Speler passing yards",
        "name_pl": "Player passing yards (incl. overtime)",
        "name_ro": "Yarzi pase jucător",
        "name_ru": "Игрок набравший больше ярдов на пасе",
        "name_tr": "Oyuncu yardı geçiyor",
        "name_se": "Spelare - passningsyards",
        "name_el": "Παίχτης passing yards"
    },
    "id_95": {
        "name_en": "2nd half - both teams to score",
        "name_aa": "الشوط الثاني - كلا الفريقين ليسجل",
        "name_de": "2. Halbzeit - Beide Mannschaften treffen",
        "name_nl": "2e helft - beide teams scoren",
        "name_pl": "2. Połowa - Oba zespoły zdobędą gola",
        "name_ro": "A 2-a repriză - ambele echipe să înscrie",
        "name_ru": "2ая половина - обе команды забьют",
        "name_tr": "İkinci yarı - her iki takım da gol atar",
        "name_se": "2:a halvlek - båda lagen gör mål",
        "name_el": "2ο ημίχρονο - να σκοράρουν και οι 2 ομάδες"
    },
    "id_319": {
        "name_en": "{!setnr} set - {$competitor2} total",
        "name_aa": "{!setnr} setnr مجموعة - {$competitor2} مجموعه",
        "name_de": "{!setnr} Satz - {$competitor2} Total",
        "name_nl": "{setnr} set - {$competitor2} totaal",
        "name_pl": "{!setnr} set - {$competitor2} suma",
        "name_ro": "Set-ul {!setnr} - total {$competitor2}",
        "name_ru": "{!setnr} сет - {$competitor2} тотал",
        "name_tr": "{!setnr}. set - {$competitor2}  toplam",
        "name_se": "{!setnr} set - {$competitor2} totalt",
        "name_el": "{!setnr} σετ - {$competitor2} σύνολο"
    },
    "id_1077": {
        "name_en": "{!inningnr} inning - {$competitor1} total pitches",
        "name_aa": "{!inningnr} inning - {$competitor1} total pitches",
        "name_de": "{!inningnr} inning - {$competitor1} total pitches",
        "name_nl": "{inningnr} inning - totaal aantal worpen {$competitor1}",
        "name_pl": "{!inningnr} inning - {$competitor1} total pitches",
        "name_ro": "{!inningnr} inning - {$competitor1} total pitches",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал поданных подач питчером",
        "name_tr": "{!inningnr} inning - {$competitor1} total pitches",
        "name_se": "{!inningnr} inning - {$competitor1} total pitches",
        "name_el": "{!inningnr} inning - {$competitor1} σύνολο pitches"
    },
    "id_588": {
        "name_en": "15 minutes - sending off from {from} to {to}",
        "name_aa": "15 دقيقة - طرد من {from} إلى {to}",
        "name_de": "15 Minuten - Platzverweise von {from} bis {to}",
        "name_nl": "15 minutes - uitsluiting van {from} tot {to}",
        "name_pl": "15 minut - czerwona kartka od {from} do {to}",
        "name_ro": "15 minute - eliminare de la {from} la {to}",
        "name_ru": "15 минут - удаление с {from} до {to}",
        "name_tr": "15 dakika - oyundan atılma {from} dan {to} a",
        "name_se": "15 minuter - utvisning från {from} till {to}",
        "name_el": "15 λεπτά - να αποβληθεί από {from} μέχρι {to}"
    },
    "id_1091": {
        "name_en": "Top {winners}",
        "name_aa": "Top {winners}",
        "name_de": "Top {winners}",
        "name_nl": "Beste {winners}",
        "name_pl": "Top {winners}",
        "name_ro": "Top {winners}",
        "name_ru": "Топ {winners}",
        "name_tr": "Top {winners}",
        "name_se": "Top {winners}",
        "name_el": "Top {winners}"
    },
    "id_603": {
        "name_en": "{!goalnr} goal - strike zone",
        "name_aa": "{!goalnr} هدف - منطقة الإضراب",
        "name_de": "{!goalnr} Tor - Schlagzone",
        "name_nl": "{goalnr} doelpunt - strike zone",
        "name_pl": "{!goalnr} gol - strefa strzału",
        "name_ro": "Golul {!goalnr} - zona loviturii",
        "name_ru": "{!goalnr} гол - зона удара",
        "name_tr": "{!goalnr}. gol - vuruş alanı",
        "name_se": "{!goalnr} mål - strike zone",
        "name_el": "{!goalnr} γκολ - ζώνη γκολ"
    },
    "id_962": {
        "name_en": "{$competitor1} total maximum consecutive points",
        "name_aa": "{$competitor1} total maximum consecutive points",
        "name_de": "{$competitor1} total maximum consecutive points",
        "name_nl": "{$competitor1} totaal aantal maximum opeenvolgende punten",
        "name_pl": "{$competitor1} total maximum consecutive points",
        "name_ro": "{$competitor1} total maximum consecutive points",
        "name_ru": "{$competitor1} тотал максимум очков подряд",
        "name_tr": "{$competitor1} total maximum consecutive points",
        "name_se": "{$competitor1} total maximum consecutive points",
        "name_el": "{$competitor1} total maximum consecutive points"
    },
    "id_304": {
        "name_en": "{!quarternr} quarter - odd/even",
        "name_aa": "{!quarternr} الربع -فردي / زوجي",
        "name_de": "{!quarternr} Viertel - Gerade/ungerade",
        "name_nl": "{quarternr} kwart - even/oneven",
        "name_pl": "{!quarternr} kwarta - parzyste/nieparzyste",
        "name_ro": "Sfertul {!quarternr} - impar/par",
        "name_ru": "{!quarternr} четверть - чет/нечет",
        "name_tr": "{!quarternr} çeyrek - tek/çift",
        "name_se": "{!quarternr} quarter - udda/jämnt",
        "name_el": "{!quarternr} περίοδος - μονά/ζυγά"
    },
    "id_920": {
        "name_en": "{%player} total receptions (incl. overtime)",
        "name_aa": "{!player} total receptions (incl. overtime)",
        "name_de": "{!player} Receptions Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal receptions",
        "name_pl": "{!player} total receptions (incl. overtime)",
        "name_ro": "{!player} total receptions (incl. overtime)",
        "name_ru": "{!player} тотал приемов",
        "name_tr": "{!player} total receptions (incl. overtime)",
        "name_se": "{!player} total receptions (incl. overtime)",
        "name_el": "{!player} total receptions"
    },
    "id_1152": {
        "name_en": "{%player} total rushing touchdowns (incl. overtime)",
        "name_aa": "{!player} total rushing touchdowns (incl. overtime)",
        "name_de": "{!player} total rushing touchdowns (incl. overtime)",
        "name_nl": "{!player} totaal aantal rushing touchdowns (incl. overtime)",
        "name_pl": "{!player} total rushing touchdowns (incl. overtime)",
        "name_ro": "{!player} total rushing touchdowns (incl. overtime)",
        "name_ru": "{!player} total rushing touchdowns (incl. overtime)",
        "name_tr": "{!player} total rushing touchdowns (incl. overtime)",
        "name_se": "{!player} total rushing touchdowns (incl. overtime)",
        "name_el": "{!player} total rushing touchdowns (incl. overtime)"
    },
    "id_495": {
        "name_en": "Odd/even frames",
        "name_aa": "إطارات فردي / زوجي",
        "name_de": "Gerade/ungerade Frames",
        "name_nl": "Even/oneven aantal frames",
        "name_pl": "Parzyste/Nieparzyste frejmy",
        "name_ro": "Număr impar/par de frame-uri",
        "name_ru": "Чет/нечет фреймов",
        "name_tr": "Tek/çift frame",
        "name_se": "Udda/jämnt frames",
        "name_el": "Μονά/Ζυγά frames"
    },
    "id_690": {
        "name_en": "{!inningnr} innings - {$competitor1} odd/even",
        "name_aa": "{!inningnr} أدوار - {$competitor1} فردي / زوجي",
        "name_de": "{!inningnr} Inning - {$competitor1} Gerade/ungerade",
        "name_nl": "{inningnr} innings - {$competitor1} even/oneven",
        "name_pl": "{!inningnr} innings - {$competitor1} parzyste/nieparzyste",
        "name_ro": "{!inningnr} innings - {$competitor1} impar/par",
        "name_ru": "{!inningnr} иннинг - {$competitor1} чет/нечет",
        "name_tr": "{!inningnr}. devre - {$competitor1} tek çift",
        "name_se": "{!inningnr} innings - {$competitor1} udda/jämnt",
        "name_el": "{!inningnr} innings - {$competitor1} μονά - ζυγά"
    },
    "id_356": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} total",
        "name_aa": "{!inningnr} أدوار المبلغ {overnr} - {$competitor1} المجموع",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor1} Total",
        "name_nl": "{inningnr} innings over {overnr} - totaal {$competitor1}",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} suma",
        "name_ro": "{!inningnr} innings over {overnr} - total {$competitor1}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {$competitor1} тотал",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor1} toplam",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor1} totalt",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} σύνολο"
    },
    "id_571": {
        "name_en": "15 minutes - odd/even corners from {from} to {to}",
        "name_aa": "15 دقيقة - فردي / زوجي زوايا من {from} إلى {to}",
        "name_de": "15 Minuten - Gerade/ungerade Ecken von {from} bis {to}",
        "name_nl": "15 minutes - even/oneven aantal hoekschoppen van {from} tot {to}",
        "name_pl": "15 minut - parzyste/nieparzyste rzuty rożne od {from} do {to}",
        "name_ro": "15 minute - număr de cornere impar/par de la {from} la {to}",
        "name_ru": "15 минут - чет/нечет угловые с {from} до {to}",
        "name_tr": "15 dakika - tek/çift korner {from} den {to} a",
        "name_se": "15 minuter - udda/jämnt hörnor från {from} till {to}",
        "name_el": "15 λεπτά - μονά/ζυγά κόρνερ από {from} μέχρι {to}"
    },
    "id_32": {
        "name_en": "{$competitor2} clean sheet",
        "name_aa": "{$competitor2} شباكه نظيفة",
        "name_de": "{$competitor2} ohne Gegentreffer",
        "name_nl": "{$competitor2} houdt doel schoon",
        "name_pl": "{$competitor2} czyste konto",
        "name_ro": "{$competitor2} fără gol primit",
        "name_ru": "{$competitor2} не пропустит",
        "name_tr": "{$competitor2} gol yemez",
        "name_se": "{$competitor2}  håller nollan",
        "name_el": "{$competitor2} να μη δεχτεί γκολ"
    },
    "id_738": {
        "name_en": "When will the match be decided",
        "name_aa": "متى سيتم تحديد المباراة",
        "name_de": "Wann wird das Spiel entschieden werden",
        "name_nl": "Wanneer wordt de wedstrijd beslist",
        "name_pl": "Kiedy zostanie rozstrzygnięty mecz",
        "name_ro": "Când se va decide meciul",
        "name_ru": "Когда решиться исход матча",
        "name_tr": "Maç ne zaman belli olacak",
        "name_se": "När kommer matchen att avgöras",
        "name_el": "Πότε θα καθοριστεί ο αγώνας"
    },
    "id_41": {
        "name_en": "Correct score [{score}]",
        "name_aa": "النتيجة الصحيحة [{score}]",
        "name_de": "Genaues Ergebnis [{score}]",
        "name_nl": "Juiste uitslag [{score}]",
        "name_pl": "Dokładny wynik [{score}]",
        "name_ro": "Scor corect [{score}]",
        "name_ru": "Точный счет [{score}]",
        "name_tr": "Maç sonucu [{score}]",
        "name_se": "Rätt resultat [{score}]",
        "name_el": "Ακριβές σκορ [{score}]"
    },
    "id_926": {
        "name_en": "{%player} total bases (incl. extra innings)",
        "name_aa": "{!player} total bases (incl. extra innings)",
        "name_de": "{!player} Bases Gesamt (inkl. Extra Innings)",
        "name_nl": "{!player} totaal aantal honken",
        "name_pl": "{!player} total bases (incl. extra innings)",
        "name_ro": "{!player} total bases (incl. extra innings)",
        "name_ru": "{!player} тотал баз",
        "name_tr": "{!player} total bases (incl. extra innings)",
        "name_se": "{!player} total bases (incl. extra innings)",
        "name_el": "{!player} total bases"
    },
    "id_343": {
        "name_en": "{!inningnr} innings - {!dismissalnr} dismissal method",
        "name_aa": "أدوار {!inningnr} - {!dismissalnr}  طريقة إقالة",
        "name_de": "{!inningnr} Inning - {!dismissalnr} Dismissal Methode",
        "name_nl": "{inningnr} innings - {dismissalnr} dismissal methode",
        "name_pl": "{!inningnr} innings - {!dismissalnr} metoda wyeliminowania",
        "name_ro": "{!inningnr} innings - metoda demiterii {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {!dismissalnr} методов выбивания",
        "name_tr": "{!inningnr}. devre - {!dismissalnr}. oyundan çıkarılma methodu",
        "name_se": "{!inningnr} innings - {!dismissalnr} avvisande metod",
        "name_el": "{!inningnr} innings - {!dismissalnr} dismissal method"
    },
    "id_51": {
        "name_en": "{$competitor2} to win either half",
        "name_aa": "{$competitor2} للفوز إما الشوط",
        "name_de": "{$competitor2}Sieger in einer der Halbzeiten",
        "name_nl": "{$competitor2} wint minstens een helft",
        "name_pl": "{$competitor2} wygra jakąkolwiek połowę",
        "name_ro": "{$competitor2} să câștige oricare repriză",
        "name_ru": "{$competitor2} выиграет любой тайм",
        "name_tr": "{$competitor2} en az bir yarıyı kazanır",
        "name_se": "{$competitor2} vinna minst en av halvlekarna",
        "name_el": "{$competitor2} να κερδίσει ένα ημίχρονο"
    },
    "id_336": {
        "name_en": "{!mapnr} map - correct score (in rounds)",
        "name_aa": "خريطة {!mapnr} - النتيجة الصحيحة (في جولات)",
        "name_de": "{!mapnr} Map - Genaues Ergebnis (in Runden)",
        "name_nl": "{mapnr} map - juiste score (in rounds)",
        "name_pl": "{!mapnr} mapa - dokładny wynik (w rundach)",
        "name_ro": "Harta {!mapnr} - scor corect (în runde)",
        "name_ru": "{!mapnr} карта - точный счет (в раундах)",
        "name_tr": "{!mapnr}. harita - doğru skor (rauntta)",
        "name_se": "{!mapnr} karta - rätt resultat (i rundor)",
        "name_el": "{!mapnr} map - ακριβές σκορ (rounds)"
    },
    "id_591": {
        "name_en": "10 minutes - booking 1x2 from {from} to {to}",
        "name_aa": "10 دقائق - حجز 1 × 2 من {from} إلى {to}",
        "name_de": "10 Minuten - Karten 1x2 von {from} bis {to}",
        "name_nl": "10 minutes - kaart 1x2 van {from} tot {to}",
        "name_pl": "10 minut - kartki 1x2 od {from} do {to}",
        "name_ro": "10 minute - cartonaș 1x2 de la {from} la {to}",
        "name_ru": "10 минут - карточки 1х2 с {from} до {to}",
        "name_tr": "10 dakika - kart 1x2 {from} dan {to} a",
        "name_se": "10 minuter - kort 1x2 från {from} till {to}",
        "name_el": "10 λεπτά - κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_709": {
        "name_en": "{%player} total player performance",
        "name_aa": "{player} إجمالي أداء المشغل",
        "name_de": "{!player} Gesamtspielerleistung",
        "name_nl": "{!player} totale speler performance",
        "name_pl": "{!player} suma wartości pkt. zawodnika",
        "name_ro": "{!player} total performanțe jucător",
        "name_ru": "{!player} тотал выступления игрока",
        "name_tr": "{!player} toplam oyuncu performansı",
        "name_se": "{!player} spelarens totalt prestation",
        "name_el": "{!player} total player performance"
    },
    "id_205": {
        "name_en": "{!setnr} set - odd/even games",
        "name_aa": "{!setnr} مجموعة - فردي / زوجي الألعاب",
        "name_de": "{!setnr} Satz - Gerade/ungerade Spiele",
        "name_nl": "{setnr} set - even/oneven aantal games",
        "name_pl": "{!setnr} set - Parzyste/Nieparzyste gemy",
        "name_ro": "Set {!setnr} - game-uri impar/par",
        "name_ru": "{!setnr} сет - геймы чет/нечет",
        "name_tr": "{!setnr}. set - tek/çift oyunlar",
        "name_se": "{!setnr} set - udda/jämn games",
        "name_el": "{!setnr} σετ - μονά/ζυγά γκέιμ"
    },
    "id_516": {
        "name_en": "{!framenr} frame - last points scored",
        "name_aa": "إطار {!framenr} - آخر نقاط تم تسجيلها",
        "name_de": "{!framenr} Frame - Zuletzt erzielte Punkte",
        "name_nl": "{framenr} frame - laatste punten gescoord",
        "name_pl": "{!framenr} frejm - ostatnie zdobyte punkty",
        "name_ro": "Frame-ul {!framenr} - ultimele puncte înscrise",
        "name_ru": "{!framenr} фрейм - последние заработанные очки",
        "name_tr": "{!framenr}. frame - son sayı alınır",
        "name_se": "{!framenr} frame - sista poäng gjordes",
        "name_el": "{!framenr} frame - τελευτάιοι πόντοι"
    },
    "id_893": {
        "name_en": "Last goalscorer",
        "name_aa": "آخر هداف",
        "name_de": "Letzter Torschütze",
        "name_nl": "Laatste doelpuntenmaker",
        "name_pl": "Strzelec ostatniego gola",
        "name_ro": "Ultimul marcator",
        "name_ru": "Последний бомбардир",
        "name_tr": "Son golü atan",
        "name_se": "Sista målgörare",
        "name_el": "τελευταίος σκόρερ"
    },
    "id_278": {
        "name_en": "Innings 1 to 5 - {$competitor2} total",
        "name_aa": "ألدور 1إلى5- {$competitor2} المجموع",
        "name_de": "Innings 1 bis 5 - {$competitor2} Total",
        "name_nl": "Inning 1 tot 5 - {$competitor2} totaal",
        "name_pl": "Innings 1 do 5 - {$competitor2} suma",
        "name_ro": "Innings de la 1 la 5 - total {$competitor2}",
        "name_ru": "Иннинги с 1 до 5 - {$competitor2} тотал",
        "name_tr": "Devreler 1 den 5 e - {$competitor2} toplam",
        "name_se": "Innings 1 till 5 - {$competitor2} totalt",
        "name_el": "Innings 1σε 5 - σύνολο {$competitor2}"
    },
    "id_285": {
        "name_en": "Innings {from} to {to} - {$competitor1} total",
        "name_aa": "ألدور{from} إلى{to}- {$competitor1} المجموع",
        "name_de": "Innings {from} bis {to} - {$competitor1} Total",
        "name_nl": "Inning {from} tot {to} - {$competitor1} totaal",
        "name_pl": "Innings {from} do {to} - {$competitor1} suma",
        "name_ro": "Innings de la {from} la {to} - total {$competitor1}",
        "name_ru": "Иннинги {from} до {to} - {$competitor1} тотал",
        "name_tr": "Devreler {from} den {to} e - {$competitor1} toplam",
        "name_se": "Innings {from} till {to} - {$competitor1} totalt",
        "name_el": "Innings {from} μέχρι {to} - σύνολο {$competitor1}"
    },
    "id_726": {
        "name_en": "{!mapnr} map - total kills",
        "name_aa": "{!mapnr} خريطة - إجمالي عمليات القتل",
        "name_de": "{!mapnr} Map - Kills gesamt",
        "name_nl": "{mapnr} map - totaal aantal kills",
        "name_pl": "{!mapnr} mapa - suma zabójstw",
        "name_ro": "Harta {!mapnr} - total kills",
        "name_ru": "{!mapnr} карта - тотал убийств",
        "name_tr": "{!mapnr}. harita - toplam kill",
        "name_se": "{!mapnr} karta - totalt antal kills",
        "name_el": "{!mapnr} map - total kills"
    },
    "id_837": {
        "name_en": "{$competitor1}/{$competitor2} supremacy",
        "name_aa": "{$competitor1}/{$competitor2} supremacy",
        "name_de": "{$competitor1}/{$competitor2} supremacy",
        "name_nl": "{$competitor1}/{$competitor2} supremacy",
        "name_pl": "{$competitor1}/{$competitor2} supremacy",
        "name_ro": "{$competitor1}/{$competitor2} supremație",
        "name_ru": "{$competitor1}/{$competitor2} превосходство",
        "name_tr": "{$competitor1}/{$competitor2} supremacy",
        "name_se": "{$competitor1}/{$competitor2} supremacy",
        "name_el": "{$competitor1}/{$competitor2} supremacy"
    },
    "id_1000": {
        "name_en": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_aa": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_de": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_nl": "{inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_pl": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_ro": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_ru": "{!inningnr} иннингов овер {overnrX} x {overnrY} - {$competitor1} мульти ран спред",
        "name_tr": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_se": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread",
        "name_el": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor1} multi run spread"
    },
    "id_375": {
        "name_en": "{!setnr} set leg {legnr} - winner",
        "name_aa": "{!setnr}مجموعة الساق {legnr} - الفائز",
        "name_de": "{!setnr} Satz Runde {legnr} - Gewinner",
        "name_nl": "{setnr} set leg {legnr} - winnaar",
        "name_pl": "{!setnr} set leg {legnr} - zwycięzca",
        "name_ro": "Set {!setnr} leg {legnr} - câștigător",
        "name_ru": "{!setnr} сет {legnr} этап - победитель",
        "name_tr": "{!setnr}. set {legnr}. ayak- kazanan",
        "name_se": "{!setnr} set legs {legnr} - vinnare",
        "name_el": "{!setnr} set, {legnr} leg - νικητής"
    },
    "id_623": {
        "name_en": "{!mapnr} map - will there be an ace (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - سيكون هناك الآس (بما في الوقت بدل الضائع.)",
        "name_de": "{!mapnr} Map - Wird es ein Ace geben (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - komt er een ace (verlenging inbegrepen)",
        "name_pl": "{!mapnr} mapa - czy będzie ace (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - va fi un as (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - будет ли эйс (включая овертайм)",
        "name_tr": "{!mapnr}. harita - ace olucak mı (uzatmalar dahil)",
        "name_se": "{!mapnr} karta - blir det ace (inkl. övertid)",
        "name_el": "{!mapnr} map -θα υπάρξει άσσος; παράταση συμπεριλαμβάνεται"
    },
    "id_238": {
        "name_en": "Total points",
        "name_aa": "مجمل النقاط",
        "name_de": "Anzahl Punkte",
        "name_nl": "Totaal aantal punten",
        "name_pl": "Suma punktów",
        "name_ro": "Total puncte",
        "name_ru": "Тотал очков",
        "name_tr": "Toplam sayı",
        "name_se": "Totalt antal poäng",
        "name_el": "Σύνολο πόντων"
    },
    "id_100": {
        "name_en": "When will the {!goalnr} goal be scored (15 min interval)",
        "name_aa": "متى سيتم تسجيل هدف {!goalnr} (15 دقيقة)",
        "name_de": "Wann wird das {!goalnr}Tor erzielt? (15 Min Intervall )",
        "name_nl": "Wanneer wordt het {goalnr} doelpunt gescoord (15 min interval)",
        "name_pl": "Kiedy zostanie strzelony {!goalnr} gol (15 min interwał)",
        "name_ro": "Când se va înscrie golul {!goalnr} (interval de 15 minute)",
        "name_ru": "Когда будет забит {!goalnr} гол (15 мин интервал)",
        "name_tr": "{!goalnr} gol ne zaman atılır (15 dakikalık aralıkla)",
        "name_se": "När kommer det {!goalnr} målet att göras (15 min intervall)",
        "name_el": "Χρόνος επίτευξης - {!goalnr} γκολ (15 λεπτά)"
    },
    "id_1041": {
        "name_en": "{$competitor1} total hits (incl. extra innings)",
        "name_aa": "{$competitor1} total hits (incl. extra innings)",
        "name_de": "{$competitor1} Hits Gesamt (inkl. Extra Innings)",
        "name_nl": "{$competitor1} totaal aantal slagen (incl. extra innings)",
        "name_pl": "{$competitor1} total hits (incl. extra innings)",
        "name_ro": "{$competitor1} total hits (incl. extra innings)",
        "name_ru": "{$competitor1} тотал хитов (включая экстра иннинги)",
        "name_tr": "{$competitor1} total hits (incl. extra innings)",
        "name_se": "{$competitor1} total antal hits (inkl. extra innings)",
        "name_el": "{$competitor1} total hits (incl. extra innings)"
    },
    "id_697": {
        "name_en": "Total dismissals",
        "name_aa": "مجموع الإقالة",
        "name_de": "Dismissals Gesamt",
        "name_nl": "Totaal aantal dismissals",
        "name_pl": "Suma wyeliminowań",
        "name_ro": "Total demiteri",
        "name_ru": "Тотал выбиваний",
        "name_tr": "Toplam çıkarılma",
        "name_se": "Totalt avvisningar",
        "name_el": "Total dismissals"
    },
    "id_595": {
        "name_en": "10 minutes - {$competitor2} sending off from {from} to {to}",
        "name_aa": "10 دقائق - {$competitor2} طرد من {from} إلى {to}",
        "name_de": "10 Minuten - {$competitor2} Platzverweise von {from} bis {to}",
        "name_nl": "10 minutes - uitsluiting {$competitor2} van {from} tot {to}",
        "name_pl": "10 minut - {$competitor2} czerwona kartka od {from} do {to}",
        "name_ro": "10 minute - {$competitor2} eliminare de la {from} la {to}",
        "name_ru": "10 минут - {$competitor2} удаление с {from} до {to}",
        "name_tr": "10 dakika - {$competitor2} oyundan atılma {from} dan {to} a",
        "name_se": "10 minuter - {$competitor2} utvisning från {from} till {to}",
        "name_el": "10 λεπτά - {$competitor2}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_214": {
        "name_en": "{!setnr} set game {gamenr} - correct score",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - النتيجة الصحيحة",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Genaues Ergebnis",
        "name_nl": "{setnr} set game {gamenr} - juiste score",
        "name_pl": "{!setnr} set gem {gamenr} - dokładny wynik",
        "name_ro": "Set {!setnr} game {gamenr} - scor corect",
        "name_ru": "{!setnr} сет {gamenr} гейм - точный счет",
        "name_tr": "{!setnr}. set {gamenr}. oyun - doğru skor",
        "name_se": "{!setnr} set game {gamenr} - rätt resultat",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - ακριβές σκορ"
    },
    "id_78": {
        "name_en": "1st half - 1x2 & both teams to score",
        "name_aa": "الشوط الأول - 1X2 وكلا الفريقين ليسجل",
        "name_de": "1. Halbzeit- 1x2 & Beide Mannschaften treffen",
        "name_nl": "1e helft - 1x2 & beide teams scoren",
        "name_pl": "1. Połowa - 1x2 & Oba zespoły zdobędą gola",
        "name_ro": "Prima repriză - 1x2 & ambele echipe să înscrie",
        "name_ru": "1ая половина - 1х2 и обе команды забьют",
        "name_tr": "İlk yarı - 1x2 & her iki takım da gol atar",
        "name_se": "1:a halvlek - 1x2 & båda lagen gör mål",
        "name_el": "1ο ημίχρονο - 1Χ2 και να σκοράρουν και οι 2"
    },
    "id_154": {
        "name_en": "1st half - {$competitor2} total bookings",
        "name_aa": "النصف الأول - إجمالي الحجوزات {$competitor2}",
        "name_de": "1. Halbzeit - {$competitor2} Gesamtanzahl Karten",
        "name_nl": "Eerste helft -  totaal aantal kaarten {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} Suma kartek",
        "name_ro": "Repriza 1 - total cartonașe {$competitor2}",
        "name_ru": "1ая половина - {$competitor2} тотал карточек",
        "name_tr": "İlk yarı - {$competitor2} kart sayısı",
        "name_se": "1:a halvlek - {$competitor2} totalt antal kort",
        "name_el": "1ο ημίχρονο - {$competitor2} σύνολο κάρτες"
    },
    "id_842": {
        "name_en": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal",
        "name_aa": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal",
        "name_de": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal",
        "name_nl": "{inningnr} innings - {$competitor2} total spread bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal",
        "name_ro": "{!inningnr} innings - {$competitor2} total interval la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал спред на {!dismissalnr}выбиваний",
        "name_tr": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal",
        "name_se": "{!inningnr} innings - {$competitor2} total spread vid {!dismissalnr} utslagen",
        "name_el": "{!inningnr} innings - {$competitor2} total spread at {!dismissalnr} dismissal"
    },
    "id_814": {
        "name_en": "{!mapnr} map - player with most deaths",
        "name_aa": "{!mapnr} خريطة - لاعب مع معظم الوفيات",
        "name_de": "{!mapnr} Karte - Spieler mit den meisten Toden",
        "name_nl": "{mapnr} map - speler met de meeste deaths",
        "name_pl": "{!mapnr} mapa - zawodnik z największą liczbą śmierci",
        "name_ro": "Harta {!mapnr} - cele mai multe deaths",
        "name_ru": "{!mapnr} карта - игрок с наибольшим количеством смертей",
        "name_tr": "{!mapnr} - harita - en çok ölen oyuncu",
        "name_se": "{!mapnr} karta - spelare med flest dödsfall",
        "name_el": "{!mapnr} map - παίχτης με τους περισσότερους θανάτους"
    },
    "id_219": {
        "name_en": "Winner (incl. overtime)",
        "name_aa": "الفائز (بما في ذلك وقت إضافي)",
        "name_de": "Gewinner (inkl. Verlängerung)",
        "name_nl": "Winnaar (verlenging inbegrepen)",
        "name_pl": "Zwycięzca (włącznie z dogrywką)",
        "name_ro": "Câștigătoare (incl. prelungiri)",
        "name_ru": "Победитель (включая овертайм)",
        "name_tr": "Kazanan (uzatmalar dahil)",
        "name_se": "Vinnare (inkl. övertid)",
        "name_el": "Νικητής (παράταση συμπ.)"
    },
    "id_895": {
        "name_en": "{!setnr} set tiebreak - total points",
        "name_aa": "{!setnr} وضع شوط كسر التعادل - مجموع النقاط",
        "name_de": "{!setnr} Satz tiebreak - insgesamter Punktestand",
        "name_nl": "{!setnr} set tiebreak - totaal aantal punten",
        "name_pl": "{!setnr} set tiebreak - total points",
        "name_ro": "Set {!setnr} tiebreak - total puncte",
        "name_ru": "{!setnr} сет тайбрек - всего очков",
        "name_tr": "{!setnr} set tiebreak - total points",
        "name_se": "{!setnr} set tiebreak - totalt antal poäng",
        "name_el": "{!setnr} σετ τάι-μπρέικ - σύνολο πόντων"
    },
    "id_362": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} total",
        "name_aa": "{!inningnr} أدوار على {overnr} - {!deliverynr} تسليم {$competitor1} مجموعه",
        "name_de": "{!inningnr} Inning Over {overnr} - {!deliverynr} Delivery {$competitor1} Total",
        "name_nl": "{inningnr} innings over {overnr} - totaal {$competitor1} {deliverynr} delivery",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} rzut {$competitor1} suma",
        "name_ro": "{!inningnr} innings over {overnr} - delivery {!deliverynr} total {$competitor1}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {!deliverynr} подача {$competitor1} тотал",
        "name_tr": "{!inningnr}. devre {overnr}. over - {!deliverynr}. delivery {$competitor1} toplam",
        "name_se": "{!inningnr} innings över {overnr} - {!deliverynr} utdelning {$competitor1} totalt",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor1} σύνολο"
    },
    "id_1047": {
        "name_en": "Innings 1 to 5 - {$competitor1} total hits",
        "name_aa": "Innings 1 to 5 - {$competitor1} total hits",
        "name_de": "Innings 1 to 5 - {$competitor1} total hits",
        "name_nl": "Innings 1 tot 5 - {$competitor1} totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - {$competitor1} total hits",
        "name_ro": "Innings 1 to 5 - {$competitor1} total hits",
        "name_ru": "Иннинги с 1 по 5 - {$competitor1} тотал хитов",
        "name_tr": "Innings 1 to 5 - {$competitor1} total hits",
        "name_se": "Inning 1 till 5 - {$competitor1} totalt antal hits",
        "name_el": "Innings 1 to 5 - {$competitor1} total hits"
    },
    "id_405": {
        "name_en": "Race to {goalnr} goals (incl. overtime)",
        "name_aa": "سباق إلى {goalnr} أهداف (بما في ذلك الوقت الإضافي)",
        "name_de": "Wer erreicht zuerst {goalnr} Tore (inkl. Verlängerung)",
        "name_nl": "Race naar {goalnr} doelpunten (verlenging inbegrepen)",
        "name_pl": "Kto pierwszy z {goalnr} golem (włącznie z dogrywką)",
        "name_ro": "Primii la {goalnr} goluri (incl. prelungiri)",
        "name_ru": "Гонка до {goalnr} голов (включая овертайм)",
        "name_tr": "{goalnr}. gol için çekişme (uzatmalar dahil)",
        "name_se": "Först till {goalnr} mål (inkl. övertid)",
        "name_el": "Ποιος θα φτάσει 1ος στο {goalnr} γκολ (παράταση συμπ.)"
    },
    "id_1121": {
        "name_en": "{$competitor1} total home runs (incl. extra innings)",
        "name_aa": "{$competitor1} total home runs (incl. extra innings)",
        "name_de": "{$competitor1} Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns {$competitor1} (incl. extra innings)",
        "name_pl": "{$competitor1} total home runs (incl. extra innings)",
        "name_ro": "{$competitor1} total home runs (incl. extra innings)",
        "name_ru": "{$competitor1} тотал хоум ранов (включая экстра иннинги)",
        "name_tr": "{$competitor1} total home runs (incl. extra innings)",
        "name_se": "{$competitor1} total home runs (incl. extra innings)",
        "name_el": "{$competitor1} Σύνολο home runs (συμπ. extra innings)"
    },
    "id_859": {
        "name_en": "{$competitor2} or under {total}",
        "name_aa": "{$competitor2} أو أقل من {total}",
        "name_de": "{$competitor2} oder unter {total}",
        "name_nl": "{$competitor2} of onder {total}",
        "name_pl": "{$competitor2} lub poniżej {total}",
        "name_ro": "{$competitor2} sau sub {total}",
        "name_ru": "{$competitor2} или меньше {total}",
        "name_tr": "{$competitor2} ya da alt {total}",
        "name_se": "{$competitor2} elr under {total}",
        "name_el": "{$competitor2} ή under {total}"
    },
    "id_689": {
        "name_en": "Keeper head2head (1x2)",
        "name_aa": "حارس وجها لوجه (1x2)",
        "name_de": "Torwächter head2head (1x2)",
        "name_nl": "Keeper head2head (1x2)",
        "name_pl": "Keeper head2head (1x2)",
        "name_ro": "Confruntare directă keeper (1x2)",
        "name_ru": "Вратарь единоборство (1x2)",
        "name_tr": "Kaleci head2head (1x2)",
        "name_se": "Målvakt head2head (1x2)",
        "name_el": "r head2head (1x2)"
    },
    "id_587": {
        "name_en": "15 minutes - total bookings from {from} to {to}",
        "name_aa": "15 دقيقة - إجمالي الحجوزات من {from} إلى {to}",
        "name_de": "15 Minuten - Gesamtanzahl Karten von {from} bis {to}",
        "name_nl": "15 minutes - totaal aantal kaarten van {from} tot {to}",
        "name_pl": "15 minut - suma kartek od {from} do {to}",
        "name_ro": "15 minute - total cartonașe de la {from} la {to}",
        "name_ru": "15 минут - тотал карточек с {from} до {to}",
        "name_tr": "15 dakika - toplam kart {from} dan {to} a",
        "name_se": "15 minuter - totalt antal kort från {from} till {to}",
        "name_el": "15 λεπτά - σύνολο κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_260": {
        "name_en": "{$competitor1} total (incl. extra innings)",
        "name_aa": "{$competitor1} مجموعه (بما في ذلك أدوار إضافية)",
        "name_de": "{$competitor1} Total (inkl. Extra Innings)",
        "name_nl": "{$competitor1} totaal (extra innings inbegrepen)",
        "name_pl": "{$competitor1} suma (włącznie z extra innings)",
        "name_ro": "Total (incl. inning-uri extra) {$competitor1}",
        "name_ru": "{$competitor1} тотал (включая дополнительные иннинги)",
        "name_tr": "{$competitor1} toplam (uzatmalar dahil)",
        "name_se": "{$competitor1} totalt (inkl. extra innings)",
        "name_el": "σύνολο {$competitor1} (έξτρα innings συμπ.)"
    },
    "id_483": {
        "name_en": "1st half - try double chance",
        "name_aa": "الشوط الأول - جرب فرصة مزدوجة",
        "name_de": "1. Halbzeit - Versuch Doppelte Chance",
        "name_nl": "Eerste helft - try dubbele kans",
        "name_pl": "1. Połowa - przyłożenie podwójna szansa",
        "name_ro": "Repriza 1 - încercări șansă dublă",
        "name_ru": "1ая половина - попытка двойной шанс",
        "name_tr": "İlk yarı - try çifte şans",
        "name_se": "1:a halvlek - försök dubbelchans",
        "name_el": "1ο ημίχρονο - try διπλή ευκαιρία"
    },
    "id_400": {
        "name_en": "{$competitor2} goal range",
        "name_aa": "{$competitor2} نطاق الأهداف",
        "name_de": "{$competitor2} Tordifferenz",
        "name_nl": "doelpunten range {$competitor2}",
        "name_pl": "{$competitor2} zakres liczby goli",
        "name_ro": "Interval goluri {$competitor2}",
        "name_ru": "{$competitor2} диапазон голов",
        "name_tr": "{$competitor2} gol aralığı",
        "name_se": "{$competitor2} målrad",
        "name_el": "{$competitor2} Εύρος των γκολ"
    },
    "id_913": {
        "name_en": "Winner & round range",
        "name_aa": "Winner & round range",
        "name_de": "Winner & round range",
        "name_nl": "Winnaar & aantal rondes",
        "name_pl": "Winner & round range",
        "name_ro": "Winner & round range",
        "name_ru": "Победитель и серия",
        "name_tr": "Winner & round range",
        "name_se": "Vinnare och rondintervall",
        "name_el": "Νικητής και round range"
    },
    "id_928": {
        "name_en": "{%player} total earned runs (incl. extra innings)",
        "name_aa": "{!player} total earned runs (incl. extra innings)",
        "name_de": "{!player} Erzielte Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "{!player} totaal aantal runs verdiend",
        "name_pl": "{!player} total earned runs (incl. extra innings)",
        "name_ro": "{!player} total earned runs (incl. extra innings)",
        "name_ru": "{!player} тотал заработанных рана",
        "name_tr": "{!player} total earned runs (incl. extra innings)",
        "name_se": "{!player} total earned runs (incl. extra innings)",
        "name_el": "{!player} total earned runs"
    },
    "id_176": {
        "name_en": "1st half - corner handicap",
        "name_aa": "الشوط الاول -هانديكاب الزاوية",
        "name_de": "1. Halbzeit - Ecken Handicap",
        "name_nl": "Eerste helft - hoekschop handicap",
        "name_pl": "1. Połowa - Rzuty rożne Handicap",
        "name_ro": "Repriza 1 - handicap cornere",
        "name_ru": "1ая половина - угловой гандикап",
        "name_tr": "İlk yarı - korner handikap",
        "name_se": "1:a halvlek - hörnor handikapp",
        "name_el": "1ο ημίχρονο - χάντικαπ κόρνερ"
    },
    "id_199": {
        "name_en": "Correct score",
        "name_aa": "نتيجة صحيحة",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Dokładny wynik",
        "name_ro": "Scor corect",
        "name_ru": "Точный счет",
        "name_tr": "Maç Sonucu",
        "name_se": "Rätt resultat",
        "name_el": "Ακριβές σκορ"
    },
    "id_601": {
        "name_en": "Race to {cornernr} corners",
        "name_aa": "سباق إلى زوايا {cornernr}",
        "name_de": "Wer erreicht zuerst {cornernr} Ecken",
        "name_nl": "Race naar {cornernr} hoekschoppen",
        "name_pl": "Kto pierwszy z {cornernr} rzutami rożnymi",
        "name_ro": "Primii la {cornernr} cornere",
        "name_ru": "Гонка до {cornernr} угловых",
        "name_tr": "{cornernr}. kornerin çekişmesi",
        "name_se": "Först till {cornernr} hörnor",
        "name_el": "Ποιος θα φτάσει 1ος στα  {cornernr} κόρνερ"
    },
    "id_922": {
        "name_en": "{%player} total assists (incl. overtime)",
        "name_aa": "{!player} total assists (incl. overtime)",
        "name_de": "{!player} Assists Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal assists",
        "name_pl": "{!player} total assists (incl. overtime)",
        "name_ro": "{!player} total assists (incl. overtime)",
        "name_ru": "{!player} тотал передач",
        "name_tr": "{!player} total assists (incl. overtime)",
        "name_se": "{!player} total assists (incl. overtime)",
        "name_el": "{!player} σύνολο ασσίστ"
    },
    "id_83": {
        "name_en": "2nd half - 1x2",
        "name_aa": "الشوط الثاني - 1x2",
        "name_de": "2. Halbzeit- 1x2",
        "name_nl": "2e helft - 1x2",
        "name_pl": "2. Połowa - 1x2",
        "name_ro": "A 2-a repriză - 1x2",
        "name_ru": "2ая половина - 1х2",
        "name_tr": "İkinci yarı - 1x2",
        "name_se": "2:a halvlek - 1x2",
        "name_el": "2ο ημίχρονο - 1Χ2"
    },
    "id_966": {
        "name_en": "{$competitor2} to lead by {points}",
        "name_aa": "{$competitor2} to lead by {points}",
        "name_de": "{$competitor2} to lead by {points}",
        "name_nl": "{$competitor2} staat {points} punten voor",
        "name_pl": "{$competitor2} to lead by {points}",
        "name_ro": "{$competitor2} to lead by {points}",
        "name_ru": "{$competitor2} лидирует на {points}",
        "name_tr": "{$competitor2} to lead by {points}",
        "name_se": "{$competitor2} to lead by {points}",
        "name_el": "{$competitor2} να προηγείται με  {points}"
    },
    "id_658": {
        "name_en": "{!inningnr} innings - {$competitor1} total fours",
        "name_aa": "{!inningnr} أدوار - {$competitor1} مجموع أربعات",
        "name_de": "{!inningnr} Inning - {$competitor1} Fours gesamt",
        "name_nl": "{inningnr} innings - totaal aantal fours {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma czwórek",
        "name_ro": "{!inningnr} innings - {$competitor1} total fours",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал 4 ранов",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam fours",
        "name_se": "{!inningnr} innings - {$competitor1} totatl antal fours",
        "name_el": "{!inningnr} innings - {$competitor1} total fours"
    },
    "id_68": {
        "name_en": "1st half - total",
        "name_aa": "الشوط الأول - المجموع",
        "name_de": "1. Halbzeit - Total",
        "name_nl": "1e helft - totaal",
        "name_pl": "1. Połowa - Suma",
        "name_ro": "Prima repriză - total",
        "name_ru": "1ая половина - тотал",
        "name_tr": "İlk Yarı - Toplam gol",
        "name_se": "1:a halvlek - totalt",
        "name_el": "1ο ημίχρονο - Σύνολο Γκολ"
    },
    "id_1084": {
        "name_en": "Exact strikes of {%player} {!appearancenr} time at bat",
        "name_aa": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_de": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_nl": "Exact aantal slagballen van {!player} {appearancenr} keer aan slag",
        "name_pl": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_ro": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_ru": "Точные броски {!player} {!appearancenr} игра битой",
        "name_tr": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_se": "Exact strikes of {!player} {!appearancenr} time at bat",
        "name_el": "Exact strikes of {!player} {!appearancenr} time at bat"
    },
    "id_491": {
        "name_en": "Which player wins the rest of the match",
        "name_aa": "اللاعب الذي يفوز ببقية المباراة",
        "name_de": "Welcher Spieler gewinnt die verbleibende Spielzeit?",
        "name_nl": "Welke speler wint het restant van de wedstrijd",
        "name_pl": "Który zawodnik wygra resztę meczu?",
        "name_ro": "Care jucător va câștiga restul partidei",
        "name_ru": "Какой игрок выиграет остаток матча",
        "name_tr": "Maçın kalanında hangi takım kazanır",
        "name_se": "Vilken spelare vinner resten av matchen",
        "name_el": "Ποιος παίχτης θα κερδίσει το υπόλοιπο του αγώνα"
    },
    "id_188": {
        "name_en": "Set handicap",
        "name_aa": "تعيين هانديكاب",
        "name_de": "Satz Handicap",
        "name_nl": "Set handicap",
        "name_pl": "Sety Handicap",
        "name_ro": "Handicap set-uri",
        "name_ru": "Гандикап на сет",
        "name_tr": "Handikaplı set",
        "name_se": "Set handikapp",
        "name_el": "Χάντικαπ Σετ"
    },
    "id_443": {
        "name_en": "{!periodnr} period - 1x2",
        "name_aa": "فترة {!periodnr} - 1X2",
        "name_de": "{!periodnr} Periode - 1x2",
        "name_nl": "{periodnr} periode - 1x2",
        "name_pl": "{!periodnr} część gry - 1x2",
        "name_ro": "Repriza {!periodnr} - 1x2",
        "name_ru": "{!periodnr} период - 1x2",
        "name_tr": "{!periodnr}. periyot - 1x2",
        "name_se": "{!periodnr} period - 1x2",
        "name_el": "{!periodnr} περίοδος - 1Χ2"
    },
    "id_65": {
        "name_en": "1st half - handicap",
        "name_aa": "النصف الأول -هانديكاب",
        "name_de": "1. Halbzeit - Handicap",
        "name_nl": "1e helft - handicap",
        "name_pl": "1. Połowa - handicap",
        "name_ro": "Prima repriză - handicap",
        "name_ru": "1ая половина - гандикап",
        "name_tr": "İlk Yarı - handikap",
        "name_se": "1:a halvlek - handikapp",
        "name_el": "1ο ημίχρονο - χάντικαπ"
    },
    "id_734": {
        "name_en": "{!mapnr} map 10 minutes - total kills from {from} to {to}",
        "name_aa": "{!mapnr} خريطة 10 دقائق - إجمالي عمليات القتل من {from} إلى {to}",
        "name_de": "{!mapnr} Map 10 Minuten - Kills von {from} bis {to} gesamt",
        "name_nl": "{mapnr} map 10 minuten - totaal aantal kills van {from} tot {to}",
        "name_pl": "{!mapnr} mapa 10 minut - suma zabójstw od {from} do {to}",
        "name_ro": "Harta {!mapnr} 10 minute -  {!mapnr} map 10 minutes - total kills de la {from} la {to}",
        "name_ru": "{!mapnr} карта 10 минут - тотал убийств с {from} до {to}",
        "name_tr": "{!mapnr}. harita 10 dakika - toplam kill {from} den {to} e",
        "name_se": "{!mapnr} karta 10 minuter - totalt antal kills från {from} till {to}",
        "name_el": "{!mapnr} map 10 λεπτά - total kills from {from} to {to}"
    },
    "id_853": {
        "name_en": "{$competitor2} to win exactly 2 sets",
        "name_aa": "{$competitor2} للفوز بمجموعتين بالضبط",
        "name_de": "{$competitor2} wird genau zwei Sätze gewinnen",
        "name_nl": "{$competitor2} wint precies 2 sets",
        "name_pl": "{$competitor2} wygra dokładnie 2 sety",
        "name_ro": "{$competitor2} să câștige exact 2 seturi",
        "name_ru": "{$competitor2} выиграть точно 2 сэт",
        "name_tr": "{$competitor2} sadece 2 set kazanır",
        "name_se": "{$competitor2} att vinna exakt 2 set",
        "name_el": "{$competitor2} να κερδίσει μόνο 1 σετ"
    },
    "id_975": {
        "name_en": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_aa": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_de": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_nl": "{inningnr} innings - {$competitor2} totaal aantal zespunters spread",
        "name_pl": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_ro": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал спред шести ранов",
        "name_tr": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_se": "{!inningnr} innings - {$competitor2} total sixes spread",
        "name_el": "{!inningnr} innings - {$competitor2} total sixes spread"
    },
    "id_1633": {
        "name_en": "Double chance (1st half) & 1x2",
        "name_aa": "Double chance (1st half) & 1x2",
        "name_de": "Double chance (1st half) & 1x2",
        "name_nl": "Double chance (1st half) & 1x2",
        "name_pl": "Double chance (1st half) & 1x2",
        "name_ro": "Double chance (1st half) & 1x2",
        "name_ru": "Double chance (1st half) & 1x2",
        "name_tr": "Double chance (1st half) & 1x2",
        "name_se": "Double chance (1st half) & 1x2",
        "name_el": "Double chance (1st half) & 1x2"
    },
    "id_287": {
        "name_en": "{!inningnr} inning - 1x2",
        "name_aa": "{!inningnr} الشوط - 1 × 2",
        "name_de": "{!inningnr} Inning - 1x2",
        "name_nl": "{inningnr} inning - 1x2",
        "name_pl": "{!inningnr} inning - 1x2",
        "name_ro": "Inning-ul {!inningnr} - 1x2",
        "name_ru": "{!inningnr} иннинг - 1x2",
        "name_tr": "{!inningnr}. devre - 1x2",
        "name_se": "{!inningnr} inning - 1x2",
        "name_el": "{!inningnr} inning - 1Χ2"
    },
    "id_223": {
        "name_en": "Handicap (incl. overtime)",
        "name_aa": "هانديكاب (بما في ذلك الوقت الإضافي)",
        "name_de": "Handicap (inkl. Verlängerung)",
        "name_nl": "Handicap (verlenging inbegrepen)",
        "name_pl": "Handicap (włącznie z dogrywką)",
        "name_ro": "Handicap (incl. prelungiri)",
        "name_ru": "Гандикап (включая овертайм)",
        "name_tr": "Handikap (uzatmalar dahil)",
        "name_se": "Handikapp (inkl. övertid)",
        "name_el": "Χάντικαπ (παράταση συμπ.)"
    },
    "id_34": {
        "name_en": "{$competitor2} win to nil",
        "name_aa": "{$competitor2} الفوز لللا شيء",
        "name_de": "{$competitor2} gewinnt zu null",
        "name_nl": "{$competitor2} wint zonder tegengoal",
        "name_pl": "{$competitor2} wygra do zera",
        "name_ro": "{$competitor2} victorie la 0",
        "name_ru": "{$competitor2} выиграет к нолю",
        "name_tr": "{$competitor2} gol yemeden kazanır",
        "name_se": "{$competitor2} vinner och håller nollan",
        "name_el": "{$competitor2} να κερδίσει με μηδέν παθητικό"
    },
    "id_1019": {
        "name_en": "Total bogeys",
        "name_aa": "Total bogeys",
        "name_de": "Bogeys Gesamt",
        "name_nl": "Totaal aantal bogeys",
        "name_pl": "Total bogeys",
        "name_ro": "Total bogeys",
        "name_ru": "Total bogeys",
        "name_tr": "Total bogeys",
        "name_se": "Total bogeys",
        "name_el": "Total bogeys"
    },
    "id_101": {
        "name_en": "When will the {!goalnr} goal be scored (10 min interval)",
        "name_aa": "متى سيتم تسجيل هدف {!goalnr} (10 دقائق)",
        "name_de": "Wann wird das {!goalnr}Tor erzielt? (10 Min Intervall )",
        "name_nl": "Wanneer wordt het {goalnr} doelpunt gescoord (10 min interval)",
        "name_pl": "Kiedy zostanie strzelony {!goalnr} gol (10 min interwał)",
        "name_ro": "Când se va înscrie golul {!goalnr} (interval de 10 minute)",
        "name_ru": "Когда будет забит {!goalnr} гол (10 мин интервал)",
        "name_tr": "{!goalnr} gol ne zaman atılır (10 dakikalık aralıkla)",
        "name_se": "När kommer det {!goalnr} målet att göras (10 min intervall)",
        "name_el": "Χρόνος επίτευξης  - {!goalnr} γκολ (10 λεπτά)"
    },
    "id_1018": {
        "name_en": "Total pars",
        "name_aa": "Total pars",
        "name_de": "Pars Gesamt",
        "name_nl": "Totaal aantal pars",
        "name_pl": "Total pars",
        "name_ro": "Total pars",
        "name_ru": "Total pars",
        "name_tr": "Total pars",
        "name_se": "Total pars",
        "name_el": "Total pars"
    },
    "id_504": {
        "name_en": "{!framenr} frame - player with highest break",
        "name_aa": "إطار {framenr!}- لاعب بأعلى استراحة",
        "name_de": "{!framenr} Frame - Spieler mit dem höchsten Break",
        "name_nl": "{framenr} frame - speler met de hoogste break",
        "name_pl": "{!framenr} frejm - zawodnik z największym brejkiem",
        "name_ro": "Frame-ul {!framenr} - jucătorul cu cel mai mare break",
        "name_ru": "{!framenr} фрейм - игрок с наивысшим брейком",
        "name_tr": "{!framenr}. frame - en yüksek break yapan oyuncu",
        "name_se": "{!framenr} frame - spelare med högsta break",
        "name_el": "{!framenr} frame - παίχτης με το μεγαλύτερο μπρέικ"
    },
    "id_730": {
        "name_en": "{!mapnr} map - tower handicap",
        "name_aa": "خريطة {!mapnr} - برج هانديكاب",
        "name_de": "{!mapnr} Map - Tower Handicap",
        "name_nl": "{mapnr} map - tower handicap",
        "name_pl": "{!mapnr} mapa - wieże handicap",
        "name_ro": "Harta {!mapnr} - handicap tower",
        "name_ru": "{!mapnr} карта - гандикап на башни",
        "name_tr": "{!mapnr}. harita - kule handikapı",
        "name_se": "{!mapnr} karta - tower handikapp",
        "name_el": "{!mapnr} map - tower handicap"
    },
    "id_661": {
        "name_en": "{!inningnr} innings - {$competitor2} total sixes",
        "name_aa": "{!inningnr} أدوار - {$competitor2} مجموع الستات",
        "name_de": "{!inningnr} Inning - {$competitor2} Sixes gesamt",
        "name_nl": "{inningnr} innings - totaal aantal sixes {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma szóstek",
        "name_ro": "{!inningnr} innings - {$competitor2} total sixes",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал шести ранов",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam sixes",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal sixes",
        "name_el": "{!inningnr} innings - {$competitor2} total sixes"
    },
    "id_1146": {
        "name_en": "{!inningnr} innings - race to {runs} runs",
        "name_aa": "{!inningnr} innings - race to {runs} runs",
        "name_de": "{!inningnr} innings - race to {runs} runs",
        "name_nl": "{!inningnr} innings - race naar {runs} runs",
        "name_pl": "{!inningnr} innings - race to {runs} runs",
        "name_ro": "{!inningnr} innings - race to {runs} runs",
        "name_ru": "{!inningnr} innings - race to {runs} runs",
        "name_tr": "{!inningnr} innings - race to {runs} runs",
        "name_se": "{!inningnr} innings - race to {runs} runs",
        "name_el": "{!inningnr} innings - race to {runs} runs"
    },
    "id_23": {
        "name_en": "{$competitor1} exact goals",
        "name_aa": "{$competitor1} الأهداف المحددة",
        "name_de": "{$competitor1} Genaue Anzahl Tore",
        "name_nl": "{$competitor1} exact aantal doelpunten",
        "name_pl": "{$competitor1} Dokładna liczba goli",
        "name_ro": "Goluri exact {$competitor1}",
        "name_ru": "Точное число голов {$competitor1}",
        "name_tr": "{$competitor1} Kesin hedefler",
        "name_se": "{$competitor1} exakt antal mål",
        "name_el": "Ακριβής αριθμός γκολ {$competitor1}"
    },
    "id_904": {
        "name_en": "Next scoring type (incl. overtime)",
        "name_aa": "Next scoring type (incl. overtime)",
        "name_de": "Nächster Scoring-Typ (inkl. Verlängerung)",
        "name_nl": "Volgende scoringstype (incl. extra tijd)",
        "name_pl": "Następny typ wyniku (włącznie z dogrywką)",
        "name_ro": "Next scoring type (incl. overtime)",
        "name_ru": "Следующий тип счета (включая овертайм)",
        "name_tr": "Next scoring type (incl. overtime)",
        "name_se": "Nästa poängtyp (inkl. övertid)",
        "name_el": "Επόμενος τύπος σκοραρίσματος -παράταση συμπεριλαμβάνεται"
    },
    "id_444": {
        "name_en": "{!periodnr} period - {!goalnr} goal",
        "name_aa": "فترة {!periodnr} - الهدف {!goalnr}",
        "name_de": "{!periodnr} Periode - {!goalnr} Tor",
        "name_nl": "{periodnr} periode - {goalnr} doelpunt",
        "name_pl": "{!periodnr} część gry - {!goalnr} gol",
        "name_ro": "Repriza {!periodnr} - golul {!goalnr}",
        "name_ru": "{!periodnr} период - {!goalnr} гол",
        "name_tr": "{!periodnr} . periyot - {!goalnr}. gol",
        "name_se": "{!periodnr} period - {!goalnr} mål",
        "name_el": "{!periodnr} περίοδος - {!goalnr}  γκολ"
    },
    "id_799": {
        "name_en": "{$event} matchday {matchday} - total",
        "name_aa": "{$event} يوم المباراة {matchday} - إجمالي",
        "name_de": "{$event} Spieltag {matchday} - Total",
        "name_nl": "{$event} matchday {matchday} - totaa",
        "name_pl": "{$event} matchday {matchday} - suma",
        "name_ro": "{$event} etapă {matchday} - total",
        "name_ru": "{$event} игровой день {matchday} - итого",
        "name_tr": "{$event} maç günü {matchday} - toplam",
        "name_se": "{$event} - totalt",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο"
    },
    "id_1095": {
        "name_en": "{!stopnr} pit stop",
        "name_aa": "{!stopnr} pit stop",
        "name_de": "{!stopnr} pit stop",
        "name_nl": "{stopnr} pitstop",
        "name_pl": "{!stopnr} pit stop",
        "name_ro": "{!stopnr} pit stop",
        "name_ru": "{!stopnr} пит-стопов",
        "name_tr": "{!stopnr} pit stop",
        "name_se": "{!stopnr} pit stop",
        "name_el": "{!stopnr} πιτ στοπ"
    },
    "id_864": {
        "name_en": "Draw or any clean sheet",
        "name_aa": "ارسم أو أي ورقة نظيفة",
        "name_de": "Draw or any clean sheet",
        "name_nl": "Gelijkspel of een schoon doel",
        "name_pl": "remis lub którykolwiek czyste konto",
        "name_ro": "Egal sau oricare să nu primească gol",
        "name_ru": "Ничья или ни одна из команд не забьет",
        "name_tr": "Beraber ya da gol yok",
        "name_se": "Oavgjort elr något annat resultat",
        "name_el": "Ισοπαλία ή  μία από τις 2 ομάδες να μη δεχτεί γκολ"
    },
    "id_157": {
        "name_en": "1st half - {$competitor2} exact bookings",
        "name_aa": "النصف الأول - {$competitor2} الحجوزات الدقيقة",
        "name_de": "1. Halbzeit -  {$competitor2} Genaue Anzahl Karten",
        "name_nl": "Eerste helft - exact aantal kaarten {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} Dokładna liczba kartek",
        "name_ro": "Repriza 1 - cartonașe exact {$competitor2}",
        "name_ru": "1ая половина - {$competitor2} точное число карточек",
        "name_tr": "İlk yarı - {$competitor2} doğru kart sayısı",
        "name_se": "1:a halvlek - {$competitor2} exakta antal kort",
        "name_el": "1ο ημίχρονο - {$competitor2} ακριβής αριθμός καρτών"
    },
    "id_213": {
        "name_en": "{!setnr} set game {gamenr} - odd/even points",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - فردي / زوجي نقطة",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Gerade/ungerade Punkte",
        "name_nl": "{setnr} set game {gamenr} - even/oneven aantal punten",
        "name_pl": "{!setnr} set gem {gamenr} - parzyste/nieparzyste punkty",
        "name_ro": "Set {!setnr} game {gamenr} - puncte impar/par",
        "name_ru": "{!setnr} сет {gamenr} гейм - очки чет/нечет",
        "name_tr": "{!setnr}. set {gamenr}. oyun  - tek/çift sayı",
        "name_se": "{!setnr} set game {gamenr} - udda/jämna poäng",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - μονά/ζυγά πόντοι"
    },
    "id_221": {
        "name_en": "{!scorenr} score (incl. overtime)",
        "name_aa": "{!scorenr} النتيجة (بما في ذلك العمل الإضافي)",
        "name_de": "{!scorenr} Ergebnis (inkl. Verlängerung)",
        "name_nl": "{scorenr} score (verlenging inbegrepen)",
        "name_pl": "{!scorenr} wynik (włącznie z dogrywką)",
        "name_ro": "Scor {!scorenr} (incl. prelungiri)",
        "name_ru": "{!scorenr} счет (включая овертайм)",
        "name_tr": "{!scorenr} skor (uzatmalar dahil)",
        "name_se": "{!scorenr} resultat (inkl. övertid)",
        "name_el": "{!scorenr} σκορ  (παράταση συμπ.)"
    },
    "id_822": {
        "name_en": "Draw sum total",
        "name_aa": "ارسم المجموع",
        "name_de": "Unentschieden Summe total",
        "name_nl": "Totale som trekking",
        "name_pl": "Draw sum total",
        "name_ro": "Sumă tragere total",
        "name_ru": "Ничья сумма итог",
        "name_tr": "Draw toplam, toplam sayı",
        "name_se": "Oavgjord summa totala",
        "name_el": "Draw sum σύνολο"
    },
    "id_781": {
        "name_en": "Batter hits (incl. extra innings)",
        "name_aa": "الخليط يضرب",
        "name_de": "Batter hits (inkl. Extra Innings)",
        "name_nl": "Slagman hits",
        "name_pl": "Batter hits (incl. extra innings)",
        "name_ro": "Lovituri batter",
        "name_ru": "Бэттер удары",
        "name_tr": "Vurucu hitleri",
        "name_se": "Slagman träffar",
        "name_el": "Batter hits"
    },
    "id_654": {
        "name_en": "Total run outs",
        "name_aa": "مجموع الجري",
        "name_de": "Run outs gesamt",
        "name_nl": "Totaal aantal run outs",
        "name_pl": "Suma run outs",
        "name_ro": "Total run outs",
        "name_ru": "Тотал ран-аутов",
        "name_tr": "Toplam dışarı koşular",
        "name_se": "Totalt antal run outs",
        "name_el": "Total run outs"
    },
    "id_586": {
        "name_en": "15 minutes - booking 1x2 from {from} to {to}",
        "name_aa": "15 دقيقة - حجز 1 × 2 من {from} إلى {to}",
        "name_de": "15 Minuten - Karten 1x2 von {from} bis {to}",
        "name_nl": "15 minutes - kaart 1x2 van {from} tot {to}",
        "name_pl": "15 minut - kartki 1x2 od {from} do {to}",
        "name_ro": "15 minute - cartonaș 1x2 de la {from} la {to}",
        "name_ru": "15 минут - карточки 1х2 с {from} до {to}",
        "name_tr": "15 dakika - kart 1x2 {from} dan {to} a",
        "name_se": "15 minuter - kort 1x2 från {from} till {to}",
        "name_el": "15 λεπτά - κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_193": {
        "name_en": "{$competitor2} to win a set",
        "name_aa": "{$competitor2} للفوز بمجموعة",
        "name_de": "{$competitor2} wird einen Satz gewinnen",
        "name_nl": "{$competitor2} wint een set",
        "name_pl": "{$competitor2} wygra seta",
        "name_ro": "Să câștige un set {$competitor2}",
        "name_ru": "{$competitor2} выиграет сет",
        "name_tr": "{$competitor2} set kazanır",
        "name_se": "{$competitor2} att vinna set",
        "name_el": "{$competitor2} να κερδίσει ένα σετ"
    },
    "id_1109": {
        "name_en": "{!lapnr} lap - fastest lap",
        "name_aa": "{!lapnr} lap - fastest lap",
        "name_de": "{!lapnr} lap - fastest lap",
        "name_nl": "{lapnr}e ronde - snelste ronde",
        "name_pl": "{!lapnr} lap - fastest lap",
        "name_ro": "{!lapnr} lap - fastest lap",
        "name_ru": "{!lapnr} круг - самый быстрый круг",
        "name_tr": "{!lapnr} lap - fastest lap",
        "name_se": "{!lapnr} lap - fastest lap",
        "name_el": "{!lapnr} γύρος - ταχύτερος γύρος"
    },
    "id_464": {
        "name_en": "Overtime - draw no bet",
        "name_aa": "الوقت الإضافي - لا رهان",
        "name_de": "Verlängerung - unentschieden, keine Wette",
        "name_nl": "Verlenging - draw no bet",
        "name_pl": "Dogrywka - remis bez zakładu",
        "name_ro": "Prelungiri - egal pariu nul",
        "name_ru": "Овертайм - ничья ставки нет",
        "name_tr": "Uzatmalar - beraberlikte iade",
        "name_se": "Övertid -  insatsen tillbaka vid oavgjort",
        "name_el": "Παράταση -Ισοπαλία όχι στοιχήμα"
    },
    "id_672": {
        "name_en": "{!inningnr} innings - {$competitor1} exact runs",
        "name_aa": "{!inningnr} أدوار - {$competitor1} تشغيل دقيق",
        "name_de": "{!inningnr} Inning - {$competitor1} Genaue Anzahl Runs",
        "name_nl": "{inningnr} innings - exact aantal runs {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} dokładna liczba runs",
        "name_ro": "{!inningnr} innings - {$competitor1} runs exact",
        "name_ru": "{!inningnr} иннинг - {$competitor1} точное число пробежек",
        "name_tr": "{!inningnr}. devre - {$competitor1} net koşu",
        "name_se": "{!inningnr} innings - {$competitor1} exakt antal runs",
        "name_el": "{!inningnr} innings - {$competitor1} exact runs"
    },
    "id_961": {
        "name_en": "{!freethrownr} free throw scored",
        "name_aa": "{!freethrownr} free throw scored",
        "name_de": "{!freethrownr} free throw scored",
        "name_nl": "{freethrownr} vrije worp gescoord",
        "name_pl": "{!freethrownr} free throw scored",
        "name_ro": "{!freethrownr} free throw scored",
        "name_ru": "{!freethrownr} штрафной бросок забит",
        "name_tr": "{!freethrownr} free throw scored",
        "name_se": "{!freethrownr} free throw scored",
        "name_el": "{!freethrownr} έυστοχη βολή"
    },
    "id_1103": {
        "name_en": "Head2head (teams)",
        "name_aa": "Head2head (teams)",
        "name_de": "Head2head (teams)",
        "name_nl": "Onderling (teams)",
        "name_pl": "Head2head (teams)",
        "name_ro": "Head2head (teams)",
        "name_ru": "Очные встречи (команды)",
        "name_tr": "Head2head (teams)",
        "name_se": "Head2head (teams)",
        "name_el": "Μεταξύ τους  (teams)"
    },
    "id_1096": {
        "name_en": "Total laps at {!stopnr} pit stop",
        "name_aa": "Total laps at {!stopnr} pit stop",
        "name_de": "Total laps at {!stopnr} pit stop",
        "name_nl": "Totaal aantal rondes bij {stopnr}e pitstop",
        "name_pl": "Total laps at {!stopnr} pit stop",
        "name_ro": "Total laps at {!stopnr} pit stop",
        "name_ru": "Итого кругов на {!stopnr} пит-стопе",
        "name_tr": "Total laps at {!stopnr} pit stop",
        "name_se": "Total laps at {!stopnr} pit stop",
        "name_el": "Σύνολο γύρων στο {!stopnr} πιτ στοπ"
    },
    "id_58": {
        "name_en": "Both halves over {total}",
        "name_aa": "كلا النصفين على {total}",
        "name_de": "Beide Hälften über {total}",
        "name_nl": "Beide helften meer dan {total}",
        "name_pl": "Obie połowy ponad {total}",
        "name_ro": "Ambele reprize peste {total}",
        "name_ru": "Обе половины больше {total}",
        "name_tr": "Her iki yarı {total} üstü",
        "name_se": "Båda halvlekarna över {total}",
        "name_el": "Και τα δύο ημίχρονα over  {total}"
    },
    "id_874": {
        "name_en": "Show",
        "name_aa": "Show",
        "name_de": "Show",
        "name_nl": "Laat zien",
        "name_pl": "Show",
        "name_ro": "Arată",
        "name_ru": "Show",
        "name_tr": "Show",
        "name_se": "Show",
        "name_el": "Show"
    },
    "id_322": {
        "name_en": "{!setnr} set end {endnr} - winner",
        "name_aa": "{!setnr}  وضع نهاية {endnr} - الفائز",
        "name_de": "{!setnr} Satzende {endnr} - Gewinner",
        "name_nl": "{setnr} set end {endnr} - winnaar",
        "name_pl": "{!setnr} set koniec {endnr} - zwycięzca",
        "name_ro": "Set-ul {!setnr} end-ul {endnr} - câștigător",
        "name_ru": "{!setnr} сет {endnr} конец - победитель",
        "name_tr": "{!setnr}. set {endnr}. end - kazanan",
        "name_se": "{!setnr} set slut {endnr} - vinnare",
        "name_el": "{!setnr} set end {endnr} - Νικητής"
    },
    "id_539": {
        "name_en": "Head2head",
        "name_aa": "رأس الرأس",
        "name_de": "Head2head",
        "name_nl": "Head2head",
        "name_pl": "Head2head",
        "name_ro": "Confruntare directă",
        "name_ru": "Единоборство",
        "name_tr": "Head to head",
        "name_se": "Head2head",
        "name_el": "Μεταξύ τους"
    },
    "id_994": {
        "name_en": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_aa": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_de": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_nl": "{inningnr} innings {overnr} over - totaal aantal runs spread",
        "name_pl": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_ro": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_ru": "{!inningnr} иннингов {!overnr} овер - тотал ран спред",
        "name_tr": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_se": "{!inningnr} innings {!overnr} over - total runs spread",
        "name_el": "{!inningnr} innings {!overnr} over - total runs spread"
    },
    "id_459": {
        "name_en": "{!periodnr} period - draw no bet",
        "name_aa": "فترة {!periodnr} - رسم أي رهان",
        "name_de": "{!periodnr} Periode - unentschieden, keine Wette",
        "name_nl": "{periodnr} periode - draw no bet",
        "name_pl": "{!periodnr} część gry - remis bez zakładu",
        "name_ro": "Repriza {!periodnr} - egal pariu nul",
        "name_ru": "{!periodnr} период - ничья ставки нет",
        "name_tr": "{!periodnr}. periyot - beraberlikte iade",
        "name_se": "{!periodnr} period - insatsen tillbaka vid oavgjort",
        "name_el": "{!periodnr} περίοδος - Ισοπαλία όχι στοιχήμα"
    },
    "id_1104": {
        "name_en": "{!retirementnr} to retire (teams)",
        "name_aa": "{!retirementnr} to retire (teams)",
        "name_de": "{!retirementnr} to retire (teams)",
        "name_nl": "Trekt zich als {retirementnr}e terug (teams)",
        "name_pl": "{!retirementnr} to retire (teams)",
        "name_ro": "{!retirementnr} to retire (teams)",
        "name_ru": "{!retirementnr} замена шин (команды)",
        "name_tr": "{!retirementnr} to retire (teams)",
        "name_se": "{!retirementnr} to retire (teams)",
        "name_el": "{!retirementnr} να εγκαταλείψει (teams)"
    },
    "id_742": {
        "name_en": "First {innings} innings 1x2 & 1x2 (incl. extra innings)",
        "name_aa": "أول {innings} أدوار 1 × 2 و 1 × 2 (بما في ذلك الجولات الإضافية)",
        "name_de": "Erste {innings} Innings 1x2 & 1x2 (inkl. Extra-Innings)",
        "name_nl": "Eerste {innings} innings 1x2 & 1x2 (extra innings inbegrepen)",
        "name_pl": "Pierwsze {innings} innings 1x2 & 1x2 (włącznie z extra innings)",
        "name_ro": "Primele {innings} innings 1x2 & 1x2 (incl. extra innings)",
        "name_ru": "Первые {innings} иннинги 1x2 и 1x2 (включая дополнительные иннинги)",
        "name_tr": "İlk {innings} devre - 1x2 & 1x2 (uzatmalar dahil)",
        "name_se": "Först {innings} innings 1x2 & 1x2 (inkl. extra innings)",
        "name_el": "First {innings} innings 1x2 & 1x2 (incl. extra innings)"
    },
    "id_229": {
        "name_en": "Odd/even (incl. overtime)",
        "name_aa": "فردي / زوجي(بما في ذلك العمل الإضافي)",
        "name_de": "Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "Even/oneven (verlenging inbegrepen)",
        "name_pl": "Parzyste/Nieparzyste (włącznie z dogrywką)",
        "name_ro": "Impar/par (incl. prelungiri)",
        "name_ru": "Чет/Нечет (включая овертайм)",
        "name_tr": "tek/çift (uzatmalar dahil)",
        "name_se": "Udda/jämnt (inkl. övertid)",
        "name_el": "Μονά/Ζυγά (παράταση συμπ.)"
    },
    "id_209": {
        "name_en": "{!setnr} set - {!gamenrX} and {!gamenrY} game winner",
        "name_aa": "{!setnr} مجموعة - {!gamenrX} و {!gamenrY} الفائز اللعبة",
        "name_de": "{!setnr} Satz - {!gamenrX} und {!gamenrY} Gewinner Spiel",
        "name_nl": "{setnr} set - {gamenrX} en {gamenrY} game winnaar",
        "name_pl": "{!setnr} set - {!gamenrX} i {!gamenrY} gem zwycięzca",
        "name_ro": "Set {!setnr} - câștigător game-uri {!gamenrX} și {!gamenrY}",
        "name_ru": "{!setnr} сет - победитель {!gamenrX} и {!gamenrY} геймов",
        "name_tr": "{!setnr}. set - {!gamenrX}. ve {!gamenrY}. oyunların kazananı",
        "name_se": "{!setnr} set - {!gamenrX} och {!gamenrY} game vinnare",
        "name_el": "{!setnr} σετ - νικητής  {!gamenrX} και {!gamenrY} γκέιμ"
    },
    "id_182": {
        "name_en": "1st half - corner range",
        "name_aa": "الشوط الأول - نطاق الزاوية",
        "name_de": "1. Halbzeit - Eckenanzahlbereich",
        "name_nl": "Eerste helft - hoekschoppen range",
        "name_pl": "1. Połowa - zakres liczby rzutów rożnych",
        "name_ro": "Repriza 1 - interval cornere",
        "name_ru": "1ая половина - количество угловых",
        "name_tr": "İlk yarı- korner aralığı",
        "name_se": "1:a halvlek - hörnintervall",
        "name_el": "1ο ημίχρονο Εύρος των κόρνερ"
    },
    "id_549": {
        "name_en": "{$competitor1} multigoals",
        "name_aa": "{$competitor1} متعددة الأهداف",
        "name_de": "{$competitor1} Gesamtzahl der Tore im Spiel",
        "name_nl": "{$competitor1} multigoals",
        "name_pl": "{$competitor1} multi-gole",
        "name_ro": "Multigoluri {$competitor1}",
        "name_ru": "{$competitor1} мултиголы",
        "name_tr": "{$competitor1} toplam gol",
        "name_se": "{$competitor1} multimå",
        "name_el": "{$competitor1}  Γκολ (πολλαπλών επιλογών)"
    },
    "id_548": {
        "name_en": "Multigoals",
        "name_aa": "أهداف متعددة",
        "name_de": "Gesamtzahl der Tore im Spiel",
        "name_nl": "Multigoals",
        "name_pl": "Multi-gole",
        "name_ro": "Multigoluri",
        "name_ru": "Мултиголы",
        "name_tr": "Toplam gol",
        "name_se": "Multimål",
        "name_el": "Γκολ (πολλαπλών επιλογών)"
    },
    "id_1621": {
        "name_en": "Score in first 20 min",
        "name_aa": "Score in first 20 min",
        "name_de": "Score in first 20 min",
        "name_nl": "Score in first 20 min",
        "name_pl": "Score in first 20 min",
        "name_ro": "Score in first 20 min",
        "name_ru": "Score in first 20 min",
        "name_tr": "Score in first 20 min",
        "name_se": "Score in first 20 min",
        "name_el": "Score in first 20 min"
    },
    "id_520": {
        "name_en": "{!gamenr} game - {!pointnr} point",
        "name_aa": "{!gamenr} لعبة - {!pointnr} نقطة",
        "name_de": "{!gamenr} Spiel - {!pointnr} Punkt",
        "name_nl": "{gamenr} game - {pointnr} punt",
        "name_pl": "{!gamenr} gem - {!pointnr} punkt",
        "name_ro": "Game-ul {!gamenr}  - punctul {!pointnr}",
        "name_ru": "{!gamenr} игра - {!pointnr} очко",
        "name_tr": "{!gamenr}. oyun - {!pointnr}. puan",
        "name_se": "{!gamenr} game - {!pointnr} poäng",
        "name_el": "{!gamenr} frame -  {!pointnr} πόντος"
    },
    "id_236": {
        "name_en": "{!quarternr} quarter - total",
        "name_aa": "الربع {!quarternr} - مجموعه",
        "name_de": "{!quarternr} Viertel - Total",
        "name_nl": "{quarternr} kwart - totaal",
        "name_pl": "{!quarternr} kwarta - suma",
        "name_ro": "Sfertul {!quarternr} - total",
        "name_ru": "{!quarternr} четверть - тотал",
        "name_tr": "{!quarternr}. çeyrek - toplam gol",
        "name_se": "{!quarternr} quarter - totalt",
        "name_el": "{!quarternr} περίοδος - σύνολο"
    },
    "id_845": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread",
        "name_aa": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread",
        "name_de": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - {$competitor2} total spread",
        "name_pl": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread",
        "name_ro": "{!inningnr} innings overs 0 la {overnr} - {$competitor2} total interval",
        "name_ru": "{!inningnr} иннингов оверов 0 до {overnr} - {$competitor2} тотал спред",
        "name_tr": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread",
        "name_se": "{!inningnr} innings overs 0 till {overnr} - {$competitor2} total spread",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total spread"
    },
    "id_416": {
        "name_en": "Exact goals (incl. overtime and penalties)",
        "name_aa": "الأهداف الدقيقة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Genaue Anzahl Tore (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Exact aantal doelpunten (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Dokładna liczba goli (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Goluri exact (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Точное число голов (включая овертайм и пенальти)",
        "name_tr": "Doğru skor (uzatmalar ve penaltılar dahil)",
        "name_se": "Exakt antal mål (inkl. övertid och straffar)",
        "name_el": "Ακριβής αριθμός γκολ (παράταση και πέναλτι συμπ.)"
    },
    "id_968": {
        "name_en": "Anytime goalscorer",
        "name_aa": "Anytime goalscorer",
        "name_de": "Torschütze(n)",
        "name_nl": "Doelpuntenmaker",
        "name_pl": "Anytime goalscorer",
        "name_ro": "Anytime goalscorer",
        "name_ru": "Гол в любое время",
        "name_tr": "Anytime goalscorer",
        "name_se": "Närsomhelst målgörare",
        "name_el": "Να σκοράρει οποτεδήποτε (90 λεπτά)"
    },
    "id_1072": {
        "name_en": "{!inningnr} inning - most hits",
        "name_aa": "{!inningnr} inning - most hits",
        "name_de": "{!inningnr} inning - most hits",
        "name_nl": "{inningnr} inning - meeste slagen",
        "name_pl": "{!inningnr} inning - most hits",
        "name_ro": "{!inningnr} inning - most hits",
        "name_ru": "{!inningnr} иннинг - наибольшее число отбитых мячей",
        "name_tr": "{!inningnr} inning - most hits",
        "name_se": "{!inningnr} inning - most hits",
        "name_el": "{!inningnr} inning - περισσότερα hits"
    },
    "id_436": {
        "name_en": "{$competitor2} to win all periods",
        "name_aa": "{$competitor2} للفوز بجميع الفترات",
        "name_de": "{$competitor2} wird alle Perioden gewinnen",
        "name_nl": "{$competitor2} wint alle periodes",
        "name_pl": "{$competitor2} wygra wszystkie części gry",
        "name_ro": "{$competitor2} să câștige toate reprizele",
        "name_ru": "{$competitor2} выиграть все периоды",
        "name_tr": "{$competitor2} tüm periyotları kazanır",
        "name_se": "{$competitor2} att vinna alla perioder",
        "name_el": "{$competitor2} να κερδίσει όλες τις περιόδους"
    },
    "id_565": {
        "name_en": "15 minutes - corner 1x2 from {from} to {to}",
        "name_aa": "15 دقيقة - الزاوية 1 × 2 من {from} إلى {to}",
        "name_de": "15 Minuten - Ecken 1x2 von {from} bis {to}",
        "name_nl": "15 minutes - hoekschop 1x2 van {from} tot {to}",
        "name_pl": "15 minut - rzuty rożne 1x2 od {from} do {to}",
        "name_ro": "15 minute - corner 1x2 de la {from} la {to}",
        "name_ru": "15 минут - угловой 1х2 с {from} до {to}",
        "name_tr": "15 dakika - korner 1x2 {from} dan {to} a",
        "name_se": "15 minuter - hörnor 1x2 från {from} till {to}",
        "name_el": "15 λεπτά - κόρνερ 1Χ2 από {from} μέχρι {to}"
    },
    "id_388": {
        "name_en": "{!setnr} set - total 180s",
        "name_aa": "مجموعة {!setnr} - مجموع 180",
        "name_de": "{!setnr} Satz - Total 180s",
        "name_nl": "{setnr} set - totaal aantal 180s",
        "name_pl": "{!setnr} set - suma 180s",
        "name_ro": "Set {!setnr} - total 180-uri",
        "name_ru": "{!setnr} сет - тотал 180ти",
        "name_tr": "{!setnr}. set - toplam 180ler",
        "name_se": "{!setnr} set - totalt antal 180:or",
        "name_el": "{!setnr} set - σύνολο 180"
    },
    "id_1036": {
        "name_en": "{!quarternr} quarter - handicap {hcp}",
        "name_aa": "{!quarternr} quarter - handicap {hcp}",
        "name_de": "{!quarternr} Viertel - Handicap {hcp}",
        "name_nl": "{quarternr} kwart - handicap {hcp}",
        "name_pl": "{!quarternr} kwarta - handicap {hcp}",
        "name_ro": "{!quarternr} quarter - handicap {hcp}",
        "name_ru": "{!quarternr} четверть - гандикап {hcp}",
        "name_tr": "{!quarternr} quarter - handicap {hcp}",
        "name_se": "{!quarternr} quarter - handikapp {hcp}",
        "name_el": "{!quarternr} περίοδος - χάντικαπ  {hcp}"
    },
    "id_159": {
        "name_en": "1st half - sending off",
        "name_aa": "الشوط الأول - طرد",
        "name_de": "1. Halbzeit - Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen",
        "name_pl": "1. Połowa - czerwona kartka",
        "name_ro": "Repriza 1 - eliminare",
        "name_ru": "1ая половина - удаление",
        "name_tr": "İlk yarı - oyundan atılma",
        "name_se": "1:a halvlek - utvisning",
        "name_el": "1ο ημίχρονο - να αποβληθεί"
    },
    "id_1001": {
        "name_en": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_aa": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_de": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_nl": "{inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_pl": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_ro": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_ru": "{!inningnr} иннингов овер {overnrX} x {overnrY} - {$competitor2} мульти ран спред",
        "name_tr": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_se": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread",
        "name_el": "{!inningnr} innings overs {overnrX} x {overnrY} - {$competitor2} multi run spread"
    },
    "id_918": {
        "name_en": "{%player} total rushing yards (incl. overtime)",
        "name_aa": "{!player} total rushing yards (incl. overtime)",
        "name_de": "{!player} Rushing Yards Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal rushing yards",
        "name_pl": "{!player} total rushing yards (incl. overtime)",
        "name_ro": "{!player} total rushing yards (incl. overtime)",
        "name_ru": "{!player} тотал пронесенных ярдов",
        "name_tr": "{!player} total rushing yards (incl. overtime)",
        "name_se": "{!player} total rushing yards (incl. overtime)",
        "name_el": "{!player} total rushing yards"
    },
    "id_585": {
        "name_en": "5 minutes - odd/even corners from {from} to {to}",
        "name_aa": "5 دقائق - فردي / زوجي زوايا من {from} إلى {to}",
        "name_de": "5 Minuten - Gerade/ungerade Ecken von {from} bis {to}",
        "name_nl": "5 minutes - even/oneven aantal hoekschoppen van {from} tot {to}",
        "name_pl": "5 minut - parzyste/nieparzyste rzuty rożne od {from} do {to}",
        "name_ro": "5 minute - număr de cornere impar/par de la {from} la {to}",
        "name_ru": "5 минут - чет/нечет угловые с {from} до {to}",
        "name_tr": "5 dakika - tek/çift korner {from} dan {to} a",
        "name_se": "5 minuter - udda/jämnt hörnor från {from} till {to}",
        "name_el": "5 λεπτά - μονά/ζυγά κόρνερ από {from} μέχρι {to}"
    },
    "id_1606": {
        "name_en": "Double chance Halftime/Fulltime",
        "name_aa": "Double chance Halftime/Fulltime",
        "name_de": "Double chance Halftime/Fulltime",
        "name_nl": "Double chance Halftime/Fulltime",
        "name_pl": "Double chance Halftime/Fulltime",
        "name_ro": "Double chance Halftime/Fulltime",
        "name_ru": "Double chance Halftime/Fulltime",
        "name_tr": "Double chance Halftime/Fulltime",
        "name_se": "Double chance Halftime/Fulltime",
        "name_el": "Double chance Halftime/Fulltime"
    },
    "id_915": {
        "name_en": "{%player} total pass completions (incl. overtime)",
        "name_aa": "{!player} total pass completions (incl. overtime)",
        "name_de": "{!player} Pass Completions Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal aangekomen passes",
        "name_pl": "{!player} total pass completions (incl. overtime)",
        "name_ro": "{!player} total pass completions (incl. overtime)",
        "name_ru": "{!player} тотал завершений паса",
        "name_tr": "{!player} total pass completions (incl. overtime)",
        "name_se": "{!player} total pass completions (incl. overtime)",
        "name_el": "{!player} total pass completions"
    },
    "id_447": {
        "name_en": "{!periodnr} period - {$competitor1} total",
        "name_aa": "فترة {!periodnr} - {$competitor1} مجموعه",
        "name_de": "{!periodnr} Periode - {$competitor1} Total",
        "name_nl": "{periodnr} periode - totaal {$competitor1}",
        "name_pl": "{!periodnr} część gry - {$competitor1} suma",
        "name_ro": "Repriza {!periodnr} - total {$competitor1}",
        "name_ru": "{!periodnr} период - {$competitor1} тотал",
        "name_tr": "{!periodnr} . periyot - {$competitor1} toplam",
        "name_se": "{!periodnr} period - {$competitor1} totalt",
        "name_el": "{!periodnr} περίοδος - {$competitor1} σύνολο"
    },
    "id_959": {
        "name_en": "{!drivenr} drive - result",
        "name_aa": "{!drivenr} drive - result",
        "name_de": "{!drivenr} drive - result",
        "name_nl": "{drivenr} drive - resultaat",
        "name_pl": "{!drivenr} drive - result",
        "name_ro": "{!drivenr} drive - result",
        "name_ru": "{!drivenr} драйв - результат",
        "name_tr": "{!drivenr} drive - result",
        "name_se": "{!drivenr} drive - resultat",
        "name_el": "{!drivenr} drive - αποτέλεσμα"
    },
    "id_855": {
        "name_en": "{$competitor1} or under {total}",
        "name_aa": "{$competitor1} أو أقل من {total}",
        "name_de": "{$competitor1} oder unter {total}",
        "name_nl": "{$competitor1} of onder {total}",
        "name_pl": "{$competitor1} lub poniżej {total}",
        "name_ro": "{$competitor1} sau sub {total}",
        "name_ru": "{$competitor1} или меньше {total}",
        "name_tr": "{$competitor1} ya da alt {total}",
        "name_se": "{$competitor1} elr under {total}",
        "name_el": "{$competitor1} ή under {total}"
    },
    "id_178": {
        "name_en": "1st half - {$competitor1} total corners",
        "name_aa": "النصف الأول - {$competitor1} مجموع الزوايا",
        "name_de": "1. Halbzeit - {$competitor1} Gesamtanzahl Ecken",
        "name_nl": "Eerste helft - totaal aantal hoekschoppen {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} Suma rzutów rożnych",
        "name_ro": "Repriza 1 - total cornere {$competitor1}",
        "name_ru": "1ая половина - тотал угловых {$competitor1}",
        "name_tr": "İlk yarı - {$competitor1} toplam korner sayısı",
        "name_se": "1:a halvlek - {$competitor1} totalt antal hörnor",
        "name_el": "1ο ημίχρονο -{$competitor1}  σύνολο κόρνερ"
    },
    "id_655": {
        "name_en": "Total extras",
        "name_aa": "مجموع الإضافات",
        "name_de": "Extras gesamt",
        "name_nl": "Totaal aantal extras",
        "name_pl": "Suma extras",
        "name_ro": "Total extras",
        "name_ru": "Тотал дополнительных ранов",
        "name_tr": "Toplam ekstralar",
        "name_se": "Totala antal extras",
        "name_el": "Total extras"
    },
    "id_513": {
        "name_en": "{!framenr} frame - player to pot {!xth} ball",
        "name_aa": "إطار {!framenr} - {xth!} لاعب إلى وعاء الكرة",
        "name_de": "{!framenr} Frame - Spieler locht {!xth} Ball ein",
        "name_nl": "{framenr} frame - speler die de {xth} bal pot",
        "name_pl": "{!framenr} frejm - zawodnik wbije {!xth} kulę",
        "name_ro": "Frame-ul {!framenr} - jucătorul care va introduce bila {!xth}",
        "name_ru": "{!framenr} фрейм - игрок, который загонит в лузу {!xth} шар",
        "name_tr": "{!framenr}. frame - {!xth}. top pota",
        "name_se": "{!framenr} frame - spelare som sätter {!xth} bollen",
        "name_el": "{!framenr} frame - παίχτης που θα κάνει pot  {!xth} μπάλα"
    },
    "id_282": {
        "name_en": "Innings 1 to 5th top - {$competitor1} total",
        "name_aa": "ألدور 1إلى5 أعلى - {$competitor1} الإجمالي",
        "name_de": "Innings 1 bis Anfang 5tes - {$competitor1} Total",
        "name_nl": "Eerste helft (top) Inning 1 tot 5 - {$competitor1} totaal",
        "name_pl": "Innings 1 do początku 5. - {$competitor1} suma",
        "name_ro": "Innings de la 1 la 5 top - total {$competitor1}",
        "name_ru": "Иннинги с 1 до 5го верха - {$competitor1} тотал",
        "name_tr": "Devreler 1den 5in sonuna - {$competitor1} toplam",
        "name_se": "Innings 1 till 5th topp - {$competitor1} totalt",
        "name_el": "Innings 1 σε 5 top - σύνολο {$competitor1}"
    },
    "id_46": {
        "name_en": "Halftime/fulltime correct score",
        "name_aa": "الشوط الاول / النتيجة الصحيحة بدوام كامل",
        "name_de": "Halbzeit/reg. Spielzeit - Genaues Ergebnis",
        "name_nl": "Correcte rust/eindstand",
        "name_pl": "Połowa/Reg. czas gry Dokładny wynik",
        "name_ro": "Scor corect pauză/final",
        "name_ru": "Точный счет после первого тайма/основного времени",
        "name_tr": "ilk yarı/maç sonucu skoru",
        "name_se": "Halvtid/fulltid - rätt resultat",
        "name_el": "Ημίχρονο/Τελικό Ακριβές σκορ"
    },
    "id_19": {
        "name_en": "{$competitor1} total",
        "name_aa": "{$competitor1} مجموعه",
        "name_de": "{$competitor1} Total",
        "name_nl": "{$competitor1} totaal",
        "name_pl": "{$competitor1} Suma",
        "name_ro": "Total {$competitor1}",
        "name_ru": "{$competitor1} тотал",
        "name_tr": "{$competitor1} toplam gol",
        "name_se": "{$competitor1} totalt",
        "name_el": "Σύνολο Γκολ {$competitor1}"
    },
    "id_112": {
        "name_en": "Overtime & goal",
        "name_aa": "الوقت الإضافي والهدف",
        "name_de": "Verlängerung & Tor",
        "name_nl": "Verlenging & doelpunt",
        "name_pl": "Dogrywka & gol",
        "name_ro": "Prelungiri & gol",
        "name_ru": "Овертайм и гол",
        "name_tr": "Uzatma & Gol",
        "name_se": "Övertid & mål",
        "name_el": "Παράταση και γκολ"
    },
    "id_382": {
        "name_en": "{!xth} player to score a 180",
        "name_aa": "{!xth}  لاعب يسجل 180",
        "name_de": "{!xth} Spieler der ein 180 erzielt",
        "name_nl": "{xth} speler gooit een 180",
        "name_pl": "{!xth} zawodnik osiągający 180",
        "name_ro": "Jucătorul {!xth} să înscrie un 180",
        "name_ru": "{!xth} игрок наберёт 180",
        "name_tr": "{!xth}. oyuncu 180 puan yapar",
        "name_se": "{!xth} spelare att kasta en 180:a",
        "name_el": "{!xth}  παίχτης που θα σκοράρει 180άρι"
    },
    "id_813": {
        "name_en": "{!mapnr} map - player with most kills",
        "name_aa": "{!mapnr} خريطة - لاعب مع معظم يقتل",
        "name_de": "{!mapnr} Karte - Spieler mit den meisten Abschüssen",
        "name_nl": "{mapnr} map - speler met de meeste kills",
        "name_pl": "{!mapnr} mapa - zawodnik z największą liczbą zabójstw",
        "name_ro": "Harta {!mapnr} - cele mai multe kills",
        "name_ru": "{!mapnr} карта - игрок с наибольшим количеством киллов",
        "name_tr": "{!mapnr} - harita - en çok kill sayısı olan oyuncu",
        "name_se": "{!mapnr} karta - spelare med flest kills",
        "name_el": "{!mapnr} map - παίχτης με τα περισσότερα kills"
    },
    "id_1062": {
        "name_en": "{!drivenr} drive play {playnr} - {%competitor} pass completion",
        "name_aa": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_de": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_nl": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_pl": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_ro": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_ru": "{!drivenr} драйв плей {playnr} - {!competitor} завершение паса",
        "name_tr": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_se": "{!drivenr} drive play {playnr} - {!competitor} pass completion",
        "name_el": "{!drivenr} drive play {playnr} - {!competitor} pass completion"
    },
    "id_993": {
        "name_en": "Multi wides spread",
        "name_aa": "Multi wides spread",
        "name_de": "Multi wides spread",
        "name_nl": "Multi wides spread",
        "name_pl": "Multi wides spread",
        "name_ro": "Multi wides spread",
        "name_ru": "Мульти уайд спред",
        "name_tr": "Multi wides spread",
        "name_se": "Multi wides spread",
        "name_el": "Multi wides spread"
    },
    "id_865": {
        "name_en": "{$competitor2} or any clean sheet",
        "name_aa": "{$competitor2} أو أي ورقة نظيفة",
        "name_de": "{$competitor2} or any clean sheet",
        "name_nl": "{$competitor2} of een schoon doel",
        "name_pl": "{$competitor2} lub którykolwiek czyste konto",
        "name_ro": "{$competitor2} sau oricare să nu primească gol",
        "name_ru": "{$competitor2} или любая из команд не забьет",
        "name_tr": "{$competitor2} ya da gol yok",
        "name_se": "{$competitor2} elr något annat resultat",
        "name_el": "{$competitor2} ή μία από τις 2 ομάδες να μη δεχτεί γκολ"
    },
    "id_801": {
        "name_en": "{$event} matchday {matchday} - total home team wins",
        "name_aa": "{$event} يوم المباراة {matchday}- فاز فريق المنزل بأكمله",
        "name_de": "{$event} Spieltag {matchday} - Siege Heimmannschaften Gesamt",
        "name_nl": "{$event} matchday {matchday} - totaal aantal overwinningen thuisspelende ploeg",
        "name_pl": "{$event} matchday {matchday} - suma zwycięstw gospodarze",
        "name_ro": "{$event} etapă {matchday} - total victorii gazde",
        "name_ru": "{$event} игровой день {matchday} - итого победы хозяев",
        "name_tr": "{$event} maç günü {matchday} - toplam ev sahibi takım kazanma sayısı",
        "name_se": "{$event} - hemmalagets totalt vinster",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο - να κερδίσει γηπεδούχος"
    },
    "id_536": {
        "name_en": "Free text multiwinner market",
        "name_aa": "Free text multiwinner market",
        "name_de": "Free text multiwinner market",
        "name_nl": "Free text multiwinner market",
        "name_pl": "Free text multiwinner market",
        "name_ro": "Free text multiwinner market",
        "name_ru": "Free text multiwinner market",
        "name_tr": "Free text multiwinner market",
        "name_se": "Free text multiwinner market",
        "name_el": "Free text multiwinner market"
    },
    "id_374": {
        "name_en": "{!setnr} set - correct score (in legs)",
        "name_aa": "مجموعة {!setnr} - النتيجة الصحيحة (في الساقين)",
        "name_de": "{!setnr} Satz - Genaues Ergebnis (in Runden)",
        "name_nl": "{setnr} set - juiste score (in legs)",
        "name_pl": "{!setnr} set - dokładny wynik ( w legach)",
        "name_ro": "Set {!setnr} - scor corect (în legs)",
        "name_ru": "{!setnr} сет - точный счет (на этапах)",
        "name_tr": "{!setnr}. set - doğru skor (ayaklarda)",
        "name_se": "{!setnr} set - rätt resultat (på legs)",
        "name_el": "{!setnr} set - ακριβές σκορ (legs)"
    },
    "id_636": {
        "name_en": "1st half - {$competitor1} goal range",
        "name_aa": "الشوط الأول - مجموعة الأهداف {$competitor1}",
        "name_de": "1. Halbzeit - {$competitor1} Tordifferenz",
        "name_nl": "Eerste helft - doelpunten range {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} zakres liczby goli",
        "name_ro": "Repriza 1 - interval goluri {$competitor1}",
        "name_ru": "1-й тайм - {$competitor1} интервал голов",
        "name_tr": "İlk yarı - {$competitor1} gol aralığı",
        "name_se": "1:a halvlek - {$competitor1} målrad",
        "name_el": "1ο ημίχρονο - {$competitor1} Εύρος των γκολ"
    },
    "id_514": {
        "name_en": "{!framenr} frame - {!xth} colour potted",
        "name_aa": "إطار {!framenr} - {!xth} لون محفوظ بوعاء",
        "name_de": "{!framenr} Frame - {!xth} Farbe eingelocht",
        "name_nl": "{framenr} frame - {xth} kleur gepot",
        "name_pl": "{!framenr} frejm - {!xth} kolor wbity",
        "name_ro": "Frame-ul {!framenr} - culoarea {!xth} introdusă",
        "name_ru": "{!framenr} фрейм - {!xth} цвет загнан в лузу",
        "name_tr": "{!framenr} frame - {!xth} colour potted",
        "name_se": "{!framenr} frame - {!xth} färg som sitter",
        "name_el": "{!framenr} frame - {!xth} χρώμα potted"
    },
    "id_284": {
        "name_en": "Innings {from} to {to} - total",
        "name_aa": "ألدور{from} إلى{to} - المجموع",
        "name_de": "Innings {from} bis {to} - Total",
        "name_nl": "Inning {from} tot {to} - totaal",
        "name_pl": "Innings {from} do {to} - suma",
        "name_ro": "Innings de la {from} la {to} - total",
        "name_ru": "Иннинги {from} до {to} - тотал",
        "name_tr": "Devreler {from} den {to} e - toplam",
        "name_se": "Innings {from} till {to} - totalt",
        "name_el": "Innings {from} μέχρι {to} - σύνολο"
    },
    "id_150": {
        "name_en": "1st half - {!bookingnr} booking",
        "name_aa": "النصف الأول - {!bookingnr} الحجز",
        "name_de": "1. Halbzeit - {!bookingnr} Karte",
        "name_nl": "Eerste helft - {bookingnr} kaart",
        "name_pl": "1. Połowa - {!bookingnr} kartka",
        "name_ro": "Repriza 1 - cartonașul {!bookingnr}",
        "name_ru": "1ая половина - {bookingnr} карточка",
        "name_tr": "İlk yarı - {!bookingnr} kart",
        "name_se": "1:a halvlek - {!bookingnr} kort",
        "name_el": "1ο ημίχρονο - {!bookingnr} κάρτα"
    },
    "id_345": {
        "name_en": "{!inningnr} innings - {$competitor1} run range",
        "name_aa": "أدوار {!inningnr} - {$competitor1} نطاق المدى",
        "name_de": "{!inningnr} Inning - {$competitor1} Laufbereich",
        "name_nl": "{inningnr} innings - run range {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} zakres liczby runs",
        "name_ro": "{!inningnr} innings - interval run-uri {$competitor1}",
        "name_ru": "{!inningnr} иннинг - {$competitor1} дистанция пробежек",
        "name_tr": "{!inningnr}. devre - {$competitor1} koşu aralığı",
        "name_se": "{!inningnr} innings - {$competitor1} run-intervall",
        "name_el": "{!inningnr} innings - {$competitor1} run range"
    },
    "id_210": {
        "name_en": "{!setnr} set game {gamenr} - winner",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - الفائز",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Gewinner",
        "name_nl": "{setnr} set game {gamenr} - winnaar",
        "name_pl": "{!setnr} set gem {gamenr} - zwycięzca",
        "name_ro": "Set {!setnr} game {gamenr} - câștigător",
        "name_ru": "{!setnr} сет {gamenr} гейм - победитель",
        "name_tr": "{!setnr}. set {gamenr}. oyun - kazanan",
        "name_se": "{!setnr} set game {gamenr} - vinnare",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - νικητής"
    },
    "id_963": {
        "name_en": "{$competitor2} total maximum consecutive points",
        "name_aa": "{$competitor2} total maximum consecutive points",
        "name_de": "{$competitor2} total maximum consecutive points",
        "name_nl": "{$competitor2} totaal aantal maximum opeenvolgende punten",
        "name_pl": "{$competitor2} total maximum consecutive points",
        "name_ro": "{$competitor2} total maximum consecutive points",
        "name_ru": "{$competitor2} тотал максимум очков подряд",
        "name_tr": "{$competitor2} total maximum consecutive points",
        "name_se": "{$competitor2} total maximum consecutive points",
        "name_el": "{$competitor2} total maximum consecutive points"
    },
    "id_635": {
        "name_en": "1st half - goal range",
        "name_aa": "الشوط الأول - مجموعة الأهداف",
        "name_de": "1. Halbzeit - Tordifferenz",
        "name_nl": "Eerste helft - doelpunten range",
        "name_pl": "1. Połowa - zakres liczby goli",
        "name_ro": "Repriza 1 - interval goluri",
        "name_ru": "1-й тайм - интервал голов",
        "name_tr": "İlk yarı - gol aralığı",
        "name_se": "1:a halvlek målrad",
        "name_el": "1ο ημίχρονο -Εύρος των γκολ"
    },
    "id_1110": {
        "name_en": "Winner",
        "name_aa": "Winner",
        "name_de": "Winner",
        "name_nl": "Winnaar",
        "name_pl": "Winner",
        "name_ro": "Winner",
        "name_ru": "Победитель",
        "name_tr": "Winner",
        "name_se": "Winner",
        "name_el": "Νικητής"
    },
    "id_384": {
        "name_en": "Total 180s",
        "name_aa": "إجمالي 180 ثانية",
        "name_de": "Total 180s",
        "name_nl": "Totaal aantal 180s",
        "name_pl": "Suma 180s",
        "name_ro": "Total 180-uri",
        "name_ru": "Тотал 180ти",
        "name_tr": "Toplam 180ler",
        "name_se": "Totalt antal 180:or",
        "name_el": "Σύνολο 180"
    },
    "id_1631": {
        "name_en": "Halftime or fulltime",
        "name_aa": "Halftime or fulltime",
        "name_de": "Halftime or fulltime",
        "name_nl": "Halftime or fulltime",
        "name_pl": "Halftime or fulltime",
        "name_ro": "Halftime or fulltime",
        "name_ru": "Halftime or fulltime",
        "name_tr": "Halftime or fulltime",
        "name_se": "Halftime or fulltime",
        "name_el": "Halftime or fulltime"
    },
    "id_677": {
        "name_en": "{!inningnr} innings - {$competitor2} top bowler",
        "name_aa": "{!inningnr} أدوار - {$competitor2} قمة الرامى",
        "name_de": "{!inningnr} Inning - {$competitor2} Bester Bowler",
        "name_nl": "{inningnr} innings - beste werper {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} najlepszy bowler",
        "name_ro": "{!inningnr} innings - {$competitor2} top bowler",
        "name_ru": "{!inningnr} иннинг - {$competitor2} лучший боулер",
        "name_tr": "{!inningnr}. devre - {$competitor2} en iyi atıcı",
        "name_se": "{!inningnr} innings - {$competitor2} topp bowler",
        "name_el": "{!inningnr} innings - {$competitor2} top bowler"
    },
    "id_620": {
        "name_en": "{!mapnr} map - race to {roundnr} rounds",
        "name_aa": "خريطة {!mapnr} - سباق إلى {roundnr} جولات",
        "name_de": "{!mapnr} Map - Wer erreicht zuerst {roundnr} Runden",
        "name_nl": "{mapnr} map - race naar {roundnr} rounds",
        "name_pl": "{!mapnr} mapa - kto pierwszy z {roundnr} rundami",
        "name_ro": "Harta {!mapnr} - primul la {roundnr} runde",
        "name_ru": "{!mapnr} карта - гонка до {roundnr} раундов",
        "name_tr": "{!mapnr}. harita - {roundnr}. raound çekişmesi",
        "name_se": "{!mapnr} karta - först till {roundnr} rundor",
        "name_el": "{!mapnr} map -Ποιος θα φτάσει 1ος στους {roundnr} γύρους"
    },
    "id_540": {
        "name_en": "Double chance (match) & 1st half both teams score",
        "name_aa": "فرصة مزدوجة (مباراة) ونقاط الشوط الأول كلا الفريقين",
        "name_de": "Doppelte Chance (Spiel) & 1. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Dubbele kans (match) & beide teams scoren in eerste helft",
        "name_pl": "Podwójna szansa (mecz) & 1. połowa oba zespoły strzelą",
        "name_ro": "Șansă dublă (meci) & repriza 1 ambele echipe să înscrie",
        "name_ru": "Двойной шанс (матч) и 1ая половина обе команды забьют",
        "name_tr": "Çifte şans (maç) & ilk yarı her iki takımda gol atar",
        "name_se": "Dubbelchans (match) & 1:a halvlek gör båda lagen mål",
        "name_el": "Διπλή ευκαιρία (αγώνας) και να σκοράρουν και οι 2 ομάδες στο 1ο ημίχρονο"
    },
    "id_26": {
        "name_en": "Odd/even",
        "name_aa": "فردي زوجي",
        "name_de": "Gerade/ungerade",
        "name_nl": "Even/oneven",
        "name_pl": "Parzyste/Nieparzyste",
        "name_ro": "Impar/Par",
        "name_ru": "Чет/Нечет",
        "name_tr": "tek/çift",
        "name_se": "Udda/jämnt",
        "name_el": "Μονά/Ζυγά"
    },
    "id_245": {
        "name_en": "{!gamenr} game - winner",
        "name_aa": "لعبة {!gamenr} - الفائز",
        "name_de": "{!gamenr} Spiel - Gewinner",
        "name_nl": "{gamenr} game - winnaar",
        "name_pl": "{!gamenr} gem - zwycięzca",
        "name_ro": "Game-ul {!gamenr} - câștigător",
        "name_ru": "{!gamenr} игра - победитель",
        "name_tr": "{!gamenr}. oyun - kazanan",
        "name_se": "{!gamenr} game - vinnare",
        "name_el": "{!gamenr} γκέιμ - νικητής"
    },
    "id_465": {
        "name_en": "Overtime - {$competitor1} no bet",
        "name_aa": "الوقت الإضافي - {$competitor1} لا يوجد رهان",
        "name_de": "Verlängerung - {$competitor1} keine Wette",
        "name_nl": "Verlenging - {$competitor1} no bet",
        "name_pl": "Dogrywka - {$competitor1} bez zakładu",
        "name_ro": "Prelungiri - {$competitor1} pariu nul",
        "name_ru": "Овертайм - на {$competitor1} нет ставки",
        "name_tr": "Uzatmalar - {$competitor1} kazanırsa iade",
        "name_se": "Övertid - {$competitor1} inget spel",
        "name_el": "Παράταση - {$competitor1}  ισοπαλία επιστροφή στοιχήματος"
    },
    "id_547": {
        "name_en": "Double chance & total",
        "name_aa": "فرصة مزدوجة ومجموع",
        "name_de": "Doppelte Chance & Total",
        "name_nl": "Dubbele kans & totaal",
        "name_pl": "Podwójna szansa & suma",
        "name_ro": "Șansă dublă & total",
        "name_ru": "Двойной шанс и тотал",
        "name_tr": "Çifte şans & toplam gol",
        "name_se": "Dubbelchans & totalt",
        "name_el": "Διπλή ευκαιρία και σύνολο"
    },
    "id_873": {
        "name_en": "Trio",
        "name_aa": "Trio",
        "name_de": "Trio",
        "name_nl": "Trio",
        "name_pl": "Trio",
        "name_ro": "Trio",
        "name_ru": "Трио",
        "name_tr": "Trio",
        "name_se": "Trio",
        "name_el": "Trio"
    },
    "id_363": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} total",
        "name_aa": "{!inningnr} أدوار على {overnr} - {!deliverynr} تسليم {$competitor2} مجموعه",
        "name_de": "{!inningnr} Inning Over {overnr} - {!deliverynr} Delivery {$competitor2} Total",
        "name_nl": "{inningnr} innings over {overnr} - totaal {$competitor2} {deliverynr} delivery",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} rzut {$competitor2} suma",
        "name_ro": "{!inningnr} innings over {overnr} - delivery {!deliverynr} total {$competitor2}",
        "name_ru": "{!inningnr} иннинг {overnr} овер - {!deliverynr} подача {$competitor2} тотал",
        "name_tr": "{!inningnr}. devre {overnr}. over - {!deliverynr}. delivery {$competitor2} toplam",
        "name_se": "{!inningnr} innings över {overnr} - {!deliverynr} utdelning {$competitor2} totalt",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} σύνολο"
    },
    "id_1074": {
        "name_en": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_aa": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_de": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_nl": "{inningnr} inning - exact aantal homeruns {$competitor1}",
        "name_pl": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_ro": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_ru": "{!inningnr} иннинг - {$competitor1} точные хоум-раны",
        "name_tr": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_se": "{!inningnr} inning - {$competitor1} exact home runs",
        "name_el": "{!inningnr} inning - {$competitor1} ακριβής αριθμός home runs"
    },
    "id_158": {
        "name_en": "1st half - booking point range",
        "name_aa": "الشوط الأول - نطاق نقطة الحجز",
        "name_de": "1. Halbzeit - Strafpunktebereich",
        "name_nl": "Eerste helft - booking point range",
        "name_pl": "1. Połowa - zakres liczby punktów karnych",
        "name_ro": "Repriza 1 - interval puncte cartonașe",
        "name_ru": "1ая половина - Количество зачетных очков",
        "name_tr": "İlk yarı - kart ceza puanı aralığı",
        "name_se": "1:a halvlek - poängintervall för kot",
        "name_el": "1ο ημίχρονο - Εύρος πόντων στις καρτες"
    },
    "id_674": {
        "name_en": "{!inningnr} innings - {$competitor1} top batter",
        "name_aa": "{!inningnr} الأدوار - {$competitor1} أعلى الخليط",
        "name_de": "{!inningnr} Inning - {$competitor1} Bester Batter",
        "name_nl": "{inningnr} innings - beste slagman {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} najlepszy batter",
        "name_ro": "{!inningnr} innings - {$competitor1} top batter",
        "name_ru": "{!inningnr} иннингов - {$competitor1} лучший бэттер",
        "name_tr": "{!inningnr}. devre - {$competitor1} en iyi vurucu",
        "name_se": "{!inningnr} innings - {$competitor1} bästa slagman",
        "name_el": "{!inningnr} innings - {$competitor1} top batter"
    },
    "id_480": {
        "name_en": "{$competitor2} total tries",
        "name_aa": "{$competitor2} مجموع المحاولات",
        "name_de": "{$competitor2} Total Versuche",
        "name_nl": "Totaal aantal tries {$competitor2}",
        "name_pl": "{$competitor2} suma przyłożeń",
        "name_ro": "Total încercări {$competitor2}",
        "name_ru": "{$competitor2} тотал попыток",
        "name_tr": "{$competitor2} toplam try",
        "name_se": "{$competitor2} totalt antal försök",
        "name_el": "{$competitor2}  Σύνολο tries"
    },
    "id_862": {
        "name_en": "{$competitor2} or both teams to score",
        "name_aa": "{$competitor2} أو كلا الفريقين للتسجيل",
        "name_de": "{$competitor2} oder beide Teams werden punkten",
        "name_nl": "{$competitor2} of beide teams scoren",
        "name_pl": "{$competitor2} lub oba zespoły punktują",
        "name_ro": "{$competitor2} sau ambele echipe să înscrie",
        "name_ru": "{$competitor2} или обе команды забьют",
        "name_tr": "{$competitor2} ya da iki takımda gol atar",
        "name_se": "{$competitor2} elr båda lagen gör mål",
        "name_el": "{$competitor2} ή και οι 2 ομάδες να σκοράρουν"
    },
    "id_812": {
        "name_en": "{$event} - to reach the final",
        "name_aa": "{$event} - للوصول إلى المباراة النهائية",
        "name_de": "{$event} - wird das Finale erreichen",
        "name_nl": "{$event} - haalt de finale",
        "name_pl": "{$event} - osiągnie finał",
        "name_ro": "{$event} - să ajungă în finală",
        "name_ru": "{$event} - дойдет до финала",
        "name_tr": "{$event} - final oynar",
        "name_se": "{$event} - kommer till final",
        "name_el": "{$event} - να φτάσει τελικό"
    },
    "id_340": {
        "name_en": "Winner (incl. super over)",
        "name_aa": "الفائز (بما في ذلك السوبر)",
        "name_de": "Gewinner (inkl. Super over)",
        "name_nl": "Winnaar (super over inbegrepen)",
        "name_pl": "Zwycięzca (włącznie z super over)",
        "name_ro": "Câștigător (incl. super over)",
        "name_ru": "Победитель (включая супер овер)",
        "name_tr": "Kazanan (super over dahil)",
        "name_se": "Vinnare (inkl. super over)",
        "name_el": "Νικητής (super over συμπ.)"
    },
    "id_1127": {
        "name_en": "{!inningnr} innings - {%player1} to score {milestone1} & {%player2} to score {milestone2}",
        "name_aa": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_de": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_nl": "{!inningnr} innings - {!player1} scoort {milestone1} & {!player2} scoort {milestone2}",
        "name_pl": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_ro": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_ru": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_tr": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_se": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}",
        "name_el": "{!inningnr} innings - {!player1} to score {milestone1} & {!player2} to score {milestone2}"
    },
    "id_55": {
        "name_en": "1st/2nd half both teams to score",
        "name_aa": "الشوط الأول/ الثاني كلا الفريقين ليسجل",
        "name_de": "1. Halbzeit / 2. Halbzeit Beide Mannschaften treffen",
        "name_nl": "Beide teams scoren in beide helften",
        "name_pl": "1./2.Połowa Oba zespoły zdobędą gola",
        "name_ro": "Prima/a doua repriză ambele echipe să înscrie",
        "name_ru": "1ая/2ая половина обе команды забьют",
        "name_tr": "Her iki devrede her iki takımda gol bulur",
        "name_se": "1:a/2:a halvlek - båda lagen gör mål",
        "name_el": "1ο/2ο ημίχρονο - Να σκοράρουν και οι 2 ομάδες και στα 2 ημίχρονα"
    },
    "id_456": {
        "name_en": "{!periodnr} period - correct score",
        "name_aa": "فترة {!periodnr} - النتيجة الصحيحة",
        "name_de": "{!periodnr} Periode - Genaues Ergebnis",
        "name_nl": "{periodnr} periode - juiste score",
        "name_pl": "{!periodnr} część gry - dokładny wynik",
        "name_ro": "Repriza {!periodnr} - scor corect",
        "name_ru": "{!periodnr} период - точный счет",
        "name_tr": "{!periodnr}. periyot - doğru skor",
        "name_se": "{!periodnr} period - rätt resultat",
        "name_el": "{!periodnr} περίοδος - ακριβές σκορ"
    },
    "id_292": {
        "name_en": "Winner & total (incl. overtime)",
        "name_aa": "الفائز والإجمالي (بما في ذلك الوقت الإضافي)",
        "name_de": "Gewinner & Total (inkl. Verlängerung)",
        "name_nl": "Winnaar & totaal (verlenging inbegrepen)",
        "name_pl": "Zwycięzca & suma (włącznie z dogrywką)",
        "name_ro": "Câștigătoare & total (incl. prelungiri)",
        "name_ru": "Победитель и тотал (включая овертайм)",
        "name_tr": "Kazanan & toplam gol (uzatmalar dahil)",
        "name_se": "Vinnare & totalt (inkl. övertid)",
        "name_el": "Νικητής και σύνολο (παράταση συμπ.)"
    },
    "id_711": {
        "name_en": "{!inningnr} innings - 1x2",
        "name_aa": "{!inningnr} أدوار - 1x2",
        "name_de": "{!inningnr} Inning - 1x2",
        "name_nl": "{inningnr} innings - 1x2",
        "name_pl": "{!inningnr} innings - 1x2",
        "name_ro": "{!inningnr} innings - 1x2",
        "name_ru": "{!inningnr} иннинг - 1x2",
        "name_tr": "{!inningnr}. devre - 1x2",
        "name_se": "{!inningnr} innings - 1x2",
        "name_el": "{!inningnr} innings - 1x2"
    },
    "id_776": {
        "name_en": "Player shots",
        "name_aa": "لقطات لاعب",
        "name_de": "Spieler Schüsse",
        "name_nl": "Speler schoten",
        "name_pl": "Player shots",
        "name_ro": "Lovituri jucător",
        "name_ru": "Игрок совершивший больше ударов",
        "name_tr": "Oyuncu şutları",
        "name_se": "Spelare skott",
        "name_el": "Παίχτης σουτ"
    },
    "id_1105": {
        "name_en": "{!stopnr} pit stop (teams)",
        "name_aa": "{!stopnr} pit stop (teams)",
        "name_de": "{!stopnr} pit stop (teams)",
        "name_nl": "{stopnr}e pitstop (teams)",
        "name_pl": "{!stopnr} pit stop (teams)",
        "name_ro": "{!stopnr} pit stop (teams)",
        "name_ru": "{!stopnr} пит-стоп (команды)",
        "name_tr": "{!stopnr} pit stop (teams)",
        "name_se": "{!stopnr} pit stop (teams)",
        "name_el": "{!stopnr} πιτ στοπ (teams)"
    },
    "id_806": {
        "name_en": "{$event} - bottom {pos} finish",
        "name_aa": "{$event} - القاع {pos} النهاية",
        "name_de": "{$event} - bottom {pos} finish",
        "name_nl": "{$event} - onderste {pos} finish",
        "name_pl": "{$event} - najgorsza {pos} na koniec",
        "name_ro": "{$event} - să termine în ultimele {pos}",
        "name_ru": "{$event} - нижняя часть {pos} окончание",
        "name_tr": "{$event} - alt {pos} bitiş",
        "name_se": "{$event} - botten {pos} slut",
        "name_el": "{$event} - bottom {pos} finish"
    },
    "id_1051": {
        "name_en": "Home run on {!pitchnr} pitch",
        "name_aa": "Home run on {!pitchnr} pitch",
        "name_de": "Home run on {!pitchnr} pitch",
        "name_nl": "Homerun bij {!pitchnr} worp",
        "name_pl": "Home run on {!pitchnr} pitch",
        "name_ro": "Home run on {!pitchnr} pitch",
        "name_ru": "Хоум раны {!pitchnr} поданных подач питчером",
        "name_tr": "Home run on {!pitchnr} pitch",
        "name_se": "Homerun på {!pitchnr} pitch",
        "name_el": "Hit on {!pitchnr} pitch"
    },
    "id_705": {
        "name_en": "{!inningnr} innings - {$competitor2} total wides bowled",
        "name_aa": "{!inningnr} innings - {$competitor2} total wides bowled",
        "name_de": "{!inningnr} innings - {$competitor2} Wides gesamt gebowled",
        "name_nl": "{inningnr} innings - {$competitor2} totaal aantal wijdballen gegooid",
        "name_pl": "{!inningnr} innings - {$competitor2} total wides bowled",
        "name_ro": "{!inningnr} innings - {$competitor2} total wides bowled",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал длинных боулдов",
        "name_tr": "{!inningnr} innings - {$competitor2} total wides bowled",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal kastade wides",
        "name_el": "{!inningnr} innings - {$competitor2} total wides bowled"
    },
    "id_663": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total dismissals",
        "name_aa": "{!inningnr} أدوار تتجاوز 0 إلى {overnr} - {$competitor1} فصل تام",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor1} Dismissals gesamt",
        "name_nl": "{inningnr} innings overs 0 to {overnr} - totaal aantal dismissals {$competitor1}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor1} suma wyeliminowań",
        "name_ro": "{!inningnr} innings overs 0 la {overnr} - {$competitor1} total demiteri",
        "name_ru": "{!inningnr} иннингов оверы от 0 до {overnr} - {$competitor1} тотал выбиваний",
        "name_tr": "{!inningnr}. devre 0 a {overnr} - {$competitor1} toplam oyundan çıkarılma",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor1} totala avvisningar",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} total dismissals"
    },
    "id_1045": {
        "name_en": "{!inningnr} inning - {$competitor2} total hits",
        "name_aa": "{!inningnr} inning - {$competitor2} total hits",
        "name_de": "{!inningnr} inning - {$competitor2} total hits",
        "name_nl": "{inningnr} inning - {$competitor2} totaal aantal slagen",
        "name_pl": "{!inningnr} inning - {$competitor2} total hits",
        "name_ro": "{!inningnr} inning - {$competitor2} total hits",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал хитс",
        "name_tr": "{!inningnr} inning - {$competitor2} total hits",
        "name_se": "{!inningnr} inning - {$competitor2} totalt hits",
        "name_el": "{!inningnr} inning - {$competitor2} total hits"
    },
    "id_7": {
        "name_en": "Remaining Match",
        "name_aa": "الفريق الذي يفوز بقية المباراة",
        "name_de": "Nullstandswette",
        "name_nl": "Rest van de Match",
        "name_pl": "Który zespół wygra resztę meczu?",
        "name_ro": "Care echipă va caștiga restul meciului",
        "name_ru": "Какая команда выиграет остаток матча",
        "name_tr": "Sifir skor bahis",
        "name_se": "Vilket lag vinner resten av matchen",
        "name_el": "Ποια ομάδα θα κερδίσει το υπόλοιπο του αγώνα"
    },
    "id_410": {
        "name_en": "Handicap (incl. overtime and penalties)",
        "name_aa": "هانديكاب (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Handicap (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Handicap (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Handicap (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Handicap (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Гандикап (включая овертайм и пенальти)",
        "name_tr": "Handikap (uzatmalar ve penaltılar dahil)",
        "name_se": "Handikapp (inkl. övertid och straffar)",
        "name_el": "Χάντικαπ (παράταση και πέναλτι συμπ.)"
    },
    "id_616": {
        "name_en": "{$competitor1} odd/even (incl. overtime)",
        "name_aa": "{$competitor1} فردي / زوجي (بما في ذلك الوقت الإضافي)",
        "name_de": "{$competitor1} Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "{$competitor1} even/oneven (verlenging inbegrepen)",
        "name_pl": "{$competitor1} parzyste/nieparzyste (włącznie z dogrywką)",
        "name_ro": "{$competitor1} impar/par (incl. prelungiri)",
        "name_ru": "{$competitor1} чет/нечет (включая овертайм)",
        "name_tr": "{$competitor1} tek/çift (uzatmalar dahil)",
        "name_se": "{$competitor1} udda/jämnt (inkl. övertid)",
        "name_el": "{$competitor1} μονά/ζυγά παράταση συμπεριλαμβάνεται"
    },
    "id_184": {
        "name_en": "{!goalnr} goal & 1x2",
        "name_aa": "{!goalnr} goal & 1x2",
        "name_de": "{!goalnr} Tor & 1x2",
        "name_nl": "{goalnr} doelpuntl & 1x2",
        "name_pl": "{!goalnr} gol & 1x2",
        "name_ro": "Golul {!goalnr}  & 1x2",
        "name_ru": "{!goalnr} гол и 1х2",
        "name_tr": "{!goalnr} gol & 1x2",
        "name_se": "{!goalnr} mål & 1x2",
        "name_el": "{!goalnr} γκολ και 1Χ2"
    },
    "id_211": {
        "name_en": "{!setnr} set game {gamenr} - exact points",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - نقطة بالضبط",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Genaue Anzahl Punkte",
        "name_nl": "{setnr} set game {gamenr} - exact aantal punten",
        "name_pl": "{!setnr} set gem {gamenr} - dokładna liczba punktów",
        "name_ro": "Set {!setnr} game {gamenr} - puncte exact",
        "name_ru": "{!setnr} сет {gamenr} гейм - точное количество очков",
        "name_tr": "{!setnr}. set {gamenr}. oyun - doğru skor",
        "name_se": "{!setnr} set game {gamenr} - exakt antal poäng",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - ακριβής αριθμός πόντων"
    },
    "id_1049": {
        "name_en": "Result of {!pitchnr} pitch",
        "name_aa": "Result of {!pitchnr} pitch",
        "name_de": "Result of {!pitchnr} pitch",
        "name_nl": "Resultaat van {!pitchnr} worp",
        "name_pl": "Result of {!pitchnr} pitch",
        "name_ro": "Result of {!pitchnr} pitch",
        "name_ru": "Результат {!pitchnr} поданных подач питчером",
        "name_tr": "Result of {!pitchnr} pitch",
        "name_se": "Resultat för {!pitchnr} pitch",
        "name_el": "Result of {!pitchnr} pitch"
    },
    "id_433": {
        "name_en": "{$competitor1} highest scoring period",
        "name_aa": "{$competitor1} أعلى فترة تسجيل",
        "name_de": "{$competitor1} Periode mit den meisten Toren",
        "name_nl": "Periode met de hoogste score van {$competitor1}",
        "name_pl": "{$competitor1} najwyżej punktująca część gry",
        "name_ro": "{$competitor1} repriza cu cele mai multe înscrieri",
        "name_ru": "У {$competitor1} самый результативный период",
        "name_tr": "{$competitor1}'un en çok sayı yapacağı periyot",
        "name_se": "{$competitor1} period med högst poäng",
        "name_el": "{$competitor1} Περίοδος με υψηλότερο σκορ"
    },
    "id_56": {
        "name_en": "{$competitor1} to score in both halves",
        "name_aa": "{$competitor1} ليسجل في كلا النصفين",
        "name_de": "{$competitor1} wird beide Hälften treffen",
        "name_nl": "{$competitor1} scoort in beide helften",
        "name_pl": "{$competitor1} zdobędzie gola w obu połowach",
        "name_ro": "{$competitor1} să înscrie în ambele reprize",
        "name_ru": "{$competitor1} забьет в обеих половинах",
        "name_tr": "{$competitor1} her iki devrede de gol bulur",
        "name_se": "{$competitor1} gör mål i båda halvlekarna",
        "name_el": "{$competitor1} να σκοράρει και στα 2 ημίχρονα"
    },
    "id_203": {
        "name_en": "{!setnr} set - game handicap",
        "name_aa": "ضبط {!setnr} - لعبة هانديكاب",
        "name_de": "{!setnr} Satz - Spiel Handicap",
        "name_nl": "{setnr} set - game handicap",
        "name_pl": "{!setnr} set - gem handicap",
        "name_ro": "Set {!setnr} - handicap game-uri",
        "name_ru": "{!setnr} сет - гандикап на гейм",
        "name_tr": "{!setnr}. set - oyun handikapı",
        "name_se": "{!setnr} set - game handikapp",
        "name_el": "{!setnr} σετ - χάντικαπ γκέιμ"
    },
    "id_11": {
        "name_en": "Draw no bet",
        "name_aa": "رسم لا رهان",
        "name_de": "Unentschieden, keine Wette",
        "name_nl": "Draw no bet",
        "name_pl": "Remis bez zakładu",
        "name_ro": "Egal pariu nul",
        "name_ru": "Ничья ставки нет",
        "name_tr": "Beraberlikte iade",
        "name_se": "Insatsen tillbaka vid oavgjort",
        "name_el": "Ισοπαλία όχι στοίχημα"
    },
    "id_218": {
        "name_en": "{!setnr} set game {gamenr} - {!pointnr} point",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - {!pointnr} نقطة",
        "name_de": "{!setnr} Satz Spiel {gamenr} -  {!pointnr} Punkt",
        "name_nl": "{setnr} set game {gamenr} - {pointnr} punt",
        "name_pl": "{!setnr} set gem {gamenr} - {!pointnr} punkt",
        "name_ro": "Set {!setnr} game {gamenr} - punctul {!pointnr}",
        "name_ru": "{!setnr} сет {gamenr} гейм - {pointnr} очко",
        "name_tr": "{!setnr}. set {gamenr}. oyun - {!pointnr}. sayı",
        "name_se": "{!setnr} set game {gamenr} - {!pointnr} poäng",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - {!pointnr} πόντος"
    },
    "id_980": {
        "name_en": "{$competitor1} joy of six spread",
        "name_aa": "{$competitor1} joy of six spread",
        "name_de": "{$competitor1} joy of six spread",
        "name_nl": "{$competitor1} joy van zespunter spread",
        "name_pl": "{$competitor1} joy of six spread",
        "name_ro": "{$competitor1} joy of six spread",
        "name_ru": "{$competitor1} радость шести ранов спред",
        "name_tr": "{$competitor1} joy of six spread",
        "name_se": "{$competitor1} joy of six spread",
        "name_el": "{$competitor1} joy of six spread"
    },
    "id_3": {
        "name_en": "Which team will win the final",
        "name_aa": "الفريق الذي سيفوز في المباراة النهائية",
        "name_de": "Welches Team gewinnt das Finale",
        "name_nl": "Welk team wint de finale",
        "name_pl": "Który zespół wygra finał",
        "name_ro": "Care echipă va caștiga finala",
        "name_ru": "Какая команда выиграет финат",
        "name_tr": "Finali kazanacak takım",
        "name_se": "Vilket lag vinner finalen",
        "name_el": "Ποια ομάδα θα κερδίσει τον τελικό"
    },
    "id_501": {
        "name_en": "{!framenr} frame - total points",
        "name_aa": "إطار {framenr!}- إجمالي النقاط",
        "name_de": "{!framenr} Frame - Anzahl Punkte",
        "name_nl": "{framenr} frame - totaal aantal punten",
        "name_pl": "{!framenr} frejm - suma punktów",
        "name_ro": "Frame-ul {!framenr} - total puncte",
        "name_ru": "{!framenr} фрейм - тотал очков",
        "name_tr": "{!framenr}. frame - toplam sayılar",
        "name_se": "{!framenr} frame - totalt poäng",
        "name_el": "{!framenr} frame - Σύνολο πόντων"
    },
    "id_488": {
        "name_en": "1st half - {$competitor1} total tries",
        "name_aa": "الشوط الأول - إجمالي محاولات {$competitor1}",
        "name_de": "1. Halbzeit - {$competitor1} Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} suma przyłożeń",
        "name_ro": "Repriza 1 - {$competitor1} total încercări",
        "name_ru": "1ая половина - {$competitor1} тотал попыток",
        "name_tr": "İlk yarı - {$competitor1} toplam try",
        "name_se": "1:a halvlek - {$competitor1} totalt antal försök",
        "name_el": "1ο ημίχρονο - {$competitor1} σύνολο tries"
    },
    "id_528": {
        "name_en": "{!setnr} set - total",
        "name_aa": "{!setnr} مجموعة - الإجمالي",
        "name_de": "{!setnr} Satz - Total",
        "name_nl": "{setnr} set - totaal",
        "name_pl": "{!setnr} set - suma",
        "name_ro": "Setul {!setnr} - total",
        "name_ru": "{!setnr} сет - тотал",
        "name_tr": "{!setnr}. set - toplam",
        "name_se": "{!setnr} set - totalt",
        "name_el": "{!setnr} σετ - σύνολο"
    },
    "id_641": {
        "name_en": "{!inningnr} innings over {overnr} - {$competitor1} dismissal",
        "name_aa": "{!inningnr} أدوار أكثر من {overnr} - {$competitor1} فصل",
        "name_de": "{!inningnr} Inning Over {overnr} - {$competitor1} Dismissal",
        "name_nl": "{inningnr} innings over {overnr} - {$competitor1} dismissal",
        "name_pl": "{!inningnr} innings over {overnr} - {$competitor1} wyeliminowanie",
        "name_ro": "{!inningnr} innings over {overnr} - demitere {$competitor1}",
        "name_ru": "{!inningnr} иннингов {overnr} овер - {$competitor1} выбиваний",
        "name_tr": "{!inningnr}. devre {overnr}. over - {$competitor1} oyundan çıkarılma",
        "name_se": "{!inningnr} innings över {overnr} - {$competitor1} avvisningar",
        "name_el": "{!inningnr} innings over {overnr} - {$competitor1} dismissal"
    },
    "id_682": {
        "name_en": "Total in the highest scoring over",
        "name_aa": "المجموع في أعلى الدرجات",
        "name_de": "Total im Over mit dem höchsten Score",
        "name_nl": "Totaal van de over met de hoogste score",
        "name_pl": "Suma w najwyżej punktowanym over",
        "name_ro": "Total în over-ul cu cele mai multe înscrieri",
        "name_ru": "Тотал в самом результативном овере",
        "name_tr": "En yüksek puanı yapan",
        "name_se": "Totalt över högst poäng",
        "name_el": "Total in the highest scoring over"
    },
    "id_70": {
        "name_en": "1st half - {$competitor2} total",
        "name_aa": "النصف الأول - {$competitor2} الإجمالي",
        "name_de": "1. Halbzeit - {$competitor2} Total",
        "name_nl": "1e helft - {$competitor2} totaal",
        "name_pl": "1. Połowa - {$competitor2} Suma",
        "name_ro": "Prima repriză - {$competitor2} total",
        "name_ru": "1ая половина - тотал {$competitor2}",
        "name_tr": "İlk Yarı - {$competitor2} toplam gol",
        "name_se": "1:a halvlek - {$competitor2} totalt",
        "name_el": "1ο ημίχρονο - {$competitor2} Σύνολο Γκολ"
    },
    "id_598": {
        "name_en": "5 minutes - sending off from {from} to {to}",
        "name_aa": "5 دقائق - طرد من {from} إلى {to}",
        "name_de": "5 Minuten - Platzverweise von {from} bis {to}",
        "name_nl": "5 minutes - uitsluiting van {from} tot {to}",
        "name_pl": "5 minut - czerwona kartka od {from} do {to}",
        "name_ro": "5 minute - eliminare de la {from} la {to}",
        "name_ru": "5 минут - удаление с {from} до {to}",
        "name_tr": "5 dakika - oyundan atılma {from} dan {to} a",
        "name_se": "5 minuter - utvisning från {from} till {to}",
        "name_el": "5 λεπτά - να αποβληθεί από {from} μέχρι {to}"
    },
    "id_485": {
        "name_en": "1st half - try handicap {hcp}",
        "name_aa": "الشوط الأول - جرّب الإعاقة {hcp}",
        "name_de": "1. Halbzeit - Versuch Handicap {hcp}",
        "name_nl": "Eerste helft - try handicap {hcp}",
        "name_pl": "1. Połowa - przyłożenie handicap {hcp}",
        "name_ro": "Repriza 1 - încercări handicap {hcp}",
        "name_ru": "1ая половина - попытка гандикап {hcp}",
        "name_tr": "İlk yarı - try handikap{hcp}",
        "name_se": "1:a halvlek - försök handikapp {hcp}",
        "name_el": "1ο ημίχρονο - try {hcp} χάντικαπ"
    },
    "id_190": {
        "name_en": "{$competitor1} total games",
        "name_aa": "{$competitor1} إجمالي الألعاب",
        "name_de": "{$competitor1} Gesamtanzahl Spiele",
        "name_nl": "Totaal aantal games {$competitor1}",
        "name_pl": "{$competitor1} Suma gemów",
        "name_ro": "Total game-uri {$competitor1}",
        "name_ru": "{$competitor1} всего игр",
        "name_tr": "{$competitor1} toplam oyun",
        "name_se": "{$competitor1} totalt antal games",
        "name_el": "{$competitor1}  σύνολο γκέιμ"
    },
    "id_1099": {
        "name_en": "Total finishers",
        "name_aa": "Total finishers",
        "name_de": "Total finishers",
        "name_nl": "Totaal aantal finishers",
        "name_pl": "Total finishers",
        "name_ro": "Total finishers",
        "name_ru": "Тотал финишировавших",
        "name_tr": "Total finishers",
        "name_se": "Total finishers",
        "name_el": "Σύνολο που τερμάτισαν"
    },
    "id_786": {
        "name_en": "Pitcher earned runs (incl. extra innings)",
        "name_aa": "كسبت الجريش أشواط",
        "name_de": "Pitcher verdiente Läufe (inkl. Extra Innings)",
        "name_nl": "Werper verdiende runs",
        "name_pl": "Pitcher earned runs (incl. extra innings)",
        "name_ro": "Run-uri dobândite pitcher",
        "name_ru": "Питчер заработанные раны",
        "name_tr": "Atıcı hak edilen koşu",
        "name_se": "Pitcher förtjänta poäng",
        "name_el": "Pitcher earned runs"
    },
    "id_1024": {
        "name_en": "Hole {holenr} - 2 ball (handicap)",
        "name_aa": "Hole {holenr} - 2 ball (handicap)",
        "name_de": "Hole {holenr} - 2 ball (handicap)",
        "name_nl": "Hole {holenr} - 2 ball (handicap)",
        "name_pl": "Hole {holenr} - 2 ball (handicap)",
        "name_ro": "Hole {holenr} - 2 ball (handicap)",
        "name_ru": "Hole {holenr} - 2 ball (handicap)",
        "name_tr": "Hole {holenr} - 2 ball (handicap)",
        "name_se": "Hole {holenr} - 2 ball (handicap)",
        "name_el": "Hole {holenr} - 2 ball (handicap)"
    },
    "id_1614": {
        "name_en": "1x2 in 15 minutes",
        "name_aa": "1x2 in 15 minutes",
        "name_de": "1x2 in 15 minutes",
        "name_nl": "1x2 in 15 minutes",
        "name_pl": "1x2 in 15 minutes",
        "name_ro": "1x2 in 15 minutes",
        "name_ru": "1x2 in 15 minutes",
        "name_tr": "1x2 in 15 minutes",
        "name_se": "1x2 in 15 minutes",
        "name_el": "1x2 in 15 minutes"
    },
    "id_741": {
        "name_en": "Both teams over {total} (incl. extra innings)",
        "name_aa": "كلا الفريقين فوق {total} (بما في ذلك أدوار إضافية)",
        "name_de": "Beide Mannschaften über {total} (inkl. Extra-Innings)",
        "name_nl": "Beide teams meer dan {total} (extra innings inbegrepen)",
        "name_pl": "Oba zespoły ponad {total} (włącznie z extra innings)",
        "name_ro": "Ambele echipe peste {total} (incl. extra innings)",
        "name_ru": "Обе команд больше {total} (включая дополнительные иннинги)",
        "name_tr": "İki takımda {total} üstü (uzatmalar dahil)",
        "name_se": "Båda lagen över {total} (inkl. extra innings)",
        "name_el": "Both teams over {total} (incl. extra innings)"
    },
    "id_423": {
        "name_en": "{!goalnr} goal & winner (incl. overtime and penalties)",
        "name_aa": "{!goalnr} الهدف والفائز (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{!goalnr} Tor & Gewinner (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{goalnr} doelpunt & winnaar (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{!goalnr} gol & zwycięzca (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Golul {!goalnr} & câștigătoare (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{!goalnr} гол и победитель (включая овертайм и пенальти)",
        "name_tr": "{!goalnr}. gol ve kazanan (uzatmalar ve penaltılar dahil)",
        "name_se": "{!goalnr} mål & vinnare(inkl. övertid och straffar)",
        "name_el": "{!goalnr} γκολ και νικητής (παράταση και πέναλτι συμπ.)"
    },
    "id_402": {
        "name_en": "1st half - correct score [{score}]",
        "name_aa": "النصف الأول - النتيجة الصحيحة [{score}]",
        "name_de": "1. Halbzeit - Genaues Ergebnis [{score}]",
        "name_nl": "Eerste helft - juiste score [{score}]",
        "name_pl": "1. Połowa - Dokładny wynik [{score}]",
        "name_ro": "Repriza 1 - scor corect [{score}]",
        "name_ru": "1ая половина - точный счет [{score}]",
        "name_tr": "İlk yarı - doğru skor [{score}]",
        "name_se": "1:a halvlek - rätt resultat [{score}]",
        "name_el": "1ο ημιχρονο - Ακριβές σκορ [{score}]"
    },
    "id_367": {
        "name_en": "Total legs",
        "name_aa": "مجموع الساقين",
        "name_de": "Total Runden",
        "name_nl": "Totaal legs",
        "name_pl": "Suma legów",
        "name_ro": "Total legs",
        "name_ru": "Тотал этапов",
        "name_tr": "Toplam ayak",
        "name_se": "Totalt antal legs",
        "name_el": "Σύνολο legs"
    },
    "id_85": {
        "name_en": "2nd half - double chance",
        "name_aa": "الشوط الثاني - فرصة مزدوجة",
        "name_de": "2. Halbzeit - Doppelte Chance",
        "name_nl": "2e helft - dubbele kans",
        "name_pl": "2. Połowa - Podwójna szansa",
        "name_ro": "A 2-a repriză - șansă dublă",
        "name_ru": "2ая половина - двойной шанс",
        "name_tr": "İkinci yarı - çifte şans",
        "name_se": "2:a halvlek - dubbelchans",
        "name_el": "2ο ημίχρονο - διπλή ευκαιρία"
    },
    "id_302": {
        "name_en": "{!quarternr} quarter - draw no bet",
        "name_aa": "الربع {!quarternr} - رسم أي رهان",
        "name_de": "{!quarternr} Viertel - unentschieden, keine Wette",
        "name_nl": "{quarternr} kwart - draw no bet",
        "name_pl": "{!quarternr} kwarta - remis bez zakładu",
        "name_ro": "Sfertul {!quarternr} - egal pariu nul",
        "name_ru": "{!quarternr} четверть - ничья ставки нет",
        "name_tr": "{!quarternr}. çeyrek - beraberlikte iade",
        "name_se": "{!quarternr} quarter - insatsen tillbaka vid oavgjort",
        "name_el": "{!quarternr} περίοδος - Ισοπαλία όχι στοιχήμα"
    },
    "id_704": {
        "name_en": "{!inningnr} innings - {$competitor1} total wides bowled",
        "name_aa": "{!inningnr} innings - {$competitor1} total wides bowled",
        "name_de": "{!inningnr} innings - {$competitor1} Wides gesamt gebowled",
        "name_nl": "{inningnr} innings - {$competitor1} totaal aantal wijdballen gegooid",
        "name_pl": "{!inningnr} innings - {$competitor1} total wides bowled",
        "name_ro": "{!inningnr} innings - {$competitor1} total wides bowled",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал длинных боулдов",
        "name_tr": "{!inningnr} innings - {$competitor1} total wides bowled",
        "name_se": "{!inningnr} innings - {$competitor1} totalt antal kastade wides",
        "name_el": "{!inningnr} innings - {$competitor1} total wides bowled"
    },
    "id_263": {
        "name_en": "Run range (incl. extra innings)",
        "name_aa": "تشغيل مجموعة (بما في ذلك أدوار إضافية)",
        "name_de": "Laufbereich (inkl. Extra Innings)",
        "name_nl": "Run range (extra innings inbegrepen)",
        "name_pl": "Zakres liczby runs (włącznie z extra innings)",
        "name_ro": "Interval run-uri (incl. inning-uri extra)",
        "name_ru": "Дистанция пробежек (включая дополнительные иннинги)",
        "name_tr": "Koşu aralığı (uzatmalar dahil)",
        "name_se": "Runintervall (inkl. extra innings)",
        "name_el": "Run range  (έξτρα innings συμπ.)"
    },
    "id_611": {
        "name_en": "{!quarternr} quarter - 1x2 (incl. overtime)",
        "name_aa": "الربع {!quarternr} - 1X2 (بما في ذلك العمل الإضافي)",
        "name_de": "{!quarternr} Viertel - 1x2 (inkl. Verlängerung)",
        "name_nl": "{quarternr} kwart - 1x2 (verlenging inbegrepen)",
        "name_pl": "{!quarternr} kwarta - 1x2 (włącznie z dogrywką)",
        "name_ro": "Sfertul {!quarternr} - 1x2 (incl. prelungiri)",
        "name_ru": "{!quarternr} четверть - 1x2 (включая овертайм)",
        "name_tr": "{!quarternr}. çeyrek - 1x2 (uzatmalar dahil)",
        "name_se": "{!quarternr} quarter - 1x2 (inkl. övertid)",
        "name_el": "{!quarternr} περίοδος - 1Χ2 παράταση συμπεριλαμβάνεται"
    },
    "id_497": {
        "name_en": "Frames {fromframe} to {toframe} - {frames} consecutive frames winner",
        "name_aa": "إطارات {fromframe} إلى {toframe} - {frames} الفائز بإطارات متتالية",
        "name_de": "Frames {fromframe} bis {toframe} - {frames} aufeinanderfolgendes Frames Gewinner",
        "name_nl": "Frame {fromframe} tot {toframe} - {frames} opeenvolgende frames winnaar",
        "name_pl": "Frejmy {fromframe} do {toframe} - {frames} kolejnych frejmów zwycięzca",
        "name_ro": "Frame-urile de la {fromframe} la {toframe} - câștigător {frames} frame-uri consecutive",
        "name_ru": "Фреймы {fromframe} до {toframe} - {frames} победитель последовательных фреймов",
        "name_tr": "{fromframe} den {toframe} a frameler - arka arkaya {frames} frame kazanır",
        "name_se": "Frames {fromframe} till {toframe} - {frames} frames vinnare i följd",
        "name_el": "Frames {fromframe} σε  {toframe} - {frames} συνεχόμενα frames (νικητής)"
    },
    "id_294": {
        "name_en": "2nd half - draw no bet (incl. overtime)",
        "name_aa": "النصف الثاني - رسم أي رهان (بما في ذلك الوقت الإضافي)",
        "name_de": "2. Halbzeit - unentschieden, keine Wette (inkl. Verlängerung)",
        "name_nl": "Tweede helft - draw no bet (verlenging inbegrepen)",
        "name_pl": "2. Połowa - remis bez zakładu (włącznie z dogrywką)",
        "name_ro": "Repriza 2 - egal pariu nul (incl. prelungiri)",
        "name_ru": "2ая половина - ничья ставки нет (включая овертайм)",
        "name_tr": "İkinci yarı - beraberlikte iade (uzatmalar dahil)",
        "name_se": "2:a halvlek - insatsen tillbaka vid oavgjort(inkl. övertid)",
        "name_el": "2ο ημίχρονο - Ισοπαλία όχι στοιχήμα(παράταση συμπ.)"
    },
    "id_429": {
        "name_en": "{!periodnr} period 1x2 & 1x2",
        "name_aa": "{!periodnr} فترة 1X2 و 1X2",
        "name_de": "{!periodnr} Periode 1x2 & 1x2",
        "name_nl": "{periodnr} periode 1x2 & 1x2",
        "name_pl": "{!periodnr} część gry 1x2 & 1x2",
        "name_ro": "Repriza {!periodnr} 1x2 & 1x2",
        "name_ru": "{!periodnr} период 1x2 и 1x2",
        "name_tr": "{!periodnr}. periyot 1x2 & 1x2",
        "name_se": "{!periodnr} period 1x2 & 1x2",
        "name_el": "{!periodnr} περίοδος 1Χ2 και 1Χ2"
    },
    "id_769": {
        "name_en": "Player field goals (incl. overtime)",
        "name_aa": "مجال أهداف لاعب",
        "name_de": "Spieler Field Goals (inkl. Verlängerung)",
        "name_nl": "Speler veldgoals",
        "name_pl": "Player field goals (incl. overtime)",
        "name_ro": "Field goluri jucător",
        "name_ru": "Игрок забивший больше голов с игры",
        "name_tr": "Oyuncu saha golleri",
        "name_se": "Spelare field goal",
        "name_el": "Παίχτης field goals"
    },
    "id_288": {
        "name_en": "{!inningnr} inning - total",
        "name_aa": "{!inningnr} الشوط - الإجمالي",
        "name_de": "{!inningnr} Inning - Total",
        "name_nl": "{inningnr} inning - totaal",
        "name_pl": "{!inningnr} inning - suma",
        "name_ro": "Inning-ul {!inningnr} - total",
        "name_ru": "{!inningnr} иннинг - тотал",
        "name_tr": "{!inningnr}. devre - toplam",
        "name_se": "{!inningnr} inning - totalt",
        "name_el": "{!inningnr} inning - σύνολο"
    },
    "id_1601": {
        "name_en": "1x2 & Multigoals",
        "name_aa": "1x2 & Multigoals",
        "name_de": "1x2 & Multigoals",
        "name_nl": "1x2 & Multigoals",
        "name_pl": "1x2 & Multigoals",
        "name_ro": "1x2 & Multigoals",
        "name_ru": "1x2 & Multigoals",
        "name_tr": "1x2 & Multigoals",
        "name_se": "1x2 & Multigoals",
        "name_el": "1x2 & Multigoals"
    },
    "id_572": {
        "name_en": "10 minutes - corner 1x2 from {from} to {to}",
        "name_aa": "10 دقائق - الزاوية 1 × 2 من {from} إلى {to}",
        "name_de": "10 Minuten - Ecken 1x2 von {from} bis {to}",
        "name_nl": "10 minutes - hoekschop 1x2 van {from} tot {to}",
        "name_pl": "10 minut - rzuty rożne 1x2 od {from} do {to}",
        "name_ro": "10 minute - corner 1x2 de la {from} la {to}",
        "name_ru": "10 минут - угловой 1х2 с {from} до {to}",
        "name_tr": "10 dakika - korner 1x2 {from} den {to} a",
        "name_se": "10 minuter - hörnor 1x2 från {from} till {to}",
        "name_el": "10 λεπτά - κόρνερ 1Χ2 από {from} μέχρι {to}"
    },
    "id_770": {
        "name_en": "Player assists (incl. overtime)",
        "name_aa": "لاعب يساعد",
        "name_de": "Spieler Vorlagen (inkl. Verlängerung)",
        "name_nl": "Speler assists",
        "name_pl": "Player assists (incl. overtime)",
        "name_ro": "Asisturi jucător",
        "name_ru": "Игрок совершивший больше ассистов",
        "name_tr": "Oyuncu asistleri",
        "name_se": "Spelarassist",
        "name_el": "Παίχτης ασσίστ"
    },
    "id_550": {
        "name_en": "{$competitor2} multigoals",
        "name_aa": "{$competitor2} متعددة الأهداف",
        "name_de": "{$competitor2} Gesamtzahl der Tore im Spiel",
        "name_nl": "{$competitor2} multigoals",
        "name_pl": "{$competitor2} multi-gole",
        "name_ro": "Multigoluri {$competitor2}",
        "name_ru": "{$competitor2} мултиголы",
        "name_tr": "{$competitor2} toplam gol",
        "name_se": "{$competitor2} multimål",
        "name_el": "{$competitor2} Γκολ (πολλαπλών επιλογών)"
    },
    "id_850": {
        "name_en": "{$competitor1} to win exactly 1 set",
        "name_aa": "{$competitor1} للفوز بمجموعة واحدة بالضبط",
        "name_de": "{$competitor1} wird genau einen Satz gewinnen",
        "name_nl": "{$competitor1} wint precies 1 set",
        "name_pl": "{$competitor1} wygra dokładnie 1 set",
        "name_ro": "{$competitor1} să câștige exact 1 set",
        "name_ru": "{$competitor1} выиграть точно 1 сэт",
        "name_tr": "{$competitor1} sadece 1 set kazanır",
        "name_se": "{$competitor1} att vinna exakt 1 set",
        "name_el": "{$competitor1} να κερδίσει μόνο 1 σετ"
    },
    "id_787": {
        "name_en": "Batter total bases (incl. extra innings)",
        "name_aa": "مجموع القواعد الاساسية",
        "name_de": "Schlagmann Gesamt Bases (inkl. Extra Innings)",
        "name_nl": "Slagman totaal honken",
        "name_pl": "Batter total bases (incl. extra innings)",
        "name_ro": "Total baze batter",
        "name_ru": "Бэттер тотал базы",
        "name_tr": "Vurucu toplam base",
        "name_se": "Slagmannens totalt antal baser",
        "name_el": "Batter total bases"
    },
    "id_69": {
        "name_en": "1st half - {$competitor1} total",
        "name_aa": "النصف الأول - {$competitor1} الإجمالي",
        "name_de": "1. Halbzeit - {$competitor1} Total",
        "name_nl": "1e helft - {$competitor1} totaal",
        "name_pl": "1. Połowa - {$competitor1} Suma",
        "name_ro": "Prima repriză - {$competitor1} total",
        "name_ru": "1ая половина - тотал {$competitor1}",
        "name_tr": "İlk Yarı - {$competitor1} toplam gol",
        "name_se": "1:a halvlek - {$competitor1} totalt",
        "name_el": "1ο ημίχρονο - {$competitor1} Σύνολο Γκολ"
    },
    "id_544": {
        "name_en": "2nd half - 1x2 & total",
        "name_aa": "النصف الثاني - 1 × 2 والإجمالي",
        "name_de": "2. Halbzeit - 1x2 & Total",
        "name_nl": "Tweede helft - 1x2 & totaal",
        "name_pl": "2. Połowa - 1x2 & suma",
        "name_ro": "Repriza 2 - 1x2 & total",
        "name_ru": "2ая половина - 1х2 и тотал",
        "name_tr": "İkinci yarı - 1x2 & toplam gol",
        "name_se": "2:a halvlek - 1x2 & totalt",
        "name_el": "2ο ημίχρονο - 1Χ2 και σύνολο"
    },
    "id_425": {
        "name_en": "1x2 & both teams to score (incl. overtime and penalties)",
        "name_aa": "1x2 وكلا الفريقين يسجلان (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "1x2 & Beide Mannschaften treffen (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "1x2 & beide teams scoren (verlenging en strafschoppen inbegrepen)",
        "name_pl": "1x2 & oba zespoły strzelą (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "1x2 & ambele echipe să înscrie (incl. prelungiri și lovituri de departajare)",
        "name_ru": "1x2 и обе команды забьют (включая овертайм и пенальти)",
        "name_tr": "1x2 & karşılıklı gol var (uzatmalar ve penaltılar dahil)",
        "name_se": "1x2 & båda lagen gör mål (inkl. övertid och straffar)",
        "name_el": "1Χ2 και οι 2 ομάδες να σκοράρουν (παράταση και πέναλτι συμπ.)"
    },
    "id_164": {
        "name_en": "Last corner",
        "name_aa": "الزاوية الأخيرة",
        "name_de": "Letzte Ecke",
        "name_nl": "Laatste hoekschop",
        "name_pl": "Ostatni Rzut rożny",
        "name_ro": "Ultimul corner",
        "name_ru": "Последний угловой",
        "name_tr": "Son korner",
        "name_se": "Sista hörnan",
        "name_el": "Τελευταίο κόρνερ"
    },
    "id_20": {
        "name_en": "{$competitor2} total",
        "name_aa": "{$competitor2} مجموعه",
        "name_de": "{$competitor2} Total",
        "name_nl": "{$competitor2} totaal",
        "name_pl": "{$competitor2} Suma",
        "name_ro": "Total {$competitor2}",
        "name_ru": "{$competitor2} тотал",
        "name_tr": "{$competitor2} toplam gol",
        "name_se": "{$competitor2} totalt",
        "name_el": "Σύνολο Γκολ {$competitor2}"
    },
    "id_1033": {
        "name_en": "Holes {from} to {to} - 3 ball most birdies",
        "name_aa": "Holes {from} to {to} - 3 ball most birdies",
        "name_de": "Holes {from} to {to} - 3 ball most birdies",
        "name_nl": "Holes {from} tot {to} - 3 ball meeste birdies",
        "name_pl": "Holes {from} to {to} - 3 ball most birdies",
        "name_ro": "Holes {from} to {to} - 3 ball most birdies",
        "name_ru": "Holes {from} to {to} - 3 ball most birdies",
        "name_tr": "Holes {from} to {to} - 3 ball most birdies",
        "name_se": "Holes {from} to {to} - 3 ball most birdies",
        "name_el": "Holes {from} to {to} - 3 ball most birdies"
    },
    "id_1083": {
        "name_en": "Pitch range of {%player} {!appearancenr} time at bat",
        "name_aa": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_de": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_nl": "Worpbereik van {!player} {appearancenr} keer aan slag",
        "name_pl": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_ro": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_ru": "Питчер диапазон {!player} {!appearancenr} игрой битой",
        "name_tr": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_se": "Pitch range of {!player} {!appearancenr} time at bat",
        "name_el": "Pitch range of {!player} {!appearancenr} time at bat"
    },
    "id_1048": {
        "name_en": "Innings 1 to 5 - {$competitor2} total hits",
        "name_aa": "Innings 1 to 5 - {$competitor2} total hits",
        "name_de": "Innings 1 to 5 - {$competitor2} total hits",
        "name_nl": "Innings 1 tot 5 - {$competitor2} totaal aantal slagen",
        "name_pl": "Innings 1 to 5 - {$competitor2} total hits",
        "name_ro": "Innings 1 to 5 - {$competitor2} total hits",
        "name_ru": "Иннинги с 1 по 5 - {$competitor2} тотал хитов",
        "name_tr": "Innings 1 to 5 - {$competitor2} total hits",
        "name_se": "Inning 1 till 5 - {$competitor2} totalt antal hits",
        "name_el": "Innings 1 to 5 - {$competitor2} total hits"
    },
    "id_912": {
        "name_en": "Winner & exact rounds",
        "name_aa": "Winner & exact rounds",
        "name_de": "Winner & exact rounds",
        "name_nl": "Winnaar & exact aantal rondes",
        "name_pl": "Winner & exact rounds",
        "name_ro": "Winner & exact rounds",
        "name_ru": "Победитель и кол-во раундов",
        "name_tr": "Winner & exact rounds",
        "name_se": "Vinnare och exakt antal ronder",
        "name_el": "Νικητής και γύροι"
    },
    "id_391": {
        "name_en": "{!setnr} set leg {legnr} - any player to score a 180",
        "name_aa": "{!setnr} مجموعة الساق {legnr} - أي لاعب لتسجيل 180",
        "name_de": "{!setnr} Satz Runde {legnr} - Jeder Spieler erzielt eine 180",
        "name_nl": "{setnr} set leg {legnr} - willekeurige speler gooit een 180",
        "name_pl": "{!setnr} set leg {legnr} - którykolwiek zawodnik zdobędzie 180",
        "name_ro": "Set {!setnr} leg {legnr} - oricare jucător să înscrie un 180",
        "name_ru": "{!setnr} сет {legnr} этап - любой игрок наберёт 180",
        "name_tr": "{!setnr}. set {legnr}. ayak - herhangi bir oyuncu 180 yapar",
        "name_se": "{!setnr} set legs {legnr} - någon spelare kastar en 180:a",
        "name_el": "{!setnr} set, {legnr} leg - οποιοσδήποτε παίχτης να σκοράρει 180άρι"
    },
    "id_802": {
        "name_en": "{$event} matchday {matchday} - total draws",
        "name_aa": "{$event} {matchday} - توجه مجموعه",
        "name_de": "{$event} Spieltag {matchday} - Unentschieden Gesamt",
        "name_nl": "{$event} matchday {matchday} - totaal aantal gelijke spelen",
        "name_pl": "{$event} matchday {matchday} - suma remisów",
        "name_ro": "{$event} etapă {matchday} - total egaluri",
        "name_ru": "{$event} игровой день {matchday} - итого ничьи",
        "name_tr": "{$event} maç günü {matchday} - toplam beraberlik sayısı",
        "name_se": "{$event} - totalt oavgjorda",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο - ισοπαλία"
    },
    "id_857": {
        "name_en": "Draw or under {total}",
        "name_aa": "ارسم أو أقل من {total}",
        "name_de": "Gleichstand oder unter {total}",
        "name_nl": "Gelijkspel of onder {total}",
        "name_pl": "Remis lub poniżej {total}",
        "name_ro": "Egal sau sub {total}",
        "name_ru": "Ничья или меньше {total}",
        "name_tr": "Berabere ya da alt {total}",
        "name_se": "Oavgjort elr under {total}",
        "name_el": "Ισοπαλία ή under  {total}"
    },
    "id_525": {
        "name_en": "Will there be a 4th set",
        "name_aa": "هل سيكون هناك مجموعة 4",
        "name_de": "Wird es einen 4ten Satz geben?",
        "name_nl": "Zal er een vierde set nodig zijn",
        "name_pl": "Czy będzie 4. set?",
        "name_ro": "Va fi un al 4-lea set",
        "name_ru": "Будет ли 4ый сет",
        "name_tr": "4. set oynanıcak mı",
        "name_se": "Blir det ett 4:e set",
        "name_el": "Θα υπάρξει 4ο σετ"
    },
    "id_1061": {
        "name_en": "{!drivenr} drive play {playnr} - {%competitor} play type",
        "name_aa": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_de": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_nl": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_pl": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_ro": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_ru": "{!drivenr} драйв плей {playnr} - {!competitor} тип игры",
        "name_tr": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_se": "{!drivenr} drive play {playnr} - {!competitor} play type",
        "name_el": "{!drivenr} drive play {playnr} - {!competitor} play type"
    },
    "id_39": {
        "name_en": "Last goalscorer",
        "name_aa": "آخر هداف",
        "name_de": "Letzter Torschütze",
        "name_nl": "Laatste doelpuntenmaker",
        "name_pl": "Strzelec ostatniego gola",
        "name_ro": "Ultimul marcator",
        "name_ru": "Последний автор гола",
        "name_tr": "Son golcü",
        "name_se": "Sista målgörare",
        "name_el": "Τελευταίος σκόρερ"
    },
    "id_424": {
        "name_en": "1x2 & total (incl. overtime and penalties)",
        "name_aa": "1x2 & الإجمالي (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "1x2 & Total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "1x2 & totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "1x2 & suma (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "1x2 & total (incl. prelungiri și lovituri de departajare)",
        "name_ru": "1x2 и тотал (включая овертайм и пенальти)",
        "name_tr": "1x2 & toplam gol (uzatmalar ve penaltılar  dahil)",
        "name_se": "1x2 & totalt (inkl. övertid och straffar)",
        "name_el": "1Χ2 και Σύνολο Γκολ (παράταση και πέναλτι συμπ.)"
    },
    "id_487": {
        "name_en": "1st half - total tries",
        "name_aa": "الشوط الأول - مجموع محاولات",
        "name_de": "1. Halbzeit - Total Versuche",
        "name_nl": "Eerste helft - totaal aantal tries",
        "name_pl": "1. Połowa - suma przyłożeń",
        "name_ro": "Repriza 1 - total încercări",
        "name_ru": "1ая половина - тотал попыток",
        "name_tr": "İlk yarı - toplam try",
        "name_se": "1:a halvlek - totalt antal försök",
        "name_el": "1ο ημίχρονο -  σύνολο tries"
    },
    "id_45": {
        "name_en": "Correct score",
        "name_aa": "نتيجة صحيحة",
        "name_de": "Genaues Ergebnis",
        "name_nl": "Juiste uitslag",
        "name_pl": "Dokładny wynik",
        "name_ro": "Scor corect",
        "name_ru": "Точный счет",
        "name_tr": "Maç sonucu",
        "name_se": "Rätt resultat",
        "name_el": "Ακριβές σκορ"
    },
    "id_931": {
        "name_en": "Total touchdowns (incl. overtime)",
        "name_aa": "Total touchdowns (incl. overtime)",
        "name_de": "Touchdowns Gesamt (inkl. Verlängerung)",
        "name_nl": "Totaal aantal touchdowns (incl. verlenging)",
        "name_pl": "Total touchdowns (incl. overtime)",
        "name_ro": "Total touchdowns (incl. overtime)",
        "name_ru": "Тотал тачдауны (включая овертайм)",
        "name_tr": "Total touchdowns (incl. overtime)",
        "name_se": "Totalt antal touchdowns (inkl. övertid)",
        "name_el": "Σύνολο touchdowns (παράταση συμπ.)"
    },
    "id_358": {
        "name_en": "1st over - total",
        "name_aa": "الأول على - المجموع",
        "name_de": "1. Over - Total",
        "name_nl": "eerste over - totaal",
        "name_pl": "1. Over - suma",
        "name_ro": "Over-ul 1 - total",
        "name_ru": "1ый овер - тотал",
        "name_tr": "Birinci over - toplam",
        "name_se": "1:a över - totalt",
        "name_el": "1st over - σύνολο"
    },
    "id_664": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total dismissals",
        "name_aa": "{!inningnr} أدر المخالفات 0 إلى {overnr} - {$competitor2} فصل تام",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor2} Dismissals gesamt",
        "name_nl": "{inningnr} innings overs 0 to {overnr} - totaal aantal dismissals {$competitor2}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor2} suma wyeliminowań",
        "name_ro": "{!inningnr} innings overs 0 la {overnr} - {$competitor2} total demiteri",
        "name_ru": "{!inningnr} иннингов оверы от 0 до {overnr} - {$competitor2} тотал выбиваний",
        "name_tr": "{!inningnr}. devre 0 a {overnr} - {$competitor2} toplam oyundan çıkarılma",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor2} totala avvisningar",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} total dismissals"
    },
    "id_270": {
        "name_en": "Team to win more innings",
        "name_aa": "فريق للفوز أكثر أدوار",
        "name_de": "Team mit den meist gewonnen Innings",
        "name_nl": "Team dat meer innings wint",
        "name_pl": "Zespół z większą liczbą wygranych innings",
        "name_ro": "Echipa care câștigă mai multe inning-uri",
        "name_ru": "Команда, выиграет больше иннингов",
        "name_tr": "Daha çok devre kazanacak takım",
        "name_se": "Lag att vinna fler innings",
        "name_el": "Ομάδα που θα κερδίσει περισσότερα innings"
    },
    "id_312": {
        "name_en": "{!setnr} set - {!pointnr} point",
        "name_aa": "مجموعة {!setnr} - {!pointnr}نقطة",
        "name_de": "{!setnr} Satz - {!pointnr} Punkt",
        "name_nl": "{setnr} set - {pointnr} punt",
        "name_pl": "{!setnr} set - {!pointnr} punkt",
        "name_ro": "Set-ul {!setnr} - punctul {!pointnr}",
        "name_ru": "{!setnr} сет - {!pointnr} очко",
        "name_tr": "{!setnr}. set - {!pointnr}. sayı",
        "name_se": "{!setnr} set - {!pointnr} poäng",
        "name_el": "{!setnr} σετ - {!pointnr}  πόντος"
    },
    "id_413": {
        "name_en": "US total (incl. overtime and penalties)",
        "name_aa": "مجموع الولايات المتحدة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "US total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "US totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "US total (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Total US (incl. prelungiri și lovituri de departajare)",
        "name_ru": "США тотал (включая овертайм и пенальти)",
        "name_tr": "US toplam gol (uzatmalar ve penaltılar dahil)",
        "name_se": "US totalt (inkl. övertid och straffar)",
        "name_el": "Αμερικανικό σύνολο (παράταση και πέναλτι συμπ.)"
    },
    "id_512": {
        "name_en": "{!framenr} frame - will there be a foul committed",
        "name_aa": "إطار (! framenr) - هل سيكون هناك خطأ أم لا",
        "name_de": "{!framenr} Frame - Wird es ein Foul geben?",
        "name_nl": "{framenr} frame - wordt er een foul gemaakt",
        "name_pl": "{!framenr} frejm - czy będzie faul",
        "name_ro": "Frame-ul {!framenr} - se va comite un fault",
        "name_ru": "{!framenr} фрейм - будет ли совершён фол",
        "name_tr": "{!framenr}. frame -  fol olur mu",
        "name_se": "{!framenr} frame - blir det en foul",
        "name_el": "{!framenr} frame - θα υπάρξει φάουλ"
    },
    "id_276": {
        "name_en": "Innings 1 to 5 - total",
        "name_aa": "ألدور 1إلى5 - المجموع",
        "name_de": "Innings 1 bis 5 - Total",
        "name_nl": "Inning 1 tot 5 - totaal",
        "name_pl": "Innings 1 do 5 - suma",
        "name_ro": "Innings de la 1 la 5 - total",
        "name_ru": "Иннинги с 1 до 5 - тотал",
        "name_tr": "Devreler 1 den 5 e - toplam",
        "name_se": "Innings 1 till 5 - totalt",
        "name_el": "Innings 1 σε 5 - Σύνολο"
    },
    "id_123": {
        "name_en": "Penalty shootout - winner",
        "name_aa": "ركلات الترجيح - الفائز",
        "name_de": "Elfmeterschießen - Gewinner",
        "name_nl": "Strafschoppenserie - winnaar",
        "name_pl": "Rzuty karne - zwycięzca",
        "name_ro": "Lovituri de departajare - câștigătoare",
        "name_ru": "Серия пенальти - победитель",
        "name_tr": "Penaltı atışları - kazanan",
        "name_se": "Straffar - vinnare",
        "name_el": "Διαδικασία πέναλτι - νικητής"
    },
    "id_1108": {
        "name_en": "{!lapnr} lap - total retirements",
        "name_aa": "{!lapnr} lap - total retirements",
        "name_de": "{!lapnr} lap - total retirements",
        "name_nl": "{lapnr}e ronde - totaal aantal terugtrekkingen",
        "name_pl": "{!lapnr} lap - total retirements",
        "name_ro": "{!lapnr} lap - total retirements",
        "name_ru": "{!lapnr} круг - тотал замен шин",
        "name_tr": "{!lapnr} lap - total retirements",
        "name_se": "{!lapnr} lap - total retirements",
        "name_el": "{!lapnr} γύρος - σύνολο εγκαταλείψεων"
    },
    "id_794": {
        "name_en": "Player tries",
        "name_aa": "لاعب يحاول",
        "name_de": "Spielerversuche",
        "name_nl": "Speler tries",
        "name_pl": "Player tries",
        "name_ro": "Încercări jucător",
        "name_ru": "Игрок попыток",
        "name_tr": "Oyuncu try",
        "name_se": "Spelaren försök",
        "name_el": "Παίχτης tries"
    },
    "id_1059": {
        "name_en": "{$competitor1} {!c1scorenr} scoring type",
        "name_aa": "{$competitor1} {!c1scorenr} scoring type",
        "name_de": "{$competitor1} {!c1scorenr} scoring type",
        "name_nl": "{$competitor1} {!c1scorenr} goaltype",
        "name_pl": "{$competitor1} {!c1scorenr} typ wyniku",
        "name_ro": "{$competitor1} {!c1scorenr} scoring type",
        "name_ru": "{$competitor1} {!c1scorenr} тип счета",
        "name_tr": "{$competitor1} {!c1scorenr} scoring type",
        "name_se": "{$competitor1} {!c1scorenr} poängtypen",
        "name_el": "{$competitor1} {!c1scorenr}  τύπος σκοραρίσματος"
    },
    "id_1017": {
        "name_en": "Total birdies",
        "name_aa": "Total birdies",
        "name_de": "Birdies Gesamt",
        "name_nl": "Totaal aantal birdies",
        "name_pl": "Total birdies",
        "name_ro": "Total birdies",
        "name_ru": "Total birdies",
        "name_tr": "Total birdies",
        "name_se": "Total birdies",
        "name_el": "Total birdies"
    },
    "id_820": {
        "name_en": "Halftime/fulltime & exact goals",
        "name_aa": "نصف الوقت / بدوام كامل والأهداف الدقيقة",
        "name_de": "Halbzeit/reg. Spielzeit & Genaue Anzahl Tore",
        "name_nl": "Ruststand/eindstand & exact aantal doelpunten",
        "name_pl": "Połowa/Reg. czas gry & dokładna liczba goli",
        "name_ro": "Pauză/final & goluri exact",
        "name_ru": "После первого тайма/общее время и точное количество голов",
        "name_tr": "İlkyarı/maç sonucu & skor sayısı",
        "name_se": "Halvtid/fulltid & exakta mål",
        "name_el": "Ημίχρονο-Τελικό και ακριβής αριθμός γκολ"
    },
    "id_596": {
        "name_en": "5 minutes - booking 1x2 from {from} to {to}",
        "name_aa": "5 دقائق - حجز 1 × 2 من {from} إلى {to}",
        "name_de": "5 Minuten - Karten 1x2 von {from} bis {to}",
        "name_nl": "5 minutes - kaart 1x2 van {from} tot {to}",
        "name_pl": "5 minut - kartki 1x2 od {from} do {to}",
        "name_ro": "5 minute - cartonaș 1x2 de la {from} la {to}",
        "name_ru": "5 минут - карточки 1х2 от {from} до {to}",
        "name_tr": "5 dakika - kart 1x2 {from} dan {to} a",
        "name_se": "5 minuter - kort 1x2 från {from} till {to}",
        "name_el": "5 λεπτά - κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_1613": {
        "name_en": "1x2 in 10 minutes",
        "name_aa": "1x2 in 10 minutes",
        "name_de": "1x2 in 10 minutes",
        "name_nl": "1x2 in 10 minutes",
        "name_pl": "1x2 in 10 minutes",
        "name_ro": "1x2 in 10 minutes",
        "name_ru": "1x2 in 10 minutes",
        "name_tr": "1x2 in 10 minutes",
        "name_se": "1x2 in 10 minutes",
        "name_el": "1x2 in 10 minutes"
    },
    "id_899": {
        "name_en": "Player to score 3+",
        "name_aa": "Player to score 3+",
        "name_de": "Player to score 3+",
        "name_nl": "Speler scoort meer dan 3 keer",
        "name_pl": "Player to score 3+",
        "name_ro": "Jucătorul să înscrie 3+",
        "name_ru": "Игрок забьет 3+",
        "name_tr": "Player to score 3+",
        "name_se": "Spelare att göra 3+ mål",
        "name_el": "να σκοράρει 3+"
    },
    "id_124": {
        "name_en": "Penalty shootout - {!penaltynr} penalty scored",
        "name_aa": "ركلات الترجيح - سجل ركلت ترجيح {!penaltynr}",
        "name_de": "Elfmeterschießen - {!penaltynr} Elfmeter erzielt",
        "name_nl": "Strafschoppenserie - {penaltynr} penalty benut",
        "name_pl": "Rzuty karne - {!penaltynr} rzut karny strzelony",
        "name_ro": "Lovituri de departajare - se înscrie penaltiul {!penaltynr}",
        "name_ru": "Серия пенальти - {penaltynr} забитый пенальти",
        "name_tr": "Penaltılar - {!penaltynr} penaltı gol olur",
        "name_se": "Straffar - {!penaltynr} straffen blir mål",
        "name_el": "Διαδικασία πέναλτι - {!penaltynr} πέναλτι  εύστοχο"
    },
    "id_161": {
        "name_en": "1st half - {$competitor2} sending off",
        "name_aa": "النصف الأول - {$competitor2} طرد",
        "name_de": "1. Halbzeit - {$competitor2} Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} czerwona kartka",
        "name_ro": "Repriza 1 - eliminare {$competitor2}",
        "name_ru": "1ая половина - Удаление у {$competitor2}",
        "name_tr": "İlk yarı - {$competitor2} oyundan atılma",
        "name_se": "1:a halvlek {$competitor2} utvisning",
        "name_el": "1ο ημίχρονο -  {$competitor2} να αποβληθεί"
    },
    "id_181": {
        "name_en": "1st half - {$competitor2} exact corners",
        "name_aa": "النصف الأول - {$competitor2} من الزوايا الصحيحة",
        "name_de": "1. Halbzeit - {$competitor2} Genaue Anzahl",
        "name_nl": "Eerste helft - Exact aantal hoekschoppen {$competitor2}",
        "name_pl": "1. Połowa - {$competitor2} dokładna liczba rzutów rożnych",
        "name_ro": "Repriza 1 - cornere exact {$competitor2}",
        "name_ru": "1ая половина - точное количество угловых {$competitor2}",
        "name_tr": "İlk yarı - {$competitor2} doğru korner sayısı",
        "name_se": "1:a halvlek - {$competitor2} exakt antal hörnor",
        "name_el": "1ο ημίχρονο - {$competitor2} ακριβής αριθμός κόρνερ"
    },
    "id_732": {
        "name_en": "{!mapnr} map - race to {xth} net worth",
        "name_aa": "{!mapnr} خريطة - السباق إلى {xth} القيمة الصافية",
        "name_de": "{!mapnr} Map - Wer erreicht zuerst {xth} Net worth",
        "name_nl": "{mapnr} map - race naar {xth} net worth",
        "name_pl": "{!mapnr} mapa - pierwszy z {xth} net worth",
        "name_ro": "Harta {!mapnr} - primul la {xth} net worth",
        "name_ru": "{!mapnr} карта - гонка до {xth} общей ценности",
        "name_tr": "{!mapnr}. harita - {xth}. net worth çekişmesi",
        "name_se": "{!mapnr} karta - först till {xth} net worth",
        "name_el": "{!mapnr} map -Ποιος θα φτάσει 1ος  {xth} net worth"
    },
    "id_74": {
        "name_en": "1st half - odd/even",
        "name_aa": "الشوط الأول- فردي زوجي",
        "name_de": "1. Halbzeit - Gerade/ungerade",
        "name_nl": "1e helft - even/oneven",
        "name_pl": "1. Połowa - Parzyste/Nieparzyste",
        "name_ro": "Prima repriză - impar/par",
        "name_ru": "1ая половина - чет/нечет",
        "name_tr": "İlk Yarı - tek/çift",
        "name_se": "1:a halvlek - udda/jämnt",
        "name_el": "1ο ημίχρονο - μονά/ζυγά"
    },
    "id_37": {
        "name_en": "1x2 & total",
        "name_aa": "1x2 والإجمالي",
        "name_de": "1x2 & Total",
        "name_nl": "1x2 & totaal",
        "name_pl": "1x2 & Suma",
        "name_ro": "1x2 & total",
        "name_ru": "1х2 и тотал",
        "name_tr": "1x2 & toplam gol",
        "name_se": "1x2 & totalt",
        "name_el": "1Χ2 και Σύνολο Γκολ"
    },
    "id_856": {
        "name_en": "Draw or over {total}",
        "name_aa": "ارسم أو فوق {total}",
        "name_de": "Gleichstand oder über {total}",
        "name_nl": "Gelijkspel of boven {total}",
        "name_pl": "Remis lub ponad {total}",
        "name_ro": "Egal sau peste {total}",
        "name_ru": "Ничья или больше {total}",
        "name_tr": "Berabere ya da üst {total}",
        "name_se": "Oavgjort elr över {total}",
        "name_el": "Ισοπαλία ή over  {total}"
    },
    "id_692": {
        "name_en": "{!inningnr} innings - {$competitor1} to finish with a boundary",
        "name_aa": "{!inningnr} الأدوار - {$competitor1} لتنتهي بحدود",
        "name_de": "{!inningnr} Inning - {$competitor1} beendet mit einem Boundary",
        "name_nl": "{inningnr} innings - {$competitor1} sluit af met een boundary",
        "name_pl": "{!inningnr} innings - {$competitor1} zakończy z boundary",
        "name_ro": "{!inningnr} innings - {$competitor1} să termine cu un boundary",
        "name_ru": "{!inningnr} иннинг - {$competitor1} заканчивать с границей",
        "name_tr": "{!inningnr}. devre - {$competitor1} boundary ile bitirir",
        "name_se": "{!inningnr} innings - {$competitor1} avslutar med en boundary",
        "name_el": "{!inningnr} innings - {$competitor1} to finish with a boundary"
    },
    "id_291": {
        "name_en": "{!pointnr} point (incl. overtime)",
        "name_aa": "{!pointnr} نقطة (بما في ذلك الوقت الإضافي)",
        "name_de": "{!pointnr} Punkt (inkl. Verlängerung)",
        "name_nl": "{pointnr} punt (verlenging inbegrepen)",
        "name_pl": "{!pointnr} punkt (włącznie z dogrywką)",
        "name_ro": "Punctul {!pointnr} (incl. prelungiri)",
        "name_ru": "{!pointnr} очко (включая овертайм)",
        "name_tr": "{!pointnr}. sayı (uzatmalar dahil)",
        "name_se": "{!pointnr} poäng (inkl. övertid)",
        "name_el": "{!pointnr} πόντος (παράταση συμπ.)"
    },
    "id_409": {
        "name_en": "Winning margin (incl. overtime and penalties)",
        "name_aa": "هامش الربح (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Gewinnspanne (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Winstmarge (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Margines zwycięstwa (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Diferență victorie (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Победа с разницей (включая овертайм и пенальти)",
        "name_tr": "Kazanma farkı (uzatmalar ve penaltılar dahil)",
        "name_se": "Vinstmarginal (inkl. övertid och straffar)",
        "name_el": "Περιθώριο νίκης (παράταση και πέναλτι συμπ.)"
    },
    "id_1005": {
        "name_en": "3 ball",
        "name_aa": "3 ball",
        "name_de": "3 ball",
        "name_nl": "3 ball",
        "name_pl": "3 ball",
        "name_ro": "3 ball",
        "name_ru": "3 ball",
        "name_tr": "3 ball",
        "name_se": "3 ball",
        "name_el": "3 ball"
    },
    "id_863": {
        "name_en": "{$competitor1} or any clean sheet",
        "name_aa": "{$competitor1} أو أي ورقة نظيفة",
        "name_de": "{$competitor1} or any clean sheet",
        "name_nl": "{$competitor1} of een schoon doel",
        "name_pl": "{$competitor1} lub którykolwiek czyste konto",
        "name_ro": "{$competitor1} sau oricare să nu primească gol",
        "name_ru": "{$competitor1} или любая из команд не забьет",
        "name_tr": "{$competitor1} ya da gol yok",
        "name_se": "{$competitor1} elr något annat resultat",
        "name_el": "{$competitor1} ή μία από τις 2 ομάδες να μη δεχτεί γκολ"
    },
    "id_1122": {
        "name_en": "{$competitor2} total home runs (incl. extra innings)",
        "name_aa": "{$competitor2} total home runs (incl. extra innings)",
        "name_de": "{$competitor2} Home Runs Gesamt (inkl. Extra Innings)",
        "name_nl": "Totaal aantal homeruns {$competitor2} (incl. extra innings)",
        "name_pl": "{$competitor2} total home runs (incl. extra innings)",
        "name_ro": "{$competitor2} total home runs (incl. extra innings)",
        "name_ru": "{$competitor2} тотал хоум ранов (включая экстра иннинги)",
        "name_tr": "{$competitor2} total home runs (incl. extra innings)",
        "name_se": "{$competitor2} total home runs (incl. extra innings)",
        "name_el": "{$competitor2} Σύνολο home runs (συμπ. extra innings)"
    },
    "id_1119": {
        "name_en": "Race to {pointnr} points",
        "name_aa": "Race to {pointnr} points",
        "name_de": "Race to {pointnr} points",
        "name_nl": "Race naar {pointnr} punten",
        "name_pl": "Race to {pointnr} points",
        "name_ro": "Race to {pointnr} points",
        "name_ru": "Гонка до {pointnr} очков",
        "name_tr": "Race to {pointnr} points",
        "name_se": "Race to {pointnr} points",
        "name_el": "Ποιος θα φτάσει 1ος στους {pointnr}  πόντους"
    },
    "id_712": {
        "name_en": "Most keeper catches",
        "name_aa": "معظم صيد الحارس",
        "name_de": "Meisten Torwächter-Paraden",
        "name_nl": "Meeste keeper catches",
        "name_pl": "Najwięcej keeper catches",
        "name_ro": "Cele mai multe rețineri de keeper",
        "name_ru": "Большинство поимок вратаря",
        "name_tr": "En çok kaleci yakalayışı",
        "name_se": "Flest målvakts fångster",
        "name_el": "Most keeper catches"
    },
    "id_93": {
        "name_en": "2nd half - exact goals",
        "name_aa": "الشوط الثاني - الأهداف المحددة",
        "name_de": "2. Halbzeit - Genaue Anzahl Tore",
        "name_nl": "2e helft - exact aantal doelpunten",
        "name_pl": "2. Połowa - Dokładna liczba goli",
        "name_ro": "A 2-a repriză - goluri exact",
        "name_ru": "2ая половина - точное число голов",
        "name_tr": "ikinci yarı - doğru skor",
        "name_se": "2:a halvlek - exakt antal mål",
        "name_el": "2ο ημίχρονο - ακριβής αριθμός γκολ"
    },
    "id_160": {
        "name_en": "1st half - {$competitor1} sending off",
        "name_aa": "النصف الأول - {$competitor1} طرد",
        "name_de": "1. Halbzeit - {$competitor1} Platzverweise",
        "name_nl": "Eerste helft - uitsluitingen {$competitor1}",
        "name_pl": "1. Połowa - {$competitor1} czerwona kartka",
        "name_ro": "Repriza 1 - eliminare {$competitor1}",
        "name_ru": "1ая половина - Удаление у {$competitor1}",
        "name_tr": "İlk yarı - {$competitor1} oyundan atılma",
        "name_se": "1:a halvlek - {$competitor1} utvisning",
        "name_el": "1ο ημίχρονο -  {$competitor1} να αποβληθεί"
    },
    "id_1605": {
        "name_en": "1x2 or both teams to score",
        "name_aa": "1x2 or both teams to score",
        "name_de": "1x2 or both teams to score",
        "name_nl": "1x2 or both teams to score",
        "name_pl": "1x2 or both teams to score",
        "name_ro": "1x2 or both teams to score",
        "name_ru": "1x2 or both teams to score",
        "name_tr": "1x2 or both teams to score",
        "name_se": "1x2 or both teams to score",
        "name_el": "1x2 or both teams to score"
    },
    "id_29": {
        "name_en": "Both teams to score",
        "name_aa": "كلا الفريقين يسجل",
        "name_de": "Beide Mannschaften treffen",
        "name_nl": "Beide teams scoren",
        "name_pl": "Oba zespoły zdobędą gola",
        "name_ro": "Ambele echipe să înscrie",
        "name_ru": "Обе команды забьют",
        "name_tr": "Karşılıklı gol",
        "name_se": "Båda lagen gör mål",
        "name_el": "Να σκοράρουν και οι 2 ομάδες"
    },
    "id_733": {
        "name_en": "{!mapnr} map - race to level {xth}",
        "name_aa": "{!mapnr} خريطة - السباق نحو المستوى {xth}",
        "name_de": "{!mapnr} Map - Wer erreicht zuerst Level {xth}",
        "name_nl": "{mapnr} map - race naar level {xth}",
        "name_pl": "{!mapnr} mapa - kto pierwszy na poziomie {xth}",
        "name_ro": "Harta {!mapnr} - primul la level {xth}",
        "name_ru": "{!mapnr} карта - гонка до {xth} уровня",
        "name_tr": "{!mapnr}. harita - {xth}. level çekişmesi",
        "name_se": "{!mapnr} karta - först till level {xth}",
        "name_el": "{!mapnr} map - Ποιος θα φτάσει 1ος  level {xth}"
    },
    "id_1630": {
        "name_en": "Both team to score 2+",
        "name_aa": "Both team to score 2+",
        "name_de": "Both team to score 2+",
        "name_nl": "Both team to score 2+",
        "name_pl": "Both team to score 2+",
        "name_ro": "Both team to score 2+",
        "name_ru": "Both team to score 2+",
        "name_tr": "Both team to score 2+",
        "name_se": "Both team to score 2+",
        "name_el": "Both team to score 2+"
    },
    "id_1629": {
        "name_en": "{$competitor1} 3 goals in a row",
        "name_aa": "{$competitor1} 3 goals in a row",
        "name_de": "{$competitor1} 3 goals in a row",
        "name_nl": "{$competitor1} 3 goals in a row",
        "name_pl": "{$competitor1} 3 goals in a row",
        "name_ro": "{$competitor1} 3 goals in a row",
        "name_ru": "{$competitor1} 3 goals in a row",
        "name_tr": "{$competitor1} 3 goals in a row",
        "name_se": "{$competitor1} 3 goals in a row",
        "name_el": "{$competitor1} 3 goals in a row"
    },
    "id_414": {
        "name_en": "{$competitor1} total (incl. overtime and penalties)",
        "name_aa": "{$competitor1} الإجمالي (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor1} Total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{$competitor1} totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor1} suma (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Total {$competitor1} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor1} тотал (включая овертайм и пенальти)",
        "name_tr": "{$competitor1} toplam gol (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor1} totalt (inkl. övertid och straffar)",
        "name_el": "{$competitor1} Σύνολο Γκολ  (παράταση και πέναλτι συμπ.)"
    },
    "id_370": {
        "name_en": "{!setnr} set - which player wins the rest",
        "name_aa": "{!setnr} مجموعة - أي لاعب يفوز بقية",
        "name_de": "{!setnr} Satz - Wer gewinnt die verbleibende Spielzeit?",
        "name_nl": "{setnr} set - welke speler wint het restant",
        "name_pl": "{!setnr} set - który zawodnik wygra resztę",
        "name_ro": "Set {!setnr} - care jucător va câștiga restul",
        "name_ru": "{!setnr} сет - какой игрок выиграет остаток",
        "name_tr": "{!setnr}. set - Kalanı hangi oyuncu kazanır",
        "name_se": "{!setnr} set - vilken spelare vinner resten",
        "name_el": "{!setnr} set - ποιος θα κερδίσει το υπόλοιπο"
    },
    "id_86": {
        "name_en": "2nd half - draw no bet",
        "name_aa": "الشوط الثاني - رسم لا الرهان",
        "name_de": "2. Halbzeit - unentschieden, keine Wette",
        "name_nl": "2e helft - draw no bet",
        "name_pl": "2. Połowa - Remis bez zakładu",
        "name_ro": "A 2-a repriză - egal pariu nul",
        "name_ru": "2ая половина - ничья ставки нет",
        "name_tr": "İkinci yarı - beraberlikte iade",
        "name_se": "2:a halvlek - insatsen tillbaka vid oavgjort",
        "name_el": "2ο ημίχρονο -Ισοπαλία όχι στοιχήμα"
    },
    "id_21": {
        "name_en": "Exact goals",
        "name_aa": "الأهداف المحددة",
        "name_de": "Genaue Anzahl Tore",
        "name_nl": "Exact aantal doelpunten",
        "name_pl": "Dokładna liczba goli",
        "name_ro": "Goluri exact",
        "name_ru": "Точное число голов",
        "name_tr": "Kesin hedefler",
        "name_se": "Exakt antal mål",
        "name_el": "Ακριβής αριθμός γκολ"
    },
    "id_1063": {
        "name_en": "{!drivenr} drive play {playnr} - {%competitor} total yards gained",
        "name_aa": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_de": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_nl": "{!drivenr} drive play {playnr} - {!competitor} totaal aantal yards gewonnen",
        "name_pl": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_ro": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_ru": "{!drivenr} драйв плей {playnr} - {!competitor} тотал пройденных ярдов",
        "name_tr": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_se": "{!drivenr} drive play {playnr} - {!competitor} total yards gained",
        "name_el": "{!drivenr} drive play {playnr} - {!competitor} total yards gained"
    },
    "id_1124": {
        "name_en": "Innings 1 to 5 - Winner",
        "name_aa": "Innings 1 to 5 - Winner",
        "name_de": "Innings 1 to 5 - Winner",
        "name_nl": "Innings 1 tot 5 - Winnaar",
        "name_pl": "Innings 1 to 5 - Winner",
        "name_ro": "Innings 1 to 5 - Winner",
        "name_ru": "Иннинги с 1 по 5 - Победитель",
        "name_tr": "Innings 1 to 5 - Winner",
        "name_se": "Innings 1 to 5 - Winner",
        "name_el": "Innings 1 to 5 - Νικητής"
    },
    "id_333": {
        "name_en": "{!mapnr} map - {!xth} kill",
        "name_aa": "{!mapnr} خريطة - {!xth} قتل",
        "name_de": "{!mapnr} Map - {!xth} Kill",
        "name_nl": "{mapnr} map - {xth} kill",
        "name_pl": "{!mapnr} mapa - {!xth} zabójstwo",
        "name_ro": "Harta {!mapnr} - kill {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} убийство",
        "name_tr": "{mapnr}. harita - {xth}. kill",
        "name_se": "{!mapnr} karta - {!xth} kill",
        "name_el": "{!mapnr} map -  {!xth} kill"
    },
    "id_25": {
        "name_en": "Goal range",
        "name_aa": "نطاق الهدف",
        "name_de": "Tordifferenz",
        "name_nl": "Aantal doelpunten",
        "name_pl": "Zakres liczby goli",
        "name_ro": "Interval goluri",
        "name_ru": "Количество голов",
        "name_tr": "Gol aralığı",
        "name_se": "Målintervall",
        "name_el": "Εύρος των γκολ"
    },
    "id_840": {
        "name_en": "{!inningnr} innings - {$competitor2} total spread",
        "name_aa": "{!inningnr} innings - {$competitor2} total spread",
        "name_de": "{!inningnr} innings - {$competitor2} total spread",
        "name_nl": "{inningnr} innings - {$competitor2} total spread",
        "name_pl": "{!inningnr} innings - {$competitor2} total spread",
        "name_ro": "{!inningnr} innings - {$competitor2} total interval",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал спред",
        "name_tr": "{!inningnr} innings - {$competitor2} total spread",
        "name_se": "{!inningnr} innings - {$competitor2} total spread",
        "name_el": "{!inningnr} innings - {$competitor2} total spread"
    },
    "id_563": {
        "name_en": "Race to {pointnr} points",
        "name_aa": "سباق إلى {pointnr} نقاط",
        "name_de": "Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "Race naar {pointnr} punten",
        "name_pl": "Kto pierwszy do {pointnr} punktów",
        "name_ro": "Primul la {pointnr} puncte",
        "name_ru": "Гонка до {pointnr} очков",
        "name_tr": "{pointnr}. sayı çekişmesi",
        "name_se": "Först till {pointnr} poäng",
        "name_el": "Ποιος θα φτάσει 1ος  στους  {pointnr}  πόντους"
    },
    "id_717": {
        "name_en": "Batter head2head (handicap)",
        "name_aa": "الخليط وجها لوجه (هانديكاب)",
        "name_de": "Batter head2head (Handicap)",
        "name_nl": "Slagman head2head (handicap)",
        "name_pl": "Batter head2head (handicap)",
        "name_ro": "Confruntare directă batter (handicap)",
        "name_ru": "Бэттер очные встречи (гандикап)",
        "name_tr": "Vurucu head2head (handikap)",
        "name_se": "Slagman head2head (handikapp)",
        "name_el": "Batter head2head (handicap)"
    },
    "id_818": {
        "name_en": "Halftime/fulltime & total",
        "name_aa": "نصف الوقت / بدوام كامل ومجموع",
        "name_de": "Halbzeit/reg. Spielzeit & Total",
        "name_nl": "Ruststand/eindstand & totaal",
        "name_pl": "Połowa/Reg. czas gry & suma",
        "name_ro": "Pauză/final & total",
        "name_ru": "После первого тайма/общее время и итого",
        "name_tr": "İlkyarı/maç sonucu & toplam",
        "name_se": "Halvtid/fulltid & totalt",
        "name_el": "Ημίχρονο-Τελικό και Σύνολο"
    },
    "id_134": {
        "name_en": "Penalty shootout - winner & total",
        "name_aa": "ركلات الترجيح -فردي/ زوجي",
        "name_de": "Elfmeterschießen - Gewinner & Total",
        "name_nl": "Strafschoppenserie - winnaar & totaal",
        "name_pl": "Rzuty karne - zwycięzca & suma",
        "name_ro": "Lovituri de departajare - câștigătoare & total",
        "name_ru": "Серия пенальти - победитель и тотал",
        "name_tr": "Penaltılar - kazanan & toplam gol",
        "name_se": "Straffar - vinnare & totalt",
        "name_el": "Διαδικασία πέναλτι - Νικητής και Σύνολο Γκολ"
    },
    "id_177": {
        "name_en": "1st half - total corners",
        "name_aa": "الشوط الأول - إجمالي الزوايا",
        "name_de": "1. Halbzeit - Gesamtanzahl Ecken",
        "name_nl": "Eerste helft - totaal aantal hoekschoppen",
        "name_pl": "1. Połowa - Suma rzutów rożnych",
        "name_ro": "Repriza 1 - total cornere",
        "name_ru": "1ая половина - тотал угловых",
        "name_tr": "İlk yarı - toplam korner sayısı",
        "name_se": "1:a halvlek - totalt antal hörnor",
        "name_el": "1ο ημίχρονο - σύνολο κόρνερ"
    },
    "id_1085": {
        "name_en": "Exact balls of {%player} {!appearancenr} time at bat",
        "name_aa": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_de": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_nl": "Exact aantal wijdballen van {!player} {appearancenr} keer aan slag",
        "name_pl": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_ro": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_ru": "Точные мячи {!player} {!appearancenr} игра битой",
        "name_tr": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_se": "Exact balls of {!player} {!appearancenr} time at bat",
        "name_el": "Exact balls of {!player} {!appearancenr} time at bat"
    },
    "id_592": {
        "name_en": "10 minutes - total bookings from {from} to {to}",
        "name_aa": "10 دقائق - إجمالي الحجوزات من {from} إلى {to}",
        "name_de": "10 Minuten - Gesamtanzahl Karten von {from} bis {to}",
        "name_nl": "10 minutes - totaal aantal kaarten van {from} tot {to}",
        "name_pl": "10 minut - suma kartek od {from} do {to}",
        "name_ro": "10 minute - total cartonașe de la {from} la {to}",
        "name_ru": "10 минут - тотал карточек с {from} до {to}",
        "name_tr": "10 dakika - toplam kart {from} dan {to} a",
        "name_se": "10 minuter - totalt antal kort från {from} till {to}",
        "name_el": "10 λεπτά - σύνολο κάρτες 1Χ2 από {from} μέχρι {to}"
    },
    "id_125": {
        "name_en": "Penalty shootout - {!goalnr} goal",
        "name_aa": "ركلات الترجيح - {!goalnr} الهدف",
        "name_de": "Elfmeterschießen - {!goalnr} Tor",
        "name_nl": "Strafschoppenserie - {goalnr} doelpunt",
        "name_pl": "Rzuty karne - {!goalnr} gol",
        "name_ro": "Lovituri de departajare - golul {!goalnr}",
        "name_ru": "Серия пенальти - {!goalnr} гол",
        "name_tr": "Penaltılar - {!goalnr} gol",
        "name_se": "Straffar - {!goalnr} mål",
        "name_el": "Διαδικασία πέναλτι - {!goalnr}  γκολ"
    },
    "id_996": {
        "name_en": "Total run outs spread",
        "name_aa": "Total run outs spread",
        "name_de": "Total run outs spread",
        "name_nl": "Totaal aantal run outs spread",
        "name_pl": "Total run outs spread",
        "name_ro": "Total run outs spread",
        "name_ru": "Тотал ран-ауты спред",
        "name_tr": "Total run outs spread",
        "name_se": "Total run outs spread",
        "name_el": "Total run outs spread"
    },
    "id_1132": {
        "name_en": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_aa": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_de": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_nl": "{!inningsnr} innings over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_pl": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_ro": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_ru": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_tr": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_se": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}",
        "name_el": "{!inningsnr} innings  over {overnr} - deliveries {deliverynr1} & {deliverynr2} {$competitor1} over {total}"
    },
    "id_984": {
        "name_en": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_aa": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_de": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_nl": "{inningnr} innings - {$competitor1} totaal aantal {upsnr} ups spread",
        "name_pl": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_ro": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал {upsnr} ups спред",
        "name_tr": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_se": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread",
        "name_el": "{!inningnr} innings - {$competitor1} total {upsnr} ups spread"
    },
    "id_235": {
        "name_en": "{!quarternr} quarter - 1x2",
        "name_aa": "الربع {!quarternr} - 1X2",
        "name_de": "{!quarternr} Viertel - 1x2",
        "name_nl": "{quarternr} kwart - 1x2",
        "name_pl": "{!quarternr} kwarta - 1x2",
        "name_ro": "Sfertul {!quarternr} - 1x2",
        "name_ru": "{!quarternr} четверть - 1x2",
        "name_tr": "{!quarternr}. çeyrek - 1x2",
        "name_se": "{!quarternr} quarter - 1x2",
        "name_el": "{!quarternr} περίοδος - 1Χ2"
    },
    "id_12": {
        "name_en": "{$competitor1} no bet",
        "name_aa": "{$competitor1} لا رهان",
        "name_de": "{$competitor1} keine Wette",
        "name_nl": "{$competitor1} no bet",
        "name_pl": "{$competitor1} bez zakładu",
        "name_ro": "{$competitor1} pariu nul",
        "name_ru": "Нет ставки на {$competitor1}",
        "name_tr": "{$competitor1} kazanırsa iade",
        "name_se": "{$competitor1} inget spel",
        "name_el": "{$competitor1} Ισοπαλία όχι στοιχήμα"
    },
    "id_415": {
        "name_en": "{$competitor2} total (incl. overtime and penalties)",
        "name_aa": "{$competitor2} الإجمالي (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "{$competitor2} Total (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "{$competitor2} totaal (verlenging en strafschoppen inbegrepen)",
        "name_pl": "{$competitor2} suma (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Total {$competitor2} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "{$competitor2} тотал (включая овертайм и пенальти)",
        "name_tr": "{$competitor2} toplam gol (uzatmalar ve penaltılar dahil)",
        "name_se": "{$competitor2} totalt (inkl. övertid och straffar)",
        "name_el": "{$competitor2} Σύνολο Γκολ (παράταση και πέναλτι συμπ.)"
    },
    "id_350": {
        "name_en": "{!inningnr} innings - {$competitor2} total at {!dismissalnr} dismissal",
        "name_aa": "أدوار {!inningnr} - {$competitor2} مجموعه في فصل {dismissalnr!}",
        "name_de": "{!inningnr} Inning - {$competitor2} Total im {!dismissalnr} Dismissal",
        "name_nl": "{inningnr} innings - totaal {$competitor2} bij {dismissalnr} dismissal",
        "name_pl": "{!inningnr} innings - {$competitor2} suma przy {!dismissalnr} wyeliminowaniu",
        "name_ro": "{!inningnr} innings - total {$competitor2} la demiterea {!dismissalnr}",
        "name_ru": "{!inningnr} иннингов - {$competitor2} тотал при {!dismissalnr} выбиваний",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam {!dismissalnr}. oyundan çıkarılma",
        "name_se": "{!inningnr} innings - {$competitor2} totala vid {!dismissalnr} avvisande",
        "name_el": "{!inningnr} innings - {$competitor2} σύνολο στο {!dismissalnr} dismissal"
    },
    "id_558": {
        "name_en": "{!mapnr} map - {!xth} inhibitor",
        "name_aa": "{!mapnr}خريطة - {!xth} المانع",
        "name_de": "{!mapnr} Map - {!xth} Inhibitor",
        "name_nl": "{mapnr} map - {xth} inhibitor",
        "name_pl": "{!mapnr} mapa - {!xth} inhibitor",
        "name_ro": "Harta {!mapnr} - inhibitor {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} ингибитор",
        "name_tr": "{!mapnr}. harita- {!xth} inhibitor",
        "name_se": "{!mapnr} karta - {!xth} inhibitor",
        "name_el": "{!mapnr} map - {!xth} inhibitor"
    },
    "id_1625": {
        "name_en": "{$competitor1} 3 goals in a row",
        "name_aa": "{$competitor1} 3 goals in a row",
        "name_de": "{$competitor1} 3 goals in a row",
        "name_nl": "{$competitor1} 3 goals in a row",
        "name_pl": "{$competitor1} 3 goals in a row",
        "name_ro": "{$competitor1} 3 goals in a row",
        "name_ru": "{$competitor1} 3 goals in a row",
        "name_tr": "{$competitor1} 3 goals in a row",
        "name_se": "{$competitor1} 3 goals in a row",
        "name_el": "{$competitor1} 3 goals in a row"
    },
    "id_546": {
        "name_en": "Double chance & both teams to score",
        "name_aa": "فرصة مزدوجة وكلا الفريقين للتسجيل",
        "name_de": "Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Dubbele kans & beide teams scoren",
        "name_pl": "Podwójna szansa & oba zespoły strzelą",
        "name_ro": "Șansă dublă & ambele echipe să înscrie",
        "name_ru": "Двойной шанс и обе команды забьют",
        "name_tr": "Çifte şans & her iki takım da gol atar",
        "name_se": "Dubbelchans & båda lagen gör mål",
        "name_el": "Διπλή ευκαιρία και να σκοράρουν και οι 2 ομάδες"
    },
    "id_10": {
        "name_en": "Double chance",
        "name_aa": "فرصة مزدوجة",
        "name_de": "Doppelte Chance",
        "name_nl": "Dubbele kans",
        "name_pl": "Podwójna szansa",
        "name_ro": "Șansă dublă",
        "name_ru": "Двойной шанс",
        "name_tr": "Çifte Şans",
        "name_se": "Dubbelchans",
        "name_el": "Διπλή ευκαιρία"
    },
    "id_226": {
        "name_en": "US total (incl. overtime)",
        "name_aa": "إجمالي الولايات المتحدة (بما في ذلك الوقت الإضافي)",
        "name_de": "US Total (inkl. Verlängerung)",
        "name_nl": "US totaal (verlenging inbegrepen)",
        "name_pl": "US total (włącznie z dogrywką)",
        "name_ro": "Total US (incl. prelungiri)",
        "name_ru": "США тотал (включая овертайм)",
        "name_tr": "Us toplam gol (uzatmalar dahil)",
        "name_se": "US totalt (inkl. övertid)",
        "name_el": "Αμερικάνικο σύνολο (παράταση συμπ.)"
    },
    "id_1014": {
        "name_en": "{%competitor} total 2+ over par",
        "name_aa": "{!competitor} total 2+ over par",
        "name_de": "{!competitor} total 2+ over par",
        "name_nl": "totaal 2+ boven par {!competitor}",
        "name_pl": "{!competitor} total 2+ over par",
        "name_ro": "{!competitor} total 2+ over par",
        "name_ru": "{!competitor} total 2+ over par",
        "name_tr": "{!competitor} total 2+ over par",
        "name_se": "{!competitor} total 2+ over par",
        "name_el": "{!competitor} total 2+ over par"
    },
    "id_57": {
        "name_en": "{$competitor2} to score in both halves",
        "name_aa": "{$competitor2} ليسجل في كلا النصفين",
        "name_de": "{$competitor2} wird beide Hälften treffen",
        "name_nl": "{$competitor2} scoort in beide helften",
        "name_pl": "{$competitor2} zdobędzie gola w obu połowach",
        "name_ro": "{$competitor2} să înscrie în ambele reprize",
        "name_ru": "{$competitor2} забьет в обеих половинах",
        "name_tr": "{$competitor2} her iki devrede de gol bulur",
        "name_se": "{$competitor2} gör mål i båda halvlekarna",
        "name_el": "{$competitor2} να σκοράρει και στα 2 ημίχρονα"
    },
    "id_47": {
        "name_en": "Halftime/fulltime",
        "name_aa": "الشوط الاول / بدوام كامل",
        "name_de": "Halbzeit/reg. Spielzeit",
        "name_nl": "Rust/eindstand",
        "name_pl": "Połowa/Reg. czas gry",
        "name_ro": "Pauză/final",
        "name_ru": "После первого тайма/основного времени",
        "name_tr": "İlk yarı/Maç sonucu",
        "name_se": "Halvtid/fulltid",
        "name_el": "Ημίχρονο/Τελικό"
    },
    "id_196": {
        "name_en": "Exact sets",
        "name_aa": "مجموعات محددة",
        "name_de": "Genaue Anzahl Sätze",
        "name_nl": "Exact aantal sets",
        "name_pl": "Dokładna liczba setów",
        "name_ro": "Set-uri exact",
        "name_ru": "Точное число сетов",
        "name_tr": "Toplam set",
        "name_se": "Exakt antal set",
        "name_el": "Ακριβής αριθμός σετ"
    },
    "id_854": {
        "name_en": "{$competitor1} or over {total}",
        "name_aa": "{$competitor1} أو أكثر من {total}",
        "name_de": "{$competitor1} oder über {total}",
        "name_nl": "{$competitor1} of boven {total}",
        "name_pl": "{$competitor1} lub ponad {total}",
        "name_ro": "{$competitor1} sau peste {total}",
        "name_ru": "{$competitor1} или больше {total}",
        "name_tr": "{$competitor1} ya da üst {total}",
        "name_se": "{$competitor1} elr över {total}",
        "name_el": "{$competitor1} ή over  {total}"
    },
    "id_967": {
        "name_en": "Any team to lead by {points}",
        "name_aa": "Any team to lead by {points}",
        "name_de": "Any team to lead by {points}",
        "name_nl": "Willekeurig team staat {points} punten voor",
        "name_pl": "Any team to lead by {points}",
        "name_ro": "Any team to lead by {points}",
        "name_ru": "Любая команда лидирует на {points}",
        "name_tr": "Any team to lead by {points}",
        "name_se": "Any team to lead by {points}",
        "name_el": "Οποιαδήποτε ομάδα  να προηγείται με  {points}"
    },
    "id_1052": {
        "name_en": "Result of {!hitnr} hit",
        "name_aa": "Result of {!hitnr} hit",
        "name_de": "Result of {!hitnr} hit",
        "name_nl": "Resultaat van {!hitnr} slag",
        "name_pl": "Result of {!hitnr} hit",
        "name_ro": "Result of {!hitnr} hit",
        "name_ru": "Результат {!hitnr} хитов",
        "name_tr": "Result of {!hitnr} hit",
        "name_se": "Resultat för {!hitnr} hit",
        "name_el": "Result of {!hitnr} hit"
    },
    "id_1137": {
        "name_en": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_aa": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_de": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_nl": "{!inningnr} innings over {overnr} - {deliverynr} delivery {$competitor2} exact aantal runs",
        "name_pl": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_ro": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_ru": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_tr": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_se": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs",
        "name_el": "{!inningnr} innings over {overnr} - {!deliverynr} delivery {$competitor2} exact runs"
    },
    "id_729": {
        "name_en": "{!mapnr} map - aegis handicap",
        "name_aa": "خريطة {!mapnr} - رعاية هانديكاب",
        "name_de": "{!mapnr} Map - Aegis Handicap",
        "name_nl": "{mapnr} map - aegis handicap",
        "name_pl": "{!mapnr} mapa - aegis handicap",
        "name_ro": "Harta {!mapnr} - handicap aegis",
        "name_ru": "{!mapnr} карта - гандикап на аегис",
        "name_tr": "{!mapnr}. harita - kalkan handikapı",
        "name_se": "{!mapnr} karta - aegis handikapp",
        "name_el": "{!mapnr} map - aegis handicap"
    },
    "id_660": {
        "name_en": "{!inningnr} innings - {$competitor1} total sixes",
        "name_aa": "{!inningnr} الأدوار - {$competitor1} مجموع الستات",
        "name_de": "{!inningnr} Inning - {$competitor1} Sixes gesamt",
        "name_nl": "{inningnr} innings - totaal aantal sixes {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma szóstek",
        "name_ro": "{!inningnr} innings - {$competitor1} total sixes",
        "name_ru": "{!inningnr} иннингов - {$competitor1} тотал шести ранов",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam sixes",
        "name_se": "{!inningnr} innings - {$competitor1} totala antal sixes",
        "name_el": "{!inningnr} innings - {$competitor1} total sixes"
    },
    "id_215": {
        "name_en": "{!setnr} set game {gamenr} - correct score or break",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - النتيجة الصحيحة أو استراحة",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Genaues Ergebnis oder Break",
        "name_nl": "{setnr} set game {gamenr} - juiste score of break",
        "name_pl": "{!setnr} set gem {gamenr} - dokładny wynik lub przełamanie",
        "name_ro": "Set {!setnr} game {gamenr} - scor corect sau break",
        "name_ru": "{!setnr} сет {gamenr} гейм - точный счет или брейк",
        "name_tr": "{!setnr}. set {gamenr}. oyun - doğru skor yada break",
        "name_se": "{!setnr} set game {gamenr} - rätt resultat eller break",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ - ακριβές σκορ ή μπρέικ"
    },
    "id_892": {
        "name_en": "{!goalnr} goalscorer",
        "name_aa": "{!goalnr} هداف",
        "name_de": "{!goalnr} Torschütze",
        "name_nl": "{!goalnr} doelpuntenmaker",
        "name_pl": "{!goalnr} strzelec gola",
        "name_ro": "Marcator {!goalnr}",
        "name_ru": "{!goalnr} бомбардир",
        "name_tr": "{!goalnr} golü atan",
        "name_se": "{!goalnr} målgörare",
        "name_el": "{!goalnr} σκόρερ"
    },
    "id_1619": {
        "name_en": "Score in first 10 min",
        "name_aa": "Score in first 10 min",
        "name_de": "Score in first 10 min",
        "name_nl": "Score in first 10 min",
        "name_pl": "Score in first 10 min",
        "name_ro": "Score in first 10 min",
        "name_ru": "Score in first 10 min",
        "name_tr": "Score in first 10 min",
        "name_se": "Score in first 10 min",
        "name_el": "Score in first 10 min"
    },
    "id_532": {
        "name_en": "Correct score (in sets)",
        "name_aa": "النتيجة الصحيحة (في مجموعات)",
        "name_de": "Genaues Ergebnis (in Sätzen)",
        "name_nl": "Juiste score (in sets)",
        "name_pl": "Dokładny wynik ( w setach)",
        "name_ro": "Scor corect (în set-uri)",
        "name_ru": "Точный счет (в сетах)",
        "name_tr": "Doğru skor (setlerde)",
        "name_se": "Rätt resultat (i sets)",
        "name_el": "Ακριβές σκορ (σετ)"
    },
    "id_606": {
        "name_en": "{!inningnr} innings - {$competitor1} total",
        "name_aa": "{!inningnr} أدوار - {$competitor1} الإجمالي",
        "name_de": "{!inningnr} Inning – {$competitor1} Total",
        "name_nl": "{inningnr} innings - {$competitor1} totaal",
        "name_pl": "{!inningnr} innings - {$competitor1} suma",
        "name_ro": "{!inningnr} innings - {$competitor1} total",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam",
        "name_se": "{!inningnr} innings - {$competitor1} totalt",
        "name_el": "{!inningnr} innings –  {$competitor1}  σύνολο"
    },
    "id_627": {
        "name_en": "{!mapnr} map round {roundnr} - kill handicap",
        "name_aa": "{!mapnr} خريطة مستديرة {roundnr} - اقتل هانديكاب",
        "name_de": "{!mapnr} Map Runde {roundnr} - Kill Handicap",
        "name_nl": "{mapnr} map round {roundnr} - kill handicap",
        "name_pl": "{!mapnr} mapa runda {roundnr} - zabójstwa handicap",
        "name_ro": "Harta {!mapnr} runda {roundnr} - handicap kills",
        "name_ru": "{!mapnr} карта {roundnr} раунд - гандикап убийств",
        "name_tr": "{!mapnr}. map {roundnr}. raund -  kill handikapı",
        "name_se": "{!mapnr} kartrunda {roundnr} - kill handikapp",
        "name_el": "{!mapnr} map, γύρος {roundnr} - kill handicap"
    },
    "id_755": {
        "name_en": "{!quarternr} quarter - point range",
        "name_aa": "الربع {!quarternr} - نطاق نقطة",
        "name_de": "{!quarternr} Viertel - Strafpunktebereich",
        "name_nl": "{quarternr} kwart - punten range",
        "name_pl": "{!quarternr} kwarta - zakres liczby punktów",
        "name_ro": "Sfertul {!quarternr} - interval puncte",
        "name_ru": "{!quarternr} четверть - диапозон очков",
        "name_tr": "{!quarternr} çeyrek - sayı aralığı",
        "name_se": "{!quarternr} quarter - poängintervall",
        "name_el": "{!quarternr} περιόδος - πόντοι (range)"
    },
    "id_667": {
        "name_en": "{!inningnr} innings - {$competitor2} total extras",
        "name_aa": "{!inningnr} أدوار - {$competitor2} إجمالي الإضافات",
        "name_de": "{!inningnr} Inning - {$competitor2} Extras gesamt",
        "name_nl": "{inningnr} innings - totaal aantal extras {$competitor2}",
        "name_pl": "{!inningnr} innings - {$competitor2} suma extras",
        "name_ro": "{!inningnr} innings - {$competitor2} total extras",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал дополнительных ранов",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam ekstralar",
        "name_se": "{!inningnr} innings - {$competitor2} totalt antal extras",
        "name_el": "{!inningnr} innings - {$competitor2} total extras"
    },
    "id_1610": {
        "name_en": "Exact goals in HT/2HT",
        "name_aa": "Exact goals in HT/2HT",
        "name_de": "Exact goals in HT/2HT",
        "name_nl": "Exact goals in HT/2HT",
        "name_pl": "Exact goals in HT/2HT",
        "name_ro": "Exact goals in HT/2HT",
        "name_ru": "Exact goals in HT/2HT",
        "name_tr": "Exact goals in HT/2HT",
        "name_se": "Exact goals in HT/2HT",
        "name_el": "Exact goals in HT/2HT"
    },
    "id_486": {
        "name_en": "1st half - try handicap",
        "name_aa": "الشوط الأول - حاول إعاقة",
        "name_de": "1. Halbzeit - Versuch Handicap",
        "name_nl": "Eerste helft - try handicap",
        "name_pl": "1. Połowa - przyłożenie handicap",
        "name_ro": "Repriza 1 - încercărihandicap",
        "name_ru": "1ая половина - попытка гандикап",
        "name_tr": "İlk yarı - try handikap",
        "name_se": "1:a halvlek - försök handikapp",
        "name_el": "1ο ημίχρονο - try χάντικαπ"
    },
    "id_15": {
        "name_en": "Winning margin",
        "name_aa": "هامش الفوز",
        "name_de": "Gewinnspanne",
        "name_nl": "Winstmarge",
        "name_pl": "Margines zwycięstwa",
        "name_ro": "Diferență victorie",
        "name_ru": "Победа с разницей",
        "name_tr": "Kazanma farkı",
        "name_se": "Vinstmarginal",
        "name_el": "Περιθώριο νίκης"
    },
    "id_617": {
        "name_en": "{$competitor2} odd/even (incl. overtime)",
        "name_aa": "{$competitor2} فردي / زوجي(بما في ذلك الوقت الإضافي)",
        "name_de": "{$competitor2} Gerade/ungerade (inkl. Verlängerung)",
        "name_nl": "{$competitor2} even/oneven (verlenging inbegrepen)",
        "name_pl": "{$competitor2} parzyste/nieparzyste (włącznie z dogrywką)",
        "name_ro": "{$competitor2} impar/par (incl. prelungiri)",
        "name_ru": "{$competitor2} чет/нечет (включая овертайм)",
        "name_tr": "{$competitor2} tek/çift (uzatmalar dahil)",
        "name_se": "{$competitor2} udda/jämnt (inkl. övertid)",
        "name_el": "{$competitor2} μονά/ζυγά παράταση συμπεριλαμβάνεται"
    },
    "id_621": {
        "name_en": "{!mapnr} map - odd/even rounds (incl. overtime)",
        "name_aa": "خريطة {!mapnr} - فردي / زوجي جولات (بما في ذلك الوقت الإضافي)",
        "name_de": "{!mapnr} Map - Gerade/ungerade Runden (inkl. Verlängerung)",
        "name_nl": "{mapnr} map - even/oneven rounds (verlenging inbegrepen)",
        "name_pl": "{!mapnr} mapa - parzyste/nieparzyste rundy (włącznie z dogrywką)",
        "name_ro": "Harta {!mapnr} - număr de runde impar/par (incl. prelungiri)",
        "name_ru": "{!mapnr} карта - чет/нечет раундов (включая овертайм)",
        "name_tr": "{!mapnr}. harita - tek/çift raundlar (uzatmalar dahil)",
        "name_se": "{!mapnr} karta - udda/jämnt rundor (inkl. övertid)",
        "name_el": "{!mapnr} map -γύροι μονά/ζυγά παράταση συμπεριλαμβάνεται"
    },
    "id_982": {
        "name_en": "Total {upsnr} ups spread",
        "name_aa": "Total {upsnr} ups spread",
        "name_de": "Total {upsnr} ups spread",
        "name_nl": "Totaal aantal {upsnr} ups spread",
        "name_pl": "Total {upsnr} ups spread",
        "name_ro": "Total {upsnr} ups spread",
        "name_ru": "Total {upsnr} ups спред",
        "name_tr": "Total {upsnr} ups spread",
        "name_se": "Total {upsnr} ups spread",
        "name_el": "Total {upsnr} ups spread"
    },
    "id_490": {
        "name_en": "1st half - odd/even tries",
        "name_aa": "الشوط الأول - فردي / زوجي يحاول",
        "name_de": "1. Halbzeit - Gerade/ungerade Versuche",
        "name_nl": "Eerste helft - even/oneven aantal tries",
        "name_pl": "1. Połowa - Parzyste/Nieparzyste przyłożenia",
        "name_ro": "Repriza 1 - încercări impar/par",
        "name_ru": "1ая половина - чет/нечет попыток",
        "name_tr": "İlk yarı - tek/çift try",
        "name_se": "1:a halvlek - udda/jämnt",
        "name_el": "1ο ημίχρονο - Μονά/Ζυγά tries"
    },
    "id_149": {
        "name_en": "1st half - booking 1x2",
        "name_aa": "الشوط الأول - الحجز 1x2",
        "name_de": "1. Halbzeit - Karten 1x2",
        "name_nl": "Eerste helft - kaarten 1x2",
        "name_pl": "1. Połowa - Kartki 1x2",
        "name_ro": "Repriza 1 - cartonaș 1x2",
        "name_ru": "1ая половина - карточки 1х2",
        "name_tr": "İlk yarı - kartlar 1x2",
        "name_se": "1:a halvlek - kort 1x2",
        "name_el": "1ο ημίχρονο - κάρτες 1Χ2"
    },
    "id_789": {
        "name_en": "Player kicks",
        "name_aa": "ركلات اللاعب",
        "name_de": "Spieler Kicks",
        "name_nl": "Speler kicks",
        "name_pl": "Player kicks",
        "name_ro": "Degajări jucător",
        "name_ru": "Игрок удары",
        "name_tr": "Oyuncu kick yapar",
        "name_se": "Spelare sparkar",
        "name_el": "Παίχτης kicks"
    },
    "id_216": {
        "name_en": "{!setnr} set game {gamenr} - race to {pointnr} points",
        "name_aa": "{!setnr} مجموعة لعبة {gamenr} - سباق إلى {pointnr} نقاط",
        "name_de": "{!setnr} Satz Spiel {gamenr} - Zuerst {pointnr} Punkte",
        "name_nl": "{setnr} set game {gamenr} - race naar {pointnr} punten",
        "name_pl": "{!setnr} set gem {gamenr} - kto pierwszy do {pointnr} punktów",
        "name_ro": "Set {!setnr} game {gamenr} - primul la {pointnr} puncte",
        "name_ru": "{!setnr} сет {gamenr} гейм - гонка до {pointnr} очков",
        "name_tr": "{!setnr}. set {gamenr}. oyun - {pointnr}. sayının çekişmesi",
        "name_se": "{!setnr} set game {gamenr} - först till {pointnr} poäng",
        "name_el": "{!setnr} σετ, {gamenr} γκέιμ -Ποιος θα φτάσει 1ος τους {pointnr} πόντους"
    },
    "id_389": {
        "name_en": "{!setnr} set - {$competitor1} total 180s",
        "name_aa": "مجموعة {!setnr} - {$competitor1} إجمالي 180",
        "name_de": "{!setnr} Satz - {$competitor1} Total 180s",
        "name_nl": "{setnr} set - totaal aantal 180s {$competitor1}",
        "name_pl": "{!setnr} set - {$competitor1} suma 180s",
        "name_ro": "Set {!setnr} - total 180-uri {$competitor1}",
        "name_ru": "{!setnr} сет - {$competitor1} тотал 180ти",
        "name_tr": "{!setnr}. set - {$competitor1} toplam 180ler",
        "name_se": "{!setnr} set - {$competitor1} totalt antal 180:or",
        "name_el": "{!setnr} set - {$competitor1} σύνολο 180"
    },
    "id_1030": {
        "name_en": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_aa": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_de": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_nl": "Holes {from} tot {to} - 2 ball (1x2) meeste birdies",
        "name_pl": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_ro": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_ru": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_tr": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_se": "Holes {from} to {to} - 2 ball (1x2) most birdies",
        "name_el": "Holes {from} to {to} - 2 ball (1x2) most birdies"
    },
    "id_267": {
        "name_en": "Race to {runnr} runs (incl. extra innings)",
        "name_aa": "سباق إلى {runnr} يدير (بما في ذلك أدوار إضافية)",
        "name_de": "Wer erreicht zuerst {runnr} Läufe (inkl. Extra Innings)?",
        "name_nl": "Race naar {runnr} runs (extra innings inbegrepen)",
        "name_pl": "Kto pierwszy zdobędzie {runnr} runs (włącznie z extra innings)",
        "name_ro": "Primii la {runnr} run-uri (incl. inning-uri extra)",
        "name_ru": "Гонка до {runnr} пробежек (включая дополнительные иннинги)",
        "name_tr": "{runnr}. koşunun çekişmesi (uzatmalar dahil)",
        "name_se": "Först till {runnr} runs (inkl. extra innings)",
        "name_el": "Ποιος θα φτάσει 1ος  στα {runnr} runs  (έξτρα innings συμπ.)"
    },
    "id_668": {
        "name_en": "{!inningnr} innings - {$competitor1} total run outs",
        "name_aa": "{!inningnr} أدوار - {$competitor1} إجمالي عمليات التشغيل",
        "name_de": "{!inningnr} Inning - {$competitor1} Run outs gesamt",
        "name_nl": "{inningnr} innings - totaal aantal run outs {$competitor1}",
        "name_pl": "{!inningnr} innings - {$competitor1} suma run outs",
        "name_ro": "{!inningnr} innings - {$competitor1} total run outs",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал ран-аутов",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam dışarı koşu",
        "name_se": "{!inningnr} innings - {$competitor1} totalt antal run outs",
        "name_el": "{!inningnr} innings - {$competitor1} total run outs"
    },
    "id_166": {
        "name_en": "Total corners",
        "name_aa": "إجمالي الزوايا",
        "name_de": "Gesamtanzahl Ecken",
        "name_nl": "Totaal aantal hoekschoppen",
        "name_pl": "Suma rzutów rożnych",
        "name_ro": "Total cornere",
        "name_ru": "Тотал угловых",
        "name_tr": "Toplam korner sayısı",
        "name_se": "Totalt antal hörnor",
        "name_el": "Σύνολο κόρνερ"
    },
    "id_183": {
        "name_en": "1st half - odd/even corners",
        "name_aa": "الشوط الأول - فردي / زوجي الزوايا",
        "name_de": "1. Halbzeit -  Gerade/ungerade Ecken",
        "name_nl": "Eerste helft - even/oneven aantal hoekschoppen",
        "name_pl": "1. Połowa - Parzyste/Nieparzyste rzuty rożne",
        "name_ro": "Repriza 1 - număr impar/par de cornere",
        "name_ru": "1ая половина -  чет/нечет угловые",
        "name_tr": "İlk yarı - tek/çift kornerler",
        "name_se": "1:a halvlek - udda/jämnt hörnor",
        "name_el": "1ο ημίχρονο - μονά/ζυγά κόρνερ"
    },
    "id_545": {
        "name_en": "2nd half - double chance & both teams to score",
        "name_aa": "الشوط الثاني - فرصة مزدوجة وكلا الفريقين للتسجيل",
        "name_de": "2. Halbzeit - Doppelte Chance & Beide Mannschaften treffen",
        "name_nl": "Tweede helft - dubbele kans & beide teams scoren",
        "name_pl": "2. Połowa - Podwójna szansa & oba zespoły strzelą",
        "name_ro": "Repriza 2 -  șansă dublă & ambele echipe să înscrie",
        "name_ru": "2ая половина - двойной шанс и обе команды забьют",
        "name_tr": "İkinci yarı - Çifte şans & her iki takımda gol atar",
        "name_se": "2:a halvlek - dubbelchans & båda lagen gör mål",
        "name_el": "2ο ημίχρονο - διπλή ευκαιρία και να σκοράρουν και οι 2 ομάδες"
    },
    "id_1159": {
        "name_en": "Winner",
        "name_aa": "Winner",
        "name_de": "Winner",
        "name_nl": "Winnaar",
        "name_pl": "Winner",
        "name_ro": "Winner",
        "name_ru": "Winner",
        "name_tr": "Winner",
        "name_se": "Winner",
        "name_el": "Νικητής"
    },
    "id_835": {
        "name_en": "{$competitor1} windex",
        "name_aa": "{$competitor1} windex",
        "name_de": "{$competitor1} windex",
        "name_nl": "{$competitor1} windex",
        "name_pl": "{$competitor1} windex",
        "name_ro": "{$competitor1} windex",
        "name_ru": "{$competitor1} виндекс",
        "name_tr": "{$competitor1} windex",
        "name_se": "{$competitor1} windex",
        "name_el": "{$competitor1} windex"
    },
    "id_693": {
        "name_en": "{!inningnr} innings - {$competitor2} to finish with a boundary",
        "name_aa": "{!inningnr} أدوار - {$competitor2} لتنتهي بحدود",
        "name_de": "{!inningnr} Inning - {$competitor2} beendet mit einem Boundary",
        "name_nl": "{inningnr} innings - {$competitor2} sluit af met een boundary",
        "name_pl": "{!inningnr} innings - {$competitor2} zakończy z boundary",
        "name_ro": "{!inningnr} innings - {$competitor2} să termine cu un boundary",
        "name_ru": "{!inningnr} иннинг - {$competitor2} заканчивать с границей",
        "name_tr": "{!inningnr}. devre - {$competitor2} boundary ile bitirir",
        "name_se": "{!inningnr} innings - {$competitor2} avslutar med en boundary",
        "name_el": "{!inningnr} innings - {$competitor2} to finish with a boundary"
    },
    "id_376": {
        "name_en": "{!setnr} set leg {legnr} - total darts",
        "name_aa": "{!setnr}مجموعة الساق {legnr} - إجمالي السهام",
        "name_de": "{!setnr} Satz Runde {legnr} -  Total darts",
        "name_nl": "{setnr} set leg {legnr} - totaal darts",
        "name_pl": "{!setnr} set leg {legnr} - suma darts",
        "name_ro": "Set {!setnr} leg {legnr} - total darts",
        "name_ru": "{!setnr} сет {legnr} этап - тотал дартс",
        "name_tr": "{!setnr}. set {legnr}. ayak- toplam dart",
        "name_se": "{!setnr} set legs {legnr} - totalt dart",
        "name_el": "{!setnr} set, {legnr} leg - σύνολο darts"
    },
    "id_355": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range",
        "name_aa": "{!inningnr} أدوار المبالغ 0 إلى {overnr}- {$competitor2} رانج رانج",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor2} Laufbereich",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - run range {$competitor2}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor2} zakres liczby runs",
        "name_ro": "{!inningnr} innings overs de la 0 la {overnr} - interval run-uri {$competitor2}",
        "name_ru": "{!inningnr} иннинг оверы от 0 до {overnr} - {$competitor2} дистанция пробежек",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor2} koşu aralığı",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor2} run-intervall",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor2} run range"
    },
    "id_463": {
        "name_en": "Overtime - double chance",
        "name_aa": "الوقت الإضافي - فرصة مزدوجة",
        "name_de": "Verlängerung - Doppelte Chance",
        "name_nl": "Verlenging - dubbele kans",
        "name_pl": "Dogrywka - Podwójna szansa",
        "name_ro": "Prelungiri - șansă dublă",
        "name_ru": "Овертайм - двойной шанс",
        "name_tr": "Uzatmalar - çifte şans",
        "name_se": "Övertid - dubbelchans",
        "name_el": "Παράταση - διπλή ευκαιρία"
    },
    "id_897": {
        "name_en": "Overtime - correct score",
        "name_aa": "Overtime - correct score",
        "name_de": "Overtime - correct score",
        "name_nl": "Extra tijd- juiste score",
        "name_pl": "Overtime - correct score",
        "name_ro": "Prelungiri - scor corect",
        "name_ru": "Овертайм - правильный счёт",
        "name_tr": "Overtime - correct score",
        "name_se": "Övertid - rätt resultat",
        "name_el": "παράταση - ακριβές σκορ"
    },
    "id_14": {
        "name_en": "Handicap",
        "name_aa": "هانديكاب",
        "name_de": "Handicap",
        "name_nl": "Handicap",
        "name_pl": "Handicap",
        "name_ro": "Handicap",
        "name_ru": "Гандикап",
        "name_tr": "Handikap",
        "name_se": "Handikapp",
        "name_el": "χάντικαπ"
    },
    "id_772": {
        "name_en": "Player rebounds (incl. overtime)",
        "name_aa": "لاعب كرة مرتدة",
        "name_de": "Spieler Rebounds (inkl. Verlängerung)",
        "name_nl": "Speler rebounds",
        "name_pl": "Player rebounds (incl. overtime)",
        "name_ro": "Rebound-uri jucător",
        "name_ru": "Игрок сделавший больше ребаудов",
        "name_tr": "Oyuncu reboundları",
        "name_se": "Spelarreturer",
        "name_el": "Παίχτης ριμπάουντ"
    },
    "id_72": {
        "name_en": "1st half - {$competitor1} exact goals",
        "name_aa": "النصف الأول - {$competitor1} أهداف دقيقة",
        "name_de": "1. Halbzeit - {$competitor1} Genaue Anzahl Tore",
        "name_nl": "1e helft - {$competitor1} exact aantal doelpunten",
        "name_pl": "1. Połowa - {$competitor1} Dokładna liczba goli",
        "name_ro": "Prima repriză - goluri exact {$competitor1}",
        "name_ru": "1ая половина - {$competitor1} точное число голов",
        "name_tr": "ilk yarı - {$competitor1} gol sayısı",
        "name_se": "1:a halvlek - {$competitor1} exakt antal mål",
        "name_el": "1ο ημίχρονο - {$competitor1} ακριβής αριθμός γκολ"
    },
    "id_784": {
        "name_en": "Batter runs (incl. extra innings)",
        "name_aa": "يدير الخليط",
        "name_de": "Schlagmann Läufe (inkl. Extra Innings)",
        "name_nl": "Slagman runs",
        "name_pl": "Batter runs (incl. extra innings)",
        "name_ro": "Run-uri batter",
        "name_ru": "Бэттер раны",
        "name_tr": "Vurucu koşar",
        "name_se": "Slagman runs",
        "name_el": "Batter runs"
    },
    "id_225": {
        "name_en": "Total (incl. overtime)",
        "name_aa": "الإجمالي (بما في ذلك العمل الإضافي)",
        "name_de": "Total (inkl. Verlängerung)",
        "name_nl": "Totaal (verlenging inbegrepen)",
        "name_pl": "Suma (włącznie z dogrywką)",
        "name_ro": "Total (incl. prelungiri)",
        "name_ru": "Тотал (включая овертайм)",
        "name_tr": "Toplam (uzatmalar dahil)",
        "name_se": "Totalt (inkl. övertid)",
        "name_el": "Σύνολο (παράταση συμπ.)"
    },
    "id_581": {
        "name_en": "5 minutes - corner handicap from {from} to {to}",
        "name_aa": "5 دقائق - هانديكاب في الزاوية من {from} إلى {to}",
        "name_de": "5 Minuten - Ecken Handicap von {from} bis {to}",
        "name_nl": "5 minutes - hoekschop handicap van {from} tot {to}",
        "name_pl": "5 minut - rzut rożny handicap od {from} do {to}",
        "name_ro": "5 minute - handicap cornere de la {from} la {to}",
        "name_ru": "5 минут - угловой гандикап с {from} до {to}",
        "name_tr": "5 dakika - korner handikap {from} dan {to} a",
        "name_se": "5 minuter - hörnor handikapp från {from} till {to}",
        "name_el": "5  λεπτά - κόρνερ χάντικαπ από {from} μέχρι {to}"
    },
    "id_868": {
        "name_en": "Place",
        "name_aa": "Place",
        "name_de": "Place",
        "name_nl": "Plaats",
        "name_pl": "Place",
        "name_ro": "Loc",
        "name_ru": "Место",
        "name_tr": "Place",
        "name_se": "Plats",
        "name_el": "Θέση"
    },
    "id_762": {
        "name_en": "Player carries (incl. overtime)",
        "name_aa": "لاعب يحمل",
        "name_de": "Spieler Carries (inkl. Verlängerung)",
        "name_nl": "Speler carries",
        "name_pl": "Player carries (incl. overtime)",
        "name_ro": "Carries jucător",
        "name_ru": "Игрок сделавший больше выносов мяча",
        "name_tr": "Oyuncu taşıyor",
        "name_se": "Spelare - carries",
        "name_el": "Παίχτης carries"
    },
    "id_354": {
        "name_en": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range",
        "name_aa": "{!inningnr} أدوار المبالغ 0 إلى {overnr}- {$competitor1} رانج رانج",
        "name_de": "{!inningnr} Inning Overs null zu {overnr} - {$competitor1} Laufbereich",
        "name_nl": "{inningnr} innings overs 0 tot {overnr} - run range {$competitor1}",
        "name_pl": "{!inningnr} innings overs 0 do {overnr} - {$competitor1} zakres liczby runs",
        "name_ro": "{!inningnr} innings overs de la 0 la {overnr} - interval run-uri {$competitor1}",
        "name_ru": "{!inningnr} иннинг оверы от 0 до {overnr} - {$competitor1} дистанция пробежек",
        "name_tr": "{!inningnr}. devre 0 a {overnr}. over -{$competitor1} koşu aralığı",
        "name_se": "{!inningnr} innings över 0 till {overnr} - {$competitor1} run-intervall",
        "name_el": "{!inningnr} innings overs 0 to {overnr} - {$competitor1} run range"
    },
    "id_747": {
        "name_en": "{!inningnr} inning - {$competitor1} total",
        "name_aa": "{!inningnr} الشوط - {$competitor1} الإجمالي",
        "name_de": "{!inningnr} Inning - {$competitor1} Total",
        "name_nl": "{inningnr} inning - {$competitor1} totaal",
        "name_pl": "{!inningnr} inning - {$competitor1} suma",
        "name_ro": "{!inningnr} inning - {$competitor1} total",
        "name_ru": "{!inningnr} иннинг - {$competitor1} тотал",
        "name_tr": "{!inningnr}. devre - {$competitor1} toplam",
        "name_se": "{!inningnr} inning - {$competitor1} totalt",
        "name_el": "{!inningnr} inning - {$competitor1} total"
    },
    "id_1006": {
        "name_en": "2 ball (1x2)",
        "name_aa": "2 ball (1x2)",
        "name_de": "2 ball (1x2)",
        "name_nl": "2 ball (1x2)",
        "name_pl": "2 ball (1x2)",
        "name_ro": "2 ball (1x2)",
        "name_ru": "2 ball (1x2)",
        "name_tr": "2 ball (1x2)",
        "name_se": "2 ball (1x2)",
        "name_el": "2 ball (1x2)"
    },
    "id_476": {
        "name_en": "Try handicap {hcp}",
        "name_aa": "حاول هانديكاب {hcp}",
        "name_de": "Versuch Handicap {hcp}",
        "name_nl": "Try handicap {hcp}",
        "name_pl": "Przyłożenie handicap {hcp}",
        "name_ro": "Încercări handicap {hcp}",
        "name_ru": "Попытка гандикап {hcp}",
        "name_tr": "{hcp} handikap dene",
        "name_se": "Försök handikapp {hcp}",
        "name_el": "Try {hcp} χάντικαπ"
    },
    "id_347": {
        "name_en": "{!inningnr} innings - team with highest scoring over",
        "name_aa": "{!inningnr} أدوار - فريق مع أعلى التهديف أكثر",
        "name_de": "{!inningnr} Inning - Welches Team gewinnt das Over mit den meisten Punkten",
        "name_nl": "{inningnr} innings - team met de over met hoogste score",
        "name_pl": "{!inningnr} innings - zespół z najwyżej punktującym over",
        "name_ro": "{!inningnr} innings - echipa cu care înscrie cel mai mult într-un over",
        "name_ru": "{!inningnr} иннинг - команда с наивысшим результатом",
        "name_tr": "{!inningnr}. devre - en çok skor üstü yapan takım",
        "name_se": "{!inningnr} innings - lag med högst poäng över",
        "name_el": "{!inningnr} innings - ομάδα με το υψηλότερο over"
    },
    "id_122": {
        "name_en": "Will there be a penalty shootout",
        "name_aa": "سيكون هناك ركلات الترجيح",
        "name_de": "Wird es ein Elfmeterschießen geben?",
        "name_nl": "Zullen er strafschoppen nodig zijn",
        "name_pl": "Czy będzie konkurs rzutów karnych?",
        "name_ro": "Vor fi lovituri de departajare",
        "name_ru": "Будет ли серия пенальти",
        "name_tr": "Penaltı atışları olacak mı",
        "name_se": "Blir det straffar",
        "name_el": "Θα υπάρξει διαδικασία πέναλτι"
    },
    "id_474": {
        "name_en": "Try double chance",
        "name_aa": "حاول فرصة مزدوجة",
        "name_de": "Versuch Doppelte Chance",
        "name_nl": "Try dubbele kans",
        "name_pl": "Przyłożenie podwójna szansa",
        "name_ro": "Încercări șansă dublă",
        "name_ru": "Попытка двойной шанс",
        "name_tr": "Çifte şans dene",
        "name_se": "Försök dubbelchans",
        "name_el": "Try διπλή ευκαιρία"
    },
    "id_162": {
        "name_en": "Corner 1x2",
        "name_aa": "الزاوية 1x2",
        "name_de": "Ecken 1x2",
        "name_nl": "Hoekschop 1x2",
        "name_pl": "Rzut rożny 1x2",
        "name_ro": "Corner 1x2",
        "name_ru": "Угловые 1х2",
        "name_tr": "Korner 1x2",
        "name_se": "Hörnor 1x2",
        "name_el": "Κόρνερ 1Χ2"
    },
    "id_575": {
        "name_en": "10 minutes - total corners from {from} to {to}",
        "name_aa": "10 دقائق - الزوايا الكلية من {from} إلى {to}",
        "name_de": "10 Minuten - Gesamtanzahl Ecken von {from} bis {to}",
        "name_nl": "10 minutes - totaal aantal hoekschoppen van {from} tot {to}",
        "name_pl": "10 minut - suma rzutów rożnych od {from} do {to}",
        "name_ro": "10 minute - total cornere de la {from} la {to}",
        "name_ru": "10 минут - тотал угловых с {from} до {to}",
        "name_tr": "10 dakika - toplam korner sayısı {from} dan {to} a",
        "name_se": "10 minuter - totalt antal hörnor från {from} till {to}",
        "name_el": "10 λεπτά - σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_1065": {
        "name_en": "{!drivenr} drive play {playnr} - {%competitor} sack",
        "name_aa": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_de": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_nl": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_pl": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_ro": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_ru": "{!drivenr} драйв плей {playnr} - {!competitor} баз",
        "name_tr": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_se": "{!drivenr} drive play {playnr} - {!competitor} sack",
        "name_el": "{!drivenr} drive play {playnr} - {!competitor} sack"
    },
    "id_1034": {
        "name_en": "Holes {from} to {to} - 3 ball most pars",
        "name_aa": "Holes {from} to {to} - 3 ball most pars",
        "name_de": "Holes {from} to {to} - 3 ball most pars",
        "name_nl": "Holes {from} tot {to} - 3 ball meeste pars",
        "name_pl": "Holes {from} to {to} - 3 ball most pars",
        "name_ro": "Holes {from} to {to} - 3 ball most pars",
        "name_ru": "Holes {from} to {to} - 3 ball most pars",
        "name_tr": "Holes {from} to {to} - 3 ball most pars",
        "name_se": "Holes {from} to {to} - 3 ball most pars",
        "name_el": "Holes {from} to {to} - 3 ball most pars"
    },
    "id_373": {
        "name_en": "{!setnr} set - odd/even legs",
        "name_aa": "مجموعة {!setnr} -فردي / زوجي الساقين",
        "name_de": "{!setnr} Satz - Gerade/ungerade Runden",
        "name_nl": "{setnr} set - even/oneven legs",
        "name_pl": "{!setnr} set - Parzyste/Nieparzyste legi",
        "name_ro": "Set {!setnr} - legs impar/par",
        "name_ru": "{!setnr} сет - этап чет/нечет",
        "name_tr": "{!setnr}. set - tek/çift ayak",
        "name_se": "{!setnr} set - udda/jämnt legs",
        "name_el": "{!setnr} set - μονά/ζυγά legs"
    },
    "id_313": {
        "name_en": "{!setnr} set - race to {pointnr} points",
        "name_aa": "ضبط {!setnr} - سباق إلى {pointnr} نقاط",
        "name_de": "{!setnr} Satz -  Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "{setnr} set - race naar {pointnr} punten",
        "name_pl": "{!setnr} set - kto pierwszy do {pointnr} punktów",
        "name_ro": "Set-ul {!setnr} - primul la {pointnr} puncte",
        "name_ru": "{!setnr} сет - гонка до {pointnr} очков",
        "name_tr": "{!setnr}. set - {pointnr}. sayının çekişmesi",
        "name_se": "{!setnr} set - först till {pointnr} poäng",
        "name_el": "{!setnr} σετ - Ποιος θα φτάσει 1ος στους {pointnr} πόντους"
    },
    "id_590": {
        "name_en": "15 minutes - {$competitor2} sending off from {from} to {to}",
        "name_aa": "15 دقيقة - {$competitor2} طرد من {from} إلى {to}",
        "name_de": "15 Minuten - {$competitor2} Platzverweise von {from} bis {to}",
        "name_nl": "15 minutes - uitsluiting {$competitor2} van {from} tot {to}",
        "name_pl": "15 minut - {$competitor2} czerwona kartka od {from} do {to}",
        "name_ro": "15 minute - {$competitor2} eliminare de la {from} la {to}",
        "name_ru": "15 минут - {$competitor2} удаление с {from} до {to}",
        "name_tr": "15 dakika - {$competitor2} oyundan atılma {from} dan {to} a",
        "name_se": "15 minuter - {$competitor2} utvisning från {from} till {to}",
        "name_el": "15 λεπτά - {$competitor2}  να αποβληθεί από {from} μέχρι {to}"
    },
    "id_646": {
        "name_en": "Team with highest score at {!dismissalnr} dismissal",
        "name_aa": "فريق بأعلى الدرجات عند الفصل {!dismissalnr}",
        "name_de": "Team mit höchster Punktzahl {!dismissalnr} Dismissal",
        "name_nl": "Team met de hoogste score bij de {dismissalnr} dismissal",
        "name_pl": "Zespół z najwyższym wynikiem przy {!dismissalnr} wyeliminowaniu",
        "name_ro": "Echipa cu cel mai bun scor la demiterea {!dismissalnr}",
        "name_ru": "Команда с наивысшим результатом при {!dismissalnr} выбиваний",
        "name_tr": "Görevden almada en yüksek puanı alan takım {!dismissalnr}",
        "name_se": "Lag med flest kills vid {!dismissalnr} avvisande",
        "name_el": "Ομάδα με το υψηλότερο σκορ σε {!dismissalnr} dismissal"
    },
    "id_259": {
        "name_en": "US Total (incl. extra innings)",
        "name_aa": "الولايات المتحدة إجمالي (بما في ذلك أدوار إضافية)",
        "name_de": "US Total (inkl. Extra Innings)",
        "name_nl": "US totaal (extra innings inbegrepen)",
        "name_pl": "US Total (włącznie z extra innings)",
        "name_ro": "Total US (incl. inning-uri extra)",
        "name_ru": "США тотал (включая дополнительные иннинги)",
        "name_tr": "US total (uzatma devreleri dahil)",
        "name_se": "US totalt (inkl. extra innings)",
        "name_el": "Αμερικανικό σύνολο (έξτρα innings συμπ.)"
    },
    "id_1149": {
        "name_en": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_aa": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_de": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_nl": "{overnr} over - {$competitor2} totaal ({ballcount} ball)",
        "name_pl": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_ro": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_ru": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_tr": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_se": "{!overnr} over - {$competitor2} total ({ballcount} ball)",
        "name_el": "{!overnr} over - {$competitor2} total ({ballcount} ball)"
    },
    "id_477": {
        "name_en": "Try handicap",
        "name_aa": "حاول هانديكاب",
        "name_de": "Versuch Handicap",
        "name_nl": "Try handicap",
        "name_pl": "Przyłożenie handicap",
        "name_ro": "Încercări handicap",
        "name_ru": "Попытка гандикап",
        "name_tr": "Handikap dene",
        "name_se": "Försök handikapp",
        "name_el": "Try χάντικαπ"
    },
    "id_1628": {
        "name_en": "Any 2 goals in a row",
        "name_aa": "Any 2 goals in a row",
        "name_de": "Any 2 goals in a row",
        "name_nl": "Any 2 goals in a row",
        "name_pl": "Any 2 goals in a row",
        "name_ro": "Any 2 goals in a row",
        "name_ru": "Any 2 goals in a row",
        "name_tr": "Any 2 goals in a row",
        "name_se": "Any 2 goals in a row",
        "name_el": "Any 2 goals in a row"
    },
    "id_318": {
        "name_en": "{!setnr} set - {$competitor1} total",
        "name_aa": "{!setnr}  مجموعة - {$competitor1} مجموعه",
        "name_de": "{!setnr} Satz - {$competitor1} Total",
        "name_nl": "{setnr} set - {$competitor1} totaal",
        "name_pl": "{!setnr} set - {$competitor1} suma",
        "name_ro": "Set-ul {!setnr} - total {$competitor1}",
        "name_ru": "{!setnr} сет - {$competitor1} тотал",
        "name_tr": "{!setnr}. set - {$competitor1}  toplam",
        "name_se": "{!setnr} set - {$competitor1} totalt",
        "name_el": "{!setnr} σετ - {$competitor1} σύνολο"
    },
    "id_710": {
        "name_en": "Which team wins the coin toss and the match",
        "name_aa": "الفريق الذي يفوز في رمي العملة والمباراة",
        "name_de": "Welches Team gewinnt den Münzwurf und das Spiel",
        "name_nl": "Welk team wint de toss en de wedstrijd",
        "name_pl": "Który zespół wygra rzut monetą i mecz",
        "name_ro": "Care echipă câștigă aruncarea banului și meciul",
        "name_ru": "Какая команда выиграет подбрасывание монеты и матч",
        "name_tr": "Yazı-turayı ve maçı hangi takım kazanır",
        "name_se": "Vilket lag vinner slantsinglingen och matchen",
        "name_el": "Ποια ομάδα θα κερδίσει το στρίψιμο του νομίσματος και τον αγώνα"
    },
    "id_472": {
        "name_en": "1st half - race to {pointnr} points",
        "name_aa": "الشوط الأول - السباق إلى النقاط {pointnr}",
        "name_de": "1. Halbzeit - Wer erreicht zuerst {pointnr} Punkte",
        "name_nl": "Eerste helft - race naar {pointnr} punten",
        "name_pl": "1. Połowa - kto pierwszy do {pointnr} punktów",
        "name_ro": "Repriza 1 - primii la {pointnr} puncte",
        "name_ru": "1ая половина - гонка до {pointnr} очков",
        "name_tr": "İlk yarı - {pointnr}. sayı çekişmesi",
        "name_se": "1:a halvlek - först till {pointnr} poäng",
        "name_el": "1ο ημίχρονο Ποιος θα φτάσει 1ος στους {pointnr} πόντους"
    },
    "id_73": {
        "name_en": "1st half - {$competitor2} exact goals",
        "name_aa": "النصف الأول - {$competitor2} أهداف دقيقة",
        "name_de": "1. Halbzeit - {$competitor2} Genaue Anzahl Tore",
        "name_nl": "1e helft - {$competitor2} exact aantal doelpunten",
        "name_pl": "1. Połowa - {$competitor2} Dokładna liczba goli",
        "name_ro": "Prima repriză - goluri exact {$competitor2}",
        "name_ru": "1ая половина - {$competitor2} точное число голов",
        "name_tr": "ilk yarı - {$competitor2} gol sayısı",
        "name_se": "1:a halvlek - {$competitor2} exakt antal mål",
        "name_el": "1ο ημίχρονο - {$competitor2} ακριβής αριθμός γκολ"
    },
    "id_310": {
        "name_en": "{!setnr} set - total points",
        "name_aa": "مجموعة {!setnr} - مجموع النقاط",
        "name_de": "{!setnr} Satz - Anzahl Punkte",
        "name_nl": "{setnr} set - totaal aantal punten",
        "name_pl": "{!setnr} set - suma punktów",
        "name_ro": "Set-ul {!setnr} - total puncte",
        "name_ru": "{!setnr} сет - тотал очков",
        "name_tr": "{!setnr}. set - toplam sayı",
        "name_se": "{!setnr} set - totalt antal poäng",
        "name_el": "{!setnr} σετ - σύνολο πόντων"
    },
    "id_434": {
        "name_en": "{$competitor2} highest scoring period",
        "name_aa": "{$competitor2} أعلى فترة تسجيل",
        "name_de": "{$competitor2} Periode mit den meisten Toren",
        "name_nl": "Periode met de hoogste score van {$competitor2}",
        "name_pl": "{$competitor2} najwyżej punktująca część gry",
        "name_ro": "{$competitor2} repriza cu cele mai multe înscrieri",
        "name_ru": "У {$competitor2} самый результативный период",
        "name_tr": "{$competitor2}'un en çok sayı yapacağı periyot",
        "name_se": "{$competitor2} period med högst poäng",
        "name_el": "{$competitor2} Περίοδος με υψηλότερο σκορ"
    },
    "id_798": {
        "name_en": "{$event} matchday {matchday} - away teams total",
        "name_aa": "{$event} يوم المباراة {matchday}- إجمالي الفرق بعيدًا",
        "name_de": "{$event} Spieltag {matchday} - Auswärtsmannschaften Total",
        "name_nl": "{$event} matchday {matchday} - uitspelende ploegen totaal",
        "name_pl": "{$event} matchday {matchday} - goście suma",
        "name_ro": "{$event} etapă {matchday} - oaspeți total",
        "name_ru": "{$event} игровой день {matchday} - гости итого",
        "name_tr": "{$event} maç günü {matchday} - deplasman takımı toplam",
        "name_se": "{$event} - bortalaget totalt",
        "name_el": "{$event} Αγωνιστική {matchday} - σύνολο - φιλοξενούμενη"
    },
    "id_995": {
        "name_en": "Multi flyer spread",
        "name_aa": "Multi flyer spread",
        "name_de": "Multi flyer spread",
        "name_nl": "Multi flyer spread",
        "name_pl": "Multi flyer spread",
        "name_ro": "Multi flyer spread",
        "name_ru": "Мульти флайер спред",
        "name_tr": "Multi flyer spread",
        "name_se": "Multi flyer spread",
        "name_el": "Multi flyer spread"
    },
    "id_1094": {
        "name_en": "{!retirementnr} to retire",
        "name_aa": "{!retirementnr} to retire",
        "name_de": "{!retirementnr} to retire",
        "name_nl": "{retirementnr} trekt zich terug",
        "name_pl": "{!retirementnr} to retire",
        "name_ro": "{!retirementnr} to retire",
        "name_ru": "{!retirementnr} замены шин",
        "name_tr": "{!retirementnr} to retire",
        "name_se": "{!retirementnr} to retire",
        "name_el": "{!retirementnr} να εγκαταλείψει"
    },
    "id_604": {
        "name_en": "{!penaltynr} penalty scored",
        "name_aa": "{!penaltynr} ركلة جزاء سجلت",
        "name_de": "{!penaltynr} Elfmeter verwandelt",
        "name_nl": "{penaltynr} strafschop benut",
        "name_pl": "{!penaltynr} rzut karny strzelony",
        "name_ro": "Penaltiul {!penaltynr} înscris",
        "name_ru": "{!penaltynr} пенальти реализовано",
        "name_tr": "{!penaltynr}. penaltı vurulu başarılı",
        "name_se": "{!penaltynr} straff mål",
        "name_el": "{!penaltynr} πέναλτυ εύστοχο"
    },
    "id_1079": {
        "name_en": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_aa": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_de": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_nl": "{inningnr} inning - exact aantal strike-outs {$competitor1}",
        "name_pl": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_ro": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_ru": "{!inningnr} иннинг - {$competitor1} точные страйк-ауты",
        "name_tr": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_se": "{!inningnr} inning - {$competitor1} exact strikeouts",
        "name_el": "{!inningnr} inning - {$competitor1} ακριβής αριθμός  strikeouts"
    },
    "id_398": {
        "name_en": "{!mapnr} map - {!xth} barracks",
        "name_aa": "خريطة {!mapnr} - {!xth} الثكنات",
        "name_de": "{!mapnr} Map - {!xth} Baracken",
        "name_nl": "{mapnr} map - {xth} barracks",
        "name_pl": "{!mapnr} mapa - {!xth} baraki",
        "name_ro": "Harta {!mapnr} - barracks {!xth}",
        "name_ru": "{!mapnr} карта - {!xth} бараки",
        "name_tr": "{!mapnr}. harita - {!xth} kışla",
        "name_se": "{!mapnr} karta - {!xth} barracks",
        "name_el": "{!mapnr} map - {!xth} barracks"
    },
    "id_745": {
        "name_en": "Innings {from} to {to} - odd/even",
        "name_aa": "أوقات من {from} إلى {to} -فردي / زوجي",
        "name_de": "Inning {from} bis {to} - Gerade/ungerade",
        "name_nl": "Innings {from} tot {to} - even/oneven",
        "name_pl": "Innings {from} do {to} - parzyste/nieparzyste",
        "name_ro": "Innings de la {from} la {to} - impar/par",
        "name_ru": "Иннинги от {from} до {to} - чет/нечет",
        "name_tr": "Devre {from} den {to} e - tek7çift",
        "name_se": "Innings {from} till {to} - udda/jämnt",
        "name_el": "Innings {from} to {to} - μονά - ζυγά"
    },
    "id_530": {
        "name_en": "1st half - {!scorenr} score",
        "name_aa": "الشوط الأول - {!scorenr} النتيجة",
        "name_de": "1. Halbzeit - {!scorenr} Ergebnis",
        "name_nl": "Eerste helft - {scorenr} score",
        "name_pl": "1. Połowa - {!scorenr} wynik",
        "name_ro": "Repriza 1 - scor {!scorenr}",
        "name_ru": "1ая половина - {!scorenr} счет",
        "name_tr": "İlk yarı - {!scorenr}. gol",
        "name_se": "1:a halvlek - {!scorenr} mål",
        "name_el": "1ο ημίχρονο - {!scorenr} σκορ"
    },
    "id_702": {
        "name_en": "Top batter total",
        "name_aa": "أعلى مجموع الخليط",
        "name_de": "Bester Batter gesamt",
        "name_nl": "Beste slagman totaal",
        "name_pl": "Najlepszy batter suma",
        "name_ro": "Total top batter",
        "name_ru": "Лучший бэттер тотал",
        "name_tr": "En iyi vurucu toplam",
        "name_se": "Totalt bästa slagman",
        "name_el": "Top batter total"
    },
    "id_884": {
        "name_en": "1 minute - total corners from {from} to {to}",
        "name_aa": "دقيقة واحدة - الزوايا الكلية من {from} إلى {to}",
        "name_de": "1 Minute - insgesamte Ecken von {from} bis {to}",
        "name_nl": "1 minuut - totaal aantal hoekschoppen van {from} tot {to}",
        "name_pl": "1 minute - total corners from {from} to {to}",
        "name_ro": "1 minut - total cornere de la {from} la {to}",
        "name_ru": "1 минута - итого угловых с {from} по {to}",
        "name_tr": "1 dakika - toplam korner sayısı {from}'dan {to}'a kadar",
        "name_se": "1 minuter - totalt antal hörnor från {from} till {to}",
        "name_el": "1 λεπτό - σύνολο κόρνερ από {from} μέχρι {to}"
    },
    "id_1607": {
        "name_en": "1x2 or {total} total",
        "name_aa": "1x2 or {total} total",
        "name_de": "1x2 or {total} total",
        "name_nl": "1x2 or {total} total",
        "name_pl": "1x2 or {total} total",
        "name_ro": "1x2 or {total} total",
        "name_ru": "1x2 or {total} total",
        "name_tr": "1x2 or {total} total",
        "name_se": "1x2 or {total} total",
        "name_el": "1x2 or {total} total"
    },
    "id_701": {
        "name_en": "Any player to score {milestone}",
        "name_aa": "أي لاعب يسجل {milestone}",
        "name_de": "Jeder Spieler erzielt {milestone}",
        "name_nl": "Er wordt een {milestone} gescoord",
        "name_pl": "Którykolwiek zawodnik zdobędzie {milestone}",
        "name_ro": "Oricare jucător să înscrie {milestone}",
        "name_ru": "Любой игрок наберёт {milestone}",
        "name_tr": "Herhangi bir oyuncu sayı {milestone} yapar",
        "name_se": "Vilken spelare att göra poäng {milestone}",
        "name_el": "Any player to score {milestone}"
    },
    "id_1618": {
        "name_en": "Score in first 5 min",
        "name_aa": "Score in first 5 min",
        "name_de": "Score in first 5 min",
        "name_nl": "Score in first 5 min",
        "name_pl": "Score in first 5 min",
        "name_ro": "Score in first 5 min",
        "name_ru": "Score in first 5 min",
        "name_tr": "Score in first 5 min",
        "name_se": "Score in first 5 min",
        "name_el": "Score in first 5 min"
    },
    "id_851": {
        "name_en": "{$competitor2} to win exactly 1 set",
        "name_aa": "{$competitor2} للفوز بمجموعة واحدة بالضبط",
        "name_de": "{$competitor2} wird genau einen Satz gewinnen",
        "name_nl": "{$competitor2} wint precies 1 set",
        "name_pl": "{$competitor2} wygra dokładnie 1 set",
        "name_ro": "{$competitor2} să câștige exact 1 set",
        "name_ru": "{$competitor2} выиграть точно 1 сэт",
        "name_tr": "{$competitor2} sadece 1 set kazanır",
        "name_se": "{$competitor2} att vinna exakt 1 set",
        "name_el": "{$competitor2} να κερδίσει μόνο 1 σετ"
    },
    "id_506": {
        "name_en": "{!framenr} frame - break 100+",
        "name_aa": "إطار {!framenr} - استراحة 100+",
        "name_de": "{!framenr} Frame - Break 100+",
        "name_nl": "{framenr} frame - break 100+",
        "name_pl": "{!framenr} frejm - brejk 100+",
        "name_ro": "Frame-ul {!framenr} - break 100+",
        "name_ru": "{!framenr} фрейм - брейк 100+",
        "name_tr": "{!framenr}. frame - 100+ break",
        "name_se": "{!framenr} frame - break 100+",
        "name_el": "{!framenr} frame - μπρέικ 100+"
    },
    "id_220": {
        "name_en": "Will there be overtime",
        "name_aa": "هل سيكون هناك وقت إضافي",
        "name_de": "Wird es eine Verlängerung geben",
        "name_nl": "Zal er verlenging nodig zijn",
        "name_pl": "Czy będzie dogrywka",
        "name_ro": "Vor fi prelungiri",
        "name_ru": "Будет ли овертайм",
        "name_tr": "Uzatma olacak mı",
        "name_se": "Övertid i matchen",
        "name_el": "Θα υπάρξει παράταση"
    },
    "id_127": {
        "name_en": "Penalty shootout - total",
        "name_aa": "ركلات الترجيح - مجموعه",
        "name_de": "Elfmeterschießen - Total",
        "name_nl": "Strafschoppenserie - totaal",
        "name_pl": "Rzuty karne - suma",
        "name_ro": "Lovituri de departajare - total",
        "name_ru": "Серия пенальти - тотал",
        "name_tr": "Penaltı atışları - toplam gol",
        "name_se": "Straffar - totalt",
        "name_el": "Διαδικασία πέναλτι - Σύνολο Γκολ"
    },
    "id_411": {
        "name_en": "US spread (incl. overtime and penalties)",
        "name_aa": "انتشار الولايات المتحدة (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "US spread (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "US spread (verlenging en strafschoppen inbegrepen)",
        "name_pl": "US spread (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Interval US (incl. prelungiri și lovituri de departajare)",
        "name_ru": "США спред (включая овертайм и пенальти)",
        "name_tr": "US spread (uzatmalar ve penaltılar dahil)",
        "name_se": "US spread (inkl. övertid och straffar)",
        "name_el": "Αμερικάνικο Σπρεντ (παράταση και πέναλτι συμπ.)"
    },
    "id_923": {
        "name_en": "{%player} total rebounds (incl. overtime)",
        "name_aa": "{!player} total rebounds (incl. overtime)",
        "name_de": "{!player} rebounds Gesamt (inkl. Verlängerung)",
        "name_nl": "{!player} totaal aantal rebounds",
        "name_pl": "{!player} total rebounds (incl. overtime)",
        "name_ro": "{!player} total rebounds (incl. overtime)",
        "name_ru": "{!player} тотал отскоков",
        "name_tr": "{!player} total rebounds (incl. overtime)",
        "name_se": "{!player} total rebounds (incl. overtime)",
        "name_el": "{!player} σύνολο ριμπάουντ"
    },
    "id_408": {
        "name_en": "Handicap {hcp} (incl. overtime and penalties)",
        "name_aa": "هانديكاب {hcp} (بما في ذلك الوقت الإضافي وركلات الترجيح)",
        "name_de": "Handicap {hcp} (inkl. Verlängerung und Elfmeterschießen)",
        "name_nl": "Handicap {hcp} (verlenging en strafschoppen inbegrepen)",
        "name_pl": "Handicap {hcp} (włącznie z dogrywką i rzutami karnymi)",
        "name_ro": "Handicap {hcp} (incl. prelungiri și lovituri de departajare)",
        "name_ru": "Гандикап {hcp} (включая овертайм и пенальти)",
        "name_tr": "Handikap {hcp} (uzatmalar ve penaltılar dahil)",
        "name_se": "Handikapp {hcp}(inkl. övertid och straffar)",
        "name_el": "{hcp} χάντικαπ (παράταση και πέναλτι συμπ.)"
    },
    "id_317": {
        "name_en": "{!setnr} set - handicap {hcp}",
        "name_aa": "مجموعة {!setnr} - هانديكاب{hcp}",
        "name_de": "{!setnr} Satz - Handicap {hcp}",
        "name_nl": "{setnr} set - handicap {hcp}",
        "name_pl": "{!setnr} set - handicap {hcp}",
        "name_ro": "Set-ul {!setnr} - handicap {hcp}",
        "name_ru": "{!setnr} сет - гандикап {hcp}",
        "name_tr": "{!setnr}. set - handikap {hcp}",
        "name_se": "{!setnr} set - handikapp {hcp}",
        "name_el": "{!setnr} σετ - {hcp}  χάντικαπ"
    },
    "id_793": {
        "name_en": "Player fantasy points",
        "name_aa": "نقاط الخيال لاعب",
        "name_de": "Spieler Fantasy Punkte",
        "name_nl": "Speler fantasy points",
        "name_pl": "Player fantasy points",
        "name_ro": "Puncte fantezie jucător",
        "name_ru": "Игрок фэнтэзи очки",
        "name_tr": "Oyuncu fantezi puanı",
        "name_se": "Spelarens fantasypoäng",
        "name_el": "Παίχτης fantasy points"
    },
    "id_607": {
        "name_en": "{!inningnr} innings - {$competitor2} total",
        "name_aa": "{!inningnr} أدوار - {$competitor2} الإجمالي",
        "name_de": "{!inningnr} Inning – {$competitor2} Total",
        "name_nl": "{inningnr} innings - {$competitor2} totaal",
        "name_pl": "{!inningnr} innings - {$competitor2} suma",
        "name_ro": "{!inningnr} innings - {$competitor2} total",
        "name_ru": "{!inningnr} иннинг - {$competitor2} тотал",
        "name_tr": "{!inningnr}. devre - {$competitor2} toplam",
        "name_se": "{!inningnr} innings - {$competitor2} totalt",
        "name_el": "{!inningnr} innings –  {$competitor2} σύνολο"
    },
    "id_129": {
        "name_en": "Penalty shootout - {$competitor2} total",
        "name_aa": "ركلات الترجيح - {$competitor2} مجموعه",
        "name_de": "Elfmeterschießen - {$competitor2} Total",
        "name_nl": "Strafschoppenserie - {$competitor2} totaal",
        "name_pl": "Rzuty karne - {$competitor2} suma",
        "name_ro": "Lovituri de departajare - total {$competitor2}",
        "name_ru": "Серия пенальти - тотал {$competitor2}",
        "name_tr": "Penaltı atışları - {$competitor2} toplam gol",
        "name_se": "Straffar - {$competitor2} totalt",
        "name_el": "Διαδικασία πέναλτι - {$competitor2} Σύνολο Γκολ"
    },
    "id_453": {
        "name_en": "{!periodnr} period - which team to score",
        "name_aa": "فترة {!periodnr}- الفريق الذي يسجل",
        "name_de": "{!periodnr} Periode - Welche Mannschaft trifft",
        "name_nl": "{periodnr} periode - welk team scoort",
        "name_pl": "{!periodnr} część gry - który zespół strzeli",
        "name_ro": "Repriza {!periodnr} - care echipă va înscrie",
        "name_ru": "{!periodnr} период - какая команда забьет",
        "name_tr": "{!periodnr}. periyot - hangi takım gol atar",
        "name_se": "{!periodnr} period - vilket lag gör mål",
        "name_el": "{!periodnr} περίοδος - να σκοράρουν και οι 2 ομάδες"
    },
    "id_138": {
        "name_en": "Total booking points",
        "name_aa": "مجموع نقاط الحجز",
        "name_de": "Gesamtanzahl Strafpunkte",
        "name_nl": "Totaal aantal booking points",
        "name_pl": "Suma punktów karnych",
        "name_ro": "Total puncte cartonașe",
        "name_ru": "Общие зачетные очки",
        "name_tr": "Toplam kart ceza puanı",
        "name_se": "Totalt antal kortpoäng",
        "name_el": "Σύνολο πόντων κάρτες"
    },
    "id_749": {
        "name_en": "{!inningnr} inning - {$competitor1} to score",
        "name_aa": "{!inningnr} الشوط - {$competitor1} ليسجل",
        "name_de": "{!inningnr} Inning - {$competitor1} punktet",
        "name_nl": "{inningnr} inning - {$competitor1} scoort",
        "name_pl": "{!inningnr} inning - {$competitor1} zdobywa punkty",
        "name_ro": "{!inningnr} inning - să înscrie {$competitor1}",
        "name_ru": "{!inningnr} иннинг - {$competitor1} забьет",
        "name_tr": "{!inningnr}. devre - {$competitor1} sayı yapar",
        "name_se": "{!inningnr} inning - {$competitor1} ta poäng",
        "name_el": "{!inningnr} inning - {$competitor1} να σκοράρει"
    },
    "id_861": {
        "name_en": "Draw or both teams to score",
        "name_aa": "ارسم أو كلا الفريقين للتسجيل",
        "name_de": "Draw or both teams to score",
        "name_nl": "Gelijkspel of beide teams scoren",
        "name_pl": "remis lub oba zespoły punktują",
        "name_ro": "Egal sau ambele echipe să înscrie",
        "name_ru": "Ничья или обе команды забьют",
        "name_tr": "Berabere ya da her iki takımda gol atar",
        "name_se": "Oavgjort elr båda lagen gör mål",
        "name_el": "Ισοπαλία ή και οι 2 να σκοράρουν"
    },
    "id_335": {
        "name_en": "{!mapnr} map - will there be overtime",
        "name_aa": "خريطة {!mapnr} - سيكون هناك الوقت بدل الضائع",
        "name_de": "{!mapnr} Map - Wird es eine Verlängerung geben",
        "name_nl": "{mapnr} map - zal er overtime nodig zijn",
        "name_pl": "{!mapnr} mapa - czy będzie dogrywka",
        "name_ro": "Harta {!mapnr} - vor fi prelungiri",
        "name_ru": "{!mapnr} карта - будет ли овертайм",
        "name_tr": "{!mapnr}. harita - uzatma süresi olur",
        "name_se": "{!mapnr} karta - blir det övertid",
        "name_el": "{!mapnr} map - θα υπάρξει παράταση"
    }
}

export const marketId = [
    "818",
    "69",
    "68",
    "88",
    "26",
    "70",
    "90",
    "90",
    "71",
    "76",
    "19",
    "18",
    "66",
    "32",
    "55",
    "551",
    "90",
    "20",
    "53",
    "19",
    "66",
    "857",
    "16",
    "856",
    "75",
    "549",
    "18",
    "98",
    "68",
    "85",
    "540",
    "68",
    "105",
    "68",
    "184",
    "90",
    "10",
    "541",
    "881",
    "81",
    "18",
    "25",
    "100",
    "34",
    "91",
    "16",
    "19",
    "858",
    "543",
    "88",
    "68",
    "65",
    "77",
    "18",
    "16",
    "9",
    "8",
    "20",
    "863",
    "51",
    "25",
    "14",
    "65",
    "547",
    "14",
    "92",
    "90",
    "58",
    "545",
    "18",
    "91",
    "101",
    "14",
    "819",
    "553",
    "74",
    "84",
    "18",
    "855",
    "97",
    "48",
    "88",
    "19",
    "90",
    "18",
    "18",
    "90",
    "90",
    "18",
    "78",
    "18",
    "66",
    "18",
    "62",
    "547",
    "41",
    "79",
    "69",
    "88",
    "91",
    "818",
    "12",
    "54",
    "35",
    "854",
    "14",
    "819",
    "95",
    "87",
    "16",
    "18",
    "37",
    "29",
    "820",
    "31",
    "16",
    "66",
    "23",
    "18",
    "65",
    "52",
    "542",
    "18",
    "33",
    "21",
    "550",
    "sport",
    "88",
    "18",
    "37",
    "66",
    "860",
    "14",
    "18",
    "818",
    "96",
    "16",
    "861",
    "87",
    "66",
    "87",
    "24",
    "859",
    "16",
    "86",
    "68",
    "544",
    "818",
    "59",
    "548",
    "87",
    "547",
    "16",
    "864",
    "90",
    "47",
    "14",
    "90",
    "28",
    "18",
    "92",
    "64",
    "37",
    "94",
    "68",
    "552",
    "63",
    "66",
    "71",
    "90",
    "93",
    "88",
    "50",
    "18",
    "880",
    "20",
    "546",
    "13",
    "30",
    "862",
    "88",
    "88",
    "15",
    "865",
    "16",
    "60",
    "18",
    "45",
    "16",
    "68",
    "57",
    "16",
    "88",
    "37",
    "68",
    "16",
    "65",
    "69",
    "11",
    "16",
    "18",
    "36",
    "88",
    "1",
    "18",
    "27",
    "66",
    "68",
    "879",
    "547",
    "46",
    "70",
    "56",
    "819",
    "68",
    "83",
    "21",
    "19",
    "66",
]