export const th = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    Game_Name:'Game Name',
    Provider:'Provider',
    Volatility:'Volatility',
    Features:'Features',
    Themes:'Themes',
    viewall:'ดูทั้งหมด',
    discoverall:'ค้นพบทั้งหมด',
    roulette:'รูเล็ต',
    blackjack:'กระบอง',
    baccarat:'บาคาร่า',
    poker:'โป๊กเกอร์',
    announceMsg:'ลงทะเบียนตอนนี้และเพลิดเพลินกับการทำธุรกรรมที่รวดเร็วและปลอดภัย! ถอนเงินวันเดียวกัน!!',
    lobby:'ห้องโถง',
    hotGames:'เกมส์สุดฮอต',
    new:'ใหม่',
    providers:'ผู้ให้บริการ',
    notice:'สังเกต',
    close:'ปิด',
    customMsg:'วันนี้เท่านั้น!',
    customPrice:'ฝาก $100 รับ $500!!',
    subCustomMsg:'เฉพาะลูกค้า 1,000 ท่านแรกเท่านั้น',
    email:'อีเมล',
    forgetPassword:'ลืมรหัสผ่าน',
    passwordAgain:'ยืนยันรหัสผ่าน',
    backLogin:'กลับไปที่เข้าสู่ระบบ',
    readAll:'อ่านทั้งหมด',
    sendOTP:'ส่งโอที',
    en: 'EN',
    tr: 'TR',
    fr: 'FR',
    de: 'DE',
    ru: 'RU',
    nl: 'NL',
    th: 'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports : 'กีฬา',
    live :  'มีชีวิต',
    inplay:  'กำลังเล่น',
    today :  'ในวันนี้',
    upcoming :  'ที่จะเกิดขึ้น',
    tomorrow :  'วันพรุ่งนี้',
    highlights :  'ไฮไลท์',
    results :  'ผล',
    lastMinute :  'นาทีสุดท้าย',
    liveCasino :  'คาสิโนสด',
    casino :  'บ่อนคาสิโน',
    myBets :  'เดิมพันของฉัน',
    home :  'บ้าน',
    login :  'เข้าสู่ระบบ',
    signup :  'ลงชื่อ',
    betslip :  'สลิปเดิมพัน',
    search :  'ค้นหา',
    searchHere :  'ค้นหาที่นี่',
    transaction :  'การซื้อขาย',
    changePassword :  'เปลี่ยนรหัสผ่าน',
    logout :  'ออกจากระบบ',
    single :  'เดียว',
    multiple :  'หลายอย่าง',
    possibleWin :  'ชนะไปได้',
    amount :  'จำนวน',
    ok :  'ตกลง',
    totalOdds :  'อัตราต่อรองรวม',
    odd :  'แปลก',
    totalPossibleWin :  'ชนะที่เป็นไปได้ทั้งหมด',
    placeBet :  'เดิมพันเพลส',
    fastBet:'เดิมพันเร็ว',
    fast :  'รวดเร็ว',
    bet :  'เดิมพัน',
    username :  'ชื่อผู้ใช้',
    password :  'รหัสผ่าน',
    stayLoggedIn :  'อยู่ในระบบ',
    all :  'ทั้งหมด',
    'in game' :  'ในเกมส์',
    won :  'วอน',
    lost :  'สูญหาย',
    refund :  'คืนเงินให้',
    date :  'วันที่',
    winAmount :  'จำนวนเงินที่ชนะ',
    stake :  'เดิมพัน',
    pick :  'เลือก',
    myAccount :  'บัญชีของฉัน',
    football :  'ฟุตบอล',
    basketball :  'บาสเกตบอล',
    iceHockey :  'ฮอคกี้น้ำแข็ง',
    tennis :  'เทนนิส',
    volleyball :  'วอลเลย์บอล',
    boxing :  'มวย',
    americanFootball: 'อเมริกันฟุตบอล',
    baseball: 'เบสบอล',
    hockey: 'ฮอกกี้',
    tableTennis: 'ปิงปอง',
    badminton: 'แบดมินตัน',
    cricket: 'คริกเก็ต',
    futsal: 'ฟุตซอล',
    golf: 'กอล์ฟ',
    handball: 'แฮนด์บอล',
    rugbyLeagues: 'ลีกรักบี้',
    pickCanNotBeCombined :  'เลือกนี้ไม่สามารถนำมารวมกัน',
    matchStopped :  'การแข่งขันหยุด',
    favorites :  'รายการโปรด',
    cashout :  'เงินออก',
    'in progress' :  'กำลังดำเนินการ',
    rejected :  'ปฏิเสธ',
    tip :  'ปลาย',
    noGoal :  'ไม่ได้ประตู',
    yes :  'ใช่',
    over :  'เกิน',
    under :  'ภายใต้',
    even :  'แม้',
    areYouSureWantCashout :  'คุณแน่ใจว่าคุณต้องการที่จะ Cashout?',
    noGoal :  'ไม่',
    bonus :  'โบนัส',
    termsAndConditon :  'ข้อตกลงและเงื่อนไข',
    aboutUs: 'เกี่ยวกับเรา',
    contactUs: 'ติดต่อเรา',
    payments: 'การจ่ายเงิน',
    howPlacebet: 'วิธีการวางเดิมพัน',
    deposit :  'เงินฝาก',
    credit: 'เครดิต',
    debit: 'การหักบัญชี',
    withdraw :  'ถอน',
    totalOdd :  'Total Odd',
    totalStake :  'สัดส่วนการถือหุ้นรวม',
    topGames :  'เกมยอดนิยม',
    allGames :  'เกมส์ทั้งหมด',
    canNotBeCombined :  'เลือกนี้ไม่สามารถนำมารวมกัน',
    betSettled :  'เดิมพันตัดสิน',
    betSuspended :  'เดิมพันที่ถูกระงับ',
    insufficientBalance :  'ยอดเงินไม่เพียงพอ',
    oldPassword :  'รหัสผ่านเก่า',
    newPassword :  'รหัสผ่านใหม่',
    confirmPassword :  'ยืนยันรหัสผ่านใหม่',
    change :  'เปลี่ยนแปลง',
    fromDate :  'จากวันที่',
    toDate :  'ถึงวันที่',
    activityType :  'ประเภทกิจกรรม',
    show :  'แสดง',
    gameType :  'ประเภทเกม',
    repeatBet :  'เดิมพันซ้ำ',
    someThingWrong :  'บางอย่างผิดพลาด',
    status :  'สถานะ',
    coupon :  'คูปอง',
    acceptOddChangesText :  'คุณจำเป็นต้องยอมรับการเปลี่ยนแปลงเพื่อให้สามารถวางเดิมพัน',
    turnOffAway :  'ปิดอยู่แล้ว',
    acceptOddChanges :  'ยอมรับการเปลี่ยนแปลงที่แปลก',
    maxWinAmount :  'จำนวนเงินที่ชนะแม็กซ์',
    maxOdd :  'แม็กซ์คี่',
    dateTime :  'วันเวลา',
    type :  'ชนิด',
    refreshCashout :  'รีเฟรช Cashout',
    vendor :  'ผู้ขาย',
    stakeLowerThen1 :  'สัดส่วนการถือหุ้นจะต้องน้อยแล้ว',
    stakeGreaterThen1 :  'สัดส่วนการถือหุ้นจะต้องมีมากขึ้นแล้ว',
    ESports: 'eSports',
    KSports: 'ksports',
    promotion: 'การส่งเสริม',
    customerServicePage: 'หน้าการบริการลูกค้า',
    decimal: 'ทศนิยม',
    fraction: 'เศษเล็กเศษน้อย',
    americanOdds: 'ราคาต่อรองอเมริกัน',
    nothingFound: 'ไม่พบอะไรเลย',
    noLiveMatch: 'ไม่มีการแข่งขันสด',
    sportBook: 'หนังสือกีฬา',
     marketNames: {
        1: '1x2',
        2: 'ภายใต้ / กว่า',
        238: 'ที่เหลือการแข่งขัน',
        59: 'เป้าหมายต่อไป',
        7: 'โอกาสคู่',
        52: ' เสมอไม่มีการเดิมพัน',
        226: ' 12 ห้องรวมถึงการทำงานล่วงเวลา',
        13: ' แฮนดิแค็ยุโรป',
        4: ' HT / FT',
        17: ' ทั้งสองทีมทำคะแนน',
        59: ' เป้าหมายต่อไป',
        28: ' ภายใต้ / กว่ารวมค่าล่วงเวลา',
        247: ' ที่เหลืออยู่ที่ 1 ครึ่ง',
    },
    tabNames: {
        'All':'ทั้งหมด',
       'Main Markets': 'ตลาดหลัก',
        'Totals': 'ผลรวม',
        '1st Half': '1 ครึ่ง',
        '2nd Half': '2 ครึ่ง',
        'Home Team': 'ทีมเจ้าบ้าน',
        'Away Team': 'ทีมเยือน',
        'All Periods': 'ทุกช่วงเวลา',
         'Results': 'ผล',
        'Home / Away': 'หน้าแรก / ทีมเยือน',
        'Slot': 'สล็อต',
        'Table Game': 'เกมโต๊ะ',
        'Instant Win': 'ชนะทันที',
        'Live Casino': 'คาสิโนสด',
        'Scratch Card': 'บัตรขูด',
        'Video Poker': 'วิดีโอโป๊กเกอร์',
        'Virtual Sports': 'กีฬาเสมือนจริง',
        'New':'ใหม่',
        'Hot Games':'เกมส์สุดฮอต',
        'Slots':'สล็อต',
        'Crash':'ชน',
        'Chinese':'ชาวจีน',
        'Bonus Buy':'ซื้อโบนัส',
        'Top Providers':'ผู้ให้บริการชั้นนำ',
        'Andar Bahar':'อันดาร์ บาฮาร์',
        'Baccarat':'บาคาร่า',
        'Keno':'คีโน',
        'Blackjack':'กระบอง',
        'Sic Bo':'ไฮโล',
        'Poker':'โป๊กเกอร์',
        'Dragon tiger':'เสือมังกร',
        'Roulette':'รูเล็ต',
        'Shooting':'การยิง',
        'Lottery':'หวย',
    },
    markets: {
        2 : '  ภายใต้ / กว่า',
        3 : '  เอเชียนแฮน',
        5 : '  คู่ / คี่',
        6 : '  สกอร์',
        7 : ' โอกาสคู่',
        9 : '  ระยะเวลา 1 คะแนนที่ถูกต้อง',
        11 : ' มุมรวม',
        12 : ' กติการวมมุ่งมั่น',
        13 : ' แฮนดิแค็ยุโรป',
        16 : ' ทีมแรกที่จะทำคะแนน',
        17 : ' ทั้งสองทีมทำคะแนน',
        19 : ' บัตรครั้งแรก',
        21 : ' ภายใต้ / กว่าช่วงที่ 1 ช่วง',
        22 : ' ทีมเยือนที่จะยิงประตู',
        23 : ' ทีมเจ้าบ้านกับคะแนน',
        25 : ' ดับเบิลโอกาส Halftime',
        29 : ' ภายใต้รอบ / กว่า',
        30 : ' ภายใต้ / กว่าเตะมุม - ทีมเจ้าบ้าน',
        31 : ' ภายใต้ / กว่าเตะมุม - ทีมเยือน',
        34 : ' หน้าแรกทีมที่ทำประตูในครึ่งทั้งสอง',
        35 : ' ทีมเยือนให้คะแนนในครึ่งทั้งสอง',
        41 : ' ช่วงที่ 1 ช่วงผู้ชนะ',
        42 : ' 2 ระยะเวลาผู้ชนะเลิศ',
        43 : ' 3 ระยะเวลาผู้ชนะเลิศ',
        44 : ' 4 ระยะเวลาผู้ชนะเลิศ',
        45 : ' ภายใต้ / กว่า 2 ระยะเวลา',
        46 : ' ภายใต้ / กว่า 3 ระยะเวลา',
        47 : ' ภายใต้ / กว่าระยะเวลาที่ 4',
        48 : ' ภายใต้ / กว่าระยะเวลา 5',
        49 : ' 5 ระยะเวลาผู้ชนะเลิศ',
        50 : ' 1X2 รวมการทำงานล่วงเวลา',
        51 : ' Odd / Even รวมการทำงานล่วงเวลา',
        52 : ' 12',
        53 : ' เอเชียนแฮนดิแค็ Halftime',
        55 : ' ทีมแรกที่ทำสกอร์ระยะเวลา 1',
        56 : ' ทีมสุดท้ายที่จะทำคะแนน',
        57 : ' ทีมสุดท้ายที่ทำสกอร์อีเกิ้ล',
        59 : ' เป้าหมายต่อไป',
        61 : ' แฮนดิแค็ยุโรป Halftime',
        62 : ' Odd / Even Halftime',
        63 : ' 12 อีเกิ้ล',
        64 : ' เอเชียนแฮนช่วงที่ 1 ช่วง',
        65 : ' เอเชียนแฮน 2 ระยะเวลา',
        66 : ' เอเชียนแฮน 3 ระยะเวลา',
        67 : ' เอเชียนแฮนดิแค็ระยะเวลา 4',
        68 : ' เอเชียนแฮนดิแค็ระยะเวลา 5',
        69 : ' Will เกมไปที่การทำงานล่วงเวลา?',
        70 : ' ระยะเวลาการให้คะแนนสูงสุด',
        71 : ' เกณฑ์การให้คะแนนสูงสุดครึ่ง',
        72 : ' ช่วงที่ 1 ช่วงคู่ / คี่',
        73 : ' 2 ระยะเวลาคู่ / คี่',
        74 : ' 3 ระยะเวลาคู่ / คี่',
        75 : ' 4 ระยะเวลาคู่ / คี่',
        76 : ' 5 ระยะเวลาคู่ / คี่',
        77 : ' ภายใต้ / กว่า Halftime',
        78 : ' ที่ทำสกอร์แตกส่วนใหญ่',
        79 : ' จะโทษที่ได้รับรางวัล?',
        80 : ' มุมแรก',
        81 : ' โค้งสุดท้าย',
        82 : ' จะบัตรสีแดงจะแสดงให้เห็น?',
        83 : ' เพื่อให้การทำความสะอาดแผ่น',
        84 : ' ชนะทั้งครึ่งแรก',
        85 : ' ที่จะชนะจากด้านหลัง',
        86 : ' ชนะต่อศูนย์ประตู',
        88 : ' ที่จะมีคุณสมบัติ',
        89 : ' จะลองครั้งแรกจะแปลง?',
        90 : ' การแข่งขันจะได้รับการตัดสินในเซตที่ 5?',
        91 : ' อะไรอยู่ในครึ่งแรกได้ประตูจะมีการทำ?',
        92 : ' ทีมแรกที่ทำสกอร์ 2 ครึ่ง',
        95 : ' มุมแฮนดิแค็',
        96 : ' ทีมเยือนชนะต่อศูนย์ประตู',
        97 : ' ทีมเจ้าบ้านชนะต่อศูนย์ประตู',
        98 : ' ทีมเจ้าบ้านการเก็บทำความสะอาดแผ่น',
        99 : ' ทีมเยือนการเก็บทำความสะอาดแผ่น',
        100 : ' สกอร์ระยะเวลา 2',
        101 : ' ภายใต้ / กว่า - ทีมเจ้าบ้าน',
        102 : ' ภายใต้ / กว่า - ทีมเยือน',
        104 : ' ทีมเยือนชนะทั้งครึ่งแรก',
        105 : ' ทีมเจ้าบ้านชนะทั้งครึ่งแรก',
        106 : ' ทีมเจ้าบ้านจะชนะจากด้านหลัง',
        107 : ' ทีมเยือนจะชนะจากด้านหลัง',
        108 : ' หน้าแรกทีมที่ทำประตูจากจุดโทษ',
        109 : ' ห่างออกไปคะแนนทีมจากจุดโทษ',
        110 : ' ทีมเยือนต้องการชนะอย่างน้อยครึ่งหนึ่ง',
        111 : ' ทีมเจ้าบ้านจะชนะอย่างน้อยครึ่งหนึ่ง',
        113 : ' ทั้งสองทีมทำประตูที่ 1 ครึ่ง',
        114 : ' ใด ๆ ที่คะแนนผู้เล่นอย่างน้อย 2 ประตู',
        117 : ' ใด ๆ ที่คะแนนผู้เล่นอย่างน้อย 3 เป้าหมาย',
        122 : ' เกณฑ์การให้คะแนนทีมแรกที่ชนะการแข่งขัน',
        123 : ' ทีมเยือนจะทำประตูแรกและชนะการแข่งขัน',
        124 : ' หน้าแรกทีมที่ทำประตูแรกและชนะการแข่งขัน',
        128 : ' จำนวนเป้าหมาย',
        129 : ' ภายใต้ / กว่าเตะมุม - 1 ครึ่ง',
        132 : ' ภายใต้ภาพ / กว่าในเป้าหมาย',
        133 : ' ภายใต้ภาพ / กว่ารวม',
        134 : ' จำนวนเป้าหมายที่ 1 ครึ่ง',
        136 : ' ภายใต้ / กว่าล้ำหน้า',
        137 : ' หน้าแรกชนะไม่มีการเดิมพัน',
        138 : ' ชนะไปไม่มีการเดิมพัน',
        139 : ' ซึ่งในครึ่งหลังทีมเจ้าบ้านทำประตูประตูแรก?',
        140 : ' ซึ่งในครึ่งหลังทีมเยือนทำประตูประตูแรก?',
        143 : ' ซึ่งในครึ่งหลังทีมเจ้าบ้านจะทำประตูมากขึ้น?',
        144 : ' ซึ่งในครึ่งหลังทีมเยือนจะทำประตูมากขึ้น?',
        145 : ' หน้าแรกจำนวนทีมงานของเป้าหมายในวันที่ 1 ครึ่ง',
        146 : ' ออกไปจำนวนทีมงานของเป้าหมายในวันที่ 1 ครึ่ง',
        147 : ' ออกไปจำนวนทีมงานของเป้าหมายในครั้งที่ 2 ครึ่ง',
        148 : ' หน้าแรกจำนวนทีมงานของเป้าหมายในครั้งที่ 2 ครึ่ง',
        149 : ' หน้าแรกจำนวนทีมงานของเป้าหมาย',
        150 : ' ออกไปจำนวนทีมงานของเป้าหมาย',
        151 : ' ดับเบิลโอกาสครั้งที่ 2 ครึ่ง',
        153 : ' ภายใต้ / กว่าช่วงที่ 1 ช่วง - ทีมเจ้าบ้าน',
        154 : ' ภายใต้ / กว่า 2 งวด - ทีมเจ้าบ้าน',
        155 : ' ภายใต้ / กว่าช่วงที่ 1 ช่วง - ทีมเยือน',
        156 : ' ภายใต้ / กว่า 2 งวด - ทีมเยือน',
        157 : ' ภายใต้ / บัตรเหลืองกว่า - 1 ครึ่ง',
        158 : ' ภายใต้ / กว่าบัตรเหลือง',
        159 : ' จะมีการผูกหักในการแข่งขัน?',
        160 : ' ผู้ชนะเลิศการแข่งขัน',
        161 : ' 10 นาทีผล',
        162 : ' 10 นาทีภายใต้ / กว่า',
        163 : ' จำนวนเป้าหมายครึ่งที่ 2',
        164 : ' จะมีการผูกหักในชุดแรก?',
        165 : ' ภายใต้ชุด / กว่า',
        166 : ' ภายใต้ / กว่าเกมส์',
        168 : ' จะเป็นเป้าหมายของตัวเอง?',
        169 : ' ให้คะแนนในครึ่งทั้งสอง',
        170 : ' Odd / Even มุม',
        171 : ' To Win ทั้งสองครึ่ง',
        175 : ' จะมีการผูกหักในครั้งที่สองชุด?',
        176 : ' จะมีการผูกหักในไตรมาสที่สามชุด?',
        177 : ' จะมีการผูกหักในชุดที่สี่?',
        178 : ' จะมีการผูกหักในห้าชุด?',
        180 : ' ภายใต้ / กว่าโยนเพิ่มเติม',
        181 : ' ภายใต้ / กว่าใบเหลือง - ทีมเจ้าบ้าน',
        182 : ' ภายใต้ / กว่าใบเหลือง - หน้าแรกของทีมที่ 1 ครึ่ง',
        183 : ' ภายใต้ / กว่าใบเหลือง - หน้าแรกของ 2 ทีมครึ่ง',
        184 : ' ภายใต้ / บัตรเหลืองกว่า - ทีมเยือน',
        185 : ' ภายใต้ / บัตรเหลืองกว่า - ทีมเยือน 1 ครึ่ง',
        186 : ' ภายใต้ / บัตรเหลืองกว่า - ทีมเยือน 2 ครึ่ง',
        187 : ' ภายใต้ / บัตรเหลืองกว่า - ครึ่ง 2',
        188 : ' ใบเหลืองแรก',
        190 : ' แรกใบเหลืองที่ 2 ครึ่ง',
        191 : ' ผู้เล่นคนใดจะได้คะแนนหมวกเคล็ดลับ?',
        192 : ' จะเป้าหมายได้คะแนนในทั้งสอง?',
        194 : ' ทีมเจ้าบ้านการเก็บทำความสะอาดแผ่นระยะเวลา 1',
        195 : ' ทีมเจ้าบ้านการเก็บทำความสะอาดแผ่นระยะเวลา 2',
        196 : ' ทีมเยือนการเก็บทำความสะอาดแผ่นระยะเวลา 1',
        197 : ' ทีมเยือนการเก็บทำความสะอาดแผ่นระยะเวลา 2',
        198 : ' Odd / Even - ทีมเจ้าบ้าน',
        199 : ' Odd / Even - ทีมเยือน',
        200 : ' จะเป็นครั้งแรกเกณฑ์การให้คะแนนทีมชนะ?',
        201 : ' เอเชียนเกมส์แฮนดิแค็',
        202 : ' ช่วงที่ 1 ช่วงผู้ชนะเจ้าบ้าน / ทีมเยือน',
        203 : ' 2 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        204 : ' 3 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        205 : ' 4 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        206 : ' 5 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        207 : ' 15 นาทีผล',
        208 : ' 30 นาทีผล',
        209 : ' มุมรวม - 1 ครึ่ง',
        210 : ' มุมรวม - ครึ่ง 2',
        211 : ' ทั้งสองทีมทำประตูที่ 2 ครึ่ง',
        212 : ' ทั้งสองทีมทำประตูได้ทั้งครึ่งแรก',
        213 : ' ในการวาดทั้งสอง',
        214 : ' ภายใต้ / กว่าการ์ด',
        215 : ' ทีมเยือนทำสกอร์ 1 ครึ่ง',
        216 : ' ทีมเยือนที่จะยิงประตูที่ 2 ครึ่ง',
        217 : ' ระยะเวลาที่ถูกต้อง 3 คะแนน',
        218 : ' ทีมแรกที่ทำสกอร์ 1 ครึ่ง',
        219 : ' ทีมแรกที่ทำสกอร์ 2 ครึ่ง',
        220 : ' ภายใต้ / กว่า - ทีมเยือนรวมถึงการทำงานล่วงเวลา',
        221 : ' ภายใต้ / กว่า - หน้าแรกของทีมรวมทั้งการทำงานล่วงเวลา',
        222 : ' ภายใต้ / กว่า 3 งวด - ทีมเยือน',
        223 : ' ภายใต้ / กว่า 3 งวด - ทีมเจ้าบ้าน',
        224 : ' ดับเบิลโอกาสที่ 3 ระยะเวลา',
        225 : ' ดับเบิลโอกาสที่ 4 ระยะเวลา',
        226 : ' 12 ห้องรวมถึงการทำงานล่วงเวลา',
        227 : ' ผู้ชนะเลิศการแข่งขัน 2 สถานที่',
        228 : ' ผู้ชนะเลิศการแข่งขัน 3 สถานที่',
        229 : ' ผู้ชนะเลิศการแข่งขัน 4 สถานที่',
        233 : ' ที่จะชนะไม่ Nil',
        235 : ' 1 5 โอกาสชนะเลิศ',
        236 : ' 1 5 โอกาสภายใต้ / กว่า',
        237 : ' จะมีคะแนนในวันที่ 1 อินนิง',
        238 : ' ที่เหลือการแข่งขัน',
        239 : ' ช่วงเป้าหมาย',
        242 : ' ช่วงที่ 1 ช่วงคู่ / คี่ - ทีมเจ้าบ้าน',
        243 : ' ช่วงที่ 1 ช่วงคู่ / คี่ - ทีมเยือน',
        244 : ' ห้างหุ้นส่วนจำกัดเปิดสูงสุด',
        245 : ' เป้าหมาย / ไม่ได้ประตู',
        247 : ' ที่เหลืออยู่ที่ 1 ครึ่ง',
        250 : ' มุมแฮนดิแค็ - 1 ครึ่ง',
        254 : ' ช่วงการ์ด',
        255 : ' เวลาของบัตรที่ 1',
        256 : ' ทีมเจ้าบ้าน - บัตรช่วง',
        257 : ' ทีมเยือน - การ์ดช่วง',
        258 : ' ช่วงมุม',
        259 : ' ทีมที่มีมุมมากที่สุด',
        260 : ' ทีมงานที่มีมากที่สุดในวันที่ 1 ครึ่งลูกเตะมุม',
        261 : ' ทีมงานที่มีส่วนใหญ่ที่ 2 ครึ่งลูกเตะมุม',
        262 : ' ทีมเจ้าบ้าน - ช่วงมุม',
        263 : ' ทีมเยือน - มุมช่วง',
        264 : ' ทีมเจ้าบ้าน - 1 ครึ่งลูกเตะมุมช่วง',
        265 : ' ทีมเยือน - 1 ครึ่งลูกเตะมุมช่วง',
        266 : ' ทีมเจ้าบ้าน - 2 ครึ่งช่วงมุม',
        267 : ' ทีมเยือน - 2 ครึ่งช่วงมุม',
        269 : ' เป้าหมายช่วงที่ 1 ระยะเวลา',
        271 : ' โอกาสผสม',
        274 : ' ผู้ชนะเลิศการทายผลทีมชนะ',
        275 : ' เป้าหมายช่วงที่ 2 ระยะเวลา',
        278 : ' เป้าหมายช่วงทีมเจ้าบ้าน',
        279 : ' เป้าหมายช่วงทีมเยือน',
        281 : ' 1 5 โอกาสเอเชียนแฮน',
        282 : ' 1X2 Halftime',
        283 : ' เอเชียนแฮน 2 ครึ่ง',
        284 : ' 1X2 2 ครึ่ง',
        285 : ' Odd / Even ครึ่ง 2',
        286 : ' 12 ครั้งที่ 2 ครึ่ง',
        287 : ' ภายใต้ / กว่า 4 งวด - ทีมเจ้าบ้าน',
        288 : ' ภายใต้ / กว่า 4 งวด - ทีมเยือน',
        289 : ' 2 ระยะเวลา Odd / Even - ทีมเจ้าบ้าน',
        290 : ' 3 ระยะเวลา Odd / Even - ทีมเจ้าบ้าน',
        291 : ' 4 ระยะเวลา Odd / Even - ทีมเจ้าบ้าน',
        292 : ' 2 ระยะเวลา Odd / Even - ทีมเยือน',
        293 : ' 3 ระยะเวลา Odd / Even - ทีมเยือน',
        294 : ' 4 ระยะเวลา Odd / Even - ทีมเยือน',
        297 : ' SP',
        299 : ' จุดผู้ชนะ',
        300 : ' ผู้ชนะเกม',
        301 : ' เป้าหมายก่อน',
        302 : ' หลังจากได้ประตู',
        304 : ' ทีมงานที่มีระยะเวลาการให้คะแนนสูงสุด',
        305 : ' มุม - ภายใต้ / เผง / กว่า',
        306 : ' ประตูแรกประตูสุดท้าย',
        307 : ' เกมส์แฮนดิแค็ยุโรป',
        308 : ' ภายใต้ / กว่าระยะเวลาการให้คะแนนสูงสุด',
        309 : ' ภายใต้ / กว่าระยะเวลาให้คะแนนต่ำสุด',
        310 : ' ซีรีส์ชนะเลิศ',
        317 : ' ภายใต้ / เผง / กว่า - ช่วงเวลาที่ 1',
        318 : ' ภายใต้ / เผง / กว่า - ช่วงเวลาที่ 2',
        319 : ' ภายใต้ / เผง / กว่า - ช่วงเวลาที่ 3',
        320 : ' ภายใต้ / เผง / กว่า - ช่วงเวลาที่ 4',
        322 : ' ภายใต้ / เผง / กว่า',
        329 : ' ช่วงที่ 1 ช่วงการแข่งขันเพื่อ',
        330 : ' 2 ระยะเวลาการแข่งขัน',
        331 : ' 3 ระยะเวลาการแข่งขัน',
        332 : ' 4 ระยะเวลาการแข่งขัน',
        333 : ' 5 ระยะเวลาการแข่งขัน',
        337 : ' ภายใต้ / กว่า - ครึ่ง 2',
        338 : ' ระยะเวลา 1 ประตูถัดไป',
        339 : ' เป้าหมายต่อไประยะเวลา 2',
        341 : ' การแข่งขันที่ต้องการ',
        342 : ' เอเชียนแฮนดิแค็รวมการทำงานล่วงเวลา',
        343 : ' ช่วงที่ 1 ระยะเวลาเกม',
        344 : ' ช่วงที่ 2 ระยะเวลาเกม',
        345 : ' ช่วงที่ 3 ระยะเวลาเกม',
        346 : ' ช่วงระยะเวลา 4 เกม',
        347 : ' ช่วงระยะเวลา 5 เกม',
        348 : ' 6 ระยะเวลาผู้ชนะเลิศ',
        349 : ' 7 ระยะเวลาผู้ชนะเลิศ',
        350 : ' 6 ระยะเวลาคู่ / คี่',
        351 : ' 7 ระยะเวลาคู่ / คี่',
        352 : ' ภายใต้ / กว่าระยะเวลา 6',
        353 : ' ภายใต้ / กว่าระยะเวลา 7',
        354 : ' ภายใต้ / กว่า Halftime - ทีมเจ้าบ้าน',
        355 : ' ภายใต้ / กว่า Halftime - ทีมเยือน',
        356 : ' วิธีการของการเลิกจ้าง 6-Way',
        357 : ' การเลิกจ้างวิธี',
        366 : ' วิ่งคู่ / คี่ในกว่า - ทีมเจ้าบ้าน',
        367 : ' รวมวิ่งคู่ / คี่ในกว่า - ทีมเยือน',
        370 : ' รวมทำงานในกว่า - ทีมเจ้าบ้าน',
        371 : ' วิ่งรวมในกว่า - ทีมเยือน',
        386 : ' แฮนดิแค็ยุโรปรวมทั้งการทำงานล่วงเวลา',
        387 : ' Odd / Even - ทำงานล่วงเวลาแรกทีมรวม',
        388 : ' Odd / Even - ทีมเยือนรวมถึงการทำงานล่วงเวลา',
        390 : ' HT / FT รวมทั้งการทำงานล่วงเวลา',
        391 : ' ประตูถัดไป - เวลาพิเศษ',
        392 : ' ทีมเจ้าบ้านยิงลูกโทษ',
        393 : ' ทีมเยือนยิงลูกโทษ',
        394 : ' สกอร์ระยะเวลา 4',
        395 : ' สกอร์ระยะเวลา 5',
        398 : ' Odd / Even การ์ด',
        400 : ' Odd / Even การ์ด 1 ครึ่ง',
        401 : ' ภายใต้ / กว่ามุมที่ 1 ครึ่ง - ทีมเจ้าบ้าน',
        402 : ' ภายใต้ / กว่ามุมที่ 1 ครึ่ง - ทีมเยือน',
        403 : ' ภายใต้ / กว่ามุม 2 ครึ่ง',
        404 : ' ภายใต้ / กว่ามุม 2 ครึ่งเวลาหลัง - ทีมเจ้าบ้าน',
        405 : ' ภายใต้ / กว่ามุม 2 ครึ่งเวลาหลัง - ทีมเยือน',
        406 : ' Odd / Even มุม 1 ครึ่ง',
        407 : ' เอเชียนแฮนการ์ด',
        408 : ' เอเชียนแฮนดิแค็การ์ด 1 ครึ่ง',
        409 : ' 1X2 มุม',
        410 : ' 1X2 มุม 1 ครึ่ง',
        411 : ' 12 มุม',
        414 : ' ภายใต้ / กว่า 1 การ์ดครึ่ง',
        415 : ' 1X2 และทั้งสองทีมทำประตู',
        416 : ' ภายใต้ / กว่า - เวลาพิเศษ',
        417 : ' ภายใต้ / กว่า - เอ็กซ์ตร้า 1 เวลาครึ่ง',
        419 : ' 1X2 - เวลาพิเศษ',
        427 : ' และอยู่ภายใต้ 1X2 / กว่า',
        428 : ' สกอร์หลาย',
        430 : ' ภายใต้ / เผง / กว่า - 1 ครึ่ง',
        431 : ' ภายใต้ / เผง / กว่า - ครึ่ง 2',
        433 : ' มุมแฮนดิแค็ยุโรป',
        434 : ' 8 ระยะเวลาผู้ชนะเลิศ',
        435 : ' 9 ระยะเวลาผู้ชนะเลิศ',
        436 : ' ภายใต้ / กว่าระยะเวลา 8',
        437 : ' ภายใต้ / กว่าระยะเวลา 9',
        438 : ' รวมวิ่งคู่ / คี่ในกว่า',
        439 : ' วิ่งรวมในกว่า',
        445 : ' 6 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        446 : ' 7 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        447 : ' เอเชียนแฮนดิแค็ระยะเวลา 6',
        448 : ' เอเชียนแฮนดิแค็ระยะเวลา 7',
        449 : ' เอเชียนแฮนดิแค็ระยะเวลา 8',
        450 : ' เอเชียนแฮนดิแค็ระยะเวลา 9',
        451 : ' 8 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        452 : ' 9 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        453 : ' คะแนนแรกวิธี',
        454 : ' ครั้งแรกคะแนนวิธี - ทีมเจ้าบ้าน',
        455 : ' คะแนนแรกวิธี - ทีมเยือน',
        456 : ' ดับเบิลโอกาสช่วงที่ 1 ช่วง',
        457 : ' ดับเบิลโอกาสครั้งที่ 2 ระยะเวลา',
        459 : ' หน้าแรกทีมที่ทำประตูในทุกไตรมาส',
        460 : ' ทีมเยือนที่จะยิงประตูในทุกไตรมาส',
        462 : ' 5 อันดับผู้ชนะเลิศ',
        463 : ' 10 สุดยอดผู้ชนะ',
        464 : ' 12 2 ล่วงเวลาครึ่งรวม',
        465 : ' 4 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือนรวมทั้งการทำงานล่วงเวลา',
        466 : ' ภายใต้ / กว่า 4 ระยะเวลารวมทั้งการทำงานล่วงเวลา',
        467 : ' เอเชียนแฮนดิแค็ 4 ระยะเวลารวมทั้งการทำงานล่วงเวลา',
        468 : ' เอเชียนแฮนดิแค็ปครึ่งหลังที่ 2 รวมทั้งการทำงานล่วงเวลา',
        469 : ' ภายใต้ / กว่า 2 ครึ่งรวมค่าล่วงเวลา',
        472 : ' จะไปที่เกมยิง?',
        475 : ' ภายใต้ / กว่าทำงานในกว่า - หน้าแรกของทีมที่ 1 อินนิง',
        476 : ' ภายใต้ / กว่าทำงานในกว่า - ทีมเยือน 1 อินนิง',
        477 : ' ภายใต้ / กว่าทำงานในกว่า - หน้าแรกของ 2 ทีมอินนิง',
        478 : ' ภายใต้ / กว่าทำงานในกว่า - ทีมเยือน 2 อินนิง',
        479 : ' Odd / Even ทำงานในกว่า - หน้าแรกของทีมที่ 1 อินนิง',
        480 : ' Odd / Even ทำงานในกว่า - ทีมเยือน 1 อินนิง',
        481 : ' Odd / Even ทำงานในกว่า - หน้าแรกของ 2 ทีมอินนิง',
        482 : ' Odd / Even ทำงานในกว่า - ทีมเยือน 2 อินนิง',
        483 : ' ทีมเจ้าบ้านจะได้รับประตูในกว่า - อินนิงที่ 1?',
        484 : ' ทีมเยือนจะได้รับประตูในกว่า - อินนิงที่ 1?',
        485 : ' ทีมเจ้าบ้านจะได้รับประตูในกว่า - อินนิง 2?',
        486 : ' ทีมเยือนจะได้รับประตูในกว่า - อินนิงที่ 2?',
        523 : ' ภายใต้ / กว่าและทั้งสองทีมคะแนน',
        524 : ' 1 7 โอกาสชนะเลิศ',
        525 : ' 1 7 โอกาสภายใต้ / กว่า',
        526 : ' 1 7 เอเชียนแฮนโอกาส',
        529 : ' Odd / Even 2 ล่วงเวลาครึ่งรวม',
        553 : ' ภายใต้บทลงโทษ / กว่ายิงนำ',
        554 : ' 12 ยิง',
        555 : ' เอเชียนแฮนดิแค็ยิง',
        556 : ' ยิงสกอร์',
        557 : ' ภายใต้ / กว่ายิง',
        558 : ' ภายใต้ / กว่ายิง - ทีมเจ้าบ้าน',
        559 : ' ภายใต้ / กว่ายิง - ทีมเยือน',
        560 : ' ทีมที่จะใช้ยิงจุดโทษล่าสุด',
        563 : ' การแข่งขันในการรวมการทำงานล่วงเวลา',
        564 : ' และอยู่ภายใต้ 1X2 / กว่า 5 วิธี',
        566 : ' 10 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        567 : ' 11 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        568 : ' 12 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        569 : ' 13 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        570 : ' 14 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        571 : ' วันที่ 15 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        573 : ' 16 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        574 : ' 17 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        575 : ' 18 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        576 : ' 19 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        577 : ' 10 นาทีภายใต้ / กว่า - ลูกเตะมุม',
        578 : ' 10 นาทีภายใต้ / กว่า - บัตร',
        579 : ' มุมถัดไป',
        586 : ' จะเสร็จสิ้นในครึ่งบน',
        587 : ' จะเสร็จสิ้นในครึ่งล่าง',
        588 : ' จะเสร็จสิ้นล่าง',
        589 : ' ไม่ได้จะทำให้เสร็จใน 4 ยอด',
        590 : ' ที่จะถูกลดชั้น',
        591 : ' ไม่ได้ที่จะผลักไส',
        592 : ' ที่จะไปถึงรอบชิงชนะเลิศ',
        593 : ' จะเสร็จสิ้นใน 6 ด้านล่าง',
        594 : ' ที่จะส่งเสริม',
        595 : ' เวลาประตูแรก',
        596 : ' เป้าหมายช่วงแรกทีมระยะเวลา 1',
        597 : ' เป้าหมายช่วงแรกทีมระยะเวลา 2',
        598 : ' เป้าหมายของทีมเยือนช่วงที่ 1 ระยะเวลา',
        599 : ' เป้าหมายของทีมเยือนช่วงที่ 2 ระยะเวลา',
        600 : ' ช่วงที่ 1 ช่วงและผู้ชนะเลิศทั้งสองทีมทำคะแนน',
        601 : ' HT / FT โอกาสคู่',
        602 : ' คอเร็คสกอร์รวมการทำงานล่วงเวลา',
        603 : ' HT ลบ / FT',
        604 : ' เป้าหมายช่วงแรกทีมทั้งงวด',
        605 : ' เป้าหมายช่วงทีมเยือนทั้งสองงวด',
        606 : ' ผู้ชนะเลิศและเป้าหมายประเภทหนึ่ง',
        607 : ' ดับเบิลโอกาสและเป้าหมาย',
        608 : ' ผู้ชนะเลิศและเป้าหมายประเภทสอง',
        609 : ' ทั้งสองทีมคะแนนและเป้าหมาย',
        610 : ' ดับเบิลโอกาสผสม',
        628 : ' จะเสร็จสิ้นใน Top 7',
        629 : ' จะเสร็จสิ้นใน 6 ยอด',
        630 : ' จะเสร็จสิ้นใน Top 3',
        631 : ' จะเสร็จสิ้นในด้านบน 2',
        632 : ' จะเสร็จสิ้นใน Top 4',
        633 : ' จะเสร็จสิ้นใน Top 5',
        634 : ' Odd / Even รอบระยะเวลา 1',
        635 : ' Odd / Even รอบระยะเวลา 2',
        636 : ' Odd / Even รอบระยะเวลา 3',
        637 : ' จะมีคนเก่ง?',
        638 : ' จะมีมีดฆ่า?',
        639 : ' รอบที่ 1 ชนะเลิศ',
        640 : ' 16 รอบชนะเลิศ',
        641 : ' จะมีมีดฆ่า? - ช่วงเวลาที่ 1',
        642 : ' จะมีมีดฆ่า? - ช่วงเวลาที่ 2',
        643 : ' จะมีมีดฆ่า? - ช่วงเวลาที่ 3',
        644 : ' จะมีคนเก่ง? - ช่วงเวลาที่ 1',
        645 : ' จะมีคนเก่ง? - ช่วงเวลาที่ 2',
        646 : ' จะมีคนเก่ง? - ช่วงเวลาที่ 3',
        647 : ' จะมีการทำงานล่วงเวลา? - ช่วงเวลาที่ 1',
        648 : ' จะมีการทำงานล่วงเวลา? - ช่วงเวลาที่ 2',
        649 : ' จะมีการทำงานล่วงเวลา? - ช่วงเวลาที่ 3',
        650 : ' 16 รอบชนะ - ช่วงที่ 1 ช่วง',
        651 : ' 16 รอบชนะเลิศ - ช่วงเวลาที่ 2',
        652 : ' 16 รอบชนะเลิศ - ช่วงเวลาที่ 3',
        653 : ' รอบที่ 1 ชนะ - ช่วงที่ 1 ช่วง',
        654 : ' รอบที่ 1 ชนะ - ระยะเวลา 2',
        655 : ' รอบที่ 1 ชนะ - ระยะเวลา 3',
        660 : ' ระยะเวลาเกม',
        662 : ' Odd / Even รอบ',
        663 : ' ช่วงที่ 1 ช่วงระยะเวลา',
        664 : ' 2 ระยะเวลาระยะเวลา',
        665 : ' 3 ระยะเวลาระยะเวลา',
        666 : ' ช่วงที่ 1 ช่วง - 1 ชาน',
        667 : ' 2 งวด - 1 ชาน',
        668 : ' 3 งวด - 1 ชาน',
        669 : ' ช่วงที่ 1 ช่วง - เลือด 1',
        670 : ' 2 งวด - เลือด 1',
        671 : ' 3 ระยะเวลา - เลือด 1',
        672 : ' ช่วงที่ 1 ช่วง - tou 1',
        673 : ' 2 งวด - tou 1',
        674 : ' 3 งวด - tou 1',
        675 : ' ช่วงที่ 1 ช่วง - ค่ายทหาร 1',
        677 : ' 2 งวด - ค่ายทหาร 1',
        678 : ' 3 งวด - ค่ายทหาร 1',
        679 : ' ช่วงที่ 1 ช่วง - 1 Turret',
        680 : ' 2 งวด - 1 Turret',
        681 : ' 3 งวด - 1 Turret',
        682 : ' การแข่งขันในการฆ่า - ช่วงที่ 1 ช่วง',
        683 : ' การแข่งขันในการฆ่า - ช่วงเวลาที่ 2',
        684 : ' การแข่งขันในการฆ่า - 3 ระยะเวลา',
        685 : ' ทั้งสองทีมจะฆ่ามังกร - ช่วงเวลาที่ 1',
        686 : ' ทั้งสองทีมจะฆ่ามังกร - ช่วงเวลาที่ 2',
        687 : ' ทั้งสองทีมจะฆ่ามังกร - ช่วงเวลาที่ 3',
        688 : ' ทั้งสองทีมจะฆ่ายับยั้ง - การระยะเวลา 1',
        689 : ' ทั้งสองทีมจะฆ่ายับยั้ง - การระยะเวลา 2',
        690 : ' ทั้งสองทีมจะฆ่ายับยั้ง - การระยะเวลา 3',
        691 : ' Odd / Even ฆ่า',
        692 : ' Odd / Even ฆ่า - ช่วงเวลาที่ 1',
        693 : ' Odd / Even ฆ่า - ช่วงเวลาที่ 2',
        694 : ' Odd / Even ฆ่า - ช่วงเวลาที่ 3',
        695 : ' HT / FT และเป้าหมาย',
        697 : ' จะเสร็จสิ้นในยอด 8',
        711 : ' เครื่องเล่นให้คะแนนในทุกที่ทุกเวลา',
        712 : ' ผู้เล่นคนแรกที่จะทำคะแนน',
        713 : ' ผู้เล่นคนสุดท้ายที่จะทำคะแนน',
        714 : ' Player ให้คะแนน 2 หรือมากกว่าเป้าหมาย',
        715 : ' Player ให้คะแนน 3 ประตูหรือมากกว่า',
        727 : ' ช่วงที่ 1 ช่วงทั้งสองทีมทำประตู',
        728 : ' 2 ระยะเวลาทั้งสองทีมทำประตู',
        729 : ' 3 ระยะเวลาทั้งสองทีมทำประตู',
        730 : ' 4 ระยะเวลาทั้งสองทีมทำประตู',
        731 : ' ช่วงที่ 1 ช่วงแรกทีมที่ทำประตู',
        732 : ' 2 ระยะเวลาแรกทีมที่ทำประตู',
        733 : ' 3 ระยะเวลาแรกทีมที่ทำประตู',
        734 : ' 4 ระยะเวลาแรกทีมที่ทำประตู',
        735 : ' ช่วงที่ 1 ช่วงทีมเยือนที่จะทำคะแนน',
        736 : ' 2 ระยะเวลาทีมเยือนที่จะทำคะแนน',
        737 : ' 3 ระยะเวลาทีมเยือนที่จะทำคะแนน',
        738 : ' 4 ระยะเวลาทีมเยือนที่จะทำคะแนน',
        751 : ' การแข่งขันวันที่ 1 ครึ่ง',
        752 : ' 2 ครึ่งซิ่งไป - รวมทั้งการทำงานล่วงเวลา',
        754 : ' เกมผีสาง',
        755 : ' เวลาพิเศษเตะมุม - ภายใต้ / เผง / กว่า',
        756 : ' ภายใต้ / Extra เกินเวลาที่ลูกเตะมุม',
        757 : ' เอเชียนแฮน - เวลาพิเศษ',
        758 : ' 10 นาทีภายใต้ / กว่า - Free Kicks',
        759 : ' 10 นาทีภายใต้ / กว่า - โยนอิน',
        760 : ' 10 นาทีภายใต้ / กว่า - ลูกเตะเข้าประตู',
        762 : ' ที่จะชนะการโยน',
        763 : ' ภายใต้ / กว่าวิ่งในการแข่งขัน',
        765 : ' ที่ทำสกอร์ Fours ส่วนใหญ่',
        766 : ' ภายใต้ / กว่า - Match Fours',
        767 : ' ภายใต้ / กว่า - Match แตก',
        768 : ' ห้าสิบที่ทำได้ในการแข่งขัน',
        769 : ' ร้อยที่ทำได้ในการแข่งขัน',
        770 : ' ส่วนใหญ่เรียกลึกหนาบาง',
        791 : ' ด้านบนลูกบอล - ทีมเจ้าบ้าน',
        792 : ' ด้านบนลูกบอล - ทีมเยือน',
        793 : ' ด้านบนกะลา - ทีมเจ้าบ้าน',
        794 : ' ด้านบนกะลา - ทีมเยือน',
        795 : ' ผู้เล่นทรงคุณค่า',
        806 : ' ลูกจะทำคะแนนห้าสิบในการแข่งขัน',
        808 : ' การแข่งขันในการวิ่ง 10',
        809 : ' สูงสุด 1 6 พากย์คะแนน',
        813 : ' Player ให้คะแนนแตกส่วนใหญ่',
        814 : ' คะแนนสูงสุดแต่ละ',
        819 : ' ที่จะมีการแบ่งจุดในเกม',
        820 : ' การแข่งขันเพื่อลูกเตะมุม',
        822 : ' ทีมใหม่ยอดนิยม',
        824 : ' ผู้เล่นที่จะจอง',
        825 : ' ผู้เล่นจะถูกส่งออก',
        834 : ' ตั้งจุดเสริม',
        835 : ' เอเชียใต้ / กว่า',
        836 : ' เอเชียใต้ / กว่าช่วงที่ 1 ช่วง',
        837 : ' 1 ครึ่งแรกทีมชนะต่อศูนย์ประตู',
        838 : ' 1 ครึ่งทีมเยือนชนะต่อศูนย์ประตู',
        839 : ' 1 ครึ่ง 1X2 และภายใต้ / กว่า',
        840 : ' ช่วงที่ 1 ช่วงแรกทีมชนะต่อศูนย์ประตู',
        841 : ' ช่วงที่ 1 ช่วงทีมเยือนชนะต่อศูนย์ประตู',
        842 : ' ภายใต้ / เผง / กว่า - ทีมเจ้าบ้าน',
        843 : ' ภายใต้ / เผง / กว่า - ทีมเยือน',
        844 : ' ทีมแรกจะให้คะแนน - หน้าแรก / ทีมเยือน',
        845 : ' ทีมที่จะเรียกครั้งแรกออก',
        846 : ' ภายใต้ / กว่าฮิต - รวมการทำงานล่วงเวลา',
        849 : ' ภายใต้ / กว่า 5 อินนิง',
        851 : ' ทีมที่มีคะแนนสูงสุดครึ่ง',
        852 : ' ทั้งสองทีมทำสกอร์ 3 ยังไม่มีคำตอบไทม์',
        853 : ' 12 และภายใต้ / กว่า',
        854 : ' ทีมพิเศษหรือดาว์นป้องกันคะแนน',
        855 : ' ชี้ช่วง',
        859 : ' 147 แบ่งในการแข่งขัน',
        861 : ' ครั้งแรกคะแนนวิธีที่ 3 Way - ทีมเจ้าบ้าน',
        862 : ' คะแนนแรกวิธีที่ 3 Way - ทีมเยือน',
        863 : ' ทีมงานที่มีเกณฑ์การให้คะแนนสูงสุด -1X2 ระยะเวลา',
        864 : ' ทีมทำประตูแรก',
        865 : ' ทีมทำประตูสุดท้าย',
        866 : ' ชุดเอเชียนแฮน',
        870 : ' ครั้งแรกคะแนนวิธีที่ 6 ทางกลับบ้าน / ทีมเยือน',
        878 : ' เป้าหมายช่วงที่ 3 ระยะเวลา',
        879 : ' ภายใต้พยายาม / กว่า',
        880 : ' ครึ่งลูกเตะมุมครั้งแรก - ภายใต้ / เผง / กว่า',
        881 : ' ประตูแรกวิธี',
        882 : ' จำนวนจุด',
        891 : ' ทีมที่ทำประตูที่ยาวที่สุดดาว์น',
        892 : ' ทีมทำประตูที่ยาวที่สุดฟิลด์',
        893 : ' ที่ยาวที่สุดดาว์นคะแนน',
        894 : ' ดาว์นที่สั้นที่สุดคะแนน',
        895 : ' ที่ยาวที่สุดในสนามยิง',
        896 : ' เวลาของทีมแรกเป้าหมาย',
        897 : ' คะแนนเกมหลังจากนั้น 2 จุด',
        898 : ' คะแนนเกมหลังจาก 3 คะแนน',
        899 : ' คะแนนเกมหลังจาก 4 คะแนน',
        900 : ' เกมที่จะทำคะแนน',
        901 : ' ช่วงที่ 1 ช่วงหลังจากตะกั่ว',
        902 : ' 2 ระยะเวลาหลังจากตะกั่ว',
        903 : ' 3 ระยะเวลาหลังจากตะกั่ว',
        904 : ' 4 ระยะเวลาหลังจากตะกั่ว',
        905 : ' 5 ระยะเวลาหลังจากตะกั่ว',
        906 : ' คะแนนหลังจาก 4 เกม',
        907 : ' คะแนนหลังจาก 6 เกม',
        908 : ' ที่จะทำลายเสิร์ฟในชุด',
        909 : ' หน้าแรกที่จะชนะในชุด',
        910 : ' ออกไปที่จะชนะในชุด',
        911 : ' คะแนนหลังจากนั้น 2 ชุด',
        912 : ' คะแนนหลังจาก 3 ชุด',
        914 : ' ภายใต้เป้าหมาย / กว่าสนามคะแนน - 1 ครึ่ง',
        915 : ' ภายใต้ / กว่าทัชดาวน์คะแนน - 1 ครึ่ง',
        916 : ' ภายใต้ / กว่าทีมเจ้าบ้านทัชดาวน์ - 1 ครึ่ง',
        917 : ' ภายใต้ / กว่าทีมเยือนทัชดาวน์ - 1 ครึ่ง',
        920 : ' ภายใต้ / กว่าทัชดาวน์',
        921 : ' ภายใต้ / เผง / กว่า - ทัชดาวน์',
        922 : ' ภายใต้เป้าหมาย / กว่าสนาม',
        923 : ' ภายใต้ / เผง / กว่า - เป้าหมายสนาม',
        924 : ' เวลาของสนามแรกยิง',
        925 : ' เวลาแรกดาว์นคะแนน',
        926 : ' เวลาของการทำประตูก่อน',
        927 : ' ทีมเจ้าบ้านภายใต้ / กว่าทัชดาวน์',
        928 : ' ทีมเยือนภายใต้ / กว่าทัชดาวน์',
        929 : ' ทีมเจ้าบ้านภายใต้ / เป้าหมายทั่วสนาม',
        930 : ' ห่างเป้าหมายของทีมภายใต้ / กว่าฟิลด์',
        932 : ' ทีมโค้ชครั้งแรกที่ท้าทาย',
        933 : ' ทีมที่จะกระทำการครั้งแรกที่ได้รับการยอมรับการลงโทษ',
        934 : ' ความปลอดภัยคะแนน',
        935 : ' แฮนดิแค็การ์ดยุโรป',
        936 : ' การ์ดเอเชียใต้ / กว่า',
        938 : ' ภายใต้ / การ์ดกว่า - ทีมเจ้าบ้าน',
        939 : ' ภายใต้ / การ์ดกว่า - ทีมเยือน',
        940 : ' เวลาของบัตรครั้งแรก',
        941 : ' ถัดไปผู้เล่นจะได้คะแนน',
        942 : ' สูงสุดสัญชาติ - เอเชีย',
        943 : ' สูงสุดสัญชาติ - ออสเตรเลีย',
        944 : ' สูงสุดสัญชาติ - ยุโรป',
        945 : ' สูงสุดสัญชาติ - สหราชอาณาจักรและไอร์แลนด์',
        946 : ' สูงสุดสัญชาติ - ส่วนที่เหลือของโลก',
        947 : ' ภายใต้ / กว่าเอซ',
        948 : ' ภายใต้ / กว่าเอซ - ทีมเจ้าบ้าน',
        949 : ' จำนวนที่แน่นอนของเอซ',
        950 : ' ภายใต้ / กว่าเอซ - ทีมเยือน',
        951 : ' จำนวนที่ถูกต้องของเอซ',
        955 : ' 1X2 เอซ',
        956 : ' จำนวนที่แน่นอนเอซ - ทีมเจ้าบ้าน',
        957 : ' จำนวนที่แน่นอนของเอซ - ทีมเยือน',
        958 : ' เอเชียนแฮนเอซ',
        959 : ' การแข่งขันเพื่อให้เอซ',
        960 : ' ภายใต้ / กว่าเอซ - ช่วงเวลาที่ 1',
        976 : ' 1X2 เอซ - ช่วงที่ 1 ช่วง',
        982 : ' เอซเอเชียแฮนดิแค็ - ช่วงที่ 1 ช่วง',
        986 : ' 12 เอซ',
        989 : ' ภายใต้ / กว่าฆ่า - ช่วงเวลาที่ 1',
        990 : ' ภายใต้ / กว่าฆ่า - ช่วงเวลาที่ 2',
        991 : ' ภายใต้ / กว่าฆ่า - ช่วงเวลาที่ 3',
        992 : ' ถัดไปดาว์นแต้ม - ทีมเจ้าบ้าน',
        993 : ' ถัดไปดาว์นแต้ม - ทีมเยือน',
        994 : ' ถัดไปดาว์นแต้ม',
        995 : ' Player ให้คะแนนทัชดาวน์ในทุกที่ทุกเวลา',
        996 : ' Player ให้คะแนนหรือมากกว่า 2 ทัชดาวน์',
        997 : ' Player ให้คะแนน 3 หรือมากกว่าทัชดาวน์',
        998 : ' มุมนัดแรก - 12',
        999 : ' ล่าสุดการแข่งขัน Corner - 12',
        1000 : ' จำนวนของชุด',
        1001 : ' ภายใต้ความผิดพลาด / กว่าคู่',
        1002 : ' ภายใต้ความผิดพลาด / กว่าคู่ - ทีมเยือน',
        1003 : ' ภายใต้ความผิดพลาด / กว่าคู่ - ทีมเจ้าบ้าน',
        1004 : ' ภายใต้ความผิดพลาด / กว่าคู่ - ระยะเวลา 1',
        1010 : ' 12 ความผิดพลาดคู่',
        1011 : ' 12 ความผิดพลาดคู่ - ช่วงที่ 1 ช่วง',
        1013 : ' ผิด 1X2 คู่',
        1016 : ' จำนวนที่แน่นอนของความผิดพลาดที่มีเตียงคู่',
        1019 : ' จำนวนที่แน่นอนของความผิดพลาดคู่ - ทีมเจ้าบ้าน',
        1020 : ' จำนวนที่แน่นอนของความผิดพลาดคู่ - ทีมเยือน',
        1027 : ' หมายเลขที่ถูกต้องของความผิดพลาดที่มีเตียงคู่',
        1030 : ' เวลาของการเปิดเตะมุมครั้งแรก',
        1031 : ' มุมหลาย',
        1034 : ' 20 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1035 : ' 21 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1036 : ' 22 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1037 : ' วันที่ 23 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1038 : ' วันที่ 24 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1039 : ' วันที่ 25 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1040 : ' วันที่ 26 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1041 : ' วันที่ 27 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1042 : ' วันที่ 28 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1043 : ' วันที่ 29 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1044 : ' วันที่ 30 ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1046 : ' 32th ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1047 : ' 33th ระยะเวลาผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1050 : ' ซิ่งไป 20 คะแนน',
        1051 : ' ภายใต้ / กว่า 2 ครึ่งเวลาหลัง - ทีมเจ้าบ้าน',
        1052 : ' ภายใต้ / กว่า 2 ครึ่งเวลาหลัง - ทีมเยือน',
        1053 : ' เอเชียใต้ / กว่า 2 ระยะเวลา',
        1054 : ' เอเชียใต้ / กว่า 3 ระยะเวลา',
        1055 : ' เอเชียใต้ / ระยะเวลากว่า 4',
        1061 : ' เอเชียใต้ / กว่ารวมค่าล่วงเวลา',
        1062 : ' เกณฑ์การให้คะแนนสูงสุดครึ่ง - ทีมเจ้าบ้าน',
        1063 : ' เกณฑ์การให้คะแนนสูงสุดครึ่ง - ทีมเยือน',
        1065 : ' ทีมเจ้าบ้าน - ครั้งแรกที่ทำประตู',
        1066 : ' ทีมเจ้าบ้าน - ทำประตูสุดท้าย',
        1067 : ' ทีมเยือน - ครั้งแรกที่ทำประตู',
        1068 : ' ทีมเยือน - ทำประตูสุดท้าย',
        1069 : ' ภายใต้ / กว่าจุดเล่น',
        1070 : ' ภายใต้ / กว่าผู้เล่นบล็อก',
        1071 : ' ภายใต้ / กว่าผู้เล่นช่วย',
        1072 : ' ภายใต้ / กว่าผู้เล่นรีบาวด์',
        1073 : ' ภายใต้ / กว่าผู้เล่นขโมย',
        1074 : ' ภายใต้ / กว่าเครื่องเล่นหลากสี',
        1075 : ' ภายใต้ / กว่าผู้เล่น 3 คะแนนทำ',
        1076 : ' ภายใต้ / กว่าผู้เล่น 3 คะแนนพยายาม',
        1077 : ' ภายใต้ / กว่าผู้เล่นที่ 2 สิ่งที่น่าทำ',
        1078 : ' ภายใต้ / กว่าผู้เล่น 2 จุดพยายาม',
        1079 : ' ภายใต้ / กว่าผู้เล่นโยนฟรีทำ',
        1080 : ' ภายใต้ / กว่าผู้เล่นพยายามโยนฟรี',
        1081 : ' บัตรเหลือง 1X2',
        1082 : ' บัตรเหลืองดับเบิลโอกาส',
        1083 : ' บัตรเหลืองเอเชียนแฮน',
        1084 : ' บัตรเหลืองที่ถูกต้องคะแนน',
        1085 : ' ภายใต้ / กว่าการ์ดแดง',
        1086 : ' ภายใต้ / เผง / จุดที่จองห้องพักกว่า',
        1087 : ' ภายใต้ / เผง / จุดที่จองห้องพักกว่า - ทีมเจ้าบ้าน',
        1088 : ' ภายใต้ / เผง / จุดที่จองห้องพักกว่า - ทีมเยือน',
        1089 : ' ภายใต้ / กว่าขโมยเครื่องเล่นและบล็อก',
        1090 : ' ภายใต้ / กว่าจุดเล่น',
        1091 : ' ภายใต้ / กว่าช่วยผู้เล่นและรีบาวด์',
        1092 : ' ภายใต้ / กว่าคะแนนของผู้เล่นและรีบาวด์',
        1093 : ' ภายใต้ / กว่าคะแนนผู้เล่นและผู้ช่วย',
        1094 : ' เครื่องเล่นคู่',
        1095 : ' เครื่องเล่นริปเปิลดับเบิล',
        1096 : ' ภายใต้ / เผง / จุดที่จองห้องพักกว่า - ช่วงที่ 1 ช่วง',
        1100 : ' ชุดผู้ชนะ',
        1101 : ' ภายใต้ / กว่า 180 \ ในชุด',
        1102 : ' ตั้ง / ผู้ชนะขา',
        1103 : ' ตั้ง / ขารวม Checkouts ภายใต้ / กว่า',
        1105 : ' ภายใต้ / กว่ารวม 180 \ s - ทีมเจ้าบ้าน',
        1106 : ' ภายใต้ / กว่ารวม 180 \ s - ทีมเยือน',
        1112 : ' ภายใต้เป้าหมาย / กว่าผู้เล่น',
        1115 : ' ภายใต้เป้าหมาย / กว่าผู้เล่นที่ได้รับอนุญาต',
        1120 : ' ภายใต้รอบ / กว่า - ช่วงเวลาที่ 1',
        1121 : ' ภายใต้รอบ / กว่า - ช่วงเวลาที่ 2',
        1122 : ' ภายใต้รอบ / กว่า - ช่วงเวลาที่ 3',
        1123 : ' 4 ระยะเวลา - เลือด 1',
        1124 : ' 5 งวด - เลือด 1',
        1125 : ' ทั้งสองทีมจะฆ่ามังกร',
        1126 : ' ทั้งสองทีมจะฆ่าบารอน',
        1127 : ' ทั้งสองทีมจะฆ่ายับยั้ง',
        1128 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า',
        1129 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า - ช่วงเวลาที่ 1',
        1130 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า - ช่วงเวลาที่ 2',
        1131 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า - ช่วงเวลาที่ 3',
        1132 : ' ภายใต้ / กว่ามังกรฆ่า',
        1133 : ' ภายใต้ / กว่ามังกรฆ่า - ช่วงเวลาที่ 1',
        1134 : ' ภายใต้ / กว่ามังกรฆ่า - ช่วงเวลาที่ 2',
        1135 : ' ภายใต้ / กว่ามังกรฆ่า - ช่วงเวลาที่ 3',
        1136 : ' ภายใต้ / กว่า 180 \ s',
        1137 : ' ครั้งแรก 180',
        1138 : ' ล่าสุด 180',
        1139 : ' ภายใต้ / กว่าคะแนนสูงสุดกร้า',
        1140 : ' ส่วนใหญ่ 180 \ s',
        1141 : ' ภายใต้ / กว่าคะแนนสูงสุดร้า - ทีมเจ้าบ้าน',
        1142 : ' ภายใต้ / กว่าคะแนนสูงสุดร้า - ทีมเยือน',
        1143 : ' ผู้เล่นคนแรกที่จะทำคะแนนทัชดาวน์',
        1144 : ' ผู้เล่นคนสุดท้ายที่จะทำคะแนนทัชดาวน์',
        1145 : ' แรก Player ให้คะแนนทัชดาวน์ - ทีมเจ้าบ้าน',
        1146 : ' แรก Player ให้คะแนนทัชดาวน์ - ทีมเยือน',
        1147 : ' ภายใต้ / กว่าฆ่า - ช่วงเวลาที่ 4',
        1148 : ' ภายใต้ / กว่าฆ่า - ระยะเวลา 5',
        1149 : ' เอเชียนแฮนดิแค็ฆ่า - ช่วงที่ 1 ช่วง',
        1150 : ' เอเชียนแฮนดิแค็ฆ่า - ช่วงเวลาที่ 2',
        1151 : ' เอเชียนแฮนดิแค็ฆ่า - ช่วงเวลาที่ 3',
        1152 : ' เอเชียนแฮนดิแค็ฆ่า - ช่วงเวลาที่ 4',
        1153 : ' เอเชียนแฮนดิแค็ฆ่า - ระยะเวลา 5',
        1154 : ' ภายใต้ / เผง / กว่า 180 \ s',
        1155 : ' ภายใต้ / เผง / กว่ารวม 180 \ s - ทีมเจ้าบ้าน',
        1156 : ' ภายใต้ / เผง / กว่ารวม 180 \ s - ทีมเยือน',
        1157 : ' เช็คเอาต์มากที่สุด',
        1158 : ' แรกลองแต้ม',
        1159 : ' ลองแต้มล่าสุด',
        1160 : ' ทุกที่ทุกเวลาลองแต้ม',
        1162 : ' ภายใต้ / กว่า tous ทำลาย - ช่วงเวลาที่ 1',
        1163 : ' ภายใต้ / กว่า tous ทำลาย - ช่วงเวลาที่ 2',
        1164 : ' ภายใต้ / กว่า tous ทำลาย - ช่วงเวลาที่ 3',
        1165 : ' ช่วงที่ 1 ช่วง - 1 บารอน',
        1166 : ' 2 งวด - 1 บารอน',
        1167 : ' 3 งวด - 1 บารอน',
        1168 : ' 4 งวด - 1 บารอน',
        1169 : ' 5 งวด - 1 บารอน',
        1170 : ' ช่วงที่ 1 ช่วง - 1 มังกร',
        1171 : ' 2 งวด - 1 มังกร',
        1172 : ' 3 งวด - 1 มังกร',
        1173 : ' 4 งวด - 1 มังกร',
        1174 : ' 5 งวด - 1 มังกร',
        1175 : ' ฆ่าตัวมากที่สุด - ช่วงที่ 1 ช่วง',
        1176 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 2',
        1177 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 3',
        1178 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 4',
        1179 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 5',
        1180 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 6',
        1181 : ' ฆ่าตัวมากที่สุด - ระยะเวลา 7',
        1182 : ' Player ให้คะแนน 2 หรือมากกว่า Trys',
        1183 : ' Player ให้คะแนน 3 หรือมากกว่า Trys',
        1184 : ' ไม่ได้ที่จะชนะสาธารณรัฐเช็ก',
        1185 : ' ภายใต้ / เผง / กว่ารวมค่าล่วงเวลา - ทีมเจ้าบ้าน',
        1186 : ' ภายใต้ / เผง / กว่ารวมค่าล่วงเวลา - ทีมเยือน',
        1187 : ' ภายใต้ / เผง / กว่ารวมค่าล่วงเวลา',
        1188 : ' ภายใต้ / กว่าแทน',
        1189 : ' ภายใต้ / กว่าแทน - ช่วงเวลาที่ 1',
        1190 : ' ผู้เล่นคนสุดท้ายเพื่อคะแนนทัชดาวน์ - ทีมเจ้าบ้าน',
        1191 : ' ผู้เล่นคนสุดท้ายเพื่อคะแนนทัชดาวน์ - ทีมเยือน',
        1192 : ' ผู้เล่นคนแรกที่จะตีเอซ',
        1193 : ' ภายใต้ / กว่าผู้เล่นกองหลังหลาผ่าน',
        1194 : ' ภายใต้ / กว่าผู้เล่นผ่านทัชดาวน์',
        1195 : ' ภายใต้ / กว่าที่ยาวที่สุดของผู้เล่นผ่านแล้วเสร็จ',
        1196 : ' ภายใต้ / กว่าผู้เล่นรับหลา',
        1197 : ' ภายใต้ / กว่าผู้เล่นเซพชั่น',
        1198 : ' ภายใต้ / กว่าผู้เล่นเตะจุด',
        1199 : ' ภายใต้ / กว่าผู้เล่นวิ่งและรับหลา',
        1200 : ' ภายใต้ / กว่าเครื่องเล่นหลา',
        1201 : ' ภายใต้ / กว่าผู้เล่นที่ยาวที่สุดของแผนกต้อนรับ',
        1202 : ' ภายใต้ / กว่าผู้เล่นออกงาน',
        1203 : ' ภายใต้เป้าหมาย / กว่าเครื่องเล่นสนามทำ',
        1204 : ' ภายใต้เป้าหมาย / กว่าเครื่องเล่นสนามพยายาม',
        1205 : ' ภายใต้ / กว่าผู้เล่นกองหลังผ่านทัชดาวน์',
        1206 : ' ภายใต้ / กว่าผู้เล่นตัวรับสัญญาณที่สำเร็จแผนกต้อนรับ',
        1207 : ' ภายใต้ / กว่าผู้เล่นกองหลังและผ่านที่เสร็จสมบูรณ์',
        1208 : ' ภายใต้ / กว่าผู้เล่นวิ่งทำทัชดาวน์',
        1209 : ' ภายใต้ / กว่าผู้เล่นตัวรับสัญญาณความพยายามที่แผนกต้อนรับ',
        1210 : ' ภายใต้ / กว่าผู้เล่นกองหลังและผ่านความพยายาม',
        1211 : ' 1X2 กติกา',
        1212 : ' ดับเบิลโอกาสกติกา',
        1213 : ' ภายใต้ / กว่ากติกา - ช่วงเวลาที่ 1',
        1214 : ' ภายใต้ / กว่ากติกา - ช่วงเวลาที่ 2',
        1215 : ' เอเชียนแฮนดิแค็กติกา',
        1216 : ' ภายใต้ / กว่ากติกา - ทีมเจ้าบ้าน',
        1217 : ' ภายใต้ / กว่ากติกา - ทีมเยือน',
        1218 : ' ภายใต้ความพยายาม / กว่าผู้เล่น Rush',
        1219 : ' ภายใต้ / กว่าผู้เล่นรับทัชดาวน์',
        1222 : ' ภายใต้ / กว่าโยน-Ins - ทีมเจ้าบ้าน',
        1223 : ' ภายใต้ / กว่าโยน-Ins - ทีมเยือน',
        1224 : ' 1X2 โยนเพิ่มเติม',
        1225 : ' ดับเบิลโอกาสโยนเพิ่มเติม',
        1226 : ' ภายใต้ / กว่าโยน-Ins - ช่วงเวลาที่ 1',
        1227 : ' โยนอินแฮนดิแค็',
        1228 : ' โยนอินแฮนดิแค็ - ช่วงเวลาที่ 1',
        1229 : ' ภายใต้ / ภาพกว่าบนเป้าหมาย - ทีมเจ้าบ้าน',
        1230 : ' ภายใต้ / ภาพกว่าบนเป้าหมาย - ทีมเยือน',
        1231 : ' ภาพเอเชียนแฮนบนเป้าหมาย',
        1232 : ' ภาพเอเชียนแฮนไปตามเป้าหมาย - ช่วงที่ 1 ช่วง',
        1233 : ' ภายใต้ / ยิงกว่าเป้าหมาย - ช่วงเวลาที่ 1',
        1234 : ' 1X2 นัดเป้าหมาย',
        1235 : ' ภาพคู่มีโอกาสในเป้าหมาย',
        1236 : ' ภายใต้ภาพ / กว่ารวม - ทีมเจ้าบ้าน',
        1237 : ' ภายใต้ภาพ / กว่ารวม - ทีมเยือน',
        1238 : ' ภาพคู่มีโอกาสรวม',
        1239 : ' ภาพรวม 1X2',
        1240 : ' เอเชียนแฮนดิแค็ภาพรวม',
        1241 : ' เอเชียนแฮนดิแค็ภาพรวม - ช่วงที่ 1 ช่วง',
        1242 : ' ภายใต้ภาพ / กว่ารวม - ช่วงเวลาที่ 1',
        1243 : ' ดับเบิลโอกาสลูกเตะมุม',
        1244 : ' ดับเบิลโอกาสเตะมุม - ช่วงที่ 1 ช่วง',
        1245 : ' ลูกเตะมุมจากทั้งหมด 4 มุมของสนาม',
        1246 : ' บัตรเหลืองดับเบิลโอกาส - ช่วงเวลาที่ 1',
        1247 : ' บัตรเหลือง 1X2 - ช่วงที่ 1 ช่วง',
        1248 : ' บัตรเหลืองเอเชียนแฮน - ช่วงที่ 1 ช่วง',
        1249 : ' ดับเบิลโอกาสและภายใต้ / กว่า',
        1250 : ' ดับเบิลโอกาสและทั้งสองทีมคะแนน',
        1251 : ' ช่วงที่ 1 ช่วง - ยับยั้ง 1',
        1252 : ' 2 งวด - ยับยั้ง 1',
        1253 : ' 3 งวด - ยับยั้ง 1',
        1254 : ' 4 งวด - ยับยั้ง 1',
        1255 : ' 5 งวด - ยับยั้ง 1',
        1256 : ' ทั้งสองทีมจะฆ่าบารอน - ช่วงที่ 1 ช่วง',
        1257 : ' ทั้งสองทีมจะฆ่าบารอน - ช่วงเวลาที่ 2',
        1258 : ' ทั้งสองทีมจะฆ่าบารอน - ช่วงเวลาที่ 3',
        1259 : ' ทั้งสองทีมจะฆ่าบารอน - ช่วงเวลาที่ 4',
        1260 : ' ทั้งสองทีมจะฆ่าบารอน - ระยะเวลา 5',
        1261 : ' ทั้งสองทีมจะฆ่ามังกร - ช่วงเวลาที่ 4',
        1262 : ' ทั้งสองทีมจะฆ่ามังกร - ระยะเวลา 5',
        1263 : ' ทั้งสองทีมจะฆ่ายับยั้ง - การระยะเวลา 4',
        1264 : ' ทั้งสองทีมจะฆ่ายับยั้ง - การระยะเวลา 5',
        1265 : ' จะมีเกมของเอสต่อไปหรือไม่',
        1266 : ' จะมีความผิดคู่เกมต่อไปหรือไม่',
        1267 : ' Odd / Even กติกา',
        1268 : ' เอเชียนแฮนดิแค็ครอบครองบอล',
        1269 : ' ดับเบิลโอกาสล้ำหน้า',
        1270 : ' เอเชียนแฮนดิแค็ล้ำหน้า',
        1271 : ' ภายใต้ / กว่าล้ำหน้า - ทีมเจ้าบ้าน',
        1272 : ' ภายใต้ / กว่าล้ำหน้า - ทีมเยือน',
        1273 : ' 1X2 ล้ำหน้า',
        1274 : ' ภายใต้ / กว่าฆ่าที่ 1 ระยะเวลา - ทีมเจ้าบ้าน',
        1275 : ' ภายใต้ / กว่าฆ่า 2 งวด - ทีมเจ้าบ้าน',
        1276 : ' ภายใต้ / กว่าฆ่า 3 งวด - ทีมเจ้าบ้าน',
        1277 : ' ภายใต้ / กว่าฆ่าที่ 1 ระยะเวลา - ทีมเยือน',
        1278 : ' ภายใต้ / กว่าฆ่า 2 งวด - ทีมเยือน',
        1279 : ' ภายใต้ / กว่าฆ่า 3 งวด - ทีมเยือน',
        1280 : ' ภายใต้ / กว่า Wides',
        1281 : ' ภายใต้เป็ด / กว่า',
        1282 : ' ภายใต้ / กว่า Wickets',
        1283 : ' ภายใต้ / กว่า Match Fours - ทีมเจ้าบ้าน',
        1284 : ' ภายใต้ / กว่า Match แตก - ทีมเจ้าบ้าน',
        1285 : ' ภายใต้ / กว่า Match Fours - ทีมเยือน',
        1286 : ' ภายใต้ / กว่า Match แตก - ทีมเยือน',
        1287 : ' ภายใต้ / กว่าพิเศษ',
        1288 : ' การล่มสลายของ 1 ประตู - ทีมเจ้าบ้าน',
        1289 : ' การล่มสลายของ 1 ประตู - ทีมเยือน',
        1290 : ' ทีมงานของด้านบนลูกบอล',
        1291 : ' ภายใต้ / กว่า Match แตกทีมเจ้าบ้าน - อินนิงที่ 1',
        1292 : ' ภายใต้ / กว่า Match แตกทีมเยือน - อินนิงที่ 1',
        1293 : ' ภายใต้ / กว่า Match Fours ทีมเจ้าบ้าน - อินนิงที่ 1',
        1294 : ' ภายใต้ / กว่า Match Fours ทีมเยือน - อินนิงที่ 1',
        1295 : ' ภายใต้ / เหนือด้านบนลูกบอล',
        1296 : ' 1 โอกาสที่ 1 กว่ายกฟ้อง - ทีมเจ้าบ้าน',
        1297 : ' 1 อินนิงที่ 2 ในรอบยกฟ้อง - ทีมเจ้าบ้าน',
        1298 : ' 1 อินนิงที่ 3 ในรอบยกฟ้อง - ทีมเจ้าบ้าน',
        1299 : ' 1 อินนิงที่ 2 ในรอบยกฟ้อง - ทีมเยือน',
        1300 : ' 1 อินนิงที่ 3 ในรอบยกฟ้อง - ทีมเยือน',
        1301 : ' 1 อินนิงที่ 1 กว่ายกฟ้อง - ทีมเยือน',
        1302 : ' เกณฑ์การให้คะแนนที่สูงที่สุดในรอบ - ภายใต้ / กว่า',
        1303 : ' 1 อินนิงที่ 2 รอบภายใต้ / กว่า - ทีมเจ้าบ้าน',
        1304 : ' 1 อินนิงที่ 3 ในรอบภายใต้ / กว่า - ทีมเจ้าบ้าน',
        1306 : ' 1 อินนิงที่ 3 ในรอบภายใต้ / กว่า - ทีมเยือน',
        1307 : ' 1 อินนิงที่ 2 รอบภายใต้ / กว่า - ทีมเยือน',
        1309 : ' ชนะ Margin - ทีม',
        1310 : ' ชนะ Margin - ทำงานล่วงเวลาทีมรวม',
        1312 : ' ชนะหลักประกันที่แน่นอน',
        1314 : ' ชนะช่วง Margin',
        1315 : ' ชนะหลักประกันที่แน่นอน - ทีม',
        1316 : ' ชนะ Margin - ทีมที่มีคะแนนใด ๆ อื่น ๆ',
        1318 : ' กติกาเอเชียนแฮน - ช่วงที่ 1 ช่วง',
        1319 : ' เอเชียนแฮนดิแค็ล้ำหน้า - ช่วงที่ 1 ช่วง',
        1320 : ' เอเชียนแฮนดิแค็ล้ำหน้า - ช่วงเวลาที่ 2',
        1321 : ' กติกาเอเชียนแฮน - ช่วงเวลาที่ 2',
        1322 : ' ภาพเอเชียนแฮนไปตามเป้าหมาย - ช่วงเวลาที่ 2',
        1324 : ' ภายใต้ / กว่าล้ำหน้า - ช่วงเวลาที่ 1',
        1325 : ' ภายใต้ / กว่าล้ำหน้า - ช่วงเวลาที่ 2',
        1326 : ' ฟาวล์ครั้งแรก',
        1327 : ' สองคนแรกชี้',
        1328 : ' ครั้งแรกที่สามตัวชี้',
        1329 : ' ล่าสุดสองชี้',
        1330 : ' ล่าสุดสามตัวชี้',
        1331 : ' ล่าสุด Free Throw คะแนน',
        1332 : ' ครั้งแรก Free Throw คะแนน',
        1333 : ' Rebound แรก',
        1334 : ' หมดเวลาล่าสุด',
        1335 : ' ชนะทีม Margin - ช่วงเวลาที่ 1',
        1336 : ' ชนะทีม Margin - ช่วงเวลาที่ 2',
        1337 : ' ชนะทีม Margin - ช่วงเวลาที่ 3',
        1338 : ' ชนะทีม Margin - ช่วงเวลาที่ 4',
        1339 : ' ชนะทีม Margin - ระยะเวลา 5',
        1340 : ' ฟาวล์ล่าสุด',
        1341 : ' ภายใต้ / กว่า 10 ระยะเวลา',
        1342 : ' ภายใต้ / กว่า 11 ระยะเวลา',
        1343 : ' ภายใต้ / กว่า 12 ระยะเวลา',
        1344 : ' ภายใต้ / กว่า 13 ระยะเวลา',
        1345 : ' ภายใต้ / กว่าระยะเวลา 14',
        1346 : ' ภายใต้ / กว่า 15 ระยะเวลา',
        1347 : ' ภายใต้ / กว่า 16 ระยะเวลา',
        1348 : ' ภายใต้ / กว่า 17 ระยะเวลา',
        1349 : ' ภายใต้ / กว่า 18 ระยะเวลา',
        1350 : ' ภายใต้ / กว่าระยะเวลา 19',
        1351 : ' ภายใต้ / กว่าระยะเวลา 20',
        1352 : ' ภายใต้ / กว่า 21 ระยะเวลา',
        1353 : ' ภายใต้ / กว่า 22 ระยะเวลา',
        1354 : ' ภายใต้ระยะเวลา 23 / กว่า',
        1355 : ' ภายใต้ / กว่า 24 ระยะเวลา',
        1356 : ' ภายใต้ / กว่า 25 ระยะเวลา',
        1357 : ' ภายใต้ / กว่า 26 ระยะเวลา',
        1358 : ' ภายใต้ / กว่า 27 ระยะเวลา',
        1359 : ' ภายใต้ / กว่า 28 ระยะเวลา',
        1360 : ' ภายใต้ / กว่า 29 ระยะเวลา',
        1361 : ' ภายใต้ / กว่า 30 ระยะเวลา',
        1362 : ' ภายใต้ / กว่า 31 ระยะเวลา',
        1363 : ' ภายใต้ / กว่า 32 ระยะเวลา',
        1364 : ' ภายใต้ระยะเวลา 33 / กว่า',
        1365 : ' ภายใต้ / กว่า 34 ระยะเวลา',
        1366 : ' ภายใต้ / กว่า 35 ระยะเวลา',
        1367 : ' เอเชียนแฮนระยะเวลา 11',
        1368 : ' เอเชียนแฮนระยะเวลา 12',
        1369 : ' แฮนดิแค็เอเชี่ยนเกมส์ครั้งที่ 13 ระยะเวลา',
        1370 : ' เอเชียนแฮนระยะเวลา 14',
        1371 : ' เอเชียนแฮนระยะเวลา 15',
        1372 : ' เอเชียนแฮนระยะเวลา 16',
        1373 : ' เอเชียนแฮนระยะเวลา 17',
        1374 : ' เอเชียนแฮนระยะเวลา 18',
        1375 : ' เอเชียนแฮนระยะเวลา 19',
        1376 : ' เอเชียนแฮนระยะเวลา 20',
        1380 : ' เอเชียนแฮนระยะเวลา 24',
        1381 : ' เอเชียนแฮนวันที่ 25 ระยะเวลา',
        1382 : ' เอเชียนแฮนระยะเวลา 26',
        1383 : ' เอเชียนแฮนระยะเวลา 27',
        1384 : ' เอเชียนแฮนระยะเวลา 28',
        1385 : ' เอเชียนแฮนระยะเวลา 29',
        1386 : ' เอเชียนแฮนวันที่ 30 ระยะเวลา',
        1390 : ' เอเชียนแฮนระยะเวลา 34',
        1391 : ' เอเชียนแฮนระยะเวลา 35',
        1392 : ' จะเสร็จสิ้นใน 10 สุดยอด',
        1393 : ' จะอยู่ถึง',
        1394 : ' เอเชียนแฮนระยะเวลา 10',
        1395 : ' ภายใต้จุด / กว่าผู้เล่น Powerplay',
        1396 : ' ภายใต้ / กว่าผู้เล่นบวก / ลบ',
        1397 : ' ภายใต้ / กว่าผู้เล่นช็อต',
        1398 : ' สูงสุดวิคตอเรียคลับ',
        1399 : ' ภายใต้ / กว่า Run ลึกหนาบาง',
        1400 : ' ภายใต้ / กว่ากรอบ',
        1401 : ' 20 อันดับผู้ชนะเลิศ',
        1402 : ' 1 ผู้นำรอบ',
        1404 : ' รอบที่ 1 หก Shooter - กลุ่ม A',
        1406 : ' รอบที่ 1 ลูก 3',
        1407 : ' รอบที่ 1 18 หลุม Match-Ups',
        1408 : ' การแข่งขันแฮนดิแค็',
        1409 : ' ทีมงานที่มีกะลาสูงสุด',
        1410 : ' ที่จะชนะการโยนและการแข่งขัน',
        1411 : ' ภายใต้ / กว่าปราการถูกทำลาย',
        1412 : ' ภายใต้ / กว่าปราการทำลาย - ช่วงเวลาที่ 1',
        1413 : ' ภายใต้ / กว่าปราการทำลาย - ช่วงเวลาที่ 2',
        1414 : ' ภายใต้ / กว่าปราการทำลาย - ช่วงเวลาที่ 3',
        1415 : ' คะแนนสูงสุดหลังจากวันที่ 1 กว่า',
        1416 : ' ทีมเจ้าบ้านภายใต้ / กว่าวิ่ง - 1 กว่าจะ 6th กว่า',
        1417 : ' ทีมเยือนภายใต้ / กว่าวิ่ง - 1 กว่าจะ 6th กว่า',
        1418 : ' ทีมเจ้าบ้านภายใต้ / กว่าวิ่ง - 1 กว่า 8 กว่า',
        1419 : ' ทีมเยือนภายใต้ / กว่าวิ่ง - 1 กว่า 8 กว่า',
        1420 : ' ทีมเจ้าบ้านภายใต้ / กว่าวิ่ง - 1 กว่า 10 กว่า',
        1421 : ' ทีมเยือนภายใต้ / กว่าวิ่ง - 1 กว่า 10 กว่า',
        1422 : ' ในการไล่ล่ากว่า 180 วิ่งและทีมที่จะชนะ',
        1423 : ' วิธีการทำงานจำนวนมากจะทำได้ตั้งแต่แรกบอล',
        1424 : ' ภายใต้ / กว่า Match Stumpings',
        1425 : ' 1 วิธีที่ประตู',
        1426 : ' ลูกแรกของการจับคู่',
        1427 : ' ภายใต้ / กว่า - อินนิงที่ 1',
        1428 : ' สูงสุดวิคตอเรียคลับบุหรี่',
        1429 : ' แกรนด์ผู้ชนะรอบชิงชนะเลิศ',
        1431 : ' ที่จะไปถึงรอบชิงชนะเลิศแกรนด์',
        1432 : ' ไม่ได้จะเสร็จสิ้นในยอด 8',
        1434 : ' ภายใต้ / กว่าฆ่า 4 งวด - ทีมเยือน',
        1435 : ' ภายใต้ / กว่าฆ่า 5 งวด - ทีมเยือน',
        1436 : ' ภายใต้ / กว่าฆ่าระยะเวลา 4 - ทีมเจ้าบ้าน',
        1437 : ' ภายใต้ / กว่าฆ่าระยะเวลา 5 - ทีมเจ้าบ้าน',
        1438 : ' ภายใต้ / กว่าอุณหภูมิที่สนามบิน (เซลเซียส)',
        1439 : ' เอเชียนแฮนดิแค็ - เต็มเวลา',
        1440 : ' ภายใต้ / กว่ามูลค่าของตัวเลขทั้งหมดวาด',
        1441 : ' ภายใต้ / กว่ามูลค่าของทั้งหมดคี่ตัวเลขที่วาด',
        1442 : ' ภายใต้ / กว่าราคาของเบอร์แม้แต่วาดทั้งหมด',
        1443 : ' อื่น ๆ แม้จะมีการวาดกว่าเลขคี่',
        1444 : ' หมายเลขติดต่อกันจะถูกดึง',
        1445 : ' ภายใต้ / เผง / กว่า - จำนวนคี่เลขลูกวาด',
        1446 : ' ภายใต้ / เผง / กว่า - จำนวนของลูกเลขแม้วาด',
        1447 : ' ผลรวมของลูก 5 โบนัส',
        1448 : ' บอลโบนัสแรกวาด - คู่ / คี่',
        1449 : ' บอลโบนัสล่าสุดวาด - คู่ / คี่',
        1450 : ' บอลโบนัสแรก',
        1451 : ' บอลโบนัสล่าสุด',
        1452 : ' ต่ำสุด 5 ลูกโบนัส',
        1453 : ' บอลโบนัส - คู่ / คี่',
        1454 : ' เบอร์ที่เหมือนจะถูกดึง',
        1455 : ' จำนวนสูงสุดวาด - คู่ / คี่',
        1456 : ' จำนวนต่ำสุดวาด - คู่ / คี่',
        1457 : ' บอล 1 - ภายใต้ / กว่า',
        1458 : ' บอล 1 - คู่ / คี่',
        1459 : ' บอล 2 - ใต้ / กว่า',
        1460 : ' ลูกที่ 2 - คู่ / คี่',
        1461 : ' บอล 3 - ใต้ / กว่า',
        1462 : ' บอล 3 - คู่ / คี่',
        1463 : ' บอล 4 - ภายใต้ / กว่า',
        1464 : ' บอล 4 - คู่ / คี่',
        1465 : ' ลูกที่ 5 - ภายใต้ / กว่า',
        1466 : ' บอล 5 - คู่ / คี่',
        1467 : ' บอล 6 - ภายใต้ / กว่า',
        1468 : ' บอล 6 - คู่ / คี่',
        1469 : ' บอล 7 - ภายใต้ / กว่า',
        1470 : ' บอล 7 - คู่ / คี่',
        1471 : ' ลูกที่ 8 - ภายใต้ / กว่า',
        1472 : ' บอล 8 - คู่ / คี่',
        1473 : ' ลูก 9 - ภายใต้ / กว่า',
        1474 : ' ลูก 9 - คู่ / คี่',
        1475 : ' บอล 10 - ภายใต้ / กว่า',
        1476 : ' บอล 10 - คู่ / คี่',
        1477 : ' บอล 11 - ภายใต้ / กว่า',
        1478 : ' บอล 11 - คู่ / คี่',
        1479 : ' บอล 12 - ภายใต้ / กว่า',
        1480 : ' บอล 12 - คู่ / คี่',
        1481 : ' บอล 13 - ภายใต้ / กว่า',
        1482 : ' บอล 13 - คู่ / คี่',
        1483 : ' บอล 14 - ภายใต้ / กว่า',
        1484 : ' บอล 14 - คู่ / คี่',
        1485 : ' บอล 15 - ภายใต้ / กว่า',
        1486 : ' บอล 15 - คู่ / คี่',
        1487 : ' บอล 16 - ภายใต้ / กว่า',
        1488 : ' บอล 16 - คู่ / คี่',
        1489 : ' บอล 17 - ภายใต้ / กว่า',
        1490 : ' บอล 17 - คู่ / คี่',
        1491 : ' บอล 18 - ภายใต้ / กว่า',
        1492 : ' บอล 18 - คู่ / คี่',
        1493 : ' บอล 19 - ภายใต้ / กว่า',
        1494 : ' บอล 19 - คู่ / คี่',
        1495 : ' บอล 20 - ภายใต้ / กว่า',
        1496 : ' บอล 20 - คู่ / คี่',
        1497 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 3',
        1498 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 4',
        1499 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 5',
        1500 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 7',
        1501 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 10',
        1502 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหาร 15 โดย',
        1503 : ' อย่างน้อยหนึ่งของตัวเลขที่วาดคือหารด้วย 20',
        1504 : ' จำนวนสูงสุดวาด - ภายใต้ / กว่า',
        1505 : ' มูลค่ารวมของต่ำสุดและสูงสุดเบอร์วาด - ภายใต้ / กว่า',
        1506 : ' จำนวนต่ำสุดวาด - ภายใต้ / กว่า',
        1507 : ' ความแตกต่างระหว่างสูงสุดและต่ำสุดหมายเลขวาด - ภายใต้ / กว่า',
        1508 : ' มูลค่ารวมของต่ำสุดและสูงสุดเบอร์วาด - คู่ / คี่',
        1509 : ' ความแตกต่างระหว่างสูงสุดและต่ำสุดหมายเลขวาด - คู่ / คี่',
        1510 : ' หมายเลขแรกวาดมากกว่าจำนวนวาดล่าสุด',
        1511 : ' จำนวนครั้งสุดท้ายวาด - คู่ / คี่',
        1512 : ' หมายเลขแรกวาด - คู่ / คี่',
        1513 : ' ตัวเลขที่วาดทั้งหมดแม้',
        1514 : ' ทั้งหมดตัวเลขที่วาดแปลก',
        1515 : ' จำนวนการวาด',
        1520 : ' 4 งวด - 1 Turret',
        1521 : ' 5 งวด - 1 Turret',
        1523 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า - ช่วงเวลาที่ 4',
        1524 : ' ภายใต้ / กว่ายักษ์ใหญ่ฆ่า - ระยะเวลา 5',
        1525 : ' ภายใต้ / กว่ามังกรฆ่า - ช่วงเวลาที่ 4',
        1526 : ' ภายใต้ / กว่ามังกรฆ่า - ระยะเวลา 5',
        1527 : ' ภายใต้ / กว่าปราการทำลาย - ช่วงเวลาที่ 4',
        1528 : ' ภายใต้ / กว่าปราการทำลาย - ระยะเวลา 5',
        1529 : ' 4 ระยะเวลาระยะเวลา',
        1530 : ' 5 ระยะเวลาระยะเวลา',
        1531 : ' Odd / Even ฆ่า - ช่วงเวลาที่ 4',
        1532 : ' Odd / Even ฆ่า - ระยะเวลา 5',
        1533 : ' ภายใต้ / กว่าสารยับยั้ง - ช่วงเวลาที่ 1',
        1534 : ' ภายใต้ / กว่าสารยับยั้ง - ช่วงเวลาที่ 2',
        1535 : ' อุณหภูมิที่สนามบินในช่วง (เซลเซียส)',
        1536 : ' ภายใต้ / กว่าแผนที่',
        1537 : ' ทีมเจ้าบ้านจะชนะและภายใต้ / กว่า - รวมการทำงานล่วงเวลา',
        1538 : ' ทีมเยือนที่จะชนะและภายใต้ / กว่า - รวมการทำงานล่วงเวลา',
        1539 : ' ผู้ชนะเลิศรอบ',
        1540 : ' ขารวมการแข่งขันภายใต้ / กว่า',
        1541 : ' แฮนดิแค็รอบ',
        1542 : ' ผู้ชนะเลิศที่ 1 ขา',
        1543 : ' คนแรกที่ชนะ 3 ขา',
        1546 : ' 4 งวด - 1 ชาน',
        1547 : ' 5 งวด - 1 ชาน',
        1548 : ' 4 งวด - ค่ายทหาร 1',
        1549 : ' 5 งวด - ค่ายทหาร 1',
        1550 : ' 4 งวด - tou 1',
        1551 : ' 5 งวด - tou 1',
        1552 : ' เอเชียใต้ / กว่าลูกเตะมุม',
        1553 : ' ทั้งสองทีมทำประตูจำนวนจุด - ช่วงเวลาที่ 1',
        1554 : ' ทั้งสองทีมทำประตูจำนวนจุด - 1 ครึ่ง',
        1555 : ' ภายใต้ / กว่า 5 งวด - ทีมเจ้าบ้าน',
        1556 : ' ภายใต้ / กว่า 5 งวด - ทีมเยือน',
        1558 : ' สิ่งที่น่าเอเชียนแฮน',
        1559 : ' ถ้วยชนะเลิศ',
        1561 : ' 1 3 โอกาสชนะเลิศ',
        1562 : ' 1 3 โอกาสภายใต้ / กว่า',
        1563 : ' เวลาของการให้คะแนน',
        1564 : ' เวลาของการให้คะแนน - ทีมเจ้าบ้าน',
        1565 : ' เวลาของการให้คะแนน - ทีมเยือน',
        1566 : ' สรุปผลการแข่งขันเวลา',
        1567 : ' สูงสุดสัญชาติ - แอฟริกาใต้',
        1568 : ' สูงสุดสัญชาติ - สเปน',
        1569 : ' สูงสุดสัญชาติ - ไอร์แลนด์',
        1570 : ' สูงสุดสัญชาติ - อเมริกาใต้',
        1571 : ' สูงสุดสัญชาติ - แคนาดา',
        1572 : ' สูงสุดสัญชาติ - อังกฤษ',
        1573 : ' สูงสุดสัญชาติ - คอนติเนนยุโรป',
        1575 : ' เพื่อให้ตัด',
        1576 : ' นางสาวตัด',
        1577 : ' สูงสุดสัญชาติ - อเมริกัน',
        1578 : ' สัญชาติของผู้ชนะ',
        1579 : ' สิ่งที่น่าเอเชียนแฮน - ช่วงที่ 1 ช่วง',
        1580 : ' สิ่งที่น่าเอเชียนแฮน - ช่วงเวลาที่ 2',
        1581 : ' สิ่งที่น่าเอเชียนแฮน - ช่วงเวลาที่ 3',
        1583 : ' ทีมชั้นนำกะลาที่ 1 อินนิง - ทีมเจ้าบ้าน',
        1584 : ' ทีมชั้นนำกะลาที่ 1 อินนิง - ทีมเยือน',
        1585 : ' ทีมชั้นนำลูกบอลที่ 1 อินนิง - ทีมเจ้าบ้าน',
        1586 : ' ทีมชั้นนำลูกบอลที่ 1 อินนิง - ทีมเยือน',
        1587 : ' จะมีแต้มจุดถัดไป',
        1589 : ' จะมีความผิดคู่ถัดไปจุด',
        1591 : ' ภายใต้ / กว่า - 1 กว่า',
        1592 : ' คว้าแชมป์ลีก',
        1593 : ' ฝ่ายชนะ',
        1594 : ' ฮิตที่สุด - รวมทั้งการทำงานล่วงเวลา',
        1595 : ' การต่อสู้ที่จะไประยะทาง',
        1596 : ' เมื่อจบการต่อสู้',
        1597 : ' การต่อสู้ของผลลัพธ์',
        1598 : ' เดิมพันรอบ',
        1599 : ' Top Hit 1',
        1600 : ' ด้านล่างตี 1',
        1601 : ' สูงสุด 1 Run',
        1602 : ' ล่างวิ่ง 1',
        1603 : ' ฮิตที่สุดในวันที่ 1 อินนิง',
        1605 : ' ทีมที่มีคะแนนสูงสุดอินนิง',
        1606 : ' ทั้งสองทีมทำประตู 2 ประตูหรือมากกว่า',
        1607 : ' ทั้งสองทีมทำประตู 3 ประตูหรือมากกว่า',
        1608 : ' ระยะเวลาทีมที่จะชนะมากที่สุด',
        1609 : ' เอเชียนแฮนดิแค็ประจำ - ช่วงที่ 1 ช่วง',
        1610 : ' เอเชียนแฮนดิแค็เต็มเวลา - ช่วงเวลาที่ 2',
        1611 : ' เอเชียนแฮนดิแค็เต็มเวลา - ช่วงเวลาที่ 3',
        1612 : ' MVP',
        1614 : ' การประชุมวิน',
        1615 : ' ชนะการประชุม',
        1617 : ' ซีซั่นผู้เล่นพิเศษ',
        1618 : ' 1 5 โอกาสชนะ - 12',
        1619 : ' ช่วงที่ 1 ช่วง - Halftime ผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1620 : ' ช่วงที่ 1 ช่วง - Halftime เอเชียแฮนดิแค็',
        1621 : ' 2 งวด - Halftime ผู้ชนะเจ้าบ้าน / ทีมเยือน',
        1622 : ' 2 งวด - Halftime เอเชียแฮนดิแค็',
        1624 : ' ภายใต้ / กว่าผู้เล่นวิ่ง',
        1625 : ' ภายใต้ / กว่าผู้เล่นเดี่ยว',
        1626 : ' ภายใต้การทำงาน / กว่าผู้เล่นหน้าแรก',
        1627 : ' ภายใต้ฐาน / กว่าผู้เล่นที่ถูกขโมย',
        1628 : ' ภายใต้ / กว่าผู้เล่นคู่ผสม',
        1629 : ' ภายใต้ / กว่าผู้เล่นอเนกประสงค์',
        1630 : ' เหยือกบันทึกวิน',
        1631 : ' ภายใต้ฐาน / กว่าผู้เล่นทั้งหมด',
        1635 : ' ภายใต้ / กว่าวิ่งเหยือกที่ได้รับ',
        1636 : ' ภายใต้ / กว่าฮิตเหยือกอนุญาต',
        1637 : ' ภายใต้ / กว่าเอาเหยือก',
        1638 : ' การจำแนกประเภททีม',
        1639 : ' หนุ่มขี่ม้าประเภท',
        1640 : ' คะแนนการจัดหมวดหมู่',
        1641 : ' พระมหากษัตริย์ของเทือกเขา',
        1642 : ' ขั้นตอนที่ 1',
        1649 : ' เร็วตัก',
        1650 : ' รถที่ชนะการประกวด',
        1651 : ' คนขับเป็นครั้งแรกที่จะเกษียณอายุ',
        1652 : ' การคัดเลือกผู้ชนะรถยนต์',
        1653 : ' ที่เร็วที่สุดในการปฏิบัติ 1',
        1654 : ' คะแนนเสร็จสิ้น',
        1655 : ' รอบคัดเลือกที่เร็วที่สุด',
        1657 : ' ขั้นตอนที่ 3',
        1659 : ' ครั้งแรกที่สร้างหลังเกษียณ',
        1660 : ' กริดตำแหน่งของผู้ชนะเลิศ',
        1661 : ' ความปลอดภัยระยะเวลารถในระหว่างการแข่งขัน',
        1662 : ' จำนวนไดร์ฟเวอร์ประกาศ',
        1663 : ' เสมือนความปลอดภัยระยะเวลารถในระหว่างการแข่งขัน',
        1664 : ' คะแนนหลุม',
        1667 : ' ที่จะชนะหลุม',
        1670 : ' ผูก Match',
        1672 : ' ภายใต้เป้าหมาย / กว่า - ครึ่ง 2',
        1673 : ' Odd / Even รอบระยะเวลา 4',
        1674 : ' Odd / Even รอบระยะเวลา 5',
        1675 : ' ผู้นำรอบที่ 2',
        1676 : ' ทั้งสองทีมทำประตู - รวมทั้งการทำงานล่วงเวลา',
        1677 : ' ทีมสุดท้ายที่จะยิงประตู - รวมการทำงานล่วงเวลา',
        1678 : ' สูงสุดสัญชาติ - เยอรมัน',
        1679 : ' สูงสุดสัญชาติ - ออสเตรีย',
        1680 : ' สูงสุดสัญชาติ - นิวซีแลนด์',
        1681 : ' ด้านบนซ้ายมือของผู้เล่น',
        1682 : ' มือสมัครเล่นสูงสุด',
        1683 : ' สูงสุดสัญชาติ - เกาหลี',
        1684 : ' สูงสุดสัญชาติ - สแกนดิเนเวียน',
        1685 : ' สูงสุดสัญชาติ - ฝรั่งเศส',
        1686 : ' ผู้ชนะเลิศสูงสุดอดีต',
        1687 : ' สูงสุดสัญชาติ - สวีเดน',
        1688 : ' สูงสุดสัญชาติ - ญี่ปุ่น',
        1689 : ' สูงสุดสัญชาติ - สกอต',
        1690 : ' สูงสุดสัญชาติ - เบลเยี่ยม',
        1691 : ' แฮนดิแค็ยุโรป 4 ระยะเวลา',
        1692 : ' แฮนดิแค็ยุโรปที่ 3 ระยะเวลา',
        1693 : ' แฮนดิแค็ยุโรปครั้งที่ 2 ระยะเวลา',
        1694 : ' แฮนดิแค็ยุโรปช่วงที่ 1 ช่วง',
        1695 : ' คะแนนรอบ',
        1696 : ' ชนะคะแนนการแข่งขัน',
        1697 : ' คะแนนชั้นนำหลังจากรอบ',
        1698 : ' จะตีแฟร์เวย์ด้วยการยิงที',
        1699 : ' จะตีสีเขียวในการควบคุม',
        1700 : ' ผู้นำรอบที่ 3',
        1702 : ' ภายใต้ / กว่าเล่นจะพยายามผ่าน',
        1704 : ' ดาว์นในระยะเวลา 1',
        1705 : ' ดาว์นในระยะเวลา 2',
        1706 : ' ดาว์นในระยะเวลา 3',
        1707 : ' ดาว์นในระยะเวลา 4',
        1708 : ' ภายใต้ / เผง / กว่า - หน้าแรกของทีมทัชดาวน์',
        1709 : ' ภายใต้ / เผง / กว่า - ทีมเยือนทัชดาวน์',
        1710 : ' ภายใต้ / เผง / กว่า - หน้าแรกของทีมที่ 1 ครึ่งทัชดาวน์',
        1711 : ' ภายใต้ / เผง / กว่า - ทีมเยือน 1 ครึ่งทัชดาวน์',
        1712 : ' 16 รอบชนะ - ระยะเวลา 4',
        1713 : ' 16 รอบชนะ - ระยะเวลา 5',
        1714 : ' ภายใต้ / กว่า tous ทำลาย - ช่วงเวลาที่ 4',
        1715 : ' ภายใต้ / กว่า tous ทำลาย - ระยะเวลา 5',
        1722 : ' ตั้งค่าให้เสร็จสิ้นเพื่อ Nil',
        1723 : ' ภายใต้ / กว่าฆ่า - ระยะเวลา 1 - รอบที่ 1',
        1724 : ' ภายใต้ / กว่าฆ่า - ระยะเวลา 2 - รอบที่ 1',
        1728 : ' ทั้งสองทีมทำประตู - เวลาพิเศษ',
        1729 : ' ดับเบิลโอกาส - เวลาพิเศษ',
        1730 : ' Will เกมไปที่ซูเปอร์กว่า?',
        1731 : ' ดับเบิลโอกาสที่ 5 ระยะเวลา',
        1732 : ' ดับเบิลโอกาสที่ 6 ระยะเวลา',
        1733 : ' ดับเบิลโอกาสที่ 7 ระยะเวลา',
        1734 : ' ดับเบิลโอกาสที่ 8 ระยะเวลา',
        1736 : ' จำนวน 1 วิ่งอินนิง',
        1737 : ' จำนวนรันอินนิงที่ 2',
        1738 : ' จำนวน 3 วิ่งอินนิง',
        1739 : ' จำนวน Runs โอกาสที่ 4',
        1740 : ' จำนวนรันอินนิงที่ 5',
        1741 : ' จำนวน 6 โอกาสทำงาน',
        1742 : ' จำนวนรันอินนิงที่ 7',
        1743 : ' จำนวน 8 โอกาสทำงาน',
        1744 : ' ห้าสิบที่จะทำคะแนนในวันที่ 1 โอกาส',
        1745 : ' การล่มสลายของประตูที่ 1',
        1747 : ' ถัดไปผู้ชายออก',
        1748 : ' เสร็จสิ้นการแข่งขัน',
        1749 : ' ศตวรรษที่คะแนน',
        1750 : ' ศตวรรษคะแนน - 1 อินนิง',
        1752 : ' ห่างยกฟ้องทีมแรก 6-Way',
        1753 : ' ด้านบนตรงกับลูกบอล',
        1754 : ' ที่จะยิงประตูจุดโทษ',
        1758 : ' 6 บุคคลแห่งปี',
        1760 : ' หน้าใหม่แห่งปี',
        1761 : ' ฤดูกาล MVP ปกติ',
        1763 : ' ลูกจะทำคะแนนร้อยในโอกาสที่ 1',
        1765 : ' 9 เสร็จสิ้นโผในการแข่งขัน',
        1766 : ' เวลาของการลองแรก',
        1767 : ' เวลาของการพยายามล่าสุด',
        1770 : ' 9 เสร็จสิ้นโผในการแข่งขัน - ทีมเจ้าบ้าน',
        1771 : ' 9 เสร็จสิ้นโผในการแข่งขัน - ทีมเยือน',
        1772 : ' ด้านบนเรียกแต้ม',
        1773 : ' ด้านบนเรียกแต้ม - ทีมเจ้าบ้าน',
        1774 : ' ด้านบนเรียกแต้ม - ทีมเยือน',
        1775 : ' ทีมของ Top Run แต้ม',
        1776 : ' ภายใต้ / กว่าเรือท้องแบน',
        1777 : ' ภายใต้ / กว่ากระสอบ',
        1778 : ' ภายใต้ / กว่า Downs 1',
        1779 : ' 1 ทีมเพื่อให้ได้เป็นครั้งแรก',
        1780 : ' เป้าหมายที่ 1 ครึ่งภายใต้ / กว่าสนาม - ทีมเจ้าบ้าน',
        1781 : ' เป้าหมายที่ 1 ครึ่งภายใต้ / กว่าสนาม - ทีมเยือน',
        1782 : ' ภายใต้ / เผง / กว่า - เป้าหมายสนาม 1 ครึ่ง',
        1783 : ' ภายใต้ / กว่าทัชดาวน์ที่ 1 ระยะเวลา',
        1784 : ' ภายใต้บทลงโทษ / กว่าได้รับการยืนยัน',
        1785 : ' ฆ่ามากที่สุดในการแข่งขัน',
        1786 : ' ทีมงานที่มีฆ่ามากที่สุดในการแข่งขัน',
        1787 : ' ภายใต้ความพยายาม / กว่าผ่าน',
        1788 : ' ภายใต้ / กว่าผ่านสำเร็จ',
        1789 : ' ภายใต้ / กว่าหลาผ่าน',
        1790 : ' ภายใต้ความพยายาม / กว่า Rush',
        1791 : ' ภายใต้ / กว่า Rush สนาม',
        1792 : ' ภายใต้ / กว่าผู้เล่นโหม่งและให้ความช่วยเหลือ',
        1794 : ' ทีมถ่อแรก',
        1795 : ' ช่วงที่ 1 ช่วง 1X2 และภายใต้ / กว่า',
        1796 : ' 1X2 ช่วย',
        1797 : ' ดับเบิลช่วยโอกาส',
        1798 : ' ทีมที่จะนำหลังจากที่ทุกช่วงเวลา',
        1799 : ' ทีมที่มีการส่งผ่านส่วนใหญ่สนาม',
        1800 : ' ทีมงานที่มีส่วนใหญ่วิ่งสนาม',
        1801 : ' ที่ประสบความสำเร็จ 2 แปลงจุด',
        1802 : ' ภายใต้ / กว่าทัชดาวน์',
        1803 : ' ภายใต้ / เป้าหมายในรอบระยะเวลา 1 สนาม',
        1804 : ' คะแนนในครั้งล่าสุดเมื่อ 2 นาทีที่ 1 ครึ่ง',
        1805 : ' ทีมที่ได้รับการเปิดเตะปิด',
        1806 : ' ภายใต้ / กว่าทีมเจ้าบ้านทัชดาวน์ - ระยะเวลา 1',
        1807 : ' ภายใต้ / กว่าทีมเยือนทัชดาวน์ - ระยะเวลา 1',
        1808 : ' ภายใต้ / เผง / กว่าทีมเจ้าบ้านทัชดาวน์ - ระยะเวลา 1',
        1809 : ' ภายใต้ / เผง / กว่าทีมเยือนทัชดาวน์ - ระยะเวลา 1',
        1810 : ' ภายใต้เป้าหมาย / กว่าหน้าแรกทีมงานภาคสนาม - ระยะเวลา 1',
        1812 : ' ภายใต้ / กว่าเป้าหมายออกทีมงานภาคสนาม - ระยะเวลา 1',
        1813 : ' ทีมเยือนจะชนะทุกไตรมาส',
        1814 : ' ทีมเจ้าบ้านจะชนะทุกไตรมาส',
        1815 : ' ครั้งแรกที่น่ารังเกียจเล่นของเกม',
        1816 : ' To Win ไตรมาส',
        1817 : ' การเข้าถึงรอบชิงชนะเลิศ',
        1818 : ' ทีมแรกที่ทำสกอร์ 25 เป้าหมาย',
        1819 : ' ทีมแรกที่ทำสกอร์ 30 เป้าหมาย',
        1820 : ' ทีมเยือนที่จะยิงประตู 25 ประตู',
        1821 : ' ทีมเยือนที่จะยิงประตู 30 ประตู',
        1822 : ' ทีมเจ้าบ้านจะชนะทั้งสองครึ่ง',
        1823 : ' ทีมเยือนจะชนะทั้งสองครึ่ง',
        1824 : ' ขั้นตอนของการกำจัด',
        1825 : ' ภายใต้ / กว่าช่วย',
        1826 : ' ภายใต้ / กว่ารีบาวด์',
        1827 : ' ภายใต้ / กว่าบ้านช่วยทีม',
        1828 : ' ภายใต้ / กว่าทีมเยือนช่วย',
        1829 : ' ภายใต้ / กว่าทีมเจ้าบ้านรีบาวด์',
        1830 : ' ภายใต้ / กว่าทีมเยือนรีบาวด์',
        1831 : ' 1X2 3 คะแนนทำ',
        1832 : ' ภายใต้ / กว่า 3 คะแนนทำ',
        1833 : ' ภายใต้ / ทีมในรอบแรก 3 คะแนนทำ',
        1834 : ' ภายใต้ / กว่าทีมเยือน 3 คะแนนทำ',
        1835 : ' เอเชียนแฮนดิแค็ 3 คะแนนทำ',
        1841 : ' แทนครั้งแรก',
        1842 : ' ประตูที่ 2 ในการแข่งขัน',
        1843 : ' ประตูที่ 3 ในการแข่งขัน',
        1846 : ' 60 นาทีผล',
        1847 : ' 75 นาทีผล',
        1848 : ' ยิงในครั้งแรก 5 นาที?',
        1849 : ' ครั้งแรกจากเส้นข้างในการแข่งขัน',
        1850 : ' วาดในทั้งสองครึ่ง',
        1851 : ' โหม่งบอลเข้าประตูในการแข่งขัน?',
        1852 : ' ภายใต้ / กว่านาทีประตู',
        1853 : ' ภายใต้ / กว่า Tie แบ่งในการแข่งขัน',
        1854 : ' ภายใต้จุด / กว่าหยุด',
        1855 : ' ภายใต้ / เหนือแบ่งคะแนนทีมเจ้าบ้าน',
        1856 : ' ภายใต้ / เหนือแบ่งคะแนนทีมเยือน',
        1857 : ' ภายใต้ / กว่าคะแนนสูงสุดไตรมาส',
        1858 : ' ภายใต้ / กว่าเกณฑ์การให้คะแนนต่ำสุดไตรมาส',
        1859 : ' พยายามมากที่สุด 3-Way',
        1860 : ' 10 นาทีชนะ Margin 5-Way',
        1861 : ' ทีมการสูญเสียมากที่สุด',
        1862 : ' งานย่อยจะทำคะแนนในเกม',
        1863 : ' ระยะเวลาการชนะทุก - ทีมเจ้าบ้าน',
        1864 : ' ระยะเวลาการชนะทุก - ทีมเยือน',
        1865 : ' ครั้งแรก 10 นาทีพยายามภายใต้ / กว่า',
        1866 : ' 15 นาทีภายใต้ / กว่า - ลูกเตะมุม',
        1867 : ' 2 เป้าหมายในแถวโดยทีมงาน',
        1868 : ' 3 เป้าหมายในแถวโดยทีมงาน',
        1869 : ' พยายามแปลงส่วนใหญ่',
        1872 : ' unseeded เข้ารอบสุดท้าย?',
        1873 : ' ป่าการ์ดไปถึงขั้นสุดท้ายหรือไม่',
        1874 : ' ชนะครึ่ง - เทนนิสทายผลทีมชนะ',
        1875 : ' ชนะ Quarter - เทนนิสทายผลทีมชนะ',
        1881 : ' Hole In One',
        1882 : ' จะมีผู้เล่นออกเป็นอย่างไร',
        1883 : ' 72 หลุม Match Ups',
        1884 : ' 54 หลุม Match Ups',
        1885 : ' จำนวน 1 ร่าง',
        1886 : ' 1X2 และโอกาสคู่',
        1887 : ' ทีมเจ้าบ้านจะชนะ \\ วาดและทั้งสองทีมทำคะแนน',
        1888 : ' ทีมเยือนจะชนะ \\ วาดและทั้งสองทีมทำคะแนน',
        1889 : ' ชนะ Margin - 1 ครึ่ง',
        1891 : ' อเมริกันลีกภาวะหนุ่ม',
        1892 : ' ลีกอเมริกัน MVP',
        1893 : ' ชาติพันธมิตรภาวะหนุ่ม',
        1894 : ' ชาติพันธมิตร MVP',
        1895 : ' เหยือกบันทึกมากที่สุดชนะฤดูกาลปกติ',
        1896 : ' ผู้เล่นที่จะตีฤดูกาลปกติส่วนใหญ่บ้านวิ่ง',
        1897 : ' จะตัดสินใจในเกมเวลาพิเศษ?',
        1898 : ' ฤดูกาลปกติชนะ%',
        1900 : ' ผู้เล่นที่ทำประตูสูงสุด',
        1901 : ' ภายใต้ / กว่าโหม่ง',
        1902 : ' จำนวน 2 ร่าง',
    }
}