export const ko = {
    DocumentDescriptionLiveCasino:'에볼루션 바카라를 즐길 수 있는 바카라 사이트인 잭팟시티를 방문해보세요. 안전하고 실시간으로 에볼루션 바카라 플레이가 가능한 잭팟시티는 온라인 카지노 사이트입니다. 잭팟시티는 현재 회원가입시 200% 보너스를 지급하며 당일 인출을 보장하고 있습니다. 지금 바로 방문해 보세요!!',
    DocumentDescription:'에볼루션 바카라를 즐길 수 있는 바카라 사이트인 잭팟시티를 방문해보세요. 안전하고 실시간으로 에볼루션 바카라 플레이가 가능한 잭팟시티는 온라인 카지노 사이트입니다. 잭팟시티는 현재 회원가입시 200% 보너스를 지급하며 당일 인출을 보장하고 있습니다. 지금 바로 방문해 보세요!!',
    DocumentHeader:'잭팟시티 바카라 사이트 - 안전한 실시간 온라인 에볼루션 바카라 사이트',
    Game_Name:'게임 이름',
    Provider:'브랜드',
    Volatility:'변동성',
    Features:'피쳐',
    Themes:'테마',
    viewall: '더보기',
    discoverall: '모두 보기',
    roulette: '룰렛',
    blackjack: '온라인 블랙잭',
    baccarat: '에볼루션 바카라',
    poker: '포커',
    announceMsg: '회원가입시 보너스 500% 지급! 당일 인출 보장!',
    lobby: '카지노 로비',
    hotGames: '인기 급상승',
    new: '에볼루션 새게임',
    providers: '카지노 브랜드',
    notice: '카지노 새로운 소식',
    close: '닫기',
    customMsg: '기간한정!',
    customPrice: '$100 충전시 $500 지급!!!',
    subCustomMsg: '선착순 1000명',
    email: '이메일',
    forgetPassword: '비밀번호 찾기',
    passwordAgain: '비밀번호 재입력',
    backLogin: '다시 로그인',
    readAll: '전체보기',
    sendOTP: 'OTP 받기',
    en: 'EN',
    tr: 'TR',
    fr: 'FR',
    de: 'DE',
    ru: 'RU',
    nl: 'NL',
    th: 'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: '스포츠의',
    inplay: '라이브',
    live: '라이브',
    today: '오늘',
    upcoming: '다가오는',
    tomorrow: '내일',
    highlights: '하이라이트',
    results: '결과',
    lastMinute: '마지막 순간',
    liveCasino: '라이브 카지노',
    casino: '카지노',
    myBets: '내 베팅',
    home: '집',
    login: '로그인',
    signup: '가입하기',
    betslip: '베팅슬립',
    search: '검색',
    searchHere: '여기 검색',
    transaction: '트랜잭션',
    changePassword: '암호 변경',
    logout: '로그 아웃',
    single: '단일',
    multiple: '배수',
    possibleWin: '가능한 승리',
    amount: '양',
    ok: '확인',
    totalOdds: '총 확률',
    odd: '이상한',
    totalPossibleWin: '총 가능한 승리',
    placeBet: '장소 내기',
    fast: '빠른',
    bet: '내기',
    fastBet: '빠른 내기',
    username: '사용자 이름',
    password: '암호',
    stayLoggedIn: '로그인 유지',
    all: '모두',
    "in game": '게임',
    won: '이겼다',
    lost: '잃어버린',
    refund: '환불금',
    date: '데이트',
    winAmount: '승리 금액',
    stake: '말뚝',
    pick: '선택',
    myAccount: '내 계정',
    football: '축구',
    basketball: '농구',
    iceHockey: '아이스 하키',
    tennis: '테니스',
    volleyball: '배구',
    boxing: '권투',
    americanFootball: '미식 축구',
    baseball: '야구',
    hockey: '하키',
    tableTennis: '탁구',
    badminton: '배드민턴',
    cricket: '크리켓',
    futsal: '풋살',
    golf: '골프',
    handball: '핸드볼',
    rugbyLeagues: '럭비 리그',
    pickCanNotBeCombined: '이 선택은 결합 할 수 없습니다',
    matchStopped: '경기가 중지',
    favorites: '즐겨 찾기',
    cashout: '현금 인출',
    "in progress": '진행 중',
    rejected: '거부',
    tip: '팁',
    noGoal: '어떤 목표 없습니다',
    yes: '예',
    over: '위에',
    under: '아래에',
    even: '조차',
    areYouSureWantCashout: '당신은 당신이 Cashout 하시겠습니까?',
    noGoal: '아니',
    bonus: '보너스',
    termsAndConditon: '이용 약관',
    aboutUs: '회사 소개',
    contactUs: '문의하기',
    payments: '지불',
    howPlacebet: '베팅을하는 방법',
    deposit: '예금',
    credit: '신용',
    debit: '직불 카드',
    withdraw: '빼다',
    totalOdd: '총 홀수',
    totalStake: '총 스테이크',
    topGames: '상위 게임',
    allGames: '모든 게임들',
    canNotBeCombined: '이 픽업은 결합 할 수 없습니다',
    betSettled: '베팅은 정착',
    betSuspended: '베팅 일시 중단',
    insufficientBalance: '잔액 불충분',
    oldPassword: '기존 비밀번호',
    newPassword: '새 비밀번호',
    confirmPassword: '새 암호를 확인합니다',
    change: '변화',
    fromDate: '날짜',
    toDate: '날짜로',
    activityType: '활동 유형',
    show: '보여 주다',
    gameType: '게임 타입',
    repeatBet: '반복 베팅',
    someThingWrong: '문제가 발생했습니다',
    status: '상태',
    coupon: '쿠폰',
    acceptOddChangesText: '당신은 내기를 배치 할 수 있도록 변경 사항을 적용 할 필요가',
    turnOffAway: '어쨌든 해제',
    acceptOddChanges: '이상한 변경에 동의',
    maxWinAmount: '최대 승리 금액',
    maxOdd: '최대 홀수',
    dateTime: '날짜 시간',
    type: '유형',
    refreshCashout: '새로 고침 Cashout',
    vendor: '공급 업체',
    stakeLowerThen1: '지분은 미만이어야합니다',
    stakeGreaterThen1: '지분은 커야합니다',
    ESports: 'e스포츠',
    KSports: 'K스포츠',
    promotion: '프로모션',
    customerServicePage: '고객 서비스 페이지',
    decimal: '소수',
    fraction: '분수',
    americanOdds: '미국의 확률',
    nothingFound: '아무것도 찾을 수 없음',
    noLiveMatch: '라이브 일치가 없습니다',
    sportsBook: '스포츠북',


    marketNames: {
        1: '1 × 2',
        2: '언더 / 오버',
        238: '남은 경기',
        59: '다음 목표',
        7: '더블 찬스',
        52: '아니 베팅을 그립니다',
        226: '12 포함하여 초과 근무',
        13: '유럽 ​​핸디캡',
        4: 'HT / FT',
        17: '두 팀 점수',
        59: '다음 목표',
        28: '/ 이상 포함하여 초과 근무에서',
        247: '전반전 남은',
    },
    tabNames: {
        'All': '모두',
        'Main Markets': '주요 시장',
        'Totals': '합계',
        '1st Half': '전반전',
        '2nd Half': '후반전',
        'Home Team': '홈 팀',
        'Away Team': '어웨이 팀',
        'All Periods': '모든 기간',
        'Results': '결과',
        'Home / Away': '멀리 홈 /',
        'Slot': '카지노 슬롯',
        'Table Game': '온라인 테이블 게임',
        'Instant Win': '인스턴트 윈',
        'Live Casino': '안전한 라이브 카지노',
        'Scratch Card': '카지노 스크래치 카드',
        'Video Poker': '온라인 비디오 포커',
        'Virtual Sports': '버추어 스포츠',
        'New': '에볼루션 새게임',
        'Hot Games': '온라인 카지노 인기게임',
        'Slots': '카지노 슬롯',
        'Crash': '바카라 크래쉬',
        'Chinese': '카지노 중국테마',
        'Bonus Buy': '안전한 보너스 구매 게임',
        'Top Providers': '온라인 카지노 탑 브랜드',
        'Andar Bahar': 'Andar bahar',
        'Baccarat': '에볼루션 바카라',
        'Keno': '케노',
        'Blackjack': '온라인 블랙잭',
        'Sic Bo': 'Sic bo',
        'Poker': '온라인 포커',
        'Dragon tiger': 'Dragon tiger',
        'Roulette': '룰렛',
        'Shooting': '슈팅',
        'Lottery': '로또',
    },
    markets: {
        1: '1 × 2',
        2: '언더 / 오버',
        3: '아시안 핸디캡',
        5: '홀수 / 짝수',
        6: '올바른 점수',
        7: '더블 찬스',
        9: '올바른 점수 1 기간',
        11: '전체 코너',
        12: '총 파울을 범한',
        13: '유럽 ​​핸디캡',
        16: '첫 번째 팀으로 점수',
        17: '두 팀 점수',
        19: '첫 번째 카드',
        21: '언더 / 오버 1 기간',
        22: '어웨이 팀 득점에',
        23: '점수로 홈 팀',
        25: '더블 찬스 하프 타임',
        29: '/ 이상 라운드에서',
        30: '/ 오버 코너에서 - 홈 팀',
        31: '/ 오버 코너에서 - 어웨이 팀',
        34: '홈 팀은 두 반쪽에서 점수',
        35: '어웨이 팀은 두 반쪽에서 점수',
        41: '1 차 기간 수상작',
        42: '2 기간 수상작',
        43: '3 기간 수상작',
        44: '4 기간 수상작',
        45: '언더 / 오버 2 기간',
        46: '언더 / 오버 3 기간',
        47: '/ 이상 4 기간에서',
        48: '/ 이상 5 기간에서',
        49: '5 기간 수상작',
        50: '1X2 포함하여 초과 근무',
        51: '홀수 / 짝수 포함하여 초과 근무',
        52: '(12)',
        53: '아시안 핸디캡 하프 타임',
        55: '첫 번째 팀은 1 기간 점수',
        56: '마지막으로 팀에 점수',
        57: '마지막 팀은 하프 타임 점수',
        59: '다음 목표',
        61: '유럽 ​​핸디캡 하프 타임',
        62: '홀수 / 짝수 하프 타임',
        63: '(12) 하프 타임',
        64: '아시안 핸디캡 1 기간',
        65: '아시안 핸디캡 2 기간',
        66: '아시안 핸디캡 3 기간',
        67: '아시안 핸디캡 4 기',
        68: '아시안 핸디캡 5 기간',
        69: '게임 이동]의 초과 근무는 것인가?',
        70: '최고 점수주기',
        71: '최고 득점의 절반',
        72: '첫번째 기간 홀수 / 짝수',
        73: '2 기간 홀수 / 짝수',
        74: '3 기간 홀수 / 짝수',
        75: '4 기간 홀수 / 짝수',
        76: '5 기간 홀수 / 짝수',
        77: '언더 / 오버 하프 타임',
        78: '대부분 되죠 점수',
        79: '패널티 수여됩니다?',
        80: '첫 번째 코너',
        81: '마지막 코너',
        82: '레드 카드가 표시됩니다?',
        83: '클린 시트를 유지하려면',
        84: '승리 두 반쪽으로',
        85: '뒤에서 승리',
        86: '승리를 무기 호에',
        88: '자격',
        89: '첫 번째는 변환 할 시도 할 것이다?',
        90: '경기는 5 세트에서 결정됩니다?',
        91: '무엇 절반 먼저 목표는 득점 수 있습니까?',
        92: '첫 번째 팀은 후반전 점수',
        95: '코너 핸디캡',
        96: '어웨이 팀 승리를 무기 호',
        97: '홈 팀 승리를 무기 호',
        98: '홈 팀 깨끗한 시트를 유지',
        99: '어웨이 팀에 클린 시트를 유지',
        100: '올바른 점수 2 기간',
        101: '언더 / 오버 - 홈 팀',
        102: '/ 오버 언더 - 어웨이 팀',
        104: '어웨이 팀에 승리 두 반쪽',
        105: '홈 팀에 승리 두 반쪽',
        106: '뒤에서 홈 팀에 승리',
        107: '뒤에서 어웨이 팀에 승리',
        108: '페널티에서 홈 팀에 점수',
        109: '페널티 멀리 팀에 점수',
        110: '어웨이 팀에 승리 적어도 하나의 반',
        111: '홈 팀에 승리 적어도 하나의 반',
        113: '두 팀은 전반전 점수',
        114: '모든 플레이어에게 점수 적어도 2 목표',
        117: '모든 플레이어에게 점수 적어도 3 목표',
        122: '팀 득점 우선으로 승리 일치',
        123: '어웨이 팀에 먼저 그리고 승리 경기를 점수',
        124: '홈 팀에 먼저 그리고 승리 경기를 점수',
        128: '목표의 수',
        129: '/ 오버 코너에서 - 1 반',
        132: '대상에 / 오버 샷에서',
        133: '/ 이상 총 샷에서',
        134: '목표 전반전의 수',
        136: '언더 / 오버 오프사이드',
        137: '홈 승 없음 내기',
        138: '원정 승 없음 내기',
        139: '어떤 반 홈 팀에서 먼저 목표 점수 것인가?',
        140: '어떤 멀리 절반 팀에서 먼저 목표 점수 것인가?',
        143: '어떤 반 홈 팀에서 더 많은 골을 넣을 것인가?',
        144: '어느 반에서 멀리 팀은 더 많은 골을 넣을 것인가?',
        145: '전반전에 골 홈팀 수',
        146: '전반전에 골 어웨이 팀 수',
        147: '하반기에 목표의 어웨이 팀 수',
        148: '하반기에 목표의 홈 팀 수',
        149: '목표의 홈 팀 수',
        150: '목표의 어웨이 팀 수',
        151: '더블 찬스 2 반',
        153: '언더 / 오버 1 기 - 홈 팀',
        154: '언더 / 오버 2 기 - 홈 팀',
        155: '언더 / 오버 1 기 - 어웨이 팀',
        156: '언더 / 오버 2 기 - 어웨이 팀',
        157: '/ 위에 노란색 카드에서 - 1 반',
        158: '아래 / 위에 노란색 카드',
        159: '타이 브레이크에서 성냥 될 것인가?',
        160: '레이스 우승자',
        161: '10 분 결과',
        162: '10 분 미만 / 이상',
        163: '목표 후반전의 수',
        164: '타이 브레이크에서 처음으로 설정됩니다?',
        165: '/ 이상 세트에서',
        166: '/ 이상 게임에서',
        168: '자책골을 수 있습니까?',
        169: '두 반쪽에서 점수를',
        170: '홀수 / 짝수 코너',
        171: '승리 중 하나를 반으로',
        175: '타이 브레이크에서 두 번째로 설정됩니다?',
        176: '타이 브레이크에서 세 번째로 설정됩니다?',
        177: '타이 브레이크에서 네 번째로 설정됩니다?',
        178: '타이 브레이크에서 다섯 번째로 설정됩니다?',
        180: '언더 / 오버 던져 - 인',
        181: '아래 / 위에 노란색 카드 - 홈 팀',
        182: '아래 / 위에 노란색 카드 - 홈 팀 1 반',
        183: '아래 / 위에 노란색 카드 - 홈 팀 2 반',
        184: '/ 위에 노란색 카드에서 - 어웨이 팀',
        185: '어웨이 팀 1 반 - / 위에 노란색 카드에서',
        186: '어웨이 팀 2 반 - / 위에 노란색 카드에서',
        187: '/ 위에 노란색 카드에서 - 하반기',
        188: '첫 번째 옐로우 카드',
        190: '첫 번째 옐로우 카드 2 반',
        191: '어떤 선수는 해트트릭을 점수 것인가?',
        192: '목표는 두 반쪽에서 득점됩니까?',
        194: '팀 홈 깨끗한 시트 1 기간을 유지하려면',
        195: '홈 팀은 클린 시트 2 일의 기간을 유지하려면',
        196: '어웨이 팀에 클린 시트 1 기간 유지',
        197: '어웨이 팀에 클린 시트 2 기간 유지',
        198: '홀수 / 짝수 - 홈 팀',
        199: '홀수 / 짝수 - 어웨이 팀',
        200: '첫 득점 팀 승리 것인가?',
        201: '아시안 핸디캡 게임',
        202: '멀리 첫번째 기간 수상작 홈 /',
        203: '멀리 2 기간 수상작 홈 /',
        204: '멀리 3 기 우승자 홈 /',
        205: '멀리 4 기 수상작 홈 /',
        206: '멀리 5 기 수상작 홈 /',
        207: '15 분 결과',
        208: '30 분 결과',
        209: '전체 코너 - 상반기',
        210: '전체 코너 - 하반기',
        211: '두 팀은 후반전 점수',
        212: '두 팀은 두 반쪽에서 점수를',
        213: '두 반쪽에서 그리기',
        214: '언더 / 오버 카드',
        215: '어웨이 팀은 전반전 점수',
        216: '어웨이 팀은 후반전 점수',
        217: '올바른 점수 3 기간',
        218: '홈 팀은 전반전 점수',
        219: '홈팀은 후반전 점수',
        220: '어웨이 팀 포함하여 초과 근무 - / 오버 언더',
        221: '홈 팀 포함하여 초과 근무 - / 오버 언더',
        222: '언더 / 오버 3 기 - 어웨이 팀',
        223: '언더 / 오버 3 기 - 홈 팀',
        224: '더블 찬스 3 기간',
        225: '더블 찬스 4 기',
        226: '12 포함하여 초과 근무',
        227: '레이스 우승자 2 곳',
        228: '레이스 우승자 3 개 장소',
        229: '레이스 우승자 4 곳',
        233: '승리하지 무기 호에',
        235: '1 5 이닝 수상작',
        236: '1 일 5 이닝 미만 / 이상',
        237: '제 1 회 매립에 의지가있을 점수',
        238: '경기 남은',
        239: '목표 범위',
        242: '첫번째 기간 홀수 / 짝수 - 홈 팀',
        243: '첫번째 기간 홀수 / 짝수 - 어웨이 팀',
        244: '최고 오프닝 파트너십',
        245: '목표 / 아니오 목표',
        247: '전반전 남은',
        250: '코너 핸디캡 - 상반기',
        254: '카드 범위',
        255: '1 카드의 시간',
        256: '홈 팀 - 카드 범위',
        257: '어웨이 팀 - 카드 범위',
        258: '코너 범위',
        259: '대부분의 모서리 팀',
        260: '대부분의 전반전 모서리 팀',
        261: '대부분의 하반기 모서리 팀',
        262: '홈 팀 - 코너 범위',
        263: '어웨이 팀 - 코너 범위',
        264: '홈 팀 - 상반기 코너 범위',
        265: '어웨이 팀 - 상반기 코너 범위',
        266: '홈 팀 - 하반기 코너 범위',
        267: '어웨이 팀 - 하반기 코너 범위',
        269: '목표 범위 1 기간',
        271: '기회 믹스',
        274: '특별 수상작',
        275: '목표 범위 2 기간',
        278: '목표 범위 홈 팀',
        279: '목표는 팀을 멀리 범위',
        281: '1 일 5 이닝 아시안 핸디캡',
        282: '1X2 하프 타임',
        283: '아시안 핸디캡 2 반',
        284: '1X2 2 반',
        285: '홀수 / 짝수 2 반',
        286: '(12) 2 반',
        287: '언더 / 오버 4 기 - 홈 팀',
        288: '언더 / 오버 4 기 - 어웨이 팀',
        289: '2 기간 홀수 / 짝수 - 홈 팀',
        290: '3 기간 홀수 / 짝수 - 홈 팀',
        291: '4 기간 홀수 / 짝수 - 홈 팀',
        292: '2 기간 홀수 / 짝수 - 어웨이 팀',
        293: '3 기간 홀수 / 짝수 - 어웨이 팀',
        294: '4 기간 홀수 / 짝수 - 어웨이 팀',
        297: 'SP',
        299: '포인트 수상작',
        300: '게임 수상작',
        301: '목표 전',
        302: '목표 후',
        304: '최고 점수 기간 팀',
        305: '코너 -에서 / 정확 / 이상',
        306: '첫 번째 목표 최종 목표',
        307: '유럽 ​​핸디캡 게임',
        308: '언더 / 오버 최고 점수주기',
        309: '아래에서 / 최저 점수주기',
        310: '시리즈 수상작',
        317: '1 기 - / 정확 / 오버 언더',
        318: '2 기 - / 정확 / 오버 언더',
        319: '제 3 시대 - / 정확 / 오버 언더',
        320: '제 4 기 - / 정확 / 오버 언더',
        322: '아래에서 / 정확 / 이상',
        329: '첫번째 기간 경주',
        330: '2 기간 경주',
        331: '3 기간 경주',
        332: '4 기간 경주',
        333: '5 기간 경주',
        337: '/ 오버 언더 - 하반기',
        338: '다음 목표 1 기간',
        339: '다음 목표 2 기간',
        341: '경주',
        342: '아시안 핸디캡 포함하여 초과 근무',
        343: '게임 범위 1 기간',
        344: '게임 범위 2 기간',
        345: '게임 범위 3 기간',
        346: '게임 범위 제 4 기',
        347: '게임 범위 5 기간',
        348: '제 6 기 수상작',
        349: '7 기 우승자',
        350: '제 6 기 홀수 / 짝수',
        351: '7 기 홀수 / 짝수',
        352: '/ 이상 6 기에서',
        353: '/ 이상 7 기에서',
        354: '홈 팀 - / 오버 하프 타임에서',
        355: '/ 이상 하프 타임에서 - 어웨이 팀',
        356: '해고 6 방법의 방법',
        357: '해고 방법',
        366: '심지어 이상에서 홀수 / 실행 - 홈 팀',
        367: '총도 이상에서 홀수를 / 실행 - 어웨이 팀',
        370: '총 이상에서 실행 - 홈 팀',
        371: '이상 총 런 - 어웨이 팀',
        386: '유럽 ​​핸디캡 포함하여 초과 근무',
        387: '홀수 / 짝수 - 홈 팀 포함하여 초과 근무',
        388: '홀수 / 짝수 - 어웨이 팀 포함하여 초과 근무',
        390: 'HT / FT 포함하여 시간외',
        391: '다음 목표 - 여분의 시간',
        392: '홈 팀의 승부 차기',
        393: '어웨이 팀 페널티 슛 아웃',
        394: '올바른 점수 4 기',
        395: '올바른 점수 5 기간',
        398: '홀수 / 짝수 카드',
        400: '홀수 / 짝수 카드 1 반',
        401: '언더 / 오버 코너 1 반 - 홈 팀',
        402: '/ 이상 코너 전반전에서 - 어웨이 팀',
        403: '언더 / 오버 코너 2 반',
        404: '언더 / 오버 코너 2 반 - 홈 팀',
        405: '/ 이상 코너 하반기에서 - 어웨이 팀',
        406: '홀수 / 짝수 코너 1 반',
        407: '아시안 핸디캡 카드',
        408: '아시안 핸디캡 카드 1 반',
        409: '1X2 코너',
        410: '1X2 코너 1 반',
        411: '12 개 코너',
        414: '언더 / 오버 카드 1 반',
        415: '1X2 그리고 두 팀은 점수를',
        416: '/ 오버 언더 - 여분의 시간',
        417: '추가 시간 1 반 - / 오버 언더',
        419: '1X2 - 추가 시간',
        427: '1X2 이하 / 이상',
        428: '여러 올바른 점수',
        430: '아래에서 / 정확 / 이상 - 1 반',
        431: '아래에서 / 정확 / 이상 - 하반기',
        433: '유럽 ​​핸디캡 코너',
        434: '8 기 수상작',
        435: '9 기간 수상작',
        436: '/ 이상 8 기에서',
        437: '/ 이상 9 기간에서',
        438: '총도 이상에서 / 홀수를 실행',
        439: '이상에서 전체 실행합니다',
        445: '멀리 6 기 우승자 홈 /',
        446: '멀리 7 기 우승자 홈 /',
        447: '아시안 핸디캡 6 기',
        448: '아시안 핸디캡 7 기',
        449: '아시안 핸디캡 8 기',
        450: '아시안 핸디캡 9 기간',
        451: '멀리 8 기 수상작 홈 /',
        452: '멀리 9 기간 수상작 홈 /',
        453: '먼저 점수 방법',
        454: '첫 번째 방법을 점수 - 홈 팀',
        455: '먼저 점수 방법 - 어웨이 팀',
        456: '더블 찬스 1 기간',
        457: '더블 찬스 2 기간',
        459: '홈 팀은 모든 분기에 점수를',
        460: '어웨이 팀은 모든 쿼터에 점수를',
        462: '상위 5 개 수상',
        463: '상위 10 개 수상작',
        464: '12 하반기 포함하여 초과 근무',
        465: '제 4 기 수상작 홈 / 어웨이 포함 초과 근무',
        466: '/ 이상 4 기 포함 초과 근무에서',
        467: '아시안 핸디캡 4 기 포함 초과 근무',
        468: '아시안 핸디캡 하반기 포함하여 초과 근무',
        469: '/ 이상 2 반 포함하여 초과 근무에서',
        472: '총격에 윌 게임 이동?',
        475: '/ 이상에서 이상에서 실행 - 홈 팀 1 이닝',
        476: '어웨이 팀 1 이닝 - / 오버 언더에서 오버를 실행',
        477: '/ 이상에서 이상에서 실행 - 홈 팀 2 이닝',
        478: '어웨이 팀 2 이닝 - / 오버 언더에서 오버를 실행',
        479: '홀수 / 짝수 년 이상을 실행 - 홈 팀 1 이닝',
        480: '홀수 / 짝수 년 이상을 실행 - 멀리 팀 1 이닝',
        481: '홀수 / 짝수 년 이상을 실행 - 홈 팀 2 이닝',
        482: '홀수 / 짝수 년 이상을 실행 - 멀리 팀 2 이닝',
        483: '홈 팀 이상에서 개찰을 얻을 것이다 - 1 이닝을?',
        484: '어웨이 팀 가져 오기 개찰 이상에서 윌 - 1 이닝?',
        485: '홈 팀 이상에서 개찰을 얻을 것이다 - 2 이닝을?',
        486: '2 이닝 - 어웨이 팀 가져 오기 개찰 이상에서 것인가?',
        523: '/ 이상 그리고 두 팀의 점수에서',
        524: '1 7 이닝 수상작',
        525: '1 7 이닝 미만 / 이상',
        526: '1 7 이닝 아시안 핸디캡',
        529: '홀수 / 짝수 하반기 포함하여 초과 근무',
        553: '/ 이상 총격전 페널티 킥 촬영에서',
        554: '12 총격전',
        555: '아시안 핸디캡 총격전',
        556: '올바른 점수 총격전',
        557: '언더 / 오버 슛 아웃',
        558: '홈 팀 - / 오버 슛 아웃에서',
        559: '/ 이상 총격전에서 - 어웨이 팀',
        560: '팀은 마지막 슛 아웃 페널티를 적용하려면',
        563: '인종을 포함한 초과 근무',
        564: '1X2 이하 / 이상 5 방법',
        566: '멀리 10 기 우승자 홈 /',
        567: '멀리 11 기 우승자 홈 /',
        568: '멀리 12 기 우승자 홈 /',
        569: '멀리 13 기 우승자 홈 /',
        570: '멀리 14 기 우승자 홈 /',
        571: '멀리 15 일 기간 수상작 홈 /',
        573: '멀리 16 기 우승자 홈 /',
        574: '멀리 17 기 우승자 홈 /',
        575: '멀리 18 기 우승자 홈 /',
        576: '멀리 19 기 우승자 홈 /',
        577: '10 분 미만 / 이상 - 코너',
        578: '/ 오버 언더 10 분 - 카드',
        579: '다음 코너',
        586: '마침에서 최고 절반',
        587: '아래쪽에 완료하려면',
        588: '마침 바닥',
        589: '아니 마침에서 최고 4',
        590: '강등하려면',
        591: '강등되지 않기 위하여',
        592: '도달 최종',
        593: '마침을 바닥 6',
        594: '승진하려면',
        595: '첫 번째 목표 시간',
        596: '목표 범위 홈 팀의 1 기간',
        597: '목표 범위 홈 팀 2 기간',
        598: '목표는 팀 1주기를 멀리 범위',
        599: '목표는 팀 두번째 기간을 멀리 범위',
        600: '1 기 우승자 그리고 두 팀의 점수',
        601: 'HT / FT 더블 찬스',
        602: '올바른 점수를 포함하여 초과 근무',
        603: '부정적인 HT / FT',
        604: '목표 범위 홈 팀 모두 기간',
        605: '목표는 두 기간 팀을 만들 멀리 범위',
        606: '승자와 목표 유형 중 하나',
        607: '두 번 기회 및 목표',
        608: '승자와 목표 유형 두',
        609: '팀 점수와 목표 모두',
        610: '더블 찬스 믹스',
        628: '마침에서 톱 7',
        629: '마침에서 톱 6',
        630: '마침에서 상위 3',
        631: '마침에서 톱 2',
        632: '마침에서 최고 4',
        633: '마침에서 상위 5',
        634: '홀수 / 짝수 첫번째 기간을 반올림',
        635: '홀수 / 짝수 두번째 기간을 반올림',
        636: '홀수 / 짝수 3 기간을 반올림',
        637: '에이스가 될 것인가?',
        638: '칼 킬이 될 것인가?',
        639: '1 라운드 우승자',
        640: '16 라운드의 우승자',
        641: '칼 죽이있을 것인가? - 1 차 기간',
        642: '칼 죽이있을 것인가? - 2 차 기간',
        643: '칼 죽이있을 것인가? - 제 3 기간',
        644: '에이스가 될 것인가? - 1 차 기간',
        645: '에이스가 될 것인가? - 2 차 기간',
        646: '에이스가 될 것인가? - 제 3 기간',
        647: '초과 근무가 될 것인가? - 1 차 기간',
        648: '초과 근무가 될 것인가? - 2 차 기간',
        649: '초과 근무가 될 것인가? - 제 3 기간',
        650: '16 라운드 수상작 - 1 차 기간',
        651: '16 라운드 수상작 - 2 차 기간',
        652: '16 라운드 수상작 - 제 3 시대',
        653: '1 라운드의 승자 - 1 차 기간',
        654: '1 라운드 수상작 - 2 차 기간',
        655: '1 라운드 수상작 - 제 3 시대',
        660: '게임 시간',
        662: '홀수 / 짝수 라운드',
        663: '1 차 기간 기간',
        664: '2 기간 기간',
        665: '3 기간 기간',
        666: '1 기 - 1의 Roshan',
        667: '두번째 기간 - 1의 Roshan',
        668: '3 기간 - 1의 Roshan',
        669: '1 기 - 1 피',
        670: '두번째 기간 - 1 피',
        671: '3 기간 - 1 피',
        672: '1 기 - 1 가나',
        673: '두번째 기간 - 1 가나',
        674: '3 기간 - 1 가나',
        675: '1 기 - 1 병영',
        677: '두번째 기간 - 1 병영',
        678: '3 기간 - 1 병영',
        679: '1 기 - 1 터렛',
        680: '두번째 기간 - 1 터렛',
        681: '3 기간 - 1 터렛',
        682: '킬으로 경주 - 1 차 기간',
        683: '킬으로 경주 - 2 차 기간',
        684: '킬으로 경주 - 3 기간',
        685: '1 기 - 두 팀은 드래곤을 죽일',
        686: '두번째 기간 - 두 팀은 드래곤을 죽일',
        687: '3 기간 - 두 팀은 드래곤을 죽일',
        688: '두 팀은 억제제를 죽일 - 1 차 기간',
        689: '두 팀은 억제제를 죽일 - 2 차 기간',
        690: '두 팀은 억제제를 죽일 - 3 기간',
        691: '홀수 / 짝수 킬',
        692: '홀수 / 짝수 살인 - 1 차 기간',
        693: '홀수 / 짝수 살인 - 2 차 기간',
        694: '홀수 / 짝수 살인 - 제 3 기간',
        695: 'HT / FT 그리고 목표',
        697: '마침에서 최고 8',
        711: '플레이어는 언제든지에서 점수',
        712: '첫 번째 플레이어에게 점수',
        713: '마지막 선수에게 점수',
        714: '플레이어는 2 또는 더 많은 골을',
        715: '플레이어는 3 개 이상의 목표를 점수',
        727: '첫번째 기간 두 팀은 점수를',
        728: '2 기간 두 팀은 점수를',
        729: '3 기간 두 팀은 점수를',
        730: '4 기간 두 팀은 점수를',
        731: '첫번째 기간 홈 팀에 점수',
        732: '2 기간 홈 팀에 점수',
        733: '3 기간 홈 팀에 점수',
        734: '4 기간 홈 팀에 점수',
        735: '첫번째 기간 어웨이 팀에 점수',
        736: '2 기간 어웨이 팀에 점수',
        737: '3 기간 어웨이 팀에 점수',
        738: '4 기간 어웨이 팀에 점수',
        751: '하려면 1 반 인종',
        752: '2 반 인종 - 포함하여 초과 근무',
        754: '게임 듀스',
        755: '추가 시간 코너 -에서 / 정확 / 이상',
        756: '아래에서 / 노출 부족 여분의 시간 코너',
        757: '아시안 핸디캡 - 여분의 시간',
        758: '10 분 미만 / 이상 - 프리킥',
        759: '10 분 미만 / 이상 - 투사 인',
        760: '10 분 미만 / 이상 - 골킥',
        762: '승리 던져',
        763: '언더 / 오버가에서 일치를 실행합니다',
        765: '대부분의 발로 점수',
        766: '언더 / 오버 - 경기 발로',
        767: '언더 / 오버 - 경기 되죠',
        768: '오십하려면에서 성냥 채점',
        769: '백은 경기의 득점 예정',
        770: '대부분의 실행 아웃',
        791: '최고 타자 - 홈 팀',
        792: '최고 타자 - 어웨이 팀',
        793: '최고 볼러 - 홈 팀',
        794: '최고 볼러 - 어웨이 팀',
        795: '맨 오브 더 매치',
        806: '타자로는 경기에 쉰 점수',
        808: '10 런 레이스',
        809: '최고 1 6 오버는 점수',
        813: '플레이어는 대부분 되죠 점수',
        814: '최고 개인 점수',
        819: '을 가지고 브레이크 포인트에서 게임',
        820: '으로 코너 경주',
        822: '톱 새로운 팀',
        824: '플레이어는 예약',
        825: '플레이어는 퇴장',
        834: '추가 포인트 설정',
        835: '아시아에서 / 이상',
        836: '아시아에서 / 이상 1 기간',
        837: '전반전 홈 팀 승리를 무기 호',
        838: '전반전 어웨이 팀 승리를 무기 호',
        839: '전반전 1X2 이하 / 이상',
        840: '첫번째 기간 홈 팀 승리를 무기 호',
        841: '첫번째 기간 어웨이 팀 승리를 무기 호',
        842: '아래에서 / 정확 / 이상 - 홈 팀',
        843: '어웨이 팀 - / 정확 / 오버 언더',
        844: '첫 번째 팀은 점수 - 홈 / 어웨이',
        845: '팀에 전화 처음으로 아웃',
        846: '/ 오버 히트에서 - 포함 초과',
        849: '아래에서 / 이상 5 매립',
        851: '최고 점수 절반 팀',
        852: '어느 팀이 3 답변이없는 시간을 점수를',
        853: '12 이하 / 이상',
        854: '특수 팀 또는 방어 터치 다운 득점',
        855: '범위 포인트',
        859: '147 브레이크에서 매치',
        861: '첫 번째 방법 3 웨이 점수 - 홈 팀',
        862: '먼저 점수 방법 3 웨이 - 어웨이 팀',
        863: '최고 점수 기간 -1X2와 팀',
        864: '팀 첫 득점 선두',
        865: '팀 마지막 득점 선두',
        866: '아시안 핸디캡 세트',
        870: '먼저 멀리 / 방법 6 웨이 홈 점수',
        878: '목표 범위 3 기간',
        879: '/ 오버 시도 횟수에서',
        880: '상반기 코너 -에서 / 정확 / 이상',
        881: '첫 번째 목표 방법',
        882: '점의 수',
        891: '가장 긴 터치 다운 점수를 팀',
        892: '팀은 가장 긴 필드 골에',
        893: '가장 긴 터치 다운 득점',
        894: '최단 터치 다운 득점',
        895: '가장 긴 필드 골은 득점',
        896: '첫 번째 팀 목표의 시간',
        897: '2 포인트 후 게임 점수',
        898: '3 포인트 후 게임 점수',
        899: '4 점 후 게임 점수',
        900: '게임 점수',
        901: '첫번째 기간 리드 후',
        902: '2 기간 리드 후',
        903: '3 기간 리드 후',
        904: '4 기간 리드 후',
        905: '5 기간 리드 후',
        906: '4 개 게임 후 점수',
        907: '6 개 게임 후 점수',
        908: '브레이크에 설정 봉사',
        909: '스트레이트 세트로 홈 승리',
        910: '원정 승 바로 세트',
        911: '2 개 세트 후 점수',
        912: '3 개 세트 후 점수',
        914: '/ 이상 필드 골 득점에서 - 1 반',
        915: '/ 이상 터치 다운 득점에서 - 1 반',
        916: '/ 이상 홈 팀 터치 다운에서 - 1 반',
        917: '전반전 - / 이상 어웨이 팀 터치 다운에서',
        920: '/ 이상 터치 다운에서',
        921: '아래에서 / 정확 / 이상 - 터치 다운',
        922: '/ 이상 필드 골에서',
        923: '아래에서 / 정확 / 이상 - 필드 골',
        924: '첫 번째 필드 골의 시간 득점',
        925: '첫 번째 터치 다운의 시간 득점',
        926: '먼저 점수의 시간',
        927: '홈 팀에서 / 이상 터치 다운',
        928: '어웨이 팀에서 / 이상 터치 다운',
        929: '홈 팀에서 / 오버 필드 골',
        930: '어웨이 팀에서 / 오버 필드 골',
        932: '유무 먼저 코치 도전에 팀',
        933: '팀은 먼저 허용 페널티을 커밋',
        934: '안전 득점',
        935: '유럽 ​​카드 핸디캡',
        936: '아시아에서 / 이상 카드',
        938: '홈 팀 - / 이상 카드에서',
        939: '/ 이상 카드에서 - 어웨이 팀',
        940: '첫 번째 카드의 시간',
        941: '다음 플레이어에게 점수',
        942: '최고 국적 - 아시아',
        943: '최고 국적 - 호주',
        944: '최고 국적 - 유럽',
        945: '최고 국적 - 영국 & 아일랜드',
        946: '최고 국적 - 세계의 나머지',
        947: '언더 / 오버 에이스',
        948: '/ 이상 에이스에서 - 홈 팀',
        949: '에이스의 정확한 수',
        950: '/ 이상 에이스에서 - 어웨이 팀',
        951: '에이스의 올바른 번호',
        955: '1X2 에이스',
        956: '에이스의 번호를 정확한 - 홈 팀',
        957: '에이스의 정확한 수 - 어웨이 팀',
        958: '에이스 아시안 핸디캡',
        959: '레이스 에이스',
        960: '/ 이상 에이스에서 - 1 기',
        976: '1X2 에이스 - 1 차 기간',
        982: '에이스 아시안 핸디캡 - 1 차 기간',
        986: '(12) 에이스',
        989: '/ 오버 킬에서 - 1 기',
        990: '/ 오버 킬에서 - 2 차 기간',
        991: '/ 오버 킬에서 - 3 기간',
        992: '다음 터치 다운 득점 왕 - 홈 팀',
        993: '다음 터치 다운 득점 왕 - 어웨이 팀',
        994: '다음 터치 다운 득점 왕',
        995: '플레이어 점수 터치 다운에서 애니',
        996: '플레이어는 2 또는 더 많은 터치 다운 점수',
        997: '플레이어는 3 개 이상의 터치 다운 점수',
        998: '첫 경기 코너 - (12)',
        999: '마지막 매치 코너 - (12)',
        1000: '세트 수',
        1001: '/ 이상 더블 폴트에서',
        1002: '/ 이상 더블 폴트에서 - 어웨이 팀',
        1003: '/ 이상 더블 폴트에서 - 홈 팀',
        1004: '/ 이상 더블 폴트에서 - 1 기',
        1010: '12 번 오류',
        1011: '12 명 번 오류 - 1 차 기간',
        1013: '1X2 더블 폴트',
        1016: '더블 오류의 정확한 수',
        1019: '더블 오류의 정확한 수 - 홈 팀',
        1020: '더블 오류의 정확한 수 - 어웨이 팀',
        1027: '더블 폴트의 올바른 번호',
        1030: '최초의 구석의 시간',
        1031: '여러 코너',
        1034: '멀리 20 기 우승자 홈 /',
        1035: '멀리 21 기 우승자 홈 /',
        1036: '멀리 22 기 우승자 홈 /',
        1037: '멀리 23 기 우승자 홈 /',
        1038: '멀리 24 일 기간 수상작 홈 /',
        1039: '멀리 25 일 기간 수상작 홈 /',
        1040: '멀리 26 일 기간 수상작 홈 /',
        1041: '멀리 27 일 기간 수상작 홈 /',
        1042: '멀리 28 일 기간 수상작 홈 /',
        1043: '멀리 29 일 기간 수상작 홈 /',
        1044: '멀리 30 일 기간 수상작 홈 /',
        1046: '멀리 32 회 기간 수상작 홈 /',
        1047: '멀리 33 회 기간 수상작 홈 /',
        1050: '20 포인트 레이스',
        1051: '언더 / 오버 2 반 - 홈 팀',
        1052: '/ 이상 2 반에서 - 어웨이 팀',
        1053: '아시아에서 / 이상 2 기간',
        1054: '아시아에서 / 이상 3 기간',
        1055: '아시아에서 / 이상 4 기간',
        1061: '아시아에서 / 이상 포함하여 초과 근무',
        1062: '최고 득점의 절반 - 홈 팀',
        1063: '최고 득점의 절반 - 어웨이 팀',
        1065: '홈 팀 - 첫 번째 득점 선두',
        1066: '홈 팀 - 마지막 득점 선두',
        1067: '어웨이 팀 - 첫 번째 득점 선두',
        1068: '어웨이 팀 - 마지막 득점 선두',
        1069: '/ 이상 선수 포인트에서',
        1070: '/ 이상 플레이어 블록에서',
        1071: '/ 이상 플레이어 도움에서',
        1072: '언더 / 오버 플레이어 리바운드',
        1073: '언더 / 오버 플레이어 훔치는',
        1074: '언더 / 오버 플레이어 회전율',
        1075: '제작 / 이상 선수 3 개 포인트에서',
        1076: '/ 이상 플레이어에서 3 점 시도',
        1077: '제작 / 이상 플레이어 2 개 포인트에서',
        1078: '/ 이상 플레이어에서 2 점 시도',
        1079: '언더 / 오버 플레이어 무료 제작을 예외',
        1080: '/ 이상 플레이어 무료에서 시도 예외',
        1081: '1X2 경고',
        1082: '더블 찬스 경고',
        1083: '아시안 핸디캡 경고',
        1084: '올바른 점수 경고',
        1085: '언더 / 오버 레드 카드',
        1086: '아래에서 / 정확 / 이상 예약 포인트',
        1087: '아래에서 / 정확 / 이상 예약 곳 - 홈 팀',
        1088: '아래에서 / 정확 / 이상 예약 곳 - 어웨이 팀',
        1089: '/ 이상 플레이어 훔치고 블록에서',
        1090: '/ 이상 선수 포인트에서',
        1091: '/ 이상 플레이어 어시스트, 리바운드에서',
        1092: '/ 이상 플레이어 포인트와 리바운드에서',
        1093: '/ 이상 플레이어 포인트 및 도움에서',
        1094: '플레이어 더블 더블',
        1095: '플레이어 트리플 더블',
        1096: '아래에서 / 정확 / 이상 예약 곳 - 1 차 기간',
        1100: '설정 수상작',
        1101: '/ 이상 180 \에서 세트로이야',
        1102: '설정 / 다리 수상작',
        1103: '설정 / 다리 전체 체크 아웃에서 / 이상',
        1105: '/ 이상 총에서 180 개 \ S - 홈 팀',
        1106: '/ 이상 총 180 \의에서 - 어웨이 팀',
        1112: '/ 이상 플레이어 목표 아래',
        1115: '/ 이상 플레이어의 목표에 따라 허용',
        1120: '/ 이상 라운드에서 - 1 기',
        1121: '/ 이상 라운드에서 - 2 차 기간',
        1122: '/ 이상 라운드에서 - 3 기간',
        1123: '4 기간 - 1 피',
        1124: '5 기간 - 1 피',
        1125: '두 팀 드래곤 죽일',
        1126: '두 팀 남작 죽일',
        1127: '두 팀 억제제를 죽일',
        1128: '언더 / 오버 바론 살해',
        1129: '/ 이상 바론 살해에서 - 1 기',
        1130: '/ 이상 바론 살해에서 - 2 차 기간',
        1131: '/ 이상 바론 살해에서 - 3 기간',
        1132: '언더 / 오버 드래곤 살해',
        1133: '/ 이상 드래곤 살해에서 - 1 기',
        1134: '/ 이상 드래곤 살해에서 - 2 차 기간',
        1135: '/ 이상 드래곤 살해에서 - 3 기간',
        1136: '아래에서 / 이상 180 \의',
        1137: '먼저 180',
        1138: '마지막 180',
        1139: '언더 / 오버 최고 체크 아웃 점수',
        1140: '대부분의 180 개 \의',
        1141: '언더 / 오버 최고 체크 아웃 점수 - 홈 팀',
        1142: '언더 / 오버 최고 체크 아웃 점수 - 어웨이 팀',
        1143: '첫 번째 플레이어에게 점수 터치 다운',
        1144: '마지막 선수에게 점수 터치 다운',
        1145: '첫 번째 플레이어는 터치 다운 점수 - 홈 팀',
        1146: '첫 번째 플레이어는 터치 다운 점수 - 어웨이 팀',
        1147: '/ 오버 킬에서 - 제 4 기',
        1148: '/ 오버 킬에서 - 5 기간',
        1149: '아시안 핸디캡 킬 - 1 차 기간',
        1150: '아시안 핸디캡 킬 - 2 차 기간',
        1151: '아시안 핸디캡 킬 - 3 기간',
        1152: '아시안 핸디캡 킬 - 제 4 기',
        1153: '아시안 핸디캡 킬 - 5 기간',
        1154: '아래에서 / 정확 / 이상 180 \의',
        1155: '아래에서 / 정확 / 이상 총 180 \ S - 홈 팀',
        1156: '아래에서 / 정확 / 이상 총 180 \ S - 어웨이 팀',
        1157: '대부분의 체크 아웃',
        1158: '첫 번째 시도에서 득점 왕',
        1159: '마지막에 한번 득점 왕',
        1160: '언제든지 득점 왕을보십시오',
        1162: '파괴 / 오버 뚜에서 - 1 기',
        1163: '파괴 / 오버 뚜에서 - 2 차 기간',
        1164: '파괴 / 오버 뚜에서 - 3 기간',
        1165: '1 기 - 남작',
        1166: '2 기 - 남작',
        1167: '3 기 - 남작',
        1168: '제 4 기 - 남작',
        1169: '5 기 - 남작',
        1170: '1 기 - 1 드래곤',
        1171: '두번째 기간 - 1 드래곤',
        1172: '3 기간 - 1 드래곤',
        1173: '4 기간 - 1 드래곤',
        1174: '5 기간 - 1 드래곤',
        1175: '대부분의 킬 - 1 차 기간',
        1176: '대부분의 킬 - 2 차 기간',
        1177: '대부분의 킬 - 3 기간',
        1178: '대부분의 킬 - 제 4 기',
        1179: '대부분의 킬 - 5 기간',
        1180: '대부분의 킬 - 제 6 기',
        1181: '대부분의 킬 - 7 기',
        1182: '플레이어는 2 개 이상 할려고 점수',
        1183: '플레이어는 3 개 이상의 할려고 점수',
        1184: '하지 승리 Leauge',
        1185: '아래에서 / 정확 / 이상 포함하여 초과 근무 - 홈 팀',
        1186: '아래에서 / 정확 / 이상 포함하여 초과 근무 - 어웨이 팀',
        1187: '아래에서 / 정확 / 이상 포함하여 초과 근무',
        1188: '/ 이상 대체에서',
        1189: '/ 이상 대체 아래에서 - 1 기',
        1190: '마지막으로 플레이어는 터치 다운 점수 - 홈 팀',
        1191: '마지막으로 플레이어는 터치 다운 점수 - 어웨이 팀',
        1192: '첫 번째 플레이어는 에이스 히트',
        1193: '아래 / 이상 선수 쿼터백이 야드 전달',
        1194: '/ 이상 플레이어 통과 터치 다운에서',
        1195: '언더 / 오버 가장 긴 플레이어 패스 완료',
        1196: '아래 / 개 이상의 플레이어 야드를 받기',
        1197: '언더 / 오버 플레이어 인터셉트',
        1198: '아래 / 이상 플레이어는 포인트를 걷어차',
        1199: '/ 이상 플레이어 야드를 돌진 및 수신에서',
        1200: '아래 / 개 이상의 플레이어 야드를 돌진',
        1201: '/ 이상 플레이어 가장 긴 리셉션에서',
        1202: '언더 / 오버 플레이어 피로연',
        1203: '제작 / 이상 플레이어 필드 골에서',
        1204: '시도 / 이상 플레이어 필드 골에서',
        1205: '/ 이상 선수 쿼터백 통과 터치 다운에서',
        1206: '/ 이상 플레이어 수신기의 수신 완료 횟수에서',
        1207: '/ 이상 선수 쿼터백에서 완료 통과',
        1208: '아래 / 개 이상의 플레이어가 터치 다운을 돌진',
        1209: '/ 이상 플레이어 수신기 리셉션 시도에서',
        1210: '/ 이상 선수 쿼터백에서 시도 통과',
        1211: '1X2 파울',
        1212: '더블 찬스 파울',
        1213: '/ 이상 파울에서 - 1 기',
        1214: '/ 이상 파울에서 - 2 차 기간',
        1215: '아시안 핸디캡 파울',
        1216: '홈 팀 - / 오버 파울에서',
        1217: '/ 이상 파울에서 - 어웨이 팀',
        1218: '/ 이상 플레이어 러쉬 시도에서',
        1219: '/ 이상 플레이어 리시 빙 터치 다운에서',
        1222: '홈 팀 - / 이상 던져 - 인에서',
        1223: '/ 이상 던져 - 인에서 - 어웨이 팀',
        1224: '1X2 던져 - 인',
        1225: '더블 찬스 던져 - 인',
        1226: '/ 이상 던져 - 인에서 - 1 기',
        1227: '일회용 인 장애인',
        1228: '핸디캡을 던져는 인 - 1 차 기간',
        1229: '아래에서 / 대상에 이상 총성 - 홈 팀',
        1230: '아래에서 / 대상에 오버 샷 - 어웨이 팀',
        1231: '대상에 아시안 핸디캡 샷',
        1232: '대상에 아시안 핸디캡 샷 - 1 차 기간',
        1233: '아래에서 / 대상에서 오버 샷 - 1 차 기간',
        1234: '대상에 1X2 샷',
        1235: '대상을 두 번 기회 샷',
        1236: '/ 이상 총 샷에서 - 홈 팀',
        1237: '/ 이상 총 샷에서 - 어웨이 팀',
        1238: '더블 찬스 총 샷',
        1239: '1X2 총 샷',
        1240: '아시안 핸디캡 총 샷',
        1241: '아시안 핸디캡 총 샷 - 1 차 기간',
        1242: '/ 이상 총 샷에서 - 1 기',
        1243: '더블 찬스 코너',
        1244: '더블 찬스 코너 - 1 차 기간',
        1245: '피치의 모든 4 개 코너에서 코너',
        1246: '더블 찬스 경고 - 1 차 기간',
        1247: '1X2 옐로우 카드 - 1 차 기간',
        1248: '아시안 핸디캡 경고 - 1 차 기간',
        1249: '더블 찬스 & 언더 / 오버',
        1250: '더블 찬스 & 두 팀 점수',
        1251: '1 기 - 1 억제제',
        1252: '두번째 기간 - 1 억제제',
        1253: '3 기간 - 1 억제제',
        1254: '4 기간 - 1 억제제',
        1255: '5 기간 - 1 억제제',
        1256: '두 팀 킬 남작 - 1 차 기간',
        1257: '두 팀 킬 남작 - 2 차 기간',
        1258: '두 팀 킬 남작 - 3 기간',
        1259: '두 팀 킬 남작 - 제 4 기',
        1260: '두 팀 킬 남작 - 5 기간',
        1261: '제 4 기 - 두 팀은 드래곤을 죽일',
        1262: '5 기간 - 두 팀은 드래곤을 죽일',
        1263: '두 팀은 억제제를 죽일 - 4 기간',
        1264: '두 팀은 억제제를 죽일 - 5 기간',
        1265: '에이스 다음 게임이 될 것인가?',
        1266: '더블 폴트 다음 게임이 될 것인가?',
        1267: '홀수 / 짝수 파울',
        1268: '아시안 핸디캡 볼 점유율',
        1269: '더블 찬스 오프사이드',
        1270: '아시안 핸디캡 오프사이드',
        1271: '홈 팀 - / 이상 오프사이드에서',
        1272: '/ 이상 오프사이드에서 - 어웨이 팀',
        1273: '1X2 오프사이드',
        1274: '언더 / 오버 킬 1 기 - 홈 팀',
        1275: '언더 / 오버 킬 2 기 - 홈 팀',
        1276: '언더 / 오버 킬 3 기 - 홈 팀',
        1277: '/ 오버 킬 1 기간에서 - 어웨이 팀',
        1278: '/ 오버 킬 2 기간에서 - 어웨이 팀',
        1279: '/ 오버 킬 3 기간에서 - 어웨이 팀',
        1280: '언더 / 오버 광각 렌즈',
        1281: '/ 오버 오리에서',
        1282: '/ 이상 삼에서',
        1283: '언더 / 오버 매치 발로 - 홈 팀',
        1284: '언더 / 오버 매치 되죠 - 홈 팀',
        1285: '/ 이상 일치 발로에서 - 어웨이 팀',
        1286: '/ 이상 일치 되죠에서 - 어웨이 팀',
        1287: '언더 / 오버 엑스트라',
        1288: '1 개찰구 가을 - 홈 팀',
        1289: '1 개찰구 가을 - 어웨이 팀',
        1290: '최고 타자의 팀',
        1291: '/ 이상 일치 되죠 홈 팀에서 - 1 이닝',
        1292: '/ 이상 일치 되죠 어웨이 팀에서 - 1 이닝',
        1293: '/ 이상 일치 발로 홈 팀에서 - 1 이닝',
        1294: '멀리 / 이상 일치 발로 팀에서 - 1 이닝',
        1295: '언더 / 오버 톱 타자',
        1296: '1 일 해고 이상 1 일 회 - 홈 팀',
        1297: '1 이닝 2 이상 기각 - 홈 팀',
        1298: '1 이닝 3 이상 기각 - 홈 팀',
        1299: '1 이닝 2 이상 기각 - 어웨이 팀',
        1300: '1 이닝 3 이상 기각 - 어웨이 팀',
        1301: '1 이닝 1 이상 기각 - 어웨이 팀',
        1302: '최고 점수 오버 - 언더 / 오버',
        1303: '1 이닝 2 오버 언더 / 오버 - 홈 팀',
        1304: '1 이닝 3 오버 언더 / 오버 - 홈 팀',
        1306: '1 이닝 3 오버 언더 / 오버 - 어웨이 팀',
        1307: '1 이닝 2 오버 언더 / 오버 - 어웨이 팀',
        1309: '여백을 승리 - 팀',
        1310: '승리 여백 - 팀 포함하여 초과 근무',
        1312: '정확한 여백 승리',
        1314: '여백 범위를 승리',
        1315: '정확한 여백을 승리 - 팀',
        1316: '모든 다른 점수와 함께 팀 - 여백 승리',
        1318: '아시안 핸디캡 파울 - 1 차 기간',
        1319: '아시안 핸디캡 오프사이드 - 1 차 기간',
        1320: '아시안 핸디캡 오프사이드 - 2 차 기간',
        1321: '아시안 핸디캡 파울 - 2 차 기간',
        1322: '대상에 아시안 핸디캡 샷 - 2 차 기간',
        1324: '/ 이상 오프사이드에서 - 1 기',
        1325: '/ 이상 오프사이드에서 - 2 차 기간',
        1326: '첫 번째 파울',
        1327: '처음 두 포인터',
        1328: '처음 세 개의 포인터',
        1329: '마지막 두 포인터',
        1330: '마지막 세 가지 포인터',
        1331: '마지막 무료 던져 득점',
        1332: '먼저 무료 던져 득점',
        1333: '첫 번째 리바운드',
        1334: '마지막 시간 초과',
        1335: '여백 팀을 승리 - 1 차 기간',
        1336: '여백 팀을 승리 - 2 차 기간',
        1337: '여백 팀을 승리 - 3 기간',
        1338: '여백 팀을 승리 - 4 기간',
        1339: '여백 팀을 승리 - 5 기간',
        1340: '마지막 파울',
        1341: '언더 / 오버 10 일주기',
        1342: '언더 / 오버 11 일주기',
        1343: '언더 / 오버 12 일주기',
        1344: '언더 / 오버 13 일주기',
        1345: '언더 / 오버 14 일주기',
        1346: '언더 / 오버 15 일주기',
        1347: '언더 / 오버 16 일주기',
        1348: '언더 / 오버 17 일주기',
        1349: '언더 / 오버 18 일주기',
        1350: '언더 / 오버 19 일주기',
        1351: '언더 / 오버 20 일주기',
        1352: '언더 / 오버 21 일주기',
        1353: '언더 / 오버 22 일주기',
        1354: '/ 이상 23 일 기간에서',
        1355: '언더 / 오버 24 일주기',
        1356: '언더 / 오버 25 일주기',
        1357: '언더 / 오버 26 일주기',
        1358: '언더 / 오버 27 일주기',
        1359: '언더 / 오버 28 일주기',
        1360: '언더 / 오버 29 일주기',
        1361: '언더 / 오버 30 일주기',
        1362: '언더 / 오버 31 일주기',
        1363: '언더 / 오버 32 기간',
        1364: '/ 오버 33 기간에서',
        1365: '언더 / 오버 34 기간',
        1366: '언더 / 오버 35 기간',
        1367: '아시안 핸디캡 11 일 기간',
        1368: '아시안 핸디캡 12 일 기간',
        1369: '아시안 핸디캡 13 일 기간',
        1370: '아시안 핸디캡 14 일 기간',
        1371: '아시안 핸디캡 15 일 기간',
        1372: '아시안 핸디캡 16 일 기간',
        1373: '아시안 핸디캡 17 일 기간',
        1374: '아시안 핸디캡 18 일 기간',
        1375: '아시안 핸디캡 19 기간',
        1376: '아시안 핸디캡 20 일 기간',
        1380: '아시안 핸디캡 24 일 기간',
        1381: '아시안 핸디캡 25 일 기간',
        1382: '아시안 핸디캡 26 일 기간',
        1383: '아시안 핸디캡 27 일 기간',
        1384: '아시안 핸디캡 28 일 기간',
        1385: '아시안 핸디캡 29 일 기간',
        1386: '아시안 핸디캡 30 일 기간',
        1390: '아시안 핸디캡 34 기간',
        1391: '아시안 핸디캡 35 기간',
        1392: '마침에서 상위 10',
        1393: '에 그대로',
        1394: '아시안 핸디캡 10 일 기간',
        1395: '/ 이상 플레이어되는 파워 포인트에서',
        1396: '언더 / 오버 플레이어 플러스 / 마이너스',
        1397: '/ 이상 플레이어 샷에서',
        1398: '톱 빅토리아 클럽',
        1399: '/ 이상 실행 아웃에서',
        1400: '언더 / 오버 프레임',
        1401: '상위 20 개 수상작',
        1402: '1 라운드 리더',
        1404: '1 라운드 식스 슈터 - 그룹 A',
        1406: '1 라운드 3 공',
        1407: '1 라운드 18 홀 매치 - 업',
        1408: '매치 핸디캡',
        1409: '팀과 함께 최고의 볼러',
        1410: '승리 토스와 일치',
        1411: '/ 이상 포탑 파괴에서',
        1412: '/ 이상 포탑이 파괴에서 - 1 기',
        1413: '/ 이상 포탑이 파괴에서 - 2 차 기간',
        1414: '/ 이상 포탑이 파괴에서 - 3 기간',
        1415: '1 개 이상의 후 최고 점수',
        1416: '홈 팀에서는 / 이상은 실행 - 6 이상에 1 회 이상을',
        1417: '어웨이 팀에서 / 이상은 실행 - 6 이상에 1 회 이상',
        1418: '홈 팀에서는 / 이상은 실행 - 8 이상에 1 회 이상을',
        1419: '어웨이 팀에서 / 이상은 실행 - 8 이상에 1 회 이상',
        1420: '홈 팀에서는 / 이상은 실행 - 1 이상을 10 일 이상으로',
        1421: '어웨이 팀에서 / 이상은 실행 - 10 이상에 1 회 이상',
        1422: '승리하려면 체이스 이상 180 실행 팀',
        1423: '얼마나 많은 런은 첫 번째 볼에서 득점 될 것임',
        1424: '언더 / 오버 매치 Stumpings',
        1425: '1 개찰구 방법',
        1426: '경기 중 첫 번째 공',
        1427: '/ 오버 언더 - 1 이닝',
        1428: '톱 비 빅토리아 클럽',
        1429: '그랜드 파이널 우승자',
        1431: '그랜드 파이널에 도달',
        1432: '아니 마침에서 최고 8',
        1434: '/ 오버 킬 4 기간에서 - 어웨이 팀',
        1435: '/ 오버 킬 5 기간에서 - 어웨이 팀',
        1436: '/ 오버 킬 4 기간에서 - 홈 팀',
        1437: '/ 오버 킬 5 기간에서 - 홈 팀',
        1438: '공항에서 / 이상 온도에서 (섭씨)',
        1439: '아시안 핸디캡 - 풀 타임',
        1440: '/ 이상 값에서 모든 숫자의 그린',
        1441: '모든 홀수 번호의 / 이상 값에서 그린',
        1442: '모든 짝수 / 이상 값에서 그린',
        1443: '더 많은 짝수 홀수 번호보다 그려집니다',
        1444: '연속 숫자가 그려집니다',
        1445: '아래에서 / 정확 / 이상 - 홀수의 번호는 공 번호가 그린',
        1446: '아래에서 / 정확 / 이상 - 짝수 번호의 공의 수 그린',
        1447: '5 개 보너스 공의 합',
        1448: '첫 번째 보너스 볼은 그린 - 홀수 / 짝수',
        1449: '마지막 보너스 볼은 그린 - 홀수 / 짝수',
        1450: '첫 번째 보너스 볼',
        1451: '마지막 보너스 볼',
        1452: '5 보너스 공의 최저',
        1453: '보너스 공 - 홀수 / 짝수',
        1454: '동일한 숫자가 그려집니다',
        1455: '가장 높은 번호는 그린 - 홀수 / 짝수',
        1456: '최저 번호는 그린 - 홀수 / 짝수',
        1457: '볼 1 -에서 / 이상',
        1458: '볼 1 - 홀수 / 짝수',
        1459: '볼 2 - 언더 / 오버',
        1460: '볼 2 - 홀수 / 짝수',
        1461: '볼 3 -에서 / 이상',
        1462: '볼 3 - 홀수 / 짝수',
        1463: '볼 4 -에서 / 이상',
        1464: '볼 4 - 홀수 / 짝수',
        1465: '볼 5 - 언더 / 오버',
        1466: '볼 5 - 홀수 / 짝수',
        1467: '볼 6 - 언더 / 오버',
        1468: '볼 6 - 홀수 / 짝수',
        1469: '공 7 -에서 / 이상',
        1470: '공 7 - 홀수 / 짝수',
        1471: '공 8 - 언더 / 오버',
        1472: '공 8 - 홀수 / 짝수',
        1473: '볼 (9) -에서 / 이상',
        1474: '공 9 - 홀수 / 짝수',
        1475: '볼 - 10 미만 / 이상',
        1476: '볼 10 - 홀수 / 짝수',
        1477: '볼 11 - 언더 / 오버',
        1478: '볼 11 - 홀수 / 짝수',
        1479: '볼 (12) - 언더 / 오버',
        1480: '볼 (12) - 홀수 / 짝수',
        1481: '볼 13 - 언더 / 오버',
        1482: '볼 13 - 홀수 / 짝수',
        1483: '볼 (14) - 언더 / 오버',
        1484: '볼 (14) - 홀수 / 짝수',
        1485: '볼 (15) - 언더 / 오버',
        1486: '볼 (15) - 홀수 / 짝수',
        1487: '볼 (16) - 언더 / 오버',
        1488: '볼 16 - 홀수 / 짝수',
        1489: '볼 17 - 언더 / 오버',
        1490: '볼 17 - 홀수 / 짝수',
        1491: '볼 18 - 언더 / 오버',
        1492: '볼 18 - 홀수 / 짝수',
        1493: '볼 19 - 언더 / 오버',
        1494: '볼 19 - 홀수 / 짝수',
        1495: '볼 20 - 언더 / 오버',
        1496: '볼 20 - 홀수 / 짝수',
        1497: '3입니다 가분을 그린 번호 중 적어도 하나',
        1498: '4입니다 가분을 그린 번호 중 적어도 하나',
        1499: '5입니다 가분을 그린 번호 중 적어도 하나',
        1500: '7입니다 가분을 그린 번호 중 적어도 하나',
        1501: '10입니다 가분을 그린 번호 중 적어도 하나',
        1502: '15입니다 가분을 그린 번호 중 적어도 하나',
        1503: '(20)입니다 가분을 그린 번호 중 적어도 하나',
        1504: '가장 높은 번호는 그린 - 언더 / 오버',
        1505: '총 가장 낮은 값과 가장 높은 숫자는 그린 - 언더 / 오버',
        1506: '최저 번호는 그린 - 언더 / 오버',
        1507: '가장 높고 가장 낮은 숫자의 차이점은 그린 - 언더 / 오버',
        1508: '그린 총 가장 낮은 값과 가장 높은 번호 - 홀수 / 짝수',
        1509: '가장 높고 가장 낮은 숫자의 차이점은 그린 - 홀수 / 짝수',
        1510: '첫 번째 숫자 그린보다 큼으로 마지막 번호는 그린',
        1511: '마지막 번호는 그린 - 홀수 / 짝수',
        1512: '첫 번째 숫자는 그린 - 홀수 / 짝수',
        1513: '모든 그려진 숫자도',
        1514: '모든 그려진 숫자 홀수',
        1515: '수에 그리기',
        1520: '4 기간 - 1 터렛',
        1521: '5 기간 - 1 터렛',
        1523: '/ 이상 바론 살해에서 - 제 4 기',
        1524: '/ 이상 바론 살해에서 - 5 기간',
        1525: '/ 이상 드래곤 살해에서 - 제 4 기',
        1526: '/ 이상 드래곤 살해에서 - 5 기간',
        1527: '/ 이상 포탑이 파괴에서 - 제 4 기',
        1528: '/ 이상 포탑이 파괴에서 - 5 기간',
        1529: '4 기간 기간',
        1530: '5 기간 기간',
        1531: '홀수 / 짝수 살인 - 제 4 기간',
        1532: '홀수 / 짝수 살인 - 5 기간',
        1533: '/ 이상 억제에서 - 1 기',
        1534: '/ 이상 억제에서 - 2 차 기간',
        1535: '범위에서 공항 (섭씨)의 온도',
        1536: '/ 이상지도에서',
        1537: '홈 팀에 승리 & 언더 / 오버 - 포함 초과',
        1538: '어웨이 팀에 승리 & 언더 / 오버 - 포함 초과',
        1539: '라운드 우승자',
        1540: '/ 오버 언더 총 일치 다리',
        1541: '라운드 핸디캡',
        1542: '1 다리 수상작',
        1543: '첫째로 승 다리',
        1546: '4 기간 - 1의 Roshan',
        1547: '5 기간 - 1의 Roshan',
        1548: '4 기간 - 1 병영',
        1549: '5 기간 - 1 병영',
        1550: '4 기간 - 1 가나',
        1551: '5 기간 - 1 가나',
        1552: '아시아에서 / 오버 코너',
        1553: '두 팀은 수 점의 점수 - 1 차 기간',
        1554: '두 팀은 포인트의 수를 점수를 - 전반전을',
        1555: '언더 / 오버 5 기 - 홈 팀',
        1556: '언더 / 오버 5 기 - 어웨이 팀',
        1558: '아시안 핸디캡 포인트',
        1559: '컵 우승자',
        1561: '1 일 3 이닝 수상작',
        1562: '1 일 3 이닝 미만 / 이상',
        1563: '채점의 시간',
        1564: '채점의 시간 - 홈 팀',
        1565: '채점의 시간 - 어웨이 팀',
        1566: '경기 시간 결과',
        1567: '최고 국적 - 남아프리카',
        1568: '최고 국적 - 스페인',
        1569: '최고 국적 - 아일랜드',
        1570: '최고 국적 - 남미',
        1571: '최고 국적 - 캐나다',
        1572: '최고 국적 - 영국인',
        1573: '최고 국적 - 유럽 대륙',
        1575: '컷을 만드는 방법',
        1576: '에 미스 컷',
        1577: '최고 국적 - 미국',
        1578: '우승자의 국적',
        1579: '아시안 핸디캡 포인트 - 1 차 기간',
        1580: '아시안 핸디캡 곳 - 두번째 기간',
        1581: '아시안 핸디캡 포인트 - 3 기간',
        1583: '최고의 팀 보 울러 1 이닝 - 홈 팀',
        1584: '최고의 팀 보 울러 1 이닝 - 어웨이 팀',
        1585: '최고의 팀 타자 1 이닝 - 홈 팀',
        1586: '최고의 팀 타자 1 이닝 - 어웨이 팀',
        1587: '에이스 다음 포인트가있을 것입니다',
        1589: '더블 폴트 다음 포인트가있을 것입니다',
        1591: '/ 오버 언더 - 1 이상',
        1592: '으로 승리 리그',
        1593: '으로 승리 부문',
        1594: '대부분의 조회수 - 포함 초과',
        1595: '이동 거리에 싸움',
        1596: '때 싸움의 끝은 윌',
        1597: '전투 결과',
        1598: '라운드 베팅',
        1599: '최고 1 히트',
        1600: '아래 첫번째 히트',
        1601: '최고 1 차 경기',
        1602: '하단 1 차 경기',
        1603: '제 1 회 회에 대부분의 조회수',
        1605: '최고 득점 이닝으로 팀',
        1606: '두 팀은 2 또는 더 많은 골을',
        1607: '두 팀은 3 인 이상 골을',
        1608: '팀에 승리 대부분의 기간',
        1609: '아시안 핸디캡 풀 타임 - 1 차 기간',
        1610: '아시안 핸디캡 풀 타임 - 제 2 기간',
        1611: '아시안 핸디캡 풀 타임 - 제 3 기간',
        1612: 'MVP',
        1614: '으로 승리 회의',
        1615: '회의 우승',
        1617: '시즌 플레이어 스페셜',
        1618: '1 5 이닝 수상작 - (12)',
        1619: '1 기 - 멀리 끝이 우승자 홈 /',
        1620: '1 기 - 하프 타임 아시아 핸디캡',
        1621: '두번째 기간 - 멀리 끝이 우승자 홈 /',
        1622: '두번째 기간 - 하프 타임 아시아 핸디캡',
        1624: '/ 이상 플레이어를 실행합니다 아래에서',
        1625: '/ 이상 플레이어 단식에서',
        1626: '/ 이상 플레이어 홈런에서',
        1627: '/ 이상 플레이어 도난 기지에서',
        1628: '언더 / 오버 플레이어 복식',
        1629: '언더 / 오버 플레이어 트리플',
        1630: '기록 승리로 투수',
        1631: '/ 이상 플레이어 전체 기지에서',
        1635: '근로 / 오버 투수를 실행합니다 아래에서',
        1636: '/ 이상 투수 조회수에 따라 허용',
        1637: '/ 이상 투수 삼진에서',
        1638: '팀 분류',
        1639: '젊은 라이더 분류',
        1640: '포인트 분류',
        1641: '산의 왕',
        1642: '스테이지 1',
        1649: '가장 빠른 랩',
        1650: '승리 자동차',
        1651: '먼저 드라이버 은퇴',
        1652: '승리 자동차 예선',
        1653: '연습 1에서 가장 빠른',
        1654: '포인트 마침',
        1655: '가장 빠른 예선',
        1657: '3 단계',
        1659: '먼저 생성자 퇴직',
        1660: '우승자의 그리드 위치',
        1661: '레이스 도중 안전 자동차 기간',
        1662: '분류 된 드라이버의 수',
        1663: '레이스 도중 가상 안전 자동차 기간',
        1664: '구멍 점수',
        1667: '으로 승리 구멍',
        1670: '묶여 일치',
        1672: '/ 이상 목표 아래 - 하반기',
        1673: '홀수 / 짝수 제 4 기간을 반올림',
        1674: '홀수 / 짝수 5 기간을 반올림',
        1675: '2 라운드 리더',
        1676: '두 팀은 점수를 - 포함하여 초과 근무',
        1677: '점수를 마지막으로 팀 - 초과 근무 포함',
        1678: '최고 국적 - 독일어',
        1679: '최고 국적 - 오스트리아',
        1680: '최고 국적 - 뉴질랜드',
        1681: '맨 왼쪽 손으로 플레이어',
        1682: '최고 아마추어',
        1683: '최고 국적 - 한국어',
        1684: '최고 국적 - 스칸디나비아',
        1685: '최고 국적 - 프랑스',
        1686: '톱 전 수상작',
        1687: '최고 국적 - 스웨덴',
        1688: '최고 국적 - 일본어',
        1689: '최고 국적 - 스코틀랜드',
        1690: '최고 국적 - 벨기에',
        1691: '유럽 ​​핸디캡 4 기',
        1692: '유럽 ​​핸디캡 3 기간',
        1693: '유럽 ​​핸디캡 2 기간',
        1694: '유럽 ​​핸디캡 1 기간',
        1695: '라운드 점수',
        1696: '토너먼트 승리 점수',
        1697: '라운드 후 점수를 선도',
        1698: '페어웨이로 티샷을 칠',
        1699: '녹색에서 규정 적중',
        1700: '3 라운드 리더',
        1702: '/ 이상 플레이어 통과 시도에서',
        1704: '1 기간에 터치 다운',
        1705: '2 기간에 터치 다운',
        1706: '3 기간에 터치 다운',
        1707: '넷째 기간에 터치 다운',
        1708: '아래에서 / 정확 / 이상 - 홈 팀 터치 다운',
        1709: '아래에서 / 정확 / 이상 - 어웨이 팀 터치 다운',
        1710: '아래에서 / 정확 / 이상 - 홈 팀 전반전 터치 다운',
        1711: '아래에서 / 정확 / 이상 - 어웨이 팀 전반전 터치 다운',
        1712: '16 라운드 수상작 - 제 4 기',
        1713: '16 라운드 수상작 - 5 기간',
        1714: '파괴 / 오버 뚜에서 - 제 4 기',
        1715: '파괴 / 오버 뚜에서 - 5 기간',
        1722: '무기 호에 완료로 설정',
        1723: '/ 오버 킬에서 - 1 기 - 1 라운드',
        1724: '/ 오버 킬에서 - 두번째 기간 - 1 라운드',
        1728: '점수에 두 팀 - 여분의 시간',
        1729: '더블 찬스 - 추가 시간',
        1730: '게임 이동] 슈퍼 오버 것인가?',
        1731: '더블 찬스 5 기간',
        1732: '더블 찬스 6 기',
        1733: '더블 찬스 7 기',
        1734: '더블 찬스 8 기',
        1736: '런 1 이닝 수',
        1737: '의 수는 2 이닝을 실행',
        1738: '런 3 이닝의 수',
        1739: '의 수는 4 이닝을 실행',
        1740: '의 수는 5 이닝을 실행',
        1741: '의 수는 6 이닝을 실행',
        1742: '의 수는 7 이닝을 실행',
        1743: '의 수는 8 이닝을 실행',
        1744: '오십이 1 이닝에서 득점 할',
        1745: '1 개찰구 가을',
        1747: '다음 남자 아웃',
        1748: '완료 경기',
        1749: '세기 득점',
        1750: '세기 득점 - 1 이닝',
        1752: '어웨이 팀 첫 번째 기각 6-방법',
        1753: '톱 매치 타자',
        1754: '페널티 점수',
        1758: '올해의 6 남자',
        1760: '올해의 신인',
        1761: '정규 시즌 MVP',
        1763: '타자는 1 이닝에서 백 점수',
        1765: '경기에서 9 다트 완료',
        1766: '첫 시도의 시간',
        1767: '마지막 시도의 시간',
        1770: '경기 9 다트 마감 - 홈 팀',
        1771: '경기 9 다트 마감 - 어웨이 팀',
        1772: '최고 실행 득점 왕',
        1773: '최고 실행 득점 왕 - 홈 팀',
        1774: '최고 실행 득점 왕 - 어웨이 팀',
        1775: '최고 실행 득점 왕의 팀',
        1776: '언더 / 오버 펀트',
        1777: '언더 / 오버 자루',
        1778: '/ 이상 1 다운스에서',
        1779: '1 팀은 첫 번째 다운을 얻는 방법',
        1780: '전반전에서 / 동안 필드 골 - 홈 팀',
        1781: '전반전에서 / 동안 필드 골 - 어웨이 팀',
        1782: '필드 골 1 반 - / 정확 / 오버 언더',
        1783: '언더 / 오버 터치 다운 1 기간',
        1784: '/ 이상 페널티 킥에서 허용',
        1785: '경기의 대부분 킬',
        1786: '경기의 대부분 킬와 팀',
        1787: '/ 이상 패스 시도에서',
        1788: '언더 / 오버 패스 개 완료',
        1789: '아래 / 오버 야드 전달',
        1790: '/ 이상 러쉬 시도에서',
        1791: '언더 / 오버 러쉬 야드',
        1792: '언더 / 오버 플레이어 태클 및 지원',
        1794: '펀트 처음에 팀',
        1795: '첫번째 기간 1X2 이하 / 이상',
        1796: '1X2 도움',
        1797: '더블 찬스 도움',
        1798: '모든 기간 후 리드하는 팀',
        1799: '대부분의 야드 전달과 함께 팀',
        1800: '대부분의 야드를 돌진와 팀',
        1801: '성공적인 2 포인트 변환',
        1802: '/ 이상 터치 다운 패스에서',
        1803: '/ 이상 필드 골 1 기간에서',
        1804: '1 절반의 2 분 마지막에 점수',
        1805: '팀은 킥 오프 열기 수신하기',
        1806: '/ 이상 홈 팀 터치 다운에서 - 1 시간',
        1807: '언더 / 오버 어웨이 팀 터치 다운 - 1 시간',
        1808: '아래에서 / 정확 / 이상 홈 팀 터치 다운 - 1 시간',
        1809: '아래에서 / 정확 / 이상 어웨이 팀 터치 다운 - 1 시간',
        1810: '/ 이상 홈 팀 필드 골에서 - 1 시간',
        1812: '언더 / 오버 어웨이 팀 필드 골 - 1 시간',
        1813: '승리 모든 분기에 멀리 팀',
        1814: '승리 모든 분기에 홈 팀',
        1815: '게임의 첫 번째 공격 플레이',
        1816: '에 승리 분기',
        1817: '최종 도달하려면',
        1818: '홈 팀은 25 골을',
        1819: '홈 팀은 30 골을',
        1820: '25 골을 멀리 팀',
        1821: '30 골을 멀리 팀',
        1822: '홈 팀에 승리 어느 반',
        1823: '어웨이 팀에 승리 어느 반',
        1824: '제거의 단계',
        1825: '/ 이상 도움에서',
        1826: '언더 / 오버 리바운드',
        1827: '/ 이상 홈 팀의 도움 아래에서',
        1828: '/ 이상 어웨이 팀 도움에서',
        1829: '언더 / 오버 홈 팀 리바운드',
        1830: '멀리 / 이상 팀 리바운드에서',
        1831: '제작 1X2 3 포인트',
        1832: '제작에서 / 이상 3 점',
        1833: '/ 이상 홈 팀에서 3 포인트 제작',
        1834: '/ 이상은 멀리 3 포인트 제작을의 팀 아래',
        1835: '제작 아시안 핸디캡 3 점',
        1841: '먼저 대체',
        1842: '경기의 두번째 목표',
        1843: '경기의 제 3 목표',
        1846: '60 분 결과',
        1847: '75 분 결과',
        1848: '처음 5 분에서 득점 목표?',
        1849: '첫 번째 스로인 경기',
        1850: '어느 반에 그리기',
        1851: '경기의 목표 헤딩?',
        1852: '/ 이상 목표 분에서',
        1853: '경기의 / 오버 타이 브레이크에서',
        1854: '/ 이상 브레이크 포인트에서',
        1855: '언더 / 오버 방학 홈 팀 포인트',
        1856: '/ 이상 브레이크 어웨이 팀 포인트에서',
        1857: '/ 이상 최고 점수 분기에서',
        1858: '/ 최저 점수 분기에서',
        1859: '대부분의 시도 횟수 3 자',
        1860: '10 분이기는 여백 5 방향',
        1861: '대부분의 손실 팀',
        1862: '하위 게임에서 점수 것인가',
        1863: '승리 모든 기간 - 홈 팀',
        1864: '승리 모든 기간 - 어웨이 팀',
        1865: '먼저 10 분에서 / 오버 시도 횟수',
        1866: '15 분 미만 / 이상 - 코너',
        1867: '팀에 의해 행 2 개 목표',
        1868: '팀에 의해 행 3 개 목표',
        1869: '대부분의 변환 시도 횟수',
        1872: '시드 화 결선 진출?',
        1873: '와일드 카드에 도달 최종?',
        1874: '특별 테니스 - 반 우승',
        1875: '승리 분기 - 테니스 특별',
        1881: '홀인원',
        1882: '[재생 오프가 될 것인가?',
        1883: '72 개 홀 매치 업',
        1884: '54 개 홀 매치 업',
        1885: '번호 1 명 선수 선발',
        1886: '1X2 더블 찬스',
        1887: '승리로 홈 팀은 그리기와 \\ 점수 두 팀',
        1888: '원정 승에 팀 그리기 및 점수에 두 팀 \\',
        1889: '승리 여백 - 상반기',
        1891: '아메리칸 리그 사이 영상',
        1892: '아메리칸 리그 MVP',
        1893: '내셔널 리그 사이 영상',
        1894: '내셔널 리그 MVP',
        1895: '기록 가장 정규 시즌 승리로 투수',
        1896: '플레이어는 대부분의 정규 시즌 홈런',
        1897: '윌 게임은 추가 시간에 결정했다?',
        1898: '정규 시즌 승리 %',
        1900: '플레이어 최고 골잡이',
        1901: '언더 / 오버 태클',
        1902: '수 2 선수 선발',
    }
};