import { lSportsConfig } from './lsports.config';

export const MARKET_FOR_OUTER_SLIDER = {
    // Below market should be present in the translation files as well.
    // Football
    1: [
        { Id: '1', Name: '1x2' },
        { Id: '7', Name: 'Remaining match' },
        // { Id: '247', Name: 'Remaining 1st Half' },
        { Id: '8', Name: 'Next Goal' },
        { Id: '18', Name: 'Under/Over' },
        // { Id: '7', Name: 'Double Chance' },
        // { Id: '52', Name: 'Draw No Bet' },
    ],
    // Basketball
    2: [
        { Id: '219', Name: '12 Including Overtime' },
        { Id: '225', Name: 'Under/Over Including Overtime', col: ['Under', 'Over' ], isLine: true },
    ],
    // Ice Hockey
    4: [
        { Id: '1', Name: '1x2' },
    ],
    // Volleyball
    23: [
        { Id: '186', Name: 'Winner' },
    ],
    // Tennis
    5: [
        { Id: '186', Name: 'Winner' }, 
    ],
    // Boxing
    10: [
        { Id: '186', Name: 'Winner' },
    ],
    // Table Tennis
    20: [
        { Id: '186', Name: 'Winner' },
    ],
};


export const mainMarketsForLive = {
    1: ['1', '238', '247', '59', '2', '338'], 2: ['219'], 4: ['1'], 23: ['186'], 5: ['186'], 10: ['186'],
};

export const MARKET_FOR_OUTER_SLIDER_PREMATCH = {
    // Below market should be present in the translation files as well.
    // Football
    1: [
        {
            Id: '1',
            Name: '1x2',
            col: ['1', 'X','2'],
            Bets: {
                'id_1_1': { Id: 'id_1_1', outcome_id: 1, Status: lSportsConfig.betStatus.suspended },
                'id_1_2': { Id: 'id_1_2', outcome_id: 2, Status: lSportsConfig.betStatus.suspended },
                'id_1_3': { Id: 'id_1_3', outcome_id: 3, Status: lSportsConfig.betStatus.suspended },
            }
        },
        { Id: '18', Name: 'Under/Over', col: ['Under', 'Over' ], isLine: true },
        { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2' ] },
        // { Id: '13', Name: 'European Handicap' },
        // { Id: '4', Name: 'HT/FT' },
        { Id: '29', Name: 'Both Teams To Score', col: ['Yes', 'No'] },
        // { Id: '7', Name: 'Double Chance' },
        // { Id: '52', Name: 'Draw No Bet' },
    ],
    // Basketball
    2: [
        { Id: '219', Name: '12 Including Overtime', col: ['1', '2' ] },
        { Id: '225', Name: 'Under/Over Including Overtime', col: ['Under', 'Over' ], isLine: true },
        // { Id: '13', Name: 'European Handicap' }
    ],
    // Ice Hockey
    4: [
        { Id: '1', Name: '1x2',  col: ['1', 'X','2'] },
        // { Id: '13', Name: 'European Handicap' }
    ],
    // Volleyball
    23: [
        { Id: '186', Name: 'Winner', col: ['1', '2' ] },
    ],
    // Tennis
    5: [
        { Id: '186', Name: 'Winner', col: ['1', '2' ] },
    ],
    // Boxing
    10: [
        { Id: '186', Name: 'Winner', col: ['1', '2' ] },
    ],
    // Table Tennis
    20: [
        { Id: '186', Name: 'Winner', col: ['1', '2' ] },
    ],
};
export const MARKET_FOR_TABLE_VIEW_LIVEMATCH = {
    // Below market should be present in the translation files as well.
    // Football
     ...MARKET_FOR_OUTER_SLIDER_PREMATCH, 1: [
        { Id: '7', Name: 'Remaining Matches', col: ['1', 'X','2'] },
        { Id: '18', Name: 'Under/over', col: ['Under', 'Over' ], isLine: true  },
        { Id: '8', Name: 'Next Goal', col: ['1', 'No Goal', '2' ]},
        { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2' ] },
    ]
};

export const MARKET_FOR_TABLE_VIEW_PREMATCH = {
    // Below market should be present in the translation files as well.
    // Football
     ...MARKET_FOR_OUTER_SLIDER_PREMATCH,
     1: [
        { Id: '1', Name: '1x2', col: ['1', 'X','2'] },
        { Id: '18', Name: 'Under/Over', col: ['Under', 'Over' ], isLine: true },
        { Id: '8', Name: 'Next Goal', col: ['1', 'No Goal', '2' ]},
        { Id: '10', Name: 'Double Chance', col: ['1X', '12', 'X2' ] },
    ],
};

export const FOOTBALL_MARKETS = {
    
    extraMarketNames : [ "All", "Main Markets", "Totals", "1st Half", "2nd Half", "Score", "Corners"],

    first_half_market_id_list: {
        marketName: "1st Half",
        marketIds: [60, 61, 62, 68, 69,70, 63, 64, 65, 66, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 402, 470, 471, 472, 482, 483, 484, 485, 486, 487, 488, 489, 490, 542, 552, 602, 618, 619, 635, 636, 637, 905],
    },
    second_half_market_id_list: {
        marketName: "2nd Half",
        marketIds: [83, 90, 84, 85, 86, 87, 88, 91, 92, 93, 94, 95, 96, 97, 98, 543, 544, 545, 553],
    },   
    
    score_market_id_list: {
        marketName: "Score",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 18, 14, 11, 68, 69, 70, 90,2,3,4,5,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,63,64,65,66,71,72,73,74,75,76,77,80,81,84,85,86,87,88,91,92,93,94,95,96,97,98,100,101,102,103,104,105,106,107,108,109,110,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,135,186,187,188,189,190,191,192,193,194,195,196,198,199,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,223,224,225,226,227,228,229,230,231,232,234,235,236,237,238,239,241,245,246,247,248,250,251,253,254,255,256,257,258,259,260,261,262,263,264,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287,288,290,291,293,294,295,297,298,300,301,302,303,304,305,306,309,310,311,312,313,314,315,316,317,318,319,322,323,324,325,326,327,328,329,330,331,332,334,335,340,341,342,345,346,347,348,349,350,351,352,353,354,355,356,357,358,359,360,361,362,363,365,366,367,368,370,371,372,373,374,375,376,377,378,399,400,401,402,404,405,406,407,408,409,410,411,412,413,414,415,416,417,418,419,420,421,422,427,430,431,432,433,434,435,436,437,438,439,440,441,442,443,444,445,446,447,448,449,450,451,452,453,454,455,456,457,458,459,460,462,463,464,465,466,467,470,471,472,491,492,493,494,495,496,497,498,499,500,501,502,503,512,513,514,515,516,520,525,526,527,528,529,532,533,548,549,550,551,552,553,563,604,605,606,607,608,609,610,611,612,613,614,615,616,617,618,619,620,621,634,635,636,637,736,737,738,739,740,741,743,744,745,746,747,748,749,750,751,753,754,755,756,757,758,849,850,851,852,853,879,880,881,883,894,895,896,897,902,903,904,905,1057,1058,1059,1060],
    }, 
    corners_market_id_list: {
        marketName: "Corners",
        marketIds: [162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 601, 602, 884],
    },
     
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1, 7, 8, 10, 29, 35, 18, 14, 11,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,36,37,38,39,40,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,546,547,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },
     
    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 68, 69, 70, 90, 19, 20, 91, 92,  36, 37, 79, 544, 547, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },

    all: {
        marketName: "All",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 35, 18, 14, 11, 68, 69, 70, 90, -1 ],
    }
}

export const BASKETBALL_MARKETS = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home Team", "Away Team", "All Periods"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1, 7, 8, 10, 29, 35, 18, 14, 11,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,36,37,38,39,40,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,546,547,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 68, 69, 70, 90, 19, 20, 91, 92,  36, 37, 79, 544, 547, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_team_id_list: {
        marketName: "Home Team",
        marketIds: [219,105,354,101,153,154,223,287,221,137,198,242,289,290,291,387,731,732,733,734],
    },

    away_team_id_list: {
        marketName: "Away Team",
        marketIds: [216,104,355,102,155,156,222,288,220,138,199,243,292,293,294,388,735,736,737,738],
    },

    all_periods_id_list: {
        marketName: "All Periods",
        marketIds: [443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 462, 529],
    },

    all: {
        marketName: "All",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 35, 18, 14, 11, 68, 69, 70, 90, -1 ],
    }
}

export const ICEHOCKEY_MARKETS = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home Team", "Away Team"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1, 7, 8, 10, 29, 35, 18, 14, 11,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,36,37,38,39,40,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,546,547,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 68, 69, 70, 90, 19, 20, 91, 92,  36, 37, 79, 544, 547, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_team_id_list: {
        marketName: "Home Team",
        marketIds: [97,101,106,137,149,153,154,198,202,203,204,221,223,278,387,1065,1066,1229],
    },

    away_team_id_list: {
        marketName: "Away Team",
        marketIds: [22,96,102,107,138,150,155,156,199,202,203,204,220,222,279,388,1067,1068,1230],
    },

    all: {
        marketName: "All",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 35, 18, 14, 11, 68, 69, 70, 90, -1 ],
    }
}

export const TENNIS_MARKETS = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "All Periods", "Results"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1, 7, 8, 10, 29, 35, 18, 14, 11,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,36,37,38,39,40,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,546,547,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 68, 69, 70, 90, 19, 20, 91, 92,  36, 37, 79, 544, 547, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    all_periods_id_list: {
        marketName: "All Periods",
        marketIds: [443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 462, 529],
    },

    results_id_list: {
        marketName: "Results",
        marketIds: [41,42,43,44,299,300,440,819,882,897,898,899,900,909,910],
    },

    all: {
        marketName: "All",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 35, 18, 14, 11, 68, 69, 70, 90, -1 ],
    }
}

export const VOLLYBALL_MARKETS = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home / Away"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1, 7, 8, 10, 29, 35, 18, 14, 11,9,12,13,15,16,19,20,21,23,24,25,26,27,28,30,31,32,33,34,36,37,38,39,40,41,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,546,547,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 68, 69, 70, 90, 19, 20, 91, 92,  36, 37, 79, 544, 547, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_away_id_list: {
        marketName: "Home / Away",
        marketIds: [101,198,202,203,204,205,206,445,909,102,910],
    },

    all: {
        marketName: "All",
        marketIds: [1, 60, 83, 7, 61, 8, 62, 10, 29, 35, 18, 14, 11, 68, 69, 70, 90, -1 ],     
    }
}

export const BOXING_MARKETS = {
    extraMarketNames: ["All"],

    all: {
        marketName: "All",
        marketIds: [],
    }
}
export const FOOTBALL_MARKETS_PREMATCH = {
    
    extraMarketNames : [ "All", "Main Markets", "Totals", "1st Half", "2nd Half", "Score", "Corners"],

    first_half_market_id_list: {
        marketName: "1st Half",
        marketIds: [ 61, 62, 63, 64, 65, 66, 68, 69, 70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 149, 150, 151, 152, 153, 154, 155, 156, 157, 158, 159, 160, 161, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 402, 470, 471, 472, 482, 483, 484, 485, 486, 487, 488, 489, 490, 542, 552, 602, 618, 619, 635, 636, 637, 905],
    },
    second_half_market_id_list: {
        marketName: "2nd Half",
        marketIds: [83, 84, 85, 86, 87, 88, 90, 91, 92, 93, 94, 95, 96, 97, 98, 543, 544, 545, 553],
    },   
    
    score_market_id_list: {
        marketName: "Score",
        marketIds: [1,60,10,18,29,14,11,19,20,45,47,2,3,4,5,7,8,9,12,13,14,15,16,18,21,23,24,25,26,27,28,29,30,31,32,33,34,41,46,48,49,50,51,52,53,54,55,56,57,58,59,61,62,63,64,65,66,68,69,70,71,72,73,74,75,76,77,80,81,83,84,85,86,87,88,90,91,92,93,94,95,96,97,98,100,101,102,103,104,105,106,107,108,109,110,113,114,115,116,117,118,119,120,121,122,123,124,125,126,127,128,129,130,131,132,133,135,186,187,188,189,190,191,192,193,194,195,196,198,199,201,202,203,204,205,206,207,208,209,210,211,212,213,214,215,216,217,218,219,220,223,224,225,226,227,228,229,230,231,232,234,235,236,237,238,239,241,245,246,247,248,250,251,253,254,255,256,257,258,259,260,261,262,263,264,266,267,268,269,270,271,272,273,274,275,276,277,278,279,280,281,282,283,284,285,286,287,288,290,291,293,294,295,297,298,300,301,302,303,304,305,306,309,310,311,312,313,314,315,316,317,318,319,322,323,324,325,326,327,328,329,330,331,332,334,335,340,341,342,345,346,347,348,349,350,351,352,353,354,355,356,357,358,359,360,361,362,363,365,366,367,368,370,371,372,373,374,375,376,377,378,399,400,401,402,404,405,406,407,408,409,410,411,412,413,414,415,416,417,418,419,420,421,422,427,430,431,432,433,434,435,436,437,438,439,440,441,442,443,444,445,446,447,448,449,450,451,452,453,454,455,456,457,458,459,460,462,463,464,465,466,467,470,471,472,491,492,493,494,495,496,497,498,499,500,501,502,503,512,513,514,515,516,520,525,526,527,528,529,532,533,548,549,550,551,552,553,563,604,605,606,607,608,609,610,611,612,613,614,615,616,617,618,619,620,621,634,635,636,637,736,737,738,739,740,741,743,744,745,746,747,748,749,750,751,753,754,755,756,757,758,849,850,851,852,853,879,880,881,883,894,895,896,897,902,903,904,905,1057,1058,1059,1060],
    }, 
    corners_market_id_list: {
        marketName: "Corners",
        marketIds: [162, 163, 164, 165, 166, 167, 168, 169, 170, 171, 172, 173, 174, 175, 176, 177, 178, 179, 180, 181, 182, 183, 565, 566, 567, 568, 569, 570, 571, 572, 573, 574, 575, 576, 577, 578, 579, 580, 581, 582, 583, 584, 585, 601, 602, 884],
    },
     
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1,10,18,29,35,37,14,11,19,20,45,47,546,547,8,9,12,13,15,16,21,23,24,25,26,27,28,30,31,32,33,34,36,38,39,40,41,46,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },
     
    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 37, 19, 20, 19, 20, 68, 90, 69, 70, 91, 92,  36, 79, 544, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },

    all: {
        marketName: "All",
        marketIds: [ 1, 60, 10, 18, 29, 35, 37, 14, 11, 19, 20, 45, 47, 546, 547, -1 ]
    }
}

export const BASKETBALL_MARKETS_PREMATCH = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home Team", "Away Team", "All Periods"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1,10,18,29,35,37,14,11,19,20,45,47,546,547,8,9,12,13,15,16,21,23,24,25,26,27,28,30,31,32,33,34,36,38,39,40,41,46,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 37, 19, 20, 19, 20, 68, 90, 69, 70, 91, 92,  36, 79, 544, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_team_id_list: {
        marketName: "Home Team",
        marketIds: [219,105,354,101,153,154,223,287,221,137,198,242,289,290,291,387,731,732,733,734],
    },

    away_team_id_list: {
        marketName: "Away Team",
        marketIds: [216,104,355,102,155,156,222,288,220,138,199,243,292,293,294,388,735,736,737,738],
    },

    all_periods_id_list: {
        marketName: "All Periods",
        marketIds: [443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 462, 529],
    },

    all: {
        marketName: "All",
        marketIds: [ 1, 60, 10, 18, 29, 35, 37, 14, 11, 19, 20, 45, 47, 546, 547, -1 ],
    }
}

export const ICEHOCKEY_MARKETS_PREMATHCH = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home Team", "Away Team"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1,10,18,29,35,37,14,11,19,20,45,47,546,547,8,9,12,13,15,16,21,23,24,25,26,27,28,30,31,32,33,34,36,38,39,40,41,46,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 37, 19, 20, 19, 20, 68, 90, 69, 70, 91, 92,  36, 79, 544, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_team_id_list: {
        marketName: "Home Team",
        marketIds: [97,101,106,137,149,153,154,198,202,203,204,221,223,278,387,1065,1066,1229],
    },

    away_team_id_list: {
        marketName: "Away Team",
        marketIds: [22,96,102,107,138,150,155,156,199,202,203,204,220,222,279,388,1067,1068,1230],
    },

    all: {
        marketName: "All",
        marketIds: [ 1, 60, 10, 18, 29, 35, 37, 14, 11, 19, 20, 45, 47, 546, 547, -1 ],
    }
};

export const TENNIS_MARKETS_PREMATCH = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "All Periods", "Results"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1,10,18,29,35,37,14,11,19,20,45,47,546,547,8,9,12,13,15,16,21,23,24,25,26,27,28,30,31,32,33,34,36,38,39,40,41,46,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 37, 19, 20, 19, 20, 68, 90, 69, 70, 91, 92,  36, 79, 544, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    all_periods_id_list: {
        marketName: "All Periods",
        marketIds: [443, 444, 445, 446, 447, 448, 449, 450, 451, 452, 453, 454, 455, 456, 457, 458, 459, 460, 462, 529],
    },

    results_id_list: {
        marketName: "Results",
        marketIds: [41,42,43,44,299,300,440,819,882,897,898,899,900,909,910],
    },

    all: {
        marketName: "All",
        marketIds: [ 1, 60, 10, 18, 29, 35, 37, 14, 11, 19, 20, 45, 47, 546, 547, -1 ],
    }
}

export const VOLLYBALL_MARKETS_PREMATCH = {
    extraMarketNames: [ "All", "Main Markets", "Totals", "Home / Away"],
    
    main_markets_id_list: {
        marketName: "Main Markets",
        marketIds: [1,10,18,29,35,37,14,11,19,20,45,47,546,547,8,9,12,13,15,16,21,23,24,25,26,27,28,30,31,32,33,34,36,38,39,40,41,46,48,49,50,51,52,53,54,55,56,57,58,59,100,101,136,137,138,139,140,141,142,143,144,145,146,147,148,162,163,164,165,166,167,168,169,170,171,172,184,186,187,188,189,190,191,192,193,194,195,196,198,199,201,220,234,237,238,239,241,268,269,270,271,272,273,297,298,300,306,314,326,327,328,329,341,342,365,366,367,368,381,382,383,384,385,386,399,400,401,429,430,431,432,433,434,435,436,437,438,439,440,441,442,467,473,474,475,476,477,478,479,480,481,491,492,493,494,495,496,525,526,532,540,541,548,549,550,551,563,601,604,609,634,723,724,737,738,740,818,819,820,850,851,852,853,854,855,856,857,858,859,860,861,862,863,864,865,879,880,881,888,889,890,891,892,893,896,898,899,900,901,902,968,969,1055,1058,1059,1060],
    },

    totals_id_list: {
        marketName: "Totals",
        marketIds: [18, 37, 19, 20, 19, 20, 68, 90, 69, 70, 91, 92,  36, 79, 544, 548, 818, 854, 858, 855, 859, 2220, 2120, 2121, 72, 73],
    },   
    
    home_away_id_list: {
        marketName: "Home / Away",
        marketIds: [101,198,202,203,204,205,206,445,909,102,910],
    },

    all: {
        marketName: "All",
        marketIds: [ 1, 60, 10, 18, 29, 35, 37, 14, 11, 19, 20, 45, 47, 546, 547, -1 ],     
    }
}

export const BOXING_MARKETS_PREMATCH = {
    extraMarketNames: ["All"],

    all: {
        marketName: "All",
        marketIds: [],
    }
}

export const TABLE_TENNIS_MARKETS = {
    extraMarketNames: [ "All", "Winner"],
    
    winner_id_list: {
        marketName: "Winner",
        marketIds: [186],
    },

    all: {
        marketName: "All",
        marketIds: [186],
    }
};
export const TABLE_TENNIS_MARKETS_PREMATCH = {
    extraMarketNames: [ "All", "Winner"],
    
    winner_id_list: {
        marketName: "Winner",
        marketIds: [186]
    },

    all: {
        marketName: "All",
        marketIds: [ 186 ],
    }
};

export const SPORT_NAME = {
    1: FOOTBALL_MARKETS, // Football
    2: BASKETBALL_MARKETS, // Basketball
    4: ICEHOCKEY_MARKETS, // Ice Hockey
    5: TENNIS_MARKETS, // Tennis
    23: VOLLYBALL_MARKETS, // Volleyball
    10: BOXING_MARKETS, // Boxing
    20: TABLE_TENNIS_MARKETS, // Table Tennis
};

export const SPORT_NAME_PREMATCH = {
    1: FOOTBALL_MARKETS_PREMATCH, // Football
    2: BASKETBALL_MARKETS_PREMATCH, // Basketball
    4: ICEHOCKEY_MARKETS_PREMATHCH, // Ice Hockey
    5: TENNIS_MARKETS_PREMATCH, // Tennis
    23: VOLLYBALL_MARKETS_PREMATCH, // Volleyball
    10: BOXING_MARKETS, // Boxing
    20: TABLE_TENNIS_MARKETS_PREMATCH, // Table Tennis

};

export const MarketIdMapping = {
    "All": 'all',
    "Main Markets": 'main_markets_id_list',
    "Totals": 'totals_id_list',
    "1st Half": 'first_half_market_id_list',
    "2nd Half": 'second_half_market_id_list',
    "Home Team": 'home_team_id_list',
    "Away Team": 'away_team_id_list',
    "All Periods": 'all_periods_id_list',
    "Results": "results_id_list",
    "Home / Away": 'home_away_id_list',
    "Score": "score_market_id_list",
    "Corners": "corners_market_id_list",
}

export const hybridMarketHelperArray = ['','', '1 HT']

export const UnderOverPairMarketIds = [18, 68, 90, 19, 20, 36, 69, 70, 72, 73, 91, 92, 189, 190, 191, 204,  225, 227, 228, 232, 236, 548, 615, 756, 757, 854, 858, 855, 859, 2220, 2120, 2121,];

export const TwoColumnMarketIds = [ 5, 16,18, 30, 55, 66, 68, 90, 19, 20, 69, 70, 72, 73, 88, 91, 92, 36, 187, 188, 189, 201, 223, 225, 227, 228, 232, 236, 292, 540, 541, 542, 545, 546, 615, 756, 757, 854, 858, 855, 859, 1055, 2220, 2120, 2121];

export const SortBySecondary = [ 35, 36, 78, 543];

export const OnlyActiveBets = [ 14, 16, 18, 19, 20, 41, 47, 52, 68, 90, 223, 225, 227, 228, 232, 236, 615 ];

export const handicapMarkets = [ 14, 65, 87 ]; // {hcp} should be removed from staticMarket file.

export const dynamicMarkets = [ 7, 8, 61, 62, 114  ];

export const showScore = [ 7 , 61 ];

// ---- below two should be used to show only market with stable lines

export const stableMarketsOnMainPage = [ 18, 68];

export const stableMarketOnExtraMarket = [ 68 ];

export const marketIds = {
    oneXTwo: 1,
    teamWinRest: 7,
    handicap: 14,
    total: 18,
    exactGoal: 21,
    firstHalfTeamWinRest: 61,
    firstHalfTotal: 68,
    winner: 186,
    oneTwoInclOvertime: 219,
    underOverInclOvertime: 225
};

export const liveMarketOnMain = [ '1', '7', '8', '18', '61', '62', '68', '219', '225', '186' ];
