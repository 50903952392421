export const SET_LOADING = '[APP] SET LOADING';
export const SET_LANGUAGE = '[APP] SET LANGUAGE';
export const SET_COUNTRY_CODE ='[APP] SET COUNTRY CODE';
export const GET_TRANSLATION = '[APP] GET TRANSLATION';
export const SET_TRANSLATION = '[APP] SET TRANSLATION';
export const SET_STATS_DATA = '[APP] SET_STATS_DATA';
export const SET_BANNERS = '[APP] SET BANNERS';
export const SET_PROMOTION_BANNERS = '[APP] SET PROMOTION BANNERS';
export const SET_AD_SPONSOR_BANNERS = '[APP] SET AD SPONSOR BANNERS';
export const SET_PAGE_CONTENT = '[APP] SET PAGE CONTENT';
export const SET_PROMOTION_BANNERS_LOADING = '[APP] SET PROMOTION BANNERS LOADING';
export const SET_AD_SPONSOR_LOADING = '[APP] SET AD SPONSOR LOADING';
export const SET_PAGE_LOADING = '[APP] SET PAGE LOADING';
export const SET_TENET_STATUS = '[APP] SET_TENET_STATUS';
export const SET_BANNERS_LOADING = '[APP] SET BANNERS LOADING';