export const betradarConfig = {
  periods: {
    '0': {
        'id': '0',
        'desc': 'Not started',
        'sports': {
            '_all': 'true'
        }
    },
    '1': {
        'id': '1',
        'desc': '1st period',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '2': {
        'id': '2',
        'desc': '2nd period',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '3': {
        'id': '3',
        'desc': '3rd period',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:4'
                }
            ]
        }
    },
    '4': {
        'id': '4',
        'desc': '4th period'
    },
    '5': {
        'id': '5',
        'desc': '5th period'
    },
    '6': {
        'id': '6',
        'desc': '1st half',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                }
            ]
        }
    },
    '7': {
        'id': '7',
        'desc': '2nd half',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                }
            ]
        }
    },
    '8': {
        'id': '8',
        'desc': '1st set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '9': {
        'id': '9',
        'desc': '2nd set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '10': {
        'id': '10',
        'desc': '3rd set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '11': {
        'id': '11',
        'desc': '4th set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '12': {
        'id': '12',
        'desc': '5th set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '13': {
        'id': '13',
        'desc': '1st quarter',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '14': {
        'id': '14',
        'desc': '2nd quarter',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '15': {
        'id': '15',
        'desc': '3rd quarter',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '16': {
        'id': '16',
        'desc': '4th quarter',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '17': {
        'id': '17',
        'desc': 'Golden set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:34'
                }
            ]
        }
    },
    '18': {
        'id': '18',
        'desc': 'Golden raid'
    },
    '19': {
        'id': '19',
        'desc': 'Coin toss'
    },
    '20': {
        'id': '20',
        'desc': 'Started'
    },
    '21': {
        'id': '21',
        'desc': 'In progress',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:155'
                }
            ]
        }
    },
    '22': {
        'id': '22',
        'desc': 'About to start'
    },
    '30': {
        'id': '30',
        'desc': 'Break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:22'
                }
            ]
        }
    },
    '31': {
        'id': '31',
        'desc': 'Halftime',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '32': {
        'id': '32',
        'desc': 'Awaiting extra time',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:155'
                }
            ]
        }
    },
    '33': {
        'id': '33',
        'desc': 'Extra time halftime',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:29'
                }
            ]
        }
    },
    '34': {
        'id': '34',
        'desc': 'Awaiting penalties',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '35': {
        'id': '35',
        'desc': 'Awaiting penalties'
    },
    '36': {
        'id': '36',
        'desc': 'Awaiting penalties'
    },
    '37': {
        'id': '37',
        'desc': 'Awaiting golden set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:23'
                }
            ]
        }
    },
    '38': {
        'id': '38',
        'desc': 'Awaiting golden raid'
    },
    '40': {
        'id': '40',
        'desc': 'Overtime',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:155'
                }
            ]
        }
    },
    '41': {
        'id': '41',
        'desc': '1st extra',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:29'
                }
            ]
        }
    },
    '42': {
        'id': '42',
        'desc': '2nd extra',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:29'
                }
            ]
        }
    },
    '50': {
        'id': '50',
        'desc': 'Penalties',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '51': {
        'id': '51',
        'desc': 'Penalties'
    },
    '52': {
        'id': '52',
        'desc': 'Penalties'
    },
    '60': {
        'id': '60',
        'desc': 'Postponed',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '61': {
        'id': '61',
        'desc': 'Start delayed',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '70': {
        'id': '70',
        'desc': 'Cancelled'
    },
    '71': {
        'id': '71',
        'desc': 'Game 1'
    },
    '72': {
        'id': '72',
        'desc': 'Game 2'
    },
    '73': {
        'id': '73',
        'desc': 'Game 3'
    },
    '74': {
        'id': '74',
        'desc': 'Game 4'
    },
    '75': {
        'id': '75',
        'desc': 'Game 5'
    },
    '76': {
        'id': '76',
        'desc': 'Game 6'
    },
    '77': {
        'id': '77',
        'desc': 'Game 7'
    },
    '80': {
        'id': '80',
        'desc': 'Interrupted',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:3'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:21'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                },
                {
                    '_id': 'sr:sport:155'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '81': {
        'id': '81',
        'desc': 'Suspended'
    },
    '90': {
        'id': '90',
        'desc': 'Abandoned',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:3'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:21'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                },
                {
                    '_id': 'sr:sport:155'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '91': {
        'id': '91',
        'desc': 'Walkover'
    },
    '92': {
        'id': '92',
        'desc': 'Retired'
    },
    '93': {
        'id': '93',
        'desc': 'Walkover, player 1 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '94': {
        'id': '94',
        'desc': 'Walkover, player 2 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '95': {
        'id': '95',
        'desc': 'Player 1 retired, player 2 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '96': {
        'id': '96',
        'desc': 'Player 2 retired, player 1 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '97': {
        'id': '97',
        'desc': 'Player 1 defaulted, player 2 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '98': {
        'id': '98',
        'desc': 'Player 2 defaulted, player 1 won',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '99': {
        'id': '99',
        'desc': 'Only Result'
    },
    '100': {
        'id': '100',
        'desc': 'Ended',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:3'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:5'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:19'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:21'
                },
                {
                    '_id': 'sr:sport:22'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                },
                {
                    '_id': 'sr:sport:155'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '110': {
        'id': '110',
        'desc': 'AET',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:155'
                }
            ]
        }
    },
    '111': {
        'id': '111',
        'desc': 'AOT'
    },
    '120': {
        'id': '120',
        'desc': 'AP',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:1'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:6'
                },
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:29'
                },
                {
                    '_id': 'sr:sport:157'
                }
            ]
        }
    },
    '130': {
        'id': '130',
        'desc': 'After golden set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:23'
                }
            ]
        }
    },
    '140': {
        'id': '140',
        'desc': 'After golden raid'
    },
    '141': {
        'id': '141',
        'desc': '1st map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '142': {
        'id': '142',
        'desc': '2nd map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '143': {
        'id': '143',
        'desc': '3rd map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '144': {
        'id': '144',
        'desc': '4th map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '145': {
        'id': '145',
        'desc': '5th map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '146': {
        'id': '146',
        'desc': '6th map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '147': {
        'id': '147',
        'desc': '7th map',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '151': {
        'id': '151',
        'desc': '1st Game',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '152': {
        'id': '152',
        'desc': '2nd Game',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '153': {
        'id': '153',
        'desc': '3rd Game',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '154': {
        'id': '154',
        'desc': '4th Game',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '155': {
        'id': '155',
        'desc': '5th Game',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:37'
                }
            ]
        }
    },
    '161': {
        'id': '161',
        'desc': '1st end'
    },
    '162': {
        'id': '162',
        'desc': '2nd end'
    },
    '163': {
        'id': '163',
        'desc': '3rd end'
    },
    '164': {
        'id': '164',
        'desc': '4th end'
    },
    '165': {
        'id': '165',
        'desc': '5th end'
    },
    '166': {
        'id': '166',
        'desc': '6th end'
    },
    '167': {
        'id': '167',
        'desc': '7th end'
    },
    '168': {
        'id': '168',
        'desc': '8th end'
    },
    '169': {
        'id': '169',
        'desc': '9th end'
    },
    '170': {
        'id': '170',
        'desc': '10th end'
    },
    '171': {
        'id': '171',
        'desc': 'Extra end'
    },
    '301': {
        'id': '301',
        'desc': 'First break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '302': {
        'id': '302',
        'desc': 'Second break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:4'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '303': {
        'id': '303',
        'desc': 'Third break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:2'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:16'
                },
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:24'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '304': {
        'id': '304',
        'desc': 'Fourth break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:31'
                },
                {
                    '_id': 'sr:sport:32'
                },
                {
                    '_id': 'sr:sport:34'
                },
                {
                    '_id': 'sr:sport:37'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:110'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '305': {
        'id': '305',
        'desc': 'Fifth break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '306': {
        'id': '306',
        'desc': 'Sixth break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                },
                {
                    '_id': 'sr:sport:109'
                },
                {
                    '_id': 'sr:sport:111'
                }
            ]
        }
    },
    '401': {
        'id': '401',
        'desc': '1st inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '402': {
        'id': '402',
        'desc': '1st inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '403': {
        'id': '403',
        'desc': '2nd inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '404': {
        'id': '404',
        'desc': '2nd inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '405': {
        'id': '405',
        'desc': '3rd inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '406': {
        'id': '406',
        'desc': '3rd inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '407': {
        'id': '407',
        'desc': '4th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '408': {
        'id': '408',
        'desc': '4th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '409': {
        'id': '409',
        'desc': '5th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '410': {
        'id': '410',
        'desc': '5th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '411': {
        'id': '411',
        'desc': '6th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '412': {
        'id': '412',
        'desc': '6th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '413': {
        'id': '413',
        'desc': '7th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '414': {
        'id': '414',
        'desc': '7th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '415': {
        'id': '415',
        'desc': '8th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '416': {
        'id': '416',
        'desc': '8th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '417': {
        'id': '417',
        'desc': '9th inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '418': {
        'id': '418',
        'desc': '9th inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '419': {
        'id': '419',
        'desc': 'Extra inning top',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '420': {
        'id': '420',
        'desc': 'Extra inning bottom',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '421': {
        'id': '421',
        'desc': 'Break top 1 bottom 1',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '422': {
        'id': '422',
        'desc': 'Break top 2 bottom 1',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '423': {
        'id': '423',
        'desc': 'Break top 2 bottom 2',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '424': {
        'id': '424',
        'desc': 'Break top 3 bottom 2',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '425': {
        'id': '425',
        'desc': 'Break top 3 bottom 3',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '426': {
        'id': '426',
        'desc': 'Break top 4 bottom 3',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '427': {
        'id': '427',
        'desc': 'Break top 4 bottom 4',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '428': {
        'id': '428',
        'desc': 'Break top 5 bottom 4',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '429': {
        'id': '429',
        'desc': 'Break top 5 bottom 5',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '430': {
        'id': '430',
        'desc': 'Break top 6 bottom 5',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '431': {
        'id': '431',
        'desc': 'Break top 6 bottom 6',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '432': {
        'id': '432',
        'desc': 'Break top 7 bottom 6',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '433': {
        'id': '433',
        'desc': 'Break top 7 bottom 7',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '434': {
        'id': '434',
        'desc': 'Break top 8 bottom 7',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '435': {
        'id': '435',
        'desc': 'Break top 8 bottom 8',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '436': {
        'id': '436',
        'desc': 'Break top 9 bottom 8',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '437': {
        'id': '437',
        'desc': 'Break top 9 bottom 9',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '438': {
        'id': '438',
        'desc': 'Break top EI bottom 9',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '439': {
        'id': '439',
        'desc': 'Break top EI bottom EI',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    },
    '440': {
        'id': '440',
        'desc': 'Sudden death',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '441': {
        'id': '441',
        'desc': '6th set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                }
            ]
        }
    },
    '442': {
        'id': '442',
        'desc': '7th set',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:20'
                },
                {
                    '_id': 'sr:sport:23'
                }
            ]
        }
    },
    '443': {
        'id': '443',
        'desc': 'Awaiting sudden death',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '444': {
        'id': '444',
        'desc': 'After sudden death',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:12'
                },
                {
                    '_id': 'sr:sport:13'
                },
                {
                    '_id': 'sr:sport:24'
                }
            ]
        }
    },
    '445': {
        'id': '445',
        'desc': 'Break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:19'
                }
            ]
        }
    },
    '501': {
        'id': '501',
        'desc': 'First innings, home team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '502': {
        'id': '502',
        'desc': 'First innings, away team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '503': {
        'id': '503',
        'desc': 'Second innings, home team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '504': {
        'id': '504',
        'desc': 'Second innings, away team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '505': {
        'id': '505',
        'desc': 'Awaiting super over',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '506': {
        'id': '506',
        'desc': 'Super over, home team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '507': {
        'id': '507',
        'desc': 'Super over, away team',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '508': {
        'id': '508',
        'desc': 'After super over',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '509': {
        'id': '509',
        'desc': 'Innings break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '510': {
        'id': '510',
        'desc': 'Super over break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '511': {
        'id': '511',
        'desc': 'Lunch break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '512': {
        'id': '512',
        'desc': 'Tea break',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '513': {
        'id': '513',
        'desc': 'Stumps',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:21'
                }
            ]
        }
    },
    '514': {
        'id': '514',
        'desc': '8th set'
    },
    '515': {
        'id': '515',
        'desc': '9th set'
    },
    '516': {
        'id': '516',
        'desc': '10th set'
    },
    '517': {
        'id': '517',
        'desc': '11th set'
    },
    '518': {
        'id': '518',
        'desc': '12th set'
    },
    '519': {
        'id': '519',
        'desc': '13th set'
    },
    '520': {
        'id': '520',
        'desc': 'Third innings, home team'
    },
    '521': {
        'id': '521',
        'desc': 'Third innings, away team'
    },
    '522': {
        'id': '522',
        'desc': 'Fourth innings, home team'
    },
    '523': {
        'id': '523',
        'desc': 'Fourth innings, away team'
    },
    '524': {
        'id': '524',
        'desc': 'Dinner Break'
    },
    '525': {
        'id': '525',
        'desc': 'Drinks'
    },
    '526': {
        'id': '526',
        'desc': 'Super over'
    },
    '531': {
        'id': '531',
        'desc': '1st inning'
    },
    '532': {
        'id': '532',
        'desc': '2nd inning'
    },
    '533': {
        'id': '533',
        'desc': '3rd inning'
    },
    '534': {
        'id': '534',
        'desc': '4th inning'
    },
    '535': {
        'id': '535',
        'desc': '5th inning'
    },
    '536': {
        'id': '536',
        'desc': '6th inning'
    },
    '537': {
        'id': '537',
        'desc': '7th inning'
    },
    '538': {
        'id': '538',
        'desc': '8th inning'
    },
    '539': {
        'id': '539',
        'desc': '9th inning'
    },
    '540': {
        'id': '540',
        'desc': 'First round'
    },
    '541': {
        'id': '541',
        'desc': 'Second round'
    },
    '542': {
        'id': '542',
        'desc': 'Third round'
    },
    '543': {
        'id': '543',
        'desc': 'Fourth round'
    },
    '544': {
        'id': '544',
        'desc': 'Fifth round'
    },
    '545': {
        'id': '545',
        'desc': 'Sixth round'
    },
    '546': {
        'id': '546',
        'desc': 'Seventh round'
    },
    '547': {
        'id': '547',
        'desc': 'Break topEI-bottom3'
    },
    '548': {
        'id': '548',
        'desc': 'Break topEI-bottom7',
        'sports': {
            'sport': [
                {
                    '_id': 'sr:sport:3'
                }
            ]
        }
    }
  },

  quarterPeriods: [14,15,16,17],

  setPeriods: [8,9,10,11,12,441,442],

  iceHockeyPeriods: [1,2,3,4],

  period: {
    '0':{
        'description':{
            'name_en':'Not started',
            'name_aa':'Not started',
            'name_nl':'Not started',
            'name_de':'Not started',
            'name_el':'Not started',
            'name_pl':'Not started',
            'name_ro':'Not started',
            'name_ru':'Not started',
            'name_tr':'Not started',
            'name_se':'Not started'
        },
        'period_number':null,
        'sports':[
            'all'
        ]
    },
    '1':{
        'description':{
            'name_en':'1st period',
            'name_aa':'1st period',
            'name_nl':'1st period',
            'name_de':'1st period',
            'name_el':'1st period',
            'name_pl':'1st period',
            'name_ro':'1st period',
            'name_ru':'1st period',
            'name_tr':'1st period',
            'name_se':'1st period'
        },
        'period_number':'1',
        'sports':[
            '2',
            '4',
            '157'
        ]
    },
    '2':{
        'description':{
            'name_en':'2nd period',
            'name_aa':'2nd period',
            'name_nl':'2nd period',
            'name_de':'2nd period',
            'name_el':'2nd period',
            'name_pl':'2nd period',
            'name_ro':'2nd period',
            'name_ru':'2nd period',
            'name_tr':'2nd period',
            'name_se':'2nd period'
        },
        'period_number':'2',
        'sports':[
            '2',
            '4',
            '157'
        ]
    },
    '3':{
        'description':{
            'name_en':'3rd period',
            'name_aa':'3rd period',
            'name_nl':'3rd period',
            'name_de':'3rd period',
            'name_el':'3rd period',
            'name_pl':'3rd period',
            'name_ro':'3rd period',
            'name_ru':'3rd period',
            'name_tr':'3rd period',
            'name_se':'3rd period'
        },
        'period_number':'3',
        'sports':[
            '4'
        ]
    },
    '4':{
        'description':{
            'name_en':'4th period',
            'name_aa':'4th period',
            'name_nl':'4th period',
            'name_de':'4th period',
            'name_el':'4th period',
            'name_pl':'4th period',
            'name_ro':'4th period',
            'name_ru':'4th period',
            'name_tr':'4th period',
            'name_se':'4th period'
        },
        'period_number':'4'
    },
    '5':{
        'description':{
            'name_en':'5th period',
            'name_aa':'5th period',
            'name_nl':'5th period',
            'name_de':'5th period',
            'name_el':'5th period',
            'name_pl':'5th period',
            'name_ro':'5th period',
            'name_ru':'5th period',
            'name_tr':'5th period',
            'name_se':'5th period'
        },
        'period_number':'5'
    },
    '6':{
        'description':{
            'name_en':'1st half',
            'name_aa':'1st half',
            'name_nl':'1st half',
            'name_de':'1st half',
            'name_el':'1st half',
            'name_pl':'1st half',
            'name_ro':'1st half',
            'name_ru':'1st half',
            'name_tr':'1st half',
            'name_se':'1st half'
        },
        'period_number':'1',
        'sports':[
            '1',
            '2',
            '6',
            '12',
            '24',
            '29'
        ]
    },
    '7':{
        'description':{
            'name_en':'2nd half',
            'name_aa':'2nd half',
            'name_nl':'2nd half',
            'name_de':'2nd half',
            'name_el':'2nd half',
            'name_pl':'2nd half',
            'name_ro':'2nd half',
            'name_ru':'2nd half',
            'name_tr':'2nd half',
            'name_se':'2nd half'
        },
        'period_number':'2',
        'sports':[
            '1',
            '2',
            '6',
            '12',
            '24',
            '29'
        ]
    },
    '8':{
        'description':{
            'name_en':'1st set',
            'name_aa':'1st set',
            'name_nl':'1st set',
            'name_de':'1st set',
            'name_el':'1st set',
            'name_pl':'1st set',
            'name_ro':'1st set',
            'name_ru':'1st set',
            'name_tr':'1st set',
            'name_se':'1st set'
        },
        'period_number':'1',
        'sports':[
            '5',
            '20',
            '23',
            '31',
            '32',
            '34'
        ]
    },
    '9':{
        'description':{
            'name_en':'2nd set',
            'name_aa':'2nd set',
            'name_nl':'2nd set',
            'name_de':'2nd set',
            'name_el':'2nd set',
            'name_pl':'2nd set',
            'name_ro':'2nd set',
            'name_ru':'2nd set',
            'name_tr':'2nd set',
            'name_se':'2nd set'
        },
        'period_number':'2',
        'sports':[
            '5',
            '20',
            '23',
            '31',
            '32',
            '34'
        ]
    },
    '10':{
        'description':{
            'name_en':'3rd set',
            'name_aa':'3rd set',
            'name_nl':'3rd set',
            'name_de':'3rd set',
            'name_el':'3rd set',
            'name_pl':'3rd set',
            'name_ro':'3rd set',
            'name_ru':'3rd set',
            'name_tr':'3rd set',
            'name_se':'3rd set'
        },
        'period_number':'3',
        'sports':[
            '5',
            '20',
            '23',
            '31',
            '32',
            '34'
        ]
    },
    '11':{
        'description':{
            'name_en':'4th set',
            'name_aa':'4th set',
            'name_nl':'4th set',
            'name_de':'4th set',
            'name_el':'4th set',
            'name_pl':'4th set',
            'name_ro':'4th set',
            'name_ru':'4th set',
            'name_tr':'4th set',
            'name_se':'4th set'
        },
        'period_number':'4',
        'sports':[
            '5',
            '20',
            '23',
            '31',
            '32',
            '34'
        ]
    },
    '12':{
        'description':{
            'name_en':'5th set',
            'name_aa':'5th set',
            'name_nl':'5th set',
            'name_de':'5th set',
            'name_el':'5th set',
            'name_pl':'5th set',
            'name_ro':'5th set',
            'name_ru':'5th set',
            'name_tr':'5th set',
            'name_se':'5th set'
        },
        'period_number':'5',
        'sports':[
            '5',
            '20',
            '23',
            '31',
            '32',
            '34'
        ]
    },
    '13':{
        'description':{
            'name_en':'1st quarter',
            'name_aa':'1st quarter',
            'name_nl':'1st quarter',
            'name_de':'1st quarter',
            'name_el':'1st quarter',
            'name_pl':'1st quarter',
            'name_ro':'1st quarter',
            'name_ru':'1st quarter',
            'name_tr':'1st quarter',
            'name_se':'1st quarter'
        },
        'period_number':'1',
        'sports':[
            '2',
            '13',
            '16',
            '24'
        ]
    },
    '14':{
        'description':{
            'name_en':'2nd quarter',
            'name_aa':'2nd quarter',
            'name_nl':'2nd quarter',
            'name_de':'2nd quarter',
            'name_el':'2nd quarter',
            'name_pl':'2nd quarter',
            'name_ro':'2nd quarter',
            'name_ru':'2nd quarter',
            'name_tr':'2nd quarter',
            'name_se':'2nd quarter'
        },
        'period_number':'2',
        'sports':[
            '2',
            '13',
            '16',
            '24'
        ]
    },
    '15':{
        'description':{
            'name_en':'3rd quarter',
            'name_aa':'3rd quarter',
            'name_nl':'3rd quarter',
            'name_de':'3rd quarter',
            'name_el':'3rd quarter',
            'name_pl':'3rd quarter',
            'name_ro':'3rd quarter',
            'name_ru':'3rd quarter',
            'name_tr':'3rd quarter',
            'name_se':'3rd quarter'
        },
        'period_number':'3',
        'sports':[
            '2',
            '13',
            '16',
            '24'
        ]
    },
    '16':{
        'description':{
            'name_en':'4th quarter',
            'name_aa':'4th quarter',
            'name_nl':'4th quarter',
            'name_de':'4th quarter',
            'name_el':'4th quarter',
            'name_pl':'4th quarter',
            'name_ro':'4th quarter',
            'name_ru':'4th quarter',
            'name_tr':'4th quarter',
            'name_se':'4th quarter'
        },
        'period_number':'4',
        'sports':[
            '2',
            '13',
            '16',
            '24'
        ]
    },
    '17':{
        'description':{
            'name_en':'Golden set',
            'name_aa':'Golden set',
            'name_nl':'Golden set',
            'name_de':'Golden set',
            'name_el':'Golden set',
            'name_pl':'Golden set',
            'name_ro':'Golden set',
            'name_ru':'Golden set',
            'name_tr':'Golden set',
            'name_se':'Golden set'
        },
        'period_number':null,
        'sports':[
            '23',
            '34'
        ]
    },
    '18':{
        'description':{
            'name_en':'Golden raid',
            'name_aa':'Golden raid',
            'name_nl':'Golden raid',
            'name_de':'Golden raid',
            'name_el':'Golden raid',
            'name_pl':'Golden raid',
            'name_ro':'Golden raid',
            'name_ru':'Golden raid',
            'name_tr':'Golden raid',
            'name_se':'Golden raid'
        },
        'period_number':null
    },
    '19':{
        'description':{
            'name_en':'Coin toss',
            'name_aa':'Coin toss',
            'name_nl':'Coin toss',
            'name_de':'Coin toss',
            'name_el':'Coin toss',
            'name_pl':'Coin toss',
            'name_ro':'Coin toss',
            'name_ru':'Coin toss',
            'name_tr':'Coin toss',
            'name_se':'Coin toss'
        },
        'period_number':null
    },
    '20':{
        'description':{
            'name_en':'Started',
            'name_aa':'Started',
            'name_nl':'Started',
            'name_de':'Started',
            'name_el':'Started',
            'name_pl':'Started',
            'name_ro':'Started',
            'name_ru':'Started',
            'name_tr':'Started',
            'name_se':'Started'
        },
        'period_number':null
    },
    '21':{
        'description':{
            'name_en':'In progress',
            'name_aa':'In progress',
            'name_nl':'In progress',
            'name_de':'In progress',
            'name_el':'In progress',
            'name_pl':'In progress',
            'name_ro':'In progress',
            'name_ru':'In progress',
            'name_tr':'In progress',
            'name_se':'In progress'
        },
        'period_number':null,
        'sports':[
            '19',
            '22',
            '155'
        ]
    },
    '22':{
        'description':{
            'name_en':'About to start',
            'name_aa':'About to start',
            'name_nl':'About to start',
            'name_de':'About to start',
            'name_el':'About to start',
            'name_pl':'About to start',
            'name_ro':'About to start',
            'name_ru':'About to start',
            'name_tr':'About to start',
            'name_se':'About to start'
        },
        'period_number':null
    },
    '30':{
        'description':{
            'name_en':'Break',
            'name_aa':'Break',
            'name_nl':'Break',
            'name_de':'Break',
            'name_el':'Break',
            'name_pl':'Break',
            'name_ro':'Break',
            'name_ru':'Break',
            'name_tr':'Break',
            'name_se':'Break'
        },
        'period_number':null,
        'sports':[
            '19',
            '22'
        ]
    },
    '31':{
        'description':{
            'name_en':'Halftime',
            'name_aa':'Halftime',
            'name_nl':'Halftime',
            'name_de':'Halftime',
            'name_el':'Halftime',
            'name_pl':'Halftime',
            'name_ro':'Halftime',
            'name_ru':'Halftime',
            'name_tr':'Halftime',
            'name_se':'Halftime'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '6',
            '12',
            '24',
            '29',
            '157'
        ]
    },
    '32':{
        'description':{
            'name_en':'Awaiting extra time',
            'name_aa':'Awaiting extra time',
            'name_nl':'Awaiting extra time',
            'name_de':'Awaiting extra time',
            'name_el':'Awaiting extra time',
            'name_pl':'Awaiting extra time',
            'name_ro':'Awaiting extra time',
            'name_ru':'Awaiting extra time',
            'name_tr':'Awaiting extra time',
            'name_se':'Awaiting extra time'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '4',
            '6',
            '12',
            '13',
            '16',
            '29',
            '155'
        ]
    },
    '33':{
        'description':{
            'name_en':'Extra time halftime',
            'name_aa':'Extra time halftime',
            'name_nl':'Extra time halftime',
            'name_de':'Extra time halftime',
            'name_el':'Extra time halftime',
            'name_pl':'Extra time halftime',
            'name_ro':'Extra time halftime',
            'name_ru':'Extra time halftime',
            'name_tr':'Extra time halftime',
            'name_se':'Extra time halftime'
        },
        'period_number':null,
        'sports':[
            '1',
            '6',
            '12',
            '13',
            '29'
        ]
    },
    '34':{
        'description':{
            'name_en':'Awaiting penalties',
            'name_aa':'Awaiting penalties',
            'name_nl':'Awaiting penalties',
            'name_de':'Awaiting penalties',
            'name_el':'Awaiting penalties',
            'name_pl':'Awaiting penalties',
            'name_ro':'Awaiting penalties',
            'name_ru':'Awaiting penalties',
            'name_tr':'Awaiting penalties',
            'name_se':'Awaiting penalties'
        },
        'period_number':null,
        'sports':[
            '1',
            '4',
            '6',
            '12',
            '24',
            '29',
            '157'
        ]
    },
    '35':{
        'description':{
            'name_en':'Awaiting penalties',
            'name_aa':'Awaiting penalties',
            'name_nl':'Awaiting penalties',
            'name_de':'Awaiting penalties',
            'name_el':'Awaiting penalties',
            'name_pl':'Awaiting penalties',
            'name_ro':'Awaiting penalties',
            'name_ru':'Awaiting penalties',
            'name_tr':'Awaiting penalties',
            'name_se':'Awaiting penalties'
        },
        'period_number':null
    },
    '36':{
        'description':{
            'name_en':'Awaiting penalties',
            'name_aa':'Awaiting penalties',
            'name_nl':'Awaiting penalties',
            'name_de':'Awaiting penalties',
            'name_el':'Awaiting penalties',
            'name_pl':'Awaiting penalties',
            'name_ro':'Awaiting penalties',
            'name_ru':'Awaiting penalties',
            'name_tr':'Awaiting penalties',
            'name_se':'Awaiting penalties'
        },
        'period_number':null
    },
    '37':{
        'description':{
            'name_en':'Awaiting golden set',
            'name_aa':'Awaiting golden set',
            'name_nl':'Awaiting golden set',
            'name_de':'Awaiting golden set',
            'name_el':'Awaiting golden set',
            'name_pl':'Awaiting golden set',
            'name_ro':'Awaiting golden set',
            'name_ru':'Awaiting golden set',
            'name_tr':'Awaiting golden set',
            'name_se':'Awaiting golden set'
        },
        'period_number':null,
        'sports':[
            '23'
        ]
    },
    '38':{
        'description':{
            'name_en':'Awaiting golden raid',
            'name_aa':'Awaiting golden raid',
            'name_nl':'Awaiting golden raid',
            'name_de':'Awaiting golden raid',
            'name_el':'Awaiting golden raid',
            'name_pl':'Awaiting golden raid',
            'name_ro':'Awaiting golden raid',
            'name_ru':'Awaiting golden raid',
            'name_tr':'Awaiting golden raid',
            'name_se':'Awaiting golden raid'
        },
        'period_number':null
    },
    '40':{
        'description':{
            'name_en':'Overtime',
            'name_aa':'Overtime',
            'name_nl':'Overtime',
            'name_de':'Overtime',
            'name_el':'Overtime',
            'name_pl':'Overtime',
            'name_ro':'Overtime',
            'name_ru':'Overtime',
            'name_tr':'Overtime',
            'name_se':'Overtime'
        },
        'period_number':null,
        'sports':[
            '2',
            '4',
            '16',
            '155'
        ]
    },
    '41':{
        'description':{
            'name_en':'1st extra',
            'name_aa':'1st extra',
            'name_nl':'1st extra',
            'name_de':'1st extra',
            'name_el':'1st extra',
            'name_pl':'1st extra',
            'name_ro':'1st extra',
            'name_ru':'1st extra',
            'name_tr':'1st extra',
            'name_se':'1st extra'
        },
        'period_number':null,
        'sports':[
            '1',
            '6',
            '12',
            '13',
            '29'
        ]
    },
    '42':{
        'description':{
            'name_en':'2nd extra',
            'name_aa':'2nd extra',
            'name_nl':'2nd extra',
            'name_de':'2nd extra',
            'name_el':'2nd extra',
            'name_pl':'2nd extra',
            'name_ro':'2nd extra',
            'name_ru':'2nd extra',
            'name_tr':'2nd extra',
            'name_se':'2nd extra'
        },
        'period_number':null,
        'sports':[
            '1',
            '6',
            '12',
            '13',
            '29'
        ]
    },
    '50':{
        'description':{
            'name_en':'Penalties',
            'name_aa':'Penalties',
            'name_nl':'Penalties',
            'name_de':'Penalties',
            'name_el':'Penalties',
            'name_pl':'Penalties',
            'name_ro':'Penalties',
            'name_ru':'Penalties',
            'name_tr':'Penalties',
            'name_se':'Penalties'
        },
        'period_number':null,
        'sports':[
            '1',
            '4',
            '6',
            '12',
            '24',
            '29',
            '157'
        ]
    },
    '51':{
        'description':{
            'name_en':'Penalties',
            'name_aa':'Penalties',
            'name_nl':'Penalties',
            'name_de':'Penalties',
            'name_el':'Penalties',
            'name_pl':'Penalties',
            'name_ro':'Penalties',
            'name_ru':'Penalties',
            'name_tr':'Penalties',
            'name_se':'Penalties'
        },
        'period_number':null
    },
    '52':{
        'description':{
            'name_en':'Penalties',
            'name_aa':'Penalties',
            'name_nl':'Penalties',
            'name_de':'Penalties',
            'name_el':'Penalties',
            'name_pl':'Penalties',
            'name_ro':'Penalties',
            'name_ru':'Penalties',
            'name_tr':'Penalties',
            'name_se':'Penalties'
        },
        'period_number':null
    },
    '60':{
        'description':{
            'name_en':'Postponed',
            'name_aa':'Postponed',
            'name_nl':'Postponed',
            'name_de':'Postponed',
            'name_el':'Postponed',
            'name_pl':'Postponed',
            'name_ro':'Postponed',
            'name_ru':'Postponed',
            'name_tr':'Postponed',
            'name_se':'Postponed'
        },
        'period_number':null,
        'sports':[
            '12',
            '24'
        ]
    },
    '61':{
        'description':{
            'name_en':'Start delayed',
            'name_aa':'Start delayed',
            'name_nl':'Start delayed',
            'name_de':'Start delayed',
            'name_el':'Start delayed',
            'name_pl':'Start delayed',
            'name_ro':'Start delayed',
            'name_ru':'Start delayed',
            'name_tr':'Start delayed',
            'name_se':'Start delayed'
        },
        'period_number':null,
        'sports':[
            '5',
            '6',
            '19',
            '20',
            '22',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111',
            '157'
        ]
    },
    '70':{
        'description':{
            'name_en':'Cancelled',
            'name_aa':'Cancelled',
            'name_nl':'Cancelled',
            'name_de':'Cancelled',
            'name_el':'Cancelled',
            'name_pl':'Cancelled',
            'name_ro':'Cancelled',
            'name_ru':'Cancelled',
            'name_tr':'Cancelled',
            'name_se':'Cancelled'
        },
        'period_number':null
    },
    '71':{
        'description':{
            'name_en':'Game 1',
            'name_aa':'Game 1',
            'name_nl':'Game 1',
            'name_de':'Game 1',
            'name_el':'Game 1',
            'name_pl':'Game 1',
            'name_ro':'Game 1',
            'name_ru':'Game 1',
            'name_tr':'Game 1',
            'name_se':'Game 1'
        },
        'period_number':'1'
    },
    '72':{
        'description':{
            'name_en':'Game 2',
            'name_aa':'Game 2',
            'name_nl':'Game 2',
            'name_de':'Game 2',
            'name_el':'Game 2',
            'name_pl':'Game 2',
            'name_ro':'Game 2',
            'name_ru':'Game 2',
            'name_tr':'Game 2',
            'name_se':'Game 2'
        },
        'period_number':'2'
    },
    '73':{
        'description':{
            'name_en':'Game 3',
            'name_aa':'Game 3',
            'name_nl':'Game 3',
            'name_de':'Game 3',
            'name_el':'Game 3',
            'name_pl':'Game 3',
            'name_ro':'Game 3',
            'name_ru':'Game 3',
            'name_tr':'Game 3',
            'name_se':'Game 3'
        },
        'period_number':'3'
    },
    '74':{
        'description':{
            'name_en':'Game 4',
            'name_aa':'Game 4',
            'name_nl':'Game 4',
            'name_de':'Game 4',
            'name_el':'Game 4',
            'name_pl':'Game 4',
            'name_ro':'Game 4',
            'name_ru':'Game 4',
            'name_tr':'Game 4',
            'name_se':'Game 4'
        },
        'period_number':'4'
    },
    '75':{
        'description':{
            'name_en':'Game 5',
            'name_aa':'Game 5',
            'name_nl':'Game 5',
            'name_de':'Game 5',
            'name_el':'Game 5',
            'name_pl':'Game 5',
            'name_ro':'Game 5',
            'name_ru':'Game 5',
            'name_tr':'Game 5',
            'name_se':'Game 5'
        },
        'period_number':'5'
    },
    '76':{
        'description':{
            'name_en':'Game 6',
            'name_aa':'Game 6',
            'name_nl':'Game 6',
            'name_de':'Game 6',
            'name_el':'Game 6',
            'name_pl':'Game 6',
            'name_ro':'Game 6',
            'name_ru':'Game 6',
            'name_tr':'Game 6',
            'name_se':'Game 6'
        },
        'period_number':'6'
    },
    '77':{
        'description':{
            'name_en':'Game 7',
            'name_aa':'Game 7',
            'name_nl':'Game 7',
            'name_de':'Game 7',
            'name_el':'Game 7',
            'name_pl':'Game 7',
            'name_ro':'Game 7',
            'name_ru':'Game 7',
            'name_tr':'Game 7',
            'name_se':'Game 7'
        },
        'period_number':'7'
    },
    '80':{
        'description':{
            'name_en':'Interrupted',
            'name_aa':'Interrupted',
            'name_nl':'Interrupted',
            'name_de':'Interrupted',
            'name_el':'Interrupted',
            'name_pl':'Interrupted',
            'name_ro':'Interrupted',
            'name_ru':'Interrupted',
            'name_tr':'Interrupted',
            'name_se':'Interrupted'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '12',
            '13',
            '16',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '29',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111',
            '155',
            '157'
        ]
    },
    '81':{
        'description':{
            'name_en':'Suspended',
            'name_aa':'Suspended',
            'name_nl':'Suspended',
            'name_de':'Suspended',
            'name_el':'Suspended',
            'name_pl':'Suspended',
            'name_ro':'Suspended',
            'name_ru':'Suspended',
            'name_tr':'Suspended',
            'name_se':'Suspended'
        },
        'period_number':null
    },
    '90':{
        'description':{
            'name_en':'Abandoned',
            'name_aa':'Abandoned',
            'name_nl':'Abandoned',
            'name_de':'Abandoned',
            'name_el':'Abandoned',
            'name_pl':'Abandoned',
            'name_ro':'Abandoned',
            'name_ru':'Abandoned',
            'name_tr':'Abandoned',
            'name_se':'Abandoned'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '12',
            '13',
            '16',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '29',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111',
            '155',
            '157'
        ]
    },
    '91':{
        'description':{
            'name_en':'Walkover',
            'name_aa':'Walkover',
            'name_nl':'Walkover',
            'name_de':'Walkover',
            'name_el':'Walkover',
            'name_pl':'Walkover',
            'name_ro':'Walkover',
            'name_ru':'Walkover',
            'name_tr':'Walkover',
            'name_se':'Walkover'
        },
        'period_number':null
    },
    '92':{
        'description':{
            'name_en':'Retired',
            'name_aa':'Retired',
            'name_nl':'Retired',
            'name_de':'Retired',
            'name_el':'Retired',
            'name_pl':'Retired',
            'name_ro':'Retired',
            'name_ru':'Retired',
            'name_tr':'Retired',
            'name_se':'Retired'
        },
        'period_number':null
    },
    '93':{
        'description':{
            'name_en':'Walkover, player 1 won',
            'name_aa':'Walkover, player 1 won',
            'name_nl':'Walkover, player 1 won',
            'name_de':'Walkover, player 1 won',
            'name_el':'Walkover, player 1 won',
            'name_pl':'Walkover, player 1 won',
            'name_ro':'Walkover, player 1 won',
            'name_ru':'Walkover, player 1 won',
            'name_tr':'Walkover, player 1 won',
            'name_se':'Walkover, player 1 won'
        },
        'period_number':null,
        'sports':[
            '5',
            '19',
            '20',
            '22',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '94':{
        'description':{
            'name_en':'Walkover, player 2 won',
            'name_aa':'Walkover, player 2 won',
            'name_nl':'Walkover, player 2 won',
            'name_de':'Walkover, player 2 won',
            'name_el':'Walkover, player 2 won',
            'name_pl':'Walkover, player 2 won',
            'name_ro':'Walkover, player 2 won',
            'name_ru':'Walkover, player 2 won',
            'name_tr':'Walkover, player 2 won',
            'name_se':'Walkover, player 2 won'
        },
        'period_number':null,
        'sports':[
            '5',
            '19',
            '20',
            '22',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '95':{
        'description':{
            'name_en':'Player 1 retired, player 2 won',
            'name_aa':'Player 1 retired, player 2 won',
            'name_nl':'Player 1 retired, player 2 won',
            'name_de':'Player 1 retired, player 2 won',
            'name_el':'Player 1 retired, player 2 won',
            'name_pl':'Player 1 retired, player 2 won',
            'name_ro':'Player 1 retired, player 2 won',
            'name_ru':'Player 1 retired, player 2 won',
            'name_tr':'Player 1 retired, player 2 won',
            'name_se':'Player 1 retired, player 2 won'
        },
        'period_number':null,
        'sports':[
            '5',
            '19',
            '20',
            '22',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '96':{
        'description':{
            'name_en':'Player 2 retired, player 1 won',
            'name_aa':'Player 2 retired, player 1 won',
            'name_nl':'Player 2 retired, player 1 won',
            'name_de':'Player 2 retired, player 1 won',
            'name_el':'Player 2 retired, player 1 won',
            'name_pl':'Player 2 retired, player 1 won',
            'name_ro':'Player 2 retired, player 1 won',
            'name_ru':'Player 2 retired, player 1 won',
            'name_tr':'Player 2 retired, player 1 won',
            'name_se':'Player 2 retired, player 1 won'
        },
        'period_number':null,
        'sports':[
            '5',
            '19',
            '20',
            '22',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '97':{
        'description':{
            'name_en':'Player 1 defaulted, player 2 won',
            'name_aa':'Player 1 defaulted, player 2 won',
            'name_nl':'Player 1 defaulted, player 2 won',
            'name_de':'Player 1 defaulted, player 2 won',
            'name_el':'Player 1 defaulted, player 2 won',
            'name_pl':'Player 1 defaulted, player 2 won',
            'name_ro':'Player 1 defaulted, player 2 won',
            'name_ru':'Player 1 defaulted, player 2 won',
            'name_tr':'Player 1 defaulted, player 2 won',
            'name_se':'Player 1 defaulted, player 2 won'
        },
        'period_number':null,
        'sports':[
            '19',
            '20',
            '22',
            '31',
            '32',
            '37'
        ]
    },
    '98':{
        'description':{
            'name_en':'Player 2 defaulted, player 1 won',
            'name_aa':'Player 2 defaulted, player 1 won',
            'name_nl':'Player 2 defaulted, player 1 won',
            'name_de':'Player 2 defaulted, player 1 won',
            'name_el':'Player 2 defaulted, player 1 won',
            'name_pl':'Player 2 defaulted, player 1 won',
            'name_ro':'Player 2 defaulted, player 1 won',
            'name_ru':'Player 2 defaulted, player 1 won',
            'name_tr':'Player 2 defaulted, player 1 won',
            'name_se':'Player 2 defaulted, player 1 won'
        },
        'period_number':null,
        'sports':[
            '19',
            '20',
            '22',
            '31',
            '32',
            '37'
        ]
    },
    '99':{
        'description':{
            'name_en':'Only Result',
            'name_aa':'Only Result',
            'name_nl':'Only Result',
            'name_de':'Only Result',
            'name_el':'Only Result',
            'name_pl':'Only Result',
            'name_ro':'Only Result',
            'name_ru':'Only Result',
            'name_tr':'Only Result',
            'name_se':'Only Result'
        },
        'period_number':null
    },
    '100':{
        'description':{
            'name_en':'Ended',
            'name_aa':'Ended',
            'name_nl':'Ended',
            'name_de':'Ended',
            'name_el':'Ended',
            'name_pl':'Ended',
            'name_ro':'Ended',
            'name_ru':'Ended',
            'name_tr':'Ended',
            'name_se':'Ended'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '3',
            '4',
            '5',
            '6',
            '12',
            '13',
            '16',
            '19',
            '20',
            '21',
            '22',
            '23',
            '24',
            '29',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111',
            '155',
            '157'
        ]
    },
    '110':{
        'description':{
            'name_en':'AET',
            'name_aa':'AET',
            'name_nl':'AET',
            'name_de':'AET',
            'name_el':'AET',
            'name_pl':'AET',
            'name_ro':'AET',
            'name_ru':'AET',
            'name_tr':'AET',
            'name_se':'AET'
        },
        'period_number':null,
        'sports':[
            '1',
            '2',
            '4',
            '6',
            '12',
            '13',
            '16',
            '29',
            '155'
        ]
    },
    '111':{
        'description':{
            'name_en':'AOT',
            'name_aa':'AOT',
            'name_nl':'AOT',
            'name_de':'AOT',
            'name_el':'AOT',
            'name_pl':'AOT',
            'name_ro':'AOT',
            'name_ru':'AOT',
            'name_tr':'AOT',
            'name_se':'AOT'
        },
        'period_number':null
    },
    '120':{
        'description':{
            'name_en':'AP',
            'name_aa':'AP',
            'name_nl':'AP',
            'name_de':'AP',
            'name_el':'AP',
            'name_pl':'AP',
            'name_ro':'AP',
            'name_ru':'AP',
            'name_tr':'AP',
            'name_se':'AP'
        },
        'period_number':null,
        'sports':[
            '1',
            '4',
            '6',
            '12',
            '24',
            '29',
            '157'
        ]
    },
    '130':{
        'description':{
            'name_en':'After golden set',
            'name_aa':'After golden set',
            'name_nl':'After golden set',
            'name_de':'After golden set',
            'name_el':'After golden set',
            'name_pl':'After golden set',
            'name_ro':'After golden set',
            'name_ru':'After golden set',
            'name_tr':'After golden set',
            'name_se':'After golden set'
        },
        'period_number':null,
        'sports':[
            '23'
        ]
    },
    '140':{
        'description':{
            'name_en':'After golden raid',
            'name_aa':'After golden raid',
            'name_nl':'After golden raid',
            'name_de':'After golden raid',
            'name_el':'After golden raid',
            'name_pl':'After golden raid',
            'name_ro':'After golden raid',
            'name_ru':'After golden raid',
            'name_tr':'After golden raid',
            'name_se':'After golden raid'
        },
        'period_number':null
    },
    '141':{
        'description':{
            'name_en':'1st map',
            'name_aa':'1st map',
            'name_nl':'1st map',
            'name_de':'1st map',
            'name_el':'1st map',
            'name_pl':'1st map',
            'name_ro':'1st map',
            'name_ru':'1st map',
            'name_tr':'1st map',
            'name_se':'1st map'
        },
        'period_number':'1',
        'sports':[
            '109',
            '110',
            '111'
        ]
    },
    '142':{
        'description':{
            'name_en':'2nd map',
            'name_aa':'2nd map',
            'name_nl':'2nd map',
            'name_de':'2nd map',
            'name_el':'2nd map',
            'name_pl':'2nd map',
            'name_ro':'2nd map',
            'name_ru':'2nd map',
            'name_tr':'2nd map',
            'name_se':'2nd map'
        },
        'period_number':'2',
        'sports':[
            '109',
            '110',
            '111'
        ]
    },
    '143':{
        'description':{
            'name_en':'3rd map',
            'name_aa':'3rd map',
            'name_nl':'3rd map',
            'name_de':'3rd map',
            'name_el':'3rd map',
            'name_pl':'3rd map',
            'name_ro':'3rd map',
            'name_ru':'3rd map',
            'name_tr':'3rd map',
            'name_se':'3rd map'
        },
        'period_number':'3',
        'sports':[
            '109',
            '110',
            '111'
        ]
    },
    '144':{
        'description':{
            'name_en':'4th map',
            'name_aa':'4th map',
            'name_nl':'4th map',
            'name_de':'4th map',
            'name_el':'4th map',
            'name_pl':'4th map',
            'name_ro':'4th map',
            'name_ru':'4th map',
            'name_tr':'4th map',
            'name_se':'4th map'
        },
        'period_number':'4',
        'sports':[
            '109',
            '110',
            '111'
        ]
    },
    '145':{
        'description':{
            'name_en':'5th map',
            'name_aa':'5th map',
            'name_nl':'5th map',
            'name_de':'5th map',
            'name_el':'5th map',
            'name_pl':'5th map',
            'name_ro':'5th map',
            'name_ru':'5th map',
            'name_tr':'5th map',
            'name_se':'5th map'
        },
        'period_number':'5',
        'sports':[
            '109',
            '110',
            '111'
        ]
    },
    '146':{
        'description':{
            'name_en':'6th map',
            'name_aa':'6th map',
            'name_nl':'6th map',
            'name_de':'6th map',
            'name_el':'6th map',
            'name_pl':'6th map',
            'name_ro':'6th map',
            'name_ru':'6th map',
            'name_tr':'6th map',
            'name_se':'6th map'
        },
        'period_number':'6',
        'sports':[
            '109',
            '111'
        ]
    },
    '147':{
        'description':{
            'name_en':'7th map',
            'name_aa':'7th map',
            'name_nl':'7th map',
            'name_de':'7th map',
            'name_el':'7th map',
            'name_pl':'7th map',
            'name_ro':'7th map',
            'name_ru':'7th map',
            'name_tr':'7th map',
            'name_se':'7th map'
        },
        'period_number':'7',
        'sports':[
            '109',
            '111'
        ]
    },
    '151':{
        'description':{
            'name_en':'1st Game',
            'name_aa':'1st Game',
            'name_nl':'1st Game',
            'name_de':'1st Game',
            'name_el':'1st Game',
            'name_pl':'1st Game',
            'name_ro':'1st Game',
            'name_ru':'1st Game',
            'name_tr':'1st Game',
            'name_se':'1st Game'
        },
        'period_number':'1',
        'sports':[
            '37'
        ]
    },
    '152':{
        'description':{
            'name_en':'2nd Game',
            'name_aa':'2nd Game',
            'name_nl':'2nd Game',
            'name_de':'2nd Game',
            'name_el':'2nd Game',
            'name_pl':'2nd Game',
            'name_ro':'2nd Game',
            'name_ru':'2nd Game',
            'name_tr':'2nd Game',
            'name_se':'2nd Game'
        },
        'period_number':'2',
        'sports':[
            '37'
        ]
    },
    '153':{
        'description':{
            'name_en':'3rd Game',
            'name_aa':'3rd Game',
            'name_nl':'3rd Game',
            'name_de':'3rd Game',
            'name_el':'3rd Game',
            'name_pl':'3rd Game',
            'name_ro':'3rd Game',
            'name_ru':'3rd Game',
            'name_tr':'3rd Game',
            'name_se':'3rd Game'
        },
        'period_number':'3',
        'sports':[
            '37'
        ]
    },
    '154':{
        'description':{
            'name_en':'4th Game',
            'name_aa':'4th Game',
            'name_nl':'4th Game',
            'name_de':'4th Game',
            'name_el':'4th Game',
            'name_pl':'4th Game',
            'name_ro':'4th Game',
            'name_ru':'4th Game',
            'name_tr':'4th Game',
            'name_se':'4th Game'
        },
        'period_number':'4',
        'sports':[
            '37'
        ]
    },
    '155':{
        'description':{
            'name_en':'5th Game',
            'name_aa':'5th Game',
            'name_nl':'5th Game',
            'name_de':'5th Game',
            'name_el':'5th Game',
            'name_pl':'5th Game',
            'name_ro':'5th Game',
            'name_ru':'5th Game',
            'name_tr':'5th Game',
            'name_se':'5th Game'
        },
        'period_number':'5',
        'sports':[
            '37'
        ]
    },
    '161':{
        'description':{
            'name_en':'1st end',
            'name_aa':'1st end',
            'name_nl':'1st end',
            'name_de':'1st end',
            'name_el':'1st end',
            'name_pl':'1st end',
            'name_ro':'1st end',
            'name_ru':'1st end',
            'name_tr':'1st end',
            'name_se':'1st end'
        },
        'period_number':null
    },
    '162':{
        'description':{
            'name_en':'2nd end',
            'name_aa':'2nd end',
            'name_nl':'2nd end',
            'name_de':'2nd end',
            'name_el':'2nd end',
            'name_pl':'2nd end',
            'name_ro':'2nd end',
            'name_ru':'2nd end',
            'name_tr':'2nd end',
            'name_se':'2nd end'
        },
        'period_number':null
    },
    '163':{
        'description':{
            'name_en':'3rd end',
            'name_aa':'3rd end',
            'name_nl':'3rd end',
            'name_de':'3rd end',
            'name_el':'3rd end',
            'name_pl':'3rd end',
            'name_ro':'3rd end',
            'name_ru':'3rd end',
            'name_tr':'3rd end',
            'name_se':'3rd end'
        },
        'period_number':null
    },
    '164':{
        'description':{
            'name_en':'4th end',
            'name_aa':'4th end',
            'name_nl':'4th end',
            'name_de':'4th end',
            'name_el':'4th end',
            'name_pl':'4th end',
            'name_ro':'4th end',
            'name_ru':'4th end',
            'name_tr':'4th end',
            'name_se':'4th end'
        },
        'period_number':null
    },
    '165':{
        'description':{
            'name_en':'5th end',
            'name_aa':'5th end',
            'name_nl':'5th end',
            'name_de':'5th end',
            'name_el':'5th end',
            'name_pl':'5th end',
            'name_ro':'5th end',
            'name_ru':'5th end',
            'name_tr':'5th end',
            'name_se':'5th end'
        },
        'period_number':null
    },
    '166':{
        'description':{
            'name_en':'6th end',
            'name_aa':'6th end',
            'name_nl':'6th end',
            'name_de':'6th end',
            'name_el':'6th end',
            'name_pl':'6th end',
            'name_ro':'6th end',
            'name_ru':'6th end',
            'name_tr':'6th end',
            'name_se':'6th end'
        },
        'period_number':null
    },
    '167':{
        'description':{
            'name_en':'7th end',
            'name_aa':'7th end',
            'name_nl':'7th end',
            'name_de':'7th end',
            'name_el':'7th end',
            'name_pl':'7th end',
            'name_ro':'7th end',
            'name_ru':'7th end',
            'name_tr':'7th end',
            'name_se':'7th end'
        },
        'period_number':null
    },
    '168':{
        'description':{
            'name_en':'8th end',
            'name_aa':'8th end',
            'name_nl':'8th end',
            'name_de':'8th end',
            'name_el':'8th end',
            'name_pl':'8th end',
            'name_ro':'8th end',
            'name_ru':'8th end',
            'name_tr':'8th end',
            'name_se':'8th end'
        },
        'period_number':null
    },
    '169':{
        'description':{
            'name_en':'9th end',
            'name_aa':'9th end',
            'name_nl':'9th end',
            'name_de':'9th end',
            'name_el':'9th end',
            'name_pl':'9th end',
            'name_ro':'9th end',
            'name_ru':'9th end',
            'name_tr':'9th end',
            'name_se':'9th end'
        },
        'period_number':null
    },
    '170':{
        'description':{
            'name_en':'10th end',
            'name_aa':'10th end',
            'name_nl':'10th end',
            'name_de':'10th end',
            'name_el':'10th end',
            'name_pl':'10th end',
            'name_ro':'10th end',
            'name_ru':'10th end',
            'name_tr':'10th end',
            'name_se':'10th end'
        },
        'period_number':null
    },
    '171':{
        'description':{
            'name_en':'Extra end',
            'name_aa':'Extra end',
            'name_nl':'Extra end',
            'name_de':'Extra end',
            'name_el':'Extra end',
            'name_pl':'Extra end',
            'name_ro':'Extra end',
            'name_ru':'Extra end',
            'name_tr':'Extra end',
            'name_se':'Extra end'
        },
        'period_number':null
    },
    '301':{
        'description':{
            'name_en':'First break',
            'name_aa':'First break',
            'name_nl':'First break',
            'name_de':'First break',
            'name_el':'First break',
            'name_pl':'First break',
            'name_ro':'First break',
            'name_ru':'First break',
            'name_tr':'First break',
            'name_se':'First break'
        },
        'period_number':null,
        'sports':[
            '2',
            '4',
            '13',
            '16',
            '20',
            '23',
            '24',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '302':{
        'description':{
            'name_en':'Second break',
            'name_aa':'Second break',
            'name_nl':'Second break',
            'name_de':'Second break',
            'name_el':'Second break',
            'name_pl':'Second break',
            'name_ro':'Second break',
            'name_ru':'Second break',
            'name_tr':'Second break',
            'name_se':'Second break'
        },
        'period_number':null,
        'sports':[
            '2',
            '4',
            '13',
            '16',
            '20',
            '23',
            '24',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '303':{
        'description':{
            'name_en':'Third break',
            'name_aa':'Third break',
            'name_nl':'Third break',
            'name_de':'Third break',
            'name_el':'Third break',
            'name_pl':'Third break',
            'name_ro':'Third break',
            'name_ru':'Third break',
            'name_tr':'Third break',
            'name_se':'Third break'
        },
        'period_number':null,
        'sports':[
            '2',
            '13',
            '16',
            '20',
            '23',
            '24',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '304':{
        'description':{
            'name_en':'Fourth break',
            'name_aa':'Fourth break',
            'name_nl':'Fourth break',
            'name_de':'Fourth break',
            'name_el':'Fourth break',
            'name_pl':'Fourth break',
            'name_ro':'Fourth break',
            'name_ru':'Fourth break',
            'name_tr':'Fourth break',
            'name_se':'Fourth break'
        },
        'period_number':null,
        'sports':[
            '20',
            '23',
            '31',
            '32',
            '34',
            '37',
            '109',
            '110',
            '111'
        ]
    },
    '305':{
        'description':{
            'name_en':'Fifth break',
            'name_aa':'Fifth break',
            'name_nl':'Fifth break',
            'name_de':'Fifth break',
            'name_el':'Fifth break',
            'name_pl':'Fifth break',
            'name_ro':'Fifth break',
            'name_ru':'Fifth break',
            'name_tr':'Fifth break',
            'name_se':'Fifth break'
        },
        'period_number':null,
        'sports':[
            '20',
            '23',
            '109',
            '111'
        ]
    },
    '306':{
        'description':{
            'name_en':'Sixth break',
            'name_aa':'Sixth break',
            'name_nl':'Sixth break',
            'name_de':'Sixth break',
            'name_el':'Sixth break',
            'name_pl':'Sixth break',
            'name_ro':'Sixth break',
            'name_ru':'Sixth break',
            'name_tr':'Sixth break',
            'name_se':'Sixth break'
        },
        'period_number':null,
        'sports':[
            '20',
            '23',
            '109',
            '111'
        ]
    },
    '401':{
        'description':{
            'name_en':'1st inning top',
            'name_aa':'1st inning top',
            'name_nl':'1st inning top',
            'name_de':'1st inning top',
            'name_el':'1st inning top',
            'name_pl':'1st inning top',
            'name_ro':'1st inning top',
            'name_ru':'1st inning top',
            'name_tr':'1st inning top',
            'name_se':'1st inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '402':{
        'description':{
            'name_en':'1st inning bottom',
            'name_aa':'1st inning bottom',
            'name_nl':'1st inning bottom',
            'name_de':'1st inning bottom',
            'name_el':'1st inning bottom',
            'name_pl':'1st inning bottom',
            'name_ro':'1st inning bottom',
            'name_ru':'1st inning bottom',
            'name_tr':'1st inning bottom',
            'name_se':'1st inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '403':{
        'description':{
            'name_en':'2nd inning top',
            'name_aa':'2nd inning top',
            'name_nl':'2nd inning top',
            'name_de':'2nd inning top',
            'name_el':'2nd inning top',
            'name_pl':'2nd inning top',
            'name_ro':'2nd inning top',
            'name_ru':'2nd inning top',
            'name_tr':'2nd inning top',
            'name_se':'2nd inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '404':{
        'description':{
            'name_en':'2nd inning bottom',
            'name_aa':'2nd inning bottom',
            'name_nl':'2nd inning bottom',
            'name_de':'2nd inning bottom',
            'name_el':'2nd inning bottom',
            'name_pl':'2nd inning bottom',
            'name_ro':'2nd inning bottom',
            'name_ru':'2nd inning bottom',
            'name_tr':'2nd inning bottom',
            'name_se':'2nd inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '405':{
        'description':{
            'name_en':'3rd inning top',
            'name_aa':'3rd inning top',
            'name_nl':'3rd inning top',
            'name_de':'3rd inning top',
            'name_el':'3rd inning top',
            'name_pl':'3rd inning top',
            'name_ro':'3rd inning top',
            'name_ru':'3rd inning top',
            'name_tr':'3rd inning top',
            'name_se':'3rd inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '406':{
        'description':{
            'name_en':'3rd inning bottom',
            'name_aa':'3rd inning bottom',
            'name_nl':'3rd inning bottom',
            'name_de':'3rd inning bottom',
            'name_el':'3rd inning bottom',
            'name_pl':'3rd inning bottom',
            'name_ro':'3rd inning bottom',
            'name_ru':'3rd inning bottom',
            'name_tr':'3rd inning bottom',
            'name_se':'3rd inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '407':{
        'description':{
            'name_en':'4th inning top',
            'name_aa':'4th inning top',
            'name_nl':'4th inning top',
            'name_de':'4th inning top',
            'name_el':'4th inning top',
            'name_pl':'4th inning top',
            'name_ro':'4th inning top',
            'name_ru':'4th inning top',
            'name_tr':'4th inning top',
            'name_se':'4th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '408':{
        'description':{
            'name_en':'4th inning bottom',
            'name_aa':'4th inning bottom',
            'name_nl':'4th inning bottom',
            'name_de':'4th inning bottom',
            'name_el':'4th inning bottom',
            'name_pl':'4th inning bottom',
            'name_ro':'4th inning bottom',
            'name_ru':'4th inning bottom',
            'name_tr':'4th inning bottom',
            'name_se':'4th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '409':{
        'description':{
            'name_en':'5th inning top',
            'name_aa':'5th inning top',
            'name_nl':'5th inning top',
            'name_de':'5th inning top',
            'name_el':'5th inning top',
            'name_pl':'5th inning top',
            'name_ro':'5th inning top',
            'name_ru':'5th inning top',
            'name_tr':'5th inning top',
            'name_se':'5th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '410':{
        'description':{
            'name_en':'5th inning bottom',
            'name_aa':'5th inning bottom',
            'name_nl':'5th inning bottom',
            'name_de':'5th inning bottom',
            'name_el':'5th inning bottom',
            'name_pl':'5th inning bottom',
            'name_ro':'5th inning bottom',
            'name_ru':'5th inning bottom',
            'name_tr':'5th inning bottom',
            'name_se':'5th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '411':{
        'description':{
            'name_en':'6th inning top',
            'name_aa':'6th inning top',
            'name_nl':'6th inning top',
            'name_de':'6th inning top',
            'name_el':'6th inning top',
            'name_pl':'6th inning top',
            'name_ro':'6th inning top',
            'name_ru':'6th inning top',
            'name_tr':'6th inning top',
            'name_se':'6th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '412':{
        'description':{
            'name_en':'6th inning bottom',
            'name_aa':'6th inning bottom',
            'name_nl':'6th inning bottom',
            'name_de':'6th inning bottom',
            'name_el':'6th inning bottom',
            'name_pl':'6th inning bottom',
            'name_ro':'6th inning bottom',
            'name_ru':'6th inning bottom',
            'name_tr':'6th inning bottom',
            'name_se':'6th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '413':{
        'description':{
            'name_en':'7th inning top',
            'name_aa':'7th inning top',
            'name_nl':'7th inning top',
            'name_de':'7th inning top',
            'name_el':'7th inning top',
            'name_pl':'7th inning top',
            'name_ro':'7th inning top',
            'name_ru':'7th inning top',
            'name_tr':'7th inning top',
            'name_se':'7th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '414':{
        'description':{
            'name_en':'7th inning bottom',
            'name_aa':'7th inning bottom',
            'name_nl':'7th inning bottom',
            'name_de':'7th inning bottom',
            'name_el':'7th inning bottom',
            'name_pl':'7th inning bottom',
            'name_ro':'7th inning bottom',
            'name_ru':'7th inning bottom',
            'name_tr':'7th inning bottom',
            'name_se':'7th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '415':{
        'description':{
            'name_en':'8th inning top',
            'name_aa':'8th inning top',
            'name_nl':'8th inning top',
            'name_de':'8th inning top',
            'name_el':'8th inning top',
            'name_pl':'8th inning top',
            'name_ro':'8th inning top',
            'name_ru':'8th inning top',
            'name_tr':'8th inning top',
            'name_se':'8th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '416':{
        'description':{
            'name_en':'8th inning bottom',
            'name_aa':'8th inning bottom',
            'name_nl':'8th inning bottom',
            'name_de':'8th inning bottom',
            'name_el':'8th inning bottom',
            'name_pl':'8th inning bottom',
            'name_ro':'8th inning bottom',
            'name_ru':'8th inning bottom',
            'name_tr':'8th inning bottom',
            'name_se':'8th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '417':{
        'description':{
            'name_en':'9th inning top',
            'name_aa':'9th inning top',
            'name_nl':'9th inning top',
            'name_de':'9th inning top',
            'name_el':'9th inning top',
            'name_pl':'9th inning top',
            'name_ro':'9th inning top',
            'name_ru':'9th inning top',
            'name_tr':'9th inning top',
            'name_se':'9th inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '418':{
        'description':{
            'name_en':'9th inning bottom',
            'name_aa':'9th inning bottom',
            'name_nl':'9th inning bottom',
            'name_de':'9th inning bottom',
            'name_el':'9th inning bottom',
            'name_pl':'9th inning bottom',
            'name_ro':'9th inning bottom',
            'name_ru':'9th inning bottom',
            'name_tr':'9th inning bottom',
            'name_se':'9th inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '419':{
        'description':{
            'name_en':'Extra inning top',
            'name_aa':'Extra inning top',
            'name_nl':'Extra inning top',
            'name_de':'Extra inning top',
            'name_el':'Extra inning top',
            'name_pl':'Extra inning top',
            'name_ro':'Extra inning top',
            'name_ru':'Extra inning top',
            'name_tr':'Extra inning top',
            'name_se':'Extra inning top'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '420':{
        'description':{
            'name_en':'Extra inning bottom',
            'name_aa':'Extra inning bottom',
            'name_nl':'Extra inning bottom',
            'name_de':'Extra inning bottom',
            'name_el':'Extra inning bottom',
            'name_pl':'Extra inning bottom',
            'name_ro':'Extra inning bottom',
            'name_ru':'Extra inning bottom',
            'name_tr':'Extra inning bottom',
            'name_se':'Extra inning bottom'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '421':{
        'description':{
            'name_en':'Break top 1 bottom 1',
            'name_aa':'Break top 1 bottom 1',
            'name_nl':'Break top 1 bottom 1',
            'name_de':'Break top 1 bottom 1',
            'name_el':'Break top 1 bottom 1',
            'name_pl':'Break top 1 bottom 1',
            'name_ro':'Break top 1 bottom 1',
            'name_ru':'Break top 1 bottom 1',
            'name_tr':'Break top 1 bottom 1',
            'name_se':'Break top 1 bottom 1'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '422':{
        'description':{
            'name_en':'Break top 2 bottom 1',
            'name_aa':'Break top 2 bottom 1',
            'name_nl':'Break top 2 bottom 1',
            'name_de':'Break top 2 bottom 1',
            'name_el':'Break top 2 bottom 1',
            'name_pl':'Break top 2 bottom 1',
            'name_ro':'Break top 2 bottom 1',
            'name_ru':'Break top 2 bottom 1',
            'name_tr':'Break top 2 bottom 1',
            'name_se':'Break top 2 bottom 1'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '423':{
        'description':{
            'name_en':'Break top 2 bottom 2',
            'name_aa':'Break top 2 bottom 2',
            'name_nl':'Break top 2 bottom 2',
            'name_de':'Break top 2 bottom 2',
            'name_el':'Break top 2 bottom 2',
            'name_pl':'Break top 2 bottom 2',
            'name_ro':'Break top 2 bottom 2',
            'name_ru':'Break top 2 bottom 2',
            'name_tr':'Break top 2 bottom 2',
            'name_se':'Break top 2 bottom 2'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '424':{
        'description':{
            'name_en':'Break top 3 bottom 2',
            'name_aa':'Break top 3 bottom 2',
            'name_nl':'Break top 3 bottom 2',
            'name_de':'Break top 3 bottom 2',
            'name_el':'Break top 3 bottom 2',
            'name_pl':'Break top 3 bottom 2',
            'name_ro':'Break top 3 bottom 2',
            'name_ru':'Break top 3 bottom 2',
            'name_tr':'Break top 3 bottom 2',
            'name_se':'Break top 3 bottom 2'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '425':{
        'description':{
            'name_en':'Break top 3 bottom 3',
            'name_aa':'Break top 3 bottom 3',
            'name_nl':'Break top 3 bottom 3',
            'name_de':'Break top 3 bottom 3',
            'name_el':'Break top 3 bottom 3',
            'name_pl':'Break top 3 bottom 3',
            'name_ro':'Break top 3 bottom 3',
            'name_ru':'Break top 3 bottom 3',
            'name_tr':'Break top 3 bottom 3',
            'name_se':'Break top 3 bottom 3'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '426':{
        'description':{
            'name_en':'Break top 4 bottom 3',
            'name_aa':'Break top 4 bottom 3',
            'name_nl':'Break top 4 bottom 3',
            'name_de':'Break top 4 bottom 3',
            'name_el':'Break top 4 bottom 3',
            'name_pl':'Break top 4 bottom 3',
            'name_ro':'Break top 4 bottom 3',
            'name_ru':'Break top 4 bottom 3',
            'name_tr':'Break top 4 bottom 3',
            'name_se':'Break top 4 bottom 3'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '427':{
        'description':{
            'name_en':'Break top 4 bottom 4',
            'name_aa':'Break top 4 bottom 4',
            'name_nl':'Break top 4 bottom 4',
            'name_de':'Break top 4 bottom 4',
            'name_el':'Break top 4 bottom 4',
            'name_pl':'Break top 4 bottom 4',
            'name_ro':'Break top 4 bottom 4',
            'name_ru':'Break top 4 bottom 4',
            'name_tr':'Break top 4 bottom 4',
            'name_se':'Break top 4 bottom 4'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '428':{
        'description':{
            'name_en':'Break top 5 bottom 4',
            'name_aa':'Break top 5 bottom 4',
            'name_nl':'Break top 5 bottom 4',
            'name_de':'Break top 5 bottom 4',
            'name_el':'Break top 5 bottom 4',
            'name_pl':'Break top 5 bottom 4',
            'name_ro':'Break top 5 bottom 4',
            'name_ru':'Break top 5 bottom 4',
            'name_tr':'Break top 5 bottom 4',
            'name_se':'Break top 5 bottom 4'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '429':{
        'description':{
            'name_en':'Break top 5 bottom 5',
            'name_aa':'Break top 5 bottom 5',
            'name_nl':'Break top 5 bottom 5',
            'name_de':'Break top 5 bottom 5',
            'name_el':'Break top 5 bottom 5',
            'name_pl':'Break top 5 bottom 5',
            'name_ro':'Break top 5 bottom 5',
            'name_ru':'Break top 5 bottom 5',
            'name_tr':'Break top 5 bottom 5',
            'name_se':'Break top 5 bottom 5'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '430':{
        'description':{
            'name_en':'Break top 6 bottom 5',
            'name_aa':'Break top 6 bottom 5',
            'name_nl':'Break top 6 bottom 5',
            'name_de':'Break top 6 bottom 5',
            'name_el':'Break top 6 bottom 5',
            'name_pl':'Break top 6 bottom 5',
            'name_ro':'Break top 6 bottom 5',
            'name_ru':'Break top 6 bottom 5',
            'name_tr':'Break top 6 bottom 5',
            'name_se':'Break top 6 bottom 5'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '431':{
        'description':{
            'name_en':'Break top 6 bottom 6',
            'name_aa':'Break top 6 bottom 6',
            'name_nl':'Break top 6 bottom 6',
            'name_de':'Break top 6 bottom 6',
            'name_el':'Break top 6 bottom 6',
            'name_pl':'Break top 6 bottom 6',
            'name_ro':'Break top 6 bottom 6',
            'name_ru':'Break top 6 bottom 6',
            'name_tr':'Break top 6 bottom 6',
            'name_se':'Break top 6 bottom 6'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '432':{
        'description':{
            'name_en':'Break top 7 bottom 6',
            'name_aa':'Break top 7 bottom 6',
            'name_nl':'Break top 7 bottom 6',
            'name_de':'Break top 7 bottom 6',
            'name_el':'Break top 7 bottom 6',
            'name_pl':'Break top 7 bottom 6',
            'name_ro':'Break top 7 bottom 6',
            'name_ru':'Break top 7 bottom 6',
            'name_tr':'Break top 7 bottom 6',
            'name_se':'Break top 7 bottom 6'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '433':{
        'description':{
            'name_en':'Break top 7 bottom 7',
            'name_aa':'Break top 7 bottom 7',
            'name_nl':'Break top 7 bottom 7',
            'name_de':'Break top 7 bottom 7',
            'name_el':'Break top 7 bottom 7',
            'name_pl':'Break top 7 bottom 7',
            'name_ro':'Break top 7 bottom 7',
            'name_ru':'Break top 7 bottom 7',
            'name_tr':'Break top 7 bottom 7',
            'name_se':'Break top 7 bottom 7'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '434':{
        'description':{
            'name_en':'Break top 8 bottom 7',
            'name_aa':'Break top 8 bottom 7',
            'name_nl':'Break top 8 bottom 7',
            'name_de':'Break top 8 bottom 7',
            'name_el':'Break top 8 bottom 7',
            'name_pl':'Break top 8 bottom 7',
            'name_ro':'Break top 8 bottom 7',
            'name_ru':'Break top 8 bottom 7',
            'name_tr':'Break top 8 bottom 7',
            'name_se':'Break top 8 bottom 7'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '435':{
        'description':{
            'name_en':'Break top 8 bottom 8',
            'name_aa':'Break top 8 bottom 8',
            'name_nl':'Break top 8 bottom 8',
            'name_de':'Break top 8 bottom 8',
            'name_el':'Break top 8 bottom 8',
            'name_pl':'Break top 8 bottom 8',
            'name_ro':'Break top 8 bottom 8',
            'name_ru':'Break top 8 bottom 8',
            'name_tr':'Break top 8 bottom 8',
            'name_se':'Break top 8 bottom 8'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '436':{
        'description':{
            'name_en':'Break top 9 bottom 8',
            'name_aa':'Break top 9 bottom 8',
            'name_nl':'Break top 9 bottom 8',
            'name_de':'Break top 9 bottom 8',
            'name_el':'Break top 9 bottom 8',
            'name_pl':'Break top 9 bottom 8',
            'name_ro':'Break top 9 bottom 8',
            'name_ru':'Break top 9 bottom 8',
            'name_tr':'Break top 9 bottom 8',
            'name_se':'Break top 9 bottom 8'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '437':{
        'description':{
            'name_en':'Break top 9 bottom 9',
            'name_aa':'Break top 9 bottom 9',
            'name_nl':'Break top 9 bottom 9',
            'name_de':'Break top 9 bottom 9',
            'name_el':'Break top 9 bottom 9',
            'name_pl':'Break top 9 bottom 9',
            'name_ro':'Break top 9 bottom 9',
            'name_ru':'Break top 9 bottom 9',
            'name_tr':'Break top 9 bottom 9',
            'name_se':'Break top 9 bottom 9'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '438':{
        'description':{
            'name_en':'Break top EI bottom 9',
            'name_aa':'Break top EI bottom 9',
            'name_nl':'Break top EI bottom 9',
            'name_de':'Break top EI bottom 9',
            'name_el':'Break top EI bottom 9',
            'name_pl':'Break top EI bottom 9',
            'name_ro':'Break top EI bottom 9',
            'name_ru':'Break top EI bottom 9',
            'name_tr':'Break top EI bottom 9',
            'name_se':'Break top EI bottom 9'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '439':{
        'description':{
            'name_en':'Break top EI bottom EI',
            'name_aa':'Break top EI bottom EI',
            'name_nl':'Break top EI bottom EI',
            'name_de':'Break top EI bottom EI',
            'name_el':'Break top EI bottom EI',
            'name_pl':'Break top EI bottom EI',
            'name_ro':'Break top EI bottom EI',
            'name_ru':'Break top EI bottom EI',
            'name_tr':'Break top EI bottom EI',
            'name_se':'Break top EI bottom EI'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    },
    '440':{
        'description':{
            'name_en':'Sudden death',
            'name_aa':'Sudden death',
            'name_nl':'Sudden death',
            'name_de':'Sudden death',
            'name_el':'Sudden death',
            'name_pl':'Sudden death',
            'name_ro':'Sudden death',
            'name_ru':'Sudden death',
            'name_tr':'Sudden death',
            'name_se':'Sudden death'
        },
        'period_number':null,
        'sports':[
            '12',
            '13',
            '24'
        ]
    },
    '441':{
        'description':{
            'name_en':'6th set',
            'name_aa':'6th set',
            'name_nl':'6th set',
            'name_de':'6th set',
            'name_el':'6th set',
            'name_pl':'6th set',
            'name_ro':'6th set',
            'name_ru':'6th set',
            'name_tr':'6th set',
            'name_se':'6th set'
        },
        'period_number':'6',
        'sports':[
            '20',
            '23'
        ]
    },
    '442':{
        'description':{
            'name_en':'7th set',
            'name_aa':'7th set',
            'name_nl':'7th set',
            'name_de':'7th set',
            'name_el':'7th set',
            'name_pl':'7th set',
            'name_ro':'7th set',
            'name_ru':'7th set',
            'name_tr':'7th set',
            'name_se':'7th set'
        },
        'period_number':'7',
        'sports':[
            '20',
            '23'
        ]
    },
    '443':{
        'description':{
            'name_en':'Awaiting sudden death',
            'name_aa':'Awaiting sudden death',
            'name_nl':'Awaiting sudden death',
            'name_de':'Awaiting sudden death',
            'name_el':'Awaiting sudden death',
            'name_pl':'Awaiting sudden death',
            'name_ro':'Awaiting sudden death',
            'name_ru':'Awaiting sudden death',
            'name_tr':'Awaiting sudden death',
            'name_se':'Awaiting sudden death'
        },
        'period_number':null,
        'sports':[
            '12',
            '13',
            '24'
        ]
    },
    '444':{
        'description':{
            'name_en':'After sudden death',
            'name_aa':'After sudden death',
            'name_nl':'After sudden death',
            'name_de':'After sudden death',
            'name_el':'After sudden death',
            'name_pl':'After sudden death',
            'name_ro':'After sudden death',
            'name_ru':'After sudden death',
            'name_tr':'After sudden death',
            'name_se':'After sudden death'
        },
        'period_number':null,
        'sports':[
            '12',
            '13',
            '24'
        ]
    },
    '445':{
        'description':{
            'name_en':'Break',
            'name_aa':'Break',
            'name_nl':'Break',
            'name_de':'Break',
            'name_el':'Break',
            'name_pl':'Break',
            'name_ro':'Break',
            'name_ru':'Break',
            'name_tr':'Break',
            'name_se':'Break'
        },
        'period_number':null,
        'sports':[
            '19'
        ]
    },
    '501':{
        'description':{
            'name_en':'First innings, home team',
            'name_aa':'First innings, home team',
            'name_nl':'First innings, home team',
            'name_de':'First innings, home team',
            'name_el':'First innings, home team',
            'name_pl':'First innings, home team',
            'name_ro':'First innings, home team',
            'name_ru':'First innings, home team',
            'name_tr':'First innings, home team',
            'name_se':'First innings, home team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '502':{
        'description':{
            'name_en':'First innings, away team',
            'name_aa':'First innings, away team',
            'name_nl':'First innings, away team',
            'name_de':'First innings, away team',
            'name_el':'First innings, away team',
            'name_pl':'First innings, away team',
            'name_ro':'First innings, away team',
            'name_ru':'First innings, away team',
            'name_tr':'First innings, away team',
            'name_se':'First innings, away team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '503':{
        'description':{
            'name_en':'Second innings, home team',
            'name_aa':'Second innings, home team',
            'name_nl':'Second innings, home team',
            'name_de':'Second innings, home team',
            'name_el':'Second innings, home team',
            'name_pl':'Second innings, home team',
            'name_ro':'Second innings, home team',
            'name_ru':'Second innings, home team',
            'name_tr':'Second innings, home team',
            'name_se':'Second innings, home team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '504':{
        'description':{
            'name_en':'Second innings, away team',
            'name_aa':'Second innings, away team',
            'name_nl':'Second innings, away team',
            'name_de':'Second innings, away team',
            'name_el':'Second innings, away team',
            'name_pl':'Second innings, away team',
            'name_ro':'Second innings, away team',
            'name_ru':'Second innings, away team',
            'name_tr':'Second innings, away team',
            'name_se':'Second innings, away team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '505':{
        'description':{
            'name_en':'Awaiting super over',
            'name_aa':'Awaiting super over',
            'name_nl':'Awaiting super over',
            'name_de':'Awaiting super over',
            'name_el':'Awaiting super over',
            'name_pl':'Awaiting super over',
            'name_ro':'Awaiting super over',
            'name_ru':'Awaiting super over',
            'name_tr':'Awaiting super over',
            'name_se':'Awaiting super over'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '506':{
        'description':{
            'name_en':'Super over, home team',
            'name_aa':'Super over, home team',
            'name_nl':'Super over, home team',
            'name_de':'Super over, home team',
            'name_el':'Super over, home team',
            'name_pl':'Super over, home team',
            'name_ro':'Super over, home team',
            'name_ru':'Super over, home team',
            'name_tr':'Super over, home team',
            'name_se':'Super over, home team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '507':{
        'description':{
            'name_en':'Super over, away team',
            'name_aa':'Super over, away team',
            'name_nl':'Super over, away team',
            'name_de':'Super over, away team',
            'name_el':'Super over, away team',
            'name_pl':'Super over, away team',
            'name_ro':'Super over, away team',
            'name_ru':'Super over, away team',
            'name_tr':'Super over, away team',
            'name_se':'Super over, away team'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '508':{
        'description':{
            'name_en':'After super over',
            'name_aa':'After super over',
            'name_nl':'After super over',
            'name_de':'After super over',
            'name_el':'After super over',
            'name_pl':'After super over',
            'name_ro':'After super over',
            'name_ru':'After super over',
            'name_tr':'After super over',
            'name_se':'After super over'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '509':{
        'description':{
            'name_en':'Innings break',
            'name_aa':'Innings break',
            'name_nl':'Innings break',
            'name_de':'Innings break',
            'name_el':'Innings break',
            'name_pl':'Innings break',
            'name_ro':'Innings break',
            'name_ru':'Innings break',
            'name_tr':'Innings break',
            'name_se':'Innings break'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '510':{
        'description':{
            'name_en':'Super over break',
            'name_aa':'Super over break',
            'name_nl':'Super over break',
            'name_de':'Super over break',
            'name_el':'Super over break',
            'name_pl':'Super over break',
            'name_ro':'Super over break',
            'name_ru':'Super over break',
            'name_tr':'Super over break',
            'name_se':'Super over break'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '511':{
        'description':{
            'name_en':'Lunch break',
            'name_aa':'Lunch break',
            'name_nl':'Lunch break',
            'name_de':'Lunch break',
            'name_el':'Lunch break',
            'name_pl':'Lunch break',
            'name_ro':'Lunch break',
            'name_ru':'Lunch break',
            'name_tr':'Lunch break',
            'name_se':'Lunch break'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '512':{
        'description':{
            'name_en':'Tea break',
            'name_aa':'Tea break',
            'name_nl':'Tea break',
            'name_de':'Tea break',
            'name_el':'Tea break',
            'name_pl':'Tea break',
            'name_ro':'Tea break',
            'name_ru':'Tea break',
            'name_tr':'Tea break',
            'name_se':'Tea break'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '513':{
        'description':{
            'name_en':'Stumps',
            'name_aa':'Stumps',
            'name_nl':'Stumps',
            'name_de':'Stumps',
            'name_el':'Stumps',
            'name_pl':'Stumps',
            'name_ro':'Stumps',
            'name_ru':'Stumps',
            'name_tr':'Stumps',
            'name_se':'Stumps'
        },
        'period_number':null,
        'sports':[
            '21'
        ]
    },
    '514':{
        'description':{
            'name_en':'8th set',
            'name_aa':'8th set',
            'name_nl':'8th set',
            'name_de':'8th set',
            'name_el':'8th set',
            'name_pl':'8th set',
            'name_ro':'8th set',
            'name_ru':'8th set',
            'name_tr':'8th set',
            'name_se':'8th set'
        },
        'period_number':'8'
    },
    '515':{
        'description':{
            'name_en':'9th set',
            'name_aa':'9th set',
            'name_nl':'9th set',
            'name_de':'9th set',
            'name_el':'9th set',
            'name_pl':'9th set',
            'name_ro':'9th set',
            'name_ru':'9th set',
            'name_tr':'9th set',
            'name_se':'9th set'
        },
        'period_number':'9'
    },
    '516':{
        'description':{
            'name_en':'10th set',
            'name_aa':'10th set',
            'name_nl':'10th set',
            'name_de':'10th set',
            'name_el':'10th set',
            'name_pl':'10th set',
            'name_ro':'10th set',
            'name_ru':'10th set',
            'name_tr':'10th set',
            'name_se':'10th set'
        },
        'period_number':'10'
    },
    '517':{
        'description':{
            'name_en':'11th set',
            'name_aa':'11th set',
            'name_nl':'11th set',
            'name_de':'11th set',
            'name_el':'11th set',
            'name_pl':'11th set',
            'name_ro':'11th set',
            'name_ru':'11th set',
            'name_tr':'11th set',
            'name_se':'11th set'
        },
        'period_number':'11'
    },
    '518':{
        'description':{
            'name_en':'12th set',
            'name_aa':'12th set',
            'name_nl':'12th set',
            'name_de':'12th set',
            'name_el':'12th set',
            'name_pl':'12th set',
            'name_ro':'12th set',
            'name_ru':'12th set',
            'name_tr':'12th set',
            'name_se':'12th set'
        },
        'period_number':'12'
    },
    '519':{
        'description':{
            'name_en':'13th set',
            'name_aa':'13th set',
            'name_nl':'13th set',
            'name_de':'13th set',
            'name_el':'13th set',
            'name_pl':'13th set',
            'name_ro':'13th set',
            'name_ru':'13th set',
            'name_tr':'13th set',
            'name_se':'13th set'
        },
        'period_number':'13'
    },
    '520':{
        'description':{
            'name_en':'Third innings, home team',
            'name_aa':'Third innings, home team',
            'name_nl':'Third innings, home team',
            'name_de':'Third innings, home team',
            'name_el':'Third innings, home team',
            'name_pl':'Third innings, home team',
            'name_ro':'Third innings, home team',
            'name_ru':'Third innings, home team',
            'name_tr':'Third innings, home team',
            'name_se':'Third innings, home team'
        },
        'period_number':null
    },
    '521':{
        'description':{
            'name_en':'Third innings, away team',
            'name_aa':'Third innings, away team',
            'name_nl':'Third innings, away team',
            'name_de':'Third innings, away team',
            'name_el':'Third innings, away team',
            'name_pl':'Third innings, away team',
            'name_ro':'Third innings, away team',
            'name_ru':'Third innings, away team',
            'name_tr':'Third innings, away team',
            'name_se':'Third innings, away team'
        },
        'period_number':null
    },
    '522':{
        'description':{
            'name_en':'Fourth innings, home team',
            'name_aa':'Fourth innings, home team',
            'name_nl':'Fourth innings, home team',
            'name_de':'Fourth innings, home team',
            'name_el':'Fourth innings, home team',
            'name_pl':'Fourth innings, home team',
            'name_ro':'Fourth innings, home team',
            'name_ru':'Fourth innings, home team',
            'name_tr':'Fourth innings, home team',
            'name_se':'Fourth innings, home team'
        },
        'period_number':null
    },
    '523':{
        'description':{
            'name_en':'Fourth innings, away team',
            'name_aa':'Fourth innings, away team',
            'name_nl':'Fourth innings, away team',
            'name_de':'Fourth innings, away team',
            'name_el':'Fourth innings, away team',
            'name_pl':'Fourth innings, away team',
            'name_ro':'Fourth innings, away team',
            'name_ru':'Fourth innings, away team',
            'name_tr':'Fourth innings, away team',
            'name_se':'Fourth innings, away team'
        },
        'period_number':null
    },
    '524':{
        'description':{
            'name_en':'Dinner Break',
            'name_aa':'Dinner Break',
            'name_nl':'Dinner Break',
            'name_de':'Dinner Break',
            'name_el':'Dinner Break',
            'name_pl':'Dinner Break',
            'name_ro':'Dinner Break',
            'name_ru':'Dinner Break',
            'name_tr':'Dinner Break',
            'name_se':'Dinner Break'
        },
        'period_number':null
    },
    '525':{
        'description':{
            'name_en':'Drinks',
            'name_aa':'Drinks',
            'name_nl':'Drinks',
            'name_de':'Drinks',
            'name_el':'Drinks',
            'name_pl':'Drinks',
            'name_ro':'Drinks',
            'name_ru':'Drinks',
            'name_tr':'Drinks',
            'name_se':'Drinks'
        },
        'period_number':null
    },
    '526':{
        'description':{
            'name_en':'Super over',
            'name_aa':'Super over',
            'name_nl':'Super over',
            'name_de':'Super over',
            'name_el':'Super over',
            'name_pl':'Super over',
            'name_ro':'Super over',
            'name_ru':'Super over',
            'name_tr':'Super over',
            'name_se':'Super over'
        },
        'period_number':null
    },
    '531':{
        'description':{
            'name_en':'1st inning',
            'name_aa':'1st inning',
            'name_nl':'1st inning',
            'name_de':'1st inning',
            'name_el':'1st inning',
            'name_pl':'1st inning',
            'name_ro':'1st inning',
            'name_ru':'1st inning',
            'name_tr':'1st inning',
            'name_se':'1st inning'
        },
        'period_number':'1'
    },
    '532':{
        'description':{
            'name_en':'2nd inning',
            'name_aa':'2nd inning',
            'name_nl':'2nd inning',
            'name_de':'2nd inning',
            'name_el':'2nd inning',
            'name_pl':'2nd inning',
            'name_ro':'2nd inning',
            'name_ru':'2nd inning',
            'name_tr':'2nd inning',
            'name_se':'2nd inning'
        },
        'period_number':'2'
    },
    '533':{
        'description':{
            'name_en':'3rd inning',
            'name_aa':'3rd inning',
            'name_nl':'3rd inning',
            'name_de':'3rd inning',
            'name_el':'3rd inning',
            'name_pl':'3rd inning',
            'name_ro':'3rd inning',
            'name_ru':'3rd inning',
            'name_tr':'3rd inning',
            'name_se':'3rd inning'
        },
        'period_number':'3'
    },
    '534':{
        'description':{
            'name_en':'4th inning',
            'name_aa':'4th inning',
            'name_nl':'4th inning',
            'name_de':'4th inning',
            'name_el':'4th inning',
            'name_pl':'4th inning',
            'name_ro':'4th inning',
            'name_ru':'4th inning',
            'name_tr':'4th inning',
            'name_se':'4th inning'
        },
        'period_number':'4'
    },
    '535':{
        'description':{
            'name_en':'5th inning',
            'name_aa':'5th inning',
            'name_nl':'5th inning',
            'name_de':'5th inning',
            'name_el':'5th inning',
            'name_pl':'5th inning',
            'name_ro':'5th inning',
            'name_ru':'5th inning',
            'name_tr':'5th inning',
            'name_se':'5th inning'
        },
        'period_number':'5'
    },
    '536':{
        'description':{
            'name_en':'6th inning',
            'name_aa':'6th inning',
            'name_nl':'6th inning',
            'name_de':'6th inning',
            'name_el':'6th inning',
            'name_pl':'6th inning',
            'name_ro':'6th inning',
            'name_ru':'6th inning',
            'name_tr':'6th inning',
            'name_se':'6th inning'
        },
        'period_number':'6'
    },
    '537':{
        'description':{
            'name_en':'7th inning',
            'name_aa':'7th inning',
            'name_nl':'7th inning',
            'name_de':'7th inning',
            'name_el':'7th inning',
            'name_pl':'7th inning',
            'name_ro':'7th inning',
            'name_ru':'7th inning',
            'name_tr':'7th inning',
            'name_se':'7th inning'
        },
        'period_number':'7'
    },
    '538':{
        'description':{
            'name_en':'8th inning',
            'name_aa':'8th inning',
            'name_nl':'8th inning',
            'name_de':'8th inning',
            'name_el':'8th inning',
            'name_pl':'8th inning',
            'name_ro':'8th inning',
            'name_ru':'8th inning',
            'name_tr':'8th inning',
            'name_se':'8th inning'
        },
        'period_number':'8'
    },
    '539':{
        'description':{
            'name_en':'9th inning',
            'name_aa':'9th inning',
            'name_nl':'9th inning',
            'name_de':'9th inning',
            'name_el':'9th inning',
            'name_pl':'9th inning',
            'name_ro':'9th inning',
            'name_ru':'9th inning',
            'name_tr':'9th inning',
            'name_se':'9th inning'
        },
        'period_number':'9'
    },
    '540':{
        'description':{
            'name_en':'First round',
            'name_aa':'First round',
            'name_nl':'First round',
            'name_de':'First round',
            'name_el':'First round',
            'name_pl':'First round',
            'name_ro':'First round',
            'name_ru':'First round',
            'name_tr':'First round',
            'name_se':'First round'
        },
        'period_number':null
    },
    '541':{
        'description':{
            'name_en':'Second round',
            'name_aa':'Second round',
            'name_nl':'Second round',
            'name_de':'Second round',
            'name_el':'Second round',
            'name_pl':'Second round',
            'name_ro':'Second round',
            'name_ru':'Second round',
            'name_tr':'Second round',
            'name_se':'Second round'
        },
        'period_number':null
    },
    '542':{
        'description':{
            'name_en':'Third round',
            'name_aa':'Third round',
            'name_nl':'Third round',
            'name_de':'Third round',
            'name_el':'Third round',
            'name_pl':'Third round',
            'name_ro':'Third round',
            'name_ru':'Third round',
            'name_tr':'Third round',
            'name_se':'Third round'
        },
        'period_number':null
    },
    '543':{
        'description':{
            'name_en':'Fourth round',
            'name_aa':'Fourth round',
            'name_nl':'Fourth round',
            'name_de':'Fourth round',
            'name_el':'Fourth round',
            'name_pl':'Fourth round',
            'name_ro':'Fourth round',
            'name_ru':'Fourth round',
            'name_tr':'Fourth round',
            'name_se':'Fourth round'
        },
        'period_number':null
    },
    '544':{
        'description':{
            'name_en':'Fifth round',
            'name_aa':'Fifth round',
            'name_nl':'Fifth round',
            'name_de':'Fifth round',
            'name_el':'Fifth round',
            'name_pl':'Fifth round',
            'name_ro':'Fifth round',
            'name_ru':'Fifth round',
            'name_tr':'Fifth round',
            'name_se':'Fifth round'
        },
        'period_number':null
    },
    '545':{
        'description':{
            'name_en':'Sixth round',
            'name_aa':'Sixth round',
            'name_nl':'Sixth round',
            'name_de':'Sixth round',
            'name_el':'Sixth round',
            'name_pl':'Sixth round',
            'name_ro':'Sixth round',
            'name_ru':'Sixth round',
            'name_tr':'Sixth round',
            'name_se':'Sixth round'
        },
        'period_number':null
    },
    '546':{
        'description':{
            'name_en':'Seventh round',
            'name_aa':'Seventh round',
            'name_nl':'Seventh round',
            'name_de':'Seventh round',
            'name_el':'Seventh round',
            'name_pl':'Seventh round',
            'name_ro':'Seventh round',
            'name_ru':'Seventh round',
            'name_tr':'Seventh round',
            'name_se':'Seventh round'
        },
        'period_number':null
    },
    '547':{
        'description':{
            'name_en':'Break topEI-bottom3',
            'name_aa':'Break topEI-bottom3',
            'name_nl':'Break topEI-bottom3',
            'name_de':'Break topEI-bottom3',
            'name_el':'Break topEI-bottom3',
            'name_pl':'Break topEI-bottom3',
            'name_ro':'Break topEI-bottom3',
            'name_ru':'Break topEI-bottom3',
            'name_tr':'Break topEI-bottom3',
            'name_se':'Break topEI-bottom3'
        },
        'period_number':null
    },
    '548':{
        'description':{
            'name_en':'Break topEI-bottom7',
            'name_aa':'Break topEI-bottom7',
            'name_nl':'Break topEI-bottom7',
            'name_de':'Break topEI-bottom7',
            'name_el':'Break topEI-bottom7',
            'name_pl':'Break topEI-bottom7',
            'name_ro':'Break topEI-bottom7',
            'name_ru':'Break topEI-bottom7',
            'name_tr':'Break topEI-bottom7',
            'name_se':'Break topEI-bottom7'
        },
        'period_number':null,
        'sports':[
            '3'
        ]
    }
}
};