export const zh = {
    DocumentDescriptionLiveCasino:'Play your favorite Online Casino table games, including blackjack, roulette, and baccarat, with real dealers in an immersive online environment',
    DocumentDescription:'Experience the thrill of online casino gaming at JackpotCT. Explore a wide selection of exciting casino games, from slots to table games, and enjoy generous bonuses and promotions',
    DocumentHeader:'Casino Games, Slots, TableGame - JackpotCT',
    //Chinese(Simplified)
    Game_Name:'Game Name',
    Provider:'Provider',
    Volatility:'Volatility',
    Features:'Features',
    Themes:'Themes',
    viewall:'查看全部',
    discoverall:'发现全部',
    roulette:'轮盘赌',
    blackjack:'二十一点',
    baccarat:'百家乐',
    poker:'扑克',
    announceMsg:'立即注册，享受快速、安全的交易！ 当天提现！！',
    lobby:"大堂",
    new:'新的',
    hotGames:'热门游戏',
    providers:'提供商',  
    notice:'注意',
    close:'注意',
    customMsg:'只有今天！',
    customPrice:'存款 100 美元获得 500 美元！',
    subCustomMsg:'仅限前 1000 名顾客',
    en: 'EN',
    tr: 'TR',
    fr: 'FR',
    de: 'DE',
    ru: 'RU',
    nl: 'NL',
    th: 'TH',
    it: 'IT',
    ja: 'JA',
    sv: 'SV',
    es: 'ES',
    ko: 'KO',
    pt: 'pt',
    zh: 'ZH',
    sports: '体育',
    sportsBook: '体育博彩',
    live: '滚球',
    inplay: '滚球',
    today: '今天',
    upcoming: '即将开赛',
    tomorrow: '明天',
    highlights: '高光',
    results: '结果',
    lastMinute: '最后时刻',
    liveCasino: '真人娱乐',
    coinPayments: 'Coin Payments',
    virtualSports: '虚拟游戏',
    casino: '电子游戏',
    myBets: '投注记录',
    deposit: '存款',
    home: '主页',
    login: '登录',
    signup: '注册',
    betslip: '注单详情',
    search: '搜索',
    searchHere: '搜寻',
    transaction: '交易记录',
    changePassword: '修改密码',
    logout: '登出',
    single: '单',
    multiple: '串关',
    possibleWin: '预计收益',
    amount: '金额',
    ok: '好的',
    totalOdds: '总赔率',
    odd: '赔率',
    totalPossibleWin: '总预计收益',
    totalWin: '总收益',
    placeBet: '下注',
    bet: '赌注',
    fastBet: '急速下注',
    fastBet1: '急速',
    fastBet2: '下注',
    username: '用户名',
    password: '密码',
    passwordAgain: '确认密码',
    stayLoggedIn: '保持登录',
    all: '所有',
    'in game': '尚未结算',
    won: '赢',
    lost: '输',
    refund: '退款',
    date: '日期',
    winAmount: '中奖金额',
    stake: '质押',
    pick: '挑选',
    myAccount: '我的账户',
    football: '足球',
    basketball: '篮球',
    iceHockey: '冰球',
    tennis: '网球',
    volleyball: '排球',
    boxing: '拳击',
    americanFootball: '美式橄榄球',
    baseball: '棒球',
    hockey: '曲棍球',
    tableTennis: '乒乓球',
    badminton: '羽毛球',
    cricket: '板球',
    futsal: '五人制足球',
    golf: '高尔夫',
    handball: '手球',
    rugbyLeagues: '橄榄球',
    pickCanNotBeCombined: '选择无法结合 ',
    matchStopped: '比赛停止',
    favorites: '收藏夹',
    cashout: '提款',
    'in  progress': '进行中',
    rejected: '拒绝',
    tip: '小费 ',
    noGoal: '未进球',
    yes: '是',
    over: '大球',
    under: '小球',
    even: '平',
    areYouSureWantCashout: '确定提款吗？',
    no: '不',
    bonus: '奖金',
    termsAndConditon: '用户协议',
    aboutUs: '关于我们',
    contactUs: '联系我们',
    payments: '支付',
    howPlacebet: '如何下注',
    credit: '信用',
    debit: '借记 ',
    withdraw: '取款',
    totalOdd: '总赔率',
    totalStake: '总投注额',
    topGames: '热门游戏',
    allGames: '所有游戏',
    canNotBeCombined: '您的选择无法结合',
    betSettled: '下注成功',
    betSuspended: '下注取消',
    insufficientBalance: '余额不足',
    oldPassword: '旧密码',
    newPassword: '新密码',
    confirmPassword: '确认新密码',
    enterAmount: '输入金额',
    qrScan: '注意：二维码在 5 分钟后过期。如果需要，请生成另一个二维码进行支付',
    change: '改变',
    continue: '继续',
    fromDate: '开始日期',
    toDate: '结束日期',
    activityType: '活动类型',
    show: '显示',
    gameType: '游戏类型',
    repeatBet: '再来一注',
    someThingWrong: '出现错误',
    status: '状态',
    coupon: '注单详情',
    acceptOddChangesText: '下注前请接受更改',
    turnOffAway: '关闭',
    acceptOddChanges: '接受任何赔率变化',
    maxWinAmount: '最大盈利金额',
    maxOdd: '最大赔率',
    dateTime: '日期\/时间',
    type: '类型',
    refreshCashout: '刷新兑现',
    vendor: '小贩',
    stakeLowerThen1: '质押金额必须小于',
    stakeGreaterThen1: '质押金额必须大于',
    nothingFound: '无搜寻结果',
    noLiveMatch: '暂无滚球比赛',
    casinoGames: '电子游戏',
    payout: '支出',
    missingTip: '缺少小费',
    pleaseAddAtleast: '请至少加',
    moreEvents: '更多事件',
    ESports: '电竞',
    KSports: 'K体育',
    promotion: '优惠',
    loyalty: '忠诚',
    winners: '优胜者',
    category: '类别',
    about: '关于',
    aboutZoobet: '关于 JackpotCT',
    support: '支持',
    faq: '常问问题',
    privacyPolicy: '隐私政策',
    cookiePolicy: 'Cookie 政策',
    bonusRules: '奖金规则',
    affiliates: '附属公司',
    introduction: '介绍',
    settingLimits: '设置限制',
    customerServicePage: '客服服务',
    decimal: '十进制',
    fraction: '分数',
    americanOdds: '美式赔率',
    sportBook: '运动书',
    profile: '我的账户',
    leagues: '联赛',
    bonusType: '奖金类型',
    claimNow: '现在申请',
    findOutMore: '了解更多',
    seeMore: '更多',
    readMore:'阅读更多',
    viewMore:'查看更多',
    playSports: '开始游戏',
    playCasino: '开始游戏',
    playLiveCasino: '开始游戏',
    playVirtual: '开始游戏',
    virtualForcasts: '虚拟游戏',
    sportsCard: '所有运动中最好的和多样化的市场',
    casinoCard: '在老虎机中玩得开心并成为赢家',
    liveCasinoCard: '足不出户体验真人娱乐场的所有快感',
    virtualCard: '最火爆有趣的虚拟游戏',
    emptyBetslip: '您的投注单目前为空。单击任何赔率将其添加到投注单。',
    topLeagues: '热门联赛',
    print: '打印',
    ticketVerification: '注单查询',
    checkTicket: '查单号',
    ticketNumber: '投注单号',
    invalidTicketNumber: '无效单号',
    reserve: '预投注',
    startDate: '开始日期',
    betPlaced: '下注完成',
    score: '分数',
    rulesAndRegulations: '条款和规则',
    responsibleGaming: '理性博彩',
    printedOn: '打印',
    email: '电子邮件',
    forgetPassword:'忘记密码',
    backLogin:'回到登入',
    sendOTP:'發送驗證碼',
    currency1: '原始货币',
    currency2: "买方货币",
    required: '此字段是必填项。',
    notValidEmail: '请输入有效邮箱。',
    greaterThanZero: '最低金额为 23 学分',
    viewStatus: '查看状态',
    checkout: '结帐',
    currency: '货币',
    coinAddress: '硬币地址',
    withdrawSuccess: '撤回请求创建成功。',
    withdrawFailed: '提现请求失败，请尝试使用真实数据。',
    withdrawPending: '提款请求被接受。',
    searchGames: '搜索游戏',
    totalEvents: '总事件',
    yourBetslipIsCurrentlyEmpty: '您的投注单目前为空。单击任何赔率将其添加到投注单。',
    comingSoon: '快来了',
    acceptTermsAndConditions: '请接受条款和条件',
    readAll: '请阅读所有',
    passwordsDidNotMatch: '密码不匹配',
    name: '姓名',
    shouldBeSixtoFourteenCharacters: '应介于 6 到 14 个字符之间',
    fastRegistration: '快速注册',
    signingUpTakesMinutes: '注册只需不到一分钟',
    wallet: '钱包',
    metaMaskLogin: '使用元掩码登录',
    walletBalance: '钱包余额',
    marketNames: { 
         1: ' 1 × 2',
         2: ' 欠压/过',
        238: ' 剩余比赛 ',
        59:'下一个目标',
        7: ' 双胜',
        52: ' 平手无效投注',
        226: ' 12包括加班费',
        13: ' 欧洲盘口',
        4: 'HT/FT',
        17: ' 两队得分',
        59: ' 下一个目标',
        28: ' 欠压/过包括加班费',
        247: ' 剩余上半场',
    },
    tabNames: {
        'All': '全部',
        'Main Markets': '主要玩法',
        'Totals': '全场',
        '1st Half': '上半场',
        '2nd Half': '下半场',
        'Home Team': '主队',
        'Away Team': '客队',
        'All Periods': '所有期间',
        'Results':'结果',
        'Home / Away': '主场/客场',
        'Slot': '投币口',
        'Table Game': '桌上游戏',
        'Instant Win': '即时获胜',
        'Live Casino': '真人娱乐场',
        'Scratch Card': '刮刮卡',
        'Video Poker': '视频扑克',
        'Virtual Sports': '虚拟运动',
        'New':'新的',
        'Hot Games':'热门游戏',
        'Slots':'投币口',
        'Crash':'碰撞',
        'Chinese':'中国人',
        'Bonus Buy':'奖金购买',
        'Top Providers':'顶级供应商',
        'Andar Bahar':'安达尔巴哈尔',
        'Baccarat':'百家乐',
        'Keno':'基诺',
        'Blackjack':'二十一点',
        'Sic Bo':'骰宝',
        'Poker':'扑克',
        'Dragon tiger':'龙虎',
        'Roulette':'轮盘赌',
        'Shooting':'射击',
        'Lottery':'彩票',
    },
    markets:{
        2 : ' 欠压/过 ',
        3 : ' 亚洲盘口 ',
        5 : ' 奇偶 ',
        6 : ' 波胆 ',
        7 : '双胜 ',
        9 : ' 正确比分第1期 ',
        11 : '总角落 ',
        12 : '总犯规次数 ',
        13 : '欧洲盘口 ',
        16 : '一线队得分 ',
        17 : '两队得分 ',
        19 : '第一张牌 ',
        21 : '欠压/过第1期 ',
        22 : '客队入球 ',
        23 : '主队得分 ',
        25 : '双重机会半场 ',
        29 : '欠压/过大红大紫 ',
        30 : '欠压/过角落 - 主队 ',
        31 : '欠压/过角落 - 客场球队 ',
        34 : '主队得分在上下半场均有 ',
        35 : '客队进球在上下半场均有 ',
        41 : '第一期获奖者 ',
        42 : '第二期获奖者 ',
        43 : '第3期获奖者 ',
        44 : '五四时期赢家 ',
        45 : '欠压/过第二期 ',
        46 : '欠压/过第3期 ',
        47 : '欠压/过五四时期 ',
        48 : '欠压/过第5期 ',
        49 : '第5周期的赢家 ',
        50 : '1X2包括加班费 ',
        51 : '奇/偶包括加班费 ',
        52 : '12 ',
        53 : '亚洲盘口半场 ',
        55 : '首先得分球队第1期 ',
        56 : '最后得分球队 ',
        57 : '最后得分球队半场 ',
        59 : '下一个目标 ',
        61 : '欧洲盘口半场 ',
        62 : '奇/偶半场 ',
        63 : '12半场 ',
        64 : '亚洲盘口第1期 ',
        65 : '亚洲盘口第二期 ',
        66 : '亚洲盘口第3期 ',
        67 : '亚洲盘口五四时期 ',
        68 : '亚洲盘口第5期 ',
        69 : '将围棋加班？ ',
        70 : '得分最高的时期 ',
        71 : '最高得分半场 ',
        72 : '第1期奇/偶 ',
        73 : '第二期奇/偶 ',
        74 : '第3期奇/偶 ',
        75 : '五四时期奇/偶 ',
        76 : '第5期奇数/偶数 ',
        77 : '欠压/过半场 ',
        78 : '为了得分最多六人 ',
        79 : '会罚颁发？ ',
        80 : '首个角球 ',
        81 : '最后一个角球 ',
        82 : '将一张红牌显示？ ',
        83 : '为了不丢球 ',
        84 : '要赢上下半场均有 ',
        85 : '为了赢得背后 ',
        86 : '胜利来无 ',
        88 : '为了证实 ',
        89 : '将第一次尝试转换？ ',
        90 : '将匹配来决定在第5集？ ',
        91 : '在什么下半期的目标是拿下？ ',
        92 : '首先得分球队下半场 ',
        95 : '角落盘口 ',
        96 : '客队取胜，以无 ',
        97 : '主队取胜为零 ',
        98 : '主队丢球 ',
        99 : '客场球队保持不失球 ',
        100 : '正确比分第二期 ',
        101 : '欠压/过 - 主队 ',
        102 : '欠压/过 - 客场球队 ',
        104 : '客场球队赢得上下半场均有 ',
        105 : '主队取胜上下半场均有 ',
        106 : '主队取胜从背后 ',
        107 : '客场球队夺冠从背后 ',
        108 : '主队得分从点球 ',
        109 : '客队得分从点球 ',
        110 : '客场球队赢得至少一个半 ',
        111 : '主队取胜至少一个半 ',
        113 : '两队都有得分上半场 ',
        114 : '任何球员得分至少2个进球 ',
        117 : '任何球员得分至少3个目标 ',
        122 : '球队得分率先拿下了比赛 ',
        123 : '客队进球第一，并赢得了比赛 ',
        124 : '主队得分至上而赢得比赛 ',
        128 : '进球数 ',
        129 : '欠压/过角落 - 上半场 ',
        132 : '在目标/过射击 ',
        133 : '欠压/过射门总次数 ',
        134 : '进球上半场数 ',
        136 : '欠压/过越位 ',
        137 : '主场取胜没有投注 ',
        138 : '客场赢不投注 ',
        139 : '在其中一半主队将比分首粒进球？ ',
        140 : '在其中一半客队将比分首粒进球？ ',
        143 : '在其中一半主队进球多？ ',
        144 : '在其中一半客场球队会进更多的球？ ',
        145 : '主队进球数在1日的一半 ',
        146 : '客队进球数在1日的一半 ',
        147 : '客队进球数在下半场中 ',
        148 : '主队进球数在下半场中 ',
        149 : '主队进球数中 ',
        150 : '客队进球数中 ',
        151 : '双胜下半场 ',
        153 : '欠压/过第1期 - 主队 ',
        154 : '欠压/过第二期 - 主队 ',
        155 : '欠压/过第1期 - 客场球队 ',
        156 : '欠压/过第二期 - 客场球队 ',
        157 : '欠压/过黄牌 - 上半场 ',
        158 : '欠压/过黄牌 ',
        159 : '将抢七比赛中出现？ ',
        160 : '分站冠军 ',
        161 : '10分钟出结果 ',
        162 : '10分钟之内/过 ',
        163 : '进球下半场数 ',
        164 : '将抢七在第一盘？ ',
        165 : '在/套以上 ',
        166 : '欠压/过游戏 ',
        168 : '将自己的目标？ ',
        169 : '得分：在上下半场均有 ',
        170 : '奇/偶角 ',
        171 : '为了赢得任意半场 ',
        175 : '将抢七在第二盘？ ',
        176 : '将抢七在第三盘？ ',
        177 : '将抢七在第四盘？ ',
        178 : '将抢七在第五盘？ ',
        180 : '欠压/过投掷宏 ',
        181 : '欠压/过黄牌 - 主队 ',
        182 : '欠压/过黄牌 - 主队上半场 ',
        183 : '欠压/过黄牌 - 主队下半场 ',
        184 : '欠压/过黄牌 - 客场球队 ',
        185 : '欠压/过黄牌 - 客队上半场 ',
        186 : '欠压/过黄牌 - 客场球队下半场 ',
        187 : '欠压/过黄牌 - 下半场 ',
        188 : '第一张黄牌 ',
        190 : '第一张黄牌下半场 ',
        191 : '将任何球员得分帽子戏法？ ',
        192 : '会进球打进在上下半场均有？ ',
        194 : '主队丢球第1期 ',
        195 : '主队丢球第二期 ',
        196 : '客场球队保持不失球第1期 ',
        197 : '客场球队保持不失球第二期 ',
        198 : '奇/偶 - 主队 ',
        199 : '奇/偶 - 客场球队 ',
        200 : '将第一个得分王的球队获胜？ ',
        201 : '亚洲盘口游戏 ',
        202 : '第一期获奖者主场/客场 ',
        203 : '第二期获奖者主场/客场 ',
        204 : '第3期得奖者主场/客场 ',
        205 : '五四时期赢家主场/客场 ',
        206 : '第5期得奖者主场/客场 ',
        207 : '15分钟出结果 ',
        208 : '30分钟出结果 ',
        209 : '总角落 - 上半场 ',
        210 : '总角落 - 下半场 ',
        211 : '两队都有得分下半场 ',
        212 : '两队都有得分在上下半场均有 ',
        213 : '绘制在上下半场均有 ',
        214 : '欠压/过卡 ',
        215 : '客队入球上半场 ',
        216 : '客队得分下半场 ',
        217 : '正确比分第3期 ',
        218 : '主队比分半场 ',
        219 : '主队比分下半场 ',
        220 : '欠压/过 - 客场球队包括加班费 ',
        221 : '欠压/过 - 主队包括加班费 ',
        222 : '欠压/过第3期 - 客场球队 ',
        223 : '欠压/过第3期 - 主队 ',
        224 : '双胜第3期 ',
        225 : '双胜五四时期 ',
        226 : '12包括加班费 ',
        227 : '分站冠军的地方2 ',
        228 : '分站冠军的地方3 ',
        229 : '站冠军4处 ',
        233 : '要赢不要无 ',
        235 : '1日5局获胜者 ',
        236 : '第一5局下/过 ',
        237 : '会不会有一个分数在第1局 ',
        238 : '剩余比赛 ',
        239 : '目标范围 ',
        242 : '第1期奇/偶 - 主队 ',
        243 : '第1期奇/偶 - 客场球队 ',
        244 : '最高首发搭档 ',
        245 : '目标/没有目标 ',
        247 : '剩余上半场 ',
        250 : '角落盘口 - 上半场 ',
        254 : '卡范围 ',
        255 : '时间1卡 ',
        256 : '主队 - 卡范围 ',
        257 : '客队 - 卡范围 ',
        258 : '角范围 ',
        259 : '团队的大多数角落 ',
        260 : '团队大部分上半场角球 ',
        261 : '团队大部分下半场角落 ',
        262 : '主队 - 角范围 ',
        263 : '客队 - 角范围 ',
        264 : '主队 - 上半场角球范围 ',
        265 : '客队 - 上半场角球范围 ',
        266 : '主队 - 下半场角球范围 ',
        267 : '客队 - 下半场角球范围 ',
        269 : '目标范围第1期 ',
        271 : '混合机会 ',
        274 : '冠军得主 ',
        275 : '目标范围第二期 ',
        278 : '目标范围主队 ',
        279 : '目标范围中退出团队 ',
        281 : '1日5局亚洲盘口 ',
        282 : '1X2半场 ',
        283 : '亚洲盘口下半场 ',
        284 : '1X2下半场 ',
        285 : '奇/偶下半场 ',
        286 : '12下半场 ',
        287 : '欠压/过五四时期 - 主队 ',
        288 : '欠压/过五四时期 - 客场球队 ',
        289 : '第二期奇/偶 - 主队 ',
        290 : '第3期奇/偶 - 主队 ',
        291 : '五四时期奇/偶 - 主队 ',
        292 : '第二期奇/偶 - 客场球队 ',
        293 : '第3期奇/偶 - 客场球队 ',
        294 : '五四时期奇/偶 - 客场球队 ',
        297 : 'SP ',
        299 : '点赢家 ',
        300 : '比赛优胜者 ',
        301 : '目标之前 ',
        302 : '进球之后 ',
        304 : '团队最高得分期 ',
        305 : '角落 - 低于/正好/过 ',
        306 : '首粒进球最后进球 ',
        307 : '欧洲盘口游戏 ',
        308 : '欠压/过最高得分期 ',
        309 : '欠压/过得分最低的时期 ',
        310 : '系列获奖者 ',
        317 : '低于/正好/过 - 第1期 ',
        318 : '低于/正好/过 - 第二期 ',
        319 : '低于/正好/过 - 第3期 ',
        320 : '低于/正好/过 - 五四时期 ',
        322 : '低于/正好/过 ',
        329 : '第一期勇闯 ',
        330 : '第二期勇闯 ',
        331 : '第3期勇闯 ',
        332 : '五四时期勇闯 ',
        333 : '第5期勇闯 ',
        337 : '欠压/过 - 下半场 ',
        338 : '下一个目标第1期 ',
        339 : '下一个目标第二期 ',
        341 : '比赛进行到 ',
        342 : '亚洲盘口包括加班费 ',
        343 : '游戏系列第1期 ',
        344 : '游戏系列第2期 ',
        345 : '游戏系列第3期 ',
        346 : '游戏范围五四时期 ',
        347 : '游戏范围第5期 ',
        348 : '第六周期赢家 ',
        349 : '第7期获奖者 ',
        350 : '第6期奇/偶 ',
        351 : '第7期奇/偶 ',
        352 : '欠压/过第6期 ',
        353 : '欠压/过7期 ',
        354 : '欠压/过半场 - 主队 ',
        355 : '欠压/过半场 - 客场球队 ',
        356 : '方法解雇6路 ',
        357 : '解雇法 ',
        366 : '运行奇/偶超过 - 主队 ',
        367 : '总运行奇/偶超过 - 客场球队 ',
        370 : '总运行在过 - 主队 ',
        371 : '总在奔跑过 - 客场球队 ',
        386 : '欧洲盘口包括加班费 ',
        387 : '奇/偶 - 主队包括加班费 ',
        388 : '奇/偶 - 客场球队包括加班费 ',
        390 : 'HT / FT包括加班费 ',
        391 : '下一个目标 - 额外时间 ',
        392 : '主队点球大战 ',
        393 : '客队点球大战 ',
        394 : '波胆五四时期 ',
        395 : '正确比分第5期 ',
        398 : '奇/偶卡 ',
        400 : '奇/偶卡上半场 ',
        401 : '在/在角落上半场 - 主队 ',
        402 : '在/在角落上半场 - 客场球队 ',
        403 : '在/在角落下半场 ',
        404 : '在/在角落下半场 - 主队 ',
        405 : '在/在角落下半场 - 客场球队 ',
        406 : '奇/偶角上半场 ',
        407 : '亚洲盘口卡 ',
        408 : '亚洲盘口卡上半场 ',
        409 : '1X2角 ',
        410 : '1X2角落上半场 ',
        411 : '12个角落 ',
        414 : '欠压/过卡上半场 ',
        415 : '1X2而且两队都有得分 ',
        416 : '欠压/过 - 额外时间 ',
        417 : '欠压/过 - 加时赛上半场 ',
        419 : '1X2  - 额外时间 ',
        427 : '1X2和欠压/过 ',
        428 : '多波胆 ',
        430 : '低于/正好/过 - 上半场 ',
        431 : '低于/正好/过 - 下半场 ',
        433 : '欧洲盘口角落 ',
        434 : '第8期获奖者 ',
        435 : '第9期获奖者 ',
        436 : '欠压/过第8期 ',
        437 : '欠压/过第9期 ',
        438 : '总运行奇/偶在过 ',
        439 : '在占总奔跑 ',
        445 : '第6期得奖者主场/客场 ',
        446 : '第7期获奖者主场/客场 ',
        447 : '亚洲盘口第六周期 ',
        448 : '亚洲盘口7期 ',
        449 : '亚洲盘口8期 ',
        450 : '亚洲盘口第9期 ',
        451 : '第8期得奖者主场/客场 ',
        452 : '第9期得奖者主场/客场 ',
        453 : '第一次得分方法 ',
        454 : '第一次得分方法 - 主队 ',
        455 : '首先得分方法 - 客场球队 ',
        456 : '双胜第1期 ',
        457 : '双胜第二期 ',
        459 : '主队在每季度以分数 ',
        460 : '客队在每季度分数 ',
        462 : '前5个优胜者 ',
        463 : '前10名优胜者 ',
        464 : '12下半场包括加班费 ',
        465 : '五四时期赢家主场/客场包括加班费 ',
        466 : '欠压/过五四时期包括加班费 ',
        467 : '亚洲盘口五四时期包括加班费 ',
        468 : '亚洲盘口下半场包括加班费 ',
        469 : '在/在下半场包括加班费 ',
        472 : '将游戏转到枪战？ ',
        475 : '在/在运行在过 - 主队第1局 ',
        476 : '在/在运行在过 - 客场球队第1局 ',
        477 : '在/在运行在过 - 主队第2局 ',
        478 : '在/在运行在过 - 客场球队第2局 ',
        479 : '奇/偶运行在过 - 主队第1局 ',
        480 : '奇/偶运行在过 - 客场球队第1局 ',
        481 : '奇/偶运行在过 - 主队第2局 ',
        482 : '奇/偶运行在过 - 客场球队第2局 ',
        483 : '将主队获得一个检票超过 - 第1局？ ',
        484 : '请问客场球队获得检票超过 - 第1局？ ',
        485 : '将主队获得一个检票超过 - 第2局？ ',
        486 : '请问客场球队获得检票超过 - 第2局？ ',
        523 : '欠压/过两支球队的得分 ',
        524 : '1日7局间赢家 ',
        525 : '第一7局间下/过 ',
        526 : '1日7局间亚洲盘口 ',
        529 : '奇/偶下半场包括加班费 ',
        553 : '欠压/过枪战采取处罚 ',
        554 : '12枪战 ',
        555 : '亚洲盘口枪战 ',
        556 : '波胆枪战 ',
        557 : '欠压/过枪战 ',
        558 : '欠压/过枪战 - 主队 ',
        559 : '欠压/过枪战 - 客场球队 ',
        560 : '团队采取最后点球大战点球 ',
        563 : '比赛进行到包括加班费 ',
        564 : '1X2和欠压/过5路 ',
        566 : '第10期获奖者主场/客场 ',
        567 : '第11期获奖者主场/客场 ',
        568 : '第12期获奖者主场/客场 ',
        569 : '第13期获奖者主场/客场 ',
        570 : '第14期获奖者主场/客场 ',
        571 : ' 第15期获奖者主场/客场',
        573 : ' 第16期获奖者主场/客场',
        574 : ' 第17期获奖者主场/客场',
        575 : ' 第18期获奖者主场/客场',
        576 : ' 第19期获奖者主场/客场',
        577 : ' 10分钟之内/过 - 角',
        578 : ' 10分钟之内/过 - 卡',
        579 : ' 下角',
        586 : ' 为了完成在上半部分',
        587 : ' 为了完成在下半区',
        588 : ' 要完成底部',
        589 : ' 没有完成在4强',
        590 : ' 被降级',
        591 : ' 不被降级',
        592 : ' 达到最终',
        593 : ' 为了完成在底部6',
        594 : ' 被提升',
        595 : ' 首粒进球时间',
        596 : ' 目标范围主队第1期',
        597 : ' 目标范围主队第二期',
        598 : ' 目标范围分离小组第1期',
        599 : ' 目标范围分离小组第二期',
        600 : ' 第1期冠军，两队都有得分',
        601 : ' HT / FT双胜',
        602 : ' 波胆包括加班费',
        603 : ' 负HT / FT',
        604 : ' 目标范围主队两个时期',
        605 : ' 目标范围，可客队两个时期',
        606 : ' 获奖者和目标类型之一',
        607 : ' 双胜和目标',
        608 : ' 获奖者和目标类型双',
        609 : ' 两队都有得分和目标',
        610 : ' 双胜混合',
        628 : ' 为了完成在顶部7',
        629 : ' 为了完成在6顶',
        630 : ' 为了完成在前三名',
        631 : ' 为了完成在顶部2',
        632 : ' 要完成在4强',
        633 : ' 为了完成在五大',
        634 : ' 奇数/偶数轮第1期',
        635 : ' 奇数/偶数轮第2期',
        636 : ' 奇数/偶数轮第3期',
        637 : ' 会不会有一个王牌？',
        638 : ' 会不会有一个刀杀？',
        639 : ' 第一回合获胜者',
        640 : ' 第16轮优胜者',
        641 : ' 会不会有刀杀？ - 第1期',
        642 : ' 会不会有刀杀？ - 第二期',
        643 : ' 会不会有刀杀？ - 第3期',
        644 : ' 会不会有一个王牌？ - 第1期',
        645 : ' 会不会有一个王牌？ - 第二期',
        646 : ' 会不会有一个王牌？ - 第3期',
        647 : ' 会不会有加班费？ - 第1期',
        648 : ' 会不会有加班费？ - 第二期',
        649 : ' 会不会有加班费？ - 第3期',
        650 : ' 第16轮冠军 - 第1期',
        651 : ' 第16轮冠军 - 第2期',
        652 : ' 第16轮冠军 - 第3期',
        653 : ' 第一回合冠军 - 第1期',
        654 : ' 第一回合冠军 - 第2期',
        655 : ' 第一回合冠军 - 第3期',
        660 : ' 游戏时间',
        662 : ' 奇数/偶数轮',
        663 : ' 第一个周期持续时间',
        664 : ' 第二个周期持续时间',
        665 : ' 第三个周期持续时间',
        666 : ' 第1期 - 第一罗山',
        667 : ' 第二期 - 第一罗山',
        668 : ' 第3期 - 第一罗山',
        669 : ' 第1期 - 第一次血',
        670 : ' 第二期 - 第一次血',
        671 : ' 第3期 - 第一次血',
        672 : ' 第1期 - 第一土特产品',
        673 : ' 第二期 - 第一土特产品',
        674 : ' 第3期 - 第一土特产品',
        675 : ' 第1期 - 第一个兵营',
        677 : ' 第二期 - 第一个兵营',
        678 : ' 第3期 - 第一个兵营',
        679 : ' 第1期 - 第一个炮塔',
        680 : ' 第二期 - 第一个炮塔',
        681 : ' 第3期 - 第一个炮塔',
        682 : ' 比赛进行到杀敌 - 第1期',
        683 : ' 比赛进行到杀敌 - 第二期',
        684 : ' 比赛进行到杀敌 - 第3期',
        685 : ' 两队杀龙 - 第1期',
        686 : ' 两队杀龙 - 第二期',
        687 : ' 两队杀龙 - 第3期',
        688 : ' 两队杀抑制剂 - 第1期',
        689 : ' 两队杀抑制剂 - 第二期',
        690 : ' 两队杀抑制剂 - 第3期',
        691 : ' 奇/偶杀敌',
        692 : ' 奇/偶杀敌 - 第1期',
        693 : ' 奇/偶杀敌 - 第二期',
        694 : ' 奇/偶杀敌 - 第3期',
        695 : ' HT / FT和目标',
        697 : ' 为了完成在8强',
        711 : ' 入球球员在任何时候',
        712 : ' 第一个球员得分',
        713 : ' 最后一名选手得分',
        714 : ' 入球球员2个或多个目标',
        715 : ' 入球球员3个或更多进球',
        727 : ' 第一期两队都有得分',
        728 : ' 第二期两队都有得分',
        729 : ' 第3期两队都有得分',
        730 : ' 五四时期两队都有得分',
        731 : ' 第一期主队得分',
        732 : ' 第二期主队得分',
        733 : ' 第3期主队得分',
        734 : ' 五四时期主队得分',
        735 : ' 第一期客队得分',
        736 : ' 第二期客队得分',
        737 : ' 第3期客队得分',
        738 : ' 五四时期客队得分',
        751 : ' 上半场比赛要',
        752 : ' 下半场比赛要 - 包括加班费',
        754 : ' 比赛平手',
        755 : ' 加时赛角落 - 低于/正好/过',
        756 : ' 欠压/过压加时赛角落',
        757 : ' 亚洲盘口 - 额外时间',
        758 : ' 10分钟之内/在 - 前场任意球',
        759 : ' 10分钟之内/过 - 投掷插件',
        760 : ' 10分钟之内/过 - 球门球',
        762 : ' 为了赢得折腾',
        763 : ' 在/在运行在比赛',
        765 : ' 为了得分最多四号位',
        766 : ' 欠压/过 - 赛四强',
        767 : ' 欠压/过 - 比赛六人',
        768 : ' 五十是打进了比赛',
        769 : ' 百家被打进了本场比赛',
        770 : ' 大多数运行前前后后',
        791 : ' 最佳击球手 - 主队',
        792 : ' 最佳击球手 - 客场球队',
        793 : ' 顶礼帽 - 主队',
        794 : ' 顶礼帽 - 客场球队',
        795 : ' 人的比赛',
        806 : ' 击球手得分50分的比赛',
        808 : ' 比赛进行到第10次，',
        809 : ' 最高1日6个配音分数',
        813 : ' 入球球员大多数六人',
        814 : ' 个人最高得分',
        819 : ' 有一个突破点在比赛中',
        820 : ' 比赛进行到角落',
        822 : ' 顶新团队',
        824 : ' 球员被预订',
        825 : ' 球员要被罚下',
        834 : ' 加分集',
        835 : ' 亚洲欠压/过',
        836 : ' 亚洲欠压/过第1期',
        837 : ' 上半场主队取胜为零',
        838 : ' 上半场客队取胜，以无',
        839 : ' 上半场1X2和欠压/过',
        840 : ' 第一期主队取胜为零',
        841 : ' 第一期客队取胜，以无',
        842 : ' 低于/正好/过 - 主队',
        843 : ' 低于/正好/过 - 客场球队',
        844 : ' 首先得分球队 - 主场/客场',
        845 : ' 团队调用第一次走出',
        846 : ' 欠压/过命中 - 包括加班费',
        849 : ' 在/在第5局',
        851 : ' 团队最高得分半场',
        852 : ' 要么队以总分3个未答复时报',
        853 : ' 12岁及以下/以上',
        854 : ' 特别团队或防守触地得分计分',
        855 : ' 点范围',
        859 : ' 147磨合匹配',
        861 : ' 第一次得分方法3路 - 主队',
        862 : ' 首先得分方法3路 - 客场球队',
        863 : ' 团队最高得分周期-1X2',
        864 : ' 小组首名入球',
        865 : ' 球队上得分球员',
        866 : ' 亚洲盘口集',
        870 : ' 第一次得分方法6路主场/客场',
        878 : ' 目标范围第3期',
        879 : ' 欠压/过尝试次数',
        880 : ' 上半场角落 - 低于/正好/过',
        881 : ' 首粒进球方法',
        882 : ' 点数',
        891 : ' 队把比分最长的达阵',
        892 : ' 得分球队长字段目标',
        893 : ' 最长的触地得分计分',
        894 : ' 最短触地得分计分',
        895 : ' 最长场进一球',
        896 : ' 时间一队目标',
        897 : ' 游戏得分后2分',
        898 : ' 游戏得分后3分',
        899 : ' 场均得分在4分',
        900 : ' 游戏得分',
        901 : ' 第1期铅后',
        902 : ' 第二期铅后',
        903 : ' 第3期铅后',
        904 : ' 五四时期之后的铅',
        905 : ' 第5期铅后',
        906 : ' 比分4个游戏',
        907 : ' 6个奥运会结束后',
        908 : ' 破发球局中集',
        909 : ' 主场取胜直落',
        910 : ' 客场取胜直落',
        911 : ' 比分2套',
        912 : ' 比分3个集',
        914 : ' 欠压/过场总进球 - 上半场',
        915 : ' 欠压/过达阵拿下 - 上半场',
        916 : ' 欠压/过主队达阵 - 上半场',
        917 : ' 欠压/过客场球队达阵 - 上半场',
        920 : ' 欠压/过达阵',
        921 : ' 低于/正好/过 - 达阵',
        922 : ' 欠压/过场进球',
        923 : ' 低于/正好/在 - 场进球',
        924 : ' 第一时间投篮命中打进',
        925 : ' 时间一次触地拿下',
        926 : ' 时间首先进球的',
        927 : ' 主队在/在达阵',
        928 : ' 客队在/在达阵',
        929 : ' 主队在/过场进球',
        930 : ' 客场欠压/过球队场均进球',
        932 : ' 队有一教练挑战',
        933 : ' 团队提交首先接受处罚',
        934 : ' 安全计分',
        935 : ' 欧洲盘口卡',
        936 : ' 亚洲欠压/过卡',
        938 : ' 欠压/过卡 - 主队',
        939 : ' 欠压/过卡 - 客场球队',
        940 : ' 时间一卡',
        941 : ' 一个玩家分数',
        942 : ' 顶级的国籍 - 亚洲',
        943 : ' 顶级的国籍 - 澳大利亚',
        944 : ' 顶级的国籍 - 欧洲',
        945 : ' 顶级国籍 - 大不列颠和爱尔兰',
        946 : ' 顶级国籍 - 休息的世界',
        947 : ' 欠压/过王牌',
        948 : ' 欠压/过王牌 - 主队',
        949 : ' 确切的数字的ACE',
        950 : ' 欠压/过王牌 - 客场球队',
        951 : ' 正确数量的ACE',
        955 : ' 1X2王牌',
        956 : ' 确切的数字的ACE  - 主队',
        957 : ' 确切的数字的ACE  - 客场球队',
        958 : ' 王牌亚洲盘口',
        959 : ' 比赛进行到王牌',
        960 : ' 欠压/过王牌 - 第1期',
        976 : ' 1X2王牌 - 第1期',
        982 : ' 王牌亚洲盘口 - 第1期',
        986 : ' 12个王牌',
        989 : ' 欠压/过杀敌 - 第1期',
        990 : ' 欠压/过杀敌 - 第二期',
        991 : ' 欠压/过杀敌 - 第3期',
        992 : ' 接下来触地得分王 - 主队',
        993 : ' 接下来触地得分王 - 客场球队',
        994 : ' 接下来触地得分王',
        995 : ' 球员得分达阵在任何时候',
        996 : ' 入球球员2次或更多达阵',
        997 : ' 入球球员3次以上的达阵',
        998 : ' 第一场比赛角 -  12',
        999 : ' 最后一场比赛角 -  12',
        1000 : ' 数套',
        1001 : ' 欠压/过双误',
        1002 : ' 欠压/过双误 - 客场球队',
        1003 : ' 欠压/过双误 - 主队',
        1004 : ' 欠压/过双误 - 第1期',
        1010 : ' 12次双发失误',
        1011 : ' 12次双发失误 - 第1期',
        1013 : ' 1X2双发失误',
        1016 : ' 确切的数字双误',
        1019 : ' 确切的数字双误 - 主队',
        1020 : ' 确切的数字双误 - 客场球队',
        1027 : ' 正确的数字双误',
        1030 : ' 时间首个角球',
        1031 : ' 多个角',
        1034 : ' 第20期获奖者主场/客场',
        1035 : ' 第21期获奖者主场/客场',
        1036 : ' 第22期获奖者主场/客场',
        1037 : ' 第23期获奖者主场/客场',
        1038 : ' 第24期获奖者主场/客场',
        1039 : ' 第25期获奖者主场/客场',
        1040 : ' 第26期获奖者主场/客场',
        1041 : ' 第27期获奖者主场/客场',
        1042 : ' 第28期获奖者主场/客场',
        1043 : ' 第29期获奖者主场/客场',
        1044 : ' 第30期获奖者主场/客场',
        1046 : ' 第32期获奖者主场/客场',
        1047 : ' 第33期获奖者主场/客场',
        1050 : ' 比赛20点',
        1051 : ' 在/在下半场 - 主队',
        1052 : ' 在/在下半场 - 客场球队',
        1053 : ' 亚洲欠压/过第二期',
        1054 : ' 亚洲欠压/过第3期',
        1055 : ' 亚洲欠压/过五四时期',
        1061 : ' 亚洲欠压/过包括加班费',
        1062 : ' 最高得分半场 - 主队',
        1063 : ' 最高得分半场 - 客场球队',
        1065 : ' 主队 - 首名入球',
        1066 : ' 主队 - 最后得分球员',
        1067 : ' 客队 - 首名入球',
        1068 : ' 客队 - 最后得分球员',
        1069 : ' 欠压/过玩家点数',
        1070 : ' 欠压/过选手框',
        1071 : ' 欠压/过球员助攻',
        1072 : ' 欠压/过球员篮板',
        1073 : ' 欠压/过球员抢断',
        1074 : ' 欠压/过球员失误',
        1075 : ' 欠压/过球员3点进行',
        1076 : ' 欠压/过球员3分尝试',
        1077 : ' 欠压/过玩家2点进行',
        1078 : ' 欠压/过玩家2点未遂',
        1079 : ' 欠压/过球员罚球数',
        1080 : ' 欠压/过球员罚球次数',
        1081 : ' 1X2黄牌',
        1082 : ' 双胜黄牌',
        1083 : ' 亚洲盘口黄牌',
        1084 : ' 波胆黄牌',
        1085 : ' 欠压/过红牌',
        1086 : ' 低于/正好/超额预订点',
        1087 : ' 低于/正好/超额预订点 - 主队',
        1088 : ' 低于/正好/超额预订点 - 客场球队',
        1089 : ' 欠压/过球员抢断和盖帽',
        1090 : ' 欠压/过玩家点数',
        1091 : ' 欠压/过球员助攻和篮板',
        1092 : ' 欠压/过球员得分和篮板',
        1093 : ' 欠压/过球员得分和助攻',
        1094 : ' 球员双人大床',
        1095 : ' 球员三次双',
        1096 : ' 低于/正好/超额预订点 - 第1期',
        1100 : ' 集赢家',
        1101 : ' 欠压/过180 \ S的一套',
        1102 : ' 设置/腿赢家',
        1103 : ' 设置/腿共签出欠压/过',
        1105 : ' 欠压/过共180个\ S  - 主队',
        1106 : ' 欠压/过共180 \ S  - 客场球队',
        1112 : ' 欠压/过球员进球',
        1115 : ' 欠压/过球员进球允许',
        1120 : ' 欠压/过大红大紫 - 第1期',
        1121 : ' 欠压/过大红大紫 - 第二期',
        1122 : ' 欠压/过大红大紫 - 第3期',
        1123 : ' 五四时期 - 第一次血',
        1124 : ' 第5期 - 第一次血',
        1125 : ' 两队都杀死了龙',
        1126 : ' 两队杀男爵',
        1127 : ' 两队杀抑制剂',
        1128 : ' 欠压/过男爵被杀害',
        1129 : ' 欠压/过男爵被杀害的 - 第1期',
        1130 : ' 欠压/过男爵被杀害的 - 第二期',
        1131 : ' 欠压/过男爵被杀害的 - 第3期',
        1132 : ' 欠压/过龙被杀害',
        1133 : ' 欠压/过龙被杀害的 - 第1期',
        1134 : ' 欠压/过龙被杀害的 - 第二期',
        1135 : ' 欠压/过龙被杀害的 - 第3期',
        1136 : ' 下/过180 \ S',
        1137 : ' 首先180',
        1138 : ' 最后180',
        1139 : ' 欠压/过最高结帐得分',
        1140 : ' 大多数180个\ S',
        1141 : ' 欠压/过最高结帐分数 - 主队',
        1142 : ' 欠压/过最高结帐得分 - 客场球队',
        1143 : ' 第一个球员得分达阵',
        1144 : ' 最后一名选手得分达阵',
        1145 : ' 第一个球员得分达阵 - 主队',
        1146 : ' 第一个球员得分达阵 - 客场球队',
        1147 : ' 欠压/过杀敌 - 五四时期',
        1148 : ' 欠压/过杀敌 -  5期',
        1149 : ' 亚洲盘口杀敌 - 第1期',
        1150 : ' 亚洲盘口杀敌 - 第二期',
        1151 : ' 亚洲盘口杀敌 - 第3期',
        1152 : ' 亚洲盘口杀敌 - 五四时期',
        1153 : ' 亚洲盘口杀敌 -  5期',
        1154 : ' 低于/正好/高于180 \ S',
        1155 : ' 低于/正好/占总180 \ S  - 主队',
        1156 : ' 低于/正好/占总180 \ S  - 客场球队',
        1157 : ' 大多数签出',
        1158 : ' 首先尝试记分员',
        1159 : ' 最后的尝试记分员',
        1160 : ' 任何时候尝试记分员',
        1162 : ' 欠压/过TOUS被毁 - 第1期',
        1163 : ' 欠压/过TOUS被毁 - 第二期',
        1164 : ' 欠压/过TOUS被毁 - 第3期',
        1165 : ' 第1期 - 第1位男爵',
        1166 : ' 第二期 - 第1位男爵',
        1167 : ' 第3期 - 第1位男爵',
        1168 : ' 五四时期 - 第1位男爵',
        1169 : ' 第5期 - 第1位男爵',
        1170 : ' 第1期 - 龙一号',
        1171 : ' 第二期 - 龙一号',
        1172 : ' 第3期 - 龙一号',
        1173 : ' 五四时期 - 第一龙',
        1174 : ' 第5期 - 龙一号',
        1175 : ' 最多杀敌 - 第1期',
        1176 : ' 最多杀敌 - 第二期',
        1177 : ' 最多杀敌 - 第3期',
        1178 : ' 最多杀敌 - 五四时期',
        1179 : ' 最多杀敌 - 第5期',
        1180 : ' 最多杀敌 -  6期',
        1181 : ' 最多杀敌 - 第7期',
        1182 : ' 入球球员2名或更多改掉',
        1183 : ' 入球球员3名或更多改掉',
        1184 : ' 并不是赢得Leauge',
        1185 : ' 低于/正好/过包括加班 - 主队',
        1186 : ' 低于/正好/过包括加班 - 客场球队',
        1187 : ' 低于/正好/过包括加班费',
        1188 : ' 欠压/过换人',
        1189 : ' 欠压/过换人 - 第1期',
        1190 : ' 最后入球球员着陆 - 主队',
        1191 : ' 最后入球球员着陆 - 客场球队',
        1192 : ' 第一个球员打一王牌',
        1193 : ' 欠压/过球员四分卫传球码',
        1194 : ' 欠压/过球员传球达阵',
        1195 : ' 欠压/过最长的球员传球成功',
        1196 : ' 欠压/过球员接受围场',
        1197 : ' 欠压/过球员拦截',
        1198 : ' 欠压/过球员踢点',
        1199 : ' 欠压/过冲球员和接受围场',
        1200 : ' 欠压/过球员冲的庭院',
        1201 : ' 欠压/过球员最长招待会',
        1202 : ' 欠压/过球员招待会',
        1203 : ' 在生产/在播放器领域的目标',
        1204 : ' 在尝试/在播放器领域的目标',
        1205 : ' 欠压/过球员四分卫传球达阵',
        1206 : ' 在/在播放器接收器接收落成',
        1207 : ' 欠压/过球员四分卫传球完成',
        1208 : ' 欠压/过球员冲球达阵',
        1209 : ' 在/在播放器接收器接收尝试',
        1210 : ' 欠压/过球员四分卫传球尝试',
        1211 : ' 1X2犯规',
        1212 : ' 双胜犯规',
        1213 : ' 欠压/过犯规 - 第1期',
        1214 : ' 欠压/过犯规 - 第二期',
        1215 : ' 亚洲盘口犯规',
        1216 : ' 欠压/过犯规 - 主队',
        1217 : ' 欠压/过犯规 - 客场球队',
        1218 : ' 欠压/过球员拉什尝试',
        1219 : ' 欠压/过球员接球达阵次数',
        1222 : ' 欠压/过投掷宏 - 主队',
        1223 : ' 欠压/过投掷宏 - 客场球队',
        1224 : ' 1X2投掷宏',
        1225 : ' 双胜掷宏',
        1226 : ' 欠压/过投掷宏 - 第1期',
        1227 : ' 一次性INS盘口',
        1228 : ' 投掷-INS盘口 - 第1期',
        1229 : ' 在/目标在射击 - 主队',
        1230 : ' 在/目标在射击 - 客场球队',
        1231 : ' 目标亚洲让球射门',
        1232 : ' 亚洲盘口射击在目标 - 第1期',
        1233 : ' 欠压/过射击在目标 - 第1期',
        1234 : ' 1X2射击目标',
        1235 : ' 对目标的双重机会射门',
        1236 : ' 欠压/过射门总次数 - 主队',
        1237 : ' 欠压/过射门总次数 - 客场球队',
        1238 : ' 双胜射门总次数',
        1239 : ' 1X2射门总次数',
        1240 : ' 亚洲盘口射门总次数',
        1241 : ' 亚洲盘口射门总次数 - 第1期',
        1242 : ' 欠压/过射门总次数 - 第1期',
        1243 : ' 双胜角落',
        1244 : ' 双胜角落 - 第1期',
        1245 : ' 角从所有4个角落的音高',
        1246 : ' 双胜黄牌 - 第1期',
        1247 : ' 1X2黄牌 - 第1期',
        1248 : ' 亚洲盘口黄牌 - 第1期',
        1249 : ' 双胜及欠压/过',
        1250 : ' 双胜＆两队都有得分',
        1251 : ' 第1期 - 第1抑制剂',
        1252 : ' 第二期 -  1抑制剂',
        1253 : ' 第3期 -  1抑制剂',
        1254 : ' 五四时期 -  1抑制剂',
        1255 : ' 第5期 - 第1抑制剂',
        1256 : ' 两队都杀了男爵 - 第1期',
        1257 : ' 两队都杀了男爵 - 第二期',
        1258 : ' 两队都杀了男爵 - 第3期',
        1259 : ' 两队都杀了男爵 - 五四时期',
        1260 : ' 两队都杀了男爵 -  5期',
        1261 : ' 两队杀龙 - 五四时期',
        1262 : ' 两队杀龙 - 第5期',
        1263 : ' 两队杀抑制剂 - 五四时期',
        1264 : ' 两队杀抑制剂 -  5期',
        1265 : ' 会不会有一个王牌游戏下个游戏？',
        1266 : ' 会不会有双重故障下个游戏？',
        1267 : ' 奇/偶犯规',
        1268 : ' 亚洲盘口控球',
        1269 : ' 双胜越位',
        1270 : ' 亚洲盘口越位',
        1271 : ' 欠压/过越位 - 主队',
        1272 : ' 欠压/过越位 - 客场球队',
        1273 : ' 1X2越位',
        1274 : ' 在/在杀死第1期 - 主队',
        1275 : ' 欠压/过杀敌第二期 - 主队',
        1276 : ' 在/在杀死第3期 - 主队',
        1277 : ' 在/在杀死第1期 - 客场球队',
        1278 : ' 欠压/过杀敌第二期 - 客场球队',
        1279 : ' 在/在杀死第3期 - 客场球队',
        1280 : ' 欠压/过Wides',
        1281 : ' 欠压/过鸭子',
        1282 : ' 欠压/过围墙门',
        1283 : ' 欠压/过赛事四强 - 主队',
        1284 : ' 欠压/过比赛六人 - 主队',
        1285 : ' 欠压/过赛事四强 - 客场球队',
        1286 : ' 欠压/过比赛六人 - 客场球队',
        1287 : ' 欠压/过额外',
        1288 : ' 秋天第一次检票 - 主队',
        1289 : ' 秋天第一次检票 - 客场球队',
        1290 : ' 球队最佳击球手',
        1291 : ' 在/在比赛六人主队 - 第1局',
        1292 : ' 在/在比赛六人客场球队 - 第1局',
        1293 : ' 欠压/过赛事四强主队 - 第1局',
        1294 : ' 欠压/过赛事四强客场球队 - 第1局',
        1295 : ' 欠压/过最佳击球手',
        1296 : ' 第1局1日在解雇 - 主队',
        1297 : ' 第1局第2次在解雇 - 主队',
        1298 : ' 第1局3日在解雇 - 主队',
        1299 : ' 第1局第2次在解雇 - 客场球队',
        1300 : ' 第1局3日在解雇 - 客场球队',
        1301 : ' 第1局1日在解雇 - 客场球队',
        1302 : ' 最高得分超过 - 欠压/过',
        1303 : ' 第1局第2过欠压/过 - 主队',
        1304 : ' 第1局第3过欠压/过 - 主队',
        1306 : ' 第1局第3过欠压/过 - 客场球队',
        1307 : ' 第1局第2过欠压/过 - 客场球队',
        1309 : ' 输赢 - 团队',
        1310 : ' 输赢 - 团队包括加班费',
        1312 : ' 输赢精确',
        1314 : ' 输赢范围',
        1315 : ' 输赢精确 - 团队',
        1316 : ' 输赢 - 团队与其他任何分数',
        1318 : ' 亚洲盘口犯规 - 第1期',
        1319 : ' 亚洲盘口越位 - 第1期',
        1320 : ' 亚洲盘口越位 - 第二期',
        1321 : ' 亚洲盘口犯规 - 第二期',
        1322 : ' 亚洲盘口射击在目标 - 第二期',
        1324 : ' 欠压/过越位 - 第1期',
        1325 : ' 欠压/过越位 - 第二期',
        1326 : ' 首先犯规',
        1327 : ' 前两个指针',
        1328 : ' 首先三分球',
        1329 : ' 最后两个指针',
        1330 : ' 最后三分球',
        1331 : ' 最后罚球拿下',
        1332 : ' 首先罚球拿下',
        1333 : ' 首次回升',
        1334 : ' 最后超时',
        1335 : ' 输赢队 - 第1期',
        1336 : ' 输赢队 - 第二期',
        1337 : ' 输赢队 - 第3期',
        1338 : ' 输赢团队 - 五四时期',
        1339 : ' 输赢队 - 第5期',
        1340 : ' 最后犯规',
        1341 : ' 在/在第10期',
        1342 : ' 欠压/过第11期',
        1343 : ' 在/在第12期',
        1344 : ' 欠压/过第13期',
        1345 : ' 欠压/过14期',
        1346 : ' 在/在第15期',
        1347 : ' 欠压/过16期',
        1348 : ' 欠压/过第17期',
        1349 : ' 欠压/过18期',
        1350 : ' 欠压/过19期',
        1351 : ' 欠压/过第20期',
        1352 : ' 欠压/过21期',
        1353 : ' 欠压/过22期',
        1354 : ' 欠压/过23期',
        1355 : ' 欠压/过第24期',
        1356 : ' 欠压/过25期',
        1357 : ' 欠压/过第26期',
        1358 : ' 欠压/过第27期',
        1359 : ' 在/在第28期',
        1360 : ' 欠压/过第29期',
        1361 : ' 在/在第30期',
        1362 : ' 在/在第31期',
        1363 : ' 欠压/过第32期',
        1364 : ' 欠压/过第33期',
        1365 : ' 欠压/过第34期',
        1366 : ' 欠压/过第35期',
        1367 : ' 亚洲盘口第11期',
        1368 : ' 亚洲盘口12期',
        1369 : ' 亚洲盘口第13期',
        1370 : ' 亚洲盘口14期',
        1371 : ' 亚洲盘口15期',
        1372 : ' 亚洲盘口16期',
        1373 : ' 亚洲盘口17期',
        1374 : ' 亚洲盘口18期',
        1375 : ' 亚洲盘口19期',
        1376 : ' 亚洲盘口20期',
        1380 : ' 亚洲盘口24期',
        1381 : ' 亚洲盘口25期',
        1382 : ' 亚洲盘口第26期',
        1383 : ' 亚洲盘口第27期',
        1384 : ' 亚洲盘口28期',
        1385 : ' 亚洲盘口第29期',
        1386 : ' 亚洲盘口30期',
        1390 : ' 亚洲盘口第34期',
        1391 : ' 亚洲盘口第35期',
        1392 : ' 为了完成在十大',
        1393 : ' 熬夜',
        1394 : ' 亚洲盘口10期',
        1395 : ' 欠压/过球员的Powerplay点',
        1396 : ' 欠压/过球员加/减',
        1397 : ' 欠压/过球员射门',
        1398 : ' 顶级的维多利亚俱乐部',
        1399 : ' 在/在运行前前后后',
        1400 : ' 欠压/过框架',
        1401 : ' 前20名优胜者',
        1402 : ' 第一回合领袖',
        1404 : ' 第一回合六位枪手 -  A组',
        1406 : ' 第一回合三球',
        1407 : ' 第1轮18洞的巅峰对决',
        1408 : ' 比赛盘口',
        1409 : ' 团队与顶级鲍勒',
        1410 : ' 赢得了折腾和匹配',
        1411 : ' 欠压/过炮塔销毁',
        1412 : ' 欠压/过炮塔被毁 - 第1期',
        1413 : ' 欠压/过炮塔被毁 - 第二期',
        1414 : ' 欠压/过炮塔被毁 - 第3期',
        1415 : ' 得分最高的第一次结束后',
        1416 : ' 主队在/在运行 - 超过1至6日在',
        1417 : ' 客队在/在运行 - 超过1至6日在',
        1418 : ' 主队在/在运行 - 超过1至8日在',
        1419 : ' 客队在/在运行 - 超过1至8日在',
        1420 : ' 主队在/在运行 - 在一号至10日在',
        1421 : ' 客队在/在运行 - 在一号至10日在',
        1422 : ' 追在180上运行，球队夺冠',
        1423 : ' 如何多次运行进行评分从第一球',
        1424 : ' 欠压/过比赛Stumpings',
        1425 : ' 第一个检票方法',
        1426 : ' 第一球的比赛',
        1427 : ' 欠压/过 - 第1局',
        1428 : ' 顶部非的维多利亚俱乐部',
        1429 : ' 总决赛优胜者',
        1431 : ' 到达总决赛',
        1432 : ' 没有完成在8强',
        1434 : ' 欠压/过杀敌五四时期 - 客场球队',
        1435 : ' 在/在杀死第5期 - 客场球队',
        1436 : ' 欠压/过杀敌五四时期 - 主队',
        1437 : ' 在/在杀死第5期 - 主队',
        1438 : ' 欠压/过温度机场（摄氏）',
        1439 : ' 亚洲盘口 - 全职',
        1440 : ' 欠压/过值的所有数字绘制',
        1441 : ' 欠压/过值所有奇数拉',
        1442 : ' 欠压/过值都为偶数时绘制',
        1443 : ' 更甚至被画比奇数',
        1444 : ' 连续的号码会被吸引',
        1445 : ' 低于/正好/过 - 向数奇数球拉',
        1446 : ' 低于/正好/过 - 数偶数球拉',
        1447 : ' 5个球奖金的总和',
        1448 : ' 首先竞猜滚珠拉 - 奇/偶',
        1449 : ' 最后奖金球拉 - 奇/偶',
        1450 : ' 首先竞猜滚珠',
        1451 : ' 最后奖金球',
        1452 : ' 最低的5个球奖金',
        1453 : ' 奖金球 - 奇/偶',
        1454 : ' 相同的数字会被吸引',
        1455 : ' 人数最多的拉 - 奇/偶',
        1456 : ' 最低的数字拉 - 奇/偶',
        1457 : ' 球1  - 欠压/过',
        1458 : ' 球1  - 奇/偶',
        1459 : ' 球2  - 欠压/过',
        1460 : ' 球2  - 奇/偶',
        1461 : ' 球3  - 欠压/过',
        1462 : ' 球3  - 奇/偶',
        1463 : ' 球4  - 欠压/过',
        1464 : ' 球4  - 奇/偶',
        1465 : ' 球5  - 欠压/过',
        1466 : ' 球5  - 奇/偶',
        1467 : ' 球6  - 欠压/过',
        1468 : ' 球6  - 奇/偶',
        1469 : ' 球7  - 欠压/过',
        1470 : ' 球7  - 奇/偶',
        1471 : ' 球8  - 欠压/过',
        1472 : ' 球8  - 奇/偶',
        1473 : ' 球9  - 欠压/过',
        1474 : ' 球9  - 奇/偶',
        1475 : ' 球10  - 欠压/过',
        1476 : ' 球10  - 奇/偶',
        1477 : ' 球11  - 欠压/过',
        1478 : ' 球11  - 奇/偶',
        1479 : ' 球12  - 欠压/过',
        1480 : ' 球12  - 奇/偶',
        1481 : ' 球13  - 欠压/过',
        1482 : ' 球13  - 奇/偶',
        1483 : ' 球14  - 欠压/过',
        1484 : ' 球14  - 奇/偶',
        1485 : ' 15球 - 欠压/过',
        1486 : ' 15球 - 奇/偶',
        1487 : ' 球16  - 欠压/过',
        1488 : ' 球16  - 奇/偶',
        1489 : ' 球17  - 欠压/过',
        1490 : ' 球17  - 奇/偶',
        1491 : ' 球18  - 欠压/过',
        1492 : ' 球18  - 奇/偶',
        1493 : ' 球19  - 欠压/过',
        1494 : ' 球19  - 奇/偶',
        1495 : ' 球20  - 欠压/过',
        1496 : ' 球20  - 奇/偶',
        1497 : ' 至少一个拉是被3整除的数字',
        1498 : ' 至少一个拉被4整除的数字',
        1499 : ' 至少一个拉是整除5的数字',
        1500 : ' 至少一个拉是整除7的数字',
        1501 : ' 至少一个拉是被10整除的数字',
        1502 : ' 至少一个引出的整除15的数字',
        1503 : ' 至少一个引出的整除20的数字',
        1504 : ' 人数最多的拉 - 欠压/过',
        1505 : ' 总价值最低和最高的数字拉 - 欠压/过',
        1506 : ' 最低的数字拉 - 欠压/过',
        1507 : ' 差异的最高和最低数字之间画 - 欠压/过',
        1508 : ' 总价值最低和最高的数字拉 - 奇/偶',
        1509 : ' 差异的最高和最低数字之间画 - 奇/偶',
        1510 : ' 首先绘制数大于最后一个号码拉',
        1511 : ' 最后号码拉 - 奇/偶',
        1512 : ' 第一个号码拉 - 奇/偶',
        1513 : ' 所有中奖号码连',
        1514 : ' 所有中奖号码奇数',
        1515 : ' 数绘制',
        1520 : ' 五四时期 - 第一个炮塔',
        1521 : ' 第5期 - 第一个炮塔',
        1523 : ' 欠压/过男爵被杀害的 - 五四时期',
        1524 : ' 欠压/过男爵被杀害的 - 第5期',
        1525 : ' 欠压/过龙被杀害的 - 五四时期',
        1526 : ' 欠压/过龙被杀害的 - 第5期',
        1527 : ' 欠压/过炮塔被毁 - 五四时期',
        1528 : ' 欠压/过炮塔被毁 - 第5期',
        1529 : ' 五四时期持续时间',
        1530 : ' 第五周期持续时间',
        1531 : ' 奇/偶杀敌 - 五四时期',
        1532 : ' 奇/偶杀敌 -  5期',
        1533 : ' 欠压/过抑制剂 - 第1期',
        1534 : ' 欠压/过抑制剂 - 第二期',
        1535 : ' 温度机场范围（摄氏）',
        1536 : ' 欠压/过地图',
        1537 : ' 主队取胜和欠压/过 - 包括加班费',
        1538 : ' 客场球队夺冠和欠压/过 - 包括加班费',
        1539 : ' 回合优胜者',
        1540 : ' 总比赛两腿/过',
        1541 : ' 轮盘口',
        1542 : ' 首回合获胜者',
        1543 : ' 率先拿下3腿',
        1546 : ' 五四时期 - 第一罗山',
        1547 : ' 第5期 - 第一罗山',
        1548 : ' 五四时期 - 第一兵营',
        1549 : ' 第5期 - 第一个兵营',
        1550 : ' 五四时期 - 第一土特产品',
        1551 : ' 第5期 - 第一土特产品',
        1552 : ' 亚洲欠压/过角',
        1553 : ' 两队都有得分点的数量 - 第1期',
        1554 : ' 两队都有得分点的数量 - 上半场',
        1555 : ' 欠压/过第5期 - 主队',
        1556 : ' 欠压/过第5期 - 客场球队',
        1558 : ' 亚洲盘口点',
        1559 : ' 杯冠军',
        1561 : ' 1日3局获胜者',
        1562 : ' 第一3局下/过',
        1563 : ' 时间计分',
        1564 : ' 时间评分 - 主队',
        1565 : ' 时间评分 - 客场球队',
        1566 : ' 比赛时间结果',
        1567 : ' 顶级的国籍 - 南非',
        1568 : ' 顶级的国籍 - 西班牙人',
        1569 : ' 顶级的国籍 - 爱尔兰',
        1570 : ' 顶级的国籍 - 南美',
        1571 : ' 顶级的国籍 - 加拿大',
        1572 : ' 顶级的国籍 - 英国人',
        1573 : ' 顶级国籍 - 欧洲大陆',
        1575 : ' 为了使剪切',
        1576 : ' 为了削减小姐',
        1577 : ' 顶级国籍 - 美国',
        1578 : ' 国籍赢家',
        1579 : ' 亚洲盘口点 - 第1期',
        1580 : ' 亚洲盘口点 - 第二期',
        1581 : ' 亚洲盘口点 - 第3期',
        1583 : ' 强队鲍勒第1局 - 主队',
        1584 : ' 强队鲍勒第1局 - 客场球队',
        1585 : ' 强队击球手第1局 - 主队',
        1586 : ' 强队击球手第1局 - 客场球队',
        1587 : ' 会不会有一个王牌接下来点',
        1589 : ' 会不会有一个双误下一点',
        1591 : ' 欠压/过 - 第一过',
        1592 : ' 要赢得联赛',
        1593 : ' 要赢科',
        1594 : ' 点击率最高的 - 包括加班费',
        1595 : ' 打去远方',
        1596 : ' 何时会结束战斗',
        1597 : ' 战斗结果',
        1598 : ' 回合投注',
        1599 : ' 前一日命中',
        1600 : ' 第1局下击',
        1601 : ' 顶部第1趟',
        1602 : ' 第1局下运行',
        1603 : ' 点击率最高的在第1局',
        1605 : ' 团队最高得分围垦',
        1606 : ' 两队都有得分2个或多个目标',
        1607 : ' 两队都有得分3个或多个目标',
        1608 : ' 球队赢得大多数时期',
        1609 : ' 亚洲盘口全职 - 第1期',
        1610 : ' 亚洲盘口全职 - 第二期',
        1611 : ' 亚洲盘口全职 - 第3期',
        1612 : ' MVP',
        1614 : ' 要赢会议',
        1615 : ' 大奖的会议',
        1617 : ' 季播放器特价',
        1618 : ' 第一5局赢家 -  12',
        1619 : ' 第1期 - 半场获胜者主场/客场',
        1620 : ' 第1期 - 半场亚洲让球盘',
        1621 : ' 第二期 - 半场获胜者主场/客场',
        1622 : ' 第二期 - 半场亚洲让球盘',
        1624 : ' 欠压/过Player中运行',
        1625 : ' 欠压/过球员单打',
        1626 : ' 欠压/过球员本垒打',
        1627 : ' 欠压/过球员盗垒',
        1628 : ' 欠压/过球员双打',
        1629 : ' 欠压/过球员三同',
        1630 : ' 投手纪录赢',
        1631 : ' 欠压/过球员总基地',
        1635 : ' 在酣畅/过投手奔跑',
        1636 : ' 欠压/过投手命中允许',
        1637 : ' 欠压/过投手三振出局',
        1638 : ' 小组分类',
        1639 : ' 年轻车手分类',
        1640 : ' 分型分类',
        1641 : ' 山中之王',
        1642 : ' 阶段1',
        1649 : ' 最快圈速',
        1650 : ' 获胜的赛车',
        1651 : ' 第一驱动退休',
        1652 : ' 排位赛获胜的赛车',
        1653 : ' 最快在练习1',
        1654 : ' 点完成',
        1655 : ' 速度最快的选手',
        1657 : ' 第3阶段',
        1659 : ' 第一个构造退休',
        1660 : ' 获奖者的发车位置',
        1661 : ' 安全车期间比赛',
        1662 : ' 分类器数目',
        1663 : ' 虚拟安全车期间比赛',
        1664 : ' 洞的成绩',
        1667 : ' 要赢洞',
        1670 : ' 绑比赛',
        1672 : ' 欠压/过目标 - 下半场',
        1673 : ' 奇数/偶数轮五四时期',
        1674 : ' 奇数/偶数轮第5期',
        1675 : ' 第二轮领先者',
        1676 : ' 两队都有得分 - 包括加班费',
        1677 : ' 最后得分球队 - 包括加班费',
        1678 : ' 顶级国籍 - 德国',
        1679 : ' 顶级的国籍 - 奥地利',
        1680 : ' 热门国籍 - 新西兰人',
        1681 : ' 顶部左手球员',
        1682 : ' 顶尖业余',
        1683 : ' 顶级的国籍 - 韩国',
        1684 : ' 顶级的国籍 - 斯堪的纳维亚',
        1685 : ' 顶级的国籍 - 法国',
        1686 : ' 前冠军得主',
        1687 : ' 顶级的国籍 - 瑞典人',
        1688 : ' 顶级的国籍 - 日本',
        1689 : ' 顶级的国籍 - 苏格兰人',
        1690 : ' 顶级的国籍 - 比利时',
        1691 : ' 欧洲盘口五四时期',
        1692 : ' 欧洲盘口第3期',
        1693 : ' 欧洲盘口第二期',
        1694 : ' 欧洲盘口第1期',
        1695 : ' 一发得分',
        1696 : ' 比赛赢取的比分',
        1697 : ' 比分领先后轮',
        1698 : ' 打到球道随着开球',
        1699 : ' 打绿色法规',
        1700 : ' 第三轮领先者',
        1702 : ' 欠压/过球员传球尝试',
        1704 : ' 触地得分第1期',
        1705 : ' 触地得分第二期',
        1706 : ' 触地得分第3期',
        1707 : ' 着陆在五四时期',
        1708 : ' 低于/正好/过 - 主队达阵',
        1709 : ' 低于/正好/过 - 客场球队达阵',
        1710 : ' 低于/正好/过 - 主队上半场达阵',
        1711 : ' 低于/正好/过 - 客场球队上半场达阵',
        1712 : ' 第16轮冠军 - 五四时期',
        1713 : ' 第16轮冠军 - 第5期',
        1714 : ' 欠压/过TOUS被毁 - 五四时期',
        1715 : ' 欠压/过TOUS被毁 - 第5期',
        1722 : ' 设置完成无',
        1723 : ' 欠压/过杀敌 - 第1期 - 第一回合',
        1724 : ' 欠压/过杀敌 - 第二期 - 第一回合',
        1728 : ' 两队都有得分 - 额外时间',
        1729 : ' 双胜 - 额外时间',
        1730 : ' 将游戏转到中超结束了吗？',
        1731 : ' 双胜第5期',
        1732 : ' 双胜第六周期',
        1733 : ' 双胜7期',
        1734 : ' 双胜8期',
        1736 : ' 奔跑第1局数',
        1737 : ' 游程的数量第2局',
        1738 : ' 奔跑第3局数',
        1739 : ' 游程的数量第4局',
        1740 : ' 游程的数量第5局',
        1741 : ' 游程的数量第6局',
        1742 : ' 游程的数量第7局',
        1743 : ' 游程的数量第8局',
        1744 : ' 五十在第1局计分',
        1745 : ' 秋季第一次检票',
        1747 : ' 一个男人出',
        1748 : ' 完成比赛',
        1749 : ' 世纪拿下',
        1750 : ' 世纪拿下 - 第1局',
        1752 : ' 客场球队首先解雇6路',
        1753 : ' 热门赛事击球手',
        1754 : ' 要得分罚款',
        1758 : ' 年度最佳第六人',
        1760 : ' 年度新秀',
        1761 : ' 常规赛MVP',
        1763 : ' 击球手得分第1局百',
        1765 : ' 9箭结束的比赛中',
        1766 : ' 第一次尝试的时间',
        1767 : ' 最后一次尝试的时间',
        1770 : ' 9箭结束的比赛 - 主队',
        1771 : ' 9箭结束的比赛 - 客场球队',
        1772 : ' 上运行得分手',
        1773 : ' 上运行得分手 - 主队',
        1774 : ' 上运行得分手 - 客场球队',
        1775 : ' 顶级射手运行团队',
        1776 : ' 欠压/过平底船',
        1777 : ' 欠压/过萨克斯',
        1778 : ' 欠压/过1唐斯',
        1779 : ' 1队得到第一下来',
        1780 : ' 在/上半场过场球 - 主队',
        1781 : ' 在/上半场过场球 - 客场球队',
        1782 : ' 低于/正好/在 - 场进球上半场',
        1783 : ' 欠压/过达阵第1期',
        1784 : ' 在/在接受处罚',
        1785 : ' 大多数杀死在比赛',
        1786 : ' 团队大部分杀死在比赛',
        1787 : ' 欠压/过通行证企图',
        1788 : ' 欠压/过通行证完成',
        1789 : ' 欠压/过传球码',
        1790 : ' 欠压/过拉什尝试',
        1791 : ' 欠压/过拉什码',
        1792 : ' 欠压/过球员铲球和协助',
        1794 : ' 球队首先蓬特',
        1795 : ' 第一期1×2和欠压/过',
        1796 : ' 1X2助攻',
        1797 : ' 双胜助攻',
        1798 : ' 班子带领每一个时期后',
        1799 : ' 团队多数通过的庭院',
        1800 : ' 团队冲的庭院',
        1801 : ' 成功的2点转换',
        1802 : ' 在/在触地传球',
        1803 : ' 欠压/过场进球第1期',
        1804 : ' 评分最后上半场2分钟',
        1805 : ' 团队接收开幕开球',
        1806 : ' 欠压/过主队达阵 - 第1期',
        1807 : ' 欠压/过客场球队达阵 - 第1期',
        1808 : ' 低于/正好/高于主队达阵 - 第1期',
        1809 : ' 低于/正好/在客场球队达阵 - 第1期',
        1810 : ' 欠压/过主队场均进球 - 第1期',
        1812 : ' 欠压/过客场球队场均进球 - 第1期',
        1813 : ' 客场球队赢得每季度',
        1814 : ' 主队取胜每季度',
        1815 : ' 游戏的第一次进攻打法',
        1816 : ' 要赢季度',
        1817 : ' 进入决赛',
        1818 : ' 主队入球25粒进球',
        1819 : ' 主队入球30个球',
        1820 : ' 客队入球25粒进球',
        1821 : ' 客队入球30个球',
        1822 : ' 主队取胜一半要么',
        1823 : ' 客场球队赢得任意半场',
        1824 : ' 消除舞台',
        1825 : ' 欠压/过助攻',
        1826 : ' 欠压/过篮板',
        1827 : ' 欠压/过主队助攻',
        1828 : ' 欠压/过客场球队助攻',
        1829 : ' 欠压/过主队篮板球',
        1830 : ' 欠压/过客场球队篮板',
        1831 : ' 1X2 3点进行',
        1832 : ' 欠压/过3点进行',
        1833 : ' 欠压/过主队3点进行',
        1834 : ' 欠压/过客队3点进行',
        1835 : ' 亚洲盘口3点进行',
        1841 : ' 首先换人',
        1842 : ' 在比赛第2个目标',
        1843 : ' 第三个进球在比赛中',
        1846 : ' 60分钟出结果',
        1847 : ' 75分钟出结果',
        1848 : ' 一个进球在第5分钟？',
        1849 : ' 首先犯规，匹配',
        1850 : ' 绘制在半双工',
        1851 : ' 头球在比赛？',
        1852 : ' 欠压/过入球记录',
        1853 : ' 在/在抢七的比赛中',
        1854 : ' 欠压/过破发点，',
        1855 : ' 欠压/过破发主队',
        1856 : ' 欠压/过破发客队',
        1857 : ' 欠压/过最高得分区',
        1858 : ' 欠压/过得分最低的季度',
        1859 : ' 大多数尝试次数3路',
        1860 : ' 10分钟得分差距5方向',
        1861 : ' 最亏团队',
        1862 : ' 一个小组将比分的游戏',
        1863 : ' 赢得所有时期 - 主队',
        1864 : ' 赢得所有时期 - 客场球队',
        1865 : ' 前10分钟内/过尝试次数',
        1866 : ' 15分钟之内/过 - 角',
        1867 : ' 2个目标在由团队行',
        1868 : ' 3个目标在由团队行',
        1869 : ' 大多数转换尝试次数',
        1872 : ' 非种子选手入围？',
        1873 : ' 外卡达成最后？',
        1874 : ' 胜出的半 - 网球冠军',
        1875 : ' 胜出的季度 - 网球冠军',
        1881 : ' 一杆进洞',
        1882 : ' 会不会有一个打得过？',
        1883 : ' 72个洞比赛跌宕',
        1884 : ' 54个洞比赛跌宕',
        1885 : ' 号码摘1个草案',
        1886 : ' 1X2和双胜',
        1887 : ' 主队取胜\\绘制和两队都有得分',
        1888 : ' 客场球队赢得\\绘制和两队都有得分',
        1889 : ' 输赢 - 上半场',
        1891 : ' 美国联盟赛扬奖',
        1892 : ' 美国联盟MVP',
        1893 : ' 国联塞扬',
        1894 : ' 国联MVP',
        1895 : ' 投手记录最常规赛胜场',
        1896 : ' 玩家要打到最常规赛本垒打',
        1897 : ' 将游戏中的额外时间决定的？',
        1898 : ' 常规赛获胜％',
        1900 : ' 球员最佳射手',
        1901 : ' 欠压/过铲球',
        1902 : ' 号码摘2草案',
    }
};