import { Translate } from '../localization';

export const gameNames = {
    "Slot": "Slots",
    "Tablegame": "Table Game",
    "Instantwin": "Instant Win",
    "Livecasino": "Live Casino",
    "Scratchcard": "Scratch Card",
    "Videopoker": "Video Poker",
    "Virtual_sports": "Virtual Sports"
}

export const getTransactionTypes = () => {
    return [
        {
            label: Translate.all,
            value: 'all',
        },
        {
            label: Translate.deposit,
            value: 'deposit',
        },
        {
            label: Translate.withdraw,
            value: 'withdraw',
        },
        {
            label: Translate.credit,
            value: 'credet',
        },
        {
            label: Translate.debit,
            value: 'debet',
        }
    ];
};

export const getMyBetFilterTypes = () => {
    return [
        {
            label: Translate.all,
            value: 'all',
        },
        {
            label: `${Translate['in game']}`,
            value: 'in game',
        },
        {
            label: Translate.won,
            value: 'won',
        },
        {
            label: Translate.lost,
            value: 'lost',
        },
        {
            label: 'Refund',
            value: 'refund',
        },
        {
            label: 'Cashout',
            value: 'cashout',
        },
    ];
};

export const NEW_CASINO_CATEGORIES = [
    {
        name: 'All',
        value: Translate.all,
        icon: 'icon-all',
    },
    {
        name: 'Pragmatic Play Casino',
        value: 'PPC',
        icon: '',
    },
    {
        name: '1x2 Gaming',
        value: '1x2',
        icon: '',
    },
    {
        name: 'Evoplay',
        value: 'EVP',
        icon: '',
    },
    {
        name: 'August Gaming',
        value: 'AUG',
        icon: '',
    },
    {
        name: 'BB Gaming',
        value: 'BBG',
        icon: '',
    },
    {
        name: 'Blueprint Gaming',
        value: 'BPG',
        icon: '',
    },
    {
        name: 'Booongo',
        value: 'BNG',
        icon: '',
    },
    {
        name: 'Dragoon Soft',
        value: 'DS',
        icon: '',
    },

    {
        name: 'Fantasma Games',
        value: 'FNG',
        icon: '',
    },
    {
        name: 'GameArt',
        value: 'GA',
        icon: '',
    },
    {
        name: 'Gamefish Global',
        value: 'GFG',
        icon: '',
    },
    {
        name: 'Habanero',
        value: 'HAB',
        icon: '',
    },
    {
        name: 'Hacksaw Gaming',
        value: 'HAK',
        icon: '',
    },
    {
        name: 'Iron Dog Studio',
        value: 'IDS',
        icon: '',
    },
    {
        name: 'Kalamba Games',
        value: 'KGL',
        icon: '',
    },
    {
        name: 'Lady Luck',
        value: 'LL',
        icon: '',
    },
    {
        name: 'Maverick',
        value: 'MAV',
        icon: '',
    },
    {
        name: 'Mobilots',
        value: 'MOB',
        icon: '',
    },
    {
        name: 'NetEnt',
        value: 'NE',
        icon: '',
    },
    {
        name: 'NetGame',
        value: 'NGE',
        icon: '',
    },
    {
        name: 'Nolimit City',
        value: 'NLC',
        icon: '',
    },
    {
        name: 'OMI Gaming',
        value: 'OMI',
        icon: '',
    },
    {
        name: 'OneTouch',
        value: 'OT',
        icon: '',
    },
    {
        name: 'PlayPearls',
        value: 'PP',
        icon: '',
    },

    {
        name: 'Push Gaming',
        value: 'PUG',
        icon: '',
    },
    {
        name: 'Revolver Gaming',
        value: 'RG',
        icon: '',
    },
    {
        name: 'Slotmill',
        value: 'SM',
        icon: '',
    },
    {
        name: 'Spearhead Studios',
        value: 'SHS',
        icon: '',
    },
    {
        name: 'Splitrock',
        value: 'SPR',
        icon: '',
    },
    {
        name: 'Thunderkick',
        value: 'TK',
        icon: '',
    },
    {
        name: 'Yggdrasil',
        value: 'YGG',
        icon: '',
    },
];

export const CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];

export const GG_SLOT_CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];

export const EVO_CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];

export const COIN_PAYMENTS_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Deposit',
        value: 'deposit',
    },
    {
        label: 'Withdraw',
        value: 'withdraw',
    }
];

export const LIVE_CASINO_TYPES = [
    {
        label: Translate.all,
        value: 'all',
    },
    {
        label: 'Credit',
        value: 'credet',
    },
    {
        label: 'Debit',
        value: 'debet',
    }
];

export const CASINO_CATEGORIES = [
    {
        id: 1,
        label: Translate.all,
        value: 'all',
    },
    {
        id: 2,
        label: 'Netent',
        value: 'netent',
    },
    {
        id: 3,
        label: 'Novomatic',
        value: 'novomatic',
    },
    {
        id: 4,
        label: 'One Touch',
        value: 'one-touch',
    },
    {
        id: 5,
        label: 'Pragmaticplay',
        value: 'pragmaticplay',
    },
    {
        id: 6,
        label: 'Amatic',
        value: 'amatic',
    },
    {
        id: 7,
        label: 'Betsoft',
        value: 'betsoft',
    },
    {
        id: 8,
        label: 'Netgame',
        value: 'netgame',
    },
    {
        id: 9,
        label: 'Egaming',
        value: 'egaming',
    },
    {
        id: 10,
        label: 'Wazdan',
        value: 'wazdan',
    },
    {
        id: 11,
        label: 'Boongo Games',
        value: 'boongo-games',
    },
    {
        id: 12,
        label: 'Spinomenal',
        value: 'spinomenal',
    },
    {
        id: 13,
        label: 'Virtual Generation',
        value: 'virtual-generation',
    },
    {
        id: 14,
        label: 'PGSoft',
        value: 'pgsoft',
    },
    {
        id: 15,
        label: 'Woohoo',
        value: 'woohoo',
    },
];

export const CASINO_TOP_GAMES = [1159335, 1002, 529, 1005, 507, 561, 1001, 570, 594, 137];

export const LIVE_CASINO_CATEGORIES = [
    {
        name: 'All',
        value: Translate.all,
        icon: 'icon-all',
    },
    {
        name: 'Roulette',
        value: 'CASINO/LIVECASINO/ROULETTE',
        icon: 'icon-roulette',
    },
    {
        name: 'Blackjack',
        value: 'CASINO/LIVECASINO/BLACKJACK',
        icon: 'icon-blackjack',
    },
    {
        name: 'Baccarat',
        value: 'CASINO/LIVECASINO/BACCARAT',
        icon: 'icon-baccarat',
    },
    {
        name: 'Dragon Tiger',
        value: 'CASINO/LIVECASINO/BACCARAT_DRAGON_TIGER',
        icon: 'icon-dragon-tiger',
    },
    {
        name: 'Poker',
        value: 'CASINO/LIVECASINO/POKER',
        icon: 'icon-poker',
    },
    {
        name: 'Lottery',
        value: 'CASINO/LIVECASINO/LOTTERY',
        icon: 'icon-bet-on-numbers',
    },
    {
        name: 'Holdem',
        value: 'CASINO/LIVECASINO/CASINO_HOLDEM',
        icon: '',
    },
    {
        name: 'Other',
        value: 'CASINO/LIVECASINO/OTHER',
        icon: '',
    },
    {
        name: 'Andar Bahar',
        value: 'CASINO/LIVECASINO/ANDAR_BAHAR',
        icon: '',
    },
    {
        name: 'Game Show',
        value: 'CASINO/LIVECASINO/GAME_SHOW',
        icon: '',
    },
    {
        name: 'Lucky 7',
        value: 'CASINO/LIVECASINO/LUCKY7',
        icon: '',
    }
];

export const VIRTUAL_SPORTS = [
    {
        name: 'All',
        value: Translate.all,
        icon: 'icon-all',
    },
    {
        name: 'Virtual Sports',
        value: 'CASINO/VIRTUAL_SPORTS/VIRTUAL_HORSES',
        icon: '',
    },
    {
        name: 'Virtual Games',
        value: 'CASINO/VIRTUALGAME/LOTTERY',
        icon: '',
    },
];

export const LIVE_CASINO_EVOLUTION_GAMES = [
    {
        category: 'Baccarat',
        company: 'New Evolution',
        name: 'First Person Baccarat',
        id: '1179673',
        logo: './images/live-casino/baccarat/Baccarat_first_person.jpg',
        sort: 4,
    },
    {
        category: 'Baccarat',
        company: 'New Evolution',
        name: 'Speed Baccarat G',
        id: '1179673',
        logo: './images/live-casino/baccarat/speed_baccarat.jpg',
        sort: 4,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'First Person Blackjack',
        id: '1179673',
        logo: './images/live-casino/blackjack/Black_jack_first_person.jpg',
        sort: 2,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'Blackjack Lobby',
        id: '1179673',
        logo: './images/live-casino/blackjack/Infonate_BlackJack.jpg',
        sort: 2,
    },
    {
        category: 'Blackjack',
        company: 'New Evolution',
        name: 'Blackjack Platinum VIP',
        id: '1179673',
        logo: './images/live-casino/blackjack/vip_black_jack.jpg',
        sort: 2,
    },
    {
        category: 'Dragon Tiger',
        company: 'New Evolution',
        name: 'Dragon Tiger',
        id: '1179673',
        logo: './images/live-casino/dragon-tiger/Dragon_Tiger.jpg',
        sort: 5,
    },
    {
        category: 'Football',
        company: 'New Evolution',
        name: 'Football studio',
        id: '1179673',
        logo: './images/live-casino/football-studio/Football_Studio.jpg',
        sort: 7,
    },
    {
        category: 'Holdem',
        company: 'New Evolution',
        name: 'Casino Holdem Lobby',
        id: '1179673',
        logo: './images/live-casino/holdem/Holdem.jpg',
        sort: 3,
    },
    {
        category: 'Holdem',
        company: 'New Evolution',
        name: 'Side Bet City',
        id: '1179673',
        logo: './images/live-casino/holdem/Side_Bet_city.jpg',
        sort: 3,
    },
    {
        category: 'Money Wheel',
        company: 'New Evolution',
        name: 'Dream Catcher',
        id: '1179673',
        logo: './images/live-casino/money-wheel/Dream_Catcher_Money_Wheel.jpg',
        sort: 8,
    },
    {
        category: 'Money Wheel',
        company: 'New Evolution',
        name: 'Dream Catcher',
        id: '1179673',
        logo: './images/live-casino/money-wheel/Money_Wheel_2.jpg',
        sort: 8,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'American Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/AMERICAN_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Auto-Roulette VIP',
        id: '1179673',
        logo: './images/live-casino/roulette/Auto_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Immersive Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/Immersive_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'RNG Lightning Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/Lightning_Roulette.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/SPEED_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Roulette',
        company: 'New Evolution',
        name: 'Roulette',
        id: '1179673',
        logo: './images/live-casino/roulette/VIP_ROULETTE.jpg',
        sort: 1,
    },
    {
        category: 'Sick Bo',
        company: 'New Evolution',
        name: 'Super Sic Bo',
        id: '1179673',
        logo: './images/live-casino/sick-bo/Sick_Bo.jpg',
        sort: 9,
    },
    {
        category: 'Bet On Numbers',
        company: 'New Evolution',
        name: 'RNG First Person Mega Ball',
        id: '1179673',
        logo: './images/live-casino/megaball/Mega_Ball.jpg',
        sort: 6,
    },
];

export const intervalTime = 180000;

export const CASINO_PER_PAGE = 50;

export const sessionTimer = 5;

export const totalSegments = 40;

export const resultTotalSegments = 20;

export const agentCode = '33879';

export const prematchEventBatchSize = 100;

export const prematchMarketSize = 20;

export const internationalLocationIds = [
    4, // International
    392, // International Youths
    393 // International Clubs
];
export const lobby_id = 4;

export const CASINO_TRANSACTION_OPTIONS = [
    {
        id: 'casino',
        label: Translate.casino,
    },
    // {
    //     id: 'GG-Slot casino',
    //     label: 'Amatic',
    // },
];

export const LIVE_CASINO_TRANSACTION_OPTIONS = [
    {
        id: 'live casino',
        label: Translate.liveCasino,
    },
    // {
    //     id: 'evo casino',
    //     label: 'Evo Casino',
    // },
];
export const COIN_PAYMENT_TRANSACTION_OPTIONS = [
    {
        id: 'coin payments',
        label: Translate.coinPayments,
    },
];
export const DESKTOP_SIDE_BAR_MENU = [
    {
        title: Translate.support,
        path: `/${Translate.getLanguage()}/d/support/contact-us`,
        pathMobile: '/support/contact-us',
        iconSrc: '/images/customer.png',
        subNav: [
            {
                title: Translate.contactUs,
                path: `/${Translate.getLanguage()}/d/support/contact-us`,
            },
            // {
            //     title: Translate.faq,
            //     path: '/d/support/faq',
            // },
            {
                title: Translate.termsAndConditon,
                path: `/${Translate.getLanguage()}/d/support/terms-and-conditions`,
            },
            {
                title: Translate.privacyPolicy,
                path: `/${Translate.getLanguage()}/d/support/privacy-policy`,
            },
            // {
            //     title : Translate.cookiePolicy,
            //     path : '/d/support/cookie-policy',
            // },
            // {
            //     title : Translate.bonusRules,
            //     path : '/d/support/bonus-rules',
            // }
        ]
    },
    {
        title: Translate.about,
        path: `/${Translate.getLanguage()}/d/about/about-jackpotCT`,
        pathMobile: '/about/about-jackpotCT',
        iconSrc: '/images/information-button.png',
        subNav: [
            {
                title: Translate.aboutZoobet,
                path: `/${Translate.getLanguage()}/d/about/about-jackpotCT`,
            },
            // {
            //     title : `${Translate.affiliates}`,
            //     path : '',
            // }
        ]
    },
    // {
    //     title: Translate.adSponsor,
    //     path: '/d/ad-sponsor',
    //     pathMobile: '/about/about-jackpotCT',
    //     iconSrc: '/images/information-button.png',
    //     subNav: [
    //         {
    //             title: Translate.aboutZoobet,
    //             path: '/d/ad-sponsor',
    //         },
    //     ]
    // },
    // {
    //     title: Translate.responsibleGaming,
    //     path: '/d/responsible-gaming/introduction',
    //     pathMobile: '/responsible-gaming/introduction',
    //     iconSrc: '/images/heart.png',
    //     subNav: [
    //         {
    //             title: Translate.introduction,
    //             path: '/d/responsible-gaming/introduction',
    //         },
    //         {
    //             title: Translate.settingLimits,
    //             path: '/d/responsible-gaming/limits',
    //         }
    //     ]
    // }
];
export const defaultCurrency = 'USD';

export const currencyType = [
    {
        name: 'Bitcoin - BTC',
        value: 'BTC'
    },
    // {
    //     name: 'Bitcoin/BTCB Token (BC Chain)',
    //     value: 'BTC.BEP2'
    // },
    // {
    //     name: 'Bitcoin/BTCB Token (BSC Chain)',
    //     value: 'BTC.BEP20'
    // },
    // {
    //     name: 'Bitcoin (Lightning Network)',
    //     value: 'BTC.LN'
    // },
    {
        name: 'Bitcoin Cash - BCH',
        value: 'BCH'
    },
    // {
    //     name: 'Bitcoin Cash Token (BSC Chain)',
    //     value: 'BCH.BEP20'
    // },
    // {
    //     name: 'Litecoin',
    //     value: 'LTC'
    // },
    // {
    //     name: 'Litecoin Token (BC Chain)',
    //     value: 'LTC.BEP2'
    // },
    // {
    //     name: 'Litecoin Token (BSC Chain)',
    //     value: 'LTC.BEP20'
    // },
    // {
    //     name: 'Velas (EVM)',
    //     value: 'VLX'
    // },
    // {
    //     name: 'Velas (Native)',
    //     value: 'VLX.Native'
    // },
    // {
    //     name: '1irstgold',
    //     value: '1GOLD'
    // },
    // {
    //     name: 'Cardano (BC Chain)',
    //     value: 'ADA.BEP2'
    // },
    // {
    //     name: 'Apollo',
    //     value: 'APL'
    // },
    // {
    //     name: 'Bit2Me',
    //     value: 'B2M'
    // },
    // {
    //     name: 'Babil',
    //     value: 'BABIL'
    // },
    // {
    //     name: 'Bytecoin',
    //     value: 'BCN'
    // },
    // {
    //     name: 'Beam',
    //     value: 'BEAM'
    // },
    // {
    //     name: 'BF Token',
    //     value: 'BFT'
    // },
    // {
    //     name: 'Bistroo Token',
    //     value: 'BIST'
    // },
    // {
    //     name: 'BizzCoin',
    //     value: 'BIZZ'
    // },
    // {
    //     name: 'BizzCoin (Tron/TRC20)',
    //     value: 'BIZZ.TRC20'
    // },

    // {
    //     name: 'BNB Coin (BSC Chain)',
    //     value: 'BNB.BSC'
    // },
    // {
    //     name: 'BNB Coin (ERC-20)',
    //     value: 'BNB.ERC20'
    // },
    // {
    //     name: 'Brazilian Digital Token',
    //     value: 'BRZ'
    // },
    // {
    //     name: 'Bitcoin Vault',
    //     value: 'BTCV'
    // },
    // {
    //     name: 'Bitcoin Gold',
    //     value: 'BTG'
    // },
    // {
    //     name: 'BitTorrent (Tron/TRC20)',
    //     value: 'BTT.TRC20'
    // },

    // {
    //     name: 'BUSD Token (BC Chain)',
    //     value: 'BUSD.BEP2'
    // },
    // {
    //     name: 'BUSD Token (BSC Chain)',
    //     value: 'BUSD.BEP20'
    // },
    // {
    //     name: 'Binance USD (Tron/TRC20)',
    //     value: 'BUSD.TRC20'
    // },
    // {
    //     name: 'Burency',
    //     value: 'BUY'
    // },
    // {
    //     name: 'Celsius',
    //     value: 'CEL'
    // },
    // {
    //     name: 'Celo',
    //     value: 'CELO'
    // },
    // {
    //     name: 'CloakCoin',
    //     value: 'CLOAK'
    // },
    // {
    //     name: 'Centric SWAP (BSC Chain)',
    //     value: 'CNS.BEP20'
    // },
    // {
    //     name: 'Crown',
    //     value: 'CRW'
    // },
    // {
    //     name: 'CoinSwap Token (BSC Chain)',
    //     value: 'CSS.BEP20'
    // },
    // {
    //     name: 'CureCoin',
    //     value: 'CURE'
    // },
    // {
    //     name: 'Celo Dollar',
    //     value: 'cUSD'
    // },
    // {
    //     name: 'Dai (ERC20)',
    //     value: 'DAI'
    // },
    // {
    //     name: 'Dai Token (BSC Chain)',
    //     value: 'DAI.BEP20'
    // },
    // {
    //     name: 'Dash',
    //     value: 'DASH'
    // },
    // {
    //     name: 'DigiByte',
    //     value: 'DGB'
    // },
    // {
    //     name: 'Divi',
    //     value: 'DIVI'
    // },
    // {
    //     name: 'Dogecoin',
    //     value: 'DOGE'
    // },
    // {
    //     name: 'Dogecoin (BSC Chain)',
    //     value: 'DOGE.BEP20'
    // },
    // {
    //     name: 'Polkadot Token (BSC Chain)',
    //     value: 'DOT.BEP20'
    // },
    // {
    //     name: 'Dynasty Global Investments AG',
    //     value: 'DYNS'
    // },
    // {
    //     name: 'Ether Classic',
    //     value: 'ETC'
    // },
    // {
    //     name: 'Ethereum Classic (BSC Chain)',
    //     value: 'ETC.BEP20'
    // },
    {
        name: 'Ether - ETH',
        value: 'ETH'
    },
    // {
    //     name: 'Ethereum (BC Chain)',
    //     value: 'ETH.BEP2'
    // },
    // {
    //     name: 'Ethereum Token (BSC Chain)',
    //     value: 'ETH.BEP20'
    // },
    // {
    //     name: 'Electroneum',
    //     value: 'ETN'
    // },
    // {
    //     name: 'STASIS EURS',
    //     value: 'EURS'
    // },
    // {
    //     name: 'EventChain',
    //     value: 'EVC'
    // },
    // {
    //     name: 'Firo',
    //     value: 'FIRO'
    // },
    // {
    //     name: 'Franklin',
    //     value: 'FLY'
    // },
    // {
    //     name: 'Fuel Token',
    //     value: 'FUEL'
    // },
    // {
    //     name: 'GlobCoin',
    //     value: 'GLX'
    // },
    // {
    //     name: 'Groestlcoin',
    //     value: 'GRS'
    // },
    // {
    //     name: 'Guapcoin',
    //     value: 'GUAP'
    // },
    // {
    //     name: 'Gemini dollar',
    //     value: 'GUSD'
    // },
    // {
    //     name: 'KuCoin Token',
    //     value: 'KCS'
    // },
    // {
    //     name: 'Kingdom Karnage Token (BSC Chain)',
    //     value: 'KKT.BEP20'
    // },
    // {
    //     name: 'Komodo',
    //     value: 'KMD'
    // },
    // {
    //     name: 'Kinguin Krowns',
    //     value: 'KRS'
    // },
    // {
    //     name: 'ChainLink Token (ERC20)',
    //     value: 'LINK'
    // },
    // {
    //     name: 'ChainLink Token (BSC Chain)',
    //     value: 'LINK.BEP20'
    // },
    // {
    //     name: 'LunaChow on xDai (BSC Chain)',
    //     value: 'LUCHOW.BEP20'
    // },
    // {
    //     name: 'Metronome',
    //     value: 'MET'
    // },
    // {
    //     name: 'Magic Internet Money (BSC Chain)',
    //     value: 'MIM.BEP20'
    // },
    // {
    //     name: 'Magic Internet Money',
    //     value: 'MIM.ERC20'
    // },
    // {
    //     name: 'OKB',
    //     value: 'OKB'
    // },
    // {
    //     name: 'Oxen',
    //     value: 'OXEN'
    // },
    // {
    //     name: 'PIVX',
    //     value: 'PIVX'
    // },
    // {
    //     name: 'POA20 (ERC20 Token)',
    //     value: 'POA20'
    // },
    // {
    //     name: 'PayPal USD',
    //     value: 'PYUSD'
    // },
    // {
    //     name: 'QASH',
    //     value: 'QASH'
    // },
    // {
    //     name: 'Qtum',
    //     value: 'QTUM'
    // },
    // {
    //     name: 'Radio Caca V2',
    //     value: 'RACA'
    // },
    // {
    //     name: 'Radio Caca V2 (BSC Chain)',
    //     value: 'RACA.BEP20'
    // },
    // {
    //     name: 'EverRise (BSC Chain)',
    //     value: 'RISE.BEP20'
    // },
    // {
    //     name: 'Rasputin Online Coin',
    //     value: 'ROC'
    // },
    // {
    //     name: 'Ravencoin',
    //     value: 'RVN'
    // },
    // {
    //     name: 'Sai',
    //     value: 'SAI'
    // },
    // {
    //     name: 'Siambitcoin',
    //     value: 'sBTC'
    // },
    // {
    //     name: 'SHIBA INU (ERC20)',
    //     value: 'SHIB'
    // },
    // {
    //     name: 'SHIBA INU (BSC Chain)',
    //     value: 'SHIB.BEP20'
    // },
    // {
    //     name: 'SkinCoin',
    //     value: 'SKIN'
    // },
    // {
    //     name: 'SmartCash',
    //     value: 'SMART'
    // },
    // {
    //     name: 'Solana',
    //     value: 'SOL'
    // },
    // {
    //     name: 'SparkPoint (ERC20)',
    //     value: 'SRK.ERC20'
    // },
    // {
    //     name: 'Sirin',
    //     value: 'SRN'
    // },
    // {
    //     name: 'StorjToken',
    //     value: 'STORJ'
    // },
    // {
    //     name: 'Syscoin',
    //     value: 'SYS'
    // },
    // {
    //     name: 'Telos',
    //     value: 'TLOS'
    // },
    // {
    //     name: 'TORG',
    //     value: 'TORG'
    // },
    // {
    //     name: 'Tronipay',
    //     value: 'TRP'
    // },
    {
        name: 'TRON - TRX',
        value: 'TRX'
    },
    {
        name: 'Binance Coin - BNB (Mainnet)',
        value: 'BNB'
    },
    // {
    //     name: 'Binance USD (ERC20)',
    //     value: 'BUSD'
    // },
    // {
    //     name: 'TrueUSD',
    //     value: 'TUSD'
    // },
    // {
    //     name: 'TrueUSD (BSC Chain)',
    //     value: 'TUSD.BEP20'
    // },
    // {
    //     name: 'TrueUSD (Tron/TRC20)',
    //     value: 'TUSD.TRC20'
    // },
    // {
    //     name: 'Ucacoin',
    //     value: 'UCA'
    // },
    // {
    //     name: 'USD Coin (ERC20)',
    //     value: 'USDC'
    // },
    // {
    //     name: 'USD Coin (BSC Chain)',
    //     value: 'USDC.BEP20'
    // },
    // {
    //     name: 'USD Coin (Tron/TRC20)',
    //     value: 'USDC.TRC20'
    // },
    // {
    //     name: 'Tether USD (BC Chain)',
    //     value: 'USDT.BEP2'
    // },
    // {
    //     name: 'Tether USD (BSC Chain)',
    //     value: 'USDT.BEP20'
    // },
    // {
    //     name: 'Tether USD (ERC20)',
    //     value: 'USDT.ERC20'
    // },
    // {
    //     name: 'Tether USD (Solana)',
    //     value: 'USDT.SOL'
    // },
    // {
    //     name: 'Tether USD (Tron/TRC20)',
    //     value: 'USDT.TRC20'
    // },
    // {
    //     name: 'Vertcoin',
    //     value: 'VTC'
    // },
    // {
    //     name: 'Waves',
    //     value: 'WAVES'
    // },
    // {
    //     name: 'WOM Token',
    //     value: 'WOM'
    // },
    // {
    //     name: 'Curate',
    //     value: 'XCUR'
    // },
    // {
    //     name: 'digitalbits',
    //     value: 'XDB'
    // },
    // {
    //     name: 'NEM',
    //     value: 'XEM'
    // },
    // {
    //     name: 'Monero',
    //     value: 'XMR'
    // },
    // {
    //     name: 'XRP Token (BC Chain)',
    //     value: 'XRP.BEP2'
    // },
    // {
    //     name: 'VERGE',
    //     value: 'XVG'
    // },
    // {
    //     name: 'ZCash',
    //     value: 'ZEC'
    // },
    // {
    //     name: 'Horizen',
    //     value: 'ZEN'
    // },
];

export const MAX_BET_COUNT = 20;

export const maxWebsocketRetryCount = 5;

export const META_LOGIN_TIME = 60000; //1 minute

export const META_ENCRYPTION_KEY = 'x/A?D(G+KbPdSgVkYp3s6v9y$B&E)H@M4u7x!A%D*G-KaPdRgUkXp2s5v8y/B?E(mZq4t7w!z%C*F-JaNdRfUjXn2r5u8x/A';

// export const META_ENCRYPTION_KEY = 'x/A?D(G+KbPdSgVkYp3s6v9y$B&E)H@M4u7x!A%D*G-KaPdRgUkXp2s5v8y/B?E(mZq4t7w!z%C*F-JaNdRfUjXn2r5u8x/A';