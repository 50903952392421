const BASE_API = process.env.REACT_APP_API_URL;
const API_URL = process.env.REACT_APP_API_URL + '/';
const CASINO_URL = process.env.REACT_APP_API_URL;
const ADMIN_URL = process.env.REACT_APP_ADMIN_URL + 'api/v0/';

export const apiConfig = {
    routes: {
        login: API_URL + 'login',
        signup: API_URL + 'signup',
        getOtp: API_URL + 'get-otp',
        verifyOtp: API_URL + 'verify-otp',
        updateUser: API_URL + 'update_user',
        forgotPassword: API_URL + 'forgot-password',
        getBanner: ADMIN_URL + 'users/banners/',
        getPromotionBanners: ADMIN_URL + 'users/promotions/',
        getAdSponsor: ADMIN_URL + 'users/sponsor-banner/',
        snedAdSponsorClick: ADMIN_URL + 'users/sponsor-banner-click/',
        getPageContent: process.env.REACT_APP_ADMIN_URL + 'get-footers/',
        getMatchBanner: 'https://admin.igamingbook.com/api/v0/users/match-banner-api/',
        changePassword: API_URL + 'change-password',
        user: API_URL + 'players',
        cashback: API_URL + 'cashback',
        getDepositAddress: API_URL + 'coin_payment_get_deposit_address',
        deposit: API_URL + 'coin_payment_create_transaction',
        withdraw: API_URL + 'coin_payment_withdraw',
        placeBet: API_URL + 'create-betslip',
        getBetslips: API_URL + 'betslip-list',
        getSingleBetslip: API_URL + 'bet-list',
        getLastBetslip: API_URL + 'last-bet-slip',
        getBetLimits: API_URL + 'get-bet-limits',
        getTransactions: API_URL + 'bets/transactions',
        bonusTransactions: API_URL + 'bonus-transactions',
        getCasinoTransactions: API_URL + 'casino-transactions',
        getCoinPaymentTransactions: API_URL + 'coin-payments-transactions',
        getLiveCasinoTransactions: API_URL + 'live-casino-transactions',
        getGgSlotTransactions: API_URL + 'gg-slot-transactions',
        refreshToken: API_URL + 'refresh',
        tenetCasinoStatus: API_URL + 'tenant_casino_status',
        // getCasinoGames: CASINO_URL + '/game/list_custom',
        initCasinoUser: API_URL + 'casino/initiate-user',
        getGameData: API_URL + 'casino/game',
        getDemoGameData: API_URL + 'casino/demo-game/',
        // getLiveCasinoGames: CASINO_URL + 'live-casino-game-list/',
        getLiveCasinoGames: CASINO_URL + '/game/list_custom',
        getLiveGameData: CASINO_URL + 'single-game',
        placeLiveBet: API_URL + 'place-live-bets',

        getDeviceLocation: 'https://get.geojs.io/v1/ip/country.json',

        getCashoutData: API_URL + 'betslip/get_cashout_amount',
        processCashout: API_URL + 'betslip/process_cashout_amount',
        casinoAPI: CASINO_URL + '/casino/single-game/',
        // casinoAPI: CASINO_URL + '/gg_slot/game',

        getLiveStreamData: API_URL + 'live-stream-events',

        // Virtual sports
        getVirtualSports: CASINO_URL + '/game/list_custom',

        // to search events at sports page
        searchEvent: API_URL + 'search_events',

        // get stats data
        getStatsScore: 'https://api.statscore.com/v2/booked-events?client_id=549&product=prematchcenter&lang=en&mapped_status=mapped&events_details=no',
        lobbyURL: CASINO_URL + '/game/lobby/',
        initGgSlotUser: CASINO_URL + '/gg_slot/initiate-user',
        getGgAllGamesList: 'https://api.gapi.lol/api/v2/games/all',
        ggSlotURL: CASINO_URL + '/gg_slot/set_lobby',
        getPrematchCount: 'https://fvbg10pe89.execute-api.us-east-1.amazonaws.com/prod',
    
        // casinoAPI

        getCasinoGames: CASINO_URL + '/casino/gameslist',
        getCasinoCategories: CASINO_URL + '/casino/categories',
        getCasinoProviders: CASINO_URL + '/casino/providers',
    },
};
