export const ADD_BET = '[APP] ADD BET';
export const REMOVE_BET = '[APP] REMOVE BET';
export const CLEAR_BETS = '[APP] CLEAR BETS';

export const SET_BET_STAKE = '[APP] SET BET STAKE';
export const SET_MULTI_STAKE = '[APP] SET MULTI STAKE';
export const SET_LIMITS = '[APP] SET LIMITS';

export const CHECK_SAVED_BETS = '[APP] CHECK SAVED BETS'; // check in localstorage

export const SET_PLACE_BET_ERROR = '[APP] SET PLACE BET ERROR';
export const SET_PLACE_BET_SUCCESS = '[APP] SET PLACE BET SUCCESS';
export const SET_PLACE_BET_COUNTDOWN = '[APP] SET PLACE BET COUNTDOWN';
export const SET_BET_PLACED_MESSAGE = '[APP] SET BET PLACED MESSAGE';
export const SET_PLACE_BET_DISABLED = '[APP] SET PLACE BET DISABLED';
export const SET_BETSLIP_LOADING = '[APP] SET BETSLIP LOADING';

export const UPDATE_BETS = '[APP] UPDATE BETS'; // after placing a bet
export const UPDATE_BETSLIP_EVENTS_MARKET = '[APP] UPDATE BETSLIP EVENTS MARKET';
export const UPDATE_BETSLIP_EVENTS_LIVESCORE = '[APP] UPDATE BETSLIP EVENTS LIVESCORE';
export const UPDATE_BETSLIP_EVENTS_STATUS = '[APP] UPDATE BETSLIP EVENTS STATUS';

export const SET_LAST_BET_ID = '[APP] SET LAST BET ID';
export const SET_LAST_BETSLIP = '[APP] SET LAST BETSLIP';

export const UPDATE_BET_FIXTURE = '[APP] UPDATE_BET_FIXTURE';
